import GlobalInvoiceDetailProvider from './context';
import GlobalInvoiceDetailHeaderBreadcrump from './header';

import GlobalInvoiceDetailHeader from './content/header';
import GlobalInvoiceDetailFooter from './content/footer';
import GlobalInvoiceDetailTable from './table';

function GlobalInvoiceDetail({ id, noBreadcrump }) {
  return (
    <GlobalInvoiceDetailProvider id={id}>
      {!noBreadcrump && <GlobalInvoiceDetailHeaderBreadcrump />}

      <div className={noBreadcrump ? '' : 'mt-4 container-light'}>
        <GlobalInvoiceDetailHeader noBreadcrump={noBreadcrump} />
        <GlobalInvoiceDetailTable />
        <GlobalInvoiceDetailFooter noBreadcrump={noBreadcrump} />
      </div>
    </GlobalInvoiceDetailProvider>
  );
}

export default GlobalInvoiceDetail;

import React from 'react'
import { I18n } from '@aws-amplify/core';

const Bottom = ({ onConfirm }) => {
  return (
    <div className="modal__bottom h-100">
      <button
        type="button"
        className="w-100 btn-action text-capitalize-first"
        onClick={() => window.open('https://ayuda.alegra.com/es/pago-a-facturas-en-el-punto-de-venta-con-wompi', '_black')}
      >
        {I18n.get("howItWorks", "Cómo funciona")}
      </button>
      <button
        type="button"
        className="w-100 btn btn-submit text-btn-normal text-capitalize-first"
        onClick={() => onConfirm()}
      >
        {I18n.get("activateIntegration", "Activar integración")}
      </button>
    </div>
  )
}

export default Bottom;
import {
  Checkbox,
  Divider,
  Icon,
  InputChip,
  Tabs,
  TextEditor,
  toastHandler,
  Typography,
  useModal,
} from '@alegradev/smile-ui-react';
import { graphqlOperation, I18n } from 'aws-amplify';
import { Field, Form } from 'react-final-form';
import { renderPhone } from '../../forms/fields/V0/Fields';
import { capitalize, get } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import {
  companySelector,
  country as countrySelector,
} from '../../../selectors/company';
import { useEffect, useRef, useState } from 'react';
import { countryByVersion } from '../../../utils';
import { renderField } from '../../../components/forms/fields/V2';
import { COUNTRIES_PHONE_PREFIXES } from '../../../utils/enums/countries';
import countries from '../../../components/countriesData/general/countries';
import { toast } from 'react-toastify';
import {
  completeEmailMessageTemplate,
  completeEmailSubject,
  completeMessageTemplate,
  getExternalClientHash,
  getMailTemplate,
  validate,
} from './utils';
import ShareInvoiceFooter from './Footer';
import alegraAPI from '../../../reducers/alegraAPI';
import { APIGraphqlSelector } from '../../../selectors/app';
import * as mutations from '../../../graphql/mutations';
import { sendGTMEvent, sendNewGTMEvent } from '../../../reducers/company';
import useCountryOptions from '../../../hooks/useCountryOptions/hook';
import { SHARE_METHODS } from '../invoiceSaved/util';
import { getPortalHash } from '../../../selectors/shareInvoice';
import { setHash } from '../../../reducers/shareInvoice';
import { IconBrandWhatsapp, IconLoader2, IconMail } from '@tabler/icons-react';

const ShareInvoice = ({ invoice }) => {
  const fetchCalled = useRef(false);
  const editFormRef = useRef(false);
  const changePhoneCodeRef = useRef(false);
  const [shareBy, setShareBy] = useState(SHARE_METHODS.EMAIL);
  const [hash, setHash] = useState(null);
  const [templateError, setTemplateError] = useState(false);
  const [hashError, setHashError] = useState(false);
  const [emailTemplate, setEmailTemplate] = useState(null);
  const [emailBody, setEmailBody] = useState(null);
  const [emailSubject, setEmailSubject] = useState(null);
  const [whatsappTemplate, setWhatsappTemplate] = useState(null);
  const [whatsappBody, setWhatsappBody] = useState(null);
  const [templateLoaded, setTemplateLoaded] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [pdfUrl, setPdfUrl] = useState(null);
  const [includePortalLink, setIncludePortalLink] = useState(false);
  const [loadingEmail, setLoadingEmail] = useState(false);
  const [client, setClient] = useState(null);
  const company = useSelector(companySelector);
  const country = useSelector(countrySelector);
  const APIGraphql = useSelector(APIGraphqlSelector);
  const { closeModal } = useModal();
  const dispatch = useDispatch();

  const [phoneValue, setPhoneValue] = useState(null);
  const [phonePrefix, setPhonePrefix] = useState(null);

  const countryOptions = useCountryOptions(countries, searchValue);

  useEffect(() => {
    if (!client) {
      setClient(get(invoice, 'client', null));
    }
  }, [invoice]);

  useEffect(() => {
    const fetchData = async () => {
      const idClient = get(invoice, 'client.id', null);
      const idInvoice = get(invoice, 'id', null);
      const idCompany = get(company, 'id', null);

      if (!idClient || !idInvoice || !idCompany) return;

      setTemplateLoaded(false);

      try {
        const [template, response] = await Promise.all([
          getMailTemplate(),
          alegraAPI.get(`/invoices/${idInvoice}?fields=pdf`),
        ]);

        setTemplateError(false);
        setPdfUrl(get(response, 'data.pdf'));
        setEmailTemplate(get(template, 'message', null));
        setEmailSubject(get(template, 'subject', null));
        setWhatsappTemplate(get(template, 'whatsappTemplateMessage', null));
        setTemplateLoaded(true);
      } catch (error) {
        setTemplateError(true);
        toast.error({
          title: I18n.get('', 'Ocurrió un error al obtener la plantilla'),
        });
        console.error(error);
      }

      try {
        const result = await getExternalClientHash({
          body: {
            idClient: parseInt(idClient),
            idInvoice: parseInt(idInvoice),
            idCompany: parseInt(idCompany),
          },
        });

        setHashError(false);
        setHash(get(result, 'data.link'));
        setIncludePortalLink(true);
      } catch (error) {
        setHashError(true);
        setIncludePortalLink(false);
        toast.error({
          title: I18n.get('', 'Ocurrió un error inesperado'),
        });
        console.error(error);
      }
    };

    if (!fetchCalled.current) {
      fetchCalled.current = true;
      fetchData();
    }
  }, [company, invoice]);

  useEffect(() => {
    if (templateLoaded && whatsappTemplate !== null && hash) {
      setWhatsappBody(
        completeMessageTemplate(whatsappTemplate, hash, company, invoice)
      );
    }

    if (templateLoaded && emailTemplate !== null && emailSubject !== null) {
      setEmailBody(
        completeEmailMessageTemplate(
          emailTemplate,
          hash,
          company,
          invoice,
          includePortalLink
        )
      );

      setEmailSubject(completeEmailSubject(emailSubject, invoice));
    }
  }, [templateLoaded, whatsappTemplate, emailTemplate, emailSubject, hash]);

  const getDocumentType = () => {
    if (country === 'spain') {
      if (get(invoice, 'numberTemplate.documentType') === 'saleTicket') {
        return I18n.get('invoiceModalTitle.saleTicket', 'Ticket');
      }
    }
    return I18n.get(get(invoice, 'numberTemplate.documentType'), 'invoice');
  };

  const handleSubmit = async (values) => {
    if (shareBy === SHARE_METHODS.WHATSAPP) {
      const phoneNumber =
        (phonePrefix || get(values, 'phoneCountry.dialCode')) +
        get(values, 'phone', null);
      dispatch(
        sendNewGTMEvent('pos-invoice-shared', {
          medium: SHARE_METHODS.WHATSAPP,
        })
      );
      window.open(
        `https://api.whatsapp.com/send?phone=${phoneNumber}&text=${encodeURI(whatsappBody)}`,
        '_blank'
      );
    } else if (shareBy === SHARE_METHODS.EMAIL) {
      try {
        setLoadingEmail(true);
        const response = await APIGraphql(
          graphqlOperation(mutations.sendEmail, {
            email: {
              name: 'sendInvoiceByEmail',
              from: 'no-responder@mail1.alegra.com',
              to: get(values, 'to[0].value', null),
              subject: emailSubject,
              applicationVersion: get(company, 'applicationVersion'),
              body: emailBody,
              attachments: [
                {
                  attachmentName:
                    getDocumentType() + get(invoice, 'numberTemplate.number'),
                  attachmentMimeType: 'application/pdf',
                  attachmentUrl: get(values, 'invoicePdfUrl'),
                },
              ],
            },
          })
        );
        toastHandler({
          type: 'success',
          title: I18n.get('', 'Correo enviado con éxito'),
        });
        dispatch(
          sendNewGTMEvent('pos-invoice-shared', {
            medium: 'mail',
          })
        );
        setLoadingEmail(false);
        closeModal('ShareInvoice');
      } catch (error) {
        setLoadingEmail(false);
        console.error(error);
      }
    }
  };

  const handleOnTabChanged = (id) => {
    setShareBy(id);
    dispatch(
      sendNewGTMEvent('pos-sale-managed', {
        id: get(invoice, 'id', ''),
        action: id,
      })
    );
  };

  // useEffect(() => {
  //   if (typeof get(form.getFieldState('phone'), 'value') === 'string') {
  //     setPhoneValue(get(form.getFieldState('phone'), 'value'));
  //   }
  // }, [form.getFieldState('phone')]);

  return (
    <div className='share-invoice'>
      <Tabs
        defaultTab={shareBy}
        items={[
          {
            id: SHARE_METHODS.EMAIL,
            title: I18n.get('email', 'Correo'),
            leftIcon: IconMail,
          },
          {
            id: SHARE_METHODS.WHATSAPP,
            title: I18n.get('whatsapp', 'WhatsApp'),
            hided: hashError && templateLoaded,
            leftIcon: IconBrandWhatsapp,
          },
        ]}
        simple={true}
        onTabChanged={handleOnTabChanged}
      />
      {/* <div className='d-flex gap-3 mt-3'>
        <button
          className={`d-flex align-items-center btn btn-md-default-outline ${shareBy === SHARE_METHODS.EMAIL ? 'share-invoice-btn-selected' : ''}`}
          onClick={() => {
            setShareBy(SHARE_METHODS.EMAIL);
            dispatch(
              sendNewGTMEvent('pos-sale-managed', {
                id: get(invoice, 'id', ''),
                action: 'email',
              })
            );
          }}
        >
          <Icon icon={IconMail} color='#94A3B8' />
          <Typography
            type='body-3-regular'
            text={I18n.get('email', 'Correo electrónico')}
          />
        </button>
        {!hashError &&
          (!templateLoaded ? (
            <div className='d-flex align-items-center'>
              <Icon icon={IconLoader2} animated extraClass='icon-primary' />
            </div>
          ) : (
            <button
              className={`d-flex align-items-center btn btn-md-default-outline ${shareBy === SHARE_METHODS.WHATSAPP ? 'share-invoice-btn-selected' : ''}`}
              onClick={() => {
                setShareBy(SHARE_METHODS.WHATSAPP);
                dispatch(
                  sendNewGTMEvent('pos-sale-managed', {
                    id: get(invoice, 'id', ''),
                    action: SHARE_METHODS.WHATSAPP,
                  })
                );
              }}
            >
              <Icon icon={IconBrandWhatspp} color='#94A3B8' />
              <Typography
                type='body-3-regular'
                text={I18n.get('whatsapp', 'WhatsApp')}
              />
            </button>
          ))}
      </div> */}
      <Divider className='my-3' />
      <Form
        validate={(values) => validate(values, shareBy)}
        initialValues={{
          phone: !changePhoneCodeRef.current
            ? get(client, 'mobile', null) || get(client, 'phonePrimary', null)
            : phoneValue,
          phoneCountry: countryByVersion(country),
          message: whatsappBody,
          subject: emailSubject,
          emailBody: emailBody,
          invoicePdfUrl: pdfUrl,
          to: [{ value: get(client, 'email', null) }],
        }}
        onSubmit={handleSubmit}
      >
        {({ handleSubmit, form, errors }) => {
          if (typeof get(form.getFieldState('phone'), 'value') === 'string') {
            changePhoneCodeRef.current = true;
            setPhoneValue(get(form.getFieldState('phone'), 'value'));
          }

          return templateLoaded ? (
            <div>
              {shareBy === 'whatsapp' ? (
                <>
                  <div className='col-md-7 p-0'>
                    <Field
                      name='phone'
                      render={renderField}
                      type='number'
                      leadingAddOn={{
                        defaultOption:
                          COUNTRIES_PHONE_PREFIXES[country.toUpperCase()],
                        width: 80,
                        search: {
                          enabled: true,
                          onSearch: (value) => {
                            changePhoneCodeRef.current = true;
                            setSearchValue(value);
                          },
                        },
                        onChange: (value) => {
                          changePhoneCodeRef.current = true;
                          form.change('phonePrefix', value?.value);
                          setPhonePrefix(value?.value);
                        },
                        options: countryOptions.map((country) => ({
                          value: country.dialCode,
                          text: country.dialCode,
                          overflowText: `${country.value} (${country.dialCode})`,
                          leading: {
                            type: 'slot',
                            slot: (
                              <span
                                className={`fi fi-${country.flag}`}
                                style={{ fontSize: 16 }}
                              />
                            ),
                          },
                        })),
                      }}
                      required
                      placeholder={I18n.get(
                        'enterYourClientsPhone',
                        'Escribe el teléfono de tu cliente'
                      )}
                      label={I18n.get('phone', 'Teléfono')}
                    />
                  </div>
                  <div className='mt-4'>
                    <div className='mt-2'>
                      <Field
                        name='message'
                        render={renderField}
                        rows={10}
                        fieldType='textarea'
                        required
                        placeholder={I18n.get(
                          'shareInvoice.whatsapp.message.placeholder',
                          'Ingresa el mensaje que enviarás a tu cliente'
                        )}
                        label={I18n.get('message', 'Mensaje')}
                      />
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div className='col-12 form-row p-0'>
                    <div className='col-md-2'>
                      <Typography type='label-2' text={'Para:'} />
                    </div>
                    <div className='col-md-10'>
                      <InputChip
                        max={1}
                        {...(!editFormRef.current &&
                          get(client, 'email', '') && {
                            defaultOptions: get(client, 'email', '')
                              ? [
                                  {
                                    label: get(client, 'email', ''),
                                    value: get(client, 'email', ''),
                                  },
                                ]
                              : [],
                          })}
                        inputPlaceholder={I18n.get(
                          'email',
                          'Correo electrónico'
                        )}
                        inputValidation={(value) =>
                          value.includes('@') && value.includes('.')
                            ? undefined
                            : I18n.get('invalidEmail', 'Correo inválido')
                        }
                        onChange={(options) => {
                          form.change('to', options);
                        }}
                        onRemove={() => {
                          editFormRef.current = true;
                        }}
                      />
                      <div className='mt-4'>
                        <Checkbox label='Enviar una copia a mi correo' />
                      </div>
                    </div>
                  </div>
                  <div className='col-12 form-row mt-4 p-0'>
                    <div className='col-md-2'>
                      <Typography type='label-2' text={'Asunto:'} />
                    </div>
                    <div className='col-md-10'>
                      <Field
                        name='subject'
                        render={renderField}
                        required
                        placeholder={I18n.get('subject', 'Asunto')}
                      />
                    </div>
                  </div>
                  <div className='mt-4'>
                    <TextEditor
                      defaultValue={completeEmailMessageTemplate(
                        emailTemplate,
                        hash,
                        company,
                        invoice,
                        includePortalLink
                      )}
                      readonly={true}
                      onChange={setEmailBody}
                    />
                    <div className='mt-4'>
                      <Checkbox
                        checked={includePortalLink}
                        disabled={hashError}
                        onChange={() => {
                          setIncludePortalLink(!includePortalLink);
                          setEmailBody(
                            completeEmailMessageTemplate(
                              emailTemplate,
                              hash,
                              company,
                              invoice,
                              !includePortalLink
                            )
                          );
                        }}
                        label='Incluir enlace al portal'
                      />
                    </div>
                  </div>
                </>
              )}

              <ShareInvoiceFooter
                method={shareBy}
                errors={errors}
                modalName={'ShareInvoice'}
                hash={hash}
                loadingEmail={loadingEmail}
                invoice={invoice}
                hashError={hashError}
              />
            </div>
          ) : (
            <Icon icon={IconLoader2} animated extraClass='icon-primary' />
          );
        }}
      </Form>
    </div>
  );
};

export default ShareInvoice;

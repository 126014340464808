import React from 'react'
import { I18n } from '@aws-amplify/core';

const NotAllowedToView = () => {

  return (
    <div className="p-4 w-100 h-100 d-flex align-items-center justify-content-center overflow-hidden">
      <div className="d-flex flex-column align-items-center card p-5">
        <p className="text-capitalize-first h3 mb-3 text-center">
          {I18n.get('userNotAllowed.invoices.view', 'no tienes permisos suficientes para ver detalles de las facturas')}
        </p>
      </div>
    </div>
  )
}

export default NotAllowedToView;
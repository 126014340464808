import React from 'react'
import { useSelector } from 'react-redux';
import { I18n } from '@aws-amplify/core';
import { capitalize } from 'lodash';

import { items as itemsSelector } from '../../../../selectors/activeRefund';
import Item from './Item';

const RefundItems = ({ openEditItem }) => {
  const items = useSelector(state => itemsSelector(state))

  return (
    <div className="h-100 active-refund__items">
      {(!!items && items.length > 0)
        ? items.map((item, index) => (
          <Item 
            key={item.id} 
            item={item} 
            index={index} 
            openEditItem={openEditItem}
          />
        ))
        : (
          <div className="p-2 w-100 h4 h-100 text-muted text-capitalize-first d-flex justify-content-center align-items-center text-center">
            {capitalize(I18n.get('selectProductsToCreateYourRefund', 'selecciona productos para crear tu devolución'))}
          </div>
        )
      }
    </div>
  )
}

export default RefundItems;
import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { I18n } from '@aws-amplify/core';
import { capitalize } from 'lodash'

import { openModal } from '../../reducers/modals';
import { country as countrySelector } from '../../selectors/company';
import { sendGTMEvent } from '../../reducers/company';

const EmptyDetail = () => {
  const dispatch = useDispatch()
  const country = useSelector(countrySelector)

  const getSeeMore = country => {
    switch (country) {
      case "mexico":
        return 'https://ayuda.alegra.com/es/lleva-un-control-de-tu-efectivo-desde-el-tpv-mex';
      default:
        return 'https://ayuda.alegra.com/es/alegra-pos-en-tu-negocio#gestion-efectivo';
    }
  }

  return (
    <div className="p-4 w-100 h-100 d-flex align-items-center justify-content-center overflow-hidden">
      <div className="d-flex flex-column align-items-center card p-5">
        <p className="text-capitalize-first h3 mb-3 text-center">
          {I18n.get('notPaymentYet', 'aún no tienes movimientos de efectivo creados desde POS')}{' '}
          <a className="text-capitalize-first btn-link" href={getSeeMore(country)} target="_blank" rel="noreferrer">
            {capitalize(I18n.get('seeMore', 'ver más'))}
          </a>
        </p>
        <button
          type="button"
          className="btn btn-submit"
          onClick={() => {
            dispatch(openModal({ modal: 'payments' }));
            dispatch(sendGTMEvent("new-cash-movement-attempted", {
              attemptOrigin: "from cash management",
              creationStatus: "success",
            }))
          }}
        >
          {I18n.get('inOutCash', 'ingresar / retirar efectivo')}
        </button>
      </div>
    </div>
  )
}

export default EmptyDetail;
import { get } from 'lodash';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { idCompanySelector } from '../selectors/company';
import * as subscriptions from '../graphql/subscriptions';
import { API, graphqlOperation } from 'aws-amplify';
import FilesAPI from '../reducers/filesAPI';
import {
  addPaymentMethod,
  changePaymentMethodDetails,
} from '../reducers/paymentMethods';

const PaymentMethodSns = () => {
  const ref = useRef();
  const dispatch = useDispatch();
  const idCompany = useSelector(idCompanySelector);
  const networkStatus = useSelector((state) =>
    get(state, 'app.networkStatus', 'online')
  );
  const [onCreatePaymentMethodError, setOnCreatePaymentMethodError] =
    useState(false);
  const [onUpdatePaymentMethodError, setOnUpdatePaymentMethodError] =
    useState(false);

  useEffect(() => {
    let subscription = null;
    if (networkStatus === 'online') {
      subscription = API.graphql(
        graphqlOperation(subscriptions.onCreateCustomProviderNotify, {
          channel: `pos_${idCompany}`,
        })
      ).subscribe({
        next: async (data) => {
          const newPaymentMethod = get(
            data,
            'value.data.onCreateCustomProviderNotify',
            null
          );
          let paymentMethod = {};
          if (newPaymentMethod !== null) {
            paymentMethod = {
              id: get(newPaymentMethod, 'idLocal', null),
              idLocal: get(newPaymentMethod, 'idLocal', null),
              instructions: get(newPaymentMethod, 'instructions', null),
              name: get(newPaymentMethod, 'name', null),
              status: 'active',
            };
            if (get(newPaymentMethod, 'logo.id', null)) {
              const response = await FilesAPI.get(
                `/files/${get(newPaymentMethod, 'logo.id', null)}`
              );

              if (get(response, 'data', null)) {
                paymentMethod = {
                  ...paymentMethod,
                  logo: {
                    id: get(response, 'data.id', null),
                    url: get(response, 'data.url', null),
                    is_private: get(response, 'data.is_private', null),
                  },
                };
              }
            }

            if (get(newPaymentMethod, 'qr.id', null)) {
              const response = await FilesAPI.get(
                `/files/${get(newPaymentMethod, 'qr.id', null)}`
              );

              if (get(response, 'data', null)) {
                paymentMethod = {
                  ...paymentMethod,
                  qr: {
                    id: get(response, 'data.id', null),
                    url: get(response, 'data.url', null),
                    is_private: get(response, 'data.is_private', null),
                  },
                };
              }
            }

            dispatch(addPaymentMethod(paymentMethod));
          }
        },
        error: (error) =>
          setTimeout(() => {
            console.log('error ============>', error);
            if (!!ref.current)
              setOnCreatePaymentMethodError(!onCreatePaymentMethodError);
          }, 60000),
      });
    }
    return () => {
      if (subscription) subscription.unsubscribe();
    };
  }, [networkStatus, onCreatePaymentMethodError, dispatch, idCompany]);

  useEffect(() => {
    let subscription = null;
    if (networkStatus === 'online') {
      subscription = API.graphql(
        graphqlOperation(subscriptions.onUpdateCustomProviderNotify, {
          channel: `pos_${idCompany}`,
        })
      ).subscribe({
        next: async (data) => {
          const updatedPaymentMethod = get(
            data,
            'value.data.onUpdateCustomProviderNotify',
            null
          );
          let paymentMethod = {};
          if (updatedPaymentMethod !== null) {
            paymentMethod = {
              id: get(updatedPaymentMethod, 'idLocal', null),
              idLocal: get(updatedPaymentMethod, 'idLocal', null),
              instructions: get(updatedPaymentMethod, 'instructions', null),
              name: get(updatedPaymentMethod, 'name', null),
              status: 'active',
            };
            if (get(updatedPaymentMethod, 'logo.id', null)) {
              const response = await FilesAPI.get(
                `/files/${get(updatedPaymentMethod, 'logo.id', null)}`
              );

              if (get(response, 'data', null)) {
                paymentMethod = {
                  ...paymentMethod,
                  logo: {
                    id: get(response, 'data.id', null),
                    url: get(response, 'data.url', null),
                    is_private: get(response, 'data.is_private', null),
                  },
                };
              }
            }

            if (get(updatedPaymentMethod, 'qr.id', null)) {
              const response = await FilesAPI.get(
                `/files/${get(updatedPaymentMethod, 'qr.id', null)}`
              );

              if (get(response, 'data', null)) {
                paymentMethod = {
                  ...paymentMethod,
                  qr: {
                    id: get(response, 'data.id', null),
                    url: get(response, 'data.url', null),
                    is_private: get(response, 'data.is_private', null),
                  },
                };
              }
            }

            dispatch(changePaymentMethodDetails(paymentMethod));
          }
        },
        error: (error) =>
          setTimeout(() => {
            if (!!ref.current)
              setOnUpdatePaymentMethodError(!onUpdatePaymentMethodError);
          }, 60000),
      });
    }
    return () => {
      if (subscription) subscription.unsubscribe();
    };
  }, [networkStatus, onUpdatePaymentMethodError, dispatch, idCompany]);

  useEffect(() => {
    ref.current = true;
    return () => (ref.current = false);
  }, []);

  return null;
};

export default PaymentMethodSns;

/**
 * class CanVoid strategies.
 * Each strategy should implement the canVoid method.
 *
 * @class
 */
class CanVoidStrategy {
  /**
   * Determines if the invoice can be voided based on specific logic.
   *
   * @param {Object} invoice - The invoice object.
   * @param {Object} context - Additional context required for evaluation.
   * @param {string} context.country - The country code.
   * @param {Object} context.company - The company object.
   * @param {boolean} context.isShiftEnabled - Indicates if shifts are enabled.
   * @param {boolean} context.isShiftOpen - Indicates if a shift is currently open.
   * @param {Object} context.currentShift - The current shift object.
   * @param {Function} context.can - Permission checker function.
   * @returns {boolean} True if the invoice can be voided, false otherwise.
   */
  canVoid(invoice, context) {
    throw new Error('canVoid method must be implemented');
  }
}

export default CanVoidStrategy;
import { createSlice } from '@reduxjs/toolkit';
import { getInitialState } from '../utils';

const initialState = {
  settings: {},
  version: 1
}

const appSlice = createSlice({
  name: 'aditionalSettings',
  initialState: getInitialState('aditionalSettings', initialState),
  reducers: {
    loadAditionalSettings: (state, action) => {
      let settings = localStorage.getItem('settings') ? JSON.parse(localStorage.getItem('settings')) : { "priceList": true };
      return {
        settings
      }
    },
    setAditionalSettings: (state, action) => {

      const { value, checked } = action.payload;
      let settings = localStorage.getItem('settings') ? JSON.parse(localStorage.getItem('settings')) : {};
      if (checked) {
        settings[value] = true;
      } else {
        settings[value] = false;
      }
      localStorage.setItem('settings', JSON.stringify(settings));
      return {
        settings
      }
    }
  },
});

const { actions, reducer } = appSlice;

export const { loadAditionalSettings, setAditionalSettings } = actions;

export default reducer;
const conditionsIibb = [
  {
    value: "Régimen simplificado",
    key: "SIMPLIFIED_REGIME"
  },
  {
    value: "Exento",
    key: "EXCENT"
  },
  {
    value: "Local (Provincial o CABA)",
    key: "LOCAL"
  },
  {
    value: "Otra",
    key: "OTHER"
  },
  {
    value: "Convenio multilateral",
    key: "MULTILATERAL_AGREEMENT"
  }
]

export default conditionsIibb
const countries =
  [
    {
      "key": "AFG",
      "value": "Afganistán",
      "alpha_2": "AF",
      "flag": "af",
      "dialCode": "+93"
    },
    {
      "key": "ALA",
      "value": "Islas de Åland",
      "alpha_2": "AX",
      "flag": "ax",
      "dialCode": "+358"
    },
    {
      "key": "ALB",
      "value": "Albania",
      "alpha_2": "AL",
      "flag": "al",
      "dialCode": "+355"
    },
    {
      "key": "DZA",
      "value": "Argelia",
      "alpha_2": "DZ",
      "flag": "dz",
      "dialCode": "+213"
    },
    {
      "key": "ASM",
      "value": "Samoa Americana",
      "alpha_2": "AS",
      "flag": "as",
      "dialCode": "+1684"
    },
    {
      "key": "AND",
      "value": "Andorra",
      "alpha_2": "AD",
      "flag": "ad",
      "dialCode": "+376"
    },
    {
      "key": "AGO",
      "value": "Angola",
      "alpha_2": "AO",
      "flag": "ao",
      "dialCode": "+244"
    },
    {
      "key": "AIA",
      "value": "Anguila",
      "alpha_2": "AI",
      "flag": "ai",
      "dialCode": "+1264"
    },
    {
      "key": "ATA",
      "value": "Antártida",
      "alpha_2": "AQ",
      "flag": "aq",
      "dialCode": "+672"
    },
    {
      "key": "ATG",
      "value": "Antigua y Barbuda",
      "alpha_2": "AG",
      "flag": "ag",
      "dialCode": "+1268"
    },
    {
      "key": "ARG",
      "value": "Argentina",
      "alpha_2": "AR",
      "flag": "ar",
      "dialCode": "+54"
    },
    {
      "key": "ARM",
      "value": "Armenia",
      "alpha_2": "AM",
      "flag": "am",
      "dialCode": "+374"
    },
    {
      "key": "ABW",
      "value": "Aruba",
      "alpha_2": "AW",
      "flag": "aw",
      "dialCode": "+297"
    },
    {
      "key": "AUS",
      "value": "Australia",
      "alpha_2": "AU",
      "flag": "au",
      "dialCode": "+61"
    },
    {
      "key": "AUT",
      "value": "Austria",
      "alpha_2": "AT",
      "flag": "at",
      "dialCode": "+43"
    },
    {
      "key": "AZE",
      "value": "Azerbaiyán",
      "alpha_2": "AZ",
      "flag": "az",
      "dialCode": "+994"
    },
    {
      "key": "BHS",
      "value": "Bahamas",
      "alpha_2": "BS",
      "flag": "bs",
      "dialCode": "+1242"
    },
    {
      "key": "BHR",
      "value": "Bahréin",
      "alpha_2": "BH",
      "flag": "bh",
      "dialCode": "+973"
    },
    {
      "key": "BGD",
      "value": "Bangladesh",
      "alpha_2": "BD",
      "flag": "bd",
      "dialCode": "+880"
    },
    {
      "key": "BRB",
      "value": "Barbados",
      "alpha_2": "BB",
      "flag": "bb",
      "dialCode": "+1246"
    },
    {
      "key": "BLR",
      "value": "Bielorrusia",
      "alpha_2": "BY",
      "flag": "by",
      "dialCode": "+375"
    },
    {
      "key": "BEL",
      "value": "Bélgica",
      "alpha_2": "BE",
      "flag": "be",
      "dialCode": "+32"
    },
    {
      "key": "BLZ",
      "value": "Belice",
      "alpha_2": "BZ",
      "flag": "bz",
      "dialCode": "+501"
    },
    {
      "key": "BEN",
      "value": "Benín",
      "alpha_2": "BJ",
      "flag": "bj",
      "dialCode": "+229"
    },
    {
      "key": "BMU",
      "value": "Islas Bermudas",
      "alpha_2": "BM",
      "flag": "bm",
      "dialCode": "+1441"
    },
    {
      "key": "BTN",
      "value": "Bután",
      "alpha_2": "BT",
      "flag": "bt",
      "dialCode": "+975"
    },
    {
      "key": "BOL",
      "value": "Bolivia",
      "alpha_2": "BO",
      "flag": "bo",
      "dialCode": "+591"
    },
    {
      "key": "BES",
      "value": "Bonaire, San Eustaquio y Saba",
      "alpha_2": "BQ",
      "flag": "bq",
      "dialCode": "+599"
    },
    {
      "key": "BIH",
      "value": "Bosnia y Herzegovina",
      "alpha_2": "BA",
      "flag": "ba",
      "dialCode": "+387"
    },
    {
      "key": "BWA",
      "value": "Botsuana",
      "alpha_2": "BW",
      "flag": "bw",
      "dialCode": "+267"
    },
    {
      "key": "BVT",
      "value": "Isla Bouvet",
      "alpha_2": "BV",
      "flag": "bv",
      "dialCode": "+47"
    },
    {
      "key": "BRA",
      "value": "Brasil",
      "alpha_2": "BR",
      "flag": "br",
      "dialCode": "+55"
    },
    {
      "key": "IOT",
      "value": "Territorio Británico del Océano Índico",
      "alpha_2": "IO",
      "flag": "io",
      "dialCode": "+246"
    },
    {
      "key": "BRN",
      "value": "Brunéi",
      "alpha_2": "BN",
      "flag": "bn",
      "dialCode": "+673"
    },
    {
      "key": "BGR",
      "value": "Bulgaria",
      "alpha_2": "BG",
      "flag": "bg",
      "dialCode": "+359"
    },
    {
      "key": "BFA",
      "value": "Burkina Faso",
      "alpha_2": "BF",
      "flag": "bf",
      "dialCode": "+226"
    },
    {
      "key": "BDI",
      "value": "Burundi",
      "alpha_2": "BI",
      "flag": "bi",
      "dialCode": "+257"
    },
    {
      "key": "KHM",
      "value": "Camboya",
      "alpha_2": "KH",
      "flag": "kh",
      "dialCode": "+855"
    },
    {
      "key": "CMR",
      "value": "Camerún",
      "alpha_2": "CM",
      "flag": "cm",
      "dialCode": "+237"
    },
    {
      "key": "CAN",
      "value": "Canad\u00e1",
      "alpha_2": "CA",
      "flag": "ca",
      "dialCode": "+1"
    },
    {
      "key": "CPV",
      "value": "Cabo Verde",
      "alpha_2": "CV",
      "flag": "cv",
      "dialCode": "+238"
    },
    {
      "key": "CYM",
      "value": "Islas Caim\u00e1n",
      "alpha_2": "KY",
      "flag": "ky",
      "dialCode": "+1345"
    },
    {
      "key": "CAF",
      "value": "Rep\u00fablica Centroafrica na",
      "alpha_2": "CF",
      "flag": "cf",
      "dialCode": "+236"
    },
    {
      "key": "TCD",
      "value": "Chad",
      "alpha_2": "TD",
      "flag": "td",
      "dialCode": "+235"
    },
    {
      "key": "CHL",
      "value": "Chile",
      "alpha_2": "CL",
      "flag": "cl",
      "dialCode": "+56"
    },
    {
      "key": "CHN",
      "value": "China",
      "alpha_2": "CN",
      "flag": "cn",
      "dialCode": "+86"
    },
    {
      "key": "CXR",
      "value": "Isla de Navidad",
      "alpha_2": "CX",
      "flag": "cx",
      "dialCode": "+61"
    },
    {
      "key": "CCK",
      "value": "Islas Cocos (Keeling)",
      "alpha_2": "CC",
      "flag": "cc",
      "dialCode": "+61"
    },
    {
      "key": "COL",
      "value": "Colombia",
      "alpha_2": "CO",
      "flag": "co",
      "dialCode": "+57"
    },
    {
      "key": "COM",
      "value": "Comoras",
      "alpha_2": "KM",
      "flag": "km",
      "dialCode": "+269"
    },
    {
      "key": "COG",
      "value": "Congo",
      "alpha_2": "CG",
      "flag": "cg",
      "dialCode": "+242"
    },
    {
      "key": "COD",
      "value": "Congo",
      "alpha_2": "CD",
      "flag": "cd",
      "dialCode": "+243"
    },
    {
      "key": "COK",
      "value": "Islas Cook",
      "alpha_2": "CK",
      "flag": "ck",
      "dialCode": "+682"
    },
    {
      "key": "CRI",
      "value": "Costa Rica",
      "alpha_2": "CR",
      "flag": "cr",
      "dialCode": "+506"
    },
    {
      "key": "CIV",
      "value": "Costa de Marfil",
      "alpha_2": "CI",
      "flag": "ci",
      "dialCode": "+225"
    },
    {
      "key": "HRV",
      "value": "Croacia",
      "alpha_2": "HR",
      "flag": "hr",
      "dialCode": "+385"
    },
    {
      "key": "CUB",
      "value": "Cuba",
      "alpha_2": "CU",
      "flag": "cu",
      "dialCode": "+53"
    },
    {
      "key": "CUW",
      "value": "Curacao",
      "alpha_2": "CW",
      "flag": "cw",
      "dialCode": "+599"
    },
    {
      "key": "CYP",
      "value": "Chipre",
      "alpha_2": "CY",
      "flag": "cy",
      "dialCode": "+357"
    },
    {
      "key": "CZE",
      "value": "Rep\u00fablica Checa",
      "alpha_2": "CZ",
      "flag": "cz",
      "dialCode": "+420"
    },
    {
      "key": "DNK",
      "value": "Dinamarca",
      "alpha_2": "DK",
      "flag": "dk",
      "dialCode": "+45"
    },
    {
      "key": "DJI",
      "value": "Yibuti",
      "alpha_2": "DJ",
      "flag": "dj",
      "dialCode": "+253"
    },
    {
      "key": "DMA",
      "value": "Dominica",
      "alpha_2": "DM",
      "flag": "dm",
      "dialCode": "+1767"
    },
    {
      "key": "DOM",
      "value": "Rep\u00fablica Dominicana",
      "alpha_2": "DO",
      "flag": "do",
      "dialCode": "+1"
    },
    {
      "key": "ECU",
      "value": "Ecuador",
      "alpha_2": "EC",
      "flag": "ec",
      "dialCode": "+593"
    },
    {
      "key": "EGY",
      "value": "Egipto",
      "alpha_2": "EG",
      "flag": "eg",
      "dialCode": "+20"
    },
    {
      "key": "SLV",
      "value": "El Salvador",
      "alpha_2": "SV",
      "flag": "sv",
      "dialCode": "+503"
    },
    {
      "key": "GNQ",
      "value": "Guinea Ecuatorial",
      "alpha_2": "GQ",
      "flag": "gq",
      "dialCode": "+240"
    },
    {
      "key": "ERI",
      "value": "Eritrea",
      "alpha_2": "ER",
      "flag": "er",
      "dialCode": "+291"
    },
    {
      "key": "EST",
      "value": "Estonia",
      "alpha_2": "EE",
      "flag": "ee",
      "dialCode": "+372"
    },
    {
      "key": "ETH",
      "value": "Etiop\u00eda",
      "alpha_2": "ET",
      "flag": "et",
      "dialCode": "+251"
    },
    {
      "key": "FLK",
      "value": "Islas Malvinas",
      "alpha_2": "FK",
      "flag": "fk",
      "dialCode": "+500"
    },
    {
      "key": "FRO",
      "value": "Islas Feroe",
      "alpha_2": "FO",
      "flag": "fo",
      "dialCode": "+298"
    },
    {
      "key": "FJI",
      "value": "Fiyi",
      "alpha_2": "FJ",
      "flag": "fj",
      "dialCode": "+679"
    },
    {
      "key": "FIN",
      "value": "Finlandia",
      "alpha_2": "FI",
      "flag": "fi",
      "dialCode": "+358"
    },
    {
      "key": "FRA",
      "value": "Francia",
      "alpha_2": "FR",
      "flag": "fr",
      "dialCode": "+33"
    },
    {
      "key": "GUF",
      "value": "Guayana Francesa",
      "alpha_2": "GF",
      "flag": "gf",
      "dialCode": "+594"
    },
    {
      "key": "PYF",
      "value": "Polinesia Francesa",
      "alpha_2": "PF",
      "flag": "pf",
      "dialCode": "+689"
    },
    {
      "key": "ATF",
      "value": "Territorios Australes y Ant\u00e1rticas Franceses",
      "alpha_2": "TF",
      "flag": "tf",
      "dialCode": "+262"
    },
    {
      "key": "GAB",
      "value": "Gab\u00f3n",
      "alpha_2": "GA",
      "flag": "ga",
      "dialCode": "+241"
    },
    {
      "key": "GMB",
      "value": "Gambia",
      "alpha_2": "GM",
      "flag": "gm",
      "dialCode": "+220"
    },
    {
      "key": "GEO",
      "value": "Georgia",
      "alpha_2": "GE",
      "flag": "ge",
      "dialCode": "+995"
    },
    {
      "key": "DEU",
      "value": "Alemania",
      "alpha_2": "DE",
      "flag": "de",
      "dialCode": "+49"
    },
    {
      "key": "GHA",
      "value": "Ghana",
      "alpha_2": "GH",
      "flag": "gh",
      "dialCode": "+233"
    },
    {
      "key": "GIB",
      "value": "Gibraltar",
      "alpha_2": "GI",
      "flag": "gi",
      "dialCode": "+350"
    },
    {
      "key": "GRC",
      "value": "Grecia",
      "alpha_2": "GR",
      "flag": "gr",
      "dialCode": "+30"
    },
    {
      "key": "GRL",
      "value": "Groenlandi a",
      "alpha_2": "GL",
      "flag": "gl",
      "dialCode": "+299"
    },
    {
      "key": "GRD",
      "value": "Granada",
      "alpha_2": "GD",
      "flag": "gd",
      "dialCode": "+1473"
    },
    {
      "key": "GLP",
      "value": "Guadalupe",
      "alpha_2": "GP",
      "flag": "gp",
      "dialCode": "+590"
    },
    {
      "key": "GUM",
      "value": "Guam",
      "alpha_2": "GU",
      "flag": "gu",
      "dialCode": "+1671"
    },
    {
      "key": "GTM",
      "value": "Guatemala",
      "alpha_2": "GT",
      "flag": "gt",
      "dialCode": "+502"
    },
    {
      "key": "GGY",
      "value": "Guernsey",
      "alpha_2": "GG",
      "flag": "gg",
      "dialCode": "+44"
    },
    {
      "key": "GIN",
      "value": "Guinea",
      "alpha_2": "GN",
      "flag": "gn",
      "dialCode": "+224"
    },
    {
      "key": "GNB",
      "value": "Guinea- Bissau",
      "alpha_2": "GW",
      "flag": "gw",
      "dialCode": "+245"
    },
    {
      "key": "GUY",
      "value": "Guyana",
      "alpha_2": "GY",
      "flag": "gy",
      "dialCode": "+592"
    },
    {
      "key": "HTI",
      "value": "Hait\u00ed",
      "alpha_2": "HT",
      "flag": "ht",
      "dialCode": "+509"
    },
    {
      "key": "HMD",
      "value": "Islas Heard y McDonald",
      "alpha_2": "HM",
      "flag": "hm",
      "dialCode": "+672"
    },
    {
      "key": "VAT",
      "value": "Ciudad del Vaticano",
      "alpha_2": "VA",
      "flag": "va",
      "dialCode": "+379"
    },
    {
      "key": "HND",
      "value": "Honduras",
      "alpha_2": "HN",
      "flag": "hn",
      "dialCode": "+504"
    },
    {
      "key": "HKG",
      "value": "Hong Kong",
      "alpha_2": "HK",
      "flag": "hk",
      "dialCode": "+852"
    },
    {
      "key": "HUN",
      "value": "Hungr\u00eda",
      "alpha_2": "HU",
      "flag": "hu",
      "dialCode": "+36"
    },
    {
      "key": "ISL",
      "value": "Islandia",
      "alpha_2": "IS",
      "flag": "is",
      "dialCode": "+354"
    },
    {
      "key": "IND",
      "value": "India",
      "alpha_2": "IN",
      "flag": "in",
      "dialCode": "+91"
    },
    {
      "key": "IDN",
      "value": "Indonesia",
      "alpha_2": "ID",
      "flag": "id",
      "dialCode": "+62"
    },
    {
      "key": "IRN",
      "value": "Ir\u00e1n",
      "alpha_2": "IR",
      "flag": "ir",
      "dialCode": "+98"
    },
    {
      "key": "IRQ",
      "value": "Irak",
      "alpha_2": "IQ",
      "flag": "iq",
      "dialCode": "+964"
    },
    {
      "key": "IRL",
      "value": "Irlanda",
      "alpha_2": "IE",
      "flag": "ie",
      "dialCode": "+353"
    },
    {
      "key": "IMN",
      "value": "Isla de Man",
      "alpha_2": "IM",
      "flag": "im",
      "dialCode": "+44"
    },
    {
      "key": "ISR",
      "value": "Israel",
      "alpha_2": "IL",
      "flag": "il",
      "dialCode": "+972"
    },
    {
      "key": "ITA",
      "value": "Italia",
      "alpha_2": "IT",
      "flag": "it",
      "dialCode": "+39"
    },
    {
      "key": "JAM",
      "value": "Jamaica",
      "alpha_2": "JM",
      "flag": "jm",
      "dialCode": "+1876"
    },
    {
      "key": "JPN",
      "value": "Jap\u00f3n",
      "alpha_2": "JP",
      "flag": "jp",
      "dialCode": "+81"
    },
    {
      "key": "JEY",
      "value": "Jersey",
      "alpha_2": "JE",
      "flag": "je",
      "dialCode": "+44"
    },
    {
      "key": "JOR",
      "value": "Jordania",
      "alpha_2": "JO",
      "flag": "jo",
      "dialCode": "+962"
    },
    {
      "key": "KAZ",
      "value": "Kazajist\u00e1n",
      "alpha_2": "KZ",
      "flag": "kz",
      "dialCode": "+77"
    },
    {
      "key": "KEN",
      "value": "Kenia",
      "alpha_2": "KE",
      "flag": "ke",
      "dialCode": "+254"
    },
    {
      "key": "KIR",
      "value": "Kiribati",
      "alpha_2": "KI",
      "flag": "ki",
      "dialCode": "+686"
    },
    {
      "key": "PRK",
      "value": "Corea del Norte",
      "alpha_2": "KP",
      "flag": "kp",
      "dialCode": "+850"
    },
    {
      "key": "KOR",
      "value": "Corea del Sur",
      "alpha_2": "KR",
      "flag": "kr",
      "dialCode": "+82"
    },
    {
      "key": "KWT",
      "value": "Kuwait",
      "alpha_2": "KW",
      "flag": "kw",
      "dialCode": "+965"
    },
    {
      "key": "KGZ",
      "value": "Kirgizst\u00e1n",
      "alpha_2": "KG",
      "flag": "kg",
      "dialCode": "+996"
    },
    {
      "key": "LAO",
      "value": "Laos",
      "alpha_2": "LA",
      "flag": "la",
      "dialCode": "+856"
    },
    {
      "key": "LVA",
      "value": "Letonia",
      "alpha_2": "LV",
      "flag": "lv",
      "dialCode": "+371"
    },
    {
      "key": "LBN",
      "value": "L\u00edbano",
      "alpha_2": "LB",
      "flag": "lb",
      "dialCode": "+961"
    },
    {
      "key": "LSO",
      "value": "Lesoto",
      "alpha_2": "LS",
      "flag": "ls",
      "dialCode": "+266"
    },
    {
      "key": "LBR",
      "value": "Liberia",
      "alpha_2": "LR",
      "flag": "lr",
      "dialCode": "+231"
    },
    {
      "key": "LBY",
      "value": "Libia",
      "alpha_2": "LY",
      "flag": "ly",
      "dialCode": "+218"
    },
    {
      "key": "LIE",
      "value": "Liechtenstein",
      "alpha_2": "LI",
      "flag": "li",
      "dialCode": "+423"
    },
    {
      "key": "LTU",
      "value": "Lituania",
      "alpha_2": "LT",
      "flag": "lt",
      "dialCode": "+370"
    },
    {
      "key": "LUX",
      "value": "Luxemburgo",
      "alpha_2": "LU",
      "flag": "lu",
      "dialCode": "+352"
    },
    {
      "key": "MAC",
      "value": "Macao",
      "alpha_2": "MO",
      "flag": "mo",
      "dialCode": "+853"
    },
    {
      "key": "MKD",
      "value": "Maced\u00f4nia",
      "alpha_2": "MK",
      "flag": "mk",
      "dialCode": "+389"
    },
    {
      "key": "MDG",
      "value": "Madagascar",
      "alpha_2": "MG",
      "flag": "mg",
      "dialCode": "+261"
    },
    {
      "key": "MWI",
      "value": "Malaui",
      "alpha_2": "MW",
      "flag": "mw",
      "dialCode": "+265"
    },
    {
      "key": "MYS",
      "value": "Malasia",
      "alpha_2": "MY",
      "flag": "my",
      "dialCode": "+60"
    },
    {
      "key": "MDV",
      "value": "Islas Maldivas",
      "alpha_2": "MV",
      "flag": "mv",
      "dialCode": "+960"
    },
    {
      "key": "MLI",
      "value": "Mali",
      "alpha_2": "ML",
      "flag": "ml",
      "dialCode": "+223"
    },
    {
      "key": "MLT",
      "value": "Malta",
      "alpha_2": "MT",
      "flag": "mt",
      "dialCode": "+356"
    },
    {
      "key": "MHL",
      "value": "Islas Marshall",
      "alpha_2": "MH",
      "flag": "mh",
      "dialCode": "+692"
    },
    {
      "key": "MTQ",
      "value": "Martinica",
      "alpha_2": "MQ",
      "flag": "mq",
      "dialCode": "+596"
    },
    {
      "key": "MRT",
      "value": "Mauritania",
      "alpha_2": "MR",
      "flag": "mr",
      "dialCode": "+222"
    },
    {
      "key": "MUS",
      "value": "Mauricio",
      "alpha_2": "MU",
      "flag": "mu",
      "dialCode": "+230"
    },
    {
      "key": "MYT",
      "value": "Mayotte",
      "alpha_2": "YT",
      "flag": "yt",
      "dialCode": "+262"
    },
    {
      "key": "MEX",
      "value": "M\u00e9xico",
      "alpha_2": "MX",
      "flag": "mx",
      "dialCode": "+52"
    },
    {
      "key": "FSM",
      "value": "Micronesia",
      "alpha_2": "FM",
      "flag": "fm",
      "dialCode": "+691"
    },
    {
      "key": "MDA",
      "value": "Moldavia",
      "alpha_2": "MD",
      "flag": "md",
      "dialCode": "+373"
    },
    {
      "key": "MCO",
      "value": "M\u00f3naco",
      "alpha_2": "MC",
      "flag": "mc",
      "dialCode": "+377"
    },
    {
      "key": "MNG",
      "value": "Mongolia",
      "alpha_2": "MN",
      "flag": "mn",
      "dialCode": "+976"
    },
    {
      "key": "MNE",
      "value": "Montenegro",
      "alpha_2": "ME",
      "flag": "me",
      "dialCode": "+382"
    },
    {
      "key": "MSR",
      "value": "Montserrat",
      "alpha_2": "MS",
      "flag": "ms",
      "dialCode": "+1664"
    },
    {
      "key": "MAR",
      "value": "Marruecos",
      "alpha_2": "MA",
      "flag": "ma",
      "dialCode": "+212"
    },
    {
      "key": "MOZ",
      "value": "Mozambique",
      "alpha_2": "MZ",
      "flag": "mz",
      "dialCode": "+258"
    },
    {
      "key": "MMR",
      "value": "Birmania",
      "alpha_2": "MM",
      "flag": "mm",
      "dialCode": "+95"
    },
    {
      "key": "NAM",
      "value": "Namibia",
      "alpha_2": "NA",
      "flag": "na",
      "dialCode": "+264"
    },
    {
      "key": "NRU",
      "value": "Nauru",
      "alpha_2": "NR",
      "flag": "nr",
      "dialCode": "+674"
    },
    {
      "key": "NPL",
      "value": "Nepal",
      "alpha_2": "NP",
      "flag": "np",
      "dialCode": "+977"
    },
    {
      "key": "NLD",
      "value": "Pa\u00edses Bajos",
      "alpha_2": "NL",
      "flag": "nl",
      "dialCode": "+31"
    },
    {
      "key": "NCL",
      "value": "Nueva Caledonia",
      "alpha_2": "NC",
      "flag": "nc",
      "dialCode": "+687"
    },
    {
      "key": "NZL",
      "value": "Nueva Zelanda",
      "alpha_2": "NZ",
      "flag": "nz",
      "dialCode": "+64"
    },
    {
      "key": "NIC",
      "value": "Nicaragua",
      "alpha_2": "NI",
      "flag": "ni",
      "dialCode": "+505"
    },
    {
      "key": "NER",
      "value": "N\u00edger",
      "alpha_2": "NE",
      "flag": "ne",
      "dialCode": "+227"
    },
    {
      "key": "NGA",
      "value": "Nigeria",
      "alpha_2": "NG",
      "flag": "ng",
      "dialCode": "+234"
    },
    {
      "key": "NIU",
      "value": "Niue",
      "alpha_2": "NU",
      "flag": "nu",
      "dialCode": "+683"
    },
    {
      "key": "NFK",
      "value": "Isla Norfolk",
      "alpha_2": "NF",
      "flag": "nf",
      "dialCode": "+672"
    },
    {
      "key": "MNP",
      "value": "Islas Marianas del Norte",
      "alpha_2": "MP",
      "flag": "mp",
      "dialCode": "+1670"
    },
    {
      "key": "NOR",
      "value": "Noruega",
      "alpha_2": "NO",
      "flag": "no",
      "dialCode": "+47"
    },
    {
      "key": "OMN",
      "value": "Om\u00e1n",
      "alpha_2": "OM",
      "flag": "om",
      "dialCode": "+968"
    },
    {
      "key": "PAK",
      "value": "Pakist\u00e1n",
      "alpha_2": "PK",
      "flag": "pk",
      "dialCode": "+92"
    },
    {
      "key": "PLW",
      "value": "Palau",
      "alpha_2": "PW",
      "flag": "pw",
      "dialCode": "+680"
    },
    {
      "key": "PSE",
      "value": "Palestina",
      "alpha_2": "PS",
      "flag": "ps",
      "dialCode": "+970"
    },
    {
      "key": "PAN",
      "value": "Panam\u00e1",
      "alpha_2": "PA",
      "flag": "pa",
      "dialCode": "+507"
    },
    {
      "key": "PNG",
      "value": "Pap\u00faa Nueva Guinea",
      "alpha_2": "PG",
      "flag": "pg",
      "dialCode": "+675"
    },
    {
      "key": "PRY",
      "value": "Paraguay",
      "alpha_2": "PY",
      "flag": "py",
      "dialCode": "+595"
    },
    {
      "key": "PER",
      "value": "Per\u00fa",
      "alpha_2": "PE",
      "flag": "pe",
      "dialCode": "+51"
    },
    {
      "key": "PHL",
      "value": "Filipinas",
      "alpha_2": "PH",
      "flag": "ph",
      "dialCode": "+63"
    },
    {
      "key": "PCN",
      "value": "Islas Pitcairn",
      "alpha_2": "PN",
      "flag": "pn",
      "dialCode": "+872"
    },
    {
      "key": "POL",
      "value": "Polonia",
      "alpha_2": "PL",
      "flag": "pl",
      "dialCode": "+48"
    },
    {
      "key": "PRT",
      "value": "Portugal",
      "alpha_2": "PT",
      "flag": "pt",
      "dialCode": "+351"
    },
    {
      "key": "PRI",
      "value": "Puerto Rico",
      "alpha_2": "PR",
      "flag": "pr",
      "dialCode": "+1939"
    },
    {
      "key": "QAT",
      "value": "Qatar",
      "alpha_2": "QA",
      "flag": "qa",
      "dialCode": "+974"
    },
    {
      "key": "REU",
      "value": "Reuni\u00f3n",
      "alpha_2": "RE",
      "flag": "re",
      "dialCode": "+262"
    },
    {
      "key": "ROU",
      "value": "Ruman\u00eda",
      "alpha_2": "RO",
      "flag": "ro",
      "dialCode": "+40"
    },
    {
      "key": "RUS",
      "value": "Rusia",
      "alpha_2": "RU",
      "flag": "ru",
      "dialCode": "+7"
    },
    {
      "key": "RWA",
      "value": "Ruanda",
      "alpha_2": "RW",
      "flag": "rw",
      "dialCode": "+250"
    },
    {
      "key": "BLM",
      "value": "San Bartolom\u00e9",
      "alpha_2": "BL",
      "flag": "bl",
      "dialCode": "+590"
    },
    {
      "key": "SHN",
      "value": "Santa Elena",
      "alpha_2": "SH",
      "flag": "sh",
      "dialCode": "+290"
    },
    {
      "key": "KNA",
      "value": "San Crist\u00f3bal y Nieves",
      "alpha_2": "KN",
      "flag": "kn",
      "dialCode": "+1869"
    },
    {
      "key": "LCA",
      "value": "Santa Luc\u00eda",
      "alpha_2": "LC",
      "flag": "lc",
      "dialCode": "+1758"
    },
    {
      "key": "MAF",
      "value": "San Mart\u00edn Francesa",
      "alpha_2": "MF",
      "flag": "mf",
      "dialCode": "+590"
    },
    {
      "key": "SPM",
      "value": "San Pedro y Miquel\u00f3n",
      "alpha_2": "PM",
      "flag": "pm",
      "dialCode": "+508"
    },
    {
      "key": "VCT",
      "value": "San Vicente y las Granadinas",
      "alpha_2": "VC",
      "flag": "vc",
      "dialCode": "+1784"
    },
    {
      "key": "WSM",
      "value": "Samoa",
      "alpha_2": "WS",
      "flag": "ws",
      "dialCode": "+685"
    },
    {
      "key": "SMR",
      "value": "San Marino",
      "alpha_2": "SM",
      "flag": "sm",
      "dialCode": "+378"
    },
    {
      "key": "STP",
      "value": "Santo Tom\u00e9 y Pr\u00edncipe",
      "alpha_2": "ST",
      "flag": "st",
      "dialCode": "+239"
    },
    {
      "key": "SAU",
      "value": "Arabia Saudita",
      "alpha_2": "SA",
      "flag": "sa",
      "dialCode": "+966"
    },
    {
      "key": "SEN",
      "value": "Senegal",
      "alpha_2": "SN",
      "flag": "sn",
      "dialCode": "+221"
    },
    {
      "key": "SRB",
      "value": "Serbia",
      "alpha_2": "RS",
      "flag": "rs",
      "dialCode": "+381"
    },
    {
      "key": "SYC",
      "value": "Seychelles",
      "alpha_2": "SC",
      "flag": "sc",
      "dialCode": "+248"
    },
    {
      "key": "SLE",
      "value": "Sierra Leona",
      "alpha_2": "SL",
      "flag": "sl",
      "dialCode": "+232"
    },
    {
      "key": "SGP",
      "value": "Singapur",
      "alpha_2": "SG",
      "flag": "sg",
      "dialCode": "+65"
    },
    {
      "key": "SXM",
      "value": "Sint Maarten Holandesa",
      "alpha_2": "SX",
      "flag": "sx",
      "dialCode": "+1721"
    },
    {
      "key": "SVK",
      "value": "Eslovaquia",
      "alpha_2": "SK",
      "flag": "sk",
      "dialCode": "+421"
    },
    {
      "key": "SVN",
      "value": "Eslovenia",
      "alpha_2": "SI",
      "flag": "si",
      "dialCode": "+386"
    },
    {
      "key": "SLB",
      "value": "Islas Salom\u00f3n",
      "alpha_2": "SB",
      "flag": "sb",
      "dialCode": "+677"
    },
    {
      "key": "SOM",
      "value": "Somalia",
      "alpha_2": "SO",
      "flag": "so",
      "dialCode": "+252"
    },
    {
      "key": "ZAF",
      "value": "Sud\u00e1frica",
      "alpha_2": "ZA",
      "flag": "za",
      "dialCode": "+27"
    },
    {
      "key": "SGS",
      "value": "Islas Georgias del Sur y Sandwich del Sur",
      "alpha_2": "GS",
      "flag": "gs",
      "dialCode": "+500"
    },
    {
      "key": "SSD",
      "value": "Sud\u00e1n del Sur",
      "alpha_2": "SS",
      "flag": "ss",
      "dialCode": "+211"
    },
    {
      "key": "ESP",
      "value": "Espa\u00f1a",
      "alpha_2": "ES",
      "flag": "es",
      "dialCode": "+34"
    },
    {
      "key": "LKA",
      "value": "Sri Lanka",
      "alpha_2": "LK",
      "flag": "lk",
      "dialCode": "+94"
    },
    {
      "key": "SDN",
      "value": "Sud\u00e1n",
      "alpha_2": "SD",
      "flag": "sd",
      "dialCode": "+249"
    },
    {
      "key": "SUR",
      "value": "Surinam",
      "alpha_2": "SR",
      "flag": "sr",
      "dialCode": "+597"
    },
    {
      "key": "SJM",
      "value": "Svalbard y Jan Mayen",
      "alpha_2": "SJ",
      "flag": "sj",
      "dialCode": "+47"
    },
    {
      "key": "SWZ",
      "value": "Suazilandia",
      "alpha_2": "SZ",
      "flag": "sz",
      "dialCode": "+268"
    },
    {
      "key": "SWE",
      "value": "Suecia",
      "alpha_2": "SE",
      "flag": "se",
      "dialCode": "+46"
    },
    {
      "key": "CHE",
      "value": "Suiza",
      "alpha_2": "CH",
      "flag": "ch",
      "dialCode": "+41"
    },
    {
      "key": "SYR",
      "value": "Siria",
      "alpha_2": "SY",
      "flag": "sy",
      "dialCode": "+963"
    },
    {
      "key": "TWN",
      "value": "Taiw\u00e1n",
      "alpha_2": "TW",
      "flag": "tw",
      "dialCode": "+886"
    },
    {
      "key": "TJK",
      "value": "Tayikist\u00e1n",
      "alpha_2": "TJ",
      "flag": "tj",
      "dialCode": "+992"
    },
    {
      "key": "TZA",
      "value": "Tanzania",
      "alpha_2": "TZ",
      "flag": "tz",
      "dialCode": "+255"
    },
    {
      "key": "THA",
      "value": "Tailandia",
      "alpha_2": "TH",
      "flag": "th",
      "dialCode": "+66"
    },
    {
      "key": "TLS",
      "value": "Timor Oriental",
      "alpha_2": "TL",
      "flag": "tl",
      "dialCode": "+670"
    },
    {
      "key": "TGO",
      "value": "Togo",
      "alpha_2": "TG",
      "flag": "tg",
      "dialCode": "+228"
    },
    {
      "key": "TKL",
      "value": "Tokelau",
      "alpha_2": "TK",
      "flag": "tk",
      "dialCode": "+690"
    },
    {
      "key": "TON",
      "value": "Tonga",
      "alpha_2": "TO",
      "flag": "to",
      "dialCode": "+676"
    },
    {
      "key": "TTO",
      "value": "Trinidad y Tobago",
      "alpha_2": "TT",
      "flag": "tt",
      "dialCode": "+1868"
    },
    {
      "key": "TUN",
      "value": "T\u00fanez",
      "alpha_2": "TN",
      "flag": "tn",
      "dialCode": "+216"
    },
    {
      "key": "TUR",
      "value": "Turqu\u00eda",
      "alpha_2": "TR",
      "flag": "tr",
      "dialCode": "+90"
    },
    {
      "key": "TKM",
      "value": "Turkmenist\u00e1n",
      "alpha_2": "TM",
      "flag": "tm",
      "dialCode": "+993"
    },
    {
      "key": "TCA",
      "value": "Islas Turcas y Caicos",
      "alpha_2": "TC",
      "flag": "tc",
      "dialCode": "+1649"
    },
    {
      "key": "TUV",
      "value": "Tuvalu",
      "alpha_2": "TV",
      "flag": "tv",
      "dialCode": "+688"
    },
    {
      "key": "UGA",
      "value": "Uganda",
      "alpha_2": "UG",
      "flag": "ug",
      "dialCode": "+256"
    },
    {
      "key": "UKR",
      "value": "Ucrania",
      "alpha_2": "UA",
      "flag": "ua",
      "dialCode": "+380"
    },
    {
      "key": "ARE",
      "value": "Emiratos \u00c1rabes Unidos",
      "alpha_2": "AE",
      "flag": "ae",
      "dialCode": "+971"
    },
    {
      "key": "GBR",
      "value": "Reino Unido",
      "alpha_2": "GB",
      "flag": "gb",
      "dialCode": "+44"
    },
    {
      "key": "USA",
      "value": "Estados Unidos de Am\u00e9rica",
      "alpha_2": "US",
      "flag": "us",
      "dialCode": "+1"
    },
    {
      "key": "UMI",
      "value": "Islas Ultramarinas Menores de Estados Unidos",
      "alpha_2": "UM",
      "flag": "um",
      "dialCode": "+1"
    },
    {
      "key": "URY",
      "value": "Uruguay",
      "alpha_2": "UY",
      "flag": "uy",
      "dialCode": "+598"
    },
    {
      "key": "UZB",
      "value": "Uzbekist\u00e1n",
      "alpha_2": "UZ",
      "flag": "uz",
      "dialCode": "+998"
    },
    {
      "key": "VUT",
      "value": "Vanuatu",
      "alpha_2": "VU",
      "flag": "vu",
      "dialCode": "+678"
    },
    {
      "key": "VEN",
      "value": "Venezuela",
      "alpha_2": "VE",
      "flag": "ve",
      "dialCode": "+58"
    },
    {
      "key": "VNM",
      "value": "Vietnam",
      "alpha_2": "VN",
      "flag": "vn",
      "dialCode": "+84"
    },
    {
      "key": "VGB",
      "value": "Islas V\u00edrgenes Brit\u00e1nicas",
      "alpha_2": "VG",
      "flag": "vg",
      "dialCode": "+1284"
    },
    {
      "key": "VIR",
      "value": "Islas V\u00edrgenes de los Estados Unidos",
      "alpha_2": "VI",
      "flag": "vi",
      "dialCode": "+1340"
    },
    {
      "key": "WLF",
      "value": "Wallis y Futuna",
      "alpha_2": "WF",
      "flag": "wf",
      "dialCode": "+681"
    },
    {
      "key": "ESH",
      "value": "Sahara Occidental",
      "alpha_2": "EH",
      "flag": "eh",
      "dialCode": "+212"
    },
    {
      "key": "YEM",
      "value": "Yemen",
      "alpha_2": "YE",
      "flag": "ye",
      "dialCode": "+967"
    },
    {
      "key": "ZMB",
      "value": "Zambia",
      "alpha_2": "ZM",
      "flag": "zm",
      "dialCode": "+260"
    },
    {
      "key": "ZWE",
      "value": "Zimbabue",
      "alpha_2": "ZW",
      "flag": "zw",
      "dialCode": "+263"
    }
  ];

export default countries;
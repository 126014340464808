import { get } from "lodash";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { openModal } from "../../reducers/modals";
import {
  country as countrySelector,
  localSettings as localSettingsSelector,
  externalProviderState,
  idCompanySelector,
  companySelector,
} from "../../selectors/company";
import dayjs from "dayjs";

export const useTestingCompanies = () => {
  const companyId = useSelector(idCompanySelector);

  // return ["119107", "108627", "19235", "218560", "91930"];
  return [companyId]
}

const useShowWompiIntegrationModal = () => {
  const dispatch = useDispatch();
  const country = useSelector(countrySelector);
  const localSettings = useSelector(localSettingsSelector);
  const company = useSelector(idCompanySelector);
  const wompiState = useSelector(externalProviderState('wompi'));
  const wompiAllowedCompanies = useTestingCompanies();
  const { registryDate } = useSelector(companySelector);

  useEffect(() => {
    if (country === 'colombia'
      && get(localSettings, 'showWompiIntegrationAvailable')
      && !(wompiState === 'active')
      && (wompiAllowedCompanies.includes(company))
      && (dayjs(registryDate).isBefore('2023-06-21')))
      dispatch(openModal({ modal: 'activateWompiIntegration' }));
  }, [country, localSettings, dispatch, wompiState, company, wompiAllowedCompanies, registryDate]);

}

export default useShowWompiIntegrationModal;
import db from './database'

export const put = async (invoice, callback) => {
  await db.pendingInvoices.put({...invoice})
  if (!!callback)
    callback();
}

export const bulkPut = async (invoices, callback) => {
  await db.pendingInvoices.clear()
    .then(() => db.pendingInvoices.bulkPut(invoices))
  if (!!callback)
    callback();
}

export const total = () => db.pendingInvoices.count() 

export const getAll = async () => {
  const data = await db.pendingInvoices.toArray()
  return [...data.slice(0, 1), ...data.slice(1).reverse()]
}

export const remove = id => db.pendingInvoices.delete(id)

export const get = id => db.pendingInvoices.get(id)

export const update = (id, changes) => db.pendingInvoices.update(id, changes)

export const clear = () => db.pendingInvoices.clear()
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { I18n } from '@aws-amplify/core';
import { Form } from 'react-final-form';
import { get, has } from 'lodash';
import dayjs from 'dayjs';

import { formError } from '../../../utils/errors';
import { downloadExportableReport, replaceAndParse } from '../../../utils';
import { closeModal } from '../../../reducers/modals';
import { validate, transform } from '../../forms/dailyReport/utils';
import { sendGTMEvent, sendNewGTMEvent } from '../../../reducers/company';
import reportsAPI from '../../../reducers/reportsAPI';

import Modal from '../../common/Modal';
import Bottom from '../common/Bottom';
import Notification from '../common/Notification';
import Body from '../../forms/dailyReport/DailyReport';

const DailyReport = () => {
  const dispatch = useDispatch();
  const isOpen = useSelector((state) =>
    get(state, 'modals.dailyReport.isOpen', false)
  );

  const submit = async (values) => {
    try {
      const transformedValues = transform(values);

      const data = await reportsAPI.get('/reports/export', transformedValues);

      if (!has(data, 'data'))
        throw new Error('hubo un error al exportar el reporte');

      dispatch(sendGTMEvent('daily-report-voucher-exported'));

      dispatch(sendNewGTMEvent('pos-daily-report-voucher-generated'));

      downloadExportableReport(
        data?.data,
        I18n.get('dailyReport', 'Comprobante de informe diario'),
        'xlsx'
      );

      dispatch(closeModal({ modal: 'dailyReport' }));
    } catch (error) {
      if (error?.message === 'Network Error') {
        return formError(
          {
            ...error,
            message: I18n.get(
              'networkErrorMessageDailyReport',
              'hubo un error al exportar el reporte'
            ),
          },
          I18n.get(
            'networkErrorMessageDailyReport',
            'hubo un error al exportar el reporte'
          )
        );
      }
      return formError(
        error,
        I18n.get('dailyReportError', 'hubo un error al exportar el reporte')
      );
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={() => dispatch(closeModal({ modal: 'dailyReport' }))}
      title={I18n.get('dailyReport', 'Comprobante de informe diario')}
      className='modal__daily-report'
      id='daily-report-modal-portal'
    >
      <Form
        onSubmit={submit}
        validate={(values) => validate(values)}
        initialValues={{
          date: dayjs().toDate(),
        }}
        keepDirtyOnReinitialize
      >
        {({ handleSubmit, submitting, submitError }) => (
          <form noValidate onSubmit={handleSubmit}>
            <div className='px-5 pb-3'>
              <p className='h5 py-4 text-subtitle-muted text-capitalize-first'>
                {replaceAndParse(
                  I18n.get(
                    'dailyReportDescription',
                    "Exporta el <a href='https://ayuda.alegra.com/es/conoce-el-comprobante-de-informe-diario-del-sistema-punto-de-venta-Colombia' target='_blank' rel='noreferrer'>resumen diario</a> de tus ventas registradas en documentos equivalentes."
                  ),
                  []
                )}
              </p>
              <Body />
            </div>

            <Notification isOpen={!!submitError} text={submitError} />

            <Bottom
              submitting={submitting}
              onClose={() => dispatch(closeModal({ modal: 'dailyReport' }))}
              hideCancel
              submitText={I18n.get('export', 'exportar')}
            />
          </form>
        )}
      </Form>
    </Modal>
  );
};

export default DailyReport;

import React from 'react'
import { I18n } from '@aws-amplify/core';
import { useDispatch } from 'react-redux';
import { toUpper, get } from 'lodash';

import { filter } from '../../../../database/itemsDB';
import { addItem } from '../../../../reducers/activeRefund';
import Select from '../../../common/Select';

const SearchItem = () => {
  const dispatch = useDispatch();

  return (
    <div className="refund-top__client shadow pb-3 d-flex d-md-none w-100 text-white align-items-center">
      <div className="w-100 px-2">
        <Select
          loadOptions={async text => await filter({ text, categories: [], limit: 10 })}
          defaultOptions
          value={null}
          hideDropdownIndicator
          placeholder={toUpper(I18n.get('selectProducts', 'selecciona los productos'))}
          getOptionLabel={option => {
            return (
              <div className="d-flex align-items-center justify-content-between p-1">
                <p className="h4">
                  {`${option.name}${!!get(option, 'reference.reference') 
                    ? ` (${get(option, 'reference.reference')})`: !!get(option, 'reference')
                    ? ` (${get(option, 'reference')})`: ''}`
                  }
                </p>
              </div>
            )
          }}
          getOptionValue={option => option.id}
          onChange={value => dispatch(addItem(value))}
        />
      </div>
    </div>
  )
}

export default SearchItem;
import React from 'react';
import PropTypes from 'prop-types';
import { I18n } from '@aws-amplify/core';
import BigNumber from 'bignumber.js';
import { getNumber } from '../../../utils/invoices';
import { formatDate } from '../utils';
import { COUNTRIES } from '../../../utils/enums/countries';

const invoiceSectionTitleMap = {
  [COUNTRIES.COLOMBIA]: 'creditToSales',
  [COUNTRIES.SPAIN]: 'associatedDocuments',
};

/**
 * Component to display invoices section.
 *
 * @param {Object} props - Component props.
 * @param {Array} props.invoices - List of invoices.
 * @param {Object} props.fmt - Formatting options.
 * @param {number} props.decimal - Number of decimal places.
 * @param {string} props.dateFormat - Date format string.
 * @param {string} props.country - Country code.
 * @returns {JSX.Element} - Rendered component.
 */
const InvoicesSection = React.memo(
  ({ invoices, fmt, decimal, dateFormat, country, type }) => {
    return (
      <>
        <p className='text-capitalize-first body-3-bold'>
          {I18n.get(
            invoiceSectionTitleMap[country] || 'creditToSales',
            'Crédito a ventas'
          )}
        </p>

        <div className='detail-items-table-wrapper mb-4'>
          <table className='detail-items-table rounded-lg'>
            <thead>
              <tr>
                <th className='caption-bold'>
                  {I18n.get('invoice', 'factura')}
                </th>
                <th className='caption-bold'>{I18n.get('date', 'fecha')}</th>
                <th className='caption-bold'>
                  {I18n.get('dueDate', 'vencimiento')}
                </th>
                <th className='caption-bold'>{I18n.get('total', 'total')}</th>
                <th className='caption-bold'>{I18n.get('paid', 'pagado')}</th>
                <th className='caption-bold'>
                  {I18n.get('pending', 'pendiente')}
                </th>
                <th className='caption-bold'>{I18n.get('amount', 'monto')}</th>
              </tr>
            </thead>
            <tbody>
              {invoices.map((invoice, index) => (
                <tr key={index}>
                  <td>{getNumber(invoice, country, true, type)}</td>
                  <td>{formatDate(invoice.date, dateFormat)}</td>
                  <td>{formatDate(invoice.dueDate, dateFormat)}</td>
                  <td>{new BigNumber(invoice.total).toFormat(decimal, fmt)}</td>
                  <td>
                    {new BigNumber(invoice.total)
                      .minus(invoice.amount)
                      .minus(invoice.balance)
                      .toFormat(decimal, fmt)}
                  </td>
                  <td>
                    {new BigNumber(invoice.balance).toFormat(decimal, fmt)}
                  </td>
                  <td>
                    {new BigNumber(invoice.amount).toFormat(decimal, fmt)}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </>
    );
  }
);

InvoicesSection.propTypes = {
  invoices: PropTypes.arrayOf(
    PropTypes.shape({
      date: PropTypes.string.isRequired,
      dueDate: PropTypes.string.isRequired,
      total: PropTypes.number.isRequired,
      amount: PropTypes.number.isRequired,
      balance: PropTypes.number.isRequired,
    })
  ).isRequired,
  fmt: PropTypes.object.isRequired,
  decimal: PropTypes.number.isRequired,
  dateFormat: PropTypes.string,
  country: PropTypes.string.isRequired,
};

export default InvoicesSection;

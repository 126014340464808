import { Container, EmptyState, Loader, Grid } from '@alegradev/smile-ui-react';
import { useParams } from 'react-router-dom';
import { I18n } from 'aws-amplify';

import { useContactQuery } from '../services/hooks';
import ContactDetailHeader from './Header';
import ContactDetailBody from './body/Body';
import { useSelector } from 'react-redux';
import { hasPermissionTo } from '../../../selectors/auth';
import { useHistory } from 'react-router-dom';
import { IconLock } from '@tabler/icons-react';

function ContactDetail({ id }) {
  const can = useSelector(hasPermissionTo);
  const params = useParams();
  const { Wrapper } = Grid;
  const { data, isLoading, isFetching } = useContactQuery(id || params?.id, [
    'statementLink',
  ]);
  const history = useHistory();

  if (isLoading || isFetching) {
    return (
      <Loader
        size='large'
        emphasis='page'
        orientation='vertical'
        title={I18n.get('loadingClient', 'Cargando cliente')}
      />
    );
  }

  if (!data) {
    return (
      <Wrapper
        justify={{ lg: 'center' }}
        align={{ lg: 'center' }}
        fullHeight
        fullWidth
      >
        <EmptyState
          title={I18n.get('noClientFound', 'No se encontro el contacto')}
          description={I18n.get(
            'noClientFoundDescription',
            'Busca otro contacto o recarga la pagina'
          )}
          action={{
            label: I18n.get('goBack', 'Volver'),
            handler: () => history.push('/contacts'),
          }}
        />
      </Wrapper>
    );
  }

  if (!can('view-client', 'contacts')) {
    return (
      <Wrapper
        fullHeight
        fullWidth
        justify={{ lg: 'center' }}
        align={{ lg: 'center' }}
      >
        <EmptyState
          hideAction
          generalIcon={IconLock}
          title={I18n.get('youNeedPermissions', 'Necesitas autorización')}
          description={I18n.get(
            'youNeedPermissionsDescription',
            'Tu usuario tiene un perfil limitado, para ingresar a esta sección debes solicitar acceso a un usuario administrador.'
          )}
        />
      </Wrapper>
    );
  }

  return (
    <Container marginTop={30}>
      <ContactDetailHeader contact={data} />
      <ContactDetailBody contact={data} />
    </Container>
  );
}

export default ContactDetail;

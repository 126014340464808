import { createDraftSafeSelector } from '@reduxjs/toolkit';

import { paymentTermsSelector } from '../reducers/paymentTerms'

export const activePaymentTerms = createDraftSafeSelector(
  paymentTermsSelector.selectAll,
  paymentTerms => !!paymentTerms
    ? paymentTerms
    : []
)

export const getPaymentTermById = id => createDraftSafeSelector(
  state => paymentTermsSelector.selectById(state, id),
  term => !!term ? term : null
)
import en from './en'

const dictionary = {
  ...en,
  "emissionStatus": "D.G.T status",
  "howToEmit": "How to stamp them",
  "numerationsSettingTooltip": "Manage the numbering used for invoicing in your business from “Alegra Accounting”.",
  "notItemsYet": "To create your first sale we invite you to add a product or service. 🏷️",
  "onboarding.video.url": "https://www.youtube.com/watch?v=nMxAI9_G28w",
  "onboarding.video.id": "nMxAI9_G28w",
  "printPersonalizedInvoices": "Invoice customization",
  "editTip": "Edit service tax",
  "printPersonalizedInvoicesHelp": "Give your touch to the final part of your tickets or invoices with a phrase, image, or both.",
  "taxesSettingTooltip": "Configure the taxes you need for invoicing at your point of sale “Alegra Accounting”.",
  "youHavePendingInvoicesToEmit": "You have sales documents to stamp! 🚨",
  "youHavePendingInvoicesToEmit.description": "Learn how to identify the electronic documents you have “To stamp” and send them to the ATV with a click.",
  "electronicPayrollHabilitation": "Learn how to enable yourself to stamp tickets and electronic invoices.",
  "youCanNowMakeElectronicInvoices": "Enable yourself to stamp electronic Invoices! 💥",
  "youCanNowMakeElectronicInvoices.description": "Activate yourself at Hacienda and start creating electronic invoices at your point of sale.",
  "AlegraAccounting.new": "Accounting",
  "includeTip": "Include service tax",
  "importProducts.text": "Import products",
  "importProducts.tooltip": "We will take you to Alegra Accounting to upload new products.",
  "shift.tipsCash": "Service in cash",
  "shift.tipsOther": "Service in other",
  "sellPreferences.tip.LinkDesc": "Read article about service tax",
  "solutionModal.accounting.supTitle": "Clear accounts with",
  "solutionModal.accounting.buttonMainText": "Try 15 days free",
  "solutionModal.accounting.buttonSecondaryText": "More information",
  "solutionModal.accounting.description": "With Alegra Accounting you control your income and expenses with a click and get smart reports so that you are always up to date with the Ministry of Finance.",
  "solutionModal.accounting.subTitle": "*After 15 days, you can choose a plan from {{price}} {{currency}} per month.",
  "solutionModal.invoice.supTitle": "Save time with",
  "solutionModal.invoice.buttonMainText": "Try 3 months for free",
  "solutionModal.invoice.buttonSecondaryText": "More information",
  "solutionModal.invoice.description": "Generate your invoices and tickets in seconds with the most secure billing software in the country. 100% adapted to the regulations of the Ministry of Finance.",
  "solutionModal.invoice.subTitle": "*After 3 months, you can choose a plan from {{price}} {{currency}} per month.",
  "solutionModal.store.supTitle": "Increase your sales with",
  "solutionModal.store.buttonMainText": "Try 15 days free",
  "solutionModal.store.buttonSecondaryText": "More information",
  "solutionModal.store.description": "Start selling online in seconds! With Alegra Store, you can create your first online store in just 4 clicks.",
  "solutionModal.store.subTitle": "*After 15 days, you can choose a plan from {{price}} {{currency}} per month.",
  "solutionModal.pos.supTitle": "Manage your business with",
  "solutionModal.pos.buttonMainText": "Try 15 days free",
  "solutionModal.pos.buttonSecondaryText": "More information",
  "solutionModal.pos.description": "Invoice in seconds with the fastest Point of Sale Software on the market. In addition, you can generate your sales and inventory reports in seconds. ⚡",
  "solutionModal.pos.subTitle": "*After 15 days, you can choose a plan from {{price}} {{currency}} per month.",
  "TipSuggested": "service tax suggested",
  "tips.accountLabel": "Account for sales",
  "tips.accountPurchasesLabel": "Account for purchases",
  "tips.subtitle": "Include the tax of service in your sales documents.",
  "userNotAllowed.edit.saleTicket": "You need the administrator to give you permission to edit tickets.",
  "userNotAllowed.edit.invoice": "You need the administrator to give you permission to edit invoices.",
  "numerationElectronicOffline": "To create electronic invoices you need an internet connection to send them to Hacienda.",
  "tips": "Serivce tax",
  "tips.add": "Add service tax",
  "tips.modalTitle": "Activate the service tax! 💰",
  "tips.modalTitleSector": "Includes service tax! 💰",
  "tips.modalDescription": "You can activate this feature to include the service tax charge on your sales.",
  "tips.modalDescriptionSector": "With this new feature you can add the service tax on your sales.",
  "tips.activateTips": "Go to activate",
  "tips.activateTipsSector": "Learn about it",
  "tips.defaultSuggested": "Suggested by default",
  "tips.accountHelp": "It is the liability accounting account where the amounts collected for service tax will be debited.",
  "tips.accountHelpPurchases": "It is the expenses accounting account where the amounts paid for service tax will be debited.",
  "tips.defaultSuggestedDesc": "Automatically adds the service tax on each sale and you can remove it when selling",
  "autocompleteTestTitle": "Try autocomplete ✨",
  "autocompleteTestDescription": "Enter your client's identification number to obtain their information from Hacienda."
}

export default dictionary;
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { get } from 'lodash'

import { station as stationSelector } from '../../selectors/app';
import { settings as settingsSelector, localSettings as localSettingsSelector, country as countrySelector } from '../../selectors/company';
import { isOnlyInvoicingPlan } from '../../selectors/company';
import { allItemCategories } from '../../selectors/itemCategories';
import Invoice from './Invoice';
import Footer from './Footer/Footer';
import { openModal } from '../../reducers/modals';
import { useShowWompiIntegrationModal } from '../../hooks/useShowWompiIntegrationModal';
import { ModalProvider } from '@alegradev/smile-ui-react';

const Home = () => {
  let hubSpotChat = document.getElementById('hubspot-messages-iframe-container')
  let fakeHubSpotChat = document.getElementById('husbpot-chat')
  const initHubspot = useSelector(state => get(state, 'app.initHubspot'));
  const categories = useSelector(allItemCategories)
  const station = useSelector(stationSelector)
  const onlyInvoicingPlan = useSelector(isOnlyInvoicingPlan)
  const settings = useSelector(settingsSelector)
  const localSettings = useSelector(localSettingsSelector)
  const country = useSelector(countrySelector)
  const dispatch = useDispatch()

  const pendingInvoicesEnabled = get(station, 'pendingInvoicesEnabled', false);

  useEffect(() => {
    if (!!hubSpotChat) {
      hubSpotChat.classList.add("home-page")
      
      if (categories.length > 0)    
        hubSpotChat.classList.add("categories")
      
      if (!!pendingInvoicesEnabled && !onlyInvoicingPlan)    
        hubSpotChat.classList.add("pending-invoices")
    }
    if (!!fakeHubSpotChat) {
      fakeHubSpotChat.classList.add("home-page")
      
      if (categories.length > 0)    
        fakeHubSpotChat.classList.add("categories")
      
      if (!!pendingInvoicesEnabled && !onlyInvoicingPlan)    
        fakeHubSpotChat.classList.add("pending-invoices")
    }
    return () => {
      if (!!hubSpotChat) {
        hubSpotChat.classList.remove("home-page")
        hubSpotChat.classList.remove("categories")
        hubSpotChat.classList.remove("pending-invoices")
      }
      if (!!fakeHubSpotChat) {
        fakeHubSpotChat.classList.remove("home-page")
        fakeHubSpotChat.classList.remove("categories")
        fakeHubSpotChat.classList.remove("pending-invoices")
      }
    }
  }, [hubSpotChat, fakeHubSpotChat, initHubspot, categories, pendingInvoicesEnabled, onlyInvoicingPlan])

  useEffect(() => {
    if (country === 'colombia' && get(settings, 'isActiveAutomationElectronicInvoice', false) && !get(localSettings, 'automationFEEnabled', false))
      dispatch(openModal({modal: 'stationSetting5UVT'}))
  }, [country, settings, localSettings, dispatch])
  
  useShowWompiIntegrationModal();

  useEffect(() => {
    window.dataLayer.push({
      'event': 'VirtualPageview',
      'virtualPageURL': '/',
      'virtualPageTitle' : 'Home'
    });
  }, [])

  return (
    <ModalProvider>
      <div className='home d-flex flex-column'>
        <Invoice />

        {!!pendingInvoicesEnabled && !onlyInvoicingPlan && <Footer />}
      </div>
    </ModalProvider>
  );
}

export default Home;
import { formatDistance, isDate, addMinutes, format } from 'date-fns';
import es from 'date-fns/locale/es';

const getDateTimezoneOffset = (originalDate = new Date()) => {
  const date = isDate(originalDate) ? originalDate : new Date(originalDate);
  return addMinutes(date, date.getTimezoneOffset());
};

/**
 * Get relative time from a start date to other date
 * @param {Date} startDate Initial date to compare, default current date
 * @param {Date} endDate End date to compare, default current date
 * @returns "2 days ago" or "in 2 days", depending on the time difference
 */
export const getDateRelativeTime = (
  startDate = new Date(),
  endDate = new Date(),
  locale = 'es'
) => {
  const formattedDistance = formatDistance(
    getDateTimezoneOffset(startDate),
    endDate,
    {
      locale: locale === 'es' && es,
    }
  );

  return `${formattedDistance.charAt(0).toUpperCase()}${formattedDistance.slice(
    1
  )}`;
};

/**
 * Format a given date according to the specified date format and locale.
 * @param {Date} date The date to format.
 * @param {string} dateFormat The desired date format (default: 'dd/MM/yyyy').
 * @param {string} locale The desired locale for formatting (default: 'es' for Spanish).
 * @returns {string} The formatted date as a string.
 */
export const formatDate = (date, dateFormat = 'dd/MM/yyyy', locale = 'es') => {
  return format(
    isNaN(new Date(date).getTime()) ? new Date() : getDateTimezoneOffset(new Date(date)),
    dateFormat,
    {
      locale: locale === 'es' && es,
    }
  );
};

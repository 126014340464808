const nationalityKindOfPerson = [
  {
    "id": "1",
    "value": "Nacional",
    "key": "NATIONAL"
  },
  {
    "id": "2",
    "value": "Extranjero/a",
    "key": "FOREIGN"
  },
]

export default nationalityKindOfPerson
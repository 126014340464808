import React from 'react';
import { I18n } from '@aws-amplify/core';
import { Field } from 'react-final-form';
import { capitalize } from 'lodash';
import { useSelector } from 'react-redux'

import { getInvoiceNumerations, getCashReceiptNumerations, getRefundNumerations } from '../../../selectors/numerations'
import { isCashReceiptNumerationActive, isRefundNumerationActive } from '../../../selectors/numerations'
import { renderSelect } from '../fields/V0/Fields';

const Numerations = ({ options }) => {
  const invoiceNumerations = useSelector(getInvoiceNumerations);
  const cashReceiptNumerations = useSelector(getCashReceiptNumerations);
  const creditNotesNumerations = useSelector(getRefundNumerations);
  const cashReceiptActive = useSelector(isCashReceiptNumerationActive)
  const refundActive = useSelector(isRefundNumerationActive)

  return (
    <div className="form-body__fields form-row justify-content-start">
      {options.hasOwnProperty('invoiceNumeration') && (
        <Field
          name="invoiceNumeration"
          className="col-12"
          component={renderSelect}
          label={capitalize(I18n.get('invoiceNumbering', 'numeración de facturas'))}
          options={invoiceNumerations}
          getOptionLabel={option => option.name}
          getOptionValue={option => option.id}
          required
        />
      )}
      
      {cashReceiptActive && options.hasOwnProperty('cashReceiptNumeration') && (
        <Field
          name="cashReceiptNumeration"
          className="col-12"
          component={renderSelect}
          label={capitalize(I18n.get('receiptNumbering', 'numeración de recibos de caja'))}
          options={cashReceiptNumerations}
          getOptionLabel={option => option.name}
          getOptionValue={option => option.id}
          required
        />
      )}
      

      {refundActive && options.hasOwnProperty('refundNumeration') && (
        <Field
          name="refundNumeration"
          className="col-12"
          component={renderSelect}
          label={capitalize(I18n.get('refundNumbering', 'numeración de devolución'))}
          options={creditNotesNumerations}
          getOptionLabel={option => option.name}
          getOptionValue={option => option.id}
          required
        />
      )}
    </div>
  )
}

export default Numerations;

import React from 'react'
import PropTypes from 'prop-types'

const Label = ({ title, required, customStyles }) => {
  return (
    <div style={{ fontSize: '14px', marginBottom: '4px', ...customStyles }}>
      {title}
      {!!required && (
        <span className="ml-2 text-primary">*</span>
      )}
    </div>
  )
}

Label.propTypes = {
  title: PropTypes.string,
  required: PropTypes.bool,
  customStyles: PropTypes.object,
}

export default Label
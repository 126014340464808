import React from 'react'
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux'; 
import { I18n } from '@aws-amplify/core';
import { Form } from "react-final-form"
import { FORM_ERROR } from 'final-form';

import { updateItem } from '../../../../reducers/activeRefund';
import { itemByIndex, currency as currencySelector } from '../../../../selectors/activeRefund';
import { decimalPrecision, country as countrySelector } from '../../../../selectors/company';
import Body from '../../../forms/editItem/EditItem';
import { transform, validate } from '../../../forms/editItem/utils';
import Summary from './Summary';
import initialValues from './InitialValues';
import { get, some } from 'lodash';

import Button from '../../../common/Button';

import { Icon } from '@alegradev/smile-ui-react';
import { IconTag } from '@tabler/icons-react';

const ItemInfo = (item) => {
  return (
    <div className="item-info p-3">
      <div className="item-info__image ml-4 mt-4 d-flex align-items-center">
        {item.images
          ? <img src={item.images[0].url} alt={item.name} />
          : <Icon icon={IconTag} extraClass="icon-gray icon x2" />}
      </div>
      <div className="item-info__detail mt-4 ml-4">
        <p className="item-info__detail-product-type">
          {I18n.get(item.type === 'service'
            ? 'service'
            : item.type === 'kit'
              ? 'kit'
              : 'product')}
        </p>
        <p className="item-info__detail-product-name">{item.name}</p>
        {(!!get(item, 'reference.reference') || !!get(item, 'reference')) && (
          <p className="item-info__detail-product-reference">
            {get(item, 'reference.reference') || get(item, 'reference')}
          </p>
        )}
      </div>
    </div>
  )
}

const EditItem = ({ isOpen, onClose, selectedIndex }) => {
  const dispatch = useDispatch()
  const item = useSelector(itemByIndex(selectedIndex))
  const decimal = useSelector(decimalPrecision)
  const country = useSelector(countrySelector)
  const currency = useSelector(currencySelector)

  const onSubmit = async (values) => {
    const modifiedValues = transform(values, {
      country, 
      initialValues: initialValues(item, decimal), 
      currency,
      decimal,
    });

    try {
      dispatch(updateItem({ index: selectedIndex, values: modifiedValues }))
      onClose()
    } catch {
      return { [FORM_ERROR]: I18n.get('editItemError', 'hubo un error en la edición del producto') }
    }
  }
  
  return (
    <div className={`${isOpen ? 'open': ''} refund-edit-item border d-none flex-column`}
      style={{width: '100%', height: '100%', position: 'absolute', top: 0, left: 0 }}
    >
      <Form
        onSubmit={onSubmit}
        initialValues={initialValues(item, decimal)}
        validate={values => validate(values, country)}
      >
        {({ handleSubmit, values, form}) => (
          <form 
            noValidate
            onSubmit={handleSubmit}
            className="w-100 h-100 p-4 bg-white d-flex flex-column"
          >
            <h3 className='title-new-refund'>
              {I18n.get('newRefund', 'nueva devolución')}
            </h3>
              
            <div className='h-95'>
              
              <ItemInfo {...item} />
              
              <Body values={values} form={form}/>
            </div>

            <Summary values={values} />

            <div className='d-flex justify-content-center mt-3 mt-md-5'>
              <button className='refunds-cancel-button' onClick={onClose}>
                {I18n.get('cancel','Cancelar')}
              </button>
              <Button disabled={some(values?.decimalsError, (value) => value)} className='ml-4' style={{ width: "116px" }} type="submit" >
                {I18n.get('save', 'guardar')}
              </Button>
            </div>
          </form>
        )}
      </Form>

    </div>
  )
}

EditItem.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  selectedIndex: PropTypes.number,
}

export default EditItem;
import { I18n } from 'aws-amplify';

export const whatsappMethodValidation = (values) => {
  let errors = {};

  if (!values.phone) {
    errors.phone = I18n.get('phoneRequired', 'El telefono requerido');
  }

  if (!values.message) {
    errors.message = I18n.get('messageRequired', 'El mensaje requerido');
  }

  return errors;
};


export const emailMethodValidation = (values) => {
  let errors = {};
  
  if (!values.subject) {
    errors.subject = I18n.get('subjectRequired', 'El asunto requerido');
  }

  if (Array.isArray(values.to) && values.to.length === 0) {
    errors.to = I18n.get('toRequired', 'El destinatario requerido');
  }

  return errors;
};
// components/refunds/Methods.js

import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { I18n } from '@aws-amplify/core';

import { companySelector } from '../../../../selectors/company';
import { isElectronicPOSDocumentAvailableSelector } from '../../../../selectors/numerations';

import MethodBody from './MethodBody';

/**
 * Methods Component
 *
 * Renders a list of available refund methods for the user to choose from.
 *
 * @param {Object} props - Component properties.
 * @param {Function} props.onChooseMethod - Callback invoked when a refund method is selected.
 * @returns {JSX.Element} - The rendered Methods component.
 */
const Methods = ({ onChooseMethod }) => {
  const company = useSelector(companySelector);
  const hasFEactive = company?.settings?.electronicInvoicing ?? false;
  const hasElectronicPOSActive = useSelector(
    isElectronicPOSDocumentAvailableSelector
  );

  const textCreditToSales =
    hasFEactive || hasElectronicPOSActive
      ? I18n.get(
          'newRefundCreditToSaleshMethodDescriptionFE',
          'Disminuye el valor por cobrar en una factura de tu cliente.'
        )
      : I18n.get(
          'newRefundCreditToSaleshMethodDescription',
          'Disminuye el valor por cobrar en una factura de tu cliente.'
        );

  return (
    <div className='container container-methods'>
      <div className='row mb-4 mt-md-4'>
        <div className='col-md-6 col-sm-12 p-0 pb-3 pr-md-3'>
          <MethodBody
            icon='cashOut'
            onClick={() => onChooseMethod('cash')}
            title={I18n.get('refundCash', 'Devolución de dinero')}
            description={I18n.get(
              'newRefundRefundCashMethodDescription',
              'Devuelve a tu cliente el total o parte del dinero de sus pagos.'
            )}
          />
        </div>
        <div className='col-md-6 col-sm-12 p-0 pl-md-3 pb-3'>
          <MethodBody
            icon='creditCard'
            title={I18n.get('creditToSales', 'Crédito a ventas')}
            showTagElectronic={hasFEactive || hasElectronicPOSActive}
            onClick={() => onChooseMethod('creditToSales')}
            description={textCreditToSales}
          />
        </div>

        <div className='col-md-6 col-sm-12 p-0 pr-md-3 pt-md-3'>
          <MethodBody
            icon='arrowsCross'
            onClick={() => onChooseMethod('combined')}
            title={I18n.get('combined', 'Combinado')}
            description={I18n.get(
              'newRefundCombinedMethodDescription',
              'Devuelve dinero a tu cliente y a la vez aplica crédito a una de sus ventas.'
            )}
          />
        </div>

        <div className='col-md-6 col-sm-12 p-0 pl-md-3 pt-3'>
          <MethodBody
            icon='seller'
            onClick={() => onChooseMethod('positiveBalance')}
            title={I18n.get('positiveBalance', 'Saldo a favor ')}
            description={I18n.get(
              'newrefundPositiveBalanceMethodDescription',
              'Registra la devolución de productos y deja el valor como anticipo de tu cliente.'
            )}
          />
        </div>
      </div>
    </div>
  );
};

Methods.propTypes = {
  onChooseMethod: PropTypes.func.isRequired,
};

export default React.memo(Methods);

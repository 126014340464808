import React from 'react'
import PropTypes from 'prop-types';
import { I18n } from '@aws-amplify/core';
import { Form } from 'react-final-form';
import { FORM_ERROR } from 'final-form';
import { useSelector, useDispatch } from 'react-redux'

import { setSettings } from '../../../../reducers/editInvoice'
import { electronicInvoicing } from '../../../../selectors/app'
import { country as countrySelector } from '../../../../selectors/company'
import { settings } from '../../../../selectors/editInvoice'
import { getMainPriceList } from '../../../../selectors/priceLists';
import { getMainCurrency } from '../../../../selectors/currencies';
import Body from '../../../forms/invoiceSettings/InvoiceSettings';
import { validate, transform } from '../../../forms/invoiceSettings/utils';
import Bottom from '../../common/Bottom'

const InvoiceSettings = ({ isOpen, onClose }) => {
  const dispatch = useDispatch();
  const initialValues = useSelector(settings)
  const country = useSelector(countrySelector)
  const isElectronic = useSelector(electronicInvoicing(initialValues.numeration))
  const mainPriceList = useSelector(getMainPriceList);
  const mainCurrency = useSelector(getMainCurrency)

  const submit = values => {
    const transformedValues = transform(values, { country, isElectronic })
    try {
      dispatch(setSettings(transformedValues))
      onClose()
    } catch {
      return { [FORM_ERROR]: I18n.get('editInvoiceSettingsError', 'hubo un error a la hora de cambiar las configuraciones') }
    }
  }

  return (
    <div className={`${isOpen ? 'open' : ''} refund-settings border d-flex flex-column`}>
      <Form
        onSubmit={submit}
        initialValues={{
          ...initialValues,
          currency: !!initialValues.currency ? initialValues.currency : mainCurrency,
          priceList: !!initialValues.priceList ? initialValues.priceList : mainPriceList,
        }}
        validate={validate}
        keepDirtyOnReinitialize
      >
        {({ handleSubmit, values }) => (
          <form
            noValidate
            onSubmit={handleSubmit}
            className="w-100 h-100 bg-white d-flex flex-column"
          >
            <Body values={values} />

            <Bottom
              onClose={onClose}
            />
          </form>
        )}
      </Form>
    </div>
  )
}

InvoiceSettings.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
}

export default InvoiceSettings;
import React from 'react';
import { I18n } from '@aws-amplify/core';

import Company from '../../forms/company/Company';

const FormBody = props => (
  <>
    <div className="card d-flex flex-column bg-white shadow my-3 p-4 rounded-lg">
      <p className="h3 text-title-secundary text-capitalize-first">
        {I18n.get('generalInfo', 'información general')}
      </p>
      <p className="h5 text-muted text-capitalize-first">
        {I18n.get('generalInfoTutorial', 'Configura la información de tu empresa')}
      </p>
      <div className="form-body w-100 p-3 overflow-hidden">
        <Company.BasicData {...props} />
      </div>
    </div>

    <div className="card d-flex flex-column bg-white shadow my-3 p-4 rounded-lg">
      <p className="h3 text-title-secundary text-capitalize-first">{I18n.get('POSSetting', 'configuración de POS')}</p>
      <p className="h5 text-muted text-capitalize-first">
        {I18n.get('POSSettingTutorial', 'Elije la configuración que tendrán todos los POS')}
      </p>
      <div className="form-body w-100 p-3 overflow-hidden">
        <Company.POSSettings {...props} />
      </div>
    </div>
  </>
)

export default FormBody;

import React from 'react';
import { Field } from 'react-final-form';
import { I18n } from 'aws-amplify';
import { get } from 'lodash';

import { renderSelect } from '../fields/V0/Fields';

import { renderProductKeyTooltip } from '../../../utils';
import configAPI from '../../../reducers/configAPI';

const getProductKeyOptionLabel = (option, country) => country === 'costaRica'
  ? !option.tax
    ? `${option.key} - ${option.name}`
    : `${option.key} - ${option.name} - IVA ${option.tax}%`
  : `${option.key} - ${option.name}`

const UpdateProductKey = ({ country, values }) => {

  return (
    <div className="d-flex w-100">
      <Field
        name="newProductKey"
        component={renderSelect}
        className="col-12 p-0"
        required={country === 'costaRica'}
        loadOptions={async inputValue => {
          try {
            // ex queries.allItemCodes

            const response = await configAPI.get('/products-keys', {
              application_version: country,
              name_contains: inputValue,
              limit: 20,
            })
            let data = get(response, 'data', [])
            if (country === 'costaRica')
              data = data.filter(itemCode => !itemCode.deprecated)

            return data;
          } catch {
            return []
          }
        }}
        defaultOptions
        getOptionLabel={option => getProductKeyOptionLabel(option, country)}
        getOptionValue={option => option.key}
        label={country === 'peru'
          ? (I18n.get('sunatCode', 'código SUNAT'))
          : get(values, 'type') === 'service' ? (I18n.get(`serviceKey`, 'código del producto')) : (I18n.get(`productKey`, 'código del producto'))
        }
        helpTooltip={renderProductKeyTooltip(country)}
        menuPosition="absolute"
      />
    </div>
  )
}

export default UpdateProductKey
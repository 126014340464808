import React, { useRef, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { I18n } from '@aws-amplify/core';
import { get } from 'lodash'

import { toast } from '../../utils'
import { languageSelector } from '../../selectors/auth'

const Checkout = ({ selectedPlan, setSelectedPlan }) => {
  const plansRef = useRef()
  const history = useHistory()
  const language = useSelector(languageSelector)

  useEffect(() => {
    let webComponent = plansRef.current

    const handleBack = () => {
      history.push('/plan/plans')
      setSelectedPlan(null)
    }
    const handleError = (event) => {
      let message = null

      try {
        message = get(event, 'detail.0', null)
      } catch {

      }

      history.push('/plan/plans')
      toast.error({
        subtitle: !!message 
          ? message
          : I18n.get('membershipError', 'Parece que ocurrio un error 🙁. Lo estamos solucionando por favor intenta de nuevo más tarde o ponte en contacto con soporte')
      })
      setSelectedPlan(null)
    }
    const handleComplete = () => {
      history.push('/plan')
      setSelectedPlan(null)
    }


    if (webComponent) {
      webComponent.addEventListener('on-back', handleBack, false);
      webComponent.addEventListener('on-error', handleError, false);
      webComponent.addEventListener('on-complete', handleComplete, false);
    }

    return () => {
      if (webComponent) {
        webComponent.removeEventListener('on-back', handleBack);
        webComponent.removeEventListener('on-error', handleError);
        webComponent.removeEventListener('on-complete', handleComplete);
      }
    }
  }, [setSelectedPlan, history, language]);

  return (
    <div className="p-relative">
      <memberships-wc
        ref={plansRef}
        product="alegra-pos"
        id-plan={selectedPlan.id}
        payment-frequency={selectedPlan.period === 'year' ? '12' : '1'}
      />
    </div>
  )
}

export default Checkout
import { createDraftSafeSelector } from '@reduxjs/toolkit';
import { get } from 'lodash';

import { calculateItemsValues } from '../utils'
import { companySelector, country, decimalPrecision, membershipPlanSelector, membershipSelector } from './company';

const itemsSelector = state => state.activeRefund.items;
const itemsDiscardedSelector = state => state.activeRefund.itemsDiscarded;
const clientSelector = state => state.activeRefund.client;
const priceListSelector = state => state.activeRefund.priceList;
const currencySelector = state => state.activeRefund.currency;
export const tipSelector = state => state.activeRefund.tip;

export const client = createDraftSafeSelector(
  clientSelector,
  client => !!client ? client : null
)

export const priceList = createDraftSafeSelector(
  priceListSelector,
  priceList => !!priceList ? priceList : null
)

export const currency = createDraftSafeSelector(
  currencySelector,
  currency => !!currency ? currency : null
)

export const items = createDraftSafeSelector(
  itemsSelector,
  items => !!items ? items : []
)

export const itemsDiscarded = createDraftSafeSelector(
  itemsDiscardedSelector,
  itemsDiscarded => !!itemsDiscarded ? itemsDiscarded : []
)

export const itemsCount = createDraftSafeSelector(
  itemsSelector,
  items => !!items
    ? items
      .map(item => +get(item, 'quantity', 0))
      .reduce((prev, curr) => prev + curr, 0)
    : 0
)

export const itemQuantity = itemId => createDraftSafeSelector(
  itemsSelector,
  items => !!items
    ? items
      .filter(i => i.id === itemId)
      .map(item => +get(item, 'quantity', 0))
      .reduce((prev, curr) => prev + curr, 0)
    : 0
)

export const itemByIndex = index => createDraftSafeSelector(
  itemsSelector,
  items => !!items ? items[index] : null
)

export const subtotal = createDraftSafeSelector(
  [itemsSelector, decimalPrecision, country, companySelector, membershipSelector, membershipPlanSelector],
  (items, decimal, country, companySelector, membershipSelector, membershipPlanSelector) => !!items
    ? calculateItemsValues({items, decimal, country, company: companySelector, membership: Object.keys(membershipSelector) > 0 ? membershipSelector : membershipPlanSelector}).subtotal
    : 0
)

export const discount = createDraftSafeSelector(
  [itemsSelector, decimalPrecision, country, companySelector,membershipSelector, membershipPlanSelector],
  (items, decimal, country, companySelector, membershipSelector, membershipPlanSelector) => !!items
    ? calculateItemsValues({items, decimal, country, company: companySelector, membership: Object.keys(membershipSelector) > 0 ? membershipSelector : membershipPlanSelector}).discount
    : 0
)

export const discSubtotal = createDraftSafeSelector(
  [itemsSelector, decimalPrecision, country, companySelector, membershipSelector, membershipPlanSelector],
  (items, decimal, country, companySelector, membershipSelector, membershipPlanSelector) => !!items
    ? calculateItemsValues({items, decimal, country, company: companySelector, membership: Object.keys(membershipSelector) > 0 ? membershipSelector : membershipPlanSelector}).discSubtotal
    : 0
)

export const taxes = createDraftSafeSelector(
  [itemsSelector, decimalPrecision, country, companySelector, membershipSelector, membershipPlanSelector],
  (items, decimal, country, companySelector, membershipSelector, membershipPlanSelector) => !!items
    ? calculateItemsValues({items, decimal, country, company: companySelector, membership: Object.keys(membershipSelector) > 0 ? membershipSelector : membershipPlanSelector}).taxes
    : 0
)

export const total = createDraftSafeSelector(
  [itemsSelector, decimalPrecision, country, companySelector, tipSelector, membershipSelector, membershipPlanSelector],
  (items, decimal, country, companySelector, tips, membershipSelector, membershipPlanSelector) => !!items
    ? calculateItemsValues({items, decimal, country, company: companySelector, tips, membership: Object.keys(membershipSelector) > 0 ? membershipSelector : membershipPlanSelector}).total
    : 0
)

export const settings = createDraftSafeSelector(
  [currency, priceList],
  (currency, priceList) => ({
    currency,
    priceList,
  })
)

export const isSelectingItems = createDraftSafeSelector(
  state => state.activeRefund.isSelectingItems,
  isSelectingMode => !!isSelectingMode ? isSelectingMode : false
)
import React from 'react';
import PropTypes from 'prop-types';
import { I18n } from '@aws-amplify/core';
import BigNumber from 'bignumber.js';

/**
 * Component to display summary table.
 *
 * @param {Object} props - Component props.
 * @param {Object} props.itemsValues - Calculated items values.
 * @param {Object} props.fmt - Formatting options.
 * @param {number} props.decimal - Number of decimal places.
 * @param {Array} props.taxes - List of taxes.
 * @param {number} props.additionalCharge - Additional charge amount.
 * @param {number} props.total - Total amount.
 * @param {string} props.country - The country code.
 * @returns {JSX.Element} - Rendered component.
 */
const SummaryTable = React.memo(
  ({ itemsValues, fmt, decimal, taxes, additionalCharge, total, country }) => (
    <div className='d-flex justify-content-end align-items-start flex-wrap'>
      <table className='detail-summary-table rounded-lg mb-4'>
        <tbody>
          <tr>
            <td className='text-capitalize-first body-2-bold'>
              {country === 'spain'
                ? I18n.get('taxableIncome', 'Base imponible')
                : I18n.get('subtotal', 'subtotal')}
            </td>
            <td>{itemsValues.subtotal.toFormat(decimal, fmt)}</td>
          </tr>
          <tr>
            <td className='text-capitalize-first body-2-bold'>
              {I18n.get('discount', 'descuento')}
            </td>
            <td>{itemsValues.discount.toFormat(decimal, fmt)}</td>
          </tr>
          <tr>
            <td className='text-capitalize-first body-2-bold'>
              {country === 'spain'
                ? I18n.get('discountedBasis', 'Base con descuento')
                : I18n.get('subtotal', 'subtotal')}
            </td>
            <td>{itemsValues.discSubtotal.toFormat(decimal, fmt)}</td>
          </tr>
          {taxes.map((tax, index) => (
            <tr key={index}>
              <td className='body-2-bold'>{tax.name}</td>
              <td>{tax.value.toFormat(decimal, fmt)}</td>
            </tr>
          ))}
          {additionalCharge > 0 && (
            <tr>
              <td className='text-capitalize-first body-2-bold'>
                {I18n.get('tip', 'Propina')}
              </td>
              <td>{new BigNumber(additionalCharge).toFormat(decimal, fmt)}</td>
            </tr>
          )}
          <tr>
            <td className='text-capitalize-first heading-3'>
              {I18n.get('total', 'total')}
            </td>
            <td className='font-weight-bold'>
              {new BigNumber(total).toFormat(decimal, fmt)}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  )
);

SummaryTable.propTypes = {
  itemsValues: PropTypes.shape({
    subtotal: PropTypes.instanceOf(BigNumber).isRequired,
    discount: PropTypes.instanceOf(BigNumber).isRequired,
    discSubtotal: PropTypes.instanceOf(BigNumber).isRequired,
    taxes: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        value: PropTypes.instanceOf(BigNumber).isRequired,
      })
    ).isRequired,
  }).isRequired,
  fmt: PropTypes.object.isRequired,
  decimal: PropTypes.number.isRequired,
  taxes: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      value: PropTypes.instanceOf(BigNumber).isRequired,
    })
  ).isRequired,
  additionalCharge: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
  country: PropTypes.string.isRequired,
};

export default SummaryTable;

import { I18n } from 'aws-amplify';

import ContactPortal from '../portal/Portal';

export const handleDeleteClient = async ({
  contact,
  handleDeleteContact,
  closeModal,
  openModal,
}) => {
  openModal({
    name: `deleteClient-${contact?.id}`,
    hideCloseButton: true,
    title: I18n.get('deleteClient', '¿Deseas eliminar este contacto?'),
    description: I18n.get(
      'deleteClientDescription',
      'Al confirmar que deseas eliminar a <b>{{name}}</b>, se borrará de forma definitiva de tus contactos.'
    ).replace('{{name}}', contact?.name || ''),
    primaryAction: {
      text: I18n.get('confirmDeleteContact', 'Si, deseo eliminarlo'),
      onClick: () => [
        closeModal(`deleteClient-${contact?.id}`),
        handleDeleteContact(contact?.id),
      ],
      variant: 'destructive',
    },
    secondaryAction: {
      text: I18n.get('keepContact', 'Conservar contacto'),
      onClick: () => closeModal(`deleteClient-${contact?.id}`),
    },
  });
};

export const handleSendAlegraPortalLink = async ({ contact, openModal }) => {
  const modalName = `sendClientLink-${contact?.id}`;

  openModal({
    name: modalName,
    component: ContactPortal,
    props: { contact, modalName },
    hideCloseButton: true,
    size: 'large',
    title: I18n.get('sendAlegraPortalLink', 'Enviar enlace del Portal Alegra'),
  });
};

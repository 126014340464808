import alegraAPI from '../../../reducers/alegraAPI';
import { useQuery } from '@tanstack/react-query';

const variantsKeys = {
  all: 'variants',
  single: 'variant'
};

const fetchVariants = async (filters) => {
  const response = await alegraAPI.get("/variant-attributes", {
    ...filters,
  })

  return response.data;
};

const fetchSingleVariant = async (id) => {
  const response = await alegraAPI.get(`/variant-attributes/${id}`);

  return response.data;
};

export const useVariantsQuery = (filters) => {
  return useQuery({
    queryKey: [variantsKeys.all, filters ?? {}],
    queryFn: () => fetchVariants(filters ?? {}),
    keepPreviousData: true,
    staleTime: 1000 * 60 * 60 * 24,
    refetchOnReconnect: 'always',
  })
};

export const useSingleVariantQuery = (id) => {
  return useQuery({
    queryKey: [variantsKeys.single, { id }],
    queryFn: () => fetchSingleVariant(id),
    keepPreviousData: true,
    staleTime: 1000 * 60 * 60 * 24,
    refetchOnReconnect: "always",
  })
}
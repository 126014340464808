import { graphqlOperation } from '@aws-amplify/api';
import { I18n } from 'aws-amplify';
import { get } from 'lodash';
import * as mutations from '../../../graphql/mutations';
import { toast } from '../../../utils';
import { handleError } from '../../../utils/errors';

export const editCompanyValue = async (api, newValues) => {
  try {
    const response = await api(graphqlOperation(mutations.updateUser, {
      user: {
        company: {
          ...newValues
        }
      }
    }))

    if (!!get(response, 'data.updateUser.id', null))
      return response

  } catch (err) {
    toast.error({ title: handleError(err, { defaultMessage: I18n.get('updateCompanyError', 'ocurrió un error al intentar actualizar los datos de la compañía') }) })
  }
  return null
}

export const editCategoryValue = async (api, name, id) => {
  try {
    const response = await api(graphqlOperation(mutations.updateUser, {
      user: {
        company: {
          localSettings: {
            [name]: id
          }
        }
      }
    }))

    if (!!get(response, 'data.updateUser.id', null))
      return response

  } catch (err) {
    toast.error({ title: handleError(err, { defaultMessage: I18n.get('updateCompanyError', 'ocurrió un error al intentar actualizar los datos de la compañía') }) })
  }
  return null
}
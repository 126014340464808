import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Form } from 'react-final-form';
import { I18n } from '@aws-amplify/core';
import { graphqlOperation } from '@aws-amplify/api';
import { get } from 'lodash'

import * as mutations from '../../../graphql/mutations';
import { formError, handleError } from '../../../utils/errors';
import { toast } from '../../../utils';
import { checkStationValues } from '../../../reducers/app';
import { closeModal, openModal } from '../../../reducers/modals';
import { getShiftPayments, getShiftStatus } from '../../../reducers/shifts';
import { sendGTMEvent } from '../../../reducers/company';
import { hasPermissionTo } from '../../../selectors/auth';
import { station as stationSelector, offlineData, APIGraphqlSelector } from '../../../selectors/app';
import { decimalPrecision } from '../../../selectors/company';
import {
  paymentsLoading,
  paymentsError,
  payments as paymentsSelector,
  baseAmount,
} from '../../../selectors/shifts';
import Body from '../../forms/closeShift/CloseShift';
import { validate, transform } from '../../forms/closeShift/utils';


import Modal from '../../common/Modal';
import Bottom from '../common/Bottom';
import Notification from '../common/Notification';
import Details from './Details';

const CloseShift = () => {
  const dispatch = useDispatch();
  const can = useSelector(hasPermissionTo);
  const isOpen = useSelector(state => get(state, 'modals.closeShift.isOpen', false));
  const attemptOrigin = useSelector(state => get(state, 'modals.closeShift.params.attemptOrigin', false));
  const loading = useSelector(paymentsLoading);
  const error = useSelector(paymentsError);
  const decimal = useSelector(decimalPrecision);
  const payments = useSelector(paymentsSelector)
  const startingAmount = useSelector(baseAmount)
  const station = useSelector(stationSelector);
  const offlineStatus = useSelector(offlineData);
  const APIGraphql = useSelector(APIGraphqlSelector);

  useEffect(() => {
    if (!!isOpen)
      dispatch(getShiftPayments());
  }, [isOpen, dispatch]);


  const submit = async values => {
    try {
      const canContinue = await dispatch(checkStationValues({ type: 'closeShift' }))
      if (!canContinue)
        return;

      const transformedValues = transform({
        ...payments,
        idStation: station.id,
        costCenter: station.idCostCenter,
        observations: values.observations,
      })
      console.log(transformedValues)
      await APIGraphql(graphqlOperation(mutations.closeShift, {
        shift: transformedValues
      }))

      dispatch(getShiftStatus())

      dispatch(closeModal({ modal: 'closeShift' }))

      dispatch(sendGTMEvent("shift-closed", {
        attemptOrigin,
      }))

      toast.success({
        title: `${I18n.get('shiftClosedSuccessfully', 'el turno fue cerrado con éxito')} 🔥`,
        subtitle: I18n.get('shiftClosedSuccessfullyMessage', 'Tu turno se encuentra cerrado y ya estás teniendo el control del efectivo en tu punto de venta.'),
      })

    } catch (error) {
      return formError(error, I18n.get('closeShiftError', 'hubo un error a la hora de cerrar el turno'))
    }
  }

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={() => dispatch(closeModal({ modal: 'closeShift' }))}
      title={(
        <div className="d-flex justify-content-between align-items-center w-100">
          <div className="modal__header-title text-capitalize-first h2 text-primary text-truncate">
            {I18n.get('closeShift', 'cerrar turno')}
          </div>
        </div>
      )}
      className="modal__close-shift"
    >
      <Form
        onSubmit={submit}
        validate={values => validate(values, {
          shift: { ...payments, startingAmount },
          decimal
        })}
      >
        {({ handleSubmit, submitting, submitError }) => (
          <form noValidate onSubmit={handleSubmit}
          >
            <Details />

            <div className="w-100 text-center">
              <button
                type="button"
                className="btn btn-submit my-2"
                onClick={() => {
                  dispatch(openModal({ modal: 'payments', params: { creationOrigin: "from shift closing" } }));
                  dispatch(sendGTMEvent("new-cash-movement-attempted", {
                    attemptOrigin: "from shift closing",
                    creationStatus: "success",
                  }));
                }}
              >
                {I18n.get('inOutCash', 'ingresar / retirar efectivo')}
              </button>
              <div className="border-bottom my-2 px-2" />
            </div>


            <div className="d-flex flex-column p-3">
              <Body />
            </div>

            <Notification
              type="warning"
              isOpen={!can('close', 'pos-cashier')}
              text={I18n.get('userNotAllowed.pos-cashier.close', 'no tienes permisos para cerrar turnos')}
            />

            <Notification
              isOpen={!!error}
              text={`${I18n.get('thereWasAnErrorLoadingShiftValues', 'Hubo un error cargando los valores del turno.')} ${handleError(error)}`}
            />

            <Notification
              isOpen={offlineStatus}
              text={`${I18n.get('youMustSynchronizeBeforeCloseShift', 'Debes sincronizar tu información antes de poder cerrar turno')} ${handleError(error)}`}
            />

            <Notification isOpen={!!submitError} text={submitError} />

            <Bottom
              disabled={!!loading || !!error || !can('close', 'pos-cashier') || offlineStatus}
              submitting={submitting}
              onClose={() => dispatch(closeModal({ modal: 'closeShift' }))}
            />
          </form>
        )}
      </Form>
    </Modal>
  )
}

export default CloseShift;
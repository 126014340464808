import { IconBulb, IconBulbOff } from "@tabler/icons-react";

export const filters = {
  id: 'status',
  title: 'Filtrar',
  options: [
    {
      label: 'Estado',
      value: 'status',
      icon: IconBulb,
      filterOptions: [
        {
          label: 'Activo',
          value: 'active',
          icon: IconBulb,
        },
        {
          label: 'Inactivo',
          value: 'inactive',
          icon: IconBulbOff,
        },
      ],
    },
  ],
};

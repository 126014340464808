import React, { useState, useRef, useEffect, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { I18n } from '@aws-amplify/core';
import { graphqlOperation } from '@aws-amplify/api';
import { get } from 'lodash';

import * as mutations from '../../graphql/mutations';
import { handleError } from '../../utils/errors';
import { getNumber, getNumberAndPrefix } from '../../utils/invoices';
import { openModal, closeModal } from '../../reducers/modals';
import { setTypeToPrint } from '../../reducers/print';
import { setInvoice } from '../../reducers/editInvoice';
import { syncOffline } from '../../reducers/activeInvoice';
import {
  shiftsEnabled,
  country as countrySelector,
  companySelector,
} from '../../selectors/company';
import { hasPermissionTo } from '../../selectors/auth';
import { isOpen, current } from '../../selectors/shifts';
import { getInvoiceNumerations as numerationSelector } from '../../selectors/numerations';
import Confirmation from '../modals/confirmation/Confirmation';
import VoidInvoiceCause from '../modals/voidInvoiceCause/VoidInvoiceCause';
import {
  getLegalStatusKey,
  getVoidTitle,
  isElegibleForApplyRefund,
  showVoidIcon,
} from './utils';
import alegraAPI from '../../reducers/alegraAPI';
import {
  replaceAndParse,
  toast,
} from '../../utils';
import parse from 'html-react-parser';
import { APIGraphqlSelector } from '../../selectors/app';
import { sendNewGTMEvent } from '../../reducers/company';
import { COUNTRIES } from '../../utils/enums/countries';
import { Button, Tooltip, Typography, useModal } from '@alegradev/smile-ui-react';
import { station as stationSelector } from '../../selectors/app';

import { Icon } from '@alegradev/smile-ui-react';
import ShareInvoice from '../modals/ShareInvoice/ShareInvoice';
import { newShareInvoiceAvailable } from '../modals/ShareInvoice/utils';
import getCanVoidStrategy from './canVoidStrategies/strategyFactory';
import { LEGAL_STATUSES } from '../../utils/enums/legalStatus';
import { REFUND_METHODS } from '../../utils/enums/refundMethods';
import { IconCircleArrowDownLeft, IconList, IconLoader2, IconMail, IconNote, IconPencil, IconPrinter, IconRotate, IconShare } from '@tabler/icons-react';
import { DOCUMENT_TYPES } from '../../utils/enums/documentTypes';

/**
 * DetailHeader Component
 *
 * Displays detailed information about an invoice along with action buttons for stamping, voiding, editing, sharing, and printing.
 *
 * @component
 * @param {Object} props
 * @param {Object} props.invoice - The invoice object containing all invoice details.
 * @param {Function} props.onListView - Function to switch to list view.
 * @param {Function} props.onRefresh - Function to refresh invoice data.
 * @param {Function} props.onEdit - Function to handle invoice editing.
 * @returns {JSX.Element|null} The rendered component or null if no invoice is provided.
 */
const DetailHeader = ({ invoice, onListView, onRefresh, onEdit }) => {
  const dispatch = useDispatch();
  const ref = useRef(null);
  const can = useSelector(hasPermissionTo);
  const [loading, setLoading] = useState(false);
  const [loadingStamp, setLoadingStamp] = useState(false);
  const [error, setError] = useState(null);
  const confirmationOpen = useSelector((state) =>
    get(state, 'modals.voidInvoice.isOpen', false)
  );
  const isShiftEnabled = useSelector(shiftsEnabled);
  const isShiftOpen = useSelector(isOpen);
  const currentShift = useSelector(current);
  const country = useSelector(countrySelector);
  const numerations = useSelector(numerationSelector);
  const APIGraphql = useSelector(APIGraphqlSelector);
  const company = useSelector(companySelector);
  const station = useSelector(stationSelector);
  const { openModal: openNewModal } = useModal();

  const documentType = invoice?.numberTemplate?.documentType ?? 'saleTicket';
  const isElectronic = invoice?.numberTemplate?.isElectronic ?? false;
  const invoiceStatus = invoice?.status ?? 'open';

  useEffect(() => {
    ref.current = true;
    return () => (ref.current = false);
  }, []);

  /**
   * Determines if the invoice can be stamped.
   *
   * @returns {boolean} True if the invoice can be stamped, false otherwise.
   */
  const canStamp = useCallback(() => {
    if (!invoice) return false;

    const allowedCountries = [
      COUNTRIES.COSTA_RICA,
      COUNTRIES.ARGENTINA,
      COUNTRIES.COLOMBIA,
      COUNTRIES.PERU,
      COUNTRIES.REPUBLICA_DOMINICANA,
      COUNTRIES.PANAMA,
    ];

    if (!allowedCountries.includes(country)) return false;

    const isInvoiceStamped = invoice?.stamp?.date ?? null;
    const invoiceNumeration = numerations.find(
      (n) => Number(n.id) === Number(invoice?.numberTemplate?.id)
    );
    const IN_PROCESS_LEGAL_STATUS = 'STAMPED_AND_WAITING_RESPONSE';
    const isInProcess =
      [COUNTRIES.REPUBLICA_DOMINICANA, COUNTRIES.PANAMA].includes(country) &&
      invoice?.stamp?.legalStatus === IN_PROCESS_LEGAL_STATUS;

    return (
      invoiceNumeration &&
      !isInvoiceStamped &&
      invoiceNumeration.isElectronic &&
      can('edit-open', 'invoices', true) &&
      !isInProcess
    );
  }, [invoice, country, numerations, can]);

  /**
     * Determines if the invoice can be voided using the Strategy Pattern with objects.
     *
     * @returns {boolean} True if the invoice can be voided, false otherwise.
     */
  const canVoid = useCallback(() => {
    if (!invoice) return false;

    const strategy = getCanVoidStrategy(country);

    return strategy.canVoid(invoice, {
      country,
      company,
      isShiftEnabled,
      isShiftOpen,
      currentShift,
      can,
    });
  }, [invoice, country, company, isShiftEnabled, isShiftOpen, currentShift, can]);

  /**
   * Determines if the invoice can be edited.
   *
   * @returns {boolean} True if the invoice can be edited, false otherwise.
   */
  const canEdit = useCallback(() => {
    if (!invoice) return false;

    return (
      get(invoice, 'status') === 'open' &&
      !get(invoice, 'stamp') &&
      can('edit-open', 'invoices', true)
    );
  }, [invoice, can]);

  /**
   * Determines if the invoice can be sent or printed.
   *
   * @returns {boolean} True if the invoice can be sent or printed, false otherwise.
   */
  const canSendOrPrint = useCallback(() => {
    if (!invoice) return false;

    if (country !== COUNTRIES.PANAMA || !get(invoice, 'numberTemplate.isElectronic'))
      return true;

    const isInvoiceClosed =
      get(invoice, 'stamp.legalStatus') === LEGAL_STATUSES.STAMPED_AND_ACCEPTED ||
      get(invoice, 'stamp.legalStatus') === LEGAL_STATUSES.STAMPED_AND_ACCEPTED_WITH_OBSERVATIONS;
    return isInvoiceClosed;
  }, [invoice, country]);

  if (!invoice) return null;

  /**
 * Generates the document type text based on the invoice and country.
 *
 * @param {Object} invoice - The invoice object.
 * @returns {string} The document type text.
 */
  const getDocumentTypeText = useCallback(
    (invoice) => {
      if (country === COUNTRIES.COLOMBIA) {
        const docType = get(invoice, 'numberTemplate.documentType');
        const replacements = {
          invoice: ['factura electrónica', 'emitida'],
          saleTicket: ['documento POS', 'emitido'],
          creditNote: ['nota de crédito', 'emitida'],
          incomeAdjustmentNote: ['nota de ajuste', 'emitida'],
        };
        if (replacements[docType]) {
          return replaceAndParse(
            I18n.get(
              'invoiceStampedSuccess',
              'Tu {} se envió con éxito a la DIAN y ya se encuentra {}'
            ),
            replacements[docType]
          );
        }
      }
      return I18n.get(
        'invoiceStampedSuccess',
        'La factura electrónica de venta se emitió con éxito'
      );
    },
    [country]
  );

  /**
   * Handles the stamping of the invoice.
   *
   * @returns {Promise<void>}
   */
  const stampInvoice = useCallback(async () => {
    setLoadingStamp(true);
    const invoicePayments = get(invoice, 'payments', []) || [];

    // Close any open payments before stamping
    await Promise.all(
      invoicePayments.map(async (payment) => {
        if (payment.status === 'open') {
          try {
            await alegraAPI.post(`/payments/${payment.id}/void`);
          } catch (e) {
            console.error(`Error voiding payment ${payment.id}:`, e);
          }
        }
      })
    );

    try {
      let updatedInvoice = {
        paymentForm: get(invoice, 'paymentForm', 'CASH'),
        paymentMethod: get(invoice, 'paymentMethod', 'CASH'),
        stamp: { generateStamp: true },
      };

      if (country === COUNTRIES.PANAMA) {
        updatedInvoice = {
          saleCondition: get(invoice, 'saleCondition', 'CASH'),
          type: 'INTERNAL_OPERATION',
          operationType: 'SALE',
          saleType: 'ORDER',
          stamp: { generateStamp: true },
        };
      }

      await alegraAPI.put(`/invoices/${invoice.id}`, updatedInvoice);

      // Re-open payments after stamping
      if (invoicePayments.length > 0) {
        await Promise.all(
          invoicePayments.map(async (payment) => {
            try {
              await alegraAPI.post(`/payments/${payment.id}/open`);
            } catch (e) {
              console.error(`Error reopening payment ${payment.id}:`, e);
            }
          })
        );
      }

      onRefresh();
      toast.success({
        title: I18n.get('stampedSuccessfullyMessage', '¡Ya está! ⚡'),
        subtitle: getDocumentTypeText(invoice),
      });
    } catch (err) {
      // Re-open payments in case of error
      if (invoicePayments.length > 0) {
        await Promise.all(
          invoicePayments.map(async (payment) => {
            try {
              await alegraAPI.post(`/payments/${payment.id}/open`);
            } catch (e) {
              console.error(`Error reopening payment ${payment.id}:`, e);
            }
          })
        );
      }
      toast.error({
        title: 'Error',
        subtitle: parse(
          handleError(
            err.response?.data,
            {
              defaultMessage: I18n.get(
                'changeInvoiceError',
                'No pudimos modificar la factura. Inténtalo de nuevo'
              )
            }
          )
        ),
      });
    } finally {
      if (ref.current) setLoadingStamp(false);
    }
  }, [invoice, country, onRefresh, getDocumentTypeText]);

  /**
   * Voids the invoice with the provided cause.
   *
   * @param {string} cause - The cause for voiding the invoice.
   * @returns {Promise<void>}
   */
  const voidInvoice = useCallback(
    async (cause) => {
      setLoading(true);
      setError(null);

      try {
        await APIGraphql(
          graphqlOperation(mutations.cancelInvoice, {
            id: invoice.id,
            payments: invoice.payments?.map((p) => p.id) || [],
            cause,
            idStation: get(station, 'id'),
          })
        );
        if (ref.current) {
          setLoading(false);
          setError(null);
        }
        dispatch(closeModal({ modal: 'voidInvoice' }));
        onRefresh();
      } catch (err) {
        if (ref.current) {
          setLoading(false);
          setError(handleError(err));
        }
      }
    },
    [APIGraphql, invoice, station, dispatch, onRefresh]
  );

  /**
   * Checks the country-specific conditions before voiding the invoice.
   *
   * @returns {void}
   */
  const checkCountry = useCallback(() => {
    if (country !== COUNTRIES.REPUBLICA_DOMINICANA) {
      voidInvoice();
    } else {
      dispatch(closeModal({ modal: 'voidInvoice' }));
      dispatch(openModal({ modal: 'voidInvoiceCause' }));
    }
  }, [country, voidInvoice, dispatch]);

  /**
   * Generates the title based on the invoice and country.
   *
   * @returns {string} The generated title.
   */
  const title = useMemo(() => {
    if ([COUNTRIES.MEXICO, COUNTRIES.SPAIN].includes(country)) {
      return getNumber(invoice, country);
    }

    const number = get(invoice, 'numberTemplate.number', null);
    return number
      ? `<b>${I18n.get('number', 'Número')}</b> ${number}`
      : '';
  }, [invoice, country]);

  if (!invoice) return null;

  /**
   * Determines the default void method based on the country and invoice details.
   *
   * @returns {string} The default void method.
   */
  const getVoidDefaultMethod = useCallback(() => {
    if (country === COUNTRIES.SPAIN) {
      const documentType = get(invoice, 'numberTemplate.documentType', 'saleTicket');
      if (!isElectronic) {
        return documentType === DOCUMENT_TYPES.SALE_TICKET
          ? REFUND_METHODS.NO_ELECTRONIC_SIMPLIFIED_INVOICE
          : REFUND_METHODS.NO_ELECTRONIC_ORDINARY_INVOICE;
      }
      return documentType === DOCUMENT_TYPES.SALE_TICKET
        ? REFUND_METHODS.RECTIFICATIVE_IN_SIMPLIFIED_INVOICE
        : REFUND_METHODS.REST;
    }
    return REFUND_METHODS.CREDIT_TO_SALES;
  }, [country, invoice, isElectronic]);

  return (
    <>
      <div className='px-4 pt-4 d-flex justify-content-between align-items-top'>
        <div className='d-flex align-items-center '>
          <Icon
            icon={IconCircleArrowDownLeft}
            extraClass='icon-grayblue7 mr-1'
            width='17.5'
            height='17.5'
          />
          {[COUNTRIES.PANAMA].includes(country) ? (
            <>
              <h2 className='title text-capitalize-first'>
                {getNumberAndPrefix(invoice, country)}
              </h2>
              {get(invoice, 'numberTemplate.prefix', null) && (
                <Typography
                  type='body-3-regular'
                  variant='secondary'
                  extraClassName='mr-3'
                  withHtml
                  text={`<b>${I18n.get('billingPoint', 'Punto de Facturación')}</b> ${get(invoice, 'numberTemplate.prefix', null)}`}
                />
              )}
            </>
          ) : (
            <div className='invoices-header__numeration'>
              {get(invoice, 'numberTemplate.number', null) && (
                <Typography
                  type='body-3-regular'
                  variant='secondary'
                  extraClassName='mr-3'
                  withHtml
                  text={title}
                />
              )}
              {get(invoice, 'numberTemplate.branchOfficeCode', null) && (
                <Typography
                  type='body-3-regular'
                  variant='secondary'
                  extraClassName='mr-3'
                  withHtml
                  text={`<b>${I18n.get('branch', 'Sucursal')}</b> ${get(invoice, 'numberTemplate.branchOfficeCode', null)}`}
                />
              )}
            </div>
          )}
        </div>

        <div className='actions d-flex align-items-center'>
          <div className='w-100 d-flex justify-content-around align-items-center flex-wrap'>
            {canStamp() && (
              <>
                <button
                  type='button'
                  className='btn btn-submit text-nowrap absolute-center'
                  onClick={() => [
                    stampInvoice(),
                    dispatch(
                      sendNewGTMEvent('pos-sale-managed', {
                        id: get(invoice, 'id', ''),
                        action: 'emit',
                      })
                    ),
                  ]}
                >
                  {loadingStamp ? (
                    <Icon icon={IconLoader2} animated extraClass=' icon-white' />
                  ) : (
                    <div className='d-none d-sm-inline'>
                      {I18n.get('emit', 'emitir')}
                    </div>
                  )}
                </button>
              </>
            )}
            {!invoice.offlineStatus && (
              <>
                {canVoid() && (
                  <Button
                    type='button'
                    onClick={() => {
                      if (isElegibleForApplyRefund(invoice, country, company)) {
                        dispatch(
                          openModal({
                            modal: 'newRefunds',
                            params: {
                              step: 1,
                              method: getVoidDefaultMethod(),
                              invoice,
                              country,
                            },
                          })
                        );
                      } else {
                        dispatch(openModal({ modal: 'voidInvoice' }));
                      }
                      dispatch(
                        sendNewGTMEvent('pos-sale-managed', {
                          id: get(invoice, 'id', ''),
                          action: 'cancel',
                        })
                      );
                    }}
                    text={getVoidTitle(invoice, country)}
                    leftIcon={showVoidIcon(invoice, country, company) ? IconNote : ''}
                  />
                )}
                {!isElectronic && invoiceStatus === 'open' && (
                  <Tooltip
                    visible={!can('edit-open', 'invoices')}
                    overlay={I18n.get(
                      `userNotAllowed.edit.${documentType}`,
                      'Necesitas permiso del administrador para editar el precio.'
                    )}
                    placement='bottom'
                  >
                    <Button
                      type='button'
                      disabled={!canEdit()}
                      onClick={() => {
                        if (!canEdit()) {
                          return;
                        }
                        onEdit();
                        dispatch(
                          sendNewGTMEvent('pos-sale-managed', {
                            id: get(invoice, 'id', ''),
                            action: 'edit',
                          })
                        );
                      }}
                      leftIcon={IconPencil}
                      text={I18n.get('edit', 'editar')}
                    />
                  </Tooltip>
                )}
                {canSendOrPrint() &&
                  (get(company, 'id') === '1507139' ||
                    newShareInvoiceAvailable({ country }) ? (
                    <>
                      <Button
                        type='button'
                        onClick={() => [
                          openNewModal({
                            component: ShareInvoice,
                            name: 'ShareInvoice',
                            title:
                              I18n.get('share', 'Compartir') +
                              ' ' +
                              I18n.get('invoice', 'factura'),
                            size: 'large',
                            props: { invoice },
                          }),
                        ]}
                        leftIcon={IconShare}
                        text={I18n.get('share', 'Compartir')}
                      />
                    </>
                  ) : (
                    <Button
                      type='button'
                      onClick={() => [
                        dispatch(
                          openModal({ modal: 'sendEmail', params: { invoice } })
                        ),
                        dispatch(
                          sendNewGTMEvent('pos-sale-managed', {
                            id: get(invoice, 'id', ''),
                            action: 'email',
                          })
                        ),
                      ]}
                      leftIcon={IconMail}
                      text={I18n.get('send', 'enviar')}
                    />
                  ))}
              </>
            )}

            {invoice.offlineStatus &&
              (['error', 'syncing'].includes(invoice.offlineStatus) ||
                (invoice.offlineStatus === 'pending' &&
                  invoice.statusInProcess === 1)) && (
                <>
                  <Button
                    type='button'
                    disabled={invoice.offlineStatus !== 'error'}
                    onClick={() => {
                      dispatch(setInvoice(invoice));
                      dispatch(openModal({ modal: 'editInvoice' }));
                    }}
                    leftIcon={IconPencil}
                    text={I18n.get('edit', 'editar')}
                  />
                  <Button
                    type='button'
                    disabled={
                      invoice.offlineStatus !== 'error' &&
                      !(
                        invoice.offlineStatus === 'pending' &&
                        invoice.statusInProcess === 1
                      )
                    }
                    onClick={() => dispatch(syncOffline(false, true))}
                    leftIcon={invoice.offlineStatus ? '' : IconRotate}
                    loading={invoice.offlineStatus === 'syncing'}
                    text={invoice.offlineStatus ? I18n.get('syncning', 'Sincronizando') : I18n.get('sync', 'sincronizar')}
                  />
                </>
              )}

            {canSendOrPrint() && (
              <Button
                type='button'
                onClick={() => [
                  dispatch(setTypeToPrint('invoice')),
                  dispatch(
                    sendNewGTMEvent('pos-sale-managed', {
                      id: get(invoice, 'id', ''),
                      action: 'print',
                    })
                  ),
                ]}
                leftIcon={IconPrinter}
                text={I18n.get('print', 'imprimir')}
              />
            )}

            <button
              type='button'
              className='btn btn-submit d-block d-sm-none'
              onClick={() => onListView()}
            >
              <Icon icon={IconList} className='icon-white' />
            </button>
          </div>
        </div>
      </div>
      {!!invoice.offlineStatus && invoice.offlineStatus === 'error' && (
        <p className='h4 px-4 text-danger text-capitalize-first'>
          {replaceAndParse(invoice.error)}
        </p>
      )}

      {getLegalStatusKey(invoice) === 'legalStatus.contingency' && (
        <p className='h5 text-capitalize-first bg-warning rounded p-2'>
          {I18n.get('contingencyWarning1', 'Recuerda enviar a la DIAN')}{' '}
          <b>{I18n.get('contingencyWarning2', 'desde Alegra Contabilidad')}</b>{' '}
          {I18n.get(
            'contingencyWarning3',
            'los documentos equivalentes que generaste durante el día sin IVA, conoce cómo hacerlo'
          )}{' '}
          <a
            className='btn-link'
            href='https://ayuda.alegra.com/es/ayuda-contingencia-dia-sin-iva-pos'
            target='_blank'
            rel='noreferrer'
          >
            {I18n.get('here', 'aquí')}
          </a>
        </p>
      )}

      <Confirmation
        isOpen={confirmationOpen}
        onClose={() => dispatch(closeModal({ modal: 'voidInvoice' }))}
        onConfirm={() => checkCountry()}
        title={I18n.get('voidInvoice', 'anular venta')}
        closeText={I18n.get('cancel', 'Cancelar')}
        confirmText={I18n.get('confirmVoid', 'Confirmar anulación')}
        body={
          <>
            <p className='text-center h4 text-capitalize-first p-5'>
              {replaceAndParse(
                I18n.get(
                  'areYouSureToVoidInvoice',
                  'Confirma que deseas anular la venta <strong> {} </strong> para que se elimine de tus registros.'
                ),
                [getNumber(invoice, country)]
              )}
            </p>
            {!!error && (
              <p className='text-center h4 text-capitalize-first text-danger pb-4'>
                {error}
              </p>
            )}
          </>
        }
        submitting={loading}
        disableCancel={loading}
        hideRequired
      />
      <VoidInvoiceCause
        voidInvoice={(cause) => voidInvoice(cause)}
        submitting={loading}
        submitError={error}
      />
    </>
  );
};

DetailHeader.propTypes = {
  invoice: PropTypes.object,
  onListView: PropTypes.func.isRequired,
  onRefresh: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
};

export default DetailHeader;

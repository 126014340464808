import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { graphqlOperation } from '@aws-amplify/api';
import { I18n } from '@aws-amplify/core';
import { get } from 'lodash'

import * as mutations from '../../../graphql/mutations'
import { closeModal } from '../../../reducers/modals';
import { setUser } from '../../../reducers/auth';
import onboardingEnd from '../../images/onboardingEnd.gif';
import BackgroundSVG from '../../images/background.png'
import Confirmation from '../confirmation/Confirmation'
import { replaceAndParse } from '../../../utils';
import { sendGTMEvent } from '../../../reducers/company';
import { APIGraphqlSelector } from '../../../selectors/app';
import { country as countrySelector} from '../../../selectors/company';

const OnboardingEnd = () => {
  const isOpen = useSelector(state => get(state, 'modals.onboardingEnd.isOpen', false))
  const dispatch = useDispatch();
  const history = useHistory();
  const discountAvailability = useSelector(state => get(state, 'modals.onboardingEnd.params.discountAvailability', false));
  const APIGraphql = useSelector(APIGraphqlSelector);
  const country = useSelector(countrySelector);

  const close = async () => {
    dispatch(sendGTMEvent("create-first-invoice-step-completed"));
    dispatch(closeModal({ modal: 'onboardingEnd' }));
    if (discountAvailability) {
      window.open(`${process.env.REACT_APP_ALEGRA_SMILE}subscriptions/products/alegra-pos/plans`, '_blank')
    } else {
      history.push('/')
    }
    await handleClose();
  }

  const handleClose = async () => {
    try {
      const response = await APIGraphql(graphqlOperation(mutations.updateUser, {
        user: { 
          seenWelcome: true
        }
      }))
      if (!!get(response, 'data.updateUser.id', null))
        dispatch(setUser(get(response, 'data.updateUser')))
    } catch {

    }
  }

  return (
    <Confirmation
      isOpen={isOpen}
      onClose={close}
      onConfirm={close}
      confirmText={discountAvailability ? I18n.get('tryNow', '¡Probar ahora!') : I18n.get('createFirstInvoice', 'Crear primera factura')}
      hideCancel
      confirmId="first-invoice-clicked"
      hideRequired
      body={(
        <div
        className="d-flex flex-column justify-content-center align-items-center py-4"
        style={{
          background: `url(${BackgroundSVG}) no-repeat`,
          backgroundSize: '100% auto',
          backgroundRepeat: 'no-repeat',
          backgroundPositionY: '100%'
        }}
        >
          <img src={onboardingEnd} alt="loading..." className="w-75" />
          <p className="text-capitalize-first text-primary h4 py-4 text-center">
            {I18n.get('onboardingEndTitle', '¡Vende fácil y rápido en tu punto de venta!')}
          </p>
          <p className="text-capitalize-first h4 px-4 text-center text-muted pb-2">
            {replaceAndParse(I18n.get('onboardingEndSubtitle', 'Es tu turno, descubre que tener el control de tus ventas y la gestión del efectivo de tu negocio, ¡ahora es posible con Alegra POS! 🙌'), [])}
          </p>
        </div>
      )}
      
    />
  )
}

export default OnboardingEnd;
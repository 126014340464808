import React from 'react'
import { useSelector } from 'react-redux'
import { I18n } from '@aws-amplify/core';

import { externalProviderState } from '../../../selectors/company'
import ConfirmationModal from '../../modals/confirmation/Confirmation'

const methods = {
  'nequi': {
    name: 'Nequi',
    message: I18n.get('configuredPaymentMethodNequiMessage', 'Tus clientes te pagarán en segundos leyendo el QR con su app.'),
  },
  'tpaga': {
    name: 'TPaga',
    message: I18n.get('configuredPaymentMethodTpagaMessage', 'Tus clientes podrán pagarte en segundos desde un código QR.')
  },
}

const renderTitle = (state, method) => {
  return `${I18n.get('connectYourPointOfSaleWith', 'Conecta tu punto de venta con')} ${methods[method] && methods[method].name}`;
}

const renderBody = (state, method) => {
  return (
    <p className="h4 text-capitalize-first p-5">
      {methods[method] && methods[method].message}
    </p>
  )
}

const ExternalProvidersMethodsModals = ({ method, isOpen, onClose }) => {
  const methodState = useSelector(externalProviderState(method));

  const onRedirectPaymentMethod = (method) => {
    window.open(`${process.env.REACT_APP_ALEGRA_SMILE}integrations/e-payments/${method}/configuration`, '_blank');
  }

  return (
    <ConfirmationModal
      isOpen={isOpen}
      onConfirm={() => onRedirectPaymentMethod(method)}
      onClose={() => onClose()}
      confirmText={`${I18n.get('connectWith', 'Conectar con')}  ${methods[method] && methods[method].name}`}
      hideCancel
      title={renderTitle(methodState, method)}
      body={renderBody(methodState, method)}
      hideRequired={true}
    />
  )
}

export default ExternalProvidersMethodsModals
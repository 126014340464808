import React, { Suspense } from 'react';
import PropTypes from 'prop-types';
import { COUNTRIES } from '../../../utils/enums/countries';
import { I18n } from '@aws-amplify/core';
import ColombiaForm from './countriesForms/ColombiaForm';
import SpainForm from './countriesForms/SpainForm';

/**
 * Mapping of countries to their corresponding refund form components.
 */
const countryFormComponents = {
  [COUNTRIES.COLOMBIA]: ColombiaForm,
  [COUNTRIES.SPAIN]: SpainForm,
};

/**
 * RefundForm Component
 *
 * Dynamically renders the appropriate refund form based on the selected country.
 *
 * @param {Object} props - Component properties.
 * @param {string} props.country - The country code.
 * @param {string} props.method - The selected refund method.
 * @param {Function} props.onChooseMethod - Callback invoked when a refund method is selected.
 * @param {Function} props.changeStep - Callback to change the current step in the refund process.
 * @param {Function} props.reset - Callback to reset the form.
 * @returns {JSX.Element} - The rendered RefundForm component.
 */
const RefundForm = ({ country, method, onChooseMethod, changeStep, reset }) => {
  const SelectedFormComponent = countryFormComponents[country] || null;

  /**
   * If a form component exists for the country, render it within Suspense.
   * Since the user ensures a corresponding form exists, no fallback UI is necessary.
   */
  return SelectedFormComponent ? (
    <Suspense fallback={<div>{I18n.get('loading', 'Cargando...')}</div>}>
      <SelectedFormComponent
        method={method}
        onChooseMethod={onChooseMethod}
        changeStep={changeStep}
        reset={reset}
      />
    </Suspense>
  ) : null;
};

RefundForm.propTypes = {
  country: PropTypes.oneOf([
    COUNTRIES.COLOMBIA,
  ]).isRequired,
  method: PropTypes.oneOf([
    'cash',
    'creditToSales',
    'combined',
    'positiveBalance',
    'rectificativeInSimplifiedInvoice',
  ]).isRequired,
  onChooseMethod: PropTypes.func.isRequired,
  changeStep: PropTypes.func.isRequired,
  reset: PropTypes.func.isRequired,
};

export default React.memo(RefundForm);

import { Typography } from '@alegradev/smile-ui-react';
import { I18n } from 'aws-amplify';
import { capitalize, get } from 'lodash';
import { Field, useFormState } from 'react-final-form';
import { renderField } from '../../fields/V0/Fields';
import { useMemo } from 'react';
import { COUNTRIES } from '../../../../utils/enums/countries';
import { country as countrySelector } from '../../../../selectors/company';
import { useSelector } from 'react-redux';

export const AnnotationField = ({ anotationMaxLimit, type }) => {
  const { values } = useFormState();
  const country = useSelector(countrySelector);

  const isAnnotationRequired = useMemo(() => {
    return (
      country === COUNTRIES.SPAIN &&
      get(values, 'numeration.isElectronic', false)
    );
  }, [country, values]);

  return (
    <Field
      name='anotation'
      className={`modal__invoice-anotation-field ${
        anotationMaxLimit ? 'has-error' : ''
      }`}
      label={
        <Typography
          type='label-2'
          text={
            isAnnotationRequired
              ? I18n.get('operationDescription', 'Descripción de la operación')
              : capitalize(I18n.get('observations', 'observaciones'))
          }
          variant='secondary'
        />
      }
      placeholder={I18n.get('addYourObservation', 'Ingresa tu observación')}
      type={type}
      required={isAnnotationRequired}
      helpTooltip={
        isAnnotationRequired ? (
          <div>
            <Typography
              text='Agrega una descripción general de tu venta, puedes aprender'
              variant='inverse'
              type='caption-bold'
            />
            <a href='www.google.com' target='_blank' rel='noopener noreferrer'>
              <Typography
                text='cómo definir una por defecto.'
                type='caption-bold'
                variant='success'
              />
            </a>
          </div>
        ) : null
      }
    >
      {(props) =>
        renderField({
          ...props,
          fontSize: '16px',
          height: '48px',
          borderRadius: '12px',
          fontColor: '#334155',
          borderColor: '#CBD5E1',
          paddingLeft: '10px',
        })
      }
    </Field>
  );
};

export default AnnotationField;

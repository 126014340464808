import { I18n } from "aws-amplify";

/**
 * Validates the given values object for required fields.
 *
 * @param {Object} values - The values object to validate.
 * @param {Object} values.address - The address object to validate.
 * @param {string} values.address.combined - The combined address string.
 * @returns {Object} errors - An object containing validation error messages.
 * @returns {string} errors.address - Error message for the address field.
 */
export const validate = (values) => {
  const errors = {
    address: {},
  };
  if (!values.address.address || values.address.address.trim() === "") {
    errors.address.address = I18n.get("thisFieldIsRequired", "Este campo es obligatorio");
  }
  if (!values.address.combined || !values.address.combined.key) {
    errors.address.combined = I18n.get("thisFieldIsRequired", "Este campo es obligatorio");
  }
  return errors;
}
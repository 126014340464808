import React from "react";
import { I18n } from "aws-amplify";

import { useFormat } from '../../../hooks/useFormat';
import BigNumber from "bignumber.js";

const Trm = ({ invoice, currentDefaultCurrency }) => {
  const { fmt, decimal } = useFormat();
  const currentSellCurrencyCode = invoice?.currency?.code;
  const currentSellCurrencyExchangeRate = new BigNumber(invoice?.currency?.exchangeRate);
  const defaultCurrencyCode = currentDefaultCurrency?.code;

  return (
    <div className="h6 container-text-flex justify-content-center">
      <div className="text-center">{`${I18n.get('trm', 'TRM')} $1 ${currentSellCurrencyCode} = ${currentSellCurrencyExchangeRate.toFormat(decimal, fmt)} ${defaultCurrencyCode}`}</div>
    </div>
  );
}

export default Trm;
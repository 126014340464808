import React, { useState, useEffect, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { I18n } from '@aws-amplify/core';
import { get } from 'lodash'
import Cookies from 'js-cookie';
import dayjs from 'dayjs';
import { toast } from '../../../utils';

import { openModal, closeModal } from '../../../reducers/modals';
import { companySelector ,country as countrySelector, legalRepresentativeSelector, identificationSelector } from '../../../selectors/company';
import { isAdmin as isAdminSelector } from '../../../selectors/auth';
import Modal from '../../common/Modal';
import { NewTermsOnboard } from './steps';
import LegalRepresentativeForm from './steps/LegalRepresentativeForm';
import ContractInfo from './steps/ContractInfo';
import NewTermsFinished from './steps/NewTermsFinished';

const NewTcCol = () => {
  const dispatch = useDispatch();
  const country = useSelector(countrySelector);
  const legalRepresentative = useSelector(legalRepresentativeSelector);
  const isAdmin = useSelector(isAdminSelector)
  const identification = useSelector(identificationSelector);
  const company = useSelector(companySelector);
  const isOpen = useSelector(state => get(state, 'modals.newTermsAndConditionsColombia.isOpen', false));
  const [step, setStep] = useState(0)

  useEffect(() => {
    if (country === 'colombia' && !!identification) {
      const STAGED_REGISTRY_DATE = "2022-06-07";
      const dateToAsk = Cookies.get('asklaterT&C')
      const askToday = !dateToAsk ? true : dayjs().isAfter(parseInt(dateToAsk));
      const hasFEactive = get(company, 'settings.electronicInvoicing', false);
      const companyIsInRegistryDateRange = dayjs(get(company, 'registryDate').split(" ")[0]).isBefore(STAGED_REGISTRY_DATE);
      const open = legalRepresentative === null && isAdmin && askToday && hasFEactive && companyIsInRegistryDateRange;
      if (open) {
        dispatch(openModal({ modal: 'newTermsAndConditionsColombia' }))
      }
    }
  }, [country, identification, legalRepresentative, isAdmin, company, dispatch])

  const close = useCallback(() => {
    dispatch(closeModal({ modal: 'newTermsAndConditionsColombia' }))
  }, [dispatch])

  const signLater = useCallback(() => {
    Cookies.set('asklaterT&C', dayjs().add(1, 'day').startOf('day').valueOf());
    close();
    toast.success({
      title: I18n.get('notificationTomorrowForFE', 'Mañana te notificaremos de nuevo.☝️'),
      subtitle: I18n.get('notificationTomorrowForFE.description', 'Ten en cuenta que es necesario aceptar los nuevos términos y condiciones para facturar electrónicamente.')
    })
  }, [close])

  const next = useCallback(() => {
    setStep(step => step + 1);
  }, [])

  return (
    <>
      <Modal
        isOpen={isOpen}
        closeAction={step !== 3 ? signLater : close}
        title={step === 0
          ? I18n.get('newTermsAndConditions', 'Nuevos términos y condiciones')
          : I18n.get('updateInTermsAndConditions', 'Actualización de términos y condiciones')}
        className={step === 0 || step === 3
          ? "modal__new-terms-col"
          : "modal__invoice mt-13rem"}
      >
        {step === 0 && (
          <NewTermsOnboard nextStep={next} signLater={signLater} />
        )}
        {step === 1 && (
          <LegalRepresentativeForm nextStep={next} />
        )}
        {step === 2 && (
          <ContractInfo nextStep={next} />
        )}
        {step === 3 && (
          <NewTermsFinished setStep={setStep} />
        )}
      </Modal>
    </>
  )
}

export default NewTcCol;
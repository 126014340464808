import { get, isString } from 'lodash';
import { isDefaultClient } from '../../../../../utils';

export const renderClientName = (client) => {
  if (!!get(client, 'name')) {
    if (!!isString(get(client, 'name'))) return get(client, 'name');
    return `${get(client, 'name.firstName', '')}${
      !!get(client, 'name.secondName', null)
        ? ' ' + get(client, 'name.secondName')
        : ''
    }${
      !!get(client, 'name.lastName', null)
        ? ' ' + get(client, 'name.lastName')
        : ''
    }`;
  }
  if (!!get(client, 'nameObject')) {
    let nameObject = get(client, 'nameObject');
    return `${get(nameObject, 'firstName', '')}${
      !!get(nameObject, 'secondName', null)
        ? ' ' + get(nameObject, 'secondName')
        : ''
    }${
      !!get(nameObject, 'lastName', null)
        ? ' ' + get(nameObject, 'lastName')
        : ''
    }`;
  }
  return '';
};

export const renderClientIdentification = (client, country) => {
  if (!client || isDefaultClient(client, country)) return '';

  if (!!get(client, 'identification')) {
    if (!!isString(get(client, 'identification')))
      return ` (${get(client, 'identification')})`;
    if (!!get(client, 'identification.number'))
      return ` (${get(client, 'identification.number')})`;
    return '';
  }
  if (!!get(client, 'identificationObject.number')) {
    if (!!isString(get(client, 'identificationObject.number')))
      return ` (${get(client, 'identificationObject.number')})`;
  }
  return '';
};

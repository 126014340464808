import { createDraftSafeSelector } from '@reduxjs/toolkit';
import { get, isEmpty } from 'lodash';
import { stationUsers } from './app';
import { idGlobal } from './user';
import { isAdmin } from './auth';
 
const permissionsSelector = state => get(state, 'auth.permissions', null);

const permissions = createDraftSafeSelector(
  permissionsSelector,
  permissions => !!permissions 
    ? JSON.parse(permissions)
    : {}
)

export const can = (entity, action) => createDraftSafeSelector(
  permissions,
  permissions => get(permissions, `${entity}.${action}`) === "allow"
)

export const canUseStation = createDraftSafeSelector(
  [stationUsers, idGlobal, isAdmin],
  (stationUsers, idGlobal, isAdmin) => {
    if (isAdmin || !idGlobal || isEmpty(stationUsers))
      return true;
    
    const user = stationUsers.filter(id => id === idGlobal);
    return !isEmpty(user);
  }
) 
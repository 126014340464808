import { useCallback, useState } from 'react';
import { I18n } from 'aws-amplify';
import { Form } from 'react-final-form';
import { useDispatch } from 'react-redux';
import PendingInvoicesView from './PendingInvoicesView';
import ConfirmLogoutView from './ConfirmLogoutView';
import BottomSection from './BottomSection';
import { logout } from '../../../reducers/app';
import { sendNewGTMEvent } from '../../../reducers/company';
import { useLogoutConfirmationCode } from '../../../hooks/useLogoutConfirmationCode';
import { useLogoutConfirmationModal } from '../../../hooks/useLogoutConfirmationModal';

const NewLogoutConfirm = () => {
  const [closeWithoutSyncing, setCloseWithoutSyncing] = useState(false);
  const dispatch = useDispatch();

  const { confirmationCode } = useLogoutConfirmationCode();

  useLogoutConfirmationModal({ closeWithoutSyncing });

  const onSubmit = useCallback(
    (values) => {
      if (values.confirmationCode === confirmationCode.toString()) {
        dispatch(
          sendNewGTMEvent('pos-confirmation-logout', {
            status: 'confirmationLogout',
          })
        );
        dispatch(logout());
      }
    },
    [dispatch, confirmationCode]
  );

  const validate = useCallback(
    (values) => {
      const errors = {};
      if (
        closeWithoutSyncing &&
        values.confirmationCode !== confirmationCode.toString()
      ) {
        errors.confirmationCode = I18n.get(
          'confirmationCodeError',
          'El código es inválido, inténtalo de nuevo.'
        );
      }
      return errors;
    },
    [closeWithoutSyncing, confirmationCode]
  );

  return (
    <Form
      onSubmit={onSubmit}
      validate={validate}
      render={({ handleSubmit, values, errors }) => {
        const hasError = errors.confirmationCode && values.confirmationCode;
        return (
          <form onSubmit={handleSubmit}>
            <div className='d-flex flex-column justify-content-center align-items-center py-5'>
              {!closeWithoutSyncing ? (
                <PendingInvoicesView />
              ) : (
                <ConfirmLogoutView
                  confirmationCode={confirmationCode}
                  hasError={hasError}
                />
              )}
            </div>
            <BottomSection
              closeWithoutSyncing={closeWithoutSyncing}
              setCloseWithoutSyncing={setCloseWithoutSyncing}
              hasError={hasError}
              confirmationCodeInput={values.confirmationCode}
            />
          </form>
        );
      }}
    />
  );
};

export default NewLogoutConfirm;

import React from 'react';
import { useSelector } from 'react-redux';

import { printSettings } from '../../../selectors/company';
import { logo as logoSelector } from '../../../selectors/company';

import { Company, Logo, LastRow } from '../defaults';
import PrintPaymentHeader from './Header';
import PrintPaymentResume from './Resume';
import PrintPaymentFooter from './Footer';

const PrintPayment = ({ payment }) => {
  const companySetting = useSelector(printSettings);
  const { imageOrQr } = companySetting;
  const companyLogo = useSelector(logoSelector);

  return (
    <div
      id='print-invoice'
      className={`print-${companySetting.format} d-flex flex-column overflow-hidden`}
      style={{
        paddingLeft: `${companySetting.leftMargin}mm`,
        paddingRight: `${companySetting.rightMargin}mm`,
      }}
    >
      <Logo companyLogo={companyLogo} setting={companySetting} />

      <Company
        companyLogo={companyLogo}
        imageOrQr={imageOrQr}
        setting={companySetting}
      />
      <PrintPaymentHeader payment={payment} />
      <PrintPaymentResume payment={payment} />
      <PrintPaymentFooter payment={payment} />
      <LastRow />
    </div>
  );
};

export default PrintPayment;

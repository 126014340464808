// eslint-disable-next-line
export default ({ id, className }) => (
  <svg
    id={id}
    className={className}
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M 13.617188 6.332031 L 15.753906 6.332031 L 13.566406 15.042969 C 13.105469 16.882812 11.433594 18.179688 9.515625 18.179688 C 5.554688 18.179688 3.820312 13.230469 6.929688 10.804688 L 11.90625 6.921875 C 12.394531 6.539062 12.996094 6.332031 13.617188 6.332031 Z M 13.617188 6.332031 "
      fill="#00D6BC" />
    <path d="M 15.992188 13.117188 L 17.6875 6.332031 L 22.3125 6.332031 L 24.003906 13.117188 C 24.648438 15.691406 22.679688 18.179688 20 18.179688 C 17.320312 18.179688 15.351562 15.691406 15.992188 13.117188 Z M 15.992188 13.117188 "
      fill="#00D6BC" />
    <path d="M 24.246094 6.332031 L 26.433594 15.042969 C 26.894531 16.882812 28.566406 18.179688 30.484375 18.179688 C 34.445312 18.179688 36.179688 13.230469 33.070312 10.804688 L 28.09375 6.921875 C 27.605469 6.539062 27.003906 6.332031 26.382812 6.332031 Z M 24.246094 6.332031 "
      fill="#00D6BC" />
    <path d="M 7.722656 23.035156 C 7.722656 21.359375 9.097656 20 10.792969 20 L 29.207031 20 C 30.902344 20 32.277344 21.359375 32.277344 23.035156 L 32.277344 30.628906 C 32.277344 32.308594 30.902344 33.667969 29.207031 33.667969 L 24.296875 33.667969 L 24.296875 28.199219 C 24.296875 26.523438 22.921875 25.164062 21.226562 25.164062 L 18.773438 25.164062 C 17.078125 25.164062 15.703125 26.523438 15.703125 28.199219 L 15.703125 33.667969 L 10.792969 33.667969 C 9.097656 33.667969 7.722656 32.308594 7.722656 30.628906 Z M 7.722656 23.035156 "
      fill="#00D6BC" />
  </svg>

)
import React, { useMemo } from 'react'
import PropTypes from 'prop-types';
import { I18n } from '@aws-amplify/core';
import { useSelector } from 'react-redux'
import { BigNumber } from 'bignumber.js'
import { get } from 'lodash'

import { companySelector, country as countrySelector } from '../../../selectors/company'
import { currency } from '../../../selectors/activeInvoice';
import { calculateSingleItemValues, calculateSingleItemValuesWithDecimals } from '../../../utils';
import useDecimalsVersionsGroup from '../../../hooks/useDecimalsVersionsGroup/hook';
import { COUNTRIES } from '../../../utils/enums/countries';
import { useFormat } from '../../../hooks/useFormat';

const Summary = ({ values }) => {
  const country = useSelector(countrySelector)
  const selectedCurrency = useSelector(currency)
  const company = useSelector(companySelector);
  const { decimal, fmt } = useFormat(selectedCurrency);
  const { isDecimalActive } = useDecimalsVersionsGroup();

  const itemValues = useMemo(() => {
    if (isDecimalActive) {
      return calculateSingleItemValuesWithDecimals(values, decimal, false, country)
    }

    return calculateSingleItemValues(values, decimal)
  }, [values, decimal, country, company, isDecimalActive]);

  const getTaxSummary = (tax) => {
    if (
      country === COUNTRIES.COLOMBIA &&
      (get(tax, 'rate') === 'EXEMPT' || get(tax, 'rate') === 'EXCLUDED')
    ) {
      return (
        tax.name +
        ' ' +
        (tax.rate === 'EXEMPT' ? 'Exento' : 'Excluido') +
        '' +
        `(${BigNumber(tax.percentage).toFormat(decimal, { ...fmt, prefix: '' })}%)`
      );
    }
    return (
      tax.name +
      ' ' +
      `(${BigNumber(tax.percentage).toFormat(decimal, { ...fmt, prefix: '' })}%)`
    );
  };

  return (
    <div className="d-flex flex-column p-4 form-row justify-content-end m-0 new-item-summary">
      <div className="col-12">
        {(itemValues.discount > 0 || !!values.tax) && (
          <div className="new-item-summary__row d-flex justify-content-between align-items-center">
            <div className="new-item-summary__row-concept text-capitalize-first">
              {I18n.get('subtotal', 'sub total')}
            </div>
            <div className="new-item-summary__row-value text-capitalize-first">
              {itemValues.subtotal.toFormat(decimal, fmt)}
            </div>
          </div>
        )}

        {itemValues.discount > 0 && (
          <div className="new-item-summary__row d-flex justify-content-between align-items-center">
            <div className="new-item-summary__row-concept text-capitalize-first">
              {I18n.get('discount', 'descuento')}
            </div>
            <div className="new-item-summary__row-value text-capitalize-first">
              -{itemValues.discountValue.toFormat(decimal, fmt)}
            </div>
          </div>
        )}

        {itemValues.discount > 0 && (
          <div className="new-item-summary__row d-flex justify-content-between align-items-center">
            <div className="new-item-summary__row-concept text-capitalize-first">
              {I18n.get('subtotal', 'sub total')}
            </div>
            <div className="new-item-summary__row-value text-capitalize-first">
              {itemValues.discSubtotal.toFormat(decimal, fmt)}
            </div>
          </div>
        )}

        {itemValues.taxesArray.map((tax, i) => !!tax && (
          <div className="new-item-summary__row d-flex justify-content-between align-items-center" key={i}>
            <div className="new-item-summary__row-concept text-capitalize-first">
              {getTaxSummary(tax)}
            </div>
            <div className="new-item-summary__row-value text-capitalize-first">
              {tax.value.toFormat(decimal, fmt)}
            </div>
          </div>
        ))}

        <div className="new-item-summary__row d-flex justify-content-between align-items-center">
          <div className="text-capitalize-first">
            {I18n.get('total', 'total')}
          </div>
          <div className="text-capitalize-first">
            {itemValues.total.toFormat(decimal, fmt)}
          </div>
        </div>

      </div>
    </div>
  )
}

Summary.propTypes = {
  values: PropTypes.object.isRequired
}

export default Summary;
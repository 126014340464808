import React from 'react';
import { I18n } from '@aws-amplify/core';
import { Field } from 'react-final-form';

import RadioButton from '../../svg/RadioButton'

const SelectType = ({ values: { type } }) => (
  <Field
    name="type"
  >
    {(fieldProps) => (
      <div className="d-flex justify-content-center mb-2">
        <button
          type="button"
          className={`btn btn-select-item-type text-capitalize-first w-100 mr-3
        ${type === 'product' ? 'active' : ''}`}
          onClick={() => fieldProps.input.onChange('product')}
        >
          {I18n.get('item', 'producto')}
          <RadioButton width="12" height="12" checked={type === 'product'} unfilled />
        </button>

        <button
          type="button"
          className={`btn btn-select-item-type text-capitalize-first w-100 mr-3
        ${type === 'service' ? 'active' : ''}`}
          onClick={() => fieldProps.input.onChange('service')}
        >
          {I18n.get('service', 'servicio')}
          <RadioButton width="12" height="12" checked={type === 'service'} unfilled />
        </button>

        <button
          type="button"
          className={`btn btn-select-item-type text-capitalize-first w-100
        ${type === 'kit' ? 'active' : ''}`}
          onClick={() => fieldProps.input.onChange('kit')}
        >
          {I18n.get('kit', 'combo')}
          <RadioButton width="12" height="12" checked={type === 'kit'} unfilled />
        </button>
      </div>
    )}
  </Field>
);

export default SelectType;

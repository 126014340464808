import React from 'react';
import PropTypes from 'prop-types';

const Header = ({
  title,
  subtitle,
  actions,
  info,
  error,
  titleClassName,
  subtitleClassName,
  extraClassName,
  sectionClassName,
}) => (
  <div className='w-100 d-flex flex-column mb-3'>
    <div className={`view-header ${extraClassName}`}>
      <section className={`${sectionClassName}`}>
        <h1
          className={`new-text-secondary font-weight-bold text-capitalize-first ${titleClassName}`}
        >
          {title}
        </h1>

        {!!subtitle && (
          <div
            className={`h4 w-100 text-muted text-capitalize-first ${subtitleClassName}`}
          >
            {subtitle}
          </div>
        )}

        {!!info && (
          <div className='text-capitalize-first text-warning'>{info}</div>
        )}

        {!!error && (
          <p className='h5 text-danger text-capitalize-first'>{error}</p>
        )}
      </section>

      <div className='view-header-actions'>{actions}</div>
    </div>
  </div>
);

Header.propTypes = {
  title: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  subtitle: PropTypes.object,
  extraClassName: PropTypes.string,
  actions: PropTypes.element,
  error: PropTypes.string,
  sectionClassName: PropTypes.string,
};

export default Header;

import platform from 'platform'

export const getShortcutKey = () => {
  if (platform.os.toString().match(/mac/gi) || platform.os.toString().match(/os x/gi)) {
    return "shift+option";
  }

  if (platform.name === 'Firefox') {
    return "ctrl+alt";
  }

  return "alt";
}

export const getSystemVersion = () => {
  if (platform.os.toString().match(/mac/gi) || platform.os.toString().match(/os x/gi)) {
    return "mac";
  }

  if (platform.name === 'Firefox') {
    return "firefox";
  }

  return "general";
}

export const keyMap = (country) => {
  const key = getShortcutKey();
  const version = getSystemVersion();

  const actions =  {
    GOTO_HOME: [key + "+m", key + "+M"],
    GOTO_PAYMENTS: version !== 'mac' ? [key + "+p", key + "+P"] : [key + "+l", key + "+L"],
    GOTO_SHIFTS: [key + "+s", key + "+S"],
    GOTO_REFUNDS: [key + "+r", key + "+R"],
    OPEN_SHORTCUT_HELP: [key + "+h", key + "+H"],
    OPEN_NEW_ITEM: [key + "+i", key + "+I"],
    OPEN_NEW_CLIENT: [key + "+c", key + "+C"],
    OPEN_PAYMENT_MODAL: [key + "+v", key + "+V"],
    FOCUS_NORMAL_SEARCH: [key + "+b", key + "+B"],
    FOCUS_BARCODE_SEARCH: [key + "+n", key + "+N"],
  };

  if (country === 'mexico') {
    delete actions.OPEN_NEW_CLIENT;
  }

  return actions;
}
import React from 'react';
import PropTypes from 'prop-types';
import { I18n } from '@aws-amplify/core';
import BigNumber from 'bignumber.js';

/**
 * Default Payments Component.
 *
 * @param {Object} props - Component props.
 * @param {BigNumber} props.tipAmount - Tip amount as BigNumber.
 * @param {Object} props.itemsValues - Calculated items values.
 * @param {string} props.align - Text alignment.
 * @param {string} props.country - Country code.
 * @returns {JSX.Element} - Rendered Default Payments Component.
 */
const PaymentsDefault = React.memo(
  ({ tipAmount, itemsValues, align, country }) => {
    const { subtotal, total, taxes, discount, discSubtotal, totalWhitoutTips } =
      itemsValues;
    const showDiscount = discount.gt(0);

    return (
      <div className={`w-100 py-2 d-flex flex-column border-bottom`}>
        <div className={`pb-2`}>
          {showDiscount && (
            <div className='container-text-flex'>
              <p className='strong'>{I18n.get('subtotal', 'subtotal')}:</p>
              <p className='pl-2'>
                {subtotal.toFormat(itemsValues.decimal, itemsValues.fmt)}
              </p>
            </div>
          )}

          {showDiscount && (
            <>
              <div className='container-text-flex'>
                <p
                  className={
                    country === 'republicaDominicana'
                      ? 'font-weight-bold'
                      : 'strong'
                  }
                >
                  {I18n.get('discount', 'descuento')}:
                </p>
                <p className='pl-2'>
                  {`-${discount.toFormat(itemsValues.decimal, itemsValues.fmt)}`}
                </p>
              </div>
              <div className='container-text-flex'>
                <p
                  className={
                    country === 'republicaDominicana'
                      ? 'font-weight-bold'
                      : 'strong'
                  }
                >
                  {I18n.get('subtotal', 'subtotal')}:
                </p>
                <p className='pl-2'>
                  {discSubtotal.toFormat(itemsValues.decimal, itemsValues.fmt)}
                </p>
              </div>
            </>
          )}

          <div className='d-flex align-items-end flex-column'>
            <p>
              <strong className='text-capitalize-first'>
                {I18n.get('subtotal', 'subtotal')}
              </strong>
              {subtotal.toFormat(itemsValues.decimal, itemsValues.fmt)}
            </p>
            {discount.gt(0) && (
              <>
                <p>
                  <strong className='text-capitalize-first'>
                    {I18n.get('discount', 'descuento')}
                  </strong>
                  {discount.toFormat(itemsValues.decimal, itemsValues.fmt)}
                </p>
                <p>
                  <strong className='text-capitalize-first'>
                    {I18n.get('subtotal', 'subtotal')}
                  </strong>
                  {discSubtotal.toFormat(itemsValues.decimal, itemsValues.fmt)}
                </p>
              </>
            )}
            {taxes.map((tax, index) => (
              <p key={index}>
                <strong>{tax.name}</strong>
                {tax.value.toFormat(itemsValues.decimal, itemsValues.fmt)}
              </p>
            ))}
            {tipAmount.isGreaterThan(0) && (
              <>
                <p>
                  <strong className='text-capitalize-first'>
                    {I18n.get('total', 'total')}
                  </strong>
                  {totalWhitoutTips.toFormat(
                    itemsValues.decimal,
                    itemsValues.fmt
                  )}
                </p>
                <p>
                  <strong className='text-capitalize-first'>
                    {I18n.get('tip', 'Propina')}
                  </strong>
                  {tipAmount.toFormat(itemsValues.decimal, itemsValues.fmt)}
                </p>
              </>
            )}

            <p>
              <strong className='text-capitalize-first font-weight-bold'>
                {tipAmount.isGreaterThan(0)
                  ? I18n.get('totalToRefund', 'total a devolver')
                  : I18n.get('total', 'total')}
              </strong>
              {total
                .plus(tipAmount)
                .toFormat(itemsValues.decimal, itemsValues.fmt)}
            </p>
          </div>
        </div>

        {itemsValues.totalLines && (
          <div className='text-left'>
            <p>
              <strong>{I18n.get('totalRows', 'total de líneas')}</strong>
              {itemsValues.itemsLength}
            </p>
            <p>
              <strong>{I18n.get('totalItems', 'total de productos')}</strong>
              {itemsValues.totalItems}
            </p>
          </div>
        )}
      </div>
    );
  }
);

PaymentsDefault.propTypes = {
  tipAmount: PropTypes.instanceOf(BigNumber).isRequired,
  itemsValues: PropTypes.shape({
    subtotal: PropTypes.instanceOf(BigNumber).isRequired,
    total: PropTypes.instanceOf(BigNumber).isRequired,
    taxes: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        value: PropTypes.instanceOf(BigNumber).isRequired,
      })
    ).isRequired,
    discount: PropTypes.instanceOf(BigNumber).isRequired,
    discSubtotal: PropTypes.instanceOf(BigNumber).isRequired,
    totalWhitoutTips: PropTypes.instanceOf(BigNumber).isRequired,
    decimal: PropTypes.number.isRequired,
    fmt: PropTypes.string.isRequired,
    totalLines: PropTypes.bool,
    itemsLength: PropTypes.number,
    totalItems: PropTypes.number,
  }).isRequired,
  align: PropTypes.string.isRequired,
  country: PropTypes.string.isRequired,
};

export default PaymentsDefault;

import React from "react";
import PropTypes from "prop-types";
import parse from 'html-react-parser';
import { I18n } from "@aws-amplify/core";

import { Tooltip, Icon } from "@alegradev/smile-ui-react";
import { IconLoader2 } from "@tabler/icons-react";

const Bottom = ({
  onClose,
  closeText,
  submitText,
  disabled,
  submitting,
  onConfirm,
  hideCancel,
  submitId,
  hideRequired = false,
  onResetForm,
  resetForm,
  saveText,
  disableCancel,
  tooltipMessage,
  tooltipActive,
}) => (
  <div
    className="new-inventory-adjustment-bottom"
  >
    {hideRequired ? (
      <div className="new-inventory-adjustment-bottom-required-message mb-3 mb-sm-0"></div>
    ) : (
      <div className="new-inventory-adjustment-bottom-required-message mb-3 mb-sm-0">
        {parse(I18n.get("fieldsMarkedRequired", "Los campos marcados con <span class='text-primary'>*</span> son obligatorios"))}
      </div>
    )}

    <div className="new-inventory-adjustment-bottom-buttons d-flex">
      {!hideCancel && (
        <button
          type="button"
          className="btn btn-cancel text-capitalize-first"
          onClick={() => onClose()}
          disabled={disableCancel}
        >
          {closeText || I18n.get("cancel", "cancelar")}
        </button>
      )}

      <Tooltip
        visible={tooltipActive}
        overlay={tooltipMessage}
        placement="top"
      >
        <button
          id={submitId}
          type={!!onConfirm ? "button" : "submit"}
          data-testid={`modal-${!!onConfirm ? "confirm" : "submit"}-btn`}
          className="btn btn-submit absolute-center text-capitalize-first"
          disabled={!!submitting || !!disabled}
          onClick={!!onConfirm ? () => onConfirm() : null}
        >
          {(submitting && !resetForm) ? <Icon icon={IconLoader2} animated extraClass=" icon-white" /> : submitText || I18n.get("save", "guardar")}
        </button>
      </Tooltip>
    </div>
  </div>
);

Bottom.propTypes = {
  closeText: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  submitText: PropTypes.string,
  disabled: PropTypes.bool,
  submitting: PropTypes.bool,
  onConfirm: PropTypes.func,
  hideCancel: PropTypes.bool,
  submitId: PropTypes.string,
  tooltipMessage: PropTypes.string,
  tooltipActive: PropTypes.bool,
};

export default Bottom;

const deliveryTerms = [
  {
    key: "CFR",
    value: "Costo y flete"
  },
  {
    key: "CIF",
    value: "Costo, flete y seguro"
  },
  {
    key: "CIP",
    value: "Transporte y seguro pagados hasta"
  },
  {
    key: "CPT",
    value: "Transporte pagado hasta"
  },
  {
    key: "DAP",
    value: "Entregado en un lugar"
  },
  {
    key: "DAT",
    value: "Entregado en terminal"
  },
  {
    key: "DDP",
    value: "Entregado con pago de derechos"
  },
  {
    key: "EXW",
    value: "En fábrica"
  },
  {
    key: "FAS",
    value: "Franco al costado del buque"
  },
  {
    key: "FCA",
    value: "Franco transportista"
  },
  {
    key: "FOB",
    value: "Franco a bordo"
  }
]

export default deliveryTerms
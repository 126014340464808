export function trimValues(obj) {
  let trimmedObj = {};
  for (let key in obj) {
    if (typeof obj[key] === 'string') {
      trimmedObj[key] = obj[key].trim();
    } else if (typeof obj[key] === 'object' && obj[key] !== null) {
      trimmedObj[key] = trimValues(obj[key]);
    } else {
      trimmedObj[key] = obj[key];
    }
  }
  return trimmedObj;
}

import { useHistory } from 'react-router-dom';
import { I18n } from '@aws-amplify/core';

import { useGlobalInvoiceDetail } from './context';
import { get } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { openModal } from '../../../reducers/modals';
import { deleteGlobalInvoiceMutation } from '../main/services/mutations';
import { createGlobalInvoiceMutation } from '../invoice/services/mutations';
import { APIGraphqlSelector } from '../../../selectors/app';
import { globalInvoiceConverterToData } from '../invoice/converters/globalInvoiceToApi';
import { getInvoiceNumerations } from '../../../selectors/numerations';
import { useEffect } from 'react';
import useElectronicPayrollStatus from '../../../hooks/useElectronicPayrollStatus/hook';
import {
  downloadFileFromApi,
  printPDFFromUrl,
} from '../../../utils/pdf';
import { PageHeading, toastHandler } from '@alegradev/smile-ui-react';
import alegraAPI from '../../../reducers/alegraAPI';
import {
  IconChecklist,
  IconFileTypePdf,
  IconPencil,
  IconPrinter,
  IconTrash,
} from '@tabler/icons-react';

function GlobalInvoiceDetailHeader() {
  const history = useHistory();
  const dispatch = useDispatch();
  const { isActive } = useElectronicPayrollStatus();
  const numerations = useSelector(getInvoiceNumerations);
  const APIGraphql = useSelector(APIGraphqlSelector);
  const {
    mutate: mutateCreation,
    isLoading,
    isSuccess,
  } = createGlobalInvoiceMutation();
  const { mutate } = deleteGlobalInvoiceMutation();
  const { data, refetch } = useGlobalInvoiceDetail();

  useEffect(() => {
    if (isSuccess) {
      refetch();
    }
  }, [isSuccess]);

  const handleEdit = () => {
    history.push(`/global-invoices/invoice/${data?.id}`);
  };

  const handleStamp = () => {
    const values = globalInvoiceConverterToData(data, numerations);

    mutateCreation({
      values: { ...values, isEditable: true },
      api: APIGraphql,
    });
  };

  const handleDelete = (id) => {
    mutate({ id, api: APIGraphql, dispatch });
  };

  const handlePrint = async () => {
    if (!get(data, 'id')) return null;
    const toastId = `downdload-file-${get(data, 'id')}`;
    toastHandler(
      {
        type: 'neutral',
        description: I18n.get('printingFile', 'Imprimiendo archivo...'),
        loader: true,
      },
      { toastId }
    );
    try {
      const response = await alegraAPI.get(
        `/global-invoices/${get(data, 'id')}/pdf`
      );
      toastHandler(
        {
          update: true,
          type: 'success',
          description: I18n.get('filePrinted', 'Archivo impreso'),
        },
        { toastId }
      );
      printPDFFromUrl(get(response, 'data.pdf'));
    } catch (error) {
      toastHandler(
        {
          update: true,
          type: 'error',
          description: I18n.get(
            'filePrimtError',
            'Error al imprimir el archivo'
          ),
        },
        { toastId }
      );
    }
  };

  const handleDownloadPDF = async () => {
    if (!get(data, 'id')) return null;
    const fileName = `Factura global ${get(data, 'numberTemplate.number')}.pdf`;

    try {
      downloadFileFromApi(
        `/global-invoices/${get(data, 'id')}/pdf`,
        fileName,
        'data.pdf'
      );
    } catch (_) {}
  };

  const detailActions = () => {
    const actions = [];

    if (
      ['draft', 'open'].includes(get(data, 'status')) &&
      !get(data, 'stamp.uuid', null)
    ) {
      if (isActive) {
        actions.push({
          text: I18n.get('stamp', 'Timbrar'),
          leftIcon: IconChecklist,
          loading: isLoading,
          action: () => handleStamp(),
        });
      }

      actions.push(
        {
          text: I18n.get('edit', 'Editar'),
          leftIcon: IconPencil,
          loading: isLoading,
          variant: isActive ? 'secondary' : 'primary',
          action: () => handleEdit(),
        },
        {
          text: I18n.get('delete', 'Eliminar'),
          variant: 'secondary',
          leftIcon: IconTrash,
          loading: isLoading,
          action: () =>
            dispatch(
              openModal({
                modal: 'confirmAction',
                params: {
                  onSuccess: () => handleDelete(data?.id),
                  title: I18n.get(
                    'cofirmDeleteGlobalInvoiceTitle',
                    '¿Deseas eliminar tu factura global?'
                  ),
                  description: I18n.get(
                    'cofirmDeleteGlobalInvoiceDescription',
                    'Al confirmar que deseas eliminar, tu <b>Factura global {{invoice}}</b> borrará de forma definitiva de tus documentos'
                  ).replace('{{invoice}}', get(data, 'numberTemplate.number')),
                  successButton: I18n.get(
                    'cofirmGlobalInvoiceDeletion',
                    'Sí, eliminar factura'
                  ),
                  cancelButton: I18n.get(
                    'cancelGlobalInvoiceDeletion',
                    'Conservar factura'
                  ),
                },
              })
            ),
        }
      );
    }

    if (!!get(data, 'stamp.uuid')) {
      actions.push(
        {
          text: I18n.get('print', 'Imprimir'),
          leftIcon: IconPrinter,
          action: () => handlePrint(),
        },
        {
          text: I18n.get('download PDF', 'Descargar PDF'),
          variant: 'secondary',
          leftIcon: IconFileTypePdf,
          action: () => handleDownloadPDF(),
        }
      );
    }

    return actions;
  };

  return (
    <PageHeading
      title={I18n.get('', 'Factura global #{{number}}').replace(
        '{{number}}',
        get(data, 'numberTemplate.number')
      )}
      actions={detailActions()}
      breadcrumb={[
        {
          title: I18n.get('globalInvoices', 'Facturas globales'),
          action: () => history.push('/global-invoices'),
        },
        {
          title: I18n.get('', 'Factura global #{{number}}').replace(
            '{{number}}',
            get(data, 'numberTemplate.number')
          ),
        },
      ]}
    />
  );
}

export default GlobalInvoiceDetailHeader;

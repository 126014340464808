import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Field } from 'react-final-form';
import { I18n } from '@aws-amplify/core';
import { capitalize, get } from 'lodash';

import {
  accountMexicoReceivableCategories,
  accountReceivableCategories,
} from '../../../../../selectors/categories';
import { country as countrySelector } from '../../../../../selectors/company';
import { renderField } from '../../../../../components/forms/fields/V2';
import { Grid, Space, Typography } from '@alegradev/smile-ui-react';
import { COUNTRIES } from '../../../../../utils/enums/countries';
import { fiscalResponsabilities } from '../../../../../components/countriesData/colombia/fiscalResponsabilities';

const Accounting = (props) => {
  const { Col, Row, Wrapper } = Grid;
  const accountReceivableOptions = useSelector(accountReceivableCategories);
  const accountMexicoReceivableOptions = useSelector(
    accountMexicoReceivableCategories
  );
  const country = useSelector(countrySelector);

  const thirdType = get(props, 'values.thirdType', []);

  const mexicoReceivableOptions = useMemo(() => {
    if (country === COUNTRIES.MEXICO) {
      if (thirdType === 'NATIONAL') {
        return accountMexicoReceivableOptions?.national;
      }

      return accountMexicoReceivableOptions?.foreign;
    }
  }, [thirdType, country]);

  const options = useMemo(() => {
    if (country === COUNTRIES.MEXICO) {
      return mexicoReceivableOptions?.map((option) => ({
        text: option.name,
        value: option?.id?.toString(),
      }));
    }

    return accountReceivableOptions?.map((option) => ({
      text: option.name,
      value: option?.id?.toString(),
    }));
  }, [country, mexicoReceivableOptions, accountReceivableOptions]);

  return (
    <Row spacing='lg'>
      <Col xs={12}>
        <Field
          name='accounting.accountReceivable'
          component={renderField}
          fieldType='select'
          label={capitalize(I18n.get('accountReceivable', 'cuenta por cobrar'))}
          placeholder={I18n.get('select', 'Seleccionar')}
          options={options}
        />
      </Col>
      {props.country === 'colombia' && props.isElectronic && (
        <Col xs={12}>
          <Typography
            type='label-2'
            text={I18n.get(
              'fiscalResponsabilities',
              'responsabilidades fiscales'
            )}
          />
          <Space height={10} />

          <Wrapper direction={{ lg: 'column' }}>
            {fiscalResponsabilities.map((responsability) => (
              <>
                <Field
                  key={responsability.id}
                  name={`fiscalResponsabilities.${responsability.id}`}
                  component={renderField}
                  fieldType='checkbox'
                  type='checkbox'
                  label={responsability.value}
                />
                <Space height={10} />
              </>
            ))}
          </Wrapper>
        </Col>
      )}
    </Row>
  );
};

export default Accounting;

export const DATE_PICKER_LOCALE_EN = {
  name: 'locale_en',
  months: [
    ['January', 'JAN'],
    ['February', 'FEB'],
    ['March', 'MAR'],
    ['April', 'APR'],
    ['May', 'MAY'],
    ['June', 'JUN'],
    ['July', 'JUL'],
    ['August', 'AUG'],
    ['September', 'SEP'],
    ['October', 'OCT'],
    ['November', 'NOV'],
    ['December', 'DEC'],
  ],
  weekDays: [
    ['Saturday', 'SAT'],
    ['Sunday', 'SUN'],
    ['Monday', 'MON'],
    ['Tuesday', 'TUE'],
    ['Wednesday', 'WED'],
    ['Thursday', 'THU'],
    ['Friday', 'FRI'],
  ],
  digits: ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'],
  meridiems: [
    ['AM', 'AM'],
    ['PM', 'PM'],
  ],
};

import React from 'react';
import PropTypes from 'prop-types';
import Logo from '../Logo';
import Company from '../Company';
import Client from '../Client';
import RefundInfo from '../RefundInfo';
import Items from '../ListItems';
import Payments from '../Payments';
import Notes from '../Notes';
import Invoice from '../Invoice';
import RefundType from '../RefundType';
import CustomPhrase from '../CustomPharese';
import LastRow from '../LastRow';
import Electronic from '../Electronic';

/**
 * Default PrintRefund Component.
 *
 * @param {Object} props - Component props.
 * @param {Object} props.refund - The refund object.
 * @param {Object} props.companySetting - Company settings.
 * @returns {JSX.Element} - Rendered component for Colombia.
 */
const PrintRefundDefault = ({
  refund,
  companySetting,
  imageOrQr,
  isElectronic,
  companyLogo,
  items,
  invoices,
  invoiceType,
  customPhrase,
  note,
  tip,
}) => (
  <div
    id="print-invoice"
    className={`print-${companySetting.format} d-flex flex-column overflow-hidden`}
    style={{
      paddingLeft: `${companySetting.leftMargin}mm`,
      paddingRight: `${companySetting.rightMargin}mm`,
    }}
  >
    <Logo companyLogo={companyLogo} setting={companySetting} />
    {isElectronic && <Electronic refund={refund} setting={companySetting} />}
    <Company companyLogo={companyLogo} imageOrQr={imageOrQr} setting={companySetting} />
    <Client refund={refund} setting={companySetting} />
    <RefundInfo refund={refund} setting={companySetting} />
    <Items items={items} setting={companySetting} />
    <Payments tip={tip} items={items} setting={companySetting} />
    <Notes notes={note} />
    {invoices.map((invoice) => (
      <Invoice key={invoice.id} invoice={invoice} invoiceType={invoiceType} isElectronic={isElectronic} />
    ))}
    <RefundType refund={refund} isElectronic={isElectronic} />
    <CustomPhrase customPhrase={customPhrase} />
    <LastRow />
  </div>
);

PrintRefundDefault.propTypes = {
  refund: PropTypes.object.isRequired,
  companySetting: PropTypes.object.isRequired,
  imageOrQr: PropTypes.string,
  isElectronic: PropTypes.bool.isRequired,
  companyLogo: PropTypes.string,
  items: PropTypes.array.isRequired,
  invoices: PropTypes.array.isRequired,
  invoiceType: PropTypes.string.isRequired,
  customPhrase: PropTypes.string.isRequired,
  note: PropTypes.string.isRequired,
  tip: PropTypes.number.isRequired,
};

export default React.memo(PrintRefundDefault);

import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form } from 'react-final-form';
import { I18n } from '@aws-amplify/core';
import { get, some } from 'lodash';

import { toast } from '../../../utils';
import { formError, handleError } from '../../../utils/errors';
import { closeModal, openModal } from '../../../reducers/modals';
import { validate, transform } from '../../forms/priceList/utils';
import Modal from '../../common/Modal';
import Body from '../../forms/priceList/PriceList';
import Bottom from '../common/Bottom';
import Notification from '../common/Notification';
import { endAction, startAction } from '../../../reducers/monitoring';
import { calculateActionTimeSelector } from '../../../selectors/monitoring';
import { useCreatePriceList, useUpdatePriceListFromAlegra } from '../../inventory/PriceLists/mutations';

const PriceList = ({ onCreated }) => {
  const dispatch = useDispatch();
  const isOpen = useSelector((state) =>
    get(state, 'modals.priceList.isOpen', false)
  );
  const onRefresh = useSelector((state) =>
    get(state, 'modals.priceList.params.onRefresh', false)
  );
  const priceList = useSelector((state) =>
    get(state, 'modals.priceList.params.priceList', false)
  );
  const calculateActionTime = useSelector(calculateActionTimeSelector);
  const [resetForm, setResetForm] = useState(false);

  const { mutate: updatePriceListMutation, isLoading: loadingUpdate } = useUpdatePriceListFromAlegra();
  const { mutate: createPriceListMutation, isLoading: loadingCreate } = useCreatePriceList();

  const close = () => {
    dispatch(closeModal({ modal: 'priceList' }));
  };

  const open = () => {
    dispatch(
      openModal({
        modal: 'priceList',
        params: {
          onRefresh,
        },
      })
    );
  };

  const submit = async (values, index) => {
    dispatch(startAction({action: 'createPriceList'}));
    const transformedValues = transform(values, priceList);
    
    if (!!priceList) {
      updatePriceListMutation(
        { priceList, transformedValues },
        {
          onSuccess: () => {
            close();
            toast.success({
              title: I18n.get(
                'priceListUpdatedSuccessfully',
                'Lista de precio actualizada con éxito'
              ),
            });
          },
          onError: (error) => {
            close();
            toast.error({
              title: I18n.get(
                'updatePriceListError',
                'Hubo un error al cambiar los datos de la lista de precios.'
              ),
              subtitle: handleError(error),
            });
            return formError(
              error,
              I18n.get(
                'updatePriceListError',
                'Hubo un error al cambiar los datos de la lista de precios.'
              )
            )
          }
        }
      );
    } else {
      createPriceListMutation(
        { transformedValues }, 
        {
          onSuccess: (data) => {
            close();
            if (!!onCreated) onCreated(data?.data);
          }
        }
      );
    }

    if (resetForm) {
      open();
      setResetForm(false);
    }
  };
  return (
    <Modal
      title={
        <div className='d-flex justify-content-between align-items-center w-100'>
          <div className='modal__header-title text-capitalize-first h2 text-primary text-truncate'>
            {!!priceList
              ? priceList.name
              : I18n.get('newPriceListModalTitle', 'Nueva lista de precio')}
          </div>
        </div>
      }
      isOpen={isOpen}
      onRequestClose={() => close()}
      className='modal__medium-size'
    >
      <Form
        onSubmit={submit}
        validate={(values) => validate(values)}
        initialValues={!!priceList ? priceList : { type: 'percentage' }}
        keepDirtyOnReinitialize
      >
        {({ handleSubmit, submitting, values, submitError, pristine, form }) => {
          return (
            <form noValidate onSubmit={handleSubmit}>
              <div className='px-md-5 py-md-3'>
                {!priceList && (
                  <p className='h5 text-subtitle-muted text-capitalize-first'>
                    {I18n.get(
                      'newPriceListModalSubtitle',
                      'Crea una lista para utilizar un precio diferente en tus productos o servicios.'
                    )}
                  </p>
                )}
                <Body values={values} form={form} />
              </div>
              {!!submitError && !pristine && (
                <Notification isOpen={true} text={submitError} type='error' />
              )}

              <Bottom
                submitting={some(values?.decimalsError, (value) => value) || submitting || loadingCreate || loadingUpdate}
                onClose={() => close()}
                onResetForm={setResetForm}
                resetForm={resetForm}
                hideRequired={true}
                submitText={
                  !!priceList
                    ? I18n.get('updatePriceList', 'Actualizar lista')
                    : I18n.get('createPriceList', 'Crear lista')
                }
                saveText={
                  !!priceList
                    ? I18n.get('updateAndCreateNew', 'Actualizar y crear nueva')
                    : I18n.get('saveAndCreateNew', 'Guardar y crea nueva')
                }
                hideCancel
              />
            </form>
          );
        }}
      </Form>
    </Modal>
  );
};

export default PriceList;

import { Avatar, Grid, Space, Typography } from '@alegradev/smile-ui-react';
import { capitalize, get } from 'lodash';

function CreatedClient({ client }) {
  const { Wrapper } = Grid;

  return (
    <>
      <Space size={20} />
      <Wrapper gap={30} align={{ lg: 'center' }}>
        <Avatar size={120} variant='secondary' text={client?.name} />
        <Wrapper direction={{ lg: 'column' }}>
          <Typography
            type='body-2-bold'
            text={capitalize(client?.name) || ''}
          />
          <Typography
            type='body-3-regular'
            text={`${get(client, 'identificationObject.type', '')}. ${get(client, 'identificationObject.number', '')}`}
            variant='tertiary'
          />
          {get(client, 'email') && (
            <Typography
              type='body-3-regular'
              text={get(client, 'email')}
              variant='tertiary'
            />
          )}
          {get(client, 'mobile') && (
            <Typography
              type='body-3-regular'
              text={get(client, 'mobile')}
              variant='tertiary'
            />
          )}
        </Wrapper>
      </Wrapper>
      <Space size={20} />
    </>
  );
}

export default CreatedClient;

import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Cookie from 'js-cookie';
import { I18n } from '@aws-amplify/core';
import ShapePlus from '../../assets/images/illustrations/graphic-shape-plus.svg';
import ShapeCircle from '../../assets/images/illustrations/graphic-shape-circle-middle.svg';
import LogoPos from '../../assets/images/illustrations/logo-pos.svg';
import AlegraDesktop from '../../assets/images/illustrations/alegra-desktop.png';
import AlegraWeb from '../../assets/images/illustrations/alegra-web.png';
import { Typography, Button } from '@alegradev/smile-ui-react';

function GoToApp() {
  const history = useHistory();

  useEffect(() => {
    goToDesktopApp();
  }, [history]);

  const goToDesktopApp = () => {
    const protocolUrl = `alegra-pos://open?token=${Cookie.get(process.env.REACT_APP_ALEGRA_COOKIE)}`;
    window.open(protocolUrl, '_self');
  };

  return (
    <>
      <div className='content'>
        <div className='shape-circle'>
          <img src={ShapeCircle} />
        </div>
        <div className='logo-pos'>
          <img src={LogoPos} />
        </div>
        <Typography
          type='heading-2'
          variant='primary'
          text={I18n.get('goAppDesktopTitle', 'Ingresa a Alegra POS')}
        />
        <Typography
          type='body-1'
          variant='primary'
          text={I18n.get(
            'goAppDesktopDescription',
            'Elige cómo deseas abrir tu punto de venta.'
          )}
        />
        <div className='options'>
          <div className='option-card'>
            <div className='option-card_content-image'>
              <img src={AlegraDesktop} />
            </div>
            <div className='option-card_actions'>
              <Button
                text={I18n.get('goAppDesktopCtaOne', 'Abrir app de escritorio')}
                rightIcon='arrow-right'
                onClick={() => goToDesktopApp()}
              />
            </div>
          </div>
          <div className='option-card'>
            <div className='option-card_content-image'>
              <img src={AlegraWeb} />
            </div>
            <div className='option-card_actions'>
              <Button
                text={I18n.get('goAppDesktopCtaTwo', 'Seguir en el navegador')}
                rightIcon='arrow-right'
                onClick={() => history.replace('/')}
              />
            </div>
          </div>
        </div>
        <div className='shape-plus'>
          <img src={ShapePlus} />
        </div>
      </div>
    </>
  );
}

export default GoToApp;

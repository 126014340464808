export const calculateInvoiceReceived = (values) => {
  const validPaymentMethods = ['cash', 'debit', 'credit', 'transfer'];

  const entries = validPaymentMethods
    .filter((key) => key in values && values[key] != null)
    .map((key) => +values[key] || 0);

  const total = entries.reduce((sum, value) => sum + value, 0);

  return entries.length === 0 ? null : total;
};

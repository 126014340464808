import { Switch, Route } from 'react-router-dom';

import { Main } from './main';
import { ContactDetail } from './detail';
import { Contact } from './contact';

function ContactsRouter() {
  return (
    <Switch>
      <Route path='/contacts/contact/:id/detail' component={ContactDetail} />
      <Route path='/contacts/contact/:id?' component={Contact} />
      <Route path='/contacts' component={Main} />
    </Switch>
  );
}

export default ContactsRouter;

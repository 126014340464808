import React from 'react'
import { I18n } from '@aws-amplify/core';
import { useSelector } from 'react-redux'
import { get } from 'lodash'

import { currency } from '../../../../selectors/activeRefund';
import { useFormat } from '../../../../hooks/useFormat';

const Total = ({ step, total, onBack }) => {
  const selectedCurrency = useSelector(currency)
  const { decimal, fmt } = useFormat(selectedCurrency);

  return (
    <div className="d-flex flex-column justify-content-center align-items-center p-3 position-relative">
      <div className="d-flex align-items-center">
        <p className="h3 text-muted text-uppercase mr-2">
          {I18n.get('total', 'total')}
        </p>
        <h1>
          {total.toFormat(decimal, fmt)}
        </h1>
      </div>

      {step > 1 && (
        <>
          <button
            type="button"
            className="btn button-transparent position-absolute modal__refunds-btn-change d-none d-sm-block"
            onClick={() => onBack()}
          >
            {I18n.get('changeMethod', 'cambiar método')}
          </button>

          <button
            type="button"
            className="btn button-transparent modal__refunds-btn-change d-sm-none"
            onClick={() => onBack()}
          >
            {I18n.get('changeMethod', 'cambiar método')}
          </button>
        </>
      )}
    </div>
  )
}

export default Total;
import { useDispatch, useSelector } from 'react-redux';
import { get } from 'lodash';
import { graphqlOperation } from 'aws-amplify';
import { I18n } from '@aws-amplify/core';

import Modal from '../../common/Modal';
import {
  companySelector,
  country as countrySelector,
  firstInvoiceCreatedSelector,
  membershipSelector,
  notificationsSelector,
} from '../../../selectors/company';
import { closeModal, openModal } from '../../../reducers/modals';
import Typography from '../../common/Typography';
import Button from '../../common/Button';
import PosDocument from '../../svg/illustrations/PosDocument';
import { useCallback, useEffect, useMemo } from 'react';
import { COUNTRIES } from '../../../utils/enums/countries';
import * as mutations from '../../../graphql/mutations';
import { setUser } from '../../../reducers/auth';
import { APIGraphqlSelector } from '../../../selectors/app';
import { notificationsTransformer } from '../../notifications/utils';
import { useHistory } from 'react-router-dom';
import { isAfter, isBefore } from 'date-fns';
import { isElectronicPOSDocumentAvailableSelector } from '../../../selectors/numerations';

function ElectronicPOSDocument() {
  const dispatch = useDispatch();
  const notifications = useSelector(notificationsSelector);
  const { registryDate } = useSelector(companySelector);
  const country = useSelector(countrySelector);
  const APIGraphql = useSelector(APIGraphqlSelector);
  const membership = useSelector(membershipSelector);
  const history = useHistory();
  const COOKIE_NAME = `pos-electronic-notification-activation-detail-${country}`;
  const isPosElectronicActive = useSelector(
    isElectronicPOSDocumentAvailableSelector
  );
  const isFirstInvoice = useSelector(firstInvoiceCreatedSelector);
  // const isOpen = useSelector((state) =>
  //   get(state, 'modals.electronicPosDocument.isOpen', false)
  // );
  const isOpen = false;
  const isTipOpen = useSelector((state) =>
    get(state, 'modals.tipsModalNotification.isOpen', false)
  );
  const isNewInvoiceOpen = useSelector((state) =>
    get(state, 'modals.invoice.isOpen', false)
  );
  const isInvoiceSavedOpen = useSelector((state) =>
    get(state, 'modals.invoiceSaved.isOpen', false)
  );
  const isCompanyDataUpdateOpen = useSelector((state) =>
    get(state, 'modals.companyDataUpdate.isOpen', false)
  );

  const availableNotification = useCallback(() => {
    const membershipPlan = get(membership, 'plan.keyword', '');
    if (!isFirstInvoice) return false;
    if (notifications?.find((notification) => notification[COOKIE_NAME]))
      return false;
    if (country !== COUNTRIES.COLOMBIA) return false;

    if (isBefore(new Date(registryDate), new Date('2024-05-01'))) {
      if (
        !membershipPlan ||
        membershipPlan === '' ||
        membershipPlan.includes('readonly')
      )
        return false;
    }

    return true;
  }, [registryDate, country, notifications, membership, isFirstInvoice]);

  const closePosElectronicNotification = async () => {
    if (!availableNotification()) return;

    try {
      const response = await APIGraphql(
        graphqlOperation(
          mutations.updateUser,
          notificationsTransformer(COOKIE_NAME)
        )
      );
      if (!!get(response, 'data.updateUser.id', null)) {
        dispatch(setUser(get(response, 'data.updateUser')));
      }
    } catch (err) {}
  };

  useEffect(() => {
    if (isInvoiceSavedOpen || isNewInvoiceOpen || isCompanyDataUpdateOpen)
      return;
    if (isPosElectronicActive) return;
    if (!availableNotification()) return;
    dispatch(closeModal({ modal: 'tipsModalNotification' }));
    dispatch(openModal({ modal: 'electronicPosDocument' }));
    closePosElectronicNotification();
  }, [
    membership,
    isFirstInvoice,
    isTipOpen,
    isInvoiceSavedOpen,
    isNewInvoiceOpen,
    isCompanyDataUpdateOpen,
  ]);

  const modalText = useMemo(() => {
    if (isAfter(new Date(registryDate), new Date('2024-05-01'))) {
      return {
        title: I18n.get('', '¡Emite tus documentos electrónicos! ⚡'),
        description: I18n.get(
          '',
          'Puedes habilitarte en minutos para emitir documentos POS o facturas electrónicas en tu negocio.'
        ),
        buttonCloseText: I18n.get('omit', 'Omitir'),
        buttonActionText: I18n.get('', 'Conocer cómo funcionan'),
        action: () => history.push('electronic-documents'),
      };
    }

    return {
      title: I18n.get('', '¡Llegó el Documento POS electrónico! ⚡'),
      description: I18n.get(
        '',
        'Ya puedes emitir facturas electrónicas y documentos POS en tu negocio. ¡Tú decides cuál usar!'
      ),
      buttonCloseText: I18n.get('omit', 'Omitir'),
      buttonActionText: I18n.get('', 'Conocer cómo funcionan'),
      action: () => history.push('electronic-documents'),
    };
  }, [registryDate]);

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={() =>
        dispatch(closeModal({ modal: 'electronicPosDocument' }))
      }
      includeHeader={false}
      className='modal__small-size modal__mobile-bottom electronic-pos-modal'
    >
      <div className='p-4 electronic-pos-modal-body'>
        <div className='electronic-pos-modal-body-icon'>
          <PosDocument />
        </div>
        <div className='electronic-pos-modal-body-info'>
          <Typography
            type='body-2-bold'
            text={modalText.title}
            variant='primary'
          />
          <Typography
            type='body-3-regular'
            text={modalText.description}
            variant='tertiary'
          />
        </div>
        <div className='electronic-pos-modal-body-actions'>
          <Button
            variant='ghost'
            onClick={() => [
              dispatch(closeModal({ modal: 'electronicPosDocument' })),
            ]}
          >
            <Typography
              type='label-1'
              variant='secondary'
              text={modalText.buttonCloseText}
            />
          </Button>
          <Button
            onClick={() => [
              dispatch(closeModal({ modal: 'electronicPosDocument' })),
              modalText.action(),
            ]}
          >
            <Typography type='label-1' text={modalText.buttonActionText} />
          </Button>
        </div>
      </div>
    </Modal>
  );
}

export default ElectronicPOSDocument;

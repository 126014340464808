import { useMemo, useState } from 'react';
import { get } from 'lodash';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Table as STable, useModal } from '@alegradev/smile-ui-react';

import { contactMainColumns } from './columns';
import { empty, noCreatedContacts } from './empty';
import { filters } from './filters';
import { noResults } from './noResults';
import { actions } from './actions';
import { country as countrySelector } from '../../../../selectors/company';
import { useContactsQuery } from '../../services/hooks';
import {
  useChangeContactStatusMutation,
  useDeleteContactMutation,
} from '../../services/mutations';
import ContactDetailBodyInformation from '../../detail/body/BodyInformation';
import { permissionsSelector } from '../../../../selectors/auth';
import { useQueryClient } from '@tanstack/react-query';
import { contactsKeys } from '../../services/utils';

function Table() {
  const history = useHistory();
  const country = useSelector(countrySelector);
  const columns = useMemo(() => contactMainColumns, []);
  const [metadata, setMetadata] = useState({});
  const { openModal, closeModal } = useModal();

  const permissions = useSelector(permissionsSelector);

  const queryClient = useQueryClient();

  const { isFetching, isLoading, data } = useContactsQuery(metadata);
  const { mutate: handleDeleteContact } = useDeleteContactMutation();
  const { mutate: handleChangeContactStatus } =
    useChangeContactStatusMutation();
  const items = get(data, 'data', []);
  const totalItems = get(data, 'metadata.total', 0);

  const handleChangeTableData = (tableState) => {
    if (tableState?.filters !== metadata.filters) {
      queryClient
        .getQueryCache()
        .findAll()
        .forEach((query) => {
          const key = query.queryKey;

          if (key[0] === contactsKeys.all && key[1]?.filters?.search) {
            queryClient.removeQueries({ queryKey: key });
          }

          if (key[0] === contactsKeys.all && key[1]?.filters?.status) {
            queryClient.removeQueries({ queryKey: key });
          }
        });
    }
    setMetadata(tableState);
  };

  const emptyComponent = useMemo(() => {
    if (
      items.length === 0 &&
      !metadata.filters?.search &&
      !metadata.filters?.status
    )
      return noCreatedContacts(history);
    return empty;
  }, [items, metadata]);

  return (
    <STable
      onChange={handleChangeTableData}
      totalItems={totalItems}
      data={items?.filter((item) => item)}
      columns={columns}
      emptyComponent={emptyComponent}
      noResultsComponent={noResults}
      loading={isLoading || isFetching}
      filters={filters}
      actions={(row) =>
        actions({
          row,
          country,
          history,
          openModal,
          closeModal,
          handleDeleteContact,
          handleChangeContactStatus,
          permissions,
        })
      }
      partibleComponent={(row) => (
        <ContactDetailBodyInformation contact={row} partible />
      )}
      onRowClick={(row) => history.push(`/contacts/contact/${row.id}/detail`)}
      partible
    />
  );
}

export default Table;

import { I18n } from 'aws-amplify';
import { useDeviceType } from '../../../../hooks/useDeviceType';
import Switch from '../../../common/Switch';
import Typography from '../../../common/Typography';
import { togglePaymentMethodMutation } from '../services/mutations';
import { useEffect, useState } from 'react';
import { APIGraphqlSelector } from '../../../../selectors/app';
import { useSelector } from 'react-redux';
import { Icon, useModal } from '@alegradev/smile-ui-react';
import { get } from 'lodash';
import { getPaymentMethodIcon, getPaymentMethodName } from '../utils';
import PaymentMethodDetail from '../../../modals/paymentMethods/PaymentMethodDetail';
import Nequi from '../../../svg/paymentMethods/Nequi';
import Wompi from '../../../../assets/images/wompi.png';
import Bancolombia from '../../../../assets/images/bancolombia-logo.png';
import { IconLoader2 } from '@tabler/icons-react';

const PaymentMethod = ({
  id,
  method,
  instructions,
  status,
  logo,
  qr,
  main = false,
}) => {
  const APIGraphql = useSelector(APIGraphqlSelector);
  const [isChecked, setIsChecked] = useState(false);
  const deviceType = useDeviceType();
  const {
    mutate: mutateToggle,
    isLoading,
    isSuccess,
  } = togglePaymentMethodMutation();

  const { openModal, closeModal } = useModal();

  useEffect(() => {
    setIsChecked(status === 'active' ? true : false);
  }, [status]);

  const handleToggle = async (value) => {
    await mutateToggle({
      values: {
        id,
        status: value,
      },
      api: APIGraphql,
    });
  };

  const getPaymentMethodImage = (method) => {
    if (method === 'Nequi') {
      return <Nequi width={48} height={48} />;
    }
    if (method === 'QR Bancolombia') {
      return (
        <div className='d-flex flex-column'>
          <img
            style={{ width: '4rem', height: '2rem' }}
            src={Wompi}
            alt='wompi'
          />
          <img
            style={{ width: '3rem', height: '2rem' }}
            src={Bancolombia}
            alt='bancolombia'
          />
        </div>
      );
    }

    if (method === 'wompiNequi') {
      return (
        <div className='d-flex justify-content-center flex-column'>
          <img
            style={{ width: '4rem', height: '2rem' }}
            src={Wompi}
            alt='wompi'
          />
          <Nequi height={'2.5rem'} />
        </div>
      );
    }
    return (
      <Icon
        icon={getPaymentMethodIcon(method)}
        width='48'
        height='48'
        color='#94A3B8'
      />
    );
  };

  return (
    <div
      key={id}
      id={id}
      className={
        deviceType === 'mobile'
          ? 'payment-method__card_mobile'
          : 'payment-method__card'
      }
    >
      <div
        className={
          deviceType !== 'mobile' &&
          'd-flex flex-column p-4 h-100 justify-content-between'
        }
      >
        <div
          className={`d-flex ${deviceType === 'mobile' && 'reverse_mobile'} ${deviceType !== 'mobile' && 'flex-column'} gap-3`}
          onClick={() => {
            if (
              method === 'cash' ||
              method === 'debit' ||
              method === 'credit' ||
              method === 'transfer' ||
              method === 'combined' ||
              method === 'Nequi' ||
              method === 'QR Bancolombia' ||
              method === 'wompiNequi'
            ) {
              return null;
            }
            openModal({
              component: PaymentMethodDetail,
              title: I18n.get('paymentMethod', 'Metodo de pago'),
              props: {
                id,
                name: method,
                status,
                logo,
                qr,
                instructions,
              },
              name: 'PaymentMethodDetail',
              size: 'large',
              secondaryAction: {
                text: I18n.get(
                  'backToPaymentMethods',
                  'Volver a métodos de pago'
                ),
                onClick: () => closeModal('PaymentMethodDetail'),
              },
            });
          }}
        >
          <Typography type='body-3-bold' text={getPaymentMethodName(method)} />
          <div
            className={
              deviceType !== 'mobile' && 'd-flex justify-content-center pt-2'
            }
          >
            {get(logo, 'url', null) ? (
              <div
                className={deviceType !== 'mobile' && 'payment-method__logo'}
              >
                <img
                  src={get(logo, 'url', null)}
                  height={64}
                  width={deviceType === 'mobile' ? 48 : 64}
                />
              </div>
            ) : (
              <div
                className={
                  deviceType === 'mobile'
                    ? 'payment-method__icon_mobile'
                    : 'payment-method__icon'
                }
              >
                {getPaymentMethodImage(method)}
              </div>
            )}
          </div>
        </div>
        <div
          className={`payment-method__actions d-flex justify-content-between ${deviceType === 'mobile' && 'pt-3 pl-3 pr-3'}`}
        >
          <Typography
            type='body-3-regular'
            text={
              status === 'active'
                ? I18n.get('active', 'Activo')
                : I18n.get('paymentMethod.inactive', 'Inactivo')
            }
          />
          {isLoading ? (
            <Icon icon={IconLoader2} animated />
          ) : (
            !main && (
              <Switch
                active={isChecked}
                disabled={
                  method === 'cash' ||
                  method === 'credit' ||
                  method === 'transfer'
                }
                large
                onChange={(e) => {
                  handleToggle(e);
                }}
              />
            )
          )}
        </div>
      </div>
    </div>
  );
};

export default PaymentMethod;

import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Form } from 'react-final-form';
import { I18n } from '@aws-amplify/core';
import { get } from 'lodash'
import { BigNumber } from 'bignumber.js'

import { formError } from '../../../utils/errors';
import { checkStationValues } from '../../../reducers/app';
import { reset, changeInvoice } from '../../../reducers/editInvoice';
import { closeModal } from '../../../reducers/modals';
import {
  stationDebitBank,
  stationCreditBank,
  stationTransferBank,
  stationInvoiceNumeration,
  stationSeller,
  electronicInvoicing,
} from '../../../selectors/app';
import { itemsCount as itemsCountSelector, total as totalSelector } from '../../../selectors/editInvoice';
import { country as countrySelector } from '../../../selectors/company';
import { transform, validate } from '../../forms/invoice/utils'
import Modal from '../../common/Modal';
import Notification from '../common/Notification';
import Bottom from '../common/WizardBottom';
import ItemsClient from './WizardSteps/ItemsClient';
import Payments from './WizardSteps/Payments';
import { useDecimalsVersionsGroup } from '../../../hooks/useDecimalsVersionsGroup';
import { COUNTRIES } from '../../../utils/enums/countries';
import NewPayments from './WizardSteps/NewPayments';
import { allPaymentMethods } from '../../../selectors/paymentMethods';
import { showNewInvoiceModal } from '../newInvoice/utils';
import { paymentMethod } from '../../../selectors/activeInvoice';
import { setIsPaymentMethodSelected, setPaymentMethod } from '../../../reducers/activeInvoice';

const EditInvoice = () => {
  const [paymentsValues, setPaymentsValues] = useState({
    cash: null,
    debit: null,
    credit: null,
    transfer: null,
  });
  const [initialValues, setInitialValues] = useState({});
  const isOpen = useSelector(state => get(state, 'modals.editInvoice.isOpen', false));
  const invoice = useSelector(state => get(state, 'editInvoice', false));

  const itemsCount = useSelector(itemsCountSelector);
  const total = useSelector(totalSelector)

  const debitBank = useSelector(stationDebitBank)
  const creditBank = useSelector(stationCreditBank)
  const transferBank = useSelector(stationTransferBank)
  const numeration = useSelector(stationInvoiceNumeration)
  const seller = useSelector(stationSeller)
  const paymentMethods = useSelector(allPaymentMethods)

  const country = useSelector(countrySelector);
  const isElectronic = useSelector(electronicInvoicing(numeration));
  const dispatch = useDispatch();
  const [step, setStep] = useState(0);
  const [isOpenSettings, setIsOpenSettings] = useState(false);
  const [isOpenEditItem, setIsOpenEditItem] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const { isDecimalActive } = useDecimalsVersionsGroup();
  const paymentMethodSelected = useSelector(paymentMethod);

  const getMethodName = (method) => {
    if (!method) {
      return null;
    }
    if (
      method !== 'cash' &&
      method !== 'debit' &&
      method !== 'credit' &&
      method !== 'transfer' &&
      method !== 'combined'
    ) {
      return 'transfer';
    }
    return method;
  };

  useEffect(() => {
    if (!isOpen) {
      setStep(0);
      setIsOpenSettings(false);
      setIsOpenEditItem(false);
      dispatch(reset())
      if (showNewInvoiceModal(country)) {
        dispatch(setIsPaymentMethodSelected(false));
        dispatch(setPaymentMethod(null));
      }
    }
  }, [isOpen, dispatch])

  useEffect(() => {
    setInitialValues({
      debitBank,
      creditBank,
      transferBank,
      numeration,
      seller,
    });
    if (showNewInvoiceModal(country)) {
      setInitialValues({
        ...initialValues,
        ...paymentsValues,
        method: getMethodName(paymentMethodSelected),
        paymentMethodSelected,
      });
    }
  }, [
    debitBank,
    creditBank,
    transferBank,
    numeration,
    seller,
    paymentsValues,
    paymentMethodSelected,
  ]);

  if (!invoice) return null;

  const previuosStep = (form) => {
    if (step > 1 && form)
      form.reset()
    setStep(Math.max(step - 1, 0))
  }

  const nextStep = () => setStep(step + 1)

  const getIdPaymentMethod = (values) => {
    const method = get(values, 'method', null);
    const paymentMethod = paymentMethods.find((pm) => pm?.name === method);
    return paymentMethod ? paymentMethod?.idLocal : null;
  };

  const submit = async values => {
    try {
      const canContinue = await dispatch(checkStationValues({ type: 'invoice', values }))
      if (!canContinue)
        return;

      const transformedValues = transform(values, country, getIdPaymentMethod(values))

      await dispatch(changeInvoice(transformedValues))
      dispatch(closeModal({ modal: 'editInvoice' }))
    } catch (error) {
      return formError(error, I18n.get('changeInvoiceError', 'hubo un error al tratar de modificar la factura'))
    }
  }

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={() => dispatch(closeModal({ modal: 'editInvoice' }))}
      title={I18n.get('editInvoice', 'editar factura')}
      className="modal__invoice modal__refunds"
    >
      {!!step
        ? (
          <Form
            onSubmit={submit}
            validate={values => validate({...values, isDecimalActive}, { total })}
            initialValues={initialValues}
            keepDirtyOnReinitialize
          >
            {({ handleSubmit, form, values, error, pristine, submitting, submitError }) => (
              <form
                noValidate
                className="form-body"
                onSubmit={handleSubmit}
              >
                {showNewInvoiceModal(country) ? (
                <NewPayments
                  form={form}
                  values={values}
                  country={country}
                  totalPrice={new BigNumber(total)}
                  paymentsValues={paymentsValues}
                  setPaymentsValues={setPaymentsValues}
                />
                ) : (
                  <Payments
                    form={form}
                    values={values}
                    country={country}
                    totalPrice={new BigNumber(total)}
                  />
                )}
                

                {(!!error && !!submitError) && (
                  <Notification isOpen={true} text={error} type="error" />
                )}

                <Bottom
                  onPrevious={!values.method ? () => previuosStep(form) : null}
                  nextDisabled={!values.method || submitting || pristine}
                  nextText={country === 'colombia' && !!isElectronic
                    ? I18n.get('emit', 'Emitir')
                    : I18n.get('checkIn', 'facturar')
                  }
                  nextAsSubmit
                />
              </form>
            )}
          </Form>
        )
        : (
          <div className="form-body">
            <div className="d-flex flex-column p-3">
              <ItemsClient
                isOpenSettings={isOpenSettings}
                isOpenEditItem={isOpenEditItem}
                selectedIndex={selectedIndex}
                setIsOpenSettings={setIsOpenSettings}
                setIsOpenEditItem={setIsOpenEditItem}
                setSelectedIndex={setSelectedIndex}
              />
            </div>

            <Bottom
              nextDisabled={!itemsCount}
              onNext={nextStep}
            />
          </div>
        )}
    </Modal>
  )
}

export default EditInvoice;
// eslint-disable-next-line
export default ({ id, className, checked, width, height, unfilled = false }) => (
  <svg
    id={id}
    className={`icon-primary ${className}`}
    width={width || "24"}
    height={height || "24"}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="0.5" y="0.5" width="15" height="15" rx="7.5" fill="white" stroke="#CCCCCC" />
    <rect x="0.5" y="0.5" width="15" height="15" rx="7.5" fill="white" stroke={checked ? "#00B19D" : "#CCCCCC"} />

    <rect x="3" y="3" width="10" height="10" rx="5" fill={checked ? "#00B19D" : unfilled ? "#FFFFFF" : "#CCCCCC"} />
  </svg>
)
import alegraAPI from '../reducers/alegraAPI';
import Cookie from 'js-cookie';
import { get, isArray, difference, flattenDeep } from 'lodash';

export const isItemFromStation = (item, stationWarehouse) => {
  const itemType = get(item, 'type');
  const warehouses = get(item, 'inventory.warehouses', null);

  let invoicingWarehouse = false;

  if (itemType === 'kit') {
    invoicingWarehouse = +get(item, 'kitWarehouse.id') === +stationWarehouse;
  } else {
    if (!!warehouses) {
      if (
        !!warehouses.find(
          (warehouse) => +get(warehouse, 'id') === +stationWarehouse
        )
      )
        invoicingWarehouse = true;
    } else invoicingWarehouse = true;
  }

  const invoicingType =
    itemType === 'simple' ||
    itemType === 'kit' ||
    itemType === 'variant' ||
    itemType === 'product';

  return invoicingWarehouse && invoicingType;
};

export const checkVariantImages = (item) => {
  return item?.itemVariants?.length
    ? item.itemVariants
      .map(({ imageFile, variantAttributes }) => ({
        image: imageFile,
        variantAttributes,
      }))
      .filter(({ image }) => Boolean(image))
    : [];
};

export const compareVariantsAttributes = (attA, attB) => {
  if (!attA || !attB) return false;

  if (!isArray(attA) || !isArray(attB)) return false;

  try {
    const attAIds = flattenDeep(
      attA.map(({ options }) => options.map(({ id }) => '' + id))
    );
    const attBIds = flattenDeep(
      attB.map(({ options }) => options.map(({ id }) => '' + id))
    );

    return (
      !difference(attAIds, attBIds).length &&
      !difference(attBIds, attAIds).length
    );
  } catch {
    return false;
  }
};

const getVariantFavoriteImageId = (variant) => {
  const allImages = get(variant, 'images', []);

  if (allImages.length === 0) return null;

  const favoriteImage = allImages.find((image) => get(image, 'favorite', false));

  return get(favoriteImage, 'id', null);
};

export const saveVariantsImages = async (itemVariants, variantsImages) => {
  try {
    await Promise.all(
      variantsImages.map(async ({ image, variantAttributes }) => {
        const variant = itemVariants.find(({ variantAttributes: itemAtt }) =>
          compareVariantsAttributes(variantAttributes, itemAtt)
        );

        const favoriteImageId = getVariantFavoriteImageId(variant);

        if (variant) {
          const operations = [];
          if (favoriteImageId) {
            operations.push(deleteImageFromItem(favoriteImageId));
          }
          operations.push(attachImagesToItem(variant.id, image));

          await Promise.all(operations);
        }
        return null;
      })
    );
  } catch (error) {
    console.error("Failed to save variant images:", error);
  }
};

export const deleteImageFromItem = async (imageId) => {
  await alegraAPI.delete(`/items/attachment/${imageId}`, null, {
    headers: {
      'X-Data-Source': 'pos',
      Authorization: `Basic ${Cookie.get(process.env.REACT_APP_ALEGRA_COOKIE)}`,
    },
  });
};

export const attachImagesToItem = async (itemId, image) => {
  let data = new FormData();
  data.append('image', image);

  await alegraAPI.post(`/items/${itemId}/attachment`, data, {
    favorite: true
  }, {
    headers: {
      'X-Data-Source': 'pos',
      Authorization: `Basic ${Cookie.get(process.env.REACT_APP_ALEGRA_COOKIE)}`,
      'Content-Type': 'multipart/form-data'
    },
  });
};

export const attachFavoriteImageToItem = async (itemId, image) => {
  let data = new FormData();
  let imageFile = await fetch(image);
  imageFile = await imageFile.blob();
  data.append('image', imageFile);

  await alegraAPI.post(
    `/items/${itemId}/attachment?favorite=true`,
    data,
    null,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
        'X-Data-Source': 'pos',
        Authorization: `Basic ${Cookie.get(
          process.env.REACT_APP_ALEGRA_COOKIE
        )}`,
      },
    }
  );
};

export const INVOICE_DOCUMENT_TYPES = {
  INVOICE: 'invoice',
  SALE_TICKET: 'saleTicket',
  ELECTRONIC_POS: 'EPOS',
};

export const INVOICE_DOCUMENT_PREFIXES = {
  ELECTRONIC_POS: 'EPOS',
};

export const BASE_INVOICE_PAYMENT_METHODS = {
  CASH: 'cash',
  DEBIT: 'debit',
  CREDIT: 'credit',
  TRANSFER: 'transfer',
};
export const INVOICE_PAYMENT_METHODS = {
  ...BASE_INVOICE_PAYMENT_METHODS,
  COMBINED: 'combined',
  BANCOLOMBIA: 'bancolombiaQR',
  NEQUI: 'nequiPush',
};

import React, { useCallback, useMemo, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { get } from 'lodash'
import { BigNumber } from "bignumber.js";
import { I18n } from 'aws-amplify';

import { allItems as allItemsSelector } from '../../../../selectors/items';
import { searchMore } from '../../../../reducers/items';
import { addItem } from '../../../../reducers/activeRefund';
import { items as selectedItemsSelector } from '../../../../selectors/activeRefund';
import { useFormat } from '../../../../hooks/useFormat';

const SearchableItems = () => {
  const dispatch = useDispatch();
  const { decimal, fmt } = useFormat();
  const itemsListRef = useRef(null)
  const items = useSelector(allItemsSelector);
  const selectedItems = useSelector(selectedItemsSelector);

  const handlerScroll = () => {
    const elem = itemsListRef.current;
    if (elem.clientHeight + elem.scrollTop >= elem.scrollHeight) {
      dispatch(searchMore())
    }
  };

  const totalQuantityItemsSelected = useMemo(() => selectedItems.reduce((prev, i) => prev + Number(i.quantity), 0), [selectedItems])

  return (
    <>
      <div
        className="w-100 h-100 bg-white items-list show-all"
        ref={itemsListRef}
        onScroll={() => handlerScroll()}
      >
        {items.map(item => {
          let price = get(item, 'price.0.price', 0);
          price = new BigNumber(price);
          
          let itemIsAdded = !!selectedItems.find(i => i.id === item.id);
          const taxReduced = item.tax.reduce((prev, t) => prev + Number(t.percentage), 0) / 100

          const quantityItemSelected = get(selectedItems.find(i => i.id === item.id), 'quantity', 0);
          const dicountPercent = get(item, 'discount', 0);
          const discountValue = price.times(dicountPercent).div(100);

          return (
            <div
              key={item.id}
              className={`d-flex position-relative new-refund__item-container pointer${itemIsAdded ? ' selected' : ''}`}
              onClick={() => dispatch(addItem(item))}>
              <div className="new-refund__item d-flex flex-column py-3 pl-3">
                <div className="new-refund__item-info d-flex align-items-center justify-content-between px-3 py-1">
                  <div className="d-flex flex-column text-break text-truncate text-nowrap new-refund__item-data">
                    <div className="new-refund__item-name name-large text-truncate">
                      {get(item, 'name')}
                    </div>
                    <div className="d-flex text-break text-truncate text-nowrap">
                      <div className="new-refund__item-price">
                        {price.toFormat(decimal, fmt)}
                      </div>
                      <div className="new-refund__item-reference ml-2">
                        {!!get(item, 'reference.reference')
                          ? " | " + get(item, 'reference.reference') : !!get(item, 'reference')
                            ? " | " + get(item, 'reference') : ''
                        }
                      </div>
                    </div>
                  </div>

                  <div className='d-none d-md-flex align-items-center justify-content-center justify-align-center' style={{ width: "106px", height: "38px" }}>
                    <div className="d-flex justify-content-center">
                      {quantityItemSelected > 1 && (
                          <div className='refund-quantity'>
                            {quantityItemSelected}
                          </div>
                        )
                      }
                    </div>
                  </div>

                  <div className="d-flex flex-column align-items-end new-refund__item-total-price">
                    <div className="text-right text-truncate text-nowrap">
                      {price.times(1 + taxReduced).toFormat(decimal, fmt)}
                    </div>

                    {discountValue > 0 && <div className="text-right text-truncate text-nowrap text-muted discount">
                      -{new BigNumber(discountValue).toFormat(decimal, fmt)}
                    </div>}
                  </div>
                </div>
                <div className='d-flex d-md-none px-3 align-items-center justify-align-center'>
                    <div className="d-flex justify-content-center">
                      {quantityItemSelected > 1 && (
                          <div className='refund-quantity'>
                            {quantityItemSelected}
                          </div>
                        )
                      }
                    </div>
                  </div>
              </div>
            </div>
          )
        }
        )}
      </div>
      {!!selectedItems.length && (
        <div className="items-form_items-caption text-right">
          {totalQuantityItemsSelected} {
            totalQuantityItemsSelected === 1
              ? I18n.get('itemAdded', 'producto agregado')
              : I18n.get('itemsAdded', 'productos agregados')
          }
        </div>
      )}
    </>
  )
}

export default SearchableItems;
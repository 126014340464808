import React from 'react'
import { I18n } from '@aws-amplify/core';
import { Field } from 'react-final-form';
import { capitalize, repeat } from 'lodash';
import { useSelector } from 'react-redux'

import { isOnlyInvoicingPlan } from '../../../selectors/company'
import { groupedCategories as categoriesSelector } from '../../../selectors/categories'
import { renderSelect, renderCheckbox } from '../fields/V0/Fields';

const POSSettings = ({ values }) => {
  const categories = useSelector(categoriesSelector)
  const onlyInvoicing = useSelector(isOnlyInvoicingPlan)

  return (
    <div className="form-body__fields form-row justify-content-start">
      <Field
        name="shiftsEnabled"
        className="col-12"
        component={renderCheckbox}
        disabled={onlyInvoicing}
        onlyInvoicingTooltip={onlyInvoicing}
        label={capitalize(I18n.get('shiftsField', 'turnos de caja'))}
        help={capitalize(I18n.get('shiftsFieldHelp', 'Con esta opción podrás llevar el control del dinero por cada turno'))}
        switchLabel={values.shiftsEnabled
          ? capitalize(I18n.get('disableShifts', 'deshabilitar turnos de caja'))
          : capitalize(I18n.get('enableShifts', 'habilitar turnos de caja'))
        }
      />
      
      <Field
        name="idCategoryDefaultIncome"
        className="col-md-6"
        component={renderSelect}
        options={categories}
        disabled={onlyInvoicing}
        onlyInvoicingTooltip={onlyInvoicing}
        label={capitalize(I18n.get('cashManagementIncomeLabel', 'cuenta contable para ingresos'))}
        help={I18n.get('cashManagementIncomeHelp', 'Define la cuenta contable por defecto en las operaciones de ingreso de efectivo')}
        getOptionValue={option => option.id}
        getOptionLabel={option => `${!!option.depth ? repeat('-', option.depth - 1): ''} ${option.name}`}
      />
      
      <Field
        name="idCategoryDefaultExpense"
        className="col-md-6"
        component={renderSelect}
        options={categories}
        disabled={onlyInvoicing}
        onlyInvoicingTooltip={onlyInvoicing}
        label={capitalize(I18n.get('cashManagementExpenseLabel', 'cuenta contable para gastos'))}
        help={I18n.get('cashManagementExpenseHelp', 'Define la cuenta contable por defecto en las operaciones de egreso de efectivo')}
        getOptionValue={option => option.id}
        getOptionLabel={option => `${!!option.depth ? repeat('-', option.depth - 1): ''} ${option.name}`}
      />
    </div>
  )
}

export default POSSettings;
import React from 'react';
import { Field } from 'react-final-form';
import { I18n } from '@aws-amplify/core';
import { capitalize } from 'lodash';

import { renderField } from '../fields/V0/Fields';

const Seller = () => {

  return (
    <div className="form-body h-100 p-4 overflow-hidden">
      <div className="form-body__fields form-row justify-content-start">
        <Field
          name="name"
          className="col-12"
          component={renderField}
          type="text"
          label={capitalize(I18n.get('name', 'Nombre'))}
          required
        />
        <Field
          name="identification"
          className="col-12"
          component={renderField}
          type="text"
          label={I18n.get('identification', 'identificación')}
        />
        <Field
          name="observations"
          className="col-12"
          component={renderField}
          type="textarea"
          label={capitalize(I18n.get('observations', 'Observaciones'))}
        />
      </div>
    </div>
  )
}

export default Seller;
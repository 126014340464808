import { graphqlOperation } from 'aws-amplify';
import * as mutations from '../../../../graphql/mutations';
import toastHandler from '../../../../utils/toast-handler';
import {
  paymentMethodConverterToApi,
  togglePaymentMethodConverterToApi,
} from '../converters/paymentMethodToApi';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { closeModal } from '../../../../reducers/modals';
import { useDispatch } from 'react-redux';
import {
  addPaymentMethod,
  changePaymentMethodStatus,
} from '../../../../reducers/paymentMethods';
import { get } from 'lodash';
import { sendNewGTMEvent } from '../../../../reducers/company';

export const createPaymentMethodMutation = () => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  return useMutation(
    async ({ values, api }) => {
      try {
        const paymentMethod = paymentMethodConverterToApi(values);
        const paymentMethodCreated = await api(
          graphqlOperation(mutations.createPaymentMethod, {
            paymentMethod,
          })
        );
        const data = get(paymentMethodCreated, 'data.createPaymentMethod', {});
        const paymethodConverted = {
          ...data,
          id: get(data, 'idLocal', ''),
        };
        dispatch(addPaymentMethod(paymethodConverted));
        dispatch(
          sendNewGTMEvent('pos-payment-method-created', {
            QR: get(paymethodConverted, 'qr.url', null) ? true : false,
          })
        );
        toastHandler({
          type: 'success',
          description: `Tu método de pago fue creado con éxito`,
        });
        return true;
      } catch (error) {
        toastHandler({
          type: 'error',
          description:
            get(error, 'errors[0].message', null) ||
            `Ocurrió un error al crear tu método de pago`,
        });
        return error;
      }
    },
    {
      onSuccess: () => {
        dispatch(closeModal({ modal: 'paymentMethod' }));
        queryClient.invalidateQueries(['allPaymentMethodss']);
      },
    }
  );
};

export const togglePaymentMethodMutation = () => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  return useMutation(
    async ({ values, api }) => {
      const paymentMethod = togglePaymentMethodConverterToApi(values);

      try {
        await api(
          graphqlOperation(mutations.togglePaymentMethod, {
            paymentMethod,
          })
        );

        const paymentMethodStatus = get(paymentMethod, 'is_active', false)
          ? 'active'
          : 'inactive';

        dispatch(
          changePaymentMethodStatus({
            id: get(paymentMethod, 'idLocal', ''),
            status: paymentMethodStatus,
          })
        );

        toastHandler({
          type: 'success',
          description: `Tu método de pago fue ${paymentMethodStatus === 'active' ? 'activado' : 'desactivado'} con éxito`,
        });

        return true;
      } catch (error) {
        toastHandler({
          type: 'error',
          description:
            get(error, 'errors[0].message', null) ||
            `Ocurrió un error al actualizar tu método de pago`,
        });
        return error;
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['allPaymentMethodss']);
      },
    }
  );
};

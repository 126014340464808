import { I18n } from 'aws-amplify';
import React from 'react';

const RefundNoElectronicBody = React.memo(({ refund, formattedDate }) => {
  const cause = refund?.cause;
  const anotation = refund?.anotation;

  return (
    <>
      <tr>
        <td className='caption-bold'>{I18n.get('creation', 'creación')}</td>
        <td>{formattedDate}</td>
      </tr>
      <tr>
        <td className='caption-bold'>{I18n.get('warehouse', 'bodega')}</td>
        <td>{refund?.warehouse?.name || ''}</td>
      </tr>
      {refund?.note && (
        <tr>
          <td className='caption-bold'>{I18n.get('notes', 'Notas')}</td>
          <td>{refund.note}</td>
        </tr>
      )}
      <tr>
        <td className='caption-bold'>
          {I18n.get('costCenter', 'centro de costo')}
        </td>
        <td>{refund?.costCenter?.name || '-'}</td>
      </tr>
      {refund?.currency && (
        <tr>
          <td className='caption-bold'>{I18n.get('currency', 'moneda')}</td>
          <td>{refund.currency.code || ''}</td>
        </tr>
      )}
      {refund?.idShift && (
        <tr>
          <td className='caption-bold'>
            {I18n.get('shiftNumber', 'número de turno')}
          </td>
          <td>{refund.idShift}</td>
        </tr>
      )}
      <tr>
        <td className='caption-bold'>{I18n.get('reasonRefund', 'Razón')}</td>
        <td>{cause}</td>
      </tr>
      <tr>
        <td className='caption-bold'>
          {I18n.get('operationDescription', 'Descripción de la operación')}
        </td>
        <td>{anotation}</td>
      </tr>
    </>
  );
});

export default RefundNoElectronicBody;

import { Icon, Typography } from "@alegradev/smile-ui-react";
import { IconDeviceDesktop, IconDownload } from "@tabler/icons-react";
import { I18n } from "aws-amplify";
import { useHistory } from "react-router-dom";

const DownloadAppOption = () => {
  const history = useHistory();

  const goToDesktopApp = () => {
    history.push('/download/desktop');
  };

  return (
    <div className="w-100 d-flex align-items-center gap-3 p-0" onClick={goToDesktopApp}>
      <Icon icon={IconDeviceDesktop} />
      <Typography
        type="body-3-bold"
        variant="secondary"
        text={I18n.get('posForWindows', 'POS para Windows')}
        extraClassName="flex-grow-1"
      />
      <Icon icon={IconDownload} />
    </div>
  );
};

export default DownloadAppOption;
import React from 'react';
import PropTypes from 'prop-types';
import { I18n } from '@aws-amplify/core';

import { Icon } from '@alegradev/smile-ui-react';
import { IconArrowRight } from '@tabler/icons-react';

const steps = [
  I18n.get('businessData', 'Datos del negocio'),
  I18n.get('tutorial', 'Tutorial'),
]

const Header = ({ step }) => (
  <div className="w-100 p-3 d-flex justify-content-center align-items-center">
    {steps.map((stepText, index) => (
      <div key={index} className="d-flex justify-content-center align-items-center">
        <p className={`text-uppercase py-1 h5 m-0 ${index <= step ? 'text-primary': 'text-muted'}`}>{stepText}</p>
        {index < steps.length - 1 && <span><Icon icon={IconArrowRight} extraClass={index <= step ? "icon-primary": "icon-gray"}/></span>}
      </div>
    ))}
  </div>
);

Header.propTypes = {
  step: PropTypes.number,
}

export default Header;

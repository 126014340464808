export function cleanObject(obj) {
  Object.keys(obj).forEach((key) => {
    if (
      obj[key] === null ||
      obj[key] === undefined ||
      obj[key] === '' ||
      obj[key] === 'null' ||
      obj[key] === 'undefined'
    ) {
      delete obj[key];
    } else if (typeof obj[key] === 'object' && !Array.isArray(obj[key])) {
      cleanObject(obj[key]);

      if (Object.keys(obj[key]).length === 0) {
        delete obj[key];
      }
    }
  });
  return obj;
}

import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';
import { get, isArray } from 'lodash'
import { COUNTRIES } from '../utils/enums/countries';

import { handleError } from '../utils/errors';
import { getInitialState } from '../utils';
import { fetchAccountingCategoriesQuery, fetchCategoriesQuery } from '../queries/categories';

const initialState = {
  entities: {},
  ids: [],
  debtsToPayProviders: null,
  receivableAccounts: null,
  loading: 'idle',
  error: null,
  version: 1
}

export const fetchCategories = createAsyncThunk(
  'categories/fetch',
  async ({ preloaded, ...params }, { rejectWithValue }) => {
    try {
      if (!!preloaded)
        return preloaded;

      return await fetchCategoriesQuery(params);
    } catch (error) {
      return rejectWithValue(handleError(error))
    }
  }
)

export const fetchContactAccountingCategories = createAsyncThunk(
  'categories/fetchContactAccountingCategories',
  async ({ preloaded, ...params }, { rejectWithValue, getState }) => {
    try {
      const state = getState();
      const APP_VERSION = get(state, 'auth.company.applicationVersion');
      if (!!get(preloaded, 'contacts')) return get(preloaded, 'contacts');
      // ex queries.getContactAccountingCategories

      const data = await fetchAccountingCategoriesQuery();

      if (APP_VERSION === COUNTRIES.MEXICO) {
        return {
          debtsToPayProviders: {
            national: get(data, 'contacts.national.debtsToPayProviders'),
            foreign: get(data, 'contacts.foreign.debtsToPayProviders'),
          },
          receivableAccounts: {
            national: get(data, 'contacts.national.receivableAccounts'),
            foreign: get(data, 'contacts.foreign.receivableAccounts'),
          },
        };
      }

      const debtsToPayProviders = isArray(
        get(data, 'contacts.debtsToPayProviders')
      )
        ? get(data, 'contacts.debtsToPayProviders')
        : [get(data, 'contacts.debtsToPayProviders')];
      const receivableAccounts = isArray(
        get(data, 'contacts.receivableAccounts')
      )
        ? get(data, 'contacts.receivableAccounts')
        : [get(data, 'contacts.receivableAccounts')];

      return {
        debtsToPayProviders,
        receivableAccounts,
      };
    } catch (error) {
      return rejectWithValue(handleError(error));
    }
  }
)

export const adapter = createEntityAdapter();

const appSlice = createSlice({
  name: 'categories',
  initialState: getInitialState('categories', initialState),
  reducers: {
  },
  extraReducers: builder => {
    builder.addCase(fetchCategories.fulfilled, (state, action) => {
      adapter.setAll(state, action.payload)
      state.loading = 'idle'
      state.error = null
    })
    builder.addCase(fetchCategories.pending, (state) => {
      state.loading = 'loading'
      state.error = null
    })
    builder.addCase(fetchCategories.rejected, (state, action) => {
      state.loading = 'idle'
      state.error = action.payload
    })
    builder.addCase(fetchContactAccountingCategories.fulfilled, (state, action) => {
      state.debtsToPayProviders = get(action, 'payload.debtsToPayProviders')
      state.receivableAccounts = get(action, 'payload.receivableAccounts')
      state.loading = 'idle'
      state.error = null
    })
    builder.addCase(fetchContactAccountingCategories.pending, (state) => {
      state.loading = 'loading'
      state.error = null
    })
    builder.addCase(fetchContactAccountingCategories.rejected, (state, action) => {
      state.loading = 'idle'
      state.error = action.payload
    })
  }
});

const { reducer } = appSlice;

export const categoriesSelector = adapter.getSelectors(state => state.categories);

export default reducer;
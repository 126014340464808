import { graphqlOperation } from '@aws-amplify/api';
import { get } from 'lodash';
import dayjs from 'dayjs';

import * as queries from '../graphql/queries';
import { hasPermissionTo } from '../selectors/auth';
import {
  multicurrency,
  baseEventParameters as baseEventParametersSelector,
  baseNewEventParameters as baseNewEventParametersSelector,
  baseCompanyParameters,
  companySelector,
} from '../selectors/company';
import { fetchBanks } from './banks';
import { fetchPriceLists } from './priceLists';
import {
  fetchCategories,
  fetchContactAccountingCategories,
} from './categories';
import { fetchNumerations } from './numerations';
import { fetchTaxes } from './taxes';
import { fetchCurencies } from './currencies';
import { fetchWarehouses } from './warehouses';
import { fetchCostCenters } from './costCenters';
import { fetchSellers } from './sellers';
import { fetchItemCategories } from './itemCategories';
import { fetchPaymentTerms } from './paymentTerms';
import { fetchCustomFields } from './customFields';
import { fetchVariants } from './variants';
import { APIGraphqlSelector } from '../selectors/app';
import { isElectronicPOSDocumentAvailableSelector } from '../selectors/numerations';
import { fetchInventoryAdjustmentsNumerations } from './inventoryAdjustmentsNumerations';

const loadAllValues = () => {
  return async (dispatch, getState) => {
    const multiCurrency = multicurrency(getState());
    const can = hasPermissionTo(getState());

    return await Promise.all([
      can('index', 'price-lists') && can('view', 'price-lists')
        ? dispatch(fetchPriceLists({ preloaded: null }))
        : Promise.resolve(),
      can('index', 'bank-accounts') && can('view', 'bank-accounts')
        ? dispatch(fetchBanks({ preloaded: null }))
        : Promise.resolve(),
      dispatch(fetchCostCenters({ preloaded: null })),
      dispatch(fetchNumerations({ preloaded: null })),
      dispatch(fetchCategories({ preloaded: null })),
      dispatch(fetchContactAccountingCategories({ preloaded: null })),
      dispatch(fetchTaxes({ preloaded: null })),
      multiCurrency && can('index', 'currencies')
        ? dispatch(fetchCurencies({ preloaded: null }))
        : Promise.resolve(),
      dispatch(fetchVariants({ preloaded: null })),
      dispatch(fetchWarehouses({ preloaded: null })),
      dispatch(fetchSellers({ preloaded: null })),
      dispatch(fetchItemCategories({ preloaded: null })),
      dispatch(fetchCustomFields({ preloaded: null })),
      can('index', 'terms')
        ? dispatch(fetchPaymentTerms({ preloaded: null }))
        : Promise.resolve(),
      dispatch(fetchInventoryAdjustmentsNumerations({ preloaded: null })),
    ]);
  };
};

const loadPreloadedValues = ({
  priceLists,
  banks,
  costCenters,
  numberTemplates,
  categories,
  contactAccountCategories,
  taxes,
  currencies,
  warehouses,
  sellers,
  paymentTerms,
  itemCategories,
  customFields,
}) => {
  return async (dispatch, getState) => {
    const multiCurrency = multicurrency(getState());
    const can = hasPermissionTo(getState());

    return await Promise.all([
      can('index', 'price-lists') && can('view', 'price-lists')
        ? dispatch(
          fetchPriceLists({ preloaded: get(priceLists, 'data', null) })
        )
        : Promise.resolve(),
      can('index', 'bank-accounts') && can('view', 'bank-accounts')
        ? dispatch(fetchBanks({ preloaded: get(banks, 'data', null) }))
        : Promise.resolve(),
      dispatch(fetchCostCenters({ preloaded: get(costCenters, 'data', null) })),
      dispatch(fetchNumerations({ preloaded: numberTemplates })),
      dispatch(fetchCategories({ preloaded: categories })),
      dispatch(
        fetchContactAccountingCategories({
          preloaded: contactAccountCategories,
        })
      ),
      dispatch(fetchTaxes({ preloaded: taxes })),
      multiCurrency && can('index', 'currencies')
        ? dispatch(fetchCurencies({ preloaded: currencies }))
        : Promise.resolve(),
      dispatch(fetchVariants({ preloaded: null })),
      dispatch(fetchWarehouses({ preloaded: get(warehouses, 'data', null) })),
      dispatch(fetchSellers({ preloaded: sellers })),
      dispatch(
        fetchItemCategories({ preloaded: get(itemCategories, 'data', null) })
      ),
      dispatch(
        fetchCustomFields({ preloaded: get(customFields, 'data', null) })
      ),
      can('index', 'terms')
        ? dispatch(fetchPaymentTerms({ preloaded: paymentTerms }))
        : Promise.resolve(),
      dispatch(fetchInventoryAdjustmentsNumerations({ preloaded: null })),
    ]);
  };
};

export const getCompanyValues = () => {
  return async (dispatch, getState) => {
    try {
      // const APIGraphql = APIGraphqlSelector(getState());
      // const response = await APIGraphql(
      //   graphqlOperation(queries.getCompanyValues)
      // );

      // const values = JSON.parse(
      //   JSON.parse(get(response, 'data.getCompanyValues', null))
      // );

      // await dispatch(loadPreloadedValues(values));
      dispatch(loadAllValues());
    } catch (error) {
      console.error(error);
    }
  };
};

const sendGTMEventAction = (event, params) => {
  return async (dispatch, getState) => {
    const baseEventParameters = baseEventParametersSelector(getState());

    const cleanEventObject = (eventObject) => {
      if (!eventObject.params) delete eventObject.params;
      return eventObject;
    };

    return await Promise.all([
      !!baseEventParameters
        ? dispatch(
          window.dataLayer.push(
            cleanEventObject({
              event,
              company: {
                ...baseEventParameters,
                eventTimestamp: dayjs(new Date().toUTCString()).format(
                  'YYYY-MM-DD HH:mm:ss'
                ),
              },
              params,
            })
          )
        )
        : Promise.resolve(),
    ]);
  };
};

export const sendGTMEvent = (event, params) => {
  return async (dispatch) => {
    if (window.Cypress) {
      console.log('Cypress detected, skipping GTM event');
      return;
    }
    try {
      await dispatch(sendGTMEventAction(event, params));
    } catch { }
  };
};

const sendNewGTMEventAction = (event, params) => {
  return async (dispatch, getState) => {
    const baseNewEventParameters = baseNewEventParametersSelector(getState());
    const isElectronicPOSDocumentAvailable = isElectronicPOSDocumentAvailableSelector(getState());
    const isElectronic = companySelector(getState())?.settings?.electronicInvoicing;

    const getActiveElectronicDocuments = ({ isElectronic, isElectronicPOSDocumentAvailable }) => {
      if (isElectronic && isElectronicPOSDocumentAvailable) {
        return 'FEAndDocPOS';
      }
      if (isElectronic) {
        return 'FE';
      }
      if (isElectronicPOSDocumentAvailable) {
        return 'DocPOS';
      }
      return 'none';
    }


    const baseNewEventParametersParsed = {
      ...baseNewEventParameters,
      activeElectronicDocuments: getActiveElectronicDocuments({ isElectronic, isElectronicPOSDocumentAvailable }),
    }

    const cleanEventObject = (eventObject) => {
      if (!eventObject.params) delete eventObject.params;
      return eventObject;
    };

    return await Promise.all([
      !!baseNewEventParameters
        ? dispatch(
          window.dataLayer.push({
            event,
            companyProperties: baseNewEventParametersParsed,
            eventProperties: {
              eventTimestamp: dayjs(new Date().toUTCString()).format(
                'YYYY-MM-DD HH:mm:ss'
              ),
              idProduct: '4',
              productKey: 'alegra-pos',
              app: !!window.matchMedia('(display-mode: standalone)').matches
                ? 'desktop'
                : 'web',
              ...params,
            },
          })
        )
        : Promise.resolve(),
    ]);
  };
};

export const sendNewGTMEvent = (event, params) => {
  return async (dispatch) => {
    if (window.Cypress) {
      console.log('Cypress detected, skipping GTM event');
      return;
    }
    try {
      await dispatch(sendNewGTMEventAction(event, params));
    } catch { }
  };
};

const sendClarityCustomTagsEventAction = () => {
  return async (dispatch, getState) => {
    const properties = baseCompanyParameters(getState());

    return await Promise.all([
      !!properties && window?.clarity
        ? dispatch(
          Object.keys(properties).forEach((key) => {
            window.clarity('set', key, properties[key]);
          })
        )
        : Promise.resolve(),
    ]);
  };
};

export const sendClarityCustomTags = () => {
  return async (dispatch) => {
    try {
      await dispatch(sendClarityCustomTagsEventAction());
    } catch { }
  };
};
import { get } from 'lodash';

export const transform = (values, state) => {
  if (!!values)
    return {
      name: get(values, 'name', get(state, 'name', '')),
      reference: get(values, 'reference', get(state, 'reference', '')),
      price: get(values, 'price', get(state, 'price', '')),
      status: get(values, 'status.value', get(state, 'status', '')),
    }
  else
    return {
      name: '',
      reference: '',
      price: '',
      status: '',
    }
}

export const inventoryAdjustmentFilterTransformer = (values) => {
  if (!!values)
    return {
      number: get(values, 'number', ''),
      date: !!get(values, 'date', null) ? new Date(get(values, 'date', '')).toISOString() : "",
    }
  else
    return {
      number: '',
      date: '',
    }
}
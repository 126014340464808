import { get } from 'lodash';
import { BaseInvoiceStrategy } from './BaseInvoiceStrategy';
import { VALIDATION_ERROR_MESSAGES } from '../../utils/invoice/activeInvoice';

export class UsaInvoiceStrategy extends BaseInvoiceStrategy {
  /**
   * Modifies the given invoice based on various parameters provided.
   *
   * @param {Object} invoice - The invoice object to be modified.
   * @param {Object} state - The current state object.
   * @param {boolean} isElectronic - Indicates if the invoice is electronic.
   * @param {Object} numberTemplate - The template for numbering the invoice.
   * @param {Date} today - The current date.
   * @param {string} cfdiUse - The CFDI use for the invoice.
   * @returns {Object} - The modified invoice.
   */
  modifyPreparedInvoice({
    invoice,
    state,
    isElectronic,
    numberTemplate,
    today,
    cfdiUse,
  }) {
    const modifiedInvoice = {
      ...invoice,
      dueDate: today
        .add(get(invoice, 'client.term.days', 0), 'day')
        .format('YYYY-MM-DD'),
    };

    delete modifiedInvoice.type;
    delete modifiedInvoice.operationType;
    delete modifiedInvoice.paymentTerm;
    delete modifiedInvoice.deliveryTerm;

    return modifiedInvoice;
  }
  /**
   * Validates the invoice based on its electronic status and stamp generation.
   *
   * @param {Object} params - An object containing the invoice data.
   * @param {Object} params.invoice - The invoice object to validate.
   * @param {boolean} params.isElectronic - Indicates if the invoice is electronic.
   *
   * @throws {Error} - Throws an error if electronic numeration is missing for electronic invoices.
   */
  modifyValidateInvoice({ invoice, isElectronic }) {
    if (isElectronic && !!get(invoice, 'stamp.generateStamp')) {
      throw new Error(VALIDATION_ERROR_MESSAGES.ELECTRONIC_NUMERATION_MISSING);
    }
  }

  transform(invoice) {
    let result = super.transform(invoice);
    const payments = result?.payments || [];

    result = {
      ...result,
      payments: payments.map((payment) => ({
        ...payment,
        idPaymentMethodLocal: get(invoice, 'idPaymentMethodLocal', null),
      })),
    };

    return result;
  }
}

import { get, has, isArray, isEmpty, isNumber } from 'lodash';

export const transformAdditionalCharges = (invoice) => {
  let additionalCharges = null;
  if (!isEmpty(get(invoice, 'aditionalCharge'), [])) {
    additionalCharges = [];

    get(invoice, 'aditionalCharge', []).forEach((charge) => {
      if (Number(get(charge, 'amount')) > 0) {
        additionalCharges.push({
          id: get(charge, 'id'),
          percentage: get(charge, 'percetage'),
          amount: get(charge, 'amount'),
        });
      }
    });
  }
  return additionalCharges;
};

export const transformItems = (invoice) => {
  const items = get(invoice, 'items', []);
  return items.map((item) => ({
    id: get(item, 'id', null),
    description: get(item, 'description', null),
    discount: !!get(item, 'discount', null)
      ? JSON.stringify(get(item, 'discount', null))
      : null,
    reference: !!get(item, 'reference', null)
      ? JSON.stringify(get(item, 'reference', null))
      : null,
    price: get(item, 'price', null),
    tax: !!get(item, 'tax', null)
      ? isArray(get(item, 'tax'))
        ? item.tax.map((t) => ({ id: t.id }))
        : [{ id: get(item, 'tax.id') }]
      : [],
    quantity:
      has(item, 'quantity') && isNumber(Number(item.quantity))
        ? Number(item.quantity).toFixed(4)
        : null,
  }));
};

export const transformPayments = (invoice) => {
  const payments = get(invoice, 'payments', []);
  return payments.map((payment) => ({
    date: get(payment, 'date', null),
    amount: get(payment, 'amount', null),
    account: !!get(payment, 'account', null)
      ? { id: get(payment, 'account', null) }
      : null,
    paymentMethod: get(payment, 'paymentMethod', null),
    numberTemplate: get(payment, 'numberTemplate', null),
    currency: !!get(payment, 'currency.exchangeRate')
      ? {
          code: get(payment, 'currency.code'),
          exchangeRate: get(payment, 'currency.exchangeRate'),
        }
      : null,
  }));
};

import { I18n } from '@aws-amplify/core';
import { getDateTime } from './utilities';

const Cude = ({ refund, cude }) => {
  if (!cude) return null;

  const dateTime = getDateTime(refund);
  const dateTimeDIAN = getDateTime(refund, 'stamp.date');

  return (
    <div className='mb-2'>
      <strong>{I18n.get('cude', 'CUDE')}:</strong>
      <p className='break-all'>{cude}</p>
      <p className='w-100 text-center'><strong>{I18n.get('generated', 'Generado')}</strong> {dateTime}</p>
      <p className='w-100 text-center'><strong>{I18n.get('dianValidation', 'Validación DIAN')}</strong> {dateTimeDIAN}</p>
    </div>
  )
}

export default Cude
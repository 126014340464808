import React from 'react'
import PropTypes from 'prop-types';

const Header = ({ title, subtitle, actions, info, error }) => (
  <div className="w-100 d-flex flex-column mb-3">
    <div className="d-flex justify-content-between align-items-center">
      <h1 className="text-primary text-capitalize-first">
        {title}
      </h1>

      <div>
        {actions}
      </div>
    </div>

    {!!subtitle && (
      <div className="w-100 text-muted text-capitalize-first">
        {subtitle}
      </div>
    )}

    {!!info && (
      <div className="text-capitalize-first text-warning">
        {info}
      </div>
    )}
    
    {!!error && (
      <p className="h4 text-danger text-capitalize-first">
        {error}
      </p>
    )}
  </div>
)

Header.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.element,
  actions: PropTypes.element,
  error: PropTypes.string,
}

export default Header;
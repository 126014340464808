import { isNumber, get, isEmpty } from 'lodash'
import { BigNumber } from 'bignumber.js'
import getNoTax from '../../countriesData/general/noTax';

const initialValues = (item, decimal, language) => {
  if (!item) return {};

  let tax = item.tax

  try {
    tax = item.tax.filter(t => get(t, 'status') === 'active')
  } catch {

  }

  const noTax = [getNoTax(language?.substring(0, 2))];

  return ({
    ...item,
    price: new BigNumber(item.price).toNumber(),
    discount: !!isNumber(get(item, 'discount.discount', null))
      ? get(item, 'discount.discount') : !!get(item, 'discount', null)
        ? get(item, 'discount') : 0,
    tax: isEmpty(tax) ? noTax : tax,
  })
}

export default initialValues;
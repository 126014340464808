import React from 'react';
import { Field } from 'react-final-form';
import { I18n } from '@aws-amplify/core';
import { capitalize } from 'lodash';
import { useSelector } from 'react-redux';

import { getTicketNumerations } from '../../../selectors/numerations';
import { renderSelect, renderDate } from '../fields/V0/Fields';

const DailyReport = () => {
  const numerations = useSelector(getTicketNumerations)
	
  return (
    <div className="form-body h-100 p-4 overflow-hidden">
      <div className="form-body__fields form-row justify-content-start">
        <Field
          name="date"
          className="col-12"
          component={renderDate}
          type="date"
          label={capitalize(I18n.get('day', 'día'))}
          dateFormat={I18n.get('dateFormatDateFNS', 'dd/MM/yyyy')}
          portalId={"daily-report-modal-portal"}
          required
        />
        
        <Field
          name="numeration"
          className="col-12"
          component={renderSelect}
          options={numerations}
          label={capitalize(I18n.get('numeration', 'numeración'))}
          placeholder={I18n.get('allF', 'todas')}
          getOptionLabel={option => option.name}
          getOptionValue={option => option.id}
          isMulti
        />
        
      </div>
    </div>
  )
}

export default DailyReport;
import React from 'react'
import { I18n } from '@aws-amplify/core';
import { BigNumber } from 'bignumber.js';
import { replaceAndParse } from '../../utils';

const DOT_DECIMAL_SEPARATOR_COUNTRIES = ['mexico', 'peru', 'republicaDominicana', 'panama', 'other', 'usa']

const getCountrySymbol = (country, type) => {
  switch (country) {
    case 'spain':
      return '€'
    case 'peru':
      return 'S/ '
    case 'costaRica':
      if (type === "price")
        return '$'
      return '₡'
    default:
      return '$'
  }
}

const getPlanCurrency = (country, planCurrency) => {
  switch (country) {
    case 'peru':
      return ''

    default:
      return planCurrency
  }
}

const getCurrencyCode = (country, currencyCode) => {
  switch (country) {
    case 'peru':
      return 'SOL'

    default:
      return currencyCode
  }
}

const getPlanRelevantFeatures = plan => {
  let relevantFeatures = []
  for (let feature in plan.features) {
    if (plan.features[feature].quantitative && plan.features[feature].relevant && plan.features[feature].include) {
      relevantFeatures.push(plan.features[feature])
    }
  }
  return relevantFeatures
}

const incomeChanger = (relevantFeatures, countrySymbol, currencyCode) => {
  const changedIncomeFeatures = relevantFeatures.map(feature => {
    if (feature.keyword.includes('income') && feature.maxQuantityFormatted !== '-1') {
      feature.description = replaceAndParse(I18n.get('planIncomeFeature', 'Ingresos hasta {}{} {} al mes'), [countrySymbol, feature.maxQuantityFormatted, currencyCode])
    }
    else if (feature.keyword.includes('invoices') && !(parseInt(feature.maxQuantityFormatted) > 0)) {
      feature.description = I18n.get('planInvoiceUnlimitedFeature', 'Facturas de venta ilimitadas al mes')
    }
    return feature
  })
  return changedIncomeFeatures;
}

const invoiceChanger = (relevantFeatures) => {
  const changedInvoiceFeatures = relevantFeatures.map(feature => {
    if (feature.keyword.includes('invoices') && feature.maxQuantityFormatted !== '-1') {
      feature.description = replaceAndParse(I18n.get('planInvoicesFeature', '{} facturas de venta al mes'), [feature.maxQuantityFormatted])
    }
    return feature;
  })
  return changedInvoiceFeatures;
}

const stationsChanger = (relevantFeatures) => {
  const changedStationsFeatures = relevantFeatures.map(feature => {
    if (feature.keyword.includes('stations') && feature.maxQuantityFormatted !== '-1') {
      feature.description = replaceAndParse(I18n.get('POSStationsFeature', '{} Puntos de venta'), [feature.maxQuantityFormatted])
    }
    if (feature.keyword.includes('stations') && feature.maxQuantityFormatted === '-1') {
      feature.description = I18n.get('POSStationsFeatureUnlimited', 'Puntos de venta ilimitados')
    }
    return feature;
  })
  return changedStationsFeatures;
}

const changeCommasForDots = (relevantFeatures, country) => {
  if (!DOT_DECIMAL_SEPARATOR_COUNTRIES.includes(country)) {
    const formattedFeatures = relevantFeatures.map(feature => {
      feature.description = feature.description.replace(/[,]/gi, ".")
      return feature
    })
    return formattedFeatures;
  }
  else {
    return relevantFeatures;
  }
}

const changeFeaturesOrder = (features, country) => {
  if (['mexico', 'peru', 'other'].includes(country)) {
    for (let feature in features) {
      if (features[feature].keyword.includes('invoices')) {
        features[feature].sortKey = 1
      }
      if (features[feature].keyword.includes('users')) {
        features[feature].sortKey = 3
      }
      if (features[feature].keyword.includes('income')) {
        features[feature].sortKey = 2
      }
    }
  } else {
    for (let feature in features) {
      if (features[feature].keyword.includes('invoices')) {
        features[feature].sortKey = 2
      }
      if (features[feature].keyword.includes('users')) {
        features[feature].sortKey = 3
      }
      if (features[feature].keyword.includes('income')) {
        features[feature].sortKey = 1
      }
      else {
        features[feature].sortKey = 4
      }
    }
  }
  return features.sort((x, y) => {
    return x.sortKey - y.sortKey;
  })
}

const changeFeatureWrapper = (plan, country) => {
  const relevantFeatures = getPlanRelevantFeatures(plan)
  const newIncomeFeatures = incomeChanger(relevantFeatures, getCountrySymbol(country), getCurrencyCode(country, plan.currency))
  const newInvoiceFeatures = invoiceChanger(newIncomeFeatures)
  const newStationsFeatures = stationsChanger(newInvoiceFeatures)
  const formattedFeatures = changeCommasForDots(newStationsFeatures, country)
  const orderedFeatures = changeFeaturesOrder(formattedFeatures, country)

  return orderedFeatures
}

const getPlanColor = plan => {
  if (plan.keyword) {
    if (plan.keyword.includes('pyme'))
      return '#BFCD31'
    if (plan.keyword.includes('plus'))
      return '#4A90E2'
  }
  return '#00B19D'
}

const Plan = ({ plan, period, country, discount, showDiscount, showHappyWeek, onChoose }) => {

  let planFeatures = changeFeatureWrapper(plan, country);

  const color = getPlanColor(plan)
  const fmt = DOT_DECIMAL_SEPARATOR_COUNTRIES.includes(country) ? {
    prefix: '',
    decimalSeparator: '.',
    groupSeparator: ',',
    groupSize: 3,
  } : {
    prefix: '',
    decimalSeparator: ',',
    groupSeparator: '.',
    groupSize: 3,
  }

  const price = new BigNumber(plan.price)
  const anualPrice = price.minus(price.multipliedBy(0.1))

  return (
    <div className="plan d-flex flex-column justify-content-start text-center">
      <div
        style={{ height: '2rem' }}
        className={`bg-primary h5 text-white justify-content-center 
          align-items-center d-flex ${!!plan.keyword && !plan.keyword.includes('pro') ? 'invisible' : 'visible'}
        `}
      >
        {I18n.get('recommendedPlan', 'Recomendado ⭐')}
      </div>

      <h2
        className="text-uppercase font-weight-bold py-4"
        style={{ color }}
      >
        {I18n.get(`planName.${plan.keyword}`, plan.name)}

        {/* {showDiscount && (
          <p
            className="text-uppercase font-weight-bold"
            style={{ color, fontSize: '13px' }}
          >
            {`${discount}% OFF`}
          </p>
        )} */}
      </h2>


      <div className="py-3">
        {showDiscount && period !== 'year' && (
          <h1 className="text-uppercase font-weight-light"
            style={{
              textDecoration: 'line-through',
              color: '#A3ADC2',
              fontSize: '20px'
            }}
          >
            {/* {`${price.multipliedBy(2).toFormat(0, { ...fmt, prefix: getCountrySymbol(country, "price") })} ${getCurrencyCode(country, plan.currency)}/${showHappyWeek ? I18n.get('twoMonths', '2 meses') : I18n.get('month', 'mes')}`} */}
            {`${price.multipliedBy(2).toFormat(2, { ...fmt, prefix: getCountrySymbol(country, "price") })} ${getCurrencyCode(country, plan.currency)}`}
          </h1>
        )}
        <h1 className="text-uppercase font-weight-light" style={{ display: 'inline' }}>
          {`${period === 'year'
            ? anualPrice.toFormat(!!price.c[0] ? 2 : 0, { ...fmt, prefix: getCountrySymbol(country, "price") })
            : showDiscount
              ? price.multipliedBy(new BigNumber(100 - discount)).dividedBy(100).multipliedBy(2)
                .toFormat((!!price.c[0] && country !== 'panama') ? 2 : 0, { ...fmt, prefix: getCountrySymbol(country, "price") })
              : price.toFormat((!!price.c[0] && country !== 'panama') ? 2 : 0, { ...fmt, prefix: getCountrySymbol(country, "price") })
            } ${country === 'argentina' ? "" : getPlanCurrency(country, plan.currency)}`}
        </h1>

        {!!(country === 'argentina') && (
          <>
            <p className="h5 text-uppercase text-muted" style={{ display: 'inline' }}>
              {showDiscount
                ? 'x' + I18n.get('twoMonths', '2 Meses')
                : `${getCurrencyCode(country, plan.currency)}/${I18n.get('month', 'mes')}`
              }
            </p>
            <p className="h5 text-muted">
              {I18n.get('includesIVA', 'Incluye IVA')}
            </p>
          </>
        )}

        {!(country === 'argentina') && (
          <p className="h5 text-uppercase text-muted">
            {showDiscount
              ? 'x' + I18n.get('twoMonths', '2 Meses')
              : `${getCurrencyCode(country, plan.currency)}/${I18n.get('month', 'mes')}`
            }
          </p>
        )}

        {showDiscount && (
          <p
            className="font-weight-bold"
            style={{ color, fontSize: '13px' }}
          >
            {`${discount}% OFF por 2 meses 🎉`}
          </p>
        )}

        {period === 'year' && (
          <p className="h6 text-capitalize-first">
            {`${I18n.get('yearlyTotal', 'Total anual')} ${anualPrice.multipliedBy(12).toFormat(price.c[0] ? 2 : 0, { ...fmt, prefix: getCountrySymbol(country, "price") })}`}
          </p>
        )}
      </div>

      <div className="py-4">
        <button
          type="button"
          className="btn text-capitalize-first"
          style={{
            margin: 0,
            height: '3.8rem',
            width: '15rem',
            border: `1px solid ${color}`,
            boxSizing: 'border-box',
            borderRadius: '50px',
            fontSize: '1.2rem',
            fontWeight: 900,
            background: color,
            color: 'white',
            padding: '0 .25rem',
            outline: 'none',
            '&:hover': {
              '&:not(:disabled)': {
                border: `1px solid ${color} !important`,
              }
            },
            '&:active': {
              '&:not(:disabled)': {
                border: `1px solid ${color} !important`,
              }
            }
          }}
          onClick={() => onChoose()}
        >
          {I18n.get('choosePlan', 'Elegir plan')}
        </button>
      </div>

      <div className="py-4">
        {!!planFeatures
          ? planFeatures.map((feature, index) => {
            return (
              <p key={index} className="h5 mb-4 text-capitalize-first">
                {feature.description}
              </p>
            )
          })
          : null
        }
      </div>
    </div>
  )
}

export default Plan;
import BasicData from './BasicData/BasicData';
import POSSettings from './POSSettings';
import NewBasicData from './BasicData/NewBasicData';
import GeneralInfo from './GeneralInfo';

const companyFormParts = {
  BasicData,
  POSSettings,
  NewBasicData,
  GeneralInfo,
}

export default companyFormParts;
import React from 'react';
import PropTypes from 'prop-types';
import RefundInfoColombia from './RefundInfoColombia';
import RefundInfoDefault from './RefundInfoDefault';
import { COUNTRIES } from '../../../../utils/enums/countries';
import RefundInfoSpain from './RefundInfoSpain';

/**
 * Factory to select the appropriate RefundInfo Component based on country.
 *
 * @param {string} country - The country code.
 * @param {Object} props - Props to pass to the RefundInfo component.
 * @returns {JSX.Element} - Rendered RefundInfo Component.
 */
const RefundInfoFactory = ({ country, ...props }) => {
  const strategies = {
    [COUNTRIES.COLOMBIA]: RefundInfoColombia,
    [COUNTRIES.SPAIN]: RefundInfoSpain,
  };

  const SelectedStrategy = strategies[country] || RefundInfoDefault;

  return <SelectedStrategy country={country} {...props} />;
};

RefundInfoFactory.propTypes = {
  country: PropTypes.string.isRequired,
  props: PropTypes.shape({
    refund: PropTypes.object.isRequired,
    align: PropTypes.string.isRequired,
  }),
};

export default RefundInfoFactory;

import { I18n } from '@aws-amplify/core';
import { get, capitalize } from 'lodash';

export const validate = values => {
  let errors = {}
  
  const name = get(values, 'name', '');
  const days = get(values, 'days', '');
  
  if (!name)
    errors.name = capitalize(I18n.get('enterAName', 'Ingresa un nombre'));

  if (!days)
    errors.days = capitalize(I18n.get('thisFieldIsRequired', 'Este campo es obligatorio'));

  return errors;
}

export const transform = values => {
  return {
    name: get(values, 'name', ''),
    days: get(values, 'days', ''),
  }
}
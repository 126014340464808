import React from 'react';
import { Field } from 'react-final-form';
import { I18n } from '@aws-amplify/core';

import ivaConditions from '../../../countriesData/argentina/companyIvaConditions';
import { renderSelect } from '../../fields/V0/Fields';

const renderIVACondition = props => 
  props.country === 'argentina' ? (
    <Field
      name="ivaCondition"
      component={renderSelect}
      className="col-md-6"
      label={I18n.get('ivaCondition', 'condición de iva')}
      options={ivaConditions}
      getOptionLabel={option => option.value}
      getOptionValue={option => option.key}
      required
    />
  ) : null;

export default renderIVACondition;
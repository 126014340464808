import { get } from 'lodash';
import { useEffect } from 'react';
import { useForm } from 'react-final-form';

const useScrollTo = ({
  submitFailed,
  errors,
  fieldRefs = {},
  onSubmitEvent,
}) => {
  const form = useForm();
  useEffect(() => {
    const scrollToContent = (ref) => {
      if (ref?.current) {
        ref.current.scrollIntoView({
          behavior: 'smooth',
          block: 'nearest',
          inline: 'nearest',
        });
      }
    };

    if (submitFailed && onSubmitEvent) {
      form.change('onSubmitEvent', false);
      const firstErrorField = Object.keys(errors).find((field) =>
        get(errors, field)
      );

      if (firstErrorField && fieldRefs[firstErrorField]) {
        scrollToContent(fieldRefs[firstErrorField]);
      }
    }
  }, [submitFailed, errors, onSubmitEvent]);
};

export default useScrollTo;

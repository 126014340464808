import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import {
  decimalPrecision,
  country as countrySelector,
  dateFormat as dateFormatSelector,
  companySelector,
  membershipSelector,
  decimalSeparator as decimalSeparatorSelector,
} from '../../../selectors/company';

import useDecimalsVersionsGroup from '../../../hooks/useDecimalsVersionsGroup/hook';
import useRefundCalculations from '../hooks/useRefundCalculations';
import useTicketBaiInformation from '../../../hooks/useTicketBaiElegible/hook';

import RefundBodyFactory from './RefundBodyFactory';
import ItemsTable from './ItemsTable';
import SummaryTable from './SummaryTable';
import InvoicesSection from './InvoicesSection';
import RefundsSection from './RefundsSection';
import { selectStatusColor } from '../utils';
import { COUNTRIES } from '../../../utils/enums/countries';

/**
 * Component to display refund details and related information.
 *
 * @param {Object} props - Component props.
 * @param {Object} props.refund - The refund object.
 * @returns {JSX.Element|null} - Rendered component or null if no refund.
 */
const DetailBody = React.memo(({ refund }) => {
  const decimal = useSelector(decimalPrecision);
  const country = useSelector(countrySelector);
  const company = useSelector(companySelector);
  const membership = useSelector(membershipSelector);
  const dateFormat = useSelector(dateFormatSelector);
  const decimalSeparator = useSelector(decimalSeparatorSelector);
  const { isDecimalActive } = useDecimalsVersionsGroup();
  const { isTicketBaiEnabled } = useTicketBaiInformation();

  const { itemsValues, fmt, formattedDate } = useRefundCalculations({
    refund,
    decimal,
    country,
    company,
    membership,
    isDecimalActive,
    dateFormat,
    decimalSeparator,
  });

  const isElectronic = refund?.numberTemplate?.isElectronic ?? false;

  if (!refund) return null;

  const additionalCharge = refund?.additionalCharges?.[0]?.amount ?? 0;

  return (
    <div className="p-4 position-relative overflow-hidden">
      <div>
        <table className="detail-info-table rounded-lg mb-4 table-body-refunds" style={{ tableLayout: 'fixed' }}>
          <tbody>
            <RefundBodyFactory
              country={country}
              refund={refund}
              isTicketBaiEnabled={isTicketBaiEnabled}
              isElectronic={isElectronic}
              selectStatusColor={selectStatusColor}
              dateFormat={dateFormat}
              formattedDate={formattedDate}
            />
          </tbody>
        </table>

        <ItemsTable
          items={refund.items}
          fmt={fmt}
          decimal={decimal}
          isDecimalActive={isDecimalActive}
        />

        <SummaryTable
          itemsValues={itemsValues}
          fmt={fmt}
          decimal={decimal}
          taxes={itemsValues.taxes}
          additionalCharge={additionalCharge}
          total={refund.total}
          country={country}
        />

        {refund.invoices?.length > 0 && (
          <InvoicesSection
            invoices={refund.invoices}
            type={refund.creditNoteOperationType}
            fmt={fmt}
            decimal={decimal}
            dateFormat={dateFormat}
            country={country}
          />
        )}

        {refund.refunds?.length > 0 && (
          <RefundsSection
            refunds={refund.refunds}
            fmt={fmt}
            decimal={decimal}
            dateFormat={dateFormat}
          />
        )}
      </div>
    </div>
  );
});

DetailBody.propTypes = {
  refund: PropTypes.shape({
    client: PropTypes.shape({
      name: PropTypes.string,
    }),
    numberTemplate: PropTypes.shape({
      isElectronic: PropTypes.bool,
      documentType: PropTypes.string,
    }),
    type: PropTypes.string,
    cause: PropTypes.string,
    stamp: PropTypes.shape({
      cude: PropTypes.string,
      cufe: PropTypes.string,
      warnings: PropTypes.arrayOf(PropTypes.string),
    }),
    dateTime: PropTypes.string.isRequired,
    warehouse: PropTypes.shape({
      name: PropTypes.string,
    }),
    note: PropTypes.string,
    costCenter: PropTypes.shape({
      name: PropTypes.string,
    }),
    currency: PropTypes.shape({
      symbol: PropTypes.string,
      code: PropTypes.string,
    }),
    idShift: PropTypes.string,
    items: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        reference: PropTypes.object,
      })
    ).isRequired,
    additionalCharges: PropTypes.arrayOf(
      PropTypes.shape({
        amount: PropTypes.number,
      })
    ),
    total: PropTypes.number.isRequired,
    invoices: PropTypes.arrayOf(
      PropTypes.shape({
        date: PropTypes.string.isRequired,
        dueDate: PropTypes.string.isRequired,
        total: PropTypes.number.isRequired,
        amount: PropTypes.number.isRequired,
        balance: PropTypes.number.isRequired,
      })
    ),
    refunds: PropTypes.arrayOf(
      PropTypes.shape({
        date: PropTypes.string.isRequired,
        account: PropTypes.shape({
          name: PropTypes.string,
        }),
        observations: PropTypes.string,
        amount: PropTypes.number.isRequired,
      })
    ),
  }),
};

export default DetailBody;

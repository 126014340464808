import { I18n } from '@aws-amplify/core';
import { get, capitalize, isNull, isUndefined } from 'lodash';

export const validate = values => {
  let errors = {}
  
  const name = get(values, 'name', '');
  
  if (!name)
    errors.name = capitalize(I18n.get('enterAName', 'Ingresa un nombre'));

  return errors;
}

export const transform = values => {
  let newValues = {
    name: get(values, 'name', null),
    address: get(values, 'address', null),
    costCenter: !!get(values, 'costCenter.id', null) ? { id: get(values, 'costCenter.id', null) }: null,
    observations: get(values, 'observations', null),
  }

  Object.keys(newValues).map(key => {
    if (isNull(newValues[key]) || isUndefined(newValues[key]))
      delete newValues[key];
    return null;
  })

  return newValues;
}
import { createDraftSafeSelector } from '@reduxjs/toolkit';
import { get, deburr, lowerCase } from 'lodash';

import { regimesSelector } from '../reducers/regimes'
import { regime } from './company'

export const regimes = createDraftSafeSelector(
  regimesSelector.selectAll,
  regimes => !!regimes ? regimes: []
)

export const getCompanyRegime = createDraftSafeSelector(
  [regimes, regime],
  (regimes, regime) => {
    // En mexico el regimen con el key BUSINESS_ACTIVITIES_REGIME en AC se guarda el value como la desciption
    const mexRegime = regimes.find(r => get(r, 'key', '') === 'BUSINESS_ACTIVITIES_REGIME')
    if (regime !== '' && lowerCase(deburr(regime)) === lowerCase(deburr(get(mexRegime, 'description', '')))) {
      return !!regimes ? mexRegime : null
    }
    
    return !!regimes
      ? regimes.find(r => lowerCase(deburr(get(r, 'value', ''))) === lowerCase(deburr(regime)))
      : null
  }
)
import React from 'react';
import { Field } from 'react-final-form';
import { I18n } from '@aws-amplify/core';
import { capitalize } from 'lodash';

import { renderField } from '../fields/V0/Fields';

const ElectronicFields = () => {

  return (
    <div className="form-body__fields form-row justify-content-start">
      <Field
        name="tariffHeading"
        className="col-sm-6"
        component={renderField}
        type="text"
        label={capitalize(I18n.get('tariffHeading', 'Partida arancelaria'))}
        helpTooltip={capitalize(I18n.get('tariffHeadingHelp', 'De uso exclusivo para exportadores en la venta de mercancías'))}
      />

      <Field
        name="brand"
        component={renderField}
        className="col-sm-6"
        type="text"
        label={capitalize(I18n.get('brand', 'marca'))}
      />

      <Field
        name="model"
        component={renderField}
        className="col-sm-6"
        type="text"
        label={capitalize(I18n.get('model', 'modelo'))}
      />

    </div>
  )
}

export default ElectronicFields;

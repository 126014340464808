import { useMutation } from '@tanstack/react-query';
import { useHistory } from 'react-router-dom';
import { I18n } from '@aws-amplify/core';
import { get } from 'lodash';

import toastHandler from '../../../../utils/toast-handler';
import { graphqlOperation } from '@aws-amplify/api';

import * as mutations from '../../../../graphql/mutations';

import { handleError } from '../../../../utils/errors';
import { globalInvoiceConverterToApi } from '../converters/globalInvoiceToApi';
import { useDispatch } from 'react-redux';
import { closeModal, openModal } from '../../../../reducers/modals';
import { getObjectValueDifferences } from '../../../../utils/object-difference';

import { sendNewGTMEvent } from '../../../../reducers/company';

export const createGlobalInvoiceMutation = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  return useMutation(async ({ values, api }) => {
    try {
      const globalInvoice = globalInvoiceConverterToApi(values);
      const globalInvoiceInitialValues = globalInvoiceConverterToApi(
        values?.initialValues || values
      );
      const differencedValues = getObjectValueDifferences(
        globalInvoiceInitialValues,
        globalInvoice
      );

      if (!values?.isDraft) {
        dispatch(
          openModal({
            modal: 'loader',
            params: {
              title: I18n.get('', 'Enviando tu factura al SAT en 🛴'),
            },
          })
        );
      }

      const createdInvoice = values?.isEditable
        ? await api(
            graphqlOperation(mutations.updateGlobalInvoice, {
              globalInvoice: {
                ...differencedValues,
                stamp: globalInvoice.stamp,
                id: get(values, 'id'),
              },
            })
          )
        : await api(
            graphqlOperation(mutations.createGlobalInvoice, { globalInvoice })
          );

      if (values?.isDraft) {
        dispatch(
          sendNewGTMEvent('pos-global-invoice-created', {
            status: values?.isEditable
              ? get(createdInvoice, 'data.updateGlobalInvoice.status')
              : get(createdInvoice, 'data.createGlobalInvoice.status'),
          })
        );
        if (values?.isEditable) {
          toastHandler({
            type: 'success',
            description: `Tu factura global <b>No. ${get(
              createdInvoice,
              values?.isEditable
                ? 'data.updateGlobalInvoice.numberTemplate.number'
                : 'data.createGlobalInvoice.numberTemplate.number'
            )}</b> ya se guardó correctamente.`,
          });
        } else {
          toastHandler({
            type: 'success',
            description: `Tu factura global <b>No. ${get(
              createdInvoice,
              values?.isEditable
                ? 'data.updateGlobalInvoice.numberTemplate.number'
                : 'data.createGlobalInvoice.numberTemplate.number'
            )}</b> ya se guardó como borrador.`,
          });
        }
      }

      if (!values?.isDraft) {
        const stamped = values?.isEditable
          ? get(createdInvoice, 'data.updateGlobalInvoice.stamp.uuid')
          : get(createdInvoice, 'data.createGlobalInvoice.stamp.uuid');
        dispatch(
          sendNewGTMEvent('pos-global-invoice-created', {
            status: stamped
              ? 'stamped'
              : values?.isEditable
                ? get(createdInvoice, 'data.updateGlobalInvoice.status')
                : get(createdInvoice, 'data.createGlobalInvoice.status'),
          })
        );
        dispatch(closeModal({ modal: 'loader' }));

        toastHandler({
          type: 'success',
          description: `Tu factura global <b>No. ${get(
            createdInvoice,
            values?.isEditable
              ? 'data.updateGlobalInvoice.numberTemplate.number'
              : 'data.createGlobalInvoice.numberTemplate.number'
          )}</b> se envió al SAT y ya está timbrada.`,
        });
      }

      history.push(
        `/global-invoices/invoice/${get(
          createdInvoice,
          values?.isEditable
            ? 'data.updateGlobalInvoice.id'
            : 'data.createGlobalInvoice.id'
        )}/detail`
      );

      return true;
    } catch (error) {
      const responseError = get(error, 'response.data.message');
      const parsedError = !!responseError ? responseError : handleError(error);

      if (!values?.isDraft) {
        dispatch(closeModal({ modal: 'loader' }));
        dispatch(
          sendNewGTMEvent('pos-global-invoice-created', {
            emissionError: parsedError,
          })
        );
      } else {
        dispatch(
          sendNewGTMEvent('pos-global-invoice-created', {
            error: parsedError,
          })
        );
      }
      if (get(error, 'errors.0.data.id')) {
        history.push(
          `/global-invoices/invoice/${get(error, 'errors.0.data.id')}/detail`
        );
      }

      toastHandler({
        type: 'error',
        description: !!responseError ? responseError : handleError(error),
      });
    }
  });
};

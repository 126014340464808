import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import BigNumber from 'bignumber.js';

import { companySelector, country as countrySelector, membershipSelector } from '../../../selectors/company';
import { calculateItemsValues } from '../../../utils';

import { useFormat } from '../../../hooks/useFormat';
import PaymentsFactory from './paymentsStrategies/PaymentsFactory';

/**
 * Payments Component.
 *
 * @param {Object} props - Component props.
 * @param {number} props.tip - Tip amount.
 * @param {Array} props.items - Array of items.
 * @param {Object} props.setting - Settings object.
 * @returns {JSX.Element|null} - Rendered Payments Component or null if no items.
 */
const Payments = ({ tip, items, setting: { totalLines } }) => {
  const { decimal, fmt } = useFormat();
  const country = useSelector(countrySelector);
  const company = useSelector(companySelector);
  const membership = useSelector(membershipSelector);

  if (!items) return null;

  const {
    subtotal,
    total,
    taxes,
    discount,
    discSubtotal,
    totalWhitoutTips,
  } = calculateItemsValues({ items, decimal, country, company, membership, totalLines });

  const tipAmount = new BigNumber(tip);

  const itemsValues = {
    subtotal,
    total,
    taxes,
    discount,
    discSubtotal,
    totalWhitoutTips,
    decimal,
    fmt,
    totalLines,
    itemsLength: items.length,
    totalItems: items.reduce((acc, item) => acc + (item.quantity ?? 0), 0),
  };

  return (
    <PaymentsFactory
      country={country}
      tipAmount={tipAmount}
      itemsValues={itemsValues}
      align='right'
    />
  );
};

Payments.propTypes = {
  tip: PropTypes.number.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      quantity: PropTypes.number,
    })
  ).isRequired,
  setting: PropTypes.shape({
    totalLines: PropTypes.bool.isRequired,
  }).isRequired,
};

export default React.memo(Payments);

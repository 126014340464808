import { matchPath } from 'react-router-dom';
import { I18n } from '@aws-amplify/core';
import { useHeader } from '../context/header.context';
import { useDispatch } from 'react-redux';
import { openMenu } from '../../../reducers/app';
import Typography from '../../common/Typography';

import { Icon } from '@alegradev/smile-ui-react';
import { IconMenu2 } from '@tabler/icons-react';

function HamburgerMenu({ location }) {
  const dispatch = useDispatch();

  const { setActiveMenu } = useHeader();

  const renderLocation = () => {
    if (!!matchPath(location.pathname, { path: '/', exact: true }))
      return I18n.get('checkIn', 'Facturar');
    if (!!matchPath(location.pathname, { path: '/invoices', exact: false }))
      return I18n.get('sales', 'Ventas');
    if (!!matchPath(location.pathname, { path: '/shifts', exact: false }))
      return I18n.get('shifts', 'Turnos');
    if (!!matchPath(location.pathname, { path: '/payments', exact: false }))
      return I18n.get('cashManagement', 'Gestión de Efectivo');
    if (!!matchPath(location.pathname, { path: '/refunds', exact: false }))
      return I18n.get('refunds', 'Devoluciones');
    if (!!matchPath(location.pathname, { path: '/settings', exact: false }))
      return I18n.get('settings', 'Configuración');
    if (!!matchPath(location.pathname, { path: '/statistics', exact: false }))
      return I18n.get('statistics', 'Estadísticas');
    if (!!matchPath(location.pathname, { path: '/plan', exact: false }))
      return I18n.get('mySubscription', 'Mi suscripción');
    if (!!matchPath(location.pathname, { path: '/inventory', exact: false }))
      return I18n.get('inventory', 'Inventario');
    if (
      !!matchPath(location.pathname, { path: '/global-invoices', exact: false })
    )
      return I18n.get('globalInvoices', 'Facturas globales');
    if (
      !!matchPath(location.pathname, {
        path: '/electronic-documents',
        exact: false,
      })
    )
      return I18n.get('electronicDocuments', 'Documentos electrónicos');
    if (!!matchPath(location.pathname, { path: '/items', exact: false }))
      return I18n.get('itemsAndServices', 'Productos y servicios');
    if (!!matchPath(location.pathname, { path: '/contacts', exact: false }))
      return I18n.get('contacts', 'Contactos');
    return '';
  };

  return (
    <div className='d-flex h-100 align-items-center'>
      <div
        className='new-menu-item mr-3 ml-3'
        onClick={() => {
          setActiveMenu('');
          dispatch(openMenu());
        }}
      >
        <Icon icon={IconMenu2} extraClass='icon-secondary' />
      </div>

      <Typography
        type='body-2-regular'
        variant='secondary'
        text={renderLocation()}
      />
    </div>
  );
}

export default HamburgerMenu;

import { Form } from 'react-final-form';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import {
  currency,
  total,
  numeration as invoiceNumerationSelector,
  client as clientSelector,
  items as itemsSelector,
  paymentMethod,
  isPaymentMethodSelected,
} from '../../../selectors/activeInvoice';
import { getMainCurrency } from '../../../selectors/currencies';
import {
  country as countrySelector,
  anotation as companyAnotation,
  decimalPrecision,
} from '../../../selectors/company';
import { capitalize, get, some } from 'lodash';
import {
  station as stationSelector,
  stationInvoiceNumeration,
  electronicInvoicing,
  stationCashBank,
  stationDebitBank,
  stationCreditBank,
  stationTransferBank,
  stationSeller,
} from '../../../selectors/app';
import { useDecimalsVersionsGroup } from '../../../hooks/useDecimalsVersionsGroup';
import { I18n } from 'aws-amplify';
import NewTotal from '../../forms/newInvoice/components/NewTotal';
import { useDeviceType } from '../../../hooks/useDeviceType';
import NewBasicData from '../../forms/newInvoice/NewBasicData';
import Seller from '../seller/Seller';
import { Tabs } from '@alegradev/smile-ui-react';
import Notification from '../common/Notification';
import { replaceAndParse } from '../../../utils';
import NewModalMethods from '../../forms/newInvoice/methods/NewModalMethods';
import UseSubmitInvoice from '../invoiceRefactored/hooks/useSubmitInvoice';
import { Bottom } from './components/Bottom';
import { INVOICE_PAYMENT_METHODS } from '../invoiceRefactored/utils/constants';
import useTotalToCollect from '../../../hooks/useTotalToCollect/hook';
import { validate } from '../invoiceRefactored/utils/invoice';

const NewInvoice = () => {
  const [paymentsValues, setPaymentsValues] = useState({
    cash: null,
    debit: null,
    credit: null,
    transfer: null,
  });
  const [canCredit, setCanCredit] = useState(true);
  const [activeNequiPush, setActiveNequiPush] = useState(true);
  const totalPrice = useSelector(total);
  const mainCurrency = useSelector(getMainCurrency);
  const selectedCurrency = useSelector(currency);
  const country = useSelector(countrySelector);
  const decimal = useSelector(decimalPrecision);
  const mainNumeration = useSelector(stationInvoiceNumeration);
  const invoiceNumeration = useSelector(invoiceNumerationSelector);
  const numeration = !!invoiceNumeration ? invoiceNumeration : mainNumeration;
  const isElectronic = useSelector(electronicInvoicing(numeration));
  const defaultAnotation = useSelector(companyAnotation);
  const client = useSelector(clientSelector);
  const synchronizingClient = useSelector((state) =>
    get(state, 'clients.synchronizingClient', false)
  );
  const paymentMethodSelected = useSelector(paymentMethod);
  const isPaymentSelected = useSelector(isPaymentMethodSelected);
  const { isDecimalActive } = useDecimalsVersionsGroup();

  const { totalToCollect, isTotalToCollectLoading } = useTotalToCollect({
    country,
    client,
    oldModal: false,
  });

  const { submit, loading } = UseSubmitInvoice({ isNewModal: true });

  const deviceType = useDeviceType();

  const currencyCode = !!get(selectedCurrency, 'code')
    ? get(selectedCurrency, 'code')
    : !!get(mainCurrency, 'code')
      ? get(mainCurrency, 'code')
      : '';

  const cashBank = useSelector(stationCashBank);
  const debitBank = useSelector(stationDebitBank);
  const creditBank = useSelector(stationCreditBank);
  const transferBank = useSelector(stationTransferBank);
  const seller = useSelector(stationSeller);

  const getMethodName = (method) => {
    if (!method) {
      return null;
    }
    if (
      method !== 'cash' &&
      method !== 'debit' &&
      method !== 'credit' &&
      method !== 'transfer' &&
      method !== 'combined'
    ) {
      return 'transfer';
    }
    return method;
  };

  return (
    <Form
      onSubmit={submit}
      validate={(values) =>
        validate({
          ...values,
          country,
          decimal,
          isDecimalActive,
          total: totalPrice,
        })
      }
      initialValues={{
        cashBank,
        debitBank,
        creditBank,
        transferBank,
        numeration,
        seller,
        anotation: defaultAnotation,
        paymentMethod: {
          value: 'CASH',
          label: capitalize(I18n.get('paymentForm.Cash', 'Contado')),
        },
        email: get(client, 'email', null),
        phone: get(client, 'mobile', null),
        method: getMethodName(paymentMethodSelected),
        paymentMethodSelected,
        ...paymentsValues,
      }}
      keepDirtyOnReinitialize
    >
      {({
        handleSubmit,
        values,
        form,
        error,
        pristine,
        submitting,
        submitError,
        submitFailed,
      }) => {
        const anotationMaxLimit =
          country === 'republicaDominicana' &&
          isElectronic &&
          get(values, 'anotation.length', 0) > 250;

        return (
          <div className='modal__invoice'>
            <form
              noValidate
              data-testid='new-form-check-in'
              onSubmit={handleSubmit}
            >
              <div className='d-flex flex-column gap-2 modal__invoice-body'>
                <div className='d-flex flex-column justify-content-center align-items-center gap-3'>
                  <div className='w-100'>
                    <NewTotal values={values} form={form} total={totalPrice} />
                  </div>
                  {values.method === 'nequiPush' &&
                    !values.transfer &&
                    isPaymentSelected && (
                      <div className='w-100'>
                        <Tabs
                          direction='column'
                          defaultTab={activeNequiPush ? 1 : 2}
                          items={[
                            {
                              id: 1,
                              title: I18n.get(
                                'sendRequestToApp',
                                'Enviar solicitud a la app'
                              ),
                            },
                            {
                              id: 2,
                              title: I18n.get(
                                'scanQRCode',
                                'Escanear código QR'
                              ),
                            },
                          ]}
                          onTabChanged={(value) => {
                            setActiveNequiPush(value === 1);
                          }}
                          simple
                        />
                      </div>
                    )}
                  <div className='d-flex flex-column align-items-center w-100'>
                    <div
                      className={`${
                        deviceType !== 'mobile'
                          ? 'payment-method-container'
                          : 'px-0'
                      } col-sm-12`}
                    >
                      <div
                        className={
                          !!values.method && isPaymentSelected ? 'd-none' : ''
                        }
                      >
                        <NewModalMethods
                          form={form}
                          total={totalPrice}
                          paymentsValues={paymentsValues}
                          setPaymentsValues={setPaymentsValues}
                        />
                      </div>
                    </div>
                    <div
                      className={`flex-column flex-sm-row w-100 ${
                        !!values.method && !isPaymentSelected
                          ? 'd-none'
                          : 'd-flex'
                      }`}
                    >
                      <div className='w-100 modal__invoice-basic-data'>
                        <NewBasicData
                          anotationMaxLimit={anotationMaxLimit}
                          values={values}
                          form={form}
                          total={totalPrice}
                          currencyCode={currencyCode}
                          activeNequiPush={activeNequiPush}
                          totalToCollect={totalToCollect}
                          isTotalToCollectLoading={isTotalToCollectLoading}
                          canCredit={canCredit}
                          setCanCredit={setCanCredit}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {!!error && !!submitFailed && (
                <Notification
                  isOpen={true}
                  text={replaceAndParse(error)}
                  type='error'
                />
              )}
              {!!submitError && !!submitFailed && (
                <Notification
                  isOpen={true}
                  text={replaceAndParse(submitError)}
                  type='error'
                />
              )}
              <Bottom
                primaryButton={{
                  text: !isPaymentSelected
                    ? I18n.get('continue', 'Continuar')
                    : I18n.get('sell', 'Vender'),
                  loading: submitting || (synchronizingClient && isElectronic),
                  disabled:
                    some(values?.decimalsError, (value) => value) ||
                    isTotalToCollectLoading ||
                    submitting ||
                    (synchronizingClient && isElectronic) ||
                    anotationMaxLimit ||
                    ([
                      INVOICE_PAYMENT_METHODS.BANCOLOMBIA,
                      INVOICE_PAYMENT_METHODS.NEQUI,
                    ].includes(get(values, 'method')) &&
                      !values.transfer) ||
                    !canCredit ||
                    !isPaymentSelected,
                }}
              />
            </form>
            <Seller onCreated={(seller) => form.change('seller', seller)} />
          </div>
        );
      }}
    </Form>
  );
};

export default NewInvoice;

import { isObject } from 'lodash';
import { basicPOSClientData } from '../../../utils';
import { COUNTRIES } from '../../../utils/enums/countries';

function isSameName(contact, defaultClient) {
  if (!defaultClient || !contact) return false;

  if (
    typeof contact.name === 'string' &&
    typeof defaultClient.name === 'string'
  ) {
    return contact.name === defaultClient.name;
  }

  if (typeof contact.name === 'string' && isObject(defaultClient.name)) {
    const defaultFullName =
      `${defaultClient.name.firstName || ''} ${defaultClient.name.lastName || ''}`.trim();
    return contact.name === defaultFullName;
  }

  if (isObject(contact.name) && typeof defaultClient.name === 'string') {
    const contactFullName =
      `${contact.name.firstName || ''} ${contact.name.lastName || ''}`.trim();
    return contactFullName === defaultClient.name;
  }

  if (isObject(contact.name) && isObject(defaultClient.name)) {
    const contactFullName =
      `${contact.name.firstName || ''} ${contact.name.lastName || ''}`.trim();
    const defaultFullName =
      `${defaultClient.name.firstName || ''} ${defaultClient.name.lastName || ''}`.trim();
    return contactFullName === defaultFullName;
  }

  return false;
}

function isSameIdentification(contact, defaultClient) {
  const contactId = contact?.identification?.number || contact?.identification;
  const defaultClientId =
    defaultClient?.identification?.number || defaultClient?.identification;

  if (
    (typeof contactId === 'string' || typeof contactId === 'number') &&
    (typeof defaultClientId === 'string' || typeof defaultClientId === 'number')
  ) {
    return contactId === defaultClientId;
  }

  if (
    typeof contactId === 'string' &&
    isObject(defaultClient?.identification)
  ) {
    return contactId === defaultClient.identification.number;
  }

  if (
    isObject(contact?.identification) &&
    typeof defaultClientId === 'string'
  ) {
    return contact.identification.number === defaultClientId;
  }

  if (
    isObject(contact?.identification) &&
    isObject(defaultClient?.identification)
  ) {
    return (
      contact.identification.number === defaultClient.identification.number &&
      contact.identification.type === defaultClient.identification.type
    );
  }

  return false;
}

function checkIfIsDefaultClient(contact, country) {
  const defaultClient = basicPOSClientData(country);
  if (!defaultClient) return false;

  const countriesWithSameValidation = [
    COUNTRIES.ARGENTINA,
    COUNTRIES.SPAIN,
    COUNTRIES.PERU,
    COUNTRIES.MEXICO,
    COUNTRIES.COSTA_RICA,
  ];

  if (countriesWithSameValidation.includes(country)) {
    return (
      isSameName(contact, defaultClient) &&
      isSameIdentification(contact, defaultClient)
    );
  }
  
  switch (country) {
    case COUNTRIES.COLOMBIA:
      return (
        isSameName(contact, defaultClient) &&
        contact?.identificationObject?.type === 'CC' &&
        isSameIdentification(contact, defaultClient)
      );
    case COUNTRIES.REPUBLICA_DOMINICANA:
      return isSameName(contact, defaultClient) && !contact?.identification;
    case COUNTRIES.USA:
    case COUNTRIES.INTERNATIONAL:
      return contact.name === 'POS' && !contact?.identification;
    default:
      return (
        isSameName(contact, defaultClient) ||
        isSameIdentification(contact, defaultClient)
      );
  }
}

export default checkIfIsDefaultClient;

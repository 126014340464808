import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Field, Form } from 'react-final-form';
import { I18n } from '@aws-amplify/core';
import { setAditionalSettings } from '../../../reducers/aditionalSettings';
import { renderListCheckbox } from '../../forms/fields/V0/Fields';
import { economicActivitiesSelector } from '../../../selectors/auth';

let options = [
  { value: 'priceList', label: I18n.get('priceList', 'Lista de precios') },
  { value: 'economicActivity', label: I18n.get('economicActivity', 'Actividad económica') },
];

const InvoiceSettings = () => {
  const refs = useRef(options.map(() => React.createRef()));
  const dispatch = useDispatch();
  const aditionalSettings = useSelector(state => state.aditionalSettings.settings);
  const activities = useSelector(economicActivitiesSelector);

  const submit = () => {};

  const handleChecked = (e) => {
    const { value, checked } = e.target;
    dispatch(setAditionalSettings({ value, checked }))
  }

  const loadSettings = () => {
    Object.keys(aditionalSettings).forEach(value => {
      let checked = aditionalSettings[value]
      onChangeChecked(value, checked)
    })
  }

  const onChangeChecked = (activity, isChecked) => {
    refs.current.length > 0 && refs.current.forEach(ref => {
      if (ref.current.value === activity) {
        ref.current.checked = isChecked;
        return;
      }
    })
  }

  useEffect(() => {
    if (activities.length === 0) {
      options = options.filter(option => option.value !== 'economicActivity');
    } else {
      const existEconomicActivityOption = options.find(option => option.value === 'economicActivity');
      if (!existEconomicActivityOption) {
        options.push({ value: 'economicActivity', label: I18n.get('economicActivity', 'Actividad económica') });
      }
    }
    loadSettings();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activities])

  return (
    <div className="invoice-top__header-invoice-settings">
      <div className="triangle" />
      <h3 className="text-center py-4 border-bottom">{I18n.get('addOptions', 'Agregar opciones')}</h3>

      <Form onSubmit={submit} initialValues={{ countPage: '1' }} keepDirtyOnReinitialize>
        {({ handleSubmit, submitting, values, submitError, pristine }) => (
          <form noValidate onSubmit={handleSubmit}>
            <Field name="options" component={renderListCheckbox} options={options} type="checkbox" onChange={handleChecked} refs={refs} />
          </form>
        )}
      </Form>
    </div>
  );
};

export default InvoiceSettings;

import React, { useMemo, useEffect } from "react";
import BigNumber from "bignumber.js";
import arrayMutators from 'final-form-arrays'
import { Form } from "react-final-form";
import { useHistory } from "react-router-dom";
import { I18n } from "aws-amplify";
import { get } from "lodash";

import { checkFeatureLimit } from "../../../../reducers/membership";
import { useFormat } from "../../../../hooks/useFormat";
import { replaceAndParse, styleBigNumber } from "../../../../utils";
import { validate, transform, transformOptimisticUpdate } from "../../../forms/inventoryAdjustment/utils";
import { useCreateInventoryAdjustment } from "../mutations";
import { toast } from "../../../../utils";
import { handleError } from "../../../../utils/errors";
import { useDispatch, useSelector } from "react-redux";
import { stationWarehouse } from "../../../../selectors/app";
import { hasPermissionTo } from "../../../../selectors/auth";
import { sendGTMEvent } from "../../../../reducers/company";

import Body from "../../../forms/inventoryAdjustment/InventoryAdjustment";
import Bottom from "./Bottom";

import { Icon } from '@alegradev/smile-ui-react';
import { IconArrowLeft, IconLoader2 } from "@tabler/icons-react";

const useShowToastAndRedirect = ({ isPaused, isError, isSuccess, error, createdInventoryAdjustment }) => {
  const history = useHistory();

  useEffect(() => {
    if (isPaused) {
      history.push('/inventory/inventory-adjustments');
      toast.success({
        title: I18n.get('inventoryAdjustmentCreatedLocally.title', 'Ajuste de inventario creado localmente.'),
        subtitle: I18n.get('inventoryAdjustmentCreatedLocally.subtitle', 'La acción se sincronizará automaticamente cuando se reestablezca la conexión.')
      })
    }
    if (isError) {
      const responseError = get(error, 'response.data.message', '')
      toast.error({
        title: I18n.get('inventoryAdjustmentCreateError', 'Error creando ajuste de inventario'),
        subtitle: !!responseError ? responseError : handleError(error),
      });
    }
    if (isSuccess) {
      history.push('/inventory/inventory-adjustments');
      toast.success({
        title: I18n.get('inventoryAdjustmentCreated.title', '¡Ajustado! ⚖'),
        subtitle: replaceAndParse(I18n.get('inventoryAdjustmentCreated.subtitle', 'Ya se guardó el ajuste <span class="font-weight-bold">No. {}</span> y se hicieron los movimientos de cantidades.'), [
          get(createdInventoryAdjustment, 'data.number')
        ])
      })
    }
  }, [isSuccess, isPaused, isError, error, history, createdInventoryAdjustment])
}

export const Total = ({ values, form }) => {
  const { decimal, fmt } = useFormat();
  const items = get(values, "items", []);

  const calculateTotal = useMemo(() => {
    const total = items.reduce((acc, current) => {
      const subTotal = new BigNumber(get(current, 'cost', 0) * get(current, 'quantity', 0) * (get(current, 'objective.key') === 'in' ? 1 : -1));
      return subTotal.plus(acc)
    }, new BigNumber("0"))
    const totalFormatted = total.toFormat(decimal, fmt);
    const {
      intPart: totalIntPart,
      decPart: totalDecPart
    } = styleBigNumber(totalFormatted, decimal);
    return {
      totalFormatted,
      totalIntPart,
      totalDecPart
    };
  }, [items, decimal, fmt])

  useEffect(() => {
    form.change('totalAdjusted', calculateTotal.totalFormatted);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [calculateTotal.totalFormatted])

  return (
    <div className="new-inventory-adjustment-total">
      <p className="h4 m-0">{I18n.get("total", "Total")}:</p>
      <p className="h4 text-truncate m-0">
        {calculateTotal.totalIntPart}
      </p>
      <p className="h4text-truncate text-muted m-0">
        {calculateTotal.totalDecPart}
      </p>
    </div>
  )
}

const NewIventoryAdjustment = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const can = useSelector(hasPermissionTo);
  const warehouse = useSelector(stationWarehouse);
  const filters = get(history, 'location.state.filters');
  const { mutate, isSuccess, isPaused, isError, error, isLoading, data: createdInventoryAdjustment = {} } = useCreateInventoryAdjustment();

  const submit = async (values, errors) => {
    if (!can("add", "inventory-adjustments"))
      return;

    const transformedValues = transform(values);
    const optimisticValues = transformOptimisticUpdate(values);

    mutate({ inventoryAdjustment: transformedValues, optimisticValues, filters });
  }

  useShowToastAndRedirect({ isSuccess, isPaused, isError, error, createdInventoryAdjustment });

  return (
    <div className="container p-5">
      <div className="new-inventory-adjustment-header">
        <button className="btn-round d-flex justify-content-center align-items-center"
          onClick={() => {
            dispatch(sendGTMEvent("new-inventory-adjustment-canceled"));
            history.goBack()
          }}
        >
          <Icon icon={IconArrowLeft} color={"#0F172A"} />
        </button>
        <div>
          <p className="h2">{I18n.get("newInventoryAdjustment", "Nuevo ajuste de inventario")}</p>
          <p className="h4 text-muted">{I18n.get("newInventoryAdjustmentSubtitle", "Modifica las cantidades de los productos que tienes en la bodega de tu terminal actual.")}</p>
        </div>
      </div>

      {(isLoading && !isPaused)
        ? (
          <div className="new-inventory-adjustment-form-container d-flex justify-content-center align-items-center h-2 ">
            <Icon icon={IconLoader2} animated extraClass=" icon-primary icon x2" />
          </div>
        )
        : (
          <Form
            // onSubmit={submit}
            onSubmit={(values) => dispatch(checkFeatureLimit("inventoryAdjustments", () => submit(values)))}
            mutators={{
              ...arrayMutators,
            }}
            initialValues={{
              items: [{
                allowCostChange: false,
                totalAdjusted: 0,
              }],
              totalAdjusted: 0,
              warehouse,
            }}
            validate={values => validate(values)}
            keepDirtyOnReinitialize
          >
            {({ handleSubmit, submitting, form, values, submitError, pristine, errors }) => (
              <form noValidate onSubmit={handleSubmit}>
                <div className="new-inventory-adjustment-form-container">
                  <div className="d-flex align-items-center w-100"
                    style={{
                      height: "62px", padding: "16px 48px", gap: "12px", borderBottom: "1px solid #E2E8F0"
                    }}
                  >
                    <div className="d-flex flex-column justify-content-center align-items-start p-0"
                      style={{ height: "50px" }}
                    >
                      <p
                        className="p-0 m-0"
                        style={{ fontStyle: "normal", fontWeight: "600", fontSize: "18px", lineHeight: "30px" }}
                      >
                        {`${I18n.get("warehouse", "Bodega")}: ${warehouse?.name}`}
                      </p>
                    </div>
                  </div>
                  <Body
                    form={form}
                    values={values}
                  />
                </div>

                <Total
                  values={values}
                  form={form}
                />

                <Bottom
                  onConfirm={!!get(errors?.items?.filter(a => a), '0.quantity')
                    ? () => toast.warning({
                      title: I18n.get(`${get(errors, 'items.0.quantity')}.title`, 'Indica la cantidad a ajustar'),
                      subtitle: replaceAndParse(I18n.get(`${get(errors, 'items.0.quantity')}.subtitle`, 'Ten en cuenta que los valores de la columna <span class="font-weight-bold">“Cantidad”</span> deben ser diferentes a cero.')),
                    })
                    : null}
                  submitting={submitting}
                  submitText={I18n.get("saveInventoryAdjustment", "Guardar ajuste")}
                  onClose={() => {
                    dispatch(sendGTMEvent("new-inventory-adjustment-canceled"));
                    history.goBack();
                  }}
                  disabled={!!get(errors, 'noItem')}
                  tooltipActive={!!get(errors, 'noItem')}
                  tooltipMessage={I18n.get("noItemsInInventoryAdjustmentError", "Debes agregar al menos un producto a tu ajuste")}
                />
              </form>
            )}
          </Form>
        )
      }
    </div>
  );
};

export default NewIventoryAdjustment;
import { I18n } from '@aws-amplify/core';
import { capitalize } from 'lodash'

export const personTypes = [
  {
    id: "LEGAL_ENTITY",
    name: capitalize(I18n.get('legalPerson', 'Persona jurídica')),
  },
  {
    id: "PERSON_ENTITY",
    name: capitalize(I18n.get('naturalPerson', 'Persona natural')),
  },
]; 

import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { graphqlOperation } from '@aws-amplify/api';
import { I18n } from 'aws-amplify';
import { Form } from 'react-final-form';
import { get, isEmpty } from 'lodash';

import { stations as stationsSelector } from '../../../selectors/station';
import { APIGraphqlSelector, station as stationSelector } from '../../../selectors/app'
import { getElectronicNumerations } from '../../../selectors/numerations';
import { updateStation } from '../../../reducers/app'
import { closeModal } from '../../../reducers/modals';
import { fetchNumerations } from '../../../reducers/numerations';

import * as mutations from '../../../graphql/mutations';
import { transform } from '../../forms/stationSetting5UVT/utils'
import { formError } from '../../../utils/errors';
import { stationTransformer } from '../../../utils/transformers';
import { toast, replaceAndParse } from '../../../utils';
import Notification from '../common/Notification';
import Modal from '../../common/Modal';
import NumerationFields from './NumerationsFields';
import { fetchStations } from '../../../reducers/stations';
import { getColombiaUVT } from '../../../utils/enums/colombiaUVT';
import { Tooltip } from '@alegradev/smile-ui-react';

import { Icon } from '@alegradev/smile-ui-react';
import { IconLoader2 } from '@tabler/icons-react';

const EditFENumerationTerminal = () => {
  const [initialValues, setInitialValues] = useState({});
  const [loading, setLoading] = useState(false);
  const [loadingNumerations, setLoadingNumerations] = useState(false);
  const stations = useSelector(stationsSelector)
  const isOpen = useSelector(state => get(state, 'modals.editFENumerationTerminal.isOpen', false));
  const numerations = useSelector(getElectronicNumerations)
  const currentStation = useSelector(stationSelector)
  const APIGraphql = useSelector(APIGraphqlSelector);
  const colombiaUVT = getColombiaUVT();
  const dispatch = useDispatch()

  useEffect(() => {
    async function loadNumerations() {
      setLoadingNumerations(prev => !prev)
      await dispatch(fetchNumerations({ preloaded: null }))
      await dispatch(fetchStations())
      setLoadingNumerations(prev => !prev)
    }
    if (isOpen)
      loadNumerations()
  }, [dispatch, isOpen])

  useEffect(() => {
    let _initialValues = {};
    let updatedStations = !!stations ? stations.filter(s => Number(s.id) !== Number(currentStation.id)) : []
    updatedStations.push(currentStation)
    if (numerations.length > 0)
      updatedStations.forEach(s => {
        const selectedNumeration = numerations.some(n => Number(n.id) === Number(get(s, 'idFENumberTemplate')))
          ? numerations.find(n => Number(n.id) === Number(get(s, 'idFENumberTemplate')))
          : numerations[0];
        _initialValues['k' + s.id] = { id: selectedNumeration.id, name: selectedNumeration.name };
      });
    setInitialValues(_initialValues);
  }, [stations, numerations, currentStation])

  const close = () => {
    dispatch(closeModal({ modal: 'editFENumerationTerminal' }))
  }

  const submit = async values => {
    try {
      setLoading(true)

      for (const s of stations) {
        const transformedValues = transform(values, 'k' + s.id)
        if (Number(get(s, 'idFENumberTemplate')) === Number(get(transformedValues, 'idFENumberTemplate')))
          continue;
       
        const transformedStationToApi = stationTransformer({ ...s, ...transformedValues });
        const response = await APIGraphql(graphqlOperation(mutations.updateStation, {
          station: transformedStationToApi
        }))

        const station = get(response, 'data.updateStation', null);
        if (currentStation.id === station.id)
          dispatch(updateStation(station))
      }

      setLoading(false)
      close();

      toast.success({
        title: `${I18n.get('5uvtActivatedSuccessfully2', '¡Todo Listo!  ✨')}`,
        subtitle: replaceAndParse(I18n.get('terminalConfiguredNumeration', 'Los cambios se guardaron con éxito en la configuración de tus facturas.'), [])
      })
    } catch (error) {
      setLoading(false)
      return formError(error, I18n.get('updateStationsError', 'hubo un error en la actualización de las terminales'))
    }
    setLoading(false)
  }

  return (
    <>
      <Modal
        isOpen={isOpen}
        className="modal__select-numerations"
        customHeader={(
          <div className="d-flex flex-column justify-content-between w-100">
            <div className="h3 font-weight-bold px-4 pt-4">
              {I18n.get('editNumerations', 'Editar numeraciones')}
            </div>
            <div className="h4 px-4 uvt-description">
              {I18n.get('editNumerations.description', `Cambia la numeración de las facturas electrónicas que se creen en lugar de documento POS si la venta supera $${colombiaUVT?.UVT_FORMATED} antes de IVA.`)}
            </div>
          </div>
        )}
        closeAction={() => close(false)}>
        <Form
          onSubmit={submit}
          initialValues={initialValues}
        >
          {({ handleSubmit, values, form, error, submitting, submitError, submitFailed, errors }) => (
            <form noValidate onSubmit={handleSubmit}>
              {
                loadingNumerations
                  ? <div className="d-flex justify-content-center align-items-center px-4">
                    <Icon icon={IconLoader2} animated extraClass=" icon-primary" />
                  </div>
                  : <NumerationFields type={values.type} values={values} form={form} />
              }

              {(!!error && !!submitFailed) && (
                <Notification isOpen={true} text={error} type="error" />
              )}

              {(!!submitError && !!submitFailed) && (
                <Notification isOpen={true} text={submitError} type="error" />
              )}

              <div className="modal__bottom w-100 p-3 px-4 d-flex justify-content-end align-items-center shadow">
                <div className="modal__bottom-buttons d-flex">
                  <button type="button"
                    onClick={close}
                    className="btn btn-cancel text-capitalize-first">
                    {I18n.get("cancel", "Cancelar")}
                  </button>
                  <Tooltip
                    visible={isEmpty(values) || !isEmpty(errors)}
                    overlay={replaceAndParse(I18n.get("noElectronicNumeration", "Debes {} para configurarla en tu terminal actual."), [
                      `<a href="https://ayuda.alegra.com/es/pasos-habilitacion-facturador-electronico-dian-colombia#paso-5" target="_blank" rel="noreferrer">
                       ${I18n.get('noElectronicNumeration1', 'crear una numeración electrónica')}
                    </a>`
                    ])}
                    placement="top"
                  >
                    <button
                      type="submit"
                      data-testid="modal-submit-btn"
                      disabled={!!submitting || loading || isEmpty(values) || !isEmpty(errors)}
                      className="btn btn-submit absolute-center text-capitalize-first">
                      {submitting || loading
                        ? <Icon icon={IconLoader2} animated extraClass=" icon-white" />
                        : I18n.get('saveChanges', 'Guardar cambios')
                      }
                    </button>
                  </Tooltip>
                </div>
              </div>
            </form>
          )}
        </Form>
      </Modal>
    </>
  )
}

export default EditFENumerationTerminal
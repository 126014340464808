import { isNumber, get } from 'lodash'
import { BigNumber } from 'bignumber.js'

const initialValues = (item, decimal) => {
  if (!item) return {};

  return ({
    ...item,
    price: new BigNumber(item.price).decimalPlaces(decimal).toNumber(),
    discount: !!isNumber(get(item, 'discount.discount', null)) 
      ? get(item, 'discount.discount') : !!get(item, 'discount', null)
      ? get(item, 'discount') : 0
  })
}

export default initialValues;
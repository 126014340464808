import React from 'react';
import { Field } from 'react-final-form';
import { I18n } from '@aws-amplify/core';
import { get, capitalize } from 'lodash';

import { renderField } from '../../../../../components/forms/fields/V2';
import { Grid } from '@alegradev/smile-ui-react';
import { regimes } from '../../../../../components/countriesData/colombia/regimes';
import { COUNTRIES } from '../../../../../utils/enums/countries';

const renderTaxLiability = (props) => {
  const { Col } = Grid;
  const identification = get(props, 'values.identification.type', '');

  if (props.country !== COUNTRIES.COLOMBIA || identification !== 'NIT')
    return null;

  const regimeOptions = regimes.filter((regime) =>
    props.isElectronic
      ? regime.electronicInvoicingVersion === '2.1'
      : regime.electronicInvoicingVersion === '2.0'
  );

  return (
    <Col xs={6}>
      <Field
        name='regime'
        component={renderField}
        fieldType='select'
        label={capitalize(
          I18n.get('taxLiability', 'responsabilidad tributaria')
        )}
        options={regimeOptions?.map((option) => ({
          text: option.display,
          value: option.key,
        }))}
        required={props.isElectronic}
      />
    </Col>
  );
};

export default renderTaxLiability;

import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form } from 'react-final-form';
import { I18n } from '@aws-amplify/core';
import { get, find } from 'lodash';

import { toast } from '../../../utils';
import { formError, handleError } from '../../../utils/errors';
import { warehouseTransformer } from '../../../utils/transformers';
import { closeModal, openModal } from '../../../reducers/modals';
import {
  address as addressSelector,
  country as countrySelector,
} from '../../../selectors/company';
import useElectronicPayrollStatus from '../../../hooks/useElectronicPayrollStatus/hook';
import { validate, transform } from '../../forms/warehouse/utils';
import { useCreateWarehouse, useUpdateWarehouse } from '../../inventory/Warehouses/mutations';

import Modal from '../../common/Modal';
import Notification from '../common/Notification';
import Bottom from '../common/Bottom';
import Body from '../../forms/warehouse/Warehouse';

import { COUNTRIES } from '../../../utils/enums/countries';
import peruAddress from '../../countriesData/peru/address.json';

const Warehouse = ({ onCreated }) => {
  const dispatch = useDispatch();
  const isOpen = useSelector((state) =>
    get(state, 'modals.warehouse.isOpen', false)
  );
  const warehouse = useSelector((state) =>
    get(state, 'modals.warehouse.params.warehouse', false)
  );
  const onRefresh = useSelector((state) =>
    get(state, 'modals.warehouse.params.onRefresh', false)
  );
  const { isActive } = useElectronicPayrollStatus();
  const country = useSelector(countrySelector);
  const address = useSelector(addressSelector);
  const [resetForm, setResetForm] = useState(false);

  const { mutate: updateWarehouseMutation, isLoading: isLoadingUpdate } = useUpdateWarehouse();
  const { mutate: createWarehouseMutation, isLoading: isLoadingCreate } = useCreateWarehouse();

  const close = () => {
    dispatch(closeModal({ modal: 'warehouse' }));
  };

  const open = () => {
    dispatch(
      openModal({
        modal: 'warehouse',
        params: {
          onRefresh,
        },
      })
    );
  };

  const updateObjectKeys = (currentObject, newObject) => {
    Object.keys(currentObject).forEach((key) => {
      if (!Object.keys(newObject).includes(key) && key !== 'id') {
        delete currentObject[key];
      }
    })
    return { ...currentObject, ...newObject };
  }

  const submit = async (values) => {
    let transformedValues = transform(values);

    if (isActive && country === COUNTRIES.PERU) {
      transformedValues.address = {
        district: find(peruAddress, { ubigeo: address?.ubigeoCode }),
        description:
          transformedValues?.address || warehouse?.address || address?.address,
      };
    }

    if (!!warehouse) {
      updateWarehouseMutation({
        warehouse: warehouseTransformer(updateObjectKeys({ ...warehouse }, { ...transformedValues }))
      }, {
        onSuccess: () => {
          close();
          toast.success({
            title: I18n.get('warehouseUpdated', 'bodega actualizada con éxito'),
          });
        },
        onError: (error) => {
          close();
          toast.error({
            title: I18n.get('updateWarehouseError', 'hubo un error al cambiar los datos de la bodega'),
            subtitle: handleError(error)
          })
          return formError(
            error,
            I18n.get('updateWarehouseError', 'hubo un error al cambiar los datos de la bodega')
          );
        }
      })
    } else {
      createWarehouseMutation({
        warehouse: warehouseTransformer({ ...transformedValues })
      }, {
        onSuccess: (data) => {
          close()
          if (!!onCreated) {
            onCreated(data?.data)
          }
        }
      })
    }

    if (resetForm) {
      open();
      setResetForm(false);
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={() => dispatch(closeModal({ modal: 'warehouse' }))}
      title={
        <div className='d-flex justify-content-between align-items-center w-100'>
          <div className='modal__header-title text-capitalize-first h2 text-primary text-truncate'>
            {!!warehouse
              ? warehouse.name
              : I18n.get('newWarehouse', 'Nueva bodega')}
          </div>
        </div>
      }
      className='modal__medium-size'
    >
      <Form
        onSubmit={submit}
        validate={(values) => validate(values)}
        initialValues={warehouse}
        keepDirtyOnReinitialize
      >
        {({ handleSubmit, submitting, values, submitError, pristine }) => (
          <form noValidate onSubmit={handleSubmit}>
            <div className='px-5 pb-3'>
              <p className='h5 py-4 text-subtitle-muted text-capitalize-first'>
                {I18n.get(
                  'warehouseModalSubtitle',
                  'Crea todos los puntos de almacenamiento y distribución de tus productos'
                )}
              </p>
              <Body values={values} />
            </div>

            {!!submitError && !pristine && (
              <Notification isOpen={true} text={submitError} type='error' />
            )}

            <Bottom
              submitting={isLoadingUpdate || isLoadingCreate}
              onClose={() => close()}
              onResetForm={setResetForm}
              resetForm={resetForm}
              hideRequired={true}
              submitText={
                !!warehouse
                  ? I18n.get('updateWarehouse', 'Actualizar bodega')
                  : I18n.get('createWarehouse', 'Crear bodega')
              }
              saveText={
                !!warehouse
                  ? I18n.get(
                    country === 'argentina'
                      ? 'updateAndCreateNewAlt'
                      : 'updateAndCreateNew',
                    'Actualizar y crear nueva'
                  )
                  : I18n.get(
                    country === 'argentina'
                      ? 'saveAndCreateNewAlt'
                      : 'saveAndCreateNew',
                    'Guardar y crea nueva'
                  )
              }
              hideCancel
            />
          </form>
        )}
      </Form>
    </Modal>
  );
};

export default Warehouse;

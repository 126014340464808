import { useEffect, useState } from 'react';
import { generateConfirmationCode } from '../../components/modals/NewLogoutConfirm/utils';

/**
 * Hook that generates a random confirmation code on mount and returns it.
 *
 * The confirmation code is a 4-digit number that is generated using the
 * generateConfirmationCode util.
 *
 * @return {Object} An object with a confirmationCode property.
 */
const useLogoutConfirmationCode = () => {
  const [confirmationCode, setConfirmationCode] = useState('');

  useEffect(() => {
    setConfirmationCode(generateConfirmationCode());
  }, []);

  return { confirmationCode };
};

export default useLogoutConfirmationCode;

import React from 'react'
import PropTypes from 'prop-types';
import { I18n } from '@aws-amplify/core';
import { useSelector, useDispatch } from 'react-redux';
import { Form } from 'react-final-form';
import { FORM_ERROR } from 'final-form';
import { get, some } from 'lodash'

import { updateItem, itemReachedMinQuantity, singleItemReachedMinQuantityFromValues, getKitMaximumQuantity } from '../../../../reducers/editInvoice';
import { hasPermissionTo, permissionValue } from '../../../../selectors/auth';
import { itemByIndex, currency as currencySelector } from '../../../../selectors/editInvoice';
import { decimalPrecision, country as countrySelector } from '../../../../selectors/company';
import { toast, replaceAndParse } from '../../../../utils';
import Body from '../../../forms/editItem/EditItem';
import { transform, validate } from '../../../forms/editItem/utils';
import Notification from '../../common/Notification';
import Bottom from '../../common/Bottom';
import Summary from './Summary';
import initialValues from './InitialValues';

const checkPermissions = (form, can, permission) => {
  const priceState = form.getFieldState('price')
  const discountState = form.getFieldState('discount')

  if (!!priceState && !get(priceState, 'pristine')) {
    if (!can('edit-items-prices', 'invoices'))
      return I18n.get('userNotAllowed.invoices.edit-items-prices', 'no tienes permisos para editar el precio de los productos')
  }
  if (!!discountState && !get(discountState, 'pristine')) {
    if (!can('edit_discount', 'invoices'))
      return I18n.get('userNotAllowed.invoices.edit-discount', 'no tienes permisos para editar el descuento de los productos')

    if (+permission('max-discount-allow', 'invoices') < +get(discountState, 'value', 0))
      return I18n.get('userNotAllowed.invoices.max-discount-allow', 'el porcentaje máximo de descuento permitido para tu usuario es') + ` ${permission('max-discount-allow', 'invoices')}`;
  }
  return null;
}

const EditItem = ({ isOpen, onClose, selectedIndex }) => {
  const dispatch = useDispatch()
  const can = useSelector(hasPermissionTo)
  const permission = useSelector(permissionValue)
  const item = useSelector(itemByIndex(selectedIndex))
  const decimal = useSelector(decimalPrecision)
  const country = useSelector(countrySelector)
  const currency = useSelector(currencySelector)

  const submit = async values => {
    const modifiedValues = transform(values, {
      country,
      initialValues: initialValues(item, decimal),
      currency,
      decimal
    });

    if (await dispatch(itemReachedMinQuantity(values, singleItemReachedMinQuantityFromValues))) {
      switch (get(values, 'type', 'product')) {
        case 'product':
          toast.warning({
            title: I18n.get('itemLimitWarningTitle', 'Ya vendiste todas las unidades. 🏁'),
            subtitle: I18n.get('itemLimitWarningSubtitle', 'Revisa si tienes una compra pendiente por registrar o edita este producto y activa la opción de ventas en negativo'),
          })
          break
        case 'kit':
          const maxQuantity = await dispatch(getKitMaximumQuantity(values));
          toast.warning({
            title: replaceAndParse(I18n.get('itemLimitWarningTitle.kitAvailable', '¡Puedes vender máximo {}! ✋'), [`${maxQuantity} ${maxQuantity > 1 ? I18n.get('kits', 'combosT') : I18n.get('kit', 'combo')}`]),
            subtitle: replaceAndParse(I18n.get('itemLimitWarningSubtitle.kitAvailable', 'Ten en cuenta que tu Combo "{}" contiene productos con ese número de unidades disponibles.'), [`<span class="font-weight-bold">${get(values, 'name', '')}</span>`]),
          })
          break;
        default:
          toast.warning({
            title: I18n.get('itemLimitWarningTitle', 'Ya vendiste todas las unidades. 🏁'),
            subtitle: I18n.get('itemLimitWarningSubtitle', 'Revisa si tienes una compra pendiente por registrar o edita este producto y activa la opción de ventas en negativo'),
          })
          break
      }
      return;
    }

    try {
      dispatch(updateItem({ index: selectedIndex, values: modifiedValues }))
      onClose()
    } catch {
      return { [FORM_ERROR]: I18n.get('editItemError', 'hubo un error al editar producto') }
    }
  }

  return (
    <div className={`${isOpen ? 'open' : ''} refund-edit-item border d-flex flex-column`}>
      <Form
        onSubmit={submit}
        initialValues={initialValues(item, decimal)}
        validate={values => validate(values, country)}
      >
        {({ handleSubmit, values, form, submitting }) => (
          <form
            noValidate
            onSubmit={handleSubmit}
            className="w-100 h-100 bg-white d-flex flex-column"
          >
            <Body values={values} form={form} />

            <Summary values={values} />

            <Notification isOpen={!!checkPermissions(form, can, permission)}
              type="warning" text={checkPermissions(form, can, permission)} />

            <Bottom
              onClose={onClose}
              disabled={some(values?.decimalsError, (value) => value) || submitting || !!checkPermissions(form, can, permission)}
              submitting={submitting}
            />
          </form>
        )}
      </Form>

    </div>
  )
}

EditItem.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  selectedIndex: PropTypes.number,
}

export default EditItem;
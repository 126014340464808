import BigNumber from 'bignumber.js';
import { I18n } from '@aws-amplify/core';

import { DOCUMENT_TYPES } from '../../../utils/enums/documentTypes';
import { LEGAL_STATUS_KEYS } from '../../../utils/enums/legalStatus';
import { getLegalStatusKey } from '../../invoices/utils';
import { REFUND_METHODS } from '../../../utils/enums/refundMethods';

/**
 * Adds an error message to the specified field within the refund errors.
 *
 * @param {Object} errors - The errors object to populate.
 * @param {string} field - The field name to add the error to.
 * @param {string} message - The error message.
 */
const addRefundError = (errors, field, message) => {
  errors.refund = {
    ...errors.refund,
    [field]: message,
  };
};

/**
 * Adds an error message to the specified field within the invoice errors.
 *
 * @param {Object} errors - The errors object to populate.
 * @param {string} field - The field name to add the error to.
 * @param {string} message - The error message.
 */
const addInvoiceError = (errors, field, message) => {
  errors.invoice = {
    ...errors.invoice,
    [field]: message,
  };
};

/**
 * Validator for Step 1 and Step 2.
 *
 * @param {Object} values - The form values.
 * @param {Object} options - Additional options for validation.
 * @param {string} method - The refund method.
 * @param {Object} errors - The errors object to populate.
 */
const validateStep1And2 = (values, { method }, errors) => {
  const invoiceIsElectronic = values?.refund?.document?.stamp !== null;

  if (!values?.refund?.client) {
    addRefundError(errors, 'client', I18n.get('thisFieldIsRequired', 'Este campo es obligatorio'));
  }
  if (!values?.refund?.numeration) {
    addRefundError(errors, 'numeration', I18n.get('thisFieldIsRequired', 'Este campo es obligatorio'));
  }
  if (!values?.refund?.date) {
    addRefundError(errors, 'date', I18n.get('thisFieldIsRequired', 'Este campo es obligatorio'));
  }

  if ([REFUND_METHODS.CREDIT_TO_SALES, REFUND_METHODS.RECTIFICATIVE_IN_SIMPLIFIED_INVOICE, REFUND_METHODS.REST].includes(method)) {
    if (!values?.refund?.document) {
      addRefundError(errors, 'document', I18n.get('thisFieldIsRequired', 'Este campo es obligatorio'));
    }
    if (invoiceIsElectronic && !values?.refund?.creditNoteType) {
      addRefundError(errors, 'creditNoteType', I18n.get('thisFieldIsRequired', 'Este campo es obligatorio'));
    }
  }

  if ([
    REFUND_METHODS.RECTIFICATIVE_IN_SIMPLIFIED_INVOICE,
    REFUND_METHODS.REST,
    REFUND_METHODS.CONTEST,
    REFUND_METHODS.ERROR_OR_MODIFICATION_OF_TAXABLE_AMOUNT,
    REFUND_METHODS.UNCOLLECTIBLE_DEBT,
    REFUND_METHODS.NO_ELECTRONIC_ORDINARY_INVOICE,
    REFUND_METHODS.NO_ELECTRONIC_SIMPLIFIED_INVOICE,
  ].includes(method)) {
    if (!values?.refund?.cause) {
      addRefundError(errors, 'cause', I18n.get('thisFieldIsRequired', 'Este campo es obligatorio'));
    }
    if (!values?.refund?.creditNoteOperationType && invoiceIsElectronic) {
      addRefundError(errors, 'creditNoteOperationType', I18n.get('thisFieldIsRequired', 'Este campo es obligatorio'));
    }
    if (!values?.refund?.anotation && invoiceIsElectronic) {
      addRefundError(errors, 'anotation', I18n.get('thisFieldIsRequired', 'Este campo es obligatorio'));
    }
  }
};

/**
 * Validator for Step 3.
 *
 * @param {Object} values - The form values.
 * @param {Object} options - Additional options for validation.
 * @param {string} method - The refund method.
 * @param {Array} itemsInRefund - List of items included in the refund.
 * @param {boolean} isAllItemsPupaleted - Indicates if all items are pupaleted.
 * @param {Object} errors - The errors object to populate.
 */
const validateStep3 = (values, { method, itemsInRefund, isAllItemsPupaleted, total }, errors) => {
  if ([
    REFUND_METHODS.CREDIT_TO_SALES,
    REFUND_METHODS.RECTIFICATIVE_IN_SIMPLIFIED_INVOICE,
    REFUND_METHODS.REST,
    REFUND_METHODS.CONTEST,
    REFUND_METHODS.ERROR_OR_MODIFICATION_OF_TAXABLE_AMOUNT,
    REFUND_METHODS.UNCOLLECTIBLE_DEBT,
    REFUND_METHODS.NO_ELECTRONIC_ORDINARY_INVOICE,
    REFUND_METHODS.NO_ELECTRONIC_SIMPLIFIED_INVOICE,
  ].includes(method)) {
    if (!isAllItemsPupaleted) {
      addRefundError(
        errors,
        'itemsInRefund',
        I18n.get('', 'Todos los items deben estar buscados en Alegra')
      );
    }

    const invoiceBalance = values?.refund?.document?.balance ?? 0;

    const totalAmount = BigNumber.isBigNumber(total) ? total : new BigNumber(total);

    if (totalAmount.gt(new BigNumber(invoiceBalance))) {
      addRefundError(
        errors,
        'amount',
        I18n.get(
          'refundTotalExceedsBalance',
          'El total a devolver debe ser menor o igual al valor pendiente por cobrar.'
        )
      );
    }
  }

  if (itemsInRefund?.length) {
    const inactiveItems = itemsInRefund.filter((item) => item.status === 'inactive');
    if (inactiveItems.length) {
      addRefundError(
        errors,
        'itemsInRefund',
        I18n.get('', 'No puedes continuar con items inactivos')
      );
    }
  }

  if (!itemsInRefund?.length) {
    addRefundError(
      errors,
      'itemsInRefund',
      I18n.get('', 'Debes elegir al menos un item')
    );
  }
};

/**
 * Validator for Step 4.
 *
 * @param {Object} values - The form values.
 * @param {Object} options - Additional options for validation.
 * @param {string} method - The refund method.
 * @param {number} total - The total amount for the refund.
 * @param {Object} tip - Information about additional charges.
 * @param {boolean} shiftsEnabled - Indicates if shifts are enabled.
 * @param {boolean} shiftOpen - Indicates if the shift is open.
 * @param {BigNumber} shiftCash - The amount of cash available in the shift.
 * @param {Object} stationCashBank - Information about the station's cash bank.
 * @param {Object} errors - The errors object to populate.
 */
const validateStep4 = (
  values,
  { method, total, tip, shiftsEnabled, shiftOpen, shiftCash, stationCashBank },
  errors
) => {
  const refundAmount = new BigNumber(values?.refund?.amount ?? 0);
  const invoiceAmount = new BigNumber(values?.invoice?.amount ?? 0);
  const refundAccountId = values?.refund?.bank?.id;
  const stationCashBankId = stationCashBank?.id;
  const invoiceBalance = values?.refund?.document?.balance ?? 0;

  if (!values?.refund?.amount) {
    addRefundError(errors, 'amount', I18n.get('thisFieldIsRequired', 'Este campo es obligatorio'));
  }

  if ([REFUND_METHODS.CREDIT_TO_SALES, REFUND_METHODS.RECTIFICATIVE_IN_SIMPLIFIED_INVOICE, REFUND_METHODS.REST].includes(method)) {
    if (new BigNumber(invoiceBalance).lt(refundAmount)) {
      addRefundError(
        errors,
        'amount',
        I18n.get('refundAmountExceedsBalance', 'El monto a devolver excede el saldo de la factura')
      );
    }
  }

  if (method === REFUND_METHODS.CASH) {
    const isEqual = new BigNumber(total).eq(refundAmount);

    if (!values?.refund?.bank) {
      addRefundError(errors, 'bank', I18n.get('thisFieldIsRequired', 'Este campo es obligatorio'));
    }

    if (
      shiftsEnabled &&
      shiftOpen === true &&
      refundAccountId === stationCashBankId &&
      refundAmount.gt(shiftCash)
    ) {
      addRefundError(
        errors,
        'amount',
        I18n.get(
          'noCashAvailable',
          'el valor del egreso no puede ser mayor a la cantidad de efectivo en caja'
        )
      );
    }

    if (!isEqual) {
      addRefundError(
        errors,
        'amount',
        I18n.get(
          'refundErrorTotalAmount',
          'El monto asociado a las ventas debe ser igual al monto total de la nota de crédito'
        )
      );
    }
  }

  if (method === REFUND_METHODS.COMBINED) {
    const documentHaveTip = !!values?.refund?.document?.additionalCharges?.[0];
    const tipInclude = !!tip?.include;

    if (!documentHaveTip && tipInclude) {
      addRefundError(
        errors,
        'document',
        I18n.get('tipError', 'No puedes agregar propina si el documento no tiene propina')
      );
    }

    if (!values?.refund?.bank) {
      addRefundError(errors, 'bank', I18n.get('thisFieldIsRequired', 'Este campo es obligatorio'));
    }

    if (!values?.refund?.document) {
      addRefundError(errors, 'document', I18n.get('thisFieldIsRequired', 'Este campo es obligatorio'));
    }

    if (!values?.invoice?.amount) {
      addInvoiceError(errors, 'amount', I18n.get('thisFieldIsRequired', 'Este campo es obligatorio'));
    }

    if (shiftsEnabled && shiftOpen === true && refundAmount.gt(shiftCash)) {
      addRefundError(
        errors,
        'amount',
        I18n.get(
          'noCashAvailable',
          'el valor del egreso no puede ser mayor a la cantidad de efectivo en caja'
        )
      );
    }

    if (values?.refund?.document) {
      if (new BigNumber(invoiceBalance).lt(invoiceAmount)) {
        addInvoiceError(
          errors,
          'amount',
          I18n.get(
            'refundAmountExceedsInvoiceAmount',
            'El monto a devolver excede el monto de la factura'
          )
        );
      }

      if (new BigNumber(total).lt(refundAmount.plus(invoiceAmount))) {
        addRefundError(
          errors,
          'amount',
          I18n.get(
            'theCombinedAmountCannotBeGreaterThanRefundTotal',
            'el monto combinado no puede ser mayor que el total de la devolución'
          )
        );

        addInvoiceError(
          errors,
          'amount',
          I18n.get(
            'theCombinedAmountCannotBeGreaterThanRefundTotal',
            'el monto combinado no puede ser mayor que el total de la devolución'
          )
        );
      }
    }
  }
};

/**
 * Mapping of steps to their corresponding validation strategies.
 */
const validationStrategies = {
  1: validateStep1And2,
  2: validateStep1And2,
  3: validateStep3,
  4: validateStep4,
};

/**
 * Validates the refund form values based on the current step and other parameters.
 *
 * @param {Object} values - The form values.
 * @param {Object} options - Additional options for validation.
 * @param {number} options.total - The total amount for the refund.
 * @param {Object} options.tip - Information about additional charges.
 * @param {number} options.step - The current step in the refund process.
 * @param {boolean} options.shiftOpen - Indicates if the shift is open.
 * @param {boolean} options.shiftsEnabled - Indicates if shifts are enabled.
 * @param {BigNumber} options.shiftCash - The amount of cash available in the shift.
 * @param {Object} options.stationCashBank - Information about the station's cash bank.
 * @param {Array} options.itemsInRefund - List of items included in the refund.
 * @param {boolean} options.isAllItemsPupaleted - Indicates if all items are pupaleted.
 *
 * @returns {Object} An object containing validation errors.
 */
export const validate = (
  values,
  {
    total,
    tip,
    step,
    shiftOpen,
    shiftsEnabled,
    shiftCash,
    stationCashBank,
    itemsInRefund,
    isAllItemsPupaleted,
  }
) => {
  const errors = {};
  const method = values?.refund?.method?.value;

  // Select the appropriate validation strategy based on the current step
  const validateStrategy = validationStrategies[step];
  if (validateStrategy) {
    validateStrategy(values, { method, total, tip, shiftOpen, shiftsEnabled, shiftCash, stationCashBank, itemsInRefund, isAllItemsPupaleted }, errors);
  }

  return errors;
};

/**
 * Transforms form values into a structured object for processing refunds.
 *
 * @param {Object} values - The form values.
 * @param {Object} options - Additional options for transformation.
 * @param {Object} options.tip - Information about additional charges.
 * @param {number} options.decimal - Number of decimal places for amounts.
 * @param {BigNumber} options.subtotal - The subtotal amount.
 *
 * @returns {Object} The transformed values ready for processing.
 */
export const transform = (values, { tip, decimal, subtotal }) => {
  const transformedValues = {
    invoices: [],
    refunds: [],
  };

  const refund = values?.refund ?? {};
  const method = refund.method?.value;

  /**
   * Handles refund details for 'cash' and 'combined' methods.
   */
  if (method === REFUND_METHODS.CASH || method === REFUND_METHODS.COMBINED) {
    const amount = Number(refund.amount ?? 0);
    const account = refund.bank?.id ?? null;
    const observations = refund.observations ?? null;

    transformedValues.refunds = [{
      amount,
      account,
      observations,
    }];
  }

  /**
   * Handles invoice and stamp details for 'creditToSales' and 'rectificativeInSimplifiedInvoice' methods.
   */
  if ([
    REFUND_METHODS.CREDIT_TO_SALES,
    REFUND_METHODS.RECTIFICATIVE_IN_SIMPLIFIED_INVOICE,
    REFUND_METHODS.REST,
    REFUND_METHODS.CONTEST,
    REFUND_METHODS.ERROR_OR_MODIFICATION_OF_TAXABLE_AMOUNT,
    REFUND_METHODS.UNCOLLECTIBLE_DEBT,
    REFUND_METHODS.NO_ELECTRONIC_ORDINARY_INVOICE,
    REFUND_METHODS.NO_ELECTRONIC_SIMPLIFIED_INVOICE,
  ].includes(method)) {
    const isElectronic = refund.document?.numberTemplate?.isElectronic ?? false;

    transformedValues.stamp = {
      generateStamp: Boolean(isElectronic),
    };

    const refundDocument = refund.document ?? {};
    const invoiceAmount = Number(refund.amount ?? 0);

    transformedValues.invoices = [{
      ...refundDocument,
      amount: invoiceAmount,
    }];
  }

  /**
   * Additional invoice handling for the 'combined' method.
   */
  if (method === REFUND_METHODS.COMBINED) {
    const refundDocument = refund.document ?? {};
    const invoiceAmount = Number(values?.invoice?.amount ?? 0);

    transformedValues.invoices = [{
      ...refundDocument,
      amount: invoiceAmount,
    }];
  }

  /**
   * Adds specific fields for the 'rectificativeInSimplifiedInvoice' method.
   */
  if ([
    REFUND_METHODS.RECTIFICATIVE_IN_SIMPLIFIED_INVOICE,
    REFUND_METHODS.REST,
    REFUND_METHODS.CONTEST,
    REFUND_METHODS.ERROR_OR_MODIFICATION_OF_TAXABLE_AMOUNT,
    REFUND_METHODS.UNCOLLECTIBLE_DEBT,
    REFUND_METHODS.NO_ELECTRONIC_ORDINARY_INVOICE,
    REFUND_METHODS.NO_ELECTRONIC_SIMPLIFIED_INVOICE,
  ].includes(method)) {
    const simpleCause = refund.simpleCause ?? null;
    const creditNoteOperationType = refund.creditNoteOperationType?.value ?? null;

    transformedValues.simpleCause = simpleCause;
    transformedValues.creditNoteOperationType = creditNoteOperationType;
    transformedValues.anotation = refund.anotation ?? null;
  }

  /**
   * Handles additional charges based on the 'tip' options.
   */
  if (tip?.include) {
    const additionalCharge = refund.document?.additionalCharges?.[0] ?? null;
    let amount = 0;

    if (tip.type === 'VALUE') {
      amount = new BigNumber(tip.value ?? 0)
        .decimalPlaces(decimal)
        .toNumber();
    } else {
      amount = subtotal.multipliedBy(tip.percentage ?? 10)
        .dividedBy(100)
        .decimalPlaces(decimal)
        .toNumber();
    }

    transformedValues.additionalCharges = [{
      id: additionalCharge?.idCharge ?? null,
      amount,
    }];
  }

  /**
   * Adds common fields to the transformed values.
   */
  transformedValues.type = refund.creditNoteType?.key ?? null;
  transformedValues.note = refund.note ?? null;
  transformedValues.cause = refund.cause ?? null;
  transformedValues.numeration = refund.numeration ?? null;

  return transformedValues;
};

export const getPrevStep = (step, method) => {
  if (step === 3 && method !== REFUND_METHODS.CREDIT_TO_SALES) return 1;
  return step - 1;
}

/**
 * Retrieves the legal status of a refund.
 *
 * @param {Object} refund - The refund object.
 * @returns {string} - The legal status key.
 */
export const getLegalStatus = (refund) => {
  const legalStatus = getLegalStatusKey(refund);
  return legalStatus || LEGAL_STATUS_KEYS.TO_BE_ISSUED;
};

/**
 * Determines the type of refund based on the method.
 *
 * @param {string} method - The refund method.
 * @returns {string} - The refund type.
 */
export const getTypeRefund = (method) => {
  return method === REFUND_METHODS.CREDIT_TO_SALES ? 'invoice' : method;
};

/**
 * Retrieves the invoice type based on the document type and electronic status.
 *
 * @param {Object} invoice - The invoice object.
 * @returns {string} - The invoice type.
 */
export const getInvoiceType = (invoice) => {
  if (!invoice) return '';

  const documentType = invoice?.numberTemplate?.documentType;

  if (documentType === DOCUMENT_TYPES.SALE_TICKET) {
    const isElectronic = invoice.numberTemplate?.isElectronic || false;
    return isElectronic ? 'docPOS' : 'saleTicket';
  }

  return documentType || '';
};

/**
 * Generates the class name for the modal based on the step, method, and country.
 *
 * @param {number} step - The current step.
 * @param {string} method - The method used.
 * @returns {string} - The generated class name.
 */
export const getModalClassName = (step, method) => {
  let className = 'modal__refunds-new';

  if (step !== 0) {
    className += ` step-${step}`;
  }

  if (method) {
    className += ` method-${method}`;
  }

  return className;
};
import { I18n } from '@aws-amplify/core';
import { get, isNull, isUndefined } from 'lodash';

export const validate = (values, { step, options }) => {
  let errors = {};

  switch (step) {
    case 1:
      if (options.hasOwnProperty('debitBank') && !get(values, 'debit.id', null))
        errors.debit = I18n.get('thisFieldIsRequired', 'Este campo es obligatorio')
      if (options.hasOwnProperty('creditBank') && !get(values, 'credit.id', null))
        errors.credit = I18n.get('thisFieldIsRequired', 'Este campo es obligatorio')
      if (options.hasOwnProperty('transferBank') && !get(values, 'transfer.id', null))
        errors.transfer = I18n.get('thisFieldIsRequired', 'Este campo es obligatorio')
      if (options.hasOwnProperty('closeBankBank') && !get(values, 'closeBank.id', null))
        errors.closeBank = I18n.get('thisFieldIsRequired', 'Este campo es obligatorio')
      if (options.hasOwnProperty('baseBankBank') && !get(values, 'baseBank.id', null))
        errors.baseBank = I18n.get('thisFieldIsRequired', 'Este campo es obligatorio')
      break;

    case 2:
      if (options.hasOwnProperty('invoiceNumeration') && !get(values, 'invoiceNumeration.id', null))
        errors.invoiceNumeration = I18n.get('thisFieldIsRequired', 'Este campo es obligatorio')
      if (options.hasOwnProperty('cashReceiptNumeration') && !get(values, 'cashReceiptNumeration.id', null))
        errors.cashReceiptNumeration = I18n.get('thisFieldIsRequired', 'Este campo es obligatorio')
      if (options.hasOwnProperty('refundNumeration') && !get(values, 'refundNumeration.id', null))
        errors.refundNumeration = I18n.get('thisFieldIsRequired', 'Este campo es obligatorio')
      break;

    case 3:
      if (options.hasOwnProperty('warehouse') && !get(values, 'warehouse.id', null))
        errors.warehouse = I18n.get('thisFieldIsRequired', 'Este campo es obligatorio')
      break;
    case 4:
      if (!!get(values, 'shiftsEnabled', null)) {
        if (!get(values, 'base'))
          errors.base = I18n.get('requiredField', 'este campo es obligatorio')

        if (!get(values, 'baseBank.id'))
          errors.baseBank = I18n.get('requiredField', 'este campo es obligatorio')
      }
      break;

    default:
      break;
  }

  return errors;
}

export const transform = values => {
  let station = {
    idNumberTemplate: get(values, 'invoiceNumeration.id', null),
    idCashReceiptNumberTemplate: get(values, 'cashReceiptNumeration.id', null),
    idRefundNumberTemplate: get(values, 'refundNumeration.id', null),
    idWarehouse: get(values, 'warehouse.id', null),
    idCredit: get(values, 'credit.id', null),
    idDebit: get(values, 'debit.id', null),
    idTransfer: get(values, 'transfer.id', null),
    baseBank: get(values, 'baseBank.id', null),
    closeBank: get(values, 'closeBank.id', null),
  }

  Object.keys(station).map(key => {
    if (isNull(station[key]) || isUndefined(station[key]))
      delete station[key];
    return null;
  })

  return {
    station,
    shift: {
      startingAmount: get(values, 'base', null),
      baseBank: get(values, 'baseBank.id', null),
    },
    localSettings: {
      shiftsConfigured: true,
      shiftsEnabled: get(values, 'shiftsEnabled')
    },
    shiftsEnabled: get(values, 'shiftsEnabled')
  }
}
import React from 'react';
import { I18n } from '@aws-amplify/core';
import { Field } from 'react-final-form';
import { capitalize } from 'lodash';
import { useSelector } from 'react-redux'

import { hasPermissionTo } from '../../../selectors/auth'
import { isOnlyInvoicingPlan } from '../../../selectors/company'
import { activeBanks } from '../../../selectors/banks'
import { renderSelect } from '../fields/V0/Fields';

const Shift = () => {
  const can = useSelector(hasPermissionTo)
  const banks = useSelector(activeBanks)
  const onlyInvoicing = useSelector(isOnlyInvoicingPlan)

  return (
    <div className="form-body__fields form-row justify-content-start">
      <Field
        name="baseBank"
        className="col-md-6"
        component={renderSelect}
        disabled={onlyInvoicing}
        onlyInvoicingTooltip={onlyInvoicing}
        label={capitalize(I18n.get('baseBank', 'banco de apertura'))}
        options={!can('index', 'bank-accounts') || !can('view', 'bank-accounts') ? [] : banks}
        selectInfo={!can('index', 'bank-accounts') || !can('view', 'bank-accounts')
          ? I18n.get('userNotAllowed.bank-accounts.view', 'no tienes permisos para ver la información de las cuentas de bancos')
          : null}
        hideNoOptionMessage={!can('index', 'bank-accounts') || !can('view', 'bank-accounts')}
        getOptionLabel={option => option.name}
        getOptionValue={option => option.id}
        menuPosition="absolute"
        required
      />

      <Field
        name="closeBank"
        className="col-md-6"
        component={renderSelect}
        disabled={onlyInvoicing}
        onlyInvoicingTooltip={onlyInvoicing}
        label={capitalize(I18n.get('closeBank', 'banco de cierre'))}
        options={!can('index', 'bank-accounts') || !can('view', 'bank-accounts') ? [] : banks}
        selectInfo={!can('index', 'bank-accounts') || !can('view', 'bank-accounts')
          ? I18n.get('userNotAllowed.bank-accounts.view', 'no tienes permisos para ver la información de las cuentas de bancos')
          : null}
        hideNoOptionMessage={!can('index', 'bank-accounts') || !can('view', 'bank-accounts')}
        getOptionLabel={option => option.name}
        getOptionValue={option => option.id}
        menuPosition="absolute"
        required
      />
    </div>
  )
}

export default Shift;

import { useModal } from '@alegradev/smile-ui-react';
import NewInvoice from '../../components/modals/newInvoice/NewInvoice';
import { replaceAndParse } from '../../utils';
import { I18n } from 'aws-amplify';
import { useDispatch, useSelector } from 'react-redux';
import { numeration as numerationSelector } from '../../selectors/activeInvoice';
import { country as countrySelector } from '../../selectors/company';
import { InvoiceTextCountryStrategyFactory } from '../../components/modals/invoiceRefactored/strategies/InvoiceTextCountryStrategyFactory';
import {
  setIsPaymentMethodSelected,
  setPaymentMethod,
} from '../../reducers/activeInvoice';

/**
 * Custom hook to handle opening a new invoice modal.
 * Uses the useModal hook to open and close the modal.
 * Dispatches actions to set payment method and close the modal.
 * @returns {Object} Object containing the function to open the new invoice modal
 */
const useNewInvoiceModal = () => {
  const { openModal: openNewModal, closeModal: closeNewModal } = useModal();
  const dispatch = useDispatch();
  const numeration = useSelector(numerationSelector);
  const country = useSelector(countrySelector);
  const strategy = InvoiceTextCountryStrategyFactory.createStrategy(country);
  const openNewInvoiceModal = () => {
    openNewModal({
      component: NewInvoice,
      size: 'large',
      name: 'NewInvoice',
      title: replaceAndParse(I18n.get('payAnyDocument', 'pagar {}'), [
        strategy.getModalTitle(numeration),
      ]),
      onClose: () => {
        dispatch(setPaymentMethod(null));
        dispatch(setIsPaymentMethodSelected(false));
      },
    });
  };

  return {
    openNewInvoiceModal,
  };
};

export default useNewInvoiceModal;

const adjustmentNote = [
  {
    id: 1,
    value: "Devolución de parte de los bienes y/o no aceptación parcial del servicio",
    key: "PARTIALL_DEVOLUTION"
  },
  {
    id: 2,
    value: "Anulación de documento equivalente electrónico",
    key: "VOID_ELECTRONIC_INVOICE"
  },
  {
    id: 3,
    value: "Rebaja o descuento parcial o total",
    key: "TOTAL_REDUCTION_OR_DISCOUNT"
  },
  {
    id: 4,
    value: "Ajuste de precio",
    key: "PRICE_ADJUSTMENT"
  },
  {
    id: 5,
    value: "Otros",
    key: "OTHER"
  }
]


export default adjustmentNote;
import { get } from "lodash";
import { COUNTRIES } from "../../utils/enums/countries";
import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { getAuthToken } from "../../pages/contacts/services/utils";
import { jwtDecode } from "jwt-decode";

dayjs.extend(utc);
dayjs.extend(timezone);

const idsCompaniesProduction = [
  '1455936',
  '1456627'
];
const enviroment = process.env.REACT_APP_ALEGRA_COOKIE === 'al-test-days-of-thunder'
  ? 'testing'
  : 'production'

const deployGroupDate = dayjs('2023-06-30')

export const userIsElegibleForTip = ({ country, registryDate, idCompany }) => {
  return country === COUNTRIES.COLOMBIA || country === COUNTRIES.COSTA_RICA
}

const USER_ROLES_ELIGIBLE_COUNTRIES = [
  {
    countries: [COUNTRIES.INTERNATIONAL, COUNTRIES.SPAIN],
    date: '2024-09-30',
    timezone: 'Europe/Berlin',
  },
  {
    countries: [COUNTRIES.PERU, COUNTRIES.PANAMA, COUNTRIES.COSTA_RICA],
    date: '2024-10-09',
    timezone: dayjs.tz.guess(),
  },
  {
    countries: [
      COUNTRIES.MEXICO,
      COUNTRIES.ARGENTINA,
      COUNTRIES.REPUBLICA_DOMINICANA,
    ],
    date: '2024-10-15',
    timezone: dayjs.tz.guess(),
  },
  {
    countries: [COUNTRIES.COLOMBIA],
    date: '2024-10-21',
    timezone: dayjs.tz.guess(),
  },
];

export const checkNewModelStatus = (tokenDecoded) => {
  return get(tokenDecoded, 'newModel', false) === true;
};

export const showManageYourAccountSection = ({ country, company }) => {
  const registryDate = dayjs(get(company, 'registryDate', ''));

  const tokenDecoded = getAuthToken() ? jwtDecode(getAuthToken()) : null;
  const isAbleToDisplay = checkNewModelStatus(tokenDecoded);

  if (isAbleToDisplay) return true;

  const eligibleCountry = USER_ROLES_ELIGIBLE_COUNTRIES.find(({ countries }) =>
    countries.includes(country)
  );

  if (!eligibleCountry) return false;

  const { date, timezone } = eligibleCountry;

  const timezoneAdjustedDate = registryDate.tz(timezone);
  return !timezoneAdjustedDate.isBefore(dayjs.tz(date, timezone));
};

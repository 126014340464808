import React from 'react';
import { Field } from 'react-final-form';
import { I18n } from '@aws-amplify/core';
import { capitalize } from 'lodash';

import { Grid } from '@alegradev/smile-ui-react';
import { renderField } from '../../../../../components/forms/fields/V2';

const renderStatementAttached = () => {
  const { Col } = Grid;
  return (
    <Col xs={12}>
      <Field
        name='statementAttached'
        defaultValue={false}
        component={renderField}
        type='checkbox'
        fieldType='checkbox'
        direction='row'
        title={capitalize(
          I18n.get('includeAccountStatement', 'incluir estado de cuenta')
        )}
        description={capitalize(
          I18n.get(
            'includeAccountStatementSubtitle',
            'Al enviar una factura al correo, esta irá acompañada del resumen de todas sus facturas'
          )
        )}
      />
    </Col>
  );
};

export default renderStatementAttached;

import { createDraftSafeSelector } from '@reduxjs/toolkit';
import { get } from 'lodash';

import { variantsSelector } from '../reducers/variants'

export const activeVariants = createDraftSafeSelector(
  variantsSelector.selectAll,
  variants => !!variants
    ? variants.filter(variant => get(variant, 'status') === 'active')
    : []
)
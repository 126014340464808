import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { getInitialState } from '../utils';

const initialState = {
  hash: null,
  emailTemplate: null,
  whatsappTemplate: null,
  version: 1
};

export const adapter = createEntityAdapter();

const appSlice = createSlice({
  name: 'shareInvoice',
  initialState: getInitialState('shareInvoice', initialState),
  reducers: {
    setHash: (state, action) => {
      state.hash = action.payload;
    },
    setEmailTemplate: (state, action) => {
      state.emailTemplate = action.payload;
    },
    setWhatsappTemplate: (state, action) => {
      state.whatsappTemplate = action.payload;
    },
  },
});

const { actions, reducer } = appSlice;

export const { setHash, setEmailTemplate, setWhatsappTemplate } = actions;

export const shareInvoiceSelector = adapter.getSelectors((state) => state.shareInvoice);

export default reducer;

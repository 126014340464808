import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { PageHeading, useModal } from '@alegradev/smile-ui-react';
import { useHistory } from 'react-router-dom';
import { I18n } from 'aws-amplify';

import { country as countrySelector } from '../../../selectors/company';
import {
  useChangeContactStatusMutation,
  useDeleteContactMutation,
} from '../services/mutations';
import { handleDeleteClient } from '../utils/modals';
import checkIfIsDefaultClient from '../utils/isDefaultClient';
import { hasPermissionTo } from '../../../selectors/auth';
import { IconBulb, IconBulbOff, IconPencil, IconTrash } from '@tabler/icons-react';

function ContactDetailHeader({ contact }) {
  const history = useHistory();
  const country = useSelector(countrySelector);
  const { openModal, closeModal } = useModal();
  const can = useSelector(hasPermissionTo);

  const { mutate: handleDeleteContact, isLoading } = useDeleteContactMutation();
  const { mutate: handleChangeContactStatus } =
    useChangeContactStatusMutation();

  const isDefaultClient = useMemo(
    () => checkIfIsDefaultClient(contact, country),
    [contact, country]
  );

  const actions = useMemo(() => {
    if (isDefaultClient) return [];

    const actions = [
      {
        text:
          contact?.status === 'active'
            ? I18n.get('deactivate', 'Desactivar')
            : I18n.get('activate', 'Activar'),
        leftIcon: contact?.status === 'active' ? IconBulbOff : IconBulb,
        variant: 'secondary',
        disabled: !can('edit', 'contacts') || isLoading,
        action: () =>
          handleChangeContactStatus({
            id: contact?.id,
            status: contact?.status,
          }),
        tooltip: !can('edit', 'contacts')
          ? I18n.get(
              'changeStatusContactsUnallow',
              'Te hace falta el permiso para cambiar el estado de contactos'
            )
          : undefined,
      },
      {
        text: I18n.get('edit', 'Editar'),
        leftIcon: IconPencil,
        disabled: !can('edit', 'contacts') || isLoading,
        action: () => history.push(`/contacts/contact/${contact.id}`),
        tooltip: !can('edit', 'contacts')
          ? I18n.get(
              'editContactsUnallow',
              'Te hace falta el permiso para editar contactos'
            )
          : undefined,
      },
    ];

    if (can('delete', 'contacts')) {
      actions.push({
        text: I18n.get('delete', 'Eliminar'),
        overflowIcon: IconTrash,
        disabled: !can('delete', 'contacts') || isLoading,
        tooltip: !can('delete', 'contacts')
          ? I18n.get(
              'deleteContactsUnallow',
              'Te hace falta el permiso para eliminar contactos'
            )
          : undefined,
        action: () =>
          handleDeleteClient({
            contact,
            handleDeleteContact,
            openModal,
            closeModal,
          }),
      });
    }

    return actions;
  }, [contact]);

  return (
    <PageHeading
      breadcrumb={[
        {
          title: I18n.get('contact', 'Contactos'),
          action: () => history.push('/contacts'),
        },
        { title: contact?.name || '' },
      ]}
      title={contact?.name || ''}
      actions={actions}
    />
  );
}

export default ContactDetailHeader;

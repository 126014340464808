import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom'
import { I18n } from '@aws-amplify/core';
import { get } from 'lodash'
import dayjs from 'dayjs'

import alegraAPI from '../../../reducers/alegraAPI';
import { openModal, closeModal } from '../../../reducers/modals';
import { membershipSelector, companySelector, country as countrySelector } from '../../../selectors/company';
import { membershipACSelector } from '../../../selectors/membership'
import Modal from '../../common/Modal';
import IconSVG from './Icon.svg'

const ReadonlyPlan = () => {
  const dispatch = useDispatch()
  const isOpen = useSelector(state => !!get(state, 'modals.readonlyPlan.isOpen'))
  const membership = useSelector(membershipSelector)
  const membershipAC = useSelector(membershipACSelector)
  const company = useSelector(companySelector)

  const getConsumptionInfo = async () => {
    let sales = 0, clients = 0, items = 0;
    items = (await alegraAPI.get('/items', { metadata: true, limit: 0 })).data.metadata.total
    clients = (await alegraAPI.get('/contacts', { metadata: true, type: 'client', limit: 0 })).data.metadata.total
    sales = (await alegraAPI.get('/invoices', { metadata: true, start: 0, limit: 1 })).data.metadata.total
    return [sales, clients, items];
  }

  useEffect(() => {
    const checkStatus = async () => {
      const showPlans = dayjs(get(company, 'registryDate')).isAfter(dayjs('09-15-2021')) ? true : get(membershipAC, 'plan.keyword', '').includes('readonly')
      const readonly = get(membership, 'plan.keyword', '').includes('readonly') && get(membership, 'product.keyword', '') === 'alegra-pos'

      if (readonly && showPlans) {
        const [sales, clients, items] = await getConsumptionInfo();
        if (!!sales || !!clients || !!items)
          dispatch(openModal({ modal: 'readonlyPlanWithData', params: { info: { sales, clients, items } } }))
        else
          dispatch(openModal({ modal: 'readonlyPlan' }))
      }
    }
    checkStatus()
  }, [membership, membershipAC, company, dispatch])

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={() => dispatch(closeModal({ modal: 'readonlyPlan' }))}
      className="modal__products-onboarding"
    >
      <div className="p-4 pb-5">

        <div className="d-flex flex-column justify-content-center py-md-4 text-center align-items-center">

          <div>
            <img src={IconSVG} alt="" />
          </div>

          <h1 className="text-primary mb-3" style={{ fontSize: '24px', lineHeight: '32px' }}>
            {I18n.get('onboarding.readonly.header', '¡Sigue vendiendo fácil y rápido! 😎')}
          </h1>

          <p className="mb-5" style={{ maxWidth: '45rem' }}>
            {I18n.get('onboarding.readonly.message.1', 'Ya disfrutaste de tu prueba gratis,')}
            {' '}
            <b>{I18n.get('onboarding.readonly.message.2', 'elige tu plan ahora')}</b>
            {' '}
            {I18n.get('onboarding.readonly.message.3', 'y continúa teniendo el control de tus puntos de venta.')}
          </p>

          <div className="text-center">
            <Link
              to={{ pathname: `${process.env.REACT_APP_ALEGRA_SMILE}subscriptions/products/alegra-pos/plans` }}
              className="btn btn-success text-white px-4 text-uppercase d-flex align-items-center"
              style={{
                height: '4.8rem',
                filter: 'drop-shadow(0px 3px 12px rgba(0, 0, 0, 0.15))'
              }}
              onClick={() => dispatch(closeModal({ modal: 'readonlyPlan' }))}
              target="_blank"
            >
              {I18n.get('onboarding.readonly.button', 'ELEGIR PLAN')}
            </Link>
          </div>

        </div>
      </div>
    </Modal>
  )
}

export default ReadonlyPlan
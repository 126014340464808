import React, { useRef, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { I18n } from '@aws-amplify/core';
import { get, capitalize } from 'lodash'
import { BigNumber } from 'bignumber.js'
import { Chart } from 'chart.js'

import { hasPermissionTo } from '../../../selectors/auth';
import { decimalPrecision } from '../../../selectors/company';
import { Icon } from '@alegradev/smile-ui-react';
import { useFormat } from '../../../hooks/useFormat';
import { IconLoader2 } from '@tabler/icons-react';

const getTransferTotal = (shift) => {
  const transfer = get(shift, 'transfer', 0);
  const paymentMethods = get(shift, 'customPaymentMethods', []);

  const customPaymentsTotal = paymentMethods.reduce(
    (total, method) => total + method.totalSales,
    0
  );

  return transfer + customPaymentsTotal;
};

const calculatePayments = data => {
  const zero = new BigNumber(0)
  let payments = [zero, zero, zero, zero, zero, zero, zero];

  data.map(shift => {
    payments[0] = payments[0].plus(get(shift, 'cash', 0))
    payments[1] = payments[1].plus(get(shift, 'debit', 0))
    payments[2] = payments[2].plus(get(shift, 'credit', 0))
    payments[3] = payments[3].plus(getTransferTotal(shift));
    payments[4] = payments[4].plus(get(shift, 'inPayment', 0))
    payments[5] = payments[5].plus(get(shift, 'outPayment', 0))
    payments[6] = payments[6].plus(get(shift, 'refunds', 0))
    return null
  })
  return payments
}

const ShiftsCharts = ({
  data,
  loading,
  error,
  total,
}) => {
  const chartRef = useRef()
  const can = useSelector(hasPermissionTo)
  const { decimal, fmt } = useFormat();

  useEffect(() => {
    let chartWrapper = chartRef.current
    if (!!chartWrapper) {
      var canvas = document.createElement('canvas');

      canvas.width = 350;
      canvas.height = 350;

      chartWrapper.appendChild(canvas)

      new Chart(canvas, {
        type: 'pie',
        data: {
          labels: [
            capitalize(I18n.get('cash', 'efectivo')),
            capitalize(I18n.get('debit', 'débito')),
            capitalize(I18n.get('credit', 'crédito')),
            capitalize(I18n.get('transfer', 'transferencia')),
            capitalize(I18n.get('inPayment', 'ingresos')),
            capitalize(I18n.get('outPayemnt', 'egresos')),
            capitalize(I18n.get('refunds', 'devoluciones')),
          ],
          datasets: [
            {
              label: capitalize(I18n.get('paymentTypes', 'Tipos de pagos')),
              data: calculatePayments(data),
              backgroundColor: [
                '#00535E',
                '#809BB6',
                '#4A90E2',
                '#BDE3DC',
                '#BFCD31',
                '#F99D36',
                '#E85E42',
              ],
              hoverOffset: 4,
            },
          ],
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            legend: {
              position: 'top',
            },
            tooltip: {
              callbacks: {
                label: ({ label, raw, dataIndex }) => {
                  if (dataIndex === 3) {
                    const paymentTotals = {};
                    let transfer = 0;

                    data.forEach((shift) => {
                      transfer += get(shift, 'transfer', 0);
                      shift.customPaymentMethods.forEach((method) => {
                        if (paymentTotals.hasOwnProperty(method.name)) {
                          paymentTotals[method.name] += method.totalSales;
                        } else {
                          paymentTotals[method.name] = method.totalSales;
                        }
                      });
                    });

                    const paymentTotalsArray = Object.keys(paymentTotals).map(
                      (paymentMethod) => ({
                        name: paymentMethod,
                        totalSales: paymentTotals[paymentMethod],
                      })
                    );

                    const result = [
                      `${label}: ${new BigNumber(raw).toFormat(decimal, fmt)}`,
                      ...paymentTotalsArray.map(({ name, totalSales }) => ` -${name}: ${new BigNumber(totalSales).toFormat(decimal, fmt)}`),
                    ];
                    
                    if(paymentTotalsArray.length > 0) {
                      result.push(` -Otras transferencias: ${new BigNumber(transfer).toFormat(decimal, fmt)}`)
                    }
                    return result;
                  } else {
                    return `${label}: ${new BigNumber(raw).toFormat(decimal, fmt)}`;
                  }
                },
              },
            },
          },
        },
      });
    }
    return () => {
      if (!!chartWrapper) {
        chartWrapper.removeChild(chartWrapper.childNodes[0])
      }
    }
  }, [data, decimal, fmt])

  return (
    <div className="chart-data-wrapper position-relative w-100">

      <div ref={chartRef} className="chart-wrapper" />

      {loading && (
        <div className="chart-data-indicator w-100 h-100 d-flex justify-content-center align-items-center">
          <Icon icon={IconLoader2} animated extraClass=" icon-primary icon x2" />
        </div>
      )}
      {data.length === 0 && !loading && !error && !!total && (
        <div className="chart-data-indicator w-100 h-100 d-flex justify-content-center align-items-center">
          <p className="text-capitalize-first p-5">
            {I18n.get('noShiftInQuery', 'Selecciona un nuevo período de consulta que cuente con turnos cerrados.')}
          </p>
        </div>
      )}
      {data.length === 0 && !loading && !error && !total && (
        <div className="chart-data-indicator w-100 h-100 d-flex justify-content-center align-items-center">
          <p className="text-capitalize-first p-5">
            {I18n.get('noClosedShiftsYet.payments', 'Aquí conocerás el resumen de los métodos de pago utilizados por tus clientes en los turnos cerrados.')}
          </p>
        </div>
      )}
      {error && (
        <div className="chart-data-indicator w-100 h-100 d-flex justify-content-center align-items-center flex-column">
          <p className="text-capitalize-first">
            {I18n.get('shiftsLoadError', 'ocurrió un error cargando el historial de turnos de la terminal')}
          </p>
        </div>
      )}
      {(!can('index', 'pos-cashier') || !can('view', 'pos-cashier')) && (
        <div className="chart-data-indicator w-100 h-100 d-flex justify-content-center align-items-center">
          <p className="text-capitalize-first">
            {I18n.get('userNotAllowed.pos-cashier.view', 'no tienes permiso para ver los cierres de turno anteriores')}
          </p>
        </div>
      )}
    </div>
  )
}

export default ShiftsCharts;
import React from 'react';
import PropTypes from 'prop-types';

const SkeletonComponent = ({
  count,
  width,
  height,
  circle,
  animation,
  extraClassName,
}) => {
  const getChildElements = () => {
    const skeletonElements = [];

    for (let i = 0; i < count; i++) {
      const style = {
        width,
        height,
        borderRadius: circle ? '50%' : '16px',
        animationDuration: `${animation}s`,
      };

      skeletonElements.push(
        <div key={i} className='skeleton' style={style}></div>
      );

      return skeletonElements;
    }
  };

  return (
    <div className={`skeleton-container ${extraClassName}`}>
      {getChildElements()}
    </div>
  );
};

SkeletonComponent.propTypes = {
  count: PropTypes.number,
  width: PropTypes.string,
  extraClassName: PropTypes.string,
  height: PropTypes.string,
  circle: PropTypes.bool,
  animation: PropTypes.number,
};

SkeletonComponent.defaultProps = {
  count: 1,
  width: '100%',
  height: '16px',
  circle: false,
  animation: 1.2,
};

export default SkeletonComponent;

import { useMemo } from 'react';

import globalInvoiceColumns from './columns';
import globalInvoiceEmptyState from './empty';
import { useGlobalInvoiceDetail } from '../context';
import { get } from 'lodash';
import { Table } from '@alegradev/smile-ui-react';

function GlobalInvoiceDetailTable() {
  const columns = useMemo(() => globalInvoiceColumns(), []);
  const { data } = useGlobalInvoiceDetail();

  return (
    <Table
      columns={columns}
      data={get(data, 'saleTickets', [])}
      emptyComponent={globalInvoiceEmptyState()}
      simple
    />
  );
}

export default GlobalInvoiceDetailTable;

import { useEffect } from 'react';
import Dexie from 'dexie';

/* We need to ensure the new IDB exists,
     simply reloading the page will trigger the
     creation of the new IDB
  */
const useCheckPersistIDBOpen = () => {
  const isPersistIDBOpen = async () => {
    if (!await Dexie.exists('keyval-store')) {
      window.location.reload();
    }
  }

  useEffect(() => {
    isPersistIDBOpen();
  }, []);
}

export default useCheckPersistIDBOpen;


import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { I18n } from '@aws-amplify/core';

import { useFormat } from '../../../../hooks/useFormat';
import { Cash } from './Cash';
import { CreditToSales } from './CreditToSales';
import { Combined } from './Combined';
import { RectificativeInSimplifiedInvoice } from './RectificativeInSimplifiedInvoice';
import { total as totalSelector } from '../../../../selectors/activeRefund';

/**
 * Mapping of refund methods to their corresponding components.
 */
const refundMethodComponents = {
  cash: Cash,
  creditToSales: CreditToSales,
  combined: Combined,
  rectificativeInSimplifiedInvoice: RectificativeInSimplifiedInvoice,
  rest: RectificativeInSimplifiedInvoice,
  errorOrModificationOfTaxableAmount: RectificativeInSimplifiedInvoice,
  contest: RectificativeInSimplifiedInvoice,
  uncollectibleDebt: RectificativeInSimplifiedInvoice,
  noElectronicSimplifiedInvoice: RectificativeInSimplifiedInvoice,
  noElectronicOrdinaryInvoice: RectificativeInSimplifiedInvoice,
};

/**
 * LastStepForm Component
 *
 * Displays the total refund amount and renders the appropriate refund method component based on the selected method.
 *
 * @param {Object} props - Component properties.
 * @param {string} props.method - Selected refund method.
 *
 * @returns {JSX.Element} The rendered component.
 */
export const LastStepForm = React.memo(({ method }) => {
  const { decimal, fmt } = useFormat();
  const total = useSelector(totalSelector);

  const formattedTotal = useMemo(() => {
    return total.toFormat(decimal, fmt);
  }, [total, decimal, fmt]);

  // Determine the component to render based on the method
  const RefundComponent = refundMethodComponents[method];

  return (
    <div className="my-4">
      <div className="d-flex mb-5 refund-total">
        <h4 className="m-auto">
          {I18n.get('total', 'TOTAL').toUpperCase()}
          <span className="ml-2">{formattedTotal}</span>
        </h4>
      </div>
      {RefundComponent && <RefundComponent />}
    </div>
  );
});

LastStepForm.propTypes = {
  method: PropTypes.oneOf(['cash', 'creditToSales', 'combined', 'rectificativeInSimplifiedInvoice']).isRequired,
};

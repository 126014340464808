import { useEffect, useMemo } from "react";
import { Field, useForm, useFormState } from "react-final-form"
import { useSelector } from "react-redux";
import { renderField } from "../../fields/V0/Fields"
import { I18n } from "aws-amplify"
import { useFormat } from "../../../../hooks/useFormat";
import { get } from "lodash";
import BigNumber from "bignumber.js"
import { total as totalSelector, items as itemsSelector } from '../../../../selectors/activeRefund';

const ItemsSummary = ({items}) => {
  if (!items.length) return null;
  const { decimal, fmt } = useFormat();

  return (
    <div>
      {
        items.map(item => {
          const taxReduced = useMemo(() => item.tax.reduce((prev, t) => prev + Number(t.percentage), 0) / 100, [item]);
          return (
            <div key={item.id} className="d-flex flex-column item-summary">

              <div className="d-flex text-break text-truncate text-nowrap p-0 align-items-center justify-content-between mb-1">
                <div className="text-truncate w-100 col-6 p-0 item-summary__name">
                  {get(item, 'name')}
                </div>
                <div className="text-right text-truncate text-nowrap item-summary__price">
                  {BigNumber(item.price).times(item.quantity).times(1 + taxReduced).toFormat(decimal, fmt)}
                </div>
              </div>

              <div className={"d-flex align-items-end justify-content-between"}>
                <div className="d-flex text-break text-truncate text-nowrap">
                  <div className="item-summary__reference">
                    {!!get(item, 'reference.reference')
                      ? get(item, 'reference.reference') : !!get(item, 'reference')
                        ? get(item, 'reference') : ''
                    }
                  </div>
                </div>
                
                <div className="item-summary__quantity">
                  {I18n.get('', 'Cant.')} {item.quantity}
                </div>
              </div>

            </div>
          )
        })
      }
    </div>
  )
}

export const CreditToSales = () => {
  const total = useSelector(totalSelector)
  const items = useSelector(itemsSelector);
  const { values } = useFormState();
  const { decimal, fmt } = useFormat();
  const form = useForm();

  useEffect(() => {
    form.change('refund.amount', total.toString());
  }, [total])

  const outstandingInvoiceAmount = new BigNumber(get(values, 'refund.document.balance')).toFormat(decimal, fmt);
  const itemsQuantityTotal = items.reduce((prev, item) => prev + Number(item.quantity), 0);

  return (
    <div>
      <Field
        name="refund.amount"
        type="text"
        component={renderField}
        className="d-none"
      />

      <div className="d-flex flex-column flex-md-row">
        <div className="col-12 col-md-6 p-0 pl-4 pl-md-0">
          <p className="refund-section-title">{I18n.get('sumary', 'Resumen')}</p>
          <div>
            <div className="refund-document-info flex-column flex-md-row">
              <div>
                {I18n.get('associatedDocument', 'Documento asociado')}: 
              </div>
              <div>
                {get(values, 'refund.document.numberTemplate.fullNumber')}
              </div>
            </div>
            <div className="refund-document-info flex-column flex-md-row">
              <div>
                {I18n.get('outstandingInvoiceAmount', 'Pendiente por cobrar')}:
              </div>
              <div>
                {outstandingInvoiceAmount}
              </div>
            </div>
            <div className="refund-document-info flex-column flex-md-row">
              <div>
                {I18n.get('totalToRefund', 'total a devolver')}:
              </div>
              <div>
                {total.toFormat(decimal, fmt)}
              </div>
            </div>
            <div className="refund-document-info">
              <div>{I18n.get('refundItems', 'Productos devueltos')}:</div>
              <div className="ml-2 m-md-0">
                {itemsQuantityTotal}
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 col-md-6 p-0 pl-4 mt-4 mt-md-0">
          <p className="refund-section-title pl-md-2">{I18n.get('productsToRefund', 'Productos a devolver')}</p>
          <div className="pl-md-4 items-summary-container">
            <ItemsSummary items={items} />
          </div>
        </div>
      </div>
    </div>
  )
}
import React, { useState } from 'react';
import PropsTypes from "prop-types";
import { Form } from 'react-final-form';
import Body from '../../forms/filters/FilterProducts';
import { transform } from '../../forms/filters/utils';
import { capitalize, isEmpty, values } from 'lodash';
import { I18n } from 'aws-amplify';

const Filters = ({ setFilters, filters, setOpenFilters }) => {

    const [filterState, setFilterState] = useState({});

    // eslint-disable-next-line
    const filtersArray = Object.entries(filterState).map(([filter, value]) => {
        if (value !== "")
            return { filter, value };
    }).filter(filter => filter);

    const submit = (values) => {
        const newFilters = transform(values, filterState);

        setFilterState(newFilters)
        setFilters({
            ...filters,
            ...newFilters,
        });
    };

    const resetFiltersAndClose = () => {
        const newFilters = transform();
        setFilterState({})
        setFilters({
            ...filters,
            ...newFilters,
        });
        setOpenFilters(false);
    };

    const resetFilters = (filter) => {
        const removedFilter = {}
        removedFilter[filter] = "";

        delete filterState[filter];

        setFilters({
            ...filters,
            ...removedFilter,
        });
    }

    return (
        <Form
            onSubmit={submit}
        >
            {({ handleSubmit, form }) => (
                <form noValidate onSubmit={handleSubmit}>
                    {
                        !isEmpty(values(filterState).filter(value => (value !== ''))) && (
                            <div className='form-filter-tags mb-4'>
                                <h5
                                    className='text-muted'
                                >
                                    {I18n.get("activeFilter", "Filtros activos:")}
                                </h5>
                                {
                                    filtersArray?.map((filter) => (
                                        <div id={filter.filter} className='d-flex justify-content-center align-items-center text-muted bg-primary-light px-3 py-1 ml-2 rounded'>
                                            <h5 className='mb-0 mr-2'>
                                                {`${capitalize(I18n.get(filter.filter, filter.filter))}: ${I18n.get(`filter.${filter.value}`, filter.value)}`}
                                            </h5>
                                            <button
                                                type='button'
                                                className='btn-transparent-inline text-muted'
                                                onClick={() => {
                                                    resetFilters(filter.filter);
                                                    form.reset();
                                                }}
                                            >
                                                {"X"}
                                            </button>
                                        </div>
                                    ))
                                }
                            </div>
                        )
                    }
                    <Body
                        resetFiltersAndClose={resetFiltersAndClose}
                    />
                </form>
            )}
        </Form>
    );
}

Filters.propTypes = {
    setFilters: PropsTypes.func,
    setOpenFilters: PropsTypes.func,
    filters: PropsTypes.object,
}

export default Filters;
import React from 'react'
import { I18n } from '@aws-amplify/core';
import { Button } from '@alegradev/smile-ui-react';

const Bottom = ({ onConfirm, onRequestClose, actionMessage, isLast }) => {
  return (
    <div className="modal__bottom h-100 d-flex justify-content-center">
      {!isLast && (
        <Button
          type="button"
          size='medium'
          variant='default'
          emphasis='outline'
          onClick={() => onRequestClose()}
          text={I18n.get("cancel", "Cancelar")}
        />
      )}
      <Button
        type="button"
        size='medium'
        variant='default'
        onClick={() => onConfirm()}
        text={actionMessage}
        full={isLast}
      />
    </div>
  )
}

export default Bottom;
import React from 'react';
import { Field } from 'react-final-form';
import { I18n } from '@aws-amplify/core';
import { capitalize } from 'lodash';
import { useSelector } from 'react-redux';

import { hasPermissionTo } from '../../../../selectors/auth';
import { activeBanks } from '../../../../selectors/banks';
import { renderSelect } from '../../fields/V0/Fields';
import { renderField } from '../../fields/V2';
import {
  decimalPrecision,
  decimalSeparator,
} from '../../../../selectors/company';
import { useDecimalsVersionsGroup } from '../../../../hooks/useDecimalsVersionsGroup';
import { preventNotNumberKeys } from '../../../../utils/keyDown';

const Combined = ({ fmt, form, values }) => {
  const can = useSelector(hasPermissionTo);
  const banks = useSelector(activeBanks);
  const decimal = useSelector(decimalPrecision);
  const { isDecimalActive } = useDecimalsVersionsGroup();
  const separator = useSelector(decimalSeparator);

  return (
    <>
      <div className='col-md-12 mb-3'>
        <Field
          name='cash'
          fieldType='decimal'
          onDecimalsError={(status) => form?.change('decimalsError', {
            ...values?.decimalsError,
            cash: status,
          })}
          decimals={isDecimalActive ? decimal : null}
          separator={separator}
          data-testid='invoice-combined-cash-input'
          component={renderField}
          label={capitalize(I18n.get('cash', 'efectivo'))}
          placeholder={fmt.prefix}
          onKeyDown={preventNotNumberKeys}
        />
      </div>

      <div className='col-md-12 mb-3'>
        <Field
          name='debit'
          fieldType='decimal'
          onDecimalsError={(status) => form?.change('decimalsError', {
            ...values?.decimalsError,
            debit: status,
          })}
          decimals={isDecimalActive ? decimal : null}
          separator={separator}
          data-testid='invoice-combined-debit-input'
          component={renderField}
          label={capitalize(I18n.get('debitCardSaleModal', 'Tarjeta débito'))}
          placeholder={fmt.prefix}
          onKeyDown={preventNotNumberKeys}
        />
      </div>

      <Field
        name='debitBank'
        className='col-md-12'
        component={renderSelect}
        options={
          !can('index', 'bank-accounts') || !can('view', 'bank-accounts')
            ? []
            : banks
        }
        selectInfo={
          !can('index', 'bank-accounts') || !can('view', 'bank-accounts')
            ? I18n.get(
                'userNotAllowed.bank-accounts.view',
                'no tienes permisos para ver la información de las cuentas de bancos'
              )
            : null
        }
        hideNoOptionMessage={
          !can('index', 'bank-accounts') || !can('view', 'bank-accounts')
        }
        getOptionLabel={(option) => option.name}
        getOptionValue={(option) => option.id}
        menuPosition='absolute'
      />

      <div className='col-md-12 mb-3'>
        <Field
          name='credit'
          fieldType='decimal'
          onDecimalsError={(status) => form?.change('decimalsError', {
            ...values?.decimalsError,
            credit: status,
          })}
          decimals={isDecimalActive ? decimal : null}
          separator={separator}
          data-testid='invoice-combined-credit-input'
          component={renderField}
          label={capitalize(I18n.get('creditCard', 'Tarjeta de crédito'))}
          placeholder={fmt.prefix}
          onKeyDown={preventNotNumberKeys}
        />
      </div>

      <Field
        name='creditBank'
        className='col-md-12'
        component={renderSelect}
        options={
          !can('index', 'bank-accounts') || !can('view', 'bank-accounts')
            ? []
            : banks
        }
        selectInfo={
          !can('index', 'bank-accounts') || !can('view', 'bank-accounts')
            ? I18n.get(
                'userNotAllowed.bank-accounts.view',
                'no tienes permisos para ver la información de las cuentas de bancos'
              )
            : null
        }
        hideNoOptionMessage={
          !can('index', 'bank-accounts') || !can('view', 'bank-accounts')
        }
        getOptionLabel={(option) => option.name}
        getOptionValue={(option) => option.id}
        menuPosition='absolute'
      />

      <div className='col-md-12 mb-3'>
        <Field
          name='transfer'
          fieldType='decimal'
          onDecimalsError={(status) => form?.change('decimalsError', {
            ...values?.decimalsError,
            transfer: status,
          })}
          decimals={isDecimalActive ? decimal : null}
          separator={separator}
          data-testid='invoice-combined-transfer-input'
          component={renderField}
          label={capitalize(I18n.get('transfer', 'transferencia'))}
          placeholder={fmt.prefix}
          onKeyDown={preventNotNumberKeys}
        />
      </div>

      <Field
        name='transferBank'
        className='col-md-12'
        component={renderSelect}
        options={
          !can('index', 'bank-accounts') || !can('view', 'bank-accounts')
            ? []
            : banks
        }
        selectInfo={
          !can('index', 'bank-accounts') || !can('view', 'bank-accounts')
            ? I18n.get(
                'userNotAllowed.bank-accounts.view',
                'no tienes permisos para ver la información de las cuentas de bancos'
              )
            : null
        }
        hideNoOptionMessage={
          !can('index', 'bank-accounts') || !can('view', 'bank-accounts')
        }
        getOptionLabel={(option) => option.name}
        getOptionValue={(option) => option.id}
        menuPosition='absolute'
      />
    </>
  );
};

export default Combined;

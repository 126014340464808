import axios from 'axios';
import { get as _get } from 'lodash';
import { sendNewGTMEvent } from '../reducers/company';

export const axiosInterceptor = (store) => {
  axios.interceptors.response.use(
    async (response) => {
      if (response && response.status >= 200 && response.status < 500) {
        localStorage.setItem('apiServiceStatus', 'success');
      } else {
        if(window.navigator.onLine) {
          localStorage.setItem('apiServiceStatus', 'failure');
        }
        await store.dispatch(
          sendNewGTMEvent(
            'pos-api-call-error-triggered',
            {
              type: 'AC_INTERNAL_API_ERROR',
              code: response.status,
            }
          )
        );
      }
      return response;
    },
    async (error) => {
      const errorStatus = _get(error, "response.status");
      if(window.navigator.onLine && errorStatus && errorStatus >= 500) {
        localStorage.setItem('apiServiceStatus', 'failure');
      }
      if(_get(error, 'message') === 'Network Error') {
        await store.dispatch(
          sendNewGTMEvent(
            'pos-api-call-error-triggered',
            {
              type: 'USER_OFFLINE_ERROR',
            }
          )
        );
      } 
      return Promise.reject(error);
    }
  );
};

const checkAndReturnError = (blockRequests, url) => {
  if (blockRequests) {
    return {
      status: 500,
      data: { message: `AC down simulation ${url}` },
    };
  }
  return null;
};

const get = async (url, params = null, userConfig = null, blockRequests = false) => {
  const error = checkAndReturnError(blockRequests, url);
  if (error) {
    return Promise.reject(error);
  }

  return await axios.get(url, {
    params,
    ...userConfig,
  });
}

const post = async (url, params = null, urlParams = null, userConfig = null, blockRequests = false) => {
  const error = checkAndReturnError(blockRequests, url);
  if (error) {
    return Promise.reject(error);
  }

  return await axios.post(url, params, {
    headers: { "Content-Type": "application/json" },
    params: urlParams,
    ...userConfig,
  });
}

const put = async (url, params = null, urlParams = null, userConfig = null, blockRequests = false) => {
  const error = checkAndReturnError(blockRequests, url);
  if (error) {
    return Promise.reject(error);
  }

  return await axios.put(url, params, {
    params: urlParams,
    ...userConfig,
  });
}

const patch = async (url, params = null, urlParams = null, userConfig = null, blockRequests = false) => {
  const error = checkAndReturnError(blockRequests, url);
  if (error) {
    return Promise.reject(error);
  }

  return await axios.patch(url, params, {
    params: urlParams,
    ...userConfig,
  });
}

const httpDelete = async (url, urlParams = null, userConfig = null, blockRequests = false) => {
  const error = checkAndReturnError(blockRequests, url);
  if (error) {
    return Promise.reject(error);
  }

  return await axios.delete(url, {
    params: urlParams,
    ...userConfig,
  });  
}

const exportDefault = {
  get,
  post,
  put,
  patch,
  delete: httpDelete,
}

export default exportDefault

import alegraAPI from '../../../reducers/alegraAPI';

import { get } from 'lodash';

export const fetchContacts = async (metadata) => {
  const params = {
    ...metadata.filters,
    metadata: true,
    start: metadata?.pagination?.start || 0,
    limit: metadata?.pagination?.limit || 10,
    order_direction: metadata?.pagination?.order_direction || 'DESC',
    order_field: metadata?.pagination?.order_field || 'created_at',
    type: 'client',
  };

  if (metadata?.filters?.search) {
    params.query = metadata.filters.search;
  }

  const data = await alegraAPI.get('/contacts', params);

  return get(data, 'data', {});
};

export const fetchContact = async (id, fields = []) => {
  try {
    const data = await alegraAPI.get(
      `/contacts/${id}?fields=${fields.join(',')}`
    );
    return get(data, 'data', {});
  } catch (error) {
    return error;
  }
};

export const fetchContactByIdentification = async (identification) => {
  try {
    const data = await alegraAPI.get(
      `/contacts?identification=${identification}&limit=1`
    );
    return get(data, 'data', {});
  } catch (error) {
    return error;
  }
};

import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { sendClarityCustomTags } from '../../reducers/company';
import { membershipSelector } from '../../selectors/company';
import { isEmpty } from 'lodash';

const usePageTracking = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const membership = useSelector(membershipSelector);
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    setInitialized(true);
  }, []);

  useEffect(() => {
    if (initialized) {
      if (isEmpty(membership)) return;
      if (process.env.REACT_APP_ALEGRA_COOKIE === 'al-test-days-of-thunder')
        return;
      dispatch(sendClarityCustomTags());
    }
  }, [initialized, location, membership]);
};

export default usePageTracking;

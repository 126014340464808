import BasicInfo from './BasicInfo';
import Bank from './Bank';
import Shift from './Shift';
import CostCenter from './CostCenter';
import Seller from './Seller';
import PendingInvoices from './PendingInvoices';
import AdditionalSettings from './AdditionalSettings';
import Users from './Users';

const stationFormParts = {
  BasicInfo,
  Bank,
  Shift,
  CostCenter,
  Users,
  Seller,
  PendingInvoices,
  AdditionalSettings,
}

export default stationFormParts;
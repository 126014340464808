import React from 'react';
import PropTypes from 'prop-types';
import RefundDetailsColombia from './RefundDetailsColombia';
import RefundDetailsSpain from './RefundDetailsSpain';
import RefundDetailsDefault from './RefundDetailsDefault';
import { COUNTRIES } from '../../../utils/enums/countries';

/**
 * Factory to select the appropriate RefundDetails Component based on country.
 *
 * @param {string} country - The country code.
 * @param {Object} props - Props to pass to the RefundDetails component.
 * @returns {JSX.Element} - Rendered RefundDetails Component.
 */
const RefundDetailsFactory = ({ country, ...props }) => {
  const strategies = {
    [COUNTRIES.COLOMBIA]: RefundDetailsColombia,
    [COUNTRIES.SPAIN]: RefundDetailsSpain,
  };

  const SelectedStrategy = strategies[country] || RefundDetailsDefault;

  return <SelectedStrategy country={country} {...props} />;
};

RefundDetailsFactory.propTypes = {
  country: PropTypes.string.isRequired,
  props: PropTypes.shape({
    refund: PropTypes.object.isRequired,
    statusColor: PropTypes.string.isRequired,
    isTicketBaiEnabled: PropTypes.bool.isRequired,
    dateFormat: PropTypes.string,
  }),
};

export default RefundDetailsFactory;

import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';

import { hasPermissionTo } from '../selectors/auth'
import { handleError } from '../utils/errors'
import { fetch, getInitialState } from '../utils'
import { APIGraphqlSelector } from '../selectors/app';
import { fetchPriceListApi } from '../components/inventory/PriceLists/queries';

const initialState = {
  entities: {},
  ids: [],
  loading: 'idle',
  error: null,
  version: 1
}

export const fetchPriceListsPartial = (api) => async (metadata, params) => {
  return await fetchPriceListApi(metadata, params);
}

export const fetchPriceLists = createAsyncThunk(
  'priceLists/fetch',
  async ({ preloaded, ...params }, { rejectWithValue, getState }) => {
    try {
      const can = hasPermissionTo(getState())
      const APIGraphql = APIGraphqlSelector(getState());
      if (!can('view', 'price-lists') || !can('index', 'price-lists'))
        return []

      return await fetch(fetchPriceListsPartial(APIGraphql), !!preloaded ? preloaded : [], params)
    } catch (error) {
      return rejectWithValue(handleError(error))
    }
  }
)

export const adapter = createEntityAdapter();

const appSlice = createSlice({
  name: 'priceLists',
  initialState: getInitialState('priceLists', initialState),
  reducers: {
    addPriceList: adapter.addOne,
    updatePriceList: adapter.updateOne,
    removePriceList: adapter.removeOne,
  },
  extraReducers: builder => {
    builder.addCase(fetchPriceLists.fulfilled, (state, action) => {
      adapter.setAll(state, action.payload)
      state.loading = 'idle'
      state.error = null
    })
    builder.addCase(fetchPriceLists.pending, (state) => {
      state.loading = 'loading'
      state.error = null
    })
    builder.addCase(fetchPriceLists.rejected, (state, action) => {
      state.loading = 'idle'
      state.error = action.payload
    })
  }
});

const { actions, reducer } = appSlice;

export const { addPriceList, updatePriceList, removePriceList } = actions;

export const priceListsSelector = adapter.getSelectors(state => state.priceLists);

export default reducer;
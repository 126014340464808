import React from 'react'

import Onboarding from './onboarding/Onboarding'

const Tours = () => {
  return (
    <>
      <Onboarding />
    </>
  )
}

export default Tours;
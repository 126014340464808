import React from 'react'
import { useSelector } from 'react-redux';
import { I18n } from '@aws-amplify/core';
import { upperCase } from 'lodash';

import { country as countrySelector } from '../../../selectors/company'
import { allNumerations } from '../../../selectors/numerations'
import { getInvoiceType, isAfterDateToProduction } from './utilities';
const Title = ({ setting, invoice }) => {
    const country = useSelector(countrySelector);
    const isAfterDate = isAfterDateToProduction(invoice, "2023-04-25");

    if (country !== 'panama')
        return null;

    if (!invoice.stamp)
        return null;

    if (isAfterDate)
        return <TitleV1 setting={setting} invoice={invoice} />

    return (
        <div className={`h3 font-weight-bold w-100 text-${setting.align} py-2`}>
            {upperCase(I18n.get('auxiliaryElectronicInvoiceReceipt', 'Comprobante Auxiliar de Factura Electrónica'))}
        </div>
    )
};

const TitleV1 = ({ setting: { align }, invoice }) => {
    const country = useSelector(countrySelector);
    const numerations = useSelector(allNumerations);
    return (
        <div className={`font-weight-bold w-100 text-${align} py-2`}>
            <p>
                {I18n.get('dgi', 'DGI')}
            </p>

            {upperCase(I18n.get('auxiliaryElectronicInvoiceReceipt', 'Comprobante Auxiliar de Factura Electrónica'))}

            <p>
                {upperCase(getInvoiceType(invoice, country, numerations))}
            </p>
        </div>
    )
}

export default Title;
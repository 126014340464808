import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { I18n } from 'aws-amplify';
import { Field } from 'react-final-form';
import { capitalize, get } from 'lodash';

import { renderField, renderIdentificationNumberField, renderSelect } from '../../forms/fields/V0/Fields';
import countries from '../../countriesData/general/countries';
import { regimes as regimesSelector } from '../../../selectors/regimes'
import { companySelector } from '../../../selectors/company';
import Radio from '../../common/Radio';
import Label from '../../common/Label';

import colombiaAddresses from './citiesDepartments.json'
import { idTypes as identificationsColombia } from './idTypes'
import kindOfPersonOptions from './kindOfPerson';
import nationalityKindOfPerson from './nationalityKindOfPerson';
import contactsBearerAPI from '../../../reducers/contactsBearerAPI';
import { Tooltip } from '@alegradev/smile-ui-react';
import { calculateDV } from '../../../pages/contacts/contact/form/basicData/Identification';

const isUpperCase = (str) => {
  return str === str.toUpperCase();
}

const searchIdentificationColombia = async (idType, props) => {
  try {
    if (props.country !== 'colombia') {
      console.warn('This function is configured to handle searches only for Colombia.');
      return;
    }

    const identification = get(props, 'values.identification.number');
    const identificationType = { idType: idType };

    props.setSearchingId && props.setSearchingId(true);

    const response = await contactsBearerAPI.get('/search-by-id-number', {
      identification,
      ...identificationType,
      version: props.country,
    });

    props.setSearchingId && props.setSearchingId(false);

    const data = get(response, 'data', null) ? get(response, 'data') : null;

    if (data) {
      if (idType !== 'NIT' && get(data, 'socialReason')) {
        const nit = identificationsColombia(false).find((id) => id.key === 'NIT');
        props.form.change('identification.type', nit);
      } else {
        const idInfo = identificationsColombia(false).find((id) => id.key === idType);
        props.form.change('identification.type', idInfo);
      }

      const name = get(data, 'name', null);
      const firstName = get(data, 'firstName', null);
      const socialReason = get(data, 'socialReason', null);
      const lastName = get(data, 'firstLastname', null);
      const email = get(data, 'email', null);

      if (name) {
        props.form.change('firstName', name);
      }

      if (firstName) {
        props.setPersonType(kindOfPersonOptions[1].key);
        props.form.change('firstName', firstName);
        props.form.change('kindOfPerson', kindOfPersonOptions[1]);
      }

      if (socialReason) {
        props.setPersonType(kindOfPersonOptions[0].key);
        props.form.change('name', socialReason);
        props.form.change('kindOfPerson', kindOfPersonOptions[0]);
      }

      if (lastName) {
        const secondLastName = get(data, 'secondLastname', null);
        props.form.change('lastName', lastName + (secondLastName ? ' ' + secondLastName : ''));
      }

      props.form.change('secondName', get(data, 'secondName', null));

      if (email) {
        props.form.change('email', email);
      }
    }
  } catch (error) {
    props.setSearchingId && props.setSearchingId(false);
    console.error('Error during identification search:', error);
  }
};

const CompanySettings = (props) => {
  const company = useSelector(companySelector);
  const [personType, setPersonType] = useState(get(kindOfPersonOptions.find(opt => opt.key === company.kindOfPerson), 'key', null) || 'PERSON_ENTITY');
  const regimes = useSelector(regimesSelector);
  const identification = get(props, 'values.identification.type.key', null);
  const companyIsElectronic = get(props, 'values.settings.electronicInvoicing', false);
  const foreignId = identification === 'DIE' || identification === 'PP' || identification === 'TE' || identification === 'FOREIGN_NIT';
  const showCountry = !!identification && foreignId;


  const changePersonType = (type) => {
    setPersonType(type);
    props.values.kindOfPerson = kindOfPersonOptions.find(opt => opt.key === type);
  }

  return (
    <div className="w-100">
      <p style={{ fontSize: '14px', marginBottom: '8px' }}>
        {I18n.get('kindOfPerson', 'Tipo de persona')} <span className="text-primary">*</span>
      </p>
      <div className="row m-0 mb-5">
        <div className="col-6 p-0 pr-3">
          <Radio
            label={I18n.get('naturalPerson', 'Persona natural')}
            containerClassName="p-3"
            active={personType === 'PERSON_ENTITY'}
            action={() => changePersonType('PERSON_ENTITY')} />
        </div>
        <div className="col-6 p-0 pl-3">
          <Radio
            label={I18n.get('legalPerson', 'Persona jurídica')}
            containerClassName="p-3"
            active={personType === 'LEGAL_ENTITY'}
            action={() => changePersonType('LEGAL_ENTITY')} />
        </div>
      </div>

      {personType === 'PERSON_ENTITY' && <NaturalPersonConfiguration {...props} setPersonType={setPersonType} />}
      {personType === 'LEGAL_ENTITY' && <LegalPersonConfiguration {...props} setPersonType={setPersonType} />}

      <div className="form-row" style={{ margin: "0 -1.5rem" }}>
        {(personType === 'LEGAL_ENTITY' ||
          ["NIT"].includes(get(props, 'values.identification.type.key'))) && (
            <>
              <div className="col-md-4 px-4 pb-4">
                <Label title={I18n.get('commercialName', 'Nombre comercial')} />
                <Field
                  name="tradeName"
                  component={renderField}
                  type="text"
                />
              </div>
              <div className="col-md-4 px-4 pb-4">
                <Label title={I18n.get('taxLiability', 'Responsabilidad tributaria')} required />
                <Field
                  name="regime"
                  options={regimes}
                  component={renderSelect}
                  getOptionLabel={option => option.value}
                  getOptionValue={option => option.key}
                  required
                />
              </div>
            </>
          )}

        {showCountry && (
          <div className="col-md-4 px-4 pb-4">
            <Label title={I18n.get('country', 'Pais')} required />
            <Field
              name="address.country"
              component={renderSelect}
              options={countries}
              getOptionLabel={option => option.value}
              getOptionValue={option => option.key}
              isSearchable={true}
              required
            />
          </div>
        )}

        {(!showCountry || get(props, 'values.address.country.key') === 'COL') && (
          <div className="col-md-4 px-4 pb-4">
            <Label title={[
              capitalize(I18n.get('city', 'municipio')),
              capitalize(I18n.get('department', 'departamento')),
            ].join(' / ')} required={companyIsElectronic} />
            <Field
              name="address.combined"
              options={colombiaAddresses}
              component={renderSelect}
              getOptionLabel={option => option.value}
              getOptionValue={option => option.key}
              isSearchable={true}
              required={companyIsElectronic}
            />
          </div>
        )}
        <div className="col-md-4 px-4 pb-4">
          <Label title={I18n.get('address', 'Dirección')}
            required={companyIsElectronic} />
          <Field
            name="address.address"
            component={renderField}
            type="text"
            required={companyIsElectronic}
          />
        </div>
        <div className="col-md-4 px-4 pb-4">
          <Label title={I18n.get('zipCode', 'Código postal')} />
          <Field
            name="address.zipCode"
            component={renderField}
            type="text"
          />
        </div>
        <div className="col-md-4 px-4 pb-4">
          <Label title={I18n.get('electronicMail', 'Correo electrónico')} />
          <Field
            name="email"
            component={renderField}
            type="text"
          />
        </div>
        <div className="col-md-4 px-4 pb-4">
          <Label title={I18n.get('phone', 'Teléfono')} />
          <Field
            name="phone"
            component={renderField}
            type="text"
          />
        </div>
        <div className="col-md-4 px-4 pb-4">
          <Label title={I18n.get('website', 'Sitio web')} />
          <Field
            name="website"
            component={renderField}
            type="text"
          />
        </div>
      </div>
    </div>
  )
}

const NaturalPersonConfiguration = (props) => {
  const [searchingId, setSearchingId] = useState(false);
  const options = identificationsColombia(true);
  const company = useSelector(companySelector);
  const identification = get(props, 'values.identification.type.key', null);
  const identificationIsNIT = identification === "NIT";
  const companyIsElectronic = get(props, 'values.settings.electronicInvoicing', false);
  const identificationType = get(props, 'values.identification.type.key', '');

  useEffect(() => {
    props.form.change('identification.type', options.find(opt => opt.key === get(company, 'identificationObject.type')));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className="form-row" style={{ margin: "0 -1.5rem" }}>
      <div className="col-md-4 px-4 pb-4">
        <Label title={I18n.get('identificationType', 'Tipo de identificación')}
          required={companyIsElectronic || identificationIsNIT} />
        <Field
          name="identification.type"
          component={renderSelect}
          options={options}
          getOptionLabel={option => `${option.abbreviature} - ${option.value}`}
          getOptionValue={option => option.key}
          required={companyIsElectronic || identificationIsNIT}
        />
      </div>
      {identificationIsNIT
        ? <div className="col-md-4 px-4 pb-4 d-flex">
          <div className="col-md-8 p-0">
            <Label title={I18n.get('identificationNumber', 'Número de identificación')} required />
            <Field
              name="identification.number"
              component={renderIdentificationNumberField}
              type="text"
              onBlur={() => {
                calculateDV(props);
              }}
              searchHelp={I18n.get(`getNIT`, `consultar NIT`)}
              searchingIdentification={searchingId}
              onSearchIdentification={() =>
                searchIdentificationColombia(identificationType, { ...props, searchingId, setSearchingId })
              }
              country={props.country}
              required
            />
          </div>
          <div className="col-md-4 p-0 pl-3">
            <Label title={I18n.get('dv', 'DV')} required />
            <Field
              name="identification.dv"
              component={renderField}
              type="text"
              disabled
              required
            />
          </div>
        </div>
        : <div className="col-md-4 px-4 pb-4">
          <Label title={I18n.get('identificationNumber', 'Número de identificación')}
            required={(companyIsElectronic || identificationIsNIT) && identification !== 'CC'} />
          <Field
            name="identification.number"
            component={renderField}
            type="text"
            required={(companyIsElectronic || identificationIsNIT) && identification !== 'CC'}
          />
        </div>}
      {["NIT"].includes(get(props, 'values.identification.type.key')) ? (
        <div className="col-md-4 px-4 pb-4">
          <Label title={I18n.get('personTypeByNacionality', 'Tipo de persona según nacionalidad')}
            required={get(props, 'values.identification.type.key', null) === "NIT"} />
          <Field
            name="nationalityKindOfPerson"
            component={renderSelect}
            options={nationalityKindOfPerson}
            getOptionLabel={option => option.value}
            defaultValue={nationalityKindOfPerson.find(opt => opt.key === props.values.identificationObject.nationalityKindOfPerson)}
            required={get(props, 'values.identification.type.key', null) === "NIT"}
          />
        </div>
      ) : get(props, 'values.identification.type.key') === "FOREIGN_NIT"
        ? (
          <></>
        )
        : (
          <div className="col-md-4 px-4 pb-4" />
        )}

      {get(props, 'values.identification.type.key', null) === "FOREIGN_NIT" ? (
        <div className="col-md-4 px-4 pb-4">
          <Label title={I18n.get('name', 'Nombre')} required />
          <Field
            name="name"
            component={renderField}
            type="text"
            required
          />
        </div>
      ) : (
        <>
          <div className="col-md-4 px-4 pb-4">
            <Label title={I18n.get('firstName', 'Primer nombre')} required />
            <Field
              name="firstName"
              component={renderField}
              type="text"
              required
            />
          </div>
          <div className="col-md-4 px-4 pb-4">
            <Label title={I18n.get('secondName', 'Segundo nombre')} />
            <Field
              name="secondName"
              component={renderField}
              type="text"
              required
            />
          </div>
          <div className="col-md-4 px-4 pb-4">
            <Label title={I18n.get('lastName', 'Apellidos')} required />
            <Field
              name="lastName"
              component={renderField}
              type="text"
              required
            />
          </div>
        </>
      )}
    </div>
  )
}

const LegalPersonConfiguration = (props) => {
  const [searchingId, setSearchingId] = useState(false);
  const options = identificationsColombia(true);
  const identificationIsNIT = get(props, 'values.identification.type.key', null) === "NIT";
  const companyIsElectronic = get(props, 'values.settings.electronicInvoicing', false);
  const identificationType = get(props, 'values.identification.type.key', '');

  useEffect(() => {
    props.form.change('identification.type', options.find(opt => opt.key === 'NIT'));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className="form-row" style={{ margin: "0 -1.5rem" }}>
      <div className="col-md-4 px-4 pb-4">
        <Label title={I18n.get('identificationType', 'Tipo de identificación')}
          required={identificationIsNIT || companyIsElectronic} />
        <Field
          name="identification.type"
          component={renderSelect}
          options={options}
          getOptionLabel={option => `${option.abbreviature} - ${option.value}`}
          getOptionValue={option => option.key}
          disabled
          required={identificationIsNIT || companyIsElectronic}
        />
      </div>
      <div className="col-md-4 px-4 pb-4 d-flex">
        <div className="col-md-8 p-0">
          <Label title={I18n.get('identificationNumber', 'Número de identificación')} required />
          <Field
            name="identification.number"
            component={renderIdentificationNumberField}
            type="text"
            onBlur={() => {
              calculateDV(props);
            }}
            searchHelp={I18n.get(`getNIT`, `consultar NIT`)}
            searchingIdentification={searchingId}
            onSearchIdentification={() =>
              searchIdentificationColombia(identificationType, { ...props, searchingId, setSearchingId })
            }
            country={props.country}
            required
          />
        </div>
        <div className="col-md-4 p-0 pl-3">
          <Label title={I18n.get('dv', 'DV')} required />
          <Field
            name="identification.dv"
            component={renderField}
            type="text"
            disabled
            required
          />
        </div>
      </div>
      <div className="col-md-4 px-4 pb-4">
        <Label title={I18n.get('nameOrSocialReason', 'Nombre o razón social')} required />
        <Tooltip
          overlay={I18n.get('', 'Tu razón social coincide con tu RUT y debe continuar así para que puedas emitir facturas electrónicas.')}
          visible={false}
          placement='bottom'
        >
          <Field
            name="name"
            component={renderField}
            type="text"
            required
          />
        </Tooltip>
      </div>
    </div>
  )
}

export default CompanySettings
import React, { useState } from 'react';
import { I18n } from '@aws-amplify/core';
import { useSelector, useDispatch } from 'react-redux';
import { NavLink } from 'react-router-dom';

import {
  logo,
  name,
  country as countrySelector,
} from '../../../../selectors/company';
import { name as userNameSelector, email } from '../../../../selectors/user';
import { openSideModal } from '../../../../reducers/sideModals';
import LogoutComponent from '../../../common/LogoutComponent';
import MenuOptions from './MenuOptions';
import Typography from '../../../common/Typography';
import MenuOption from '../../../common/MenuOption';
import ElectronicPayrollStatus from '../../../settings/Invoices/ElectronicPayrollStatus';
import useElectronicPayrollStatus from '../../../../hooks/useElectronicPayrollStatus/hook';
import { Popover } from '@alegradev/smile-ui-react';
import { showNotificationDownloadAppMenu } from '../../../../selectors/app';
import { setShowNotificationDownloadAppMenu } from '../../../../reducers/app';

const Menu = () => {
  const [logoError, setLogoError] = useState(false);
  const dispatch = useDispatch();
  const companyLogo = useSelector(logo);
  const companyName = useSelector(name);
  const userName = useSelector(userNameSelector);
  const userEmail = useSelector(email);

  const country = useSelector(countrySelector);
  const { canElectronicPayroll } = useElectronicPayrollStatus();
  const showNotificationDownloadApp = useSelector(showNotificationDownloadAppMenu);

  const isAvailableCountry = [
    'usa',
    'other',
    'spain',
    'peru',
    'chile',
    'argentina',
    'southAfrica',
    'kenya',
    'nigeria',
    'costaRica',
    'republicaDominicana',
    'mexico',
    'panama',
    'colombia',
  ].includes(country);

  return (
    <Popover
      defaultVisible={showNotificationDownloadApp}
      title={
        <Typography 
          text={I18n.get('titleNotificationDownloadAppMenu', 'Aquí estará disponible 👆')} 
          type='body-2-bold' 
          variant='inverse'
        />
      }
      placement='bottomRight'
      description={I18n.get('textNotificationDownloadAppMenu', 'Puedes descargar tu POS para escritorio desde el icono superior derecho.')}
      showCloseButton
      onClose={() => dispatch(setShowNotificationDownloadAppMenu(false))}
    >
      <MenuOption
        id='menu'
        eventName='Account Button'
        tooltipAlign='bottomRight'
        tooltip={
          <div>
            <Typography
              type='caption-bold'
              variant='white'
              text={companyName}
            />
            <Typography type='caption-bold' variant='white' text={userEmail} />
          </div>
        }
        headerClassName='btn btn-transparent bg-white rounded-pill header__profile-btn mx-2 new-menu-container-button d-flex align-items-center'
        header={
          <div className='d-flex align-items-center'>
            <div className='header__profile-btn-logo d-sm-inline-flex d-none'>
              {!!companyLogo && !logoError ? (
                <img
                  className='profile-logo'
                  src={companyLogo}
                  alt={companyName}
                  onError={() => setLogoError(true)}
                />
              ) : (
                <div className='d-flex align-items-center justify-content-start text-truncate'>
                  <Typography
                    type='label-2'
                    variant='secondary'
                    text={companyName}
                  />
                </div>
              )}
            </div>

            <div className='profile-btn-new-initial'>
              <Typography
                type='label-3'
                variant='secondary'
                text={
                  !!userName ? userName[0] : !!userEmail ? userEmail[0] : ''
                }
              />
            </div>
          </div>
        }
        extraClassName='header__profile-options'
      >
        <div className='p-4 border-bottom menu-options-menu-header'>
          <Typography
            type='body-3-regular'
            variant='secondary'
            text={companyName ?? userName}
          />
          <Typography
            type='caption-regular'
            variant='tertiary'
            text={userEmail}
          />
        </div>
        {isAvailableCountry ? (
          <MenuOptions />
        ) : (
          <>
            {!!canElectronicPayroll && (
              <div className='electronic-payroll-menu'>
                <ElectronicPayrollStatus />
              </div>
            )}

            <div className='menu-option'>
              <NavLink to='/plan/consumption'>
                <Typography
                  type='body-3-regular'
                  variant='secondary'
                  text={I18n.get('planConsumption', 'Consumo del plan')}
                />
              </NavLink>
            </div>

            <div className='menu-option'>
              <NavLink to='/'>
                <Typography
                  type='body-3-regular'
                  variant='secondary'
                  text={I18n.get('checkIn', 'Facturar')}
                />
              </NavLink>
            </div>

            <div className='menu-option'>
              <div
                onClick={() =>
                  dispatch(openSideModal({ sideModal: 'company' }))
                }
              >
                <Typography
                  type='body-3-regular'
                  variant='secondary'
                  text={I18n.get('myCompany', 'Mi empresa')}
                />
              </div>
            </div>

            <div className='menu-option'>
              <NavLink to='/settings'>
                <Typography
                  type='body-3-regular'
                  variant='secondary'
                  text={I18n.get('settings', 'Configuraciones')}
                />
              </NavLink>
            </div>

            <div className='menu-option'>
              <LogoutComponent>
                <Typography
                  type='body-3-regular'
                  variant='secondary'
                  text={I18n.get('logout', 'cerrar sesión')}
                />
              </LogoutComponent>
            </div>
          </>
        )}
      </MenuOption>
    </Popover>
  );
};

export default Menu;

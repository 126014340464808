import { Typography } from '@alegradev/smile-ui-react';

export const FastOptionButton = ({ onChange, text, fontSize, cash }) => {
  return (
    <button
      type='button'
      className='btn btn-cancel'
      onClick={() => onChange(cash)}
    >
      <Typography
        maxLength={15}
        text={text}
        style={{
          fontSize,
        }}
        type='label-1'
      />
    </button>
  );
};

export default FastOptionButton;

import React from 'react'
import { useSelector } from 'react-redux';

import { items as itemsSelector } from '../../../../selectors/editInvoice';
import Item from './Item';

const InvoiceItems = ({ openEditItem }) => {
  const items = useSelector(state => itemsSelector(state))

  return (
    <div className="h-100 active-refund__items">
      {(!!items && items.length > 0) &&
        items.map((item, index) => (
          <Item 
            key={item.id} 
            item={item} 
            index={index} 
            openEditItem={openEditItem}
          />
        ))
      }
    </div>
  )
}

export default InvoiceItems;
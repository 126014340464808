export const countryConfig = {
  colombia: {
    decimalSeparator: ',',
    groupSeparator: '.',
    currencyPrefix: '$',
  },
  republicaDominicana: {
    decimalSeparator: '.',
    groupSeparator: ',',
    currencyPrefix: '$',
  },
  mexico: {
    decimalSeparator: '.',
    groupSeparator: ',',
    currencyPrefix: '$',
  },
  peru: {
    decimalSeparator: '.',
    groupSeparator: ',',
    currencyPrefix: 'S/ ',
  },
  costaRica: {
    decimalSeparator: ',',
    groupSeparator: '.',
    currencyPrefix: '₡',
  },
  international: {
    decimalSeparator: '.',
    groupSeparator: ',',
    currencyPrefix: '$',
  },
  usa: {
    decimalSeparator: '.',
    groupSeparator: ',',
    currencyPrefix: '$',
  },
  spain: {
    decimalSeparator: ',',
    groupSeparator: '.',
    currencyPrefix: '€',
  },
  panama: {
    decimalSeparator: '.',
    groupSeparator: ',',
    currencyPrefix: '$',
  },
  argentina: {
    decimalSeparator: ',',
    groupSeparator: '.',
    currencyPrefix: '$',
  },
  other: {
    decimalSeparator: '.',
    groupSeparator: ',',
    currencyPrefix: '$',
  },
};

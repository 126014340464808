import React from 'react';
import { Field } from 'react-final-form';
import { I18n } from '@aws-amplify/core';
import { graphqlOperation } from '@aws-amplify/api';
import { capitalize, get } from 'lodash';
import { useSelector } from 'react-redux'

import * as queries from '../../../../graphql/queries'
import { getNumber } from '../../../../utils/invoices'
import { country as countrySelector, decimalPrecision } from '../../../../selectors/company'
import { activeBanks } from '../../../../selectors/banks'
import { renderField, renderSelect } from '../../fields/V0/Fields';
import { refundMaxDecimals } from './Cash';
import { APIGraphqlSelector } from '../../../../selectors/app';

const Combined = ({ values, client }) => {
  const banks = useSelector(activeBanks)
  const country = useSelector(countrySelector)
  const decimal = useSelector(decimalPrecision)
  const APIGraphql = useSelector(APIGraphqlSelector);

  return (
    <div className="form-fields form-row justify-content-start w-100">
      <div className="form-row col-md-6">
        <p className="h4 text-uppercase text-muted my-3 px-2">
          {I18n.get('refundCash', 'Devolución de dinero')}
        </p>

        <Field
          name="refund.amount"
          component={renderField}
          type="number"
          className="col-12"
          required
          label={capitalize(I18n.get('amount', 'monto'))}
          onChange={e => refundMaxDecimals(e, `refund.amount`, decimal, values, country)}
        />

        <Field
          name="refund.account"
          component={renderSelect}
          className="col-12"
          options={banks}
          getOptionLabel={option => option.name}
          getOptionValue={option => option.id}
          label={capitalize(I18n.get('bankAccount', 'cuenta bancaria'))}
          menuPosition="absolute"
          required
        />

        <Field
          name="refund.observations"
          component={renderField}
          className="col-12"
          type="textarea"
          label={capitalize(I18n.get('observations', 'observaciones'))}
        />
      </div>

      <div className="form-row col-md-6">
        <p className="h4 text-uppercase text-muted my-3 px-2">
          {I18n.get('creditToInvoice', 'crédito a factura')}
        </p>

        <Field
          name="invoice.invoice"
          component={renderSelect}
          className="col-12"
          loadOptions={async () => {
            try {
              const response = await APIGraphql(graphqlOperation(queries.getClientInvoices, {
                clientName: client.name,
              }))

              return get(response, 'data.getClientInvoices.data', [])
            } catch {
              return []
            }
          }}
          defaultOptions
          getOptionLabel={option => getNumber(option, country)}
          getOptionValue={option => option.id}
          required
          label={capitalize(I18n.get('clientInvoice', 'factura del cliente'))}
          menuPosition="absolute"
        />

        <Field
          name="invoice.amount"
          component={renderField}
          type="number"
          className="col-12"
          required
          label={capitalize(I18n.get('amount', 'monto'))}
        />

        <Field
          name="invoice.paid"
          type="number"
          className="col-12"
          disabled
          label={capitalize(I18n.get('paid', 'pagado'))}
          render={props =>
            renderField({
              ...props, input: {
                ...props.input, value: get(values, 'invoice.invoice.totalPaid', '')
              }
            })}
        />

        <Field
          name="invoice.pending"
          type="number"
          className="col-12"
          disabled
          label={capitalize(I18n.get('pending', 'pendiente'))}
          render={props =>
            renderField({
              ...props, input: {
                ...props.input, value: get(values, 'invoice.invoice.balance', '')
              }
            })}
        />
      </div>
    </div>
  )
}

export default Combined;

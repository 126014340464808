import { useMemo, useState } from 'react';

import globalInvoiceColumns from './columns';
import globalInvoiceEmptyState from './empty';
import { useGlobalInvoice } from '../context';
import GlobalInvoiceTableHeader from './header';
import { APIGraphqlSelector } from '../../../../selectors/app';
import { useGlobalInvoiceTicketItemsQuery } from '../services/queries';
import { filter, get, isNull, orderBy } from 'lodash';
import { useSelector } from 'react-redux';
import { Space, Table } from '@alegradev/smile-ui-react';

function GlobalInvoiceTable() {
  const { toggleSelectedRows, initialRows, selectedRows } = useGlobalInvoice();
  const columns = useMemo(() => globalInvoiceColumns(), []);
  const [metadata, setMetadata] = useState({});
  const APIGraphql = useSelector(APIGraphqlSelector);
  const { isLoading, data, isFetching } = useGlobalInvoiceTicketItemsQuery(
    metadata,
    APIGraphql
  );
  const items = get(data, 'data', []);

  const rows = useMemo(() => {
    const mergedRows = [...items, ...initialRows];
    const filteredRows = filter(mergedRows, (row) => !isNull(row));
    return orderBy(filteredRows, ['id'], ['desc']);
  }, [items, initialRows]);
  
  return (
    <>
      <GlobalInvoiceTableHeader
        onChangeDate={(date) =>
          setMetadata({
            ...metadata,
            filters: { ...metadata?.filters, datePicker: date },
          })
        }
      />
      <Space height={10} />
      <Table
        columns={columns}
        data={rows}
        emptyComponent={globalInvoiceEmptyState()}
        noResultsComponent={globalInvoiceEmptyState()}
        onChange={(tableState) => setMetadata(tableState)}
        onSelected={(rows) => toggleSelectedRows(rows)}
        loading={isLoading || isFetching}
        initialSelectedRows={selectedRows}
        limit={30}
        selectable
        minimal
        hideSearchBar
        hideToolbar
        hidePagination
      />
    </>
  );
}

export default GlobalInvoiceTable;

import Cookie from 'js-cookie';
import http from '../utils/http';

const baseUrl = process.env.REACT_APP_ALEGRA;
const baseConfig = {
  headers: {
    'X-Data-Source': 'pos',
    Authorization: `Basic ${Cookie.get(process.env.REACT_APP_ALEGRA_COOKIE)}`,
  },
};

const get = async (url, params = null, userConfig = null) =>
  await http.get(
    `${baseUrl}${url}`,
    params,
    Object.assign({}, baseConfig, userConfig),
    Cookie.get('pos-block-api-request')
  );

const defaultExport = {
  get,
};

export default defaultExport;

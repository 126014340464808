import { createContext, useContext, useState } from 'react';
import { useDispatch } from 'react-redux';
import { sendNewGTMEvent } from '../../../reducers/company';

const HeaderContext = createContext({
  activeMenu: '',
  setActiveMenu: () => null,
  showTutorial: false,
  closeShowTutorial: () => null,
});

export const HeaderProvider = ({ children }) => {
  const [activeMenu, setMenu] = useState('');
  const dispatch = useDispatch();

  const setActiveMenu = (menu = '', eventName = '') => {
    setMenu(menu);
    if (eventName !== '') {
      dispatch(
        sendNewGTMEvent('pos-topbar-menu-opened', {
          name: eventName,
        })
      );
    }
  };

  return (
    <HeaderContext.Provider value={{ activeMenu, setActiveMenu }}>
      {children}
    </HeaderContext.Provider>
  );
};

export const useHeader = () => {
  return useContext(HeaderContext);
};

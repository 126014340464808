import React from 'react'
import { I18n } from '@aws-amplify/core';
import { useSelector } from 'react-redux';
import { BigNumber } from 'bignumber.js';
import dayjs from 'dayjs';

import {
  decimalPrecision,
  timezone as tzSelector,
  dateFormat as dateFormatSelector,
} from '../../../selectors/company';
import { useFormat } from '../../../hooks/useFormat';

const ShiftDetail = ({ shift }) => {
  const { decimal, fmt } = useFormat();
  const timezone = useSelector(tzSelector);
  const dateFormat = useSelector(dateFormatSelector);

  if (!shift) return null;

  const start = shift.start;
  const end = shift.end;
  const base = new BigNumber(shift.startingAmount);
  const cash = new BigNumber(shift.cash);
  const debit = new BigNumber(shift.debit);
  const credit = new BigNumber(shift.credit);
  const transfer = new BigNumber(shift.transfer);
  const refunds = new BigNumber(shift.refunds);
  const inPayment = new BigNumber(shift.inPayment);
  const outPayment = new BigNumber(shift.outPayment);
  const totalsTipCash = new BigNumber(shift.tipsTotalCash);
  const totalsTipDebit = new BigNumber(shift.tipsTotalDebit);
  const totalsTipCredit = new BigNumber(shift.tipsTotalCredit);
  const totalsTipTransfer = new BigNumber(shift.tipsTotalTransfer);

  const totalsTipsOther = totalsTipDebit.plus(totalsTipCredit).plus(totalsTipTransfer);
  const total = new BigNumber(base)
    .plus(cash)
    .plus(debit)
    .plus(credit)
    .plus(transfer)
    .plus(inPayment)
    .plus(totalsTipCash)
    .plus(totalsTipsOther)
    .minus(refunds)
    .minus(outPayment)

  return (
    <div className="py-2 px-2 h-100 d-flex flex-column">

      <div className="shifts__detail-header mb-4 d-flex justify-content-between align-items-center">
        <h1 className="text-capitalize-first text-muted">
          {`${I18n.get('closeNo', 'Cierre No')} ${shift.idLocal}`}
        </h1>

      </div>

      <div className="d-flex flex-column m-0">
        <div className="d-flex align-items-center justify-content-between border-bottom my-2">
          <strong className="text-capitalize-first h3">{I18n.get('startDate', 'fecha de inicio')}</strong>
          <div className="h4">{!!start ? dayjs.tz(start, timezone).format(!!dateFormat ? `${dateFormat.toUpperCase()} h:mm a` : I18n.get('dateTimeFormat', 'DD/MM/YYYY h:mm a')) : ''}</div>
        </div>

        <div className="d-flex align-items-center justify-content-between border-bottom my-2">
          <strong className="text-capitalize-first h3">{I18n.get('endDate', 'fecha de cierre')}</strong>
          <div className="h4">{!!end ? dayjs.tz(end, timezone).format(!!dateFormat ? `${dateFormat.toUpperCase()} h:mm a` : I18n.get('dateTimeFormat', 'DD/MM/YYYY h:mm a')) : ''}</div>
        </div>
      </div>

      <div className="px-1 py-2 d-flex flex-column">

        <div className="d-flex align-items-center justify-content-between border-bottom my-2">
          <strong className="text-capitalize-first h4">{I18n.get('base', 'base inicial')}</strong>
          <div className="h4">{new BigNumber(base).toFormat(decimal, fmt)}</div>
        </div>

        <div className="d-flex align-items-center justify-content-between border-bottom my-2">
          <strong className="text-capitalize-first h4">{I18n.get('cashSales', 'ventas en efectivo')}</strong>
          <div className="h4">{cash.toFormat(decimal, fmt)}</div>
        </div>

        <div className="d-flex align-items-center justify-content-between border-bottom my-2">
          <strong className="text-capitalize-first h4">{I18n.get('debitSales', 'ventas por tarjeta de débito')}</strong>
          <div className="h4">{debit.toFormat(decimal, fmt)}</div>
        </div>

        <div className="d-flex align-items-center justify-content-between border-bottom my-2">
          <strong className="text-capitalize-first h4">{I18n.get('creditSales', 'ventas por tarjeta de crédito')}</strong>
          <div className="h4">{credit.toFormat(decimal, fmt)}</div>
        </div>

        <div className="d-flex align-items-center justify-content-between border-bottom my-2">
          <strong className="text-capitalize-first h4">{I18n.get('transferSales', 'ventas por transferencias')}</strong>
          <div className="h4">{transfer.toFormat(decimal, fmt)}</div>
        </div>

        <div className="d-flex align-items-center justify-content-between border-bottom my-2">
          <strong className="text-capitalize-first h4">{I18n.get('refundCash', 'Devolución de dinero')}</strong>
          <div className="h4 text-danger">{refunds.toFormat(decimal, fmt)}</div>
        </div>

        <div className="d-flex align-items-center justify-content-between border-bottom my-2">
          <strong className="text-capitalize-first h4">{I18n.get('invoiceCash', 'ingresos de efectivo')}</strong>
          <div className="h4">{inPayment.toFormat(decimal, fmt)}</div>
        </div>

        { ( (totalsTipCash.isGreaterThan(0) || totalsTipsOther.isGreaterThan(0)) ) &&
          <>
            <div className="d-flex align-items-center justify-content-between border-bottom my-2">
              <strong className="text-capitalize-first h4">{I18n.get('invoiceCash', 'ingresos de efectivo')}</strong>
              <div className="h4">{totalsTipCash.toFormat(decimal, fmt)}</div>
            </div>

            <div className="d-flex align-items-center justify-content-between border-bottom my-2">
              <strong className="text-capitalize-first h4">{I18n.get('invoiceCash', 'ingresos de efectivo')}</strong>
              <div className="h4">{totalsTipsOther.toFormat(decimal, fmt)}</div>
            </div>
          </>
        }

        <div className="d-flex align-items-center justify-content-between border-bottom my-2">
          <strong className="text-capitalize-first h4">{I18n.get('expenseCash', 'retiros de efectivo')}</strong>
          <div className="h4 text-danger">{outPayment.toFormat(decimal, fmt)}</div>
        </div>

        <div className="d-flex align-items-center justify-content-between">
          <strong className="text-capitalize-first h2">{I18n.get('total', 'total')}</strong>
          <div className="h3">{total.toFormat(decimal, fmt)}</div>
        </div>

      </div>

      <div className="d-flex align-items-center justify-content-between">
        <strong className="text-capitalize-first h4">{I18n.get('observations', 'observaciones')}:</strong>
      </div>
      <p className="h4">{shift.observations}</p>
    </div>
  )
}

export default ShiftDetail;
// eslint-disable-next-line
export default ({ id, className }) => (
  <svg
    width="40"
    height="40"
    id={id}
    className={className}
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M 17.652344 7.332031 C 16.164062 7.332031 14.960938 8.539062 14.960938 10.023438 L 14.960938 20.535156 L 25.039062 20.535156 L 25.039062 10.023438 C 25.039062 8.539062 23.835938 7.332031 22.347656 7.332031 Z M 17.652344 7.332031 "
      fill="#00D6BC" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M 11.136719 14.046875 C 10.394531 14.046875 9.789062 14.648438 9.789062 15.390625 L 9.789062 17.808594 L 8.691406 17.808594 C 7.207031 17.808594 6 19.015625 6 20.5 L 6 29.644531 C 6 31.128906 7.207031 32.332031 8.691406 32.332031 L 31.308594 32.332031 C 32.792969 32.332031 34 31.128906 34 29.644531 L 34 20.5 C 34 19.015625 32.792969 17.808594 31.308594 17.808594 L 30.210938 17.808594 L 30.210938 15.390625 C 30.210938 14.648438 29.605469 14.046875 28.863281 14.046875 C 28.121094 14.046875 27.515625 14.648438 27.515625 15.390625 L 27.515625 20.375 C 27.515625 21.859375 26.3125 23.0625 24.824219 23.0625 L 15.175781 23.0625 C 13.6875 23.0625 12.484375 21.859375 12.484375 20.375 L 12.484375 15.390625 C 12.484375 14.648438 11.878906 14.046875 11.136719 14.046875 Z M 16.078125 25.871094 C 15.152344 25.871094 14.398438 26.628906 14.398438 27.558594 C 14.398438 28.488281 15.152344 29.242188 16.078125 29.242188 L 23.921875 29.242188 C 24.847656 29.242188 25.601562 28.488281 25.601562 27.558594 C 25.601562 26.628906 24.847656 25.871094 23.921875 25.871094 Z M 16.078125 25.871094 "
      fill="#00D6BC" />
  </svg>
)

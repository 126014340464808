import { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { API, graphqlOperation } from '@aws-amplify/api';
import { get } from 'lodash';

import * as subscriptions from '../graphql/subscriptions';
import { idCompanySelector } from '../selectors/company';
import { updateCompany } from '../reducers/auth';

const RefundsSns = () => {
  const ref = useRef();
  const dispatch = useDispatch();
  const idCompany = useSelector(idCompanySelector);
  const networkStatus = useSelector(state => get(state, 'app.networkStatus', 'online'));
  const [onUpdateCompanyError, setOnUpdateCompanyError] = useState(false);

  useEffect(() => {
    let subscription = null;
    if (networkStatus === 'online') {
      subscription = API.graphql(graphqlOperation(subscriptions.onCreateCreditNoteNotify, {
        channel: `pos_${idCompany}`
      }))
        .subscribe({
          next: (data) => {
            const updateCompanyComsuption = async data => {
              const invoices = get(data, 'value.data.onCreateCreditNoteNotify.invoices');
              const income = get(data, 'value.data.onCreateCreditNoteNotify.income');

              dispatch(updateCompany({
                monthIncome: {
                  invoices,
                  income
                }
              }));
            }

            updateCompanyComsuption(data);
          },
          error: (error) => setTimeout(() => {
            if (!!ref.current)
              setOnUpdateCompanyError(!onUpdateCompanyError)
          }, 60000)
        })
    }
    return () => {
      if (subscription)
        subscription.unsubscribe();
    }
  }, [networkStatus, onUpdateCompanyError, idCompany, dispatch]);

  useEffect(() => {
    ref.current = true
    return () => ref.current = false
  }, []);

  return null;
};

export default RefundsSns;


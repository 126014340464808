import { I18n } from '@aws-amplify/core';
import { get, capitalize, isNull, isUndefined } from 'lodash';

export const validate = values => {
  let errors = {}
  
  const name = get(values, 'name', '');
  const type = get(values, 'type', '');
  const percentage = get(values, 'percentage', '');
  
  
  if (!type)
  errors.type = capitalize(I18n.get('choosePriceListType', 'Elige un tipo de lista de precio'))
  
  if (!name)
    errors.name = capitalize(I18n.get('enterAName', 'Ingresa un nombre'));
  
  if (type === 'percentage') {
    if (!percentage)
    errors.percentage = capitalize(I18n.get('thisFieldIsRequired', 'Este campo es obligatorio'));
  }

  return errors;
}

export const transform = values => {
  let newValues = {
    name: get(values, 'name', null),
    type: get(values, 'type', 'percentage'),
    percentage: get(values, 'percentage', null),
  }

  Object.keys(newValues).map(key => {
    if (isNull(newValues[key]) || isUndefined(newValues[key]))
      delete newValues[key];
    return null;
  })

  return newValues;
}
import { COUNTRIES } from '../../utils/enums/countries';
import { ArgentinaInvoiceStrategy } from './ArgentinaInvoiceStrategy';
import { BaseInvoiceStrategy } from './BaseInvoiceStrategy';
import { ColombiaInvoiceStrategy } from './ColombiaInvoiceStrategy';
import { CostaRicaInvoiceStrategy } from './CostaRicaInvoiceStrategy';
import { InternationalInvoiceStrategy } from './InternationalInvoiceStrategy';
import { MexicoInvoiceStrategy } from './MexicoInvoiceStrategy';
import { PanamaInvoiceStrategy } from './PanamaInvoiceStrategy';
import { PeruInvoiceStrategy } from './PeruInvoiceStrategy';
import { RepublicaDominicanaInvoiceStrategy } from './RepublicaDominicanaInvoiceStrategy';
import { SpainInvoiceStrategy } from './SpainInvoiceStrategy';
import { UsaInvoiceStrategy } from './UsaInvoiceStrategy';

const invoiceStrategyRegistry = {
  [COUNTRIES.MEXICO]: new MexicoInvoiceStrategy(),
  [COUNTRIES.COLOMBIA]: new ColombiaInvoiceStrategy(),
  [COUNTRIES.SPAIN]: new SpainInvoiceStrategy(),
  [COUNTRIES.INTERNATIONAL]: new InternationalInvoiceStrategy(),
  [COUNTRIES.USA]: new UsaInvoiceStrategy(),
  [COUNTRIES.ARGENTINA]: new ArgentinaInvoiceStrategy(),
  [COUNTRIES.COSTA_RICA]: new CostaRicaInvoiceStrategy(),
  [COUNTRIES.PANAMA]: new PanamaInvoiceStrategy(),
  [COUNTRIES.REPUBLICA_DOMINICANA]: new RepublicaDominicanaInvoiceStrategy(),
  [COUNTRIES.PERU]: new PeruInvoiceStrategy(),
};
export class InvoiceCountryStrategyFactory {
  /**
   * Creates an invoice strategy for a given country.
   *
   * @param {string} country
   * @return {InvoiceStrategy}
   */
  static createStrategy(country) {
    return invoiceStrategyRegistry[country];
  }
}

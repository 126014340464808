import React from 'react'
import { I18n } from '@aws-amplify/core';
import { useHistory } from 'react-router-dom';
import { capitalize } from 'lodash'

const NoSelectedElement = () => {
  const history = useHistory();

  return (
    <div className="w-100 h-100 d-flex justify-content-center align-items-center">
      <div className="p-4 text-center">
        <p className="text-capitalize-first">
          {I18n.get('noSelectedInvoice', 'selecciona ventas de la lista para ver los detalles. O crea una nueva')}{' '}
          <a className="text-capitalize-first btn-link" href="https://ayuda.alegra.com/facturaci%C3%B3n-%C3%A1gil-en-el-punto-de-venta-pos" target="_blank" rel="noreferrer">
            {capitalize(I18n.get('seeMore', 'ver más'))}
          </a>
        </p>

        <button
          type="button"
          className="btn btn-submit"
          onClick={() => history.push('/')}
        >
          {I18n.get('newSale', 'nueva venta')}
        </button>
      </div>
    </div>
  )
}

export default NoSelectedElement;
import React from 'react';
import { I18n } from '@aws-amplify/core';

import Company from '../../forms/company/Company';

const FormBody = props => (
  <>
    <div className="settings-body d-flex flex-column bg-white">
      <p className="settings-body-title text-capitalize-first">
        {I18n.get('item.generalInfo', 'información general')}
      </p>
      <p className="settings-body-subtitle text-muted text-capitalize-first" style={{ marginBottom: '24px' }}>
        {I18n.get('item.generalInfo.subtitle', 'Configura la información principal y los datos de contacto de tu negocio.')}
      </p>
      <div className="border-top" style={{ margin: '0 -40px' }} />
      <div className="company-form-body w-100 py-3 overflow-hidden">
        <Company.NewBasicData {...props} />
      </div>
    </div>

    <div className="settings-body d-flex flex-column bg-white mt-5" style={{ marginBottom: '160px !important' }}>
      <p className="settings-body-title text-capitalize-first">
        {I18n.get('basicInformation', 'información basica')}
      </p>
      <p className="settings-body-subtitle text-muted text-capitalize-first" style={{ marginBottom: '24px' }}>
        {I18n.get('basicInformation.subtitle', 'Configura algunos datos necesarios para la generación de tus documentos y transacciones.')}
      </p>
      <div className="border-top" style={{ margin: '0 -40px' }} />
      <div className="company-form-body w-100 py-3 px-2 overflow-hidden">
        <Company.GeneralInfo {...props} />
      </div>
    </div>
  </>
)

export default FormBody;

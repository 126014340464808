import { useState, useEffect, useRef, useCallback, useMemo } from "react";
import { Field, useFormState } from "react-final-form";
import { renderField, renderSelect } from "../../fields/V0/Fields";
import { I18n } from "aws-amplify"
import { graphqlOperation } from "aws-amplify"
import { APIGraphqlSelector, stationCostCenter } from '../../../../selectors/app';
import { useSelector } from "react-redux";
import { activeBanks } from "../../../../selectors/banks";
import BigNumber from "bignumber.js"
import * as queries from "../../../../graphql/queries"
import { get } from "lodash";
import { useFormat } from "../../../../hooks/useFormat";

export const Combined = () => {
  const { values } = useFormState();
  const [loading, setLoading] = useState(false);
  const [documents, setDocuments] = useState([]);
  const costCenter = useSelector(stationCostCenter) || {};
  const { decimal, fmt } = useFormat();
  const banks = useSelector(activeBanks);
  
  const APIGraphql = useSelector(APIGraphqlSelector);
  const ref = useRef();

  useEffect(() => {
    ref.current = true;
    search();
    return () => ref.current = false;
  }, []);

  const search = useCallback(async () => {    
    if (ref.current)
    setLoading(true);
  
    try {
      const response = await APIGraphql(graphqlOperation(queries.getClientInvoicesById, {
        clientId: values.refund.client.id
      }));

      const dataDocuments = get(response, 'data.getClientInvoicesById.data', [])
        .filter(d => {
          const invoiceCostCenter = d.costCenter || {};
          return (!invoiceCostCenter || invoiceCostCenter.id === costCenter.id);
        });

      setDocuments(dataDocuments);
    } catch (error) {
      console.log('error', error)
      return [];
    } finally {
      if (ref.current)
        setLoading(false);
    }
  }, [APIGraphql]);

  const outstandingInvoiceAmount = useMemo(() => {
    if (!values?.refund?.document?.balance) return 0;
    return new BigNumber(get(values, 'refund.document.balance')).toFormat(decimal, fmt);
  },[values?.refund?.document?.balance])

  return (
    <>
      <div className="w-100 flex-column d-none d-md-flex">
        <div className="w-100 d-flex justify-content-around mb-4 refund-combined-subtitle">
          <div>{I18n.get('refundCash', 'Devolución de dinero')}</div>
          <div>{I18n.get('creditToInvoice', 'Crédito a factura')}</div>
        </div>
        <div className="w-100 row mx-0">
          <Field 
            name="refund.amount"
            type="text"
            component={renderField}
            className="col-6 p-0 pr-2 select-refund new-refund-input mb-4"
            label={I18n.get('refundAmount', 'Monto a devolver')}
            placeholder={I18n.get('enterAmount', 'Ingresar monto')}
            required
          />

          <Field
            type="select"
            name="refund.document"
            className="col-6 p-0 pl-2 new-refund-input select-refund mb-4"
            placeholder={I18n.get('select', 'Seleccionar')}
            options={documents}
            isLoading={loading}
            component={renderSelect}
            getOptionValue={(option) => option.numberTemplate.fullNumber}
            getOptionLabel={option => {
              const balance = new BigNumber(option.balance);
              const fullNumber = option.numberTemplate.fullNumber;

              return (
                <div className="d-flex justify-content-between">
                  <span className='document-fullNumber' style={{ display: 'none', color: "#0F172A"}}>
                    {fullNumber}
                  </span>
                  <span className='refund-document-label mb-0 mr-2' style={{ fontWeight: 600, color: "#0F172A"}}>
                    {fullNumber}
                  </span>
                  <span className='refund-document-balance-label text-wrap mb-0' style={{ fontSize: 12, color: "#64748B" }}>
                    {balance.toFormat(decimal, fmt)}
                  </span>
                </div>
              )
            }}
            required
            label={I18n.get('associatedDocument', 'Documento asociado')}
          />

          <Field
            name="refund.bank"
            type="select"
            options={banks}
            component={renderSelect}
            getOptionValue={(option) => option.id}
            getOptionLabel={(option) => option.name}
            className="col-6 p-0 pr-2 select-refund new-refund-input"
            label={I18n.get('bankAccount', 'Cuenta bancaria')}
            required
          />

          <Field 
            name="invoice.amount"
            type="text"
            component={renderField}
            className="col-6 p-0 pl-2 select-refund new-refund-input"
            label={I18n.get('refundAmount', 'Monto a devolver')}
            placeholder={I18n.get('enterAmount', 'Ingresar monto')}
            required
          />

        </div>
        <Field 
          name="refund.observations"
          type="text"
          component={renderField}
          className="col-12 p-0 select-refund new-refund-input"
          label={I18n.get('observations', 'Observaciones')}
        />

        <div className="d-flex justify-content-end">
          <div className="col-8 col-md-6 p-0">
            <div className="refund-document-info">
              <div>
                {I18n.get('outstandingInvoiceAmount', 'Pendiente por cobrar')}:
              </div>
              <div>
                {outstandingInvoiceAmount}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="w-100 d-flex flex-column d-md-none">
        <div className="w-100 row mx-0">
          <div className="mb-2 refund-combined-subtitle-sm">{I18n.get('refundCash', 'Devolución de dinero')}</div>
          <div className="refund-combined-subtitle-sm">{I18n.get('creditToInvoice', 'crédito a factura')}</div>

          <Field 
            name="invoice.amount"
            type="text"
            component={renderField}
            className="col-12 p-0 select-refund new-refund-input"
            label={I18n.get('refundAmount', 'Monto a devolver')}
            placeholder={I18n.get('enterAmount', 'Ingresar monto')}
            required
          />

          <Field
            type="select"
            name="refund.document"
            className="col-12 p-0 new-refund-input select-refund mb-4"
            placeholder={I18n.get('select', 'Seleccionar')}
            options={documents}
            isLoading={loading}
            component={renderSelect}
            getOptionValue={(option) => option.numberTemplate.fullNumber}
            getOptionLabel={option => {
              const balance = new BigNumber(option.balance);
              const fullNumber = option.numberTemplate.fullNumber;

              return (
                <div className="d-flex justify-content-between">
                  <span className='document-fullNumber' style={{ display: 'none', color: "#0F172A"}}>
                    {fullNumber}
                  </span>
                  <span className='refund-document-label mb-0 mr-2' style={{ fontWeight: 600, color: "#0F172A"}}>
                    {fullNumber}
                  </span>
                  <span className='refund-document-balance-label text-wrap mb-0' style={{ fontSize: 12, color: "#64748B" }}>
                    {balance.toFormat(decimal, fmt)}
                  </span>
                </div>
              )
            }}
            required
            label={I18n.get('associatedDocument', 'Documento asociado')}
          />

          <Field
            name="refund.bank"
            type="select"
            options={banks}
            component={renderSelect}
            getOptionValue={(option) => option.id}
            getOptionLabel={(option) => option.name}
            className="col-12 p-0 select-refund new-refund-input"
            label={I18n.get('bankAccount', 'Cuenta bancaria')}
            required
          />

          <Field 
            name="refund.amount"
            type="text"
            component={renderField}
            className="col-12 p-0 select-refund new-refund-input mb-4"
            label={I18n.get('refundAmount', 'Monto a devolver')}
            placeholder={I18n.get('enterAmount', 'Ingresar monto')}
            required
          />
        </div>

        <Field 
          name="refund.observations"
          type="text"
          component={renderField}
          className="col-12 p-0 select-refund new-refund-input"
          label={I18n.get('observations', 'Observaciones')}
        />

        <div className="d-flex">
          <div className="col-12 col-md-6 p-0">
            <div className="refund-document-info">
              <div>
                {I18n.get('outstandingInvoiceAmount', 'Pendiente por cobrar')}:
              </div>
              <div className="ml-1">
                {outstandingInvoiceAmount}
              </div>
            </div>
          </div>
        </div>
        
      </div>
    </>
  )
}
import { I18n } from '@aws-amplify/core';
import { Field } from 'react-final-form';

import Typography from '../../../../components/common/Typography';
import { renderMultiDatePickerField } from '../../../../components/forms/fields/V0/Fields';

function GlobalInvoiceTableHeader({ onChangeDate }) {
  return (
    <div className='global-invoices-header'>
      <div>
        <Typography
          type='body-1-bold'
          variant='secondary'
          text={I18n.get('', 'Tickets de venta')}
        />
        <Typography
          type='body-3-regular'
          variant='secondary'
          text={I18n.get(
            '',
            'Selecciona los tickets de venta que vas a timbrar en esta factura global.'
          )}
        />
      </div>
      <Field
        name='creationDate'
        required
        onChange={(value) => onChangeDate(value)}
        placeholder={I18n.get('', 'Rango de fechas')}
        component={renderMultiDatePickerField}
        numberOfMonths={2}
        fastOptions
        inputFormat='DD/MM/YYYY'
        className='mb-0 global-invoices-header-filter'
      />
    </div>
  );
}

export default GlobalInvoiceTableHeader;

import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { fetchPayments } from '../../reducers/payments'
import { hasPermissionTo } from '../../selectors/auth'
import { payments, loadingSelector, errorSelector, totalSelector } from '../../selectors/payments'
import MasterDetail from '../common/DetailView';
import Payment from './Payment';
import DetailHeader from './DetailHeader';
import DetailBody from './DetailBody';
import EmptyDetail from './EmptyDetail';
import ErrorElement from './ErrorElement';
import NoSelectedElement from './NoSelectedElement';
import PaginationHeader from './PaginationHeader';
import NotAllowedToIndex from './NotAllowedToIndex'
import NotAllowedToView from './NotAllowedToView'
import PageNetworkMessage from '../network/PageNetworkMessage';
import { useConnectionStatus } from '../../hooks/useConnectionStatus';

const Payments = () => {
  const connectionStatus = useConnectionStatus();
  const dispatch = useDispatch()
  const can = useSelector(hasPermissionTo)
  const ref = useRef(null)
  const [selected, setSelected] = useState(null);
  const [metadata, setMetadata] = useState({
    start: 0,
    limit: 20,
    sortDirection: 'desc'
  })

  const elements = useSelector(payments);
  const loading = useSelector(loadingSelector);
  const error = useSelector(errorSelector)
  const total = useSelector(totalSelector)
  const selectedPayment = selected === -1 ? null : elements[selected]

  useEffect(() => {
    ref.current = true;
    window.dataLayer.push({
      'event': 'VirtualPageview',
      'virtualPageURL': '/payments',
      'virtualPageTitle' : 'Payments'
    });

    return () => ref.current = false
  }, []);

  const fetchData = useCallback(async batch => {
    await dispatch(fetchPayments(batch))

    if (ref.current)
      setMetadata(batch)
  }, [dispatch])

  useEffect(() => {
    fetchData({
      start: 0,
      limit: 20,
      sortDirection: 'desc'
    });
  }, [fetchData]);

  if(!connectionStatus) {
    return <PageNetworkMessage />
  }

  return (
    <div className="payments d-flex">
      <MasterDetail
        loading={loading}
        error={error}
        selected={!!selectedPayment}
        options={elements.map((option, index) => (
          <Payment
            key={option.id}
            payment={option}
            idSelected={!!selectedPayment ? selectedPayment.id : null}
            onSelect={() => setSelected(index)}
          />
        ))}
        onFetchData={fetchData}
        paginationData={{ ...metadata, total }}
        paginationHeader={<PaginationHeader />}
        detailHeader={<DetailHeader payment={selectedPayment} onListView={() => setSelected(-1)} />}
        detailChildren={<DetailBody payment={selectedPayment} />}
        detailClassName={!!selectedPayment ? 'item-selected' : ''}
        emptyElement={<EmptyDetail />}
        errorElement={<ErrorElement onRefresh={() => fetchData(metadata)} />}
        noSelectedElement={<NoSelectedElement />}
        notAllowedToIndex={!can('retrieve-in', 'payments') && !can('index', 'payment')
          ? <NotAllowedToIndex /> : null}
        notAllowedToView={(!can('retrieve-in', 'payments') && !can('index', 'payment'))
          || (!can('viewin', 'payments') && !can('viewout', 'payments'))
          ? <NotAllowedToView /> : null}
      />
    </div>
  )
}

export default Payments;
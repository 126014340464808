import { I18n } from '@aws-amplify/core';
import { useQuery } from '@tanstack/react-query';
import toastHandler from '../../../../utils/toast-handler';
import * as queries from '../../../../graphql/queries';
import { graphqlOperation } from '@aws-amplify/api';
import { handleError } from '../../../../utils/errors';
import { get } from 'lodash';

export const globalInvoiceKeys = {
  all: 'globalInvoices',
};

const fetchGlobalInvoices = async (metadata, api) => {
  const params = {
    batch: {
      start: metadata?.pagination?.start || 0,
      limit: metadata?.pagination?.limit || 10,
      sortDirection: metadata?.pagination?.order_direction || 'DESC',
    },
    filter: {
      status: metadata?.filters?.status,
    },
    includeMetadata: true,
  };

  const data = await api(
    graphqlOperation(queries.allInvoicesForGlobal, params)
  );

  return get(data, 'data.allGlobalInvoices', {});
};

export const useGlobalInvoicesQuery = (metadata, api) => {
  return useQuery({
    queryKey: [globalInvoiceKeys.all, metadata, api],
    queryFn: () => fetchGlobalInvoices(metadata, api),
    onError: (error) => {
      toastHandler({
        title: I18n.get(
          'globalInvoicesLoadError',
          'Sucedió un error cargando las facturas'
        ),
        description: handleError(error),
        type: 'error',
      });
    },
    keepPreviousData: true,
    cacheTime: 0,
    refetchOnWindowFocus: false,
    refetchOnReconnect: true,
    enabled: !!metadata,
  });
};

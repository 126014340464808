import { COUNTRIES } from "../../../utils/enums/countries";

export const showCreditLimitField = (country) => {
  return [
    COUNTRIES.COLOMBIA,
    COUNTRIES.PANAMA,
    COUNTRIES.REPUBLICA_DOMINICANA,
    COUNTRIES.COSTA_RICA,
  ].includes(country);
};

import { Space } from '@alegradev/smile-ui-react';
import GlobalInvoicesHeader from './header';
import GlobalInvoicesActivateNotification from './notification';
import GlobalInvoicesTable from './table';

function GlobalInvoices() {
  return (
    <>
      <GlobalInvoicesActivateNotification />
      <GlobalInvoicesHeader />
      <Space height={12} />
      <GlobalInvoicesTable />
    </>
  );
}

export default GlobalInvoices;

const identificationTypes = [
  {
    id: "1",
    value: "Contribuyente",
    abbreviature: "TAXPAYER",
    key: "TAXPAYER"
  },
  {
    id: "2",
    value: "Consumidor Final",
    abbreviature: "FINAL_CONSUMER",
    key: "FINAL_CONSUMER"
  },
  {
    id: "3",
    value: "Extranjero",
    abbreviature: "FOREIGN",
    key: "FOREIGN"
  },
  {
    id: "4",
    value: "Empresa Extranjera",
    abbreviature: "FOREIGN_COMPANY",
    key: "FOREIGN_COMPANY"
  }
]

export default identificationTypes
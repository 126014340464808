import React from 'react';
import { useSelector } from 'react-redux'
import { Field } from 'react-final-form';
import { I18n } from '@aws-amplify/core';
import { graphqlOperation } from '@aws-amplify/api';
import { capitalize, get } from 'lodash';

import * as queries from '../../../../graphql/queries'
import { decimalPrecision, country as countrySelector } from '../../../../selectors/company';
import { APIGraphqlSelector, stationCostCenter } from '../../../../selectors/app';
import { getNumber } from '../../../../utils/invoices'
import { renderField, renderSelect } from '../../fields/V0/Fields';
import { refundMaxDecimals } from './Cash';

const Invoice = ({ values, client }) => {
  const country = useSelector(countrySelector)
  const costCenter = useSelector(stationCostCenter) || {};
  const decimal = useSelector(decimalPrecision)
  const APIGraphql = useSelector(APIGraphqlSelector);

  return (
    <div className="form-fields form-row justify-content-start w-100">
      <Field
        name="invoice.invoice"
        component={renderSelect}
        className="col-md-6"
        loadOptions={async () => {
          try {
            const response = await APIGraphql(graphqlOperation(queries.getClientInvoices, {
              clientName: client.name,
            }))
            const invoices = get(response, 'data.getClientInvoices.data', []);
            
            return invoices.filter(invoice => {
              const invoiceCostCenter = invoice.costCenter || {};
              return !invoiceCostCenter || invoiceCostCenter.id === costCenter.id;
            });
          } catch {
            return []
          }
        }}
        defaultOptions
        getOptionLabel={option => getNumber(option, country)}
        getOptionValue={option => option.id}
        required
        label={capitalize(I18n.get('clientInvoice', 'factura del cliente'))}
        menuPosition="absolute"
      />

      <Field
        name="invoice.amount"
        component={renderField}
        type="number"
        className="col-md-6"
        required
        label={capitalize(I18n.get('amount', 'monto'))}
        onChange={e => refundMaxDecimals(e, `refund.amount`, decimal, values, country)}
      />
      
      <Field
        name="invoice.paid"
        type="number"
        className="col-md-6"
        disabled
        label={capitalize(I18n.get('paid', 'pagado'))}
        render={props => 
          renderField({ ...props, input: {
            ...props.input, value: get(values, 'invoice.invoice.totalPaid', '')
          } 
        })}
      />
      
      <Field
        name="invoice.pending"
        type="number"
        className="col-md-6"
        disabled
        label={capitalize(I18n.get('pending', 'pendiente'))}
        render={props => 
          renderField({ ...props, input: {
            ...props.input, value: get(values, 'invoice.invoice.balance', '')
          } 
        })}
      />
      
    </div>
  )
}

export default Invoice;

import { get } from 'lodash';

export const paymentMethodConverterToApi = (values) => {
  return {
    name: get(values, 'name', '').trim(),
    instructions: get(values, 'instructions', ''),
    logo_image: {
      is_private: false,
      name: get(values, 'name', ''),
      file: get(values, 'imageSrc', ''),
    },
    qr_image: {
      is_private: false,
      name: get(values, 'name', ''),
      file: get(values, 'imageQR', ''),
    },
  };
};

export const togglePaymentMethodConverterToApi = (values) => {
  return {
    idLocal: get(values, 'id', ''),
    is_active: get(values, 'status', false),
  };
};

const getPaymentMethodName = (name) => {
  switch (name) {
    case 'Efectivo':
      return 'cash';
    case 'Tarjeta débito':
      return 'debit';
    case 'Tarjeta crédito':
      return 'credit';
    case 'Transferencia':
      return 'transfer';
    case 'Combinado':
      return 'combined';
    default:
      return name;
  }
};
export const paymentMethodsConverterFromApi = (values) => {
  return values.map((paymentMethod) => {
    return {
      ...paymentMethod,
      id: get(paymentMethod, 'idLocal', ''),
      name: getPaymentMethodName(get(paymentMethod, 'name', '')),
    };
  });
};

import { Typography, useDeviceType } from '@alegradev/smile-ui-react';
import { I18n } from 'aws-amplify';
import { capitalize, get } from 'lodash';
import { Field, useFormState } from 'react-final-form';
import { useSelector } from 'react-redux';
import { hasPermissionTo } from '../../../../selectors/auth';
import { activeBanks } from '../../../../selectors/banks';
import { renderSelect } from '../../fields/V0/Fields';

export const BankField = ({
  name,
  isCombined = false,
  paymentOption = {},
  index = 0,
  isLastRow = false,
}) => {
  const can = useSelector(hasPermissionTo);
  const banks = useSelector(activeBanks);
  const { deviceType } = useDeviceType();
  const { values } = useFormState();

  const isDisabled = isCombined && get(paymentOption, 'name', '') === 'cash';
  const placeholder =
    isCombined && !get(values, 'cashBank', '') ? 'Efectivo POS - Terminal' : '';

  return (
    <Field
      name={name}
      className={`col-sm-${isLastRow && index > 1 && isCombined ? '10' : '12 p-0'}`}
      label={
        !isCombined ? (
          <Typography
            type='label-2'
            text={capitalize(I18n.get('bank', 'banco'))}
            variant='secondary'
          />
        ) : null
      }
      options={
        !can('index', 'bank-accounts') || !can('view', 'bank-accounts')
          ? []
          : banks
      }
      selectInfo={
        !can('index', 'bank-accounts') || !can('view', 'bank-accounts')
          ? I18n.get(
              'userNotAllowed.bank-accounts.view',
              'no tienes permisos para ver la información de las cuentas de bancos'
            )
          : null
      }
      hideNoOptionMessage={
        !can('index', 'bank-accounts') || !can('view', 'bank-accounts')
      }
      required
      getOptionLabel={(option) => option.name}
      getOptionValue={(option) => option.id}
      menuPosition='absolute'
      disabled={isDisabled}
      placeholder={placeholder}
    >
      {(props) => (
        <>
          {renderSelect({
            ...props,
            fontSize: deviceType === 'mobile' ? '14px' : '16px',
            borderRadius: '12px',
            height: '48px',
            borderColor: '#CBD5E1',
            fontColor: '#334155',
          })}
        </>
      )}
    </Field>
  );
};

export default BankField;

import React from 'react';

import Cash from './Cash';
import Invoice from './Invoice';
import Combined from './Combined';

const BasicData = ({ values, total, client }) => {
  if (!values) return null;

  return (
    <div className="form-body h-100 p-4 overflow-hidden">
      <div className="form-body__fields form-row justify-content-start">
        {values.method === 'cash' && (
          <Cash values={values} total={total}/>
        )}
        
        {values.method === 'combined' && (
          <Combined values={values} client={client}/>
        )}
        
        {values.method === 'invoice' && (
          <Invoice values={values} client={client}/>
        )}
        
      </div>
    </div>
  )
}

export default BasicData;
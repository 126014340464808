import React, { useEffect, useMemo, useState } from 'react';
import { Field } from 'react-final-form';
import { I18n } from '@aws-amplify/core';
import { capitalize, replace, get, forEach } from 'lodash';
import { renderField } from '../../fields/V0/Fields';
import Typography from '../../../common/Typography';
import { useDeviceType } from '@alegradev/smile-ui-react';
import BankField from '../components/BankField';
import SellerField from '../components/SellerField';
import AnnotationField from '../components/AnnotationField';
import UseField from '../components/UseField';

const NewCard = ({ form, values, total, anotationMaxLimit }) => {
  const deviceType = useDeviceType();

  if (!form || !values) return null;
  const { method } = values;

  return (
    <div className='form-row col-sm-12 pl-2 pr-0'>
      <div className='col-sm-12 col-md-6'>
        <Field
          name={values.method}
          className='col-md-12 p-0'
          defaultValue={total}
          type='number'
          label={
            <Typography
              type='label-2'
              text={capitalize(
                replace(
                  I18n.get('valueOfCardPayment', 'valor del pago en tarjeta'),
                  '{}',
                  method === 'credit'
                    ? I18n.get('creditCard', 'tarjeta de crédito')
                    : I18n.get('debitCardSaleModal', 'tarjeta débito')
                )
              )}
              variant='secondary'
            />
          }
          required
        >
          {(props) => (
            <>
              {renderField({
                ...props,
                fontSize: '16px',
                height: '46px',
                fontColor: '#334155',
                paddingLeft: '10px',
              })}
            </>
          )}
        </Field>

        <BankField name={`${values.method}Bank`} />
      </div>
      <div
        className={`col-sm-12 col-md-6  ${deviceType === 'mobile' ? 'mobile' : 'px-0 form__invoice-advanced-data'}`}
      >
        <UseField values={values} />
        <div className='d-flex justify-content-center'>
          <SellerField />
        </div>
        <div
          className={`col-sm-12 ${deviceType === 'mobile' ? 'p-0' : 'pl-2 pr-0'}`}
        >
          <AnnotationField
            anotationMaxLimit={anotationMaxLimit}
            type='textarea'
          />
        </div>
      </div>
    </div>
  );
};

export default NewCard;

import Cookie from 'js-cookie';
import http from '../utils/http';

const baseUrl = process.env.REACT_APP_POS_CACHE_ENDPOINT;
const baseConfig = {
  headers: {
    token: `Basic ${Cookie.get(process.env.REACT_APP_ALEGRA_COOKIE)}`,
  }
};

const get = async (url, params = null, userConfig = null) => await http.get(`${baseUrl}${url}`, params, Object.assign({}, { headers: {...baseConfig.headers, ...userConfig} }), Cookie.get("pos-block-api-request"));

const defaultExport = {
  get,
}

export default defaultExport
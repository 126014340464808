import { Grid, Space, Typography } from '@alegradev/smile-ui-react';
import { I18n } from 'aws-amplify';
import { formatMoney } from '../../../utils';
import { get } from 'lodash';

const PrintPaymentResume = ({ payment }) => {
  const { Wrapper } = Grid;
  return (
    <Wrapper direction={{ lg: 'column' }}>
      {!!get(payment, 'client', null) && (
        <Typography
          tag='h3'
          type='body-2-bold'
          style={{ textAlign: 'center' }}
          text={get(payment, 'client.name', '')}
        />
      )}

      <Wrapper justify={{ lg: 'between' }}>
        {payment?.categories?.map((category) => (
          <Typography
            text={`<b>${I18n.get('concept', 'Concepto')}:</b> ${get(category, 'name', '')}`}
            withHtml
          />
        ))}
        <Typography text={formatMoney(get(payment, 'amount', 0))} />
      </Wrapper>

      <Space height={10} />
      <div className='border-bottom' />
      <Space height={10} />

      <Wrapper align={{ lg: 'end' }} direction={{ lg: 'column' }}>
        <Typography
          text={`<b>${I18n.get('subtotal', 'Subtotal')}: ${formatMoney(get(payment, 'amount', 0))}</b>`}
          withHtml
        />
        <Typography
          text={`<b>${I18n.get('total', 'Total')}: ${formatMoney(get(payment, 'amount', 0))}</b>`}
          withHtml
        />
      </Wrapper>

      <Space height={10} />
    </Wrapper>
  );
};

export default PrintPaymentResume;

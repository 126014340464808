import { has, get, isBoolean } from 'lodash'

import WithTotal from './PaginationWithTotal'
import WithoutTotal from './PaginationWithoutTotal'

const Pagination = (props) => has(props, 'paginationData.hasNextPage')
  && isBoolean(get(props, 'paginationData.hasNextPage'))
  ? WithoutTotal(props)
  : WithTotal(props)

export default Pagination;
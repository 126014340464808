// eslint-disable-next-line
export default ({ id, className }) => (
  <svg 
    id={id}
    className={className}
    width="120" 
    height="48" 
    viewBox="0 0 120 48" 
    fill="none" 
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M109.108 32.9786L107.274 30.7833H110.112L111.852 32.9786L110.112 35.0954H107.399L109.108 32.9786Z" fill="#2FA8DD"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M85.102 35.3306L92.5344 16.5772H97.0189L104.577 35.3306H99.7472L98.2733 31.0656H91.3741L89.7747 35.3306H85.102ZM92.6912 27.4278L94.7609 21.4381L97.0189 27.4278H92.6912Z" fill="#126F98"/>
    <path d="M72.3071 26.5497C72.5329 31.6426 77.3352 32.2468 79.7081 31.9123V27.0745H83.9417V34.8204C80.5382 35.7425 78.7695 35.6195 78.5236 35.6195C68.3494 35.6195 67.7913 28.1196 67.7913 25.7657C68.3558 16.5772 75.4118 16.295 78.0147 16.1068C80.097 15.9563 82.8337 16.5877 83.9417 16.9222V20.6227C83.1264 20.0582 81.872 19.9955 79.0496 19.8073C76.2272 19.6192 72.0249 20.1837 72.3071 26.5497Z" fill="#126F98"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M47.7836 35.3933L55.2159 16.64H59.7004L67.2582 35.3933H62.4287L60.9548 31.1283H54.0556L52.4562 35.3933H47.7836ZM55.3727 27.4905L57.4425 21.5008L59.7004 27.4905H55.3727Z" fill="#126F98"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M34.0165 16.7027H41.9506C43.9786 16.7654 48.0533 18.07 48.1285 22.7865C48.2226 28.6822 42.2956 29.0272 38.156 28.9331V35.3619H34.0165V16.7027ZM38.156 20.2464V25.4678C38.3073 25.4678 38.458 25.4682 38.6077 25.4687C41.3436 25.4763 43.7563 25.4831 43.8009 22.8963C43.8385 20.7136 42.144 20.2202 41.2921 20.2464H38.156Z" fill="#126F98"/>
    <path d="M18.5247 16.734V35.3462H23.8245V16.734H30.7864L32.3858 14.5075L30.6296 12.375H18.5247L20.1554 14.5075L18.5247 16.734Z" fill="#126F98"/>
    <path d="M15.7336 14.5702L14.0715 16.6713H11.5V12.375H13.9147L15.7336 14.5702Z" fill="#126F98"/>
    <path d="M15.859 14.5702L14.0245 12.375H16.8626L18.6031 14.5702L16.8626 16.687H14.1499L15.859 14.5702Z" fill="#2FA8DD"/>
  </svg>
)

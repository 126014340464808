const newCreditNoteTypes = [
  {
    id: 1,
    value: "Devolución parcial de los bienes y/o no aceptación parcial del servicio",
    key: "PARTIALL_DEVOLUTION"
  },
  {
    id: 2,
    value: "Anulación de factura electrónica",
    key: "VOID_ELECTRONIC_INVOICE"
  },
  {
    id: 3,
    value: "Rebaja o descuento parcial o total",
    key: "REDUCTION_DISCOUNT_PARTIAL_TOTAL"
  },
  {
    id: 4,
    value: "Ajuste de precio",
    key: "PRICE_ADJUSTMENT"
  },
  {
    id: 5,
    value: "Descuento comercial por pronto pago",
    key: "COMMERCIAL_DISCOUNT_FOR_EARLY_PAYMENT"
  },
  {
    id: 6,
    value: "Descuento comercial por volumen de ventas",
    key: "COMMERCIAL_DISCOUNT_FOR_SALES_VOLUME"
  }
]


export default newCreditNoteTypes;

function AlegraManagement({ className, id }) {
  return (
    <svg
      id={id}
      className={className}
      width='206'
      height='56'
      viewBox='0 0 891 240'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M63.619 32C53.8883 32 46 39.7131 46 49.2277V188.772C46 198.287 53.8883 206 63.6191 206H176.381C186.112 206 194 198.287 194 188.772V75.0693C194 65.5547 186.112 57.8416 176.381 57.8416H165.81V49.2277C165.81 39.7131 157.921 32 148.19 32H63.619ZM176.381 192.218H67.1429C63.2506 192.218 60.0952 189.133 60.0952 185.327C60.0952 181.521 63.2506 178.436 67.1429 178.436H148.19C157.921 178.436 165.81 170.723 165.81 161.208V71.6238H176.381C178.327 71.6238 179.905 73.1664 179.905 75.0693V188.772C179.905 190.675 178.327 192.218 176.381 192.218ZM84.7619 90.5743C76.9773 90.5743 70.6667 96.7447 70.6667 104.356C70.6667 111.968 76.9773 118.139 84.7619 118.139C92.5465 118.139 98.8571 111.968 98.8571 104.356C98.8571 96.7447 92.5465 90.5743 84.7619 90.5743ZM70.6667 145.703C70.6667 138.091 76.9773 131.921 84.7619 131.921C92.5465 131.921 98.8571 138.091 98.8571 145.703C98.8571 153.315 92.5465 159.485 84.7619 159.485C76.9773 159.485 70.6667 153.315 70.6667 145.703ZM127.048 90.5743C119.263 90.5743 112.952 96.7447 112.952 104.356C112.952 111.968 119.263 118.139 127.048 118.139C134.832 118.139 141.143 111.968 141.143 104.356C141.143 96.7447 134.832 90.5743 127.048 90.5743ZM112.952 145.703C112.952 138.091 119.263 131.921 127.048 131.921C134.832 131.921 141.143 138.091 141.143 145.703C141.143 153.315 134.832 159.485 127.048 159.485C119.263 159.485 112.952 153.315 112.952 145.703ZM81.2381 52.6733C75.3997 52.6733 70.6667 57.3011 70.6667 63.0099C70.6667 68.7187 75.3997 73.3465 81.2381 73.3465H130.571C136.41 73.3465 141.143 68.7187 141.143 63.0099C141.143 57.3011 136.41 52.6733 130.571 52.6733H81.2381Z'
        fill='#00D6BC'
      />
      <path
        d='M431.001 92.068C421.345 92.068 414.273 84.18 414.273 74.524C414.273 65.004 421.889 57.116 432.021 57.116C442.085 57.116 449.769 64.596 449.769 74.524V91.252H443.377V85.404C440.861 89.484 436.441 92.068 431.001 92.068ZM432.021 85.676C438.277 85.676 443.037 80.644 443.037 74.592C443.037 68.472 438.277 63.44 432.021 63.44C425.833 63.44 421.005 68.472 421.005 74.592C421.005 80.644 425.833 85.676 432.021 85.676Z'
        fill='#64748B'
      />
      <path
        d='M400.914 91.252H394.182V71.396C394.182 63.304 398.806 57.252 407.51 57.252C409.618 57.252 411.794 57.66 413.494 58.476V65.14C411.862 64.188 409.958 63.712 408.122 63.712C403.634 63.712 400.914 66.772 400.914 71.668V91.252Z'
        fill='#64748B'
      />
      <path
        d='M368.219 107.368C361.011 107.368 355.027 104.24 351.967 98.324L357.611 95.264C359.583 98.868 363.255 101.18 368.083 101.18C375.087 101.18 379.847 96.148 379.847 88.328V85.676C377.671 89.144 373.591 91.728 368.015 91.728C358.563 91.728 351.083 84.248 351.083 74.456C351.083 64.936 358.699 57.116 368.831 57.116C378.895 57.116 386.579 64.596 386.579 74.524V88.26C386.579 99.82 378.827 107.368 368.219 107.368ZM368.763 85.404C374.883 85.404 379.779 80.508 379.779 74.388C379.779 68.268 374.883 63.508 368.763 63.508C362.711 63.508 357.747 68.268 357.747 74.388C357.747 80.508 362.711 85.404 368.763 85.404Z'
        fill='#64748B'
      />
      <path
        d='M331.69 92C321.558 92 314.01 84.656 314.01 74.388C314.01 64.8 320.878 56.98 330.874 56.98C340.938 56.98 347.194 64.256 347.194 73.572V76.292H320.538C321.286 82.004 325.638 86.016 331.622 86.016C335.702 86.016 339.374 84.248 341.346 80.576L346.514 83.228C343.59 88.94 338.218 92 331.69 92ZM320.81 71.94H340.462C340.19 66.636 336.314 63.032 330.806 63.032C325.162 63.032 321.49 66.976 320.81 71.94Z'
        fill='#64748B'
      />
      <path d='M300.459 42.632H307.191V91.252H300.459V42.632Z' fill='#64748B' />
      <path
        d='M272.728 92.068C263.072 92.068 256 84.18 256 74.524C256 65.004 263.616 57.116 273.748 57.116C283.812 57.116 291.496 64.596 291.496 74.524V91.252H285.104V85.404C282.588 89.484 278.168 92.068 272.728 92.068ZM273.748 85.676C280.004 85.676 284.764 80.644 284.764 74.592C284.764 68.472 280.004 63.44 273.748 63.44C267.56 63.44 262.732 68.472 262.732 74.592C262.732 80.644 267.56 85.676 273.748 85.676Z'
        fill='#64748B'
      />
      <path
        d='M614.45 150.764C603.698 150.764 598.322 159.53 598.322 169.628V195.925H587.234V168.518C587.234 152.651 596.754 140.223 614.45 140.223C632.37 140.223 642.114 152.651 642.114 168.407V195.925H631.026V169.739C631.026 159.53 625.426 150.764 614.45 150.764Z'
        fill='#334155'
      />
      <path
        d='M554.131 132.234H542.819L556.035 115.368H567.571L554.131 132.234ZM549.091 197.257C532.515 197.257 519.747 184.386 519.747 168.629C519.747 152.984 532.627 140.223 549.091 140.223C565.667 140.223 578.211 153.095 578.211 168.629C578.211 184.386 565.555 197.257 549.091 197.257ZM549.091 186.827C559.283 186.827 567.123 178.616 567.123 168.74C567.123 158.754 559.283 150.542 549.091 150.542C538.787 150.542 530.835 158.754 530.835 168.74C530.835 178.616 538.787 186.827 549.091 186.827Z'
        fill='#334155'
      />
      <path
        d='M502.916 130.791C498.884 130.791 495.636 127.574 495.636 123.579C495.636 119.584 498.884 116.256 502.916 116.256C506.948 116.256 510.084 119.584 510.084 123.579C510.084 127.574 506.948 130.791 502.916 130.791ZM497.428 141.555H508.516V195.925H497.428V141.555Z'
        fill='#334155'
      />
      <path
        d='M473.102 197.368C459.438 197.368 452.158 188.824 452.158 176.396V151.097H441.294V141.555H452.158V127.463H463.246V141.555H484.302V151.097H463.246V176.063C463.246 183.165 467.502 187.381 474.11 187.381C477.694 187.381 481.614 186.05 484.302 184.164V194.261C481.502 196.147 477.134 197.368 473.102 197.368Z'
        fill='#334155'
      />
      <path
        d='M416.402 197.146C406.21 197.146 398.37 193.151 394.114 185.051L402.626 180.391C405.426 185.828 409.906 187.825 415.954 187.825C422.226 187.825 427.042 185.273 427.042 180.502C427.042 169.073 395.57 177.728 395.57 156.978C395.57 147.436 404.082 140.445 416.066 140.445C426.258 140.445 433.202 145.105 436.674 151.43L428.162 156.201C425.81 151.43 421.33 149.766 416.29 149.766C411.138 149.766 406.658 152.429 406.658 156.756C406.658 167.852 438.13 159.752 438.13 180.28C438.13 190.599 428.05 197.146 416.402 197.146Z'
        fill='#334155'
      />
      <path
        d='M361.276 197.146C344.588 197.146 332.156 185.162 332.156 168.407C332.156 152.762 343.468 140.001 359.932 140.001C376.508 140.001 386.812 151.874 386.812 167.076V171.514H342.908C344.14 180.835 351.308 187.381 361.164 187.381C367.884 187.381 373.932 184.496 377.18 178.505L385.692 182.832C380.876 192.153 372.028 197.146 361.276 197.146ZM343.356 164.413H375.724C375.276 155.758 368.892 149.877 359.82 149.877C350.524 149.877 344.476 156.312 343.356 164.413Z'
        fill='#334155'
      />
      <path
        d='M294.192 197.257C272.688 197.257 256 180.058 256 158.643C256 137.671 272.576 120.361 294.192 120.361C306.624 120.361 318.72 126.353 324.544 137.671L315.248 142.997C310.768 135.341 302.928 131.013 293.744 131.013C278.96 131.013 267.76 143.33 267.76 158.865C267.76 174.732 278.96 186.827 294.192 186.827C303.152 186.827 310.432 183.387 314.016 179.17V161.306H325.104V183.054C319.952 190.488 308.864 197.257 294.192 197.257Z'
        fill='#334155'
      />
    </svg>
  );
}

export default AlegraManagement;

import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';
import { graphqlOperation } from '@aws-amplify/api';
import { get } from 'lodash'

import * as queries from '../graphql/queries'
import { handleError } from '../utils/errors'
import { APIGraphqlSelector, station as stationSelector } from '../selectors/app'
import { getInitialState } from '../utils';

const initialState = {
  entities: {},
  ids: [],
  loading: 'idle',
  error: null,
  total: 0,
  version: 1
}

export const fetchRefunds = createAsyncThunk(
  'refunds/fetch',
  async (batch = null, { rejectWithValue, dispatch, getState }) => {
    const station = stationSelector(getState())
    try {
      const APIGraphql = APIGraphqlSelector(getState());
      const response = await APIGraphql(graphqlOperation(queries.allRefunds, {
        idStation: station.id,
        includeMetadata: true,
        batch,
      }))

      const refunds = get(response, 'data.allRefunds.data', [])
      const refundsTotal = !!get(response, 'data.allRefunds.metadata.total')
        ? get(response, 'data.allRefunds.metadata.total') : 0
      
      dispatch(receiveRefunds(refunds))
      dispatch(setTotal(refundsTotal))

    } catch (error) {
      return rejectWithValue(handleError(error));
    }
  }
)

export const adapter = createEntityAdapter({
  sortComparer: (a, b) => +b.id - +a.id
});

const appSlice = createSlice({
  name: 'refunds',
  initialState: getInitialState('refunds', initialState),
  reducers: {
    receiveRefunds: (state, action) => {
      adapter.setAll(state, action.payload)
    },
    updateRefund: adapter.updateOne,
    replaceRefund: (state, action) => {
      const index = state.ids.indexOf(action.payload.id)

      if (index >= 0 && !!get(action, 'payload.new.id')) {
        state.ids[index] = action.payload.new.id;
        state.entities[action.payload.new.id] = action.payload.new;
        delete state.entities[action.payload.id]
      }
    },
    setTotal: (state, action) => {
      state.total = action.payload
    },
  },
  extraReducers: builder => {
    builder.addCase(fetchRefunds.pending, state => {
      state.error = null;
      state.loading = 'loading'
    })
    builder.addCase(fetchRefunds.rejected, (state, action) => {
      state.error = action.payload;
      state.loading = 'idle'
    })
    builder.addCase(fetchRefunds.fulfilled, state => {
      state.error = null;
      state.loading = 'idle'
    })
  }
});

const { reducer, actions } = appSlice;

export const refundsSelector = adapter.getSelectors(state => state.refunds);

export const { receiveRefunds, updateRefund, replaceRefund, setTotal } = actions;

export default reducer;
import { Icon } from "@alegradev/smile-ui-react"
import Typography from "../../common/Typography"
import { capitalize, get, lowerCase } from "lodash"
import BigNumber from "bignumber.js"
import { replaceAndParse } from "../../../utils"
import { useDispatch } from "react-redux"
import { I18n } from "aws-amplify"
import { NavLink } from "react-router-dom/cjs/react-router-dom"
import { country } from "../../../selectors/company"
import { useDeviceType } from "../../../hooks/useDeviceType"
import { closeModal } from "../../../reducers/modals"
import { IconDotsCircleHorizontal } from "@tabler/icons-react"
import { useFormat } from "../../../hooks/useFormat"



const InvoiceSaving = ({ invoiceCreated }) => {
    const dispatch = useDispatch();
    const { fmt, decimal } = useFormat();
    const isElectronic = get(invoiceCreated, 'numberTemplate.isElectronic', false)
    const getDocumentType = () => {
        if (country === 'spain') {
            if (get(invoiceCreated, 'numberTemplate.documentType') === 'saleTicket') {
                return I18n.get('invoiceModalTitle.saleTicket', 'Ticket');
            }
        }
        return I18n.get(
            get(invoiceCreated, 'numberTemplate.documentType'),
            'invoice'
        );
    };
    const deviceType = useDeviceType();

    return (
        <div className='p-5'>
            <div className='d-flex flex-column justify-content-center align-items-center'>
                <div className='mt-5 mb-3'>
                    <Icon icon={IconDotsCircleHorizontal} size='huge' color='#94A3B8' />
                </div>
                <div className='d-flex gap-3'>
                    <Typography
                        text={isElectronic ? I18n.get('invoiceCreatedElectronicMessageTitle', 'Guardando') : I18n.get('saving', 'Guardando')}
                        type='heading-4'
                    />
                    <div className="dot-flashing"></div>
                </div>
                <Typography
                    text={isElectronic ? I18n.get('invoiceCreatedElectronicMessage', 'Estamos enviando tu factura ⚡') : ''}
                    type='heading-4'
                />
                {isElectronic === false && (
                    <div className='d-flex align-items-center gap-3 modal__invoice-success-total my-3'>
                        <div>
                            <Typography text={capitalize(I18n.get('total', 'total'))} type='heading-4' />
                        </div>
                        <div>
                            <Typography
                                text={new BigNumber(invoiceCreated.total).toFormat(
                                    decimal,
                                    fmt
                                )}
                                type='heading-4'
                            />
                        </div>
                    </div>
                )}
            </div>
            {isElectronic === false && (
                <div className={`${deviceType !== 'mobile' && 'd-flex'} justify-content-center gap-2`}>
                    <p className='text-center h4 text-capitalize-first'>
                        {replaceAndParse(
                            I18n.get(
                                'invoiceSavingMessageNoElectronic',
                                'Puedes seguir vendiendo o consultar esta {} en el'
                            ),
                            [lowerCase(getDocumentType())]
                        )}
                    </p>
                    <p className='text-center h4 text-capitalize-first'>
                        <NavLink
                            to='/invoices'
                            onClick={() => dispatch(closeModal({ modal:  country === 'colombia' ? 'invoiceSaved' : 'newInvoiceSaved' }))}
                        >
                            {I18n.get('salesHistoryLink', 'Historial de ventas →')}
                        </NavLink>
                    </p>
                </div>
            )}
        </div>
    )

}

export default InvoiceSaving;
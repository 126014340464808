// eslint-disable-next-line
export default ({ id, className }) => (
  <svg 
    id={id}
    className={className}
    width="49" 
    height="48" 
    viewBox="0 0 49 48" 
    fill="none" 
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M7.51241 18.4635C7.32011 18.1652 7.40606 17.7675 7.70438 17.5752L24.3824 6.82457C24.6808 6.63227 25.0785 6.71823 25.2708 7.01655L33.0994 19.1616C33.2917 19.4599 33.2058 19.8577 32.9075 20.05L16.2294 30.8006C15.9311 30.9929 15.5333 30.9069 15.341 30.6086L7.51241 18.4635Z" fill="#126F98"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M7.008 16.4948L23.6861 5.74424C24.581 5.16734 25.7742 5.4252 26.3511 6.32018L34.1798 18.4652C34.7567 19.3602 34.4988 20.5534 33.6038 21.1303L16.9258 31.8809C16.0308 32.4578 14.8376 32.2 14.2607 31.305L6.43207 19.1599C5.85517 18.2649 6.11302 17.0717 7.008 16.4948ZM7.70439 17.5752C7.40606 17.7675 7.32011 18.1652 7.51241 18.4635L15.341 30.6086C15.5333 30.9069 15.9311 30.9929 16.2294 30.8006L32.9075 20.05C33.2058 19.8577 33.2917 19.46 33.0994 19.1616L25.2708 7.01656C25.0785 6.71823 24.6808 6.63228 24.3824 6.82458L7.70439 17.5752Z" fill="#17273D"/>
    <path d="M6.59415 19.3125C6.46961 18.9801 6.63809 18.6097 6.97046 18.4852L28.5229 10.4093C28.8553 10.2848 29.2257 10.4532 29.3502 10.7856L33.8747 22.8604C33.9993 23.1927 33.8308 23.5631 33.4984 23.6877L11.946 31.7636C11.6136 31.8881 11.2432 31.7196 11.1187 31.3873L6.59415 19.3125Z" fill="#2FA8DD"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M6.51946 17.2816L28.0719 9.20569C29.069 8.83207 30.1802 9.3375 30.5538 10.3346L35.0784 22.4094C35.452 23.4065 34.9465 24.5177 33.9494 24.8913L12.397 32.9672C11.3999 33.3408 10.2887 32.8354 9.91507 31.8383L5.39055 19.7635C5.01693 18.7664 5.52236 17.6552 6.51946 17.2816ZM6.97047 18.4852C6.6381 18.6097 6.46962 18.9801 6.59416 19.3125L11.1187 31.3873C11.2432 31.7196 11.6136 31.8881 11.946 31.7636L33.4984 23.6877C33.8308 23.5632 33.9993 23.1928 33.8747 22.8604L29.3502 10.7856C29.2257 10.4532 28.8553 10.2848 28.5229 10.4093L6.97047 18.4852Z" fill="#17273D"/>
    <path d="M6.36865 20.0924C6.36865 18.8501 7.37571 17.843 8.61798 17.843H32.0753C33.3176 17.843 34.3246 18.8501 34.3246 20.0924V37.605C34.3246 38.8473 33.3176 39.8543 32.0753 39.8543H8.61798C7.37571 39.8543 6.36865 38.8473 6.36865 37.605V20.0924Z" fill="white"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M8.61798 16.397H32.0753C34.1162 16.397 35.7706 18.0515 35.7706 20.0923V37.605C35.7706 39.6459 34.1162 41.3003 32.0753 41.3003H8.61798C6.57711 41.3003 4.92265 39.6459 4.92265 37.605V20.0923C4.92265 18.0515 6.57711 16.397 8.61798 16.397ZM8.61798 17.843C7.37571 17.843 6.36865 18.8501 6.36865 20.0923V37.605C6.36865 38.8473 7.37571 39.8543 8.61798 39.8543H32.0753C33.3176 39.8543 34.3246 38.8473 34.3246 37.605V20.0923C34.3246 18.8501 33.3176 17.843 32.0753 17.843H8.61798Z" fill="#17273D"/>
    <path d="M25.2301 27.6869C25.2301 26.7996 25.9494 26.0802 26.8368 26.0802H36.4247C37.312 26.0802 38.0314 26.7996 38.0314 27.6869V31.2893C38.0314 32.1767 37.312 32.896 36.4247 32.896H26.8368C25.9494 32.896 25.2301 32.1767 25.2301 31.2893V27.6869Z" fill="white"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M26.8367 24.4736H36.4247C38.1994 24.4736 39.638 25.9122 39.638 27.6869V31.2893C39.638 33.064 38.1994 34.5027 36.4247 34.5027H26.8367C25.0621 34.5027 23.6234 33.064 23.6234 31.2893V27.6869C23.6234 25.9122 25.0621 24.4736 26.8367 24.4736ZM26.8367 26.0802C25.9494 26.0802 25.2301 26.7996 25.2301 27.6869V31.2893C25.2301 32.1767 25.9494 32.896 26.8367 32.896H36.4247C37.312 32.896 38.0313 32.1767 38.0313 31.2893V27.6869C38.0313 26.7996 37.312 26.0802 36.4247 26.0802H26.8367Z" fill="#17273D"/>
    <path d="M30.2537 29.1269C30.2537 29.8886 29.6362 30.5061 28.8745 30.5061C28.1128 30.5061 27.4953 29.8886 27.4953 29.1269C27.4953 28.3652 28.1128 27.7478 28.8745 27.7478C29.6362 27.7478 30.2537 28.3652 30.2537 29.1269Z" fill="#17273D"/>
    <path d="M40.7074 17.9782C40.7983 17.0497 40.7055 16.1124 40.4341 15.2197C40.1628 14.3271 39.7184 13.4966 39.1261 12.7757C38.5338 12.0549 37.8054 11.4577 36.9823 11.0183C36.1593 10.579 35.2578 10.3061 34.3292 10.2151L34.1402 12.1458C34.8151 12.2119 35.4705 12.4103 36.0688 12.7297C36.6671 13.0491 37.1966 13.4832 37.6272 14.0072C38.0577 14.5312 38.3808 15.1349 38.5781 15.7839C38.7753 16.4328 38.8428 17.1142 38.7767 17.7891L40.7074 17.9782Z" fill="#2FA8DD"/>
    <path d="M44.0275 17.9094C44.3487 16.6313 44.3708 15.2963 44.092 14.0083C43.8133 12.7203 43.2412 11.5139 42.4203 10.483C41.5995 9.45205 40.5519 8.62433 39.359 8.06419C38.1661 7.50405 36.8601 7.22657 35.5426 7.25334L35.5826 9.22307C36.5972 9.20246 37.603 9.41614 38.5216 9.8475C39.4402 10.2789 40.247 10.9163 40.8791 11.7102C41.5113 12.5041 41.9518 13.4331 42.1665 14.425C42.3811 15.4169 42.3641 16.4449 42.1168 17.4292L44.0275 17.9094Z" fill="#2FA8DD"/>
  </svg>
)

import { useSelector } from 'react-redux';
import { I18n } from '@aws-amplify/core';

import {
  country as countrySelector,
  decimalPrecision,
  regime as regimeSelector,
} from '../../../../selectors/company';

import Typography from '../../../../components/common/Typography';
import { formatCurrencyMoney } from '../../../../utils/format-money';
import { useGlobalInvoiceDetail } from '../context';
import { get } from 'lodash';
import { mexicoDefaultPaymentValues } from '../../../../utils';

function GlobalInvoiceDetailFooter({ noBreadcrump }) {
  const country = useSelector(countrySelector);
  const regime = useSelector(regimeSelector);
  const decimal = useSelector(decimalPrecision);
  const { paymentMethod, paymentUse, paymentWay } = mexicoDefaultPaymentValues;
  const { data } = useGlobalInvoiceDetail();

  return (
    <div className='global-invoices-detail-footer'>
      <div className='mb-4'>
        <Typography
          type='body-1-bold'
          variant='secondary'
          text={I18n.get('', 'Información adicional')}
        />
        <div className='row w-100'>
          <div className={`col-md-${noBreadcrump ? '12' : '6'} col-sm-12 mt-4`}>
            <Typography
              type='label-2'
              variant='secondary'
              text={I18n.get('', 'Método de pago')}
            />
            <div className='mt-1' />
            <Typography
              type='body-3-regular'
              variant='secondary'
              text={paymentMethod}
            />
          </div>
          <div className={`col-md-${noBreadcrump ? '12' : '6'} col-sm-12 mt-4`}>
            <Typography
              type='label-2'
              variant='secondary'
              text={I18n.get('', 'Forma de pago')}
            />
            <div className='mt-1' />
            <Typography
              type='body-3-regular'
              variant='secondary'
              text={paymentWay}
            />
          </div>
          <div className={`col-md-${noBreadcrump ? '12' : '6'} col-sm-12 mt-4`}>
            <Typography
              type='label-2'
              variant='secondary'
              text={I18n.get('', 'Uso')}
            />
            <div className='mt-1' />
            <Typography
              type='body-3-regular'
              variant='secondary'
              text={paymentUse}
            />
          </div>
          <div className={`col-md-${noBreadcrump ? '12' : '6'} col-sm-12 mt-4`}>
            <Typography
              type='label-2'
              variant='secondary'
              text={I18n.get('', 'Régimen')}
            />
            <div className='mt-1' />
            <Typography
              type='body-3-regular'
              variant='secondary'
              text={regime}
            />
          </div>
        </div>
      </div>
      <div className='global-invoices-detail-resume'>
        <div className='d-flex justify-content-between'>
          <Typography type='caption-bold' text='Subtotal' variant='secondary' />
          <Typography
            type='body-3-bold'
            text={formatCurrencyMoney(get(data, 'total'), country, decimal)}
            variant='secondary'
          />
        </div>
        <div className='d-flex justify-content-between'>
          <Typography
            type='caption-bold'
            text='Descuento'
            variant='secondary'
          />
          <Typography
            type='body-3-bold'
            text={formatCurrencyMoney(0, country, decimal)}
            variant='secondary'
          />
        </div>
        <div className='dashed-line' />
        <div className='d-flex justify-content-between'>
          <Typography type='body-2-bold' text='Total' variant='secondary' />
          <Typography
            type='body-1-bold'
            text={formatCurrencyMoney(get(data, 'total'), country, decimal)}
            variant='secondary'
          />
        </div>
      </div>
    </div>
  );
}

export default GlobalInvoiceDetailFooter;

import React from 'react'

import BasicData from './BasicData';
import Inventory from './Inventory';
import Price from './Price';
import CustomField from './CustomFields';

const ItemSimplifiedForm = ({ type, ...props }) => {
  return (
    <div className="form-body__fields form-row justify-content-start">
      <>
        <BasicData {...props} />
        {type === 'product' && (
          <Inventory {...props} />
        )}
        <Price {...props} />
        {type !== 'kit' && (
          <CustomField {...props} />
        )
        }
      </>
    </div>
  )
}

export default ItemSimplifiedForm;
import { Loader } from '@alegradev/smile-ui-react';
import { I18n } from 'aws-amplify';
import { createContext, useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useContactQuery } from '../services/hooks';

const ContactContext = createContext({
  editable: false,
  contact: null,
  ignoreRepeated: false,
  toggleContact: () => {},
  toggleEditable: () => {},
  toggleIgnoreRepeated: () => {},
});

function ContactProvider({ children }) {
  const [editable, setEditable] = useState(false);
  const [ignoreRepeated, setIgnoreRepeated] = useState(false);

  const params = useParams();
  const [contact, setContact] = useState(null);
  const { isFetching } = useContactQuery(params?.id);

  useEffect(() => {
    return () => {
      setEditable(false);
      setContact(null);
    };
  }, []);

  useEffect(() => {
    if (params?.id) {
      setEditable(true);
    }
  }, [params?.id]);

  if (isFetching) {
    return (
      <Loader
        orientation='vertical'
        size='large'
        emphasis='page'
        title={I18n.get(
          'loadingContactInfo',
          'Cargando información del contacto'
        )}
      />
    );
  }

  const toggleEditable = () => {
    setEditable(true);
  };

  const toggleContact = (contact) => {
    setContact(contact);
  };

  const toggleIgnoreRepeated = () => {
    setIgnoreRepeated(!ignoreRepeated);
  };

  return (
    <ContactContext.Provider
      value={{
        editable,
        toggleEditable,
        toggleContact,
        contact,
        ignoreRepeated,
        toggleIgnoreRepeated,
      }}
    >
      {children}
    </ContactContext.Provider>
  );
}

function useContact() {
  return useContext(ContactContext);
}

export { ContactProvider, useContact };

import React from 'react'
import { useDispatch } from 'react-redux';
import { I18n } from '@aws-amplify/core';

import { openModal } from '../../reducers/modals';

const NoSelectedElement = () => {
  const dispatch = useDispatch()

  return (
    <div className="w-100 h-100 d-flex justify-content-center align-items-center">
      <div className="p-4 text-center">
        <p className="text-capitalize-first">
          {I18n.get('noSelectedPayment', 'selecciona operaciones de efectivo de la lista para ver los detalles. O crea una nueva')}
        </p>

        <button
          type="button"
          className="btn btn-submit"
          onClick={() => dispatch(openModal({ modal: 'payments' }))}
        >
          {I18n.get('inOutCash', 'ingresar / retirar efectivo')}
        </button>
      </div>
    </div>
  )
}

export default NoSelectedElement;
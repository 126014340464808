import { createDraftSafeSelector } from '@reduxjs/toolkit';
import { get } from 'lodash';


export const userSelector = state => get(state, 'auth', null);
export const permissionsSelector = state => get(state, 'auth.permissions', null);

export const isAdmin = createDraftSafeSelector(
  userSelector,
  user => get(user, 'role', null) === 'admin'
)

export const hasPermissionTo = createDraftSafeSelector(
  permissionsSelector,
  permissions => (action, entity) =>
    !!permissions ? get(permissions, `${entity}.${action}`, 'allow') === 'allow' : false
)

export const permissionValue = createDraftSafeSelector(
  permissionsSelector,
  permissions => (action, entity) =>
    !!permissions ? get(permissions, `${entity}.${action}`) : null
)

export const languageSelector = createDraftSafeSelector(
  userSelector,
  user => get(user, 'language', 'es')
)

export const economicActivitiesSelector = createDraftSafeSelector(
  userSelector,
  user => get(user, 'company.economicActivities', null) ?
    get(user, 'company.economicActivities', null).filter(activity => activity.id !== null) : []
)

export const mainEconomicActivitySelector = createDraftSafeSelector(
  userSelector,
  user => get(user, 'company.economicActivities', null) ? get(user, 'company.economicActivities', null).find(active => active.main === true && active.code !== null) : null
)
function AlegraInvoice({ className, id }) {
  return (
    <svg
      id={id}
      className={className}
      width='207'
      height='56'
      viewBox='0 0 207 56'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M19.8965 14.8927C19.8965 12.3378 21.9512 10.2666 24.4857 10.2666H42.0775C44.6121 10.2666 46.6667 12.3378 46.6667 14.8927V41.1072C46.6667 43.6621 44.6121 45.7333 42.0775 45.7333H24.4857C21.9512 45.7333 19.8965 43.6621 19.8965 41.1072V14.8927ZM25.6332 17.9768C25.6332 16.6993 26.6605 15.6637 27.9277 15.6637H30.9872C32.2545 15.6637 33.2818 16.6993 33.2818 17.9768C33.2818 19.2542 32.2545 20.2898 30.9872 20.2898H27.9277C26.6605 20.2898 25.6332 19.2542 25.6332 17.9768ZM25.6332 27.6145C25.6332 26.337 26.6605 25.3014 27.9277 25.3014H38.6358C39.9031 25.3014 40.9304 26.337 40.9304 27.6145C40.9304 28.8919 39.9031 29.9275 38.6358 29.9275H27.9277C26.6605 29.9275 25.6332 28.8919 25.6332 27.6145Z'
        fill='#00D6BC'
      />
      <path
        d='M12.3542 15.6009L17.602 13.6756V43.6608L9.61138 21.5303C8.74452 19.1294 9.97253 16.4748 12.3542 15.6009Z'
        fill='#00D6BC'
      />
      <path
        d='M63.6411 21.4374C61.3854 21.4374 59.7333 19.6178 59.7333 17.3903C59.7333 15.1942 61.5125 13.3746 63.8794 13.3746C66.2305 13.3746 68.0256 15.1001 68.0256 17.3903V21.2491H66.5323V19.9001C65.9446 20.8413 64.912 21.4374 63.6411 21.4374ZM63.8794 19.9629C65.3409 19.9629 66.4529 18.8021 66.4529 17.406C66.4529 15.9942 65.3409 14.8334 63.8794 14.8334C62.4338 14.8334 61.306 15.9942 61.306 17.406C61.306 18.8021 62.4338 19.9629 63.8794 19.9629Z'
        fill='#64748B'
      />
      <path
        d='M70.1195 10.0334H71.6922V21.2491H70.1195V10.0334Z'
        fill='#64748B'
      />
      <path
        d='M77.4153 21.4217C75.0484 21.4217 73.2851 19.7276 73.2851 17.3589C73.2851 15.1472 74.8895 13.3433 77.2247 13.3433C79.5758 13.3433 81.0372 15.0217 81.0372 17.1707V17.7982H74.8101C74.9848 19.1158 76.0015 20.0413 77.3994 20.0413C78.3526 20.0413 79.2104 19.6334 79.6711 18.7864L80.8784 19.3982C80.1953 20.7158 78.9403 21.4217 77.4153 21.4217ZM74.8736 16.7942H79.4646C79.401 15.5707 78.4955 14.7393 77.2088 14.7393C75.8903 14.7393 75.0325 15.6491 74.8736 16.7942Z'
        fill='#64748B'
      />
      <path
        d='M85.949 24.9668C84.2651 24.9668 82.8672 24.2452 82.1523 22.8805L83.4708 22.1746C83.9315 23.006 84.7894 23.5393 85.9172 23.5393C87.5534 23.5393 88.6654 22.3785 88.6654 20.5746V19.9629C88.1571 20.7629 87.204 21.3589 85.9013 21.3589C83.6932 21.3589 81.9458 19.6334 81.9458 17.3746C81.9458 15.1785 83.725 13.3746 86.092 13.3746C88.443 13.3746 90.2381 15.1001 90.2381 17.3903V20.5589C90.2381 23.2256 88.4272 24.9668 85.949 24.9668ZM86.0761 19.9001C87.5058 19.9001 88.6496 18.7707 88.6496 17.3589C88.6496 15.9472 87.5058 14.8491 86.0761 14.8491C84.6623 14.8491 83.5026 15.9472 83.5026 17.3589C83.5026 18.7707 84.6623 19.9001 86.0761 19.9001Z'
        fill='#64748B'
      />
      <path
        d='M93.587 21.2491H92.0143V16.6687C92.0143 14.8021 93.0945 13.406 95.1279 13.406C95.6204 13.406 96.1287 13.5001 96.5258 13.6883V15.2256C96.1446 15.006 95.6998 14.8962 95.2709 14.8962C94.2224 14.8962 93.587 15.6021 93.587 16.7315V21.2491Z'
        fill='#64748B'
      />
      <path
        d='M100.616 21.4374C98.3598 21.4374 96.7077 19.6178 96.7077 17.3903C96.7077 15.1942 98.4868 13.3746 100.854 13.3746C103.205 13.3746 105 15.1001 105 17.3903V21.2491H103.507V19.9001C102.919 20.8413 101.886 21.4374 100.616 21.4374ZM100.854 19.9629C102.315 19.9629 103.427 18.8021 103.427 17.406C103.427 15.9942 102.315 14.8334 100.854 14.8334C99.4082 14.8334 98.2803 15.9942 98.2803 17.406C98.2803 18.8021 99.4082 19.9629 100.854 19.9629Z'
        fill='#64748B'
      />
      <path
        d='M62.3991 45.63H59.7333V30.6651C59.7333 29.1893 60.3867 28.2831 62.19 28.2831H71.8081V30.7169H62.3991V36.5941H71.2069V39.0278H62.3991V45.63Z'
        fill='#334155'
      />
      <path
        d='M79.3585 45.9407C75.6472 45.9407 72.929 42.9374 72.929 39.2609C72.929 35.6361 75.8563 32.6328 79.7505 32.6328C83.6186 32.6328 86.572 35.4808 86.572 39.2609V45.63H84.1152V43.4034C83.1482 44.9568 81.4493 45.9407 79.3585 45.9407ZM79.7505 43.507C82.155 43.507 83.9845 41.591 83.9845 39.2867C83.9845 36.9566 82.155 35.0407 79.7505 35.0407C77.3721 35.0407 75.5165 36.9566 75.5165 39.2867C75.5165 41.591 77.3721 43.507 79.7505 43.507Z'
        fill='#334155'
      />
      <path
        d='M95.3749 45.9148C91.6897 45.9666 88.6841 42.9374 88.6841 39.2609C88.6841 35.6102 91.6897 32.6069 95.3749 32.6328C97.7271 32.6328 99.8964 33.6943 101.072 35.9727L98.9293 37.1637C98.1714 35.7915 96.8907 35.0665 95.3749 35.0665C93.1272 35.0665 91.2715 36.9566 91.2715 39.2609C91.2715 41.591 93.1272 43.507 95.3749 43.507C96.8907 43.507 98.1714 42.782 98.9293 41.4098L101.072 42.5749C99.8964 44.8792 97.7271 45.9148 95.3749 45.9148Z'
        fill='#334155'
      />
      <path
        d='M108.755 45.9666C105.566 45.9666 103.868 43.973 103.868 41.0732V35.1701H101.332V32.9435H103.868V29.6554H106.455V32.9435H111.369V35.1701H106.455V40.9955C106.455 42.6526 107.448 43.6364 108.99 43.6364C109.827 43.6364 110.741 43.3257 111.369 42.8856V45.2416C110.715 45.6818 109.696 45.9666 108.755 45.9666Z'
        fill='#334155'
      />
      <path
        d='M120.025 43.4552C122.403 43.4552 123.71 41.4098 123.71 39.0537V32.9435H126.297V39.3644C126.297 43.0409 124.05 45.9407 120.025 45.9407C116.052 45.9407 113.804 43.015 113.804 39.3385V32.9435H116.392V39.0796C116.392 41.4098 117.672 43.4552 120.025 43.4552Z'
        fill='#334155'
      />
      <path
        d='M131.715 45.63H129.128V38.0699C129.128 34.9889 130.905 32.6846 134.251 32.6846C135.061 32.6846 135.897 32.8399 136.55 33.1506V35.6879C135.923 35.3254 135.191 35.1442 134.486 35.1442C132.761 35.1442 131.715 36.3093 131.715 38.1734V45.63Z'
        fill='#334155'
      />
      <path
        d='M143.279 45.9407C139.568 45.9407 136.85 42.9374 136.85 39.2609C136.85 35.6361 139.777 32.6328 143.671 32.6328C147.539 32.6328 150.493 35.4808 150.493 39.2609V45.63H148.036V43.4034C147.069 44.9568 145.37 45.9407 143.279 45.9407ZM143.671 43.507C146.076 43.507 147.905 41.591 147.905 39.2867C147.905 36.9566 146.076 35.0407 143.671 35.0407C141.293 35.0407 139.437 36.9566 139.437 39.2867C139.437 41.591 141.293 43.507 143.671 43.507Z'
        fill='#334155'
      />
      <path
        d='M159.295 45.9148C155.61 45.9666 152.605 42.9374 152.605 39.2609C152.605 35.6102 155.61 32.6069 159.295 32.6328C161.648 32.6328 163.817 33.6943 164.993 35.9727L162.85 37.1637C162.092 35.7915 160.811 35.0665 159.295 35.0665C157.048 35.0665 155.192 36.9566 155.192 39.2609C155.192 41.591 157.048 43.507 159.295 43.507C160.811 43.507 162.092 42.782 162.85 41.4098L164.993 42.5749C163.817 44.8792 161.648 45.9148 159.295 45.9148Z'
        fill='#334155'
      />
      <path
        d='M168.651 30.4321C167.71 30.4321 166.952 29.6812 166.952 28.7492C166.952 27.8171 167.71 27.0404 168.651 27.0404C169.592 27.0404 170.323 27.8171 170.323 28.7492C170.323 29.6812 169.592 30.4321 168.651 30.4321ZM167.37 32.9435H169.957V45.63H167.37V32.9435Z'
        fill='#334155'
      />
      <path
        d='M180.602 30.7687H177.962L181.046 26.8333H183.738L180.602 30.7687ZM179.426 45.9407C175.558 45.9407 172.578 42.9374 172.578 39.2609C172.578 35.6102 175.584 32.6328 179.426 32.6328C183.294 32.6328 186.221 35.6361 186.221 39.2609C186.221 42.9374 183.268 45.9407 179.426 45.9407ZM179.426 43.507C181.804 43.507 183.634 41.591 183.634 39.2867C183.634 36.9566 181.804 35.0407 179.426 35.0407C177.021 35.0407 175.166 36.9566 175.166 39.2867C175.166 41.591 177.021 43.507 179.426 43.507Z'
        fill='#334155'
      />
      <path
        d='M194.678 35.0924C192.169 35.0924 190.914 37.1378 190.914 39.4939V45.63H188.327V39.235C188.327 35.5326 190.548 32.6328 194.678 32.6328C198.859 32.6328 201.133 35.5326 201.133 39.2091V45.63H198.546V39.5198C198.546 37.1378 197.239 35.0924 194.678 35.0924Z'
        fill='#334155'
      />
    </svg>
  );
}

export default AlegraInvoice;

import React from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { I18n } from '@aws-amplify/core';
import { get } from 'lodash';
import dayjs from 'dayjs';
import BigNumber from 'bignumber.js'

import {
  decimalPrecision,
  country as countrySelector,
  dateFormat as dateFormatSelector,
  companySelector,
  membershipSelector,
  decimalSeparator as decimalSeparatorSelector,
} from '../../selectors/company';
import { calculateItemsValues, calculateSingleItemValues, calculateSingleItemValuesWithDecimals } from '../../utils';
import { getNumber } from '../../utils/invoices';
import useDecimalsVersionsGroup from '../../hooks/useDecimalsVersionsGroup/hook';

const getItemReference = (item) => {
  try {
    return !!get(item, 'reference.reference')
      ? JSON.parse(get(item, 'reference.reference')) : !!get(item, 'reference')
        ? JSON.parse(get(item, 'reference')) : ''
  } catch {
    return ''
  }
}

const DetailBody = ({ refund }) => {
  const decimal = useSelector(decimalPrecision);
  const country = useSelector(countrySelector);
  const company = useSelector(companySelector);
  const membership = useSelector(membershipSelector);
  const dateFormat = useSelector(dateFormatSelector);
  const { isDecimalActive } = useDecimalsVersionsGroup();
  const decimalSeparator = useSelector(decimalSeparatorSelector);

  if (!refund) return null;

  const prefix = !!get(refund, 'currency.symbol')
    ? get(refund, 'currency.symbol') : null;

  const fmt = prefix ? {
    prefix,
    decimalSeparator,
    groupSeparator: decimalSeparator === "," ? "." : ",",
    groupSize: 3,
  } : {
    decimalSeparator,
    groupSeparator: decimalSeparator === "," ? "." : ",",
    groupSize: 3,
  };

  const itemsValues = calculateItemsValues({items: refund.items, decimal, country, company, membership});

  return (
    <div className="p-4 position-relative overflow-hidden">
      <div>
        <table className="detail-info-table rounded-lg mb-4">
          <tbody>
            <tr>
              <td>{I18n.get('client', 'cliente')}</td>
              <td>{get(refund, 'client.name', '')}</td>
            </tr>
            <tr>
              <td>{I18n.get('creation', 'creación')}</td>
              <td>{dayjs(refund.dateTime).format(!!dateFormat ? `${dateFormat.toUpperCase()} h:mm a` : I18n.get('dateTimeFormat', 'DD/MM/YYYY h:mm a'))}</td>
            </tr>
            <tr>
              <td>{I18n.get('refundType', 'tipo de nota de crédito')}</td>
              <td>{get(refund, 'type', '')}</td>
            </tr>
            <tr>
              <td>{I18n.get('warehouse', 'bodega')}</td>
              <td>{get(refund, 'warehouse.name', '')}</td>
            </tr>
            <tr>
              <td>{I18n.get('costCenter', 'centro de costo')}</td>
              <td>{get(refund, 'costCenter.name', '')}</td>
            </tr>
            {!!get(refund, 'currency', null) && (
              <tr>
                <td>{I18n.get('currency', 'moneda')}</td>
                <td>{get(refund, 'currency.code', '')}</td>
              </tr>
            )}
            {!!get(refund, 'idShift', null) && (
              <tr>
                <td>{I18n.get('shiftNumber', 'número de turno')}</td>
                <td>{get(refund, 'idShift', '')}</td>
              </tr>
            )}
          </tbody>
        </table>

        <div className="detail-items-table-wrapper mb-4">
          <table className="detail-items-table rounded-lg">
            <thead>
              <tr>
                <th>{I18n.get('item', 'producto')}</th>
                <th>{I18n.get('reference', 'referencia')}</th>
                <th>{I18n.get('price', 'precio')}</th>
                <th>{I18n.get('discount', 'descuento')}</th>
                <th>{I18n.get('taxes', 'impuestos')}</th>
                <th>{I18n.get('quantity', 'cantidad')}</th>
                <th>{I18n.get('total', 'total')}</th>
              </tr>
            </thead>
            <tbody>
              {refund.items.map((item, index) => {
                const itemValues = isDecimalActive ? calculateSingleItemValuesWithDecimals(item, decimal) : calculateSingleItemValues(item, decimal)
                return (
                  <tr key={index}>
                    <td>{item.name}</td>
                    <td>
                      {getItemReference(item)}
                    </td>
                    <td>{itemValues.price.toFormat(decimal, fmt)}</td>
                    <td>{itemValues.discount}%</td>
                    <td>{itemValues.taxes}</td>
                    <td>{itemValues.quantity}</td>
                    <td>{itemValues.total.toFormat(decimal, fmt)}</td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>

        <div className="d-flex justify-content-end align-items-start flex-wrap">
          <table className="detail-summary-table rounded-lg mb-4">
            <tbody>
              {/* {(itemsValues.discount.gt(0) || !!itemsValues.taxes.length) && ( */}
              <tr>
                <td className="text-capitalize-first">{I18n.get('subtotal', 'subtotal')}</td>
                <td>{itemsValues.subtotal.toFormat(decimal, fmt)}</td>
              </tr>
              {/* )} */}
              {/* {itemsValues.discount.gt(0) && ( */}
              <tr>
                <td className="text-capitalize-first">{I18n.get('discount', 'descuento')}</td>
                <td>{itemsValues.discount.toFormat(decimal, fmt)}</td>
              </tr>
              {/* )} */}
              {/* {itemsValues.discount.gt(0) && ( */}
              <tr>
                <td className="text-capitalize-first">{I18n.get('subtotal', 'subtotal')}</td>
                <td>{itemsValues.discSubtotal.toFormat(decimal, fmt)}</td>
              </tr>
              {/* )} */}
              {itemsValues.taxes.map((tax, index) => (
                <tr key={index}>
                  <td>{tax.name}</td>
                  <td>{tax.value.toFormat(decimal, fmt)}</td>
                </tr>
              ))}
              <tr>
                <td className="text-capitalize-first font-weight-bold">{I18n.get('total', 'total')}</td>
                <td className="font-weight-bold">{new BigNumber(refund.total).toFormat(decimal, fmt)}</td>
              </tr>
            </tbody>
          </table>
        </div>

        {!!get(refund, 'invoices.length', '') && (
          <>
            <p className="text-capitalize-first h4">
              {I18n.get('cashToInvoices', 'crédito a facturas')}
            </p>

            <div className="detail-items-table-wrapper mb-4">
              <table className="detail-items-table rounded-lg">
                <thead>
                  <tr>
                    <th>{I18n.get('invoice', 'factura')}</th>
                    <th>{I18n.get('date', 'fecha')}</th>
                    <th>{I18n.get('dueDate', 'vencimiento')}</th>
                    <th>{I18n.get('total', 'total')}</th>
                    <th>{I18n.get('paid', 'pagado')}</th>
                    <th>{I18n.get('pending', 'pendiente')}</th>
                    <th>{I18n.get('amount', 'monto')}</th>
                  </tr>
                </thead>
                <tbody>
                  {refund.invoices.map((invoice, index) => (
                    <tr key={index}>
                      <td>{getNumber(invoice, country, true)}</td>
                      <td>{dayjs(invoice.date).format(!!dateFormat ? dateFormat.toUpperCase() : I18n.get('dateFormat', 'DD/MM/YYYY'))}</td>
                      <td>{dayjs(invoice.dueDate).format(!!dateFormat ? dateFormat.toUpperCase() : I18n.get('dateFormat', 'DD/MM/YYYY'))}</td>
                      <td>{new BigNumber(invoice.total).toFormat(decimal, fmt)}</td>
                      <td>
                        {
                          new BigNumber(invoice.total)
                            .minus(invoice.amount)
                            .minus(invoice.balance)
                            .toFormat(decimal, fmt)
                        }
                      </td>
                      <td>{new BigNumber(invoice.balance).toFormat(decimal, fmt)}</td>
                      <td>{new BigNumber(invoice.amount).toFormat(decimal, fmt)}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </>
        )}

        {!!get(refund, 'refunds.length', '') && (
          <>
            <p className="text-capitalize-first h4">
              {I18n.get('refunds', 'devoluciones')}
            </p>

            <div className="detail-items-table-wrapper mb-4">
              <table className="detail-items-table rounded-lg">
                <thead>
                  <tr>
                    <th>{I18n.get('date', 'fecha')}</th>
                    <th>{I18n.get('bankAccount', 'cuenta bancaria')}</th>
                    <th>{I18n.get('annotations', 'observaciones')}</th>
                    <th>{I18n.get('amount', 'monto')}</th>
                  </tr>
                </thead>
                <tbody>
                  {refund.refunds.map((refund, index) => (
                    <tr key={index}>
                      <td>{dayjs(refund.date).format(!!dateFormat ? dateFormat.toUpperCase() : I18n.get('dateFormat', 'DD/MM/YYYY'))}</td>
                      <td>{get(refund, 'account.name', '')}</td>
                      <td>{get(refund, 'observations', '')}</td>
                      <td>{new BigNumber(refund.amount).toFormat(decimal, fmt)}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </>
        )}
      </div>
    </div>
  )
}

DetailBody.propTypes = {
  refund: PropTypes.object
}

export default DetailBody;
import React from "react";
import { NavLink } from "react-router-dom";
import { I18n } from "@aws-amplify/core";
import { capitalize } from "lodash";

const Attributes = () => (
  <div
    className="inventory-menu container d-flex flex-wrap p-5 
    justify-content-center justify-content-sm-start align-items-start text-left"
  >
    <div className="inventory-menu__option d-flex flex-column shadow">
      <div className="inventory-menu__option-header text-capitalize-first text-primary">{I18n.get("variants", "Variantes")}</div>
      <div className="inventory-menu__option-description text-muted text-capitalize-first">
        {I18n.get("variantsInventoryInfo", "Configura atributos que definen las características de tus productos como color, talla u otra cualidad.")}
      </div>

      <div className="inventory-menu__option-options d-flex flex-column">
        <NavLink to="/inventory/variants">{capitalize(I18n.get("myVariants", "Mis variantes"))}</NavLink>
      </div>
    </div>

    <div className="inventory-menu__option d-flex flex-column shadow">
      <div className="inventory-menu__option-header text-capitalize-first text-primary">{I18n.get("customFields", "Campos adicionales")}</div>
      <div className="inventory-menu__option-description text-muted text-capitalize-first">
        {I18n.get("customFieldsInventoryInfo", "Crea campos adicionales para agregar información extra en tus items, como el código de barras.")}
      </div>

      <div className="inventory-menu__option-options d-flex flex-column">
        <NavLink to="/inventory/custom-fields">{capitalize(I18n.get('myCustomFields', 'Mis campos adicionales'))}</NavLink>
      </div>
    </div>
  </div>
);

export default Attributes;

import React from 'react';
import { Field } from 'react-final-form';
import { I18n } from '@aws-amplify/core';
import { useSelector } from 'react-redux'
import { capitalize, lowerCase, deburr, get } from 'lodash';

import { activeCurrencies } from '../../../selectors/currencies'
import { renderField, renderSelect } from '../fields/V0/Fields';
import configAPI from '../../../reducers/configAPI';

const filterOptions = (query, options) =>
  options.filter(option =>
    lowerCase(deburr(option.name)).includes(lowerCase(deburr(query)))
    || lowerCase(deburr(option.code)).includes(lowerCase(deburr(query))))

const Currency = () => {
  const currencies = useSelector(activeCurrencies)

  return (
    <div className="form-body h-100 p-4 overflow-hidden">
      <div className="form-body__fields form-row justify-content-start">
        <Field
          name="currency"
          className="col-12"
          component={renderSelect}
          label={capitalize(I18n.get('currency', 'Moneda'))}
          loadOptions={async inputValue => {
            try {
              // ex queries.allAlegraCurrencies

              const response = await configAPI.get('/currencies', {
                codes_excludes: !!currencies
                  ? currencies.map(c => c.code).join(',')
                  : null
              })

              let data = get(response, 'data', [])
              return filterOptions(inputValue, data)
            } catch {
              return []
            }
          }}
          cacheOptions
          defaultOptions
          isSearchable
          getOptionLabel={option => option.name}
          getOptionValue={option => option.code}
          menuPosition="absolute"
          required
        />

        <Field
          name="exchangeRate"
          className="col-12"
          component={renderField}
          type="number"
          label={capitalize(I18n.get('exchangeRate', 'Tasa de cambio'))}
          required
        />
      </div>
    </div>
  )
}

export default Currency;
export function trimObjectStrings(obj) {
  Object.keys(obj).forEach((key) => {
    const value = obj[key];

    if (typeof value === 'string') {
      obj[key] = value.trim();
    } else if (
      typeof value === 'object' &&
      value !== null &&
      !Array.isArray(value)
    ) {
      trimObjectStrings(value);

      if (Object.keys(value).length === 0) {
        delete obj[key];
      }
    }
  });

  return obj;
}

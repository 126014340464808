import React from 'react';
import { I18n } from '@aws-amplify/core';
import { Field } from 'react-final-form';
import { capitalize } from 'lodash';
import { useSelector } from 'react-redux'

import { activeWarehouses } from '../../../selectors/warehouses'
import { renderSelect } from '../fields/V0/Fields';

const BasicInfo = () => {
  const warehouses = useSelector(activeWarehouses);

  return (
    <div className="form-body__fields form-row justify-content-start">
      <Field
        name="warehouse"
        className="col-12"
        component={renderSelect}
        label={capitalize(I18n.get('warehouse', 'bodega'))}
        options={warehouses}
        getOptionLabel={option => option.name}
        getOptionValue={option => option.id}
        required
      />
    </div>
  )
}

export default BasicInfo;

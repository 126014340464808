const operationTypes = [
  // {
  //   id: "05",
  //   value: "Genérica",
  //   key: "GENERIC"
  // },
  {
    id: "09",
    value: "Servicios AIU",
    key: "AIU_SERVICE"
  },
  {
    id: "10",
    value: "Estándar",
    key: "STANDARD"
  },
  // {
  //   id: "11",
  //   value: "Mandatos bienes",
  //   key: "MANDATE"
  // }
]

export default operationTypes;

import React from 'react';
import { Field } from 'react-final-form';
import { I18n } from '@aws-amplify/core';
import { useSelector, useDispatch } from 'react-redux';
import { capitalize, lowerCase, get, replace } from 'lodash';

import {
  country as countrySelector,
  isOnlyInvoicingPlan,
  electronicInvoicing,
} from '../../../selectors/company';
import { allItemCategories } from '../../../selectors/itemCategories';
import { renderCheckbox, renderField, renderSelect } from '../fields/V0/Fields';
import { openModal } from '../../../reducers/modals';
import { checkFeatureLimit } from '../../../reducers/membership';
import { renderProductKeyTooltip, replaceAndParse } from '../../../utils';
import configAPI from '../../../reducers/configAPI';
import { COUNTRIES } from '../../../utils/enums/countries';

export const referenceTypesTemporal = [
  {
    id: 1,
    code: '01',
    key: 'CODE_OF_THE_SELLER',
    value: 'Código del producto del vendedor',
  },
  {
    id: 2,
    code: '02',
    key: 'CODE_OF_THE_BUYER',
    value: 'Código del producto del comprador',
  },
  {
    id: 3,
    code: '03',
    key: 'CODE_ASSIGNED_BY_INDUSTRY',
    value: 'Código del producto asignado por la industria',
  },
  {
    id: 4,
    code: '04',
    key: 'CODE_OF_INTERNAL_USE',
    value: 'Código uso interno',
  },
  {
    id: 5,
    code: '99',
    key: 'OTHER',
    value: 'Otros',
  },
];

const getProductKeyOptionLabel = (option, country) =>
  country === 'costaRica'
    ? !option.tax
      ? `${option.key} - ${option.name}`
      : `${option.key} - ${option.name} - IVA ${option.tax}%`
    : `${option.key} - ${option.name}`;

const RapidData = (props) => {
  const dispatch = useDispatch();
  const itemCategories = useSelector(allItemCategories);
  const country = useSelector(countrySelector);
  const onlyInvoicing = useSelector(isOnlyInvoicingPlan);
  const isElectronic = useSelector(electronicInvoicing);
  const referenceMaxLimit =
    country === 'costaRica' && get(props, 'values.reference.length', 0) > 20;

  const countriesWithKeys =
    country === 'colombia' ||
    country === 'costaRica' ||
    country === 'peru' ||
    country === 'mexico';

  const getReferenceTooltipText = () => {
    const referenceCharactersPerCountry = {
      [COUNTRIES.COSTA_RICA]: 20,
      [COUNTRIES.REPUBLICA_DOMINICANA]: 35,
      [COUNTRIES.PERU]: 100,
    };

    if (
      [
        COUNTRIES.REPUBLICA_DOMINICANA,
        COUNTRIES.PERU,
        COUNTRIES.COSTA_RICA,
      ].includes(country)
    ) {
      return capitalize(
        replace(
          I18n.get(
            'maxReferenceCharactersExceeded',
            'Código de máximo {} caracteres que te servirá para identificar tus productos'
          ),
          '{}',
          referenceCharactersPerCountry[country]
        )
      );
    }

    return replaceAndParse(
      I18n.get(
        'referenceHelp',
        'Código único para identificar tus productos, ejemplo CAS002'
      ),
      [lowerCase(I18n.get(get(props, 'values.type', 'product')))]
    );
  };

  return (
    <div className='form-body__fields form-row justify-content-start m-0'>
      <div className='mt-2 w-100' />

      {get(props, 'values.type') === 'product' && (
        <Field
          name='negativeSale'
          component={renderCheckbox}
          className='col-12'
          type='text'
          position='left'
          justify='between'
          switchLabelTitle={capitalize(I18n.get('negativeSaleTitle', 'Venta en negativo'))}
          switchLabel={capitalize(
            I18n.get('negativeSale', 'Vende sin unidades disponibles')
          )}
          defaultValue={true}
        />
      )}

      <div className='mb-3 w-100' />

      <Field
        name='reference'
        component={renderField}
        className={`col-sm-6${referenceMaxLimit ? ' has-error' : ''}`}
        type='text'
        label={capitalize(I18n.get('reference', 'referencia'))}
        helpTooltip={getReferenceTooltipText()}
      />

      {countriesWithKeys && (
        <Field
          name='productKey'
          component={renderSelect}
          className='col-sm-6'
          required={
            (country === 'mexico' && isElectronic) ||
            (country === 'costaRica' && isElectronic)
          }
          loadOptions={async (inputValue) => {
            try {
              // ex queries.allItemCodes

              const response = await configAPI.get('/products-keys', {
                application_version: country,
                name_contains: inputValue,
                limit: 20,
              });
              let data = get(response, 'data', []);
              if (country === 'costaRica')
                data = data.filter((itemCode) => !itemCode.deprecated);

              return data;
            } catch {
              return [];
            }
          }}
          defaultOptions
          getOptionLabel={(option) => getProductKeyOptionLabel(option, country)}
          getOptionValue={(option) => option.key}
          label={
            country === 'peru'
              ? I18n.get('sunatCode', 'código SUNAT')
              : I18n.get(
                  `${get(props, 'values.type')}Key`,
                  'código del producto'
                )
          }
          helpTooltip={renderProductKeyTooltip(country)}
        />
      )}

      {country === 'costaRica' && !!get(props, 'values.reference', null) && (
        <Field
          name='referenceType'
          component={renderSelect}
          className='col-sm-6'
          // options={[
          //   { value: "01", label: capitalize(I18n.get('sellerProductCode', 'Código del producto del vendedor')) },
          //   { value: "02", label: capitalize(I18n.get('buyerProductCode', 'Código del producto del comprador')) },
          //   { value: "03", label: capitalize(I18n.get('productCodeAssignedByTheIndustry', 'Código del producto asignado por la industria')) },
          //   { value: "04", label: capitalize(I18n.get('codeForInternalUse', 'Código uso interno')) },
          // ]}
          options={referenceTypesTemporal}
          getOptionLabel={(option) => option.value}
          getOptionValue={(option) => option.code}
          label={capitalize(I18n.get('categoryType', 'tipo de referencia'))}
          validate={(value) =>
            !value
              ? capitalize(
                  I18n.get(
                    '',
                    'El tipo de referencia es obligatorio cuando se indica la referencia del item.'
                  )
                )
              : null
          }
          required
        />
      )}

      {country === 'costaRica' && (
        <Field
          name='tariffHeading'
          className={`col-sm-${
            !!get(props, 'values.reference', null) ? '6' : '12'
          }`}
          component={renderField}
          type='text'
          label={capitalize(I18n.get('tariffHeading', 'Partida arancelaria'))}
          helpTooltip={capitalize(
            I18n.get(
              'tariffHeadingHelp',
              'De uso exclusivo para exportadores en la venta de mercancías'
            )
          )}
        />
      )}

      <Field
        name='category'
        component={renderSelect}
        className={countriesWithKeys ? 'col-12' : 'col-sm-6'}
        options={itemCategories}
        disabled={onlyInvoicing}
        onlyInvoicingTooltip={onlyInvoicing}
        getOptionLabel={(option) => option.name}
        getOptionValue={(option) => option.id}
        label={capitalize(I18n.get('category', 'categoría'))}
        helpTooltip={
          <p className='text-white'>
            {capitalize(
              I18n.get(
                'categoryDescription',
                'Las categorías te sirven para agrupar tus productos y servicios según su tipo o clase.'
              )
            )}
            <a
              href='https://ayuda.alegra.com/es/crea-categor%C3%ADas-y-clasifica-tus-productos-en-el-sistema-de-punto-de-venta-pos'
              target='_blank'
              rel='noreferrer'
            >
              {I18n.get('knowMore', 'Saber más')}
            </a>
          </p>
        }
        addOptionText={capitalize(
          I18n.get('newItemCategory', 'Nueva categoría')
        )}
        addOptionAction={() =>
          dispatch(
            checkFeatureLimit('itemCategories', () =>
              dispatch(openModal({ modal: 'itemCategory' }))
            )
          )
        }
        showClearIndicator
      />
    </div>
  );
};

export default RapidData;

import React from 'react'
import { I18n } from 'aws-amplify';

const FeatureConsumption = ({ title, currentValue, limit, formatFunction }) => {
  formatFunction = formatFunction || ((value) => value);
  return (
    <div className="py-4">
      <div className="general-consumption-content d-flex flex-row justify-content-between">
        <p className="h5 text-muted text-capitalize-first">
          {title}
        </p>
        <p className="h5" style={{ textAlign: "end" }}>
          <span className="font-weight-bold">{formatFunction(currentValue)}</span> {`${I18n.get('of', 'de')} ${formatFunction(limit)}`}
        </p>
      </div>
      <div
        className="progress w-100 position-relative"
        style={{
          marginTop: '.5rem',
          height: '.8rem',
          background: '#cccccc',
          overflow: 'hidden',
        }}
      >
        <div
          className="progress-bar"
          role="progressbar"
          style={{
            width: '100%',
            background:
              'linear-gradient(90deg, #00C3AD 7.99%, #B3DE7C 37.96%, #FFC687 66.37%, #F99D36 91.69%, #FF4F4F 103.06%)',
          }}
          aria-valuenow={+`${(currentValue / limit) * 100}` || 0}
          aria-valuemin="0"
          aria-valuemax="100"
        />
        <div
          className="position-absolute w-100"
          style={{
            left: `${currentValue / limit * 100}%`,
            height: '100%',
            background: '#cccccc',
          }}
        />
      </div>
    </div>
  );
}

export default FeatureConsumption;
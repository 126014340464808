import BigNumber from 'bignumber.js';

export const getFastOptionFontSize = (value) => {
  const length = value.length;

  if (length >= 14) return '9px';

  return `${16 - (length - 3) * 0.5}px`;
};

export const getFastOptionFormated = ({ cashOption, decimal, fmt }) => {
  return new BigNumber(cashOption).toFormat(decimal, fmt);
};

export const countryDenominations = (code) => {
  switch (code) {
    case 'COP':
      return [1000, 2000];
    case 'CRC':
      return [1000, 2000];
    case 'DOP':
      return [20, 50];
    case 'MXN':
      return [20, 50];
    case 'PEN':
      return [10, 20];
    case 'ARL':
      return [20, 50];
    case 'CLP':
      return [1000, 2000];
    default:
      return [5, 10];
  }
};

import { useQuery } from "@tanstack/react-query";
import alegraAPI from "../../reducers/alegraAPI";

const fetchRefund = async (id) => {
  const fields = "stampFiles";
  const response = await alegraAPI.get(`/credit-notes/${id}?fields=${fields}`);

  return response.data;
};

export const useRefundQuery = (id, enabled) => {
  return useQuery({
    queryKey: ["refund", {
      id
    }],
    queryFn: () => fetchRefund(id),
    refetchOnWindowFocus: false,
    refetchOnReconnect: true,
    staleTime: 1000 * 60 * 60 * 24,
    enabled
  });
};
import React from 'react';
import {I18n} from '@aws-amplify/core';
import {BigNumber} from 'bignumber.js';
import {contactUsLink, replaceAndParse} from '../../utils'

const DOT_DECIMAL_SEPARATOR_COUNTRIES = ['mexico', 'peru', 'republicaDominicana', 'panama', 'other', 'usa']

const getCountrySymbol = country => {
  switch (country) {
    case 'spain':
      return '€'
    case 'peru':
      return 'S/ '
    default:
      return '$'
  }
}

const getPlanCurrency = (country, planCurrency) => {
  switch (country) {
    case 'peru':
      return ''
    default:
      return planCurrency
  }
}

const getCurrencyCode = (country, currencyCode) => {
  switch (country) {
    case 'peru':
      return 'SOL'
    default:
      return currencyCode
  }
}

const getPlanRelevantFeatures = plan => {
  let relevantFeatures = []
  for (let feature in plan.features) {
    if (plan.features[feature].quantitative && plan.features[feature].relevant && plan.features[feature].include) {
      relevantFeatures.push(plan.features[feature])
    }
  }
  return relevantFeatures
}

const incomeChanger = (relevantFeatures, countrySymbol, currencyCode) => {
  const changedIncomeFeatures = relevantFeatures.map(feature => {
    if (feature.keyword.includes('income') && parseInt(feature.maxQuantityFormatted) > 0) {
      feature.description = replaceAndParse(I18n.get('planIncomeFeature', 'Ingresos hasta {}{} {} al mes'), [countrySymbol, feature.maxQuantityFormatted, currencyCode])
    }
    return feature
  })
  return changedIncomeFeatures;
}

const invoiceChanger = (relevantFeatures) => {
  const changedInvoiceFeatures = relevantFeatures.map(feature => {
    if (feature.keyword.includes('invoices') && parseInt(feature.maxQuantityFormatted) > 0) {
      feature.description = replaceAndParse(I18n.get('planInvoicesFeature', '{} facturas de venta al mes'), [feature.maxQuantityFormatted])
    } else if (feature.keyword.includes('invoices') && !(parseInt(feature.maxQuantityFormatted) > 0)) {
      feature.description = I18n.get('planInvoiceUnlimitedFeature', 'Facturas de venta ilimitadas al mes')
    }
    return feature
  })
  return changedInvoiceFeatures
}

const stationsChanger = (relevantFeatures) => {
  const changedStationsFeatures = relevantFeatures.map(feature => {
    if (feature.keyword.includes('stations') && feature.maxQuantityFormatted !== '-1') {
      feature.description = replaceAndParse(I18n.get('POSStationsFeature', '{} Puntos de venta'), [feature.maxQuantityFormatted])
    }
    if (feature.keyword.includes('stations') && feature.maxQuantityFormatted === '-1') {
      feature.description = I18n.get('POSStationsFeatureUnlimited', 'Puntos de venta ilimitados')
    }
    return feature;
  })
  return changedStationsFeatures;
}


const changeCommasForDots = (relevantFeatures, country) => {
  if (!DOT_DECIMAL_SEPARATOR_COUNTRIES.includes(country)) {
    const formattedFeatures = relevantFeatures.map(feature => {
      feature.description = feature.description.replace(/[,]/gi, ".")
      return feature
    })
    return formattedFeatures
  } else {
    return relevantFeatures
  }
}

const changeFeaturesOrder = (features, country) => {
  if (['mexico', 'peru', 'other'].includes(country)) {
    for (let feature in features) {
      if (features[feature].keyword.includes('invoices')) {
        features[feature].sortKey = 1
      }
      if (features[feature].keyword.includes('users')) {
        features[feature].sortKey = 3
      }
      if (features[feature].keyword.includes('income')) {
        features[feature].sortKey = 2
      }
    }
  } else {
    for (let feature in features) {
      if (features[feature].keyword.includes('invoices')) {
        features[feature].sortKey = 2
      }
      if (features[feature].keyword.includes('users')) {
        features[feature].sortKey = 3
      }
      if (features[feature].keyword.includes('income')) {
        features[feature].sortKey = 1
      } else {
        features[feature].sortKey = 4
      }
    }
  }
  return features.sort((x, y) => {
    return x.sortKey - y.sortKey;
  })
}

const changeFeatureWrapper = (plan, country) => {
  const relevantFeatures = getPlanRelevantFeatures(plan)
  const newIncomeFeatures = incomeChanger(relevantFeatures, getCountrySymbol(country), getCurrencyCode(country, plan.currency))
  const newInvoiceFeatures = invoiceChanger(newIncomeFeatures)
  const newStationsFeatures = stationsChanger(newInvoiceFeatures)
  const formattedFeatures = changeCommasForDots(newStationsFeatures, country)
  const orderedFeatures = changeFeaturesOrder(formattedFeatures, country)

  return orderedFeatures
}

const getPlanColor = plan => {
  if (plan.keyword) {
    if (plan.keyword.includes('pyme'))
      return '#BFCD31'
    if (plan.keyword.includes('plus'))
      return '#4A90E2'
    if (plan.keyword.includes('emprendedor'))
      return '#8592AD'
  }
  return '#00535e'
}

const isCorporative = plan => {
  return plan.keyword.includes('corporativo')
}

const redirectToContact = country => {
  switch (country) {
    case 'colombia':
      window.open(
        'https://www.alegra.com/colombia/enterprise/',
        '_blank'
      )
      break;
    case 'republicaDominicana':
      window.open(
        'https://es.alegra.com/rdominicana/plan-enterprise',
        '_blank'
      )
      break;
    case 'peru':
      window.open(
        'https://es.alegra.com/peru/plan-enterprise',
        '_blank'
      )
      break;
    default:
      window.open(
        contactUsLink(country),
        '_blank'
      )
  }
}

const Plan = ({plan, period, country, discount, showDiscount, onlyPlan, onChoose}) => {

  let planFeatures = changeFeatureWrapper(plan, country);

  const color = getPlanColor(plan)
  const fmt = DOT_DECIMAL_SEPARATOR_COUNTRIES.includes(country) ? {
    prefix: '',
    decimalSeparator: '.',
    groupSeparator: ',',
    groupSize: 3,
  } : {
    prefix: '',
    decimalSeparator: ',',
    groupSeparator: '.',
    groupSize: 3,
  }

  const price = new BigNumber(plan.price)
  const anualPrice = price.minus(price.multipliedBy(0.1))

  return (
    <div
      className={`${onlyPlan ? "secondary-plan d-flex flex-row justify-content-between align-items-center text-center"
        : "plan d-flex flex-column justify-content-start align-items-center mx-2 text-center"}`}>
      <div className={`text-center ${!!(isCorporative(plan) && onlyPlan) ? "pt-5" : "pt-4"}`}>
        <h2
          className="text-uppercase font-weight-bold "
          style={{color}}
        >
          {I18n.get(`planName.${plan.keyword}`, plan.name)}

          {showDiscount && (
            <p
              className="text-uppercase font-weight-bold"
              style={{color, fontSize: '13px'}}
            >
              {`${discount}% OFF`}
            </p>
          )}
        </h2>

        {
          !!isCorporative(plan) ? (<div className="my-5"></div>) : (
            <div className="py-3">
              {showDiscount && period !== 'year' && (
                <h1 className="text-uppercase font-weight-light"
                    style={{
                      textDecoration: 'line-through',
                      color: '#A3ADC2',
                      fontSize: '20px'
                    }}
                >
                  {`${price.multipliedBy(2).toFormat(0, {
                    ...fmt,
                    prefix: getCountrySymbol(country)
                  })} ${getCurrencyCode(country, plan.currency)}/${I18n.get('month', 'mes')}`}
                </h1>
              )}

              <div>
                <h3 className="text-uppercase font-weight-light"
                    style={onlyPlan ? {display: 'block'} : {display: 'inline'}}>
                  {`${period === 'year'
                    ? anualPrice.toFormat((!!price.c[0] && country === 'panama') ? 2 : 0, {
                      ...fmt,
                      prefix: getCountrySymbol(country)
                    })
                    : showDiscount
                      ? price.multipliedBy(new BigNumber(100 - discount)).dividedBy(100).multipliedBy(2)
                        .toFormat((!!price.c[1] && country !== 'panama') ? 2 : 0, {
                          ...fmt,
                          prefix: getCountrySymbol(country)
                        })
                      : price.toFormat((!!price.c[1] && country !== 'panama') ? 2 : 0, {
                        ...fmt,
                        prefix: getCountrySymbol(country)
                      })
                  } `}
                </h3>

                <p className="h5 text-uppercase text-muted" style={onlyPlan ? {display: 'block'} : {display: 'inline'}}>
                  {showDiscount
                    ? `${getPlanCurrency(country, plan.currency)} ${I18n.get('twoMonths', '2 Meses')}`
                    : `${getCurrencyCode(country, plan.currency)}/${I18n.get('month', 'mes')}`
                  }
                </p>
                {country === 'argentina' && (
                  <p className="h5 text-muted">
                    {I18n.get('includesIVA', 'Incluye IVA')}
                  </p>
                )}
              </div>

              {period === 'year' && (
                <p className="h6 text-capitalize-first">
                  {`${I18n.get('yearlyTotal', 'Total anual')} ${anualPrice.multipliedBy(12).toFormat(price.c[1] ? 2 : 0, {
                    ...fmt,
                    prefix: getCountrySymbol(country)
                  })}`}
                </p>
              )}
            </div>
          )
        }
      </div>

      <div className={!!(isCorporative(plan) && !onlyPlan) ? "mb-4" : ""}>
        <div className="px-4 text-center">
          <p className="h5 text-capitalize-first">
            {!!planFeatures
              ? planFeatures.map((feature, index) => {
                return (
                  <p key={index} className={`h5 ${onlyPlan ? "my-4" : "mb-4"} text-capitalize-first`}>
                    {feature.description}
                  </p>
                )
              })
              : null
            }
          </p>
        </div>
      </div>
      <div className={!!isCorporative(plan) ? "mt-3" : period === 'year' ? "mt-1" : "mt-4"}>
        <div className={`text-center text-sm-right ${onlyPlan ? "pb-3" : "pb-4"}`}>
          <button
            type="button"
            className="btn text-capitalize-first"
            style={{
              margin: 0,
              height: '3.8rem',
              width: '15rem',
              border: `1px solid ${color}`,
              boxSizing: 'border-box',
              borderRadius: '50px',
              fontSize: '1.2rem',
              fontWeight: 900,
              background: `${!!isCorporative(plan) ? color : '#F4F5FB'}`,
              color: `${!!isCorporative(plan) ? 'white' : '#00535e'}`,
              padding: '0 .25rem',
              outline: 'none',
              '&:hover': {
                '&:not(:disabled)': {
                  border: `1px solid ${color} !important`,
                }
              },
              '&:active': {
                '&:not(:disabled)': {
                  border: `1px solid ${color} !important`,
                }
              }
            }}
            onClick={
              !!isCorporative(plan) ? () => redirectToContact(country)
                : () => onChoose()}
          >
            {!!isCorporative(plan) ? I18n.get('contactUs', 'contáctanos')
              : I18n.get('choosePlan', 'Elegir plan')
            }
          </button>
        </div>
      </div>
    </div>
  )
}

export default Plan;
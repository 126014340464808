import React from 'react';
import { Field } from 'react-final-form';
import { I18n } from '@aws-amplify/core';

import conditionsIibb from '../../../countriesData/argentina/conditionsIibb';
import { renderSelect, renderField, renderDate } from '../../fields/V0/Fields';

const renderArgValues = props =>
  props.country === 'argentina' ? (
    <>
      
      <Field
        name="conditionIibb"
        component={renderSelect}
        className="col-md-6"
        label={I18n.get('iibbCondition', 'Condición IIBB')}
        options={conditionsIibb}
        getOptionLabel={option => option.value}
        getOptionValue={option => option.key}
        required
      />
      
      <Field
        name="numberIibb"
        component={renderField}
        className="col-md-6"
        label={I18n.get('numberIibb', 'Número de IIBB')}
        type="text"
        required
      />
      
      <Field
        name="activityStartDate"
        component={renderDate}
        className="col-md-6"
        label={I18n.get('activityStartDate', 'Inicio de actividades')}
        required
      />
    </>
  ) : null;

export default renderArgValues;
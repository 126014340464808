/**
 * Enum for refund methods.
 * @enum {string}
 * @readonly
 */
export const REFUND_METHODS = {
  /**
   * Refund method for cash.
   * @type {string}
   */
  CASH: 'cash',

  /**
   * Refund method for credit to sales.
   * @type {string}
   */
  CREDIT_TO_SALES: 'creditToSales',

  /**
   * Refund method for combined.
   * @type {string}
   */
  COMBINED: 'combined',

  /**
   * Refund method for combined.
   * @type {string}
   */
  POSITIVE_BALANCE: 'positiveBalance',

  /**
   * Refund method for rectificative in simplified invoice.
   * @type {string}
   */
  RECTIFICATIVE_IN_SIMPLIFIED_INVOICE: 'rectificativeInSimplifiedInvoice',

  /**
   * Refund method for rest.
   * @type {string}
   */
  REST: 'rest',

  /**
   * Refund method for error or modification of taxable amount.
   * @type {string}
   */
  ERROR_OR_MODIFICATION_OF_TAXABLE_AMOUNT: 'errorOrModificationOfTaxableAmount',

  /**
   * Refund method for contest.
   * @type {string}
   */
  CONTEST: 'contest',

  /**
   * Refund method for uncollectible debt.
   * @type {string}
   */
  UNCOLLECTIBLE_DEBT: 'uncollectibleDebt',

  /**
   * Refund method for no electronic simplified invoice.
   * Exists only in the POS and serves to differentiate methods of no electronic invoices only for runtime execution.
   * @type {string}
   */
  NO_ELECTRONIC_SIMPLIFIED_INVOICE: "noElectronicSimplifiedInvoice",

  /**
   * Refund method for no electronic ordinary invoice.
   * Exists only in the POS and serves to differentiate methods of no electronic invoices only for runtime execution.
   * @type {string}
   */
  NO_ELECTRONIC_ORDINARY_INVOICE: "noElectronicOrdinaryInvoice",
};
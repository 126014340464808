import { I18n } from "aws-amplify";
import { get } from "lodash";

export function getCreditNoteTypeLabel(invoice, type) {
  const numerationIsElectronic = get(invoice, 'numberTemplate.isElectronic');
  let res = type.value;
  switch (type.key) {
    case 'VOID_ELECTRONIC_INVOICE':
      if (get(invoice, 'numberTemplate.documentType') === 'saleTicket' && numerationIsElectronic)
        res = I18n.get('', 'Anulación del documento electronico');
      if (get(invoice, 'numberTemplate.documentType') === 'invoice' && numerationIsElectronic)
        res = I18n.get('', 'Anulación de factura electrónica');
      break;
  }
  return res;
}

export const isAccepted = (stamp) => {
  const legalStatus = get(stamp, 'legalStatus', null);
  switch (legalStatus) {
    case 'STAMPED_AND_ACCEPTED':
    case 'STAMPED_AND_ACCEPTED_WITH_OBSERVATIONS':
      return true;
    default:
      return false;
  }
}

export const transformItemFromInvoice = (originalItem, itemFromInvoice) => {
  let itemModified = { ...originalItem };
  if (!!itemModified.price?.length)
    itemModified.price = [{ ...originalItem.price[0], price: itemFromInvoice.price }];

  if (!!itemFromInvoice.description) itemModified.description = itemFromInvoice.description
  if (!!itemFromInvoice.discount) itemModified.discount = get(itemFromInvoice, 'discount', null)

  if (!!itemFromInvoice.tax?.length) itemModified.tax = get(itemFromInvoice, 'tax')
  else itemModified.tax = []

  return itemModified;
}

import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';
import { get } from 'lodash'

import { handleError } from '../utils/errors'
import configAPI from './configAPI';
import { getInitialState } from '../utils';

const initialState = {
  entities: {},
  ids: [],
  loading: 'idle',
  error: null,
  version: 1
}

export const fetchUnits = createAsyncThunk(
  'units/fetch',
  async ({ preloaded, ...params }, { rejectWithValue, getState }) => {
    try {
      if (!!preloaded)
        return preloaded

      // ex queries.allUnits
      const data = await configAPI.v2.get('/units', {
        application_version: params.applicationVersion,
      })
      if (params.applicationVersion === "republicaDominicana" && !!get(data, 'data')) {
        data.data = data.data.map(unit => ({ ...unit, unitType: unit.itemType }))
      }

      let units = [];
      if (!!get(data, 'data', null))
        units = units.concat(get(data, 'data'))
      return units;
    } catch (error) {
      return rejectWithValue(handleError(error))
    }
  }
)

export const adapter = createEntityAdapter();

const appSlice = createSlice({
  name: 'units',
  initialState: getInitialState('units', initialState),
  reducers: {
  },
  extraReducers: builder => {
    builder.addCase(fetchUnits.fulfilled, (state, action) => {
      adapter.setAll(state, action.payload)
      state.loading = 'idle'
      state.error = null
    })
    builder.addCase(fetchUnits.pending, (state) => {
      state.loading = 'loading'
      state.error = null
    })
    builder.addCase(fetchUnits.rejected, (state, action) => {
      state.loading = 'idle'
      state.error = action.payload
    })
  }
});

const { reducer } = appSlice;

export const unitsSelector = adapter.getSelectors(state => state.units);

export default reducer;
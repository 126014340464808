import { useEffect, useState, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { API, graphqlOperation } from '@aws-amplify/api';
import { get } from 'lodash';

import * as subscriptions from '../graphql/subscriptions';
import { station as stationSelector } from '../selectors/app';
import { idCompanySelector } from '../selectors/company';
import { refresh } from '../reducers/items'
import { put } from '../database/itemsDB';
import { isItemFromStation } from '../utils/items';

const Items = () => {
  const ref = useRef()
  const dispatch = useDispatch()
  const idCompany = useSelector(idCompanySelector)
  const station = useSelector(stationSelector)
  const networkStatus = useSelector(state => get(state, 'app.networkStatus', 'online'))
  const [onCreateItemError, setOnCreateItemError] = useState(false);

  useEffect(() => {
    let subsciption = null
    if (networkStatus === 'online') {
      subsciption = API.graphql(graphqlOperation(subscriptions.onCreateItem, {
        channel: `pos_${idCompany}`
      }))
        .subscribe({
          next: (data) => {
            const putItem = async data => {
              try {
                const { idWarehouse } = station;
                const item = get(data, 'value.data.onCreateItem', null);

                if (isItemFromStation(item, idWarehouse)) {
                  await put(item)
                  dispatch(refresh())
                }
              } catch {
              }
            }
            
            putItem(data);
          },
          error: () => setTimeout(() => {
            if (!!ref.current)
              setOnCreateItemError(!onCreateItemError)
          }, 60000)
        })
    }
    return () => {
      if (subsciption)
        subsciption.unsubscribe();
    }
  }, [networkStatus, onCreateItemError, dispatch, idCompany, station])

  useEffect(() => {
    ref.current = true
    return () => ref.current = false
  }, [])
  
  return null;
}

export default Items;
import { createDraftSafeSelector } from '@reduxjs/toolkit';
import { get } from 'lodash';

import { banksSelector } from '../reducers/banks'

export const activeBanks = createDraftSafeSelector(
  banksSelector.selectAll,
  banks => !!banks
    ? banks.filter(bank => get(bank, 'status') === 'active')
    : []
)

export const getBankById = id => createDraftSafeSelector(
  state => banksSelector.selectById(state, id),
  bank => !!bank && get(bank, 'status') === 'active' ? bank : null
)
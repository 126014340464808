import { Input, Typography } from '@alegradev/smile-ui-react';
import { I18n } from 'aws-amplify';
import { Field, useFormState } from 'react-final-form';
import { get } from 'lodash';

const ConfirmLogoutView = ({ confirmationCode }) => {
  const { touched, errors } = useFormState();
  return (
    <>
      <Typography
        type='body-2-bold mt-5'
        text={I18n.get('confirmLogout', 'Confirma tu cierre de sesión')}
      />
      <Typography
        extraClassName='text-center px-5 mx-3'
        withHtml
        type='body-3-regular'
        variant='secondary'
        text={I18n.get(
          'confirmLogout.description',
          'Si no deseas guardar las ventas pendientes de sincronización, ingresa el código <b>{{code}}</b> para cerrar sesión sin sincronizar.'
        ).replace('{{code}}', confirmationCode)}
      />
      <div className='mt-5 w-60'>
        <Field name='confirmationCode'>
          {({ input }) => (
            <Input
              {...input}
              fullWidth
              type='text'
              maxLength={4}
              placeholder={I18n.get(
                'confirmationCode',
                'Código de confirmación'
              )}
              hasError={
                get(touched, 'confirmationCode', false) &&
                get(errors, 'confirmationCode', false)
              }
              supportText={
                get(touched, 'confirmationCode', false) &&
                get(errors, 'confirmationCode', false)
                  ? get(errors, 'confirmationCode', '')
                  : null
              }
              onChange={(e) => {
                const value = e.target.value;
                if (/^\d{0,4}$/.test(value)) {
                  input.onChange(e);
                }
              }}
            />
          )}
        </Field>
      </div>
    </>
  );
};

export default ConfirmLogoutView;

import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';
import { get } from 'lodash'

import { handleError } from '../utils/errors'
import alegraAPI from '../reducers/alegraAPI';
import { getInitialState } from '../utils';

const initialState = {
  entities: {},
  ids: [],
  loading: 'idle',
  error: null,
  version: 1
}

export const fetchTaxes = createAsyncThunk(
  'taxes/fetch',
  async ({ preloaded, ...params }, { rejectWithValue, getState }) => {
    try {
      if (!!preloaded)
        return preloaded

      // ex queries.allTaxes

      const data = await alegraAPI.get('/taxes',{
        ...params
      });
      return get(data, 'data', null);
    } catch (error) {
      return rejectWithValue(handleError(error))
    }
  }
)

export const adapter = createEntityAdapter();

const appSlice = createSlice({
  name: 'taxes',
  initialState: getInitialState('taxes', initialState),
  reducers: {
  },
  extraReducers: builder => {
    builder.addCase(fetchTaxes.fulfilled, (state, action) => {
      adapter.setAll(state, action.payload)
      state.loading = 'idle'
      state.error = null
    })
    builder.addCase(fetchTaxes.pending, (state) => {
      state.loading = 'loading'
      state.error = null
    })
    builder.addCase(fetchTaxes.rejected, (state, action) => {
      state.loading = 'idle'
      state.error = action.payload
    })
  }
});

const { reducer } = appSlice;

export const taxesSelector = adapter.getSelectors(state => state.taxes);

export default reducer;
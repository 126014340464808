import { useState, useEffect, useCallback, useRef } from 'react';
import { throttle } from 'lodash';
import db from '../../database/database';
import { getAll } from '../../database/itemsDB';

const useItemsFromLocalDB = () => {
  const [allItems, setAllItems] = useState([]);
  const isFetchingRef = useRef(false);
  const listenerRef = useRef(null);

  const fetchItemsInLocalDB = useCallback(async () => {
    if (isFetchingRef.current) {
      return;
    }

    isFetchingRef.current = true;
    try {
      const items = await getAll();
      setAllItems(items);
    } catch (error) {
      console.error('Error fetching items:', error);
    } finally {
      isFetchingRef.current = false;
    }
  }, []);

  const throttledFetchItems = useCallback(
    throttle(() => {
      fetchItemsInLocalDB();
    }, 2000),
    [fetchItemsInLocalDB]
  );

  useEffect(() => {
    if (db?.items?.hook) {
      listenerRef.current = throttledFetchItems;
      db.items.hook('updating', listenerRef.current);
    }

    return () => {
      if (listenerRef.current && db?.items?.hook) {
        db.items.hook('updating').unsubscribe(listenerRef.current);
        listenerRef.current = null;
      }
    };
  }, [throttledFetchItems]);

  return { allItems, fetchItemsInLocalDB };
};

export default useItemsFromLocalDB;

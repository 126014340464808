import React from 'react';
import PropTypes from 'prop-types';
import { I18n } from '@aws-amplify/core';
import ClientInfo from './ClientInfo';
import RefundDetails from './RefundDetails';

/**
 * Colombia-specific Refund Tbody Component.
 *
 * @param {Object} props - Component props.
 * @param {Object} props.refund - The refund object.
 * @param {boolean} props.isElectronic - Flag indicating if the refund is electronic.
 * @param {Function} props.selectStatusColor - Function to select status color.
 * @param {string} props.dateFormat - Date format string.
 * @param {string} props.formattedDate - Formatted date string.
 * @param {string} props.country - The country code.
 * @returns {JSX.Element} - Rendered tbody for Colombia.
 */
const RefundBodyColombia = ({
  refund,
  isElectronic,
  selectStatusColor,
  dateFormat,
  formattedDate,
  country,
}) => (
  <>
    <ClientInfo clientName={refund?.client?.name || ''} />
    {isElectronic && (
      <RefundDetails
        refund={refund}
        statusColor={selectStatusColor(refund)}
        dateFormat={dateFormat}
        country={country}
      />
    )}
    {!isElectronic && refund?.cause && (
      <tr>
        <td className='caption-bold'>{I18n.get('reasonRefund', 'Razón')}</td>
        <td>{refund.cause}</td>
      </tr>
    )}
    <tr>
      <td className='caption-bold'>{I18n.get('creation', 'creación')}</td>
      <td>{formattedDate}</td>
    </tr>
    <tr>
      <td className='caption-bold'>{I18n.get('warehouse', 'bodega')}</td>
      <td>{refund?.warehouse?.name || ''}</td>
    </tr>
    {refund?.note && (
      <tr>
        <td className='caption-bold'>{I18n.get('notes', 'Notas')}</td>
        <td>{refund.note}</td>
      </tr>
    )}
    <tr>
      <td className='caption-bold'>
        {I18n.get('costCenter', 'centro de costo')}
      </td>
      <td>{refund?.costCenter?.name || ''}</td>
    </tr>
    {refund?.currency && (
      <tr>
        <td className='caption-bold'>{I18n.get('currency', 'moneda')}</td>
        <td>{refund.currency.code || ''}</td>
      </tr>
    )}
    {refund?.idShift && (
      <tr>
        <td className='caption-bold'>
          {I18n.get('shiftNumber', 'número de turno')}
        </td>
        <td>{refund.idShift}</td>
      </tr>
    )}
  </>
);

RefundBodyColombia.propTypes = {
  refund: PropTypes.object.isRequired,
  isElectronic: PropTypes.bool.isRequired,
  selectStatusColor: PropTypes.func.isRequired,
  dateFormat: PropTypes.string,
  formattedDate: PropTypes.string.isRequired,
  country: PropTypes.string.isRequired,
};

export default RefundBodyColombia;

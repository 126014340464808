import React from 'react'
import PropTypes from 'prop-types';

import ActiveInvoice from '../ActiveInvoice/ActiveInvoice'
import SearchItems from '../SearchItems/SearchItems';
import InvoiceSettings from '../InvoiceSettings/InvoiceSettings';
import EditItem from '../EditItem/EditItem';

const ItemsClient = ({
  isOpenSettings,
  isOpenEditItem,
  selectedIndex,
  setIsOpenSettings,
  setIsOpenEditItem,
  setSelectedIndex,
}) => {

  return (
    <div className="d-flex justify-content-between w-100 modal__refunds-body">
      <div className="col-md-6 d-none d-md-block">
        <div className="w-100 h-100">
          <SearchItems />
        </div>
      </div>
      <div className="col-md-6 col-12">
        <div className="w-100 h-100 bg-secondary position-relative overflow-hidden">
          <ActiveInvoice 
            openSettings={() => setIsOpenSettings(true)}
            openEditItem={index => {
              setSelectedIndex(index)
              setIsOpenEditItem(true)
            }}
          />

          <InvoiceSettings 
            isOpen={isOpenSettings}
            onClose={() => setIsOpenSettings(false)}
          />
          
          <EditItem 
            selectedIndex={selectedIndex}
            isOpen={isOpenEditItem}
            onClose={() => setIsOpenEditItem(false)}
          />
        </div>
      </div>
    </div>
  )
}

ItemsClient.propTypes = {
  isOpenSettings: PropTypes.bool,
  isOpenEditItem: PropTypes.bool,
  selectedIndex: PropTypes.number,
  setIsOpenSettings: PropTypes.func,
  setIsOpenEditItem: PropTypes.func,
  setSelectedIndex: PropTypes.func,
}

export default ItemsClient;
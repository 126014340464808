import dayjs from "dayjs";
import { get } from "lodash";
import { I18n } from '@aws-amplify/core';

const getDate = (date) => {
  if (!date) return null;
  return dayjs(date).format('DD/MM/YYYY');
}

const Invoice = ({ invoice }) => {
  if (!invoice) return null;
  const number = get(invoice, 'number', null);
  const prefix = get(invoice, 'prefix', null);
  const fullNumber = `${prefix}${number}`;
  const date = getDate(get(invoice, 'date', null));

  return (
    <div>
      <p>{I18n.get('document', 'Documento')} ref: <span>N° {fullNumber}</span> {date}</p>
    </div>
  )
}

export default Invoice
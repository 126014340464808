import { useEffect, useState } from 'react';
import { isMexicoFeAvailable } from '../../../../../utils/deploys/mexicoFeDeployGroups';
import useElectronicPayrollStatus from '../../../../../hooks/useElectronicPayrollStatus/hook';
import { COUNTRIES } from '../../../../../utils/enums/countries';
import { useSelector } from 'react-redux';
import { country as countrySelector } from '../../../../../selectors/company';

export function isCreateClientAvailable() {
  const country = useSelector(countrySelector);
  const { isActive } = useElectronicPayrollStatus();
  const [isAvailable, setIsAvailable] = useState(false);

  useEffect(() => {
    const isFeMexActive = isMexicoFeAvailable(country, isActive);
    if (country === COUNTRIES.MEXICO && !isFeMexActive) setIsAvailable(false);

    setIsAvailable(true);
  }, [country, isActive]);

  return isAvailable;
}

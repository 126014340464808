import React from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { I18n } from '@aws-amplify/core';
import { Form } from 'react-final-form';
import { FORM_ERROR } from 'final-form';
import { get } from 'lodash';

import { mainEconomicActivitySelector } from '../../../selectors/auth';
import { electronicInvoicing, stationInvoiceNumeration } from '../../../selectors/app';
import { companySelector, country as countrySelector } from '../../../selectors/company';
import { settings, economicActivity as economyActivitySelector } from '../../../selectors/activeInvoice';
import { getMainPriceList } from '../../../selectors/priceLists';
import { getMainCurrency } from '../../../selectors/currencies';
import { getPaymentTermById } from '../../../selectors/paymentTerms';
import { closeSideModal } from '../../../reducers/sideModals';
import { setSettings } from '../../../reducers/activeInvoice';
import { transform } from '../../forms/invoiceSettings/utils';
import { checkNumerationBlock } from '../../home/NewActiveInvoice/utils';

import CurrencyModal from '../../modals/currency/Currency'
import invoiceTypes from '../../countriesData/colombia/invoiceTypes'
import operationTypes from '../../countriesData/colombia/operationTypes'
import SideModal from '../../common/SideModal';
import Body from '../../forms/invoiceSettings/InvoiceSettings';
import Header from '../common/Header';
import Notification from '../common/Notification';
import Bottom from '../common/Bottom';

const InvoiceSettings = () => {
  const dispatch = useDispatch();
  const isOpen = useSelector(state => get(state, 'sideModals.invoiceSettings.isOpen', false));
  const country = useSelector(countrySelector);
  let initialValues = useSelector(settings);
  const isElectronic = useSelector(electronicInvoicing(initialValues.numeration));
  const mainPriceList = useSelector(getMainPriceList);
  const mainCurrency = useSelector(getMainCurrency);
  const mainPaymentTerm = useSelector(getPaymentTermById(1));
  const stationNumeration = useSelector(stationInvoiceNumeration);
  const economicActivity = useSelector(economyActivitySelector);
  const mainEconomicActivity = useSelector(mainEconomicActivitySelector);
  const { registryDate } = useSelector(companySelector);

  if (country === 'colombia' && isElectronic) {
    initialValues = {
      ...initialValues,
      type: invoiceTypes.find(el => el.key === 'NATIONAL'),
      operationType: operationTypes.find(el => el.key === 'STANDARD'),
      paymentTerm: mainPaymentTerm,
    }
  }

  const save = values => {

    const modifiedValues = transform(values, { country, isElectronic });

    try {
      dispatch(setSettings(modifiedValues))
      dispatch(closeSideModal({ sideModal: 'invoiceSettings' }))
    } catch {
      return { [FORM_ERROR]: I18n.get('editInvoiceSettingsError', 'hubo un error a la hora de cambiar las configuraciones') }
    }
  }

  return (
    <SideModal
      open={isOpen}
      onRequestClose={() => dispatch(closeSideModal({ sideModal: 'invoiceSettings' }))}
      destroyOnClose
    >
      <>
        <Form
          onSubmit={values => save(values)}
          validate={values => {
            let errors = {}
            const numeration = get(values, 'numeration')
            if (!!checkNumerationBlock(numeration, country, registryDate))
              errors.numerationError = true
            return errors
          }}
          initialValues={{
            ...initialValues,
            currency: !!initialValues.currency ? initialValues.currency : mainCurrency,
            priceList: !!initialValues.priceList ? initialValues.priceList : mainPriceList,
            numeration: !!initialValues.numeration ? initialValues.numeration : stationNumeration,
            economicActivity: economicActivity ? economicActivity : mainEconomicActivity
          }}
          keepDirtyOnReinitialize
        >
          {({ handleSubmit, values, form, submitting, submitError, errors }) => (
            <form
              noValidate
              onSubmit={handleSubmit}
              className="w-100 h-100 bg-white d-flex flex-column"
            >
              <Header
                title={I18n.get('invoiceSettings', 'configuración de venta')}
                onClose={() => dispatch(closeSideModal({ sideModal: 'invoiceSettings' }))}
              />

              <div className="side-modal__body">
                <Body values={values} form={form} errors={errors} />
              </div>

              <Notification
                isOpen={!!submitError}
                type="error"
                text={submitError}
              />

              <Bottom
                onClose={() => dispatch(closeSideModal({ sideModal: 'invoiceSettings' }))}
                disabled={submitting || errors?.numerationError}
                submitting={submitting}
              />
            </form>
          )}
        </Form>

        <CurrencyModal onCreated={(currency) => dispatch(setSettings({ currency }))} />
      </>
    </SideModal>
  )
}

export default InvoiceSettings;
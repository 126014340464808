import React from 'react';
import { Field } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays'
import { I18n } from '@aws-amplify/core';
import { capitalize, get } from 'lodash';

import { renderField } from '../fields/V0/Fields';

import { Icon } from '@alegradev/smile-ui-react';
import { IconPlus, IconX } from '@tabler/icons-react';

const Variant = ({ form, values }) => {

  const addOption = () => {
    form.mutators.push("options")
  }

  return (
    <div className="form-body h-100 p-4 overflow-hidden">
      <div className="form-body__fields form-row justify-content-start">
        <Field
          name="name"
          className="col-md-6"
          component={renderField}
          type="text"
          label={capitalize(I18n.get('name', 'Nombre'))}
          required
        />

        <div className="col-md-6">
          <div className="form-group">
            <div className="form-group__label">
              <div className="label-wrapper d-flex align-items-center">
                <label className="h3">
                  {I18n.get('options', 'Opciones')}
                </label>
              </div>
            </div>

            <FieldArray name="options">
              {({ fields }) =>
                fields.map((name, index) => {
                  return (
                    <div key={index} className="form-row align-items-start">
                      <Field
                        name={`${name}.value`}
                        className="col-10"
                        component={renderField}
                        disabled={!(get(values, `${name}.editable`) == null)}
                        type="text"
                      />

                      <button
                        type="button"
                        className="btn button-transparent col-2 mb-3"
                        disabled={fields.length <= 1}
                        style={{ height: '3.6rem', outline: 'none', boxShadow: 'none', display: (values?.options?.length > 1 && (get(values, `${name}.editable`) == null) )? 'inline' : 'none' }}
                        onClick={() => fields.remove(index)}
                      >
                        <Icon icon={IconX} extraClass="icon-secondary" />
                      </button>
                    </div>
                  )
                })
              }
            </FieldArray>

          </div>

        </div>
      </div>

      <div className="col-12 text-right" style={{ maxWidth: '56rem' }}>
        <button
          type="button"
          className="btn btn-secondary mb-3 primary"
          style={{ border: "none", outline: "none", boxShadow: "none" }}
          onClick={() => addOption()}
        >
          <Icon icon={IconPlus} extraClass="icon icon-primary mr-2" />
          {capitalize(I18n.get('addOption', 'agregar opción'))}
        </button>
      </div>
    </div>
  )
}

export default Variant;
import React, { useCallback } from 'react';
import { I18n } from '@aws-amplify/core';
import { useDispatch, useSelector } from 'react-redux';
import { get, upperCase } from 'lodash';
import {
  isPaymentMethodSelected,
  paymentMethod,
} from '../../../../selectors/activeInvoice';
import { useDeviceType } from '../../../../hooks/useDeviceType';
import { Icon, Typography, useModal } from '@alegradev/smile-ui-react';
import {
  setIsPaymentMethodSelected,
  setPaymentMethod,
} from '../../../../reducers/activeInvoice';
import { IconSwitchVertical } from '@tabler/icons-react';
import { useFormat } from '../../../../hooks/useFormat';

const NewTotal = ({ form, values, total }) => {
  const isPaymentSelected = useSelector(isPaymentMethodSelected);
  const paymentMethodSelected = useSelector(paymentMethod);

  const { updateModal } = useModal();
  const { decimal, fmt } = useFormat();

  const dispatch = useDispatch();

  const deviceType = useDeviceType();

  const handlePaymentChange = useCallback(() => {
    form.change('method', '');
    form.reset();
    if (paymentMethodSelected) {
      form.change(paymentMethodSelected, null);
    }
    dispatch(setPaymentMethod(null));
    dispatch(setIsPaymentMethodSelected(false));
  }, [form, paymentMethodSelected, updateModal, dispatch, total]);

  return (
    <div className='d-flex justify-content-center p-3 position-relative '>
      <div className='d-flex flex-column align-items-center modal__invoice-total gap-3'>
        <div className='modal__invoice-total-title'>
          <Typography
            text={upperCase(I18n.get('total', 'total'))}
            type='body-3-bold'
            variant='placeholder'
          />
        </div>
        <div className='modal__invoice-total-amount'>
          <Typography
            text={total.toFormat(decimal, fmt)}
            type={'heading-3'}
            variant='secondary'
          />
        </div>
      </div>
      {get(values, 'method') && isPaymentSelected && (
        <button
          className={`change-payment-btn ${deviceType === 'mobile' ? 'mobile' : ''}`}
          onClick={handlePaymentChange}
        >
          <Icon icon={IconSwitchVertical} size='small' color='#30ABA9' />
          <Typography
            text={I18n.get('changeMethod', 'Cambiar método')}
            type={deviceType === 'mobile' ? 'caption-regular' : 'body-3-bold'}
            variant='success'
          />
        </button>
      )}
    </div>
  );
};

export default NewTotal;

import { useMemo } from 'react';

const useCountryOptions = (countries, searchValue) => {
  return useMemo(() => {
    if (searchValue) {
      const lowercasedSearchValue = searchValue.toLowerCase();

      const filteredCountriesByName = countries.filter((country) =>
        country.value.toLowerCase().includes(lowercasedSearchValue)
      );

      const filteredCountriesByCode = countries.filter((country) =>
        country.dialCode.toLowerCase().includes(lowercasedSearchValue)
      );

      if (filteredCountriesByName.length > 0) {
        return filteredCountriesByName;
      } else if (filteredCountriesByCode.length > 0) {
        return filteredCountriesByCode;
      }

      return [
        {
          value: '',
          dialCode: '',
        },
      ];
    }

    return countries;
  }, [countries, searchValue]);
};

export default useCountryOptions;

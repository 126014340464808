const newCreditNoteTypes = [
  {
    id: 1,
    value: "Diferencias",
    key: "DIFFERENCE"
  },
]


export default newCreditNoteTypes;

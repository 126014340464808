import BigNumber from 'bignumber.js';
import { calculateInvoiceReceived } from '../util';

const useInvoiceChange = (values, total) => {
  const cash = new BigNumber(!!values.cash ? +values.cash : 0);
  const debit = new BigNumber(!!values.debit ? +values.debit : 0);
  const credit = new BigNumber(!!values.credit ? +values.credit : 0);
  const transfer = new BigNumber(!!values.transfer ? +values.transfer : 0);

  let received = calculateInvoiceReceived(values);

  const payment = cash.plus(debit).plus(credit).plus(transfer);
  const change = total.minus(payment);

  return { payment, change, received };
};

export default useInvoiceChange;

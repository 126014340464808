import React from 'react';
import { Field } from 'react-final-form';
import { I18n } from '@aws-amplify/core';
import { capitalize } from 'lodash';
import { useSelector } from 'react-redux'

import { hasPermissionTo } from '../../../../selectors/auth'
import { activeBanks } from '../../../../selectors/banks'
import { renderSelect } from '../../fields/V0/Fields';
import { renderField } from '../../fields/V2';
import { country as countrySelector, decimalPrecision, decimalSeparator } from '../../../../selectors/company';
import { useDecimalsVersionsGroup } from '../../../../hooks/useDecimalsVersionsGroup';
import { preventNotNumberKeys } from '../../../../utils/keyDown';
import { countryConfig } from '../../../../utils/format-money/countryConfig';

const Card = ({ form, values, total }) => {
  const can = useSelector(hasPermissionTo)
  const banks = useSelector(activeBanks)
  const decimal = useSelector(decimalPrecision);
  const { isDecimalActive } = useDecimalsVersionsGroup();
  const country = useSelector(countrySelector)
  const separator = useSelector(decimalSeparator);

  if (!form || !values) return null;
  const { method } = values;

  return (
    <>
      <Field
        name={values.method}
        fieldType="decimal"
        placeholder={countryConfig[country]?.currencyPrefix || ''}
        decimals={isDecimalActive ? decimal : null}
        separator={separator}
        label={capitalize(I18n.get(values.method === 'debit' ? 'debitCardSaleModal' : 'creditCard', values.method))}
        onDecimalsError={(status) => form?.change('decimalsError', {
          ...values?.decimalsError,
          debit: status,
        })}
        required
        onKeyDown={preventNotNumberKeys}
      >
        {props => (
          <>
            <div className="col-md-12">{renderField(props)}</div>

            <div className="form__invoice-divider position-relative justify-content-center d-flex w-100 my-3">
              <div className="form__invoice-divider-line">
                <div className="border-bottom" />
              </div>
              <p className="form__invoice-divider-text text-muted h5 text-center text-uppercase">
                {I18n.get('cardType', 'tipo de tarjeta')}
              </p>
            </div>

            <div className="btn-group col-12 mb-3" role="group">
              <button
                type="button"
                data-testid="card-debit-method-btn"
                className={`btn text-uppercase btn-generic
                  ${method === 'debit' ? 'text-white bg-primary border-primary' : 'text-primary bg-white border-primary'}`}
                onClick={() => {
                  if (values.method !== 'debit') {
                    form.change('method', 'debit')
                    form.change('debit', total)
                    form.change('credit', null)
                  }
                }}
              >
                {I18n.get('debit', 'débito')}
              </button>
              <button
                type="button"
                data-testid="card-credit-method-btn"
                className={`btn text-uppercase btn-generic
                  ${method === 'credit' ? 'text-white bg-primary border-primary' : 'text-primary bg-white border-primary'}`}
                onClick={() => {
                  if (values.method !== 'credit') {
                    form.change('method', 'credit')
                    form.change('credit', total)
                    form.change('debit', null)
                  }
                }}
              >
                {I18n.get('credit', 'crédito')}
              </button>
            </div>
          </>
        )}
      </Field>
      
      <Field
        name={`${values.method}Bank`}
        className="col-md-12"
        component={renderSelect}
        options={!can('index', 'bank-accounts') || !can('view', 'bank-accounts') ? [] : banks}
        selectInfo={!can('index', 'bank-accounts') || !can('view', 'bank-accounts')
          ? I18n.get('userNotAllowed.bank-accounts.view', 'no tienes permisos para ver la información de las cuentas de bancos')
          : null}
        hideNoOptionMessage={!can('index', 'bank-accounts') || !can('view', 'bank-accounts')}
        getOptionLabel={option => option.name}
        getOptionValue={option => option.id}
        menuPosition="absolute"
      />
    </>
  )
}

export default Card;
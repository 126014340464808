import { useEffect } from 'react';

const useShowToast = (options) => {
  const { isPaused, isSuccess, onPaused, onSuccess } = options;

  useEffect(() => {
    if (isPaused && typeof onPaused === 'function') {
      onPaused();
    }

    if (isSuccess && typeof onSuccess === 'function') {
      onSuccess();
    }
    // eslint-disable-next-line
  }, [isPaused, isSuccess]);
};

export default useShowToast;
export const Bubble = ({ className }) => (
  <svg className={className} width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="31.9996" height="31.9996" rx="15.9998" transform="matrix(1 0 -1.30562e-05 -1 0.000488281 32)" fill="#30ABA9"/>
    <g clipPath="url(#clip0_557_15793)">
    <path d="M10 21.25C10 21.6478 10.158 22.0294 10.4393 22.3107C10.7206 22.592 11.1022 22.75 11.5 22.75C11.8978 22.75 12.2794 22.592 12.5607 22.3107C12.842 22.0294 13 21.6478 13 21.25C13 20.8522 12.842 20.4706 12.5607 20.1893C12.2794 19.908 11.8978 19.75 11.5 19.75C11.1022 19.75 10.7206 19.908 10.4393 20.1893C10.158 20.4706 10 20.8522 10 21.25Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M16.75 19.75H11.5V9.25H10" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M11.5 10.75L22 11.5L21.5687 14.5165M17.875 16.75H11.5" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M22.75 18.25H20.875C20.5766 18.25 20.2905 18.3685 20.0795 18.5795C19.8685 18.7905 19.75 19.0766 19.75 19.375C19.75 19.6734 19.8685 19.9595 20.0795 20.1705C20.2905 20.3815 20.5766 20.5 20.875 20.5H21.625C21.9234 20.5 22.2095 20.6185 22.4205 20.8295C22.6315 21.0405 22.75 21.3266 22.75 21.625C22.75 21.9234 22.6315 22.2095 22.4205 22.4205C22.2095 22.6315 21.9234 22.75 21.625 22.75H19.75" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M21.25 22.75V23.5M21.25 17.5V18.25" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </g>
    <defs>
    <clipPath id="clip0_557_15793">
    <rect width="18" height="18" fill="white" transform="translate(7 7)"/>
    </clipPath>
    </defs>
  </svg>
);
import { I18n } from "aws-amplify";
import { COUNTRIES } from "../../utils/enums/countries";
import { BADGE_STATUSES, LEGAL_STATUS_KEYS } from "../../utils/enums/legalStatus";
import { DOCUMENT_TYPES } from "../../utils/enums/documentTypes";
import { getLegalStatusKey } from "../invoices/utils";
import colombiaCreditNoteTypes from '../countriesData/colombia/creditNoteTypes';
import spainCreditNoteTypes from '../countriesData/spain/creditNoteTypes';
import adjustmentNoteTypes from '../countriesData/colombia/adjustmentNote';
import dayjs from "dayjs";
import { REFUND_METHODS } from "../../utils/enums/refundMethods";
import { get } from "lodash";

/**
 * Maps legal status keys to badge statuses.
 *
 * @param {string} status - The legal status key.
 * @param {string} country - The country code.
 * @returns {string} - The corresponding badge status.
 */
export const legalStatusToColor = (status) => {
  const statusMap = {
    [LEGAL_STATUS_KEYS.ISSUED]: BADGE_STATUSES.CLOSED,
    [LEGAL_STATUS_KEYS.ISSUED_ALTER]: BADGE_STATUSES.CLOSED,
    [LEGAL_STATUS_KEYS.REJECTED]: BADGE_STATUSES.OPEN,
    [LEGAL_STATUS_KEYS.TO_BE_ISSUED]: BADGE_STATUSES.VOID,
    [LEGAL_STATUS_KEYS.IN_PROCESS]: BADGE_STATUSES.PROCESS,
  };

  return statusMap[status] || BADGE_STATUSES.VOID;
}

/**
 * Configuration mapping for credit note types based on country.
 *  
 * @type {Object}
  */
const creditNoteTypesCountryMap = {
  [COUNTRIES.COLOMBIA]: colombiaCreditNoteTypes,
  [COUNTRIES.SPAIN]: spainCreditNoteTypes,
};

/**
 * List of countries where new refunds are available.
 */
const NEW_REFUNDS_AVAILABLE_COUNTRIES = [COUNTRIES.COLOMBIA, COUNTRIES.SPAIN];

/**
 * 
 * @param {string} country - The country code.
 * @param {Object} company - The company object.
 * @returns {boolean} - Whether the user is elegible for a new refund.
 */
export const isUserElegibleForNewRefund = (country, company) => {
  if (!NEW_REFUNDS_AVAILABLE_COUNTRIES.includes(country)) {
    return false;
  }

  return true;
};

/**
 * Generates the display number for a refund based on its number template.
 *
 * @param {Object} refund - The refund object containing numberTemplate.
 * @returns {string} - The formatted number string.
 */
export const getRefundNumber = (refund) => {
  if (!refund || !refund.numberTemplate) {
    return I18n.get('number', 'número');
  }

  const valuePrefix = I18n.get('number', 'número');
  const number = refund.numberTemplate.number || '';
  const fullNumber = refund.numberTemplate.fullNumber || '';
  const prefix = refund.numberTemplate.prefix || '';

  if (fullNumber) {
    return `${valuePrefix} ${fullNumber}`;
  }

  return `${valuePrefix} ${prefix}${number}`;
};

/**
 * Generates the display number for a refund based on its number template.
 *
 * @param {Object} refund - The refund object containing numberTemplate.
 * @returns {string} - The formatted number string.
 */
export const getNumber = (refund) => {
  const valuePrefix = I18n.get('number', 'número');

  const number = refund?.numberTemplate?.number || '';
  const fullNumber = refund?.numberTemplate?.fullNumber || '';
  const prefix = refund?.numberTemplate?.prefix || '';

  if (fullNumber) {
    return `${valuePrefix} ${fullNumber}`;
  }

  return `${valuePrefix} ${prefix}${number}`;
};

/**
 * Configuration mapping for refund titles based on country and document type.
 */
const REFUND_TITLES_CONFIG = {
  [COUNTRIES.COLOMBIA]: {
    [DOCUMENT_TYPES.CREDIT_NOTE]: 'refund.creditNote',
    [DOCUMENT_TYPES.INCOME_ADJUSTMENT_NOTE]: 'refund.adjustmentNote',
  },
  [COUNTRIES.SPAIN]: {
    [DOCUMENT_TYPES.CREDIT_NOTE]: 'refund.rectificativeInvoice',
  },
};

/**
 * Determines the title of the refund based on its document type and country.
 *
 * @param {Object} refund - The refund object containing numberTemplate.
 * @param {string} country - The country code (e.g., 'CO', 'US', 'MX').
 * @returns {string} - The localized title string.
 */
export const getRefundTitle = (refund, country) => {
  const documentType = refund?.numberTemplate?.documentType;
  const countryConfig = REFUND_TITLES_CONFIG[country];

  if (!countryConfig || !documentType) {
    return I18n.get('refund', 'Devolución');
  }

  const titleKey = countryConfig[documentType];

  if (!titleKey) {
    return I18n.get('refund', 'Devolución');
  }

  return I18n.get(titleKey, 'Nota de Crédito');
};

/**
 * Parses the item reference from the refund item.
 *
 * @param {Object} item - The refund item.
 * @returns {string} - The parsed reference or an empty string.
 */
export const getItemReference = (item) => {
  try {
    if (item?.reference?.reference) {
      return JSON.parse(item.reference.reference);
    }
    if (item?.reference) {
      return JSON.parse(item.reference);
    }
    return '';
  } catch {
    return '';
  }
};

/**
 * Determines the label of the refund type based on document type, refund type and country.
 *
 * @param {Object} refund - The refund object.
 * @param {string} country - The country code.
 * @returns {string} - The localized refund type label.
 */
export const getRefundTypeLabel = (refund, country) => {
  const creditNoteTypes = creditNoteTypesCountryMap[country];

  const isAdjustNote = refund?.numberTemplate?.documentType === 'incomeAdjustmentNote';
  let refundType;

  if (isAdjustNote) {
    refundType = adjustmentNoteTypes.find((type) => type.key === refund.type);
  } else {
    refundType = creditNoteTypes.find((type) => type.key === refund.type);
  }

  return refundType ? I18n.get(refundType.value, refundType.value) : I18n.get('refund.creditNote', 'Credit Note');
};

/**
 * Selects the status color based on the refund's legal status.
 *
 * @param {Object} refund - The refund object.
 * @returns {string} - The CSS class for the status color.
 */
export const selectStatusColor = (refund) => {
  const legalStatusKey = getLegalStatusKey(refund);
  return legalStatusToColor(legalStatusKey, 'colombia');
};

/**
 * Retrieves the legal status of the refund.
 *
 * @param {Object} refund - The refund object.
 * @returns {string} - The legal status key or a default value.
 */
export const getLegalStatus = (refund) => {
  const legalStatus = getLegalStatusKey(refund);
  return legalStatus || 'legalStatus.toBeIssued';
};

/**
 * Formats a given date string based on the provided date format.
 *
 * @param {string} date - The date string to format.
 * @param {string|null|undefined} dateFormat - The date format string. If null or undefined, use default.
 * @param {string} [timeFormat='h:mm a'] - The time format string.
 * @returns {string} - The formatted date string.
 */
export const formatDate = (date, dateFormat, timeFormat = 'h:mm a') => {
  const format = dateFormat
    ? `${dateFormat.toUpperCase()} ${timeFormat}`
    : I18n.get('dateTimeFormat', 'DD/MM/YYYY h:mm a');
  return dayjs(date).format(format);
};

/**
 * Downloads a file from a given source.
 *  
 * @param {string} source - The source URL.
 * @param {string} fileName - The name of the file.
 */
export const downloadFile = async (source, fileName) => {
  try {
    const response = await fetch(source, {
      method: 'GET',
      headers: {
        'Accept': 'application/json, text/plain, */*',
        'Accept-Language': 'en-US,en;q=0.5',
      },
    });

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    const blob = await response.blob();
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    window.URL.revokeObjectURL(url);
    document.body.removeChild(link);
  } catch (error) {
    console.error('There was a problem with the fetch operation:', error);
  }
};

/**
 * Determines the default refund method based on country and invoice.
 *
 * @param {string} country - The country code.
 * @param {Object} invoice - The invoice object.
 * @returns {string} - The refund method.
 */
export const getVoidDefaultMethod = (country, isTicketBaiEnabled) => {
  if (country === COUNTRIES.SPAIN) {
    if (!isTicketBaiEnabled) {
      return REFUND_METHODS.NO_ELECTRONIC_SIMPLIFIED_INVOICE
    }
    return REFUND_METHODS.RECTIFICATIVE_IN_SIMPLIFIED_INVOICE
  }
  return REFUND_METHODS.CREDIT_TO_SALES;
};

// eslint-disable-next-line
export default ({ id, className, width = 16, height = 16, mobile = false }) => (
  <svg
    id={id}
    className={className}
    width={width}
    height={height}
    viewBox='0 0 16 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M8.31328 3.64657C8.50854 3.45131 8.82512 3.45131 9.02038 3.64657L13.0204 7.64657C13.2156 7.84183 13.2156 8.15841 13.0204 8.35368L9.02038 12.3537C8.82512 12.5489 8.50854 12.5489 8.31328 12.3537C8.11801 12.1584 8.11801 11.8418 8.31328 11.6466L11.4597 8.50012H3.3335C3.05735 8.50012 2.8335 8.27626 2.8335 8.00012C2.8335 7.72398 3.05735 7.50012 3.3335 7.50012H11.4597L8.31328 4.35368C8.11801 4.15841 8.11801 3.84183 8.31328 3.64657Z'
      fill={mobile ? '#00b19d' : '#0F172A'}
      
    />
  </svg>
);

import { Icon, Typography } from '@alegradev/smile-ui-react';
import { IconTransferIn } from '@tabler/icons-react';
import { get } from 'lodash';
import { Field } from 'react-final-form';
import { renderField } from '../../fields/V0/Fields';
import BankField from '../components/BankField';
import UseField from '../components/UseField';
import SellerField from '../components/SellerField';
import AnnotationField from '../components/AnnotationField';

export const CustomPaymentMethodQrBody = ({
  paymentMethod,
  total,
  values,
  anotationMaxLimit,
}) => {
  return (
    <>
      <div className='form-row'>
        <div className='modal__invoice-custom-payment-instruction col-md-12'>
          <Typography
            type='body-3-bold'
            text={get(paymentMethod, 'instructions', null)}
          />
        </div>
        <div className='col-md-4 d-flex justify-content-center'>
          {get(paymentMethod, 'qr.url', null) ? (
            <img
              src={get(paymentMethod, 'qr.url', null)}
              height={192}
              width={192}
            />
          ) : (
            <div className='d-flex justify-content-center align-items-center'>
              <Icon icon={IconTransferIn} size='huge' />
            </div>
          )}
        </div>
        <div className='col-md-8'>
          <div className='col-md-12 p-0'>
            <Field
              name={'transfer'}
              className='col-md-12 p-0 mt-3'
              defaultValue={total}
              type='number'
              label={
                <Typography
                  type='label-2'
                  text={'Valor del pago en ' + get(paymentMethod, 'name', '')}
                  variant='secondary'
                />
              }
              required
            >
              {(props) => (
                <>
                  {renderField({
                    ...props,
                    fontSize: '16px',
                    height: '46px',
                    fontColor: '#334155',
                  })}
                </>
              )}
            </Field>

            <BankField name='transferBank' />
          </div>
        </div>
      </div>

      <UseField values={values} />
      <div className='d-flex justify-content-center mt-4'>
        <SellerField />
      </div>
      <div className='col-sm-12 pl-2 pr-0'>
        <AnnotationField anotationMaxLimit={anotationMaxLimit} type='text' />
      </div>
    </>
  );
};

export default CustomPaymentMethodQrBody;

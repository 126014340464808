// DetailHeader.jsx

import React, { useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { I18n } from '@aws-amplify/core';

import { openModal } from '../../reducers/modals';
import { hasPermissionTo } from '../../selectors/auth';
import { companySelector, country as countrySelector } from '../../selectors/company';
import { getRefundNumber, isUserElegibleForNewRefund } from './utils';
import { Tooltip, Icon } from '@alegradev/smile-ui-react';
import { IconList } from '@tabler/icons-react';

/**
 * DetailHeader Component
 *
 * Displays detailed information about a refund along with action buttons for creating a new refund and switching to list view.
 *
 * @component
 * @param {Object} props
 * @param {Object} props.refund - The refund object containing details like numberTemplate.
 * @param {Function} props.onListView - Function to switch to list view.
 * @returns {JSX.Element|null} The rendered component or null if no refund is provided.
 */
const DetailHeader = ({ refund, onListView }) => {
  const dispatch = useDispatch();
  const can = useSelector(hasPermissionTo);
  const country = useSelector(countrySelector);
  const company = useSelector(companySelector);

  const displayNumber = useMemo(() => {
    return getRefundNumber(refund);
  }, [refund]);

  // Memoize the modal opening handler
  const handleOpenModal = useCallback(() => {
    if (isUserElegibleForNewRefund(country, company)) {
      dispatch(openModal({ modal: 'newRefunds' }));
    } else {
      dispatch(openModal({ modal: 'refunds' }));
    }
  }, [country, company, dispatch]);

  if (!refund) return null;

  return (
    <div className="px-4 pt-4 d-flex justify-content-between align-items-center">
      <h2 className="title text-capitalize-first">
        {displayNumber}
      </h2>
      <div className="actions d-flex align-items-center">
        <div className="w-100 h-25 d-flex justify-content-between align-items-center">
          <Tooltip
            visible={!can('add', 'credit-notes')}
            overlay={I18n.get('userNotAllowed.credit-notes.add', 'no tienes permisos para agregar devoluciones')}
          >
            <button
              type="button"
              disabled={!can('add', 'credit-notes')}
              className="btn btn-submit d-none d-sm-block"
              onClick={handleOpenModal}
            >
              {I18n.get('newRefund', 'nueva devolución')}
            </button>
          </Tooltip>

          <button
            type="button"
            className="btn btn-submit d-block d-sm-none"
            onClick={onListView}
          >
            <Icon icon={IconList} extraClass="icon-white" />
          </button>
        </div>
      </div>
    </div>
  );
};

DetailHeader.propTypes = {
  refund: PropTypes.object,
  onListView: PropTypes.func.isRequired,
};

export default React.memo(DetailHeader);

import React from 'react';
// import { I18n } from '@aws-amplify/core';

import Modal from '../../common/Modal';
import Bottom from './Bottom';

const DeleteInventoryAdjustmentConfirmation = ({ isOpen, onRequestClose, onConfirm, body,  submitText, modalTitle}) => {
  return (
    <Modal
      title={modalTitle}
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      className="modal__confirmation"
      hideClose
    >
      {body}
      <Bottom
        onClose={onRequestClose}
        onConfirm={onConfirm}
        submitText={submitText}
        hideRequired
      />
    </Modal>
  )
}

export default DeleteInventoryAdjustmentConfirmation;
import React, { useState, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useFormState } from 'react-final-form';
import { I18n } from 'aws-amplify';
import BigNumber from 'bignumber.js';
import { v4 as uuidv4 } from 'uuid';

import Button from '../../../common/Button';
import Item from './Item';
import Summary from './Summary';
import Search from '../../../modals/refunds/SearchItems/Search';
import SearchableItems from './SearchableItems';

import { Icon } from '@alegradev/smile-ui-react';
import { useFormat } from '../../../../hooks/useFormat';

import {
  isSelectingItems,
  items as itemsSelector,
} from '../../../../selectors/activeRefund';
import {
  setIsSelectingItems,
} from '../../../../reducers/activeRefund';

import usePopulateItems from './usePopulateItems';
import { idCompanySelector } from '../../../../selectors/company';
import { userSelector } from '../../../../selectors/auth';
import { IconArrowBarLeft, IconLoader2, IconPlus } from '@tabler/icons-react';

/**
 * ItemsForm Component
 *
 * Manages the selection and display of items in the refund process.
 *
 * @param {Object} props - Component properties.
 * @param {Function} props.changeStep - Function to change the current step.
 * @param {Function} props.openEditItem - Function to open the edit item modal.
 * @param {Function} props.openEditTip - Function to open the edit tip modal.
 *
 * @returns {JSX.Element} The rendered component.
 */
const ItemsForm = React.memo(({ changeStep, openEditItem, openEditTip }) => {
  const [loadingItems, setLoadingItems] = useState(false);
  const dispatch = useDispatch();

  // Selectors
  const isSelectingMode = useSelector(isSelectingItems);
  const invoiceSelected = useSelector(
    (state) => state.modals.newRefunds.params.invoice
  );
  const itemsInRefund = useSelector(itemsSelector);
  const idCompany = useSelector(idCompanySelector);
  const user = useSelector(userSelector);
  const { values } = useFormState();
  const { decimal, fmt } = useFormat();

  // Derived values
  const devolutionType = values?.refund?.method?.value;
  const outstandingInvoiceAmount = useMemo(() => {
    const balance = values?.refund?.document?.balance;
    return balance
      ? new BigNumber(balance).toFormat(decimal, fmt)
      : '0.00';
  }, [values, decimal, fmt]);

  // Custom Hook to handle population of items from invoice
  usePopulateItems({
    itemsInRefund,
    invoiceSelected,
    devolutionType,
    values,
    dispatch,
    setLoadingItems,
    idCompany,
    user,
  });

  /**
   * Handles the back action when in selecting mode.
   */
  const handleBack = useCallback(() => {
    if (!itemsInRefund.length) {
      changeStep(2);
    } else {
      dispatch(setIsSelectingItems(false));
    }
  }, [changeStep, dispatch, itemsInRefund.length]);

  /**
   * Handles adding items by exiting selecting mode.
   */
  const handleAddItems = useCallback(() => {
    dispatch(setIsSelectingItems(false));
  }, [dispatch]);

  /**
   * Deduplicates itemsInRefund to ensure unique keys.
   */
  const uniqueItemsInRefund = useMemo(() => {
    const seen = new Set();
    return itemsInRefund.filter((item) => {
      const identifier = item.id || item.uniqueKey;
      if (seen.has(identifier)) {
        return false;
      }
      seen.add(identifier);
      return true;
    });
  }, [itemsInRefund]);

  return (
    <div className='new-refund-items-form'>
      <p className='items-form_subtitle'>
        {devolutionType === 'creditToSales'
          ? I18n.get(
            'itemsToAddInDevolution.creditToSales',
            'Productos que se incluirán en la devolución de tu cliente'
          )
          : I18n.get(
            'itemsToAddInDevolution',
            'Elige los productos del documento asociado que devuelve el cliente'
          )}
      </p>
      <div className='items-form_items-container'>
        <div className='d-flex flex-column justify-content-between items-container-header'>
          <p className='items-container-header_title mb-0'>
            {values?.refund?.client?.name}
          </p>
          {devolutionType === 'creditToSales' && outstandingInvoiceAmount && (
            <div className='d-flex flex-column mt-4'>
              <p className='items-form_items-caption p-0 mb-md-1'>
                {I18n.get(
                  'associatedDocument',
                  'Documento asociado'
                )}:{' '}
                {values?.refund?.document?.numberTemplate?.fullNumber}
              </p>
              <p className='items-form_items-caption p-0'>
                {I18n.get(
                  'outstandingInvoiceAmount',
                  'Pendiente por cobrar'
                )}:{' '}
                {outstandingInvoiceAmount}
              </p>
            </div>
          )}
        </div>
        {isSelectingMode ? (
          <>
            <Search newRefund />
            <SearchableItems />
          </>
        ) : (
          <>
            <p className='items-form_items-caption font-weight-bold'>
              {I18n.get('productsToRefund', 'Productos a devolver')}
            </p>
            <div
              className='d-flex flex-column justify-content-between h-100'
              style={{ position: 'relative', height: '100%' }}
            >
              <section className='items-list'>
                {!loadingItems &&
                  uniqueItemsInRefund.map((item, index) => (
                    <Item
                      key={index}
                      index={index}
                      item={item}
                      openEditItem={openEditItem}
                    />
                  ))}

                {loadingItems && (
                  <div className='d-flex w-100 justify-content-center'>
                    <Icon icon={IconLoader2} animated extraClass=' icon-primary icon x4' />
                  </div>
                )}

                {!loadingItems && (
                  <button
                    className='btn-md-default-filled'
                    onClick={() => dispatch(setIsSelectingItems(true))}
                  >
                    <Icon icon={IconPlus} extraClass="icon-white" width={16} height={16} />
                    {I18n.get('addItems', 'Agregar productos')}
                  </button>
                )}
              </section>
              <section className='items-summary'>
                <Summary openEditTip={openEditTip} />
              </section>
            </div>
          </>
        )}
      </div>
      {isSelectingMode && (
        <div className='d-flex flex-end justify-content-between mt-3 bg-white'>
          <button
            type='button'
            className='refunds-cancel-button'
            style={{ border: 'none', fontWeight: '500' }}
            onClick={handleBack}
          >
            <Icon icon={IconArrowBarLeft} extraClass="icon-black mr-2" />
            {I18n.get('goBack', 'Volver')}
          </button>
          <Button
            className='ml-4'
            disabled={!itemsInRefund.length}
            type='button'
            onClick={handleAddItems}
          >
            {I18n.get('addItems', 'Agregar productos')}
          </Button>
        </div>
      )}
    </div>
  );
});

ItemsForm.propTypes = {
  changeStep: PropTypes.func.isRequired,
  openEditItem: PropTypes.func.isRequired,
  openEditTip: PropTypes.func.isRequired,
};

export default ItemsForm;

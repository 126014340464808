import alegraAPI from '../reducers/alegraAPI';
import { get } from 'lodash';
import { queryClient } from '../utils/queryClient';

export const CategoriesKeys = {
  all: 'categories',
  allAccounting: 'accountingCategories',
};

const fetchCategories = async (filters) => {
  const response = await alegraAPI.get("/categories", { ...filters });

  return get(response, 'data', null);
}

const fetchAccountingCategories = async () => {
  const response = await alegraAPI.get("/categories/accounting/settings");
  return get(response, 'data', null);
}

export const fetchCategoriesQuery = (filters) => {
  return queryClient.fetchQuery({
    queryKey: [CategoriesKeys.all, filters ?? {}],
    queryFn: () => fetchCategories(filters),
    keepPreviousData: true,
    staleTime: 1000 * 60 * 60 * 24,
    refetchOnReconnect: "always",
    retry: 3,
    retryDelay: 2000,
  });
};

export const fetchAccountingCategoriesQuery = () => {
  return queryClient.fetchQuery({
    queryKey: [CategoriesKeys.allAccounting],
    queryFn: () => fetchAccountingCategories(),
    keepPreviousData: true,
    staleTime: 1000 * 60 * 60 * 24,
    refetchOnReconnect: "always",
    retry: 3,
    retryDelay: 2000,
  });
};
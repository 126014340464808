function AlegraPosLogo({ id, className }) {
  return (
    <svg
      id={id}
      className={className}
      width='480'
      height='240'
      viewBox='0 0 480 240'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M105.914 44C96.9923 44 89.76 51.2247 89.76 60.1367V123.213H150.24V60.1367C150.24 51.2247 143.008 44 134.086 44H105.914Z'
        fill='#00D6BC'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M66.8215 84.2701C62.3608 84.2701 58.7446 87.8825 58.7446 92.3385V106.862H52.1538C43.2323 106.862 36 114.086 36 122.998V177.863C36 186.775 43.2323 194 52.1538 194H187.846C196.768 194 204 186.775 204 177.863V122.998C204 114.086 196.768 106.862 187.846 106.862H181.255V92.3385C181.255 87.8825 177.639 84.2701 173.178 84.2701C168.718 84.2701 165.102 87.8825 165.102 92.3385V122.245C165.102 131.157 157.869 138.382 148.948 138.382H91.0523C82.1308 138.382 74.8985 131.157 74.8985 122.245V92.3385C74.8985 87.8825 71.2823 84.2701 66.8215 84.2701ZM96.48 155.236C90.913 155.236 86.4 159.763 86.4 165.348C86.4 170.933 90.913 175.461 96.48 175.461H143.52C149.087 175.461 153.6 170.933 153.6 165.348C153.6 159.763 149.087 155.236 143.52 155.236H96.48Z'
        fill='#00D6BC'
      />
      <path
        d='M272.748 93.874C263.08 93.874 256 86.0756 256 76.5294C256 67.1176 263.625 59.3193 273.769 59.3193C283.845 59.3193 291.538 66.7143 291.538 76.5294V93.0672H285.139V87.2857C282.62 91.3193 278.194 93.874 272.748 93.874ZM273.769 87.5546C280.033 87.5546 284.798 82.5798 284.798 76.5966C284.798 70.5462 280.033 65.5714 273.769 65.5714C267.574 65.5714 262.74 70.5462 262.74 76.5966C262.74 82.5798 267.574 87.5546 273.769 87.5546Z'
        fill='#64748B'
      />
      <path d='M300.512 45H307.252V93.0672H300.512V45Z' fill='#64748B' />
      <path
        d='M331.78 93.8067C321.636 93.8067 314.079 86.5462 314.079 76.395C314.079 66.916 320.955 59.1849 330.963 59.1849C341.039 59.1849 347.303 66.3782 347.303 75.5882V78.2773H320.615C321.364 83.9244 325.721 87.8908 331.712 87.8908C335.797 87.8908 339.473 86.1429 341.448 82.5126L346.622 85.1345C343.694 90.7815 338.316 93.8067 331.78 93.8067ZM320.887 73.9748H340.563C340.29 68.7311 336.41 65.1681 330.895 65.1681C325.244 65.1681 321.568 69.0672 320.887 73.9748Z'
        fill='#64748B'
      />
      <path
        d='M368.353 109C361.136 109 355.145 105.908 352.082 100.059L357.732 97.0336C359.707 100.597 363.383 102.882 368.217 102.882C375.229 102.882 379.995 97.9076 379.995 90.1765V87.5546C377.816 90.9832 373.732 93.5378 368.149 93.5378C358.686 93.5378 351.197 86.1429 351.197 76.4622C351.197 67.0504 358.822 59.3193 368.966 59.3193C379.042 59.3193 386.735 66.7143 386.735 76.5294V90.1092C386.735 101.538 378.974 109 368.353 109ZM368.898 87.2857C375.025 87.2857 379.927 82.4454 379.927 76.395C379.927 70.3445 375.025 65.6387 368.898 65.6387C362.839 65.6387 357.869 70.3445 357.869 76.395C357.869 82.4454 362.839 87.2857 368.898 87.2857Z'
        fill='#64748B'
      />
      <path
        d='M401.087 93.0672H394.347V73.437C394.347 65.437 398.977 59.4538 407.691 59.4538C409.802 59.4538 411.98 59.8571 413.682 60.6639V67.2521C412.048 66.3109 410.142 65.8403 408.304 65.8403C403.811 65.8403 401.087 68.8655 401.087 73.7059V93.0672Z'
        fill='#64748B'
      />
      <path
        d='M431.21 93.874C421.542 93.874 414.462 86.0756 414.462 76.5294C414.462 67.1176 422.087 59.3193 432.231 59.3193C442.307 59.3193 450 66.7143 450 76.5294V93.0672H443.6V87.2857C441.081 91.3193 436.656 93.874 431.21 93.874ZM432.231 87.5546C438.494 87.5546 443.26 82.5798 443.26 76.5966C443.26 70.5462 438.494 65.5714 432.231 65.5714C426.035 65.5714 421.202 70.5462 421.202 76.5966C421.202 82.5798 426.035 87.5546 432.231 87.5546Z'
        fill='#64748B'
      />
      <path
        d='M267.389 193.649H256V118.238H279.784C299.883 118.238 312.947 127.355 312.947 144.913C312.947 162.922 299.66 171.926 280.342 171.926H267.389V193.649ZM280.119 128.818H267.389V161.234H280.119C292.402 161.234 301.223 156.394 301.223 144.913C301.223 133.883 292.737 128.818 280.119 128.818Z'
        fill='#334155'
      />
      <path
        d='M356.113 195C334.339 195 317.59 178.004 317.59 155.831C317.59 133.996 334.451 117 356.113 117C377.664 117 394.413 133.996 394.413 155.831C394.413 178.004 377.664 195 356.113 195ZM356.113 184.082C370.852 184.082 382.688 171.701 382.688 155.831C382.688 140.186 370.964 127.918 356.113 127.918C341.262 127.918 329.314 140.186 329.314 155.831C329.314 171.701 341.15 184.082 356.113 184.082Z'
        fill='#334155'
      />
      <path
        d='M429.201 194.887C416.584 194.887 405.976 189.147 401.509 178.792L411.112 174.065C414.015 180.593 420.38 184.082 429.201 184.082C437.911 184.082 444.611 179.918 444.611 172.489C444.611 164.16 437.018 162.697 428.196 161.009C416.919 158.758 402.961 155.043 402.961 139.061C402.961 126.567 414.239 117.113 428.42 117.113C439.809 117.113 449.97 122.515 454.437 132.87L444.946 137.823C441.931 131.632 436.236 127.805 427.973 127.805C420.38 127.805 414.239 132.307 414.239 138.723C414.239 146.827 422.167 148.628 431.211 150.429C442.377 152.68 456 156.394 456 172.377C456 185.658 444.276 194.887 429.201 194.887Z'
        fill='#334155'
      />
    </svg>
  );
}

export default AlegraPosLogo;

import { I18n } from '@aws-amplify/core';
import { capitalize } from 'lodash';

import { TableCellRegular } from '@alegradev/smile-ui-react';
import { format } from 'date-fns';
import { formatMoney } from '../../../../utils';

function globalInvoiceColumns() {
  return [
    {
      Header: capitalize(I18n.get('', 'Folio')),
      accessor: 'numberTemplate.fullNumber',
      width: '33%',
      Cell: ({ value }) => <TableCellRegular value={value} />,
    },
    {
      Header: capitalize(I18n.get('', 'Fecha de creación')),
      accessor: 'datetime',
      sortable: true,
      width: '33%',
      Cell: ({ value }) => (
        <TableCellRegular value={format(new Date(value), 'dd/MM/yyyy')} />
      ),
    },
    {
      Header: capitalize(I18n.get('', 'Valor total')),
      accessor: 'total',
      alignment: 'right',
      width: '33%',
      Cell: ({ value }) => <TableCellRegular value={formatMoney(value)} />,
    },
  ];
}

export default globalInvoiceColumns;

import { get } from "lodash";
import { useQuery } from "@tanstack/react-query";
import alegraApi from "../../../reducers/alegraAPI";
import { queryClient } from "../../../utils/queryClient";

export const SellersKeys = {
  all: 'sellers',
};

const fetchSellers = async (params) => {
  const response = await alegraApi.get('/sellers', {
    ...params
  });

  const data = get(response, 'data', null)
  if (data)
    return data;
  return [];
};

export const fetchSellersApi = (params) => {
  return queryClient.fetchQuery({
    queryKey: [SellersKeys.all, { ...params }],
    queryFn: () => fetchSellers(params),
    keepPreviousData: true,
    staleTime: 1000 * 60 * 60 * 24,
    refetchOnReconnect: "always",
  });
}

export const useSellersQuery = (params) => {
  return useQuery({
    queryKey: [SellersKeys.all, params ?? {}],
    queryFn: () => fetchSellersApi(params),
    keepPreviousData: true,
    staleTime: 1000 * 60 * 60 * 24,
    refetchOnReconnect: "always",
  })
};
import React from 'react';
import { I18n } from '@aws-amplify/core';
import { Field } from 'react-final-form';
import { capitalize } from 'lodash';
import { useSelector } from 'react-redux'

import { hasPermissionTo } from '../../../selectors/auth'
import { isOnlyInvoicingPlan } from '../../../selectors/company'
import { activeCostCenters } from '../../../selectors/costCenters'
import { renderSelect } from '../fields/V0/Fields';

const CostCenter = () => {
  const can = useSelector(hasPermissionTo)
  const costCenters = useSelector(activeCostCenters)
  const onlyInvoicing = useSelector(isOnlyInvoicingPlan)

  return (
    <div className="form-body__fields form-row justify-content-start">
      <Field
        name="costCenter"
        className="col-md-6"
        component={renderSelect}
        disabled={onlyInvoicing}
        onlyInvoicingTooltip={onlyInvoicing}
        label={capitalize(I18n.get('costCenter', 'centro de costo'))}
        help={
          <p className="h5 text-muted text-capitalize-first">
            {I18n.get('costCenterSettingTutorial', 'Selecciona centro de costos para distribuir los ingresos y gastos según las áreas, proyectos o cualquier división de tu negocio.')}
            <a className="btn-link" href="https://ayuda.alegra.com/como-crear-centro-de-costos-en-tu-cuenta-de-alegra" target="_blank" rel="noreferrer">
              {I18n.get('seeMore', 'ver mas')}
            </a>
          </p>
        }
        options={!can('index', 'cost-centers') ? [] : costCenters}
        selectInfo={!can('index', 'cost-centers')
          ? I18n.get('userNotAllowed.cost-centers.view', 'no tienes permisos suficientes para ver detalles de centros de costo')
          : null}
        hideNoOptionMessage={!can('index', 'cost-centers')}
        getOptionLabel={option => option.name}
        getOptionValue={option => option.id}
        menuPosition="absolute"
        showClearIndicator
      />
    </div>
  )
}

export default CostCenter;

export const DATE_PICKER_LOCALE_ES = {
  name: 'locale_es',
  months: [
    ['Enero', 'ENE'],
    ['Febrero', 'FEB'],
    ['Marzo', 'MAR'],
    ['Abril', 'ABR'],
    ['Mayo', 'MAY'],
    ['Junio', 'JUN'],
    ['Julio', 'JUL'],
    ['Agosto', 'AGO'],
    ['Septiembre', 'SEP'],
    ['Octubre', 'OCT'],
    ['Noviembre', 'NOV'],
    ['Diciembre', 'DIC'],
  ],
  weekDays: [
    ['Sábado', 'SÁB'],
    ['Domingo', 'DOM'],
    ['Lunes', 'LUN'],
    ['Martes', 'MAR'],
    ['Miércoles', 'MIÉ'],
    ['Jueves', 'JUE'],
    ['Viernes', 'VIE'],
  ],
  digits: ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'],
  meridiems: [
    ['AM', 'am'],
    ['PM', 'pm'],
  ],
};

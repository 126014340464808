import {
  Avatar,
  Button,
  Grid,
  Toast,
  Typography,
} from '@alegradev/smile-ui-react';
import { useContactForm } from '../contact/form/FormProvider';
import { capitalize, get } from 'lodash';
import { I18n } from 'aws-amplify';
import { useDispatch, useSelector } from 'react-redux';
import { closeSideModal, updateSideModal } from '../../../reducers/sideModals';
import { setClient } from '../../../reducers/activeInvoice';
import useConnectionStatus from '../../../hooks/useConnectionStatus/hook';
import { useContact } from '../contact/ContactProvider';
import { hasPermissionTo } from '../../../selectors/auth';
import { useEffect } from 'react';
import { IconPencil, IconPlus } from '@tabler/icons-react';

function DuplicatedContactModal() {
  const { Wrapper } = Grid;
  const dispatch = useDispatch();
  const isOnline = useConnectionStatus();
  const can = useSelector(hasPermissionTo);
  const { contact, duplicatedContact, setIsDuplicatedContact } =
    useContactForm();
  const { toggleIgnoreRepeated, toggleContact, toggleEditable } = useContact();
  const error = useSelector((state) =>
    get(state, 'sideModals.contact.params.error', null)
  );

  useEffect(() => {
    if (error?.includes('existe un contacto')) {
      setIsDuplicatedContact(contact);
    }
  }, [error]);

  const handleIgnoreRepeated = () => {
    toggleIgnoreRepeated();
    setIsDuplicatedContact(false);
  };

  const handleEditRepeated = () => {
    setIsDuplicatedContact(false);
    toggleContact(duplicatedContact);
    dispatch(updateSideModal({ sideModal: 'contact', params: { contact: duplicatedContact } }));
    toggleEditable();
  };

  if (!duplicatedContact) return null;

  return (
    <Wrapper
      padding={{ lg: 'lg' }}
      direction={{ lg: 'column' }}
      align={{ lg: 'center' }}
      justify={{ lg: 'between' }}
      backgroundColor='white'
      extraClasses='duplicated-contact-modal'
    >
      <Wrapper direction={{ lg: 'column' }} fullWidth gap={10}>
        <Typography
          type='body-1-bold'
          text={I18n.get('newClient', 'Nuevo cliente')}
        />

        <Toast
          direction='vertical'
          shadow={false}
          type='warning'
          title='¡Ya existe un contacto con esta identificación!'
          description='El numero de identificación que ingresaste se encuentra asociado a:'
        />
      </Wrapper>
      <Wrapper gap={20} direction={{ lg: 'column' }} align={{ lg: 'center' }}>
        <Avatar size={120} variant='secondary' text={duplicatedContact?.name} />
        <Wrapper direction={{ lg: 'column' }} align={{ lg: 'center' }}>
          <Typography
            type='body-2-bold'
            text={capitalize(duplicatedContact?.name) || ''}
          />
          {(duplicatedContact?.identificationObject ||
            !duplicatedContact?.identification) && (
            <Typography
              type='body-3-regular'
              text={
                duplicatedContact?.identification
                  ? get(duplicatedContact, 'identification', '')
                  : `${get(duplicatedContact, 'identificationObject.type', '')}. ${get(duplicatedContact, 'identificationObject.number', '')}`
              }
              variant='tertiary'
            />
          )}
          {get(duplicatedContact, 'email') && (
            <Typography
              type='body-3-regular'
              text={get(duplicatedContact, 'email')}
              variant='tertiary'
            />
          )}
          {get(duplicatedContact, 'mobile') && (
            <Typography
              type='body-3-regular'
              text={get(duplicatedContact, 'mobile')}
              variant='tertiary'
            />
          )}
        </Wrapper>
        {isOnline && can('edit', 'contacts') && (
          <Button
            leftIcon={IconPencil}
            emphasis='text'
            text={I18n.get('editCurrentContact', 'Editar este contacto')}
            onClick={handleEditRepeated}
          />
        )}
      </Wrapper>

      <Wrapper extraClasses='w-100' gap={16}>
        <Button
          leftIcon={IconPlus}
          emphasis='outline'
          full
          size='large'
          text={I18n.get(
            'duplicatedIdentificationContinueButton',
            'Crear cliente duplicado'
          )}
          onClick={handleIgnoreRepeated}
        />
        <Button
          full
          size='large'
          text={I18n.get('sellCurrentContact', 'Vender al contacto existente')}
          onClick={() => {
            dispatch(setClient(duplicatedContact));
            dispatch(closeSideModal({ sideModal: 'contact' }));
          }}
        />
      </Wrapper>
    </Wrapper>
  );
}

export default DuplicatedContactModal;

import dayjs from "dayjs";

export const notificationsTransformer = (name, params) => {
  return {
    user: {
      company: {
        userNotifications: {
          notifications: JSON.stringify({ [name]: dayjs(), ...params })
        }
      }
    }
  }
}

export const handleNotificationEvent = (ref, eventName, action) => {
  const innerAs = ref.current?.shadowRoot.getElementById('notifications-box').getElementsByTagName('a')
  if (!!innerAs && innerAs.length >= 1 && innerAs[1]?.href?.includes(eventName)) {
    innerAs[1]?.addEventListener("click", action)
  }
}

export const createNotificationBody = ({ name, userId, from = null, to = null, type = "info", section = null, title, text, actionCall, actionLabel, actionType }) => ({
  name,
  teamInCharge: "pos",
  receptors: {
    users: [userId.toString()],
  },
  fromDate: from ? from : dayjs().toISOString(),
  toDate: to ? to : dayjs().add(1, 'days').toISOString(),
  messages: [
    {
      channel: "web",
      type: type,
      closeable: true,
      section: section ? section : "index-pos-home",
      title,
      text,
      application: "alegra-pos",
      category: 'system',
      action: {
        actionType,
        actionCall,
        actionLabel
      },
      style: {
        type,
      }
    }
  ]
})

export const isNewUserSince = (registryDate, compareDate) => {
  return dayjs(registryDate).isAfter(dayjs(compareDate))
}

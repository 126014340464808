import React, { useEffect, useCallback } from 'react'
import { I18n } from '@aws-amplify/core'
import { useSelector } from 'react-redux'
import dayjs from 'dayjs'

import notificationsAPIV2 from '../../reducers/notificationsAPIV2'
import { userSelector } from '../../selectors/auth'
import { country as countrySelector } from '../../selectors/company';
import Cookies from 'js-cookie'
import { getPlanKeyword } from '../../selectors/membership'

const LimitedQueryPlanNotification = () => {
  const user = useSelector(userSelector);
  const country = useSelector(countrySelector);
  const planName = useSelector(getPlanKeyword);

  const selectedBody = useCallback(() => {
    return {
      userId: user.idGlobal.toString(),
      title: I18n.get('limitedQueryPlan', '¡Actualiza tu suscripción y no pares tu facturación!🚨'),
      text: I18n.get('limitedQueryPlan.description', 'Te encuentras en un Plan Consulta y a partir del lunes 13 de marzo solo podrás ver la información creada hasta ese día.'),
      actionCall: 'https://mi.alegra.com/subscriptions/products/alegra-pos/plans',
      actionLabel: I18n.get('limitedQueryPlan.action', 'Actualizar suscripción'),
      type: "warning",
      to: dayjs().add(1, 'days').toISOString(),
      country,
    }
  }, [user, country])

  useEffect(() => {
    async function checkNotification() {
      const cookieName = `queryPlan_${country}`
      const cookieValue = Cookies.get(cookieName)
      const cookieDate = !!cookieValue ? cookieValue : null;
      const sendNotification = !cookieDate ? true : dayjs().isAfter(parseInt(cookieDate));

      if (planName !== 'readonly') return;
      if (!sendNotification || dayjs().isAfter(dayjs('2023-03-13'))) return;
      try {
        await notificationsAPIV2.post('/notifications',
          notificationBody({
            ...selectedBody(),
          }));
        Cookies.set(cookieName, dayjs().add(1, 'day').startOf('day').valueOf());

      } catch (err) {
        console.log("error while creating notification", err);
      }
    }

    checkNotification();
  }, [country, planName, selectedBody])

  return (
    <>
    </>
  )
}

const notificationBody = ({ userId, from = null, to = null, section = null, title, text, actionCall, actionLabel, type, actionType, country }) => ({
  name: "limited-query-user:"+country,
  teamInCharge: "pos",
  receptors: {
    users: [userId.toString()]
  },
  fromDate: from ? from : dayjs().toISOString(),
  toDate: to ? to : dayjs().add(1, 'days').toISOString(),
  messages: [
    {
      channel: "web",
      type: "localized",
      closeable: true,
      section: section ? section : "index-pos-home",
      title,
      text,
      application: "alegra-pos",
      category: 'system',
      action: {
        actionType,
        actionCall,
        actionLabel
      },
      style: {
        type,
      }
    }
  ]
})

export default LimitedQueryPlanNotification;
import { I18n } from 'aws-amplify';
import { get, capitalize } from 'lodash';
import { isNaN, isNull, isEmpty } from 'lodash';

const validateInt = (value) => {
  return (!(isNaN(parseInt(value)) || value.includes(".")));
}

const validateDecimal = (value) => {
  return !!(!isNaN(parseFloat(value)) && value.includes("."));
}

const validateBoolean = (value) => {
  return !!(typeof (value) === 'boolean');
}

const validateDate = (value) => {
  if (typeof (value) === 'boolean') return false;
  return !!((new Date(value)).toString() === value.toString());
}

export const validateType = (value) => {
  if (validateInt(value)) return 'int';
  if (validateDecimal(value)) return 'decimal';
  if (validateBoolean(value)) return 'boolean';
  if (validateDate(value)) return 'date'
  return 'text';
}

export const validate = values => {
  let errors = {}

  const name = get(values, 'name', '');
  const type = get(values, 'type', '');
  const defaultValue = get(values, 'defaultValue');
  const options = !!get(values, 'options', null) ? get(values, 'options').filter(option => !isEmpty(option)).map(option => option.value) : [];

  if (!name)
    errors.name = capitalize(I18n.get('enterAName', 'Ingresa un nombre'));
  if (!type)
    errors.type = capitalize(I18n.get('noCustomFieldType', 'Elige un tipo de campo'))
  if (type?.value === 'optionsList' && defaultValue) {
    if (!options.includes(defaultValue))
      errors.defaultValue = capitalize(I18n.get('fsdfd', 'El valor por defecto debe estar incluido en las opciones'))
    return errors;
  }
  if (defaultValue && type.value !== 'text') {
    if (validateType(defaultValue) !== type.value) {
      errors.defaultValue = capitalize(I18n.get('updateDefaultValue', 'Actualiza el valor por defecto'))
    }
  }
  return errors;
}

export const transform = values => {
  const defaultValue = isNull(get(values, 'defaultValue', '')) ? '' : get(values, 'defaultValue', '').toString();

  const parsedOptions = !!get(values, 'options', null)
    ? get((get(values, 'options').filter(option => option.value)), 'length', null) > 0
      ? JSON.stringify(get(values, 'options').map(option => option.value))
      : null
    : null

  return {
    name: get(values, 'name', ''),
    type: get(values, 'type.value', ''),
    defaultValue,
    settings: JSON.stringify({
      isRequired: get(values, 'mandatory', false),
      showInItemVariants: get(values, 'showInItemVariants', true),
      printOnInvoices: get(values, 'printOnInvoice', false)
    }),
    status: get(values, 'status', 'active'),
    description: get(values, 'description', ''),
    resourceType: get(values, 'resourceType', 'item'),
    options: get(values, 'type.value', '') === 'optionsList' ? parsedOptions : null,
  }
}

export const transformFromQuery = values => {
  let defaultValue = '';

  if (values.defaultValue === 'true')
    defaultValue = true
  else if (values.defaultValue === 'false')
    defaultValue = false
  else
    defaultValue = values.defaultValue

  return {
    ...values,
    mandatory: values.isRequired,
    showInItemVariants: values.showInItemVariants,
    printOnInvoice: values.printOnInvoices,
    defaultValue
  }
}
import { sendNewGTMEvent } from '../../../../../reducers/company';
import { openSideModal } from '../../../../../reducers/sideModals';

export function openCreateClientSideModal({ contact = null, dispatch }) {
  try {
    let params = {};
    if (!!contact) params = { contact };
    dispatch(openSideModal({ sideModal: 'contact', params }));
    dispatch(
      sendNewGTMEvent('client-creation-attempted', {
        creationStatus: 'success',
      })
    );
  } catch {
    dispatch(
      sendNewGTMEvent('client-creation-attempted', {
        creationStatus: 'failure',
      })
    );
  }
}

import { Typography } from '@alegradev/smile-ui-react';
import pendingInvoices from './assets/pendingInvoices.svg';
import { I18n } from 'aws-amplify';
import { memo } from 'react';

const PendingInvoicesView = () => (
  <>
    <img src={pendingInvoices} alt='pendingInvoices' />
    <Typography
      extraClassName='text-center'
      type='body-2-bold'
      text={I18n.get(
        'youHavePendingInvoices',
        'Tienes ventas pendientes de sincronización'
      )}
    />
    <Typography
      extraClassName='text-center px-5'
      type='body-3-regular'
      variant='secondary'
      text={I18n.get(
        'youHavePendingInvoices.description',
        'Para evitar perder el registro de estas ventas, te sugerimos sincronizarlas antes de cerrar sesión.'
      )}
    />
  </>
);

export default memo(PendingInvoicesView);

import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form } from 'react-final-form';
import { I18n } from '@aws-amplify/core';
import { get } from 'lodash';

import { toast } from '../../../utils';
import { formError, handleError } from '../../../utils/errors';
import { itemCategoryTransformer } from '../../../utils/transformers';
import { closeModal, openModal } from '../../../reducers/modals';
import { useCreateItemCategory, useUpdateItemCategory } from '../../inventory/Categories/mutations';
import { validate, transform } from '../../forms/itemCategory/utils';

import Modal from '../../common/Modal';
import Body from '../../forms/itemCategory/ItemCategory';
import Bottom from '../common/Bottom';
import Notification from '../common/Notification';

const ItemCategory = ({ onCreated }) => {
  const dispatch = useDispatch();
  const isOpen = useSelector((state) =>
    get(state, 'modals.itemCategory.isOpen', false)
  );
  const onRefresh = useSelector((state) =>
    get(state, 'modals.itemCategory.params.onRefresh', false)
  );
  const itemCategory = useSelector((state) =>
    get(state, 'modals.itemCategory.params.itemCategory', false)
  );
  const [resetForm, setResetForm] = useState(false);

  const { mutate: updateItemCategoryMutation, isLoading: isLoadingUpdate } = useUpdateItemCategory();
  const { mutate: createItemCategoryMutation, isLoading: isLoadingCreate } = useCreateItemCategory()

  const close = () => {
    dispatch(closeModal({ modal: 'itemCategory' }));
  }

  const open = () => {
    dispatch(openModal({
      modal: 'itemCategory', params: {
        onRefresh,
      }
    }));
  }

  const submit = async (values) => {
    const transformedValues = transform(values, itemCategory);

    if (!!itemCategory) {
      updateItemCategoryMutation({
        itemCategory: itemCategoryTransformer({ ...itemCategory, ...transformedValues })
      }, {
        onSuccess: () => {
          close();
          toast.success({ title: I18n.get('itemCategoryUpdatedSuccesfully', 'Categoria actualizada con exito') });
        },
        onError: (error) => {
          close();
          toast.error({
            title: I18n.get('updateItemCategoryError', 'Hubo un error al cambiar los datos de la categoría.'),
            subtitle: handleError(error)
          })
          return formError(error, I18n.get('updateItemCategoryError', 'Hubo un error al cambiar los datos de la categoría.'));
        }
      })
    } else {
      createItemCategoryMutation({
        itemCategory: itemCategoryTransformer({ ...transformedValues })
      }, {
        onSuccess: (_, __, context) => {
          close();
          if (!!onCreated) {
            onCreated(get(context, 'itemCategory'));
          }
        }
      });
    }

    if (resetForm) {
      open();
      setResetForm(false);
    }
  }

  return (
    <Modal
      title={(
        <div className="d-flex justify-content-between align-items-center w-100">
          <div className="modal__header-title text-capitalize-first h2 text-primary text-truncate">
            {!!itemCategory ? itemCategory.name : I18n.get('newItemCategoryModalTitle', 'Nueva categoría')}
          </div>
        </div>
      )}
      isOpen={isOpen}
      onRequestClose={() => close()}
      className="modal__medium-size"
    >
      <Form
        onSubmit={submit}
        validate={values => validate(values)}
        initialValues={itemCategory}
        keepDirtyOnReinitialize
      >
        {({ handleSubmit, form, values, submitError, pristine }) => (
          <form noValidate onSubmit={handleSubmit}>
            <div className="px-md-5 py-md-3">
              {!itemCategory && (
                <p className="h5 py-3 text-subtitle-muted text-capitalize-first">
                  {I18n.get('newItemCategoryModalSubtitle', 'Crea nuevas categorías para clasificar tus productos y ubicarlos fácilmente.')}
                </p>
              )}
              <Body values={values} form={form} />
            </div>

            {(!!submitError && !pristine) && (
              <Notification isOpen={true} text={submitError} type="error" />
            )}

            <Bottom
              submitting={isLoadingUpdate || isLoadingCreate}
              onClose={() => close()}
              onResetForm={setResetForm}
              resetForm={resetForm}
              hideRequired={true}
              submitText={!!itemCategory
                ? I18n.get("updateItemCategory", "Actualizar categoría")
                : I18n.get("createItemCategory", "Crear categoría")
              }
              saveText={!!itemCategory
                ? I18n.get("updateAndCreateNew", "Actualizar y crear nueva")
                : I18n.get("saveAndCreateNew", "Guardar y crea nueva")
              }
              hideCancel
            />
          </form>
        )}
      </Form>
    </Modal>
  )
}

export default ItemCategory;

import React, { useState, useRef } from 'react';
import { Field } from 'react-final-form';
import { I18n } from '@aws-amplify/core';
import { useSelector } from 'react-redux';
import { capitalize, get } from 'lodash';

import {
  country as countrySelector,
  isOnlyInvoicingPlan,
} from '../../../selectors/company';
import { allItemCategories } from '../../../selectors/itemCategories';
import { renderField, renderSelect } from '../fields/V0/Fields';
import Price from './Price';
import Inventory from './Inventory';
import configAPI from '../../../reducers/configAPI';
import { COUNTRIES } from '../../../utils/enums/countries';

import { Icon } from '@alegradev/smile-ui-react';
import { IconTag } from '@tabler/icons-react';

const productKeyTooltip = (country) => {
  switch (country) {
    case 'colombia':
      return (
        <>
          {I18n.get(
            'productKeyHelp',
            'Es un campo obligatorio si generas facturas electrónicas, puedes conocerlo'
          )}
          <a
            href='https://ayuda.alegra.com/agrega-el-c%C3%B3digo-de-producto-a-tus-%C3%ADtems-de-venta'
            target='_blank'
            rel='noreferrer'
          >
            {I18n.get('here', 'aqui')}
          </a>
        </>
      );
    case 'peru':
      return (
        <>
          {I18n.get(
            'sunatCodeHelp',
            'Conoce cómo agregar el código de SUNAT a tus productos y servicios '
          )}
          <a
            href='https://ayuda.alegra.com/es/crea-tus-productos-y-servicios-con-el-c%C3%B3digo-sunat-para-facturar-desde-el-punto-de-venta'
            target='_blank'
            rel='noreferrer'
          >
            {I18n.get('here', 'aqui')}
          </a>
        </>
      );
    default:
      return null;
  }
};

const getProductKeyOptionLabel = (option, country) =>
  country === 'costaRica'
    ? !option.tax
      ? `${option.key} - ${option.name}`
      : `${option.key} - ${option.name} - IVA ${option.tax}%`
    : `${option.key} - ${option.name}`;

const BasicData = (props) => {
  const [src, setSrc] = useState(null);
  const imageInputRef = useRef();

  const itemCategories = useSelector(allItemCategories);
  const country = useSelector(countrySelector);
  const onlyInvoicing = useSelector(isOnlyInvoicingPlan);

  const getReferenceTooltipText = () => {
    const referenceCharactersPerCountry = {
      [COUNTRIES.COSTA_RICA]: 20,
      [COUNTRIES.REPUBLICA_DOMINICANA]: 35,
      [COUNTRIES.PERU]: 100,
    };

    if (
      [
        COUNTRIES.REPUBLICA_DOMINICANA,
        COUNTRIES.PERU,
        COUNTRIES.COSTA_RICA,
      ].includes(country)
    ) {
      return capitalize(
        replace(
          I18n.get(
            'maxReferenceCharactersExceeded',
            'Código de máximo {} caracteres que te servirá para identificar tus productos'
          ),
          '{}',
          referenceCharactersPerCountry[country]
        )
      );
    }

    return I18n.get(
      'referenceHelp',
      'Código único para identificar tus productos, ejemplo CAS002'
    );
  };

  return (
    <div className='form-body__fields form-row justify-content-start'>
      <Field
        name='name'
        component={renderField}
        className='col-12'
        type='text'
        required
        label={capitalize(I18n.get('name', 'nombre'))}
      />

      <div className='col-12 my-3 d-flex align-items-center justify-content-center'>
        <Field name='image'>
          {(fieldProps) => (
            <div className='image-container'>
              {!!src ? (
                <img
                  onClick={() => imageInputRef.current.click()}
                  src={src}
                  alt={I18n.get('itemImage', 'imagen de producto')}
                  className='w-100 h-100 pointer'
                />
              ) : (
                <div
                  onClick={() => imageInputRef.current.click()}
                  className='d-flex w-100 h-100 flex-column justify-content-center align-items-center pointer'
                >
                  <Icon icon={IconTag} extraClass='icon-secondary icon x3 mb-2' />
                  <p className='text-capitalize-first text-secondary h5'>
                    {I18n.get('itemImage', 'imagen de producto')}
                  </p>
                </div>
              )}

              <input
                {...fieldProps.input}
                type='file'
                onChange={(e) => {
                  let file = e.target.files[0],
                    reader = new FileReader();
                  reader.readAsDataURL(file);
                  reader.onload = (ev) => {
                    setSrc(ev.target.result);
                    props.form.change('imageSrc', ev.target.result);
                  };
                  fieldProps.input.onChange(e);
                }}
                ref={imageInputRef}
                className='w-100 h-100 d-none'
              />
            </div>
          )}
        </Field>
      </div>

      <Price {...props} />

      <Field
        name='reference'
        component={renderField}
        className='col-sm-6'
        type='text'
        label={capitalize(I18n.get('reference', 'referencia'))}
        helpTooltip={getReferenceTooltipText()}
      />

      {country === 'costaRica' && !!get(props, 'values.reference', null) && (
        <Field
          name='referenceType'
          component={renderSelect}
          className='col-sm-6'
          options={[
            {
              value: '01',
              label: capitalize(
                I18n.get(
                  'sellerProductCode',
                  'Código del producto del vendedor'
                )
              ),
            },
            {
              value: '02',
              label: capitalize(
                I18n.get(
                  'buyerProductCode',
                  'Código del producto del comprador'
                )
              ),
            },
            {
              value: '03',
              label: capitalize(
                I18n.get(
                  'productCodeAssignedByTheIndustry',
                  'Código del producto asignado por la industria'
                )
              ),
            },
            {
              value: '04',
              label: capitalize(
                I18n.get('codeForInternalUse', 'Código uso interno')
              ),
            },
          ]}
          label={capitalize(I18n.get('categoryType', 'tipo de referencia'))}
        />
      )}

      {(country === 'colombia' ||
        country === 'costaRica' ||
        country === 'peru' ||
        country === 'mexico' ||
        country === 'panama') && (
        <Field
          name='productKey'
          required={country === 'mexico'}
          component={renderSelect}
          className='col-sm-6'
          loadOptions={async (inputValue) => {
            try {
              // ex queries.allItemCodes

              const response = await configAPI.get('/products-keys', {
                application_version: country,
                name_contains: inputValue,
                limit: 20,
              });
              let data = get(response, 'data', []);
              if (country === 'costaRica')
                data = data.filter((itemCode) => !itemCode.deprecated);

              return data;
            } catch {
              return [];
            }
          }}
          defaultOptions
          getOptionLabel={(option) => getProductKeyOptionLabel(option, country)}
          getOptionValue={(option) => option.key}
          label={
            country === 'peru'
              ? I18n.get('sunatCode', 'código SUNAT')
              : capitalize(
                  I18n.get('productKey', 'código del producto o servicio')
                )
          }
          helpTooltip={productKeyTooltip(country)}
        />
      )}

      <Field
        name='category'
        component={renderSelect}
        className='col-sm-6'
        options={itemCategories}
        disabled={onlyInvoicing}
        onlyInvoicingTooltip={onlyInvoicing}
        getOptionLabel={(option) => option.name}
        getOptionValue={(option) => option.id}
        label={capitalize(I18n.get('category', 'categoría'))}
        helpTooltip={capitalize(
          I18n.get(
            'categoryDescription',
            'Las categorías te sirven para agrupar tus productos y servicios según su tipo o clase.'
          )
        )}
        addOptionText={
          !!props.newItemCategoryAction
            ? capitalize(I18n.get('newItemCategory', 'Nueva categoría'))
            : null
        }
        addOptionAction={props.newItemCategoryAction}
        showClearIndicator
      />

      {country === 'costaRica' && (
        <Field
          name='tariffHeading'
          className='col-sm-6'
          component={renderField}
          type='text'
          label={capitalize(I18n.get('tariffHeading', 'Partida arancelaria'))}
        />
      )}

      <Inventory {...props} />
    </div>
  );
};

export default BasicData;

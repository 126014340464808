import { get } from 'lodash';
import { I18n } from '@aws-amplify/core';
export const validate = (values) => {
  const MAX_LENGTH = 200;
  let errors = {};
  const customPhrase = get(values, 'customPhrase', null);
  if (customPhrase && customPhrase.length >= MAX_LENGTH) {
    errors.customPhrase = `${customPhrase.length} ${I18n.get("of", "de")} ${MAX_LENGTH} ${I18n.get("characters", "caracteres")}`;
  }
  return errors;
}
export const transform = values => {
  return {
    localSettings: {
      printTemplate: get(values, 'template.value', 'classic'),
      printAlignment: get(values, 'align', 'center'),
      ticketItemsDescription: get(values, 'description', false),
      ticketUnitPrice: get(values, 'unitPrice', false),
      ticketCompanyLogo: get(values, 'logo', true),
      printFormat: get(values, 'format.value', '') + 'MM',
      printLeftMargin: get(values, 'leftMargin', 2),
      printRightMargin: get(values, 'rightMargin', 2),
      customPhrase: get(values, 'customPhrase', null) ? get(values, 'customPhrase', null).replace(/['"]+/g, "'") : null,
      imageOrQr: get(values, 'imageInfo', null) || get(values, 'imageOrQr', null),
      printItemFullLine: get(values, 'printItemFullLine', false),
      printUnitOfMeasure: get(values, 'unitOfMeasure', false),
      printTotalLines: get(values, 'totalLines', true),
      taxesIncludedInTotal: get(values, 'taxesIncludedInTotal', true),
    }
  }
}
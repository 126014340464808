import React, { useEffect } from 'react';
import { Switch, Route, useHistory } from 'react-router-dom';

import GlobalInvoices from './main';
import GlobalInvoice from './invoice';
import GlobalInvoiceDetail from './detail';
import { useSelector } from 'react-redux';
import { country as countrySelector } from '../../selectors/company';
import { COUNTRIES } from '../../utils/enums/countries';
import { Container } from '@alegradev/smile-ui-react';

function GlobalInvoicesRouter() {
  const history = useHistory();
  const country = useSelector(countrySelector);

  useEffect(() => {
    if (![COUNTRIES.MEXICO].includes(country)) {
      history.push('/');
    }
  }, []);

  return (
    <Container marginTop={30}>
      <Switch>
        <Route
          path='/global-invoices/invoice/:id/detail'
          component={GlobalInvoiceDetail}
        />
        <Route path='/global-invoices/invoice/:id?' component={GlobalInvoice} />
        <Route path='/global-invoices' component={GlobalInvoices} />
      </Switch>
    </Container>
  );
}

export default GlobalInvoicesRouter;

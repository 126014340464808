import React from 'react';
import { Field } from 'react-final-form';
import { I18n } from '@aws-amplify/core';
import { capitalize, get } from 'lodash';
import { useSelector } from 'react-redux';

import { activeBanks } from '../../../selectors/banks';
import { renderField, renderSelect, renderCheckbox } from '../fields/V0/Fields';

const OpenShift = ({ values, options }) => {
  const banks = useSelector(activeBanks)

  return (
    <div className="form-body__fields form-row justify-content-start">
      {options.hasOwnProperty('configureShift') && (
        <Field
          name="shiftsEnabled"
          className="col-12 mt-2 mb-3"
          component={renderCheckbox}
          switchLabel={!!get(values, 'shiftsEnabled')
            ? capitalize(I18n.get('disable', 'desactivar'))
            : capitalize(I18n.get('enable', 'activar'))
          }
          label={capitalize(I18n.get('manageShifts', 'administrar turnos'))}
          help={capitalize(I18n.get('manageShiftsHelp', 'administrar turnos te permite controlar tus operaciones por períodos de tiempos definidos'))}
        />
      )}

      {(!!get(values, 'shiftsEnabled') || options.hasOwnProperty('openShift')) && (
        <>
          <Field
            name="base"
            className="col-12"
            component={renderField}
            type="number"
            label={capitalize(I18n.get('base', 'base inicial'))}
            help={capitalize(I18n.get('baseHelp', 'la base es el dinero con que se cuenta a la hora de abrir un turno'))}
            required
          />

          <Field
            name="baseBank"
            className="col-12"
            component={renderSelect}
            options={banks}
            label={capitalize(I18n.get('baseBank', 'banco base'))}
            help={capitalize(I18n.get('baseBankHelp', 'el banco al cual se transferirá la base inicial'))}
            getOptionLabel={option => option.name}
            getOptionValue={option => option.id}
            required
          />
        </>
      )}

    </div>
  )
}

export default OpenShift;
import { get } from 'lodash';

export const spainTransformer = (values, _props, newValues) => {
  const identificationType = get(values, 'identification.type', null);
  const identificationNumber = get(values, 'identification.number', null);
  const combinedAddress = get(values, 'address.combined', null);

  let newAddress = {};
  if (combinedAddress) {
    newAddress = {
      city: (combinedAddress?.municipality) || null,
      province: (combinedAddress?.province) || null,
      zipCode: get(values, 'address.zipCode', null),
      address: get(values, 'address.address', null),
    };
  }
  if (!combinedAddress) {
    newAddress = {
      country: get(values, 'address.country', null),
      city: get(values, 'address.city', null),
      address: get(values, 'address.address', null),
      zipCode: get(values, 'address.zipCode', null),
    };
  }
  return {
    ...newValues,
    identificationObject: {
      type: identificationType,
      number: identificationNumber,
    },
    identification: null,
    address: newAddress,
  };
};

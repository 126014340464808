import React from 'react';
import { Form, Field } from 'react-final-form';
import arrayMutators from 'final-form-arrays'
import { I18n } from '@aws-amplify/core';
import { get } from 'lodash';
import { useSelector } from 'react-redux';

import { customFields as customFieldsSelector } from '../../../../selectors/customFields'
import Bottom from '../../../modals/common/Bottom';
import Modal from '../../../common/Modal';
import { transformVariants as transform } from '../utils'
import { renderDynamicField } from '../../fields/V0/Fields';
import Inventory from './Inventory';

const EditItemVariant = props => {
  const customFields = useSelector(customFieldsSelector);

  const barcodeField = customFields.filter(field => get(field, 'key') === 'barcode')

  const submit = values => {
    const transformedValues = transform(values, { customFields })
    props.form.change(`itemVariants.${props.index}`, {
      ...props.variant,
      ...transformedValues,
    });
    props.onRequestClose()
  }

  return (
    <Modal
      {...props}
      title={I18n.get('editVariant', 'editar variante')}
      className="select-kit-item-modal"
    >
      <Form
        onSubmit={submit}
        mutators={{
          ...arrayMutators
        }}
        initialValues={{
          warehouses: !!get(props, 'variant.inventory.warehouses.length')
            ? get(props, 'variant.inventory.warehouses')
            : [{}],
          ...props.variant,
        }}
        keepDirtyOnReinitialize
      >
        {({ handleSubmit, form, values, invalid }) => (
          <form
            noValidate
            onSubmit={handleSubmit}
          >
            <div className="p-5 form-body">
              {!!barcodeField && (
                <div className="form-row">
                  {
                    barcodeField.map((field, index) => {
                      return (
                        <Field
                          key={index}
                          name={`customFields.[${index}].value`}
                          label={get(field, 'name')}
                          fieldType={get(field, 'type')}
                          options={get(field, 'options')}
                          component={renderDynamicField}
                          className="col-12"
                          required={!!get(field, 'settings.isRequired', null)}
                        />
                      )
                    })
                  }
                </div>
              )}

              <Inventory form={form} values={values} />
            </div>

            <Bottom
              hideCancel
              disabled={invalid}
              onClose={props.onRequestClose}
            />
          </form>
        )}
      </Form>
    </Modal>
  )
}

export default EditItemVariant
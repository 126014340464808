import { toastHandler, useModal } from '@alegradev/smile-ui-react';
import { graphqlOperation, I18n } from 'aws-amplify';
import { Form } from 'react-final-form';
import { emailMethodValidation, whatsappMethodValidation } from './validations';
import { useDispatch, useSelector } from 'react-redux';
import {
  companySelector,
  country as countrySelector,
} from '../../../../selectors/company';
import ContactPortalWhatsappMethod from './Whatsapp';
import ContactPortalEmailMethod from './Email';
import { APIGraphqlSelector } from '../../../../selectors/app';
import * as mutations from '../../../../graphql/mutations';
import { useState } from 'react';
import { sendNewGTMEvent } from '../../../../reducers/company';

export function ContactPortalWhatsappFormProvider({ contact, modalName }) {
  const { closeModal } = useModal();
  const dispatch = useDispatch();
  const ALEGRA_PORTAL_LINK = process.env.REACT_APP_ALEGRA_PORTAL;

  const submit = async (values) => {
    const { phone, message, phonePrefix, contact } = values;
    const url = `https://wa.me/${phonePrefix?.replace('+', '')}${phone}?text=${encodeURIComponent(message)}`;
    window.open(url, '_blank');
    dispatch(
      sendNewGTMEvent('pos-client-account-status-sent', {
        medium: 'whatsapp',
      })
    );
    closeModal(`sendClientLink-${contact?.id}`);
  };

  const initialValues = {
    message: `Hola, un feliz día. 😉\n\nPuedes consultar y gestionar las facturas generadas por {{companyName}} a nombre de {{name}} en: ${ALEGRA_PORTAL_LINK} \n\nSi tienes dudas puedes responder a este mensaje o escribir al correo {{companyEmail}} \n`,
  };

  return (
    <Form
      onSubmit={submit}
      initialValues={initialValues}
      validate={(values) => whatsappMethodValidation(values)}
    >
      {({ handleSubmit, errors }) => (
        <form noValidate onSubmit={handleSubmit}>
          <ContactPortalWhatsappMethod
            contact={contact}
            modalName={modalName}
            errors={errors}
          />
        </form>
      )}
    </Form>
  );
}

export function ContactPortalEmailFormProvider({ contact, modalName }) {
  const { closeModal } = useModal();
  const [loading, setLoading] = useState(false);
  const company = useSelector(companySelector);
  const country = useSelector(countrySelector);
  const APIGraphql = useSelector(APIGraphqlSelector);
  const dispatch = useDispatch();

  const submit = async (values) => {
    const { contact, subject, content, to, sendCopy } = values;

    const data = {
      name: 'sendAlegraPortalLink',
      applicationVersion: country,
      subject,
      body: content,
      to: to.map((item) => item.value).join(','),
      from: company?.email,
      fromName: company?.name,
    };

    if (sendCopy) {
      data.cc = company?.email;
    }

    try {
      setLoading(true);
      await APIGraphql(
        graphqlOperation(mutations.sendEmail, {
          email: data,
        })
      );
      toastHandler({
        type: 'success',
        title: I18n.get('emailSent', 'Correo enviado'),
      });
      dispatch(
        sendNewGTMEvent('pos-client-account-status-sent', {
          medium: 'mail',
        })
      );
      closeModal(`sendClientLink-${contact?.id}`);
    } catch (error) {
      toastHandler({
        type: 'error',
        title: I18n.get('error', 'Error'),
        description: I18n.get(
          'errorSendingEmail',
          'Ocurrió un error al enviar el correo'
        ),
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Form
      onSubmit={submit}
      initialValues={{
        sendCopy: true,
      }}
      validate={(values) => emailMethodValidation(values)}
    >
      {({ handleSubmit, errors }) => (
        <form noValidate onSubmit={handleSubmit}>
          <ContactPortalEmailMethod
            contact={contact}
            modalName={modalName}
            errors={errors}
            loading={{ isLoading: loading }}
          />
        </form>
      )}
    </Form>
  );
}

import React from 'react'
import { I18n } from '@aws-amplify/core';
import { graphqlOperation } from '@aws-amplify/api';
import { Form } from 'react-final-form'
import { useDispatch, useSelector } from 'react-redux'
import { get } from 'lodash'

import * as mutations from '../../../../graphql/mutations'
import { addWarehouse } from '../../../../reducers/warehouses'
import { toast, replaceAndParse } from '../../../../utils'
import { formError } from '../../../../utils/errors'
import Body from '../../../forms/warehouse/Warehouse'
import { validate, transform } from '../../../forms/warehouse/utils'
import Notification from '../../common/Notification'
import Bottom from '../../common/Bottom'
import Modal from '../common/Modal'
import { APIGraphqlSelector } from '../../../../selectors/app';

const Warehouse = ({ isOpen, onRequestClose, onCreated, fromOldForm }) => {
  const dispatch = useDispatch()
  const APIGraphql = useSelector(APIGraphqlSelector);
  
  const submit = async values => {
    const transformedValues = transform(values)

    try {
      const response = await APIGraphql(graphqlOperation(mutations.createWarehouse, {
        warehouse: transformedValues
      }))

      const warehouse = !!get(response, 'data.createWarehouse') ? get(response, 'data.createWarehouse'): null

      if (!!warehouse) {
        dispatch(addWarehouse(warehouse))
        if (!!onCreated)
          onCreated(warehouse)
      }
      
      onRequestClose();
      toast.success({ 
        title: `${I18n.get('warehouseCreatedSuccessfully', 'bodega creada con éxito')} ✌`,
        subtitle: replaceAndParse(I18n.get('warehouseCreatedSuccessfullyMessage', 'La bodega {} ya está disponible para que distribuyas tu inventario.'), [
          `<span class="font-weight-bold">${get(warehouse, 'name', '')}</span>`
        ]),
      })
    } catch (error) {
      return formError(error, I18n.get('createWarehouseError', 'hubo un error en la creación de la bodega'))
    }
  }

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      title={I18n.get('newWarehouse', 'nueva bodega')}
      description={I18n.get('newWarehouseSubtitle', 'Creá todos tus puntos de almacenamiento de tu inventario')}
    >
      <Form
        onSubmit={submit}
        validate={(values) => validate(values)}
      >
        {({ handleSubmit, values, submitting, submitError }) => (
          <form 
            noValidate
            className="w-100 h-100 bg-white d-flex flex-column"
            onSubmit={handleSubmit}
          >
            <div className="side-modal__body">
              <Body values={values} fromOldForm/>
            </div>

            <Notification
              isOpen={!!submitError}
              type="error"
              text={submitError}
            />

            <Bottom
              onClose={() => onRequestClose()} 
              disabled={submitting} 
              submitting={submitting}
            />
          </form>
        )}
      </Form>
    </Modal>
  )
}

export default Warehouse;
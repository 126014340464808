import React, { useEffect, useState, useCallback } from "react";
import arrayMutators from 'final-form-arrays'
import { Form } from "react-final-form";
import { useHistory, useParams } from "react-router-dom";
import { I18n } from "aws-amplify";
import { get } from "lodash";

import { checkFeatureLimit } from "../../../../reducers/membership";
import { useSingleInventoryAdjustmentQuery } from "../queries";
import { transform, transformOptimisticUpdate, validate } from "../../../forms/inventoryAdjustment/utils";
import { Total } from "../newInventoryAdjustment/NewInventoryAdjustment";
import { getItem } from "../../../../database/itemsDB";
import { useUpdateInventoryAdjustment } from "../mutations";
import { replaceAndParse, toast } from "../../../../utils";
import { handleError } from "../../../../utils/errors";
import { useDispatch, useSelector } from "react-redux";
import { stationWarehouse } from "../../../../selectors/app";
import { hasPermissionTo } from "../../../../selectors/auth";
import { sendGTMEvent } from "../../../../reducers/company";

import Bottom from "../newInventoryAdjustment/Bottom";
import Body from "../../../forms/inventoryAdjustment/InventoryAdjustment";

import { Icon } from '@alegradev/smile-ui-react';
import { IconArrowLeft, IconLoader2 } from "@tabler/icons-react";

const useShowToastAndRedirect = ({ isPaused, isError, isSuccess, error, editedInventoryAdjustment }) => {
  const history = useHistory();

  useEffect(() => {
    if (isPaused) {
      toast.success({
        title: I18n.get('inventoryAdjustmentEditedLocally.title', 'Ajuste de inventario editado localmente.'),
        subtitle: I18n.get('inventoryAdjustmentEditedLocally.subtitle', 'La acción se sincronizará automaticamente cuando se reestablezca la conexión.')
      })
      history.push('/inventory/inventory-adjustments');
    }
    if (isError) {
      const responseError = get(error, 'response.data.message', '')
      toast.error({
        title: I18n.get('inventoryAdjustmentCreateError', 'error editando el ajuste de inventario'),
        subtitle: !!responseError ? responseError : handleError(error),
      });
    }
    if (isSuccess) {
      toast.success({
        title: I18n.get('inventoryAdjustmentEdited.title', '¡Cambios listos! ✨'),
        subtitle: replaceAndParse(I18n.get('inventoryAdjustmentEdited.subtitle', 'El ajuste de inventario <span class="font-weight-bold">No. {}</span> ya tiene la forma que le diste.'), [
          get(editedInventoryAdjustment, 'data.number')
        ])
      })
      history.push('/inventory/inventory-adjustments');
    }
  }, [isSuccess, isPaused, isError, error, history, editedInventoryAdjustment])
}

const EditInventoryAdjustment = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const can = useSelector(hasPermissionTo);
  const warehouse = useSelector(stationWarehouse);
  const filters = get(history, 'location.state.filters');
  const { id } = useParams();
  const [fullItems, setFullItems] = useState(null);

  const { data, isLoading: isLoadingQuery } = useSingleInventoryAdjustmentQuery(id);
  const { mutate, isLoading: isLoadingMutation, isPaused, error, isError, isSuccess, data: editedInventoryAdjustment } = useUpdateInventoryAdjustment();

  const getItemCallback = useCallback(
    async (data) => {
      const result = await Promise.all(get(data, 'items', []).map(it => {
        return getItem(it.id)
      }));
      setFullItems(result)
    }, [])

  const submit = async (values) => {
    if (!can("edit", "inventory-adjustments"))
      return;

    const transformedValues = transform(values);
    const optimisticValues = transformOptimisticUpdate(values);

    mutate({ inventoryAdjustment: transformedValues, optimisticValues, filters });
  };

  useShowToastAndRedirect({ isSuccess, isPaused, isError, error, editedInventoryAdjustment });

  useEffect(() => {
    getItemCallback(data)
  }, [data, getItemCallback])


  const sendEditionFailedEvent = useCallback(() => {
    if (!data)
      return;

    const itemsNumber = get(data, 'items', []).length;
    const itemsIncreasedNumber = get(data, 'items', []).filter((i) => get(i, 'objective.key') === 'in').length;
    const itemsDecreasedNumber = itemsNumber - itemsIncreasedNumber;

    dispatch(sendGTMEvent("inventory-adjustment-edition-attempted", {
      itemsNumber,
      itemsDecreasedNumber,
      itemsIncreasedNumber,
      inventoryAdjustmentEditionStatus: false,
    }))
  }, [data, dispatch])

  return (
    <div className="container p-5">
      <div className="new-inventory-adjustment-header">
        <button className="btn-round d-flex justify-content-center align-items-center"
          onClick={() => {
            history.goBack()
            sendEditionFailedEvent()
          }}
        >
          <Icon icon={IconArrowLeft} color={"#0F172A"} />
        </button>
        <div>
          <p className="h2">{I18n.get("editInventoryAdjustment.title", "Editar ajuste de inventario")}</p>
          <p className="h4 text-muted">{I18n.get("editInventoryAdjustment.subtitle", "Cambia los datos del ajuste de inventario que habías creado.")}</p>
        </div>
      </div>

      {(isLoadingQuery || isLoadingMutation)
        ? (
          <div className="new-inventory-adjustment-form-container d-flex justify-content-center align-items-center h-2 ">
            <Icon icon={IconLoader2} animated extraClass="icon-primary icon x2" />
          </div>
        )
        : (
          <Form
            // onSubmit={submit}
            onSubmit={(values) => dispatch(checkFeatureLimit("inventoryAdjustments", () => submit(values)))}
            mutators={{
              ...arrayMutators,
            }}
            initialValues={{
              ...data,
              items: get(data, 'items', []).map((it, i) => {
                return {
                  ...it,
                  item: !!fullItems ? fullItems[i] : ""
                }
              })
            }}
            validate={values => validate(values)}
            keepDirtyOnReinitialize
          >
            {({ handleSubmit, submitting, form, values, errors }) => (
              <form noValidate onSubmit={handleSubmit}>
                <div className="new-inventory-adjustment-form-container">
                  <div className="d-flex align-items-start w-100"
                    style={{
                      height: "5.375em", padding: "16px 48px 20px", gap: "12px", borderBottom: "1px solid #E2E8F0"
                    }}
                  >
                    <div className="d-flex flex-column justify-content-center align-items-start p-0"
                      style={{ height: "50px" }}
                    >
                      <p
                        className="p-0 m-0"
                        style={{ fontStyle: "normal", fontWeight: "600", fontSize: "18px", lineHeight: "30px" }}
                      >
                        {`${I18n.get("warehouse", "Bodega")}: ${warehouse?.name}`}
                      </p>
                      {/* <p
                        className="p-0 m-0"
                        style={{ fontStyle: "normal", fontWeight: "400", fontSize: "14px", lineHeight: "20px" }}
                      >
                        Modifica las cantidades de tus productos en la bodega de tu terminal actual
                      </p> */}
                    </div>
                  </div>
                  <Body
                    form={form}
                    values={values}
                  />
                </div>

                <Total
                  values={values}
                  form={form}
                />

                <Bottom
                  onConfirm={!!get(errors?.items?.filter(a => a), '0.quantity')
                    ? () => toast.warning({
                      title: I18n.get(`${get(errors, 'items.0.quantity')}.title`, 'Indica la cantidad a ajustar'),
                      subtitle: replaceAndParse(I18n.get(`${get(errors, 'items.0.quantity')}.subtitle`, 'Ten en cuenta que los valores de la columna <b>“Cantidad”</b> deben ser diferentes a cero.')),
                    })
                    : null}
                  submitting={submitting}
                  submitText={I18n.get("saveChanges", "Guardar Cambios")}
                  onClose={() => {
                    history.goBack()
                    sendEditionFailedEvent()
                  }}
                  disabled={!!get(errors, 'noItem')}
                  tooltipActive={!!get(errors, 'noItem')}
                  tooltipMessage={I18n.get("noItemsInInventoryAdjustmentError", "Debes agregar al menos un producto a tu ajuste")}
                />

              </form>
            )

            }
          </Form>
        )
      }

    </div>
  );
};

export default EditInventoryAdjustment;
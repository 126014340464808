// eslint-disable-next-line
export default ({ id, className }) => (
  <svg
    id={id}
    className={className}
    xmlns='http://www.w3.org/2000/svg'
    width='64'
    height='64'
    viewBox='0 0 64 64'
    fill='none'
  >
    <path
      d='M41.4142 28.0809C42.1953 27.2998 42.1953 26.0335 41.4142 25.2525C40.6332 24.4714 39.3668 24.4714 38.5858 25.2525L29.3333 34.5049L25.4142 30.5858C24.6332 29.8047 23.3668 29.8047 22.5858 30.5858C21.8047 31.3668 21.8047 32.6332 22.5858 33.4142L27.9191 38.7476C28.7002 39.5286 29.9665 39.5286 30.7475 38.7476L41.4142 28.0809Z'
      fill='#30ABA9'
    />
    <path
      fill-rule='evenodd'
      clip-rule='evenodd'
      d='M32 6C17.6406 6 6 17.6406 6 32C6 46.3594 17.6406 58 32 58C46.3594 58 58 46.3594 58 32C58 17.6406 46.3594 6 32 6ZM10 32C10 19.8497 19.8497 10 32 10C44.1503 10 54 19.8497 54 32C54 44.1503 44.1503 54 32 54C19.8497 54 10 44.1503 10 32Z'
      fill='#30ABA9'
    />
  </svg>
);

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { I18n } from '@aws-amplify/core';
import { get } from 'lodash';
import { BigNumber } from 'bignumber.js';

import { closeModal } from '../../../reducers/modals';
import { country as countrySelector } from '../../../selectors/company';
import Success from '../../svg/NewSuccess';
import Modal from '../../common/Modal';
import BodyInvoiceSaved from './BodyInvoiceSaved';
import BottomInvoiceSaved from './BottomInvoiceSaved';
import InvoiceSaving from './InvoiceSaving';
import useOfflineStatusAvailable from '../../../hooks/useOfflineStatusAvailable/hook';
import useConnectionStatus from '../../../hooks/useConnectionStatus/hook';
import { useApiAvailable } from '../../../hooks/useApiAvailable';
import InvoiceOfflineSaved from './InvoiceOfflineSaved';
import { Typography } from '@alegradev/smile-ui-react';
import useNpsSurvey from '../../../hooks/useNpsSurvey/hook';
import { useFormat } from '../../../hooks/useFormat';
import { getInvoiceMessage, getTitle } from './util';
import NewBodyInvoiceSaved from './NewBodyInvoiceSaved';
import { newShareInvoiceAvailable } from '../ShareInvoice/utils';

const NewInvoiceSaved = () => {
  const { isVersionAvailable } = useOfflineStatusAvailable();
  const isOnline = useConnectionStatus();
  const isApiAvailable = useApiAvailable();

  const dispatch = useDispatch();
  const invoiceCreated = useSelector((state) =>
    get(state, 'print.invoice', null)
  );

  const isOpen = useSelector((state) =>
    get(state, 'modals.newInvoiceSaved.isOpen', false)
  );
  const country = useSelector(countrySelector);
  const items = get(invoiceCreated, 'items', null);
  const { fmt, decimal } = useFormat();
  const { handleNps } = useNpsSurvey();

  const handleClose = () => {
    dispatch(closeModal({ modal: 'newInvoiceSaved' }));
    handleNps();
    if (!items) return;
  };

  if (isVersionAvailable && (!isOnline || !isApiAvailable)) {
    return (
      <InvoiceOfflineSaved
        isOpen={isOpen}
        isOnline={isOnline}
        handleClose={handleClose}
        invoiceCreated={invoiceCreated}
      />
    );
  }

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={handleClose}
      title={getTitle(invoiceCreated, country)}
      className='modal__invoice'
      includeHeader={false}
    >
      {!!invoiceCreated && !!invoiceCreated.timestamp ? (
        <InvoiceSaving invoiceCreated={invoiceCreated} />
      ) : (
        <div>
          <div className='p-3'>
            <Typography text={getTitle()} type='heading-4' />
          </div>
          <div className='d-flex flex-column p-3 justify-content-center align-items-center'>
            <div className='p-4 d-flex flex-column justify-content-center align-items-center'>
              <Success />
              <div className='modal__invoice-success-title'>
                <Typography
                  text={I18n.get('invoiceCreatedSuccessfully', '¡Muy bien!')}
                  type='heading-4'
                />
              </div>
              <div className='modal__invoice-success-message'>
                <Typography
                  text={getInvoiceMessage(invoiceCreated, country)}
                  type='body-2-regular'
                />
              </div>
            </div>
            {!!invoiceCreated && (
              <div className='d-flex align-items-center gap-3 modal__invoice-success-total'>
                <div>
                  <Typography
                    text={I18n.get('total', 'total')}
                    type='heading-4'
                  />
                </div>
                <div>
                  <Typography
                    text={new BigNumber(invoiceCreated.total).toFormat(
                      decimal,
                      fmt
                    )}
                    type='heading-4'
                  />
                </div>
              </div>
            )}

            {newShareInvoiceAvailable({ country }) ? (
              <NewBodyInvoiceSaved />
            ) : (
              <BodyInvoiceSaved />
            )}
          </div>
        </div>
      )}
      <BottomInvoiceSaved handleClose={handleClose} />
    </Modal>
  );
};

export default NewInvoiceSaved;

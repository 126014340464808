import React from 'react';
import PropTypes from 'prop-types';
import RefundDetailsFactory from './RefundDetailsFactory';

/**
 * Component to display refund details.
 *
 * @param {Object} props - Component props.
 * @param {Object} props.refund - The refund object.
 * @param {string} props.statusColor - CSS class for status color.
 * @param {boolean} props.isTicketBaiEnabled - Flag to determine if BAI is enabled.
 * @param {string} props.dateFormat - Date format string.
 * @param {string} props.country - Country code.
 * @returns {JSX.Element} - Rendered RefundDetails Component.
 */
const RefundDetails = React.memo(
  ({ refund, statusColor, isTicketBaiEnabled, dateFormat, country }) => {
    return (
      <RefundDetailsFactory
        country={country}
        refund={refund}
        statusColor={statusColor}
        isTicketBaiEnabled={isTicketBaiEnabled}
        dateFormat={dateFormat}
      />
    );
  }
);

RefundDetails.propTypes = {
  refund: PropTypes.shape({
    cause: PropTypes.string,
    stamp: PropTypes.shape({
      date: PropTypes.string,
      cude: PropTypes.string,
      cufe: PropTypes.string,
      warnings: PropTypes.arrayOf(PropTypes.string),
    }),
    type: PropTypes.string,
  }).isRequired,
  statusColor: PropTypes.string.isRequired,
  isTicketBaiEnabled: PropTypes.bool,
  dateFormat: PropTypes.string,
  country: PropTypes.string.isRequired,
};

RefundDetails.defaultProps = {
  isTicketBaiEnabled: false,
  dateFormat: '',
};

export default RefundDetails;

import React, { cloneElement, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Typography from '../Typography';
import Button from '../Button';

import { Icon } from '@alegradev/smile-ui-react';
import { IconX } from '@tabler/icons-react';

const PopoverComponent = ({
  children,
  onRequestClose = () => console.log('close popover'),
  position = 'bottom',
  overlay = null,
  popoverActive = false,
  theme = 'dark',
  title = '',
  orientation = 'right',
  large = false,
  hideClose = false,
  trigger = 'click',
  ...props
}) => {
  const [active, setActive] = useState(false);
  
  const togglePopover = () => {
    setActive(!active);
  };

  const notchPositionMap = {
    bottom: 'top',
    top: 'bottom',
    left: 'right',
    right: 'left',
  };
  
  useEffect(() => {
    if (popoverActive && trigger === 'click') {
      setActive(true);
    }
  },[popoverActive])

  if (!active && trigger !== 'hover') return children;

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'flex-end',
        justifyContent: `flex-${position === 'left' ? 'start' : 'end'}`,
        flexDirection: `${
          ['top', 'bottom'].includes(position)
            ? `column${position === 'bottom' ? '' : '-reverse'}`
            : `row`
        }`,
        width: 'fit-content',
      }}
    >
      <div onMouseEnter={trigger === 'hover' ? togglePopover : undefined} onClick={ trigger === 'click' ? togglePopover : undefined} onMouseLeave={ trigger === 'hover' ? togglePopover : undefined}>
        {children}
      </div>
      <div className={`popover-custom ${large && 'popover-custom-large'}`} style={{
        display: active ? 'flex' : 'none',
      }}>
        <div
          className={`popover-custom-${theme}-body-notch-${notchPositionMap[position]} popover-custom-orientation-${orientation}`}
        >
          <div className='w-100 d-flex justify-content-between align-items-center'>
            <Typography type='label-1' text={title} variant='white' />
           {trigger === 'click' && <Button
              type='button'
              variant='ghost'
              size='icon'
              className={`${
                hideClose ? 'invisible' : 'visible'
              } popover-custom-close-button`}
              onClick={() => onRequestClose()}
            >
              <Icon 
                icon={IconX}
                extraClass={`icon-${theme === 'dark' ? 'white ' : 'gray'}`}
              />
            </Button>}
          </div>
          {overlay}
        </div>
      </div>
    </div>
  );
};

PopoverComponent.propTypes = {
  onRequestClose: PropTypes.func,
  position: PropTypes.oneOf(['top', 'bottom', 'right', 'left']),
  theme: PropTypes.oneOf(['dark', 'light']),
  orientation: PropTypes.oneOf(['left', 'right']),
  title: PropTypes.string,
  overlay: PropTypes.element,
  popoverActive: PropTypes.bool,
  hideClose: PropTypes.bool,
};

export default PopoverComponent;

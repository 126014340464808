import { useSelector } from 'react-redux';
import {
  country as companyCountry,
  companySelector,
} from '../../selectors/company';
import { COUNTRIES } from '../../utils/enums/countries';

export const availableIntermittenceCountries = [
  COUNTRIES.PERU,
  COUNTRIES.PANAMA,
  COUNTRIES.USA,
  COUNTRIES.COLOMBIA,
  COUNTRIES.SPAIN,
  COUNTRIES.COSTA_RICA,
  COUNTRIES.CHILE,
  COUNTRIES.MEXICO,
  COUNTRIES.REPUBLICA_DOMINICANA,
  COUNTRIES.INTERNATIONAL,
  COUNTRIES.ARGENTINA,
];
export const availableIntermittenceCompanies = [
  '1171753',
  '647807',
  '91930',
  '19235',
  '19235',
];

const useOfflineStatusAvailable = () => {
  const company = useSelector(companySelector);
  const country = useSelector(companyCountry);

  return {
    isVersionAvailable:
      availableIntermittenceCountries.includes(country) ||
      availableIntermittenceCompanies.includes(company?.id),
    isCompanyAvailable: availableIntermittenceCompanies.includes(company?.id),
  };
};

export default useOfflineStatusAvailable;

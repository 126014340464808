import { merge } from 'lodash'

import http from '../utils/http'
import Cookie from 'js-cookie';

const baseUrl = process.env.REACT_APP_CONFIG_ENDPOINT
const baseConfig = {
  headers: {
    // 'X-Data-Source': 'pos',
    // 'User-Agent' : 'Alegra Pos Api Lambda',
  }
}

const get = async (url, params = null, userConfig = null) => await http.get(`${baseUrl}${url}`, params, merge(baseConfig, userConfig), Cookie.get("pos-block-api-request"));

const post = async (url, params = null, urlParams = null, userConfig = null) => await http.post(`${baseUrl}${url}`, params, urlParams, merge(baseConfig, userConfig), Cookie.get("pos-block-api-request"));

const put = async (url, params = null, urlParams = null, userConfig = null) => await http.put(`${baseUrl}${url}`, params, urlParams, merge(baseConfig, userConfig), Cookie.get("pos-block-api-request"));

const httpDelete = async (url, urlParams = null, userConfig = null) => await http.delete(`${baseUrl}${url}`, urlParams, merge(baseConfig, userConfig), Cookie.get("pos-block-api-request"));

const configAPI = {
  get,
  post,
  put,
  delete: httpDelete,
  v2: {
    get: async (url, params = null, userConfig = null) => await http.get(`${baseUrl.replace("v1", "v2")}${url}`, params, merge(baseConfig, userConfig), Cookie.get("pos-block-api-request")),
    post: async (url, params = null, urlParams = null, userConfig = null) => await http.post(`${baseUrl.replace("v1", "v2")}${url}`, params, urlParams, merge(baseConfig, userConfig), Cookie.get("pos-block-api-request")),
    put: async (url, params = null, urlParams = null, userConfig = null) => await http.put(`${baseUrl.replace("v1", "v2")}${url}`, params, urlParams, merge(baseConfig, userConfig), Cookie.get("pos-block-api-request")),
    delete: async (url, urlParams = null, userConfig = null) => await http.delete(`${baseUrl.replace("v1", "v2")}${url}`, urlParams, merge(baseConfig, userConfig), Cookie.get("pos-block-api-request"))
  }
}

export default configAPI
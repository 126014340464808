import React from 'react';
import { I18n } from '@aws-amplify/core';
import PropTypes from 'prop-types';

import { Icon } from '@alegradev/smile-ui-react';
import { IconRotate } from '@tabler/icons-react';

const ErrorElement = ({ onRefresh }) => {
  return (
    <div className='w-100 h-100 d-flex flex-column justify-content-center align-items-center'>
      <p className='text-capitalize-first text-center'>
        {I18n.get(
          'invoicesLoadError',
          'ocurrió un error cargando las facturas de la terminal'
        )}
      </p>
      <button
        type='button'
        className='btn btn-submit'
        onClick={() => onRefresh()}
      >
        <Icon icon={IconRotate} extraClass='icon-white' />
      </button>
    </div>
  );
};

ErrorElement.propTypes = {
  onRefresh: PropTypes.func,
};

export default ErrorElement;

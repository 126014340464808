import React from 'react';
import { Field } from 'react-final-form';
import { I18n } from '@aws-amplify/core';
import { capitalize } from 'lodash';

import { renderField } from '../fields/V0/Fields';

const CloseShift = () => {

  return (
    <div className="form-body h-100 p-4 overflow-hidden">
      <div className="form-body__fields form-row justify-content-start">
        <Field
          name="expectedCash"
          className="col-12"
          component={renderField}
          type="number"
          label={capitalize(I18n.get('expectedCash', 'valor esperado en caja'))}
          help={capitalize(I18n.get('expectedCashHelp', 'valor de efectivo esperado en caja al terminar el turno'))}
          required
        />
        
        <Field
          name="observations"
          className="col-12"
          component={renderField}
          type="textarea"
          label={capitalize(I18n.get('observations', 'observaciones'))}
          small
        />
        
      </div>
    </div>
  )
}

export default CloseShift;
import Cookie from 'js-cookie';
import http from '../utils/http';

const baseUrl = process.env.REACT_APP_EXTERNAL_CLIENTS;
const baseConfig = {
  headers: {
    'X-Data-Source': 'pos',
    Authorization: `Basic ${Cookie.get(process.env.REACT_APP_ALEGRA_COOKIE)}`,
  },
};

const post = async (url, params = null, urlParams = null, userConfig = null) =>
  await http.post(
    `${baseUrl}${url}`,
    params,
    urlParams,
    Object.assign({}, baseConfig, userConfig),
    Cookie.get('pos-block-api-request')
  );

const defaultExport = {
  post,
};

export default defaultExport;

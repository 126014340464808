import { I18n } from '@aws-amplify/core';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

import Page from '../svg/illustrations/Page';

function PageNetworkMessage({ fullPage }) {
  const history = useHistory();

  return (
    <div
      className={`network-message vw-10 d-flex align-items-center justify-content-center ${
        !fullPage && 'dynamic-height'
      }`}
    >
      <div className='network-message-body'>
        <Page />
        <section className='network-message-body-info'>
          <h2>
            {' '}
            {I18n.get('connectionIssueTitle', 'Trabajando sin internet')}
          </h2>
          <p className='mb-0'>
            {I18n.get(
              'connectionIssueDescription',
              'Podrás consultar tus datos al recuperar la conexión.'
            )}
          </p>
          <button
            type="button"
            className="btn btn-primary button-menu-action text-lowercase text-capitalize-first"
            onClick={() => history.push('/')}
          >
            {I18n.get('goToMenu', 'Ir al menu de')}{" "}{I18n.get('checkIn', 'Facturar')}
          </button>
        </section>
      </div>
    </div>
  );
}

PageNetworkMessage.propTypes = {
  fullPage: PropTypes.bool,
};

PageNetworkMessage.defaultProps = {
  fullPage: true,
};

export default PageNetworkMessage;

import React from 'react'
import { I18n } from '@aws-amplify/core';
import { useHistory } from 'react-router-dom';
import { capitalize } from 'lodash';

const EmptyDetail = () => {
  const history = useHistory();

  return (
    <div className="p-4 w-100 h-100 d-flex align-items-center justify-content-center overflow-hidden">
      <div className="d-flex flex-column align-items-center card p-5">
        <p className="text-capitalize-first h3 mb-3 text-center">
          {I18n.get('notInvoiceYet', 'aún no tienes ventas creadas desde POS')}{' '}
          <a href="https://ayuda.alegra.com/es/facturaci%C3%B3n-%C3%A1gil-en-el-punto-de-venta-pos" target="_blank" rel="noreferrer">
            {capitalize(I18n.get('seeMore', 'Ver más'))}
          </a>
        </p>
        <button
          type="button"
          className="btn btn-submit"
          onClick={() => history.push('/')}
        >
          {I18n.get('newSale', 'nueva venta')}
        </button>
      </div>
    </div>
  )
}

export default EmptyDetail;
import Numerations from './Numerations';
import Bank from './Bank';
import Warehouse from './Warehouse';
import OpenShift from './OpenShift';

const stationFormParts = {
  Numerations,
  Bank,
  OpenShift,
  Warehouse,
}

export default stationFormParts;
import React from 'react';
import PropTypes from 'prop-types';


const Notification = ({ isOpen, type, text }) => (
  <div className={`side-modal__notification side-modal__notification-${!!type ? type : 'error'} ${isOpen ? 'open' : 'closed'}`}>
    <div className="side-modal__notification-text text-left p-3 text-capitalize-first">
      {text}
    </div>
  </div>
);

Notification.propTypes = {
  isOpen: PropTypes.bool,
  type: PropTypes.string,
  text: PropTypes.string,
}

export default Notification;
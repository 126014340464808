import React from 'react'
import { I18n } from '@aws-amplify/core';
import { useSelector } from 'react-redux'
import { get } from 'lodash'
import dayjs from 'dayjs'

import { membershipSelector } from '../../selectors/company'
import { isHappyWeekAvailable } from '../../selectors/membership';
import { replaceAndParse } from '../../utils'

const MembershipNotification = () => {
  const membership = useSelector(membershipSelector);
  const happyWeek = useSelector(isHappyWeekAvailable);

  const isPlanDemo = get(membership, 'plan.keyword', '').includes('demo');
  const isPlanReadonly = get(membership, 'plan.keyword', '').includes('readonly');

  const dateStart = get(membership, 'dateStart')
  const dateEnd = get(membership, 'dateEnd')

  const totalTime = dayjs(dateEnd).diff(dateStart, 'days')
  const timeLeft = dayjs(dateEnd).diff(dayjs().startOf('day'), 'days')

  return (
    <div
      className="d-block d-md-none"
      style={{
        paddingRight: '1rem'
      }}
    >
      <div className="d-flex align-items-start">
        <div>
          <svg width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="0.525146" y="0.0301514" width="31.9397" height="31.9397" rx="15.9698" fill="#FBE8D2" fillOpacity="0.77" />
            <path d="M23.1314 20.3384H9.84109V21.3233H23.1314V20.3384Z" fill="#F99D36" />
            <path d="M16.4952 10.6767L14.2592 13.5463L9.84106 10.7276V18.8442H23.1493V10.7276L18.7311 13.5463L16.4952 10.6767Z" fill="#F99D36" />
          </svg>
        </div>

        <div className="d-flex flex-column align-items-start justify-content-between w-100 p-1 px-3">
          <div className="m-0 h4">
            {(!!get(membership, 'plan.name')) && <b className="text-title">{get(membership, 'plan.name')}:</b>}

            {happyWeek && (isPlanDemo || isPlanReadonly)
              ? (
                <p className="m-0 h5 d-inline">{' '}
                  {isPlanDemo && replaceAndParse(I18n.get('alegraWeekIsHere1.demo', 'Elige tu plan hoy con 50% OFF por 2 meses o continúa tus {} {} gratis.'), [
                    `<span class='font-weight-bold'>${timeLeft > 1 ? timeLeft : 0}</span>`,
                    `<span class='font-weight-bold'>${timeLeft > 1 ? I18n.get('days', 'días') : I18n.get('day', 'día')}</span>`
                  ])}
                  {isPlanReadonly && I18n.get('alegraWeekIsHere1.readOnly', '¡Llegó el Happy Week con grandes ofertas!')}
                </p>
              )
              : (
                <p className="m-0 h5 d-inline">
                  {' '}{replaceAndParse(I18n.get('isMomentToChoseYourPlan', 'Es momento de elegir tu plan ideal para seguir vendiendo fácil y rápido en tu negocio.'), [])}{' '}
                </p>
              )
            }
          </div>

          <div
            className="progress w-100"
            style={{
              marginTop: '.5rem',
              height: '.5rem',
              background: '#FCEEDD'
            }}
          >
            <div
              className="progress-bar"
              role="progressbar"
              style={{
                width: `${(totalTime - timeLeft) / totalTime * 100}%`,
                background: '#F99D36'
              }}
              aria-valuenow={+`${(totalTime - timeLeft) / totalTime * 100}` || 0}
              aria-valuemin="0"
              aria-valuemax="100"
            />
          </div>
        </div>

        <div>
          <button
            type="button"
            className="btn text-capitalize-first"
            onClick={() => window.open(`${process.env.REACT_APP_ALEGRA_SMILE}subscriptions/products/alegra-pos/plans`, '_blank')}
            style={{
              margin: 0,
              minWidth: '10rem',
              height: '3.2rem',
              border: '1px solid #F99D36',
              boxSizing: 'border-box',
              borderRadius: '31px',
              fontSize: '1.1rem',
              fontWeight: 900,
              color: '#F99D36',
              padding: '0 .25rem',
              outline: 'none',
              '&:hover': {
                '&:not(:disabled)': {
                  border: '1px solid #F99D36 !important',
                }
              },
              '&:active': {
                '&:not(:disabled)': {
                  border: '1px solid #F99D36 !important',
                }
              },
              WebkitAppearance: 'none',
              MozAppearance: 'none',
              appearance: 'none',
            }}
          >
            {I18n.get('chooseMyPlan', 'Elegir mi plan')}
          </button>
        </div>
      </div>
    </div>
  )
}

export default MembershipNotification
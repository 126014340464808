import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';
import { get } from 'lodash'

import { handleError } from '../utils/errors'
import alegraAPI from '../reducers/alegraAPI';
import { fetch, getInitialState } from '../utils';
import { hasPermissionTo } from '../selectors/auth';


const initialState = {
  entities: {},
  ids: [],
  loading: 'idle',
  error: null,
  version: 1
}


const fetchVariantsPartialFromAlegra = (api) => async (metadata, params) => {

  const response = await alegraAPI.get('/variant-attributes', {
    metadata: true,
    fields: 'deletable,editable,options.editable',
    ...metadata,
    ...params
  })
  const data = get(response, 'data.data', null)
  const total = get(response, 'data.metadata.total', 0)
  if (data)
    return { data, total };
  return { data: [], total };
}

export const fetchVariants = createAsyncThunk(
  'variants/fetch',
  async ({ preloaded, ...params }, { rejectWithValue, getState }) => {
    try {
      const can = hasPermissionTo(getState())
      if (!can('view', 'variant-attributes') || !can('index', 'variant-attributes'))
        return []

      return await fetch(fetchVariantsPartialFromAlegra(alegraAPI), preloaded ?? [], params)
    } catch (error) {
      return rejectWithValue(handleError(error))
    }
  }
)

export const adapter = createEntityAdapter();

const appSlice = createSlice({
  name: 'variants',
  initialState: getInitialState('variants', initialState),
  reducers: {
    addVariant: adapter.addOne,
    updateVariant: adapter.updateOne,
    removeVariant: adapter.removeOne,
  },
  extraReducers: builder => {
    builder.addCase(fetchVariants.fulfilled, (state, action) => {
      adapter.setAll(state, action.payload)
      state.loading = 'idle'
      state.error = null
    })
    builder.addCase(fetchVariants.pending, (state) => {
      state.loading = 'loading'
      state.error = null
    })
    builder.addCase(fetchVariants.rejected, (state, action) => {
      state.loading = 'idle'
      state.error = action.payload
    })
  }
});

const { reducer, actions } = appSlice;

export const { addVariant, updateVariant, removeVariant } = actions

export const variantsSelector = adapter.getSelectors(state => state.variants);

export default reducer;
import { createDraftSafeSelector } from '@reduxjs/toolkit';
import { get } from 'lodash';

const userSelector = state => get(state, 'auth', null);
const companySelector = state => get(state, 'auth.company', null);

export const seenWelcome = createDraftSafeSelector(
  userSelector,
  user => get(user, 'seenWelcome', false)
)

export const seenTutorialWizard = createDraftSafeSelector(
  companySelector,
  company => {
    const seenTutorialWizardPOS = get(company, 'localSettings.seenTutorialWizardPOS', false);

    return (get(company, 'seenTutorialWizard', false) || seenTutorialWizardPOS)
  }
)
import React, { useEffect, useRef, forwardRef } from 'react'
import { I18n } from '@aws-amplify/core';
import { capitalize, get } from 'lodash'
import PropTypes from 'prop-types';
import { useTable, useBlockLayout, usePagination, useRowSelect, } from 'react-table';

import Pagination from './Pagination';
import { useConnectionStatus } from '../../../hooks/useConnectionStatus';
import TableNetworkMessage from '../../network/TableNetworkMessage';

import { Icon } from '@alegradev/smile-ui-react';
import { IconLoader2 } from '@tabler/icons-react';

// La funcion a continuacion fue adaptada de:
// https://github.com/TanStack/table/issues/2988

const getConditionalSelectHeaderCheckboxProps = ({
  headerProps,
  checkIfRowIsSelectable,
  shouldSelectPage = true,
}) => {
  const checkIfAllSelectableRowsSelected = (rows) =>
    rows.filter(checkIfRowIsSelectable).every(row => row.isSelected)

  const isSelectPage =
    shouldSelectPage &&
    headerProps.page
      .filter(checkIfRowIsSelectable)
      .some(row => !row.isSelected)

  const checkboxProps = isSelectPage
    ? headerProps.getToggleAllPageRowsSelectedProps()
    : headerProps.getToggleAllRowsSelectedProps()

  const disabled = headerProps.rows.filter(checkIfRowIsSelectable).length === 0
  const checked = !disabled && checkIfAllSelectableRowsSelected(headerProps.rows)
  const indeterminate = !checked && headerProps.rows.some(row => row.isSelected)

  const onChange = () => {
    if (!isSelectPage && checkIfAllSelectableRowsSelected(headerProps.rows)) {
      headerProps.rows.forEach(row => {
        headerProps.toggleRowSelected(row.id, false)
      })
    } else {
      const rows = isSelectPage ? headerProps.page : headerProps.rows
      rows.forEach(row => {
        const checked = checkIfRowIsSelectable(row)
        headerProps.toggleRowSelected(row.id, checked)
      })
    }
  }
  return {
    ...checkboxProps,
    checked,
    indeterminate,
    onChange,
    disabled,
  }
}

const IndeterminateCheckbox = forwardRef(({ indeterminate, ...rest }, ref) => {
  const defaultRef = useRef()
  const resolvedRef = ref || defaultRef

  useEffect(() => {
    resolvedRef.current.indeterminate = indeterminate
  }, [resolvedRef, indeterminate])

  return (
    <>
      <input type="checkbox" ref={resolvedRef} {...rest} disabled={get(rest, 'isVariant')} />
    </>
  )
}
)

const ItemsTable = ({ columns, data, loading, onFetchData, noDataText, error, setSelectedIds, onRefresh, _pageSize, controlledPageCount, setFilters, filters, showNetworkConnectionError= false }) => {
  const connectionStatus = useConnectionStatus()
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    pageCount,
    canPreviousPage,
    canNextPage,
    pageOptions,
    gotoPage,
    nextPage,
    previousPage,
    selectedFlatRows,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      manualPagination: !!onFetchData,
      pageCount: !!controlledPageCount ? controlledPageCount : Math.ceil(data.length / _pageSize),
      initialState: { pageSize: _pageSize || 10 },
    },
    useBlockLayout,
    usePagination,
    useRowSelect,
    hooks => {
      hooks.visibleColumns.push(columns => [
        {
          id: 'selection',
          Header: (props) => {
            const checkboxProps = getConditionalSelectHeaderCheckboxProps({
              headerProps: props,
              checkIfRowIsSelectable: row => row.original.type !== 'variant'
            })
            return (
              <>
                <IndeterminateCheckbox {...checkboxProps} title={capitalize(I18n.get('all', 'Todos'))} />
              </>
            )
          },
          Cell: ({ row }) => (
            get(row, 'original.type') !== 'variant' &&
            <>
              <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} title="" />
            </>
          )
          ,
        },
        ...columns,
      ])
    }
  );

  useEffect(() => {
    const fetchData = () => {
      if (!!onFetchData) {
        onFetchData({ pageIndex, pageSize })
      }
    }
    fetchData();
  }, [pageIndex, pageSize, onFetchData]);

  useEffect(() => {
    setSelectedIds(selectedFlatRows)
    // eslint-disable-next-line
  }, [selectedFlatRows.length, setSelectedIds]);


  const handleChangeIndex = (event, value) => {
    let page;
    if (event)
      page = event.target.value ? +event.target.value - 1 : 0;
    else
      page = value;
    gotoPage(page);
  };

  const handleChangePageSize = (value) => {
    setPageSize(+value);
  };

  const showConnectionError = () => {
    return !connectionStatus &&  showNetworkConnectionError;
  }
  
  return (
    <>
      <div className='react-table-gray w-100 table-responsive position-relative'>
        <table {...getTableProps()} className='react-table-gray__table table'>
          <thead className='react-table-gray__head shadow'>
            {headerGroups.map((headerGroup, index) => (
              <tr
                key={index}
                className='w-100'
                {...headerGroup.getHeaderGroupProps()}
              >
                {headerGroup.headers.map((column, i) => (
                  <th
                    key={i}
                    className='react-table-gray__th text-left h4'
                    style={{ maxWidth: i === 0 ? '40px' : '' }}
                  >
                    {column.render('Header')}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody
            className={`react-table-gray__body w-100 d-block position-relative ${
              page.length === 0 || !connectionStatus ? 'no-data' : ''
            }`}
            {...getTableBodyProps()}
          >
            {page.map((row, i) => {
              prepareRow(row);
              return (
                <tr
                  key={i}
                  className='react-table-gray__body-tr w-100'
                  {...row.getRowProps()}
                >
                  {row.cells.map((cell, index) => {
                    return (
                      <td
                        key={index}
                        className='react-table-gray__td react-table-gray__body-td'
                        {...cell.getCellProps()}
                        style={{ maxWidth: index === 0 ? '40px' : '' }}
                      >
                        {cell.render('Cell')}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>

        {!loading && showConnectionError() && (
            <TableNetworkMessage
              onReload={() => onRefresh({ pageIndex, pageSize })}
            />
          )}

        {loading && (
          <div className='react-table-gray__loading position-absolute d-flex align-items-center justify-content-center h-2 p-3'>
            <Icon icon={IconLoader2} animated extraClass=' icon-primary icon x2' />
          </div>
        )}

        {!loading && page.length === 0 && !showConnectionError() && (
          <div className='react-table-gray__no-data position-absolute d-flex align-items-center justify-content-center p-3'>
            {noDataText}
          </div>
        )}

        {!loading && !data && !!error && !showConnectionError() && (
          <div className='react-table__no-data position-absolute d-flex flex-column align-items-center justify-content-center h-2 p-3'>
            <p>{error}</p>

            {!!onRefresh && (
              <button
                type='button'
                className='btn btn-submit'
                onClick={() => onRefresh({ pageIndex, pageSize })}
              >
                {I18n.get('retry', 'reintentar')}
              </button>
            )}
          </div>
        )}
      </div>

      <Pagination
        previousPage={previousPage}
        nextPage={nextPage}
        pageOptions={pageOptions}
        pageCount={pageCount}
        canPreviousPage={canPreviousPage}
        canNextPage={canNextPage}
        pageIndex={pageIndex}
        handleChangePageSize={(value) => handleChangePageSize(value)}
        handleChangeIndex={(e, value) => handleChangeIndex(e, value)}
        onRefresh={({ pageIndex, pageSize }) =>
          onRefresh({ pageIndex, pageSize })
        }
        pageSize={pageSize}
      />
    </>
  );
}

ItemsTable.propTypes = {
  colums: PropTypes.array,
  data: PropTypes.arrayOf(PropTypes.object),
  onFetchData: PropTypes.func,
  loading: PropTypes.bool,
  noDataText: PropTypes.object,
  showNetworkConnectionError: PropTypes.bool
}

ItemsTable.defaultProps = {
  showNetworkConnectionError: false
}

export default ItemsTable;

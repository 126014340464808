const creditNoteTypes = [
  {
    id: 1,
    value: "Devolución de parte de los bienes; no aceptación de partes del servicio",
    key: "PARTIALL_DEVOLUTION"
  },
  {
    id: 2,
    value: "Anulación de factura electrónica",
    key: "VOID_ELECTRONIC_INVOICE"
  },
  {
    id: 3,
    value: "Rebaja total aplicada",
    key: "TOTAL_REDUCTION"
  },
  {
    id: 4,
    value: "Descuento total aplicado",
    key: "TOTAL_DISCOUNT"
  },
  {
    id: 5,
    value: "Rescisión: nulidad por falta de requisitos",
    key: "RECISSION"
  },
  {
    id: 6,
    value: "Otros",
    key: "OTHER"
  }
]


export default creditNoteTypes;
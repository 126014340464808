// src/components/inventoryAdjustment/InventoryAdjustmentModal.jsx
import React from "react";
import Modal from '../../common/Modal';
import Bottom from "../../modals/common/Bottom";
import { I18n } from "@aws-amplify/core";
import { Icon } from '@alegradev/smile-ui-react';
import { IconAlertTriangle } from "@tabler/icons-react";

const InventoryAdjustmentModal = React.memo(({ isOpen, onRequestClose, onConfirm }) => {
  return (
    <Modal
      title={I18n.get("changeCostConfirmationModal.title", "Ajuste del costo promedio")}
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      className="modal__inventory-adjustment"
    >
      <div className="modal__inventory-adjustment-body d-flex flex-column align-items-center justify-content-center text-center p-5" style={{ gap: "2rem" }}>
        <div
          className="d-flex align-items-center justify-content-center mt-5"
          style={{ width: "40px", height: "40px", background: "#FEF3C7", borderRadius: "29px" }}>
          <Icon icon={IconAlertTriangle} />
        </div>
        <p className="h1">{I18n.get("changeCostConfirmationModal.content.title", "¿Deseas ajustar el costo?")}</p>
        <p className="h3 mb-5">{I18n.get("changeCostConfirmationModal.content.body", "Al cambiar el costo en una disminución de inventario pueden generarse diferencias en tus reportes de rentabilidad.")}</p>
      </div>
      <Bottom
        onClose={onRequestClose}
        onConfirm={onConfirm}
        submitText={I18n.get("changeCostConfirmationModal.confirm", "Sí, deseo ajustar el costo")}
        hideRequired
      />
    </Modal>
  )
});

export default InventoryAdjustmentModal;

import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { I18n } from '@aws-amplify/core';
import { capitalize } from 'lodash';

import { initApp } from '../../reducers/app';
import { cleanDB } from '../../database/database';
import { error as errorSelector } from '../../selectors/app';
import store from 'store2';

function LoadError() {
  const dispatch = useDispatch();
  const error = useSelector(errorSelector);
  const isOfflineData = error === 'Offline data';

  return (
    <div className="app d-flex justify-content-center align-items-center">
      <div className="card app-error rounded p-5">
        <div className="app-error-img-wrapper">
          <img alt="error" src="/error.png" />
        </div>
        <h2 className="mb-4 text-capitalize-first">
          {I18n.get('initAppError', 'Ups! El POS no se ha podido cargar')}
        </h2>

        <p className="text-capitalize-first text-left">
          {!isOfflineData
            ? I18n.get('initAppErrorMessage', 'Por favor intenta alguna de las siguientes acciones:')
            : I18n.get('initAppOfflineDataErrorMessage', 'Hay datos que no han sido sincronizados pertenecientes a otra terminal. Si continúa podría perderlos')
          }
        </p>

        <ul>
          {!!isOfflineData && (
            <li>{I18n.get('makeSureYouAreRegisteredWithTheLastStation', 'Asegurate de estar registrado en la última estación con la que se trabajó')}</li>
          )}

          {!!isOfflineData && (
            <li>
              <a className="btn-link" href={process.env.REACT_APP_ALEGRA} target="_blank" rel="noreferrer">
                {I18n.get('goToAlegra', 'Ir a Alegra')}
              </a>
            </li>
          )}

          <li>
            <button
              type="button"
              className="btn btn-link"
              onClick={async () => {
                if (isOfflineData) {
                  await cleanDB();
                  store.remove('lastSyncDatetime');
                }
                dispatch(initApp())
              }}
            >
              {!isOfflineData
                ? capitalize(I18n.get('reloadPage', 'recarga la página'))
                : capitalize(I18n.get('continue', 'continuar'))
              }
            </button>
            {!isOfflineData
              ? ` ${I18n.get('orRestartYourBrowser', 'o reinicia tu navegador')}`
              : ` ${I18n.get('andLoseSavedData', 'y perder datos guardados')}`
            }
          </li>

          {!isOfflineData && (
            <li>{I18n.get('makeSureYouHaveTheNecessaryPermissions', 'Asegurate de tener los permisos necesarios para ingresar al POS')}</li>
          )}

          {!isOfflineData && (
            <li>
              <a className="btn-link" href={process.env.REACT_APP_ALEGRA} target="_blank" rel="noreferrer">
                {I18n.get('goToAlegra', 'Ir a Alegra')}
              </a>
            </li>
          )}
        </ul>

      </div>
    </div>
  )

}

export default LoadError;

import { Icon, Typography } from '@alegradev/smile-ui-react';
import { I18n } from 'aws-amplify';
import { replaceAndParse } from '../../../utils';
import BigNumber from 'bignumber.js';
import { useFormat } from '../../../hooks/useFormat';
import { useSelector } from 'react-redux';
import { country as countrySelector } from '../../../selectors/company';
import { useMemo } from 'react';
import { get } from 'lodash';
import useInvoiceChange from '../../modals/invoice/hooks/useInvoiceChange';
import { getHelpLink, HELP_LINKS } from './utils';
import { IconInfoCircle } from '@tabler/icons-react';

export const CreditLimitNotification = ({
  client,
  values,
  total,
  totalToCollect,
  setCanCredit,
}) => {
  const { fmt, decimal } = useFormat();
  const country = useSelector(countrySelector);
  const { change, received } = useInvoiceChange(values, total);

  const isAbleToCredit = (creditLimit, change, totalToCollect) => {
    const bigTotal = new BigNumber(total);

    if (creditLimit === null || bigTotal.isLessThanOrEqualTo(received)) {
      return true;
    }

    const bigCreditLimit = new BigNumber(creditLimit);
    const bigTotalToCollect = new BigNumber(totalToCollect);

    let totalCredit = bigTotalToCollect;

    if (change.isGreaterThanOrEqualTo(0)) {
      totalCredit = bigTotalToCollect.plus(change);
    }

    return bigCreditLimit.isGreaterThanOrEqualTo(totalCredit);
  };

  const canCreditInvoice = useMemo(() => {
    const creditLimit = get(client, 'creditLimit', null);
    const canCredit = isAbleToCredit(creditLimit, change, totalToCollect);
    setCanCredit(canCredit);
    return canCredit;
  }, [client, change, totalToCollect, setCanCredit]);

  const creditLimitAmount = useMemo(() => {
    const creditLimit = get(client, 'creditLimit', null);
    return creditLimit ? new BigNumber(creditLimit) : new BigNumber(0);
  }, [client]);

  const firstLoad = useMemo(() => received === null, [received]);

  const renderNotificationText = (creditLimitAmount) => {
    if (creditLimitAmount.isEqualTo(0)) {
      return replaceAndParse(
        I18n.get(
          'creditLimit.notification.description.zero',
          'Con esta venta el cliente supera el límite que le asignaste, puedes registrar el pago total o conocer {{link}}'
        ),
        [
          `<a target='_blank' href='${getHelpLink(country)}'>${I18n.get('creditLimit.notification.link', 'cómo aumentar su crédito ↗')}</a>`,
        ]
      );
    }

    return replaceAndParse(
      I18n.get(
        'creditLimit.notification.description',
        'Con esta venta el cliente supera los {{amount}} del límite que le asignaste, puedes registrar el pago total o conocer {{link}}'
      ),
      [
        `<b>${creditLimitAmount.toFormat(decimal, fmt)}</b>`,
        `<a target='_blank' href='${getHelpLink(country)}'>${I18n.get('creditLimit.notification.link', 'cómo aumentar su crédito ↗')}</a>`,
      ]
    );
  };

  return !canCreditInvoice && !firstLoad && received !== null ? (
    <div className='credit-limit-container'>
      <div>
        <Icon icon={IconInfoCircle} color='#B45309' />
      </div>
      <div className=''>
        <Typography
          type='body-3-bold'
          text={I18n.get(
            'creditLimit.notification.title',
            'Límite de crédito excedido 🚫'
          )}
        />

        <p className='mt-2 body-3-regular'>
          {renderNotificationText(creditLimitAmount)}
        </p>
      </div>
    </div>
  ) : null;
};

export default CreditLimitNotification;

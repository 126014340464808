import { useDispatch, useSelector } from 'react-redux';
import { I18n } from '@aws-amplify/core';

import { get, uniqueId } from 'lodash';
import { closeModal } from '../../../reducers/modals';

import Modal from '../../common/Modal';
import Typography from '../../common/Typography';
import Button from '../../common/Button';

function ErrorDetailModal() {
  const dispatch = useDispatch();
  const isOpen = useSelector((state) =>
    get(state, 'modals.errorDetail.isOpen', false)
  );
  const params = useSelector((state) =>
    get(state, 'modals.errorDetail.params', false)
  );
  const items = get(params, 'items', []);

  const toggleCloseModal = () => {
    dispatch(closeModal({ modal: 'errorDetail' }));
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={() => toggleCloseModal()}
      includeHeader={false}
      className='modal__mobile-bottom modal__rounded'
    >
      <div className='error-detail-modal'>
        <div className='error-detail-modal-body'>
          <Typography
            type='body-1-bold'
            variant='primary'
            text={get(params, 'title', '')}
          />
          <Typography
            type='body-3-regular'
            variant='secondary'
            text={get(params, 'description', '')}
          />
          <div className='error-detail-modal-body-table mt-4'>
            <table>
              <colgroup>
                <col className='one' />
                <col className='two' />
              </colgroup>
              <tr>
                <th>
                  <Typography
                    type='label-3'
                    variant='secondary'
                    text={get(params, 'detailHeaderName', '')}
                  />
                  <div className='separator' />
                </th>
                <th>
                  <Typography
                    type='label-3'
                    variant='secondary'
                    text={get(params, 'detailHeaderDescription', '')}
                  />
                </th>
              </tr>
              {items.map((item) => (
                <tr key={uniqueId()}>
                  <td>
                    <Typography
                      type='body-3-regular'
                      variant='secondary'
                      text={get(item, 'name', '')}
                    />
                  </td>
                  <td>
                    <Typography
                      type='body-3-regular'
                      variant='secondary'
                      text={get(item, 'error', '')}
                    />
                  </td>
                </tr>
              ))}
            </table>
          </div>
        </div>
        <div className='error-detail-modal-actions mt-3'>
          <Button
            size='md'
            buttonType='default'
            onClick={() => {
              if (params?.onSuccess) {
                params.onSuccess();
              }
              toggleCloseModal();
            }}
          >
            <Typography
              type='label-1'
              variant='inverse'
              text={
                get(params, 'closeButtonText', '') ||
                I18n.get('understood', 'Entendido')
              }
            />
          </Button>
        </div>
      </div>
    </Modal>
  );
}

export default ErrorDetailModal;

import React from 'react'
import { useDispatch } from 'react-redux';
import { I18n } from 'aws-amplify';
// import Cookies from 'js-cookie';
import { useHistory } from 'react-router-dom'

import { closeModal } from '../../../../reducers/modals'

import imageTermsAndConditions from '../../../../assets/images/image_terms_and_conditions.png'

const NewTermsFinished = ({ setStep }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const close = () => {
    dispatch(closeModal({ modal: 'newTermsAndConditionsColombia' }))
    setStep(0)
    // Cookies.remove('asklaterT&C')
  }

  const closeAndRedirect = (e) => {
    e.preventDefault();
    close();
    history.push("/settings/company");
  }

  return (
    <>
      <div className="d-flex flex-column justify-content-center align-items-center">
        <img src={imageTermsAndConditions} style={{ width: '100%' }} alt="" />
        <div className="my-4 px-4">
          <p className="h1 text-capitalize-first mb-3">
            {I18n.get('contractUpdated.success', '¡El contrato se actualizó correctamente!')}
          </p>
          <p className="h4 text-capitalize-first mb-5">
            {I18n.get('contractUpdated.info1', 'Consulta o descarga el contrato con los')}{" "}
            <u className="text-primary" role="button">{I18n.get('newTermsAndConditions', 'nuevos términos y condiciones').toLowerCase()}</u>
          </p>
          <p className="h5">
            {I18n.get('contractUpdated.info2', '* Podrás acceder a tu contrato de facturación desde')}{" "}
            <u className="text-primary" role="button" onClick={closeAndRedirect}>{I18n.get('myBusiness', 'Mi negocio')}</u>
          </p>
        </div>
      </div>

      <div className="modal__bottom w-100 p-3 d-flex justify-content-end align-items-center shadow">
        <div className="modal__bottom-buttons d-flex">
          <button
            type="button"
            data-testid="modal-submit-btn"
            onClick={close}
            className="btn btn-submit text-capitalize-first"
          >
            {I18n.get('joyride.last', 'Finalizar')}
          </button>
        </div>
      </div>
    </>
  )
}

export default NewTermsFinished
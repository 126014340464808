import React, { useEffect, useState, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux';

import { isOpen } from '../../selectors/shifts';
import { getShiftPayments } from '../../reducers/shifts';
import { setPrint } from '../../reducers/print';
import ShiftsList from './ShiftsList'
import ShiftsCurrent from './ShiftsCurrent'
import ShiftDetail from './ShiftDetail'
import PageNetworkMessage from '../network/PageNetworkMessage';
import { useConnectionStatus } from '../../hooks/useConnectionStatus';

const Shifts = () => {
  const connectionStatus = useConnectionStatus();
  const dispatch = useDispatch();
  const shiftOpen = useSelector(isOpen)
  const [listView, setListView] = useState(false);
  const [selected, setSelected] = useState();
  const loadShiftPayments = useCallback(() => {
    if (!!shiftOpen)
      dispatch(getShiftPayments());
  },[shiftOpen, dispatch])

  useEffect(() => {
    window.dataLayer.push({
      'event': 'VirtualPageview',
      'virtualPageURL': '/shifts',
      'virtualPageTitle': 'Shifts'
    });
  }, [])

  useEffect(() => {
    loadShiftPayments()
  }, [loadShiftPayments]);

  useEffect(() => {
    if (!!selected)
      dispatch(setPrint({ type: 'shift', value: selected }));
  }, [selected, dispatch]);

  if(!connectionStatus) {
    return <PageNetworkMessage />
  }

  return (
    <div className="shifts d-flex w-100 h-100 position-relative">
      <ShiftsList
        isListOpen={listView}
        selected={selected}
        onSelect={shift => {
          setSelected(shift)
          setListView(false);
        }}
        onShowCurrent={() => {
          setListView(false);
          setSelected(null);
        }}
      />

      {!!selected
        ? <ShiftDetail shift={selected} onShowList={() => setListView(true)} />
        : <ShiftsCurrent onShowList={() => setListView(true)} loadShiftPayments={loadShiftPayments} />
      }

    </div>
  )
}

export default Shifts;
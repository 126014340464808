import React from 'react';
import PropTypes from 'prop-types';
import { I18n } from '@aws-amplify/core';
import dayjs from 'dayjs';
import BigNumber from 'bignumber.js';
import { formatDate } from '../utils';

/**
 * Component to display refunds section.
 *
 * @param {Object} props - Component props.
 * @param {Array} props.refunds - List of refunds.
 * @param {Object} props.fmt - Formatting options.
 * @param {number} props.decimal - Number of decimal places.
 * @param {string} props.dateFormat - Date format string.
 * @returns {JSX.Element} - Rendered component.
 */
const RefundsSection = React.memo(({ refunds, fmt, decimal, dateFormat }) => (
  <>
    <p className='text-capitalize-first body-3-bold'>
      {I18n.get('refunds', 'devoluciones')}
    </p>

    <div className='detail-items-table-wrapper mb-4'>
      <table className='detail-items-table rounded-lg'>
        <thead>
          <tr>
            <th className='caption-bold'>{I18n.get('date', 'fecha')}</th>
            <th className='caption-bold'>
              {I18n.get('bankAccount', 'cuenta bancaria')}
            </th>
            <th className='caption-bold'>
              {I18n.get('annotations', 'observaciones')}
            </th>
            <th className='caption-bold'>{I18n.get('amount', 'monto')}</th>
          </tr>
        </thead>
        <tbody>
          {refunds.map((refund, index) => (
            <tr key={index}>
              <td>{formatDate(refund.date, dateFormat)}</td>
              <td>{refund?.account?.name || ''}</td>
              <td>{refund.observations || ''}</td>
              <td>{new BigNumber(refund.amount).toFormat(decimal, fmt)}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  </>
));

RefundsSection.propTypes = {
  refunds: PropTypes.arrayOf(
    PropTypes.shape({
      date: PropTypes.string.isRequired,
      account: PropTypes.shape({
        name: PropTypes.string,
      }),
      observations: PropTypes.string,
      amount: PropTypes.number.isRequired,
    })
  ).isRequired,
  fmt: PropTypes.object.isRequired,
  decimal: PropTypes.number.isRequired,
  dateFormat: PropTypes.string,
};

export default RefundsSection;

import { I18n } from '@aws-amplify/core';
import { get, capitalize } from 'lodash';

export const validate = values => {
  let errors = {}
  
  const name = get(values, 'name', '');
  
  if (!name)
    errors.name = capitalize(I18n.get('enterAName', 'Ingresa un nombre'));

  return errors;
}

export const transform = values => {
  return {
    name: get(values, 'name', ''),
    identification: get(values, 'identification', ''),
    observations: get(values, 'observations', ''),
  }
}
import { I18n } from '@aws-amplify/core';
import { get, capitalize } from 'lodash';

export const validate = values => {
  let errors = {}

  const code = get(values, 'currency.code', '');
  const exchangeRate = get(values, 'exchangeRate', '');

  if (!code) {
    errors.currency = capitalize(I18n.get('thisFieldIsRequired', 'Este campo es obligatorio'))
  }

  if (!exchangeRate)
    errors.exchangeRate = capitalize(I18n.get('thisFieldIsRequired', 'Este campo es obligatorio'));

  return errors;
}

export const transform = values => {
  return {
    code: get(values, 'currency.code', ''),
    exchangeRate: get(values, 'exchangeRate', ''),
  }
}
import { I18n } from '@aws-amplify/core';
import { get } from 'lodash';

export const validate = (values) => {
  let errors = {};
  
  if (!get(values, 'cause', null))
    errors.amount = I18n.get('thisFieldIsRequired', 'Este campo es obligatorio')
 
  return errors;
}

import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { I18n } from '@aws-amplify/core';
import { graphqlOperation } from '@aws-amplify/api';
import { Form } from 'react-final-form';
import { get } from 'lodash'

import * as mutations from '../../../graphql/mutations';
import { formError } from '../../../utils/errors';
import { toast } from '../../../utils';
import { companyTransformer } from '../../../utils/transformers';
import { closeModal } from '../../../reducers/modals';
import { setUser } from '../../../reducers/auth'
import { 
  country as countrySelector, 
  companySelector,
  electronicInvoicing  
} from '../../../selectors/company';
import initialValues from '../../forms/company/BasicData/initialValues';
import Body from '../../forms/companyEmail/CompanyEmail';
import { validate, transform } from '../../forms/companyEmail/utils';
import Modal from '../../common/Modal';
import Bottom from '../common/Bottom';
import Notification from '../common/Notification'
import { APIGraphqlSelector } from '../../../selectors/app';

const OpenShift = () => {
  const dispatch = useDispatch();
  const isOpen = useSelector(state => get(state, 'modals.companyEmail.isOpen', false));
  const country = useSelector(countrySelector)
  const company = useSelector(companySelector)
  const isElectronic = useSelector(electronicInvoicing)
  const APIGraphql = useSelector(APIGraphqlSelector);

  const submit = async values => {
    try {
      const transformedValues = transform(values, { country, isElectronic })
      const response = await APIGraphql(graphqlOperation(mutations.updateUser, {
        user: { 
          company: companyTransformer(transformedValues)
        }
      }))

      if (!!get(response, 'data.updateUser.id', null))
        await dispatch(setUser(get(response, 'data.updateUser')))

      dispatch(closeModal({ modal: 'companyEmail' }))
      toast.success({ title: I18n.get('companyUpdatedSuccessfully', 'compañía configurada con éxito') })
    } catch (error) {
      return formError(error, I18n.get('updateCompanyError', 'ocurrió un error al intentar actualizar los datos de la compañía'))
    }
  }

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={() => dispatch(closeModal({ modal: 'companyEmail' }))}
      title={I18n.get('configureCompany', 'Configurar mi negocio')}
      className="modal__medium"
    >
      <Form
        onSubmit={submit}
        validate={(values) => validate(values, { country, isElectronic })}
        initialValues={{
          ...initialValues({ country, company, isElectronic }),
        }}
        keepDirtyOnReinitialize
      >
        {({ handleSubmit, submitting, submitError, form, values }) => (
          <form noValidate onSubmit={handleSubmit}>
            <p className="text-center h4 text-capitalize-first pt-5 px-5">
              {I18n.get('configureCompanyMessage', 'Para enviar ventas por email primero tiene que configurar los datos de la compañía')}
            </p>

            <div className="d-flex flex-column p-3">
              <Body 
                values={values}
                form={form}
                country={country}
              />
            </div>

            <Notification isOpen={!!submitError} text={submitError} />

            <Bottom 
              submitting={submitting}
              onClose={() => dispatch(closeModal({ modal: 'companyEmail' }))}
            />
          </form>
        )}  
      </Form>
    </Modal>
  )
}

export default OpenShift;
import { useEffect, useState, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { API, graphqlOperation } from '@aws-amplify/api';
import { get } from 'lodash'

import * as subscriptions from '../graphql/subscriptions';
import { idCompanySelector } from '../selectors/company';
import { fetchUser } from '../reducers/auth';

const Company = () => {
  const ref = useRef()
  const dispatch = useDispatch()
  const idCompany = useSelector(idCompanySelector)
  const networkStatus = useSelector(state => get(state, 'app.networkStatus', 'online'))
  const [onCompanyChangeError, setOnCompanyChangeError] = useState(false);

  useEffect(() => {
    let subsciption = null
    if (networkStatus === 'online') {
      subsciption = API.graphql(graphqlOperation(subscriptions.onUpdateUser, {
        channel: `pos_${idCompany}`
      }))
        .subscribe({
          next: () => {
            try {
              dispatch(fetchUser())
            } catch {
              
            }
          },
          error: () => setTimeout(() => {
            if (!!ref.current)
              setOnCompanyChangeError(!onCompanyChangeError)
          }, 60000)
        })
    }
    return () => {
      if (subsciption)
        subsciption.unsubscribe();
    }
  }, [networkStatus, onCompanyChangeError, dispatch, idCompany])

  useEffect(() => {
    ref.current = true
    return () => ref.current = false
  }, [])
  
  return null;
}

export default Company;
import { I18n } from '@aws-amplify/core';
import { get, isArray } from 'lodash';
import { BigNumber } from 'bignumber.js'

export const validate = (values, country) => {
  let errors = {}

  if (country === 'costaRica' && get(values, 'discount', 0) > 0 && !get(values, 'discountNature', null)) {
    errors.discountNature = I18n.get('youNeedToAddDiscountNature', 'necesitas agregar la naturaleza del descuento')
  }

  return errors;
}

export const transform = (values, { country, initialValues: { tax, price, discount }, currency, decimal, newProductKey }) => {

  let newTax = !!get(values, 'tax', null) ?
    isArray(get(values, 'tax')) ? get(values, 'tax') : [get(values, 'tax')]
    : []

  const newPrice = Math.max(+get(values, 'price', 0), 0)
  const newDiscount = Math.max(+get(values, 'discount', 0), 0)
  const newQuantity = new BigNumber(!!+get(values, 'quantity', 0) ? +get(values, 'quantity') : 1)
  let originalPrice = newPrice
  const productKey = !!newProductKey ? { productKey: get(newProductKey, 'key') } : {}
  newTax = newTax.filter(tax => get(tax, 'id') !== -1)

  if (!!currency) {
    const exchangeRate = !!get(currency, 'exchangeRate') ? +get(currency, 'exchangeRate') : 1;
    originalPrice = new BigNumber(newPrice).multipliedBy(new BigNumber(exchangeRate)).decimalPlaces(decimal).toNumber()
  }

  return {
    originalPrice,
    ...productKey,
    price: newPrice,
    discount: country === 'costaRica'
      ? {
        discount: (new BigNumber(newDiscount)).decimalPlaces(2).toNumber(),
        nature: get(values, 'discountNature', '')
      }
      : (new BigNumber(newDiscount)).decimalPlaces(2).toNumber(),
    quantity: newQuantity.isPositive() ? newQuantity.decimalPlaces(4).toNumber() : 1,
    tax: newTax,
    description: values.description,
    priceModified: +price !== newPrice,
    modified: +price !== newPrice || +discount !== newDiscount || tax !== newTax,
  }
}
function AlegraPointOfSaleLogo({ id, className }) {
  return (
    <svg
      id={id}
      className={className}
      width='1060'
      height='240'
      viewBox='0 0 1060 240'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M105.914 44C96.9923 44 89.76 51.2247 89.76 60.1367V123.213H150.24V60.1367C150.24 51.2247 143.008 44 134.086 44H105.914Z'
        fill='#00D6BC'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M66.8215 84.2701C62.3608 84.2701 58.7446 87.8825 58.7446 92.3385V106.862H52.1538C43.2323 106.862 36 114.086 36 122.998V177.863C36 186.775 43.2323 194 52.1538 194H187.846C196.768 194 204 186.775 204 177.863V122.998C204 114.086 196.768 106.862 187.846 106.862H181.255V92.3385C181.255 87.8825 177.639 84.2701 173.178 84.2701C168.718 84.2701 165.102 87.8825 165.102 92.3385V122.245C165.102 131.157 157.869 138.382 148.948 138.382H91.0523C82.1308 138.382 74.8985 131.157 74.8985 122.245V92.3385C74.8985 87.8825 71.2823 84.2701 66.8215 84.2701ZM96.48 155.236C90.913 155.236 86.4 159.763 86.4 165.348C86.4 170.933 90.913 175.461 96.48 175.461H143.52C149.087 175.461 153.6 170.933 153.6 165.348C153.6 159.763 149.087 155.236 143.52 155.236H96.48Z'
        fill='#00D6BC'
      />
      <path
        d='M272.748 92.374C263.08 92.374 256 84.5756 256 75.0294C256 65.6176 263.625 57.8193 273.769 57.8193C283.845 57.8193 291.538 65.2143 291.538 75.0294V91.5672H285.139V85.7857C282.62 89.8193 278.194 92.374 272.748 92.374ZM273.769 86.0546C280.033 86.0546 284.798 81.0798 284.798 75.0966C284.798 69.0462 280.033 64.0714 273.769 64.0714C267.574 64.0714 262.74 69.0462 262.74 75.0966C262.74 81.0798 267.574 86.0546 273.769 86.0546Z'
        fill='#64748B'
      />
      <path d='M300.512 43.5H307.252V91.5672H300.512V43.5Z' fill='#64748B' />
      <path
        d='M331.78 92.3067C321.636 92.3067 314.079 85.0462 314.079 74.895C314.079 65.416 320.955 57.6849 330.963 57.6849C341.039 57.6849 347.303 64.8782 347.303 74.0882V76.7773H320.615C321.364 82.4244 325.721 86.3908 331.712 86.3908C335.797 86.3908 339.473 84.6429 341.448 81.0126L346.622 83.6345C343.694 89.2815 338.316 92.3067 331.78 92.3067ZM320.887 72.4748H340.563C340.29 67.2311 336.41 63.6681 330.895 63.6681C325.244 63.6681 321.568 67.5672 320.887 72.4748Z'
        fill='#64748B'
      />
      <path
        d='M368.353 107.5C361.136 107.5 355.145 104.408 352.082 98.5588L357.732 95.5336C359.707 99.0966 363.383 101.382 368.217 101.382C375.229 101.382 379.995 96.4076 379.995 88.6765V86.0546C377.816 89.4832 373.732 92.0378 368.149 92.0378C358.686 92.0378 351.197 84.6429 351.197 74.9622C351.197 65.5504 358.822 57.8193 368.966 57.8193C379.042 57.8193 386.735 65.2143 386.735 75.0294V88.6092C386.735 100.038 378.974 107.5 368.353 107.5ZM368.898 85.7857C375.025 85.7857 379.927 80.9454 379.927 74.895C379.927 68.8445 375.025 64.1387 368.898 64.1387C362.839 64.1387 357.869 68.8445 357.869 74.895C357.869 80.9454 362.839 85.7857 368.898 85.7857Z'
        fill='#64748B'
      />
      <path
        d='M401.087 91.5672H394.347V71.937C394.347 63.937 398.977 57.9538 407.691 57.9538C409.802 57.9538 411.98 58.3571 413.682 59.1639V65.7521C412.048 64.8109 410.142 64.3403 408.304 64.3403C403.811 64.3403 401.087 67.3655 401.087 72.2059V91.5672Z'
        fill='#64748B'
      />
      <path
        d='M431.21 92.374C421.542 92.374 414.462 84.5756 414.462 75.0294C414.462 65.6176 422.087 57.8193 432.231 57.8193C442.307 57.8193 450 65.2143 450 75.0294V91.5672H443.6V85.7857C441.081 89.8193 436.656 92.374 431.21 92.374ZM432.231 86.0546C438.494 86.0546 443.26 81.0798 443.26 75.0966C443.26 69.0462 438.494 64.0714 432.231 64.0714C426.035 64.0714 421.202 69.0462 421.202 75.0966C421.202 81.0798 426.035 86.0546 432.231 86.0546Z'
        fill='#64748B'
      />
      <path
        d='M267.43 195.054H256V120.507H279.868C300.039 120.507 313.15 129.519 313.15 146.876C313.15 164.679 299.815 173.58 280.429 173.58H267.43V195.054ZM280.205 130.966H267.43V163.01H280.205C292.531 163.01 301.384 158.225 301.384 146.876C301.384 135.973 292.867 130.966 280.205 130.966Z'
        fill='#334155'
      />
      <path
        d='M347.505 185.707C357.702 185.707 363.305 176.918 363.305 166.793V140.534H374.399V168.128C374.399 183.927 364.762 196.389 347.505 196.389C330.472 196.389 320.835 183.816 320.835 168.016V140.534H331.928V166.904C331.928 176.918 337.419 185.707 347.505 185.707Z'
        fill='#334155'
      />
      <path
        d='M413.765 149.769C403.008 149.769 397.629 158.559 397.629 168.684V195.054H386.535V167.571C386.535 151.661 396.06 139.199 413.765 139.199C431.695 139.199 441.444 151.661 441.444 167.46V195.054H430.35V168.795C430.35 158.559 424.747 149.769 413.765 149.769Z'
        fill='#334155'
      />
      <path
        d='M478.965 196.5C465.294 196.5 458.01 187.933 458.01 175.471V150.103H447.14V140.534H458.01V126.404H469.104V140.534H490.171V150.103H469.104V175.137C469.104 182.258 473.362 186.486 479.973 186.486C483.559 186.486 487.481 185.151 490.171 183.26V193.385C487.369 195.276 482.999 196.5 478.965 196.5Z'
        fill='#334155'
      />
      <path
        d='M525.854 196.389C509.269 196.389 496.494 183.482 496.494 167.683C496.494 151.995 509.381 139.199 525.854 139.199C542.438 139.199 554.989 152.106 554.989 167.683C554.989 183.482 542.326 196.389 525.854 196.389ZM525.854 185.93C536.051 185.93 543.895 177.696 543.895 167.794C543.895 157.78 536.051 149.547 525.854 149.547C515.544 149.547 507.588 157.78 507.588 167.794C507.588 177.696 515.544 185.93 525.854 185.93Z'
        fill='#334155'
      />
      <path
        d='M618.382 196.389C601.686 196.389 589.135 183.593 589.135 167.905C589.135 151.327 601.237 139.199 617.822 139.199C625.218 139.199 631.941 142.426 636.424 147.321L636.536 115.5H647.63V167.905C647.63 184.038 634.967 196.389 618.382 196.389ZM618.382 186.041C628.692 186.041 636.536 177.808 636.536 167.794C636.536 157.891 628.692 149.658 618.382 149.658C608.185 149.658 600.229 157.891 600.229 167.794C600.229 177.808 608.185 186.041 618.382 186.041Z'
        fill='#334155'
      />
      <path
        d='M685.821 196.277C669.124 196.277 656.685 184.261 656.685 167.46C656.685 151.772 668.003 138.977 684.476 138.977C701.061 138.977 711.37 150.882 711.37 166.125V170.576H667.443C668.676 179.922 675.847 186.486 685.709 186.486C692.432 186.486 698.483 183.593 701.733 177.585L710.249 181.924C705.431 191.271 696.578 196.277 685.821 196.277ZM667.891 163.455H700.276C699.828 154.776 693.441 148.879 684.364 148.879C675.063 148.879 669.012 155.332 667.891 163.455Z'
        fill='#334155'
      />
      <path
        d='M760.715 187.933L743.01 140.534H754.888L771.024 184.929C771.137 185.485 771.585 186.041 772.257 186.041C773.041 186.041 773.49 185.485 773.602 184.929L789.738 140.534H801.504L783.687 187.933C781.558 193.607 777.636 196.5 772.257 196.5C767.102 196.5 762.844 193.607 760.715 187.933Z'
        fill='#334155'
      />
      <path
        d='M832.114 196.277C815.417 196.277 802.979 184.261 802.979 167.46C802.979 151.772 814.297 138.977 830.769 138.977C847.354 138.977 857.663 150.882 857.663 166.125V170.576H813.736C814.969 179.922 822.141 186.486 832.002 186.486C838.725 186.486 844.777 183.593 848.026 177.585L856.543 181.924C851.724 191.271 842.872 196.277 832.114 196.277ZM814.185 163.455H846.569C846.121 154.776 839.734 148.879 830.657 148.879C821.356 148.879 815.305 155.332 814.185 163.455Z'
        fill='#334155'
      />
      <path
        d='M894.776 149.769C884.019 149.769 878.64 158.559 878.64 168.684V195.054H867.546V167.571C867.546 151.661 877.071 139.199 894.776 139.199C912.706 139.199 922.455 151.661 922.455 167.46V195.054H911.361V168.795C911.361 158.559 905.758 149.769 894.776 149.769Z'
        fill='#334155'
      />
      <path
        d='M959.976 196.5C946.305 196.5 939.021 187.933 939.021 175.471V150.103H928.151V140.534H939.021V126.404H950.115V140.534H971.182V150.103H950.115V175.137C950.115 182.258 954.373 186.486 960.985 186.486C964.571 186.486 968.493 185.151 971.182 183.26V193.385C968.38 195.276 964.01 196.5 959.976 196.5Z'
        fill='#334155'
      />
      <path
        d='M1005.07 196.389C989.159 196.389 977.505 183.482 977.505 167.683C977.505 152.106 990.056 139.199 1006.75 139.199C1023.34 139.199 1036 151.438 1036 167.683V195.054H1025.47V185.485C1021.32 192.161 1014.04 196.389 1005.07 196.389ZM1006.75 185.93C1017.06 185.93 1024.91 177.696 1024.91 167.794C1024.91 157.78 1017.06 149.547 1006.75 149.547C996.555 149.547 988.599 157.78 988.599 167.794C988.599 177.696 996.555 185.93 1006.75 185.93Z'
        fill='#334155'
      />
    </svg>
  );
}

export default AlegraPointOfSaleLogo;

import React, { useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { I18n } from '@aws-amplify/core';

import Button from '../../../common/Button';
import { isSelectingItems } from '../../../../selectors/activeRefund';
import { useFormState } from 'react-final-form';
import { Tooltip } from '@alegradev/smile-ui-react';

/**
 * RectificativeInSimplifiedInvoice Component
 *
 * Renders the refund form controls for the "Rectificative In Simplified Invoice" method.
 * Displays different buttons based on the current step and selection mode.
 *
 * @param {Object} props - Component properties.
 * @param {number} props.step - Current step in the refund process.
 * @param {Function} props.changeStep - Function to change the current step.
 * @param {Function} props.closeModal - Function to close the modal.
 * @param {boolean} props.disabled - Flag to disable buttons.
 * @param {boolean} props.submitting - Flag indicating if a submission is in progress.
 * @param {string} props.lastStepError - Error message from the last step.
 *
 * @returns {JSX.Element|null} The rendered component or null if conditions are met.
 */
export const NoElectronicSpainMethod = React.memo(
  ({ step, changeStep, closeModal, disabled, submitting, lastStepError }) => {
    const { values } = useFormState();

    const isElectronic = useMemo(
      () => values?.refund?.document?.numberTemplate?.isElectronic ?? false,
      [values]
    );

    const submitText = useMemo(
      () =>
        isElectronic
          ? I18n.get('emit', 'Emitir')
          : I18n.get('save', 'Guardar'),
      [isElectronic]
    );

    const isSelectingMode = useSelector(isSelectingItems);

    const handleChangeStep = useCallback(
      (newStep) => {
        changeStep(newStep);
      },
      [changeStep]
    );

    if (isSelectingMode && step === 3) return null;

    return (
      <div className='d-flex flex-end justify-content-end bottom container-buttons-controls'>
        <button
          type='button'
          className='refunds-cancel-button mr-md-4'
          onClick={closeModal}
          disabled={submitting}
        >
          {I18n.get('cancel', 'Cancelar')}
        </button>

        {/* Continue Button for Step 1 and 2 */}
        {(step === 1 || step === 2) && (
          <Button
            className='ml-4'
            style={{ width: '116px' }}
            onClick={() => handleChangeStep(3)}
            disabled={disabled}
          >
            {I18n.get('continue', 'Continuar')}
          </Button>
        )}

        {/* Continue Button with Tooltip for Step 3 */}
        {step === 3 && !isSelectingMode && (
          <Tooltip
            visible={!!lastStepError}
            placement='top'
            overlay={lastStepError}
          >
            <Button
              className={`ml-md-4 ${lastStepError ? 'disabled-button-creditToSales' : ''
                }`}
              style={{ width: '116px' }}
              onClick={() => handleChangeStep(4)}
              disabled={disabled}
            >
              {I18n.get('continue', 'Continuar')}
            </Button>
          </Tooltip>
        )}

        {/* Submit Button for Step 4 */}
        {step === 4 && (
          <Button
            style={{ width: '116px' }}
            type='submit'
            disabled={disabled}
            loading={submitting}
          >
            {!submitting ? submitText : ''}
          </Button>
        )}
      </div>
    );
  }
);

NoElectronicSpainMethod.propTypes = {
  step: PropTypes.number.isRequired,
  changeStep: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  submitting: PropTypes.bool,
  lastStepError: PropTypes.string,
};

NoElectronicSpainMethod.defaultProps = {
  disabled: false,
  submitting: false,
  lastStepError: '',
};
import db from './database';
export const bulkPut = async (paymentMethods, callback) => {
  paymentMethods = paymentMethods.map((paymentMethod) => {
    return paymentMethod;
  });
  await db.paymentMethods.bulkPut(paymentMethods);

  if (!!callback) callback();
};

export const getPaymentMethod = (id) => db.paymentMethods.get(id);

export const getAll = async () => {
  try {
    return await db.paymentMethods.toArray();
  } catch {
    return [];
  }
};

export const put = (paymentMethod) => {
  return db.paymentMethods.put(paymentMethod);
};

export const bulkDelete = (keys) => db.paymentMethods.bulkDelete(keys);

import React from 'react'
import { useDispatch } from 'react-redux';
import { I18n } from '@aws-amplify/core';

import { openModal } from '../../reducers/modals';

const PaginationHeader = () => {
  const dispatch = useDispatch()

  return (
    <div className="d-flex p-4 justify-content-center align-items-center d-sm-none d-block w-100">
      <button
        type="button"
        className="btn btn-submit"
        onClick={() => dispatch(openModal({ modal: 'payments' }))}
      >
        {I18n.get('inOutCash', 'ingresar / retirar efectivo')}
      </button>
    </div>
  )
}

export default PaginationHeader;
import React, { useState, useEffect } from 'react';
import { Field, useFormState } from 'react-final-form';
import { I18n } from '@aws-amplify/core';
import { capitalize, get, set } from 'lodash';
import { useSelector } from 'react-redux'

import { activeBanks } from '../../../../selectors/banks'
import { stationCashBank } from '../../../../selectors/app'
import { decimalPrecision} from '../../../../selectors/company';
import { renderField, renderSelect } from '../../fields/V0/Fields';
import { useDecimalsVersionsGroup } from '../../../../hooks/useDecimalsVersionsGroup';

const Cash = ({ values }) => {
  const banks = useSelector(activeBanks)
  const cashBank = useSelector(stationCashBank) || null
  const decimal = useSelector(decimalPrecision)
  const [accountWarning, setAccountWarning] = useState("");
  const formState = useFormState();
  const { isDecimalActive } = useDecimalsVersionsGroup();

  useEffect(() => {
    let account = get(formState.values, 'refund.account', null);
    if (account && cashBank && account.id !== cashBank.id)
      setAccountWarning(I18n.get('refundAccountWarning', 'Solo se descontarán del valor en caja de tu cierre de turno los valores asociados a la cuenta bancaria del Efectivo POS.'));
    else 
      setAccountWarning("");
  }, [formState, cashBank])

  return (
    <div className="form-fields form-row justify-content-start w-100">
      <Field
        name="refund.amount"
        component={renderField}
        type="number"
        className="col-md-6"
        required
        label={capitalize(I18n.get('amount', 'monto'))}
        onChange={e => refundMaxDecimals(e, `refund.amount`, decimal, values, isDecimalActive)}
      />
      
      <Field
        name="refund.account"
        component={renderSelect}
        defaultValue={cashBank}
        className="col-md-6"
        options={banks}
        getOptionLabel={option => option.name}
        getOptionValue={option => option.id}
        label={capitalize(I18n.get('bankAccount', 'cuenta bancaria'))}
        warning={accountWarning}
        menuPosition="absolute"
        required
      />
      <Field
        name="refund.observations"
        component={renderField}
        className="col-12"
        type="textarea"
        label={capitalize(I18n.get('observations', 'observaciones'))}
      />
      
    </div>
  )
}

export function refundMaxDecimals(e, key, decimals = 6, values, isActive) {
  if (!isActive)
    return e.target.value;

  const newButton = e.nativeEvent.data;
  set(values, key, !get(values, key) ? "" : get(values, key));
  e.target.value = !e.target.value ? "" : e.target.value;

  if (newButton === '.') {
    if (!e.target.value.slice(0, -1).includes('.')) {
      return e.target.value;
    } else {
      e.target.value = values[key];
      return get(values, key);
    }
  }

  if (!isNaN(newButton)) {
    if (!e.target.value.includes('.') || (e.target.value.includes('.') && e.target.value.split('.')[1].length <= decimals)) {
      return e.target.value;
    } else {
      e.target.value = get(values, key);
      return get(values, key);
    }
  }

  if (isNaN(newButton)) {
    e.target.value = get(values, key);
    return get(values, key);
  }
}

export default Cash;

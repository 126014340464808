import React from 'react';
import { I18n } from '@aws-amplify/core';
import { get, isString } from 'lodash';
import { useSelector } from 'react-redux';

import { country as countrySelector } from '../../../selectors/company';
import { getID, getDV } from './utilities';
import { isDefaultClient } from '../../../reducers/activeInvoice';

const Client = ({ refund: { client }, setting: { align, template } }) => {
  const country = useSelector(countrySelector);

  if (!client) return null;

  const renderClientName = () => {
    if (!!get(client, 'name')) {
      if (!!isString(get(client, 'name'))) return get(client, 'name');
      return `${get(client, 'name.firstName', '')}${
        !!get(client, 'name.secondName', null)
          ? ' ' + get(client, 'name.secondName')
          : ''
      }${
        !!get(client, 'name.lastName', null)
          ? ' ' + get(client, 'name.lastName')
          : ''
      }`;
    }
    return '';
  };

  const renderClientIdentification = (client, country) => {
    if (isDefaultClient(client, country)) return null;

    if (
      ['peru', 'republicaDominicana'].includes(country) &&
      !!get(client, 'identificationObject.type') &&
      !!get(client, 'identificationObject.number')
    )
      return (
        <p>
          <strong>{client.identificationObject.type}</strong>
          {client.identificationObject.number}
        </p>
      );

    if (
      !!get(client, 'identification', null) ||
      !!get(client, 'identificationObject', null)
    )
      return (
        <p>
          {getIdTitle() ? <strong>{getIdTitle()}</strong> : null}
          {`${getID(client)} `}
          {getDV(client ? client : client, country) ? (
            <>
              <span className='font-weight-bold'>{I18n.get('dv', 'DV')}: </span>{' '}
              {getDV(client ? client : client, country)}
            </>
          ) : null}
        </p>
      );

    return null;
  };

  const getIdTitle = () => {
    if (country === 'panama') {
      const currentClient = client ? client : client;
      const kindOfPerson = get(
        currentClient,
        'identificationObject.kindOfPerson',
        null
      );
      if (kindOfPerson) {
        return 'RUC';
      } else {
        return null;
      }
    }

    return I18n.get('identification', 'identificación');
  };

  return (
    <div className={`w-100 text-${align} py-2 d-flex flex-column border-bottom`}>
      <h3 className={`h3 font-weight-bold ${template}`}>
        {template !== 'classic' && <strong>{I18n.get('client', 'cliente')}</strong>}
        {renderClientName()}
      </h3>

      <p>{renderClientIdentification(client, country)}</p>
    </div>
  );
};

export default Client;

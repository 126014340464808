import React, { useState } from "react";
import { I18n } from "aws-amplify";

import db from "../../../database/database";

const ADMIN_PASSWORD = "POSDevelopers";

const LoadIBDInfo = () => {
  const [isPasswordCorrect, setIsPasswordCorrect] = useState(false);

  const handlePasswordCheck = () => {
    const userInput = window.prompt("Please enter the temporary password:", "");

    if (userInput === ADMIN_PASSWORD) {
      setIsPasswordCorrect(true);
    } else {
      alert("Incorrect password. Please try again.");
    }
  };

  const importData = async (jsonString) => {
    try {
      const data = JSON.parse(jsonString);

      await db.invoices.clear();

      await db.invoices.bulkPut(data);
    } catch (error) {
      console.error("Error importing data to IndexedDB:", error);
    }
  };

  const handleImport = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    const reader = new FileReader();

    reader.onload = async (e) => {
      const jsonString = e.target.result;
      await importData(jsonString);
    };

    reader.readAsText(file);
  };

  return (
    <div>
      {isPasswordCorrect ? (
        <input type="file" onChange={handleImport} />
      ) : (
        <button
          type="button"
          onClick={handlePasswordCheck}
          className="btn btn-menu py-2"
        >
          {I18n.get("load", "Cargar")}
        </button>
      )}
    </div>
  );
};

export default LoadIBDInfo;

import { createDraftSafeSelector } from '@reduxjs/toolkit';

import { invoicesSelector } from '../reducers/invoices'

const invoicesDataSelector = state => state.invoices; 

export const invoices = createDraftSafeSelector(
  invoicesSelector.selectAll,
  invoices => !!invoices ? invoices : [] 
)

export const getInvoiceById = id => createDraftSafeSelector(
  state => invoicesSelector.selectById(state, id),
  invoice => invoice
)

export const loadingSelector = createDraftSafeSelector(
  invoicesDataSelector,
  data => data.loading === 'loading'
)

export const errorSelector = createDraftSafeSelector(
  invoicesDataSelector,
  data => data.error
)

export const offlineTotalSelector = createDraftSafeSelector(
  invoicesDataSelector,
  data => data.offlineTotal
)

export const metadataSelector = createDraftSafeSelector(
  invoicesDataSelector,
  data => data.metadata
)
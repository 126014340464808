export const getFeHelpedArticle = (country) => {
  switch (country) {
    case 'argentina':
      return 'https://ayuda.alegra.com/es/c%C3%B3mo-activo-mis-facturas-electr%C3%B3nicas-en-el-sistema-punto-de-venta-alegra-argentina';

    case 'panama':
      return (
        process.env.REACT_APP_ALEGRA_SMILE + 'fepan-wizard/redirect/alegra-pos'
      );

    case 'republicaDominicana':
      return 'https://ayuda.alegra.com/es/emite-facturas-electronicas-desde-el-pos-republica-dominicana';

    case 'costaRica':
      return (
        process.env.REACT_APP_ALEGRA_SMILE + 'fe-wizard/redirect/alegra-pos'
      );

    case 'colombia':
      return 'https://ayuda.alegra.com/es/agiliza-la-facturaci%C3%B3n-del-terminal-de-tu-punto-de-venta-pos-colombia';

    case 'peru':
      return 'https://ayuda.alegra.com/es/crea-boletas-y-facturas-electr%C3%B3nicas-desde-el-punto-de-venta-de-alegra-per%C3%BA';

    default:
      break;
  }
};

import React, { useCallback, useEffect, useMemo } from 'react'
import { I18n } from '@aws-amplify/core';
import { graphqlOperation } from '@aws-amplify/api';
import { useSelector, useDispatch } from 'react-redux'
import { Form } from 'react-final-form';
import { useHistory } from 'react-router-dom';
import { get } from 'lodash';
import parse from 'html-react-parser';


import * as mutations from '../../../graphql/mutations'
import { formError, handleError } from '../../../utils/errors';
import { companyTransformer } from '../../../utils/transformers';
import { setUser } from '../../../reducers/auth'
import { hasPermissionTo } from '../../../selectors/auth'
import {
  localSettings,
  isOnlyInvoicingPlan,
  country as countrySelector,
  companySelector,
  electronicInvoicing
} from '../../../selectors/company'
import initialValues from '../../forms/company/BasicData/initialValues';
import { getCategoryById, defaultInCategory, defaultOutCategory } from '../../../selectors/categories'
import { getCompanyRegime } from '../../../selectors/regimes'
import { allEconomicActivities, mainActivity } from '../../../selectors/economicActivity';
import { toast } from '../../../utils'
import { transform, validate } from '../../forms/company/utils'
import Body from './FormBody';
import Header from '../common/NewHeader';
import Bottom from '../common/Bottom';
import { APIGraphqlSelector } from '../../../selectors/app';
import { useTicketBaiInformation } from '../../../hooks/useTicketBaiElegible';

const Company = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const can = useSelector(hasPermissionTo)
  const posSettings = useSelector(localSettings)
  const country = useSelector(countrySelector)
  const isElectronic = useSelector(electronicInvoicing)
  const onlyInvoicing = useSelector(isOnlyInvoicingPlan)
  const company = useSelector(companySelector)
  const companyRegime = useSelector(getCompanyRegime)
  const companyIncome = useSelector(getCategoryById(posSettings.idCategoryDefaultIncome))
  const defaultIncome = useSelector(defaultInCategory)
  const companyExpense = useSelector(getCategoryById(posSettings.idCategoryDefaultExpense))
  const defaultExpense = useSelector(defaultOutCategory)
  const economicActivities = useSelector(allEconomicActivities);
  const mainEconomicActivity = useSelector(mainActivity)
  const APIGraphql = useSelector(APIGraphqlSelector);

  const { isTicketBaiEligible } = useTicketBaiInformation();

  useEffect(() => {
    window.dataLayer.push({
      'event': 'VirtualPageview',
      'virtualPageURL': '/settings/company',
      'virtualPageTitle': 'Company Settings'
    });
  }, [])

  const memoizedInitialValues = useMemo(() => {
    const transformedInitialValues = initialValues({ country, company, isElectronic });
    return {
      ...transformedInitialValues,
      ...posSettings,
      idCategoryDefaultIncome: !onlyInvoicing
        ? companyIncome || defaultIncome
        : defaultIncome,
      idCategoryDefaultExpense: !onlyInvoicing
        ? companyExpense || defaultExpense
        : defaultExpense,
      shiftsEnabled: !onlyInvoicing ? posSettings.shiftsEnabled : false,
      regime: companyRegime,
    };
  }, [
    country,
    company,
    isElectronic,
    posSettings,
    onlyInvoicing,
    companyIncome,
    defaultIncome,
    companyExpense,
    defaultExpense,
    companyRegime,
  ]);

  const validateForm = useMemo(
    () => (values) => validate(values, { country, isElectronic, isTicketBaiEligible }),
    [country, isElectronic, isTicketBaiEligible]
  );

  const submit = useCallback(
    async (values) => {
      const transformedValues = transform(values, { country, isElectronic });

      const validationErrors = validate(values, { country, isElectronic });
      if (Object.keys(validationErrors).length !== 0) {
        toast.error({
          title: I18n.get('requiredFieldsMissing', 'Hay algunos campos obligatorios sin completar'),
        });
        return;
      }

      try {
        const companyData =
          country === 'costaRica'
            ? {
              ...companyTransformer(transformedValues),
              economicActivities: economicActivities || [],
              economicActivity: mainEconomicActivity?.code || '',
            }
            : { ...companyTransformer(transformedValues) };

        const response = await APIGraphql(
          graphqlOperation(mutations.updateUser, {
            user: {
              company: {
                ...companyData,
              },
            },
          })
        );

        const updatedUser = response?.data?.updateUser;
        if (updatedUser?.id) {
          dispatch(setUser(updatedUser));
        }

        if (!values.noRedirect) {
          toast.success({
            title: I18n.get('companyUpdatedSuccessfully', 'Compañía configurada con éxito'),
          });
          history.push('/settings');
        }
      } catch (error) {
        const errorMessage = handleError(
          error,
          {
            defaultMessage: I18n.get('updateCompanyError', 'Ocurrió un error al intentar actualizar los datos de la compañía'),
            country
          }
        );
        toast.error({ title: errorMessage });
        formError(
          error,
          I18n.get('updateCompanyError', 'Ocurrió un error al intentar actualizar los datos de la compañía')
        );
      }
    },
    [
      APIGraphql,
      country,
      economicActivities,
      mainEconomicActivity?.code,
      dispatch,
      history,
      isElectronic,
    ]
  );

  return (
    <div className="settings-container">
      <div className="d-flex flex-column">
        <Form
          onSubmit={submit}
          validate={validateForm}
          initialValues={memoizedInitialValues}
          keepDirtyOnReinitialize
        >
          {({ handleSubmit, values, form, submitting, submitError, pristine }) => (
            <form noValidate onSubmit={handleSubmit}>
              <Header
                title={I18n.get('yourBusinessData', 'Datos de tu negocio')}
                subtitle={I18n.get('yourBusinessData.subtitle', 'Actualiza la información de tu negocio que aparecerá en tus facturas')}
                error={submitError}
                info={!can('edit', 'company') && I18n.get(('userNotAllowed.company.edit', 'no tienes permisos suficientes para editar la información de la compañía'))}
              />

              <Body
                values={values}
                form={form}
                country={country}
              />

              <Bottom
                disabled={!can('edit', 'company')}
                submitting={submitting}
                requiredMessage={<p className="text-muted ml-5">
                  {parse(I18n.get('fieldsMarkedRequired', 'Los campos marcados con <span class="text-primary">*</span> son obligatorios'))}
                </p>}
                onClose={() => history.push('/settings')}
              />
            </form>
          )}
        </Form>
      </div>
    </div>
  )
}

export default Company;
import { createDraftSafeSelector } from '@reduxjs/toolkit';
import { get } from 'lodash';

import { taxesSelector } from '../reducers/taxes'
import { country, ivaConditionSelector as ivaCondition, regime } from './company';

//import especialTaxesMexico from '../components/countriesData/mexico/especialTaxes';

const especialTaxes = {
  // we cannot set taxes manually
  //mexico: especialTaxesMexico,
}

export const activeTaxes = createDraftSafeSelector(
  taxesSelector.selectAll, country,
  (taxes, country) => !!taxes
    ? [...get(especialTaxes, country, []), ...taxes.filter(tax => get(tax, 'status') === 'active')]
    : get(especialTaxes, country, [])
)

export const getTaxById = id => createDraftSafeSelector(
  state => taxesSelector.selectById(state, id),
  tax => !!tax && get(tax, 'status') === 'active' ? tax : null
)

export const getIVA0Tax = createDraftSafeSelector(
  [activeTaxes],
  taxes => taxes.find(tax => get(tax, 'type') === 'IVA' && +get(tax, 'percentage') === 0) || null
)

export const getDefaultTax = createDraftSafeSelector(
  [activeTaxes, country, ivaCondition, regime],
  (taxes, country, ivaCondition, regime) => {
    switch (country) {
      case 'peru':
        return taxes.find(tax => get(tax, 'name') === 'IGV')
      case 'argentina':
        return ivaCondition === 'IVA_RESPONSABLE' ? taxes.find(tax => get(tax, 'name') === 'IVA' && get(tax, 'percentage') === '21.00') : null
      case 'costaRica':
        return taxes.find(tax => get(tax, 'name') === 'IVA' && get(tax, 'percentage') === 13)
      case 'colombia':
        return (regime === 'Responsable de IVA' || regime === 'Responsable de IVA e INC') ? taxes.find(tax => get(tax, 'name') === 'IVA' && get(tax, 'percentage') === '19.00') : null
      default:
        return null
    }
  }
)
import React from 'react';
import { Field, useForm } from 'react-final-form';
import { I18n } from '@aws-amplify/core';
import { capitalize, get, set } from 'lodash';
import { useSelector, useDispatch } from 'react-redux'

import { openModal } from '../../../reducers/modals'
import { checkFeatureLimit } from '../../../reducers/membership'
import { hasPermissionTo } from '../../../selectors/auth'
import { activeCurrencies } from '../../../selectors/currencies'
import { renderSelect } from '../fields/V0/Fields';
import { renderField as renderNewField } from '../fields/V2';
import { useDecimalsVersionsGroup } from '../../../hooks/useDecimalsVersionsGroup';
import { decimalSeparator } from '../../../selectors/company';

const InvoiceSettings = ({ values }) => {
  const can = useSelector(hasPermissionTo)
  const dispatch = useDispatch()
  const currencies = useSelector(activeCurrencies)
  const { isDecimalActive } = useDecimalsVersionsGroup();
  const separator = useSelector(decimalSeparator)
  const { change } = useForm();

  return (
    <div className="form-body h-100 p-4 overflow-hidden">
      <div className="form-body__fields form-row justify-content-start">
        <Field
          name="currency"
          className="col-12"
          component={renderSelect}
          options={!can('index', 'currencies') ? [] : currencies}
          selectInfo={!can('index', 'currencies')
            ? I18n.get('userNotAllowed.currencies.view', 'no tienes permisos para ver las monedas')
            : null}
          hideNoOptionMessage={!can('index', 'currencies')}
          getOptionLabel={option => option.code}
          getOptionValue={option => option.code}
          label={capitalize(I18n.get('currency', 'moneda'))}
          addOptionText={capitalize(I18n.get('newCurrency', 'Nueva moneda'))}
          addOptionAction={() => dispatch(checkFeatureLimit('multicurrency', () => dispatch(openModal({ modal: 'currency' }))))}
        />

       <div className='col-12'>
        <Field
          name="currency.exchangeRate"
          component={renderNewField}
          fieldType='decimal'
          onDecimalsError={(status) => change('decimalsError', {
            ...values?.decimalsError,
            exchangeRate: status,
          })}
          fullWidth
          separator={separator}
          decimals={isDecimalActive ? 8 : null}
          disabled={!get(values, 'currency.status')}
          label={capitalize(I18n.get('exchangeRate', 'tasa de cambio'))}
        />
       </div>
      </div>
    </div>
  )
}

export default InvoiceSettings;
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useDispatch, useSelector } from "react-redux";

import alegraAPI from "../../../reducers/alegraAPI";
import { useRefresh } from "../../../hooks/queryHooks/useRefresh";
import { useCancel } from "../../../hooks/queryHooks/useCancel";
import { get } from "lodash";
import { replaceAndParse, toast } from "../../../utils";
import { I18n } from "aws-amplify";
import { handleError } from "../../../utils/errors";
import { addWarehouse, removeWarehouse, updateWarehouse as updateWarehouseReducer } from "../../../reducers/warehouses";
import { sendGTMEvent, sendNewGTMEvent } from "../../../reducers/company";
import { endAction, startAction } from "../../../reducers/monitoring";
import { calculateActionTimeSelector } from "../../../selectors/monitoring";

const deleteWarehouse = async ({ id, warehouse, currentWarehouse }) => {
  if (id === currentWarehouse.id) {
    throw new Error(I18n.get("stationWarehouseDeletedError", "Solo puedes eliminar bodegas que no estén asociadas a una terminal."));
  }

  await alegraAPI.delete(`/warehouses/${id}`);
  return { id, warehouse }
};

const createWarehouse = async ({ warehouse }) => {
  const response = await alegraAPI.post('/warehouses',
    warehouse
  );

  return response;
};

const updateWarehouse = async ({ warehouse, currentWarehouseId, isStatusChange }) => {
  if (isStatusChange && warehouse.id === currentWarehouseId) {
    throw new Error(I18n.get("stationWarehouseStatusError", "Solo puedes desactivar bodegas que no estén asociadas a una terminal."));
  }

  const response = await alegraAPI.put(`/warehouses/${warehouse?.id}`,
    warehouse
  );

  return response;
};

export const useDeleteWarehouse = () => {
  const queryClient = useQueryClient();
  const cancel = useCancel();
  const refresh = useRefresh()
  const dispatch = useDispatch();

  queryClient.setMutationDefaults(['deleteWarehouse'], {
    mutationFn: deleteWarehouse,
  });

  return useMutation({
    mutationKey: ['deleteWarehouse'],
    mutationFn: deleteWarehouse,
    onMutate: async () => {
      await cancel('warehouses');
    },
    onError: (error) => {
      const responseError = get(error, 'response.data.message', '');

      toast.error({
        title: I18n.get('warehouseDeletedError', 'Error eliminando bodega'),
        subtitle: responseError ?? handleError(error),
      })
    },
    onSuccess: (_, variables) => {
      toast.success({
        title: I18n.get("warehouseDeleted", "bodega eliminada con éxito"),
      })
      dispatch(removeWarehouse(get(variables, 'id')));
    },
    onSettled: () => {
      refresh('warehouses');
    }
  })
}

export const useUpdateWarehouse = () => {
  const queryClient = useQueryClient();
  const cancel = useCancel();
  const refresh = useRefresh()
  const dispatch = useDispatch();

  queryClient.setMutationDefaults(['updateWarehouse'], {
    mutationFn: updateWarehouse,
  });

  return useMutation({
    mutationKey: ['updateWarehouse'],
    mutationFn: updateWarehouse,
    onMutate: async () => {
      await cancel('warehouses');
    },
    // onError: (error) => {
    //   const responseError = get(error, 'response.data.message', '');

    //   toast.error({
    //     title: I18n.get('warehouseUpdatedError', 'Error actualizando bodega'),
    //     subtitle: responseError ?? handleError(error),
    //   })
    // },
    onSuccess: (data, variables) => {
      dispatch(updateWarehouseReducer({
        id: get(variables, 'warehouse.id'),
        changes: get(data, 'data')
      }));
    },
    onSettled: () => {
      refresh('warehouses');
    }
  })
}

export const useCreateWarehouse = () => {
  const queryClient = useQueryClient();
  const cancel = useCancel();
  const refresh = useRefresh()
  const dispatch = useDispatch();
  const calculateActionTime = useSelector(calculateActionTimeSelector);

  queryClient.setMutationDefaults(['createWarehouse'], {
    mutationFn: createWarehouse,
  });

  return useMutation({
    mutationKey: ['createWarehouse'],
    mutationFn: createWarehouse,
    onMutate: async () => {
      dispatch(startAction({action: 'createWarehouse'}));
      await cancel('warehouses');
    },
    onError: (error) => {
      const responseError = get(error, 'response.data.message', '');

      dispatch(
        sendNewGTMEvent('pos-warehouse-created', {
          error: handleError(error),
        })
      );
      toast.error({
        title: I18n.get('warehouseCreatedError', 'Error creando bodega'),
        subtitle: responseError ?? handleError(error),
      })
    },
    onSuccess: (data, variables) => {
      toast.success({
        title: `${I18n.get('warehouseCreatedSuccessfully', 'Que cool.')} 💪`,
        subtitle: replaceAndParse(
          I18n.get(
            'warehouseCreatedSuccessfullyMessage',
            'La bodega {} ya está disponible para que distribuyas tu inventario.'
          ),
          [
            `<span class="font-weight-bold">${get(variables, 'warehouse.name', '')}</span>`,
          ]
        ),
      });

      dispatch(endAction({action: 'createWarehouse'}));
      dispatch(sendGTMEvent('warehouse-created'));
      dispatch(sendNewGTMEvent('pos-warehouse-created', {
        error: 'no error',
        responseTime: calculateActionTime("createWarehouse")
      }));
      dispatch(addWarehouse(get(data, 'data')));
    },
    onSettled: () => {
      refresh('warehouses');
    }
  })
}
import { I18n } from 'aws-amplify';
import dayjs from 'dayjs';
import { find, get } from 'lodash';
import QRCode from 'qrcode.react';
import { useMemo } from 'react';
import { CFDI_USES } from '../../countriesData/mexico/cfdiUses';
import { getInvoicePaymentMethd } from './utilities';
import { allNumerations } from '../../../selectors/numerations';
import { companySelector, country as countrySelector } from '../../../selectors/company';
import { useSelector } from 'react-redux';
import { mexicoDefaultPaymentValues } from '../../../utils';
import { isMexicoFeAvailable } from '../../../utils/deploys/mexicoFeDeployGroups';
import useElectronicPayrollStatus from '../../../hooks/useElectronicPayrollStatus/hook';

function Stamp({ invoice }) {
  if (!invoice?.stamp) return null;
  const country = useSelector(countrySelector);
  const company = useSelector(companySelector);
  const {isActive} = useElectronicPayrollStatus();
  const numerations = useSelector(allNumerations);

  const isFeMexActive = useMemo(() => {
    return isMexicoFeAvailable(country, isActive);
  },[country, company, isActive])

  const renderKeyValue = (label, value) => (
    <p>{label && <strong>{I18n.get('', label)}</strong>} {value}</p>
  );

  const cdfiUse = useMemo(() => {
    const use = find(CFDI_USES, { key: get(invoice, 'cfdiUse', '') });
    return use ? `${use.key} - ${use.value}` : '';
  }, [invoice?.cfdiUse])

  if(!isFeMexActive) return null;

  return (
    <div className='print-stamp-invoice'>
      {renderKeyValue('Folio Fiscal', get(invoice, 'stamp.uuid', ''))}
  
      <strong>{I18n.get('', 'Sello digital del CFDI')}</strong>
      <br />
      {renderKeyValue(null, get(invoice, 'stamp.cfdSeal', ''))}

      <strong>{I18n.get('', 'Sello del SAT')}</strong>
      <br />
      {renderKeyValue(null, get(invoice, 'stamp.satSeal', ''))}

      <strong>
        {I18n.get(
          '',
          'Cadena original del complemento de certificación digital del SAT'
        )}
      </strong>
      <br />
      {renderKeyValue(null, get(invoice, 'stamp.originalString', ''))}

      <div className='mt-2'>
        {renderKeyValue('Método de pago', mexicoDefaultPaymentValues.paymentMethod)}

        {renderKeyValue('Forma de pago', getInvoicePaymentMethd(invoice, country, numerations))}

        {renderKeyValue('No serie del certificado del CSD', get(invoice, 'stamp.certificateNumber', ''))}

        {renderKeyValue('No de serie del certificado del SAT', get(invoice, 'stamp.satCertificateNumber', ''))}

        {renderKeyValue('Fecha y hora de timbre', dayjs(get(invoice, 'stamp.stampDate', new Date())).format('YYYY-MM-DD hh:mm:ss'))}

        {renderKeyValue('Fecha y hora de certificación', dayjs(get(invoice, 'stamp.datetime', new Date())).format('YYYY-MM-DD hh:mm:ss'))}

        {renderKeyValue('Uso', cdfiUse)}

        {renderKeyValue('Versión CFDI', '4.0')}
      </div>

      <div className='d-flex justify-content-center mt-2'>
        {get(invoice, 'stamp.barCodeContent') && (
          <QRCode
            value={get(invoice, 'stamp.barCodeContent')}
            renderAs='svg'
            size={80}
            level='M'
          />
        )}
      </div>
    </div>
  );
}

export default Stamp;

import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { get } from 'lodash';

import { getMainCurrency } from '../../../../selectors/currencies';
import { currency } from '../../../../selectors/editInvoice';
import { BasicData, AdvancedData } from '../../../forms/invoice/Invoice';
import Change from './Change';
import Methods from './Methods';
import NewBasicData from '../../../forms/newInvoice/NewBasicData';
import { isPaymentMethodSelected } from '../../../../selectors/activeInvoice';
import NewModalMethods from '../../../forms/newInvoice/methods/NewModalMethods';
import NewTotal from '../../../forms/newInvoice/components/NewTotal';
import { useFormat } from '../../../../hooks/useFormat';

const NewPayments = ({ values, form, totalPrice, paymentsValues, setPaymentsValues }) => {
  const mainCurrency = useSelector(getMainCurrency);
  const selectedCurrency = useSelector(currency);
  const isPaymentSelected = useSelector(isPaymentMethodSelected);
  const { fmt } = useFormat();

  let currencyCode = '';

  if (get(selectedCurrency, 'code')) {
    currencyCode = get(selectedCurrency, 'code');
  } else if (get(mainCurrency, 'code')) {
    currencyCode = get(mainCurrency, 'code');
  }

  return (
    <div className='d-flex flex-column p-3 modal__invoice-body'>
      <NewTotal values={values} form={form} total={totalPrice} />

      <div
        className={`flex-column flex-sm-row ${!values.method ? 'd-none' : 'd-flex'}`}
      >
        <NewBasicData
          values={values}
          form={form}
          total={totalPrice}
          fmt={fmt}
          currencyCode={currencyCode}
        />
      </div>

      <div
        style={{ maxWidth: '60rem', margin: 'auto' }}
        className={!!values.method && isPaymentSelected ? 'd-none' : ''}
      >
        <NewModalMethods form={form} total={totalPrice} paymentsValues={paymentsValues} setPaymentsValues={setPaymentsValues} />
      </div>
    </div>
  );
};

export default NewPayments;

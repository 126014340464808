import React from 'react'
import { Field } from 'react-final-form';
import { I18n } from '@aws-amplify/core';
import { capitalize } from 'lodash';
import { useSelector } from 'react-redux';

import { renderSelect } from '../../forms/fields/V0/Fields';
import { getElectronicNumerations } from '../../../selectors/numerations'
import { stations as stationsSelector } from '../../../selectors/station';
import { station as stationSelector } from '../../../selectors/app';

const NumerationFields = ({ values, ...props }) => {
  const numerations = useSelector(getElectronicNumerations)
  const stations = useSelector(stationsSelector);
  const currentStation = useSelector(stationSelector);

  return (
    <div className="stations-container px-4">
      {stations.map((station, index) => {
        return (
          <div className="station-info-container" key={index}>
            <div className="station-title">
              {currentStation.id === station.id ? "Terminal actual" : station.name}
            </div>
            <div className="station-description">
              Numeración de facturas electrónicas <span className="text-primary">*</span>
            </div>
            <Field
              className=""
              name={'k' + station.id}
              component={renderSelect}
              options={numerations}
              getOptionLabel={option => option.name}
              getOptionValue={option => option.id}
              addOptionText={`+ ${capitalize(I18n.get('newNumeration', 'Nueva numeración'))}`}
              addOptionAction={() => window.open(`${process.env.REACT_APP_ALEGRA}resolution`, '_blank')}
              menuPosition="absolute"
              validate={value => !value ? capitalize(I18n.get('thisFieldIsRequired', 'Este campo es obligatorio')) : null}
              required
            />
          </div>
        )
      })
      }
    </div>
  )
}

export default NumerationFields;
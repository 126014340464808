import React from 'react';
import PropTypes from 'prop-types';
import { I18n } from '@aws-amplify/core';
import { calculateSingleItemValues, calculateSingleItemValuesWithDecimals } from '../../../utils';
import { getItemReference } from '../utils';

/**
 * Component to display items table.
 *
 * @param {Object} props - Component props.
 * @param {Array} props.items - List of refund items.
 * @param {Object} props.fmt - Formatting options.
 * @param {number} props.decimal - Number of decimal places.
 * @param {boolean} props.isDecimalActive - Flag indicating if decimal is active.
 * @returns {JSX.Element} - Rendered component.
 */
const ItemsTable = React.memo(({ items, fmt, decimal, isDecimalActive }) => (
  <div className='detail-items-table-wrapper mb-4'>
    <table className='detail-items-table rounded-lg'>
      <thead>
        <tr>
          <th className='caption-bold'>{I18n.get('item', 'producto')}</th>
          <th className='caption-bold'>
            {I18n.get('reference', 'referencia')}
          </th>
          <th className='caption-bold'>{I18n.get('price', 'precio')}</th>
          <th className='caption-bold'>{I18n.get('discount', 'descuento')}</th>
          <th className='caption-bold'>{I18n.get('taxes', 'impuestos')}</th>
          <th className='caption-bold'>{I18n.get('quantity', 'cantidad')}</th>
          <th className='caption-bold'>{I18n.get('total', 'total')}</th>
        </tr>
      </thead>
      <tbody>
        {items.map((item, index) => {
          const itemValues = isDecimalActive
            ? calculateSingleItemValuesWithDecimals(item, decimal)
            : calculateSingleItemValues(item, decimal);
          return (
            <tr key={index}>
              <td>{item.name}</td>
              <td>{getItemReference(item)}</td>
              <td>{itemValues.price.toFormat(decimal, fmt)}</td>
              <td>{itemValues.discount}%</td>
              <td>{itemValues.taxes}</td>
              <td>{itemValues.quantity}</td>
              <td>{itemValues.total.toFormat(decimal, fmt)}</td>
            </tr>
          );
        })}
      </tbody>
    </table>
  </div>
));

ItemsTable.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      reference: PropTypes.object,
    })
  ).isRequired,
  fmt: PropTypes.object.isRequired,
  decimal: PropTypes.number.isRequired,
  isDecimalActive: PropTypes.bool.isRequired,
};

export default ItemsTable;

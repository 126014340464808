import React, { useCallback, useMemo, useState, useRef, useEffect } from 'react'
import { I18n } from '@aws-amplify/core';
import { useDispatch, useSelector } from 'react-redux'
import { toUpper, debounce, get, isString } from 'lodash'

import { filter } from '../../../../reducers/clients';
import { setClient } from '../../../../reducers/activeRefund';
import { hasPermissionTo } from '../../../../selectors/auth'
import { activeClients } from '../../../../selectors/clients'
import { client as clientSelector } from '../../../../selectors/activeRefund';
import Select from '../../../common/Select';

const renderClientName = client => {
  if (!!get(client, 'name')) {
    if (!!isString(get(client, 'name')))
      return get(client, 'name')
    return `${get(client, 'name.firstName', '')}${!!get(client, 'name.secondName', null)
      ? ' ' + get(client, 'name.secondName') : ''}${!!get(client, 'name.lastName', null)
        ? ' ' + get(client, 'name.lastName') : ''}`;
  }
  return ''
}

const renderClientIdentification = client => {
  if (!client) return ''

  if (!!get(client, 'identification')) {
    if (!!isString(get(client, 'identification')))
      return ` (${get(client, 'identification')})`
    return ` (${get(client, 'identification.number')})`
  }
  return ''
}

const Client = () => {
  const dispatch = useDispatch();
  const can = useSelector(hasPermissionTo)
  const clients = useSelector(activeClients);
  const client = useSelector(clientSelector);

  const ref = useRef()
  const [loading, setLoading] = useState(false)
  
  useEffect(() => {
    ref.current = true
    return () => ref.current = false
  }, [])

  const search = useCallback(async (text = '') => {
    if (ref.current)
      setLoading(true)
    
    await dispatch(filter({ text, limit: 35 }))
    
    if (ref.current)
      setLoading(false)
  }, [dispatch])

  return (
    <div className="refund-top__client d-flex w-100 text-white align-items-center">
      <div className="w-100 px-2">
        <Select 
          value={client}
          options={can('view', 'contacts') ? clients: []}
          isLoading={loading}
          placeholder={toUpper(I18n.get('selectAClient', 'selecciona un cliente'))}
          noOptionsMessage={() => can('view', 'contacts')
            ? I18n.get('noResultsWereFound', 'No se encontraron resultados.')
            : I18n.get('userNotAllowed.contacts.view', 'No tienes permiso para ver el detalle de contacto. Habla con tu administrador para que te habilite el permiso y así puedas usar el POS correctamente.')
          }
          getOptionLabel={option => 
            `${renderClientName(option)}${renderClientIdentification(option)}`
          }
          getOptionValue={option => option.id}
          onChange={client => dispatch(setClient(client))}
          hideDropdownIndicator
          showClearIndicator
          onInputChange={useMemo(() => 
            debounce((value) => { 
              if (can('view', 'contacts'))
                search(value) 
            }, 350), [can, search]
          )}
          cacheOptions={false}
          menuPosition="absolute"
        />
      </div>
    </div>
  )
}

export default Client;
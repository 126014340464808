import { get } from 'lodash';

export const validate = () => {
  return {}
}

export const transform = values => {
  return {
    priceList: get(values, 'priceList', null),
    currency: get(values, 'currency', null),
  }
}
import { createDraftSafeSelector } from '@reduxjs/toolkit';
import { get } from 'lodash';

const notificationsWCSelector = state => get(state, 'notificationsWC', null);

export const WebComponents = {
  componentBox: 'componentBox',
  componentTop: 'componentTop',
}

export const webcomponentHasContent = wc => createDraftSafeSelector(
  notificationsWCSelector,
  notificationsWC => get(notificationsWC, `${wc}.hasContent`, null)
)
import React from 'react'
import { I18n } from 'aws-amplify'
import { capitalize } from 'lodash'

import { Icon } from '@alegradev/smile-ui-react';
import { IconAdjustmentsHorizontal, IconCash, IconPrinter } from '@tabler/icons-react';

const SmallDevicesSettings = ({ printAction, currencyAction, settingsAction }) => {
  return (
    <div className="invoice-top__header-invoice-settings p-0">
      <p className="invoice-top__header-invoice-settings-small-options"
        onClick={printAction}>
        <Icon icon={IconPrinter} extraClass="icon-gray mr-3" />
        {capitalize(I18n.get('print', 'Imprimir'))}
      </p>
      <p className="invoice-top__header-invoice-settings-small-options"
        onClick={currencyAction}>
        <Icon icon={IconCash} extraClass="icon-gray mr-3" />
        {capitalize(I18n.get('currency', 'Moneda'))}
      </p>
      <p className="invoice-top__header-invoice-settings-small-options"
        onClick={settingsAction}>
        <Icon icon={IconAdjustmentsHorizontal} extraClass="icon-gray mr-3" />
        {capitalize(I18n.get('invoiceSettings', 'Configuración de venta'))}
      </p>
    </div>
  )
}

export default SmallDevicesSettings
import React, { useMemo, useRef, useEffect } from "react";
import BigNumber from "bignumber.js";
import { get } from "lodash";
import { useSelector } from "react-redux";

import { I18n } from "aws-amplify";
import { useFormat } from "../../../hooks/useFormat";
import { useLoading } from "../../../hooks/useLoading";
import { useInventoryAdjustmentsQuery } from "./queries";
import { formatDateRelative, styleBigNumber } from "../../../utils";
import { languageSelector } from "../../../selectors/auth";

import Table from "../../common/SimplifiedTable";

const InventoryAdjustmentsSimplified = ({
  setOpenDetail,
  setSelected,
  filters,
  tableIndicators,
  setTableIndicators,
  pageCount,
  setControlledPageCount,
  setSelectedIds,
  errors,
  setErrors,
  selectedRow,
  noDataText,
}) => {
  const ref = useRef(null);
  const language = useSelector(languageSelector);

  useEffect(() => {
    ref.current = true;
    window.dataLayer.push({
      'event': 'VirtualPageView',
      'virtualPageURL': '/inventory/items',
      'virtualPageTitle': 'Items'
    });

    return () => (ref.current = false);
  }, []);
  const { fmt, decimal } = useFormat();
  const { data, isLoading, isFetching, error, refetch } = useInventoryAdjustmentsQuery(filters)
  const { loading } = useLoading({ ref, isLoading, isFetching, error, data, tableIndicators, setErrors, setControlledPageCount });

  const parsedData = !!get(data, 'data') ? data.data.filter(ia => get(ia, 'number')): [];

  const columns = useMemo(() => [
    {
      id: "number",
      accessor: "number",
      Cell: ({ value, row }) => (
        <div
          className="w-100 d-flex flex-column justify-content-center align-items-start pointer"
          onClick={() => {
            if (!get(row, 'original.editable', true))
              return null;
            setOpenDetail(true);
            setSelected(row.original);
          }}
        >
          <p
            className="h4 text-truncate"
            style={{ fontWeight: 700 }}
          >
            {value}
          </p>
          <div className="h4 text-truncate d-flex m-0 mt-3">
            <p className="m-0">{`${I18n.get("warehouse", "Bodega")}: `}</p>
            <p className="text-muted m-0 ml-3">{get(row, 'original.warehouse.name')}</p>
          </div>
        </div>
      )
    },
    {
      id: "totalCostOnlyAdjustment",
      Cell: ({ row }) => {
        const inventoryAdjustment = get(row, 'original.totalCostOnlyAdjustment');
        const optimisticTotalCost = get(row, 'original.totalAdjusted');
        const bigNumber = new BigNumber(inventoryAdjustment).toFormat(decimal, fmt);

        const { intPart, decPart } = styleBigNumber(!!optimisticTotalCost ? optimisticTotalCost : bigNumber, decimal);

        return (
          <div
            className="w-100 d-flex flex-column justify-content-between text-right pointer"
            onClick={() => {
              if (!get(row, 'original.editable', true))
                return null;
              setOpenDetail(true);
              setSelected(row.original);
            }}
          >
            <p className="h5 text-muted text-truncate mb-3">
              {formatDateRelative(get(row, 'original.date'), language)}
            </p>
            <div
              className="h4 text-truncate styled-hover d-flex flex-row m-0 mt-3"
              hovercontent={bigNumber}
            >
              <p className="text-truncate m-0">
                {intPart}
              </p>
              <p className="text-truncate text-muted m-0">
                {decPart}
              </p>
            </div>
          </div>
        );
      },
    },
  ], [decimal, fmt, setOpenDetail, setSelected, language])

  return (
    <Table
      loading={loading}
      data={parsedData}
      total={data?.metadata.total || 0}
      onFetchData={setTableIndicators}
      controlledPageCount={pageCount}
      _pageSize={tableIndicators.pageSize}
      _pageIndex={tableIndicators.pageIndex}
      error={errors}
      setSelectedIds={setSelectedIds}
      onRefresh={refetch}
      columns={columns}
      selectedRow={selectedRow}
      noDataText={noDataText}
    />
  )
};

export default InventoryAdjustmentsSimplified;
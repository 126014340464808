import { createDraftSafeSelector } from '@reduxjs/toolkit';
import { get } from 'lodash'

import { customFieldsSelector } from '../reducers/customFields'

export const customFields = createDraftSafeSelector(
  customFieldsSelector.selectAll,
  customFields => !!customFields
    ? customFields.filter(field => get(field, 'status', '') === 'active')
    : []
)

export const requiredFields = createDraftSafeSelector(
  [customFields],
  customFields => !!customFields
    ? customFields.filter(field => {
      try {
        let settingField = JSON.parse(get(field, 'settings', ''))
        return get(settingField, 'isRequired', '') === true
      } catch (error) {
        return get(field, 'settings.isRequired', '') === true
      }
    })
    : []
)

export const notRequiredFields = createDraftSafeSelector(
  [customFields],
  customFields => !!customFields
    ? customFields.filter(field => {
      try {
        let settingField = JSON.parse(get(field, 'settings', ''))
        return get(settingField, 'isRequired', '') === false
      } catch (error) {
        return get(field, 'settings.isRequired', '') === false
      }
    })
    : []
)

import React from 'react';
import { I18n } from '@aws-amplify/core';
import { Field } from 'react-final-form';
import { capitalize } from 'lodash';
import { useSelector } from 'react-redux'

import { activeSellers } from '../../../selectors/sellers'
import { renderSelect } from '../fields/V0/Fields';

const Seller = () => {
  const sellers = useSelector(activeSellers)

  return (
    <div className="form-body__fields form-row justify-content-start">
      <Field
        name="seller"
        className="col-md-6"
        component={renderSelect}
        label={capitalize(I18n.get('seller', 'vendedor'))}
        options={sellers}
        getOptionLabel={option => option.name}
        getOptionValue={option => option.id}
        showClearIndicator
      />
    </div>
  )
}

export default Seller;

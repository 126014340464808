import React, { useState, useRef } from 'react'
import { I18n } from 'aws-amplify';
import { useSelector } from 'react-redux';
import Modal from 'react-modal';
import { get } from 'lodash'
import { toast } from '../../../../utils';
import { handleError } from '../../../../utils/errors';

import {
  mandateSignatureURLSelector,
  name as nameSelector,
} from '../../../../selectors/company';

import http from '../../../../utils/http'

import { Icon } from '@alegradev/smile-ui-react';
import { IconEye, IconLoader2 } from '@tabler/icons-react';

const ViewContract = ({ setLoading, modalOpen, setModalOpen, close, loading }) => {
  const name = useSelector(nameSelector)
  const mandateSignatureURL = useSelector(mandateSignatureURLSelector)
  const [contractContent, setContractContent] = useState("");
  const downloadButton = useRef()

  const openContract = async (e) => {
    e.preventDefault();
    setLoading(true)
    if (contractContent.length === 0) {
      try {
        const response = await http.get(mandateSignatureURL, null, { responseType: 'arraybuffer' });

        if (!!get(response, 'data', null)) {
          let binary = "";
          let bytes = new Uint8Array(response.data);
          let len = bytes.byteLength;
          for (let i = 0; i < len; i++) {
            binary += String.fromCharCode(bytes[i])
          }
          setContractContent(window.btoa(binary));
        }
      } catch (error) {
        if (get(error, 'response.data') && error.response.data.message &&
          error.response.data.message.toUpperCase().includes("SERVER ERROR")) {
          return openContract(e);
        }
        toast.error({
          title: 'Error al enviar la petición',
          subtitle: handleError(error),
          params: { autoClose: 3000 }
        })
      }
    }
    setLoading(false);
    setModalOpen(prev => ({ ...prev, contract: true }));
  }

  const download = () => {
    downloadButton.current.click();
  }

  if (mandateSignatureURL === null) return null;

  return (
    <>
      <button className="btn btn-submit absolute-center text-capitalize-first"
        onClick={openContract}
        disabled={loading}>
        {loading
          ? <Icon icon={IconLoader2} animated extraClass=" icon-white" />
          : <>
            <Icon icon={IconEye} extraClass="icon-white" />
            {I18n.get('seeContract', 'Ver contrato')}
          </>}
      </button>
      <Modal
        isOpen={modalOpen.contract}
        onRequestClose={() => close('contract')}
        className="modal_contract w-75">
        <object
          style={{ width: "100%", height: "600px", padding: "5rem", paddingBottom: "2rem" }}
          data={`data:application/pdf;base64,${contractContent}`}
          type="application/pdf">{" "}</object>
        <a href={mandateSignatureURL} style={{ display: "none" }}
          ref={downloadButton} download={`Contrato Legal ${name}.pdf`}>-</a>
        <div className="d-flex justify-content-end pb-3 pr-3">
          <button className="btn btn-cancel text-capitalize-first mr-3" onClick={() => close('contract')}>
            {I18n.get('goBack', 'Volver')}
          </button>
          <button className="btn btn-submit text-capitalize-first" onClick={download}>
            {I18n.get('download', 'Descargar')}
          </button>
        </div>
      </Modal>
    </>
  )
}

export default ViewContract
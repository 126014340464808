import Cookie from 'js-cookie';
import { merge } from 'lodash'

import http from '../utils/http'

const baseUrl = process.env.REACT_APP_REPORTS_ENDPOINT
const baseConfig = {
  headers: {
    'X-Data-Source': 'pos',
    Authorization: `Basic ${Cookie.get(process.env.REACT_APP_ALEGRA_COOKIE)}`,
  }
}

const get = async (url, params = null, userConfig = null) => await http.get(`${baseUrl}${url}`, params, merge(baseConfig, userConfig), Cookie.get("pos-block-api-request"));

const defaultExport = {
  get,
}

export default defaultExport
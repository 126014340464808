import { I18n } from '@aws-amplify/core';

import Toast from '../../../components/common/Toast';
import useElectronicPayrollStatus from '../../../hooks/useElectronicPayrollStatus/hook';

function GlobalInvoicesActivateNotification() {
  const { isActive } = useElectronicPayrollStatus();

  const hadleOpenHelp = () => {
    window.open(
      'https://ayuda.alegra.com/es/pasos-para-habilitar-tu-facturaci%C3%B3n-electr%C3%B3nica-en-alegra-m%C3%A9xico',
      '_blank',
      'noopener noreferrer'
    );
  };

  if (!isActive) {
    return (
      <div className='mb-4'>
        <Toast
          type='warning'
          shadow={false}
          description={`<b>${I18n.get(
            '',
            '¡Tus facturas globales se guardarán como borrador! '
          )}</b> ${I18n.get(
            '',
            'Para timbrarlas debes activar tu facturación electrónica en Alegra.'
          )}`}
          action={{
            title: I18n.get('', 'Conocer cómo activarla'),
            handler: () => hadleOpenHelp(),
          }}
          closable={false}
        />
      </div>
    );
  }
  return <></>;
}

export default GlobalInvoicesActivateNotification;

// eslint-disable-next-line
export default ({ id, className }) => (
  <svg
    id={id}
    className={className}
    width='149'
    height='176'
    viewBox='0 0 149 176'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <g filter='url(#filter0_dd_6_5749)'>
      <rect
        x='15.9346'
        y='10.9064'
        width='104.339'
        height='126.506'
        rx='15.7377'
        transform='rotate(-6 15.9346 10.9064)'
        fill='white'
      />
      <rect
        x='46.304'
        y='40.5927'
        width='55.602'
        height='55.602'
        rx='27.801'
        fill='#F1F5F9'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M72.3876 57.3751C66.3204 58.2976 62.1498 63.9638 63.0723 70.031C63.9948 76.0981 69.661 80.2687 75.7281 79.3462C81.7953 78.4238 85.9659 72.7576 85.0434 66.6904C84.1209 60.6233 78.4547 56.4527 72.3876 57.3751ZM67.0236 62.1216C68.4312 60.5329 70.382 59.4092 72.6445 59.0652C77.7783 58.2847 82.5728 61.8136 83.3533 66.9474C83.6973 69.2099 83.2043 71.4065 82.1057 73.2227L67.0236 62.1216ZM66.0102 63.4983C64.9115 65.3146 64.4184 67.5113 64.7624 69.774C65.5429 74.9077 70.3374 78.4367 75.4712 77.6561C77.7338 77.3121 79.6848 76.1884 81.0924 74.5995L66.0102 63.4983Z'
        fill='#94A3B8'
      />
      <circle cx='91' cy='45' r='10' fill='#30ABA9' />
      <path
        d='M90.7934 40.1037L91.9931 47.1937L90.4212 47.4968L88.9041 40.4679L90.7934 40.1037ZM91.7944 50.3914C91.4983 50.4485 91.2243 50.3933 90.9726 50.2258C90.7242 50.0577 90.5711 49.8239 90.5134 49.5245C90.4569 49.2316 90.5128 48.9609 90.6809 48.7125C90.8523 48.4634 91.0861 48.3103 91.3822 48.2532C91.6719 48.1974 91.9425 48.2532 92.1942 48.4207C92.4492 48.5876 92.6049 48.8175 92.6614 49.1104C92.6996 49.3089 92.684 49.4993 92.6145 49.6814C92.5482 49.863 92.4431 50.0183 92.2992 50.1473C92.158 50.2724 91.9897 50.3538 91.7944 50.3914Z'
        fill='white'
      />
    </g>
    <defs>
      <filter
        id='filter0_dd_6_5749'
        x='-1.16043'
        y='-6.10352e-05'
        width='151.181'
        height='176.608'
        filterUnits='userSpaceOnUse'
        color-interpolation-filters='sRGB'
      >
        <feFlood flood-opacity='0' result='BackgroundImageFix' />
        <feColorMatrix
          in='SourceAlpha'
          type='matrix'
          values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
          result='hardAlpha'
        />
        <feMorphology
          radius='5.69835'
          operator='erode'
          in='SourceAlpha'
          result='effect1_dropShadow_6_5749'
        />
        <feOffset dy='11.3967' />
        <feGaussianBlur stdDeviation='5.69835' />
        <feComposite in2='hardAlpha' operator='out' />
        <feColorMatrix
          type='matrix'
          values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.04 0'
        />
        <feBlend
          mode='normal'
          in2='BackgroundImageFix'
          result='effect1_dropShadow_6_5749'
        />
        <feColorMatrix
          in='SourceAlpha'
          type='matrix'
          values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
          result='hardAlpha'
        />
        <feMorphology
          radius='5.69835'
          operator='erode'
          in='SourceAlpha'
          result='effect2_dropShadow_6_5749'
        />
        <feOffset dy='22.7934' />
        <feGaussianBlur stdDeviation='11.3967' />
        <feComposite in2='hardAlpha' operator='out' />
        <feColorMatrix
          type='matrix'
          values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0'
        />
        <feBlend
          mode='normal'
          in2='effect1_dropShadow_6_5749'
          result='effect2_dropShadow_6_5749'
        />
        <feBlend
          mode='normal'
          in='SourceGraphic'
          in2='effect2_dropShadow_6_5749'
          result='shape'
        />
      </filter>
    </defs>
  </svg>
);

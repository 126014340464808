import React, { useState, useEffect, useCallback, useRef, useMemo } from 'react'
import { I18n } from '@aws-amplify/core';
import { useDispatch, useSelector } from 'react-redux'
import { Field } from 'react-final-form';
import { get, toUpper, debounce } from 'lodash';

import { filter } from '../../../reducers/clients';
import { activeSellers } from '../../../selectors/sellers';
import { invoiceClients } from '../../../selectors/clients';
import { activeCurrencies, getMainCurrency } from '../../../selectors/currencies';
import { hasPermissionTo } from '../../../selectors/auth'
import { country as countrySelector, multicurrency } from '../../../selectors/company';
import { renderSelect, renderDate, renderField } from '../../forms/fields/V0/Fields';
import {
  getLegalStatusKey,
  legalStatusToBadgeStatus,
  renderClientIdentification,
  renderClientName,
} from '../utils'

const Info = ({ invoice, form, values, updateItemPrice }) => {
  const dispatch = useDispatch();
  const can = useSelector(hasPermissionTo)
  const country = useSelector(countrySelector)
  const isMulticurrency = useSelector(multicurrency)
  const clients = useSelector(invoiceClients);
  const sellers = useSelector(activeSellers);
  const currencies = useSelector(activeCurrencies);
  const mainCurrency = useSelector(getMainCurrency)

  const legalStatusKey = getLegalStatusKey(invoice);

  const ref = useRef()
  const [loading, setLoading] = useState(false)
  const [hasSearched, setHasSearched] = useState(false);
  const [searchText, setSearchText] = useState('');

  useEffect(() => {
    ref.current = true
    return () => ref.current = false
  }, [])

const search = useCallback(
  async (text = '') => {
    if (ref.current) {
      setLoading(true);
    }

    if (text.trim() || hasSearched) {
      await dispatch(filter({ text: hasSearched ? '' : text, limit: 35 }));

      if (!hasSearched) {
        setHasSearched(true);
      }
    }

    if (ref.current) {
      setLoading(false);
    }

    setSearchText(text);
  },
  [dispatch, hasSearched]
);

useEffect(() => {
  if (!searchText.trim() && hasSearched) {
    setHasSearched(false);
  }
}, [searchText, hasSearched]);

  return (
    <table className="detail-info-table mb-4">
      <tbody>
        <tr>
          <td>{I18n.get('status', 'estado')}</td>
          {!!invoice.offlineStatus ? (
            <td>
              <div className="badge badge-pill m-0 font-weight-normal p-2 px-3 badge-warning">
                {I18n.get("offline", "Offline")}
              </div>
            </td>
          ) : (
            <td>
              <div className={`badge badge-pill m-0 font-weight-normal p-2 px-3 badge-${invoice.status}`}>
                {I18n.get(`invoiceStatus${invoice.status}`, `${invoice.status}`)}
              </div>
            </td>
          )}
        </tr>
        {!!legalStatusKey &&
          <tr>
            <td>{I18n.get('emissionStatus', 'estado de emision')}</td>
            <td>
              <div className={`badge badge-pill m-0 font-weight-normal text-capitalize-first p-2 px-3 badge-${legalStatusToBadgeStatus(legalStatusKey)}`}>
                {I18n.get(legalStatusKey, legalStatusKey)}
              </div>
            </td>
          </tr>
        }

        <tr>
          <td>
            {I18n.get('client', 'cliente')}
            <i className="text-primary mr-2"> * </i>
          </td>
          <td>
            <Field
              name="client"
              className="edit-field"
              height="2.4rem"
              fontSize="12px"
              component={renderSelect}
              options={country !== 'mexico' && can('view', 'contacts') ? clients : []}
              isLoading={loading}
              placeholder={toUpper(I18n.get('selectAClient', 'selecciona un cliente'))}
              noOptionsMessage={() => can('view', 'contacts')
                ? I18n.get('noResultsWereFound', 'No se encontraron resultados.')
                : I18n.get('userNotAllowed.contacts.view', 'No tienes permiso para ver el detalle de contacto. Habla con tu administrador para que te habilite el permiso y así puedas usar el POS correctamente.')
              }
              getOptionLabel={option =>
                `${renderClientName(option)}${renderClientIdentification(option)}`
              }
              getOptionValue={option => option.id}
              hideDropdownIndicator
              onInputChange={useMemo(() =>
                debounce((value) => {
                  if (can('view', 'contacts'))
                    search(value)
                }, 350), [can, search]
              )}
              cacheOptions={false}
            />
          </td>
        </tr>

        <tr>
          <td>
            {I18n.get('creation', 'creación')}
            <i className="text-primary mr-2"> * </i>
          </td>
          <td>
            <Field
              name="date"
              className="edit-field"
              component={renderDate}
              onChange={date => {
                try {
                  const dueDate = new Date(values.dueDate)
                  if (date > dueDate)
                    form.change('dueDate', date)
                } catch {

                }
                return date
              }}
            />
          </td>
        </tr>
        <tr>
          <td>
            {I18n.get('dueDate', 'fecha de vencimiento')}
            <i className="text-primary mr-2"> * </i>
          </td>
          <td>
            <Field
              name="dueDate"
              className="edit-field"
              component={renderDate}
              minDate={new Date(get(values, 'date'))}
            />
          </td>
        </tr>
        <tr>
          <td>{I18n.get('termsConditions', 'términos y condiciones')}</td>
          <td>{get(invoice, 'termsConditions', '')}</td>
        </tr>
        <tr>
          <td>{I18n.get('anotations', 'observaciones')}</td>
          <td>{get(invoice, 'anotation', '')}</td>
        </tr>
        {!!get(invoice, 'numberTemplate.text', '') && (
          <tr>
            <td>{I18n.get('resolution', 'Resolución')}</td>
            <td>{get(invoice, 'numberTemplate.text', '')}</td>
          </tr>
        )}
        {!!isMulticurrency && !get(invoice, 'payments.length')
          ? (
            <tr>
              <td>{I18n.get('currency', 'moneda')}</td>
              <td className="d-flex form-row m-0">
                <Field
                  name="currency"
                  className={`edit-field ${!get(values, 'currency.main') ? 'currency' : ''}`}
                  component={renderSelect}
                  options={currencies}
                  height="2.4rem"
                  fontSize="12px"
                  getOptionLabel={option => option.code}
                  getOptionValue={option => option.code}
                  hideDropdownIndicator
                  showClearIndicator
                  onChange={value => {
                    const currency = !!value ? value : mainCurrency
                    if (get(values, 'items.length')) {
                      values.items.map((item, index) => {
                        form.change(`items.${index}`, { ...updateItemPrice(item, { ...values, currency }) })
                        return null
                      })
                    }
                    return currency
                  }}
                />

                {!get(values, 'currency.main') && (
                  <Field
                    name="currency.exchangeRate"
                    className="edit-field currency"
                    component={renderField}
                    type="number"
                    onChange={event => {
                      values.items.map((item, index) => {
                        form.change(`items.${index}`, {
                          ...updateItemPrice(item, {
                            ...values,
                            currency: {
                              ...values.currency,
                              exchangeRate: +event.target.value,
                            }
                          })
                        })
                        return null
                      })
                      return event
                    }}
                  />
                )}
              </td>
            </tr>
          )
          : (
            <tr>
              <td>{I18n.get('currency', 'moneda')}</td>
              <td>
                {!!get(invoice, 'currency.code')
                  ? get(invoice, 'currency.code')
                  : get(mainCurrency, 'code')
                }
              </td>
            </tr>
          )
        }
        <tr>
          <td>{I18n.get('warehouse', 'bodega')}</td>
          <td>{get(invoice, 'warehouse.name', '')}</td>
        </tr>
        <tr>
          <td>{I18n.get('seller', 'vendedor')}</td>
          <td>
            <Field
              name="seller"
              className="edit-field"
              component={renderSelect}
              options={sellers}
              height="2.4rem"
              fontSize="12px"
              getOptionLabel={option => option.name}
              getOptionValue={option => option.id}
              hideDropdownIndicator
              showClearIndicator
            />
          </td>
        </tr>
        {!!get(invoice, 'idShift', null) && (
          <tr>
            <td>{I18n.get('shiftNumber', 'número de turno')}</td>
            <td>{get(invoice, 'idShift', '')}</td>
          </tr>
        )}
      </tbody>
    </table>
  )
}

export default Info
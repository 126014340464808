import React from "react";
import { Field } from "react-final-form";
import { I18n } from "@aws-amplify/core";
import { capitalize, get } from "lodash";
import { useSelector } from "react-redux";

import { activeCostCenters } from "../../../selectors/costCenters";
import { renderField, renderSelect } from "../fields/V0/Fields";

const Warehouse = (props) => {
  const costCenters = useSelector(activeCostCenters);
  
  return (
    <div className="form-body h-100 overflow-hidden">
      <div className={`form-body__fields form-${get(props, 'fromOldForm') ? "column" : "row"} justify-content-start`}>
        <Field name="name" className="col-6" component={renderField} type="text" label={capitalize(I18n.get("name", "Nombre"))} required />

        <Field name="address" className="col-6" component={renderField} type="text" label={capitalize(I18n.get("address", "Dirección"))} />

        <Field
          name="costCenter"
          component={renderSelect}
          className="col-12"
          options={costCenters}
          getOptionLabel={(option) => option.name}
          getOptionValue={(option) => option.id}
          label={capitalize(I18n.get("costCenter", "Centro de costo"))}
          menuPosition="absolute"
          showClearIndicator
        />

        <Field
          name="observations"
          className="col-12"
          component={renderField}
          type="textarea"
          label={capitalize(I18n.get("description", "Descripción"))}
        />
      </div>
    </div>
  );
};

export default Warehouse;

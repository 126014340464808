import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import PrintRefundFactory from './mainPrintStrategies/PrintRefundFactory';
import { printSettings, logo as logoSelector, country as countrySelector } from '../../../selectors/company';
import { extractRefundData } from './utilities';

/**
 * Component to render the printable refund.
 *
 * @param {Object} props - Component props.
 * @param {Object} props.refund - The refund object.
 * @returns {JSX.Element} - Rendered printable refund component.
 */
const PrintRefund = ({ refund }) => {
  const companySetting = useSelector(printSettings);
  const companyLogo = useSelector(logoSelector);
  const country = useSelector(countrySelector);

  const {
    isElectronic,
    items,
    invoices,
    invoiceType,
    cude,
    note,
    tip,
  } = extractRefundData(refund);

  const imageOrQr = companySetting.imageOrQr;
  const customPhrase = companySetting.customPhrase;

  return (
    <PrintRefundFactory
      country={country}
      refund={refund}
      companySetting={companySetting}
      imageOrQr={imageOrQr}
      isElectronic={isElectronic}
      companyLogo={companyLogo}
      items={items}
      invoices={invoices}
      invoiceType={invoiceType}
      cude={cude}
      customPhrase={customPhrase}
      note={note}
      tip={tip}
    />
  );
};

PrintRefund.propTypes = {
  refund: PropTypes.shape({
    numberTemplate: PropTypes.shape({
      isElectronic: PropTypes.bool,
      documentType: PropTypes.string,
    }),
    items: PropTypes.array,
    invoices: PropTypes.array,
    stamp: PropTypes.shape({
      cufe: PropTypes.string,
      warnings: PropTypes.arrayOf(PropTypes.string),
      date: PropTypes.string,
    }),
    additionalCharges: PropTypes.arrayOf(
      PropTypes.shape({
        amount: PropTypes.number,
      })
    ),
    note: PropTypes.string,
    total: PropTypes.number.isRequired,
  }).isRequired,
};

export default React.memo(PrintRefund);

import { createDraftSafeSelector } from '@reduxjs/toolkit';
import { get } from 'lodash'
import { BigNumber } from 'bignumber.js'

const currentShiftSelector = state => state.shifts.currentShiftStatus;
const currentShiftPaymentsSelector = state => state.shifts.currentShiftPayments;

export const isOpen = createDraftSafeSelector(
  currentShiftSelector, 
  shift => !!(get(shift, 'status', null))
    ? get(shift, 'status') === 'open'
    : null
)

export const baseAmount = createDraftSafeSelector(
  currentShiftSelector, 
  shift => get(shift, 'startingAmount', 0)
)

export const startDate = createDraftSafeSelector(
  currentShiftSelector, 
  shift => get(shift, 'start')
)

export const current = createDraftSafeSelector(
  currentShiftSelector, 
  shift => shift
)

export const paymentsLoading = createDraftSafeSelector(
  currentShiftPaymentsSelector, 
  payments => payments.loading
)

export const paymentsError = createDraftSafeSelector(
  currentShiftPaymentsSelector, 
  payments => payments.error
)

export const payments = createDraftSafeSelector(
  currentShiftPaymentsSelector, 
  payments => payments.payments
)

export const availableCash = createDraftSafeSelector(
  [baseAmount, payments],
  (base, payments) => {
    const baseCash = new BigNumber(!!base ? base : 0)
    const cash = new BigNumber(!!get(payments, 'cash') ? get(payments, 'cash') : 0)
    const inCash = new BigNumber(!!get(payments, 'inPayment') ? get(payments, 'inPayment') : 0)
    const outCash = new BigNumber(!!get(payments, 'outPayment') ? get(payments, 'outPayment') : 0)
    const refunds = new BigNumber(!!get(payments, 'refunds') ? get(payments, 'refunds') : 0)

    return baseCash.plus(cash).plus(inCash).minus(outCash).minus(refunds).toNumber()
  }
)

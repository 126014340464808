import React from 'react'
import PropTypes from 'prop-types';

import PaginationComponent from '../Pagination'

import { Icon } from '@alegradev/smile-ui-react';
import { IconLoader2 } from '@tabler/icons-react';

const renderBody = ({
  header,
  options,
  error,
  errorElement,
  emptyElement,
  loading,
  notAllowedToView,
  notAllowedToIndex,
  ...props
}) => {
  if (!!notAllowedToView)
    return <div className="w-100 h-100 d-flex justify-content-center align-items-center d-sm-none">{notAllowedToView}</div>
  
  if (!!notAllowedToIndex)
    return <div className="w-100 h-100 d-flex justify-content-center align-items-center">{notAllowedToView}</div>
  
  if (!!error)
    return errorElement;

  if (loading) {
    return (
      <div className="w-100 h-100 d-flex justify-content-center align-items-center">
        <Icon icon={IconLoader2} animated extraClass=" icon-primary icon x2" />
      </div>
    )
  }

  if (!options || !options.length)
    return <div className="w-100 h-100 d-flex justify-content-center align-items-center d-sm-none">{emptyElement}</div>

  return (
    <>
      <div className="pagination-header d-flex">
        {header}
      </div>
      <div className="body h-100">
        {options}
      </div>

      <PaginationComponent {...props}/>
    </>
  )
}

const Pagination = ({ className, ...props }) => {
  return (
    <div className={`master-detail__pagination d-flex flex-column ${className ? className : ''}`}>
      {renderBody(props)}
    </div>
  )
}

Pagination.propTypes = {
  header: PropTypes.element,
  options: PropTypes.array,
  className: PropTypes.string,
  onFetchData: PropTypes.func,
  paginationData: PropTypes.shape({
    start: PropTypes.number,
    limit: PropTypes.number,
    total: PropTypes.number,
    sortDirection: PropTypes.string,
  }),
  error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  loading: PropTypes.bool,
  errorElement: PropTypes.element,
  emptyElement: PropTypes.element,
  notAllowedToIndex: PropTypes.string
}

export default Pagination;

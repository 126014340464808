import React from 'react'
import PropTypes from 'prop-types'

import Modal from '../../common/Modal';
import Bottom from '../common/Bottom';

let Confirmation = ({
  isOpen,
  onClose,
  onConfirm,
  title,
  body,
  closeText,
  confirmText,
  disabled,
  submitting,
  hideCancel,
  className,
  hideHeader,
  confirmId,
  closeId,
  hideRequired,
  disableCancel,
}) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={() => onClose()}
      title={title}
      className={`modal__medium-size ${className || ''}`}
      hideHeader={hideHeader}
      closeId={closeId}
    >
      {body}

      <Bottom 
        onClose={() => onClose()}
        onConfirm={() => onConfirm()}
        closeText={closeText}
        submitText={confirmText}
        disabled={disabled}
        submitting={submitting}
        hideCancel={hideCancel}
        submitId={confirmId}
        hideRequired={hideRequired}
        disableCancel={disableCancel}
      />
    </Modal>
  )
}

Confirmation.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
  title: PropTypes.string,
  body: PropTypes.element,
  closeText: PropTypes.string,
  confirmText: PropTypes.string,
  disabled: PropTypes.bool,
  submitting: PropTypes.bool,
  hideCancel: PropTypes.bool,
  className: PropTypes.string,
  hideHeader: PropTypes.bool,
  confirmId: PropTypes.string,
  hideRequired: PropTypes.bool
}

export default Confirmation;
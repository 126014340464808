import { useHistory } from 'react-router-dom';
import { I18n } from '@aws-amplify/core';

import { useGlobalInvoice } from './context';
import { PageHeading } from '@alegradev/smile-ui-react';

function GlobalInvoiceHeader() {
  const history = useHistory();
  const { isEditable } = useGlobalInvoice();

  return (
    <PageHeading
      title={
        isEditable
          ? I18n.get('editGlobalInvoice', 'Editar factura global')
          : I18n.get('createGlobalInvoice', 'Nueva factura global')
      }
      supTitle={I18n.get('CFDIVersion', 'Versión CFDI 4.0')}
      back={{
        action: () => history.push('/global-invoices'),
      }}
    />
  );
}

export default GlobalInvoiceHeader;

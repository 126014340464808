import React from 'react';
import { Field } from 'react-final-form';
import { useSelector } from 'react-redux'
import { I18n } from '@aws-amplify/core';
import { capitalize, lowerCase, deburr, get } from 'lodash'

import { activeWarehouses } from '../../../selectors/warehouses'
import { renderSelect, renderField } from '../fields/V0/Fields'
import configAPI from '../../../reducers/configAPI';

const filterOptions = (query, options) =>
  options.filter(option =>
    lowerCase(deburr(option.value)).includes(lowerCase(deburr(query)))
    || lowerCase(deburr(option.code)).includes(lowerCase(deburr(query))))

const OtherMethods = ({ country }) => {
  const warehouses = useSelector(activeWarehouses)

  let creditNoteTypesParams = {}

  if (country === 'costaRica') {
    creditNoteTypesParams = {
      ...creditNoteTypesParams,
      electronicInvoicingVersion: '4.3',
      onlyElectronicInvoicing: false
    }
  }
  if (country === 'colombia') {
    creditNoteTypesParams = {
      ...creditNoteTypesParams,
      electronicInvoicingVersion: '2.1',
      onlyElectronicInvoicing: false
    }
  }


  return (
    <div className="form-body h-100 p-4 overflow-hidden">
      <div className="form-body__fields form-row align-items-start justify-content-start">

        {(country === 'costaRica' || country === 'peru' || country === 'colombia') &&
          <Field
            name="type"
            component={renderSelect}
            className="col-md-4 col-12"
            label={capitalize(I18n.get('creditNoteType', 'tipo de nota crédito'))}
            loadOptions={async inputValue => {
              // ex queries.allCreditNoteTypes

              try {
                const response = await configAPI.get('/credit-note-types', {
                  application_version: country,
                  ...creditNoteTypesParams
                })
                let data = get(response, 'data', [])
                return filterOptions(inputValue, data)
              } catch {
                return []
              }
            }}
            required
            cacheOptions
            defaultOptions
            getOptionLabel={option => option.value}
            getOptionValue={option => option.key}
            isSearchable
            placeholder={capitalize(I18n.get('select', 'selecciona'))}
            menuPosition="absolute"
          />
        }
        {(country === 'costaRica' || country === 'peru') &&
          <Field
            name="cause"
            className="col-md-4 col-12"
            component={renderField}
            type="text"
            label={capitalize(I18n.get('cause', 'razón'))}
            placeholder={capitalize(I18n.get('cause', 'razón'))}
            required
          />
        }
        {(country === 'costaRica' || country === 'argentina') &&
          <Field
            name="saleCondition"
            component={renderSelect}
            className="col-md-4 col-12"
            label={capitalize(I18n.get('saleCondition', 'condición'))}
            required
            cacheOptions
            loadOptions={async inputValue => {
              try {
                // ex queries.allSaleConditions

                const response = await configAPI.get('/sale-conditions', {
                  application_version: country
                })
                let data = get(response, 'data', [])
                return filterOptions(inputValue, data)
              } catch {
                return []
              }
            }}
            defaultOptions
            getOptionLabel={option => option.value}
            getOptionValue={option => option.key}
            isSearchable
            placeholder={capitalize(I18n.get('select', 'selecciona'))}
            menuPosition="absolute"
          />
        }
        {country === 'costaRica' &&
          <Field
            name="paymentMethod"
            component={renderSelect}
            className="col-md-4 col-12"
            label={capitalize(I18n.get('paymentMethod', 'método de pago'))}
            required
            cacheOptions
            loadOptions={async inputValue => {
              try {
                // ex queries.allPaymentMethods

                const response = await configAPI.get('/payment-methods', {
                  application_version: country
                })
                let data = get(response, 'data', [])
                return filterOptions(inputValue, data)
              } catch {
                return []
              }
            }}
            defaultOptions
            getOptionLabel={option => option.value}
            getOptionValue={option => option.key}
            isSearchable
            placeholder={capitalize(I18n.get('select', 'selecciona'))}
            menuPosition="absolute"
          />
        }
        {country === 'costaRica' &&
          <Field
            name="economicActivity"
            component={renderSelect}
            className="col-md-4 col-12"
            label={capitalize(I18n.get('economicActivity', 'actividad económica'))}
            required
            cacheOptions
            loadOptions={async inputValue => {
              try {
                // ex queries.allEconomicActivities

                const response = await configAPI.get('/economic-activities', {
                  application_version: country,
                })
                let data = get(response, 'data', [])
                return filterOptions(inputValue, data)
              } catch {
                return []
              }
            }}
            defaultOptions
            getOptionLabel={option => `${option.code} - ${option.value}`}
            getOptionValue={option => option.code}
            isSearchable
            placeholder={capitalize(I18n.get('select', 'selecciona'))}
            menuPosition="absolute"
          />
        }
        {country === 'costaRica' &&
          <Field
            name="warehouse"
            component={renderSelect}
            className="col-md-4 col-12"
            label={capitalize(I18n.get('warehouse', 'bodega'))}
            required
            options={warehouses}
            getOptionLabel={option => option.name}
            getOptionValue={option => option.id}
            placeholder={capitalize(I18n.get('select', 'selecciona'))}
            menuPosition="absolute"
          />
        }

      </div>
    </div>
  )
}

export default OtherMethods;
function AlegraStore({ className, id }) {
  return (
    <svg
      id={id}
      className={className}
      width='146'
      height='56'
      viewBox='0 0 146 56'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M19.0636 8.8667H22.0544L18.9949 21.0596C18.3478 23.6383 16.008 25.4489 13.3226 25.4489C7.77808 25.4489 5.34973 18.524 9.70276 15.1262L16.6682 9.68906C17.3506 9.15639 18.1945 8.8667 19.0636 8.8667Z'
        fill='#00D6BC'
      />
      <path
        d='M22.3918 18.3622L24.7628 8.8667H31.2373L33.6082 18.3622C34.508 21.9658 31.7516 25.4489 28 25.4489C24.2484 25.4489 21.492 21.9658 22.3918 18.3622Z'
        fill='#00D6BC'
      />
      <path
        d='M33.9456 8.8667L37.0051 21.0596C37.6522 23.6383 39.992 25.4489 42.6774 25.4489C48.2219 25.4489 50.6503 18.524 46.2973 15.1262L39.3318 9.68906C38.6494 9.15639 37.8056 8.8667 36.9364 8.8667H33.9456Z'
        fill='#00D6BC'
      />
      <path
        d='M10.8113 32.2519C10.8113 29.9036 12.7352 28 15.1085 28H40.8919C43.2652 28 45.1891 29.9037 45.1891 32.2519V42.8815C45.1891 45.2297 43.2652 47.1334 40.8919 47.1334H34.0163V39.48C34.0163 37.1318 32.0924 35.2282 29.7191 35.2282H26.2813C23.908 35.2282 21.9841 37.1318 21.9841 39.48V47.1334H15.1085C12.7352 47.1334 10.8113 45.2297 10.8113 42.8815V32.2519Z'
        fill='#00D6BC'
      />
      <path
        d='M63.6412 21.4374C61.3854 21.4374 59.7333 19.6178 59.7333 17.3903C59.7333 15.1942 61.5125 13.3746 63.8795 13.3746C66.2306 13.3746 68.0256 15.1001 68.0256 17.3903V21.2491H66.5324V19.9001C65.9446 20.8413 64.912 21.4374 63.6412 21.4374ZM63.8795 19.9629C65.341 19.9629 66.453 18.8021 66.453 17.406C66.453 15.9942 65.341 14.8334 63.8795 14.8334C62.4339 14.8334 61.306 15.9942 61.306 17.406C61.306 18.8021 62.4339 19.9629 63.8795 19.9629Z'
        fill='#64748B'
      />
      <path
        d='M70.1195 10.0334H71.6922V21.2491H70.1195V10.0334Z'
        fill='#64748B'
      />
      <path
        d='M77.4154 21.4217C75.0484 21.4217 73.2851 19.7276 73.2851 17.3589C73.2851 15.1472 74.8896 13.3433 77.2248 13.3433C79.5758 13.3433 81.0373 15.0217 81.0373 17.1707V17.7982H74.8101C74.9849 19.1158 76.0016 20.0413 77.3995 20.0413C78.3526 20.0413 79.2105 19.6334 79.6711 18.7864L80.8784 19.3982C80.1954 20.7158 78.9404 21.4217 77.4154 21.4217ZM74.8737 16.7942H79.4646C79.4011 15.5707 78.4956 14.7393 77.2089 14.7393C75.8904 14.7393 75.0325 15.6491 74.8737 16.7942Z'
        fill='#64748B'
      />
      <path
        d='M85.9491 24.9668C84.2652 24.9668 82.8673 24.2452 82.1524 22.8805L83.4709 22.1746C83.9316 23.006 84.7894 23.5393 85.9173 23.5393C87.5535 23.5393 88.6655 22.3785 88.6655 20.5746V19.9629C88.1572 20.7629 87.204 21.3589 85.9014 21.3589C83.6933 21.3589 81.9459 19.6334 81.9459 17.3746C81.9459 15.1785 83.7251 13.3746 86.092 13.3746C88.4431 13.3746 90.2382 15.1001 90.2382 17.3903V20.5589C90.2382 23.2256 88.4272 24.9668 85.9491 24.9668ZM86.0761 19.9001C87.5059 19.9001 88.6496 18.7707 88.6496 17.3589C88.6496 15.9472 87.5059 14.8491 86.0761 14.8491C84.6623 14.8491 83.5027 15.9472 83.5027 17.3589C83.5027 18.7707 84.6623 19.9001 86.0761 19.9001Z'
        fill='#64748B'
      />
      <path
        d='M93.5871 21.2491H92.0144V16.6687C92.0144 14.8021 93.0946 13.406 95.128 13.406C95.6204 13.406 96.1288 13.5001 96.5259 13.6883V15.2256C96.1446 15.006 95.6998 14.8962 95.2709 14.8962C94.2225 14.8962 93.5871 15.6021 93.5871 16.7315V21.2491Z'
        fill='#64748B'
      />
      <path
        d='M100.616 21.4374C98.3598 21.4374 96.7077 19.6178 96.7077 17.3903C96.7077 15.1942 98.4869 13.3746 100.854 13.3746C103.205 13.3746 105 15.1001 105 17.3903V21.2491H103.507V19.9001C102.919 20.8413 101.886 21.4374 100.616 21.4374ZM100.854 19.9629C102.315 19.9629 103.427 18.8021 103.427 17.406C103.427 15.9942 102.315 14.8334 100.854 14.8334C99.4083 14.8334 98.2804 15.9942 98.2804 17.406C98.2804 18.8021 99.4083 19.9629 100.854 19.9629Z'
        fill='#64748B'
      />
      <path
        d='M73.1154 28.0913V30.5551H67.7417V45.6521H65.0809V30.5551H59.7333V28.0913H73.1154Z'
        fill='#334155'
      />
      <path
        d='M76.3504 30.2668C75.4113 30.2668 74.6548 29.5067 74.6548 28.5631C74.6548 27.6196 75.4113 26.8333 76.3504 26.8333C77.2895 26.8333 78.0199 27.6196 78.0199 28.5631C78.0199 29.5067 77.2895 30.2668 76.3504 30.2668ZM75.0722 32.8091H77.6547V45.6521H75.0722V32.8091Z'
        fill='#334155'
      />
      <path
        d='M87.0527 45.9404C83.1659 45.9404 80.2704 43.1097 80.2704 39.152C80.2704 35.4564 82.9051 32.4422 86.7397 32.4422C90.6004 32.4422 93.0003 35.2467 93.0003 38.8375V39.8859H82.7747C83.0616 42.0875 84.7311 43.6339 87.0267 43.6339C88.5918 43.6339 90.0004 42.9524 90.7569 41.5371L92.7394 42.5593C91.6178 44.7609 89.557 45.9404 87.0527 45.9404ZM82.879 38.2084H90.4178C90.3135 36.164 88.8266 34.7749 86.7136 34.7749C84.5485 34.7749 83.1399 36.2951 82.879 38.2084Z'
        fill='#334155'
      />
      <path
        d='M101.64 34.9846C99.1356 34.9846 97.8834 37.0552 97.8834 39.4403V45.6521H95.301V39.1782C95.301 35.4301 97.5182 32.4946 101.64 32.4946C105.814 32.4946 108.083 35.4301 108.083 39.152V45.6521H105.501V39.4665C105.501 37.0552 104.196 34.9846 101.64 34.9846Z'
        fill='#334155'
      />
      <path
        d='M117 45.9666C113.113 45.9666 110.192 42.9524 110.192 39.2568C110.192 35.3515 113.009 32.4946 116.87 32.4946C118.591 32.4946 120.156 33.2547 121.2 34.408L121.226 26.9119H123.808V39.2568C123.808 43.0573 120.861 45.9666 117 45.9666ZM117 43.5291C119.4 43.5291 121.226 41.5895 121.226 39.2306C121.226 36.8979 119.4 34.9584 117 34.9584C114.626 34.9584 112.774 36.8979 112.774 39.2306C112.774 41.5895 114.626 43.5291 117 43.5291Z'
        fill='#334155'
      />
      <path
        d='M132.334 45.9666C128.629 45.9666 125.917 42.9262 125.917 39.2044C125.917 35.535 128.838 32.4946 132.725 32.4946C136.586 32.4946 139.533 35.3777 139.533 39.2044V45.6521H137.081V43.398C136.116 44.9706 134.421 45.9666 132.334 45.9666ZM132.725 43.5028C135.125 43.5028 136.951 41.5633 136.951 39.2306C136.951 36.8717 135.125 34.9322 132.725 34.9322C130.351 34.9322 128.499 36.8717 128.499 39.2306C128.499 41.5633 130.351 43.5028 132.725 43.5028Z'
        fill='#334155'
      />
    </svg>
  );
}

export default AlegraStore;

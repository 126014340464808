import React from 'react';
import { Field } from 'react-final-form';
import { I18n } from '@aws-amplify/core';
import { capitalize } from 'lodash';

import renderIdentification from '../company/BasicData/Identification'
import { renderField } from '../fields/V0/Fields';

const CompanyEmail = props => {
  return (
    <div className="form-body h-100 p-4 overflow-hidden">
      <div className="form-body__fields form-row justify-content-start">
        {renderIdentification(props)}

        <Field
          name="email"
          className="col-12"
          component={renderField}
          type="text"
          label={capitalize(I18n.get('email', 'correo electrónico'))}
          help={capitalize(I18n.get('companyEmailHelp', 'Este es el email de tu compañía'))}
          required
        />
      </div>
    </div>
  )
}

export default CompanyEmail;
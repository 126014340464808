import { useSelector } from "react-redux";
import {
  address as addressSelector,
  country as countrySelector,
  electronicInvoicing,
  identificationSelector,
  name as nameSelector
} from "../../selectors/company";
import { COUNTRIES } from "../../utils/enums/countries";
import { useMemo } from "react";

/**
 * Custom hook to determine TicketBAI eligibility and enabled status.
 * 
 * @returns {Object} 
 *  - isTicketBaiEligible: Indicates if the company is eligible for TicketBAI.
 *  - isTicketBaiEnabled: Indicates if TicketBAI is enabled.
 */
const useTicketBaiInformation = () => {

  const country = useSelector(countrySelector);
  const address = useSelector(addressSelector);
  const isElectronic = useSelector(electronicInvoicing);
  const name = useSelector(nameSelector);
  const identification = useSelector(identificationSelector);

  /**
   * Determines eligibility and enabled status for TicketBAI, also return if all
   * req.
   * 
   * - Eligibility Criteria:
   *   - Country must be Spain.
   *   - Province must be Bizkaia.
   *   - Electronic invoicing must be enabled.
   */
  const { isTicketBaiEligible, isTicketBaiEnabled, isAllRequiredDataFilled } = useMemo(() => {
    const eligible =
      country === COUNTRIES.SPAIN &&
      address?.province === 'Bizkaia';

    const isAllRequiredDataFilled = !!name && !!identification && !!address?.address && !!address?.province && !!address?.zipCode;

    return {
      isTicketBaiEligible: eligible,
      isTicketBaiEnabled: eligible && isElectronic,
      isAllRequiredDataFilled
    };
  }, [country, address, isElectronic, name, identification]);

  return { isTicketBaiEligible, isTicketBaiEnabled, isAllRequiredDataFilled };
};

export default useTicketBaiInformation;

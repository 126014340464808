import { I18n } from '@aws-amplify/core';
import { get, has } from 'lodash';
import dayjs from 'dayjs';

export const validate = values => {
  let errors = {};

  if (!get(values, 'date'))
    errors.date = I18n.get('requiredField', 'este campo es obligatorio')

  return errors;
}

export const transform = values => {
  let data = {
    date: dayjs(get(values, 'date', null)).format('YYYY-MM-DD'),
    type: 'report-check-daily'
  }

  if (has(values, 'numeration'))
    data.resolution_ids = get(values, 'numeration', []).map(n => n.id).join(',');

  return data;
}
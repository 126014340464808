import { useQuery } from '@tanstack/react-query';
import { I18n } from 'aws-amplify';

import { fetchContact, fetchContacts } from './queries';
import { contactsKeys, DEFAULT_QUERY_PARAMS } from './utils';

export const useContactsQuery = (metadata) => {
  return useQuery({
    queryKey: [contactsKeys.all, metadata],
    queryFn: () => fetchContacts(metadata),
    enabled: !!metadata,
    ...DEFAULT_QUERY_PARAMS({
      errorTitle: I18n.get(
        'contactsLoadError',
        'Sucedió un error cargando los contactos'
      ),
    }),
  });
};

export const useContactQuery = (id, fields = []) => {
  return useQuery({
    queryKey: [contactsKeys.detail, id],
    queryFn: () => fetchContact(id, fields),
    enabled: !!id,
    ...DEFAULT_QUERY_PARAMS({
      errorTitle: I18n.get(
        'contactLoadError',
        'Sucedió un error cargando el contacto'
      ),
    }),
  });
};
import { Grid } from '@alegradev/smile-ui-react';
import ContactDetailBodyHeader from './BodyHeader';
import ContactDetailBodyInformation from './BodyInformation';

function ContactDetailBody({ contact, partible = false }) {
  const { Col, Row } = Grid;

  return (
    <Row spacing='lg'>
      <Col xs={8} md={12}>
        <ContactDetailBodyInformation contact={contact} partible={partible} />
      </Col>
      <Col xs={4} md={'hidden'}>
        <ContactDetailBodyHeader contact={contact} />
      </Col>
    </Row>
  );
}

export default ContactDetailBody;

export const COUNTRIES = {
  COLOMBIA: 'colombia',
  PERU: 'peru',
  CHILE: 'chile', // DEPRECATED
  SPAIN: 'spain',
  MEXICO: 'mexico',
  COSTA_RICA: 'costaRica',
  REPUBLICA_DOMINICANA: 'republicaDominicana',
  PANAMA: 'panama',
  ARGENTINA: 'argentina',
  USA: 'usa',
  INTERNATIONAL: 'other',
};

export const COUNTRIES_CODES = {
  COLOMBIA: '22',
  PERU: '27',
  CHILE: '25',
  SPAIN: '30',
  MEXICO: '24',
  COSTARICA: '23',
  REPUBLICADOMINICANA: '28',
  PANAMA: '26',
  ARGENTINA: '21',
  USA: '29',
  OTHER: '29',
};

export const COUNTRIES_PHONE_PREFIXES = {
  COLOMBIA: '+57',
  PERU: '+51',
  CHILE: '+56',
  SPAIN: '+34',
  MEXICO: '+52',
  COSTARICA: '+506',
  REPUBLICADOMINICANA: '+1',
  PANAMA: '+507',
  ARGENTINA: '+54',
  USA: '+1',
  OTHER: '+1',
};

export const COUNTRIES_NAMES = { 
  COLOMBIA: 'Colombia',
  PERU: 'Perú',
  SPAIN: 'España',
  MEXICO: 'México',
  COSTA_RICA: 'Costa Rica',
  REPUBLICA_DOMINICANA: 'República Dominicana',
  PANAMA: 'Panamá',
  ARGENTINA: 'Argentina',
  USA: 'Estados Unidos',
  INTERNATIONAL: 'Internacional',
}

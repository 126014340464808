import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { getRefundNumerationsElectronic, getRefundNumerationsSaleTicket } from '../../../../selectors/numerations';
import { DOCUMENT_TYPES } from '../../../../utils/enums/documentTypes';
import { COUNTRIES } from '../../../../utils/enums/countries';

/**
 * Custom hook to retrieve refund numerations based on invoice and country.
 *
 * @param {Object|null} invoiceSelected - The selected invoice object.
 * @param {string} country - The country code.
 * @returns {Object} - Contains methods to get numerations and invoice refund numeration.
 */
const useRefundNumerations = (invoiceSelected, country) => {
  // Retrieve numerations from Redux store
  const numerationsElectronic = useSelector(getRefundNumerationsElectronic);
  const numerationsSaleTicket = useSelector(getRefundNumerationsSaleTicket);

  /**
   * Retrieves the appropriate numerations based on the invoice's document type and country.
   *
   * @returns {Array} - List of relevant numerations.
   */
  const getNumerations = useCallback(() => {
    const documentType = invoiceSelected?.numberTemplate?.documentType ?? null;

    if (country === COUNTRIES.SPAIN) {
      return numerationsElectronic;
    }

    switch (documentType) {
      case DOCUMENT_TYPES.SALE_TICKET:
        return numerationsSaleTicket;
      case DOCUMENT_TYPES.INVOICE:
        return numerationsElectronic;
      default:
        return [];
    }
  }, [numerationsElectronic, numerationsSaleTicket, invoiceSelected, country]);

  /**
   * Retrieves the default numeration for the invoice.
   *
   * @returns {Object} - Contains the name and id of the default numeration.
   */
  const getInvoiceRefundNumeration = useCallback(() => {
    const numerations = getNumerations();
    let numeration = null;

    if (numerations.length === 1) {
      numeration = numerations[0];
    } else {
      numeration = numerations.find(n => n.isDefault) || null;
    }

    if (!numeration) {
      console.warn('No default numeration found. Returning empty strings.');
    }

    return {
      name: numeration?.name ?? '',
      id: numeration?.id ?? '',
    };
  }, [getNumerations]);

  return { getNumerations, getInvoiceRefundNumeration };
};

export default useRefundNumerations;

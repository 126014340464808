import React from 'react';
import { I18n } from '@aws-amplify/core';
import { Field } from 'react-final-form';

import RadioButton from '../../svg/RadioButton'

const helpText = type => {
  switch (type) {
    case 'product':
      return I18n.get('createProductHelp', 'Crea los bienes y mercancías que vendes e incluye todos sus detalles.')
    case 'service':
      return I18n.get('createServiceHelp', 'Crea las actividades comerciales o de consultoría que ofreces a tus clientes.')
    case 'kit':
      return (
        <p>
          {I18n.get('createKitHelp', 'Agrupa en un solo ítem un conjunto de productos, servicios o una combinación de ambos.')}
          <a href="https://ayuda.alegra.com/es/crea-combos-y-kits-de-productos-para-agilizar-tus-ventas-con-el-sistema-punto-de-venta-pos" target="_blank" rel="noreferrer">
            {I18n.get('knowMore', 'Saber más')}
          </a>
        </p>
      )
    default:
      return null
  }
}

const SelectType = ({ values: { type } }) => (
  <Field
    name="type"
  >
    {(fieldProps) => (
      <div className="m-2">
        <div className="d-flex justify-content-center">
          <button
            type="button"
            className={`btn btn-select-item-type text-capitalize-first w-100 mr-3
          ${type === 'product' ? 'active' : ''}`}
            onClick={() => fieldProps.input.onChange('product')}
          >
            {I18n.get('item', 'producto')}
            <RadioButton width="12" height="12" checked={type === 'product'} unfilled />
          </button>

          <button
            type="button"
            className={`btn btn-select-item-type text-capitalize-first w-100 mr-3
          ${type === 'service' ? 'active' : ''}`}
            onClick={() => fieldProps.input.onChange('service')}
          >
            {I18n.get('service', 'servicio')}
            <RadioButton width="12" height="12" checked={type === 'service'} unfilled />
          </button>

          <button
            type="button"
            className={`btn btn-select-item-type text-capitalize-first w-100
          ${type === 'kit' ? 'active' : ''}`}
            onClick={() => fieldProps.input.onChange('kit')}
          >
            {I18n.get('kit', 'combo')}
            <RadioButton width="12" height="12" checked={type === 'kit'} unfilled />
          </button>

        </div>

        <p className="py-2 h5 text-subtitle border-bottom my-2">
          {helpText(type)}
        </p>
      </div>
    )}
  </Field>
);

export default SelectType;

const getNoTax = (languagePrefix) => {
    switch (languagePrefix) {
        case 'es':
            return {
                id: -1,
                name: 'Ninguno',
                percentage: 0,
            }
        case 'en':
            return {
                id: -1,
                name: 'None',
                percentage: 0,
            }
        default:
            return {
                id: -1,
                name: 'Ninguno',
                percentage: 0,
            }
    }
}

export default getNoTax;
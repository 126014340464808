import { Typography, useDeviceType, useModal } from '@alegradev/smile-ui-react';
import { capitalize } from 'lodash';
import { Field, useForm } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';
import { renderSelect } from '../../fields/V0/Fields';
import { openModal } from '../../../../reducers/modals';
import { I18n } from 'aws-amplify';
import { hasPermissionTo } from '../../../../selectors/auth';
import { activeSellers } from '../../../../selectors/sellers';
import { checkFeatureLimit } from '../../../../reducers/membership';
import Seller from '../../../../pages/contacts/contact/form/modals/Seller';

export const SellerField = () => {
  const sellers = useSelector(activeSellers);
  const can = useSelector(hasPermissionTo);
  const { openModal: openNewModal, closeModal: closeNewModal } = useModal();
  const form = useForm();

  const { deviceType } = useDeviceType();
  const dispatch = useDispatch();

  const openNewSellerModal = () => {
    openNewModal({
      name: 'seller',
      title: I18n.get('newSeller', 'Nuevo vendedor'),
      component: Seller,
      props: {
        modalName: 'seller',

        onCreated: (seller) => {
          form.change('seller', seller);
        },
      },
    });
  };

  return (
    <Field
      name='seller'
      className={`col-sm-12 ${deviceType === 'mobile' ? 'p-0' : 'pl-2 pr-0'}`}
      options={sellers}
      label={
        <Typography
          text={capitalize(I18n.get('seller', 'vendedor'))}
          type='label-2'
          variant='secondary'
        />
      }
      getOptionLabel={(option) => option.name}
      getOptionValue={(option) => option.id}
      addOptionText={
        can('add', 'sellers')
          ? capitalize(I18n.get('newSeller', 'Nuevo vendedor'))
          : null
      }
      addOptionAction={() =>
        dispatch(
          checkFeatureLimit(
            'sellers',
            can('add', 'sellers') ? () => openNewSellerModal() : null
          )
        )
      }
      menuPosition='absolute'
    >
      {(props) => (
        <>
          {renderSelect({
            ...props,
            fontSize: '16px',
            borderRadius: '12px',
            height: '48px',
            fontColor: '#334155',
          })}
        </>
      )}
    </Field>
  );
};

export default SellerField;

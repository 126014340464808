import { useDispatch, useSelector } from 'react-redux';
import parse from 'html-react-parser';

import { get } from 'lodash';
import { closeModal } from '../../../reducers/modals';

import Modal from '../../common/Modal';
import Typography from '../../common/Typography';
import Button from '../../common/Button';

function ConfirmActionModal() {
  const dispatch = useDispatch();
  const isOpen = useSelector((state) =>
    get(state, 'modals.confirmAction.isOpen', false)
  );
  const params = useSelector((state) =>
    get(state, 'modals.confirmAction.params', false)
  );

  const toggleCloseModal = () => {
    if (params?.loading) return;

    dispatch(closeModal({ modal: 'confirmAction' }));
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={() => toggleCloseModal()}
      includeHeader={false}
      className='modal__mobile-bottom modal__rounded'
    >
      <div className='confirm-action-modal'>
        <div className='confirm-action-modal-body'>
          <Typography
            type='body-1-bold'
            variant='primary'
            text={parse(get(params, 'title', ''))}
          />
          <Typography
            type='body-3-regular'
            variant='secondary'
            text={parse(get(params, 'description', ''))}
          />
        </div>
        <div className='confirm-action-modal-actions'>
          <Button
            size='md'
            variant='outline'
            onClick={toggleCloseModal}
            disabled={params?.loading}
          >
            <Typography
              type='label-1'
              variant='primary'
              text={get(params, 'cancelButton', '')}
            />
          </Button>

          <Button
            size='md'
            buttonType='destructive'
            onClick={() => {
              if (params?.onSuccess) {
                params.onSuccess();
              }
            }}
            disabled={params?.loading}
            loading={params?.loading}
          >
            <Typography
              type='label-1'
              variant='inverse'
              text={get(params, 'successButton', '')}
            />
          </Button>
        </div>
      </div>
    </Modal>
  );
}

export default ConfirmActionModal;

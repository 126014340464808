import { useEffect, useState, useRef, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { API, graphqlOperation } from '@aws-amplify/api';
import { get } from 'lodash';

import * as subscriptions from '../graphql/subscriptions';
import { idCompanySelector } from '../selectors/company';
import { refresh } from '../reducers/clients'
import alegraAPI from '../reducers/alegraAPI'
import {
  updateClientByEvent as updateActiveInvoice,
  removeClientByEvent as removeActiveInvoice,
} from '../reducers/activeInvoice'
import {
  updateClientByEvent as updateEditInvoice,
  removeClientByEvent as removeEditInvoice,
} from '../reducers/editInvoice'
import {
  updateClientByEvent as updatePendingInvoices,
  removeClientByEvent as removePendingInvoices,
} from '../reducers/pendingInvoices'
import {
  updateClientByEvent as updateActiveRefund,
  removeClientByEvent as removeActiveRefund,
} from '../reducers/activeRefund'
import { put, remove, update } from '../database/contactsDB';
import { handleClientSubscription } from '../pages/contacts/services/subscription';
import { useQueryClient } from '@tanstack/react-query';

const updateClientGlobal = (client) => {
  return (dispatch) => {
    if (!client) return

    dispatch(updateActiveInvoice(client))
    dispatch(updateEditInvoice(client))
    dispatch(updateActiveRefund(client))
    dispatch(updatePendingInvoices(client))
  }
}

const removeClientGlobal = (id) => {
  return (dispatch) => {
    dispatch(removeActiveInvoice(id))
    dispatch(removeEditInvoice(id))
    dispatch(removeActiveRefund(id))
    dispatch(removePendingInvoices(id))
  }
}

const ClientsSns = () => {
  const ref = useRef()
  const dispatch = useDispatch()
  const idCompany = useSelector(idCompanySelector)
  const networkStatus = useSelector(state => get(state, 'app.networkStatus', 'online'))
  const queryClient = useQueryClient();
  const [onCreateClientError, setOnCreateClientError] = useState(false);
  const [onUpdateClientError, setOnUpdateClientError] = useState(false);
  const [onDeleteClientError, setOnDeleteClientError] = useState(false);

  const getClient = useCallback(
    async (id) => {
      try {
        const response = await alegraAPI.get(`/contacts/${id}`)

        return get(response, 'data', null)
      } catch {
        return null
      }
    },
    [],
  )

  useEffect(() => {
    let subsciption = null
    if (networkStatus === 'online') {
      subsciption = API.graphql(graphqlOperation(subscriptions.onCreateClientNotify, {
        channel: `pos_${idCompany}`
      }))
        .subscribe({
          next: (data) => {
            const putClient = async data => {
              try {
                const clientId = get(data, 'value.data.onCreateClientNotify.id', null);
                const client = await getClient(clientId)
                
                handleClientSubscription(client, 'create', queryClient);

                if (!!client && get(client, 'status') === 'active') {
                  await put(client)
                  dispatch(updateClientGlobal(client));
                  dispatch(refresh())
                }
              } catch {
              }
            }

            putClient(data);
          },
          error: () => setTimeout(() => {
            if (!!ref.current)
              setOnCreateClientError(!onCreateClientError)
          }, 60000)
        })
    }
    return () => {
      if (subsciption)
        subsciption.unsubscribe();
    }
  }, [networkStatus, onCreateClientError, dispatch, idCompany, getClient])

  useEffect(() => {
    let subsciption = null
    if (networkStatus === 'online') {
      subsciption = API.graphql(graphqlOperation(subscriptions.onUpdateClientNotify, {
        channel: `pos_${idCompany}`
      }))
        .subscribe({
          next: (data) => {
            const updateClient = async data => {
              try {
                const clientId = get(data, 'value.data.onUpdateClientNotify.id', null);
                const client = await getClient(clientId)

                handleClientSubscription(client, 'update', queryClient);

                if (!!client) {
                  if (get(client, 'status') === 'active') {
                    await remove(clientId)
                    await put(client)
                    dispatch(updateClientGlobal(client))
                  } else {
                    await remove(clientId)
                    dispatch(removeClientGlobal(clientId))
                  }
                  dispatch(refresh())
                }
              } catch {
              }
            }

            updateClient(data);
          },
          error: () => setTimeout(() => {
            if (!!ref.current)
              setOnUpdateClientError(!onUpdateClientError)
          }, 60000)
        })
    }
    return () => {
      if (subsciption)
        subsciption.unsubscribe();
    }
  }, [networkStatus, onUpdateClientError, dispatch, idCompany, getClient])

  useEffect(() => {
    let subsciption = null
    if (networkStatus === 'online') {
      subsciption = API.graphql(graphqlOperation(subscriptions.onDeleteClientNotify, {
        channel: `pos_${idCompany}`
      }))
        .subscribe({
          next: (data) => {
            const removeClient = async data => {
              try {
                const clientId = get(data, 'value.data.onDeleteClientNotify.id', null);

                handleClientSubscription({ id: clientId }, 'delete', queryClient);

                await remove(clientId)

                dispatch(removeClientGlobal(clientId))
                dispatch(refresh())
              } catch {
              }
            }

            removeClient(data);
          },
          error: () => setTimeout(() => {
            if (!!ref.current)
              setOnDeleteClientError(!onDeleteClientError)
          }, 60000)
        })
    }
    return () => {
      if (subsciption)
        subsciption.unsubscribe();
    }
  }, [networkStatus, onDeleteClientError, dispatch, idCompany])

  useEffect(() => {
    ref.current = true
    return () => ref.current = false
  }, [])

  return null;
}

export default ClientsSns;
import { createDraftSafeSelector } from '@reduxjs/toolkit';
import { get } from 'lodash';

const preInvoiceSelector = state => get(state, 'activeInvoice', null);
const printSelector = state => get(state, 'print', null);

export const printType = createDraftSafeSelector(
  printSelector,
  print => get(print, 'type', null)
)

export const preInvoiceToPrint = createDraftSafeSelector(
  preInvoiceSelector,
  preInvoice => preInvoice
)

export const invoiceToPrint = createDraftSafeSelector(
  printSelector,
  print => get(print, 'invoice', null)
)

export const shiftToPrint = createDraftSafeSelector(
  printSelector,
  print => get(print, 'shift', null)
)

export const refundToPrint = createDraftSafeSelector(
  printSelector,
  print => get(print, 'refund', null)
)

export const paymentToPrint = createDraftSafeSelector(
  printSelector,
  print => get(print, 'payment', null)
)
const provinces = [
  {
    "id": "1",
    "code": "0",
    "value": "Ciudad Autónoma de Buenos Aires"
  },
  {
    "id": "2",
    "code": "1",
    "value": "Buenos Aires"
  },
  {
    "id": "3",
    "code": "2",
    "value": "Catamarca"
  },
  {
    "id": "4",
    "code": "3",
    "value": "Córdoba"
  },
  {
    "id": "5",
    "code": "4",
    "value": "Corrientes"
  },
  {
    "id": "6",
    "code": "5",
    "value": "Entre Ríos"
  },
  {
    "id": "7",
    "code": "6",
    "value": "Jujuy"
  },
  {
    "id": "8",
    "code": "7",
    "value": "Mendoza"
  },
  {
    "id": "9",
    "code": "8",
    "value": "La Rioja"
  },
  {
    "id": "10",
    "code": "9",
    "value": "Salta"
  },
  {
    "id": "11",
    "code": "10",
    "value": "San Juan"
  },
  {
    "id": "12",
    "code": "11",
    "value": "San Luis"
  },
  {
    "id": "13",
    "code": "12",
    "value": "Santa Fe"
  },
  {
    "id": "14",
    "code": "13",
    "value": "Santiago del Estero"
  },
  {
    "id": "15",
    "code": "14",
    "value": "Tucumán"
  },
  {
    "id": "16",
    "code": "16",
    "value": "Chaco"
  },
  {
    "id": "17",
    "code": "17",
    "value": "Chubut"
  },
  {
    "id": "18",
    "code": "18",
    "value": "Formosa"
  },
  {
    "id": "19",
    "code": "19",
    "value": "Misiones"
  },
  {
    "id": "20",
    "code": "20",
    "value": "Neuquén"
  },
  {
    "id": "21",
    "code": "21",
    "value": "La Pampa"
  },
  {
    "id": "22",
    "code": "22",
    "value": "Río Negro"
  },
  {
    "id": "23",
    "code": "23",
    "value": "Santa Cruz"
  },
  {
    "id": "24",
    "code": "24",
    "value": "Tierra del Fuego"
  }
]

export default provinces;
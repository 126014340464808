import {
  Grid,
  Space,
  Typography,
  TextEditor,
  Loader,
  InputChip,
  Chip,
} from '@alegradev/smile-ui-react';
import { I18n } from 'aws-amplify';
import { useCallback, useEffect, useState } from 'react';
import { Field, useForm } from 'react-final-form';
import alegraAPI from '../../../../reducers/alegraAPI';
import { useSelector } from 'react-redux';
import { companySelector } from '../../../../selectors/company';
import { renderField } from '../../../../components/forms/fields/V2';
import ContactPortalFooter from './Footer';

function ContactPortalEmailMethod({
  contact,
  modalName,
  errors,
  loading: { isLoading },
}) {
  const [loading, setLoading] = useState(false);
  const [editorDefaultValue, setEditorDefaultValue] = useState('');
  const company = useSelector(companySelector);
  const { Col, Row, Wrapper } = Grid;
  const form = useForm();

  const fetchEmailTemplate = useCallback(async () => {
    try {
      setLoading(true);
      const template = await alegraAPI.get('/email-templates/statement');
      const ALEGRA_PORTAL_LINK =
        process.env.REACT_APP_ALEGRA_PORTAL || 'https://portal.alegra.com/';
      form.change(
        'subject',
        template?.data?.subject?.replace('{{NombreEmpresa}}', company?.name)
      );
      const data = {
        NombreEmpresa: company?.name || 'Default',
        TeléfonoEmpresa: company?.phone || '0000',
        NombreCliente: contact?.name || 'Default',
        TextoBotonPortal: I18n.get('seeAccountStatus', 'Ver estado de cuenta'),
        CorreoEmpresa: company?.email || 'default@default.com',
        Link: ALEGRA_PORTAL_LINK,
      };
      const templateContent = template?.data?.message
        ?.replace(
          /\{\{([\wéíóúÁÉÍÓÚñÑ\s]+)\}\}/g,
          (match, key) => data[key] || match
        )
        .replace(/<a/g, '<a target="_blank"');
      form.change('content', templateContent);
      setLoading(false);
      setEditorDefaultValue(templateContent);
    } catch (error) {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchEmailTemplate();
  }, []);

  useEffect(() => {
    if (contact?.email) {
      form.change('to', [{ label: contact?.email, value: contact?.email }]);
    }
    form.change('contact', contact);
  }, []);

  if (loading) {
    return (
      <Loader
        size='large'
        orientation='vertical'
        title={I18n.get('loadingTemplate', 'Cargando plantilla de email')}
      />
    );
  }

  return (
    <>
      <Row spacing='lg'>
        <Col xs={12}>
          <Wrapper align={{ lg: 'center' }} gap={10}>
            <Typography type='label-2' text={I18n.get('for', 'Para:')} />
            <Space width={8} />
            <Wrapper direction='column' gap={4}>
              {contact?.email && <Chip label={contact?.email} disabled />}
              {!contact?.email && (
                <InputChip
                  inputPlaceholder={I18n.get('email', 'Correo electrónico')}
                  inputValidation={(value) =>
                    value.includes('@') && value.includes('.')
                      ? undefined
                      : I18n.get('invalidEmail', 'Correo inválido')
                  }
                  onChange={(options) => form.change('to', options)}
                />
              )}
            </Wrapper>
          </Wrapper>
        </Col>
        <Col xs={12}>
          <Wrapper align={{ lg: 'center' }} gap={10}>
            <Typography type='label-2' text={I18n.get('subject', 'Asunto:')} />
            <Field
              name='subject'
              render={renderField}
              fullWidth
              placeholder={I18n.get('subject', 'Asunto:')}
            />
          </Wrapper>
        </Col>
        <Col xs={12}>
          <TextEditor
            defaultValue={editorDefaultValue}
            onChange={(html) => form.change('content', html)}
          />

          <Space height={10} />

          <Field
            name='sendCopy'
            type='checkbox'
            fieldType='checkbox'
            render={renderField}
            label={I18n.get('sendCopy', 'Enviar copia a mi correo')}
          />
        </Col>
      </Row>
      <ContactPortalFooter
        modalName={modalName}
        errors={errors}
        method='email'
        contact={contact}
        loading={isLoading}
      />
    </>
  );
}

export default ContactPortalEmailMethod;

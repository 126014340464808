import React from 'react'
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux'
import { get } from 'lodash'

import { getMainCurrency } from '../../../../selectors/currencies'
import { currency } from '../../../../selectors/editInvoice';
import { BasicData, AdvancedData } from '../../../forms/invoice/Invoice'
import Total from './Total'
import Change from './Change'
import Methods from './Methods'

const Payments = ({ values, form, totalPrice }) => {
  const mainCurrency = useSelector(getMainCurrency)
  const selectedCurrency = useSelector(currency)

  const currencyCode = !!get(selectedCurrency, 'code') ? get(selectedCurrency, 'code')
    : !!get(mainCurrency, 'code') ? get(mainCurrency, 'code') : ''
  const prefix = !!get(selectedCurrency, 'symbol') ? get(selectedCurrency, 'symbol')
    : !!get(mainCurrency, 'symbol') ? get(mainCurrency, 'symbol') : ''

  const fmt = {
    prefix,
    decimalSeparator: '.',
    groupSeparator: ',',
    groupSize: 3,
  }

  return (
    <div className="d-flex flex-column p-3 modal__invoice-body">
      <Total values={values} form={form} total={totalPrice} />

      {!!values.method && <Change values={values} total={totalPrice} />}

      <div className={`flex-column flex-sm-row ${!values.method ? 'd-none': 'd-flex'}`}>
        <div className="modal__invoice-basic-data">
          <BasicData
            values={values}
            form={form}
            total={totalPrice}
            fmt={fmt}
            currencyCode={currencyCode}
          />
        </div>

        <div className="modal__invoice-advanced-data">
          <AdvancedData />
        </div>
      </div>

      <div className={!!values.method ? 'd-none': ''}>
        <Methods form={form} total={totalPrice} />
      </div>
    </div>
  )
}

Payments.propTypes = {
  values: PropTypes.object,
  form: PropTypes.any,
  totalPrice: PropTypes.any,
  country: PropTypes.string,
}

export default Payments;
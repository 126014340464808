import { get, set, del } from 'idb-keyval';

//key-val IDB no requiere inicio manual

export function createIDBPersister(idbValidKey = "reactQuery") {
  return {
    persistClient: async (client) => {
      set(idbValidKey, client);
    },
    restoreClient: async () => {
      return await get(idbValidKey);
    },
    removeClient: async () => {
      await del(idbValidKey);
    },
  }
}
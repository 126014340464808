import React from 'react';
import { I18n } from '@aws-amplify/core';
import { Field } from 'react-final-form';
import { capitalize } from 'lodash';
import { useSelector } from 'react-redux'

import { isOnlyInvoicingPlan } from '../../../selectors/company'
import { renderField, renderCheckbox } from '../fields/V0/Fields';

const PendingInvoices = ({ values }) => {
  const onlyInvoicing = useSelector(isOnlyInvoicingPlan)

  return (
    <div className="form-body__fields form-row justify-content-start">
      <Field
        name="pendingInvoicesEnabled"
        className="col-12"
        component={renderCheckbox}
        disabled={onlyInvoicing}
        onlyInvoicingTooltip={onlyInvoicing}
        switchLabel={!!values.pendingInvoicesEnabled
          ? capitalize(I18n.get('disablePendingInvoices', 'deshabilitar ventas pendientes'))
          : capitalize(I18n.get('enablePendingInvoices', 'habilitar ventas pendientes'))
        }
      />

      {!!values.pendingInvoicesEnabled && (
        <Field
          name="pendingInvoicesPrefix"
          className="col-md-6"
          type="text"
          component={renderField}
          label={capitalize(I18n.get('pendingInvoicesPrefix', 'prefijo de ventas pendientes'))}
        />
      )}
    </div>
  )
}

export default PendingInvoices;

import React from 'react';

import renderLogo from './Logo';
import renderIdentification from './Identification';
import renderName from './Name';
import renderAddress from './Address';
import renderInfo from './Info';
import renderPriceInfo from './PriceInfo';
import renderKindOfPerson from './KindOfPerson';
import renderIVACondition from './IVACondition';
import renderIibb from './Iibb';
import renderEconomicActivity from './EconomicActivity';
import renderRegime from './Regime';
import renderSaleTickets from './SaleTickets';
import renderMulticurrency from './Multicurrency';

const BasicData = props => {
  
  return (
    <div className="form-body__fields form-row justify-content-start">
      {renderLogo(props)}

      {renderIdentification(props)}

      {renderKindOfPerson(props)}

      {renderName(props)}

      {renderIVACondition(props)}

      {renderEconomicActivity(props)}

      {renderRegime(props)}

      {renderAddress(props)}

      {renderIibb(props)}

      {renderPriceInfo(props)}

      {renderInfo(props)}

      {renderSaleTickets(props)}
      
      {renderMulticurrency(props)}
    </div>
  )
}

export default BasicData;

import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';
import { graphqlOperation } from '@aws-amplify/api';
import { get } from 'lodash'
import dayjs from 'dayjs'


import * as queries from '../graphql/queries'
import * as mutations from '../graphql/mutations'
import { paymentTransformer } from '../utils/transformers'
import { handleError } from '../utils/errors'
import { country as countrySelector, timezone as tzSelector } from '../selectors/company'
import { APIGraphqlSelector, station as stationSelector } from '../selectors/app'
import { isCashReceiptNumerationActive } from '../selectors/numerations'
import { sendGTMEvent } from './company';
import { getInitialState } from '../utils';

const initialState = {
  entities: {},
  ids: [],
  loading: 'idle',
  error: null,
  total: 0,
  version: 1
}

export const fetchPayments = createAsyncThunk(
  'payments/fetch',
  async (batch = null, { rejectWithValue, dispatch, getState }) => {
    const station = stationSelector(getState())
    const APIGraphql = APIGraphqlSelector(getState());

    try {
      const response = await APIGraphql(graphqlOperation(queries.allPayments, {
        idStation: station.id,
        includeMetadata: true,
        batch,
      }))

      const payments = get(response, 'data.allPayments.data', [])
      const paymentsTotal = !!get(response, 'data.allPayments.metadata.total')
        ? get(response, 'data.allPayments.metadata.total') : 0

      dispatch(receivePayments(payments))
      dispatch(setTotal(paymentsTotal))

    } catch (error) {
      return rejectWithValue(handleError(error));
    }
  }
)

export const adapter = createEntityAdapter({
  sortComparer: (a, b) => +b.id - +a.id
});

const appSlice = createSlice({
  name: 'payments',
  initialState: getInitialState('payments', initialState),
  reducers: {
    receivePayments: (state, action) => {
      adapter.setAll(state, action.payload)
    },
    updatePayment: adapter.updateOne,
    replacePayment: (state, action) => {
      const index = state.ids.indexOf(action.payload.id)

      if (index >= 0 && !!get(action, 'payload.new.id')) {
        state.ids[index] = action.payload.new.id;
        state.entities[action.payload.new.id] = action.payload.new;
        delete state.entities[action.payload.id]
      }
    },
    setTotal: (state, action) => {
      state.total = action.payload
    },
  },
  extraReducers: builder => {
    builder.addCase(fetchPayments.pending, state => {
      state.error = null;
      state.loading = 'loading'
    })
    builder.addCase(fetchPayments.rejected, (state, action) => {
      state.error = action.payload;
      state.loading = 'idle'
    })
    builder.addCase(fetchPayments.fulfilled, state => {
      state.error = null;
      state.loading = 'idle'
    })
  }
});

const { reducer, actions } = appSlice;

export const paymentsSelector = adapter.getSelectors(state => state.payments);

export const { receivePayments, updatePayment, replacePayment, setTotal } = actions;

export default reducer;

const preparePayment = values => {
  return (_dispatch, getState) => {
    const state = getState()
    const station = stationSelector(state)
    const country = countrySelector(state)
    const today = dayjs(new Date(), tzSelector(state))
    const includeNumeration = isCashReceiptNumerationActive(state)

    return paymentTransformer({
      ...values,
      idStation: station.id,
      date: today.format('YYYY-MM-DD'),
      control: today.format('YYYY-MM-DD hh:mm:ss'),
      numberTemplate: get(values, 'type') === 'in' && includeNumeration
        ? { id: get(station, 'idCashReceiptNumberTemplate', null) } : null,
      paymentMethod: country === 'mexico' ? 'cash' : null,
      costCenter: get(station, 'idCostCenter', null)
    })
  }
}

// const trackCreatedPayment = companyId => {
//   window.dataLayer.push({
//     event: 'eventActivity',
//     eventActivityAction: 'payment / add / *',
//     eventActivityLabel: companyId,
//   });
// }

export const createPayment = props => {
  return async (dispatch, getState) => {

    const payment = dispatch(preparePayment(props))
    const APIGraphql = APIGraphqlSelector(getState());
    
    const response = await APIGraphql(graphqlOperation(mutations.createPayment, {
      payment
    }))

    dispatch(sendGTMEvent("new-cash-movement-created", {
      cashMovementType: get(payment, "type"),
      amountOfCash: parseInt(get(payment, "categories.0.price")),
      clientIsConfigured: !!get(payment, "client"),
      hasObservations: !!get(payment, "observations"),
      creationOrigin: get(props, "creationOrigin") || "from cash management",
    }))

    return get(response, 'data.createPayment')
  }
}
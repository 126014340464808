import { createContext, useContext, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { I18n } from '@aws-amplify/core';
import { stationInvoiceNumeration } from '../../../../selectors/app';
import { useSelector } from 'react-redux';
import { get, isNull } from 'lodash';
import { GLOBAL_INVOICE_INITIAL_VALUES } from './utils';
import { useGlobalInvoiceTicketDetailQuery } from '../services/queries';
import Loader from '../../../../components/common/Loader';
import { getAllInvoiceNumerations } from '../../../../selectors/numerations';
import { globalInvoiceConverterToData } from '../converters/globalInvoiceToApi';

const GlobalInvoiceContext = createContext({
  selectedRows: [],
  initialRows: [],
  isEditable: false,
  initialValues: {},
  toggleSelectedRows: () => null,
  toggleNumeration: () => null,
  refetch: () => null,
});

export function useGlobalInvoice() {
  return useContext(GlobalInvoiceContext);
}

function GlobalInvoiceProvider({ children }) {
  const params = useParams();
  const numerations = useSelector(getAllInvoiceNumerations);
  const { data, isLoading, refetch } = useGlobalInvoiceTicketDetailQuery(
    params.id
  );
  const currentNumeration = useSelector(stationInvoiceNumeration);
  const [selectedRows, setSelectedRows] = useState([]);
  const [initialRows, setInitialRows] = useState([]);
  const [initialValues, setInitialValues] = useState(
    GLOBAL_INVOICE_INITIAL_VALUES
  );
  const [isEditable, setIsEditable] = useState(false);

  useEffect(() => {
    if (!params.id) {
      const findCurrentNumeration = numerations.find(
        (numeration) => numeration.id === currentNumeration.id
      );
      if (findCurrentNumeration) {
        const currentNumerationPrefix = get(currentNumeration, 'prefix', '');
        setInitialValues({
          ...initialValues,
          idNumberTemplate: `${ !isNull(currentNumerationPrefix) ? currentNumerationPrefix : ''}${get(
            currentNumeration,
            'nextInvoiceNumber'
          )}`,
          numeration: currentNumeration,
        });
      } else {
        const numerationPrefix = get(numerations[0], 'prefix', '');
        setInitialValues({
          ...initialValues,
          idNumberTemplate: `${ !isNull(numerationPrefix) ? numerationPrefix : ''}${get(
            numerations[0],
            'nextInvoiceNumber'
          )}`,
          numeration: numerations[0],
        });
      }
    }
  }, [currentNumeration]);

  useEffect(() => {
    if (params.id) {
      setIsEditable(true);
    }
  }, [params.id]);

  useEffect(() => {
    if (data) {
      const initialInvoiceValues = {
        ...initialValues,
        ...globalInvoiceConverterToData(data, numerations),
      };
      setInitialRows(get(data, 'saleTickets', []));
      setSelectedRows(get(data, 'saleTickets', []));
      setInitialValues({
        ...initialInvoiceValues,
        initialValues: {
          ...initialInvoiceValues,
          selectedRows: get(data, 'saleTickets', []),
        },
      });
    }
  }, [data]);

  const toggleSelectedRows = (rows) => {
    setSelectedRows(rows);
  };

  const toggleNumeration = (numeration) => {
    const numerationPrefix = get(numeration, 'prefix', '');
    setInitialValues({
      ...initialValues,
      idNumberTemplate: `${
        !isNull(numerationPrefix) ? numerationPrefix : ''
      }${get(numeration, 'nextInvoiceNumber', '')}`,
    });
  };

  if (isLoading && params.id) {
    return (
      <div style={{ height: 600 }}>
        <Loader title={I18n.get('', 'Cargando tu factura 🛴')} />
      </div>
    );
  }

  return (
    <GlobalInvoiceContext.Provider
      value={{
        selectedRows,
        toggleSelectedRows,
        isEditable,
        initialValues,
        toggleNumeration,
        initialRows,
        refetch,
      }}
    >
      {children}
    </GlobalInvoiceContext.Provider>
  );
}

export default GlobalInvoiceProvider;

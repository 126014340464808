import React from 'react';
import { Field } from 'react-final-form';
import { I18n } from '@aws-amplify/core';
import { get, capitalize } from 'lodash';

import kindOfPersonOptions from '../../../countriesData/colombia/kindOfPerson';
import { renderSelect } from '../../fields/V0/Fields';

const renderKindOfPerson = props => {
  const identification = get(props, 'values.identification.type.key', '');

  if (props.country === 'colombia' && identification === 'NIT') {
    return (
      <Field
        name="kindOfPerson"
        component={renderSelect}
        className="col-md-6"
        label={capitalize(I18n.get('kindOfPerson', 'tipo de persona'))}
        options={kindOfPersonOptions}
        getOptionLabel={option => option.value}
        getOptionValue={option => option.key}
      />
    )
  }

  if (props.country === 'panama') {
    return (
      <Field
        name="kindOfPerson"
        component={renderSelect}
        className="col-md-6"
        label={capitalize(I18n.get('kindOfPerson', 'tipo de persona'))}
        options={kindOfPersonOptions}
        getOptionLabel={option => option.value}
        getOptionValue={option => option.key}
      />
    )
  }

  return null;
}

export default renderKindOfPerson;

import { get } from 'lodash';
import { COUNTRIES } from '../../../../utils/enums/countries';
import { I18n } from 'aws-amplify';
import {
  BASE_INVOICE_PAYMENT_METHODS,
  INVOICE_PAYMENT_METHODS,
} from './constants';
import { FORM_ERROR } from 'final-form';
import BigNumber from 'bignumber.js';
import { InvoiceTextCountryStrategyFactory } from '../strategies/InvoiceTextCountryStrategyFactory';
import { validateNumberString } from '../../../../utils/decimals/validateNumberString';

const COUNTRIES_AVAILABLE = [
  COUNTRIES.INTERNATIONAL,
  COUNTRIES.USA,
  COUNTRIES.SPAIN,
  COUNTRIES.PERU,
  COUNTRIES.COSTA_RICA,
  COUNTRIES.PANAMA,
  COUNTRIES.REPUBLICA_DOMINICANA,
];
/**
 * Return true if the invoice refactored modal should be displayed for the given
 * country.
 *
 * @param {Object} props
 * @param {string} props.country - ISO 3166-1 alpha-2 code
 * @returns {boolean}
 */
export const displayInvoiceRefactored = ({ country }) => {
  return COUNTRIES_AVAILABLE.includes(country);
};

const ERROR_MESSAGES = {
  paymentValueRequired: I18n.get(
    'youNeedToAddThePaymentValue',
    'necesitas agregar el valor del pago'
  ),
  electronicPaymentMustBeEqualToTotal: I18n.get(
    'electronicPaymentsTotalMustBeLTETotal',
    'el total de los pagos electrónicos debe ser menor o igual al total de la factura'
  ),
};
const validateByPaymentMethodValue = (values, errors) => {
  const method = get(values, 'method');

  const basePaymentMethodKey = Object.keys(BASE_INVOICE_PAYMENT_METHODS).find(
    (key) => BASE_INVOICE_PAYMENT_METHODS[key] === method
  );

  if (basePaymentMethodKey) {
    const paymentValue = get(values, method);
    if (!validateNumberString(paymentValue)) {
      errors[method] = ERROR_MESSAGES.paymentValueRequired;
    }
  } else if (method === INVOICE_PAYMENT_METHODS.COMBINED) {
    const hasAtLeastOnePayment = Object.values(
      BASE_INVOICE_PAYMENT_METHODS
    ).some((key) => validateNumberString(get(values, key)));
    if (!hasAtLeastOnePayment) {
      errors[FORM_ERROR] = ERROR_MESSAGES.paymentValueRequired;
    }
  } else {
    errors[FORM_ERROR] = ERROR_MESSAGES.paymentValueRequired;
  }
  return errors;
};

const validateElectronicPaymentsValues = (values, errors) => {
  const { total, country, isDecimalActive } = values;
  const decimal = get(values, 'decimal');
  const debit = new BigNumber(get(values, INVOICE_PAYMENT_METHODS.DEBIT) || 0);
  const credit = new BigNumber(
    get(values, INVOICE_PAYMENT_METHODS.CREDIT) || 0
  );
  const transfer = new BigNumber(
    get(values, INVOICE_PAYMENT_METHODS.TRANSFER) || 0
  );

  let electronicPayments = debit.plus(credit).plus(transfer);

  if (country && decimal && isDecimalActive) {
    const electronicPaymentsWithDecimals =
      electronicPayments.decimalPlaces(decimal);
    const totalWithDecimals = total.decimalPlaces(decimal);

    if (electronicPaymentsWithDecimals.eq(totalWithDecimals)) {
      electronicPayments = total;
    }
  }

  if (electronicPayments.gt(total)) {
    errors[FORM_ERROR] = ERROR_MESSAGES.electronicPaymentMustBeEqualToTotal;
  }

  return errors;
};

export const validate = (values) => {
  let errors = {};

  errors = validateByPaymentMethodValue(values, errors);
  errors = validateElectronicPaymentsValues(values, errors);

  const countryStrategy = InvoiceTextCountryStrategyFactory.createStrategy(
    values?.country
  );

  if (countryStrategy?.validate) {
    errors = countryStrategy.validate(values, errors);
  }

  return errors;
};

const COUNTRIES_AVAILABLE_TO_PAY_INVOICE_REFACTORED = [
  COUNTRIES.INTERNATIONAL,
  COUNTRIES.USA,
];

export const isCountryAvailableToPayInvoiceRefactored = ({ country }) => {
  return COUNTRIES_AVAILABLE_TO_PAY_INVOICE_REFACTORED.includes(country);
};

import { useMemo } from 'react';
import dayjs from 'dayjs';
import { I18n } from '@aws-amplify/core';
import { calculateItemsValues } from '../../../utils';
import { formatDate } from '../utils';

/**
 * Custom hook to handle refund calculations.
 *
 * @param {Object} refund - The refund object.
 * @param {number} decimal - Number of decimal places.
 * @param {string} country - Country code.
 * @param {string} company - Company identifier.
 * @param {string} membership - Membership type.
 * @param {boolean} isDecimalActive - Flag indicating if decimal is active.
 * @param {string} dateFormat - Date format string.
 * @param {string} decimalSeparator - Decimal separator character.
 * @returns {Object} - Calculated values and formatted date strings.
 */
const useRefundCalculations = ({
  refund,
  decimal,
  country,
  company,
  membership,
  dateFormat,
  decimalSeparator,
}) => {
  const itemsValues = useMemo(
    () => calculateItemsValues({ items: refund.items, decimal, country, company, membership }),
    [refund.items, decimal, country, company, membership]
  );

  const fmt = useMemo(() => {
    const prefix = refund?.currency?.symbol || null;
    return prefix
      ? {
        prefix,
        decimalSeparator,
        groupSeparator: decimalSeparator === ',' ? '.' : ',',
        groupSize: 3,
      }
      : {
        decimalSeparator,
        groupSeparator: decimalSeparator === ',' ? '.' : ',',
        groupSize: 3,
      };
  }, [refund, decimalSeparator]);

  const formattedDate = useMemo(
    () => formatDate(refund.dateTime, dateFormat),
    [refund.dateTime, dateFormat]
  );

  return { itemsValues, fmt, formattedDate };
};

export default useRefundCalculations;

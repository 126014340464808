import React, { useRef, useEffect, useState } from 'react';
import { I18n } from '@aws-amplify/core';
import { useSelector, useDispatch } from 'react-redux'
import { capitalize, get } from 'lodash'

import * as db from '../../../database/pendingInvoicesDB';
import { openModal, closeModal } from '../../../reducers/modals'
import { addInvoice, changeInvoice, removeInvoice, setInvoices } from '../../../reducers/pendingInvoices'
import Confirmation from '../../modals/confirmation/Confirmation';
import Invoice from './Invoice';
import InvoiceOptions from './InvoiceOptions';
import QuickNav from './QuickNav';

import { Icon } from '@alegradev/smile-ui-react';
import { IconArrowLeft, IconArrowRight, IconList, IconPlus } from '@tabler/icons-react';

const Footer = () => {
  const dispatch = useDispatch()
  const invoices = useSelector(state => get(state, 'pendingInvoices.invoices'))
  const selected = useSelector(state => get(state, 'pendingInvoices.selected'))
  const confirmationOpen = useSelector(state => get(state, 'modals.deletePendingInvoice.isOpen', false))

  const [showNav, setShowNav] = useState(false);
  const [showOpts, setShowOpts] = useState(false);
  const [showQuickNav, setShowQuickNav] = useState(false);

  const mountedRef = useRef();
  const listRef = useRef();
  const optionsRef = useRef();
  const quickNavRef = useRef();

  const checkWidth = () => {
    setShowNav(listRef.current.scrollWidth > listRef.current.clientWidth)
  }

  const scrollStep = factor => {
    listRef.current.scrollTo({
      left: listRef.current.scrollLeft + factor * listRef.current.children[0].offsetWidth,
      behavior: 'smooth'
    })
  }

  const onAddInvoice = async () => {
    await dispatch(addInvoice());
  }

  const onChangeInvoice = async (index) => {
    await dispatch(changeInvoice({ index }))
  }

  const onRemoveInvoice = async () => {
    await dispatch(removeInvoice())
    setShowOpts(false)
    dispatch(closeModal({ modal: 'deletePendingInvoice' }))
  }

  const onShowOptions = event => {
    event.stopPropagation();
    const selectedElWidth = listRef.current.children[selected].offsetLeft;
    const listScroll = listRef.current.scrollLeft;

    optionsRef.current.style.transform = `translate(${selectedElWidth - listScroll}px, -${optionsRef.current.clientHeight + 10}px)`
    setShowQuickNav(false);
    setShowOpts(true);
  }

  const onShowQuickNav = event => {
    event.stopPropagation();
    quickNavRef.current.style.transform = `translateY(-${quickNavRef.current.clientHeight + 10}px)`
    setShowOpts(false);
    setShowQuickNav(true);
  }

  useEffect(() => {
    mountedRef.current = true;
    return () => {
      mountedRef.current = false;
    }
  }, [])

  useEffect(() => {
    window.addEventListener('resize', checkWidth);

    return () => {
      window.removeEventListener('resize', checkWidth);
    }
  }, [])

  useEffect(() => {
    const checkClick = event => {
      if (!event.target.classList.contains('footer__invoice-action')) {
        setShowOpts(false);
      }
      if (!event.target.classList.contains('footer__quick-nav-option')) {
        setShowQuickNav(false);
      }
    }

    window.addEventListener('click', checkClick);

    return () => {
      window.removeEventListener('click', checkClick);
    }
  }, [])

  useEffect(() => {
    const loadInvoices = async () => {
      try {
        const storedInvoices = await db.getAll()
        if (!!mountedRef.current && !!storedInvoices) {
          if (!!storedInvoices.length)
            dispatch(setInvoices(storedInvoices))
          else
            dispatch(setInvoices([{
              id: 0,
              name: capitalize(I18n.get('mainSale', 'venta principal')),
              mainSale: true,
            }]))
        }
      } catch {

      }
    }
    loadInvoices()
  }, [dispatch])

  useEffect(() => {
    checkWidth();
  }, [invoices])

  useEffect(() => {
    const selectedEl = listRef.current.children[selected];
    if (!!selectedEl) {
      listRef.current.scrollTo({
        left: selectedEl.offsetLeft,
        behavior: 'smooth'
      })
    }
  }, [selected, invoices])

  return (
    <div className="footer d-flex position-relative w-100">
      <div className={`footer__wrapper w-100 h-100 d-flex position-relative ${showNav ? 'footer-navigation' : ''}`}>

        {!!showNav && (
          <div className="footer__navigation-left position-absolute d-flex">
            <button
              type="button"
              className="btn button-transparent"
              onClick={() => scrollStep(-1)}
            >
              <Icon icon={IconArrowLeft} extraClass="icon-gray" />
            </button>
          </div>
        )}

        <div className="footer__invoice-list d-flex position-relative" ref={listRef}>
          {invoices.map((invoice, index) =>
            <Invoice
              key={invoice.id}
              invoice={invoice}
              selected={selected}
              index={index}
              onSelect={() => onChangeInvoice(index)}
              onOptions={event => onShowOptions(event)}
            />
          )}

          {!showNav && (
            <button
              type="button"
              className="btn button-transparent"
              data-testid="add-pending-invoice-btn"
              onClick={() => onAddInvoice()}
            >
              <Icon icon={IconPlus} extraClass="icon-gray" />
            </button>
          )}
        </div>

        <div className="footer__navigation-right d-flex position-absolute">

          {!!showNav && (
            <>
              <button
                type="button"
                data-testid="add-pending-invoice-btn"
                className="btn button-transparent"
                onClick={() => onAddInvoice()}
              >
                <Icon icon={IconPlus} extraClass="icon-gray" />
              </button>

              <button
                type="button"
                className="btn button-transparent"
                onClick={event => onShowQuickNav(event)}
              >
                <Icon icon={IconList} extraClass="icon-gray" />
              </button>
              <button
                type="button"
                className="btn button-transparent"
                onClick={() => scrollStep(1)}
              >
                <Icon icon={IconArrowRight} extraClass="icon-gray" />
              </button>
            </>
          )}

        </div>

        <InvoiceOptions
          optionsRef={optionsRef}
          show={showOpts}
          onDelete={() => dispatch(openModal({ modal: 'deletePendingInvoice' }))}
          onRename={() => {
            setShowOpts(false);
            dispatch(openModal({
              modal: 'renamePendingInvoice', params: {
                index: selected,
                invoice: invoices[selected]
              }
            }))
          }}
        />

        <QuickNav
          quickNavRef={quickNavRef}
          show={showQuickNav}
          invoices={invoices}
          onSelect={(index) => {
            onChangeInvoice(index)
            setShowQuickNav(false);
          }}
          selected={selected}
        />
      </div>

      <Confirmation
        isOpen={confirmationOpen}
        onClose={() => dispatch(closeModal({ modal: 'deletePendingInvoice' }))}
        onConfirm={() => onRemoveInvoice()}
        title={I18n.get('deletePendingInvoice', 'eliminar venta pendiente')}
        closeText={I18n.get('no', 'no')}
        confirmText={I18n.get('yes', 'si')}
        body={(
          <>
            <p className="text-center h4 text-capitalize-first p-5">
              {I18n.get('areYouSureToDeletePendingInvoice', 'seguro que quieres eliminar la venta pendiente')}
              {' '}<strong>{get(invoices, `${selected}.name`)}</strong>
            </p>
          </>
        )}
      />
    </div>
  )
}

export default Footer;
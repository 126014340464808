import React from "react";
import SpainAddressSimplified from "./SpainAddressSimplified";

/**
 * Component that serves as the body of the form, organizing various form sections.
 *
 * @component
 * @param {Object} props - Component properties.
 * @returns {JSX.Element} The rendered form body.
 */
const FormBody = (props) => {
  return (
    <div className="form-body w-100 p-3 overflow-hidden">
      <SpainAddressSimplified {...props} />
    </div>
  );
};

export default FormBody;

import { get } from "lodash";

export class Filters {
  constructor(filters) {
    this.metadata = get(filters, 'metadata', true);
    this.start = get(filters, "start", 0);
    this.limit = get(filters, "limit", 0);
    this.order_field = get(filters, "order_field", "");
    this.order_direction = get(filters, "order_direction", "ASC");
    this.fields = get(filters, "fields", "");
    this.updater = null;
    this.currentKeys = [];
  };

  getFilters() {
    const filters = { ...this };

    delete filters.updater;
    delete filters.currentKeys;
    return {
      ...filters,
    }
  };

  setUpdater(updater) {
    this.updater = updater;
  };

  updatePagination(tableIndicators) {
    this.start = tableIndicators.pageIndex * tableIndicators.pageSize;
    this.limit = tableIndicators.pageSize;

    this.updater(prev => ({
      ...prev,
      start: this.start,
      limit: this.limit,
    }));
  };

  sortResults(key) {
    this.order_field = key;
    this.order_direction = this.order_direction === "ASC" ? "DESC" : "ASC";

    this.updater(prev => ({
      ...prev,
      order_field: this.order_field,
      order_direction: this.order_direction,
    }));
  };

  filterByKeys(keys) {
    this.currentKeys.forEach(key => delete this[key]);
    this.currentKeys = [];

    Object.entries(keys).forEach(([key, value]) => {
      this[key] = value
      this.currentKeys.push(key)
    });

    this.updater({
      ...this.getFilters(),
    });
  };

  setGlobalAdditionalFilters(keys) {
    Object.entries(keys).forEach(([key, value]) => {
      this[key] = value
    });

    this.updater({
      ...this.getFilters(),
    });
  };

  resetFilters() {
    this.currentKeys.forEach(key => delete this[key]);
    this.currentKeys = [];

    this.updater({
      ...this.getFilters(),
    });
  };

};
import en from './en'

const dictionary = {
  ...en,
  "configureFEmodal.title": "Electronic invoices in Alegra POS!",
  "configureFEmodal.description": "Register with the DGII and <b>start issuing</b> your electronic invoices from your point of sale.",
  "emissionStatus": "DGII Status",
  "goToTutorial": "Know how to enable me",
  "howToEmit": "How to stamp them",
  "identificationDefaultError": "It must be a number with 9 to 11 digits",
  "identificationFailed.ced": "It must be an 11 digit number",
  "identificationFailed.rnc": "It must be a 9 digit number",
  "networkErrorMessage": "Check your internet connection and try to issue your document again",
  "numerationExpired.body": "Choose another numbering or request a new one at the DGII virtual office.",
  "numerationExpired.button": "How to request another",
  "numerationMaxNumberReached.body": "Choose another numbering or request more documents to the DGII.",
  "numerationMaxNumberReached.button": "How to request more",
  "numerationsSettingTooltip": "Manage the numbering used for invoicing in your business from “Alegra Accounting”.",
  "onboarding.video.url": "https://www.youtube.com/watch?v=W1kwsPHfWVQ",
  "onboarding.video.id": "W1kwsPHfWVQ",
  "taxesSettingTooltip": "Configure the taxes you need for invoicing at your point of sale “Alegra Accounting”.",
  "youHavePendingInvoicesToEmit": "You have sales invoices to stamp! 🚨",
  "youHavePendingInvoicesToEmit.description": "Learn how to identify the invoices you have “To stamp” and send them to the DGII with a click.",
  "youCanNowMakeElectronicInvoices.description": "Active yourself at DGII and start creating electronic invoices at your POS.",
  "AlegraAccounting.new": "Accounting",
  "importProducts.text": "Import products",
  "importProducts.tooltip": "We will take you to Alegra Accounting to upload new products.",
  "solutionModal.accounting.supTitle": "Clear accounts with",
  "solutionModal.accounting.buttonMainText": "Try 15 days free",
  "solutionModal.accounting.buttonSecondaryText": "More information",
  "solutionModal.accounting.description": "With Alegra Accounting, you control your income and expenses with a click, and you get smart reports so that you are always up to date with the DGII.",
  "solutionModal.accounting.subTitle": "*After 15 days, you can choose a plan from {{price}} {{currency}} per month.",
  "solutionModal.store.supTitle": "Increase your sales with",
  "solutionModal.store.buttonMainText": "Try 15 days free",
  "solutionModal.store.buttonSecondaryText": "More information",
  "solutionModal.store.description": "Start selling online in seconds! With Alegra Store, you can create your first online store in just 4 clicks.",
  "solutionModal.store.subTitle": "*After 15 days, you can choose a plan from {{price}} {{currency}} per month.",
  "solutionModal.pos.supTitle": "Manage your business with",
  "solutionModal.pos.buttonMainText": "Try 15 days free",
  "solutionModal.pos.buttonSecondaryText": "More information",
  "solutionModal.pos.description": "Invoice in seconds with the fastest Point of Sale System on the market. In addition, you can generate your sales and inventory reports in seconds. ⚡",
  "solutionModal.pos.subTitle": "*After 15 days, you can choose a plan from {{price}} {{currency}} per month.",
  "numerationElectronicOffline": "To create electronic invoices you need an internet connection to send them to DGII."
}

export default dictionary;
import React, { useState, useEffect } from 'react'
import { I18n } from '@aws-amplify/core';
import { get } from 'lodash'
import { useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import dayjs from 'dayjs'

import { fetchMembership } from '../../reducers/membership'
import { membershipSelector, dateFormat as dateFormatSelector } from '../../selectors/company'
import SubscriptionSVG from './SubscriptionSVG'
import Loading from './Loading'

const Subscription = () => {
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const history = useHistory();
  const membership = useSelector(membershipSelector);
  const dateFormat = useSelector(dateFormatSelector);

  useEffect(() => {
    const loadMembership = async () => {
      setLoading(true)
      await dispatch(fetchMembership())
      setLoading(false)
    }
    loadMembership()
  }, [dispatch])

  return (
    <div className="plans d-flex flex-column align-items-center">
      {loading
        ? <Loading text={I18n.get('subscriptionLoadingMessage', 'Espera un momento mientras se carga la información de tu plan. ⌛')} />
        : (

          <div className="plans-container p-md-5 p-3 d-flex align-items-start justify-content-center flex-column px-3 position-relative">
            <div style={{ left: 0 }} className="position-absolute w-100 h-100 d-sm-flex align-items-start justify-content-end overflow-hidden d-none">
              <SubscriptionSVG />
            </div>

            <div className="plans-container-info d-flex flex-column align-items-start justify-content-between text-left h-100">
              <div style={{ maxHeight: '50rem' }} className="d-flex flex-column justify-content-between h-75">
                <div className="mb-2">
                  <h1 style={{ fontSize: '3.2rem' }} className="text-capitalize-first text-primary font-weight-light mt-2">
                    {I18n.get('subscriptionHeader', '¡Ya facturas fácil y rápido!')}
                  </h1>
                  <p className="text-capitalize-first font-weight-light">
                    {I18n.get('subscriptionIndications', 'Con Alegra POS vendes en segundos y haces crecer tu negocio. Conoce a continuación los detalles de tu suscripción:')}
                  </p>
                </div>

                <div className="mb-4">
                  <p style={{ fontSize: '1.4rem' }} className="text-capitalize-first h4">
                    {I18n.get('subscriptionStatus', 'Estado de suscripción')}
                  </p>
                  <p
                    style={{ color: get(membership, 'status') === "active" ? '#00B19D' : '#e85e42', fontSize: '1.2rem' }}
                    className="text-capitalize-first h5"
                  >
                    {get(membership, 'status') === "active"
                      ? I18n.get('active', 'Activo')
                      : I18n.get('inactive', 'Desactivado')
                    }
                  </p>
                </div>

                <div className="mb-4">
                  <p style={{ fontSize: '1.4rem' }} className="text-capitalize-first h4">
                    {I18n.get('AlegraPlan', 'Plan de Alegra')}
                  </p>
                  <div 
                    className="current-plan-button"
                    onClick={() => history.push('/plan/consumption')}
                  >
                    <p style={{ fontSize: '1.2rem' }} className="text-capitalize-first h5">
                      {get(membership, 'plan.name')}
                    </p>
                  </div>
                  
                </div>

                <div className="mb-4">
                  <p style={{ fontSize: '1.4rem' }} className="text-capitalize-first h4">
                    {I18n.get('paymentFrequency', 'Frecuencia de pago')}
                  </p>
                  <p style={{ fontSize: '1.2rem' }} className="text-capitalize-first h5">
                    {!!+get(membership, 'monthlyFrequency')
                      ? I18n.get('monthly', 'Mensual')
                      : I18n.get('yearly', 'Anual')
                    }
                  </p>
                </div>

                {!!get(membership, 'nextPaymentDate') && (
                  <div className="mb-4">
                    <p style={{ fontSize: '1.4rem' }} className="text-capitalize-first h4">
                      {I18n.get('nextPaymentDate', 'Próxima fecha de pago')}
                    </p>
                    <p style={{ fontSize: '1.2rem' }} className="text-capitalize-first h5">
                      {dayjs(get(membership, 'nextPaymentDate')).format(!!dateFormat ? dateFormat.toUpperCase() : I18n.get('dateFormat', 'DD/MM/YYYY'))}
                    </p>
                  </div>
                )}

                <div className="mb-2">
                  <button
                    type="button"
                    className="btn btn-submit"
                    onClick={() => history.push('/plan/plans')}
                    style={{
                      width: '24rem'
                    }}
                  >
                    {I18n.get('goToPlans', 'Ver planes')}
                  </button>
                </div>
              </div>

              <div>
                <div className="d-flex align-items-center">
                  <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M18.1805 5.58407H16.5138V13.0841H5.6805V14.7507C5.6805 15.2091 6.0555 15.5841 6.51383 15.5841H15.6805L19.0138 18.9174V6.4174C19.0138 5.95907 18.6388 5.58407 18.1805 5.58407ZM14.8472 10.5841V3.08407C14.8472 2.62573 14.4722 2.25073 14.0138 2.25073H3.1805C2.72217 2.25073 2.34717 2.62573 2.34717 3.08407V14.7507L5.6805 11.4174H14.0138C14.4722 11.4174 14.8472 11.0424 14.8472 10.5841Z" fill="#00B19D" />
                  </svg>
                  <p style={{ fontSize: '1.4rem' }} className="text-primary text-capitalize-first h3 ml-2 font-weight-bold">
                    {I18n.get('solveYourDoubts', 'Resuelve tus dudas')}
                  </p>
                </div>
                <p style={{ fontSize: '1.2rem' }} className="text-primary-first h5 ml-5">
                  {`${I18n.get('solveYourDoubtsSubtitle1', 'Escribe al correo')} `}
                  <b><a href="mailto:soporte@alegra.com" className="text-title">soporte@alegra.com</a></b>
                  {` ${I18n.get('solveYourDoubtsSubtitle1', 'para recibir ayuda de los especialistas de soporte.')}`}
                </p>
              </div>
            </div>
          </div>
        )
      }
    </div>
  )
}

export default Subscription;
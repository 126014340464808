import { toastHandler } from '@alegradev/smile-ui-react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useHistory } from 'react-router-dom';
import { get, uniqueId } from 'lodash';
import { I18n } from 'aws-amplify';
import * as contactDB from '../../../database/contactsDB';
import alegraAPI from '../../../reducers/alegraAPI';
import { handleError } from '../../../utils/errors';
import { contactsKeys } from './utils';
import { useDispatch, useSelector } from 'react-redux';
import { setClient } from '../../../reducers/activeInvoice';
import { sendNewGTMEvent } from '../../../reducers/company';
import { calculateActionTimeSelector } from '../../../selectors/monitoring';

export const useCreateContactMutation = () => {
  const queryClient = useQueryClient();
  const history = useHistory();
  const dispatch = useDispatch();
  const calculateActionTime = useSelector(calculateActionTimeSelector);

  return useMutation(async (values) => {
    const isEditable = get(values, 'editable', false);
    const ignoreRepeated = get(values, 'ignoreRepeated', false);

    if (isEditable && !values?.id) {
      throw new Error(
        I18n.get(
          'contactIdRequired',
          'No se ha encontrado el id del contacto a editar'
        )
      );
    }

    const TOAST_ID = `create-contact-${uniqueId()}`;
    try {
      const message = [
        isEditable && !ignoreRepeated
          ? I18n.get('updatingContact', 'Editando contacto...')
          : I18n.get('creatingContact', 'Creando contacto...'),
        isEditable && !ignoreRepeated
          ? I18n.get('contactUpdated', 'Contacto editado')
          : I18n.get('contactCreated', 'Contacto creado'),
        isEditable && !ignoreRepeated
          ? I18n.get(
              'contactUpdatedDescription',
              'El contacto ha sido editado correctamente'
            )
          : I18n.get(
              'contactCreatedDescription',
              'El contacto ha sido creado correctamente'
            ),
      ];

      toastHandler({
        id: TOAST_ID,
        title: message[0],
        type: 'neutral',
        loader: true,
      });

      const data =
        isEditable && !ignoreRepeated
          ? await alegraAPI.put(`/contacts/${values?.id}`, values)
          : await alegraAPI.post(`/contacts`, values);

      toastHandler({
        id: TOAST_ID,
        update: true,
        title: message[1],
        description: message[2]?.replace('{{name}}', data?.data?.name),
        type: 'success',
      });

      if (isEditable && !ignoreRepeated) {
        if (values?.from === 'sideModal') {
          queryClient.setQueriesData(contactsKeys.all, (items) => {
            if (Array.isArray(items?.data) && items?.data.length > 0) {
              return {
                ...items,
                data: items.data.map((item) => {
                  if (item?.id === values?.id) {
                    return {
                      ...item,
                      ...data.data,
                    };
                  }
                  return item;
                }),
              };
            }
          });
        }

        queryClient.setQueriesData(contactsKeys.detail, (item) => {
          if (item && item?.id === values?.id) {
            return {
              ...item,
              ...data.data,
            };
          }
        });
      } else {
        if (values?.from === 'sideModal') {
          queryClient.setQueriesData([contactsKeys.all], (oldData) => {
            return {
              data: [data.data, ...(oldData?.data || [])],
              metadata: {
                ...oldData.metadata,
                total: oldData.metadata.total + 1,
              },
            };
          });
        }
      }

      if (isEditable && !ignoreRepeated) {
        contactDB.update(data?.data?.id, data?.data);
        dispatch(
          sendNewGTMEvent('pos-contact-edited', {
            id: get(data, 'data.id') ?? get(data, 'data.createClient.id'),
            error: 'no error',
            responseTime: calculateActionTime('createContact'),
            type: get(values, 'type', 'client'),
            formType: 'Avanzado',
            origin: values?.from === 'page' ? 'contact' : 'contactModule',
            scraping: false,
          })
        );
      } else {
        dispatch(
          sendNewGTMEvent('pos-contact-created', {
            id: get(data, 'data.id') ?? get(data, 'data.createClient.id'),
            error: 'no error',
            responseTime: calculateActionTime('createContact'),
            type: get(values, 'type', 'client'),
            formType: 'Avanzado',
            origin: 'contact',
            scraping: false,
          })
        );
      }

      if (values?.from === 'page') {
        history.push(`/contacts/contact/${data?.data?.id}/detail`);
      } else {
        dispatch(setClient(data.data));
      }

      return get(data, 'data', {});
    } catch (error) {
      const errorMessage =
        isEditable && !ignoreRepeated
          ? I18n.get('errorUpdatingContact', 'Error editando el contacto')
          : I18n.get('errorCreatingContact', 'Error creando el contacto');

      toastHandler({
        id: TOAST_ID,
        update: true,
        title: errorMessage,
        description: handleError(error),
        type: 'error',
      });
    }
  });
};

export const useDeleteContactMutation = () => {
  const queryClient = useQueryClient();
  const history = useHistory();

  return useMutation(async (id) => {
    const TOAST_ID = `deleteContact-${id}`;
    try {
      const deleteMessage = [
        I18n.get('deletingContact', 'Eliminando contacto...'),
        I18n.get('contactDeleted', 'Contacto eliminado'),
        I18n.get(
          'contactDeletedDescription',
          'El contacto ha sido eliminado correctamente'
        ),
      ];

      toastHandler({
        id: TOAST_ID,
        title: deleteMessage[0],
        type: 'neutral',
        loader: true,
      });

      const data = await alegraAPI.delete(`/contacts/${id}`);

      toastHandler({
        id: TOAST_ID,
        update: true,
        title: deleteMessage[1],
        description: deleteMessage[2],
        type: 'success',
      });

      queryClient.setQueriesData([contactsKeys.all], (old) => ({
        data: old.data.filter((od) => od.id !== id),
        metadata: {
          ...old.metadata,
          total: old.metadata.total - 1,
        },
      }));

      contactDB.remove(id);

      history.push('/contacts');

      return get(data, 'data', {});
    } catch (error) {
      const errorMessage = I18n.get(
        'errorDeletingContact',
        'Error eliminando contacto'
      );

      toastHandler({
        id: TOAST_ID,
        update: true,
        title: errorMessage,
        description: handleError(error),
        type: 'error',
      });
    }
  });
};

export const useChangeContactStatusMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(async ({ id, status }) => {
    const TOAST_ID = `changeContactStatus-${id}`;
    const newStatus = status === 'active' ? 'inactive' : 'active';
    const activationMessage =
      status === 'active'
        ? [
            I18n.get('contactDeactivated', 'Contacto desactivado'),
            I18n.get(
              'contactDeactivatedDescription',
              'El contacto ha sido desactivado correctamente'
            ),
          ]
        : [
            I18n.get('contactActivated', 'Contacto activado'),
            I18n.get(
              'contactActivatedDescription',
              'El contacto ha sido activado correctamente'
            ),
          ];

    try {
      toastHandler({
        id: TOAST_ID,
        title:
          status === 'active'
            ? I18n.get('deactivatingContact', 'Desactivando contacto...')
            : I18n.get('activatingContact', 'Activando contacto...'),
        type: 'neutral',
        loader: true,
      });

      const data = await alegraAPI.put(`/contacts/${id}`, {
        id,
        status: newStatus,
      });

      toastHandler({
        id: TOAST_ID,
        update: true,
        title: activationMessage[0],
        description: activationMessage[1],
        type: 'success',
      });

      queryClient.setQueriesData(contactsKeys.all, (items) => {
        if (Array.isArray(items?.data) && items?.data.length > 0) {
          return {
            ...items,
            data: items.data.map((item) => {
              if (item.id === id) {
                return {
                  ...item,
                  status: newStatus,
                };
              }
              return item;
            }),
          };
        }
      });
      queryClient.setQueriesData(contactsKeys.detail, (item) => {
        if (item && item.id === id) {
          return {
            ...item,
            status: newStatus,
          };
        }
      });
      return get(data, 'data', {});
    } catch (error) {
      toastHandler({
        id: TOAST_ID,
        update: true,
        title:
          status === 'active'
            ? I18n.get(
                'errorDeactivatingContact',
                'Error desactivando contacto'
              )
            : I18n.get('errorActivatingContact', 'Error activando contacto'),
        description: handleError(error),
        type: 'error',
      });
    }
  });
};

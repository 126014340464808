import { I18n } from '@aws-amplify/core';
import { get, capitalize } from 'lodash'
import { defaultClient, clientDataAreEquals } from '../reducers/activeInvoice';
import { COUNTRIES } from './enums/countries';
import { DOCUMENT_TYPES } from './enums/documentTypes';

/**
 * Abstract comparator strategy class.
 * Each comparator should implement the `compare` method.
 */
class ComparatorStrategy {
  /**
   * Compares default client data with actual client data.
   *
   * @param {Object} defaultClientData - The default client data.
   * @param {Object} clientData - The actual client data.
   * @returns {boolean} - Returns true if the client data matches the default, false otherwise.
   */
  compare(defaultClient, clientData) {
    throw new Error('Compare method not implemented');
  }
}

/**
 * Comparator for Costa Rica.
 */
class CostaRicaComparator extends ComparatorStrategy {
  compare(defaultClientData, clientData) {
    return (
      clientDataAreEquals(defaultClientData.name, clientData.name) &&
      clientDataAreEquals(defaultClientData.identificationObject.type, clientData.identificationObject.type) &&
      clientDataAreEquals(defaultClientData.identificationObject.number, clientData.identificationObject.number)
    );
  }
}

/**
 * Comparator for Peru.
 */
class PeruComparator extends ComparatorStrategy {
  compare(defaultClientData, clientData) {
    return (
      (clientDataAreEquals(defaultClientData.name, clientData.name) &&
        clientDataAreEquals(defaultClientData.identification.type, clientData.identification.type) &&
        clientDataAreEquals(defaultClientData.identification.number, clientData.identification.number)) ||
      (clientDataAreEquals('Cliente general', clientData.name) &&
        clientDataAreEquals('DNI', clientData.identification.type) &&
        clientDataAreEquals('00000000', clientData.identification.number))
    );
  }
}

/**
 * Comparator for Colombia.
 */
class ColombiaComparator extends ComparatorStrategy {
  compare(defaultClientData, clientData) {
    const hasNameObject = !!get(clientData, 'nameObject');

    if (hasNameObject) {
      return (
        clientDataAreEquals(defaultClientData.nameObject.firstName, clientData.nameObject.firstName) &&
        clientDataAreEquals(defaultClientData.nameObject.lastName, clientData.nameObject.lastName) &&
        clientDataAreEquals(defaultClientData.identificationObject.type, clientData.identificationObject.type) &&
        clientDataAreEquals(defaultClientData.identificationObject.number, clientData.identificationObject.number)
      );
    }

    return (
      clientDataAreEquals(defaultClientData.nameObject.firstName, clientData.name.firstName) &&
      clientDataAreEquals(defaultClientData.nameObject.lastName, clientData.name.lastName) &&
      clientDataAreEquals(defaultClientData.identificationObject.type, clientData.identificationObject.type) &&
      clientDataAreEquals(defaultClientData.identificationObject.number, clientData.identificationObject.number)
    );
  }
}

/**
 * Comparator for Mexico.
 */
class MexicoComparator extends ComparatorStrategy {
  compare(defaultClientData, clientData) {
    return (
      clientDataAreEquals(defaultClientData.name, clientData.name) &&
      clientDataAreEquals(defaultClientData.identification, clientData.identification)
    );
  }
}

/**
 * Comparator for Argentina.
 */
class ArgentinaComparator extends ComparatorStrategy {
  compare(defaultClientData, clientData) {
    return (
      clientDataAreEquals(defaultClientData.name, clientData.name) &&
      clientDataAreEquals(defaultClientData.identification.type, clientData.identification.type) &&
      clientDataAreEquals(defaultClientData.identification.number, clientData.identification.number)
    );
  }
}

/**
 * Comparator for Republica Dominicana.
 */
class RepublicaDominicanaComparator extends ComparatorStrategy {
  compare(defaultClientData, clientData) {
    return clientDataAreEquals(defaultClientData.name, clientData.name);
  }
}

/**
 * Comparator for Spain.
 */
class SpainComparator extends ComparatorStrategy {
  compare(defaultClientData, clientData) {
    return clientDataAreEquals(defaultClientData.identification, clientData.identification);
  }
}

/**
 * Default comparator for countries without specific strategies.
 */
class DefaultComparator extends ComparatorStrategy {
  compare(defaultClientData, clientData) {
    return clientDataAreEquals(defaultClientData.name, clientData.name);
  }
}

/**
 * Mapping of country codes to their respective comparator strategies.
 */
const comparatorStrategies = {
  [COUNTRIES.COSTA_RICA]: new CostaRicaComparator(),
  [COUNTRIES.PERU]: new PeruComparator(),
  [COUNTRIES.COLOMBIA]: new ColombiaComparator(),
  [COUNTRIES.MEXICO]: new MexicoComparator(),
  [COUNTRIES.ARGENTINA]: new ArgentinaComparator(),
  [COUNTRIES.REPUBLICA_DOMINICANA]: new RepublicaDominicanaComparator(),
  [COUNTRIES.SPAIN]: new SpainComparator(),
};

/**
 * Determines if the given client is the default client for the specified country.
 *
 * @param {Object} client - The client object to compare.
 * @param {string} country - The country code.
 * @param {boolean} [refund=false] - Indicates if the client is related to a refund.
 * @returns {boolean} - Returns true if the client is the default client, false otherwise.
 */
export const isDefaultClient = (client, country, refund = false) => {
  if (!client) return false;

  try {
    const defaultC = defaultClient(country, client.address, client.isElectronic);

    const comparator = comparatorStrategies[country] || new DefaultComparator();

    return comparator.compare(defaultC, client);
  } catch (error) {
    console.error(`Error in isDefaultClient: ${error.message}`);
    return false;
  }
};

// Define constants for target lengths per country
const COUNTRY_TARGET_LENGTHS = {
  peru: 8,
  argentina: 8,
  republicaDominicana: 8,
  costaRica: 10,
};

/**
 * Formats the invoice number by adding leading zeros based on country-specific rules.
 *
 * @param {Object} invoice - The invoice object containing number details.
 * @param {string} number - The invoice number to format.
 * @param {string} country - The country code.
 * @returns {string|null} - The formatted invoice number or null if invoice is not provided.
 */
export const getFormattedNumber = (invoice, number, country) => {
  if (!invoice) return null;

  let targetLength = COUNTRY_TARGET_LENGTHS[country] || 0;

  // Special case for Costa Rica: Check if 'numberTemplate.stamp' exists
  if (country === 'costaRica') {
    const hasStamp = get(invoice, 'numberTemplate.stamp', null);
    targetLength = hasStamp ? COUNTRY_TARGET_LENGTHS.costaRica : 0;
  }

  if (targetLength === 0) return number;

  const numberStr = String(number || '');

  const leadingZeros = Math.max(targetLength - numberStr.length, 0);

  const formattedNumber = leadingZeros > 0 ? '0'.repeat(leadingZeros) + numberStr : numberStr;

  return formattedNumber;
};

/**
 * Determines the label for Peru based on the prefix.
 *
 * @param {Object} params - Parameters containing prefix.
 * @param {string|null} params.prefix - The prefix of the invoice.
 * @returns {string} - The appropriate label.
 */
const getPeruLabel = ({ prefix }) => {
  if (/^B.{3}/.test(prefix)) {
    return I18n.get('ticket', 'Boleta');
  } else if (/^F.{3}/.test(prefix)) {
    return I18n.get('invoice', 'Factura');
  } else {
    return I18n.get('sale', 'Venta');
  }
};

/**
 * Determines the label for Mexico based on the document type.
 *
 * @param {Object} invoice - The invoice object.
 * @returns {string} - The appropriate label.
 */
const getMexicoLabel = (invoice) => {
  const documentType = get(invoice, 'numberTemplate.documentType', null);
  if (documentType === 'saleTicket') {
    return I18n.get('invoiceTicket', 'Ticket');
  }
  return I18n.get('invoiceBill', 'Factura de venta');
};

/**
 * Formats the number with prefix.
 *
 * @param {string} label - The label for the invoice.
 * @param {string|null} prefix - The prefix of the invoice.
 * @param {string|null} formattedNumber - The formatted number.
 * @returns {string} - The formatted invoice number.
 */
const formatNumberWithPrefix = (label, prefix, formattedNumber) => {
  const prefixSegment = prefix ? `${prefix}-` : '';
  return `${label} ${prefixSegment}${formattedNumber || ''}`;
};

/**
 * Formats the invoice number for Spain with specific HTML formatting.
 *
 * @param {string} label - The label for the invoice.
 * @param {string|null} documentType - The document type of the invoice.
 * @param {string|null} prefix - The prefix of the invoice.
 * @param {string|null} formattedNumber - The formatted number.
 * @returns {string} - The formatted invoice number with HTML.
 */
const formatSpainInvoice = (label, documentType, prefix, formattedNumber, type = null) => {
  const invoiceType = !type
    ? (documentType === 'invoice'
      ? I18n.get('ordinaryInvoice', 'Factura Ordinaria')
      : I18n.get('simplifiedInvoice', 'Factura Simplificada'))
    : (type === 'REST'
      ? I18n.get('ordinaryInvoice', 'Factura Ordinaria')
      : I18n.get('simplifiedInvoice', 'Factura Simplificada'));

  const prefixSegment = prefix ? `${prefix} -` : '';
  return `${invoiceType} ${prefixSegment}${formattedNumber || ''}`;
};

/**
 * Formats the invoice number based on country and refund status.
 *
 * @param {Object} invoice - The invoice object containing number details.
 * @param {string} country - The country code.
 * @param {boolean} [refund=false] - Indicates if the invoice is a refund.
 * @returns {string|null} - The formatted invoice number or null if invoice is not provided.
 */
export const getNumber = (invoice, country, refund = false, type = null) => {
  if (!invoice) return null;

  const {
    numberTemplate: {
      number: templateNumber = null,
      prefix: templatePrefix = null,
      fullNumber: templateFullNumber = null,
      documentType: templateDocumentType = null,
    } = {},
    number = null,
    prefix = null,
    fullNumber = null,
  } = invoice;

  const usedNumber = refund ? number : templateNumber;
  const usedPrefix = refund ? prefix : templatePrefix;
  const usedFullNumber = refund ? fullNumber : templateFullNumber;

  const formattedNumber = getFormattedNumber(invoice, usedNumber, country);

  let label = I18n.get('number', 'Número');

  switch (country) {
    case COUNTRIES.PERU:
      label = getPeruLabel({ usedPrefix });
      break;
    case COUNTRIES.MEXICO:
      label = getMexicoLabel(invoice);
      break;
    default:
      label = capitalize(label);
      break;
  }

  if (usedFullNumber && ![COUNTRIES.PANAMA, COUNTRIES.SPAIN].includes(country)) {
    return `${label} ${usedFullNumber}`;
  }

  switch (country) {
    case COUNTRIES.PERU:
    case COUNTRIES.ARGENTINA:
    case COUNTRIES.PANAMA:
      return formatNumberWithPrefix(label, usedPrefix, formattedNumber);
    case COUNTRIES.SPAIN:
      return formatSpainInvoice(label, templateDocumentType, usedPrefix, formattedNumber, type);
    default:
      return `${label} ${usedPrefix || ''}${formattedNumber || ''}`;
  }
};


/**
 * Determines the label for Colombia based on the document type and electronic status.
*
* @param {Object} params - Parameters containing documentType and electronic status.
* @param {string|null} params.documentType - The document type of the invoice.
* @param {boolean} [params.isElectronic=false] - Indicates if the document is electronic.
* @returns {string} - The appropriate label.
*/
const getColombiaLabel = ({ documentType, isElectronic }) => {
  if (documentType === DOCUMENT_TYPES.SALE_TICKET && isElectronic) {
    return I18n.get('electronicPOSDocument', 'Documento POS electrónico');
  } else if (documentType === DOCUMENT_TYPES.SALE_TICKET) {
    return I18n.get('posDocument', 'Documento POS');
  } else if (documentType === DOCUMENT_TYPES.INVOICE && isElectronic) {
    return I18n.get('electronicSellInvoice', 'Factura de venta electrónica');
  } else {
    return I18n.get('invoiceOfSale', 'Factura de venta');
  }
};

/**
 * Maps country-specific document type to label determination functions.
 */
const COUNTRY_DOCUMENT_LABELS = {
  peru: getPeruLabel,
  colombia: getColombiaLabel,
  // Add other countries and their label functions as needed
};

/**
 * Formats the invoice number and returns it with the appropriate prefix label.
 *
 * @param {Object} invoice - The invoice object containing number details.
 * @param {string} country - The country code.
 * @param {boolean} [refund=false] - Indicates if the invoice is a refund.
 * @returns {string|null} - The formatted invoice number with prefix or null if invoice is not provided.
 */
export const getNumberAndPrefix = (invoice, country, refund = false) => {
  if (!invoice) return null;

  const {
    numberTemplate: {
      number: templateNumber = null,
      prefix: templatePrefix = null,
      fullNumber: templateFullNumber = null,
      documentType: templateDocumentType = null,
      isElectronic: templateIsElectronic = false,
    } = {},
    number = null,
    prefix = null,
    fullNumber = null,
  } = invoice;

  // Determine which fields to use based on refund status
  const usedNumber = refund ? number : templateNumber;
  const usedPrefix = refund ? prefix : templatePrefix;
  const usedFullNumber = refund ? fullNumber : templateFullNumber;
  const usedDocumentType = templateDocumentType;
  const usedIsElectronic = templateIsElectronic;

  const formattedNumber = getFormattedNumber(invoice, usedNumber, country);

  let label = I18n.get('number', 'Número');

  if (COUNTRY_DOCUMENT_LABELS[country]) {
    label = COUNTRY_DOCUMENT_LABELS[country]({
      invoice,
      prefix: usedPrefix,
      documentType: usedDocumentType,
      isElectronic: usedIsElectronic,
    });
  } else {
    label = capitalize(label);
  }

  if (usedFullNumber && country !== COUNTRIES.PANAMA) {
    return `${label} ${usedFullNumber || ''}`;
  }

  const formattedNumberWithPrefix = formatNumberWithPrefix(label, usedPrefix, formattedNumber);

  return formattedNumberWithPrefix;
};

/**
 * Determines the label for Colombia based on the document type and electronic status.
 *
 * @param {Object} params - Parameters containing documentType and electronic status.
 * @param {string|null} params.documentType - The document type of the invoice.
 * @param {boolean} [params.isElectronic=false] - Indicates if the document is electronic.
 * @returns {string} - The appropriate label.
 */
const getColombiaLabelSimplified = ({ documentType, isElectronic }) => {
  if (documentType === DOCUMENT_TYPES.SALE_TICKET && isElectronic) {
    return I18n.get('electronicPOS', 'POS electrónico');
  } else if (documentType === DOCUMENT_TYPES.SALE_TICKET) {
    return I18n.get('pos', 'POS');
  } else if (documentType === DOCUMENT_TYPES.INVOICE && isElectronic) {
    return I18n.get('electronicInvoice', 'factura electrónica');
  } else {
    return I18n.get('invoiceOfSale', 'factura de venta');
  }
};

const COUNTRY_DOCUMENT_LABES_SIMPLIFIED = {
  colombia: getColombiaLabelSimplified,
};

/**
 * Formats the invoice number and returns it with the appropriate prefix label.
 *
 * @param {Object} invoice - The invoice object containing number details.
 * @param {string} country - The country code.
 * @param {boolean} [refund=false] - Indicates if the invoice is a refund.
 * @returns {string|null} - The formatted invoice number with prefix or null if invoice is not provided.
 */
export const getNumberAndPrefixSimplified = (invoice, country, refund = false) => {
  if (!invoice) return null;

  const {
    numberTemplate: {
      documentType: templateDocumentType = null,
      number: templateNumber = null,
      prefix: templatePrefix = null,
      fullNumber: templateFullNumber = null,
      isElectronic: templateIsElectronic = false,
    } = {},
    number = null,
    prefix = null,
    fullNumber = null,
  } = invoice;

  const usedNumber = refund ? number : templateNumber;
  const usedPrefix = refund ? prefix : templatePrefix;
  const usedFullNumber = refund ? fullNumber : templateFullNumber;
  const usedDocumentType = templateDocumentType;
  const usedIsElectronic = templateIsElectronic;

  const formattedNumber = getFormattedNumber(invoice, usedNumber, country);

  let label = I18n.get('number', 'Número');

  if (COUNTRY_DOCUMENT_LABELS[country]) {
    label = COUNTRY_DOCUMENT_LABES_SIMPLIFIED[country]({
      invoice,
      documentType: usedDocumentType,
      isElectronic: usedIsElectronic,
    });
  }

  if (usedFullNumber && country !== COUNTRIES.PANAMA) {
    return `${label} ${usedFullNumber}`;
  }

  const formattedNumberWithPrefix = formatNumberWithPrefix(label, usedPrefix, formattedNumber);

  return formattedNumberWithPrefix;
};


export const STATUS_IN_PROCESS = {
  inFrontend: 1,
  inBackend: 2,
  inAC: 3
}
import React, { useState, useEffect, useCallback, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { getMembershipPlans } from '../../reducers/membership'
import { country as countrySelector } from '../../selectors/company'
import ChoosePlan from './ChoosePlan'
import Loading from './Loading'
import LoadingError from './LoadingError'

const USER_FEATURE_POSITION = 1;
const MULTICURRENCY_FEATURE_POSITION = 9;
const STATION_FEATURE_POSITION = 77;

const Plans = ({ onPlanSelected }) => {
  const dispatch = useDispatch()
  const ref = useRef(false)
  const [loadingPlans, setLoadingPlans] = useState(false)
  const [plans, setPlans] = useState(null)
  const country = useSelector(countrySelector);

  const getPlans = useCallback(async () => {
    setLoadingPlans(true)
    const posPlans = await dispatch(getMembershipPlans())
    if (!!posPlans && ref.current) {
      for (let plan in posPlans) {
        if (posPlans[plan].features[USER_FEATURE_POSITION].keyword === 'users') {
          posPlans[plan].features[USER_FEATURE_POSITION].relevant = true
        }
        if (posPlans[plan].features[MULTICURRENCY_FEATURE_POSITION].keyword === 'multicurrency') {
          posPlans[plan].features[MULTICURRENCY_FEATURE_POSITION].relevant = false
        }
        if (posPlans[plan].features[STATION_FEATURE_POSITION].keyword === 'stations' && country === 'argentina') {
          posPlans[plan].features[STATION_FEATURE_POSITION].relevant = true
        }
      }
      setPlans(posPlans)
    }
    if (ref.current)
      setLoadingPlans(false)
  }, [dispatch, country])

  useEffect(() => {
    ref.current = true
    return () => ref.current = false
  }, [])

  useEffect(() => {
    getPlans()
  }, [getPlans])

  return (
    <div className="plans d-flex flex-column align-items-center">
      {loadingPlans && (
        <Loading />
      )}
      {!loadingPlans && !plans && (
        <LoadingError onRetry={() => getPlans()} />
      )}
      {!loadingPlans && !!plans && (
        <ChoosePlan
          plans={plans}
          onSelectPlan={(plan) => onPlanSelected(plan)}
        />
      )}
    </div>
  )
}

export default Plans;
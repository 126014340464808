import React, { useState, useEffect, useCallback } from 'react';
import { Field } from 'react-final-form';
import { I18n } from '@aws-amplify/core';
import { capitalize, get } from 'lodash';
import { useSelector, useDispatch } from 'react-redux'

import { openModal } from '../../../reducers/modals'
import { checkFeatureLimit } from '../../../reducers/membership'
import { hasPermissionTo } from '../../../selectors/auth'
import { electronicInvoicing } from '../../../selectors/app'
import { companySelector, country as countrySelector } from '../../../selectors/company'
import { activePriceLists } from '../../../selectors/priceLists'
import { activeCurrencies } from '../../../selectors/currencies'
import { groupedNumerations as groupedNumerationsSelector } from '../../../selectors/numerations'
import { activePaymentTerms } from '../../../selectors/paymentTerms'
import { economicActivitiesSelector, mainEconomicActivitySelector } from '../../../selectors/auth'
import { economicActivity as economyActivitySelector } from '../../../selectors/activeInvoice';
import { renderField, renderSelect, renderSelectActivities } from '../fields/V0/Fields';
import { checkNumerationBlock, getResolutionHelpUrl } from '../../home/NewActiveInvoice/utils';

import deliveryTerms from '../../countriesData/colombia/deliveryTerms'
import operationTypes from '../../countriesData/colombia/operationTypes'
import invoiceTypes from '../../countriesData/colombia/invoiceTypes'
import PopoverComponent from '../../common/Popover';

const InvoiceSettings = ({ values }) => {
  const can = useSelector(hasPermissionTo);
  const dispatch = useDispatch();
  const country = useSelector(countrySelector);
  const isElectronic = useSelector(electronicInvoicing(get(values, 'numeration', null)));
  const paymentTerms = useSelector(activePaymentTerms);
  const priceLists = useSelector(activePriceLists);
  const currencies = useSelector(activeCurrencies);
  const economicActivities = useSelector(economicActivitiesSelector);
  const groupedNumerations = useSelector(groupedNumerationsSelector);
  const { registryDate } = useSelector(companySelector);
  const [showNumerationBlockPopover, setShowNumerationBlockPopover] = useState(true);
  const [numerationBlocked, setNumerationBlocked] = useState(null);

  const getNumerationBlock = useCallback(() => {
    setNumerationBlocked(checkNumerationBlock(values?.numeration, country, registryDate))
    setShowNumerationBlockPopover(true)
  }, [values?.numeration, country, registryDate])

  useEffect(() => {
    getNumerationBlock()
  }, [getNumerationBlock])

  const economicActivity = useSelector(economyActivitySelector)
  const mainEconomicActivity = useSelector(mainEconomicActivitySelector)

  return (
    <div className="form-body h-100 p-4 overflow-hidden">
      <div className="form-body__fields form-row justify-content-start">
        <Field
          name="currency"
          className={!!get(values, 'currency.status') ? 'col-md-6' : 'col-md-12'}
          component={renderSelect}
          options={!can('index', 'currencies') ? [] : currencies}
          selectInfo={!can('index', 'currencies')
            ? I18n.get('userNotAllowed.currencies.view', 'no tienes permisos para ver las monedas')
            : null}
          hideNoOptionMessage={!can('index', 'currencies')}
          getOptionLabel={option => option.code}
          getOptionValue={option => option.code}
          label={capitalize(I18n.get('currency', 'moneda'))}
          addOptionText={capitalize(I18n.get('newCurrency', 'Nueva moneda'))}
          addOptionAction={() => dispatch(checkFeatureLimit('multicurrency', () => dispatch(openModal({ modal: 'currency' }))))}
        />

        {!!get(values, 'currency.status') && (
          <Field
            name="currency.exchangeRate"
            className="col-md-6"
            component={renderField}
            type="number"
            label={capitalize(I18n.get('exchangeRate', 'tasa de cambio'))}
          />
        )}

        <Field
          name="priceList"
          className="col-md-12"
          component={renderSelect}
          options={!can('index', 'price-lists') || !can('view', 'price-lists') ? [] : priceLists}
          selectInfo={!can('index', 'price-lists') || !can('view', 'price-lists')
            ? I18n.get('userNotAllowed.price-lists.view', 'no tienes permisos para ver la información de las listas de precio')
            : null}
          hideNoOptionMessage={!can('index', 'price-lists') || !can('view', 'price-lists')}
          getOptionLabel={option => option.name}
          getOptionValue={option => option.id}
          label={capitalize(I18n.get('priceList', 'lista de precio'))}
        />

        <PopoverComponent
          title={I18n.get(numerationBlocked?.name, 'Hay problemas con esta numeracion')}
          overlay={
            <div className='pr-5'>
              <p className='h5' style={{ fontWeight: '400', opacity: '0.7' }}>{I18n.get('', 'Elige otra numeración o solicita una nueva ante la DIAN para seguir facturando.')}</p>
              <button
                className='btn-sm-contrast-outline mt-4'
                onClick={() => window.open(getResolutionHelpUrl(values?.numeration, country), '_blank')}
              >
                {I18n.get('', 'Cómo solicitar otra')}
              </button>
            </div>
          }
          popoverActive={!!numerationBlocked && showNumerationBlockPopover}
          position='bottom'
          theme='dark'
          onRequestClose={() => setShowNumerationBlockPopover(false)}
        >
          <Field
            name="numeration"
            className="col-md-12 justify-self-center pt-1 px-2 m-0"
            component={renderSelect}
            options={groupedNumerations}
            getOptionLabel={option => option.name}
            getOptionValue={option => option.id}
            label={capitalize(I18n.get('numeration', 'Numeración'))}
            hasError={!!numerationBlocked}
          />
        </PopoverComponent>

        {!!isElectronic && country === 'colombia' && (
          <>
            <Field
              name="type"
              className="col-md-12"
              component={renderSelect}
              options={invoiceTypes}
              getOptionLabel={option => option.value}
              getOptionValue={option => option.key}
              label={capitalize(I18n.get('invoiceType', 'tipo de factura'))}
            />

            {get(values, 'type.key') === 'EXPORT' && (
              <Field
                name="deliveryTerm"
                className="col-md-12"
                component={renderSelect}
                options={deliveryTerms}
                getOptionLabel={option => option.value}
                getOptionValue={option => option.key}
                label={capitalize(I18n.get('deliveryTerm', 'condición de entrega'))}
              />
            )}


            <Field
              name="operationType"
              className="col-md-12"
              component={renderSelect}
              options={operationTypes}
              getOptionLabel={option => option.value}
              getOptionValue={option => option.key}
              label={capitalize(I18n.get('operationType', 'tipo de operación'))}
            />

            <Field
              name="paymentTerm"
              component={renderSelect}
              className="col-md-12"
              label={capitalize(I18n.get('paymentTerm', 'plazo de pago'))}
              options={!can('index', 'terms') ? [] : paymentTerms}
              selectInfo={!can('index', 'terms')
                ? I18n.get('userNotAllowed.terms.view', 'no tienes permisos para ver los plazos de pago')
                : null}
              hideNoOptionMessage={!can('index', 'terms')}
              getOptionLabel={option => option.name}
              getOptionValue={option => option.id}
            />
          </>
        )}

        {
          country === 'costaRica' && economicActivities.length !== 0 && (
            <Field
              name="economicActivity"
              className="col-md-12"
              component={renderSelectActivities}
              options={economicActivities}
              getOptionLabel={option => option.name}
              getOptionValue={option => option.id}
              label={capitalize(I18n.get('economicActivity', 'Actividad económica'))}
              defaultValue={economicActivity ? economicActivity : mainEconomicActivity}
            />
          )
        }

      </div>
    </div>
  )
}

export default InvoiceSettings;
import React from 'react';
import { I18n } from '@aws-amplify/core';
import { useSelector } from 'react-redux';

import {
  station as stationSelector,
  stationDebitBank,
  stationCreditBank,
  stationTransferBank,
  stationInvoiceNumeration,
  stationWarehouse,
  stationCostCenter,
} from '../../../selectors/app';
import { shiftsEnabled, isOnlyInvoicingPlan } from '../../../selectors/company';
import { isOpen } from '../../../selectors/shifts';
import Typography from '../../common/Typography';
import Tag from '../../common/Tag';
import MenuOption from '../../common/MenuOption';

import { Icon } from '@alegradev/smile-ui-react';
import { IconClipboardList } from '@tabler/icons-react';

const Station = () => {
  const isShiftsEnabled = useSelector(shiftsEnabled);
  const shiftOpen = useSelector(isOpen);
  const onlyInvoicingPlan = useSelector(isOnlyInvoicingPlan);
  const station = useSelector(stationSelector);
  const debitBank = useSelector(stationDebitBank);
  const creditBank = useSelector(stationCreditBank);
  const transferBank = useSelector(stationTransferBank);
  const numeration = useSelector(stationInvoiceNumeration);
  const warehouse = useSelector(stationWarehouse);
  const costCenter = useSelector(stationCostCenter);

  return (
    <MenuOption
      header={<Icon icon={IconClipboardList} />}
      id='station'
      extraClassName='mobile-right'
      eventName='Terminal Overview'
      tooltip={I18n.get('terminal_summary', 'Resumen de terminal')}
    >
      <div className='d-flex justify-content-between align-items-center p-4'>
        <Typography
          text={station.name}
          type='body-3-bold'
          variant='primary'
        />

        {!!isShiftsEnabled && !onlyInvoicingPlan && (
          <Tag
            variant={shiftOpen === true ? 'green' : 'rose'}
            text={
              shiftOpen === true
                ? I18n.get('shiftOpen', 'turno abierto')
                : I18n.get('shiftClosed', 'turno cerrado')
            }
          />
        )}
      </div>

      <div className='border-top p-3'>
        <div className='d-flex justify-content-between station-row px-2 py-1'>
          <Typography
            type='label-2'
            variant='secondary'
            text={I18n.get('debitBank', 'banco débito')}
          />
          <Typography
            type='body-3-regular'
            variant={!!debitBank ? 'secondary' : 'placeholder'}
            max={16}
            text={
              !!debitBank
                ? debitBank.name
                : I18n.get('notSelected', 'no seleccionado')
            }
          />
        </div>
        <div className='d-flex justify-content-between station-row px-2 py-1'>
          <Typography
            type='label-2'
            variant='secondary'
            text={I18n.get('creditBank', 'banco crédito')}
          />
          <Typography
            type='body-3-regular'
            variant={!!creditBank ? 'secondary' : 'placeholder'}
            max={16}
            text={
              !!creditBank
                ? creditBank.name
                : I18n.get('notSelected', 'no seleccionado')
            }
          />
        </div>
        <div className='d-flex justify-content-between station-row px-2 py-1'>
          <Typography
            type='label-2'
            variant='secondary'
            text={I18n.get('transferBank', 'banco transferencias')}
          />
          <Typography
            type='body-3-regular'
            variant={!!transferBank ? 'secondary' : 'placeholder'}
            max={16}
            text={
              !!transferBank
                ? transferBank.name
                : I18n.get('notSelected', 'no seleccionado')
            }
          />
        </div>
        <div className='d-flex justify-content-between station-row px-2 py-1'>
          <Typography
            type='label-2'
            variant='secondary'
            text={I18n.get('numeration', 'numeración')}
          />
          <Typography
            type='body-3-regular'
            variant={!!numeration ? 'secondary' : 'placeholder'}
            max={16}
            text={
              !!numeration
                ? numeration.name
                : I18n.get('notSelected', 'no seleccionado')
            }
          />
        </div>
        <div className='d-flex justify-content-between station-row px-2 py-1'>
          <Typography
            type='label-2'
            variant='secondary'
            text={I18n.get('warehouse', 'bodega')}
          />
          <Typography
            type='body-3-regular'
            variant={!!warehouse ? 'secondary' : 'placeholder'}
            max={16}
            text={
              !!warehouse
                ? warehouse.name
                : I18n.get('notSelected', 'no seleccionado')
            }
          />
        </div>
        <div className='d-flex justify-content-between station-row px-2 py-1'>
          <Typography
            type='label-2'
            variant='secondary'
            text={I18n.get('costCenter', 'centro de costo')}
          />
          <Typography
            type='body-3-regular'
            variant={!!costCenter ? 'secondary' : 'placeholder'}
            max={16}
            text={
              !!costCenter
                ? costCenter.name
                : I18n.get('notSelected', 'no seleccionado')
            }
          />
        </div>
      </div>
    </MenuOption>
  );
};

export default Station;

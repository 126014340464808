import React from 'react';
import { toast } from 'react-toastify';

import Toast from '../components/common/Toast';
import { Icon } from '@alegradev/smile-ui-react';
import { IconX } from '@tabler/icons-react';

function toastHandler(
  { title, description, type, update, loader = false },
  params
) {
  const baseToast = (
    <Toast title={title} description={description} type={type} closable />
  );
  const baseProps = {
    autoClose: type === 'error' || loader ? false : 9000,
    className: 'custom-toastify-container',
    closeButton: !loader ? (
      <div className='custom-toastify-container-close-icon'>
        <Icon
          icon={IconX}
          className={`${type === 'neutral' ? 'icon-white' : ''}`}
        />
      </div>
    ) : (
      <></>
    ),
    position: window.innerWidth <= 600 ? 'bottom-center' : 'top-right',
    toastId: params?.toastId,
  };

  if (update) {
    toast.update(params?.toastId, {
      render: type === 'default' ? <></> : baseToast,
      autoClose: type === 'error' ? false : type === 'default' ? 100 : 9000,
      ...baseProps,
    });
    return;
  }

  toast.success(baseToast, baseProps);
}

export default toastHandler;

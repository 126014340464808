import React, { useEffect, useState } from 'react';
import { Field } from 'react-final-form';
import { get, capitalize } from 'lodash';
import { I18n } from '@aws-amplify/core';

import countries from '../../../countriesData/general/countries';
import colombiaAddresses from '../../../countriesData/colombia/citiesDepartments.json';
import costaRicaAddresses from '../../../countriesData/costaRica/districtsCantonsProvinces.json';
import neighborhoods from '../../../countriesData/costaRica/dataNeighborhood';
import argentinaProvinces from '../../../countriesData/argentina/provinces';
import argentinaCities from '../../../countriesData/argentina/cities.json';
import peruAddresses from '../../../countriesData/peru/address.json';
import dominicanaAddresses from '../../../countriesData/republicaDominicana/municipalityProvince.json';
import panamaAddresses from '../../../countriesData/panama/cities.json';
import { renderField, renderSelect } from '../../fields/V0/Fields';
import { useSelector } from 'react-redux';
import { companySelector, idCompanySelector } from '../../../../selectors/company';
import { fetchDistricts, fetchNeighborhoods } from './utils';
const colombiaAddress = props => {
  const identification = get(props, 'values.identification.type.key', '');
  const colombianContact = get(props, 'values.address.country.key', '') === 'COL';
  const foreignId = identification === 'DIE' || identification === 'PP' || identification === 'TE' || identification === 'FOREIGN_NIT';
  const showCountry = !!identification && foreignId;
  const showCombinedAddress = !foreignId || colombianContact;

  return (
    <>
      {showCountry && (
        <Field
          name="address.country"
          component={renderSelect}
          className="col-md-6"
          options={countries}
          label={capitalize(I18n.get('country', 'país'))}
          getOptionLabel={option => option.value}
          getOptionValue={option => option.key}
          isSearchable={true}
          required={props.isElectronic}
        />
      )}

      {showCombinedAddress && (
        <Field
          name="address.combined"
          component={renderSelect}
          className={showCountry ? 'col-md-6' : 'col-12'}
          options={colombiaAddresses}
          label={[
            capitalize(I18n.get('city', 'municipio')),
            capitalize(I18n.get('department', 'departamento')),
          ].join(' / ')}
          getOptionLabel={option => option.value}
          getOptionValue={option => option.key}
          isSearchable={true}
          required={props.isElectronic}
        />
      )}

      <Field
        name="address.zipCode"
        className="col-md-6"
        component={renderField}
        type="text"
        label={capitalize(I18n.get('zipCode', 'Código Postal'))}
      />
      <Field
        name="address.address"
        className={showCombinedAddress ? 'col-md-6' : 'col-12'}
        component={renderField}
        type="text"
        label={capitalize(I18n.get('address', 'Dirección'))}
        required={props.isElectronic}
      />
    </>
  )
}

const getLabelDistrict = (option) => {
  if (!option.parentValue) return option.value;
  return [get(option, 'value', ''), get(option, 'parentValue', ''), get(option, 'provinceValue', '')].join(', ');
}

const costaRicaAddress = props => {
  const company = useSelector(companySelector);
  const [costaRicaAddresses, setCostaRicaAddresses] = useState([]);
  const [neighborhoodsOptions, setNeighborhoodsOptions] = useState([]);
  const [loading, setLoading] = useState({ districts: false, neighborhoods: false });
  const identification = get(props, 'values.identification.type.key', '');
  const foreignId = identification === 'PE';

  const isElectronic = get(props, 'isElectronic', false);
  const idCompany = useSelector(idCompanySelector);

  const getOptions = async () => {
    try {
      setLoading({ neighborhoods: true, districts: true });
      const newOptions = await fetchDistricts({ idCompany });
      const defaultOption = newOptions.find(option => {
        return get(option, 'value', '') === get(company, 'address.district', '')
          && get(option, 'parentValue', '') === get(company, 'address.city', '')
          && get(option, 'provinceValue', '') === get(company, 'address.department', '');
      })

      if (!!defaultOption) {
        props.form.change('address.combined', defaultOption);
        const neighborhoods = getNeighborhoods(idCompany, defaultOption.id);
        setNeighborhoodsOptions(neighborhoods);
        props.form.change('address.neighborhood', {value: get(company, 'address.neighborhood', '')});
      }

      setCostaRicaAddresses(newOptions);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading({ neighborhoods: false, districts: false });
    }
  }

  const getNeighborhoods = async (idCompany, idDistrict) => {
    try {
      setLoading({ ...loading, neighborhoods: true });
      const newOptions = await fetchNeighborhoods({ idCompany, idDistrict });
      setNeighborhoodsOptions(newOptions);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading({ ...loading, neighborhoods: false });
    }
  }


  useEffect(() => {
    if (!idCompany)
      return;

    getOptions();
  }, [idCompany]);

  return (
    <>
      {!foreignId && (
        <Field
          name="address.combined"
          className="col-md-6"
          component={renderSelect}
          options={costaRicaAddresses}
          isLoading={loading.districts}
          label={[
            capitalize(I18n.get('district', 'distrito')),
            capitalize(I18n.get('canton', 'cantón')),
            capitalize(I18n.get('province', 'provincia')),
          ].join(' / ')}
          getOptionLabel={option => getLabelDistrict(option)}
          getOptionValue={option => option}
          isSearchable={true}
          onChange={(option) => {
            const idDistrict = get(option, 'id', '');
            getNeighborhoods(idCompany, idDistrict);
            props.form.change('address.neighborhood', '');
            return option;
          }}
          required={isElectronic}
        />
      )}

      {!foreignId && (
        <Field
          name="address.neighborhood"
          component={renderSelect}
          isLoading={loading.neighborhoods}
          className="col-md-6"
          options={neighborhoodsOptions}
          getOptionLabel={option => option.value}
          getOptionValue={option => option.value}
          label={capitalize(I18n.get('neighborhood', 'Barrio'))}
          isSearchable={true}
        // required
        />
      )}

      <Field
        name="address.address"
        className="col-md-6"
        component={renderField}
        type="text"
        label={capitalize(I18n.get('address', 'Dirección'))}
        required={isElectronic}
      />
    </>
  )
}

const argentinaAddress = props => {
  const provinceId = get(props, 'values.address.province.id', '');

  return (
    <>
      <Field
        name="address.province"
        className="col-md-6"
        component={renderSelect}
        options={argentinaProvinces}
        label={capitalize(I18n.get('province', 'Provincia'))}
        getOptionLabel={option => option.value}
        getOptionValue={option => option.value}
        required={props.isElectronic}
        isSearchable={true}
      />

      <Field
        name="address.city"
        className="col-md-6"
        component={renderSelect}
        options={argentinaCities.filter(i => i.idProvince === provinceId)}
        label={capitalize(I18n.get('city', 'Ciudad'))}
        getOptionLabel={option => option.value}
        getOptionValue={option => option.value}
        required={props.isElectronic}
        isSearchable={true}
      />

      <Field
        name="address.postalCode"
        className="col-md-6"
        component={renderField}
        type="text"
        label={capitalize(I18n.get('zipCode', 'Código postal'))}
      />

      <Field
        name="address.address"
        className="col-md-6"
        component={renderField}
        type="text"
        label={capitalize(I18n.get('address', 'Dirección'))}
        required={props.isElectronic}
      />
    </>
  )
}

const peruAddress = props => {
  const identification = get(props, 'values.identification.type.key', '');
  const peruContact = get(props, 'values.address.country.key', '') === 'PER';
  const foreignId = !(identification === 'RUC' || identification === 'DNI');
  const showCountry = !!identification && foreignId;
  const showCombinedAddress = !foreignId || peruContact;

  return (
    <>
      {showCountry && (
        <Field
          name="address.country"
          className="col-12"
          component={renderSelect}
          options={countries}
          label={capitalize(I18n.get('country', 'país'))}
          getOptionLabel={option => option.value}
          getOptionValue={option => option.key}
          isSearchable={true}
        />
      )}

      {showCombinedAddress && (
        <Field
          name="address.combined"
          className="col-12"
          component={renderSelect}
          options={peruAddresses}
          label={[
            capitalize(I18n.get('district', 'distrito')),
            capitalize(I18n.get('province', 'provincia')),
            capitalize(I18n.get('department', 'departamento')),
            capitalize(I18n.get('ubigeoCode', 'código ubigeo')),
          ].join(' / ')}
          getOptionLabel={option => option.caption}
          getOptionValue={option => option.caption}
          isSearchable={true}
        />
      )}
      {showCombinedAddress && (
        <Field
          name="address.urbanization"
          className="col-md-6"
          component={renderField}
          type="text"
          label={capitalize(I18n.get('urbanization', 'Urbanización'))}
        />
      )}

      <Field
        className={showCombinedAddress ? 'col-md-6' : 'col-12'}
        name="address.address"
        component={renderField}
        type="text"
        label={capitalize(I18n.get('address', 'Dirección'))}
      />
    </>
  )
}

const dominicanaAddress = props => (
  <>
    <Field
      name="address.combined"
      className="col-12"
      component={renderSelect}
      options={dominicanaAddresses}
      label={[
        capitalize(I18n.get('city', 'municipio')),
        capitalize(I18n.get('province', 'provincia')),
      ].join(' / ')}
      getOptionLabel={option => option.value}
      getOptionValue={option => option.key}
      isSearchable={true}
    />
    <Field
      name="address.description"
      className="col-12"
      component={renderField}
      type="text"
      label={I18n.get('address', 'Dirección')}
    />
  </>
)

const mexicoAddress = props => {
  return (
    <>
      <Field
        name="address.street"
        className="col-md-6"
        component={renderField}
        type="text"
        label={I18n.get('street', 'calle')}
      />
      <Field
        name="address.exteriorNumber"
        className="col-md-6"
        component={renderField}
        type="text"
        label={I18n.get('exteriorNumber', 'número exterior')}
      />
      <Field
        name="address.interiorNumber"
        className="col-md-6"
        component={renderField}
        type="text"
        label={I18n.get('interiorNumber', 'número interior')}
      />
      <Field
        name="address.colony"
        className="col-md-6"
        component={renderField}
        type="text"
        label={I18n.get('colony', 'colonia')}
      />
      <Field
        name="address.locality"
        className="col-md-6"
        component={renderField}
        type="text"
        label={I18n.get('locality', 'localidad')}
      />
      <Field
        name="address.municipality"
        className="col-md-6"
        component={renderField}
        type="text"
        label={I18n.get('municipality', 'municipio')}
      />
      <Field
        name="address.zipCode"
        className="col-md-6"
        component={renderField}
        type="text"
        label={I18n.get('zipCode', 'código postal')}
        required={props.isElectronic}
      />
      <Field
        name="address.state"
        className="col-md-6"
        component={renderField}
        type="text"
        label={I18n.get('state', 'estado')}
      />
    </>
  )
}

const panamaAddress = props => (
  <>
    <Field
      name="address.address"
      className="col-md-6"
      component={renderField}
      type="text"
      label={I18n.get('address', 'Dirección')}
      required
    />
    <Field
      name="address.combined"
      className="col-md-6"
      component={renderSelect}
      options={panamaAddresses}
      label={[
        capitalize(I18n.get('township', 'Corregimiento')),
        capitalize(I18n.get('district', 'distrito')),
        capitalize(I18n.get('province', 'provincia')),
      ].join(' / ')}
      getOptionLabel={option => `${option.value}, ${option.parentValue}, ${option.provinceValue}`}
      getOptionValue={option => option.code}
      isSearchable={true}
      required
    />
    {props.isElectronic &&
      <Field
        name="address.geographicCoordinates"
        className="col-md-6"
        component={renderField}
        type="text"
        label={I18n.get('geographicCoordinates', 'Ubicación geográfica')}
        placeholder="+X.XXXX,-XX.XXXX"
        required
      />
    }
  </>
)

const defaultAddress = props => (
  <>
    <Field
      name="address.city"
      className="col-md-6"
      component={renderField}
      type="text"
      label={capitalize(I18n.get('city', 'ciudad'))}
    />
    <Field
      name="address.address"
      className="col-md-6"
      component={renderField}
      type="text"
      label={capitalize(I18n.get('address', 'Dirección'))}
    />
  </>
)

const renderAddress = props => {
  if (props.country === 'costaRica')
    return costaRicaAddress(props);

  if (props.country === 'peru')
    return peruAddress(props);

  if (props.country === 'argentina')
    return argentinaAddress(props);

  if (props.country === 'colombia')
    return colombiaAddress(props);

  if (props.country === 'republicaDominicana')
    return dominicanaAddress(props);

  if (props.country === 'mexico')
    return mexicoAddress(props);

  if (props.country === 'panama')
    return panamaAddress(props);

  return defaultAddress(props);
}

export default renderAddress;
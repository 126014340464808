import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
} from '@reduxjs/toolkit';
import { graphqlOperation } from '@aws-amplify/api';
import { difference, get, set } from 'lodash';

import * as queries from '../graphql/queries';
import { handleError } from '../utils/errors';
import { APIGraphqlSelector } from '../selectors/app';
import { getInitialState } from '../utils';
import { paymentMethodsConverterFromApi } from '../components/settings/PaymentMethods/converters/paymentMethodToApi';
import * as paymentMethodsDB from '../database/paymentMethodsDB';

const initialState = {
  entities: {},
  ids: [],
  loading: 'idle',
  error: null,
};

export const fetchPaymentMethods = createAsyncThunk(
  'paymentMethods/fetch',
  async (params, { rejectWithValue, getState }) => {
    try {
      const parameters = {
        ...params,
        batch: {
          start: 0,
          limit: 1000
        },
        includeMetadata: false,
      };
      const APIGraphql = APIGraphqlSelector(getState());
      const data = await APIGraphql(
        graphqlOperation(queries.allSettingsPaymentMethods, {
          ...parameters,
        })
      );
      const paymentMethods = get(data, 'data.allPaymentMethodss.data', null);
      if (paymentMethods) {
        const paymentMethodsConverted =
          paymentMethodsConverterFromApi(paymentMethods);
        paymentMethodsDB.bulkPut(paymentMethodsConverted);
        return paymentMethodsConverted;
      }

      return [];
    } catch (error) {
      return rejectWithValue(handleError(error));
    }
  }
);

export const syncPaymentMethods = createAsyncThunk(
  'paymentMethods/sync',
  async (params, { dispatch, getState }) => {
    try {
      const parameters = {
        ...params,
        batch: {
          start: 0,
          limit: 1000
        },
        includeMetadata: false,
      };

      const APIGraphql = APIGraphqlSelector(getState());
      const data = await APIGraphql(
        graphqlOperation(queries.allSettingsPaymentMethods, {
          ...parameters,
        })
      );
      let localData = await paymentMethodsDB.getAll();
      const paymentMethods = get(data, 'data.allPaymentMethodss.data', []);
      const paymentMethodsConverted =
        paymentMethodsConverterFromApi(paymentMethods);
      data = paymentMethodsConverted.map((paymentMethod) =>
        get(paymentMethod, 'id')
      );
      localData = localData.map((paymentMethod) => get(paymentMethod, 'id'));

      await paymentMethodsDB.bulkDelete(difference(localData, data));

      dispatch(refresh());
    } catch {}
  }
);

export const changePaymentMethodStatus = createAsyncThunk(
  'paymentMethods/changePaymentMethodStatus',
  async ({ id, status }, { dispatch }) => {
    try {
      let paymentMethod = await paymentMethodsDB.getPaymentMethod(id);

      if (!!paymentMethod) {
        set(paymentMethod, 'status', status);
        await paymentMethodsDB.put(paymentMethod);
        dispatch(refresh());
      }
    } catch (error) {
      console.log(error);
    }
  }
);

export const changePaymentMethodDetails = createAsyncThunk(
  'paymentMethods/changePaymentMethodDetails',
  async ({ id, status, name, instructions, logo, qr }, { dispatch }) => {
    try {
      let paymentMethod = await paymentMethodsDB.getPaymentMethod(id);

      if (!!paymentMethod) {
        paymentMethod.status = status;
        paymentMethod.name = name;
        paymentMethod.instructions = instructions;

        paymentMethod.logo = {
          id: logo && logo.id !== undefined ? logo.id : null,
          url: logo && logo.url !== undefined ? logo.url : null,
          is_private:
            logo && logo.is_private !== undefined ? logo.is_private : null,
        };

        paymentMethod.qr = {
          id: qr && qr.id !== undefined ? qr.id : null,
          url: qr && qr.url !== undefined ? qr.url : null,
          is_private: qr && qr.is_private !== undefined ? qr.is_private : null,
        };

        await paymentMethodsDB.put(paymentMethod);
        dispatch(refresh());
      }
    } catch (error) {
      console.log(error);
    }
  }
);

export const addPaymentMethod = createAsyncThunk(
  'paymentMethods/createPaymentMethod',
  async (paymentMethod, { dispatch }) => {
    try {
      await paymentMethodsDB.put(paymentMethod);
      dispatch(refresh());
    } catch (error) {
      console.log(error);
    }
  }
);

export const refresh = createAsyncThunk(
  'paymentMethods/refresh',
  async (_, { rejectWithValue, getState }) => {
    try {
      const data = await paymentMethodsDB.getAll();
      return { data };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const adapter = createEntityAdapter();

const appSlice = createSlice({
  name: 'paymentMethods',
  initialState: getInitialState('paymentMethods', initialState),
  reducers: {
    createPaymentMethod: adapter.addOne,
  },
  extraReducers: (builder) => {
    builder.addCase(fetchPaymentMethods.fulfilled, (state, action) => {
      adapter.setAll(state, action.payload);
      state.loading = 'idle';
      state.error = null;
    });
    builder.addCase(fetchPaymentMethods.pending, (state) => {
      state.loading = 'loading';
      state.error = null;
    });
    builder.addCase(fetchPaymentMethods.rejected, (state, action) => {
      state.loading = 'idle';
      state.error = action.payload;
    });
    builder.addCase(refresh.fulfilled, (state, action) => {
      adapter.setAll(state, action.payload.data);
    });
  },
});

const { reducer, actions } = appSlice;

export const paymentMethodsSelector = adapter.getSelectors(
  (state) => state.paymentMethods
);

export const { createPaymentMethod } = actions;
export default reducer;

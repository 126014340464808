import React, { useState } from 'react'
import { I18n } from '@aws-amplify/core';
import { useDispatch, useSelector } from 'react-redux';
import { capitalize, get } from 'lodash'

import { openSideModal } from '../../../reducers/sideModals';
import { setTypeToPrint } from '../../../reducers/print';
import { station as stationSelector, electronicInvoicing, stationInvoiceNumeration } from '../../../selectors/app';
import { isOnlyInvoicingPlan, country as countrySelector, multicurrency as isMulticurrencyEnabled } from '../../../selectors/company';
import { numeration as invoiceNumerationSelector } from '../../../selectors/activeInvoice'
import ActiveInvoiceSettings from './ActiveInvoiceSettings';
import InvoiceSettings from './InvoiceSettings';
import SmallDevicesSettings from './SmallDevicesSettings';
import Typography from '../../common/Typography';
import { COUNTRIES } from '../../../utils/enums/countries';

import { Icon, Tooltip } from '@alegradev/smile-ui-react';
import { IconAdjustmentsHorizontal, IconCash, IconDotsVertical, IconPrinter } from '@tabler/icons-react';

const invoiceTitle = (country, isElectronic, numeration) => {
  const { prefix, documentType } = numeration;

  let title = I18n.get('invoiceOfSale', 'factura de venta');
  if (['peru', 'costaRica', 'republicaDominicana', 'colombia', 'argentina', 'panama'].includes(country)) {
    if (isElectronic) {
      if (documentType === 'invoice')
        title = I18n.get('electronicSellInvoice', 'factura de venta electrónica')
      if (documentType === 'saleTicket')
        title = I18n.get('electronicTicket', 'boleta electrónica')
    } else {
      if (documentType === 'invoice')
        title = I18n.get('invoiceOfSale', 'factura de venta')
      if (documentType === 'saleTicket')
        title = country === 'colombia' ? I18n.get('posDocument', 'Documento POS') : I18n.get('saleTicket', 'boleta de venta')
    }
  }

  if(country === 'spain'){
    if(documentType === 'saleTicket'){
      title = I18n.get('invoiceOfSale', 'Ticket de venta');
    }
    if(documentType === 'invoice'){
      title = I18n.get('ordinaryInvoice', 'Factura ordinaria')
    }
  }

  if(country === COUNTRIES.MEXICO) {
    if(documentType === 'invoice') {
      title = I18n.get('invoiceOfSale.new', 'Factura de venta');
    } else {
      title = I18n.get('saleTicket.new', 'Ticket de venta');
    }
  }

  if (prefix === 'EPOS' || !!get(numeration, 'linkedDevices', null)) {
    title = I18n.get('posDocument', 'Documento POS');
    if (isElectronic)
      title = I18n.get('electronicPOSDocument', 'Documento POS electrónico');
  }

  return title
}

const Header = () => {
  const [openSettings, setOpenSettings] = useState(false)
  const [openInvoiceSettings, setOpenInvoiceSettings] = useState(false);
  const [openSmallDevicesSettings, setOpenSmallDevicesSettings] = useState(false);
  const dispatch = useDispatch();
  const station = useSelector(stationSelector)
  const country = useSelector(countrySelector)
  const invoiceNumeration = useSelector(invoiceNumerationSelector)
  const mainNumeration = useSelector(stationInvoiceNumeration)
  const numeration = !!invoiceNumeration ? invoiceNumeration : mainNumeration;
  const isElectronic = useSelector(electronicInvoicing(numeration))
  const multicurrency = useSelector(isMulticurrencyEnabled)
  const onlyInvoicingPlan = useSelector(isOnlyInvoicingPlan)
  const pendingInvoicesEnabled = get(station, 'pendingInvoicesEnabled', false);

  return (
    <>
      <div className="invoice-top__header d-flex w-100 pl-3 align-items-center justify-content-between">
        <div className='py-4 pl-3'>
          <Typography type="body-1-bold" variant="secondary" text={invoiceTitle(country, isElectronic, { ...numeration })} />
          </div>

        <div className="invoice-top__header-actions-large justify-content-between align-items-center pr-2">
          {!!pendingInvoicesEnabled && !onlyInvoicingPlan && (
            <Tooltip
              placement="bottom"
              overlay={capitalize(I18n.get('print', 'imprimir'))}>
              <button
                type="button"
                className="invoice-top__header-buttons btn button-transparent mx-1"
                onClick={() => dispatch(setTypeToPrint('preInvoice'))}
              >
                <Icon icon={IconPrinter} extraClass="icon-gray" />
              </button>
            </Tooltip>
          )}

          {!!multicurrency && (
            <Tooltip
              placement="bottomRight"
              overlay={capitalize(I18n.get('currenciesSettings', 'configuración de monedas'))}>
              <button
                type="button"
                className="invoice-top__header-buttons btn button-transparent mx-1"
                onClick={() => setOpenSettings(true)}
              >
                <Icon icon={IconCash} extraClass="icon-gray" />
              </button>
            </Tooltip>
          )}

          {country === 'costaRica' && (
            <Tooltip
              placement="bottomRight"
              overlay={capitalize(I18n.get('invoiceSettings', 'configuración de venta'))}>

              <button
                type="button"
                className="invoice-top__header-buttons btn button-transparent mx-1"
                onClick={() => setOpenInvoiceSettings(!openInvoiceSettings)}
              >
                <Icon icon={IconAdjustmentsHorizontal} extraClass="icon-gray" />
              </button>
            </Tooltip>
          )}

          {!['costaRica'].includes(country) && (
            <Tooltip
              placement="bottomRight"
              overlay={capitalize(I18n.get('invoiceSettings', 'configuración de venta'))}>

              <button
                type="button"
                className="invoice-top__header-buttons btn button-transparent mx-1"
                onClick={() => dispatch(openSideModal({ sideModal: 'invoiceSettings' }))}
              >
                <Icon icon={IconAdjustmentsHorizontal} extraClass="icon-gray" />
              </button>
            </Tooltip>
          )}
        </div>

        <div className="invoice-top__header-actions-small pointer"
          onClick={() => setOpenSmallDevicesSettings(!openSmallDevicesSettings)}>
          <Icon icon={IconDotsVertical} />
        </div>
      </div>

      {openInvoiceSettings && (
        <InvoiceSettings />
      )}

      {openSettings && (
        <ActiveInvoiceSettings
          onClose={() => setOpenSettings(false)}
        />
      )}

      {openSmallDevicesSettings && (
        <SmallDevicesSettings
          printAction={() => dispatch(setTypeToPrint('preInvoice'))}
          currencyAction={() => setOpenSettings(true)}
          settingsAction={() => dispatch(openSideModal({ sideModal: 'invoiceSettings' }))}
          onClose={() => openSmallDevicesSettings(false)} />
      )}
    </>
  )
}

export default Header;
import { I18n, Hub } from '@aws-amplify/core'
import { FORM_ERROR } from 'final-form'
import { get, capitalize, isString, toUpper, isEmpty } from 'lodash'

const trackingErrorLog = (errorMessage) => {

  const errorsToFilter = [
    'Cannot add version when database is open',
    'Request failed with status code 404',
    'Subscription not found',
    'Revisa tu conexión a internet',
    'Verifica tu conexión e intente de nuevo',
    'Se debe habilitar la funcionalidad de multimoneda',
    'Esta acción no se puede realizar en tu plan actual',
    'Verifica tu conexión e intente de nuevo',
    'Forbidden',
    'Too Many request',
    I18n.get('imageTooLargeError', 'Ocurrió un error al recibir el archivo, verifica que el tamaño de la imagen sea menor que 2MB'),
  ]

  if (errorsToFilter.some(str => errorMessage.includes(str)) || isEmpty(errorMessage))
    return;

  // console.log("Error tracking:")
  // console.log(errorMessage)
}


/**
 * Handles different types of errors and returns a user-friendly message.
 *
 * @param {any} error - The error object or message.
 * @param {Object} [options] - Additional options for error handling.
 * @param {string} [options.defaultMessage=''] - The default message to use if no specific message is found.
 * @param {string} [options.country] - The country code to customize error messages.
 * @param {extraInfo} [options.extraInfo={}] - Additional information about the error.
 * @returns {string} The processed error message.
 */
export const handleError = (error, options = {}) => {
  const { defaultMessage = '', country, extraInfo = {} } = options;
  let message = defaultMessage;

  try {
    const strategies = [
      handleLambdaError,
      handleGeneralError,
      handleNetworkError,
      handleTimeoutError,
      handleStringError,
    ];

    for (const strategy of strategies) {
      const result = strategy(error, country, extraInfo);
      if (result) {
        message = result;
        break;
      }
    }
  } catch (err) {
    console.error("Error in handleError:", err);
  }

  trackingErrorLog(message, extraInfo);
  return message;
};

/**
 * Handles the 'createInvoice' lambda error.
 *
 * @param {Object} lambdaError - The lambda error object.
 * @param {Object} extraInfo - Additional error information.
 * @returns {string} The processed error message.
 */
const handleCreateInvoiceError = (lambdaError, extraInfo) => {
  const { invoice } = extraInfo;
  const isElectronic = invoice?.stamp?.generateStamp;

  if (isElectronic) {
    return I18n.get(
      "lambdaError.electronicInvoicing",
      "La emisión de ventas tiene intermitencia y trabajamos para solucionarla. Por favor, inténtalo de nuevo en unos minutos."
    );
  }

  return I18n.get(
    "lambdaError.noElectronicInvoicing",
    "Estamos resolviendo una intermitencia temporal. Por favor, intenta sincronizar esta venta en unos minutos."
  );
};

/**
 * Handles the 'openShift' lambda error.
 *
 * @returns {string} The processed error message.
 */
const handleOpenShiftError = () => {
  return I18n.get(
    "lambdaError.openShift",
    "La apertura de turno tiene intermitencia y trabajamos para solucionarla. Por favor, inténtalo de nuevo en unos minutos."
  );
};

/**
 * Handles the 'closeShift' lambda error.
 *
 * @returns {string} The processed error message.
 */
const handleCloseShiftError = () => {
  return I18n.get(
    "lambdaError.closeShift",
    "Identificamos una falla que impide cerrar turnos y trabajamos para solucionarla lo antes posible. Por favor, inténtalo de nuevo en unos minutos."
  );
};

/**
 * Handles generic lambda errors.
 *
 * @returns {string} The processed error message.
 */
const handleGenericLambdaError = () => {
  return I18n.get(
    "lambdaError.generic",
    "Funcionalidad con intermitencias. Estamos trabajando para solucionarlo lo antes posible. Por favor, inténtalo de nuevo en unos minutos."
  );
};

/**
 * Maps lambda error paths to their respective handler functions.
 */
const lambdaErrorHandlers = {
  createInvoice: handleCreateInvoiceError,
  openShift: handleOpenShiftError,
  closeShift: handleCloseShiftError,
};

/**
 * Handles errors from Lambda functions.
 *
 * @param {any} error - The error object.
 * @param {string} country - The country code.
 * @param {Object} extraInfo - Additional error information.
 * @returns {string|null} The processed message or null if not applicable.
 */
const handleLambdaError = (error, country, extraInfo) => {
  const lambdaError = error?.errors?.[0];
  if (!lambdaError) {
    return null;
  }

  if (lambdaError.errorType !== 'Lambda:IllegalArgument') {
    return lambdaError.message;
  }

  const errorPath = lambdaError.path?.[0];
  const handler = lambdaErrorHandlers[errorPath] || handleGenericLambdaError;

  return handler(lambdaError, extraInfo);
};

/**
 * Handles general errors.
 *
 * @param {any} error - The error object.
 * @returns {string|null} The processed message or null if not applicable.
 */
const handleGeneralError = (error) => {
  if (error?.message) {
    return error.message;
  }

  if (error?.request?.responseText) {
    try {
      const parsedResponse = JSON.parse(error.request.responseText);
      return parsedResponse.message;
    } catch (parseError) {
      console.error("Failed to parse responseText:", parseError);
    }
  }

  if (error?.error?.message) {
    return error.error.message;
  }

  return null;
};

/**
 * Handles network-related errors.
 *
 * @param {any} error - The error object.
 * @param {string} country - The country code.
 * @param {extraInfo} extraInfo - Additional error information.
 * @returns {string|null} The processed message or null if not applicable.
 */
const handleNetworkError = (error, country, extraInfo) => {
  const message = error?.message;
  if (message === 'Network Error') {
    Hub.dispatch('network', {
      event: 'status',
      data: { status: 'offline' },
      message: ''
    });
    return capitalize(
      I18n.get('networkErrorMessage', 'Verifica tu conexión e intenta de nuevo')
    );
  }
  return null;
};

/**
 * Handles timeout-related errors.
 *
 * @param {any} error - The error object.
 * @param {string} country - The country code.
 * @param {extraInfo} extraInfo - Additional error information.
 * @returns {string|null} The processed message or null if not applicable.
 */
const handleTimeoutError = (error, country, extraInfo) => {
  const message = error?.message;
  if (
    message &&
    (message.toUpperCase().includes('TIMEOUT') ||
      message.toUpperCase().includes('TIMED OUT') ||
      error?.errors?.[0]?.errorType === 'Runtime.ExitError')
  ) {
    return capitalize(
      I18n.get('runtimeErrorMessage', 'Por favor, intente de nuevo')
    );
  }
  return null;
};

/**
 * Handles errors that are plain strings.
 *
 * @param {any} error - The error object or message.
 * @param {string} country - The country code.
 * @param {extraInfo} extraInfo - Additional error information.
 * @returns {string|null} The processed message or null if not applicable.
 */
const handleStringError = (error, country, extraInfo) => {
  if (typeof error === 'string') {
    let message = error;
    if (
      message === 'Ya existe una factura con el número ingresado' &&
      country === 'republicaDominicana'
    ) {
      message = I18n.get(
        'invoiceNumberError',
        "Ya hay una factura con el número de este comprobante, conoce cómo <a href='https://app.powerbi.com/view?r=eyJrIjoiZDdmNTBlYTktYmE0Ni00NGM3LWJhNzYtMGZhNjBlNTQyYTdmIiwidCI6IjYxOGQwYTQ1LTI1YTYtNDYxOC05ZjgwLThmNzBhNDM1ZWU1MiJ9' target='_blank' rel='noreferrer'><b>editar la numeración actual</b></a> o selecciona otra antes de vender"
      );
    }
    return message;
  }
  return null;
};

export const formError = (error, defaultMessage = '', country) => {
  return ({ [FORM_ERROR]: handleError(error, { defaultMessage, country }) })
};
import React from 'react'
import { I18n } from '@aws-amplify/core';
import { useSelector, useDispatch } from 'react-redux';
import { BigNumber } from 'bignumber.js';
import dayjs from 'dayjs';
import { get } from 'lodash'

import {
  timezone as tzSelector,
  dateFormat as dateFormatSelector,
  country as countrySelector,
} from '../../selectors/company';
import { setTypeToPrint } from '../../reducers/print';
import NewShiftDetail from './NewShiftDetail';
import { countriesFirstGroupNewDesign } from '../navigation/Header';

import { Icon } from '@alegradev/smile-ui-react';
import { useFormat } from '../../hooks/useFormat';
import { IconList, IconPrinter } from '@tabler/icons-react';

const ShiftDetail = ({ shift, onShowList }) => {
  const dispatch = useDispatch()
  const timezone = useSelector(tzSelector);
  const dateFormat = useSelector(dateFormatSelector);
  const country = useSelector(countrySelector);
  const { decimal, fmt } = useFormat();

  if (!shift) return null;
  if (!!shift && ((dayjs(get(shift, 'start')).isAfter('2023-04-20') && countriesFirstGroupNewDesign.includes(country)) ||
    (dayjs(get(shift, 'start')).isAfter('2023-05-30') && !countriesFirstGroupNewDesign.includes(country))))
    return <NewShiftDetail shift={shift} onShowList={onShowList} />

  const start = shift.start;
  const end = shift.end;
  const base = new BigNumber(shift.startingAmount);
  const cash = new BigNumber(shift.cash);
  const debit = new BigNumber(shift.debit);
  const credit = new BigNumber(shift.credit);
  const transfer = new BigNumber(shift.transfer);
  const refunds = new BigNumber(shift.refunds);
  const inPayment = new BigNumber(shift.inPayment);
  const outPayment = new BigNumber(shift.outPayment);
  const totalsTipCash = new BigNumber(shift.tipsTotalCash);
  const totalsTipDebit = new BigNumber(shift.tipsTotalDebit);
  const totalsTipCredit = new BigNumber(shift.tipsTotalCredit);
  const totalsTipTransfer = new BigNumber(shift.tipsTotalTransfer);

  const totalsTipOther = totalsTipDebit.plus(totalsTipCredit).plus(totalsTipTransfer);

  const total = new BigNumber(base)
    .plus(cash)
    .plus(debit)
    .plus(credit)
    .plus(transfer)
    .plus(inPayment)
    .plus(totalsTipCash)
    .plus(totalsTipOther)
    .minus(refunds)
    .minus(outPayment)

  return (
    <div className="shifts__detail h-100 d-flex flex-column">

      <div className="shifts__detail-header mb-4 d-flex justify-content-between align-items-center">
        <div className="d-flex align-items-center">
          <h1 className="text-capitalize-first text-muted">
            {`${I18n.get('closeNo', 'Cierre No')} ${shift.idLocal}`}
          </h1>

          <span className="badge badge-danger badge-pill text-uppercase mx-2 mb-2 pt-2 px-3">
            {I18n.get('closed', 'Cerrado')}
          </span>
        </div>

        <div className="shifts__detail-header-actions d-flex justify-content-end align-items-center">
          <button
            type="button"
            className="btn btn-submit text-nowrap"
            onClick={() => dispatch(setTypeToPrint('shift'))}
          >
            <Icon icon={IconPrinter} extraClass="icon-white" />
            <div className="d-none d-sm-inline text-capitalize">{I18n.get('print', 'imprimir')}</div>
          </button>

          <button
            type="button"
            className="btn btn-submit d-block d-sm-none"
            onClick={() => onShowList()}
          >
            <Icon icon={IconList} extraClass="icon-white" />
          </button>
        </div>
      </div>

      <div className="shifts__detail-body card">

        <div className="d-flex form-row m-0 p-4 border-bottom">
          <div className="col-4 py-3">
            <div className="d-flex flex-column">
              <strong className="text-capitalize-first h3">
                {I18n.get('startDate', 'fecha de inicio')}
              </strong>
              <div className="h4">
                {!!start ? dayjs.tz(start, timezone).format(!!dateFormat ? `${dateFormat.toUpperCase()} h:mm a` : I18n.get('dateTimeFormat', 'DD/MM/YYYY h:mm a')) : null}
              </div>
            </div>
          </div>
          <div className="col-4 py-3">
            <div className="d-flex flex-column">
              <strong className="text-capitalize-first h3">
                {I18n.get('endDate', 'fecha de cierre')}
              </strong>
              <div className="h4">
                {!!end ? dayjs.tz(end, timezone).format(!!dateFormat ? `${dateFormat.toUpperCase()} h:mm a` : I18n.get('dateTimeFormat', 'DD/MM/YYYY h:mm a')) : null}
              </div>
            </div>
          </div>
          <div className="col-4 py-3">
            <div className="d-flex flex-column">
              <strong className="text-capitalize-first h3">
                {I18n.get('base', 'base inicial')}
              </strong>
              <div className="h4">{new BigNumber(base).toFormat(decimal, fmt)}</div>
            </div>
          </div>
        </div>

        <div className="p-4 d-flex flex-column">

          <div className="d-flex align-items-center justify-content-between border-bottom my-2">
            <strong className="text-capitalize-first h4">{I18n.get('base', 'base inicial')}</strong>
            <div className="h4">{new BigNumber(base).toFormat(decimal, fmt)}</div>
          </div>

          <div className="d-flex align-items-center justify-content-between border-bottom my-2">
            <strong className="text-capitalize-first h4">{I18n.get('cashSales', 'ventas en efectivo')}</strong>
            <div className="h4">{cash.toFormat(decimal, fmt)}</div>
          </div>

          <div className="d-flex align-items-center justify-content-between border-bottom my-2">
            <strong className="text-capitalize-first h4">{I18n.get('debitSales', 'ventas por tarjeta de débito')}</strong>
            <div className="h4">{debit.toFormat(decimal, fmt)}</div>
          </div>

          <div className="d-flex align-items-center justify-content-between border-bottom my-2">
            <strong className="text-capitalize-first h4">{I18n.get('creditSales', 'ventas por tarjeta de crédito')}</strong>
            <div className="h4">{credit.toFormat(decimal, fmt)}</div>
          </div>

          <div className="d-flex align-items-center justify-content-between border-bottom my-2">
            <strong className="text-capitalize-first h4">{I18n.get('transferSales', 'ventas por transferencias')}</strong>
            <div className="h4">{transfer.toFormat(decimal, fmt)}</div>
          </div>

          <div className="d-flex align-items-center justify-content-between border-bottom my-2">
            <strong className="text-capitalize-first h4">{I18n.get('refundCash', 'Devolución de dinero')}</strong>
            <div className="h4 text-danger">{refunds.toFormat(decimal, fmt)}</div>
          </div>

          <div className="d-flex align-items-center justify-content-between border-bottom my-2">
            <strong className="text-capitalize-first h4">{I18n.get('invoiceCash', 'ingresos de efectivo')}</strong>
            <div className="h4">{inPayment.toFormat(decimal, fmt)}</div>
          </div>

          {(totalsTipCash.isGreaterThan(0) || totalsTipOther.isGreaterThan(0)) &&
            <>
              <div className="d-flex align-items-center justify-content-between border-bottom my-2">
                <strong className="text-capitalize-first h4">{I18n.get('shift.tipsCash', 'Propinas en efectivo')}</strong>
                <div className="h4">{totalsTipCash.toFormat(decimal, fmt)}</div>
              </div>

              <div className="d-flex align-items-center justify-content-between border-bottom my-2">
                <strong className="text-capitalize-first h4">{I18n.get('shift.tipsOther', 'Propinas en otros medios')}</strong>
                <div className="h4">{totalsTipOther.toFormat(decimal, fmt)}</div>
              </div>
            </>
          }
          <div className="d-flex align-items-center justify-content-between border-bottom my-2">
            <strong className="text-capitalize-first h4">{I18n.get('expenseCash', 'retiros de efectivo')}</strong>
            <div className="h4 text-danger">{outPayment.toFormat(decimal, fmt)}</div>
          </div>

          {!!get(shift, 'currencies', null) && (
            Object.keys(shift.currencies).map((key, index) => (
              <div key={index} className="d-flex align-items-center justify-content-between">
                <strong className="text-capitalize-first h2">{`${I18n.get('total', 'total')} ${key}`}</strong>
                <div className="h3">
                  {new BigNumber(get(shift, `currencies.${key}.total`)).toFormat(decimal, {
                    prefix: get(shift, `currencies.${key}.symbol`),
                    decimalSeparator,
                    groupSeparator: decimalSeparator === "," ? "." : ",",
                    groupSize: 3,
                  })}
                </div>
              </div>
            ))
          )}

          <div className="d-flex align-items-center justify-content-between">
            <strong className="text-capitalize-first h2">{I18n.get('total', 'total')}</strong>
            <div className="h3">{total.toFormat(decimal, fmt)}</div>
          </div>

        </div>

        <div className="d-flex align-items-center justify-content-between pl-4 pt-4">
          <strong className="text-capitalize-first h4">{I18n.get('observations', 'observaciones')}:</strong>
        </div>
        <p className="h4 pl-4">{!!shift.observations
          ? shift.observations
          : "-"}</p>
      </div>
    </div>
  )
}

export default ShiftDetail;
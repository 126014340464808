import { useDispatch, useSelector } from 'react-redux';
import parse from 'html-react-parser';

import { get } from 'lodash';
import { closeModal } from '../../../reducers/modals';

import Modal from '../../common/Modal';
import Loader from '../../common/Loader';

function LoaderModal() {
  const dispatch = useDispatch();
  const isOpen = useSelector((state) =>
    get(state, 'modals.loader.isOpen', false)
  );
  const params = useSelector((state) =>
    get(state, 'modals.loader.params', false)
  );

  const toggleCloseModal = () => {
    if (params?.loading) return;

    dispatch(closeModal({ modal: 'loader' }));
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={() => toggleCloseModal()}
      includeHeader={false}
      className='modal__mobile-bottom modal__rounded'
    >
      <div className='confirm-action-modal'>
        <div className='confirm-action-modal-body'>
          <Loader title={`${parse(get(params, 'title', ''))}`} />
        </div>
      </div>
    </Modal>
  );
}

export default LoaderModal;

import React from 'react';
import { I18n } from '@aws-amplify/core';
import { Field } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import { useSelector } from 'react-redux';
import { get } from 'lodash';

import { calculateSingleItemValues, calculateSingleItemValuesWithDecimals, validateSelectTaxes } from '../../../utils';
import { multitax, decimalPrecision, country as countrySelector, companySelector, decimalSeparator as decimalSeparatorSelector } from '../../../selectors/company';
import { activeTaxes } from '../../../selectors/taxes';
import { hasPermissionTo } from '../../../selectors/auth';
import { filter } from '../../../database/itemsDB';
import { renderSelect, renderField } from '../../forms/fields/V0/Fields';
import { getFmt } from '../utils';
import { Tooltip } from '@alegradev/smile-ui-react';

import { Icon } from '@alegradev/smile-ui-react';
import { getTaxesLabel } from '../../forms/itemSimplified/utils';
import { COUNTRIES } from '../../../utils/enums/countries';
import useDecimalsVersionsGroup from '../../../hooks/useDecimalsVersionsGroup/hook';
import { IconPlus, IconTrash } from '@tabler/icons-react';

const Items = ({ invoice, form, values, updateItemPrice }) => {
  const decimal = useSelector(decimalPrecision);
  const taxes = useSelector(activeTaxes);
  const isMultitax = useSelector(multitax);
  const can = useSelector(hasPermissionTo);
  const country = useSelector(countrySelector);
  const company = useSelector(companySelector);
  const decimalSeparator = useSelector(decimalSeparatorSelector);

  const fmt = getFmt(null, values.currency, decimalSeparator);
  const { isDecimalActive } = useDecimalsVersionsGroup();

  // const handleEditItemPriceTooltipLabel = () => {
  //   const documentType = get(
  //     values,
  //     'numberTemplate.documentType',
  //     'saleTicket'
  //   );
  //   if (documentType === 'saleTicket') {
  //     return 'userNotAllowed.invoices.saleTicket.edit-items-prices';
  //   }
  //   console.log('values', values);
  //   return 'userNotAllowed.invoices.invoice.edit-items-prices';
  // };

  return (
    <>
      <div className='detail-items-table-wrapper mb-3'>
        <table className='detail-items-table rounded-lg'>
          <thead>
            <tr>
              <th>{I18n.get('item', 'producto')}</th>
              <th>{I18n.get('reference', 'referencia')}</th>
              <th>{I18n.get('price', 'precio')}</th>
              <th>{I18n.get('discount', 'descuento')}</th>
              <th>{I18n.get('taxes', 'impuestos')}</th>
              <th>{I18n.get('quantity', 'cantidad')}</th>
              <th>{I18n.get('total', 'total')}</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <FieldArray name='items'>
              {({ fields }) =>
                fields.map((name, index) => {
                  const item = get(values, `items.${index}`);
                  const itemValues = isDecimalActive
                    ? calculateSingleItemValuesWithDecimals(item, decimal)
                    : calculateSingleItemValues(item, decimal);

                  return (
                    <tr key={index} className='position-relative w-100'>
                      <td className='items-field'>
                        <Field
                          name={`${name}.item`}
                          height='2.4rem'
                          fontSize='12px'
                          component={renderSelect}
                          className='edit-field'
                          loadOptions={async (text) =>
                            await filter({ text, categories: [], limit: 10 })
                          }
                          defaultOptions
                          hideDropdownIndicator
                          placeholder={I18n.get(
                            'selectProducts',
                            'selecciona los productos'
                          )}
                          getOptionLabel={(option) => option.name}
                          getOptionValue={(option) => option.id}
                          onChange={(value) => {
                            if (!!value) {
                              const modifiedItem = updateItemPrice(
                                {
                                  ...value,
                                  priceList: get(value, 'price'),
                                  price: get(value, 'price.0.price'),
                                  originalPrice: get(value, 'price.0.price'),
                                  quantity: !!+get(
                                    values,
                                    `items.${index}.quantity`
                                  )
                                    ? +get(values, `items.${index}.quantity`)
                                    : 1,
                                },
                                values
                              );
                              form.change(`items.${index}`, {
                                ...modifiedItem,
                              });
                              return modifiedItem;
                            }
                            return value;
                          }}
                        />
                      </td>
                      <td className='reference-field'>
                        <Field
                          name={`${name}.reference`}
                          className='edit-field'
                          component={renderField}
                          type='text'
                        />
                      </td>
                      <td className='price-field'>
                        <Tooltip
                          visible={!can('edit-items-prices', 'invoices')}
                          overlay={I18n.get(
                            'userNotAllowed.invoices.edit-items-prices-new',
                            'Necesitas permiso del administrador para editar el precio.'
                          )}
                          placement='bottomRight'
                        >
                          <Field
                            name={`${name}.price`}
                            className='edit-field'
                            component={renderField}
                            type='number'
                            placeholder={fmt.prefix}
                            min='0'
                            disabled={!can('edit-items-prices', 'invoices')}
                            onBlur={() => {
                              if (!get(values, `items.${index}.price`))
                                form.change(`items.${index}.price`, '0');
                              else if (+get(values, `items.${index}.price`) < 0)
                                form.change(`items.${index}.price`, '0');
                            }}
                          />
                        </Tooltip>
                      </td>
                      <td className='discount-field'>
                        <Field
                          name={`${name}.discount`}
                          className='edit-field'
                          component={renderField}
                          type='number'
                          placeholder='%'
                          min='0'
                          onBlur={() => {
                            if (!get(values, `items.${index}.discount`))
                              form.change(`items.${index}.discount`, '0');
                            else if (
                              +get(values, `items.${index}.discount`) < 0
                            )
                              form.change(`items.${index}.discount`, '0');
                          }}
                        />
                      </td>
                      <td className='taxes-field'>
                        <Field
                          name={`${name}.tax`}
                          height='2.4rem'
                          className='edit-field'
                          fontSize='12px'
                          component={renderSelect}
                          options={taxes}
                          getOptionLabel={(option) =>
                            getTaxesLabel(country, option)
                          }
                          getOptionValue={(option) => option.id}
                          isMulti={isMultitax}
                          showFavoriteIcon={false}
                          showClearIndicator
                          hideDropdownIndicator
                          placeholder={I18n.get('taxes', 'impuestos')}
                          onChange={(values) => validateSelectTaxes({ values, country, isMultitax })}
                        />
                      </td>
                      <td className='quantity-field'>
                        <Field
                          name={`${name}.quantity`}
                          className='edit-field'
                          component={renderField}
                          type='number'
                          min='0'
                          onBlur={() => {
                            if (!get(values, `items.${index}.quantity`))
                              form.change(`items.${index}.quantity`, '1');
                            else if (
                              +get(values, `items.${index}.quantity`) <= 0
                            )
                              form.change(`items.${index}.quantity`, '1');
                          }}
                        />
                      </td>
                      <td className='total-field'>
                        {itemValues.total.toFormat(decimal, fmt)}
                      </td>

                      <td className='delete-field'>
                        <button
                          type='button'
                          className='btn button-transparent'
                          disabled={fields.length <= 1}
                          style={{
                            height: '2.4rem',
                            outline: 'none',
                            boxShadow: 'none',
                          }}
                          onClick={() => fields.remove(index)}
                        >
                          <Icon icon={IconTrash} extraClass='icon-secondary' />
                        </button>
                      </td>
                    </tr>
                  );
                })
              }
            </FieldArray>
          </tbody>
        </table>
      </div>

      <button
        type='button'
        className='btn btn-primary mb-4 detail-items-table-wrapper'
        onClick={() =>
          form.mutators.concat('items', [
            {
              quantity: 0,
              tax: [],
              discount: 0,
              price: 0,
            },
          ])
        }
      >
        <Icon icon={IconPlus} extraClass='icon-primary' />
        {I18n.get('addNewItem', 'Agregar nuevo ítem')}
      </button>
    </>
  );
};

export default Items;

import React from 'react';
import { Field } from 'react-final-form';
import { I18n } from '@aws-amplify/core';

import { renderSelect } from '../../fields/V0/Fields';

const renderPriceInfo = props => {
  return (
    <>
      <Field
        name="decimalPrecision"
        className="col-md-6"
        options={['0', '1', '2', '3', '4'].map(decimals => ({ key: decimals, label: decimals }))}
        component={renderSelect}
        type="text"
        getOptionValue={option => option.key}
        helpTooltip={I18n.get('decimalPrecisionTooltip', 'Selecciona el número de decimales que utilizarás para los precios de tus productos y valores de tus facturas')}
        label={I18n.get('decimalPrecision', 'Precisión decimal')}
        isSearchable={false}
        required
      />
    </>
  )
}

export default renderPriceInfo;
export const LEGAL_STATUSES = {
  ACCEPTED: 'ACCEPTED',
  ACCEPTED_WITH_OBSERVATIONS: 'ACCEPTED_WITH_OBSERVATIONS',
  PENDING: 'PENDING',
  STAMPED_AND_ACCEPTED: 'STAMPED_AND_ACCEPTED',
  STAMPED_AND_ACCEPTED_WITH_OBSERVATIONS: 'STAMPED_AND_ACCEPTED_WITH_OBSERVATIONS',
  REJECTED: 'REJECTED',
  STAMPED_AND_REJECTED: 'STAMPED_AND_REJECTED',
  STAMPED_AND_WAITING_RESPONSE: 'STAMPED_AND_WAITING_RESPONSE',
};

export const LEGAL_STATUS_KEYS = {
  ISSUED_ALTER: 'legalStatus.issued.alter',
  ISSUED: 'legalStatus.issued',
  REJECTED: 'legalStatus.rejected',
  TO_BE_ISSUED: 'legalStatus.toBeIssued',
  IN_PROCESS: 'legalStatus.inProcess',
  CONTINGENCY: 'legalStatus.contingency',
  UNKNOWN: 'legalStatus.unknown',
};

export const BADGE_STATUSES = {
  CLOSED: 'closed',
  OPEN: 'open',
  VOID: 'void',
  PROCESS: 'process',
  DEFAULT: 'void',
};
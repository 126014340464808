import { graphqlOperation } from '@aws-amplify/api';
import { useQuery } from '@tanstack/react-query';
import { get } from 'lodash';
import * as queries from '../../../graphql/queries'
import alegraApi from '../../../reducers/alegraAPI';
import { queryClient } from '../../../utils/queryClient';

export const PriceListKeys = {
  all: 'priceListsGraphql',
  allApi: 'priceListsApi',
};

const fetchPriceListsGraphql = async (api, params) => {
  const { pageIndex, pageSize } = params;
  const response = await api(graphqlOperation(queries.allPriceLists, {
    batch: { limit: pageSize, start: pageIndex * pageSize },
    includeMetadata: true,
  }));
  const data = get(response, 'data.allPriceLists.data', []);
  const total = get(response, 'data.allPriceLists.metadata.total', 0);
  return { data, total };
};

const fetchPriceListsApi = async (metadata, params) => {
  const response = await alegraApi.get('/price-lists', {
    metadata: true,
    fields: 'deletable,editable',
    ...metadata,
    ...params
  });

  const data = get(response, 'data.data', null)
  const total = get(response, 'data.metadata.total', 0)
  if (data)
    return { data, total };
  return { data: [], total };
};

export const fetchPriceListApi = (metadata, params) => {
  return queryClient.fetchQuery({
    queryKey: [PriceListKeys.allApi, { ...metadata, ...params }],
    queryFn: () => fetchPriceListsApi(metadata, params),
    keepPreviousData: true,
    staleTime: 1000 * 60 * 60 * 24,
    refetchOnReconnect: "always",
  });
};

export const usePriceListsQueryGraphql = (api, params) => {
  return useQuery({
    queryKey: [PriceListKeys.all, params],
    queryFn: () => fetchPriceListsGraphql(api, params),
    keepPreviousData: true,
    staleTime: 1000 * 60 * 60 * 24,
    refetchOnReconnect: "always",
  })
};

export const usePriceListsQuery = (params) => {
  return useQuery({
    queryKey: [PriceListKeys.allApi, params ?? {}],
    queryFn: () => fetchPriceListsApi(params),
    keepPreviousData: true,
    staleTime: 1000 * 60 * 60 * 24,
    refetchOnReconnect: "always",
  })
};

import dayjs from 'dayjs';
import { BaseInvoiceStrategy } from './BaseInvoiceStrategy';
import {
  electronicInvoicing,
  timezone as tzSelector,
} from '../../selectors/company';
import { get } from 'lodash';
import { VALIDATION_ERROR_MESSAGES } from '../../utils/invoice/activeInvoice';

export class SpainInvoiceStrategy extends BaseInvoiceStrategy {
  /**
   * Modifies the given invoice based on various parameters provided.
   *
   * @param {Object} invoice - The invoice object to be modified.
   * @param {Object} state - The current state object.
   * @param {boolean} isElectronic - Indicates if the invoice is electronic.
   * @param {Object} numberTemplate - The template for numbering the invoice.
   * @param {Date} today - The current date.
   * @param {string} cfdiUse - The CFDI use for the invoice.
   * @returns {Object} - The modified invoice.
   */
  modifyPreparedInvoice({
    invoice,
    state,
    isElectronic,
    numberTemplate,
    today,
    cfdiUse,
  }) {
    const isCompanyElectronic = electronicInvoicing(state);
    const modifiedInvoice = { ...invoice };
    delete modifiedInvoice.type;
    delete modifiedInvoice.operationType;
    delete modifiedInvoice.paymentTerm;
    delete modifiedInvoice.deliveryTerm;

    modifiedInvoice.today = dayjs(new Date()).tz(tzSelector(state));

    if (isCompanyElectronic) {
      (modifiedInvoice.operationType = 'GENERAL_REGIME_AND_OTHERS'),
        (modifiedInvoice.stamp = {
          generateStamp:
            !!isElectronic && !!numberTemplate
              ? !!numberTemplate.isElectronic
              : false,
        });
    }

    if (modifiedInvoice.totalReceived < modifiedInvoice.total) {
      modifiedInvoice.dueDate = today
        .add(get(invoice, 'client.term.days', 0), 'day')
        .format('YYYY-MM-DD');
    }

    return modifiedInvoice;
  }
  /**
   * Provides the default client information for Spain invoices.
   *
   * @param {Object} options - The options for the default client.
   * @param {Object} options.address - The address details for the client.
   * @param {boolean} options.isElectronic - Indicates if the invoice is electronic.
   * @returns {Object} - The default client object, including name, address, and identification.
   */
  defaultClient({ address, isElectronic }) {
    return {
      name: 'Público en general',
      address: {
        city: 'Madrid',
        province: 'Madrid',
        address: 'Calle A 123',
      },
      type: ['client'],
      ignoreRepeated: true,
      identification: 'X0101010Y',
      identificationObject: {
        number: 'X0101010Y',
        type: 'NIF',
      },
    };
  }
}

import { createDraftSafeSelector } from '@reduxjs/toolkit';
import { get } from 'lodash'

import { itemCategoriesSelector } from '../reducers/itemCategories'

const itemCategoriesObjectSelector = state => state.itemCategories;

export const allItemCategories = createDraftSafeSelector(
  itemCategoriesSelector.selectAll,
  categories => !!categories ? categories.filter(cat => get(cat, 'status') === 'active') : []
)

export const selectedCategories = createDraftSafeSelector(
  itemCategoriesObjectSelector,
  itemCategoryObject => !!itemCategoryObject ? itemCategoryObject.selected : [] 
)
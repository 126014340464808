import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Link, useRouteMatch } from 'react-router-dom'
import { I18n } from '@aws-amplify/core';
import { get } from 'lodash'

import { openModal, closeModal, closeAll } from '../../../reducers/modals';
import { membershipSelector } from '../../../selectors/company';
import Modal from '../../common/Modal';
import BackgroundSVG from './Background.svg'
import IconSVG from './Icon.svg'

const SuspendedPlan = () => {
  const dispatch = useDispatch()
  const isOpen = useSelector(state => !!get(state, 'modals.suspendedPlan.isOpen'))
  const membership = useSelector(membershipSelector)
  let match = useRouteMatch("/plan");


  useEffect(() => {
    const suspendedPlan = get(membership, 'status', '') === 'suspended'

    if (suspendedPlan && !match) {
      if (!isOpen) {
        dispatch(closeAll())
        dispatch(openModal({ modal: 'suspendedPlan' }))
      }
    } else {
      if (isOpen)
        dispatch(closeModal({ modal: 'suspendedPlan' }))
    }
  }, [membership, match, isOpen, dispatch])

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={() => { }}
      hideHeader
      className="modal__suspended-plan"
    >
      <div className="position-relative overflow-hidden">
        <img className="position-absolute" src={BackgroundSVG} alt="" />

        <div className="p-4 pb-5">

          <div className="d-flex flex-column justify-content-center py-md-4 text-center align-items-center">

            <div className="mb-5">
              <img src={IconSVG} alt="" />
            </div>

            <h1 className="text-primary mb-5" style={{ fontSize: '24px', lineHeight: '32px' }}>
              {I18n.get('onboarding.suspendedPlan.header', 'Esta función está inactiva')}
            </h1>

            <p className="mb-5" style={{ maxWidth: '45rem' }}>
              {I18n.get('onboarding.suspendedPlan.message', 'Hemos presentado inconvenientes con el cobro de tu plan y tu cuenta ha sido suspendida. Puedes activarla realizando tu pago y si tienes alguna inquietud, escríbenos al correo')}
              {' '}
              <a href="mailto:tuspagos@alegra.com">tuspagos@alegra.com</a>
            </p>

            <div className="text-center">
              <Link
                to={{ pathname: `${process.env.REACT_APP_ALEGRA_SMILE}subscriptions/products/alegra-pos/plans` }}
                className="btn btn-primary text-white px-5 d-flex align-items-center"
                style={{
                  height: '4rem',
                  filter: 'drop-shadow(0px 3px 12px rgba(0, 0, 0, 0.15))'
                }}
                onClick={() => dispatch(closeModal({ modal: 'suspendedPlan' }))}
                target="_blank"
              >
                {I18n.get('onboarding.suspendedPlan.button', 'Activar mi cuenta')}
              </Link>
            </div>

          </div>
        </div>

      </div>
    </Modal>
  )
}

export default SuspendedPlan;
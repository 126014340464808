import {
  Button,
  Grid,
  toastHandler,
  Tooltip,
  useModal,
} from '@alegradev/smile-ui-react';
import { I18n } from 'aws-amplify';
import { useForm } from 'react-final-form';
import { handleCopyLink } from './utils';
import { get } from 'lodash';
import { useDispatch } from 'react-redux';
import { sendGTMEvent, sendNewGTMEvent } from '../../../reducers/company';
import { SHARE_METHODS } from '../invoiceSaved/util';
import { IconLink } from '@tabler/icons-react';

function ShareInvoiceFooter({
  method = SHARE_METHODS.EMAIL,
  errors,
  modalName,
  hash,
  invoice,
  loadingEmail,
  hashError,
}) {
  const dispatch = useDispatch();
  const { Wrapper } = Grid;
  const { closeModal } = useModal();
  const form = useForm();

  return (
    <Wrapper
      fullWidth
      justify={{ lg: 'between' }}
      direction={{ sm: 'column' }}
      gap={10}
      extraClasses='mt-4'
    >
      <Wrapper>
        {!hashError && (
          <Button
            onClick={() => {
              handleCopyLink(hash, invoice);
              dispatch(
                sendNewGTMEvent('pos-invoice-shared', {
                  medium: 'URL',
                })
              );
            }}
            text={I18n.get('copyLink', 'Copiar enlace')}
            emphasis='text'
            leftIcon={IconLink}
            type='button'
          />
        )}
      </Wrapper>
      <Wrapper gap={10}>
        <Button
          onClick={() => closeModal(modalName)}
          text={I18n.get('cancel', 'Cancelar')}
          emphasis='outline'
          type='button'
        />

        <Tooltip
          width='full'
          visible={
            !hashError &&
            !get(form.getFieldState('phone'), 'value') &&
            method === SHARE_METHODS.WHATSAPP
          }
          overlay={I18n.get(
            'youNeedToIncludePhone',
            'Necesitas incluir un número de teléfono para el envío'
          )}
          placement='top'
        >
          <Button
            type='button'
            onClick={() => form.submit()}
            loading={loadingEmail}
            full
            disabled={
              Object.keys(errors).length > 0 ||
              (!get(form.getFieldState('phone'), 'value') &&
                !hashError &&
                method === SHARE_METHODS.WHATSAPP)
            }
            text={
              method === SHARE_METHODS.EMAIL
                ? I18n.get('sendEmail', 'Enviar correo')
                : I18n.get('sendWhatsapp', 'Enviar por WhatsApp')
            }
          />
        </Tooltip>
      </Wrapper>
    </Wrapper>
  );
}

export default ShareInvoiceFooter;

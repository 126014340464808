import React from 'react';
import PropTypes from 'prop-types';
import Notification from '../common/Notification';

/**
 * RefundNotifications Component
 *
 * Displays error notifications based on form submission.
 *
 * @param {Object} props - Component props.
 * @param {string|null} props.method - Current refund method.
 * @param {string|null} props.submitError - Error message from form submission.
 * @param {number} props.step - Current step in the refund process.
 * @returns {JSX.Element|null} - The rendered component or null if no error.
 * 
 * @component
 */
const RefundNotifications = ({ method, submitError, step }) => {
  const isErrorVisible =
    !!submitError &&
    ((method === 'positiveBalance' && step === 3) ||
      (method !== 'positiveBalance' && step === 4));

  return (
    <Notification
      isOpen={isErrorVisible}
      text={submitError}
      useDangerouslySetInnerHTML={true}
    />
  );
};

RefundNotifications.propTypes = {
  method: PropTypes.string,
  submitError: PropTypes.string,
  step: PropTypes.number.isRequired,
};

export default React.memo(RefundNotifications);

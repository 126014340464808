import Cookie from 'js-cookie';
import http from '../utils/http';

const baseUrl = process.env.REACT_APP_NOTIFICATIONS_ENDPOINT_V2;
const baseConfig = {
  headers: {
    Authorization: `Bearer ${process.env.REACT_APP_NOTIFICATIONS_AUTHORIZATION}`,
  },
};

const post = async (url, params = null, urlParams = null, userConfig = null) =>
  await http.post(
    `${baseUrl}${url}`,
    params,
    urlParams,
    { ...baseConfig, ...userConfig },
    Cookie.get('pos-block-api-request')
  );

const defaultExport = {
  post,
};

export default defaultExport;

import { useEffect, useState, useRef, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { API, graphqlOperation } from '@aws-amplify/api';
import { get } from 'lodash';

import * as subscriptions from '../graphql/subscriptions';
import { idCompanySelector } from '../selectors/company';
import alegraAPI from '../reducers/alegraAPI'
import {
  addWarehouse,
  updateWarehouse as updateWarehouseAction,
  removeWarehouse as removeWarehouseAction
} from '../reducers/warehouses';
import { stationWarehouse as stationWarehouseSelector } from '../selectors/app';
import { openModal } from '../reducers/modals';

const WarehousesSns = () => {
  const ref = useRef();
  const dispatch = useDispatch();
  const idCompany = useSelector(idCompanySelector);
  const stationWarehouse = useSelector(stationWarehouseSelector);
  const networkStatus = useSelector(state => get(state, 'app.networkStatus', 'online'));
  const [onCreateWarehouseError, setOnCreateWarehouseError] = useState(false);
  const [onUpdateWarehouseError, setOnUpdateWarehouseError] = useState(false);
  const [onDeleteWarehouseError, setOnDeleteWarehouseError] = useState(false);

  const getWarehouse = useCallback(
    async (id) => {
      try {
        const response = await alegraAPI.get(`/warehouses/${id}`);
        return get(response, 'data', null);
      } catch {
        return null
      }
    }, []);

  useEffect(() => {
    let subscription = null;
    if (networkStatus === 'online') {
      subscription = API.graphql(graphqlOperation(subscriptions.onCreateWarehouseNotify, {
        channel: `pos_${idCompany}`
      }))
        .subscribe({
          next: (data) => {
            const putWarehouse = async data => {
              try {
                const warehouseId = get(data, 'value.data.onCreateWarehouseNotify.id', null);
                const warehouse = await getWarehouse(warehouseId);

                if (!!warehouse) {
                  dispatch(addWarehouse(warehouse));
                }
              } catch (error) { }
            }

            putWarehouse(data)
          },
          error: () => setTimeout(() => {
            if (!!ref.current)
              setOnCreateWarehouseError(!onCreateWarehouseError)
          }, 60000)
        })
    }
    return () => {
      if (subscription)
        subscription.unsubscribe();
    }
  }, [networkStatus, onCreateWarehouseError, dispatch, idCompany, getWarehouse]);

  useEffect(() => {
    let subscription = null
    if (networkStatus === 'online') {
      subscription = API.graphql(graphqlOperation(subscriptions.onUpdateWarehouseNotify, {
        channel: `pos_${idCompany}`
      }))
        .subscribe({
          next: (data) => {
            const updateWarehouse = async data => {
              try {
                const warehouseId = get(data, 'value.data.onUpdateWarehouseNotify.id', null);
                const warehouse = await getWarehouse(warehouseId);

                const showConfigureWarehouseModal = !stationWarehouse
                  || (get(warehouse, 'status') === 'inactive'
                    && get(stationWarehouse, 'id') === warehouseId);

                if (!warehouse) {
                  return null
                }

                dispatch(updateWarehouseAction({
                  id: get(warehouse, "id"),
                  changes: warehouse
                }));

                if (showConfigureWarehouseModal) {
                  dispatch(openModal({ modal: "configureWarehouse" }));
                }
              } catch { }
            }

            updateWarehouse(data);
          },
          error: () => setTimeout(() => {
            if (!!ref.current)
              setOnUpdateWarehouseError(!onUpdateWarehouseError)
          }, 60000)
        })
    }
    return () => {
      if (subscription)
        subscription.unsubscribe();
    }
  }, [networkStatus, onUpdateWarehouseError, dispatch, idCompany, getWarehouse, stationWarehouse]);

  useEffect(() => {
    let subscription = null
    if (networkStatus === 'online') {
      subscription = API.graphql(graphqlOperation(subscriptions.onDeleteWarehouseNotify, {
        channel: `pos_${idCompany}`
      }))
        .subscribe({
          next: (data) => {
            const removeWarehouse = async data => {
              try {
                const warehouseId = get(data, 'value.data.onDeleteWarehouseNotify.id', null);
                const showConfigureWarehouseModal = !stationWarehouse || get(stationWarehouse, 'id') === warehouseId;

                if (!warehouseId)
                  return null

                dispatch(removeWarehouseAction(warehouseId));

                if (showConfigureWarehouseModal) {
                  dispatch(openModal({ modal: "configureWarehouse" }));
                }
              } catch { }
            }

            removeWarehouse(data);
          },
          error: () => setTimeout(() => {
            if (!!ref.current)
              setOnDeleteWarehouseError(!onDeleteWarehouseError)
          }, 60000)
        })
    }
    return () => {
      if (subscription)
        subscription.unsubscribe();
    }
  }, [networkStatus, onDeleteWarehouseError, dispatch, idCompany, stationWarehouse]);

  useEffect(() => {
    ref.current = true
    return () => ref.current = false
  }, []);

  return null;
};

export default WarehousesSns;


import { createDraftSafeSelector } from '@reduxjs/toolkit';

import { refundsSelector } from '../reducers/refunds'

const refundsDataSelector = state => state.refunds; 

export const refunds = createDraftSafeSelector(
  refundsSelector.selectAll,
  refunds => !!refunds ? refunds : [] 
)

export const getRefundById = id => createDraftSafeSelector(
  state => refundsSelector.selectById(state, id),
  refund => refund
)

export const loadingSelector = createDraftSafeSelector(
  refundsDataSelector,
  data => data.loading === 'loading'
)

export const errorSelector = createDraftSafeSelector(
  refundsDataSelector,
  data => data.error
)

export const totalSelector = createDraftSafeSelector(
  refundsDataSelector,
  data => data.total
)
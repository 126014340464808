import * as JsSearch from 'js-search';
import { deburr, words, uniq, has, get, isString } from 'lodash';

class Search {
  constructor(index) {
    this._uniqIndex = index;
    this._jsSearch = new JsSearch.Search(index);
    this._jsSearch.indexStrategy = new JsSearch.AllSubstringsIndexStrategy();
    this._jsSearch.tokenizer = {
      tokenize(text) {
        return uniq(words(deburr(text), /[^, ]+/g));
      }
    }
    this._initialized = true;
  }

  addDocuments(documents) {
    this._jsSearch.addDocuments(documents);
  }

  search(query) {
    if (this._initialized) {
      if (!!query)
        query = deburr(query.toLowerCase());

      return this._jsSearch.search(query);
    }
    return [];
  }
}

export class SearchItems extends Search {
  constructor() {
    super('id');
    this._jsSearch.addIndex('name');
    this._jsSearch.addIndex('reference');
    this._jsSearch.addIndex(['reference', 'reference']);
  }

  search(query) {
    let result = super.search(query);
    if (!!query)
      query = deburr(query.toLowerCase());
    return result.sort((a, b) => this.compare(a,b,query));
  }

  compare(a, b, query) {
    let nameA = has(a, 'name') ? get(a, 'name') : "";
    let nameB = has(b, 'name') ? get(b, 'name') : "";
    nameA = !!nameA && isString(nameA) ? nameA : "";
    nameB = !!nameB && isString(nameB) ? nameB : "";
    nameA = deburr(a.name.toLowerCase());
    nameB = deburr(b.name.toLowerCase());
    const indexOfNameA = nameA.indexOf(query); 
    const indexOfNameB = nameB.indexOf(query);
    
    let referenceA = has(a, 'reference.reference') ? get(a, 'reference.reference') : get(a, 'reference');
    let referenceB = has(b, 'reference.reference') ? get(b, 'reference.reference') : get(b, 'reference');
    referenceA = !!referenceA && isString(referenceA) ? referenceA : "";
    referenceB = !!referenceB && isString(referenceB) ? referenceB : "";
    referenceA = deburr(referenceA.toLowerCase());
    referenceB = deburr(referenceB.toLowerCase());
    const indexOfReferenceA = referenceA.indexOf(query); 
    const indexOfReferenceB = referenceB.indexOf(query);

    if (indexOfNameA === indexOfNameB) {
      if (indexOfNameA === -1) {
        if (indexOfReferenceA === indexOfReferenceB && indexOfReferenceA >= 0) {
          return indexOfReferenceA - indexOfReferenceB;
        }

        if (indexOfReferenceA !== indexOfReferenceB) {
          if (indexOfReferenceA >= 0 && indexOfReferenceB >= 0) {
            return indexOfReferenceA - indexOfReferenceB;
          }
          return indexOfReferenceA >= 0 ? -1 : 1;
        }

        return nameA.localeCompare(nameB);
      }
      return nameA.localeCompare(nameB);
    }
    
    if (indexOfNameA >= 0 && indexOfNameB >= 0)
      return indexOfNameA - indexOfNameB;

    return indexOfNameA >= 0 ? -1 : 1; 
  }

}
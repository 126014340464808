import React from 'react';
import { Field } from 'react-final-form';
import { I18n } from '@aws-amplify/core';
import { capitalize } from 'lodash';
import { useSelector } from 'react-redux';

import { activeBanks } from '../../../selectors/banks';
import { renderSelect } from '../fields/V0/Fields';
import { renderField } from '../fields/V2';
import { useDecimalsVersionsGroup } from '../../../hooks/useDecimalsVersionsGroup';
import { decimalSeparator } from '../../../selectors/company';

const OpenShift = ({ values, form }) => {
  const banks = useSelector(activeBanks)
  const separator = useSelector(decimalSeparator);
  const { isDecimalActive } = useDecimalsVersionsGroup();

  return (
    <div className="form-body h-100 p-4 overflow-hidden">
      <div className="form-body__fields form-row justify-content-start">
       <div className="col-12 mb-2">
        <Field
          name="base"
          component={renderField}
          fieldType="decimal"
          decimals={isDecimalActive ? 6 : null}
          separator={separator}
          onDecimalsError={(status) => form?.change('decimalsError', {
            ...values?.decimalsError,
            base: status,
          })}
          label={capitalize(I18n.get('base', 'base inicial'))}
          description={capitalize(I18n.get('baseHelp', 'la base es el dinero con que se cuenta a la hora de abrir un turno'))}
          required
        />
       </div>
        
        <Field
          name="baseBank"
          className="col-12"
          component={renderSelect}
          options={banks}
          label={capitalize(I18n.get('baseBank', 'banco base'))}
          help={capitalize(I18n.get('baseBankHelp', 'el banco al cual se transferirá la base inicial'))}
          getOptionLabel={option => option.name}
          getOptionValue={option => option.id}
          menuPosition="absolute"
          required
        />
        
      </div>
    </div>
  )
}

export default OpenShift;
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { I18n } from '@aws-amplify/core';
import { get } from 'lodash';

import { toast } from '../../../utils';
import { formError, handleError } from '../../../utils/errors';
import { variantTransformer } from '../../../utils/transformers';
import { closeModal, openModal } from '../../../reducers/modals';
import { validate, transform } from '../../forms/variant/utils';
import Modal from '../../common/Modal';
import Notification from '../common/Notification';
import Bottom from '../common/Bottom';
import Body from '../../forms/variant/Variant';

import { useCreateVariant, useUpdateVariant } from '../../inventory/Variants/mutations';

import { Icon } from '@alegradev/smile-ui-react';
import { IconInfoCircle } from '@tabler/icons-react';

const Variant = ({ onCreated }) => {
  const dispatch = useDispatch();
  const isOpen = useSelector((state) =>
    get(state, 'modals.variant.isOpen', false)
  );
  const variant = useSelector((state) =>
    get(state, 'modals.variant.params.variant', false)
  );
  const onRefresh = useSelector((state) =>
    get(state, 'modals.variant.params.onRefresh', false)
  );
  const [resetForm, setResetForm] = useState(false);

  const { mutate: updateVariantMutation, isLoading: isLoadingUpdate } = useUpdateVariant();
  const { mutate: createVariantMutation, isLoading: isLoadingCreate } = useCreateVariant();

  const close = () => {
    dispatch(closeModal({ modal: 'variant' }));
  };

  const open = () => {
    dispatch(
      openModal({
        modal: 'variant',
        params: {
          onRefresh,
        },
      })
    );
  };

  const submit = async (values) => {
    const transformedValues = transform(values);

    if (!!variant) {
      updateVariantMutation({
        variant: variantTransformer({ ...variant, ...transformedValues })
      }, {
        onSuccess: () => {
          close();
          toast.success({
            title: I18n.get(
              'variantUpdatedSuccessfully',
              'Variante actualizada con éxito.'
            ),
          })
        },
        onError: (error) => {
          close();
          toast.error({
            title: I18n.get(
              'updateVariantError',
              'Hubo un error al cambiar los datos de la variante.'
            ),
            subtitle: handleError(error)
          })
          return formError(
            error,
            I18n.get(
              'updateVariantError',
              'Hubo un error al cambiar los datos de la variante.'
            )
          )
        }
      })
    } else {
      createVariantMutation({
        variant: variantTransformer({ ...transformedValues })
      }, {
        onSuccess: (data) => {
          close();
          if (!!onCreated) {
            onCreated(data?.data);
          }
        }
      });
    }

    if (resetForm) {
      open();
      setResetForm(false);
    }
  };

  return (
    <Modal
      title={
        <div className='d-flex justify-content-between align-items-center w-100'>
          <div className='modal__header-title text-capitalize-first h2 text-primary text-truncate'>
            {!!variant
              ? variant.name
              : I18n.get('newVariant', 'nueva variante')}
          </div>
        </div>
      }
      isOpen={isOpen}
      onRequestClose={() => close()}
      className='modal__variant'
    >
      <Form
        onSubmit={submit}
        mutators={{
          ...arrayMutators,
        }}
        validate={(values) => validate(values)}
        initialValues={!!variant ? variant : { options: [{}] }}
        keepDirtyOnReinitialize
      >
        {({
          handleSubmit,
          form,
          values,
          submitError,
          pristine,
        }) => (
          <form noValidate onSubmit={handleSubmit}>
            <div className='modal__variant-body'>
              {!variant && (
                <p className='text-capitalize-first h5 text-subtitle px-4'>
                  {I18n.get(
                    'createVariantHelp',
                    'Crea una nueva característica con sus opciones, como por ejemplo diferentes colores o tamaños.'
                  )}
                </p>
              )}
              {variant && (
                <div className='d-flex flex-column p-2'>
                  <p
                    style={{
                      background: '#ECFCFF',
                      border: '1px #A9E9F2 solid',
                      color: '#146E80',
                    }}
                    className='rounded h5 p-3 d-flex justify-content-between align-items-center'
                  >
                    <Icon icon={IconInfoCircle} color='#146E80' />
                    <p className='ml-3 mb-0'>
                      {I18n.get(
                        'editItemVatiantHelp',
                        'La configuración general no puede ser editada en variantes que tengan items asociados, únicamente puedes agregar nuevas opciones.'
                      )}
                    </p>
                  </p>
                </div>
              )}
              <Body values={values} form={form} />
            </div>

            {!!submitError && !pristine && (
              <Notification isOpen={true} text={submitError} type='error' />
            )}

            <Bottom
              submitting={isLoadingCreate || isLoadingUpdate}
              onClose={() => close()}
              onResetForm={setResetForm}
              resetForm={resetForm}
              hideRequired={true}
              submitText={
                !!variant
                  ? I18n.get('updateVariant', 'Actualizar variante')
                  : I18n.get('createVariant', 'Crear variante')
              }
              saveText={
                !!variant
                  ? I18n.get('updateAndCreateNew', 'Actualizar y crear nueva')
                  : I18n.get('saveAndCreateNew', 'Guardar y crea nueva')
              }
              hideCancel
            />
          </form>
        )}
      </Form>
    </Modal>
  );
};

export default Variant;

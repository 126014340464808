import React, { useEffect, useState } from 'react'
import { I18n } from 'aws-amplify';
import { useSelector } from 'react-redux';
import { capitalize, get } from 'lodash';
import { useHistory } from 'react-router-dom';

import { getPlanName } from '../../selectors/membership';
import { loadingSelector as loadingUsersSelector, allUsers } from '../../selectors/users';
import { featureCurrentAmount, featureLimit, unlimitedFeatures } from '../../selectors/membership';
import { country as countrySelector, decimalPrecision } from '../../selectors/company';
import { currency } from '../../selectors/activeInvoice';
import { getMainCurrency } from '../../selectors/currencies';
import { formatMoney } from '../../utils';

import FeatureConsumption from './FeatureConsumption';
import UnlimitedFeatureConsumption from './UnlimitedFeatureConsumption';
import Table from '../common/Table';

const Consumption = () => {
  const history = useHistory();
  const featCurrentAmount = useSelector(featureCurrentAmount);
  const featLimit = useSelector(featureLimit);
  const unlimitedFeats = useSelector(unlimitedFeatures);
  const [featDetails, setFeatDetails] = useState({});

  const planName = useSelector(getPlanName);
  const loadingUsers = useSelector(loadingUsersSelector);
  const users = useSelector(allUsers);
  const country = useSelector(countrySelector);

  const decimal = useSelector(decimalPrecision);
  const mainCurrency = useSelector(getMainCurrency);
  const selectedCurrency = useSelector(currency);

  const prefix = !!get(selectedCurrency, 'symbol') ? get(selectedCurrency, 'symbol')
    : !!get(mainCurrency, 'symbol') ? get(mainCurrency, 'symbol') : ''

  const fmt = {
    prefix,
    decimalSeparator: '.',
    groupSeparator: ',',
    groupSize: 3,
  }

  useEffect(() => {
    window.dataLayer.push({
      'event': 'VirtualPageview',
      'virtualPageURL': '/plan/consumption',
      'virtualPageTitle': 'Plan Consumption'
    });
  }, [])

  useEffect(() => {
    setFeatDetails(unlimitedFeats(['income', 'invoices', 'users', 'warehouses']));
  }, [unlimitedFeats])

  return (
    <div className="h-100 settings">
      <div className="container p-5">
        <div className="d-flex flex-column" style={{ fontFamily: "Inter" }}>
          <div className="w-100 d-flex flex-column mb-5">
            <div className="d-flex membership-title justify-content-between align-items-center">
              <h1 className="text-capitalize-first m-0">
                {I18n.get('planConsumption', 'Consumo del plan')}
              </h1>
            </div>
          </div>

          <div className="consumption-summary d-flex flex-row bg-white my-3 p-4">
            <div className="consumption-summary-block d-flex flex-column ml-4">
              <p className="h5 text-muted text-capitalize-first">
                {I18n.get('currentPOSPlan', 'Plan actual')}
              </p>
              <p className="current-plan text-primary text-uppercase">
                {planName.replace("Plan ", "")}
              </p>
            </div>

            <div className="consumption-summary-block d-flex flex-column">
              <p className="h5 text-muted text-capitalize-first text-center">
                {I18n.get('createdUsers', 'Usuarios creados')}
              </p>
              <p className="feature text-capitalize-first">
                {featCurrentAmount('users')}
              </p>
            </div>

            <div className="consumption-summary-block d-flex flex-column">
              <p className="h5 text-muted text-capitalize-first text-center">
                {I18n.get('inventoryWarehouses', 'Bodegas de inventario')}
              </p>
              <p className="feature text-capitalize-first">
                {featCurrentAmount('warehouses')}
              </p>
            </div>

            <div className="consumption-summary-block consumption-summary-income d-flex flex-column">
              <p className="h5 text-muted text-capitalize-first text-center">
                {I18n.get('income', 'Ingresos')}
              </p>
              <p className="feature text-capitalize-first">
                {formatMoney(featCurrentAmount('income'), decimal, fmt)}
              </p>
            </div>


            <div className="consumption-summary-block d-flex flex-column">
              <p className="h5 text-muted text-capitalize-first text-center">
                {I18n.get('numberOfInvoices', 'número de facturas')}
              </p>
              <p className="feature text-primary text-capitalize-first">
                {featCurrentAmount('invoices')}
              </p>
            </div>
          </div>

          <div className="consumption-details d-flex flex-row justify-content-between">
            <div className="general-consumption d-flex flex-column bg-white my-3">
              <div className='title w-100 p-4'>
                <p className="text-uppercase m-0 p-0">{I18n.get('generalConsumption', 'Consumo general')}</p>
              </div>
              <div>
                <div className='px-5 py-4'>
                  {featDetails['income'] &&
                    <UnlimitedFeatureConsumption
                      title={I18n.get('income', 'Ingresos')}
                      currentValue={formatMoney(featCurrentAmount('income'), decimal, fmt)}
                    />
                  }

                  {featDetails['invoices'] &&
                    <UnlimitedFeatureConsumption
                      title={I18n.get('invoicesCreatedInTheMonth', 'Facturas creadas en el mes')}
                      currentValue={featCurrentAmount('invoices')}
                    />
                  }

                  {featDetails['users'] &&
                    <UnlimitedFeatureConsumption
                      title={I18n.get('createdUsers', 'Usuarios creados')}
                      currentValue={featCurrentAmount('users')}
                    />
                  }

                  {featDetails['warehouses'] &&
                    <UnlimitedFeatureConsumption
                      title={I18n.get('inventoryWarehouses', 'Bodegas de inventario')}
                      currentValue={featCurrentAmount('warehouses')}
                    />
                  }

                  {!((featDetails['income'] && featDetails['warehouses'] && featDetails['invoices'] && featDetails['users']) ||
                    (!featDetails['income'] && !featDetails['warehouses'] && !featDetails['invoices'] && !featDetails['users'])) &&
                    <div className="general-consumption-divider pt-1" />
                  }

                  {!featDetails['income'] &&
                    <FeatureConsumption
                      title={I18n.get('income', 'Ingresos')}
                      currentValue={featCurrentAmount('income')}
                      limit={featLimit('income')}
                      formatFunction={value => formatMoney(value, decimal, fmt)}
                    />
                  }

                  {!featDetails['invoices'] &&
                    <FeatureConsumption
                      title={I18n.get('invoicesCreatedInTheMonth', 'Facturas creadas en el mes')}
                      currentValue={featCurrentAmount('invoices')}
                      limit={featLimit('invoices')}
                    />
                  }

                  {!featDetails['users'] &&
                    <FeatureConsumption
                      title={I18n.get('createdUsers', 'Usuarios creados')}
                      currentValue={featCurrentAmount('users')}
                      limit={featLimit('users')}
                    />
                  }

                  {!featDetails['warehouses'] &&
                    <FeatureConsumption
                      title={I18n.get('inventoryWarehouses', 'Bodegas de inventario')}
                      currentValue={featCurrentAmount('warehouses')}
                      limit={featLimit('warehouses')}
                    />
                  }
                </div>
                <div className="general-consumption-divider" />
                <div className='w-100 d-flex flex-column justify-content-center align-items-center p-5'>
                  <p className='h5 text-center mt-4 mb-4'>{I18n.get("discoverAvailablePlans", "¡Conoce todos los planes disponibles para ti!")}</p>
                  <button
                    type='button'
                    onClick={() => window.open(`${process.env.REACT_APP_ALEGRA_SMILE}subscriptions/products/alegra-pos/plans`, '_blank')}
                    className='w-100 btn btn-submit'
                  >
                    {I18n.get("goToSubscriptions", "Ir a suscripciones")}
                  </button>
                </div>

              </div>
            </div>
            <div className="created-users w-60 d-flex flex-column bg-white my-3">
              <div className='title w-100 p-4'>
                <p className="text-uppercase m-0 p-0">{I18n.get('createdUsers', 'Usuarios creados')}</p>
              </div>
              <div className='px-5 py-4'>
                <Table
                  loading={loadingUsers}
                  data={users}
                  _pageSize={100}
                  noDataText={capitalize(I18n.get('youDontHaveCreatedSellers', 'aún no tienes vendedores creados'))}
                  columns={[
                    {
                      Header: capitalize(I18n.get('name', 'Nombre')),
                      sortable: false,
                      accessor: 'name'
                    },
                    {
                      Header: capitalize(I18n.get('email', 'correo')),
                      accessor: 'email',
                      Cell: ({ value }) => (
                        <p className="text-truncate text-muted styled-hover" hovercontent={value}>
                          {value}
                        </p>
                      )
                    },
                    {
                      Header: capitalize(I18n.get('status', 'estado')),
                      accessor: 'status',
                      Cell: ({ value }) => (
                        <p className={`${value === 'active' ? 'text-primary' : 'text-danger'} m-0`}>
                          {value === 'active' ? capitalize(I18n.get('active', 'Activado')) : capitalize(I18n.get('inactive', 'Desactivado'))}
                        </p>
                      )
                    }
                  ]}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  )
}

export default Consumption;
const thirdType = [
  {
    label: 'Nacional',
    value: 'NATIONAL',
  },
  {
    label: 'Extranjero',
    value: 'FOREIGN',
  },
];

export default thirdType;

import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { sendNewGTMEvent } from '../../reducers/company';

function useSendGTMEvent(isOpen, eventName) {
  const dispatch = useDispatch();

  useEffect(() => {
    if (isOpen) {
      dispatch(sendNewGTMEvent(eventName));
    }
  }, [isOpen, eventName, dispatch]);
}

export default useSendGTMEvent;

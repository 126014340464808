import db from './database';
export const put = (data) => {
  return db.totalToCollect.put(data);
};

export const getAll = async () => {
  try {
    return await db.totalToCollect.toArray();
  } catch {
    return [];
  }
};

import React, { useEffect, useState } from 'react';
import parse from 'html-react-parser';
import { I18n } from 'aws-amplify';
import { get } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { graphqlOperation } from 'aws-amplify';

import * as mutations from '../../../graphql/mutations';
import { setUser } from '../../../reducers/auth';
import { closeModal } from '../../../reducers/modals';
import { sendGTMEvent, sendNewGTMEvent } from '../../../reducers/company';

import Modal from '../../common/Modal';
import Bottom from './Bottom';
import AlegraWompi from '../../../assets/images/image_alegra_wompi.png';
import { APIGraphqlSelector } from '../../../selectors/app';
import { membershipSelector } from '../../../selectors/company';

const sleep = (ms) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

const ActivateWompiIntegration = () => {
  const dispatch = useDispatch();
  const membership = useSelector(membershipSelector);
  const isOpen = useSelector((state) =>
    get(state, 'modals.activateWompiIntegration.isOpen', false)
  );
  const APIGraphql = useSelector(APIGraphqlSelector);
  const isReadOnly = get(membership, 'readOnly', true);
  const isDemo = get(membership, 'plan.keyword', null) === 'demo';
  const [showModaltoReadOnly, setShowModaltoReadOnly] = useState(false);

  useEffect(() => {
    const handleReadOnly = async () => {
      if (isReadOnly) {
        // In case the plan is readOnly we wait 5min to show the modal
        await sleep(5 * 60000);
        setShowModaltoReadOnly(true);
      }
      setShowModaltoReadOnly(true)
    };

    handleReadOnly();

    return () => {
      setShowModaltoReadOnly(true);
    };
  }, [isReadOnly]);

  const onChangeShowWompiIntegrationAvailable = async (value) => {
    try {
      const response = await APIGraphql(
        graphqlOperation(mutations.updateUser, {
          user: {
            company: {
              localSettings: {
                showWompiIntegrationAvailable: value,
              },
            },
          },
        })
      );
      if (!!get(response, 'data.updateUser.id', null))
        dispatch(setUser(get(response, 'data.updateUser')));
      else throw new Error('error');
    } catch (error) {
      console.log(error);
    }
  };

  const handleClose = () => {
    onChangeShowWompiIntegrationAvailable(false);
    dispatch(closeModal({ modal: 'activateWompiIntegration' }));
  };

  return (
    <Modal
      isOpen={isOpen && showModaltoReadOnly && !isDemo}
      onRequestClose={handleClose}
      className='modal__activate-wompi'
      headerImg={AlegraWompi}
      iconBlack
      bordelessHeader
    >
      <p className='body__title inter-semibold m-0 p-0'>
        {I18n.get(
          'activateWompiIntegrationModal.title',
          '¡Nueva integración de pagos en línea! ⚡'
        )}
      </p>
      <p className='body__content inter-regular m-0 p-0'>
        {parse(
          I18n.get(
            'activateWompiIntegrationModal.content',
            'Recibe tus pagos desde <b>Bancolombia</b> o <b>Nequi</b> y valida el estado   de cada transacción en el momento de la venta.'
          )
        )}
      </p>
      <Bottom
        onConfirm={() => {
          dispatch(
            sendGTMEvent('customer-payment-method-activation-attempted', {
              customerPaymentMethod: 'wompi',
            })
          );
          dispatch(sendNewGTMEvent('pos-sale-payment-method-attempted', {
            type: 'wompi',
            cta: I18n.get(
              'activateWompiIntegrationModal.content',
              'Recibe tus pagos desde <b>Bancolombia</b> o <b>Nequi</b> y valida el estado   de cada transacción en el momento de la venta.'
            )
          }));
          window.open(
            `${process.env.REACT_APP_ALEGRA_SMILE}integrations/e-payments/wompi/configuration`,
            '_blank'
          );
        }}
      />
    </Modal>
  );
};

export default ActivateWompiIntegration;

import { Grid, Space, Typography } from '@alegradev/smile-ui-react';
import { I18n } from 'aws-amplify';
import { get } from 'lodash';

const PrintPaymentFooter = ({ payment }) => {
  const { Wrapper } = Grid;
  return (
    <Wrapper direction={{ lg: 'column' }}>
      {!!get(payment, 'observations') && (
        <Typography
          text={`<b>${I18n.get('observations', 'Observaciones')}:</b> ${get(payment, 'observations')}`}
          withHtml
        />
      )}
      {!!get(payment, 'bankAccount.name') && (
        <Typography
          text={`<b>${I18n.get('bankAccount', 'Cuenta bancaria')}:</b> ${get(payment, 'bankAccount.name')}`}
          withHtml
        />
      )}

      <Space height={20} />
    </Wrapper>
  );
};

export default PrintPaymentFooter;

import React from 'react';
import { I18n } from '@aws-amplify/core';
import { Field } from 'react-final-form';
import { capitalize } from 'lodash';
import { useSelector } from 'react-redux'

import { hasPermissionTo } from '../../../selectors/auth'
import { activeBanks } from '../../../selectors/banks'
import { renderSelect } from '../fields/V0/Fields';

const Bank = ({ options }) => {
  const can = useSelector(hasPermissionTo)
  const banks = useSelector(activeBanks)

  return (
    <div className="form-body__fields form-row justify-content-start">
      {options.hasOwnProperty('debitBank') && (
        <Field
          name="debit"
          className="col-12"
          component={renderSelect}
          label={capitalize(I18n.get('debitBank', 'banco débito'))}
          options={!can('index', 'bank-accounts') || !can('view', 'bank-accounts') ? [] : banks}
          selectInfo={!can('index', 'bank-accounts') || !can('view', 'bank-accounts')
            ? I18n.get('userNotAllowed.bank-accounts.view', 'no tienes permisos para ver la información de las cuentas de bancos')
            : null}
          hideNoOptionMessage={!can('index', 'bank-accounts') || !can('view', 'bank-accounts')}
          getOptionLabel={option => option.name}
          getOptionValue={option => option.id}
          required
        />
      )}

      {options.hasOwnProperty('creditBank') && (
        <Field
          name="credit"
          className="col-12"
          component={renderSelect}
          label={capitalize(I18n.get('creditBank', 'banco crédito'))}
          options={!can('index', 'bank-accounts') || !can('view', 'bank-accounts') ? [] : banks}
          selectInfo={!can('index', 'bank-accounts') || !can('view', 'bank-accounts')
            ? I18n.get('userNotAllowed.bank-accounts.view', 'no tienes permisos para ver la información de las cuentas de bancos')
            : null}
          hideNoOptionMessage={!can('index', 'bank-accounts') || !can('view', 'bank-accounts')}
          getOptionLabel={option => option.name}
          getOptionValue={option => option.id}
          required
        />
      )}

      {options.hasOwnProperty('transferBank') && (
        <Field
          name="transfer"
          className="col-12"
          component={renderSelect}
          label={capitalize(I18n.get('transferBank', 'banco transferencia'))}
          options={!can('index', 'bank-accounts') || !can('view', 'bank-accounts') ? [] : banks}
          selectInfo={!can('index', 'bank-accounts') || !can('view', 'bank-accounts')
            ? I18n.get('userNotAllowed.bank-accounts.view', 'no tienes permisos para ver la información de las cuentas de bancos')
            : null}
          hideNoOptionMessage={!can('index', 'bank-accounts') || !can('view', 'bank-accounts')}
          getOptionLabel={option => option.name}
          getOptionValue={option => option.id}
          required
        />
      )}

      {options.hasOwnProperty('closeBank') && (
        <Field
          name="closeBank"
          className="col-12"
          component={renderSelect}
          label={capitalize(I18n.get('closeBank', 'banco de cierre'))}
          options={!can('index', 'bank-accounts') || !can('view', 'bank-accounts') ? [] : banks}
          selectInfo={!can('index', 'bank-accounts') || !can('view', 'bank-accounts')
            ? I18n.get('userNotAllowed.bank-accounts.view', 'no tienes permisos para ver la información de las cuentas de bancos')
            : null}
          hideNoOptionMessage={!can('index', 'bank-accounts') || !can('view', 'bank-accounts')}
          getOptionLabel={option => option.name}
          getOptionValue={option => option.id}
          required
        />
      )}
      
      {options.hasOwnProperty('baseBank') && (
        <Field
          name="baseBank"
          className="col-12"
          component={renderSelect}
          label={capitalize(I18n.get('baseBank', 'banco base'))}
          options={!can('index', 'bank-accounts') || !can('view', 'bank-accounts') ? [] : banks}
          selectInfo={!can('index', 'bank-accounts') || !can('view', 'bank-accounts')
            ? I18n.get('userNotAllowed.bank-accounts.view', 'no tienes permisos para ver la información de las cuentas de bancos')
            : null}
          hideNoOptionMessage={!can('index', 'bank-accounts') || !can('view', 'bank-accounts')}
          getOptionLabel={option => option.name}
          getOptionValue={option => option.id}
          required
        />
      )}
    </div>
  )
}

export default Bank;

import React from 'react';
import { I18n } from '@aws-amplify/core';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { Tooltip } from '@alegradev/smile-ui-react';

const OnlyInvoicingTooltip = ({ children, visible, overlay }) => (
  <Tooltip
    visible={visible}
    overlay={
      overlay ? (
        overlay
      ) : (
        <p className='text-capitalize-first text-white'>
          {I18n.get(
            'onlyInvoicingTooltip',
            'Para disfrutar de esta funcionalidad, conoce los planes todo incluido'
          )}{' '}
          <NavLink to='/plan/plans' className='btn-link'>
            {I18n.get('here', 'aquí')}
          </NavLink>
        </p>
      )
    }
  >
    {children}
  </Tooltip>
);

OnlyInvoicingTooltip.propTypes = {
  visible: PropTypes.bool,
  overlay: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};

export default OnlyInvoicingTooltip;

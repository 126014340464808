import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { I18n } from '@aws-amplify/core';
import dayjs from 'dayjs';

import {
  aboutPOSLink,
  createElectronicInvoicesLink,
} from '../../../../../utils';
import { openTour } from '../../../../../reducers/tours';
import {
  country as countrySelector,
  regime as regimeSelector,
} from '../../../../../selectors/company';
import Typography from '../../../../common/Typography';
import MenuOption from '../../../../common/MenuOption';
import {
  COUNTRIES,
  COUNTRIES_CODES,
} from '../../../../../utils/enums/countries';

import { Icon, Tag } from '@alegradev/smile-ui-react';
import useNotification from '../../../../../hooks/useNotification/hook';
import { contactLink } from '../../../../modals/contactSupport/ContactSupport';
import { IconArrowUpRight, IconHelp } from '@tabler/icons-react';

const posCourseAvailableCountries = {
  [COUNTRIES.REPUBLICA_DOMINICANA]: 'https://aleg.la/cursoposdom',
  [COUNTRIES.MEXICO]:
    'https://academy.alegra.com/courses/curso-de-punto-de-venta-mex/',
};


const Help = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const country = useSelector(countrySelector);
  const regime = useSelector(regimeSelector);
  const { viewed, setViewed } = useNotification({
    notificationName: `pos-news-notification-menu-${country}`,
  });

  let showIvaNotification =
    country === 'colombia' &&
    dayjs().isBefore('2022-03-12') &&
    (regime === 'Responsable de IVA' || regime === 'Responsable de IVA e INC');

  let showNotification = showIvaNotification;

  const hanldeOpenTour = (event) => {
    event.preventDefault();
    event.stopPropagation();
    if (country === COUNTRIES.COLOMBIA) {
      window.open('https://aleg.la/152', '_blank', 'noopener noreferrer');
    } else {
      history.push('/');
      dispatch(openTour({ tour: 'onboarding' }));
    }
  };

  const handleContactLink = () => {
    return contactLink(country);
  };

  const handleNewsClick = () => {
    return `https://novedades.alegra.com/pos/filtro-pais/${COUNTRIES_CODES[country.toUpperCase()]}`;
  };

  return (
    <MenuOption
      id='help'
      eventName='Help'
      tooltip={I18n.get('help', 'Ayuda')}
      header={<Icon icon={IconHelp} />}
    >
      <div className='menu-option p-3'>
        <a
          className='p-2 d-flex align-items-center justify-content-between'
          href={handleContactLink()}
          target='_blank'
          rel='noreferrer'
        >
          <Typography
            type='body-3-regular'
            text={I18n.get('contactToSupport', 'Contactar Soporte')}
            variant='secondary'
          />
          <Icon icon={IconArrowUpRight} />
        </a>
      </div>

      <div className='menu-option p-3'>
        <a
          className='p-2 d-flex align-items-center justify-content-between'
          href={handleNewsClick()}
          onClick={setViewed}
          target='_blank'
          rel='noreferrer'
        >
          <Typography
            type='body-3-regular'
            text={I18n.get('posNews', 'Novedades')}
            variant='secondary'
          />
          <section className='d-flex gap-2'>
            {!viewed && <Tag type='green' label={I18n.get('new', 'Nuevo')} />}
            <Icon icon={IconArrowUpRight} />
          </section>
        </a>
      </div>

      {!!showNotification && (
        <>
          <div className='menu-option p-3'>
            <a
              className='p-2 d-flex align-items-center justify-content-between'
              href='https://ayuda.alegra.com/es/ayuda-dia-sin-iva-pos'
              target='_blank'
              rel='noreferrer'
            >
              <Typography
                type='body-3-regular'
                text={I18n.get(
                  'noIVADaysNotification',
                  'Prepárate para el día sin IVA'
                )}
                variant='secondary'
              />
              <Icon icon={IconArrowUpRight} />
            </a>
            <div className='btn-link-notification' />
          </div>
          <div className='menu-option p-3'>
            <a
              className='p-2 d-flex align-items-center justify-content-between'
              href='https://ayuda.alegra.com/es/ayuda-contingencia-dia-sin-iva-pos'
              target='_blank'
              rel='noreferrer'
            >
              <Typography
                type='body-3-regular'
                text={I18n.get(
                  'noIVADaysNotification2',
                  'Prepara tu facturación de contingencia'
                )}
                variant='secondary'
              />
              <Icon icon={IconArrowUpRight} />
            </a>
            <div className='btn-link-notification' />
          </div>
        </>
      )}

      {(!showIvaNotification && country !== COUNTRIES.REPUBLICA_DOMINICANA) && (
        <div className='menu-option p-3'>
          <a
            className='p-2 d-flex align-items-center justify-content-between'
            href='/#'
            onClick={hanldeOpenTour}
            target='_blank'
            rel='noreferrer'
          >
            <Typography
              type='body-3-regular'
              text={I18n.get('POSTutorial', 'Tutorial POS')}
              variant='secondary'
            />
            <Icon icon={IconArrowUpRight} />
          </a>
        </div>
      )}

      {
        (posCourseAvailableCountries[country]) && (
          <div className='menu-option p-3'>
            <a
              className='p-2 d-flex align-items-center justify-content-between'
              href={posCourseAvailableCountries[country]}
              target='_blank'
              rel='noreferrer'
            >
              <Typography
                type='body-3-regular'
                text={I18n.get('POSIntroductoryCourse', 'Tutorial POS')}
                variant='secondary'
              />
              <Icon icon={IconArrowUpRight} />
            </a>
          </div>
        )
      }

      {
        !showIvaNotification && (
          <div className='menu-option p-3'>
            <a
              className='p-2 d-flex align-items-center justify-content-between'
              href={aboutPOSLink(country)}
              target='_blank'
              rel='noreferrer'
            >
              <Typography
                type='body-3-regular'
                text={I18n.get('aboutPOS', 'acerca de POS')}
                variant='secondary'
              />
              <Icon icon={IconArrowUpRight} />
            </a>
          </div>
        )
      }

      {country === COUNTRIES.COLOMBIA && (
        <>
          <div className='menu-option p-3'>
            <a
              className='p-2 d-flex align-items-center justify-content-between'
              href='https://hardware.alegra.com/'
              target='_blank'
              rel='noreferrer'
            >
              <Typography
                type='body-3-regular'
                text={I18n.get(
                  'findYourIdealHardware',
                  'Descubre tu hardware ideal'
                )}
                variant='secondary'
              />
              <Icon icon={IconArrowUpRight} />
            </a>
          </div>
          <div className='menu-option p-3'>
            <a
              className='p-2 d-flex align-items-center justify-content-between'
              href={createElectronicInvoicesLink(country)}
              target='_blank'
              rel='noreferrer'
            >
              <Typography
                type='body-3-regular'
                text={I18n.get('', 'Facturas electrónicas desde POS')}
                variant='secondary'
              />
              <Icon icon={IconArrowUpRight} />
            </a>
          </div>
        </>
      )}
    </MenuOption>
  );
};

export default Help;

import React, { useEffect, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { I18n } from '@aws-amplify/core';
import Typography from '../../common/Typography';
import { Icon } from '@alegradev/smile-ui-react';
import { setPrint, setTypeToPrint } from '../../../reducers/print';
import { getRefundTitle, getNumber } from '../utils';
import { country as countrySelector } from '../../../selectors/company';
import RefundActions from './RefundActions';
import useRefundActions from '../hooks/useRefundActions';
import { IconCircleArrowDownLeft, IconList, IconLoader2, IconMail, IconPrinter } from '@tabler/icons-react';

/**
 * Component to display refund details and actions.
 *
 * @param {Object} props - Component props.
 * @param {Object} props.refund - The refund object.
 * @param {Function} props.onListView - Handler to switch to list view.
 * @param {Function} props.onRefresh - Handler to refresh data.
 * @returns {JSX.Element|null} - Rendered component or null if no refund.
 */
const DetailHeader = React.memo(({ refund, onListView, onRefresh }) => {
  const dispatch = useDispatch();
  

  const {
    stampLoading,
    sendRefund,
    emitRefund,
    canAddRefund,
    openNewRefundModal,
  } = useRefundActions(refund, onRefresh);

  const isElectronic = useMemo(() => refund?.numberTemplate?.isElectronic ?? false, [refund]);
  const isToBeIssued = useMemo(() => !(refund?.stamp ?? false), [refund]);
  const showButtonToEmit = useMemo(() => isElectronic && isToBeIssued, [isElectronic, isToBeIssued]);

  const country = useSelector(countrySelector);

  useEffect(() => {
    if (refund) {
      dispatch(setPrint({ type: 'refund', value: refund }));
    }
  }, [refund, dispatch]);

  const handlePrint = useCallback(() => {
    dispatch(setTypeToPrint('refund'));
  }, [dispatch]);

  if (!refund) return null;

  return (
    <>
      <RefundActions
        isElectronic={isElectronic}
        showButtonToEmit={showButtonToEmit}
        stampLoading={stampLoading}
        emitRefund={emitRefund}
        sendRefund={sendRefund}
        setTypeToPrint={handlePrint}
        canAddRefund={canAddRefund}
        openNewRefundModal={openNewRefundModal}
      />
      <div className="px-4 pt-4 new-refund-header">
        <div className="d-flex justify-content-between align-items-center new-refund-header">
          <Typography
            type="body-2-bold title"
            variant="primary"
            text={
              <>
                <Icon icon={IconCircleArrowDownLeft} />
                <span className="title-main">{getRefundTitle(refund, country)}</span>
                <span className="title-secondary">{getNumber(refund)}</span>
              </>
            }
          />
          <div className="new-refund-header_buttons d-none d-sm-flex">
            {showButtonToEmit && (
              <button
                onClick={emitRefund}
                className="btn btn-action d-flex px-4"
              >
                {stampLoading ? (
                  <Icon icon={IconLoader2} animated type='primary' />
                ) : (
                  I18n.get('emit', 'emitir')
                )}
              </button>
            )}
            {isElectronic && (
              <button
                className="btn btn-action d-flex ml-4"
                onClick={sendRefund}
              >
                <Icon icon={IconMail}  color='#8a8e94' size='small' />
                {I18n.get('send', 'enviar')}
              </button>
            )}
            <button
              onClick={handlePrint}
              className="btn btn-action d-flex"
            >
              <Icon icon={IconPrinter} color='#8a8e94' size='small' />
              {I18n.get('print', 'imprimir')}
            </button>
          </div>
          <div className="actions d-flex align-items-center d-block d-sm-none">
            <div className="w-100 h-25 d-flex justify-content-between align-items-center">
              <button
                type="button"
                className="btn btn-submit d-block d-sm-none"
                onClick={onListView}
              >
                <Icon icon={IconList} extraClass="icon-white" />
              </button>
            </div>
          </div>
        </div>
        <div className="new-refund-header_buttons d-sm-none d-flex">
          {showButtonToEmit && (
            <button
              onClick={emitRefund}
              className="btn btn-action d-flex px-4"
            >
              {stampLoading ? (
                <Icon icon={IconLoader2} animated type='primary' />
              ) : (
                I18n.get('emit', 'emitir')
              )}
            </button>
          )}

          {isElectronic && (
            <button
              className="btn btn-action d-flex ml-4"
              onClick={sendRefund}
            >
              <Icon icon={IconMail} color='#8a8e94' size='small' />
              {I18n.get('send', 'enviar')}
            </button>
          )}

          <button
            onClick={handlePrint}
            className="btn btn-action d-flex"
          >
            <Icon icon={IconPrinter} color='#8a8e94' size='small' />
            {I18n.get('print', 'imprimir')}
          </button>
        </div>
      </div>
    </>
  );
});

DetailHeader.propTypes = {
  refund: PropTypes.shape({
    id: PropTypes.string.isRequired,
    numberTemplate: PropTypes.shape({
      isElectronic: PropTypes.bool,
      documentType: PropTypes.string,
    }),
    stamp: PropTypes.bool,
  }),
  onListView: PropTypes.func.isRequired,
  onRefresh: PropTypes.func.isRequired,
};

export default DetailHeader;

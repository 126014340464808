import { COUNTRIES } from '../../../../utils/enums/countries';
import { MexicoInvoiceTextStrategy } from './MexicoInvoiceTextStrategy';
import { ColombiaInvoiceTextStrategy } from './ColombiaInvoiceTextStrategy';
import { SpainInvoiceTextStrategy } from './SpainInvoiceTextStrategy';
import { BaseInvoiceTextStrategy } from './BaseInvoiceTextStrategy';
import { CostaRicaInvoiceTextStrategy } from './CostaRicaInvoiceTextStrategy';
import { PanamaInvoiceTextStrategy } from './PanamaInvoiceTextStrategy';
import { RepublicaDominicanaInvoiceTextStrategy } from './RepublicaDominicanaInvoiceTextStrategy';

const invoiceTextStrategyRegistry = {
  [COUNTRIES.MEXICO]: MexicoInvoiceTextStrategy,
  [COUNTRIES.COLOMBIA]: ColombiaInvoiceTextStrategy,
  [COUNTRIES.SPAIN]: SpainInvoiceTextStrategy,
  [COUNTRIES.COSTA_RICA]: CostaRicaInvoiceTextStrategy,
  [COUNTRIES.PANAMA]: PanamaInvoiceTextStrategy,
  [COUNTRIES.REPUBLICA_DOMINICANA]: RepublicaDominicanaInvoiceTextStrategy,
};

export class InvoiceTextCountryStrategyFactory {
  /**
   * Create an invoice text strategy based on the given country.
   *
   * If there is a strategy for the given country in the registry, it will be
   * returned. Otherwise, the {@link BaseInvoiceTextStrategy} will be returned as
   * a fallback.
   *
   * @param {string} country
   * @return {InvoiceTextStrategy}
   */
  static createStrategy(country) {
    return invoiceTextStrategyRegistry[country] || BaseInvoiceTextStrategy;
  }
}

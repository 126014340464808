import React from 'react';
import { useSelector } from 'react-redux'
import { get } from 'lodash'

import { station as stationSelector } from '../../selectors/app';
import Items from './Items/Items';
import NewActiveInvoice from './NewActiveInvoice/NewActiveInvoice';

const Invoice = () => {
  const station = useSelector(stationSelector)

  const pendingInvoicesEnabled = get(station, 'pendingInvoicesEnabled', false);

  return (
    <div className={`invoice d-flex ${!!pendingInvoicesEnabled ? 'pending-invoice': ''}`}>
      <Items />
      <NewActiveInvoice />
    </div>
  )
}

export default Invoice;
import { createDraftSafeSelector } from '@reduxjs/toolkit';
import { get } from 'lodash';

const appSelector = state => get(state, 'economicActivities', null);

export const allEconomicActivities = createDraftSafeSelector(
  appSelector,
  app => get(app, 'activities', false)
)

export const mainActivity = createDraftSafeSelector(
  appSelector,
  app => app.activities.find(activity => activity.main === true)
)
import { I18n } from '@aws-amplify/core';
import { get } from 'lodash';

export const validate = (values, { shiftCash, shiftsEnabled, shiftOpen }) => {
  let errors = {};
  
  if (!get(values, 'amount', null))
    errors.amount = I18n.get('thisFieldIsRequired', 'Este campo es obligatorio')
  
  if (!!get(values, 'amount', null) && get(values, 'amount') <= 0)
    errors.amount = I18n.get('theValueOfPaymentsShouldBeGreaterThanZero', 'el valor del monto de los pagos debe ser mayor que cero')

  if (!get(values, 'accountingAccount.id', null))
    errors.accountingAccount = I18n.get('thisFieldIsRequired', 'Este campo es obligatorio')

  if (!!shiftsEnabled && shiftOpen === true && get(values, 'type') === 'out') {
    if (+get(values, 'amount') > shiftCash)
      errors.amount = I18n.get('noCashAvailable', 'el valor del egreso no puede ser mayor a la cantidad de efectivo en caja')
  }

  return errors;
}

export const transform = values => {
  return {
    type: get(values, 'type'),
    client: get(values, 'client', null),
    categories: [{
      id: get(values, 'accountingAccount.id', null),
      price: get(values, 'amount', null),
      quantity: 1,
    }],
    observations: get(values, 'observations', null),
  }
}
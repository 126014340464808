import React from 'react'
import PropsTypes from 'prop-types';

import Header from './Header';

const Modal = ({ children, title, description, seeMoreUrl, newDesing, ...props }) => {

  if(newDesing) {
    return (
      <div
        className={`side-modal-information ${!!props.isOpen ? 'open' : 'closed'}`}
      >
        <div className='side-modal-information-body'>
          {props.isOpen && children}
        </div>
      </div>
    );
  }

  return (
    <div className={`side-modal-modal 
      ${!!props.isOpen ? 'open' : 'closed'}
      ${!!props.className ? props.className : ''}`} 
    >
      <div className="d-flex flex-column side-modal-modal-body">
        <Header title={title} description={description} seeMoreUrl={seeMoreUrl} onClose={props.onRequestClose} {...props}/>

        <div className="modal__body">
          {props.isOpen && children}
        </div>
      </div>
    </div>
  )
}

Modal.propTypes = {
  isOpen: PropsTypes.bool.isRequired,
  title: PropsTypes.string,
  className: PropsTypes.string,
  onRequestClose: PropsTypes.func.isRequired,
  testId: PropsTypes.string,
  onConfirm: PropsTypes.func,
}

export default Modal;
import React from "react";
import { useSelector } from "react-redux";
import { I18n } from "aws-amplify";

import db from "../../../database/database";
import { companySelector } from "../../../selectors/company";

const DownloadIBDInfo = () => {
  const company = useSelector(companySelector)

  const exportData = async () => {
    try {
      const data = await db.invoices.toArray();

      const jsonString = JSON.stringify(data);

      return jsonString;
    } catch (error) {
      console.error("Error fetching data from IndexedDB:", error);
    }
  };

  const downloadData = (data, filename, mimeType) => {
    const blob = new Blob([data], { type: mimeType });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");

    link.href = url;
    link.download = filename;
    link.click();

    setTimeout(() => {
      URL.revokeObjectURL(url);
    }, 100);
  };

  const handleExport = async () => {
    const jsonString = await exportData();
    downloadData(jsonString, "exported-invoices.json", "application/json");
  };

  const handleCompanyExport = async () => {
    downloadData(JSON.stringify(company), "exported-company.json", "application/json");
  }

  return (
    <>
      <button
        type="button"
        onClick={handleExport}
        className="btn btn-menu py-2"
      >
        {I18n.get("download", "Descargar")}
      </button>
      <button
        type="button"
        onClick={handleCompanyExport}
        className="btn btn-menu py-2"
      >
        {I18n.get('myCompany')}
      </button>
    </>
  );
};

export default DownloadIBDInfo;

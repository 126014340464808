import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { I18n } from '@aws-amplify/core';
import {
  downloadFile,
  formatDate,
  getLegalStatus,
  getRefundTypeLabel,
} from '../utils';
import { DOCUMENT_TYPES } from '../../../utils/enums/documentTypes';
import { useRefundQuery } from '../queries';
import { useTicketBaiInformation } from '../../../hooks/useTicketBaiElegible';
import { Loader, Tag } from '@alegradev/smile-ui-react';
import { REFUND_METHODS } from '../../../utils/enums/refundMethods';

/**
 * Spain-specific RefundDetails Component.
 *
 * @param {Object} props - Component props.
 * @param {Object} props.refund - The refund object.
 * @param {string} props.statusColor - CSS class for status color.
 * @param {string} props.country - Country code.
 * @param {string} props.dateFormat - Date format string.
 * @returns {JSX.Element} - Rendered component for Spain.
 */
const RefundDetailsSpain = React.memo(
  ({ refund, statusColor, country, dateFormat }) => {
    const documentType = refund.numberTemplate.documentType;
    const legalStatus = useMemo(() => getLegalStatus(refund), [refund]);
    const isElectronicDocument = useMemo(
      () => refund.numberTemplate.isElectronic,
      [refund]
    );

    const { isTicketBaiEnabled } = useTicketBaiInformation();

    const { data: additionalRefundData, isFetching } = useRefundQuery(
      refund.id,
      isTicketBaiEnabled
    );
    
    return (
      <>
        <tr>
          <td className='caption-bold'>
            {I18n.get('emissionStatus', 'Estado de emisión BATUZ	')}
          </td>
          <td className={`emission-status-${statusColor}`}>
            <div
              className={`badge badge-pill m-0 font-weight-normal text-capitalize-first p-2 px-3 badge-${statusColor}`}
            >
              {I18n.get(legalStatus)}
            </div>
          </td>
        </tr>
        {refund.stamp?.date && (
          <tr>
            <td className='caption-bold'>
              {I18n.get('emissionDate', 'Concepto')}
            </td>
            <td>{formatDate(refund.stamp.date, dateFormat)}</td>
          </tr>
        )}
        {refund.creditNoteOperationType && (
          <tr>
            <td className='caption-bold'>
              {I18n.get('operationType', 'Tipo de operación')}
            </td>
            <td>
              {I18n.get(
                REFUND_METHODS[refund.creditNoteOperationType],
                'Factura rectificativa en factura simplificada'
              )}
            </td>
          </tr>
        )}
        <tr>
          <td className='caption-bold'>
            {documentType === DOCUMENT_TYPES.CREDIT_NOTE
              ? I18n.get(
                  'rectificativeInvoiceType',
                  'Tipo de factura rectificativa'
                )
              : I18n.get('refundType', 'Tipo de devolución')}
          </td>{' '}
          <td>{getRefundTypeLabel(refund, country)}</td>
        </tr>
        {refund.cause && (
          <tr>
            <td className='caption-bold'>
              {I18n.get('reasonRefund', 'Razón')}
            </td>
            <td>{refund.cause}</td>
          </tr>
        )}
        {(refund.stamp?.warnings || []).length > 0 && (
          <tr>
            <td className='caption-bold'>
              {I18n.get('notifications', 'Notificaciones')}
            </td>
            <td>
              {refund.stamp.warnings.map((warning, index) => (
                <span className='d-block' key={index}>
                  {warning.replace(/^"|"$/g, '')}
                </span>
              ))}
            </td>
          </tr>
        )}
        {isTicketBaiEnabled && isElectronicDocument && (
          <>
            <tr>
              <td className='caption-bold'>
                {I18n.get('xmlDocument', 'Documento XML de la factura')}
              </td>
              <td>
                {isFetching ? (
                  <Loader
                    emphasis='component'
                    orientation='horizontal'
                    size='medium'
                    title=''
                  />
                ) : (
                  <div
                    className='pointer'
                    onClick={() =>
                      downloadFile(
                        additionalRefundData?.stampFiles?.xml,
                        `${I18n.get(`electronic.${documentType}`, documentType)} ${refund.numberTemplate.fullNumber}.xml`
                      )
                    }
                  >
                    <Tag
                      label={`${I18n.get(`electronic.${documentType}`, documentType)} ${refund.numberTemplate.fullNumber}.xml`}
                      type='slate'
                      leadingIcon='file-type-xml'
                    />
                  </div>
                )}
              </td>
            </tr>
            <tr>
              <td className='caption-bold'>
                {I18n.get('roeDocument', 'Documento XML de la LROE')}
              </td>
              <td>
                {isFetching ? (
                  <Loader
                    emphasis='component'
                    orientation='horizontal'
                    size='medium'
                    title=''
                  />
                ) : (
                  <div
                    className='pointer'
                    onClick={() =>
                      downloadFile(
                        additionalRefundData?.stampFiles?.lroeXml,
                        `${I18n.get(`electronic.${documentType}`, documentType)} ${refund.numberTemplate.fullNumber}_LROE.xml`
                      )
                    }
                  >
                    <Tag
                      label={`${I18n.get(`electronic.${documentType}`, documentType)} ${refund.numberTemplate.fullNumber}_LROE.xml`}
                      type='slate'
                      leadingIcon='file-type-xml'
                    />
                  </div>
                )}
              </td>
            </tr>
            <tr>
              <td className='caption-bold'>
                {I18n.get('responseDocument', 'Documento XML de la respuesta')}
              </td>
              <td>
                {isFetching ? (
                  <Loader
                    emphasis='component'
                    orientation='horizontal'
                    size='medium'
                    title=''
                  />
                ) : (
                  <div
                    className='pointer'
                    onClick={() =>
                      downloadFile(
                        additionalRefundData?.stampFiles?.governmentResponseXml,
                        `${I18n.get(`electronic.${documentType}`, documentType)} ${refund.numberTemplate.fullNumber}_RESPONSE.xml`
                      )
                    }
                  >
                    <Tag
                      label={`${I18n.get(`electronic.${documentType}`, documentType)} ${refund.numberTemplate.fullNumber}_RESPONSE.xml`}
                      type='slate'
                      leadingIcon='file-type-xml'
                    />
                  </div>
                )}
              </td>
            </tr>
          </>
        )}
      </>
    );
  }
);

RefundDetailsSpain.propTypes = {
  refund: PropTypes.shape({
    cause: PropTypes.string,
    stamp: PropTypes.shape({
      date: PropTypes.string,
      cude: PropTypes.string,
      cufe: PropTypes.string,
      warnings: PropTypes.arrayOf(PropTypes.string),
    }),
    type: PropTypes.string,
  }).isRequired,
  statusColor: PropTypes.string.isRequired,
  country: PropTypes.string.isRequired,
  dateFormat: PropTypes.string,
};

export default RefundDetailsSpain;

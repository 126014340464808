import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addTotalToCollect } from '../../reducers/totalToCollect';
import ReportsAPI from '../../reducers/balanceByClient';
import { allTotalToCollectData } from '../../selectors/totalToCollect';
import { useConnectionStatus } from '../useConnectionStatus';
import { get } from 'lodash';
import { showCreditLimitField } from '../../pages/contacts/utils/limitCredit';

/**
 * @function useTotalToCollect
 * @description Hook to get the total to collect balance for a client.
 * @param {Object} props
 * @param {String} props.country
 * @param {Object} props.client the client object
 * @param {Boolean} props.oldModal whether the modal is from the old version
 * @param {Boolean} props.isOpen whether the modal is open
 * @returns {Object} an object with the total to collect balance and a boolean indicating whether it's loading
 */
const useTotalToCollect = ({
  country,
  client,
  oldModal = false,
  isOpen = false,
  balaceByClientRef,
}) => {
  const connectionStatus = useConnectionStatus();
  const [isTotalToCollectLoading, setIsTotalToCollectLoading] = useState(false);
  const [totalToCollect, setTotalToCollect] = useState(null);
  const allTotalToCollect = useSelector(allTotalToCollectData);

  const dispatch = useDispatch();
  const getLocalBalance = (clientId) => {
    const foundItem = allTotalToCollect.find((item) => item.id === clientId);
    return foundItem ? foundItem.totalToCollect : null;
  };

  const getBalanceByClient = async () => {
    setIsTotalToCollectLoading(true);
    try {
      let amount = null;
      const clientId = get(client, 'id', null);

      if (connectionStatus && clientId) {
        const response = await ReportsAPI.get(
          `client_id=${clientId}&balance_type=totalToCollect`
        );
        amount = get(response, 'data.totalToCollect', null);

        dispatch(
          addTotalToCollect({
            id: clientId,
            totalToCollect: amount,
          })
        );
      } else {
        amount = getLocalBalance(clientId);
      }

      setTotalToCollect(amount);
    } catch (error) {
      console.error('Error en el proceso:', error);
      const clientId = get(client, 'id', null);
      setTotalToCollect(getLocalBalance(clientId));
    } finally {
      setIsTotalToCollectLoading(false);
    }
  };

  useEffect(() => {
    if (
      ((oldModal && isOpen && !balaceByClientRef?.current) ||
        !balaceByClientRef?.current) &&
      showCreditLimitField(country)
    ) {
      getBalanceByClient();
      if (!balaceByClientRef?.current) {
        balaceByClientRef.current = true;
      }
    }
  }, [oldModal, isOpen, country, getBalanceByClient]);

  return { totalToCollect, isTotalToCollectLoading };
};

export default useTotalToCollect;

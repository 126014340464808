import { createDraftSafeSelector } from '@reduxjs/toolkit';
import { get } from 'lodash';

import { calculateItemsValues } from '../utils'
import { companySelector, country, decimalPrecision, membershipPlanSelector, membershipSelector } from './company';

const itemsSelector = state => get(state, 'editInvoice.items');
const clientSelector = state => get(state, 'editInvoice.client');
const priceListSelector = state => get(state, 'editInvoice.priceList');
const currencySelector = state => get(state, 'editInvoice.currency');
const numerationSelector = state => get(state, 'editInvoice.numeration');

export const client = createDraftSafeSelector(
  clientSelector,
  client => !!client ? client : null
)

export const priceList = createDraftSafeSelector(
  priceListSelector,
  priceList => !!priceList ? priceList : null
)

export const currency = createDraftSafeSelector(
  currencySelector,
  currency => !!currency ? currency : null
)

export const numeration = createDraftSafeSelector(
  numerationSelector,
  numeration => !!numeration ? numeration : null
)

export const items = createDraftSafeSelector(
  itemsSelector,
  items => !!items ? items : []
)

export const itemsCount = createDraftSafeSelector(
  itemsSelector,
  items => !!items 
    ? items
        .map(item => +get(item, 'quantity', 0))
        .reduce((prev, curr) => prev + curr, 0)
    : 0
)

export const itemQuantity = itemId => createDraftSafeSelector(
  itemsSelector,
  items => !!items 
    ? items
        .filter(i => i.id === itemId)
        .map(item => +get(item, 'quantity', 0))
        .reduce((prev, curr) => prev + curr, 0)
    : 0
)

export const itemByIndex = index => createDraftSafeSelector(
  itemsSelector,
  items => !!items ? items[index]: null
)

export const subtotal = createDraftSafeSelector(
  [itemsSelector, decimalPrecision, country, companySelector, membershipSelector, membershipPlanSelector],
  (items, decimal, country, companySelector, membershipSelector, membershipPlanSelector) => !!items 
    ? calculateItemsValues({items, decimal, country, company: companySelector, membership: Object.keys(membershipSelector) > 0 ? membershipSelector : membershipPlanSelector}).subtotal
    : 0
)

export const discount = createDraftSafeSelector(
  [itemsSelector, decimalPrecision, country, companySelector, membershipSelector, membershipPlanSelector],
  (items, decimal, country, companySelector, membershipSelector, membershipPlanSelector) => !!items 
    ? calculateItemsValues({items, decimal, country, company: companySelector, membership: Object.keys(membershipSelector) > 0 ? membershipSelector : membershipPlanSelector}).discount
    : 0
)

export const discSubtotal = createDraftSafeSelector(
  [itemsSelector, decimalPrecision, country, companySelector, membershipSelector, membershipPlanSelector],
  (items, decimal, country, companySelector, membershipSelector, membershipPlanSelector) => !!items 
    ? calculateItemsValues({items, decimal, country, company: companySelector, membership: Object.keys(membershipSelector) > 0 ? membershipSelector : membershipPlanSelector}).discSubtotal
    : 0
)

export const taxes = createDraftSafeSelector(
  [itemsSelector, decimalPrecision, country, companySelector, membershipSelector, membershipPlanSelector],
  (items, decimal, country, companySelector, membershipSelector, membershipPlanSelector) => !!items 
    ? calculateItemsValues({items, decimal, country, company: companySelector, membership: Object.keys(membershipSelector) > 0 ? membershipSelector : membershipPlanSelector}).taxes
    : 0
)

export const total = createDraftSafeSelector(
  [itemsSelector, decimalPrecision, country, companySelector, membershipSelector, membershipPlanSelector],
  (items, decimal, country, companySelector, membershipSelector, membershipPlanSelector) => !!items 
    ? calculateItemsValues({items, decimal, country, company: companySelector, membership: Object.keys(membershipSelector) > 0 ? membershipSelector : membershipPlanSelector}).total
    : 0
)

export const settings = createDraftSafeSelector(
  [currency, priceList, numeration],
  (currency, priceList, numeration) => ({
    currency,
    priceList,
    numeration
  })
)
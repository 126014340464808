import BasicData from './BasicData'; 
import NewBasicData from './NewBasicData'; 
import RapidData from './RapidData'; 
import AdvancedData from './AdvancedData'; 
import AdicionalFields from './AdicionalFields'; 
import Accounting from './Accounting'; 
import Inventory from './Inventory'; 
import NewInventory from './NewInventory'; 
import PriceLists from './PriceLists'; 
import Image from './Image'; 
import SelectType from './SelectType'; 
import Kit from './Kit'; 
import Variants from './Variants'; 
import ElectronicFields from './ElectronicFields'; 
import EditBasicData from './EditBasicData';

const itemFormParts = {
  BasicData,
  NewBasicData,
  RapidData,
  AdvancedData,
  AdicionalFields,
  Accounting,
  Inventory,
  NewInventory,
  PriceLists,
  Image,
  SelectType,
  Kit,
  Variants,
  ElectronicFields,
  EditBasicData,
}

export default itemFormParts;
import React from 'react';

import Station from '../../forms/stationSettings/Station';

const FormBody = ({ step, ...props }) => (
  <>
    {step === 1 && (
      <div className="d-flex flex-column p-4 border-bottom">
        <div className="form-body w-100 p-3 overflow-hidden">
          <Station.Bank {...props}/>
        </div>
      </div>
    )}

    {step === 2 && (
      <div className="d-flex flex-column p-4 border-bottom">
        <div className="form-body w-100 p-3 overflow-hidden">
          <Station.Numerations {...props}/>
        </div>
      </div>
    )}

    {step === 3 && (
      <div className="d-flex flex-column p-4 border-bottom">
        <div className="form-body w-100 p-3 overflow-hidden">
          <Station.Warehouse {...props}/>
        </div>
      </div>
    )}
    
    {step === 4 && (
      <div className="d-flex flex-column p-4 border-bottom">
        <div className="form-body w-100 p-3 overflow-hidden">
          <Station.OpenShift {...props}/>
        </div>
      </div>
    )}
  </>
)

export default FormBody;

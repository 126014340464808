import React from "react";
import PropsTypes from "prop-types";
import { I18n } from "@aws-amplify/core";
import { Field, Form } from "react-final-form";
import { renderSelect } from "../../forms/fields/V0/Fields";

import { Icon } from '@alegradev/smile-ui-react';
import { IconArrowLeft, IconArrowRight, IconRefresh } from "@tabler/icons-react";

const Pagination = ({ previousPage, handleChangeIndex, nextPage, pageIndex, pageOptions, canPreviousPage, canNextPage, onRefresh, handleChangePageSize, pageSize, results, total }) => {

  const allPages = () => {
    return pageOptions.map(page => ({
      value: page + 1,
      label: (page + 1).toString(),
    }))
  }

  return (
    <div className="react-table-simplified__pagination bg-white">
      <div className="react-table-simplified__pagination-container">

        <div className="d-flex align-items-center mx-3">
          <div className="d-flex align-items-center mr-4">
            <div className="pagination-description text-capitalize-first text-break description mr-3">{I18n.get("page", "Página")}</div>
            <div className="mr-3" style={{ width: "7rem" }}>
              <Form onSubmit={{}} keepDirtyOnReinitialize>
                {() => (
                  <Field
                    name="pageNumber"
                    placeholder={pageIndex + 1}
                    component={renderSelect}
                    options={allPages()}
                    className="m-0 pages"
                    menuPosition="absolute"
                    getOptionLabel={option => option.label}
                    getOptionValue={option => option.value}
                    onChange={option => handleChangeIndex(null, option.value - 1)}
                    height="2.5rem"
                  />
                )}
              </Form>
            </div>
            <div className="text-break description">{`${I18n.get("of", "de")} ${pageOptions.length}`}</div>
          </div>

          <div className="carrent-left d-flex mr-4">
            <div className="carrent__simple" onClick={canPreviousPage ? previousPage : null}>
              <Icon icon={IconArrowLeft} color={canPreviousPage ? "#00B19D" : "#CCCCCC"} />
            </div>
          </div>

          <div className="carrent-right d-flex mr-4">
            <div className="carrent__simple" onClick={canNextPage ? nextPage : null}>
              <Icon icon={IconArrowRight} color={canNextPage ? "#00B19D" : "#CCCCCC"} />
            </div>
          </div>

          <div className="reload" onClick={() => onRefresh()}>
            <Icon icon={IconRefresh} color="#00B19D" />
          </div>

        </div>

      </div>
    </div>
  );
};

Pagination.propTypes = {
  previousPage: PropsTypes.func,
  handleChangeIndex: PropsTypes.func,
  handleChangePageSize: PropsTypes.func,
  nextPage: PropsTypes.func,
  pageIndex: PropsTypes.number,
  pageOptions: PropsTypes.any,
  canPreviousPage: PropsTypes.bool,
  canNextPage: PropsTypes.bool,
  handleGotoPage: PropsTypes.func,
  pageSize: PropsTypes.number,
  results: PropsTypes.number,
  total: PropsTypes.number,
};

export default Pagination;

import { createContext, useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';

export const NavigationMenuContext = createContext({
  menu: {
    name: '',
    expandable: false,
    submenu: '',
  },
  currentMenu: '',
  setMenuOptions: () => null,
});

export const NavigationProvider = ({ children }) => {
  const [currentMenu, setCurrentMenu] = useState('');
  const location = useLocation();
  const [menu, setMenu] = useState({
    name: '',
    expandable: false,
    submenu: '',
  });

  const handleMenu = (options) => {
    if (options?.expandable) {
      setMenu({ ...menu, ...options });
    } else {
      if (!options?.isSubItem) {
        setCurrentMenu(options?.name);
      } else {
        setCurrentMenu(menu?.name);
      }
      setMenu({ ...menu, ...options });
    }
  };

  useEffect(() => {
    if (location.pathname && !menu.name) {
      const splitPathName = location?.pathname?.split('/');
      setCurrentMenu(`/${splitPathName[1]}` ?? '/');
      setMenu({
        ...menu,
        name: `/${splitPathName[1]}` ?? '/',
        submenu: splitPathName[2] ? location.pathname : '',
        expandable: !!splitPathName[2],
      });
    }
  }, [location, menu]);

  return (
    <NavigationMenuContext.Provider
      value={{ menu, currentMenu, setMenuOptions: handleMenu }}
    >
      {children}
    </NavigationMenuContext.Provider>
  );
};

export const useNavigationMenu = () => {
  return useContext(NavigationMenuContext);
};

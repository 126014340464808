import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { useConnectionStatus } from '../../hooks/useConnectionStatus';
import { fetchRefunds } from '../../reducers/refunds';
import { hasPermissionTo } from '../../selectors/auth';
import { country as countrySelector } from '../../selectors/company';
import { refunds, loadingSelector, errorSelector, totalSelector } from '../../selectors/refunds';
import MasterDetail from '../common/DetailView';
import Refund from './Refund';
import DetailHeader from './DetailHeader';
import DetailBody from './DetailBody';
import NewDetailHeader from './new/DetailHeader';
import NewDetailBody from './new/DetailBody';
import EmptyDetail from './EmptyDetail';
import ErrorElement from './ErrorElement';
import NoSelectedElement from './NoSelectedElement';
import PaginationHeader from './PaginationHeader';
import NotAllowedToView from './NotAllowedToView';
import NotAllowedToIndex from './NotAllowedToIndex';
import PageNetworkMessage from '../network/PageNetworkMessage';
import { COUNTRIES } from '../../utils/enums/countries';

/**
 * Refunds Component
 *
 * Manages and displays a list of refunds with detailed views and action capabilities.
 * Handles data fetching, selection, and conditional rendering based on user permissions
 * and connectivity status.
 *
 * @component
 * @returns {JSX.Element} The rendered Refunds component.
 */
const Refunds = () => {
  const connectionStatus = useConnectionStatus();
  const dispatch = useDispatch();
  const location = useLocation();
  const can = useSelector(hasPermissionTo);
  const country = useSelector(countrySelector);

  const ref = useRef(null);
  const [selected, setSelected] = useState(null);
  const [metadata, setMetadata] = useState({
    start: 0,
    limit: 20,
    sortDirection: 'desc',
  });

  const { refundId } = location.state || {};
  const elements = useSelector(refunds);
  const loading = useSelector(loadingSelector);
  const error = useSelector(errorSelector);
  const total = useSelector(totalSelector);
  const selectedRefund = selected === -1 ? null : elements[selected];

  /**
   * Initializes the selected refund based on the refundId from location state.
   */
  useEffect(() => {
    if (refundId) {
      const index = elements.findIndex(
        (refund) => refund.id === refundId.toString()
      );
      setSelected(index);
    }
  }, [refundId, elements]);

  useEffect(() => {
    ref.current = true;
    window.dataLayer.push({
      event: 'VirtualPageview',
      virtualPageURL: '/refunds',
      virtualPageTitle: 'Refunds',
    });

    return () => {
      ref.current = false;
    };
  }, []);

  /**
   * Fetches refunds data based on the provided batch parameters.
   * Dispatches the fetchRefunds action if the user has the necessary permissions.
   * Updates the metadata state if the component is still mounted.
   *
   * @param {Object} batch - The batch parameters for fetching refunds.
   * @param {number} batch.start - The starting index for fetching.
   * @param {number} batch.limit - The number of refunds to fetch.
   * @param {string} batch.sortDirection - The sort direction ('asc' or 'desc').
   */
  const fetchData = useCallback(
    async (batch) => {
      if (can('view', 'credit-notes') && can('index', 'credit-notes')) {
        await dispatch(fetchRefunds(batch));
      }

      if (ref.current) {
        setMetadata(batch);
      }
    },
    [dispatch, can]
  );

  /**
   * Fetches the initial batch of refunds when the component mounts.
   */
  useEffect(() => {
    fetchData({
      start: 0,
      limit: 20,
      sortDirection: 'desc',
    });
  }, [fetchData]);

  /**
   * Memoizes the list of refund elements to prevent unnecessary re-renders.
   */
  const refundOptions = useMemo(
    () =>
      elements.map((option, index) => (
        <Refund
          key={option.id}
          refund={option}
          idSelected={selectedRefund ? selectedRefund.id : null}
          onSelect={() => setSelected(index)}
        />
      )),
    [elements, selectedRefund]
  );

  /**
   * Memoizes the detailHeader component based on the country and selected refund.
   */
  const detailHeaderComponent = useMemo(() => {
    if ([COUNTRIES.COLOMBIA, COUNTRIES.SPAIN].includes(country)) {
      return (
        <NewDetailHeader
          refund={selectedRefund}
          onRefresh={() => fetchData(metadata)}
          onListView={() => setSelected(-1)}
        />
      );
    }
    return (
      <DetailHeader
        refund={selectedRefund}
        onListView={() => setSelected(-1)}
      />
    );
  }, [country, selectedRefund, fetchData, metadata]);

  /**
   * Memoizes the detailChildren component based on the country and selected refund.
   */
  const detailChildrenComponent = useMemo(() => {
    if ([COUNTRIES.COLOMBIA, COUNTRIES.SPAIN].includes(country)) {
      return <NewDetailBody refund={selectedRefund} />;
    }
    return <DetailBody refund={selectedRefund} />;
  }, [country, selectedRefund]);

  /**
   * Handles the rendering of not allowed components based on permissions.
   */
  const notAllowedComponents = useMemo(() => {
    if (!can('view', 'credit-notes')) {
      return {
        notAllowedToIndex: <NotAllowedToIndex />,
        notAllowedToView: <NotAllowedToView />,
      };
    }
    return {
      notAllowedToIndex: null,
      notAllowedToView: null,
    };
  }, [can]);

  if (!connectionStatus) {
    return <PageNetworkMessage />;
  }

  return (
    <div className="refunds d-flex">
      <MasterDetail
        loading={loading}
        error={error}
        selected={!!selectedRefund}
        options={refundOptions}
        onFetchData={fetchData}
        paginationData={{ ...metadata, total }}
        paginationHeader={<PaginationHeader />}
        detailHeader={detailHeaderComponent}
        detailChildren={detailChildrenComponent}
        detailClassName={selectedRefund ? 'item-selected' : ''}
        emptyElement={<EmptyDetail />}
        errorElement={<ErrorElement onRefresh={() => fetchData(metadata)} />}
        noSelectedElement={<NoSelectedElement />}
        notAllowedToIndex={notAllowedComponents.notAllowedToIndex}
        notAllowedToView={notAllowedComponents.notAllowedToView}
      />
    </div>
  );
};

export default React.memo(Refunds);

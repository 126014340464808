// import dayjs from "dayjs";

// import { useSelector } from "react-redux"
// import { companySelector } from "../../selectors/company"

export const useCheckTaxesSummaryAvailability = () => {
  // const groupADate = '2021-12-31';
  // const groupBDate = '2023-01-01';
  // const { registryDate } = useSelector(companySelector);

  // return dayjs(registryDate).isBefore(groupBDate);
  return true;
}
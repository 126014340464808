import React from 'react'
import { I18n } from '@aws-amplify/core';
import { Field } from 'react-final-form'
import { capitalize } from 'lodash'

import { renderField } from '../fields/V0/Fields'

const RenamePendingInvoice = () => (
  <div className="form-body h-100 p-4 overflow-hidden">
    <div className="form-body__fields form-row justify-content-start">

      <Field
        name="name"
        className="col-md-8"
        component={renderField}
        type="text"
        label={capitalize(I18n.get('name', 'Nombre'))}
        required
      />

    </div>
  </div>
);

export default RenamePendingInvoice
// eslint-disable-next-line
export default ({ id, className }) => (
  <svg
    id={id}
    className={className}
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M 14.210938 10.636719 C 14.210938 8.8125 15.679688 7.332031 17.488281 7.332031 L 30.054688 7.332031 C 31.867188 7.332031 33.332031 8.8125 33.332031 10.636719 L 33.332031 29.363281 C 33.332031 31.1875 31.867188 32.667969 30.054688 32.667969 L 17.488281 32.667969 C 15.679688 32.667969 14.210938 31.1875 14.210938 29.363281 Z M 18.308594 12.839844 C 18.308594 11.929688 19.042969 11.1875 19.949219 11.1875 L 22.132812 11.1875 C 23.039062 11.1875 23.773438 11.929688 23.773438 12.839844 C 23.773438 13.753906 23.039062 14.492188 22.132812 14.492188 L 19.949219 14.492188 C 19.042969 14.492188 18.308594 13.753906 18.308594 12.839844 Z M 18.308594 19.726562 C 18.308594 18.8125 19.042969 18.074219 19.949219 18.074219 L 27.597656 18.074219 C 28.503906 18.074219 29.234375 18.8125 29.234375 19.726562 C 29.234375 20.636719 28.503906 21.375 27.597656 21.375 L 19.949219 21.375 C 19.042969 21.375 18.308594 20.636719 18.308594 19.726562 Z M 18.308594 19.726562 "
      fill="#00D6BC" />
    <path d="M 8.824219 11.144531 L 12.574219 9.769531 L 12.574219 31.1875 L 6.867188 15.378906 C 6.246094 13.664062 7.125 11.769531 8.824219 11.144531 Z M 8.824219 11.144531 "
      fill="#00D6BC" />
  </svg>

)
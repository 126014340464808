export const regimes = [
  {
    id: "0",
    display: "No responsable del IVA",
    value: "Régimen simplificado",
    key: "SIMPLIFIED_REGIME",
    electronicInvoicingVersion: "2.0"
  },
  {
    id: "2",
    display: "Responsable del IVA",
    value: "Régimen común",
    key: "COMMON_REGIME",
    electronicInvoicingVersion: "2.0"
  },
  {
    id: 3,
    code: '4',
    dianId: '01',
    display: 'Responsable de IVA',
    electronicInvoicingVersion: '2.1',
    key: 'COMMON_REGIME',
    listNameDian: '48',
    nameDian: 'IVA',
    value: 'Régimen común',
  },
  {
    id: 4,
    code: '5',
    dianId: 'ZZ',
    display: 'No responsable de IVA',
    electronicInvoicingVersion: '2.1',
    key: 'SIMPLIFIED_REGIME',
    listNameDian: '49',
    nameDian: 'No aplica',
    value: 'Régimen simplificado',
  },
  {
    id: 7,
    code: '8',
    dianId: '04',
    display: 'Impuesto Nacional al Consumo - INC',
    electronicInvoicingVersion: '2.1',
    key: 'NATIONAL_CONSUMPTION_TAX',
    listNameDian: '49',
    nameDian: 'INC',
    value: 'No responsable del IVA',
  },
  {
    id: 5,
    code: '6',
    dianId: 'ZZ',
    display: 'No responsable de INC',
    electronicInvoicingVersion: '2.1',
    key: 'NOT_REPONSIBLE_FOR_CONSUMPTION',
    listNameDian: '49',
    nameDian: 'No aplica',
    value: 'No responsable del consumo',
  },
  {
    id: 8,
    code: '9',
    dianId: 'ZA',
    display: 'Responsable de IVA e INC',
    electronicInvoicingVersion: '2.1',
    key: 'INC_IVA_RESPONSIBLE',
    listNameDian: '48',
    nameDian: 'IVA e INC',
    value: 'Responsable de IVA e INC',
  },
  {
    id: 6,
    code: '7',
    dianId: '01',
    display: 'Régimen especial',
    electronicInvoicingVersion: '2.1',
    key: 'SPECIAL_REGIME',
    listNameDian: '48',
    nameDian: 'IVA',
    value: 'Régimen especial',
  },
];
import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux';
import { get } from 'lodash';
import { I18n } from '@aws-amplify/core';
import { Field } from 'react-final-form';

import Label from '../../common/Label';
import { renderSelect } from '../fields/V0/Fields';
import { country as countrySelector } from '../../../selectors/company';
import { languageSelector } from '../../../selectors/auth';
import configAPI from '../../../reducers/configAPI';

const GeneralInfo = ({ form, values }) => {
  const [sectors, setSectors] = useState([])
  const [isLoading, setLoading] = useState(true)
  const [errors, setErrors] = useState(0)
  const country = useSelector(countrySelector)
  const language = useSelector(languageSelector)

  const change = form.change

  useEffect(() => {
    const loadData = async () => {
      if (!get(sectors, 'length') && errors < 5) {
        try {
          setLoading(true)
          // ex queries.allCompanySectors

          const response = await configAPI.get('/sectors', {
            application_version: country,
            lang: language.slice(0, 2),
          })
          setLoading(false)

          const allSectors = get(response, 'data', [])
          setSectors(allSectors)

          if (!values.sector)
            change('sector', allSectors.find(s => get(s, 'key') === 'RETAIL') || allSectors[0])
        } catch {
          setLoading(false)
          setErrors(errors + 1)
        }
      }

    }

    loadData()
  }, [errors, sectors, country, language, change, values.sector])

  return (
    <div className="form-body__fields form-row justify-content-start pt-2">
      <div className="w-100">
        <div className="form-row" style={{ margin: "0 -1.5rem" }}>
          <div className="col-md-4 px-4">
            <Label title={I18n.get('sector', 'Sector')} />
            <Field
              name="sector"
              isLoading={isLoading}
              options={sectors}
              getOptionLabel={option => option.value}
              getOptionValue={option => option.key}
              component={renderSelect}
            />
          </div>
          <div className="col-md-4 px-4">
            <Label title={I18n.get('decimalPrecision', 'Precisión decimal')} required />
            <Field
              name="decimalPrecision"
              options={['0', '1', '2', '3', '4'].map(decimals => ({ key: decimals, label: decimals }))}
              getOptionValue={option => option.key}
              component={renderSelect}
              required
            />
          </div>
          <div className="col-md-4 px-4">
            <Label title={I18n.get('decimalSeparator', 'Separador decimal')} required />
            <Field
              name="decimalSeparator"
              options={['.', ','].map(separators => ({ key: separators, label: separators }))}
              getOptionValue={option => option.key}
              value={{ input: values.decimalSeparator }}
              component={renderSelect}
              required
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default GeneralInfo;
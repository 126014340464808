import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { Field } from 'react-final-form';
import { I18n } from '@aws-amplify/core';

import { clear } from '../../../../reducers/activeRefund';
import { Cash } from '../methods/Cash';
import { Combined } from '../methods/Combined';
import { CreditToSales } from '../methods/CreditToSales';
import { PositiveBalance } from '../methods/PositiveBalance';
import { renderSelect } from '../../fields/V0/Fields';

/**
 * List of available refund methods with their corresponding labels.
 */
const OPTIONS_METHODS = [
  {
    value: 'cash',
    label: I18n.get('refundCash', 'Devolución de dinero'),
  },
  {
    value: 'creditToSales',
    label: I18n.get('creditToSales', 'Crédito a ventas'),
  },
  {
    value: 'combined',
    label: I18n.get('combined', 'Combinado'),
  },
  {
    value: 'positiveBalance',
    label: I18n.get('positiveBalance', 'Saldo a favor'),
  },
];

/**
 * Mapping of refund methods to their corresponding components.
 */
const methodComponents = {
  cash: Cash,
  creditToSales: CreditToSales,
  combined: Combined,
  positiveBalance: PositiveBalance,
};

/**
 * ColombiaForm Component
 *
 * Manages the refund form specific to Colombia by rendering different components based on the selected refund method.
 *
 * @param {Object} props - Component properties.
 * @param {string} props.method - The selected refund method.
 * @param {Function} props.onChooseMethod - Callback invoked when a refund method is selected.
 * @param {Function} props.changeStep - Callback to change the current step in the refund process.
 * @param {Function} props.reset - Callback to reset the form.
 * @returns {JSX.Element} - The rendered ColombiaForm component.
 */
const ColombiaForm = ({ method, onChooseMethod, changeStep, reset }) => {
  const dispatch = useDispatch();

  /**
   * Computes the default value for the refund method select field.
   */
  const defaultValue = useMemo(() => {
    const selectedMethod = OPTIONS_METHODS.find(
      (item) => item.value === method
    );
    return selectedMethod
      ? { value: selectedMethod.value, label: selectedMethod.label }
      : { value: '', label: '' };
  }, [method]);

  /**
   * Handles the change event when a new refund method is selected.
   *
   * @param {Object} option - The selected option from the dropdown.
   * @returns {Object} - The selected option.
   */
  const handleMethodChange = useMemo(
    () => (option) => {
      if (option && option.value) {
        onChooseMethod(option.value);
        reset();
        dispatch(clear());
      }
      return option;
    },
    [onChooseMethod, reset, dispatch]
  );

  /**
   * Determines which refund method component to render based on the selected method.
   */
  const SelectedMethodComponent = useMemo(() => {
    return methodComponents[method] || null;
  }, [method]);

  return (
    <div className='h-100 mt-4'>
      <div className='refund-form-body'>
        <Field
          name='refund.method'
          component={renderSelect}
          options={OPTIONS_METHODS}
          defaultValue={defaultValue}
          getOptionLabel={(option) => option.label}
          getOptionValue={(option) => option.value}
          onChange={handleMethodChange}
          type='select'
          className='col-12 p-0 select-refund new-refund-input'
          required
          label={I18n.get('typeRefund', 'Tipo de devolución')}
        />

        {SelectedMethodComponent && (
          <SelectedMethodComponent changeStep={changeStep} />
        )}
      </div>
    </div>
  );
};

ColombiaForm.propTypes = {
  method: PropTypes.oneOf([
    'cash',
    'creditToSales',
    'combined',
    'positiveBalance',
  ]).isRequired,
  onChooseMethod: PropTypes.func.isRequired,
  changeStep: PropTypes.func.isRequired,
  reset: PropTypes.func.isRequired,
};

export default React.memo(ColombiaForm);

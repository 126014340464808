import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { get } from 'lodash';
import { I18n } from 'aws-amplify';

import useHappyWeekPopupValidator from '../../../hooks/useHappyWeekPopupValidator';
import { membershipSelector } from '../../../selectors/company';
import { isHappyWeekAvailable } from '../../../selectors/membership';
import { closeModal } from '../../../reducers/modals';
import { sendNewGTMEvent } from '../../../reducers/company';
import Modal from '../../common/Modal';

import mobileBanner from './mobile-banner.jpg';
import banner from './banner.jpg';

function HappyWeekExperiment() {
  const dispatch = useDispatch();
  const { showHappyWeekPopUp, userIsElegibleForExperiment } = useHappyWeekPopupValidator();
  const isOpen = useSelector((state) => get(state, 'modals.happyWeekPopup.isOpen', false));
  const membership = useSelector(membershipSelector);
  const happyWeekAvailability = useSelector(isHappyWeekAvailable);

  useEffect(() => {
    if (happyWeekAvailability && userIsElegibleForExperiment)
      showHappyWeekPopUp();
  }, [membership])

  const redirectToPlans = () => {
    window.open(`${process.env.REACT_APP_ALEGRA_SMILE}subscriptions/products/alegra-pos/plans`, '_blank');
    dispatch(sendNewGTMEvent('exp-pos-banner-hw-managed', { action: 'started-payment' }));
  }

  const close = () => {
    dispatch(closeModal({ modal: 'happyWeekPopup' }));
    dispatch(sendNewGTMEvent('exp-pos-banner-hw-managed', { action: 'skipped' }));
  }

  const redirectToTermsAndConditions = () => {
    window.open('https://docs.google.com/document/d/e/2PACX-1vSwAYIwmwXUt_NuUmm-Ry4H8MOV14ke7LjVENT14xCj1WcGrCqZEb-XcpQES1PTWx4Mz7AeS02GU4VU/pub', '_blank');
  }

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={close}
      includeHeader={false}
      className={'modal__happy-week-experiment modal__mobile-bottom'}
    >
      <div className="position-relative">
        <div className="modal__happy-week-experiment-lg d-none d-md-block">
          <p className="modal__happy-week-experiment-button-close" onClick={close} />
          <img src={banner} alt="happy week experiment banner" />
          <p className="modal__happy-week-experiment-button-info" onClick={redirectToPlans} />
          <p className="modal__happy-week-experiment-button-terms" onClick={redirectToTermsAndConditions} />
        </div>
        <div className="modal__happy-week-experiment-sm d-sm-flex d-md-none">
          <img src={mobileBanner} alt="happy week experiment banner" />
          <p className="modal__happy-week-experiment-button-info btn-md-default-filled" onClick={redirectToPlans}>
            {I18n.get('seePrices', 'Ver precios')}
          </p>
          <p className="modal__happy-week-experiment-button-close btn-md-default-outline" onClick={close}>
            {I18n.get('close', 'Cerrar')}
          </p>
          <p className="modal__happy-week-experiment-button-terms btn-md-default-outline" onClick={redirectToTermsAndConditions}>
            {I18n.get('termsConditions', 'Términos y condiciones')}
          </p>
        </div>
      </div>
    </Modal>
  );
}

export default HappyWeekExperiment
import { useEffect } from 'react'
import { Hub } from '@aws-amplify/core';
import { graphqlOperation } from '@aws-amplify/api';
import { useDispatch, useSelector } from 'react-redux'
import { get } from 'lodash'

import { getUser } from '../graphql/queries'
import { syncOffline } from '../reducers/activeInvoice'
import { setNetworkStatus } from '../reducers/app'
import { APIGraphqlSelector } from '../selectors/app';

const NetworkStatus = () => {
  const dispatch = useDispatch()
  const networkStatus = useSelector(state => get(state, 'app.networkStatus', 'online'))
  const APIGraphql = useSelector(APIGraphqlSelector);

  Hub.listen('network', (data) => {
    const newStatus = get(data, 'payload.data.status', 'online')
    if (networkStatus !== newStatus)
      dispatch(setNetworkStatus(newStatus))
  })

  useEffect(() => {
    if (networkStatus !== 'online') {
      let interval = setInterval(async () => {
        try {
          await APIGraphql(graphqlOperation(getUser))
          
          dispatch(setNetworkStatus('online'))
          clearInterval(interval)
          dispatch(syncOffline(true))
        } catch {
          //
        }
      }, 5000);
    }
  }, [dispatch, networkStatus, APIGraphql])

  return null
}

export default NetworkStatus;
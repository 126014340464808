import dayjs from 'dayjs';
import { COUNTRIES } from './enums/countries';

export const getDefaultCategoryIdPurchases = (categories, registryDate) => {
  const defaultCategoryName = dayjs(registryDate).isBefore('2023-05-16') 
  ? "Otros gastos generales" 
  : "Impuesto de servicio 10%"

  let defaultCategory = categories.filter(cat => cat.name === defaultCategoryName)[0]?.id
  
  return defaultCategory
}

export const getDefaultCategoryIdToCreateCharge = (categories, country, registryDate) => {
  switch (country) {
    case COUNTRIES.COSTA_RICA:
    {
      const defaultCategoryName = dayjs(registryDate).isBefore('2023-05-16') 
      ? "Otros pasivos corrientes" 
      : "Cuentas por pagar a saloneros y meseros"

      let defaultCategory = categories.filter(cat => cat.name === defaultCategoryName)[0]?.id
      
      return defaultCategory
    }
    case COUNTRIES.COLOMBIA:
    {
      let defaultCategory = categories.filter(cat => cat.name === "Otros pasivos corrientes")[0]?.id
      if (!defaultCategory) {
        defaultCategory = categories.filter(cat => cat.name === "Otras cuentas por pagar")[0]?.id
      }
      return defaultCategory
    }
  }
}
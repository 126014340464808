import React from 'react'
import { I18n } from 'aws-amplify';
import { replaceAndParse } from '../../../../utils'
import Notification from '../../common/Notification';
import { sendGTMEvent } from '../../../../reducers/company';
import { useDispatch } from 'react-redux';
import { getColombiaUVT } from '../../../../utils/enums/colombiaUVT';

import { Icon } from '@alegradev/smile-ui-react';
import { IconArrowRight } from '@tabler/icons-react';

const Activate5UVTOnboard = ({ nextStep, back, close, error, setError, activate5uvt }) => {
  const dispatch = useDispatch();
  const colombiaUVT = getColombiaUVT();

  const active = async () => {
    try {
      setError(null);
      nextStep();
      await activate5uvt();

      dispatch(sendGTMEvent("uvt-feature-enabled", {
        creationOrigin: "from pos wizard",
      }));
      nextStep();
    } catch (error) {
      back();
      setError(I18n.get('updateCompanyError', 'ocurrió un error al intentar actualizar los datos de la compañía'));
    }
  }
  
  return (
    <>
      <div className="d-flex flex-column justify-content-center align-items-center">
        <div className="mb-5 mt-4 px-5">
          <p className="h3 uvt-title text-capitalize-first font-weight-bold">
            {I18n.get('activate5UVTOnboard.title', '¡Cambio automático de documento al vender! ✨')}
          </p>
          <p className="h4 uvt-description text-capitalize-first text-subtitle">
            {replaceAndParse(I18n.get('activate5UVTOnboard.description', `Cumple con la DIAN activando el cambio automático de “Documento POS” a “Factura electrónica” en
  las <span class='font-weight-bold'>ventas que superen $${colombiaUVT?.UVT_FORMATED}</span> antes de IVA. Puedes desactivar esta opción cuando desees desde la configuración de tus facturas.`, []))}
          </p>

          <div className='d-flex '>
            <a href='https://ayuda.alegra.com/es/configura-sistema-pos-para-ventas-superiores-5-uvt-colombia' rel="noreferrer" target="_blank" className='h4 mb-0 mr-2' style={{lineHeight: 1.5}}>{I18n.get('activate5UVTOnboard.more', 'Conoce más sobre la regulación')}</a> 
            <Icon icon={IconArrowRight} extraClass="icon-primary2" width={20} />
          </div>
          
        </div>
      </div>

      {(!!error) && (
        <Notification isOpen={true} text={error} type="error" />
      )}
      <div className="modal__bottom w-100 p-3 d-flex justify-content-end align-items-center shadow">
        <div className="modal__bottom-buttons d-flex">
          <button
            type="button"
            className="btn btn-cancel btn-no-border text-capitalize-first"
            onClick={close}
          >
            {I18n.get('avoidForNow', 'Omitir por ahora')}
          </button>
          <button
            type="button"
            data-testid="modal-submit-btn"
            onClick={active}
            className="btn btn-submit text-capitalize-first"
          >
            {I18n.get('activateDocumentChange', 'Activar cambio de documento')}
          </button>
        </div>
      </div>
    </>
  )
}

export default Activate5UVTOnboard
import React from 'react';
import PropTypes from 'prop-types';
import { I18n } from '@aws-amplify/core';
import { calculateRefundType, getDateTime } from '../utilities';

/**
 * Default RefundInfo Component.
 *
 * @param {Object} props - Component props.
 * @param {Object} props.refund - The refund object.
 * @param {string} props.align - Alignment for text.
 * @returns {JSX.Element} - Rendered default component.
 */
const RefundInfoDefault = React.memo(({ refund, align }) => {
  const refundType = calculateRefundType(refund);
  const fullNumber = refund?.numberTemplate?.fullNumber ?? '';
  const dateTime = getDateTime(refund);
  const dueDate = refund?.dueDate ?? '';

  return (
    <div
      className={`w-100 text-${align} py-2 d-flex flex-column border-bottom`}
    >
      <p>
        <strong>{refundType}</strong> N° {fullNumber}
      </p>
      <p>
        <strong>{I18n.get('generationDate', 'Fecha de generación')}</strong>{' '}
        {dateTime}
      </p>
      {dueDate && (
        <p>
          <strong>{I18n.get('dueDate', 'vencimiento')}</strong> {dueDate}
        </p>
      )}
    </div>
  );
});

RefundInfoDefault.propTypes = {
  refund: PropTypes.shape({
    numberTemplate: PropTypes.shape({
      documentType: PropTypes.string,
      isElectronic: PropTypes.bool,
      fullNumber: PropTypes.string,
    }),
    dueDate: PropTypes.string,
  }).isRequired,
  align: PropTypes.string.isRequired,
};

export default RefundInfoDefault;

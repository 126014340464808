import { I18n } from '@aws-amplify/core';
import { IconFileInvoice, IconPlus } from '@tabler/icons-react';

function globalInvoicesEmptyState({ history }) {
  return {
    title: I18n.get(
      'global-empty-invoice',
      'Crea tu primera factura \n global en segundos. ⚡'
    ),
    description: null,
    generalIcon: IconFileInvoice,
    action: {
      icon: IconPlus,
      label: I18n.get('new-global-invoice', 'Nueva factura global'),
      handler: () => history.push('/global-invoices/invoice'),
    },
  };
}

export default globalInvoicesEmptyState;

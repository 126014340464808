import React, { useState } from 'react';
import { capitalize } from 'lodash';
import { Field } from 'react-final-form';
import { I18n } from '@aws-amplify/core';
import PropsTypes from "prop-types";

import { renderDate, renderGeneralSearchField } from '../../forms/fields/V0/Fields';

import { Icon } from '@alegradev/smile-ui-react';
import { IconChevronDown, IconFilter } from '@tabler/icons-react';

const FilterInventoryAdjustments = ({ resetFilters, form, setOpenFilters, openDetail }) => {
  const [openFilterMenu, setOpenFilterMenu] = useState(false);

  return (
    <div className="form-body h-100 w-100 overflow-hidden d-flex align-items-center mb-3"
      style={{ gap: "8px" }}
    >
      <Field
        name="number"
        className={`col-sm-${openDetail ? "6" : "4"} mb-0 p-0`}
        component={renderGeneralSearchField}
        type="text"
        placeholder={capitalize(`${I18n.get('search', 'buscar')}...`)}
      />
      <div className='d-flex flex-column'>
        <button
          className='btn-select-filter justify-content-between'
          type="button"
          style={{
            border: openFilterMenu ? "1px solid #00b19d" : "1px solid #cbd5e1"
          }}
          onClick={() => setOpenFilterMenu(!openFilterMenu)}
        >
          <Icon icon={IconFilter} />
          {capitalize(I18n.get('filter', 'Filtrar'))}
          <Icon icon={IconChevronDown} />
        </button>
        <div className={`${openFilterMenu ? 'd-block' : 'd-none'} container-filter-select`}>
          <div className="form-body h-100 w-100 overflow-hidden d-flex pt-2 pb-2">
            <Field
              name="date"
              component={renderDate}
              className="col-12"
              placeholder={I18n.get("dateFormatPlaceholder", "dd/mm/aaaa")}
              label={capitalize(I18n.get("date", "Fecha"))}
            />
          </div>
          <div className='d-flex justify-content-center align-items-center '
            style={{
              padding: "8px",
              gap: "8px"
            }}
          >
            <button
              className="btn btn-cancel w-100 d-flex justify-content-center align-items-center"
              type='button'
              onClick={() => {
                resetFilters()
                setOpenFilterMenu(false)
                setOpenFilters(false)
                form.reset()
              }}
              style={{
                color: 'black'
              }}
            >
              {capitalize(I18n.get("cleanFilters", "Limpiar Filtros"))}
            </button>
            <button
              className="btn btn-submit w-100 d-flex justify-content-center align-items-center"
              type='submit'
              onClick={() => {
                setOpenFilterMenu(false)
              }}
            >
              {capitalize(I18n.get("apply", "Aplicar"))}
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

FilterInventoryAdjustments.propTypes = {
  resetFilters: PropsTypes.func,
  setOpenFilters: PropsTypes.func
}

export default FilterInventoryAdjustments;
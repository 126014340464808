import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { APIGraphqlSelector } from '../../selectors/app';
import * as mutations from '../../graphql/mutations';
import { notificationsSelector } from '../../selectors/company';
import { graphqlOperation } from 'aws-amplify';
import { notificationsTransformer } from '../../components/notifications/utils';
import { get } from 'lodash';
import { setUser } from '../../reducers/auth';

const useNotification = ({ notificationName }) => {
  const APIGraphql = useSelector(APIGraphqlSelector);
  const notifications = useSelector(notificationsSelector);
  const dispatch = useDispatch();

  const notificationViewed = useMemo(
    () =>
      !!notifications?.find((notification) => notification[notificationName]),
    [notificationName, notifications]
  );

  const closeNotification = async () => {
    if (!notificationName || notificationViewed) return;

    try {
      const response = await APIGraphql(
        graphqlOperation(
          mutations.updateUser,
          notificationsTransformer(notificationName)
        )
      );
      const updatedUser = get(response, 'data.updateUser');
      if (!!updatedUser?.id) {
        dispatch(setUser(updatedUser));
      }
    } catch (err) {
      console.error('Error while updating user:', err);
    }
  };

  return { viewed: notificationViewed, setViewed: closeNotification };
};

export default useNotification;

import { useEffect, useState, useRef, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { API, graphqlOperation } from '@aws-amplify/api';
import { get } from 'lodash';

import * as subscriptions from '../graphql/subscriptions';
import { idCompanySelector } from '../selectors/company';
import alegraAPI from '../reducers/alegraAPI'
import {
  addNumeration,
  updateNumeration as updateNumerationAction,
  removeNumeration as removeNumerationAction,
} from '../reducers/numerations';
import { activeNumerations } from '../selectors/numerations';
import {
  updateNumerationByEvent as updateActiveInvoice,
  removeNumerationByEvent as removeActiveInvoice,
} from '../reducers/activeInvoice';
import {
  updateNumerationByEvent as updateEditInvoice,
  removeNumerationByEvent as removeEditInvoice,
} from '../reducers/editInvoice';
import {
  updateNumerationByEvent as updatePendingInvoices,
  removeNumerationByEvent as removePendingInvoices,
} from '../reducers/pendingInvoices';
import {
  updateNumerationByEvent as updateActiveRefund,
  removeNumerationByEvent as removeActiveRefund,
} from '../reducers/activeRefund';

const updateNumerationGlobal = (numeration) => {
  return (dispatch) => {
    if (!numeration) return

    dispatch(updateActiveInvoice(numeration));
    dispatch(updateEditInvoice(numeration));
    dispatch(updatePendingInvoices(numeration));
    dispatch(updateActiveRefund(numeration));
  }
}

const removeNumerationGlobal = (id) => {
  return (dispatch) => {
    dispatch(removeActiveInvoice(id));
    dispatch(removeEditInvoice(id));
    dispatch(removePendingInvoices(id));
    dispatch(removeActiveRefund(id));
  }
}

const NumerationsSns = () => {
  const ref = useRef();
  const dispatch = useDispatch();
  const idCompany = useSelector(idCompanySelector);
  const numerations = useSelector(activeNumerations);
  const networkStatus = useSelector(state => get(state, 'app.networkStatus', 'online'));
  const [onCreateNumerationError, setOnCreateNumerationError] = useState(false);
  const [onUpdateNumerationError, setOnUpdateNumerationError] = useState(false);
  const [onDeleteNumerationError, setOnDeleteNumerationError] = useState(false);

  const getNumeration = useCallback(
    async (id) => {
      try {
        const response = await alegraAPI.get(`/number-templates/${id}`);
        return get(response, 'data', null);
      } catch {
        return null
      }
    }, []);

  useEffect(() => {
    let subscription = null;
    if (networkStatus === 'online') {
      subscription = API.graphql(graphqlOperation(subscriptions.onCreateNumberTemplateNotify, {
        channel: `pos_${idCompany}`
      }))
        .subscribe({
          next: (data) => {
            const putNumeration = async data => {
              try {
                const numerationId = get(data, 'value.data.onCreateNumberTemplateNotify.id', null);
                const numeration = await getNumeration(numerationId);

                if (!!numeration) {
                  dispatch(addNumeration(numeration));
                }
              } catch (error) { }
            }

            putNumeration(data)
          },
          error: () => setTimeout(() => {
            if (!!ref.current)
              setOnCreateNumerationError(!onCreateNumerationError);
          }, 60000)
        })
    }
    return () => {
      if (subscription)
        subscription.unsubscribe();
    }
  }, [networkStatus, onCreateNumerationError, dispatch, idCompany, getNumeration]);

  useEffect(() => {
    let subscription = null
    if (networkStatus === 'online') {
      subscription = API.graphql(graphqlOperation(subscriptions.onUpdateNumberTemplateNotify, {
        channel: `pos_${idCompany}`
      }))
        .subscribe({
          next: (data) => {
            const updateNumeration = async data => {
              try {
                const numerationId = get(data, 'value.data.onUpdateNumberTemplateNotify.id', null);
                const numeration = await getNumeration(numerationId);

                if (!numeration) {
                  return null
                }

                if (get(numeration, 'status') === 'active' && !!numerations.find((n) => n.id === get(numeration, 'id'))) {
                  dispatch(updateNumerationAction({
                    id: get(numeration, "id"),
                    changes: numeration
                  }));
                  dispatch(updateNumerationGlobal(numeration));
                  return;
                }

                if (get(numeration, 'status') === 'active' && !numerations.find((n) => n.id === get(numeration, 'id'))) {
                  dispatch(addNumeration(numeration));
                  return;
                }

                if (!!numerations.find((n) => n.id === get(numeration, 'id'))) {
                  dispatch(removeNumerationAction(numerationId));
                  dispatch(removeNumerationGlobal(numerationId));
                  return;
                }

              } catch { }
            }

            updateNumeration(data);
          },
          error: () => setTimeout(() => {
            if (!!ref.current)
              setOnUpdateNumerationError(!onUpdateNumerationError);
          }, 60000)
        })
    }
    return () => {
      if (subscription)
        subscription.unsubscribe();
    }
    // 
  }, [networkStatus, onUpdateNumerationError, dispatch, idCompany, getNumeration, numerations]);

  useEffect(() => {
    let subscription = null
    if (networkStatus === 'online') {
      subscription = API.graphql(graphqlOperation(subscriptions.onDeleteNumberTemplateNotify, {
        channel: `pos_${idCompany}`
      }))
        .subscribe({
          next: (data) => {
            const removeNumeration = async data => {
              try {
                const numerationId = get(data, 'value.data.onDeleteNumberTemplateNotify.id', null);

                if (!numerationId)
                  return null

                dispatch(removeNumerationAction(numerationId));
                dispatch(removeNumerationGlobal(numerationId));

              } catch { }
            }

            removeNumeration(data);
          },
          error: () => setTimeout(() => {
            if (!!ref.current)
              setOnDeleteNumerationError(!onDeleteNumerationError);
          }, 60000)
        })
    }
    return () => {
      if (subscription)
        subscription.unsubscribe();
    }
  }, [networkStatus, onDeleteNumerationError, dispatch, idCompany]);

  useEffect(() => {
    ref.current = true
    return () => ref.current = false
  }, []);

  return null;
};

export default NumerationsSns;


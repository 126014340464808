import { I18n } from 'aws-amplify';
import { get, capitalize } from 'lodash';

export const peruValidation = (values) => {
  let errors = {};

  const identificationType = get(values, 'identification.type', '');
  const identificationNumber = get(values, 'identification.number', '');

  const validRucStarts = ['10', '20', '15', '16', '17'];

  if (!identificationType)
    errors.identification = {
      ...errors.identification,
      type: capitalize(
        I18n.get(
          'selectTheIdentificationType',
          'seleccione el tipo de identificación'
        )
      ),
    };

  if (identificationType === 'RUC' && identificationNumber.length < 11)
    errors.identification = {
      ...errors.identification,
      number:
        capitalize(
          I18n.get(
            'theMinimumSizeForThisFieldIs',
            'El tamaño minimo para este campo es de'
          )
        ) + ' 11.',
    };
  if (identificationType === 'RUC' && identificationNumber.length > 11)
    errors.identification = {
      ...errors.identification,
      number:
        capitalize(
          I18n.get(
            'theMaximumSizeForThisFieldIs',
            'El tamaño maximo para este campo es de'
          )
        ) + ' 11.',
    };
  if (
    identificationType === 'RUC' &&
    !validRucStarts.some((word) => identificationNumber.startsWith(word))
  )
    errors.identification = {
      ...errors.identification,
      number: I18n.get(
        'RUCNotValidHelp',
        'El RUC ingresado tiene un formato no válido'
      ),
    };

  if (identificationType === 'SIN RUC' && identificationNumber.length > 15)
    errors.identification = {
      ...errors.identification,
      number:
        capitalize(
          I18n.get(
            'theMaximumSizeForThisFieldIs',
            'El tamaño maximo para este campo es de'
          )
        ) + ' 15.',
    };

  if (identificationType === 'CDI' && identificationNumber.length > 15)
    errors.identification = {
      ...errors.identification,
      number:
        capitalize(
          I18n.get(
            'theMaximumSizeForThisFieldIs',
            'El tamaño maximo para este campo es de'
          )
        ) + ' 15.',
    };

  if (identificationType === 'PP' && identificationNumber.length > 12)
    errors.identification = {
      ...errors.identification,
      number:
        capitalize(
          I18n.get(
            'theMaximumSizeForThisFieldIs',
            'El tamaño maximo para este campo es de'
          )
        ) + ' 12.',
    };

  if (identificationType === 'CE' && identificationNumber.length > 12)
    errors.identification = {
      ...errors.identification,
      number:
        capitalize(
          I18n.get(
            'theMaximumSizeForThisFieldIs',
            'El tamaño maximo para este campo es de'
          )
        ) + ' 12.',
    };

  if (identificationType === 'DNI' && identificationNumber.length < 8)
    errors.identification = {
      ...errors.identification,
      number:
        capitalize(
          I18n.get(
            'theMinimumSizeForThisFieldIs',
            'El tamaño minimo para este campo es de'
          )
        ) + ' 8.',
    };
  if (identificationType === 'DNI' && identificationNumber.length > 8)
    errors.identification = {
      ...errors.identification,
      number:
        capitalize(
          I18n.get(
            'theMaximumSizeForThisFieldIs',
            'El tamaño maximo para este campo es de'
          )
        ) + ' 8.',
    };

  if (!identificationNumber)
    errors.identification = {
      ...errors.identification,
      number: capitalize(I18n.get('enterAnID', 'Ingresa una identificación')),
    };

  return errors;
};

import React from 'react'
import PropTypes from 'prop-types';

const QuickNav = ({ quickNavRef, show, invoices, selected, onSelect }) => (
  <div ref={quickNavRef} className={`footer__quick-nav position-absolute shadow ${show ? 'visible': 'invisible'}`}>
    <ul className="list-group">
      {invoices.map((invoice, index) => (
        <li 
          key={index}
          className={`btn list-group-item footer__quick-nav-option ${selected === index ? 'active': ''}`}
          onClick={() => onSelect(index)}
        >
          {invoice.name}
        </li>
      ))}
    </ul>
  </div>
)

QuickNav.propTypes = {
  show: PropTypes.bool,
  quickNavRef: PropTypes.any,
  invoices: PropTypes.array,
  selected: PropTypes.any,
  onSelected: PropTypes.func,
};

export default QuickNav;
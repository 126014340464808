import { get, isArray } from 'lodash';

export const mexicoTransformer = (values, _props, newValues) => {
  const identification = get(values, 'rfc', null);
  const fiscalId = get(values, 'fiscalId', null);
  const thirdType = get(values, 'thirdType', null);
  const regime = get(values, 'regime', null);

  const regimeValues = isArray(regime) ? regime : regime?.split('%s%');
  const favoriteRegime = get(values, 'favoriteRegime', null);

  return {
    ...newValues,
    identification,
    fiscalId,
    thirdType,
    regime: favoriteRegime || regimeValues[0],
    regimeObject: regimeValues,
  };
};

import { I18n } from '@aws-amplify/core';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { firstInvoiceCreatedSelector } from '../../../../selectors/company';
import NoDocument from '../../../../components/svg/illustrations/NoDocument.svg';

function globalInvoiceEmptyState() {
  const isFirstInvoice = useSelector(firstInvoiceCreatedSelector);
  const history = useHistory();

  if (!isFirstInvoice) {
    return {
      title: I18n.get(
        'global-invoice-empty-state-first-invoice-title',
        '¡Crea tu primer ticket al instante! ⚡'
      ),
      description: I18n.get(
        'global-invoice-empty-state-first-invoice-description',
        'Registra tus tickets de venta y tímbralos en facturas globales'
      ),
      illustration: NoDocument,
      action: {
        label: I18n.get('newTicket', 'Nuevo ticket'),
        handler: () => history.push('/'),
      },
    };
  }

  return {
    title: I18n.get(
      'global-invoice-empty-state-title',
      'Sin tickets por timbrar 🚫'
    ),
    description: I18n.get(
      'global-invoice-empty-state-description',
      'Ajusta los filtros o crea nuevos tickets en segundos'
    ),
    illustration: NoDocument,
    action: {
      label: I18n.get('newTicket', 'Nuevo ticket'),
      handler: () => history.push('/global-invoices/invoice'),
    },
  };
}

export default globalInvoiceEmptyState;

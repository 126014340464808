import React from 'react'
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux'; 
import { I18n } from '@aws-amplify/core';
import { Form } from 'react-final-form';
import { FORM_ERROR } from 'final-form';

import { updateItem } from '../../../../reducers/activeRefund';
import { itemByIndex, currency as currencySelector } from '../../../../selectors/activeRefund';
import { decimalPrecision, country as countrySelector } from '../../../../selectors/company';
import Body from '../../../forms/editItem/EditItem';
import { transform, validate } from '../../../forms/editItem/utils';
import Bottom from '../../common/Bottom';
import Summary from './Summary';
import initialValues from './InitialValues';
import { some } from 'lodash';

const EditItem = ({ isOpen, onClose, selectedIndex }) => {
  const dispatch = useDispatch()
  const item = useSelector(itemByIndex(selectedIndex))
  const decimal = useSelector(decimalPrecision)
  const country = useSelector(countrySelector)
  const currency = useSelector(currencySelector)

  const submit = values => {
    const modifiedValues = transform(values, {
      country, 
      initialValues: initialValues(item, decimal), 
      currency,
      decimal,
    });
    try {
      dispatch(updateItem({ index: selectedIndex, values: modifiedValues }))
      onClose()
    } catch {
      return { [FORM_ERROR]: I18n.get('editItemError', 'hubo un error en la edición del producto') }
    }
  }
  
  return (
    <div className={`${isOpen ? 'open': ''} refund-edit-item border d-flex flex-column`}>
      <Form
        onSubmit={submit}
        initialValues={initialValues(item, decimal)}
        validate={values => validate(values, country)}
      >
        {({ handleSubmit, values, form }) => (
          <form 
            noValidate
            onSubmit={handleSubmit}
            className="w-100 h-100 bg-white d-flex flex-column"
          >
            <Body values={values} form={form}/>

            <Summary values={values} />

            <Bottom
              disabled={some(values?.decimalsError, (value) => value)}
              onClose={onClose} 
            />
          </form>
        )}
      </Form>

    </div>
  )
}

EditItem.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  selectedIndex: PropTypes.number,
}

export default EditItem;
import React from 'react';
import { Field } from 'react-final-form';
import { I18n } from '@aws-amplify/core';
import { capitalize } from 'lodash';

import ivaConditions from '../../../../../components/countriesData/argentina/ivaConditions';

import { Grid } from '@alegradev/smile-ui-react';
import { renderField } from '../../../../../components/forms/fields/V2';

const renderIVACondition = (props) => {
  const { Col } = Grid;
  return props.country === 'argentina' ? (
    <Col xs={6}>
      <Field
        name='ivaCondition'
        component={renderField}
        fieldType='select'
        placeholder={I18n.get('select', 'Seleccionar')}
        label={I18n.get('ivaCondition', 'Condición de IVA')}
        options={ivaConditions?.map((option) => ({
          text: option.value,
          value: option.key,
        }))}
        required
      />
    </Col>
  ) : null;
};
export default renderIVACondition;

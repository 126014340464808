import React from 'react';
import { Field } from 'react-final-form';
import { I18n } from '@aws-amplify/core';
import { capitalize, get } from 'lodash';
import { useSelector, useDispatch } from 'react-redux'

import { openModal } from '../../../reducers/modals'
import { checkFeatureLimit } from '../../../reducers/membership'
import { hasPermissionTo } from '../../../selectors/auth'
import { activePriceLists } from '../../../selectors/priceLists'
import { activeCurrencies } from '../../../selectors/currencies'
import { renderField, renderSelect } from '../fields/V0/Fields';

const InvoiceSettings = ({ values }) => {
  const can = useSelector(hasPermissionTo)
  const dispatch = useDispatch()
  const priceLists = useSelector(activePriceLists);
  const currencies = useSelector(activeCurrencies)

  return (
    <div className="form-body h-100 p-4 overflow-hidden">
      <div className="form-body__fields form-row justify-content-start">
        <Field
          name="currency"
          className={!!get(values, 'currency.status') ? 'col-md-6': 'col-md-12'}
          component={renderSelect}
          options={currencies}
          getOptionLabel={option => option.code}
          getOptionValue={option => option.code}
          label={capitalize(I18n.get('currency', 'moneda'))}
          addOptionText={capitalize(I18n.get('newCurrency', 'Nueva moneda'))}
          addOptionAction={() => dispatch(checkFeatureLimit('multicurrency', () => dispatch(openModal({ modal: 'currency' }))))}
          menuPosition="absolute"
        />
        
        {!!get(values, 'currency.status') && (
          <Field
            name="currency.exchangeRate"
            className="col-md-6"
            component={renderField}
            type="number"
            label={capitalize(I18n.get('exchangeRate', 'tasa de cambio'))}
          />
        )}
        
        <Field
          name="priceList"
          className="col-md-12"
          component={renderSelect}
          options={!can('index', 'price-lists') || !can('view', 'price-lists') ? []: priceLists}
          selectInfo={!can('index', 'price-lists') || !can('view', 'price-lists')
            ? I18n.get('userNotAllowed.price-lists.view', 'no tienes permisos para ver la información de las listas de precio')
            : null}
          hideNoOptionMessage={!can('index', 'price-lists') || !can('view', 'price-lists')}
          getOptionLabel={option => option.name}
          getOptionValue={option => option.id}
          label={capitalize(I18n.get('priceList', 'lista de precio'))}
          menuPosition="absolute"
        />
      </div>
    </div>
  )
}

export default InvoiceSettings;
import { createDraftSafeSelector } from '@reduxjs/toolkit';
import { paymentMethodsSelector } from '../reducers/paymentMethods';

const paymentMethodsDataSelector = (state) => state.paymentMethods;

export const allPaymentMethods = createDraftSafeSelector(
  paymentMethodsSelector.selectAll,
  (paymentMethods) => paymentMethods
);

export const loadingSelector = createDraftSafeSelector(
  paymentMethodsDataSelector,
  (data) => data.loading === 'loading'
);

// src/hooks/useFetchItems.js
import { useState, useCallback } from 'react';
import { filter } from '../../../../database/itemsDB'; 
import { debounce } from 'lodash';

const useFetchItems = (formItems) => {
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const search = useCallback(
    debounce(async (text = '') => {
      setLoading(true);
      setError(null);

      try {
        const fetchedItems = await filter({ text, categories: [], limit: 35 });
        const filteredItems = fetchedItems.filter(
          (item) =>
            (item.type === 'product' ||
              item.type === 'simple' ||
              item.type === 'variant') &&
            item.inventory?.availableQuantity !== null &&
            !formItems.includes(+item.id)
        );
        setItems(filteredItems);
      } catch (error) {
        console.error('Error fetching items:', error);
        setError('Failed to load items. Please try again.');
      } finally {
        setLoading(false);
      }
    }, 500),
    [formItems]
  );

  return { items, loading, error, search };
};

export default useFetchItems;

import React from "react";
import { useSelector } from "react-redux";
import { country as countrySelector } from "../../../selectors/company";
import { I18n } from "aws-amplify";
import { numeration } from "../../../selectors/activeInvoice";
import { get } from "lodash";
import { Icon } from "@alegradev/smile-ui-react";
import { IconInfoCircle } from "@tabler/icons-react";

const InvoiceNotification = () => {
  const country = useSelector(countrySelector);
  const currentNumeration = useSelector(numeration);

  const isCurrentNumerationPOSDocument = get(currentNumeration, 'documentType') === 'saleTicket' && !get(currentNumeration, 'isElectronic')

  if (country !== 'colombia' || !isCurrentNumerationPOSDocument) return null

  return (
    <div className="invoice-notification">

      <div className="invoice-notification-body">

        <div style={{ width: '7%' }} className="d-flex justify-content-center">
          <Icon icon={IconInfoCircle} />
        </div>
        <div style={{ width: '70%' }}>
          <p >
            {I18n.get('electronicPOSDocumentAlert', 'Ten en cuenta que los documentos POS deben ser electrónicos por regulación DIAN.')}
          </p>
        </div>
        <div style={{ width: '20%' }}>
          <a href="https://ayuda.alegra.com/es/emite-documentos-equivalentes-pos-electronicos-desde-tu-cuenta-de-alegra-pos" target="_blank" rel="noreferrer">
            {I18n.get('knowMore', 'Saber más')}
          </a>
        </div>
      </div>
    </div>
  )
}

export default InvoiceNotification;
import dayjs from 'dayjs';
import { BaseInvoiceStrategy } from './BaseInvoiceStrategy';
import { get } from 'lodash';
import { allRegimes } from '../../components/countriesData/mexico/regimes';
import { PAYMENT_METHODS } from '../../utils/enums/paymentMethods';
import {
  clientDataAreEquals,
  VALIDATION_ERROR_MESSAGES,
} from '../../utils/invoice/activeInvoice';
import {
  INVOICE_DOCUMENT_TYPES,
  INVOICE_PAYMENT_METHODS,
} from '../../components/modals/invoiceRefactored/utils/constants';

export class MexicoInvoiceStrategy extends BaseInvoiceStrategy {
  /**
   * Calculates the payment method according to the country and the received amount.
   *
   * If the received amount is greater than 0 and the greater payment method is
   * 'transfer', it returns 'transfer'. Otherwise, it delegates the calculation
   * to the base class.
   *
   * @param {string} greaterPaymentMethod The greater payment method.
   * @param {string} country The country.
   * @param {BigNumber} totalReceived The total received.
   * @param {boolean} isElectronicPOSDocument Whether the invoice is an electronic
   *     POS document.
   * @return {string} The payment method.
   */
  calculatePaymentMethod({
    greaterPaymentMethod,
    country,
    totalReceived,
    isElectronicPOSDocument,
  }) {
    if (greaterPaymentMethod === INVOICE_PAYMENT_METHODS.TRANSFER) {
      return INVOICE_PAYMENT_METHODS.TRANSFER;
    }

    return super.calculatePaymentMethod({
      greaterPaymentMethod,
      country,
      totalReceived,
      isElectronicPOSDocument,
    });
  }
  /**
   * Calculates the payments for a Mexican invoice by invoking the base class method
   * and determining the payment method based on the greater payment method, country,
   * and total received.
   *
   * @param {Object} params - The parameters for calculating payments.
   * @param {Object} params.payments - An object representing different payment types and their amounts.
   * @param {Object} params.banks - An object mapping payment types to bank accounts.
   * @param {Object} params.today - A dayjs object representing the current date.
   * @param {BigNumber} params.total - The total amount to be received.
   * @param {BigNumber} params.subtotal - The subtotal before any additional charges.
   * @param {string} params.country - The country where the invoice is being issued.
   * @param {number} params.decimal - The number of decimal places to consider for currency calculations.
   * @param {Object} params.cashReceiptNumberTemplate - Template for the cash receipt numbering.
   * @param {Object} params.currency - The currency object which may include exchange rate.
   * @param {Object} params.numberTemplate - Template for the invoice numbering.
   *
   * @returns {Object} An object containing the total, subtotal, total received, processed payments,
   *                   original payment method, and final payment method based on Mexican rules.
   */
  calculatePayments({
    payments,
    banks,
    today,
    total,
    subtotal,
    country,
    decimal,
    cashReceiptNumberTemplate,
    currency,
    numberTemplate,
  }) {
    const response = super.calculatePayments({
      payments,
      banks,
      today,
      total,
      subtotal,
      country,
      decimal,
      cashReceiptNumberTemplate,
      currency,
      numberTemplate,
    });
    return {
      ...response,
      paymentMethod: this.calculatePaymentMethod({
        greaterPaymentMethod: response.originalPaymentMethod,
        country,
        totalReceived: response.totalReceived,
        isElectronicPOSDocument: false,
      }),
    };
  }
  /**
   * Modify the invoice to create an electronic or non-electronic invoice for Mexico.
   *
   * @param {Object} invoice - The invoice object.
   * @param {Object} state - The global state object.
   * @param {boolean} isElectronic - The invoice is electronic or not.
   * @param {Object} numberTemplate - The number template object.
   * @param {Date} today - The current date.
   * @param {string} cfdiUse - The CFDI use for the invoice.
   * @returns {Object} - The modified invoice.
   */
  modifyPreparedInvoice({
    invoice,
    state,
    isElectronic,
    numberTemplate,
    today,
    cfdiUse,
  }) {
    const modifiedInvoice = { ...invoice };
    delete modifiedInvoice.type;
    delete modifiedInvoice.operationType;
    delete modifiedInvoice.paymentTerm;
    delete modifiedInvoice.deliveryTerm;

    if (modifiedInvoice.totalReceived < modifiedInvoice.total) {
      modifiedInvoice.dueDate = today
        .add(get(invoice, 'client.term.days', 0), 'day')
        .format('YYYY-MM-DD');
    }

    if (
      get(numberTemplate, 'documentType') === INVOICE_DOCUMENT_TYPES.INVOICE
    ) {
      const dateTimeInMexico = dayjs().tz('America/Mexico_City');

      return {
        ...modifiedInvoice,
        cfdiUse,
        regimeClient: get(
          modifiedInvoice,
          'client.regime',
          allRegimes.SIMPLIFIED_REGIME.value
        ),
        timestamp: dateTimeInMexico.format('YYYY-MM-DD HH:mm:ss'),
        stamp: {
          generateStamp:
            numberTemplate?.documentType === INVOICE_DOCUMENT_TYPES.INVOICE,
          version: '4.0',
        },
        paymentMethod:
          modifiedInvoice?.paymentMethod === 'CASH'
            ? PAYMENT_METHODS.CASH
            : modifiedInvoice?.paymentMethod,
      };
    }
    return modifiedInvoice;
  }
  /**
   * Create a default client for Mexico.
   *
   * @param {Object} props - The properties for the default client.
   * @param {Object} props.address - The address of the client.
   * @param {boolean} props.isElectronic - The invoice is electronic or not.
   * @returns {Object} - The default client.
   */
  defaultClient({ address, isElectronic }) {
    return {
      name: 'Público en General',
      identification: 'XAXX010101000',
      address,
      type: ['client'],
      ignoreRepeated: true,
      regime: !!isElectronic ? 'SIMPLIFIED_REGIME' : 'NO_REGIME',
      regimeObject: ['SIMPLIFIED_REGIME'],
      thirdType: 'NATIONAL',
      address: {
        country: 'MEX',
        zipCode: '12345',
      },
    };
  }

  /**
   * Determines if the provided client matches the default client for Mexico.
   *
   * @param {Object} params - An object containing the client data.
   * @param {Object} params.client - The client object to be compared.
   * @returns {boolean} - True if the client matches the default client criteria, otherwise false.
   */
  isDefaultClient({ client }) {
    const defaultC = this.defaultClient();
    return (
      clientDataAreEquals(defaultC.name, client.name) &&
      clientDataAreEquals(defaultC.identification, client.identification)
    );
  }
  /**
   * Validates the invoice based on its electronic status and stamp generation.
   *
   * @param {Object} invoice - The invoice object to validate.
   * @param {boolean} isElectronic - Indicates if the invoice is electronic.
   * @throws {Error} - Throws an error if electronic numeration is missing for electronic invoices.
   */
  modifyValidateInvoice({ invoice, isElectronic }) {
    if (isElectronic && !!get(invoice, 'stamp.generateStamp')) {
      throw new Error(VALIDATION_ERROR_MESSAGES.ELECTRONIC_NUMERATION_MISSING);
    }
  }
  /**
   * Transforms the invoice based on its document type and electronic status.
   * If the document type is an invoice, it adds the cfdiUse and regimeClient
   * properties to the invoice object. It also sets the stamp version. If the
   * payment method is cash, it sets the payment method to 'efectivo'.
   *
   * @param {Object} invoice - The invoice object to transform.
   * @returns {Object} - The transformed invoice object.
   */
  transform(invoice) {
    let result = super.transform(invoice);

    if (
      get(invoice, 'numberTemplate.documentType') ===
      INVOICE_DOCUMENT_TYPES.INVOICE
    ) {
      result = {
        ...result,
        cfdiUse: get(invoice, 'cfdiUse', null),
        regimeClient: get(invoice, 'regimeClient', null),
        stamp: {
          ...result.stamp,
          version: get(invoice, 'stamp.version', null),
        },
      };
    }
    if (invoice?.paymentMethod === 'CASH') {
      result = {
        ...result,
        paymentMethod: PAYMENT_METHODS.CASH,
      };
    }
    return result;
  }
}

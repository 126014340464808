import {
  useMutation,
  useQueryClient,
  onlineManager,
} from '@tanstack/react-query';
import { useDispatch, useSelector } from 'react-redux';
import { findIndex, get } from 'lodash';
import { I18n } from 'aws-amplify';

import alegraAPI from '../../../reducers/alegraAPI';
import { useRefresh } from '../../../hooks/queryHooks/useRefresh';
import { useCancel } from '../../../hooks/queryHooks/useCancel';
import {
  changeItemInventory,
  changeItemsByApi,
  refresh,
} from '../../../reducers/items';
import { toast } from '../../../utils';
import { handleError } from '../../../utils/errors';
import { sendGTMEvent, sendNewGTMEvent } from '../../../reducers/company';
import { endAction, startAction } from '../../../reducers/monitoring';
import { calculateActionTimeSelector } from '../../../selectors/monitoring';

const changeLocalItemsQuantity = async ({
  inventoryAdjustment,
  previousInventoryAdjustment,
  action,
  dispatch,
}) => {
  const items = get(inventoryAdjustment, 'items', []);
  const isOnline = onlineManager.isOnline();
  let previousItems = !!previousInventoryAdjustment
    ? get(previousInventoryAdjustment, 'items', [])
    : null;

  switch (action) {
    case 'delete':
      if (!isOnline) {
        await Promise.all(
          items.map(async ({ id, type, quantity }) => {
            return (
              await dispatch(
                changeItemInventory({
                  id: +id,
                  quantity: type === 'in' ? -quantity : +quantity,
                })
              ),
              dispatch(refresh())
            );
          })
        );
      }
      break;
    case 'edit':
      if (!isOnline) {
        await Promise.all(
          items.map(async ({ id, type, quantity }, i) => {
            const getQuantityToChange = () => {
              if (!!previousItems[i]) {
                return -(parseFloat(previousItems[i].quantity) - quantity);
              }
              return type === 'in' ? -quantity : +quantity;
            };

            return (
              await dispatch(
                changeItemInventory({
                  id: +id,
                  quantity: getQuantityToChange(),
                })
              ),
              dispatch(refresh())
            );
          })
        );
      }
      break;

    case 'create':
      if (!isOnline) {
        await Promise.all(
          items.map(async ({ id, type, quantity }) => {
            return (
              await dispatch(
                changeItemInventory({
                  id: +id,
                  quantity: type === 'in' ? +quantity : -quantity,
                })
              ),
              dispatch(refresh())
            );
          })
        );
      }
      break;
    case 'restore':
      if (isOnline) {
        await dispatch(changeItemsByApi(items.map(({ id }) => +id)));
        dispatch(refresh);
      }
      break;
    default:
      break;
  }
};

const deleteInventoryAdjustment = async ({
  id,
  inventoryAdjustment,
  isMulti,
}) => {
  await alegraAPI.delete(`/inventory-adjustments/${id}`);
  return { id, inventoryAdjustment, isMulti };
};

const createInventoryAdjustment = async ({ inventoryAdjustment }) => {
  const response = await alegraAPI.post(
    '/inventory-adjustments',
    inventoryAdjustment
  );
  return response;
};

const updateInventoryAdjustment = async ({ inventoryAdjustment }) => {
  const response = await alegraAPI.put(
    `/inventory-adjustments/${inventoryAdjustment?.id}`,
    inventoryAdjustment
  );
  return response;
};

export const useDeleteInventoryAdjustment = () => {
  const queryClient = useQueryClient();
  const cancel = useCancel();
  const dispatch = useDispatch();

  queryClient.setMutationDefaults(['deleteInventoryAdjustment'], {
    mutationFn: deleteInventoryAdjustment,
  });

  return useMutation({
    mutationKey: ['deleteInventoryAdjustment'],
    mutationFn: deleteInventoryAdjustment,
    onMutate: async ({ id, filters, inventoryAdjustment }) => {
      await cancel('inventoryAdjustments');

      changeLocalItemsQuantity({
        inventoryAdjustment,
        action: 'delete',
        dispatch,
      });

      const previousInventoryAdjustments = queryClient.getQueryData([
        'inventoryAdjustments',
        filters,
      ]);

      queryClient.setQueriesData(['inventoryAdjustments'], (old) => ({
        data: old.data.filter((od) => od.id !== id),
        metadata: {
          total: old.metadata.total - 1,
        },
      }));
      return { previousInventoryAdjustments, inventoryAdjustment };
    },
    onError: (error, __, context) => {
      queryClient.setQueriesData(
        ['inventoryAdjustments'],
        context.previousInventoryAdjustments
      );

      const responseError = get(error, 'response.data.message', '');
      toast.error({
        title: I18n.get(
          'inventoryAdjustmentDeletedError',
          'error eliminando ajuste de inventario'
        ),
        subtitle: !!responseError ? responseError : handleError(error),
      });
    },
    onSettled: async (_, __, context) => {
      changeLocalItemsQuantity({
        inventoryAdjustment: get(context, 'inventoryAdjustment'),
        action: 'restore',
        dispatch,
      });
    },
  });
};

export const useUpdateInventoryAdjustment = () => {
  const queryClient = useQueryClient();
  const refresh = useRefresh();
  const cancel = useCancel();
  const dispatch = useDispatch();

  queryClient.setMutationDefaults(['updateInventoryAdjustment'], {
    mutationFn: updateInventoryAdjustment,
  });

  return useMutation({
    mutationKey: ['updateInventoryAdjustment'],
    mutationFn: updateInventoryAdjustment,
    onMutate: async ({ optimisticValues, filters }) => {
      await cancel('inventoryAdjustments');

      const previousInventoryAdjustment = queryClient.getQueryData([
        'inventoryAdjustment',
        { id: optimisticValues.id },
      ]);

      const previousInventoryAdjustments = queryClient.getQueryData([
        'inventoryAdjustments',
        filters,
      ]);

      changeLocalItemsQuantity({
        inventoryAdjustment: optimisticValues,
        previousInventoryAdjustment,
        action: 'edit',
        dispatch,
      });

      queryClient.setQueryData(
        ['inventoryAdjustment', { id: optimisticValues.id }],
        optimisticValues
      );

      queryClient.setQueriesData(['inventoryAdjustments'], (old) => {
        old.data[findIndex(old.data, ['id', get(optimisticValues, 'id')])] =
          optimisticValues;
        return {
          data: old.data,
          metadata: old.metadata,
        };
      });

      return {
        previousInventoryAdjustment,
        previousInventoryAdjustments,
        optimisticValues,
      };
    },
    onError: (_, __, context) => {
      queryClient.setQueryData(
        [
          'inventoryAdjustment',
          { id: get(context, 'previousInventoryAdjustment.id') },
        ],
        get(context, 'previousInventoryAdjustment')
      );
      queryClient.setQueriesData(
        ['inventoryAdjustments'],
        context.previousInventoryAdjustments
      );
    },
    onSuccess: (data) => {
      const itemsNumber = get(data, 'data.items', []).length;
      const itemsIncreasedNumber = get(data, 'data.items', []).filter(
        (i) => get(i, 'type') === 'in'
      ).length;
      const itemsDecreasedNumber = itemsNumber - itemsIncreasedNumber;

      dispatch(
        sendGTMEvent('inventory-adjustment-edition-attempted', {
          itemsNumber,
          itemsDecreasedNumber,
          itemsIncreasedNumber,
          inventoryAdjustmentEditionStatus: true,
        })
      );
    },
    onSettled: async (_, __, context) => {
      await refresh('inventoryAdjustments');
      await refresh('inventoryAdjustment');
      changeLocalItemsQuantity({
        inventoryAdjustment: get(context, 'optimisticValues'),
        action: 'restore',
        dispatch,
      });
    },
  });
};

export const useCreateInventoryAdjustment = () => {
  const queryClient = useQueryClient();
  const refresh = useRefresh();
  const cancel = useCancel();
  const dispatch = useDispatch();
  const calculateActionTime = useSelector(calculateActionTimeSelector);

  queryClient.setMutationDefaults(['createInventoryAdjustment'], {
    mutationFn: createInventoryAdjustment,
  });

  return useMutation({
    mutationKey: ['createInventoryAdjustment'],
    mutationFn: createInventoryAdjustment,
    onMutate: async ({ optimisticValues, filters }) => {
      dispatch(startAction({action: 'createInventoryAdjustment'}));
      await cancel('inventoryAdjustments');
      changeLocalItemsQuantity({
        inventoryAdjustment: optimisticValues,
        action: 'create',
        dispatch,
      });

      const previousInventoryAdjustments = queryClient.getQueryData([
        'inventoryAdjustments',
        filters,
      ]);

      queryClient.setQueriesData(['inventoryAdjustments'], (old) => ({
        data: [optimisticValues, ...old.data],
        metadata: {
          total: old.metadata.total + 1,
        },
      }));

      dispatch(endAction({action: 'createInventoryAdjustment'}));
      return { previousInventoryAdjustments, optimisticValues };
    },
    onError: (error, __, context) => {
      queryClient.setQueriesData(
        ['inventoryAdjustments'],
        context.previousInventoryAdjustments
      );
      const parseError = handleError(error);
      dispatch(
        sendNewGTMEvent('pos-inventory-adjustment-created', {
          error: parseError,
        })
      );
    },
    onSuccess: (data) => {
      const itemsNumber = get(data, 'data.items', []).length;
      const itemsIncreasedNumber = get(data, 'data.items', []).filter(
        (i) => get(i, 'type') === 'in'
      ).length;
      const itemsDecreasedNumber = itemsNumber - itemsIncreasedNumber;

      dispatch(
        sendGTMEvent('new-inventory-adjustment-saved', {
          itemsNumber,
          itemsDecreasedNumber,
          itemsIncreasedNumber,
        })
      );

      dispatch(
        sendNewGTMEvent('pos-inventory-adjustment-created', {
          error: 'no error',
          responseTime: calculateActionTime("createInventoryAdjustment"),
          up: itemsIncreasedNumber,
          down: itemsDecreasedNumber,
          number: itemsNumber,
        })
      );
    },
    onSettled: async (_, __, context) => {
      await refresh('inventoryAdjustments');
      changeLocalItemsQuantity({
        inventoryAdjustment: get(context, 'optimisticValues'),
        action: 'restore',
        dispatch,
      });
    },
  });
};

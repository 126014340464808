import { get } from "lodash";

export const MAX_ALLOWED_DECIMALS = 6;

export function maxDecimals({
  e,
  key,
  decimals = MAX_ALLOWED_DECIMALS,
  values,
  isActive,
}) {
  if (!isActive) return e.target.value;

  const inputChar = e.nativeEvent.data;
  const inputValue = e.target.value;
  const previousValue = get(values, key);
  const decimalPosition = inputValue.indexOf('.');

  if (inputChar === '.') {
    if (decimalPosition === -1) return inputValue;
    e.target.value = previousValue;
    return previousValue;
  }

  if (!isNaN(inputChar)) {
    const decimalCount =
      decimalPosition !== -1 ? inputValue.slice(decimalPosition + 1).length : 0;
    if (decimalPosition === -1 || decimalCount <= decimals) return inputValue;
    e.target.value = previousValue;
    return previousValue;
  }

  e.target.value = previousValue;
  return previousValue;
}

import React from 'react';
import PropTypes from 'prop-types';
import { isString } from 'lodash';

import { Icon } from '@alegradev/smile-ui-react';
import { IconX } from '@tabler/icons-react';

const Header = ({
  title,
  icon,
  onClose,
  hideClose,
  hideHeader,
  bordelessHeader,
  headerImg,
  closeId,
  customHeader,
  iconBlack,
}) => {
  if (customHeader) return customHeader;

  return (
    <div
      className={`modal__header w-100 d-flex justify-content-between align-items-center 
    ${!headerImg ? 'px-5 py-4' : 'mb-4'} ${!hideHeader && !bordelessHeader ? 'border-bottom' : ''}`}
    >
      <div className='d-flex w-100'>
        {!hideHeader && !headerImg && !!icon && (
          <div className='modal__header-icon mr-3'>{icon}</div>
        )}
        {!hideHeader && !headerImg && !!title && isString(title) ? (
          <div className='modal__header-title text-capitalize-first h2 new-text-secondary text-truncate'>
            {title}
          </div>
        ) : (
          title
        )}
        {!hideHeader && !!headerImg && (
          <img
            src={headerImg}
            style={{
              maxWidth: '100%',
              maxHeight: '100%',
              objectFit: 'contain',
            }}
            className='modal__header-img mx-auto'
            alt=''
          />
        )}
      </div>

      <button
        id={closeId}
        className={`btn button-transparent btn-sm ${hideHeader || hideClose ? 'invisible' : 'visible'} 
        ${headerImg ? 'modal__header-modal-close-button-absolute ' : ''}`}
        type='button'
        data-testid='modal-close-button'
        onClick={() => onClose()}
      >
        <Icon
          icon={IconX}
          extraClass={`icon-${headerImg ? (iconBlack ? 'black' : 'white ') : 'gray'}`}
        />
      </button>
    </div>
  );
};

export default Header;

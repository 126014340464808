import React from 'react';
import { I18n } from '@aws-amplify/core';
import { useDispatch, useSelector } from 'react-redux'
import { get, replace } from 'lodash';

import { setStation } from '../../reducers/app';
import { station as stationSelector } from '../../selectors/app';

function NoStationsError({ stationsQty }) {
  const dispatch = useDispatch();
  const station = useSelector(stationSelector);
  const stationName = !!get(station, 'name') ? ' ' + get(station, 'name') : '';

  return (
    <div className="app d-flex justify-content-center align-items-center">
      <div className="card app-error rounded p-5">
        <div className="app-error-img-wrapper">
          <img alt="error" src="/error.png" />
        </div>
        <h2 className="mb-4 text-capitalize-first">
          {replace(I18n.get('noStationPermissionWithNameError', '¿Necesitas acceder a la terminal{}?'), '{}', stationName)}
        </h2>

        <p className="text-capitalize-first text-left">
          {stationsQty > 1
            ? I18n.get('noStationPermissionErrorMessage', 'Solicitá acceso a un perfil administrador o ingresá desde otra terminal en la que tengas permiso.')
            : I18n.get('noUniqueStationPermissionErrorMessage', 'Solicitá acceso a un perfil administrador')}
        </p>
        {stationsQty > 1 && (
          <button type="button" className="btn btn-submit" style={{ width: "18rem" }}
            onClick={() => dispatch(setStation(null))}>
            {I18n.get('getIntoOtherTerminal', 'Ingresar a otra terminal')}
          </button>
        )}
      </div>
    </div>
  )

}

export default NoStationsError;

import React from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { I18n } from '@aws-amplify/core';
import { Form } from 'react-final-form';
import { get } from 'lodash';

import { country as countrySelector } from '../../../selectors/company';
import { tipSelector } from '../../../selectors/activeInvoice';
import { closeSideModal } from '../../../reducers/sideModals';
import { setTip } from '../../../reducers/activeInvoice';
import Switch from '../../common/Switch';

import { Tooltip, Icon } from '@alegradev/smile-ui-react';
import SideModal from '../../common/SideModal';
import Body from '../../forms/editTip/Body';
import Bottom from '../common/Bottom';
import { COUNTRIES } from '../../../utils/enums/countries';
import { IconInfoCircle, IconX } from '@tabler/icons-react';

const Header = ({ include, onClose, setInclude, hideClose = false }) => {
  
  return (
    <div className='pt-4 pl-5 pr-4'>
      <div className="side-modal__new-header tips w-100 d-flex justify-content-between align-items-center">
        <div className="d-flex align-items-center">
          <div className="side-modal__header-title text-capitalize-first">
            <Title />
          </div>
        </div>
  
        <button
          className={`btn button-transparent ${hideClose ? 'invisible' : 'visible'}`}
          type="button"
          onClick={() => onClose()}>
          <Icon icon={IconX} extraClass="icon-black" />
        </button>
      </div>
  

      <div className='d-flex align-items-center mb-4'>
        <Switch
          active={include}
          onChange={() => setInclude(!include)}
          large
        />

        <p className='pl-2 m-0'>{I18n.get('includeTip', 'Incluir propina')}</p>
      </div>

      <div className='side-modal__divider'></div>
    </div>
  )

}

const Tip = () => {
  const dispatch = useDispatch();
  const isOpen = useSelector(state => get(state, 'sideModals.tip.isOpen', false));
  const tip = useSelector(tipSelector);

  const save = values => {
    const type = get(values, "type", "PERCENTAGE");
    let include = get(values, "include", false);

    if (include) {
      if (type === 'PERCENTAGE' && Number(get(values, "percentage", 0)) === 0) {
        include = false;
      }

      if (type === 'VALUE' && Number(get(values, "value", 0)) === 0) {
        include = false;
      }
    }

    dispatch(setTip({
      ...tip,
      include,
      type,
      percentage: get(values, "type", "PERCENTAGE") === 'PERCENTAGE' ? get(values, "percentage", 10) : 0,
      value: get(values, "type", "PERCENTAGE") === 'VALUE' ? get(values, "value", 0) : 0
    }))
    dispatch(closeSideModal({ sideModal: 'tip' }))
  }

  return (
    <SideModal
      open={isOpen}
      onRequestClose={() => dispatch(closeSideModal({ sideModal: 'tip' }))}
      destroyOnClose
    >
      <>
        <Form
          onSubmit={values => save(values)}
          initialValues={{
            include: get(tip, "include", false),
            type: get(tip, "type", "PERCENTAGE"),
            percentage: get(tip, "percentage", 10),
            value: get(tip, "value", 0),
          }}
          keepDirtyOnReinitialize
        >
          {({ handleSubmit, values, form, submitting, submitError, errors }) => (
            <form
              noValidate
              onSubmit={handleSubmit}
              className="w-100 h-100 bg-white d-flex flex-column"
            >
              <Header
                include={values.include}
                setInclude={include => form.change('include', include)}
                onClose={() => dispatch(closeSideModal({ sideModal: 'tip' }))}
              />

              <div className='side-modal__body'>
                <Body values={values} form={form} errors={errors} />
              </div>

              <div data-testid="edit-tip-bottom">
                <Bottom
                  onClose={() => dispatch(closeSideModal({ sideModal: 'tip' }))}
                  disabled={submitting || errors?.numerationError}
                  submitting={submitting}
                />
              </div>
            </form>
          )}
        </Form>
      </>
    </SideModal>
  )
}

const Title = () => {
  const country = useSelector(countrySelector)
  return (
    <div className='my-4 d-flex align-items-center'>
      <h2 className='m-0 mr-2'>{I18n.get('editTip', 'Editar propina')}</h2>
      { country === COUNTRIES.COLOMBIA &&
        <Tooltip
          placement="bottom"
          overlay={<p className='text-white text-capitalize-first'>{I18n.get('editTip.tooltip', 'Esta sugerencia puede ser máximo el 10% según la SIC, y tu cliente puede decidir no aceptarla o modificar su valor')}</p>}
        >
          <Icon icon={IconInfoCircle} type='primary' />
        </Tooltip>
      }
    </div>
  )
}

export default Tip;
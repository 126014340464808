import React, { useMemo, useEffect, useCallback } from 'react';
import { I18n } from '@aws-amplify/core';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, toastHandler, Typography } from '@alegradev/smile-ui-react';
import { get, find } from 'lodash';

import contactsBearerAPI from '../../../../reducers/contactsBearerAPI';

import { setClient, setSettings } from '../../../../reducers/activeInvoice';
import {
  country as countrySelector,
  companySelector,
} from '../../../../selectors/company';
import {
  client as clientSelector,
  numeration as numerationSelector,
} from '../../../../selectors/activeInvoice';
import {
  stationInvoiceNumberNumeration,
  stationInvoiceNumeration,
} from '../../../../selectors/app';

import useElectronicPayrollStatus from '../../../../hooks/useElectronicPayrollStatus/hook';
import { COUNTRIES } from '../../../../utils/enums/countries';
import { getInvoiceNumerations } from '../../../../selectors/numerations';
import { MEXICO_DEFAULT_RFC } from '../../../countriesData/mexico/rfc';
import { isMexicoFeAvailable } from '../../../../utils/deploys/mexicoFeDeployGroups';

import { identificationTypesEnum } from '../../../countriesData/costaRica/identificationTypes';

import NewClientButton from './NewClientButton';
import ClientSelector from './Selector';

const Client = () => {
  const { Wrapper } = Grid;
  const dispatch = useDispatch();
  const country = useSelector(countrySelector);
  const client = useSelector(clientSelector);
  const numeration = useSelector(numerationSelector);
  const numerations = useSelector(getInvoiceNumerations);
  const stationNumeration = useSelector(stationInvoiceNumeration);
  const stationNumberInvoiceNumberation = useSelector(
    stationInvoiceNumberNumeration
  );
  const { isActive } = useElectronicPayrollStatus();
  const { registryDate } = useSelector(companySelector);

  const isFeMexActive = useMemo(() => {
    return isMexicoFeAvailable(country, isActive);
  }, [country, registryDate, isActive]);

  useEffect(() => {
    if (!isFeMexActive) return;
    if (country === COUNTRIES.MEXICO) {
      const TICKET_NUMERATION_TYPE = 'saleTicket';
      if (client && client?.identification !== MEXICO_DEFAULT_RFC) {
        if (stationNumeration?.documentType === TICKET_NUMERATION_TYPE) {
          if (stationNumberInvoiceNumberation) {
            dispatch(
              setSettings({ numeration: stationNumberInvoiceNumberation })
            );
          } else {
            const invoiceNumeration = find(numerations, {
              documentType: 'invoice',
            });
            const favoriteNumeration = find(numerations, {
              documentType: 'invoice',
              isDefault: true,
            });
            if (favoriteNumeration) {
              dispatch(setSettings({ numeration: favoriteNumeration }));
            } else if (invoiceNumeration) {
              dispatch(setSettings({ numeration: invoiceNumeration }));
            }
          }
        }
      }
    }
    if (country === COUNTRIES.REPUBLICA_DOMINICANA) {
      if (stationNumeration?.prefix === 'E31') {
      }
    }
  }, [client, country, stationNumeration, numerations, isFeMexActive]);

  const searchCRIFinalConsumer = useCallback(
    async (identification, idType) => {
      try {
        const response = await contactsBearerAPI.get('/search-by-id-number', {
          identification,
          idType,
          version: country,
        });
        const data = get(response, 'data', null) ? get(response, 'data') : null;
        if (!data) {
          toastHandler({
            type: 'info',
            title: I18n.get(
              'criIdentificationNotFound',
              'Identificación no encontrada en Hacienda'
            ),
            description: I18n.get(
              'criIdentificationNotFoundDescription',
              'La identificación no está asociada a un <br /> contribuyente. Te recomendamos revisar los <br /> datos para evitar errores en Hacienda.'
            ),
          });
        }
      } catch (_) {
        toastHandler({
          type: 'info',
          title: I18n.get(
            'criIdentificationNotFound',
            'Identificación no encontrada en Hacienda'
          ),
          description: I18n.get(
            'criIdentificationNotFoundDescription',
            'La identificación no está asociada a un <br /> contribuyente. Te recomendamos revisar los <br /> datos para evitar errores en Hacienda.'
          ),
        });
      }
    },
    [client, numeration, stationNumeration]
  );
 

  useEffect(() => {
    if (country === COUNTRIES.COSTA_RICA) {
      const SALE_TICKET_NUMERATION = 'saleTicket';
      const numerationDocumentType =
        get(numeration, 'documentType', null) ||
        get(stationNumeration, 'documentType', null);
      const clientIdentificationType = get(
        client,
        'identificationObject.type',
        null
      );
      if (
        clientIdentificationType === identificationTypesEnum.FINAL_CONSUMER &&
        numerationDocumentType !== SALE_TICKET_NUMERATION
      ) {
        dispatch(setClient(null));
        return;
      }
    }
  }, [numeration, stationNumeration]);


  useEffect(() => {
    if (country === COUNTRIES.COSTA_RICA) {
      const SALE_TICKET_NUMERATION = 'saleTicket';
      const numerationDocumentType =
        get(numeration, 'documentType', null) ||
        get(stationNumeration, 'documentType', null);
      const clientIdentificationType = get(
        client,
        'identificationObject.type',
        null
      );
      const clientIdentificationNumber =
        get(client, 'identification', null) ||
        get(client, 'identificationObject.number', null);
      const isElectronicNumeration = get(numeration, 'isElectronic', false);
      if (
        clientIdentificationType === identificationTypesEnum.FINAL_CONSUMER &&
        numerationDocumentType !== SALE_TICKET_NUMERATION
      ) {
        const invoiceNumeration = find(numerations, {
          documentType: SALE_TICKET_NUMERATION,
        });
        const favoriteNumeration = find(numerations, {
          documentType: SALE_TICKET_NUMERATION,
          isDefault: true,
        });
        if (favoriteNumeration) {
          dispatch(setSettings({ numeration: favoriteNumeration }));
        } else if (invoiceNumeration) {
          dispatch(setSettings({ numeration: invoiceNumeration }));
        }
        return;
      }
      if (
        clientIdentificationNumber &&
        clientIdentificationType === identificationTypesEnum.FINAL_CONSUMER &&
        numerationDocumentType === SALE_TICKET_NUMERATION &&
        isElectronicNumeration
      ) {
        searchCRIFinalConsumer(
          clientIdentificationNumber,
          clientIdentificationType
        );
      }
    }
  }, [client]);
  return (
    <Wrapper align={{ lg: 'center' }} gap={10} extraClasses='p-3'>
      <Typography
        type='label-2'
        text={I18n.get('client', 'Cliente')}
        variant='secondary'
        extraClassName='pl-3'
      />
      <ClientSelector />
      <NewClientButton />
    </Wrapper>
  );
};

export default Client;

export default ({ id, className }) => (
  <svg
    id={id}
    className={className}
    width='172'
    height='157'
    viewBox='0 0 172 157'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <g filter='url(#filter0_dd_1208_72860)'>
      <g clip-path='url(#clip0_1208_72860)'>
        <rect
          x='10.3564'
          y='17.8584'
          width='68.5213'
          height='98.8288'
          rx='10.5891'
          transform='rotate(-15 10.3564 17.8584)'
          fill='white'
        />
        <rect
          x='31.4834'
          y='61.7988'
          width='36.1431'
          height='8.28279'
          rx='4.1414'
          transform='rotate(-15 31.4834 61.7988)'
          fill='#25BCB3'
        />
        <rect
          x='34.7598'
          y='74.9307'
          width='48.9438'
          height='8.28279'
          rx='4.1414'
          transform='rotate(-15 34.7598 74.9307)'
          fill='#F1F5F9'
        />
        <rect
          x='39.2222'
          y='89.4619'
          width='18.5384'
          height='8.28279'
          rx='4.1414'
          transform='rotate(-15 39.2222 89.4619)'
          fill='#F1F5F9'
        />
        <rect
          width='68.5193'
          height='58.6384'
          transform='matrix(0.965926 -0.258819 0.258806 0.965929 5.70215 0.49707)'
          fill='#EEF2F5'
        />
      </g>
    </g>
    <g filter='url(#filter1_dd_1208_72860)'>
      <g clip-path='url(#clip1_1208_72860)'>
        <rect
          width='68.5213'
          height='98.8288'
          rx='10.5891'
          transform='matrix(-0.965926 -0.258819 -0.258819 0.965926 163.866 40.4512)'
          fill='white'
        />
        <rect
          width='46.7098'
          height='46.2093'
          rx='23.1046'
          transform='matrix(-0.965926 -0.258819 -0.258806 0.965929 150.961 44.8672)'
          fill='#EEF2F5'
        />
        <rect
          width='41.8933'
          height='8.28279'
          rx='4.1414'
          transform='matrix(-0.965926 -0.258819 -0.258819 0.965926 135.506 93.2432)'
          fill='#25BCB3'
        />
        <rect
          width='51.2726'
          height='8.28279'
          rx='4.1414'
          transform='matrix(-0.965926 -0.258819 -0.258819 0.965926 136.723 106.821)'
          fill='#F1F5F9'
        />
        <g filter='url(#filter2_dd_1208_72860)'>
          <path
            d='M113.887 61.0933L118.535 66.6322L129.612 57.3368'
            stroke='white'
            stroke-width='4.28446'
            stroke-linecap='round'
            stroke-linejoin='round'
            shape-rendering='crispEdges'
          />
        </g>
      </g>
    </g>
    <defs>
      <filter
        id='filter0_dd_1208_72860'
        x='0.473567'
        y='0.124023'
        width='111.531'
        height='136.255'
        filterUnits='userSpaceOnUse'
        color-interpolation-filters='sRGB'
      >
        <feFlood flood-opacity='0' result='BackgroundImageFix' />
        <feColorMatrix
          in='SourceAlpha'
          type='matrix'
          values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
          result='hardAlpha'
        />
        <feMorphology
          radius='3.29429'
          operator='erode'
          in='SourceAlpha'
          result='effect1_dropShadow_1208_72860'
        />
        <feOffset dy='6.58859' />
        <feGaussianBlur stdDeviation='3.29429' />
        <feComposite in2='hardAlpha' operator='out' />
        <feColorMatrix
          type='matrix'
          values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.04 0'
        />
        <feBlend
          mode='normal'
          in2='BackgroundImageFix'
          result='effect1_dropShadow_1208_72860'
        />
        <feColorMatrix
          in='SourceAlpha'
          type='matrix'
          values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
          result='hardAlpha'
        />
        <feMorphology
          radius='3.29429'
          operator='erode'
          in='SourceAlpha'
          result='effect2_dropShadow_1208_72860'
        />
        <feOffset dy='13.1772' />
        <feGaussianBlur stdDeviation='6.58859' />
        <feComposite in2='hardAlpha' operator='out' />
        <feColorMatrix
          type='matrix'
          values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0'
        />
        <feBlend
          mode='normal'
          in2='effect1_dropShadow_1208_72860'
          result='effect2_dropShadow_1208_72860'
        />
        <feBlend
          mode='normal'
          in='SourceGraphic'
          in2='effect2_dropShadow_1208_72860'
          result='shape'
        />
      </filter>
      <filter
        id='filter1_dd_1208_72860'
        x='62.2182'
        y='22.7168'
        width='111.531'
        height='136.255'
        filterUnits='userSpaceOnUse'
        color-interpolation-filters='sRGB'
      >
        <feFlood flood-opacity='0' result='BackgroundImageFix' />
        <feColorMatrix
          in='SourceAlpha'
          type='matrix'
          values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
          result='hardAlpha'
        />
        <feMorphology
          radius='3.29429'
          operator='erode'
          in='SourceAlpha'
          result='effect1_dropShadow_1208_72860'
        />
        <feOffset dy='6.58859' />
        <feGaussianBlur stdDeviation='3.29429' />
        <feComposite in2='hardAlpha' operator='out' />
        <feColorMatrix
          type='matrix'
          values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.04 0'
        />
        <feBlend
          mode='normal'
          in2='BackgroundImageFix'
          result='effect1_dropShadow_1208_72860'
        />
        <feColorMatrix
          in='SourceAlpha'
          type='matrix'
          values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
          result='hardAlpha'
        />
        <feMorphology
          radius='3.29429'
          operator='erode'
          in='SourceAlpha'
          result='effect2_dropShadow_1208_72860'
        />
        <feOffset dy='13.1772' />
        <feGaussianBlur stdDeviation='6.58859' />
        <feComposite in2='hardAlpha' operator='out' />
        <feColorMatrix
          type='matrix'
          values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0'
        />
        <feBlend
          mode='normal'
          in2='effect1_dropShadow_1208_72860'
          result='effect2_dropShadow_1208_72860'
        />
        <feBlend
          mode='normal'
          in='SourceGraphic'
          in2='effect2_dropShadow_1208_72860'
          result='shape'
        />
      </filter>
      <filter
        id='filter2_dd_1208_72860'
        x='109.486'
        y='53.6884'
        width='24.5281'
        height='18.098'
        filterUnits='userSpaceOnUse'
        color-interpolation-filters='sRGB'
      >
        <feFlood flood-opacity='0' result='BackgroundImageFix' />
        <feColorMatrix
          in='SourceAlpha'
          type='matrix'
          values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
          result='hardAlpha'
        />
        <feOffset dy='0.752981' />
        <feGaussianBlur stdDeviation='0.752981' />
        <feComposite in2='hardAlpha' operator='out' />
        <feColorMatrix
          type='matrix'
          values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.06 0'
        />
        <feBlend
          mode='normal'
          in2='BackgroundImageFix'
          result='effect1_dropShadow_1208_72860'
        />
        <feColorMatrix
          in='SourceAlpha'
          type='matrix'
          values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
          result='hardAlpha'
        />
        <feOffset dy='0.752981' />
        <feGaussianBlur stdDeviation='1.12947' />
        <feComposite in2='hardAlpha' operator='out' />
        <feColorMatrix
          type='matrix'
          values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0'
        />
        <feBlend
          mode='normal'
          in2='effect1_dropShadow_1208_72860'
          result='effect2_dropShadow_1208_72860'
        />
        <feBlend
          mode='normal'
          in='SourceGraphic'
          in2='effect2_dropShadow_1208_72860'
          result='shape'
        />
      </filter>
      <clipPath id='clip0_1208_72860'>
        <rect
          x='10.3564'
          y='17.8584'
          width='68.5213'
          height='98.8288'
          rx='10.5891'
          transform='rotate(-15 10.3564 17.8584)'
          fill='white'
        />
      </clipPath>
      <clipPath id='clip1_1208_72860'>
        <rect
          width='68.5213'
          height='98.8288'
          rx='10.5891'
          transform='matrix(-0.965926 -0.258819 -0.258819 0.965926 163.866 40.4512)'
          fill='white'
        />
      </clipPath>
    </defs>
  </svg>
);

import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { get } from "lodash";
import { API, graphqlOperation } from "aws-amplify";
import { useQueryClient } from "@tanstack/react-query";
import { idCompanySelector } from "../selectors/company";
import { fetchSellers } from "../reducers/sellers";
import { SellersKeys } from "../components/settings/Sellers/queries";
import * as subscriptions from "../graphql/subscriptions";

const SellersSNS = () => {
  const ref = useRef();
  const idCompany = useSelector(idCompanySelector);
  const networkStatus = useSelector((state) => get(state, "app.networkStatus", "online"));
  const queryClient = useQueryClient()
  const dispatch = useDispatch();

  useEffect(() => {
    let subscription = null;

    if (networkStatus === "online") {
      subscription = API.graphql(graphqlOperation(subscriptions.onCreateSellerNotify, {
        channel: `pos_${idCompany}`
      }))
        .subscribe({
          next: async () => {
            await queryClient.invalidateQueries(SellersKeys.all);
            dispatch(fetchSellers());
          },
          error: () => setTimeout(() => {
            if (!!ref.current)
              console.log("Error");
          }, 60000)
        });
    }

    return () => {
      if (subscription)
        subscription.unsubscribe();
    };
  }, [networkStatus, idCompany]);

  useEffect(() => {
    let subscription = null;

    if (networkStatus === "online") {
      subscription = API.graphql(graphqlOperation(subscriptions.onUpdateSellerNotify, {
        channel: `pos_${idCompany}`
      }))
        .subscribe({
          next: async () => {
            await queryClient.invalidateQueries(SellersKeys.all);
            dispatch(fetchSellers());
          },
          error: () => setTimeout(() => {
            if (!!ref.current)
              console.log("Error");
          }, 60000)
        });
    }

    return () => {
      if (subscription)
        subscription.unsubscribe();
    };
  }, [networkStatus, idCompany]);

  useEffect(() => {
    let subscription = null;

    if (networkStatus === "online") {
      subscription = API.graphql(graphqlOperation(subscriptions.onDeleteSellerNotify, {
        channel: `pos_${idCompany}`
      }))
        .subscribe({
          next: async () => {
            await queryClient.invalidateQueries(SellersKeys.all);
            dispatch(fetchSellers());
          },
          error: () => setTimeout(() => {
            if (!!ref.current)
              console.log("Error");
          }, 60000)
        });
    }

    return () => {
      if (subscription)
        subscription.unsubscribe();
    };
  }, [networkStatus, idCompany]);

  return null;
};

export default SellersSNS;
import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { I18n } from '@aws-amplify/core';
import { get } from 'lodash';

import { filter } from '../../../database/itemsDB';
import { openModal } from '../../../reducers/modals';
import { addItem } from '../../../reducers/activeInvoice';
import { checkFeatureLimit } from '../../../reducers/membership';
import { hasPermissionTo } from '../../../selectors/auth'
import Select from '../../common/Select';
import { Icon, Tooltip, Typography } from '@alegradev/smile-ui-react';
import { useConnectionStatus } from '../../../hooks/useConnectionStatus';
import { getAvailableQuantity, showNewStockFeature } from '../Items/utils';
import { station } from '../../../selectors/app';
import { IconAlertCircle } from '@tabler/icons-react';
import { country as countrySelector } from '../../../selectors/company';

const SearchItem = () => {
  const dispatch = useDispatch();
  const can = useSelector(hasPermissionTo)
  const { idWarehouse } = useSelector(station);
  const online = useConnectionStatus();
  const country = useSelector(countrySelector);

  return (
    <div className="invoice-top__item px-3 pb-3 shadow d-flex d-sm-none w-100 text-white align-items-center">
      <div className="w-100 px-2">
        <Select
          loadOptions={async text => await filter({ text, categories: [], limit: 10 })}
          defaultOptions
          value={null}
          placeholder={I18n.get('searchItems', 'Buscar items')}
          getOptionLabel={option => {
            const availableQuantity = getAvailableQuantity(option, idWarehouse);
            const isOutOfStock =
              availableQuantity <= 0 &&
              !get(option, 'inventory.negativeSale', false) &&
              get(option, 'type', '') === 'product';
            return !showNewStockFeature({ country }) ? (
              <div className='d-flex align-items-center justify-content-between p-1'>
                <p className='h4 text-truncate'>
                  {`${option.name}${
                    !!get(option, 'reference.reference')
                      ? ` (${get(option, 'reference.reference')})`
                      : !!get(option, 'reference')
                        ? ` (${get(option, 'reference')})`
                        : ''
                  }`}
                </p>
              </div>
            ) : (
              <div
                className={`d-flex align-items-center gap-3 justify-content-between p-1 ${isOutOfStock ? 'op-50' : ''}`}
              >
                <div className='text-truncate'>
                  {`${option.name}${
                    !!get(option, 'reference.reference')
                      ? ` (${get(option, 'reference.reference')})`
                      : !!get(option, 'reference')
                        ? ` (${get(option, 'reference')})`
                        : ''
                  }`}
                </div>
                {get(option, 'type', '') === 'product' &&
                  availableQuantity <= 0 && (
                    <div className='invoice-top__no-quantity'>
                      <Icon
                        icon={IconAlertCircle}
                        color='#F59E0B'
                        size='small'
                      />
                      <Typography
                        type='caption-regular'
                        text={I18n.get('outOfStock', 'Agotado')}
                        variant='secondary'
                      />
                    </div>
                  )}
              </div>
            );
          }}
          getOptionValue={option => option.id}
          onChange={item => dispatch(addItem(item))}
        />
      </div>

      <Tooltip
        visible={!can('add', 'items') || !online}
        overlay={online ? I18n.get(
          'userNotAllowed.items.add',
          'no tienes permisos para agregar productos'
        ) : I18n.get('offileCreatingProductBlocked', 'Necesitás tener conexión para crear productos.')}
      >
        <button
          id="add-item-button"
          type="button"
          disabled={!can('add', 'items') || !online}
          className="btn button-transparent btn-add-item d-flex justify-content-between align-items-center mr-3"
          onClick={() => dispatch(checkFeatureLimit('items', () => dispatch(openModal({ modal: 'item' }))))}
        >
          <div className="w-100">{I18n.get('newItemTitle', 'Nuevo item')}</div>
        </button>
      </Tooltip>
    </div>
  )
}

export default SearchItem;
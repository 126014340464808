import { createDraftSafeSelector } from '@reduxjs/toolkit';
import { totalToCollectSelector } from '../reducers/totalToCollect';

const totalToCollectDataSelector = (state) => state.totalToCollect;

export const allTotalToCollectData = createDraftSafeSelector(
  totalToCollectSelector.selectAll,
  (data) => data
);

export const loadingSelector = createDraftSafeSelector(
  totalToCollectDataSelector,
  (data) => data.loading === 'loading'
);

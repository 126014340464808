import React from 'react';
import { I18n } from '@aws-amplify/core';
import { graphqlOperation } from '@aws-amplify/api';
import { Form, Field } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';
import { capitalize, get } from 'lodash';

import * as mutations from '../../../../../graphql/mutations';

import {
  validate,
  transform,
} from '../../../../../components/forms/paymentTerm/utils';
import { APIGraphqlSelector } from '../../../../../selectors/app';
import {
  Button,
  Grid,
  toastHandler,
  Typography,
  useModal,
} from '@alegradev/smile-ui-react';
import { renderField } from '../../../../../components/forms/fields/V2';
import { addPaymentTerm } from '../../../../../reducers/paymentTerms';
import { formError } from '../../../../../utils/errors';

function PaymentTerm({ modalName, onCreated }) {
  const { closeModal } = useModal();
  const { Wrapper } = Grid;
  const dispatch = useDispatch();
  const APIGraphql = useSelector(APIGraphqlSelector);

  const submit = async (values) => {
    const transformedValues = transform(values);

    try {
      const response = await APIGraphql(
        graphqlOperation(mutations.createPaymentTerm, {
          paymentTerm: transformedValues,
        })
      );

      const paymentTerm = !!get(response, 'data.createPaymentTerm')
        ? get(response, 'data.createPaymentTerm')
        : null;

      if (!!paymentTerm) {
        dispatch(addPaymentTerm(paymentTerm));
        if (!!onCreated) onCreated(paymentTerm);
      }

      closeModal(modalName);
      toastHandler({
        type: 'success',
        title: I18n.get(
          'paymentTermCreatedSuccessfully',
          'plazo de pago creado con éxito'
        ),
      });
    } catch (error) {
      return formError(
        error,
        I18n.get(
          'createPaymentTermError',
          'hubo un error en la creación del plazo de pago'
        )
      );
    }
  };

  return (
    <Form onSubmit={submit} validate={(values) => validate(values)}>
      {({ handleSubmit, submitting, submitError, errors }) => (
        <form noValidate onSubmit={handleSubmit}>
          <Wrapper direction={{ lg: 'column' }} gap={10}>
            <Field
              name='name'
              component={renderField}
              type='text'
              label={capitalize(I18n.get('name', 'Nombre'))}
              required
            />
            <Field
              name='days'
              component={renderField}
              type='number'
              label={capitalize(I18n.get('daysQuantity', 'Cantidad de días'))}
              required
            />

            {submitError && (
              <Typography
                type='body-3-regular'
                variant='error'
                text={submitError}
              />
            )}

            <Wrapper gap={10}>
              <Button
                emphasis='outline'
                full
                disabled={submitting}
                onClick={() => closeModal(modalName)}
                type='button'
                text={I18n.get('cancel', 'Cancelar')}
              />
              <Button
                full
                disabled={submitting || Object.keys(errors).length > 0}
                loading={submitting}
                text={I18n.get('save', 'Guardar')}
              />
            </Wrapper>
          </Wrapper>
        </form>
      )}
    </Form>
  );
}

export default PaymentTerm;

import React from 'react'
import PropTypes from 'prop-types';
import { I18n } from '@aws-amplify/core';
import { capitalize } from 'lodash'

const InvoiceOptions = ({ optionsRef, show, onDelete, onRename }) => (
  <div ref={optionsRef} className={`footer__invoice-options position-absolute shadow ${show ? 'visible': 'invisible'}`}>
    <ul className="list-group">
      <li 
        className="btn list-group-item footer__invoice-action"
        onClick={() => onRename()}
      >
        {capitalize(I18n.get('rename', 'renombrar'))}
      </li>
      <li 
        className="btn list-group-item footer__invoice-action"
        onClick={() => onDelete()}
      >
        {capitalize(I18n.get('delete', 'eliminar'))}
      </li>
    </ul>
  </div>
)

InvoiceOptions.propTypes = {
  show: PropTypes.bool,
  optionsRef: PropTypes.any,
  onDelete: PropTypes.func,
  onRename: PropTypes.func,
};

export default InvoiceOptions;
import { get } from 'lodash';

export const colombiaTransformer = (values, { isElectronic }, newValues) => {
  const identificationType = get(values, 'identification.type', null);
  const identificationNumber = get(values, 'identification.number', null);
  const kindOfPerson = identificationType
    ? get(values, 'kindOfPerson', null)
    : isElectronic
      ? 'PERSON_ENTITY'
      : null;

  const completeName = kindOfPerson === 'PERSON_ENTITY';

  const countryKey = get(values, 'address.country', null);
  const countryValue = get(values, 'address.country', null);
  const combinedAddress = get(values, 'address.combined.value', null);
  const zipCode = get(values, 'address.zipCode', null);
  const address = get(values, 'address.address', null);

  const foreignId =
    identificationType === 'DIE' ||
    identificationType === 'PP' ||
    identificationType === 'TE' ||
    identificationType === 'FOREIGN_NIT';
  const showCountry = !!identificationType && foreignId;

  let newAddress = {
    country: showCountry ? countryValue : 'Colombia',
    zipCode: zipCode,
    address: address,
  };
  if (!!combinedAddress && (!foreignId || countryKey === 'COL')) {
    newAddress = {
      ...newAddress,
      city: get(values, 'address.combined.city', null),
      department: get(values, 'address.combined.department', null),
    };
  }

  let fiscalResponsabilities = [];
  const fiscalResponsabilitiesValues = get(
    values,
    'fiscalResponsabilities',
    null
  );
  if (!!fiscalResponsabilitiesValues) {
    Object.keys(fiscalResponsabilitiesValues).map((key) => {
      if (fiscalResponsabilitiesValues[key] === true)
        fiscalResponsabilities.push(key);
      return null;
    });
  }

  delete newValues.identification;
  
  return {
    ...newValues,
    nameObject: !!completeName
      ? {
          firstName: get(values, 'firstName', ''),
          secondName: get(values, 'secondName', ''),
          lastName: get(values, 'lastName', ''),
        }
      : null,
    name: !completeName ? get(values, 'firstName', '') : null,
    identificationObject: {
      type: identificationType,
      number: identificationNumber,
    },
    kindOfPerson,
    address: newAddress,
    regime: get(values, 'regime', null),
    fiscalResponsabilities: isElectronic ? fiscalResponsabilities : null,
    creditLimit: get(values, 'creditLimit', null),
  };
};

const invoiceTypes = [
	{
		id: "01",
		value: "Factura nacional",
		key: "NATIONAL"
	},
	{
		id: "02",
		value: "Factura de exportación",
		key: "EXPORT"
	}
]

export default invoiceTypes
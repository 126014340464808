import { createDraftSafeSelector } from '@reduxjs/toolkit';

const monitoringDataSelector = state => state.monitoring; 

export const calculateActionTimeSelectorWrapper = (getState) => {
  return (action) => {
    return calculateActionTimeSelector(getState())(action)
  }
}

export const calculateActionTimeSelector = createDraftSafeSelector(
  monitoringDataSelector,
  monitoring => (action) => {
    const actionState = monitoring[action];

    if(actionState) {
      console.log(`monitoring ${action}`, actionState)
      const diff = actionState.endTime - actionState.startTime;
      let diffSec = diff > 1000 ? diff / 1000 : 0; 
      diffSec = Number(diffSec.toFixed(3))
      console.log(`time: ${diffSec}`)
      return diffSec
    }

    return null;
  }
)
export const fiscalResponsabilities = [
  {
    id: 5,
    value: "Gran contribuyente",
  },
  {
    id: 7,
    value: "Autorretenedor",
  },
  {
    id: 12,
    value: "Agente de retención en el impuesto sobre las ventas",
  },
  {
    id: 114,
    value: "Régimen simple de tributación",
  },
]
import React from 'react';
import { I18n } from '@aws-amplify/core';
import { useSelector } from 'react-redux';
import { get, replace } from 'lodash';
import dayjs from 'dayjs';
import { replaceAndParse } from '../../../utils';
import { membershipSelector, companySelector, country as countrySelector } from '../../../selectors/company';
import { isHappyWeekAvailable } from '../../../selectors/membership';

// Helper function for rendering the notification message
const renderNotificationMessage = (happyWeek, isPlanDemo, isPlanReadonly, showMexDiscount, timeLeft, country) => {
  {/*if (country === 'colombia' && happyWeek && isPlanDemo) {
    return (
      <p className="m-0 h5 d-inline">
        {' '}
        {replaceAndParse(I18n.get('alegraWeekIsHere1.demo', 'Elige tu plan hoy con 50% OFF por 2 meses o continúa tus {} {} gratis.'), [
          `<span class='font-weight-bold'>${timeLeft > 1 ? timeLeft : 0}</span>`,
          `<span class='font-weight-bold'>${timeLeft > 1 ? I18n.get('days', 'días') : I18n.get('day', 'día')}</span>`
        ])}
      </p>
    );
  }

  if (happyWeek && (isPlanDemo || isPlanReadonly)) {
    return (
      <p className="m-0 h5 d-inline">
        {' '}
        {isPlanDemo && replaceAndParse(I18n.get('alegraWeekIsHere1.demo', 'Elige tu plan hoy con 50% OFF por 2 meses o continúa tus {} {} gratis.'), [
          `<span class='font-weight-bold'>${timeLeft > 1 ? timeLeft : 0}</span>`,
          `<span class='font-weight-bold'>${timeLeft > 1 ? I18n.get('days', 'días') : I18n.get('day', 'día')}</span>`
        ])}
        {isPlanReadonly && I18n.get('alegraWeekIsHere1.readOnly', '¡Llegó el Happy Week con grandes ofertas!')}
      </p>
    );
  }*/}

  return (
    <p className="m-0 h5 d-inline">
      {' '}
      {isPlanDemo && showMexDiscount
        ? replaceAndParse(I18n.get('mexDiscountFreeTrial', 'Tienes {} {} de prueba gratis. ¡Adquiere tu plan TPV antes del 16 de septiembre con 50% OFF por dos meses! CTA Adquirir mi plan'), [
          `<span class='font-weight-bold'>${timeLeft > 1 ? timeLeft : 0}</span>`,
          `<span class='font-weight-bold'>${timeLeft > 1 ? I18n.get('days', 'días') : I18n.get('day', 'día')}</span>`
        ])
        : isPlanDemo
          ? replaceAndParse(I18n.get('youHaveA15DayFreeTrial', 'Tienes {} {} de prueba gratis para descubrir lo fácil que es gestionar tu negocio.'), [
            `<span class='font-weight-bold'>${timeLeft > 1 ? timeLeft : 0}</span>`,
            `<span class='font-weight-bold'>${timeLeft > 1 ? I18n.get('days', 'días') : I18n.get('day', 'día')}</span>`
          ])
          : replaceAndParse(I18n.get('isMomentToChoseYourPlan', 'Es momento de elegir tu plan ideal para seguir vendiendo fácil y rápido en tu negocio.'), [])}
      {' '}
    </p>
  );
};

const MembershipNotification = () => {
  const membership = useSelector(membershipSelector);
  const company = useSelector(companySelector);
  const country = useSelector(countrySelector);
  const happyWeek = useSelector(isHappyWeekAvailable);

  const planKeyword = get(membership, 'plan.keyword', '');
  const showMexDiscount = country === 'mexico' && dayjs().isAfter('2022-09-12') && dayjs().isBefore('2022-09-19');
  const showPlans = planKeyword.includes('readonly') || (planKeyword.includes('demo') && dayjs(get(company, 'registryDate')).isAfter(dayjs('2021-09-15')));
  const isPlanDemo = planKeyword.includes('demo');
  const isPlanReadonly = planKeyword.includes('readonly');
  const planSelected = !!get(company, 'subscription');

  if (!showPlans || planSelected) return null;

  const dateStart = get(membership, 'dateStart');
  const dateEnd = get(membership, 'dateEnd');
  const totalTime = dayjs(dateEnd).diff(dateStart, 'days');
  const timeLeft = dayjs(dateEnd).diff(dayjs().startOf('day'), 'days');

  const getMembershipPlanName = () => {
    let planName = get(membership, 'plan.name');
    if (country === 'spain') {
      planName = replace(planName, 'POS', 'TPV');
    }
    return planName;
  };

  return (
    <div className="main-notification d-none d-md-block">
      <div className="main-notification__notification bg-white d-flex align-items-start p-2">
        <div>
          <svg width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="0.525146" y="0.0301514" width="31.9397" height="31.9397" rx="15.9698" fill="#FBE8D2" fillOpacity="0.77" />
            <path d="M23.1314 20.3384H9.84109V21.3233H23.1314V20.3384Z" fill="#F99D36" />
            <path d="M16.4952 10.6767L14.2592 13.5463L9.84106 10.7276V18.8442H23.1493V10.7276L18.7311 13.5463L16.4952 10.6767Z" fill="#F99D36" />
          </svg>
        </div>

        <div className="d-flex flex-column align-items-start justify-content-between w-100 p-1 px-3">
          <div className="m-0 h4">
            {!!get(membership, 'plan.name') && <b className="text-title">{getMembershipPlanName()}:</b>}
            {renderNotificationMessage(happyWeek, isPlanDemo, isPlanReadonly, showMexDiscount, timeLeft, country)}
          </div>

          <div
            className="progress w-100"
            style={{
              marginTop: '.5rem',
              height: '.5rem',
              background: '#FCEEDD'
            }}
          >
            <div
              className="progress-bar"
              role="progressbar"
              style={{
                width: `${(totalTime - timeLeft) / totalTime * 100}%`,
                background: '#F99D36'
              }}
              aria-valuenow={+`${(totalTime - timeLeft) / totalTime * 100}` || 0}
              aria-valuemin="0"
              aria-valuemax="100"
            />
          </div>
        </div>

        <div>
          <button
            type="button"
            className="btn text-capitalize-first"
            onClick={() => window.open(`${process.env.REACT_APP_ALEGRA_SMILE}subscriptions/products/alegra-pos/plans`, '_blank')}
            style={{
              margin: 0,
              minWidth: '10rem',
              height: '3.2rem',
              border: '1px solid #F99D36',
              boxSizing: 'border-box',
              borderRadius: '31px',
              fontSize: '1.1rem',
              fontWeight: 900,
              color: '#F99D36',
              padding: '0 .25rem',
              outline: 'none',
              WebkitAppearance: 'none',
              MozAppearance: 'none',
              appearance: 'none'
            }}
          >
            {I18n.get('chooseMyPlan', 'Elegir mi plan')}
          </button>
        </div>
      </div>
    </div>
  );
};

export default MembershipNotification;

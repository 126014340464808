import React from 'react'

const UnlimitedFeatureConsumption = ({title, currentValue}) => {
    return (
        <div className="general-consumption-content unlimited-feature-consumption py-2 d-flex flex-row justify-content-between">
            <p className="h5 text-muted text-capitalize-first">
                {title}
            </p>
            <p className="h5" style={{ textAlign: "end" }}>
                {currentValue}
            </p>
        </div>
    );
}

export default UnlimitedFeatureConsumption;
import { I18n } from 'aws-amplify';
import { get, capitalize } from 'lodash';

export const spainValidation = (values) => {
  let errors = {};

  const identificationType = get(values, 'identification.type', '');
  const identificationNumber = get(values, 'identification.number', '');
  const combinedAddress = get(values, 'address.combined', '');
  const address = get(values, 'address.address', '');
  const postalCode = get(values, 'address.zipCode', '');
  const country = get(values, 'address.country', '');

  if (!identificationType)
    errors.identification = {
      ...errors.identification,
      type: capitalize(
        I18n.get(
          'selectTheIdentificationType',
          'seleccione el tipo de identificación'
        )
      ),
    };
  if (
    (identificationType === 'DNI' ||
      identificationType === 'NIF' ||
      identificationType === 'NIE') &&
    identificationNumber.length < 9
  )
    errors.identification = {
      ...errors.identification,
      number:
        capitalize(
          I18n.get(
            'theMinimumSizeForThisFieldIs',
            'El tamaño minimo para este campo es de'
          )
        ) + ' 9.',
    };
  if (
    (identificationType === 'DNI' ||
      identificationType === 'NIF' ||
      identificationType === 'NIE') &&
    identificationNumber.length > 9
  )
    errors.identification = {
      ...errors.identification,
      number:
        capitalize(
          I18n.get(
            'theMaximumSizeForThisFieldIs',
            'El tamaño maximo para este campo es de'
          )
        ) + ' 9.',
    };
  if (
    identificationType === 'DNI' &&
    !/^\d{8}[A-Za-z]$/.test(identificationNumber)
  ) {
    errors.identification = {
      ...errors.identification,
      number: I18n.get(
        'theDNIhasEightNumbersAndOneDigitVerificationCode',
        'Debe tener ocho números y una letra.'
      ),
    };
  }

  if (
    identificationType === 'NIF' &&
    !/^[xyzbXYZB]\d{7}[0-9a-zA-Z]$/.test(identificationNumber)
  )
    errors.identification = {
      ...errors.identification,
      number: I18n.get(
        'mustHaveALetterAndSevenNumbersAndOneVerificationCode',
        'Debe tener una letra, siete números y un dígito de control.'
      ),
    };
  if (
    identificationType === 'NIE' &&
    !/^[xyzXYZ]\d{7}[0-9a-zA-Z]$/.test(identificationNumber)
  )
    errors.identification = {
      ...errors.identification,
      number: I18n.get(
        'mustHaveALetterAndSevenNumbersAndOneVerificationCode',
        'Debe tener una letra, siete números y un dígito de control.'
      ),
    };

  if (identificationType === 'NIF' && !combinedAddress)
    errors.address = {
      ...errors.address,
      combined: capitalize(
        I18n.get('thisFieldIsRequired', 'Este campo es obligatorio')
      ),
    };
  if (identificationType === 'NIF' && !address)
    errors.address = {
      ...errors.address,
      address: capitalize(
        I18n.get('thisFieldIsRequired', 'Este campo es obligatorio')
      ),
    };
  if (postalCode) {
    if (postalCode.length < 5)
      errors.address = {
        ...errors.address,
        zipCode:
          capitalize(
            I18n.get(
              'theMinimumSizeForThisFieldIs',
              'El tamaño minimo para este campo es de'
            )
          ) + ' 5.',
      };
    if (postalCode.length > 5)
      errors.address = {
        ...errors.address,
        zipCode:
          capitalize(
            I18n.get(
              'theMaximumSizeForThisFieldIs',
              'El tamaño maximo para este campo es de'
            )
          ) + ' 5.',
      };
    if (!/^\d{5}$/.test(postalCode)) {
      errors.address = {
        ...errors.address,
        postalCode: I18n.get(
          'thePostalCodeMustHaveFiveNumbers',
          'Debe tener cinco números.'
        ),
      };
    }
  }

  if (identificationType === 'EXT' && !country)
    errors.address = {
      ...errors.address,
      country: capitalize(
        I18n.get('thisFieldIsRequired', 'Este campo es obligatorio')
      ),
    };
  return errors;
};

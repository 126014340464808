import React, { useState, useEffect, useRef, useCallback } from 'react'
import { I18n } from '@aws-amplify/core';
import { graphqlOperation } from '@aws-amplify/api';
import { useSelector } from 'react-redux'
import { NavLink, useHistory } from 'react-router-dom'
import { get, capitalize } from 'lodash'
import dayjs from 'dayjs'

import * as queries from '../../../graphql/queries'
import { hasPermissionTo } from '../../../selectors/auth';
import { APIGraphqlSelector, station as stationSelector } from '../../../selectors/app'
import { shiftsConfigured } from '../../../selectors/company'
import ConfirmationModal from '../../modals/confirmation/Confirmation'
import Header from '../common/Header'
import ShiftHeader from './Header';
import ChartWrapper from './ChartWrapper'
import ShiftsCharts from './ShiftsCharts'
import PaymentsCharts from './PaymentsCharts'
import PageNetworkMessage from '../../network/PageNetworkMessage';
import { useConnectionStatus } from '../../../hooks/useConnectionStatus'

const Shifts = () => {
  const connectionStatus = useConnectionStatus();
  const history = useHistory()
  const can = useSelector(hasPermissionTo)
  const APIGraphql = useSelector(APIGraphqlSelector);
  const ref = useRef()
  const [openModal, setOpenModal] = useState(false)
  const [total, setTotal] = useState(null)
  const [startDate, setStartDate] = useState(dayjs().subtract(1, 'week').toDate())
  const [endDate, setEndDate] = useState(new Date())
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [metadata, setMetadata] = useState({
    start: 0,
    limit: 10000,
    sortDirection: 'desc'
  })
  const isShiftsConfigured = useSelector(shiftsConfigured)

  const { id } = useSelector(stationSelector);

  const fetchData = useCallback(async batch => {
    if (!can('index', 'pos-cashier') || !can('view', 'pos-cashier')) return;

    setError(false);
    setLoading(true);
    try {
      const response = await APIGraphql(graphqlOperation(queries.allShifts, {
        idStation: id,
        includeMetadata: true,
        batch,
        filter: {
          startDate: dayjs(startDate).toISOString(),
          endDate: dayjs(endDate).toISOString(),
        }
      }))
      if (!!ref.current) {
        setLoading(false);
        setData(get(response, 'data.allShifts.data', []).reverse())
        setTotal(get(response, 'data.allShifts.metadata.total', 0))
      }
    } catch (error) {
      if (!!ref.current) {
        setLoading(false);
        setError(true);
      }
    }
    if (!!ref.current)
      setMetadata(batch)
  }, [id, can, startDate, endDate, APIGraphql])

  useEffect(() => {
    fetchData({
      start: 0,
      limit: 10000,
      sortDirection: 'desc'
    });
  }, [fetchData]);

  useEffect(() => {
    ref.current = true;
    window.dataLayer.push({
      'event': 'VirtualPageview',
      'virtualPageURL': '/statistics/shifts',
      'virtualPageTitle' : 'Shifts Statistics'
    });

    return () => {
      setData([])
      setTotal(null)
      ref.current = false
    }
  }, [])

  useEffect(() => {
    if (total === 0)
      setOpenModal(true)

    return () => {
      setOpenModal(false)
    }
  }, [total])


  return (
    <div className='w-100 h-100 container py-5'>
      <Header
        title={I18n.get('shiftStatistics', 'Estadísticas de turnos')}
        subtitle={
          <p className='text-capitalize-first'>
            {I18n.get(
              'shiftsStatisticsHelpText',
              'Conoce el detalle de tus turnos cerrados en el período de tiempo que elijas'
            )}{' '}
            <a
              className='btn-link'
              href='https://ayuda.alegra.com/es/grafica-del-flujo-de-efectivo-de-turnos-del-pos'
              target='_blank'
              rel='noreferrer'
            >
              {capitalize(I18n.get('seeMore', 'ver mas'))}
            </a>
          </p>
        }
      />

      {!connectionStatus ? (
        <PageNetworkMessage fullPage={false} />
      ) : (
        <>
          <div className='d-flex form-row justify-content-between'>
            <ShiftHeader
              data={data}
              loading={loading}
              error={error}
              startDate={startDate}
              endDate={endDate}
              setStartDate={setStartDate}
              setEndDate={setEndDate}
            />

            <div className='col-md-6'>
              <ChartWrapper
                chart={ShiftsCharts}
                title={I18n.get(
                  'cashOperationsByShifts',
                  'Operaciones de efectivo'
                )}
                data={data}
                loading={loading}
                error={error}
                total={total}
                fetchData={() => fetchData(metadata)}
              />
            </div>
            <div className='col-md-6'>
              <ChartWrapper
                chart={PaymentsCharts}
                title={I18n.get('paymentTypes', 'Tipos de pagos')}
                data={data}
                loading={loading}
                error={error}
                total={total}
                fetchData={() => fetchData(metadata)}
              />
            </div>
          </div>

          <ConfirmationModal
            isOpen={openModal && !isShiftsConfigured}
            onConfirm={() => history.push('/settings/company')}
            onClose={() => setOpenModal(false)}
            hideCancel
            confirmText={I18n.get('understood', 'Entendido')}
            title={I18n.get('configureShifts', 'Configura turnos')}
            body={
              <p className='text-center h4 text-capitalize-first p-5'>
                {I18n.get(
                  'noShiftsConfigured',
                  'Los turnos son intervalos de tiempo que sirven para administrar tus operaciones en tu compañía. Comienza a utilizarlos'
                )}{' '}
                <NavLink className='btn-link' to='/settings/company'>
                  {I18n.get('here', 'aquí')}
                </NavLink>
              </p>
            }
          />
        </>
      )}
    </div>
  );
}

export default Shifts;
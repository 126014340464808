import { I18n } from '@aws-amplify/core';
import { isToday, isValid, subDays } from 'date-fns';
import { find } from 'lodash';

export const periodicity = [
  { value: '01', label: 'Diario' },
  { value: '02', label: 'Semanal' },
  { value: '03', label: 'Quincenal' },
  { value: '04', label: 'Mensual' },
  { value: '05', label: 'Bimestral' },
];

const validate = (values, isEditable) => {
  const formValues = { ...values };
  const errors = {};

  const fields = [
    'numeration',
    'idNumberTemplate',
    'creationDate',
    'periodicityPeriod',
    'periodicityMonth',
    'periodicityYear',
  ];

  formValues.creationDate = new Date(values.creationDate);
  formValues.periodicityMonth = new Date(values.periodicityMonth);
  formValues.periodicityYear = new Date(values.periodicityYear);

  fields.forEach((field) => {
    if (!formValues[field]) {
      errors[field] = I18n.get('', 'Campo requerido');
    }
  });

  if (
    formValues.periodicityPeriod &&
    !find(periodicity, (p) => p.value === formValues.periodicityPeriod.value)
  ) {
    errors.periodicityPeriod = I18n.get('', 'Periodicidad inválida');
  }

  if(!isValid(formValues.periodicityMonth)) {
    errors.periodicityMonth = I18n.get('', 'Mes inválido');
  }

  if(!isValid(formValues.periodicityYear)) {
    errors.periodicityYear = I18n.get('', 'Año inválido');
  }

  if (formValues.creationDate && !isEditable) {
    const currentDate = new Date();
    const threeDaysBefore = subDays(currentDate, 3);

    if (
      !isToday(formValues.creationDate) &&
      formValues.creationDate < threeDaysBefore
    ) {
      errors.creationDate = I18n.get('', 'Fecha de creación inválida');
    }
  }

  return errors;
};

export default validate;

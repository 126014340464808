import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form } from 'react-final-form';
import { I18n } from '@aws-amplify/core';
import { graphqlOperation } from '@aws-amplify/api';
import { get, isEmpty } from 'lodash';

import * as mutations from '../../../graphql/mutations';
import { toast, replaceAndParse } from '../../../utils';
import { formError, handleError } from '../../../utils/errors';
import { stationTransformer } from '../../../utils/transformers';
import { getMainPriceList } from '../../../selectors/priceLists';
import { updateStation } from '../../../reducers/app';
import { closeModal } from '../../../reducers/modals';
import {
  APIGraphqlSelector,
  station as stationSelector,
} from '../../../selectors/app';
import {
  getNumerationById,
  isCashReceiptNumerationActive,
  isRefundNumerationActive,
} from '../../../selectors/numerations';
import { getBankById } from '../../../selectors/banks';
import { getCostCenterById } from '../../../selectors/costCenters';
import { getSellerById } from '../../../selectors/sellers';
import { getWarehouseById } from '../../../selectors/warehouses';
import { getStationUsers } from '../../../selectors/users';
import { validate, transform } from '../../forms/station/utils';
import Modal from '../../common/Modal';
import Notification from '../common/Notification';
import Bottom from '../common/Bottom';
import Body from './FormBody';
import { sendNewGTMEvent } from '../../../reducers/company';
import { getPriceListById } from '../../../selectors/priceLists';
import { endAction, startAction } from '../../../reducers/monitoring';
import { calculateActionTimeSelector } from '../../../selectors/monitoring';
import useSendGTMEvent from '../../../hooks/useSendGtmEvent/hook';

const Station = ({ onRefresh }) => {
  const dispatch = useDispatch();
  const isOpen = useSelector((state) =>
    get(state, 'modals.station.isOpen', false)
  );
  useSendGTMEvent(isOpen, 'pos-station-started');
  const station = useSelector((state) =>
    get(state, 'modals.station.params.station', false)
  );
  const currentStation = useSelector(stationSelector);
  const cashReceiptActive = useSelector(isCashReceiptNumerationActive);
  const refundActive = useSelector(isRefundNumerationActive);

  const { id } = currentStation;

  const numberTemplate = useSelector(
    getNumerationById(station.idNumberTemplate)
  );
  const receiptNumberTemplate = useSelector(
    getNumerationById(station.idCashReceiptNumberTemplate)
  );
  const refundNumberTemplate = useSelector(
    getNumerationById(station.idRefundNumberTemplate)
  );
  const feNumberTemplate = useSelector(
    getNumerationById(station.idFENumberTemplate)
  );
  const debit = useSelector(getBankById(station.idDebit));
  const credit = useSelector(getBankById(station.idCredit));
  const transfer = useSelector(getBankById(station.idTransfer));
  const cash = useSelector(getBankById(station.idCash));
  const baseBank = useSelector(getBankById(station.baseBank));
  const closeBank = useSelector(getBankById(station.closeBank));
  const warehouse = useSelector(getWarehouseById(station.idWarehouse));
  const costCenter = useSelector(getCostCenterById(station.idCostCenter));
  const seller = useSelector(getSellerById(station.seller));
  const users = useSelector(getStationUsers(station));
  const mainPriceList = useSelector(getMainPriceList);
  const priceList = useSelector(getPriceListById(station.idPriceList));
  const calculateActionTime = useSelector(calculateActionTimeSelector);

  const APIGraphql = useSelector(APIGraphqlSelector);

  const submit = async (values) => {
    dispatch(startAction({action: 'createStation'}));
    const transformedValues = transform(values);

    const request = !!station
      ? APIGraphql(
          graphqlOperation(mutations.updateStation, {
            station: stationTransformer({ ...station, ...transformedValues }),
          })
        )
      : APIGraphql(
          graphqlOperation(mutations.createStation, {
            station: stationTransformer({ ...transformedValues }),
          })
        );

    try {
      const response = await request;

      const updatedStation = get(
        response,
        !!station ? 'data.updateStation' : 'data.createStation'
      );
      if (!!station && station.id === id && !!updatedStation)
        dispatch(updateStation(updatedStation));

      dispatch(closeModal({ modal: 'station' }));
      onRefresh();

      if (!!station)
        toast.success({
          title: I18n.get('stationUpdated', 'terminal actualizada con éxito'),
        });
      else
        toast.success({
          title: `${I18n.get(
            'stationCreatedSuccessfully',
            'terminal creada con éxito'
          )} 🎊`,
          subtitle: replaceAndParse(
            I18n.get(
              'stationCreatedSuccessfullyMessage',
              'La terminal {} ya está disponible para que gestiones las ventas de tu negocio.'
            ),
            [
              `<span class="font-weight-bold">${get(
                updatedStation,
                'name',
                ''
              )}</span>`,
            ]
          ),
        });

      dispatch(endAction({action: 'createStation'}));
      dispatch(sendNewGTMEvent('pos-station-created', {
        responseTime: calculateActionTime("createStation")
      }));
    } catch (error) {
      const formatError = handleError(error);
      dispatch(
        sendNewGTMEvent('pos-station-created', {
          error: formatError
        })
      );
      return formError(
        error,
        I18n.get(
          'updateStationError',
          'hubo un error al cambiar los datos de la terminal'
        )
      );
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={() => dispatch(closeModal({ modal: 'station' }))}
      title={
        <div className='d-flex justify-content-between align-items-center w-100'>
          <div className='modal__header-title text-capitalize-first h2 text-primary text-truncate'>
            {!!station
              ? station.name
              : I18n.get('newStation', 'nueva terminal')}
          </div>
        </div>
      }
      className='modal__stations'
    >
      <Form
        onSubmit={submit}
        validate={(values) =>
          validate(values, { cashReceiptActive, refundActive })
        }
        initialValues={{
          ...station,
          numberTemplate,
          receiptNumberTemplate,
          feNumberTemplate,
          refundNumberTemplate,
          debit,
          credit,
          transfer,
          cash,
          baseBank,
          closeBank,
          warehouse,
          costCenter,
          seller,
          priceList: !!priceList ? priceList : mainPriceList,
          users: !isEmpty(users) ? users : [{ idGlobal: -1, name: 'Todos' }],
        }}
        keepDirtyOnReinitialize
      >
        {({ handleSubmit, submitting, values, submitError, pristine }) => (
          <form noValidate onSubmit={handleSubmit}>
            <Body values={values} />

            {!!submitError && !pristine && (
              <Notification isOpen={true} text={submitError} type='error' />
            )}

            <Bottom
              submitting={submitting}
              onClose={() => dispatch(closeModal({ modal: 'station' }))}
            />
          </form>
        )}
      </Form>
    </Modal>
  );
};

export default Station;

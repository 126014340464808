import React, { useState } from 'react'
import { Switch, Route, Redirect, useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { get } from 'lodash'

import { membershipSelector } from '../../selectors/company'
import Plans from './Plans'
import Subscription from './Subscription'
import Checkout from './Checkout'
import Consumption from './Consumption'
import PageNetworkMessage from '../network/PageNetworkMessage'
import { useConnectionStatus } from '../../hooks/useConnectionStatus'

const Membership = () => {
  const connectionStatus = useConnectionStatus();
  const history = useHistory()
  const membership = useSelector(membershipSelector)
  const [selectedPlan, setSelectedPlan] = useState(null)

  const planSelected = !!get(membership, 'plan.keyword')
    && !(get(membership, 'plan.keyword', '').includes('readonly') || (get(membership, 'plan.keyword', '').includes('demo')))


  if(!connectionStatus) {
    return <PageNetworkMessage />
  }

  return (
    <Switch>
      <Route path="/plan/plans">
        <Plans
          selectedPlan={selectedPlan}
          onPlanSelected={(plan) => {
            setSelectedPlan(plan)
            history.push('/plan/checkout')
          }}
        />
      </Route>
      <Route path="/plan/checkout">
        {!!selectedPlan
          ? (
            <Checkout
              selectedPlan={selectedPlan}
              setSelectedPlan={(plan) => setSelectedPlan(plan)}
            />
          ) : <Redirect to="/plan/plans" />
        }
      </Route>
      <Route path="/plan/consumption">
        <Consumption />
      </Route>
      {planSelected
        ? <Route component={Subscription} />
        : <Redirect to="/plan/plans" />
      }
    </Switch>
  )
}

export default Membership
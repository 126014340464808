import { I18n } from '@aws-amplify/core';
import { capitalize } from 'lodash'

const regimesLabel = country => {
  switch (country) {
    case 'colombia':
      return capitalize(I18n.get('taxLiability', 'Responsabilidad tributaria'));

    default:
      return capitalize(I18n.get('regime', 'Régimen'));
  }
}

export default regimesLabel;
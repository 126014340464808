import React from 'react';

import Search from './Search';
import Items from './Items';

const SearchItems = () => {
  return (
    <div className="search-item-refund w-sm-100 d-flex flex-column h-100">
      <div className="invoice-top d-flex flex-column">
        <Search />
      </div>
      
      <Items />
      
    </div>
  )
}

export default SearchItems;

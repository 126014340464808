import React, { useEffect } from 'react';
import { Field } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays'
import { I18n } from '@aws-amplify/core';
import { capitalize, get } from 'lodash';
import { useSelector, useDispatch } from 'react-redux'
import { BigNumber } from 'bignumber.js'

import { checkFeatureLimit } from '../../../reducers/membership'
import { openModal } from '../../../reducers/modals';
import { hasPermissionTo } from '../../../selectors/auth'
import { decimalPrecision } from '../../../selectors/company'
import { activeExtraPriceLists } from '../../../selectors/priceLists'
import { renderField, renderSelect } from '../fields/V0/Fields';

import { useDecimalsVersionsGroup } from '../../../hooks/useDecimalsVersionsGroup';
import { Icon } from '@alegradev/smile-ui-react';
import { IconPlus, IconX } from '@tabler/icons-react';
import { maxDecimals } from '../../../utils/decimals/maxDecimals';

const PriceLists = ({ values, form, ...props }) => {
  const dispatch = useDispatch()
  const can = useSelector(hasPermissionTo)
  const priceLists = useSelector(activeExtraPriceLists);
  const decimal = useSelector(decimalPrecision);
  const { isDecimalActive } = useDecimalsVersionsGroup();

  const addPriceList = () => {
    form.mutators.push("priceLists")
  }

  function setUpDecimals(key, decimals = 6) {
    if (!isDecimalActive)
      return;
    values[key[0]][key[1]] = values[key[0]][key[1]] === undefined ? { [key[2]]: '' } : values[key[0]][key[1]];
    let inputValue = values[key[0]][key[1]][key[2]];
    if (inputValue && inputValue.includes('.')) {
      if (inputValue.slice(-1) === '.') {
        inputValue = inputValue.slice(0, -1);
        return;
      }

      if (inputValue.split('.')[1].length > decimals)
        inputValue = Number(inputValue).toFixed(decimals)
    }
  }

  useEffect(() => {
    if (!!values.isEditing && (!values.priceLists || values.priceLists.length === 0))
      form.mutators.push("priceLists")
  }, [form.mutators, values])

  return (
    <div className="form-fields form-row justify-content-start">
      <div className="p-0 col-12 form-row form-field-array-wrapper">
        <FieldArray name="priceLists">
          {({ fields }) =>
            fields.map((name, index) => {
              return (
                <div key={index} className="col-12 form-row m-0">
                  <Field
                    name={`${name}.priceList`}
                    component={renderSelect}
                    className="col-6"
                    options={!can('index', 'price-lists') || !can('view', 'price-lists') ? [] : priceLists}
                    selectInfo={!can('index', 'price-lists') || !can('view', 'price-lists')
                      ? I18n.get('userNotAllowed.price-lists.view', 'no tienes permisos para ver la información de las listas de precio')
                      : null}
                    hideNoOptionMessage={!can('index', 'price-lists') || !can('view', 'price-lists')}
                    getOptionLabel={option => get(option, 'type') === 'percentage'
                      ? `${option.name} - ${option.percentage ?? option.value}%`
                      : option.name
                    }
                    getOptionValue={option => option.id}
                    label={capitalize(I18n.get('priceList', 'lista de precio'))}
                    addOptionAction={get(props, 'fromOldForm', false)
                      ? props.newPriceListAction
                      : () => {
                        dispatch(checkFeatureLimit('priceLists', () => {
                          dispatch(openModal({ modal: 'priceList' }))
                        }));
                        props.setPriceListIndex(index);
                      }}
                    addOptionText={`+ ${capitalize(I18n.get('newPriceList', 'Nueva lista de precio'))}`}
                  />
                  <div className="col-6 form-row align-items-end">
                    <Field
                      name={`${name}.price`}
                      className="col-10"
                      type="number"
                      min="0"
                      label={capitalize(I18n.get('price', 'precio'))}
                      disabled={get(values, `${name}.priceList.type`) === 'percentage'}
                    >
                      {props => renderField({
                        ...props,
                        onChange: e => maxDecimals({e, key: `priceLists.${index}.price`, decimals: 6, isActive: isDecimalActive, values}),
                        onBlur: () => setUpDecimals(['priceLists', index, 'price'], 6),
                        input: {
                          ...props.input,
                          value: get(values, `${name}.price`,
                            get(values, `${name}.priceList.type`) === 'percentage'
                              ? new BigNumber(100)
                                .minus(new BigNumber(get(values, `${name}.priceList.percentage`)))
                                .dividedBy(100).multipliedBy(new BigNumber(get(values, 'salePrice', 0)))
                                .decimalPlaces(decimal).toNumber()
                              : get(values, `${name}.priceList.value`))
                          // get(values, `${name}.priceList.type`) === 'percentage'
                          //   ? new BigNumber(100)
                          //     .minus(new BigNumber(get(values, `${ name }.priceList.percentage`)))
                          //     .dividedBy(100).multipliedBy(new BigNumber(get(values, 'salePrice', 0)))
                          //     .decimalPlaces(decimal).toNumber()
                          //   : get(values, `${ name }.price`)
                        }
                      })}
                    </Field>
                    <button
                      type="button"
                      className="btn button-transparent col-2 mb-3"
                      style={{ height: '3.6rem', outline: 'none', boxShadow: 'none', display: 'inline' }}
                      onClick={() => { fields.remove(index) }}
                    >
                      <Icon icon={IconX} extraClass="icon-secondary" />
                    </button>
                  </div>
                </div>
              )
            })
          }
        </FieldArray>
      </div>

      <div className="col-11 text-right">
        <button
          type="button"
          className="btn btn-secondary primary mb-3 mr-3"
          style={{ border: "none", outline: "none", boxShadow: "none" }}
          onClick={() => addPriceList()}
        >
          <Icon icon={IconPlus} extraClass="icon icon-primary mr-2" />
          {capitalize(I18n.get('addPriceList', 'agregar lista de precio'))}
        </button>
      </div>
    </div>
  )
}

export default PriceLists;

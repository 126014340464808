import { Button } from "@alegradev/smile-ui-react";
import { I18n } from "aws-amplify";
import React from "react";

const Bottom = ({ onChangeNumeration, disabled, submitting }) => {
  return (
    <div className="modal__footer">
      <Button
        emphasis="outline"
        onClick={() => onChangeNumeration()}
        text={I18n.get('', 'Cambiar a factura simplificada')}
        disabled={submitting}
        size="small"
      />
      <Button
        text={I18n.get('', 'Guardar datos y continuar')}
        disabled={disabled}
        loading={submitting}
        size="small"
      />
    </div>
  );
};

export default Bottom;
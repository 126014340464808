import React from 'react';
import { I18n } from '@aws-amplify/core';
import { useSelector } from 'react-redux';
import { get } from 'lodash'

import { subtotal, discount, discSubtotal, taxes, total, itemsCount, currency } from '../../../../selectors/editInvoice';
import { useFormat } from '../../../../hooks/useFormat';

const Summary = () => {
  const selectedCurrency = useSelector(currency)
  const { decimal, fmt } = useFormat(selectedCurrency);

  const refundSubtotal = useSelector(subtotal);
  const refundDiscount = useSelector(discount);
  const refundDiscSubtotal = useSelector(discSubtotal);
  const refundTaxes = useSelector(taxes);
  const refundTotal = useSelector(total);
  const refundItems = useSelector(itemsCount);

  return (
    <div className="refund-bottom__summary d-flex flex-column">
      
      {(refundDiscount.gt(0) || !!refundTaxes.length) && (
        <div className="refund-bottom__summary-row d-flex justify-content-between align-items-center">
          <div className="text-capitalize-first">
            {I18n.get('subtotal', 'subtotal')}
          </div>
          <div className="text-capitalize-first text-muted">
            {refundSubtotal.toFormat(decimal, fmt)}
          </div>
        </div>
      )}

      {refundDiscount.gt(0) && (
        <div className="refund-bottom__summary-row d-flex justify-content-between align-items-center">
          <div className="text-capitalize-first">
            {I18n.get('discount', 'descuento')}
          </div>
          <div className="text-capitalize-first text-muted">
            -{refundDiscount.toFormat(decimal, fmt)}
          </div>
        </div>
      )}
      
      {refundDiscount.gt(0) && (
        <div className="refund-bottom__summary-row d-flex justify-content-between align-items-center">
          <div className="text-capitalize-first">
            {I18n.get('subtotal', 'subtotal')}
          </div>
          <div className="text-capitalize-first text-muted">
            {refundDiscSubtotal.toFormat(decimal, fmt)}
          </div>
        </div>
      )}

      {refundTaxes.map((tax, index) => (
        <div key={index} className="refund-bottom__summary-row d-flex justify-content-between align-items-center">
          <div className="text-capitalize-first">
            {tax.name}
          </div>
          <div className="text-capitalize-first text-muted">
            {tax.value.toFormat(decimal, fmt)}
          </div>
        </div>
      ))}

      {!!refundItems && (
        <div className="refund-bottom__summary-row d-flex justify-content-between align-items-center">
          <div className="text-capitalize-first font-weight-bold">
            {I18n.get('total', 'total')}
          </div>
          <div className="text-capitalize-first text-muted font-weight-bold">
            {refundTotal.toFormat(decimal, fmt)}
          </div>
        </div>
      )}
    
    </div>
  )
}

export default Summary;

import { I18n } from '@aws-amplify/core';
import { capitalize, find } from 'lodash';

import { periodicity } from '../../../../components/forms/globalInvoice/utils';
import { get } from 'lodash';
import { TableCellRegular, Tag } from '@alegradev/smile-ui-react';
import { format } from 'date-fns';
import { formatMoney } from '../../../../utils';

export const globalInvoiceStatusTag = {
  success: {
    title: I18n.get('stamped', 'Timbrada'),
    color: 'green',
  },
  draft: {
    title: I18n.get('draft', 'Borrador'),
    color: 'amber',
  },
  open: {
    title: I18n.get('pendingStamped', 'Por timbrar'),
    color: 'slate',
  },
  cancelled: {
    title: I18n.get('cancelled', 'Cancelada'),
    color: 'rose',
  },
};

function globalInvoicesColumns() {
  return [
    {
      Header: capitalize(I18n.get('', 'Folio')),
      sortable: true,
      accessor: 'numberTemplate.number',
      partiblePosition: 'top-left',
      width: '15%',
      Cell: ({ value }) => <TableCellRegular value={value} />,
    },
    {
      Header: capitalize(I18n.get('', 'Creación')),
      accessor: 'createdAt',
      partiblePosition: 'bottom-left',
      width: '15%',
      Cell: ({ value }) => (
        <TableCellRegular value={format(new Date(value), 'dd/MM/yyyy')} />
      ),
    },
    {
      Header: capitalize(I18n.get('', 'Periodicidad')),
      accessor: 'periodicity.period',
      width: '15%',
      Cell: ({ value }) => (
        <TableCellRegular value={find(periodicity, { value: value })?.label} />
      ),
    },
    {
      Header: capitalize(I18n.get('', 'Mes / Año')),
      accessor: 'date',
      partiblePosition: 'bottom-right',
      width: '15%',
      PartibleComponent: ({ value }) => (
        <TableCellRegular value={format(new Date(value), 'h:mm a')} />
      ),
      Cell: ({ value }) => (
        <TableCellRegular value={format(new Date(value), 'MMMM / yyyy')} />
      ),
    },
    {
      Header: capitalize(I18n.get('', 'Valor total')),
      accessor: 'total',
      alignment: 'right',
      partiblePosition: 'top-right',
      width: '15%',
      Cell: ({ value }) => <TableCellRegular value={formatMoney(value)} />,
    },
    {
      Header: capitalize(I18n.get('', 'Estado')),
      accessor: 'status',
      partiblePosition: 'top-left',
      width: '15%',
      Cell: ({ value, row }) => {
        const isStamped = get(row, 'original.stamp.uuid', null);
        if (isStamped) {
          return (
            <Tag
              label={globalInvoiceStatusTag.success?.title ?? ''}
              type={globalInvoiceStatusTag.success?.color ?? 'slate'}
            />
          );
        }

        return (
          <Tag
            label={globalInvoiceStatusTag[value]?.title ?? ''}
            type={globalInvoiceStatusTag[value]?.color ?? 'slate'}
          />
        );
      },
    },
  ];
}

export default globalInvoicesColumns;

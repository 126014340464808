import React from 'react'
import PropTypes from 'prop-types';

import { BasicData } from '../../../forms/refund/Refund'
import Total from './Total'
import Methods from './Methods'

const Payments = ({
  values, client, form, totalPrice, step, onBack
}) => {

  return (
    <div className="d-flex flex-column p-3 modal__refund-body">
      {step > 0 && <Total step={step} total={totalPrice} onBack={onBack}/>}

      {step === 0 && (
        <Methods form={form} total={totalPrice} values={values}/>
      )}

      {step === 2 && (
        <div className="modal__refunds-basic-data">
          <BasicData values={values} form={form} total={totalPrice} client={client}/>
        </div>
      )}
    </div>
  )
}

Payments.propTypes = {
  values: PropTypes.object,
  client: PropTypes.object,
  form: PropTypes.any,
  totalPrice: PropTypes.any,
  step: PropTypes.number,
  onBack: PropTypes.func
}

export default Payments;
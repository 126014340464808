import React from 'react';
import PropTypes from 'prop-types';
import RefundBodyColombia from './RefundBodyColombia';
import RefundBodyDefault from './RefundDefaultBody';
import { COUNTRIES } from '../../../utils/enums/countries';
import RefundBodySpain from './RefundBodySpain';

/**
 * Factory to select the appropriate Refund Body Strategy based on country.
 *
 * @param {string} country - The country code.
 * @param {Object} props - Props to pass to the strategy component.
 * @returns {JSX.Element} - Rendered Refund Tbody Strategy component.
 */
const RefundBodyFactory = ({ country, ...props }) => {
  const strategies = {
    [COUNTRIES.COLOMBIA]: RefundBodyColombia,
    [COUNTRIES.SPAIN]: RefundBodySpain,
  };

  const SelectedStrategy = strategies[country] || RefundBodyDefault;

  return <SelectedStrategy country={country} {...props} />;
};

RefundBodyFactory.propTypes = {
  country: PropTypes.string.isRequired,
  props: PropTypes.object,
};

export default RefundBodyFactory;

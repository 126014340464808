import React from 'react';
import { I18n } from '@aws-amplify/core';
import { Field } from 'react-final-form';
import { capitalize, get, isNil, isObject } from 'lodash';
import { useSelector } from 'react-redux';

import { hasPermissionTo } from '../../../selectors/auth';
import { isOnlyInvoicingPlan } from '../../../selectors/company';
import { activeBanks } from '../../../selectors/banks';
import { renderSelect } from '../fields/V0/Fields';

const Bank = ({ values }) => {
  const can = useSelector(hasPermissionTo);
  const banks = useSelector(activeBanks);
  const onlyInvoicing = useSelector(isOnlyInvoicingPlan);

  const hasValidIdCash = (values) => !isNil(get(values, 'idCash'));
  const hasValidCashObject = (values) => {
    const cash = get(values, 'cash');
    return !isNil(cash) && isObject(cash) && !isNil(cash.id);
  };

  const existCashBank = hasValidIdCash(values) || hasValidCashObject(values);

  return (
    <div className='form-body__fields form-row justify-content-start'>
      <Field
        name='debit'
        className='col-md-6'
        component={renderSelect}
        disabled={onlyInvoicing}
        onlyInvoicingTooltip={onlyInvoicing}
        label={capitalize(I18n.get('debitCard', 'tarjeta de débito'))}
        options={
          !can('index', 'bank-accounts') || !can('view', 'bank-accounts')
            ? []
            : banks
        }
        selectInfo={
          !can('index', 'bank-accounts') || !can('view', 'bank-accounts')
            ? I18n.get(
                'userNotAllowed.bank-accounts.view',
                'no tienes permisos para ver la información de las cuentas de bancos'
              )
            : null
        }
        hideNoOptionMessage={
          !can('index', 'bank-accounts') || !can('view', 'bank-accounts')
        }
        getOptionLabel={(option) => option.name}
        getOptionValue={(option) => option.id}
        menuPosition='absolute'
        required
      />

      <Field
        name='credit'
        className='col-md-6'
        component={renderSelect}
        disabled={onlyInvoicing}
        onlyInvoicingTooltip={onlyInvoicing}
        label={capitalize(I18n.get('creditCard', 'tarjeta de crédito'))}
        options={
          !can('index', 'bank-accounts') || !can('view', 'bank-accounts')
            ? []
            : banks
        }
        selectInfo={
          !can('index', 'bank-accounts') || !can('view', 'bank-accounts')
            ? I18n.get(
                'userNotAllowed.bank-accounts.view',
                'no tienes permisos para ver la información de las cuentas de bancos'
              )
            : null
        }
        hideNoOptionMessage={
          !can('index', 'bank-accounts') || !can('view', 'bank-accounts')
        }
        getOptionLabel={(option) => option.name}
        getOptionValue={(option) => option.id}
        menuPosition='absolute'
        required
      />

      <Field
        name='transfer'
        className='col-md-6'
        component={renderSelect}
        disabled={onlyInvoicing}
        onlyInvoicingTooltip={onlyInvoicing}
        label={capitalize(I18n.get('transfer', 'transferencia'))}
        options={
          !can('index', 'bank-accounts') || !can('view', 'bank-accounts')
            ? []
            : banks
        }
        selectInfo={
          !can('index', 'bank-accounts') || !can('view', 'bank-accounts')
            ? I18n.get(
                'userNotAllowed.bank-accounts.view',
                'no tienes permisos para ver la información de las cuentas de bancos'
              )
            : null
        }
        hideNoOptionMessage={
          !can('index', 'bank-accounts') || !can('view', 'bank-accounts')
        }
        getOptionLabel={(option) => option.name}
        getOptionValue={(option) => option.id}
        menuPosition='absolute'
        required
      />

      {existCashBank && (
        <Field
          name='cash'
          className='col-md-6'
          component={renderSelect}
          disabled={true}
          onlyInvoicingTooltip={onlyInvoicing}
          label={capitalize(I18n.get('cash', 'efectivo'))}
          options={
            !can('index', 'bank-accounts') || !can('view', 'bank-accounts')
              ? []
              : banks
          }
          helpTooltip={
            <p className='text-capitalize-first text-white'>
              {I18n.get(
                'defaultCashBankTutorial',
                'Esta cuenta de banco se asigna por defecto a cada terminal para que tengas un mejor control del efectivo'
              )}{' '}
            </p>
          }
          selectInfo={
            !can('index', 'bank-accounts') || !can('view', 'bank-accounts')
              ? I18n.get(
                  'userNotAllowed.bank-accounts.view',
                  'no tienes permisos para ver la información de las cuentas de bancos'
                )
              : null
          }
          hideNoOptionMessage={
            !can('index', 'bank-accounts') || !can('view', 'bank-accounts')
          }
          getOptionLabel={(option) => option.name}
          getOptionValue={(option) => option.id}
          menuPosition='absolute'
        />
      )}
    </div>
  );
};

export default Bank;

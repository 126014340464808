import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Form } from 'react-final-form';
import { I18n } from '@aws-amplify/core';
import { graphqlOperation } from '@aws-amplify/api';
import { get } from 'lodash'

import * as mutations from '../../../graphql/mutations';
import { toast } from '../../../utils';
import { formError } from '../../../utils/errors';
import { closeModal } from '../../../reducers/modals';
import { addCurrency } from '../../../reducers/currencies';
import { validate, transform } from '../../forms/currency/utils';
import Modal from '../../common/Modal';
import Notification from '../common/Notification';
import Bottom from '../common/Bottom';
import Body from '../../forms/currency/Currency';
import { replaceAndParse } from '../../../utils/index';
import { APIGraphqlSelector } from '../../../selectors/app';

const Currency = ({ onCreated }) => {
  const dispatch = useDispatch();
  const isOpen = useSelector(state => get(state, 'modals.currency.isOpen', false));
  const APIGraphql = useSelector(APIGraphqlSelector);

  const submit = async values => {
    const transformedValues = transform(values)

    try {
      const response = await APIGraphql(graphqlOperation(mutations.createCurrency, {
        currency: transformedValues
      }))

      dispatch(closeModal({ modal: 'currency' }))

      const newCurrency = get(response, 'data.createCurrency', null)

      if (!!newCurrency) {
        dispatch(addCurrency(newCurrency))
        if (!!onCreated)
          onCreated(newCurrency)
      }

      toast.success({
        title: `${I18n.get('currencyCreatedSuccessfully', 'moneda creada con éxito')} 💪`,
        subtitle: replaceAndParse(I18n.get('currencyCreatedSuccessfullyMessage', 'Ya puedes hacer ventas en {}'), [
          `<span class="font-weight-bold">${get(newCurrency, 'name', '')}</span>`
        ]),
      })
    } catch (error) {
      return formError(error, I18n.get('newCurrencyError', 'hubo un error creando la nueva moneda'))
    }
  }

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={() => dispatch(closeModal({ modal: 'currency' }))}
      title={(
        <div className="d-flex justify-content-between align-items-center w-100">
          <div className="modal__header-title text-capitalize-first h2 text-primary text-truncate">
            {I18n.get('newCurrency', 'nueva moneda')}
          </div>
        </div>
      )}
      className="modal__medium-size"
    >
      <Form
        onSubmit={submit}
        validate={values => validate(values)}
        keepDirtyOnReinitialize
      >
        {({ handleSubmit, submitting, values, submitError, pristine }) => (
          <form noValidate onSubmit={handleSubmit}>

            <div className="px-md-5 py-md-3">
              <Body values={values} />
            </div>

            {(!!submitError && !pristine) && (
              <Notification isOpen={true} text={submitError} type="error" />
            )}

            <Bottom
              submitting={submitting}
              onClose={() => dispatch(closeModal({ modal: 'currency' }))}
            />
          </form>
        )}
      </Form>
    </Modal>
  )
}

export default Currency;
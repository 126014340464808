import React, { useMemo } from 'react';
import { I18n } from '@aws-amplify/core';
import { Field } from 'react-final-form';
import { capitalize } from 'lodash';
import { useSelector } from 'react-redux';

import { activePriceLists } from '../../../selectors/priceLists';
import {
  country as countrySelector,
  isOnlyInvoicingPlan,
  companySelector,
} from '../../../selectors/company';
import {
  getInvoiceNumerations,
  getCashReceiptNumerations,
  getRefundNumerations,
  getSaleTicketNumerations,
  getOnlyInvoiceNumerations,
} from '../../../selectors/numerations';
import {
  isCashReceiptNumerationActive,
  isRefundNumerationActive,
} from '../../../selectors/numerations';
import { activeWarehouses } from '../../../selectors/warehouses';
import { renderField, renderSelect } from '../fields/V0/Fields';
import { hasPermissionTo } from '../../../selectors/auth';
import { COUNTRIES } from '../../../utils/enums/countries';
import useElectronicPayrollStatus from '../../../hooks/useElectronicPayrollStatus/hook';
import { isMexicoFeAvailable } from '../../../utils/deploys/mexicoFeDeployGroups';

const getHelpToolTip = () => {
  return (
    <div>
      <p className='text-white'>
        {I18n.get(
          'priceListsHelpTooltip',
          'Las listas de precio te permiten definir precios especiales para clientes o grupos de productos y servicios. ¡Configúralas desde tu punto de venta!'
        )}
        <a
          href='https://ayuda.alegra.com/es/crea-listas-de-precio-en-el-sistema-punto-de-venta-pos'
          target='_blank'
          rel='noreferrer'
          className='ml-2'
        >
          {I18n.get('priceListsKnowMore', 'Saber más')}
        </a>
      </p>
    </div>
  );
};

const BasicInfo = () => {
  const country = useSelector(countrySelector);
  const company = useSelector(companySelector);
  const onlyInvoicing = useSelector(isOnlyInvoicingPlan);
  const invoiceNumerations = useSelector(getInvoiceNumerations);
  const cashReceiptNumerations = useSelector(getCashReceiptNumerations);
  const creditNotesNumerations = useSelector(getRefundNumerations);
  const warehouses = useSelector(activeWarehouses);
  const cashReceiptActive = useSelector(isCashReceiptNumerationActive);
  const refundActive = useSelector(isRefundNumerationActive);
  const priceLists = useSelector(activePriceLists);
  const can = useSelector(hasPermissionTo);
  const { isActive } = useElectronicPayrollStatus();
  const saleTicketNumerations = useSelector(getSaleTicketNumerations)
  const onlyInvoiceNumerations = useSelector(getOnlyInvoiceNumerations);

  const isFeMexActive = useMemo(() => {
    return isMexicoFeAvailable(country, isActive);
  },[country, company, isActive])

  return (
    <div className='form-body__fields form-row justify-content-start'>
      <Field
        name='name'
        className='col-md-6'
        component={renderField}
        type='text'
        label={capitalize(I18n.get('stationName', 'nombre de tu terminal'))}
        required
      />

      {country !== COUNTRIES.MEXICO && (
        <Field
          name='numberTemplate'
          className='col-md-6'
          component={renderSelect}
          label={capitalize(
            I18n.get('salesNumbering', 'Numeración de ventas')
          )}
          options={invoiceNumerations}
          getOptionLabel={(option) => option.name}
          getOptionValue={(option) => option.id}
          menuPosition='absolute'
          required
        />
      )}

      {country === COUNTRIES.MEXICO && (
        <>
          <Field
            name='numberTemplate'
            className='col-md-6'
            component={renderSelect}
            label={capitalize(
              I18n.get('invoiceNumbering', 'numeración de facturas')
            )}
            options={saleTicketNumerations}
            getOptionLabel={(option) => option.name}
            getOptionValue={(option) => option.id}
            menuPosition='absolute'
            required
          />
          {isFeMexActive && <Field
            name='invoiceNumberTemplate'
            className='col-md-6'
            component={renderSelect}
            label={capitalize(
              I18n.get('onlyInvoiceNumbering', 'Numeración de facturas')
            )}
            options={onlyInvoiceNumerations}
            getOptionLabel={(option) => option.name}
            getOptionValue={(option) => option.id}
            menuPosition='absolute'
          />}
        </>
      )}

      {/* {get(localSettings, 'automationFEEnabled', false) && (
        <Field
          name='feNumberTemplate'
          className='col-md-6'
          component={renderSelect}
          label={capitalize(
            I18n.get('feNumeration', 'Numeración de facturas electrónicas')
          )}
          options={feNumerations}
          getOptionLabel={(option) => option.name}
          getOptionValue={(option) => option.id}
          menuPosition='absolute'
          required
        />
      )} */}

      {cashReceiptActive && (
        <Field
          name='receiptNumberTemplate'
          className='col-md-6'
          component={renderSelect}
          label={capitalize(
            I18n.get('receiptNumbering', 'numeración de recibos de caja')
          )}
          options={cashReceiptNumerations}
          getOptionLabel={(option) => option.name}
          getOptionValue={(option) => option.id}
          menuPosition='absolute'
          required
        />
      )}

      {refundActive && (
        <Field
          name='refundNumberTemplate'
          className='col-md-6'
          component={renderSelect}
          label={capitalize(
            I18n.get(
              'stationRefundNumbering',
              'Numeración para devoluciones de clientes'
            )
          )}
          options={creditNotesNumerations}
          getOptionLabel={(option) => option.name}
          getOptionValue={(option) => option.id}
          menuPosition='absolute'
          required
        />
      )}

      <Field
        name='warehouse'
        className='col-md-6'
        component={renderSelect}
        disabled={onlyInvoicing}
        onlyInvoicingTooltip={onlyInvoicing}
        label={capitalize(I18n.get('warehouse', 'bodega'))}
        options={warehouses}
        getOptionLabel={(option) => option.name}
        getOptionValue={(option) => option.id}
        menuPosition='absolute'
        required
      />

      <Field
        name='priceList'
        className='col-md-6'
        component={renderSelect}
        label={capitalize(I18n.get('priceLists', 'Listas de precios'))}
        options={
          !can('index', 'price-lists') || !can('view', 'price-lists')
            ? []
            : priceLists
        }
        getOptionLabel={(option) => option.name}
        getOptionValue={(option) => option.id}
        helpTooltip={getHelpToolTip()}
        menuPosition='absolute'
      />
    </div>
  );
};

export default BasicInfo;

import React from 'react'
import { I18n } from '@aws-amplify/core';
import { useSelector } from 'react-redux';

import { Methods, NewOtherMethods, OtherMethods } from '../../forms/invoice/Invoice';
import { useTestingCompanies } from '../../../hooks/useShowWompiIntegrationModal/hook';
import { idCompanySelector } from '../../../selectors/company';

const InvoiceMethods = ({ form, total }) => {
  const company = useSelector(idCompanySelector);

  return (
    <div className="px-3">
      <div className="modal__invoice-divider position-relative justify-content-center d-flex">
        <div className="modal__invoice-divider-line">
          <div className="border-bottom" />
        </div>
        <p className="modal__invoice-divider-text text-muted h5 text-center text-uppercase">
          {I18n.get('invoiceMethod', 'método de pago')}
        </p>
      </div>

      <div className="px-3">
        <Methods form={form} total={total} />
      </div>

      <div className="modal__invoice-divider position-relative justify-content-center d-flex">
        <div className="modal__invoice-divider-line">
          <div className="border-bottom" />
        </div>
        <p className="modal__invoice-divider-text text-muted h5 text-center text-uppercase">
          {I18n.get('otherMethods', 'otros métodos')}
        </p>
      </div>

      <div className="px-3">
        {useTestingCompanies().includes(company)
          ? <NewOtherMethods form={form} total={total} />
          : <OtherMethods form={form} total={total} />
        }
      </div>

    </div>
  )
}

export default InvoiceMethods;
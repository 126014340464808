import React from 'react'

import { Icon } from '@alegradev/smile-ui-react';
import { IconLoader2 } from '@tabler/icons-react';

const Loading = ({ text }) => {
  return (
    <div className="plans-container d-flex align-items-center justify-content-center flex-column px-3">
      <div
        className="d-flex justify-content-center align-items-center mb-5"
        style={{
          borderRadius: '100%',
          boxShadow: '1px 2px 5px rgba(0, 0, 0, 0.1)',
          padding: '1.5rem'
        }}
      >
        <Icon icon={IconLoader2} animated extraClass=" icon-primary icon x1-5" />
      </div>

      <p className="text-capitalize-first h4 text-center">
        {text}
      </p>
    </div>
  )
}

export default Loading;
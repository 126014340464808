import React from 'react'

const Logo = ({ companyLogo, setting: { logo, align } }) => {
  if (!companyLogo || !logo) return null;

  return (
    <div className={`w-100 text-${align} py-2`}>
      {companyLogo && (
        <img alt="logo" src={companyLogo}/>
      )}
    </div>
  )
};

export default Logo;
import { I18n } from '@aws-amplify/core';
import { capitalize } from 'lodash'

const regimes = country => { 
    switch(country) { 
      case 'mexico': 
        return ( 
          [ 
            {   id:1, 
                value:"Régimen General de Ley Personas Morales", 
                name:capitalize(I18n.get('generalRegimeOfTheLawForLegalEntities', 'Régimen General de Ley Personas Morales')) 
            }, 
            {   id:2, 
                value:"Régimen actividades empresariales", 
                name:capitalize(I18n.get('businessActivitiesRegime', 'Régimen actividades empresariales')) 
            }, 
            {   id:3, 
                value:"Régimen de Incorporación Fiscal", 
                name:capitalize(I18n.get('fiscalIncorporationRegime', 'Régimen de Incorporación Fiscal')) 
            }, 
            {   id:4, 
                value:"Régimen asalariados", 
                name:capitalize(I18n.get('employeeRegime', 'Régimen asalariados')) 
            }, 
            {   id:5, 
                value:"Régimen honorarios (servicios profesionales)", 
                name:capitalize(I18n.get('feeRegimeProfessionalServices', 'Régimen honorarios (servicios profesionales)')) 
            }, 
            {   id:6, 
                value:"Régimen de arrendamiento", 
                name:capitalize(I18n.get('leasingRegime', 'Régimen de arrendamiento')) 
            }, 
            {   id:7, 
                value:"Régimen de las Personas Morales con Fines No Lucrativos", 
                name:capitalize(I18n.get('regimeOfLegalEntitiesForNonProfitPurposes', 'Régimen de las Personas Morales con Fines No Lucrativos')) 
            }, 
            {   id:8, 
                value:"Régimen de los Coordinados", 
                name:capitalize(I18n.get('regimeOfTheCoordinates', 'Régimen de los Coordinados')) 
            }, 
            {   id:9, 
                value:"Régimen de Actividades Agrícolas, Ganaderas, Silvicolas y Pesqueras", 
                name:capitalize(I18n.get('regimeOfAgriculturalLivestockSilvicultureFishingActivities', 'Régimen de Actividades Agrícolas, Ganaderas, Silvicolas y Pesqueras')) 
            } 
          ] 
        ) 
   
      case 'republicaDominicana': 
        return( 
          [ 
            {   id:10, 
                value:"Régimen general",
                name:capitalize(I18n.get('generalRegime', 'Régimen general')) 
            }, 
            {   id:11, 
                value:"Régimen PST", 
                name:capitalize(I18n.get('pstRegime', 'Régimen PST')) 
            }, 
            {   id:12, 
                value:"Régimen de estimación simple", 
                name:capitalize(I18n.get('simpleEstimationRegime', 'Régimen de estimación simple')) 
            }, 
            {   id:13, 
                value:"Regímenes especiales de tributación", 
                name:capitalize(I18n.get('specialTaxationRegimes', 'Regímenes especiales de tributación')) 
            } 
          ] 
      ) 
   
      case 'peru': 
        return( 
          [ 
            {   id:14, 
                value: 'Régimen general', 
                name: (I18n.get('generalRegime', 'Régimen general')),
                additionalInfo: (I18n.get('generalRegimeAdditionalInfo', 'Negocios sin límite de ingresos')),
            }, 
            {   id:15, 
                value: 'Régimen especial', 
                name: (I18n.get('specialRegime', 'Régimen especial')), 
                additionalInfo: (I18n.get('specialRegimeAdditionalInfo', 'Negocios con un ingreso anual menor a S/525.000')),
            }, 
            {   id:16, 
                value: 'Régimen MYPE tributario', 
                name: (I18n.get('mypeTaxRegime', 'Régimen MYPE tributario')),
                additionalInfo: (I18n.get('mypeTaxRegimeAdditionalInfo', 'Negocios con un ingreso anual menor o igual a 1700 UIT')), 
            }, 
            {   id:17, 
                value: 'Nuevo RUS', 
                name: (I18n.get('newRUS', 'Nuevo RUS')),
                additionalInfo: (I18n.get('newRusAdditionalInfo', 'Negocios no obligados a llevar contabilidad con un ingreso anual hasta de S/s96.000')),
            } 
          ] 
        ) 
   
      case 'chile': 
        return( 
          [ 
              {   id:18, 
                  name: capitalize(I18n.get('presumptiveRent', 'Renta presunta')), 
                  value: 'Renta presunta' 
              }, 
              {   id:19, 
                  name: capitalize(I18n.get('simplifiedTaxation', 'Tributación simplificada')), 
                  value: 'Tributación simplificada' 
              }, 
              {   id:20, 
                  name: capitalize(I18n.get('attributedRent', 'Renta atribuida')), 
                  value: 'Renta atribuida' 
              }, 
              {   id:21, 
                  name: capitalize(I18n.get('semiIntegrated', 'Semi integrado')), 
                  value: 'Semi integrado' 
              } 
          ] 
        ) 
   
      case 'spain': 
        return( 
          [ 
              {   id:22, 
                  name: capitalize(I18n.get('generalRegime', 'Régimen general')), 
                  value: 'Régimen general' 
              }, 
              {   id:23, 
                  name: capitalize(I18n.get('simplifiedRegime', 'Régimen simplificado')), 
                  value: 'Régimen simplificado' 
              } 
          ] 
        ) 
   
      case 'costaRica': 
        return( 
          [ 
            {   id:24, 
                name: capitalize(I18n.get('traditionalRegime', 'Régimen tradicional')), 
                value: 'Régimen tradicional' 
            }, 
            {   id:25, 
                name: capitalize(I18n.get('simplifiedRegime', 'Régimen simplificado')), 
                value: 'Régimen simplificado' 
            }, 
            {   id:26, 
                name: capitalize(I18n.get('physicalPerson', 'Persona física')), 
                value: 'Persona física' 
            } 
          ] 
        ) 
   
      case 'colombia': 
        return( 
          [ 
            { 
              id:27, 
              name: I18n.get('responsibleForVATCommonRegime', 'Responsable del IVA (Régimen común)'),
              otherName: I18n.get('responsibleForVAT', 'Responsable del IVA'), 
              value:'Régimen común' 
            }, 
            { 
              id: 28, 
              name: I18n.get('notResponsibleForVATSimplifiedRegime', 'No responsable de IVA (Régimen simplificado)'), 
              otherName: I18n.get('notResponsibleForVAT', 'No responsable de IVA'),
              value: 'Régimen simplificado' 
            }, 
            { 
              id:29, 
              name: I18n.get('simpleTaxationRegime', 'Régimen Simple de Tributación'), 
              value: 'Régimen simple de tributación' 
            }, 
            { 
              id:30, 
              name: I18n.get('notResponsibleForConsumptionSimplifiedRegimeOfTheINC', 'No responsable de consumo (Régimen simplificado del INC)'), 
              otherName: capitalize(I18n.get('notResponsibleForConsumption', 'No responsable de consumo')),
              value: 'Régimen simplificado impuesto nacional al consumo' 
            }, 
            { 
              id:31, 
              name: capitalize(I18n.get('specialRegime', 'Régimen especial')), 
              value: 'Régimen especial' 
            } 
          ] 
        ) 
   
      default: 
        return(null); 
    } 
  } 
   
  export default regimes;
import { get } from 'lodash';

export const panamaTransformer = (values, _props, newValues) => {
  const identificationType = get(values, 'identification.type', null);
  const identificationNumber = get(values, 'identification.passport', null);
  const identificationRuc = get(values, 'identification.number', null);
  const identificationDv = get(values, 'identification.dv', null);
  const kindOfPerson = get(values, 'identification.kindOfPerson', null);
  const address = get(values, 'address.address', null);
  const combinedAddress = get(values, 'address.combined', null);
  const splitAddress = combinedAddress?.split(', ');
  const country = get(values, 'address.country', null);
  const phone = get(values, 'phonePrimary', null);
  const mobile = get(values, 'mobile', '');

  let valueNames = {};

  if (kindOfPerson === 'PERSON_ENTITY') {
    valueNames = {
      nameObject: {
        firstName: get(values, 'firstName', null),
        secondName: get(values, 'secondName', null),
        lastName: get(values, 'lastName', null),
        secondLastName: get(values, 'secondLastName', null),
      },
    };
  } else {
    valueNames = {
      name: get(values, 'firstName', null),
    };
  }

  return {
    ...newValues,
    ...valueNames,
    identification:
      identificationType === 'FOREIGN' ||
      identificationType === 'FOREIGN_COMPANY'
        ? identificationNumber
        : identificationRuc,
    identificationObject: {
      kindOfPerson,
      number:
        identificationType === 'FOREIGN' ||
        identificationType === 'FOREIGN_COMPANY'
          ? identificationNumber
          : identificationRuc,
      dv: identificationDv,
    },
    address: {
      province: (combinedAddress && splitAddress[2]) || null,
      district: (combinedAddress && splitAddress[1]) || null,
      township: (combinedAddress && splitAddress[0]) || null,
      address,
      country,
    },
    phonePrimary: phone,
    mobile,
    kindOfPerson,
    thirdsType: identificationType,
    creditLimit: get(values, 'creditLimit', null),
  };
};

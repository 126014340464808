import { useMemo } from "react";
import { useSelector } from "react-redux";
import { get } from "lodash";

import { getMainCurrency } from "../../selectors/currencies";
import { decimalPrecision, decimalSeparator as decimalSeparatorSelector } from "../../selectors/company";

const useFormat = (selectedCurrency = false) => {
  const decimalSeparator = useSelector(decimalSeparatorSelector);
  const mainCurrency = useSelector(getMainCurrency);
  const decimal = useSelector(decimalPrecision);

  const prefix = !!get(selectedCurrency, 'symbol') ? get(selectedCurrency, 'symbol')
    : !!get(mainCurrency, 'symbol') ? get(mainCurrency, 'symbol') : ''

  const fmt = useMemo(
    () => ({
      prefix,
      decimalSeparator,
      groupSeparator: decimalSeparator === "," ? "." : ",",
      groupSize: 3,
    }),
    [prefix, decimalSeparator]
  );

  return {
    fmt,
    decimal,
  };
};

export default useFormat;
import { Icon, Typography } from '@alegradev/smile-ui-react';
import Modal from '../../common/Modal';
import BottomInvoiceSaved from './BottomInvoiceSaved';
import { I18n } from 'aws-amplify';
import BigNumber from 'bignumber.js';
import { IconAlertCircle, IconFileReport } from '@tabler/icons-react';

const InvoiceOfflineSaved = ({
  isOpen,
  isOnline,
  handleClose,
  invoiceCreated,
  decimal,
  fmt,
}) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={handleClose}
      includeHeader={false}
      className='modal__small-size modal__mobile-bottom modal__invoice-saved-offline'
    >
      <div className='modal__invoice-saved-offline-body'>
        <Icon icon={IconFileReport} extraClass='icon-secondary' size='huge' />
        {!!invoiceCreated && (
          <Typography
            type='heading-4'
            variant='secondary'
            text={I18n.get(
              'offline_success_invoice_total',
              'Total {{total}}'
            ).replace(
              '{{total}}',
              new BigNumber(invoiceCreated.total).toFormat(decimal, fmt)
            )}
          />
        )}
        <div className='d-flex align-items-center gap-2'>
          <Icon icon={IconAlertCircle} extraClass='icon-amber' />
          <Typography
            type='body-3-bold'
            variant='primary'
            text={
              !isOnline
                ? I18n.get(
                    'offline_success_invoice_title',
                    'Se sincronizará cuando tengas conexión'
                  )
                : I18n.get(
                    'api_intermittences_invoice_title',
                    'Se sincronizará cuando estabilicemos el servicio'
                  )
            }
          />
        </div>
        <Typography
          type='body-3-regular'
          variant='secondary'
          text={
            !isOnline
              ? I18n.get(
                  'offline_success_invoice_description',
                  'Puedes seguir vendiendo y sincronizaremos tus ventas cuando recuperes la conexión.'
                )
              : I18n.get(
                  'api_intermittences_invoice_description',
                  'La factura se creó y quedó pendiente de sincronizarse, la guardaremos por ti cuando finalice la intermitencia. Agradecemos tu comprensión. '
                )
          }
          withHtml
        />
      </div>

      <BottomInvoiceSaved handleClose={handleClose} />
    </Modal>
  );
};

export default InvoiceOfflineSaved;

import React from 'react';
import { useSelector } from 'react-redux';

import renderLogo from './NewLogo';
import { country as countrySelector } from '../../../../selectors/company';
import SpainCompanySettings from '../../../countriesData/spain/CompanySettings';
import ColombiaCompanySettings from '../../../countriesData/colombia/CompanySettings';

const NewBasicData = (props) => {
  const country = useSelector(countrySelector);

  return (
    <div className="form-body__fields form-row justify-content-start m-0">
      {renderLogo(props)}

      <div className="border-top col-12" style={{ marginBottom: "32px", marginTop: "32px" }} />

      {country === 'spain' && (
        <SpainCompanySettings {...props} />
      )}

      {country === 'colombia' && (
        <ColombiaCompanySettings {...props} />
      )}
    </div>
  )
}

export default NewBasicData;

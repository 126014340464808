import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { graphqlOperation } from 'aws-amplify'

import { closeModal, openModal } from "../../../reducers/modals";
import Modal from "../../common/Modal";
import Bottom from "./Bottom";
import PaymentDeclined from '../../../assets/images/payment_declined.png';
import Typography from "../../common/Typography";
import { notificationsSelector } from "../../../selectors/company";
import { APIGraphqlSelector } from "../../../selectors/app";
import * as mutations from '../../../graphql/mutations';
import { fetchUser } from "../../../reducers/auth";

const MembershipPaymentDeclined = () => {
  const dispatch = useDispatch();
  const notifications = useSelector(notificationsSelector);
  const APIGraphql = useSelector(APIGraphqlSelector);
  const { isOpen = false, params = {} } = useSelector(state => state.modals.membershipPaymentDeclined ?? {});

  useEffect(() => {
    const notification = notifications?.find(n => n['declined-payment']);
    if (notification) {
      dispatch(openModal({
        modal: 'membershipPaymentDeclined',
        params: {
          title: notification?.title,
          text: notification?.text,
          action: notification?.action,
          actionUrl: notification?.actionUrl,
          last: notification?.last
        }
      }));
    }
  }, [notifications, dispatch]);

  const { title = '', text = '', action = '', actionUrl = '', last = false } = params ?? {};

  const textParts = text?.split("\n\n") ?? [];

  const deleteUserNotification = async () => {
    try {
      await APIGraphql(graphqlOperation(mutations.deleteUserNotification, {
        notification: 'declined-payment'
      }));

      await dispatch(fetchUser());

    } catch (e) {
      console.error(e);
    }
  }

  const handleClose = useCallback(() => {
    if (last) {
      return null;
    };
    deleteUserNotification();
    dispatch(closeModal({ modal: 'membershipPaymentDeclined' }));
  }, [dispatch]);

  return (
    <Modal
      isOpen={isOpen}
      className="modal__payment-declined"
      headerImg={PaymentDeclined}
      borderlessHeader
      hideClose
    >
      <Typography
        type='heading-4'
        variant='primary'
        text={title}
      />
      {textParts.map((part, index) => (
        <React.Fragment key={index}>
          {index === 1 ? (
            <a
              href="https://share.hsforms.com/1IuHm6VhsRniWi0MxJ0dEgA3ogwa"
              target="_blank"
              rel="noreferrer"
              className="ml-1"
            >
              <Typography
                type='body-3-underline'
                variant='primary'
                text={<b>{part}</b>}
              />
            </a>
          ) : (
            <Typography
              type='body-3-regular'
              variant='primary'
              text={part}
            />
          )}
        </React.Fragment>
      ))}
      <Bottom
        isLast={last}
        onConfirm={useCallback(() => {
          window.open(actionUrl || `${process.env.REACT_APP_ALEGRA}payment-method/add`, '_blank');
        }, [actionUrl])}
        onRequestClose={handleClose}
        actionMessage={action}
      />
    </Modal>
  );
};

export default MembershipPaymentDeclined;

import { I18n } from 'aws-amplify';
import { get, capitalize } from 'lodash';

export const argentinaValidation = (values, { isElectronic }) => {
  let errors = {};

  const identificationType = get(values, 'identification.type', '');
  const identificationNumber = get(values, 'identification.number', '');
  const ivaCondition = get(values, 'ivaCondition', '');

  if (!identificationType)
    errors.identification = {
      ...errors.identification,
      type: capitalize(
        I18n.get(
          'selectTheIdentificationType',
          'seleccione el tipo de identificación'
        )
      ),
    };

  if (
    identificationType === 'CUIT' &&
    !/^[0-9]+[0-9\\-]+[0-9]+$/.test(identificationNumber)
  )
    errors.identification = {
      ...errors.identification,
      number: capitalize(
        I18n.get(
          'CUITIdentificationIsANumericalValue',
          'El CUIT debe ser un valor numérico, puede tener guiones'
        )
      ),
    };

  if (!identificationNumber)
    errors.identification = {
      ...errors.identification,
      number: capitalize(I18n.get('enterAnID', 'Ingresa una identificación')),
    };

  if (!ivaCondition)
    errors.ivaCondition = capitalize(
      I18n.get('thisFieldIsRequired', 'Este campo es obligatorio')
    );

  if (isElectronic && !['CI', 'OTHER'].includes(identificationType)) {
    const province = get(values, 'address.province', '');
    const city = get(values, 'address.city', '');
    const address = get(values, 'address.address', '');

    if (!province)
      errors.address = {
        ...errors.address,
        province: capitalize(
          I18n.get('thisFieldIsRequired', 'Este campo es obligatorio')
        ),
      };
    if (!city)
      errors.address = {
        ...errors.address,
        city: capitalize(
          I18n.get('thisFieldIsRequired', 'Este campo es obligatorio')
        ),
      };
    if (!address)
      errors.address = {
        ...errors.address,
        address: capitalize(
          I18n.get('thisFieldIsRequired', 'Este campo es obligatorio')
        ),
      };
  }

  return errors;
};

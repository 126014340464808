import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { Form } from 'react-final-form';
import { get } from 'lodash';
import { I18n } from '@aws-amplify/core';
import { graphqlOperation } from '@aws-amplify/api';

import * as mutations from '../../../graphql/mutations'
import { closeSideModal } from '../../../reducers/sideModals';
import { setUser } from '../../../reducers/auth';
import {
  country as countrySelector,
  electronicInvoicing,
  companySelector,
  localSettings,
  isOnlyInvoicingPlan,
} from '../../../selectors/company';
import { getCategoryById, defaultInCategory, defaultOutCategory } from '../../../selectors/categories';
import { getCompanyRegime } from '../../../selectors/regimes';
import { hasPermissionTo } from '../../../selectors/auth';
import { toast } from '../../../utils';
import { formError } from '../../../utils/errors';
import { companyTransformer } from '../../../utils/transformers';
import { validate, transform } from '../../forms/company/utils';
import SideModal from '../../common/SideModal';
import CloseWarning from '../modals/closeWarning/CloseWarning';
import Header from '../common/Header';
import Notification from '../common/Notification';
import Bottom from '../common/Bottom';
import Body from './FormBody';
import initialValues from '../../forms/company/BasicData/initialValues';
import { APIGraphqlSelector } from '../../../selectors/app';

const initialModalState = {
  closeWarning: false,
}

const Company = () => {
  const [modals, setModals] = useState(initialModalState)
  const can = useSelector(hasPermissionTo)

  const dispatch = useDispatch();
  const isOpen = useSelector(state => get(state, 'sideModals.company.isOpen', false));
  const country = useSelector(countrySelector);
  const isElectronic = useSelector(electronicInvoicing);
  const company = useSelector(companySelector)
  const companyRegime = useSelector(getCompanyRegime)
  const posSettings = useSelector(localSettings)
  const onlyInvoicing = useSelector(isOnlyInvoicingPlan)

  const companyIncome = useSelector(getCategoryById(posSettings.idCategoryDefaultIncome))
  const defaultIncome = useSelector(defaultInCategory)
  const companyExpense = useSelector(getCategoryById(posSettings.idCategoryDefaultExpense))
  const defaultExpense = useSelector(defaultOutCategory)
  const APIGraphql = useSelector(APIGraphqlSelector);

  useEffect(() => {
    setModals(initialModalState)
  }, [isOpen])

  const submit = async values => {
    const transformedValues = transform(values, { country, isElectronic })
    try {
      const response = await APIGraphql(graphqlOperation(mutations.updateUser, {
        user: {
          company: companyTransformer(transformedValues)
        }
      }))
      if (!!get(response, 'data.updateUser.id', null))
        dispatch(setUser(get(response, 'data.updateUser')))

      toast.success({ title: I18n.get('companyUpdatedSuccessfully', 'compañía configurada con éxito') })
      dispatch(closeSideModal({ sideModal: 'company' }))
    } catch (error) {
      return formError(error, I18n.get('updateCompanyError', 'hubo un error a la hora de modificar tu compañía'))
    }
  }

  const openModal = modal => setModals({
    ...modals,
    [modal]: true
  });

  const closeModal = modal => setModals({
    ...modals,
    [modal]: false
  });

  return (
    <SideModal
      open={isOpen}
      destroyOnClose
    >
      <Form
        onSubmit={submit}
        validate={(values) => validate(values, { country, isElectronic })}
        initialValues={{
          ...initialValues({ country, company, isElectronic }),
          ...posSettings,
          idCategoryDefaultIncome: !onlyInvoicing
            ? !!companyIncome ? companyIncome : defaultIncome
            : defaultIncome,
          idCategoryDefaultExpense: !onlyInvoicing
            ? !!companyExpense ? companyExpense : defaultExpense
            : defaultExpense,
          shiftsEnabled: !onlyInvoicing ? posSettings.shiftsEnabled : false,
          regime: companyRegime,
        }}
        keepDirtyOnReinitialize
      >
        {({ handleSubmit, values, form, submitting, submitError, pristine }) => (
          <>
            <form
              noValidate
              className="w-100 h-100 bg-white d-flex flex-column position-relative"
              onSubmit={handleSubmit}
            >
              <Header
                title={I18n.get('myCompany', 'mi empresa')}
                onClose={() => pristine
                  ? dispatch(closeSideModal({ sideModal: 'company' }))
                  : openModal('closeWarning')
                }
              />

              <div className="side-modal__body">
                <Body
                  values={values}
                  form={form}
                  country={country}
                />
              </div>

              <Notification isOpen={!!submitError} text={submitError} />
              <Notification
                isOpen={!can('edit', 'company')}
                type="warning"
                text={I18n.get('userNotAllowed.company.edit', 'no tienes permisos suficientes para editar la información de la compañía')}
              />

              <Bottom
                onClose={() => pristine
                  ? dispatch(closeSideModal({ sideModal: 'company' }))
                  : openModal('closeWarning')
                }
                disabled={submitting || !can('edit', 'company')}
                submitting={submitting}
              />

              <CloseWarning
                isOpen={!!modals.closeWarning}
                onClose={() => closeModal('closeWarning')}
                onConfirm={() => dispatch(closeSideModal({ sideModal: 'company' }))}
              />
            </form>
          </>
        )}
      </Form>
    </SideModal >
  )
}

export default Company;
const identificationTypes = [
  {
    id: "6",
    value: "Registro único de contribuyente",
    abbreviature: "RUC",
    key: "RUC",
    rules: {
      type: "int",
      maxLength: 11,
      minLength: 11
    }
  },
  {
    id: "1",
    value: "Documento nacional de identidad",
    abbreviature: "DNI",
    key: "DNI",
    rules: {
      type: "int",
      maxLength: 8
    }
  },
  {
    id: "7",
    value: "Pasaporte",
    abbreviature: "PP",
    key: "PP",
    rules: {
      maxLength: 12
    }
  },
  {
    id: "4",
    value: "Carnet de extranjería",
    abbreviature: "CE",
    key: "CE",
    rules: {
      maxLength: 12
    }
  },
  {
    id: "0",
    value: "No domiciliado sin RUC",
    abbreviature: "SIN RUC",
    key: "NOT_DOMICILIED_WITHOUT_RUC",
    rules: {
      maxLength: 15
    }
  },
  {
    id: "A",
    value: "Cédula diplomática de identidad",
    abbreviature: "CDI",
    key: "CDI",
    rules: {
      maxLength: 15
    }
  },
  {
    id: "B",
    value: "Documento de identidad país residencia - No domiciliado",
    abbreviature: "DIP",
    key: "DIP",
    rules: {
      maxLength: 15
    }
  },
  {
    id: "C",
    value: "Tax Identification Number - Doc Trib PP.NN",
    abbreviature: "TIN",
    key: "TIN",
    rules: {
      maxLength: 15
    }
  },
  {
    id: "D",
    value: "Identification Number - Doc Trib PP.JJ",
    abbreviature: "IN",
    key: "IN",
    rules: {
      maxLength: 15
    }
  },
  {
    id: "F",
    value: "Permiso Temporal de permanencia",
    abbreviature: "PTP",
    key: "PTP",
    rules: {
      maxLength: 15
    }
  },
  {
    id: "G",
    value: "Salvoconducto",
    abbreviature: "SC",
    key: "SC",
    rules: {
      maxLength: 15
    }
  },
]

export default identificationTypes
import React from 'react'
import { useSelector } from 'react-redux'
import { get } from 'lodash'
import dayjs from 'dayjs'

import { country as countrySelector, printSettings } from '../../../selectors/company'
import { countriesFirstGroupNewDesign } from '../../navigation/Header'

import Shift from './ShiftDetail'
import NewShift from './NewShiftDetail'

const Print = ({ shift }) => {
  const country = useSelector(countrySelector);
  const format = useSelector(printSettings).format;

  return (
    <div
      id="print-shift"
      className={`flex-column overflow-hidden p-3 d-none print-${format}`}
    >
      {(dayjs(get(shift, 'start')).isAfter('2023-04-20') && countriesFirstGroupNewDesign.includes(country)) ||
        (dayjs(get(shift, 'start')).isAfter('2023-05-30') && !countriesFirstGroupNewDesign.includes(country))
        ? <NewShift shift={shift} format={format} />
        : <Shift shift={shift} />}
    </div>
  )
}

export default Print;
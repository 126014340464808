import React from 'react'
import { I18n } from '@aws-amplify/core';
import { get } from 'lodash';

const getRefundType = (refund) => {
  const documentType = get(refund, 'numberTemplate.documentType', null);
  
  if (documentType === 'incomeAdjustmentNote') {
    return I18n.get('printedRepresentationOfElectronicAdjustNote', 'Representación impresa de la nota de ajuste del documento equivalente electrónico');
  } else {
    return I18n.get('printedRepresentationOfElectronicCreditNote', 'Representación impresa de la nota crédito electrónica')
  }
}

const RefundType = ({isElectronic, refund}) => {
  return (
    <>
      { isElectronic && 
        <p className='text-center mt-4'>{getRefundType(refund)}</p>
      }
    </>
  )
};

export default RefundType;
import PropTypes from "prop-types";
import Typography from "../Typography";

/**
 * A reusable Tag component that provides consistent text styling throughout the application.
 *
 * @typedef {'primary' | 'slate' | 'rose' | 'amber' | 'green' | 'indigo'} TagVariant
 *
 * @param {object} props - The properties of the Tag component.
 * @param {string} props.text - The text content to be styled.
 * @param {TagVariant} props.variant - The style variant for the tag.
 *
 * @returns {JSX.Element} A React element representing the styled tag.
 */
function Tag({ text, variant = 'primary' }) {
  return (
    <div className={`tag-container tag-${variant}`}>
      <Typography text={text} type="label-3" />
    </div>
  );
}

Tag.propTypes = {
  text: PropTypes.string,
  variant: PropTypes.oneOf([
    "primary",
    "slate",
    "rose",
    "amber",
    "green",
    "indigo",
  ]),
};

export default Tag;

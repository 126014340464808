import React from 'react';

import NewEditItem from './editItem/NewEditItem';
import Item from './item/Item';
import InvoiceSettings from './invoiceSettings/InvoiceSettings';
import Company from './company/Company';
import Tip from './tip/Tip';
import ContactSideModal from '../../pages/contacts/sideModal/ContactSideModal';

const SideModals = () => {
  return (
    <>
      <ContactSideModal />
      <Item />
      <InvoiceSettings />
      <Company />
      <NewEditItem />
      <Tip />
    </>
  );
};

export default SideModals;

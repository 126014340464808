import { useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';

import globalInvoicesColumns from './columns';
import globalInvoicesEmptyState from './empty';
import globalInvoicesActions from './actions';
import GlobalInvoiceDetail from '../../detail';
import { useGlobalInvoicesQuery } from '../services/queries';
import { useDispatch, useSelector } from 'react-redux';
import { APIGraphqlSelector } from '../../../../selectors/app';
import { get } from 'lodash';
import { deleteGlobalInvoiceMutation } from '../services/mutations';
import { createGlobalInvoiceMutation } from '../../invoice/services/mutations';
import { getInvoiceNumerations } from '../../../../selectors/numerations';
import { Table } from '@alegradev/smile-ui-react';

function GlobalInvoicesTable() {
  const columns = useMemo(() => globalInvoicesColumns(), []);
  const { mutate } = deleteGlobalInvoiceMutation();
  const { mutate: mutateCreation } = createGlobalInvoiceMutation();
  const numerations = useSelector(getInvoiceNumerations);
  const history = useHistory();
  const [metadata, setMetadata] = useState({});
  const dispatch = useDispatch();
  const APIGraphql = useSelector(APIGraphqlSelector);
  const { isLoading, data, isFetching, refetch } = useGlobalInvoicesQuery(
    metadata,
    APIGraphql
  );
  const items = get(data, 'data', []);
  const totalItems = get(data, 'metadata.total', 0);

  return (
    <Table
      columns={columns}
      data={items}
      totalItems={totalItems}
      partibleComponent={(value) => (
        <GlobalInvoiceDetail id={value?.id} noBreadcrump />
      )}
      order={{
        order_field: 'numberTemplate.number',
        order_direction: 'DESC',
      }}
      onChange={(tableState) => setMetadata(tableState)}
      emptyComponent={globalInvoicesEmptyState({ history })}
      actions={(row) =>
        globalInvoicesActions({
          row,
          refetch,
          history,
          APIGraphql,
          dispatch,
          mutate,
          mutateCreation,
          numerations,
        })
      }
      loading={isLoading || isFetching}
      showSecondAction
      onRowClick={(row) =>
        history.push(`/global-invoices/invoice/${row.id}/detail`)
      }
      minimal
      partible
    />
  );
}

export default GlobalInvoicesTable;

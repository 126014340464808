import en from './en'

const dictionary = {
  ...en,
  "emissionStatus": "DIAN status",
  'invoicePrintFooter': 'Alegra S.A.S - solutions NIT 900.559.088-2 - alegra.com/pos',
  "numerationExpired.body": "Choose another numbering or request a new one from the DIAN to continue invoicing.",
  "numerationExpired.button": "How to request another",
  "numerationMaxNumberReached.body": "Choose another numbering or request a new one from the DIAN to continue invoicing.",
  "numerationMaxNumberReached.button": "How to request another",
  "numerationsSettingTooltip": "Manage the numbering used for invoicing in your business from “Alegra Accounting”.",
  "saleTicket": "document",
  "taxesSettingTooltip": "Configure the taxes you need for invoicing at your point of sale “Alegra Accounting”.",
  "youHavePendingInvoicesToEmit": "You have sales documents to stamp! 🚨",
  "youHavePendingInvoicesToEmit.description": "Learn how to identify the electronic documents you have “To stamp” and send them to the DIAN with a click.",
  "networkErrorMessage": "Try to export your daily report once you're back online.",
  "youCanNowMakeElectronicInvoices.description": "Enable yourself at DIAN in less than 10 minutes and start creating electronic invoices at your POS.",
  "AlegraAccounting.new": "Accounting",
  "importProducts.text": "Import products",
  "importProducts.tooltip": "We will take you to Alegra Accounting to upload new products.",
  "solutionModal.accounting.supTitle": "Clear accounts with",
  "solutionModal.accounting.buttonMainText": "Try 15 days free",
  "solutionModal.accounting.buttonSecondaryText": "More information",
  "solutionModal.accounting.description": "With Alegra Accounting you control your income and expenses with a click, and you get smart reports so that you are always up to date with the DIAN.",
  "solutionModal.accounting.subTitle": "*After 15 days, you can choose a plan from {{price}} {{currency}} per month.",
  "solutionModal.store.supTitle": "Increase your sales with",
  "solutionModal.store.buttonMainText": "Try 15 days free",
  "solutionModal.store.buttonSecondaryText": "More information",
  "solutionModal.store.description": "Start selling online in seconds! With Alegra Store, you can create your first online store in just 4 clicks.",
  "solutionModal.store.subTitle": "*After 15 days, you can choose a plan from {{price}} {{currency}} per month.",
  "solutionModal.payroll.supTitle": "Your team is up to date with",
  "solutionModal.payroll.buttonMainText": "Try 15 days free",
  "solutionModal.payroll.buttonSecondaryText": "More information",
  "solutionModal.payroll.description": "With Alegra Payroll, you solve your electronic payroll in 10 minutes and issue your payment receipts to the DIAN in seconds.",
  "solutionModal.payroll.subTitle": "*After 15 days, you can choose a plan from {{price}} {{currency}} per month.",
  "solutionModal.pos.supTitle": "Manage your business with",
  "solutionModal.pos.buttonMainText": "Try 15 days free",
  "solutionModal.pos.buttonSecondaryText": "More information",
  "solutionModal.pos.description": "You can invoice in seconds using the fastest and most intuitive POS system. In addition, you can generate your sales and inventory reports in seconds.⚡",
  "solutionModal.pos.subTitle": "*After 15 days, you can choose a plan from {{price}} {{currency}} per month.",
  "userNotAllowed.edit.saleTicket": "You need the administrator to give you permission to edit POS documents.",
  "userNotAllowed.edit.invoice": "You need the administrator to give you permission to edit invoices.",
  "numerationElectronicOffline": "To create electronic invoices you need an internet connection to send them to the DIAN.",
  "autocompleteTestTitle": "Try autocomplete ✨",
  "autocompleteTestDescription": "Enter your client's identification number to obtain their information from the DIAN."
}

export default dictionary;
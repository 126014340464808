import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { graphqlOperation } from '@aws-amplify/api';
import { get } from 'lodash';
import { BigNumber } from 'bignumber.js'

import * as queries from '../graphql/queries'
import { userSelector } from '../selectors/auth'
import { handleError } from '../utils/errors'
import alegraAPI from '../reducers/alegraAPI';

import { getEconomicActivities } from './economicActyivity';
import { APIGraphqlSelector } from '../selectors/app';
import { getInitialState } from '../utils';

const initialState = {
}

export const fetchUser = createAsyncThunk(
  'auth/fetch',
  async (_, { rejectWithValue, dispatch, fulfillWithValue, getState, extra }) => {
    const maxAttempts = get(extra, 'maxAttempts', 2);
    const APIGraphql = APIGraphqlSelector(getState());

    try {
      const data = await APIGraphql(graphqlOperation(queries.getUser))
      dispatch(getEconomicActivities(get(data, 'data.getUser.company.economicActivities', null)))
      let user = get(data, 'data.getUser', null);
      try {
        let sales = 0, clients = 0, items = 0;
        items = (await alegraAPI.get('/items', { metadata: true, limit: 0 })).data.metadata.total
        clients = (await alegraAPI.get('/contacts', { metadata: true, type: 'client', limit: 0 })).data.metadata.total
        sales = (await alegraAPI.get('/invoices', { metadata: true, start: 0, limit: 1 })).data.metadata.total

        user.consommation = {
          items,
          clients,
          sales
        }

      } catch (error) {
        console.log(error);
      }

      return user;
    } catch (error) {
      let result;
      if (get(error, 'errors.0.errorType', '') === "Runtime.ExitError" && maxAttempts > 0) {
        result = await dispatch(fetchUser({ ...extra, maxAttempts: maxAttempts - 1  }));
      }
      if (!!get(result, 'payload'))
        return fulfillWithValue(result.payload);

      return rejectWithValue(handleError(error));
    }
  }
)

const setGTMDimensions = user => {
  try {
    window.dataLayer.push({
      dimension1: get(user, 'company.registryDate', null),
      dimension2: get(user, 'company.applicationVersion', null),
      dimension3: get(user, 'company.origin', null),
      dimension4: get(user, 'membership.planName', null),
      dimension5: get(user, 'company.profile', null),
      dimension6: get(user, 'company.firstPOSInvoice', null),
    })
  } catch {
    // 
  }
}

const appSlice = createSlice({
  name: 'auth',
  initialState: getInitialState('auth', initialState),
  reducers: {
    setUser: (_state, action) => {
      BigNumber.config({
        FORMAT: {
          prefix: get(action, 'payload.company.currency.symbol'),
          decimalSeparator: '.',
          groupSeparator: ',',
          groupSize: 3,
        }
      })

      setGTMDimensions(action.payload)

      return {
        ...action.payload,
        permissions: !!get(action, 'payload.permissions')
          ? JSON.parse(get(action, 'payload.permissions')) : null
      }
    },
    setUserWithoutFetch: (_state, action) => action.payload,
  },
  extraReducers: (builder) => {
    builder.addCase(fetchUser.fulfilled, (_state, action) => {
      BigNumber.config({
        FORMAT: {
          prefix: get(action, 'payload.company.currency.symbol'),
          decimalSeparator: '.',
          groupSeparator: ',',
          groupSize: 3,
        }
      })

      setGTMDimensions(action.payload)

      return {
        ...action.payload,
        permissions: !!get(action, 'payload.permissions')
          ? JSON.parse(get(action, 'payload.permissions')) : null
      }
    })
  }
});

export const updateCompany = (companyValues) => {
  return (dispatch, getState) => {
    const auth = userSelector(getState())

    if (!!auth) {
      dispatch(setUserWithoutFetch({
        ...auth,
        company: {
          ...get(auth, 'company', null),
          ...companyValues
        }
      }))
    }
  }
}

const { actions, reducer } = appSlice;

export const { setUser, setUserWithoutFetch, updateTipsSettings } = actions

export default reducer;
import React from 'react';
import { useSelector } from 'react-redux';
import { I18n } from '@aws-amplify/core';
import { capitalize } from 'lodash';

import { error as errorSelector } from '../../selectors/app';

function NoStationsError() {
  const error = useSelector(errorSelector);
  const isOfflineData = error === 'Offline data';

  return (
    <div className="app d-flex justify-content-center align-items-center">
      <div className="card app-error rounded p-5">
        <div className="app-error-img-wrapper">
          <img alt="error" src="/error.png" />
        </div>
        <h2 className="mb-4 text-capitalize-first">
          {capitalize(I18n.get('noEligibleStation', 'no hay terminales elegibles'))}
        </h2>

        <p className="text-capitalize-first text-left">
          {!isOfflineData
            ? I18n.get('initAppErrorMessage', 'Por favor intenta alguna de las siguientes acciones:')
            : I18n.get('initAppOfflineDataErrorMessage', 'Hay datos que no han sido sincronizados pertenecientes a otra terminal. Si continúa podría perderlos')
          }
        </p>

        <ul>
          <li>
            {capitalize(I18n.get('noEligibleStationMessage', 'verifica que tenga al menos una terminal activa en la cual puede operar'))}
          </li>
          <li>{I18n.get('makeSureYouHaveTheNecessaryPermissions', 'Asegurate de tener los permisos necesarios para ingresar al POS')}</li>
          <li>
            <a className="btn-link" href={process.env.REACT_APP_ALEGRA} target="_blank" rel="noreferrer">
              {I18n.get('goToAlegra', 'Ir a Alegra')}
            </a>
          </li>
        </ul>

      </div>
    </div>
  )

}

export default NoStationsError;

import React from 'react';
import PropTypes from 'prop-types';
import { I18n } from '@aws-amplify/core';
import { getLegalStatus, getRefundTypeLabel } from '../utils';

/**
 * Default RefundDetails Component.
 *
 * @param {Object} props - Component props.
 * @param {Object} props.refund - The refund object.
 * @param {string} props.statusColor - CSS class for status color.
 * @param {string} props.country - Country code.
 * @returns {JSX.Element} - Rendered default component.
 */
const RefundDetailsDefault = React.memo(({ refund, statusColor, country }) => (
  <>
    <tr>
      <td className='caption-bold'>
        {I18n.get('emissionStatus', 'Estado DIAN')}
      </td>
      <td className={`emission-status-${statusColor}`}>
        {I18n.get(getLegalStatus(refund))}
      </td>
    </tr>
    <tr>
      <td className='caption-bold'>{I18n.get('concept', 'Concepto')}</td>
      <td>{getRefundTypeLabel(refund, country)}</td>
    </tr>
    {refund.cause && (
      <tr>
        <td className='caption-bold'>{I18n.get('reasonRefund', 'Razón')}</td>
        <td>{refund.cause}</td>
      </tr>
    )}
    {(refund.stamp?.cude || refund.stamp?.cufe) && (
      <tr>
        <td className='caption-bold'>CUDE</td>
        <td>{refund.stamp.cude || refund.stamp.cufe || ''}</td>
      </tr>
    )}
    {(refund.stamp?.warnings || []).length > 0 && (
      <tr>
        <td className='caption-bold'>
          {I18n.get('notifications', 'Notificaciones')}
        </td>
        <td>
          {refund.stamp.warnings.map((warning, index) => (
            <span className='d-block' key={index}>
              {warning.replace(/^"|"$/g, '')}
            </span>
          ))}
        </td>
      </tr>
    )}
  </>
));

RefundDetailsDefault.propTypes = {
  refund: PropTypes.shape({
    cause: PropTypes.string,
    stamp: PropTypes.shape({
      date: PropTypes.string,
      cude: PropTypes.string,
      cufe: PropTypes.string,
      warnings: PropTypes.arrayOf(PropTypes.string),
    }),
    type: PropTypes.string,
  }).isRequired,
  statusColor: PropTypes.string.isRequired,
  country: PropTypes.string.isRequired,
};

export default RefundDetailsDefault;

import es from "./es";

const dictionary = {
  ...es,
  "numerationsSettingTooltip": 'Administra desde "Alegra Gestión" las numeraciones que usas para facturar en tu negocio.',
  "taxesSettingTooltip": 'Configura desde "Alegra Gestión" los impuestos que necesitas para facturar en tu punto de venta.',
  "numerationExpired.notification.title": "¡Se venció la fecha de tu numeración! 📅",
  "numerationExpired.notification.text": "Ten en cuenta que la numeración {} ya está vencida y a partir del 17 de julio no podrás usarla al vender.",
  "numerationMaxNumberReached.notification.title": "¡Se acabaron los consecutivos de tu numeración! 🚨",
  "numerationMaxNumberReached.notification.text": "Ten en cuenta que la numeración {} se agotó y a partir del 17 de julio no podrás usarla al vender.",
  "solutions.description.new": "Más soluciones para tu negocio",
  "AlegraPayroll.new": "Facturación",
  "AlegraPayroll.description.new": "Crea tus facturas en segundos",
  "AlegraAccounting.new": "Gestión",
  "AlegraAccounting.description.new": "Factura, administra y gestiona tu stock",
  "AlegraPOS.new": "POS",
  "AlegraPOS.description.new": "Agiliza tus ventas y controla tu efectivo",
  "AlegraShop.new": "Tienda",
  "AlegraShop.description.new": "Crea tu primera tienda online en 4 clics",
  "importProducts.text": "Importar productos",
  "importProducts.tooltip": "Te llevaremos a Alegra Gestión para que los subas de manera masiva.",
  "importProducts.tooltip": "Te llevaremos a Alegra Gestión para que los subas de manera masiva.",
  "solutionModal.accounting.supTitle": "Tus cuentas claras con",
  "solutionModal.accounting.buttonMainText": "Prueba 15 días gratis",
  "solutionModal.accounting.buttonSecondaryText": "Más información",
  "solutionModal.accounting.description": "Con Alegra Gestión controlas tus ingresos y gastos con un clic, y obtienes reportes administrativos y de ventas en tiempo real.",
  "solutionModal.accounting.subTitle": "*Después de los 15 días puedes elegir un plan desde {{price}} {{currency}} al mes.",
  "solutionModal.store.supTitle": "Incrementa tus ventas con",
  "solutionModal.store.buttonMainText": "Prueba 15 días gratis",
  "solutionModal.store.buttonSecondaryText": "Más información",
  "solutionModal.store.description": "¡Empieza a vender online en segundos!\nCon Alegra Tienda creas tu primera tienda online en solo 4 clics.",
  "solutionModal.store.subTitle": "*Después de los 15 días puedes elegir un plan desde {{price}} {{currency}} al mes.",
  "solutionModal.pos.supTitle": "Gestiona tu negocio con",
  "solutionModal.pos.buttonMainText": "Prueba 15 días gratis",
  "solutionModal.pos.buttonSecondaryText": "Más información",
  "solutionModal.pos.description": "Factura en segundos con el Sistema Punto de Venta más veloz del mercado, además, podrás generar tus reportes de ventas e inventario en segundos. ⚡",
  "solutionModal.pos.subTitle": "*Después de los 15 días puedes elegir un plan desde {{price}} {{currency}} al mes.",
};

export default dictionary;
import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Form } from 'react-final-form'
import { I18n } from '@aws-amplify/core';
import { NavLink } from 'react-router-dom'
import { get } from 'lodash'

import { closeModal } from '../../../reducers/modals';
import { renameInvoice } from '../../../reducers/pendingInvoices';
import Body from '../../forms/renamePendingInvoice/RenamePendingInvoice'
import { validate } from '../../forms/renamePendingInvoice/utils'
import Modal from '../../common/Modal';
import Bottom from '../common/Bottom'

const RenamePendingInvoice = () => {
  const dispatch = useDispatch()
  const isOpen = useSelector(state => get(state, 'modals.renamePendingInvoice.isOpen', false));
  const index = useSelector(state => get(state, 'modals.renamePendingInvoice.params.index', null));
  const invoice = useSelector(state => get(state, 'modals.renamePendingInvoice.params.invoice', null));

  const submit = async ({ name }) => {
    if (!!name) {
      await dispatch(renameInvoice({ index, name }))
      dispatch(closeModal({ modal: 'renamePendingInvoice' }))
    }
  }

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={() => dispatch(closeModal({ modal: 'renamePendingInvoice' }))}
      title={I18n.get('renamePendingInvoice', 'renombrar factura pendiente')}
      className="modal__medium-size"
    >
      <Form
        onSubmit={submit}
        validate={values => validate(values)}
        initialValues={{
          name: get(invoice, 'name', null)
        }}
      >
        {({ handleSubmit, submitting }) => (
          <form noValidate onSubmit={handleSubmit}>
            <div className="d-flex flex-column p-3 justify-content-center align-items-center">
              <p className="text-muted text-capitalize-first h4 py-3">
                {I18n.get('renamePendingInvoiceHelp', 'para cambiar el prefijo general tienes que dirigirte')}
                {' '}
                <NavLink to="/settings/station" onClick={() => dispatch(closeModal({ modal: 'renamePendingInvoice' }))}>
                  {I18n.get('here', 'aquí')}
                </NavLink>
              </p>
              <div className="px-sm-5 py-4 d-flex flex-column w-75 m-auto">
                <Body />
              </div>
            </div>
            <Bottom
              submitting={submitting}
              onClose={() => dispatch(closeModal({ modal: 'renamePendingInvoice' }))}
            />
          </form>
        )}
      </Form>

    </Modal>
  )
}

export default RenamePendingInvoice;
import { createSlice } from '@reduxjs/toolkit';
import { getInitialState } from '../utils';

const modalInitial = {
  isOpen: false,
  params: null,
}

const initialState = {
  onboarding: modalInitial,
}

const appSlice = createSlice({
  name: 'tours',
  initialState: getInitialState('tours', initialState),
  reducers: {
    openTour: (state, action) => {
      state[action.payload.tour] = { isOpen: true, params: action.payload.params }
    },
    closeTour: (state, action) => {
      state[action.payload.tour] = { isOpen: false, params: null }
    }
  }
});

const { actions, reducer } = appSlice;

export const { openTour, closeTour } = actions;

export default reducer;
import React from 'react'
import { I18n } from '@aws-amplify/core';
import { useDispatch, useSelector } from 'react-redux'
import { capitalize, get } from 'lodash'

import { closeModal } from '../../../reducers/modals'
import { country as countrySelector, shiftsEnabled } from '../../../selectors/company'
import { getSystemVersion } from '../../../utils/platform'
import Modal from '../../common/Modal';

let Shortcuts = () => {
  const dispatch = useDispatch()
  const isOpen = useSelector(state => get(state, 'modals.shortcuts.isOpen', false));
  const isShiftEnabled = useSelector(shiftsEnabled);
  const country  = useSelector(countrySelector);

  const version = getSystemVersion();
  let key = 'alt';

  if (version === 'mac') {
    key = 'shift + option';
  } else if (version === 'firefox') {
    key = 'ctrl + alt';
  }

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={() => dispatch(closeModal({ modal: 'shortcuts' }))}
      title={I18n.get('keyboardShortcuts', 'atajos del teclado')}
      className="modal__medium-size"
    >
      <div className="px-md-5 px-2 py-5">
        <ul className="h3">
          <li className="mb-3 text-muted">
            <span className="font-weight-bold">{key} + H:</span> {capitalize(I18n.get('showShortcuts', 'Mostrar Atajos'))}
          </li>
          <li className="mb-3 text-muted">
            <span className="font-weight-bold">{key} + M:</span> {country === 'peru' ? capitalize(I18n.get('recordSale', 'Registrar venta')) : capitalize(I18n.get('sell', 'Vender'))}
          </li>
          <li className="mb-3 text-muted">
            <span className="font-weight-bold">{version !== 'mac' ? (key + ' + P') : (key + ' + L')}:</span> {capitalize(I18n.get('cashManagement', 'Gestión de Efectivo'))}
          </li>
          <li className="mb-3 text-muted">
            <span className="font-weight-bold">{key} + I:</span> {capitalize(I18n.get('createProduct', 'Crear Producto'))}
          </li>

          {
            country !== "mexico" && (
              <li className="mb-3 text-muted">
                <span className="font-weight-bold">{key} + C:</span> {capitalize(I18n.get('createClient', 'Crear Cliente'))}
              </li>
            )
          }

          <li className="mb-3 text-muted">
            <span className="font-weight-bold">{key} + R:</span> {capitalize(I18n.get('refunds', 'Devoluciones'))}
          </li>
          <li className="mb-3 text-muted">
            <span className="font-weight-bold">{key} + V:</span> {capitalize(I18n.get('checkInSection', 'Facturar'))}
          </li>
          {isShiftEnabled &&
            <li className="mb-3 text-muted">
              <span className="font-weight-bold">{key} + S:</span> {capitalize(I18n.get('shifts', 'turnos'))}
            </li>
          }
          <li className="mb-3 text-muted">
            <span className="font-weight-bold">{key} + B:</span> {capitalize(I18n.get('searchItemByText', 'Buscar producto'))}
          </li>
          <li className="mb-3 text-muted">
            <span className="font-weight-bold">{key} + N:</span> {capitalize(I18n.get('searchItemByBarcode', 'Código de Barras'))}
          </li>
        </ul>
      </div>

    </Modal>
  )
}

export default Shortcuts;
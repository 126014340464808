import React from 'react';
import { Field } from 'react-final-form';
import { I18n } from '@aws-amplify/core';
import { capitalize } from 'lodash';

import { renderCheckbox } from '../../fields/V0/Fields';

const RenderMulticurrency = ({ values }) => {

  return (
    <>
      <Field
        name="multicurrency"
        className="col-12"
        component={renderCheckbox}
        label={I18n.get('multicurrencyField', 'multimoneda')}
        help={I18n.get('multicurrencyFieldHelp', 'Con esta opción podrás vender en diferentes monedas desde POS')}
        switchLabel={values.multicurrency
          ? capitalize(I18n.get('disableMulticurrency', 'deshabilitar multimoneda'))
          : capitalize(I18n.get('enableMulticurrency', 'habilitar multimoneda'))
        }
      />
    </>
  )
}

export default RenderMulticurrency;
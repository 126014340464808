import React from 'react'

const SubscriptionSVG = () => {
  return (
    <svg width="360" height="100%" viewBox="0 0 454 591" fill="none" xmlns="http://www.w3.org/2000/svg" x="50%">
      <circle opacity="0.2" cx="342.488" cy="295.5" r="341.5" fill="url(#paint0_linear)" />
      <g filter="url(#filter0_d)">
        <ellipse cx="342.488" cy="295.5" rx="59" ry="58.5" fill="#00B19D" />
        <path fillRule="evenodd" clipRule="evenodd" d="M352.26 292.078C356.087 307.42 363.833 310.712 363.833 310.712C363.833 310.712 350.628 307.763 341.898 296.836C336.584 290.186 331.482 277.543 331.482 277.543L351.05 272.218C351.05 272.218 350.035 283.159 352.26 292.078ZM334.187 317.503C352.68 321.842 369.377 313.754 369.377 313.754C369.377 313.754 358.29 316.811 341.427 303.519C331.618 295.794 323.636 283.112 323.636 283.112L306.934 305.562C306.934 305.562 322.936 314.854 334.187 317.503ZM361.94 305.782C361.94 305.782 359.435 300.604 364.233 291.058C367.022 285.508 372.352 280.392 372.352 280.392L360.044 274.254C360.044 274.254 357.084 283.004 356.864 288.795C356.506 298.313 361.94 305.782 361.94 305.782ZM370.392 295.934C364.565 297.856 363.496 301.021 363.496 301.021C363.496 301.021 364.288 295.692 368.284 291.938C370.718 289.654 375.473 287.293 375.473 287.293L378.042 294.937C378.042 294.937 373.785 294.818 370.392 295.934Z" fill="white" />
      </g>
      <g opacity="0.4" filter="url(#filter1_d)">
        <ellipse cx="333.988" cy="51.5" rx="34" ry="33.5" fill="white" />
        <path fillRule="evenodd" clipRule="evenodd" d="M335.95 38.2873C335.309 38.1983 334.654 38.1523 333.988 38.1523C326.286 38.1523 320.043 44.3041 320.043 51.8926C320.043 59.4811 326.286 65.6328 333.988 65.6328C341.69 65.6328 347.934 59.4811 347.934 51.8926C347.934 50.7985 347.804 49.7343 347.559 48.7141L345.493 50.8221C345.526 51.1745 345.543 51.5316 345.543 51.8926C345.543 58.1802 340.37 63.2774 333.988 63.2774C327.607 63.2774 322.434 58.1802 322.434 51.8926C322.434 45.6761 327.49 40.6234 333.772 40.5098L335.95 38.2873ZM338.773 49.5269L339.323 53.501L341.677 53.883L340.595 46.0659L332.661 45L333.049 47.3194L337.083 47.8613L328.934 55.8905C328.467 56.3504 328.467 57.0961 328.934 57.5561C329.4 58.016 330.157 58.016 330.624 57.5561L338.773 49.5269Z" fill="#00B19D" />
      </g>
      <g opacity="0.5" filter="url(#filter2_d)">
        <ellipse cx="232.488" cy="73" rx="33.5" ry="34" fill="white" />
        <path fillRule="evenodd" clipRule="evenodd" d="M234.421 58.7932C233.79 58.7029 233.144 58.6562 232.488 58.6562C224.9 58.6562 218.748 64.8998 218.748 72.6016C218.748 80.3034 224.9 86.5469 232.488 86.5469C240.077 86.5469 246.229 80.3034 246.229 72.6016C246.229 71.4912 246.101 70.4111 245.859 69.3756L243.823 71.5151C243.856 71.8728 243.873 72.2352 243.873 72.6016C243.873 78.983 238.776 84.1563 232.488 84.1563C226.201 84.1563 221.104 78.983 221.104 72.6016C221.104 66.2923 226.086 61.1642 232.275 61.0489L234.421 58.7932ZM231.693 75.7926L239.722 67.6435C240.182 67.1768 240.182 66.4199 239.722 65.9531C239.262 65.4863 238.516 65.4863 238.057 65.9531L230.027 74.1022L229.485 70.0687L227.166 69.681L228.232 77.6147L236.049 78.6966L235.667 76.3426L231.693 75.7926Z" fill="#00B19D" />
      </g>
      <g opacity="0.7" filter="url(#filter3_d)">
        <ellipse cx="145.988" cy="136.5" rx="33" ry="34.5" fill="white" />
        <path fillRule="evenodd" clipRule="evenodd" d="M136.615 123.562C134.957 123.562 133.613 124.906 133.613 126.564V127.03C133.613 128.687 134.957 130.031 136.615 130.031H136.799C138.457 130.031 139.801 128.687 139.801 127.03V126.564C139.801 124.906 138.457 123.562 136.799 123.562H136.615ZM136.615 133.266C134.957 133.266 133.613 134.609 133.613 136.267V136.733C133.613 138.391 134.957 139.734 136.615 139.734H136.799C138.457 139.734 139.801 138.391 139.801 136.733V136.267C139.801 134.609 138.457 133.266 136.799 133.266H136.615ZM142.895 136.267C142.895 134.609 144.238 133.266 145.896 133.266H146.08C147.738 133.266 149.082 134.609 149.082 136.267V136.733C149.082 138.391 147.738 139.734 146.08 139.734H145.896C144.238 139.734 142.895 138.391 142.895 136.733V136.267ZM136.615 142.969C134.957 142.969 133.613 144.313 133.613 145.97V146.436C133.613 148.094 134.957 149.438 136.615 149.438H136.799C138.457 149.438 139.801 148.094 139.801 146.436V145.97C139.801 144.313 138.457 142.969 136.799 142.969H136.615ZM142.895 145.97C142.895 144.313 144.238 142.969 145.896 142.969H146.08C147.738 142.969 149.082 144.313 149.082 145.97V146.436C149.082 148.094 147.738 149.438 146.08 149.438H145.896C144.238 149.438 142.895 148.094 142.895 146.436V145.97ZM155.177 142.969C153.52 142.969 152.176 144.313 152.176 145.97V146.436C152.176 148.094 153.52 149.438 155.177 149.438H155.362C157.019 149.438 158.363 148.094 158.363 146.436V145.97C158.363 144.313 157.019 142.969 155.362 142.969H155.177Z" fill="#00B19D" />
      </g>
      <g filter="url(#filter4_d)">
        <circle cx="108.988" cy="227" r="34" fill="white" />
        <path fillRule="evenodd" clipRule="evenodd" d="M107.572 221.115C107.572 223.147 105.986 224.793 104.03 224.793C102.074 224.793 100.488 223.147 100.488 221.115C100.488 219.084 102.074 217.438 104.03 217.438C105.986 217.438 107.572 219.084 107.572 221.115ZM116.844 221.851C116.844 223.544 115.547 224.916 113.947 224.916C112.346 224.916 111.049 223.544 111.049 221.851C111.049 220.158 112.346 218.786 113.947 218.786C115.547 218.786 116.844 220.158 116.844 221.851ZM111.822 234.757C111.822 235.977 111.534 237.131 111.021 238.156H97.039C96.5263 237.131 96.2383 235.977 96.2383 234.757C96.2383 231.482 98.312 228.685 101.234 227.576C101.696 228.172 102.853 228.594 104.207 228.594C105.48 228.594 106.578 228.221 107.089 227.682C109.872 228.855 111.822 231.581 111.822 234.757ZM113.854 238.156H120.768C121.383 237.171 121.738 236.005 121.738 234.757C121.738 231.217 118.884 228.348 115.363 228.348C114.222 228.348 113.152 228.649 112.225 229.177C113.722 230.578 114.655 232.56 114.655 234.757C114.655 235.977 114.367 237.131 113.854 238.156Z" fill="#00B19D" />
      </g>
      <g filter="url(#filter5_d)">
        <circle cx="108.988" cy="328" r="34" fill="white" />
        <path fillRule="evenodd" clipRule="evenodd" d="M97.8096 316.855C97.8096 315.639 98.7958 314.652 100.012 314.652H114.335C114.775 314.652 115.132 315.009 115.132 315.449V318.338H119.631C120.345 318.338 120.923 318.916 120.923 319.63V340.763C120.923 341.168 120.594 341.497 120.189 341.497H118.533V341.494H101.752C99.7232 341.494 98.0526 339.96 97.8367 337.989C97.819 337.926 97.8096 337.859 97.8096 337.791V316.855ZM118.533 320.43H115.132V335.819L101.752 335.819C101.728 335.819 101.703 335.82 101.679 335.821C100.754 335.859 100.016 336.621 100.016 337.555C100.016 337.915 100.125 338.248 100.312 338.525C100.624 338.987 101.152 339.291 101.752 339.291L118.532 339.287L118.533 320.43ZM104.755 325.211C104.755 326.311 103.863 327.203 102.763 327.203C101.663 327.203 100.771 326.311 100.771 325.211C100.771 324.111 101.663 323.219 102.763 323.219C103.863 323.219 104.755 324.111 104.755 325.211ZM102.763 333.273C103.863 333.273 104.755 332.381 104.755 331.281C104.755 330.181 103.863 329.289 102.763 329.289C101.663 329.289 100.771 330.181 100.771 331.281C100.771 332.381 101.663 333.273 102.763 333.273ZM111.526 331.281C111.526 332.381 110.634 333.273 109.534 333.273C108.433 333.273 107.541 332.381 107.541 331.281C107.541 330.181 108.433 329.289 109.534 329.289C110.634 329.289 111.526 330.181 111.526 331.281ZM109.534 327.203C110.634 327.203 111.526 326.311 111.526 325.211C111.526 324.111 110.634 323.219 109.534 323.219C108.433 323.219 107.541 324.111 107.541 325.211C107.541 326.311 108.433 327.203 109.534 327.203ZM101.02 320.978C100.58 320.978 100.223 320.621 100.223 320.181L100.223 318.587C100.223 318.147 100.58 317.79 101.02 317.79L111.351 317.79C111.791 317.79 112.148 318.147 112.148 318.587V320.181C112.148 320.621 111.791 320.978 111.351 320.978L101.02 320.978Z" fill="#00B19D" />
      </g>
      <g opacity="0.4" filter="url(#filter6_d)">
        <ellipse cx="333.988" cy="533.5" rx="34" ry="33.5" fill="white" />
        <path fillRule="evenodd" clipRule="evenodd" d="M327.228 537.957V523.429H340.749V537.957H327.228ZM346.752 540.959V543.668C346.752 545.054 346.336 546.425 345.52 547.518C344.703 548.611 343.342 549.574 341.581 549.574H326.396C324.635 549.574 323.274 548.611 322.457 547.518C321.641 546.425 321.225 545.054 321.225 543.668V523.332C321.225 521.946 321.641 520.575 322.457 519.482C323.274 518.388 324.635 517.426 326.396 517.426H341.581C343.342 517.426 344.703 518.388 345.52 519.482C346.336 520.575 346.752 521.946 346.752 523.332V540.959Z" fill="#00B19D" />
      </g>
      <g opacity="0.7" filter="url(#filter7_d)">
        <ellipse cx="145.988" cy="431" rx="33" ry="34" fill="white" />
        <path fillRule="evenodd" clipRule="evenodd" d="M143.238 428.875C143.51 428.875 143.772 428.833 144.02 428.755C144.874 429.208 145.651 430.479 146.403 432.521C147.152 434.554 147.947 436.069 148.808 436.986C148.762 437.196 148.738 437.415 148.738 437.64C148.738 439.254 149.969 440.562 151.488 440.562C153.007 440.562 154.238 439.254 154.238 437.64C154.238 437.011 154.051 436.428 153.732 435.951C154.558 434.676 155.331 432.796 156.094 430.291C157.384 430.05 158.363 428.853 158.363 427.414C158.363 425.8 157.132 424.492 155.613 424.492C154.095 424.492 152.863 425.8 152.863 427.414C152.863 428.417 153.339 429.303 154.065 429.829C153.346 432.139 152.641 433.794 152.004 434.77C151.837 434.736 151.665 434.719 151.488 434.719C150.949 434.719 150.445 434.884 150.02 435.169C149.532 434.55 148.956 433.439 148.324 431.722C147.542 429.601 146.665 428.11 145.714 427.227C145.89 426.842 145.988 426.41 145.988 425.953C145.988 424.339 144.757 423.031 143.238 423.031C141.719 423.031 140.488 424.339 140.488 425.953C140.488 426.487 140.623 426.988 140.859 427.419C139.51 428.555 138.104 430.52 136.679 433.277C136.576 433.264 136.47 433.258 136.363 433.258C134.844 433.258 133.613 434.566 133.613 436.179C133.613 437.793 134.844 439.101 136.363 439.101C137.882 439.101 139.113 437.793 139.113 436.179C139.113 435.467 138.873 434.814 138.475 434.307C140.037 431.165 141.351 429.348 142.508 428.771C142.741 428.839 142.986 428.875 143.238 428.875Z" fill="#00B19D" />
      </g>
      <g opacity="0.5" filter="url(#filter8_d)">
        <ellipse cx="232.488" cy="508.5" rx="33.5" ry="34.5" fill="white" />
        <path fillRule="evenodd" clipRule="evenodd" d="M245.051 508.772C245.051 509.951 244.361 511.021 243.287 511.507L242.771 511.74C242.595 512.338 242.372 512.909 242.1 513.462L242.605 514.791C243.019 515.88 242.766 517.111 241.954 517.948L241.635 518.277C240.786 519.152 239.492 519.427 238.361 518.972L237.148 518.484C236.686 518.714 236.202 518.91 235.707 519.071L235.473 519.618C235.002 520.725 233.915 521.443 232.712 521.443H232.363C231.157 521.443 230.069 520.722 229.599 519.613L229.384 519.106C228.838 518.936 228.312 518.727 227.807 518.475L226.483 519.008C225.352 519.463 224.058 519.189 223.209 518.314L222.89 517.987C222.079 517.151 221.824 515.92 222.237 514.831L222.808 513.326C222.571 512.815 222.369 512.285 222.205 511.74L221.69 511.507C220.616 511.021 219.926 509.951 219.926 508.772V508.198C219.926 507.018 220.617 505.947 221.692 505.462L222.216 505.226C222.415 504.565 222.676 503.926 222.985 503.325L222.655 502.454C222.242 501.363 222.497 500.133 223.31 499.297L223.631 498.968C224.48 498.095 225.772 497.821 226.902 498.275L227.696 498.595C228.233 498.319 228.798 498.084 229.385 497.903L229.6 497.395C230.07 496.284 231.159 495.562 232.365 495.562H232.713C233.916 495.562 235.003 496.281 235.475 497.388L235.708 497.935C236.284 498.124 236.844 498.362 237.373 498.64L238.197 498.309C239.327 497.855 240.62 498.129 241.469 499.004L241.788 499.332C242.6 500.168 242.854 501.399 242.44 502.488L242.067 503.472C242.341 504.034 242.578 504.618 242.761 505.226L243.284 505.462C244.36 505.947 245.051 507.018 245.051 508.198V508.772ZM238.77 508.5C238.77 512.073 235.957 514.969 232.488 514.969C229.019 514.969 226.207 512.073 226.207 508.5C226.207 504.927 229.019 502.031 232.488 502.031C235.957 502.031 238.77 504.927 238.77 508.5Z" fill="#00B19D" />
      </g>
      <defs>
        <filter id="filter0_d" x="271.488" y="228" width="142" height="141" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
          <feOffset dy="3" />
          <feGaussianBlur stdDeviation="6" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
        </filter>
        <filter id="filter1_d" x="287.988" y="9" width="92" height="91" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
          <feOffset dy="3" />
          <feGaussianBlur stdDeviation="6" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
        </filter>
        <filter id="filter2_d" x="186.988" y="30" width="91" height="92" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
          <feOffset dy="3" />
          <feGaussianBlur stdDeviation="6" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
        </filter>
        <filter id="filter3_d" x="100.988" y="93" width="90" height="93" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
          <feOffset dy="3" />
          <feGaussianBlur stdDeviation="6" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
        </filter>
        <filter id="filter4_d" x="62.9883" y="184" width="92" height="92" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
          <feOffset dy="3" />
          <feGaussianBlur stdDeviation="6" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
        </filter>
        <filter id="filter5_d" x="62.9883" y="285" width="92" height="92" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
          <feOffset dy="3" />
          <feGaussianBlur stdDeviation="6" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
        </filter>
        <filter id="filter6_d" x="287.988" y="491" width="92" height="91" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
          <feOffset dy="3" />
          <feGaussianBlur stdDeviation="6" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
        </filter>
        <filter id="filter7_d" x="100.988" y="388" width="90" height="92" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
          <feOffset dy="3" />
          <feGaussianBlur stdDeviation="6" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
        </filter>
        <filter id="filter8_d" x="186.988" y="465" width="91" height="93" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
          <feOffset dy="3" />
          <feGaussianBlur stdDeviation="6" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
        </filter>
        <linearGradient id="paint0_linear" x1="408.952" y1="-1.16213" x2="120.182" y2="607.53" gradientUnits="userSpaceOnUse">
          <stop stopColor="#7AEEE1" />
          <stop offset="1" stopColor="#7AEEE1" stopOpacity="0" />
        </linearGradient>
      </defs>
    </svg>

  )
}

export default SubscriptionSVG
import { I18n } from "aws-amplify";
import { get, capitalize } from "lodash";
import dayjs from "dayjs";

export const validate = values => {
  let errors = {}

  const items = get(values, 'items', []);
  const numeration = get(values, 'numeration');
  const isItemSelected = !!items.find((item) => get(item, 'item'))

  if (!isItemSelected) {
    errors.noItem = capitalize(I18n.get("numerationRequired", "Elige una numeración"));
  }

  if (!numeration)
    errors.numeration = capitalize(I18n.get("numerationRequired", "Elige una numeración"));

  return errors;
};

export const transform = values => {
  const items = get(values, 'items', []).map((item) => {
    return {
      id: get(item, 'item.id', null) || get(item, 'id', null),
      quantity: get(item, 'quantity', 0),
      unitCost: get(item, 'cost', 0),
      type: get(item, 'objective.key', '')
    }
  })

  return {
    id: get(values, 'id') ?? '',
    resolution: get(values, 'numeration.id'),
    warehouse: { id: get(values, 'warehouse.id') },
    date: get(values, 'date') ?? dayjs(new Date()).format("YYYY-MM-DD"),
    observations: get(values, 'observations', ''),
    items: items,
  }
}

export const transformOptimisticUpdate = values => {
  const items = get(values, 'items', []).map((item) => {
    return {
      id: get(item, 'item.id', null),
      quantity: get(item, 'quantity', 0),
      unitCost: get(item, 'cost', 0),
      type: get(item, 'objective.key', '')
    }
  })

  return {
    id: get(values, 'id') ?? '',
    number: get(values, 'number', null),
    warehouse: { id: get(values, 'warehouse.id'), name: get(values, 'warehouse.name') },
    date: dayjs(new Date()).format("YYYY-MM-DD"),
    observations: get(values, 'observations', ''),
    totalAdjusted: get(values, 'totalAdjusted'),
    items: items,
    status: "offline",
  }
};

export const transformFromAPI = (values) => {

  const items = get(values, 'items').map((item) => {
    return {
      id: get(item, 'id'),
      quantity: get(item, 'quantity'),
      name: get(item, 'name'),
      images: get(item, 'images'),
      objective: {
        key: get(item, 'type'),
        label: get(item, 'type') === 'in' ? I18n.get("increment", "Incrementar") : I18n.get("decrement", "Disminuir")
      },
      cost: get(item, 'unitCost'),
      inventory: get(item, 'inventory'),
    }
  })
  const inventoryAdjustment = {
    ...values,
    items: items,
  }
  return inventoryAdjustment;
}
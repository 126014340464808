import PropTypes from 'prop-types';
import { createElement } from 'react';
import { Tooltip } from '@alegradev/smile-ui-react';

/**
 * A global typography component for consistent text styling throughout the application.
 *
 * @typedef {'display-1' | 'display-2' | 'display-3' | 'heading-1' | 'heading-2' | 'heading-3' |
 * 'heading-4' | 'subheading-1' | 'subheading-2' | 'body-1-regular' | 'body-1-bold' |
 * 'body-1-italic' | 'body-1-underline' | 'body-2-regular' | 'body-2-bold' | 'body-2-italic' |
 * 'body-2-underline' | 'body-3-regular' | 'body-3-bold' | 'body-3-italic' | 'body-3-underline' |
 * 'caption-regular' | 'caption-bold' | 'label-1' | 'label-2' | 'label-3'} TypographyType
 *
 * @typedef {'primary' | 'secondary' | 'tertiary' | 'placeholder' | 'disabled' | 'inverse' | 'green' | 'danger2' | 'danger'} TypographyVariant
 * @typedef {'p' | 'span'} TypographyHtmlType
 *
 * @param {object} props - The properties passed to the Typography component.
 * @param {TypographyType} props.type - The type of typography style to apply.
 * @param {TypographyHtmlType} props.htmlType - The parent type of typography style to apply.
 * @param {TypographyVariant} props.variant - The variant of typography style to apply.
 * @param {string} props.text - The text content to be styled.
 * @param {number} props.max - The text length to be styled.
 * @param {extra} props.extra - Extra component after text
 * @param {withHtml} props.withHtml - Render html tags
 *
 * @returns {JSX.Element} A React element representing the styled text.
 */
function Typography({ type, text, variant, extra, htmlType = 'p', max,withHtml }) {
  const renderText = () => {
    if (typeof text === 'string') {
      const textSliced =  `${text.slice(0, max ? max : text.length)}${
        max && text.length > max ? '...' : ''
      }`;

      if(withHtml) {
        return { __html: textSliced };
      } else {
        return textSliced;
      }
    }
    return text;
  };

  const element = createElement(
    htmlType,
    {
      className: `${type} color-${variant} reset-typography`,
      ...(withHtml && { dangerouslySetInnerHTML: renderText() }),
    },
    withHtml ? null : renderText()
  );

  return typeof text === 'string' && max && text.length > max ? (
    <Tooltip
      overlay={text}
      placement='bottom'
    >
      {element}
    </Tooltip>
  ) : (
    element
  );
}

Typography.propTypes = {
  type: PropTypes.string,
  variant: PropTypes.string,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  max: PropTypes.number,
  extra: PropTypes.node,
  htmlType: PropTypes.oneOf(['p', 'span']),
  withHtml: PropTypes.bool,
};

export default Typography;

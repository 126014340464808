import React from 'react'
import { I18n } from '@aws-amplify/core';
import { graphqlOperation } from '@aws-amplify/api';
import { useDispatch, useSelector } from 'react-redux'
import { capitalize, get } from 'lodash'

import * as mutations from '../../../graphql/mutations'
import { updateCustomField as updateCustomFieldReducer, removeCustomField } from '../../../reducers/customFields';
import { hasPermissionTo } from '../../../selectors/auth';
import { handleError } from '../../../utils/errors'
import { toast } from '../../../utils';
import alegraAPI from '../../../reducers/alegraAPI';

import { APIGraphqlSelector } from '../../../selectors/app';
import { Tooltip } from '@alegradev/smile-ui-react';

import { Icon } from '@alegradev/smile-ui-react';
import { IconLock, IconLockOpen, IconPencil, IconTrash } from '@tabler/icons-react';


const Actions = ({ customField, onRefresh, onView, startLoading, stopLoading }) => {
  const dispatch = useDispatch();
  const can = useSelector(hasPermissionTo);
  const APIGraphql = useSelector(APIGraphqlSelector);

  const deleteCustomField = async () => {
    startLoading()
    try {
      await APIGraphql(graphqlOperation(mutations.deleteCustomField, {
        id: customField.id,
      }))
      stopLoading()
      toast.success({
        title: I18n.get('customFieldDeleted', 'Campo adicional eliminado con éxito')
      })
      dispatch(removeCustomField(customField.id));
      onRefresh()
    } catch (error) {
      stopLoading()
      toast.error({
        title: I18n.get('customFieldDeletedError', 'Error eliminando el campo adicional'),
        subtitle: handleError(error)
      })
    }
  }

  const updateCustomField = async isActive => {
    startLoading()
    try {
      let newCustomField = { id: get(customField, 'id'), status: !!isActive ? 'active' : 'inactive' }

      if (get(newCustomField, 'id') === "1") {
        await alegraAPI.put(`/custom-fields/${get(newCustomField, 'id')}`, {
          status: !!isActive ? 'active' : 'inactive',
        })
      }
      else {
        await APIGraphql(graphqlOperation(mutations.updateCustomField, {
          customField: newCustomField
        }))
      }


      stopLoading()
      toast.success({
        title: isActive
          ? I18n.get('customFieldActivated', 'Campo adicional activado con éxito')
          : I18n.get('customFieldDeactivated', 'Campo adicional desactivado con éxito')
      })

      dispatch(updateCustomFieldReducer({
        id: newCustomField.id,
        changes: newCustomField,
      }));
      onRefresh()
    } catch (error) {
      stopLoading()
      toast.error({
        title: I18n.get('customFieldStatusError', 'Error cambiando el estado del campo adicional'),
        subtitle: handleError(error)
      })
    }
  }

  return (
    <div className="d-flex align-items-center justify-content-start table-gray-actions">
      <Tooltip
        visible={!can('edit', 'custom-fields')}
        overlay={I18n.get('userNotAllowed.custom-fields.edit', 'no tienes permisos para editar campos adicionales')}
      >
        <button
          type="button"
          className="btn button-transparent"
          disabled={!can('edit', 'custom-fields') || !get(customField, 'editable', true)}
          title={capitalize(I18n.get('edit', 'editar'))}
          onClick={() => onView()}
        >
          <Icon icon={IconPencil} />
        </button>
      </Tooltip>

      <Tooltip
        visible={!can('edit', 'custom-fields')}
        overlay={I18n.get('userNotAllowed.custom-fields.edit', 'no tienes permisos para editar campos adicionales')}
      >
        <button
          type="button"
          className="btn button-transparent"
          disabled={!can('edit', 'custom-fields') || !get(customField, 'editable', true)}
          onClick={() => updateCustomField(!(get(customField, 'status') === 'active'))}
          title={get(customField, 'status') === 'active'
            ? capitalize(I18n.get('deactivate', 'desactivar'))
            : capitalize(I18n.get('activate', 'activar'))
          }
        >
          {get(customField, 'status') === 'active'
            ? <Icon icon={IconLockOpen} extraClass="icon-primary" />
            : <Icon icon={IconLock} extraClass="icon-primary" />
          }
        </button>
      </Tooltip>

      <Tooltip
        visible={!can('delete', 'custom-fields')}
        overlay={I18n.get('userNotAllowed.custom-fields.delete', 'no tienes permisos para eliminar campos adicionales')}
      >
        <button
          type="button"
          className="btn button-transparent button-remove"
          disabled={!can('delete', 'custom-fields') || !get(customField, 'deletable', true)}
          title={capitalize(I18n.get('delete', 'eliminar'))}
          onClick={() => deleteCustomField()}
        >
          <Icon icon={IconTrash} />
        </button>
      </Tooltip>
    </div>
  )
}

export default Actions;
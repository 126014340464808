import React from 'react';
import PropTypes from 'prop-types';
import { I18n } from '@aws-amplify/core';
import { Icon } from '@alegradev/smile-ui-react';
import { IconArrowsCross, IconCreditCard, IconCreditCardRefund, IconUserDollar } from '@tabler/icons-react';

/**
 * Renders the appropriate icon based on the provided icon name.
 *
 * @param {string} icon - The name of the icon to render.
 * @returns {JSX.Element|null} - The rendered icon component or null if icon name is invalid.
 */
const RenderIcon = ({ icon }) => {
  switch (icon) {
    case 'cashOut':
      return <Icon icon={IconCreditCardRefund} />;
    case 'creditCard':
      return <Icon icon={IconCreditCard} />;
    case 'arrowsCross':
      return <Icon icon={IconArrowsCross} />;
    case 'seller':
      return <Icon icon={IconUserDollar} />;
    default:
      return null;
  }
};

RenderIcon.propTypes = {
  icon: PropTypes.string.isRequired,
};

/**
 * MethodBody Component
 *
 * Represents an individual refund method option.
 *
 * @param {Object} props - Component properties.
 * @param {string} props.icon - The icon name to display.
 * @param {string} props.title - The title of the refund method.
 * @param {string} props.description - The description of the refund method.
 * @param {boolean} [props.showTagElectronic=false] - Whether to show the electronic document tag.
 * @param {Function} props.onClick - Callback invoked when the method is selected.
 * @returns {JSX.Element} - The rendered MethodBody component.
 */
const MethodBody = ({ icon, title, description, showTagElectronic = false, onClick }) => {
  return (
    <button
      onClick={onClick}
      className='btn btn-refound-method-new p-4 w-100 d-flex flex-column justify-content-start'
      aria-label={`${title}: ${description}`}
    >
      <div className='d-flex flex-row align-items-center flex-start'>
        <div className='icon-refund-method mr-3'>
          <RenderIcon icon={icon} />
        </div>
        {showTagElectronic && (
          <div className='tag-electronic' aria-label='Documento electrónico'>
            {I18n.get('electronicDocument', 'Documento electrónico')}
          </div>
        )}
      </div>

      <div className='text-left'>
        <p className='title-method-refund mb-1'>{title}</p>
        <p className='description-method-refund'>{description}</p>
      </div>
    </button>
  );
};

MethodBody.propTypes = {
  icon: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  showTagElectronic: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
};

export default React.memo(MethodBody);
import Cookie from 'js-cookie';

import { base64ToBlob } from "../../../utils";
import alegraAPI from '../../../reducers/alegraAPI';

export const saveImage = async (image, id) => {
  const extension = image.substring("data:/image".length, image.indexOf(";base64"));

  const filename = `${Date.now()}-${Math.floor(Math.random() * 100000)}.${extension}`;

  const blob = base64ToBlob(image, `image/${extension}`);

  const form = new FormData();
  form.append('image', blob, filename);

  const response = await alegraAPI.post(
    `/item-categories/${id}/attachment`,
    form, null,
    {
      headers: {
        'X-Data-Source': 'pos',
        Authorization: `Basic ${Cookie.get(process.env.REACT_APP_ALEGRA_COOKIE)}`,
        'Content-Type': 'multipart/form-data'
      }
    }
  );

  return response.data;
}
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Field, Form } from "react-final-form";
import { I18n } from "aws-amplify";
import { graphqlOperation } from "@aws-amplify/api";
import { capitalize, get, isEmpty } from "lodash";

import * as mutations from '../../../graphql/mutations'
import Modal from "../../common/Modal";
import Notification from "../common/Notification";
import Bottom from "../common/Bottom";
import { closeModal } from "../../../reducers/modals";
import { renderSelect } from "../../forms/fields/V0/Fields";
import { activeWarehouses as activeWarehousesSelector } from "../../../selectors/warehouses";
import { stationTransformer } from "../../../utils/transformers";
import * as stationSelectors from '../../../selectors/app'
import { updateStation } from "../../../reducers/app";
import { toast } from "../../../utils";
import { formError } from "../../../utils/errors";


const ConfigureWarehouse = () => {
  const dispatch = useDispatch();
  const isOpen = useSelector((state) => get(state, "modals.configureWarehouse.isOpen", false));
  const station = useSelector(stationSelectors.station);
  const activeWarehouses = useSelector(activeWarehousesSelector);
  const APIGraphql = useSelector(stationSelectors.APIGraphqlSelector);

  const submit = async (values) => {
    const idWarehouse = get(values, 'warehouse.id', null);

    try {
      const response = await APIGraphql(graphqlOperation(mutations.updateStation, {
        station: stationTransformer({ ...station, idWarehouse })
      }));

      const updatedStation = get(response, 'data.updateStation');
      if (!!updateStation)
        dispatch(updateStation(updatedStation))

      toast.success({ title: I18n.get('stationUpdated', 'terminal actualizada') });
      dispatch(closeModal({ modal: "configureWarehouse" }))

    } catch (error) {

      return formError(error, I18n.get('updateStationError', 'hubo un error al cambiar los datos de la terminal'));
    }
  }

  const validate = (values) => {
    let errors = {}

    if (!get(values, 'warehouse.id')) {
      errors.warehouse = capitalize(I18n.get("", "Escoge una bodega"));
    }

    return errors;
  }

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={() => null}
      title={capitalize(I18n.get("setStationWarehouse", "Configura una bodega para tu terminal"))}
      className="modal__medium-size"
      hideClose
    >
      <Form
        onSubmit={submit}
        validate={(values) => validate(values)}
        keepDirtyOnReinitialize
      >
        {({ handleSubmit, submitting, values, submitError, pristine }) => (
          <form noValidate onSubmit={handleSubmit}>
            <div className="px-5 pb-3">
              <p className="h4 py-4 text-subtitle-muted text-capitalize-first">
                {I18n.get("setStationWarehouseHelp", "Parece que se desactivó o eliminó la bodega que estaba asociada a tu terminal, selecciona una para seguir vendiendo.")}
              </p>
              <div className="d-flex align-items-center justify-content-center">
                <div className="form-body w-50">
                  <Field
                    name="warehouse"
                    className=""
                    component={renderSelect}
                    options={activeWarehouses}
                    getOptionLabel={(option) => option.name}
                    getOptionValue={(option) => option.id}
                    isloading={isEmpty(activeWarehouses)}
                    label={capitalize(I18n.get("warehouse", "Bodega"))}
                    menuPosition="absolute"
                    required
                  >
                  </Field>
                </div>
              </div>
            </div>
            {!!submitError && !pristine && <Notification isOpen={true} text={submitError} type="error" />}
            <Bottom
              submitting={submitting}
              onClose={() => dispatch(closeModal({ modal: "configureWarehouse" }))}
              submitText={capitalize(I18n.get("configureStationWarehouse", "Configurar bodega"))}
              hideCancel
            />
          </form>
        )}
      </Form>
    </Modal>
  )
}

export default ConfigureWarehouse;
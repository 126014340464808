import { get, isNull, isUndefined } from 'lodash';

export const argentinaTransformer = (values, _props, newValues) => {
  const identificationType = get(values, 'identification.type', null);
  const identificationNumber = get(values, 'identification.number', null);

  delete newValues.identification;
  const transformedValues = {
    ...newValues,
    identificationObject: {
      type: identificationType,
      number: identificationNumber,
    },
    ivaCondition: get(values, 'ivaCondition', null),
    address: {
      province: get(values, 'address.province', null),
      city: get(values, 'address.city', null),
      postalCode: get(values, 'address.postalCode', null),
      address: get(values, 'address.address', null),
    },
  };
  Object.keys(transformedValues.address).forEach((key) => {
    if (
      isNull(transformedValues.address[key]) ||
      isUndefined(transformedValues.address[key])
    )
      delete transformedValues.address[key];
  });
  return transformedValues;
};

import React, { useEffect } from 'react';
import { Field } from 'react-final-form';
import { useSelector } from 'react-redux';
import { I18n } from '@aws-amplify/core';
import { capitalize, get, lowerCase, deburr } from 'lodash';

import {
  country as countrySelector,
  electronicInvoicing,
} from '../../../selectors/company';
import { renderField, renderSelect } from '../fields/V0/Fields';
import { getDefaultProductUnit } from '../../../selectors/units';
import { renderProductKeyTooltip } from '../../../utils';
import configAPI from '../../../reducers/configAPI';

const filterOptions = (query, options) =>
  options.filter(
    (option) =>
      lowerCase(deburr(option.value)).includes(lowerCase(deburr(query))) ||
      lowerCase(deburr(option.code)).includes(lowerCase(deburr(query)))
  );

const getProductKeyOptionLabel = (option, country) =>
  country === 'costaRica'
    ? !option.tax
      ? `${option.key} - ${option.name}`
      : `${option.key} - ${option.name} - IVA ${option.tax}%`
    : `${option.key} - ${option.name}`;

const BasicData = ({ form, values }) => {
  const isElectronic = useSelector(electronicInvoicing);
  const country = useSelector(countrySelector);
  const defaultUnit = useSelector(
    getDefaultProductUnit(
      country === 'peru' || country === 'panama'
        ? 'product'
        : get(values, 'type')
    )
  );

  useEffect(() => {
    form.change('unit', defaultUnit);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultUnit]);

  const countriesWithKeys =
    (country === 'costaRica' && isElectronic) ||
    (country === 'mexico' && isElectronic);

  return (
    <>
      <Field
        name='name'
        component={renderField}
        className='col-6'
        type='text'
        required
        label={capitalize(I18n.get('name', 'nombre'))}
      />

      <Field
        name='unit'
        component={renderSelect}
        className='col-6'
        loadOptions={async (inputValue) => {
          try {
            // ex queries.allUnits
            const response = await configAPI.v2.get('/units', {
              application_version: country,
            })
            let data = get(response, 'data', []);
            if (country === "republicaDominicana") {
              data = data.map(unit => ({ ...unit, unitType: unit.itemType }))
            }

            if (country === 'republicaDominicana') {
              data =
                data.filter((unit) => unit.unitType === 'product').length !== 0
                  ? data.filter((unit) => unit.unitType === 'product')
                  : data;
            }
            return filterOptions(inputValue, data);
          } catch {
            return [];
          }
        }}
        defaultOptions
        getOptionLabel={(option) =>
          I18n.get(`units.${option.key}`, option.value)
        }
        getOptionValue={(option) => option.key}
        label={capitalize(I18n.get('measurementUnit', 'unidad de medida'))}
        menuPosition='absolute'
        required
      />

      {countriesWithKeys && (
        <Field
          name='productKey'
          component={renderSelect}
          className='col-sm-12'
          required={country === 'mexico' || country === 'costaRica'}
          loadOptions={async (inputValue) => {
            try {
              // ex queries.allItemCodes

              const response = await configAPI.get('/products-keys', {
                application_version: country,
                name_contains: inputValue,
                limit: 20,
              })
              let data = get(response, 'data', [])
              if (country === 'costaRica')
                data = data.filter((itemCode) => !itemCode.deprecated);

              return data;
            } catch {
              return [];
            }
          }}
          defaultOptions
          getOptionLabel={(option) => getProductKeyOptionLabel(option, country)}
          getOptionValue={(option) => option.key}
          label={
            country === 'peru'
              ? I18n.get('sunatCode', 'código SUNAT')
              : get(values, 'type') === 'service'
                ? I18n.get(`serviceKey`, 'código del producto')
                : I18n.get(`productKey`, 'código del producto')
          }
          helpTooltip={renderProductKeyTooltip(country)}
          menuPosition='absolute'
        />
      )}

      {/* {country === 'mexico' && (
        <Field
          name="predial"
          className={isElectronic ? "col-sm-6" : "col-sm-3"}
          component={renderField}
          type="text"
          label={capitalize(I18n.get('tariffHeadings', 'Predial'))}
        />
      )} */}

      {/* {(country === 'peru' && isElectronic) && (
        <Field
          name="description"
          component={renderField}
          className="col-12"
          type="textarea"
          label={capitalize(I18n.get('description', 'descripción'))}
          required={country === 'peru' && isElectronic}
        />
      )} */}
    </>
  );
};

export default BasicData;

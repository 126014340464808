import React from 'react';
import { useDispatch } from 'react-redux';

import { openModal } from '../../../reducers/modals';

const LogoutComponent = ({ children, ...props }) => {
  const dispatch = useDispatch();

  return (
    <div 
      {...props}
      data-testid="logout-component"
      onClick={() => dispatch(openModal({ modal: 'logoutConfirm' }))}
    >
      {children}
    </div>
  )
}

export default LogoutComponent;
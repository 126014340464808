import { useSelector } from 'react-redux';
import { I18n } from '@aws-amplify/core';
import parse from 'html-react-parser';

import {
  country as countrySelector,
  decimalPrecision,
} from '../../../selectors/company';

import Typography from '../../../components/common/Typography';
import { useGlobalInvoice } from './context';
import { formatCurrencyMoney } from '../../../utils/format-money';

function GlobalInvoiceFooter() {
  const { selectedRows } = useGlobalInvoice();
  const country = useSelector(countrySelector);
  const decimal = useSelector(decimalPrecision);

  const handleSelectedRowsPrice = () => {
    let [subtotal, discount] = [0, 0];

    if (Array.isArray(selectedRows) && selectedRows.length > 0) {
      selectedRows.forEach((row) => {
        subtotal += Number(row?.total || 0);
      });
    }

    return { subtotal, discount, total: subtotal - discount };
  };

  return (
    <div className='global-invoices-detail-footer'>
      <Typography
        type='caption-regular'
        variant='tertiary'
        text={parse(
          I18n.get(
            'fieldsMarkedRequired',
            'Los campos marcados con <span class="text-primary">*</span> son obligatorios'
          )
        )}
      />
      <div className='global-invoices-detail-resume'>
        <div className='d-flex justify-content-between'>
          <Typography type='caption-bold' text='Subtotal' variant='secondary' />
          <Typography
            type='body-3-bold'
            text={formatCurrencyMoney(
              handleSelectedRowsPrice().subtotal,
              country,
              decimal
            )}
            variant='secondary'
          />
        </div>
        <div className='d-flex justify-content-between'>
          <Typography
            type='caption-bold'
            text='Descuento'
            variant='secondary'
          />
          <Typography
            type='body-3-bold'
            text={formatCurrencyMoney(
              handleSelectedRowsPrice().discount,
              country,
              decimal
            )}
            variant='secondary'
          />
        </div>
        <div className='dashed-line' />
        <div className='d-flex justify-content-between'>
          <Typography type='body-2-bold' text='Total' variant='secondary' />
          <Typography
            type='body-1-bold'
            text={formatCurrencyMoney(
              handleSelectedRowsPrice().total,
              country,
              decimal
            )}
            variant='secondary'
          />
        </div>
      </div>
    </div>
  );
}

export default GlobalInvoiceFooter;

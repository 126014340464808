export const baseKindOfPerson = [
  {
    "id": "1",
    "value": "Persona jurídica",
    "key": "LEGAL_ENTITY"
  },
  {
    "id": "2",
    "value": "Persona natural",
    "key": "PERSON_ENTITY"
  },
]

const kindOfPerson = (identificationType) => 
  identificationType !== "FINAL_CONSUMER" ? (baseKindOfPerson) : (
    baseKindOfPerson.filter((option) => option.key !== 'LEGAL_ENTITY').concat([
      {
        "id": "3",
        "value": "Ninguno",
        "key": "PERSON_EMPTY"
      }
    ])
  )

export default kindOfPerson;
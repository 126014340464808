import { pick } from 'lodash';
import { isEqual } from 'lodash';
import { keys } from 'lodash';
import { difference } from 'lodash';

export function getObjectDifference(obj1, obj2) {
  const keysDifference = difference(keys(obj1), keys(obj2));
  const differenceObject = pick(obj1, keysDifference);

  return differenceObject;
}

export function getObjectValueDifferences(obj1, obj2) {
  const differingValues = {};

  keys(obj1).forEach((key) => {
    if (!isEqual(obj1[key], obj2[key])) {
      differingValues[key] = obj2[key];
    }
  });

  return differingValues;
}

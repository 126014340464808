import { useMemo } from 'react';

/**
 * Custom hook to simplify numeration selection.
 *
 * @param {Array} numerations - Array of numeration objects.
 * @returns {Object|null} The simplified numeration object or null.
 */
const useSimplifiedNumeration = (numerations) => {
  return useMemo(() => {
    if (!Array.isArray(numerations)) return null;

    const numerationDefault = numerations.find(
      (numeration) =>
        numeration.documentType === 'saleTicket' && numeration.isDefault
    );
    return (
      numerationDefault ||
      numerations.find((numeration) => numeration.documentType === 'saleTicket') ||
      null
    );
  }, [numerations]);
};

export default useSimplifiedNumeration;

import { Icon } from '@alegradev/smile-ui-react';
import Typography from '../Typography';
import Button from '../Button';

function Empty({ icon, title, description, buttonText, buttonAction }) {
  return (
    <div className='empty-state'>
      {icon && <Icon icon={icon} />}
      {title && <Typography type='heading-3' variant='primary' text={title} />}
      {description && (
        <Typography
          type='body-3-regular'
          variant='secondary'
          text={description}
        />
      )}
      {buttonText && (
        <Button variant='outline' onClick={buttonAction}>
          <Typography type='label-1' variant='primary' text={buttonText} />
        </Button>
      )}
    </div>
  );
}

export default Empty;

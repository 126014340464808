import alegraAPI from "../../../reducers/alegraAPI";
import { useQuery } from "@tanstack/react-query";
import { transformFromAPI } from "../../forms/inventoryAdjustment/utils";

const inventoryAdjustmentsKeys = {
  all: 'inventoryAdjustments',
  single: 'inventoryAdjustment'
}

const fetchInventoryAdjustments = async (filters) => {
  const response = await alegraAPI.get("/inventory-adjustments", {
    ...filters,
  });

  return response.data;
}

const fetchSingleInventoryAdjustment = async (id) => {
  const fields = "items.inventory,editable"
  const response = await alegraAPI.get(`/inventory-adjustments/${id}?fields=${fields}`)

  return response.data;
}

export const useInventoryAdjustmentsQuery = (filters) => {
  return useQuery({
    queryKey: [inventoryAdjustmentsKeys.all, filters],
    queryFn: () => fetchInventoryAdjustments(filters),
    keepPreviousData: true,
    staleTime: 1000 * 60 * 60 * 24,
    refetchOnWindowFocus: false,
    refetchOnReconnect: true,
  })
};

export const useSingleInventoryAdjustmentQuery = (id) => {
  return useQuery({
    queryKey: [inventoryAdjustmentsKeys.single, { id }],
    queryFn: () => fetchSingleInventoryAdjustment(id),
    refetchOnWindowFocus: false,
    refetchOnReconnect: true,
    staleTime: 1000 * 60 * 60 * 24,
    select: transformFromAPI
  })
} 
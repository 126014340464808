import { createSlice } from '@reduxjs/toolkit';
import { graphqlOperation } from '@aws-amplify/api';
import { get, set, has, isNumber } from 'lodash';
import { BigNumber } from 'bignumber.js';
import dayjs from 'dayjs'

import * as mutations from '../graphql/mutations'
import {
  timezone as tzSelector,
  country as countrySelector,
  electronicInvoicing as companyElectronicInvoicing,
} from '../selectors/company'
import {
  station as stationSelector,
  stationRefundNumeration,
  electronicRefund,
  APIGraphqlSelector,
} from '../selectors/app'
import { isRefundNumerationActive, getNumerationById } from '../selectors/numerations';
import { refundTransformer } from '../utils/transformers'
// import { changeItemInventory, refresh } from './items'
import { calcultateSaleConcept, isDefaultClient } from './activeInvoice'
import { discSubtotal as subtotalSelector } from '../selectors/activeRefund';
import { updateCompany } from './auth';
import { getMainCurrency } from '../selectors/currencies';
import { sendGTMEvent } from './company';
import { getInitialState } from '../utils';
import { COUNTRIES } from '../utils/enums/countries';
import { queryClient } from '../utils/queryClient';

const initialState = {
  items: [],
  itemsDiscarded: [],
  client: null,
  priceList: null,
  currency: null,
  isSelectingItems: true,
  isAllItemsPupaleted: false,
  tip: null,
  version: 1
}

const findIndex = (items = [], id) => items
  .findIndex(item => get(item, 'id') === id && !get(item, 'modified'));

const updateItemPrice = (item, priceList, currency) => {
  let price = get(item, 'originalPrice', 0)

  if (!get(item, 'priceModified') && !!priceList) {
    const itemPriceList = get(item, 'priceLists').find(list => +get(list, 'idPriceList') === +get(priceList, 'id'))
    if (!!itemPriceList)
      price = get(itemPriceList, 'price');
  }

  if (!!currency) {
    const exchangeRate = !!get(currency, 'exchangeRate') ? +get(currency, 'exchangeRate') : 1;
    price = new BigNumber(price).dividedBy(new BigNumber(exchangeRate)).decimalPlaces(4).toNumber()
  }

  const itemTax = !!get(item, 'tax', []) ? get(item, 'tax', []) : [];

  return { ...item, price, tax: itemTax }
}

export const updateItemByEvent = item => {
  return (dispatch, getState) => {
    const items = get(getState(), 'activeRefund.items')
    const priceList = get(getState(), 'activeRefund.priceList')
    const currency = get(getState(), 'activeRefund.currency')

    const index = findIndex(items, item.id);

    if (index >= 0)
      dispatch(updateItem({
        index, values: updateItemPrice({
          ...item,
          originalPrice: get(item, 'price.0.price', 0),
          price: get(item, 'price.0.price', 0),
          priceLists: get(item, 'price'),
        }, priceList, currency)
      }))
  }
}

export const removeItemByEvent = id => {
  return (dispatch, getState) => {
    const items = get(getState(), 'activeRefund.items')

    const index = findIndex(items, id);

    if (index >= 0)
      dispatch(removeItem(index))
  }
}

export const updateClientByEvent = client => {
  return (dispatch, getState) => {
    const currentClientId = get(getState(), 'activeRefund.client.id')

    if (get(client, 'id') === currentClientId)
      dispatch(setClient(client))
  }
}

export const removeClientByEvent = id => {
  return (dispatch, getState) => {
    const currentClientId = get(getState(), 'activeRefund.client.id')

    if (id === currentClientId)
      dispatch(setClient(null))
  }
}

export const updateNumerationByEvent = numeration => {
  return (dispatch, getState) => {
    const currentNumerationtId = get(getState(), 'activeRefund.numeration.id')

    if (get(numeration, 'id') === currentNumerationtId)
      dispatch(setNumeration(numeration))
  }
}

export const removeNumerationByEvent = id => {
  return (dispatch, getState) => {
    const currentNumerationtId = get(getState(), 'activeRefund.numeration.id')

    if (id === currentNumerationtId)
      dispatch(setNumeration(null))
  }
}

const appSlice = createSlice({
  name: 'activeRefund',
  initialState: getInitialState('activeRefund', initialState),
  reducers: {
    setClient: (state, action) => {
      const priceList = !!get(action, 'payload.priceList', null) ? get(action, 'payload.priceList') : state.priceList
      return {
        ...state,
        client: action.payload,
        priceList,
        items: state.items.map(item => updateItemPrice(item, priceList, state.currency))
      }
    },
    setNumeration: (state, action) => {
      return {
        ...state,
        numeration: action.payload
      }
    },
    setSettings: (state, action) => {
      const newState = {
        ...state,
        ...action.payload
      }

      return {
        ...newState,
        items: state.items.map(item => updateItemPrice(item, newState.priceList, newState.currency))
      }
    },
    populateItem: (state, action) => {
      state.items.push(updateItemPrice({
        ...action.payload,
        originalPrice: get(action, 'payload.price.0.price', 0),
        price: get(action, 'payload.price.0.price', 0),
        priceLists: get(action, 'payload.price'),
        quantity: get(action, 'payload.quantity', 1)
      }, state.priceList, state.currency));
    },
    addItem: (state, action) => {
      const index = findIndex(state.items, action.payload.id);
      const quantity = index >= 0 ? get(state, `items.${index}.quantity`, 0) : 0;

      if (index < 0) {
        state.items.push(updateItemPrice({
          ...action.payload,
          originalPrice: get(action, 'payload.price.0.price', 0),
          price: get(action, 'payload.price.0.price', 0),
          priceLists: get(action, 'payload.price'),
          quantity: quantity + 1
        }, state.priceList, state.currency));
      } else {
        set(state, `items.${index}.quantity`, quantity + 1)
      }
    },
    addDiscardedItem: (state, action) => {
      const item = {
        ...action.payload,
        originalPrice: get(action, 'payload.price.0.price', 0),
        price: [{ price: get(action, 'payload.price', 0) }],
        discount: get(action, 'payload.discount', 0),
        tax: get(action, 'payload.tax', []),
      }
      state.itemsDiscarded.push(item)
    },
    clearDiscardedItems: (state, action) => {
      const items = get(state, 'items', [])
      const itemsDiscarded = get(state, 'itemsDiscarded', [])
      const itemsDiscardedFiltered = itemsDiscarded.filter(itemDiscarded => {
        return !items.find(item => item.id === itemDiscarded.id)
      })
      state.itemsDiscarded = itemsDiscardedFiltered
    },
    removeItem: (state, action) => {
      state.items.splice(action.payload, 1);
    },
    removeDiscardedItem: (state, action) => {
      state.itemsDiscarded.splice(action.payload, 1);
    },
    increaseItem: (state, action) => {
      const quantity = +get(state, `items.${action.payload}.quantity`);
      set(state, `items.${action.payload}.quantity`, quantity + 1)
    },
    decreaseItem: (state, action) => {
      const quantity = +get(state, `items.${action.payload}.quantity`);
      set(state, `items.${action.payload}.quantity`, quantity > 1 ? quantity - 1 : 1)
    },
    setTip: (state, action) => {
      set(state, 'tip', action.payload)
    },
    updateItem: (state, action) => {
      // if (has(action, 'payload.values.quantity') && isNumber(action.payload.values.quantity))
      //   action.payload.values.quantity = action.payload.values.quantity.toFixed(2);

      set(state, `items.${action.payload.index}`, {
        ...get(state, `items.${action.payload.index}`),
        ...action.payload.values
      })
    },
    updateItemStatusById: (state, action) => {
      const index = findIndex(state.items, action.payload.id);
      set(state, `items.${index}.status`, action.payload.status)
    },
    setIsSelectingItems: (state, action) => {
      set(state, 'isSelectingItems', action.payload)
    },
    updateIsAllItemsPopulated: (state, action) => {
      set(state, 'isAllItemsPupaleted', action.payload)
    },
    clear: () => {
      return initialState
    },
  },
});

const { actions, reducer } = appSlice;

export const {
  setClient,
  setNumeration,
  setSettings,
  populateItem,
  addItem,
  addDiscardedItem,
  removeItem,
  removeDiscardedItem,
  increaseItem,
  decreaseItem,
  updateItem,
  setIsSelectingItems,
  updateIsAllItemsPopulated,
  clear,
  clearDiscardedItems,
  updateItemStatusById,
  setTip,
} = actions;

export default reducer;

/**
 * Strategy for handling refund transformations based on the country.
 */
const countryStrategies = {
  COLOMBIA: (refund, { isElectronic }) => {
    if (isElectronic) {
      return {
        ...refund,
        stamp: { generateStamp: true },
      };
    }
    return refund;
  },
  ARGENTINA: (refund, { isElectronic, isCompanyElectronic }, today, state) => {
    if (isCompanyElectronic) {
      const saleConcept = calcultateSaleConcept(refund);
      const dateService = ['PRODUCTS_SERVICES', 'SERVICES'].includes(saleConcept);
      refund = {
        ...refund,
        saleConcept,
        startDateService: dateService ? today.format('YYYY-MM-DD') : null,
        endDateService: dateService ? today.format('YYYY-MM-DD') : null,
      };
    }

    // Temporary fix for electronic refunds in Argentina
    // if (isElectronic && refund.invoices?.length > 0) {
    //   const invoice = refund.invoices[0];
    //   const invoiceNumeration = getNumerationById(invoice?.numberTemplate?.id)(state);
    //   const refundNumeration = refund.numberTemplate;

    //   if (
    //     invoiceNumeration?.isElectronic &&
    //     refundNumeration?.isElectronic &&
    //     invoiceNumeration?.subDocumentType?.match(/_(A|B|C)$/)
    //   ) {
    //     refund = {
    //       ...refund,
    //       stamp: { generateStamp: true },
    //       saleCondition: invoice.saleCondition ?? null,
    //       saleConcept: invoice.saleConcept ?? null,
    //       startDateService: invoice.startDateService ?? null,
    //       endDateService: invoice.endDateService ?? null,
    //     };
    //   }
    // }

    return refund;
  },
  DEFAULT: (refund) => refund,
};

/**
 * Transforms the refund values into a structured object for processing.
 *
 * @param {Object} values - The form values.
 * @returns {Function} A thunk function that transforms the refund based on the current state.
 */
const prepareRefund = (values) => {
  return (dispatch, getState) => {
    const state = getState();
    const station = stationSelector(state);
    const today = dayjs(new Date()).tz(tzSelector(state));
    const includeNumeration = isRefundNumerationActive(state);
    const subtotal = subtotalSelector(state);
    const activeRefund = state.activeRefund;
    const numberTemplate = activeRefund.numeration ?? stationRefundNumeration(state);
    const country = countrySelector(state);
    const isElectronic = electronicRefund(numberTemplate)(state);
    const isCompanyElectronic = companyElectronicInvoicing(state);
    const mainCurrency = getMainCurrency(state);
    const additionalCharges = values?.additionalCharges?.map(charge => ({ ...charge })) || [];

    // Initial refund object construction
    let refund = {
      idStation: station.id,
      ...activeRefund,
      date: today.format('YYYY-MM-DD'),
      ...values,
      additionalCharges,
      refunds: values?.refunds?.map(ref => ({ ...ref, date: today.format('YYYY-MM-DD') })) || [],
      numberTemplate: includeNumeration ? numberTemplate : null,
      costCenter: station.idCostCenter ?? null,
      warehouse: station.idWarehouse ?? null,
      subtotal,
    };

    // Apply country-specific transformations
    const strategy = countryStrategies[country] || countryStrategies.DEFAULT;
    refund = strategy(refund, { isElectronic, isCompanyElectronic }, today, state);

    // Validate currency and compare with mainCurrency
    if (refund.currency?.code === mainCurrency.code) {
      refund.currency = null;
    }

    return refundTransformer(refund);
  };
};

// const trackCreatedRefund = companyId => {
//   window.dataLayer.push({
//     event: 'eventActivity',
//     eventActivityAction: 'refund / add / *',
//     eventActivityLabel: companyId,
//   });
// }

export const createRefund = props => {
  return async (dispatch, getState) => {
    if (!!get(props, 'numeration', null)) {
      dispatch(setNumeration(props.numeration))
    }

    let refund = dispatch(prepareRefund(props))
    const country = countrySelector(getState())
    const APIGraphql = APIGraphqlSelector(getState());
    const response = await APIGraphql(graphqlOperation(mutations.createRefund, {
      refund
    }))



    if (Array.isArray(get(response, 'data.createRefund.invoices'))) {
      get(response, 'data.createRefund.invoices').forEach(invoice => {
        queryClient.invalidateQueries({ queryKey: ['invoice', { id: invoice.id }] });
      });
    }

    const getReturnType = (response) => {
      if (!!get(response, "data.createRefund.balance"))
        return "positiveBalance"
      if (!get(response, "data.createRefund.refunds.length"))
        return "cashToInvoice"
      if (!get(response, "data.createRefund.invoices.length"))
        return "refund"

      return "combined"
    }

    if (!!get(response, "data"))
      dispatch(sendGTMEvent("new-return-created", {
        returnType: getReturnType(response),
        typeOfCreditNote: get(response, "data.createRefund.type"),
        clientIsConfigured: isDefaultClient(get(response, "data.createRefund.client"), country),
        hasObservations: !!get(response, "data.createRefund.refunds.0.observations"),
      }))

    if (has(response, 'data.createRefund.monthIncome'))
      dispatch(updateCompany({ monthIncome: get(response, 'data.createRefund.monthIncome') }));

    return get(response, 'data.createRefund')
  }
}
import React, { useRef } from 'react';
import { Switch, Route, Redirect, useLocation } from 'react-router-dom';

import NewItem from './NewItem/NewItem';
import EditItem from './EditItem/EditItem';

const Items = () => {
  const location = useLocation();
  const containerRef = useRef();

  React.useEffect(() => {
    containerRef.current.scrollTo({ top: 0 });
  }, [location]);

  return (
    <div ref={containerRef} className="h-100 w-100 container">
      <Switch>
        <Route path="/items/add" component={NewItem}/>
        <Route path="/items/edit/:id" component={EditItem} />
        <Redirect to="/" />
      </Switch>
    </div>
  )
}

export default Items;
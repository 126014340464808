const ivaConditions = [
   {
      "id":"1",
      "value":"IVA responsable inscripto",
      "key":"IVA_RESPONSABLE"
   },
   {
      "id":"2",
      "value":"IVA responsable no inscripto",
      "key":"IVA_RESPONSABLE_NO_INSCRIPT"
   },
   {
      "id":"3",
      "value":"IVA sujeto exento",
      "key":"IVA_EXEMPT"
   },
   {
      "id":"4",
      "value":"Consumidor final",
      "key":"FINAL_CONSUMER"
   },
   {
      "id":"5",
      "value":"Responsable monotributo",
      "key":"UNIQUE_TRIBUTE_RESPONSABLE"
   },
   {
      "id":"6",
      "value":"Proveedor del exterior",
      "key":"PROVIDER_FROM_EXTERIOR"
   },
   {
      "id":"7",
      "value":"Cliente del exterior",
      "key":"CLIENT_FROM_EXTERIOR"
   },
   {
      "id":"8",
      "value":"IVA Liberado - Ley 19.640",
      "key":"IVA_RELEASED_LAW_19640"
   },
   {
      "id":"9",
      "value":"Monotributista Social",
      "key":"UNIQUE_TRIBUTE_SOCIAL"
   },
   {
      "id":"10",
      "value":"IVA No alcanzado",
      "key":"IVA_NOT_REACHED"
   }
];

export default ivaConditions;
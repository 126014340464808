import { Grid } from '@alegradev/smile-ui-react';
import { Field, useForm } from 'react-final-form';
import { I18n } from 'aws-amplify';
import { useEffect, useMemo, useState } from 'react';
import { renderField } from '../../../../components/forms/fields/V2';
import countries from '../../../../components/countriesData/general/countries';
import { useSelector } from 'react-redux';
import {
  companySelector,
  country as countrySelector,
} from '../../../../selectors/company';
import { COUNTRIES_PHONE_PREFIXES } from '../../../../utils/enums/countries';
import ContactPortalFooter from './Footer';

function ContactPortalWhatsappMethod({ contact, modalName, errors }) {
  const ALEGRA_PORTAL_LINK = contact?.statementLink
    ? contact.statementLink
    : process.env.REACT_APP_ALEGRA_PORTAL || 'https://portal.alegra.com';
  const [searchValue, setSearchValue] = useState('');
  const company = useSelector(companySelector);
  const country = useSelector(countrySelector);

  const { Col, Row } = Grid;

  const form = useForm();

  useEffect(() => {
    form.change('phonePrefix', COUNTRIES_PHONE_PREFIXES[country.toUpperCase()]);
    if (contact?.mobile || contact?.phonePrimary || contact?.phoneSecondary) {
      form.change(
        'phone',
        contact?.mobile || contact?.phonePrimary || contact?.phoneSecondary
      );
    }
    form.change('contact', contact);
    form.change(
      'message',
      `Hola, un feliz día. 😉\n\nPuedes consultar y gestionar las facturas generadas por *{{companyName}}* a nombre de *{{clientName}}* en: ${ALEGRA_PORTAL_LINK}\n\nSi tienes dudas puedes responder a este mensaje o escribir al correo *{{companyEmail}}*`
        .replace('{{companyName}}', company?.name || 'Default')
        .replace('{{companyEmail}}', company?.email || 'default@default.com')
        .replace('{{clientName}}', contact?.name || 'Cliente')
    );
  }, []);

  const countryOptions = useMemo(() => {
    if (searchValue) {
      const filteredCountries = countries.filter((country) => {
        if (!isNaN(Number(searchValue))) {
          return country.dialCode.includes(searchValue);
        }

        return country.value.toLowerCase().includes(searchValue.toLowerCase());
      });

      if (filteredCountries.length > 0) {
        return filteredCountries;
      }
    }

    return countries;
  }, [searchValue]);

  return (
    <>
      <Row spacing='lg'>
        <Col xs={7} sm={12}>
          <Field
            name='phone'
            render={renderField}
            type='number'
            leadingAddOn={{
              defaultOption: COUNTRIES_PHONE_PREFIXES[country.toUpperCase()],
              width: 80,
              search: {
                enabled: true,
                onSearch: (value) => setSearchValue(value),
              },
              onChange: (value) => form.change('phonePrefix', value?.value),
              options: countryOptions.map((country) => ({
                value: country.dialCode,
                text: country.dialCode,
                overflowText: `${country.value} (${country.dialCode})`,
                leading: {
                  type: 'slot',
                  slot: (
                    <span
                      className={`fi fi-${country.flag}`}
                      style={{ fontSize: 16 }}
                    />
                  ),
                },
              })),
            }}
            required
            placeholder={I18n.get('phone', 'Teléfono')}
            label={I18n.get('phone', 'Teléfono')}
          />
        </Col>
        <Col xs={12}>
          <Field
            name='message'
            render={renderField}
            fieldType='textarea'
            required
            placeholder={I18n.get('message', 'Mensaje')}
            label={I18n.get('message', 'Mensaje')}
          />
        </Col>
      </Row>
      <ContactPortalFooter
        errors={errors}
        contact={contact}
        modalName={modalName}
        method='whatsapp'
      />
    </>
  );
}

export default ContactPortalWhatsappMethod;

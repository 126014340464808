import React from 'react'
import { I18n } from '@aws-amplify/core';
import { graphqlOperation } from '@aws-amplify/api';
import { Form } from 'react-final-form'
import { useDispatch, useSelector } from 'react-redux'
import { get } from 'lodash'

import * as mutations from '../../../../graphql/mutations'
import { addItemCategory } from '../../../../reducers/itemCategories'
import { toast, replaceAndParse } from '../../../../utils'
import { formError } from '../../../../utils/errors'
import Body from '../../../forms/itemCategory/ItemCategory'
import { validate, transform } from '../../../forms/itemCategory/utils'
import Notification from '../../common/Notification'
import Bottom from '../../common/Bottom'
import Modal from '../common/Modal'
import { APIGraphqlSelector } from '../../../../selectors/app';

const ItemCategory = ({ isOpen, onRequestClose, onCreated }) => {
  const dispatch = useDispatch()
  const APIGraphql = useSelector(APIGraphqlSelector);
  
  const submit = async values => {
    const transformedValues = transform(values)

    try {
      const response = await APIGraphql(graphqlOperation(mutations.createItemCategory, {
        itemCategory: transformedValues
      }))

      const itemCategory = !!get(response, 'data.createItemCategory') ? get(response, 'data.createItemCategory'): null

      if (!!itemCategory) {
        dispatch(addItemCategory(itemCategory))
        if (!!onCreated)
          onCreated(itemCategory)
      }
      
      onRequestClose();
      toast.success({ 
        title: `${I18n.get('itemCategoryCreatedSuccessfully', 'categoría de producto creada con éxito')} 😎`,
        subtitle: replaceAndParse(I18n.get('itemCategoryCreatedSuccessfullyMessage', 'La categoría {} ya está disponible para que clasifiques tus productos.'), [
          `<span class="font-weight-bold">${get(itemCategory, 'name', '')}</span>`
        ]), 
      })
    } catch (error) {
      return formError(error, I18n.get('createPaymentTermError', 'hubo un error en la creación de la categoría de producto'))
    }
  }

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      title={I18n.get('newCategory', 'nueva categoría')}
    >
      <Form
        onSubmit={submit}
        validate={(values) => validate(values)}
      >
        {({ handleSubmit, form, submitting, submitError }) => (
          <form 
            noValidate
            className="w-100 h-100 bg-white d-flex flex-column"
            onSubmit={handleSubmit}
          >
            <div className="side-modal__body">
              <Body form={form}/>
            </div>

            <Notification
              isOpen={!!submitError}
              type="error"
              text={submitError}
            />

            <Bottom
              onClose={() => onRequestClose()} 
              disabled={submitting} 
              submitting={submitting}
            />
          </form>
        )}
      </Form>
    </Modal>
  )
}

export default ItemCategory;
import { contactsKeys } from './utils';

export function handleClientSubscription(client, type = 'create', queryClient) {
  try {
    if (type === 'create') {
      queryClient.setQueriesData([contactsKeys.all], (oldData) => {
        return {
          data: [client, ...(oldData?.data || [])],
          metadata: {
            ...oldData.metadata,
            total: oldData.metadata.total + 1,
          },
        };
      });
    } else if (type === 'update') {
      queryClient.setQueriesData(contactsKeys.all, (items) => {
        if (Array.isArray(items?.data) && items?.data.length > 0) {
          return {
            ...items,
            data: items.data.map((item) => {
              if (item.id === client.id) {
                return {
                  ...item,
                  ...client,
                };
              }
              return item;
            }),
          };
        }
      });
    } else if (type === 'delete') {
      queryClient.setQueriesData([contactsKeys.all], (old) => ({
        data: old.data.filter((od) => od.id !== client.id),
        metadata: {
          ...old.metadata,
          total: old.metadata.total - 1,
        },
      }));
    }
  } catch (_) {}
}

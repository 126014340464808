import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form } from 'react-final-form';
import { I18n } from '@aws-amplify/core';
import { get } from 'lodash';

import { clear, createRefund, setClient } from '../../../reducers/activeRefund';
import { fetchRefunds } from '../../../reducers/refunds';
import { closeModal } from '../../../reducers/modals';
import { checkStationValues, getPOSClient } from '../../../reducers/app';
import {
  itemsCount as itemsCountSelector,
  total as totalSelector,
  client as clientSelector,
} from '../../../selectors/activeRefund';
import {
  country as countrySelector,
  electronicInvoicing,
  shiftsEnabled as shiftsEnabledSelector,
} from '../../../selectors/company';
import { stationCashBank as stationCashBankSelector } from '../../../selectors/app';
import { paymentsLoading as shiftPaymentsLoading } from '../../../selectors/shifts';
import { getShiftPayments } from '../../../reducers/shifts';
import {
  availableCash,
  isOpen as shiftOpenSelector,
} from '../../../selectors/shifts';
import { formError, handleError } from '../../../utils/errors';
import { toast } from '../../../utils';
import { validate, transform } from '../../forms/refund/utils';
import Modal from '../../common/Modal';
import Bottom from '../common/WizardBottom';
import Notification from '../common/Notification';
import ItemsClient from './WizardSteps/ItemsClient';
import Payments from './WizardSteps/Payments';
import { sendNewGTMEvent } from '../../../reducers/company';
import NewRefunds from '../newRefunds/NewRefunds';
import useSendGTMEvent from '../../../hooks/useSendGtmEvent/hook';

const getInvoiceType = (invoice) => {
  if (!invoice) return '';
  if (get(invoice, 'numberTemplate.documentType') === 'saleTicket') {
    const isElectronic = get(invoice, 'numberTemplate.isElectronic', false);
    return isElectronic ? 'docPOS' : 'saleTicket';
  }
  
  return get(invoice, 'numberTemplate.documentType', '');
}

const OldRefunds = () => {
  const isOpen = useSelector((state) =>
    get(state, 'modals.refunds.isOpen', false)
  );
  useSendGTMEvent(isOpen, 'pos-refund-started');
  const itemsCount = useSelector(itemsCountSelector);
  const client = useSelector(clientSelector);
  const total = useSelector(totalSelector);
  const country = useSelector(countrySelector);
  const isElectronic = useSelector(electronicInvoicing);
  const shiftOpen = useSelector(shiftOpenSelector);
  const shiftsEnabled = useSelector(shiftsEnabledSelector);
  const shiftCash = useSelector(availableCash);
  const stationCashBank = useSelector(stationCashBankSelector);
  const loadingShiftPayments = useSelector(shiftPaymentsLoading);
  const dispatch = useDispatch();
  const [step, setStep] = useState(0);
  const [isOpenSettings, setIsOpenSettings] = useState(false);
  const [isOpenEditItem, setIsOpenEditItem] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(-1);

  useEffect(() => {
    if (!isOpen) {
      setStep(0);
      setIsOpenSettings(false);
      setIsOpenEditItem(false);
      dispatch(clear());
    } else if (shiftsEnabled && shiftOpen) dispatch(getShiftPayments());
  }, [isOpen, shiftsEnabled, shiftOpen, dispatch]);

  const previuosStep = (form) => {
    if (step === 1) form.reset();
    setStep(Math.max(step - 1, 0));
  };

  const resetSteps = (form) => {
    form.reset();
    setStep(0);
  };

  const nextStep = async () => {
    if (step === 0 && !client) {
      const posClient = await dispatch(getPOSClient());
      if (!!posClient) dispatch(setClient(posClient));
      setStep(step + 1);
    } else setStep(step + 1);
  };

  const submit = async (values) => {
    try {
      const canContinue = await dispatch(
        checkStationValues({ type: 'refund' })
      );
      if (!canContinue) return;

      const transformedValues = transform(values, { isElectronic, country });

      await dispatch(createRefund(transformedValues));
      dispatch(closeModal({ modal: 'refunds' }));
      toast.success({
        title: I18n.get(
          'refundCreatedSuccessfully',
          'devolución creada con éxito'
        ),
      });
      dispatch(
        sendNewGTMEvent('pos-refund-created', {
          error: 'no error',
          type: get(values, 'method', ''),
          creditNoteType: get(transformedValues, 'type', ''),
          invoiceType: getInvoiceType(get(transformedValues, 'invoices[0]', false)),
        })
      );
      dispatch(fetchRefunds());
    } catch (error) {
      const parseError = handleError(error);
      dispatch(
        sendNewGTMEvent('pos-refund-created', {
          error: parseError,
        })
      );
      return formError(
        error,
        I18n.get(
          'createRefundError',
          'hubo un error a la hora de crear la devolución'
        )
      );
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={() => dispatch(closeModal({ modal: 'refunds' }))}
      title={I18n.get('newRefund', 'nueva devolución')}
      className='modal__refunds'
    >
      <Form
        onSubmit={submit}
        validate={(values) =>
          validate(values, {
            step,
            total,
            country,
            isElectronic,
            shiftCash,
            shiftsEnabled,
            shiftOpen,
            stationCashBank,
          })
        }
      >
        {({ handleSubmit, form, values, submitError, errors, submitting }) =>
          step !== 1 ? (
            <form noValidate className='form-body' onSubmit={handleSubmit}>
              <div className='d-flex flex-column p-3'>
                <Payments
                  form={form}
                  values={values}
                  client={client}
                  country={country}
                  totalPrice={total}
                  step={step}
                  onBack={() => resetSteps(form)}
                />
              </div>

              <Notification isOpen={!!submitError} text={submitError} />

              <Bottom
                submitting={submitting || loadingShiftPayments}
                onPrevious={step === 1 ? () => previuosStep(form) : null}
                onNext={() => nextStep()}
                nextDisabled={!!Object.keys(errors).length}
                nextAsSubmit={step > 1}
                nextText={step > 1 ? I18n.get('save', 'guardar') : null}
              />
            </form>
          ) : (
            <>
              <div className='d-flex flex-column p-3'>
                <ItemsClient
                  isOpenSettings={isOpenSettings}
                  isOpenEditItem={isOpenEditItem}
                  selectedIndex={selectedIndex}
                  setIsOpenSettings={setIsOpenSettings}
                  setIsOpenEditItem={setIsOpenEditItem}
                  setSelectedIndex={setSelectedIndex}
                />
              </div>

              <Notification isOpen={!!submitError} text={submitError} />

              <Bottom
                submitting={submitting}
                onPrevious={step === 1 ? () => previuosStep(form) : null}
                onNext={() =>
                  values.method === 'positiveBalance'
                    ? form.submit()
                    : nextStep()
                }
                nextDisabled={
                  (step === 1 && !itemsCount) || !!Object.keys(errors).length
                }
                nextText={
                  values.method === 'positiveBalance'
                    ? I18n.get('save', 'guardar')
                    : null
                }
              />
            </>
          )
        }
      </Form>
    </Modal>
  );
};

const Refunds = () => (
  <>
    <NewRefunds />
    <OldRefunds />
  </>
)

export default Refunds;

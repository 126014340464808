const voidInvoiceCause = [
  {
      id: "01",
      value: 'Deterioro de factura pre-impresa',
  },
  {
    id: "02",
    value: 'Errores de impresión (factura pre-impresa)',
  },
  {
    id: "03",
    value: 'Impresión defectuosa'
  },
  {
    id: "04",
    value: 'Duplicidad de factura',
  },
  {
    id: "05",
    value: 'Corrección de la información',
  },
  {
    id: "06",
    value: 'Cambio de productos',
  },
  {
    id: "07",
    value: 'Devolución de productos',
  },
  {
    id: "08",
    value: 'Omisión de productos',
  },
  {
    id: "09",
    value: 'Errores en secuencia de ncf',
  },
  {
    id: "9",
    value: 'Por cese de operaciones',
  },
  {
    id: "10",
    value: 'Pérdida o hurto de talonario(s)',
  },
]

export default voidInvoiceCause;
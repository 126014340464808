import React, { useEffect, useCallback, useState } from 'react'
import { I18n } from '@aws-amplify/core'
import { useDispatch, useSelector } from 'react-redux'
import dayjs from 'dayjs'

import notificationsAPIV2 from '../../reducers/notificationsAPIV2'
import { userSelector } from '../../selectors/auth'
import { country as countrySelector, notificationsSelector } from '../../selectors/company';
import { APIGraphqlSelector } from '../../selectors/app'
import { graphqlOperation } from 'aws-amplify'
import { updateUser } from '../../graphql/mutations'
import { notificationsTransformer } from './utils'
import { setUser } from '../../reducers/auth'
import { isElectronicPOSDocumentAvailableSelector } from '../../selectors/numerations'
import { get } from 'lodash'

const ElectronicPOSDocumentTopNotification = () => {
  const dispatch = useDispatch();
  const APIGraphql = useSelector(APIGraphqlSelector);
  const user = useSelector(userSelector);
  const country = useSelector(countrySelector);
  const notifications = useSelector(notificationsSelector);
  const isElectronicPOSDocumentAvailable = useSelector(isElectronicPOSDocumentAvailableSelector);
  const planName = get(user, 'membership.planKeyword', null)

  const [notificationSent, setNotificationSent] = useState(false);

  const isNotificationSent = () => {
    return sessionStorage.getItem('electronicPOSTopNotificationSent') === 'true';
  };

  const setNotificationSentInSessionStorage = () => {
    sessionStorage.setItem('electronicPOSTopNotificationSent', 'true');
  };

  const selectedBody = useCallback(() => {
    if (planName === 'demo') {
      return {
        userId: user.idGlobal.toString(),
        title: "1",
        text: I18n.get('', '<b>¡Habilítate para emitir Documentos POS Electrónicos!</b> 🔥 Mantén tu negocio al día y cumple con esta obligación de la DIAN.'),
        actionCall: `${process.env.REACT_APP_ALEGRA_SMILE}pos-wizard/redirect/alegra-pos`,
        actionLabel: I18n.get('.action', 'Quiero habilitarme'),
        type: "info_dark",
        to: dayjs().add(30, 'days').toISOString(),
        country,
      }
    }
    return {
      userId: user.idGlobal.toString(),
      title: "1",
      text: I18n.get('', '<b>¡Actualización normativa!</b> 📝 A partir del 1 de junio quienes declaren renta deben emitir factura o Documento POS electrónico al vender.'),
      actionCall: 'https://pos.alegra.com/electronic-documents',
      actionLabel: I18n.get('.action', ' Quiero saber más'),
      type: "info_dark",
      to: dayjs().add(30, 'days').toISOString(),
      country,
    }
  }, [user, country, planName]);

  useEffect(() => {
    async function checkNotification() {
      if (isNotificationSent() || notificationSent) return;

      const notificationName = `electronic-pos-document-top-notification`

      if (!planName || !country || planName === 'readonly' || !["colombia"].includes(country)) return;
      if (!["demo", "readonly"].includes(planName) && isElectronicPOSDocumentAvailable) return;
      if (notifications.find(n => n[`${notificationName}`])) return;

      try {
        const response = await APIGraphql(graphqlOperation(updateUser, notificationsTransformer(notificationName)));

        if (!!get(response, 'data.updateUser.id', null)) {
          dispatch(setUser(get(response, 'data.updateUser')));
        }

        if (!isNotificationSent() && !notificationSent) {
          await notificationsAPIV2.post('/notifications',
            notificationBody({
              ...selectedBody(),
            }));
          setNotificationSent(true);
          setNotificationSentInSessionStorage();
        }

      } catch (err) {
        console.log("error while creating notification", err);
      }
    }

    checkNotification();
  }, [country, planName, selectedBody, notifications, dispatch, APIGraphql, notificationSent])

  return null;
}

const notificationBody = ({ userId, from = null, to = null, section = null, title, text, actionCall, actionLabel, type, actionType }) => ({
  name: "electronic-pos-document-top-notification",
  teamInCharge: "pos",
  receptors: {
    users: [userId.toString()]
  },
  fromDate: from ? from : dayjs().toISOString(),
  toDate: to ? to : dayjs().add(30, 'days').toISOString(),
  messages: [
    {
      channel: "web",
      type: "top",
      closeable: true,
      section: section ? section : "index-pos-home",
      title,
      text,
      application: "alegra-pos",
      category: 'system',
      action: {
        actionType,
        actionCall,
        actionLabel
      },
      style: {
        type,
      }
    }
  ]
})

export default ElectronicPOSDocumentTopNotification;

import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { I18n } from '@aws-amplify/core';
import { get } from 'lodash';
import { BigNumber } from 'bignumber.js';
import dayjs from 'dayjs';

import {
  decimalPrecision,
  country as countrySelector,
  dateFormat as dateFormatSelector,
  decimalSeparator as decimalSeparatorSelector,
} from '../../selectors/company';
import { getNumber, getNumberAndPrefixSimplified } from '../../utils/invoices';
import { globalInvoiceStatusTag } from '../../pages/globalInvoices/main/table/columns';
import { COUNTRIES } from '../../utils/enums/countries';
import { Typography } from '@alegradev/smile-ui-react';

const Invoice = ({ invoice, idSelected, onSelect }) => {
  const companyDecimal = useSelector(decimalPrecision);
  const decimal = !!get(invoice, 'decimalPrecision', null)
    ? Number(invoice.decimalPrecision)
    : companyDecimal;
  const country = useSelector(countrySelector);
  const dateFormat = useSelector(dateFormatSelector);
  const decimalSeparator = useSelector(decimalSeparatorSelector);

  if (!invoice) return null;

  const prefix = !!get(invoice, 'currency.symbol')
    ? get(invoice, 'currency.symbol')
    : null;

  const fmt = prefix
    ? {
        prefix,
        decimalSeparator,
        groupSeparator: decimalSeparator === "," ? "." : ",",
        groupSize: 3,
      }
    : {
        decimalSeparator,
        groupSeparator: decimalSeparator === "," ? "." : ",",
        groupSize: 3,
    };

  const renderGLobalInvoiceTag = () => {
    if (country !== COUNTRIES.MEXICO) return null;
    if (!invoice.globalInvoice) return null;

    if (!!get(invoice, 'globalInvoice.stamp.uuid')) {
      return (
        <div className='col-2'>
          <div className='badge badge-pill m-0 font-weight-normal p-2 px-3 badge-closed ml-2 w-fit'>
            <Typography
              text={globalInvoiceStatusTag.success.title}
              type='caption-regular'
              variant='inverse'
            />
          </div>
        </div>
      );
    }
  };

  const hasStamp = !!get(invoice, 'globalInvoice.stamp.uuid');

  const invoiceTitle = useMemo(() => {
    if (country === COUNTRIES.COLOMBIA) {
      return getNumberAndPrefixSimplified(invoice, country);
    }

    return getNumber(invoice, country);
  }, [invoice, country]);

  return (
    <div
      className={`invoice form-row p-3 d-flex justify-content-between align-items-center ${+idSelected === +invoice.id ? 'selected' : ''}`}
      onClick={() => onSelect(invoice.id)}
    >
      <div className={`d-flex flex-column ${hasStamp ? 'col-6' : 'col-8'}`}>
        <Typography
          type='body-3-regular'
          variant='secondary'
          text={invoiceTitle}
          withHtml
        />
        <div className='text-break text-wrap text-capitalize-first'>
          {!!invoice.offlineStatus
            ? dayjs(invoice.timestamp).format(
                I18n.get(
                  !!dateFormat
                    ? `${dateFormat.toUpperCase()} h:mm a`
                    : I18n.get('dateTimeFormat', 'DD/MM/YYYY h:mm a')
                )
              )
            : dayjs(invoice.createdAt).format(
                I18n.get(
                  !!dateFormat
                    ? `${dateFormat.toUpperCase()} h:mm a`
                    : I18n.get('dateTimeFormat', 'DD/MM/YYYY h:mm a')
                )
              )}
        </div>
        <div className='text-break text-nowrap text-truncate text-capitalize-first'>
          {!!invoice.offlineStatus && invoice.offlineStatus === 'pending' && (
            <span className='badge badge-warning badge-pill m-0'>
              {I18n.get('pending', 'pendiente')}
            </span>
          )}

          {!!invoice.offlineStatus && invoice.offlineStatus === 'syncing' && (
            <span className='badge badge-info badge-pill m-0'>
              {I18n.get('syncing', 'sincronizando')}
            </span>
          )}

          {!!invoice.offlineStatus && invoice.offlineStatus === 'error' && (
            <span className='badge badge-danger badge-pill m-0'>
              {I18n.get('error', 'error')}
            </span>
          )}
        </div>
      </div>
      {renderGLobalInvoiceTag()}
      <div className='col-4'>
        <div className='price text-break text-nowrap text-capitalize-first text-right'>
          {new BigNumber(invoice.total).toFormat(decimal, fmt)}
        </div>
      </div>
    </div>
  );
};

Invoice.propTypes = {
  invoice: PropTypes.object.isRequired,
  idSelected: PropTypes.any,
  onSelect: PropTypes.func.isRequired,
};

export default Invoice;

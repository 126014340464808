import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import Methods from './RefundMethods';
import Form from '../../../forms/newRefund';
//To do: change the import style
import { LastStepForm } from '../../../forms/newRefund/lastStepForm';
import ItemsForm from '../../../forms/newRefund/ItemsForm';

/**
 * Mapping of steps to their corresponding components.
 */
const stepComponents = {
  0: Methods,
  1: Form,
  2: Form,
  3: ItemsForm,
  4: LastStepForm,
};

/**
 * Payments Component
 *
 * Renders different forms based on the current step in the refund process.
 *
 * @param {Object} props - Component properties.
 * @param {number} props.step - The current step in the refund process.
 * @param {string} props.method - The selected refund method.
 * @param {Function} props.onChooseMethod - Callback invoked when a refund method is selected.
 * @param {Function} props.changeStep - Callback to change the current step.
 * @param {Function} props.reset - Callback to reset the form.
 * @param {Function} props.openEditItem - Callback to open the edit item modal.
 * @param {Function} props.openEditTip - Callback to open the edit tip modal.
 * @param {string} props.country - The country code.
 * @returns {JSX.Element} - The rendered Payments component.
 */
const Payments = ({
  step,
  method,
  onChooseMethod,
  changeStep,
  reset,
  openEditItem,
  openEditTip,
  country,
}) => {
  const StepComponent = useMemo(() => stepComponents[step], [step]);

  const stepProps = useMemo(() => {
    switch (step) {
      case 0:
        return { onChooseMethod };
      case 1:
      case 2:
        return { reset, method, onChooseMethod, changeStep, country };
      case 3:
        return { changeStep, openEditItem, openEditTip };
      case 4:
        return { method };
      default:
        return {};
    }
  }, [
    step,
    onChooseMethod,
    reset,
    method,
    changeStep,
    openEditItem,
    openEditTip,
  ]);

  return (
    <div className='d-flex flex-column modal__refund-body'>
      {StepComponent && <StepComponent {...stepProps} />}
    </div>
  );
};

Payments.propTypes = {
  step: PropTypes.number.isRequired,
  method: PropTypes.string,
  onChooseMethod: PropTypes.func.isRequired,
  changeStep: PropTypes.func.isRequired,
  reset: PropTypes.func.isRequired,
  openEditItem: PropTypes.func.isRequired,
  openEditTip: PropTypes.func.isRequired,
  country: PropTypes.string.isRequired,
};

export default React.memo(Payments);

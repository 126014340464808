import { useEffect } from 'react';
import { Field, useForm, useFormState } from 'react-final-form';
import { useSelector } from 'react-redux';
import { renderField } from '../../fields/V0/Fields';
import { get } from 'lodash';
import { I18n } from 'aws-amplify';

import { useFormat } from '../../../../hooks/useFormat';
import { total as totalSelector } from '../../../../selectors/activeRefund';
import BigNumber from 'bignumber.js';
import { Typography } from '@alegradev/smile-ui-react';

export const RectificativeInSimplifiedInvoice = () => {
  const total = useSelector(totalSelector);
  const { values } = useFormState();
  const { decimal, fmt } = useFormat();
  const form = useForm();

  useEffect(() => {
    form.change('refund.amount', total.toString());
  }, [total]);

  const outstandingInvoiceAmount = new BigNumber(
    get(values, 'refund.document.balance')
  ).toFormat(decimal, fmt);

  return (
    <div className='w-100 d-flex flex-column'>
      <div className='w-100 row mx-0'>
        <Field
          name='refund.amount'
          type='text'
          component={renderField}
          className='col-12 p-0 pr-md-2 select-refund new-refund-input'
          label={I18n.get('refundAmount', 'Monto a devolver')}
          disabled
          required
        />
      </div>
      <div className='w-100 flex flex-column align-items-end'>
        <div className='w-50 flex justify-content-between'>
          <Typography
            type='body-2-bold'
            text={`${I18n.get('associatedDocument', 'Documento asociado')}:`}
          />
          <Typography
            type='body-2-regular'
            text={get(values, 'refund.document.numberTemplate.fullNumber')}
          />
        </div>
        <div className='w-50 flex justify-content-between'>
          <Typography
            type='body-2-bold'
            text={`${I18n.get('outstandingInvoiceAmount', 'Pendiente por cobrar')}:`}
          />
          <Typography type='body-2-regular' text={outstandingInvoiceAmount} />
        </div>
      </div>
    </div>
  );
};

import { useModal } from '@alegradev/smile-ui-react';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { sendNewGTMEvent } from '../../reducers/company';

/**
 * A hook that updates the logout confirmation modal with a custom onClose
 * handler to track the event in GTM.
 *
 * @param {Object} props The props object.
 * @prop {boolean} closeWithoutSyncing Whether the modal was closed without
 *     syncing or not.
 */
const useLogoutConfirmationModal = ({ closeWithoutSyncing }) => {
  const { updateModal } = useModal();

  const dispatch = useDispatch();

  useEffect(() => {
    const eventName = closeWithoutSyncing
      ? 'pos-confirmation-logout'
      : 'pos-warning-logout';
    const status = 'skipped';

    updateModal('logoutConfirm', {
      onClose: (fromCloseButton) => {
        if (fromCloseButton) {
          dispatch(
            sendNewGTMEvent(eventName, {
              status,
            })
          );
        }
      },
    });
  }, [closeWithoutSyncing]);
};

export default useLogoutConfirmationModal;

import { merge } from 'lodash'

import Cookie from 'js-cookie';
import http from '../utils/http';

const baseUrl = process.env.REACT_APP_CONTACTS_ENDPOINT;
const TOKEN_COOKIE_NAME = process.env.REACT_APP_ALEGRA_AUTH_TOKEN_COOKIE; 
const baseConfig = {
  headers: {
    'X-Data-Source': 'pos',
    'x-access-key': process.env.REACT_APP_CONTACTS_AUTHORIZATION,
    Authorization: `Bearer ${Cookie.get(TOKEN_COOKIE_NAME)}`,
  }
};

const get = async (url, params = null, userConfig = null) => await http.get(`${baseUrl}${url}`, params, merge(baseConfig, userConfig), Cookie.get("pos-block-api-request"));

const defaultExport = {
  get,
}

export default defaultExport
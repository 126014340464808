import React, { useEffect, useCallback } from 'react'
import { I18n } from '@aws-amplify/core'
import { useSelector, useDispatch } from 'react-redux'
import { get } from 'lodash'
import dayjs from 'dayjs'

import * as mutations from '../../graphql/mutations';
import { graphqlOperation } from 'aws-amplify'

import { APIGraphqlSelector } from '../../selectors/app'
import { userSelector } from '../../selectors/auth'
import { setUser } from '../../reducers/auth';
import notificationsAPIV2 from '../../reducers/notificationsAPIV2';
import { country as countrySelector, notificationsSelector, membershipSelector, ivaConditionSelector } from '../../selectors/company';
import { getPlanKeyword, invoiceFeatureLimitReached } from '../../selectors/membership'
import { createNotificationBody, notificationsTransformer } from './utils';

const ARGUniqueTributeCourseNotification = () => {
  const user = useSelector(userSelector);
  const country = useSelector(countrySelector);
  const planName = useSelector(getPlanKeyword);
  const notifications = useSelector(notificationsSelector);
  const invoiceLimitReached = useSelector(invoiceFeatureLimitReached);
  const ivaCondition = useSelector(ivaConditionSelector);
  const membership = useSelector(membershipSelector);
  const APIGraphql = useSelector(APIGraphqlSelector);
  const dispatch = useDispatch();

  const selectedBody = useCallback(() => {
    return {
      name: 'AR-monotributo-course',
      teamInCharge: "pos",
      userId: user.idGlobal.toString(),
      title: I18n.get('', 'Curso gratis | Todo sobre el Monotributo ⚡'),
      text: I18n.get('', 'Inscribite hoy y conocé de qué se trata, cómo darte de alta y los beneficios de pertenecer a este régimen.'),
      actionCall: 'https://www.alegra.com/academy/courses/curso-de-monotributo-argentina/',
      actionLabel: I18n.get('', 'Quiero inscribirme'),
      type: "info",
      to: dayjs().add(10, 'days').toISOString()
    }
  }, [user])

  useEffect(() => {
    async function checkNotification() {
      if (!['argentina'].includes(country)) return;
      const notificationName = 'AR-monotributo-course';
      if (notifications?.find(n => n[notificationName])) return;
      if (ivaCondition !== 'UNIQUE_TRIBUTE_RESPONSABLE') return;
      const plan = get(membership, 'plan.keyword', '')
      const sendAfter5days = dayjs().isAfter(dayjs(get(membership, 'dateStart')).add(5, 'day'))
      const sendBeforeDemoFinishes = dayjs().isBefore(dayjs(get(membership, 'dateEnd')))
      if (!['demo', 'readonly'].includes(plan) || !(sendAfter5days && sendBeforeDemoFinishes)) return;

      try {
        // create cookie to validate if the notification has been shown
        const response = await APIGraphql(graphqlOperation(mutations.updateUser, notificationsTransformer(notificationName)));

        if (!!get(response, 'data.updateUser.id', null)) {
          dispatch(setUser(get(response, 'data.updateUser')))
        }

        await notificationsAPIV2.post(
          '/notifications',
          createNotificationBody({
            ...selectedBody(),
          })
        );

      } catch (err) {
        console.log("error while creating notification", err);
      }

    }

    checkNotification();
  }, [country, planName, invoiceLimitReached, notifications, membership, ivaCondition, APIGraphql, dispatch, selectedBody])

  return (
    <>
    </>
  )
}

export default ARGUniqueTributeCourseNotification;
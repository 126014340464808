import React, { useEffect, useMemo } from 'react';
import { Field } from 'react-final-form';
import { I18n } from '@aws-amplify/core';
import { capitalize, isString } from 'lodash';

import {
  getRegimeOptionsByRFC,
  allRegimes,
} from '../../../../../components/countriesData/mexico/regimes';
import { get } from 'lodash';
import { Grid } from '@alegradev/smile-ui-react';
import { renderField } from '../../../../../components/forms/fields/V2';
import { COUNTRIES } from '../../../../../utils/enums/countries';

const mexicoRegime = (props) => {
  const { Col } = Grid;
  const thirdType = get(props, 'values.thirdType', '');
  const regimeValue = get(props, 'values.regime', '');
  const favoriteRegime = get(props, 'values.favoriteRegime', '');

  useEffect(() => {
    if (thirdType === 'FOREIGN') {
      props.form.change('regime', allRegimes.SIMPLIFIED_REGIME.value);
    }
  }, [thirdType]);

  const regimeOptions = useMemo(() => {
    const options = getRegimeOptionsByRFC(get(props, 'values.rfc', ''))?.map(
      (option) => ({
        text: option.label,
        value: option.value,
      })
    );

    return options?.sort((a, b) => {
      if (a.value === 'SIMPLIFIED_REGIME') return -1;
      if (b.value === 'SIMPLIFIED_REGIME') return 1;
      return 0;
    });
  }, [props.values.rfc]);

  const favoriteRegimeOption = useMemo(() => {
    {
      const DEFAULT_FAVORITE_REGIME = 'SIMPLIFIED_REGIME';

      if (favoriteRegime) return favoriteRegime;

      return DEFAULT_FAVORITE_REGIME;
    }
  }, [favoriteRegime, regimeValue]);

  useEffect(() => {
    if (regimeValue) {
      const splittedRegime = isString(regimeValue)
        ? regimeValue?.split('%s%')
        : regimeValue;
      if (splittedRegime?.length > 0) {
        if (!splittedRegime?.includes(favoriteRegime)) {
          props.form.change('favoriteRegime', splittedRegime[0]);
        }
      }
    }
  }, [regimeValue, favoriteRegime]);

  return (
    <Col xs={12}>
      <Field
        name='regime'
        component={renderField}
        fieldType='select'
        canFavorite
        multiple
        changeAction={(value) => {
          if (value?.isFavorite) {
            props.form.change('favoriteRegime', value?.value);
          }
        }}
        defaultFavoriteOption={favoriteRegimeOption}
        options={regimeOptions}
        label={capitalize(I18n.get('regime', 'Regímenes fiscales'))}
        required
        disabled={thirdType === 'FOREIGN'}
        forceDefaultOptions
      />
    </Col>
  );
};

const renderRegime = (props) => {
  switch (props.country) {
    case COUNTRIES.MEXICO:
      return mexicoRegime(props);
    default:
      return null;
  }
};

export default renderRegime;

import React from 'react'
import { Form, Field } from 'react-final-form';
import { useSelector } from 'react-redux';
import { I18n } from '@aws-amplify/core';
import { get, capitalize } from 'lodash'

import { filter } from '../../../../database/itemsDB';
import { calculateSingleItemValues, calculateSingleItemValuesWithDecimals } from '../../../../utils';
import { decimalPrecision, country as countrySelector, companySelector } from '../../../../selectors/company';
import Bottom from '../../../modals/common/Bottom';
import Modal from '../../../common/Modal';
import { renderSelect, renderField } from '../../fields/V0/Fields';
import { kitMaxDecimals } from '../Kit';
import useDecimalsVersionsGroup from '../../../../hooks/useDecimalsVersionsGroup/hook';

const KitItem = props => {
  const decimal = useSelector(decimalPrecision);
  const country = useSelector(countrySelector);
  const company = useSelector(companySelector);
  const { isDecimalActive } = useDecimalsVersionsGroup();

  const submit = values => {

    props.form.change(`items.${props.index}`, {
      ...values,
      quantity: !get(values, 'quantity') || +get(values, 'quantity') <= 0
        ? 1 : get(values, 'quantity')
    });
    props.onRequestClose()
  }

  const itemValue = !!props.item
    ? isDecimalActive ? calculateSingleItemValuesWithDecimals({
      ...props.item,
      price: get(props, 'item.inventory.unitCost'),
    }, decimal) : calculateSingleItemValues({
      ...props.item,
      price: get(props, 'item.inventory.unitCost'),
    }, decimal)
    : null

  return (
    <Modal
      {...props}
      title={I18n.get('selectProduct', 'seleccionar producto')}
      className="select-kit-item-modal"
    >
      <Form
        onSubmit={submit}
        initialValues={{
          ...props.item,
          quantity: get(props, 'item.quantity', 1),
          unitCost: !!itemValue ? itemValue.price.toString() : 0,
          unit: !!get(props, 'item.item.inventory.unit')
            ? I18n.get("units." + get(props, 'item.item.inventory.unit'), get(props, 'item.item.inventory.unit'))
            : null
        }}
        keepDirtyOnReinitialize
      >
        {({ handleSubmit, form, values }) => {
          if (!get(values, 'unit', null))
            form.change('unit', get(values, "item.inventory.unit", null)
              ? I18n.get("units." + get(values, "item.inventory.unit"), get(values, "item.inventory.unit"))
              : null)
          return (
            <form
              noValidate
              onSubmit={handleSubmit}
            >
              <div className="p-5 form-body">
                <div className="row">
                  <Field
                    name="item"
                    component={renderSelect}
                    className="col-12"
                    label={capitalize(I18n.get('item', 'producto'))}
                    required
                    loadOptions={async text => await filter({ text, categories: [], limit: 10, noNegativeSale: true })}
                    defaultOptions
                    value={null}
                    placeholder={I18n.get('searchItemByText', 'Buscar productos')}
                    getOptionLabel={option => {
                      return (
                        <div className="d-flex align-items-center justify-content-between p-1">
                          <p className="h4 text-truncate m-0">
                            {`${option.name}${!!get(option, 'reference.reference')
                              ? ` (${get(option, 'reference.reference')})` : !!get(option, 'reference')
                                ? ` (${get(option, 'reference')})` : ''}`
                            }
                          </p>
                        </div>
                      )
                    }}
                    getOptionValue={option => option.id}
                    onChange={(values) => {
                      form.change('price', isDecimalActive ? calculateSingleItemValuesWithDecimals({
                        ...values,
                        price: get(values, 'price.0.price'),
                      }, decimal).price.toString() : calculateSingleItemValues({
                        ...values,
                        price: get(values, 'price.0.price'),
                      }, decimal).price.toString())
                      form.change('unit', !!get(values, 'unit')
                        ? I18n.get("units." + get(values, 'unit'), get(values, 'unit'))
                        : null)
                      return values
                    }}
                    menuPosition="absolute"
                  />
                </div>
                <div className="row">
                  <Field
                    name="quantity"
                    component={renderField}
                    className="col-4"
                    type="number"
                    label={capitalize(I18n.get('quantity', 'cantidad'))}
                    onChange={e => kitMaxDecimals(e, `quantity`, 4, values, country)}
                    required
                  />

                  <Field
                    name="unit"
                    component={renderField}
                    className="col-4"
                    type="text"
                    label={capitalize(I18n.get('measurementUnit', 'unidad de medida'))}
                    disabled
                  />

                  {/* <Field
                    name="unitCost"
                    component={renderField}
                    className="col-4"
                    type="number"
                    label={capitalize(I18n.get('price', 'precio'))}
                    disabled
                  /> */}
                  <Field
                    name="item.inventory.unitCost"
                    className="col-4"
                    type="number"
                    label={capitalize(I18n.get('cost', 'Costo'))}
                    disabled
                  >
                    {props => renderField({
                      ...props,
                      input: {
                        ...props.input,
                        value: isDecimalActive ? calculateSingleItemValuesWithDecimals({
                          ...get(values, 'item'),
                          price: get(values, 'item.inventory.unitCost'),
                          quantity: get(values, 'quantity'),
                          tax: [],
                        }, decimal).total  : calculateSingleItemValues({
                          ...get(values, 'item'),
                          price: get(values, 'item.inventory.unitCost'),
                          quantity: get(values, 'quantity'),
                          tax: [],
                        }, decimal).total
                      }
                    })}
                  </Field>
                </div>
              </div>

              <Bottom
                hideCancel
                onClose={props.onRequestClose}
              />
            </form>
          )
        }}
      </Form>
    </Modal>
  )
}

export default KitItem
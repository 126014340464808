import { get } from 'lodash';
import configAPI from '../../../../reducers/configAPI';


const getParams = () => {
  return {
    application_version: 'costaRica',
    catalog_version: '4.3',
  };
}

export const fetchDistricts = async ({ idCompany }) => {
  const params = getParams();

  const data = await configAPI.get('/districts', {
    ...params,
  });
  return get(data, 'data', []);
}

export const fetchNeighborhoods = async ({ idCompany, idDistrict }) => {
  const params = getParams();

  const data = await configAPI.get('/neighborhoods', {
    ...params,
    district_id: idDistrict,
  });
  return get(data, 'data', []);
}
import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom'
import { I18n } from '@aws-amplify/core';
import { get } from 'lodash'

import { closeModal } from '../../../reducers/modals';
import Modal from '../../common/Modal';

const ReadonlyPlanWithData = () => {
  const dispatch = useDispatch()
  const isOpen = useSelector(state => !!get(state, 'modals.readonlyPlanWithData.isOpen'))
  const { sales, clients, items } = useSelector(state => get(state, 'modals.readonlyPlanWithData.params.info', { sales: 0, items: 0, clients: 0 }));

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={() => dispatch(closeModal({ modal: 'readonlyPlanWithData' }))}
      className="modal__products-onboarding modal__medium-size min-height-sm"
    >
      <div className="p-4 pb-0">

        <div className="d-flex flex-column justify-content-center py-md-4 text-center align-items-center">

          <h1 className="text-primary mb-3 mt-3" style={{ fontSize: '24px', lineHeight: '32px' }}>
            {I18n.get('onboarding.readonly.header', '¡Sigue vendiendo fácil y rápido! 😎')}
          </h1>

          <p className="mb-5" style={{ maxWidth: '45rem' }}>
            {I18n.get('onboarding.readonly.message.1', 'Ya disfrutaste de tu prueba gratis,')}
            {' '}
            <b>{I18n.get('onboarding.readonly.message.2', 'elige tu plan ahora')}</b>
            {' '}
            {I18n.get('onboarding.readonly.message.3', 'y continúa teniendo el control de tus puntos de venta.')}
          </p>

          <div className="w-75 d-flex justify-content-between mb-5">
            <p className="w-25">
              {I18n.get('youRegistered', 'Has registrado')}
              <span className="d-block text-primary" style={{ fontSize: '32px', fontWeight: '400', lineHeight: '32px' }}>{sales}</span>
              {I18n.get('sales', 'ventas').toLowerCase()}
            </p>

            <p className="w-25">
              {I18n.get('youHave', 'Tienes')}
              <span className="d-block text-primary" style={{ fontSize: '32px', fontWeight: '400', lineHeight: '32px' }}>{clients}</span>
              {I18n.get('clients', 'clientes').toLowerCase()}
            </p>

            <p className="w-25">
              {I18n.get('youCreated', 'Ya creaste')}
              <span className="d-block text-primary" style={{ fontSize: '32px', fontWeight: '400', lineHeight: '32px' }}>{items}</span>
              {I18n.get('items', 'productos').toLowerCase()}
            </p>
          </div>

          <div className="text-center mt-2">
            <Link
              to={{ pathname: `${process.env.REACT_APP_ALEGRA_SMILE}subscriptions/products/alegra-pos/plans` }}
              className="btn btn-success text-white px-4 text-uppercase d-flex align-items-center"
              style={{
                height: '4.8rem',
                filter: 'drop-shadow(0px 3px 12px rgba(0, 0, 0, 0.15))'
              }}
              onClick={() => dispatch(closeModal({ modal: 'readonlyPlan' }))}
              target="_blank"
            >
              {I18n.get('onboarding.readonly.button', 'ELEGIR PLAN')}
            </Link>
          </div>

        </div>
      </div>
    </Modal>
  )
}

export default ReadonlyPlanWithData
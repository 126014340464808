import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useDispatch, useSelector } from "react-redux";
import { graphqlOperation, I18n } from "aws-amplify";
import { get } from "lodash";
import { useCancel } from "../../../hooks/queryHooks/useCancel";
import { useRefresh } from "../../../hooks/queryHooks/useRefresh";
import { replaceAndParse, toast } from "../../../utils";
import { handleError } from "../../../utils/errors";
import { updatePriceList as updatePriceListReducer, removePriceList, addPriceList } from "../../../reducers/priceLists";
import * as mutations from '../../../graphql/mutations';
import { PriceListKeys } from "./queries";
import { priceListTransformer } from "../../../utils/transformers";
import alegraAPI from "../../../reducers/alegraAPI";
import { endAction, startAction } from "../../../reducers/monitoring";
import { sendGTMEvent, sendNewGTMEvent } from "../../../reducers/company";
import { calculateActionTimeSelector } from "../../../selectors/monitoring";

const deletePriceList = async ({ api, id }) => {
  await api(graphqlOperation(mutations.deletePriceList, {
    id,
  }))
};

const updatePriceList = async ({ api, priceList, isActive }) => {
  const newPriceList = priceListTransformer({ ...priceList, status: !!isActive ? 'active' : 'inactive' });
  await api(graphqlOperation(mutations.updatePriceList, {
    priceList: newPriceList,
  }));
  return newPriceList;
};

const createPriceList = async ({ transformedValues }) => {
  return await alegraAPI.post('/price-lists', {
    ...priceListTransformer({ ...transformedValues })
  });
};

const updatePriceListFromAlegra = async ({ priceList, transformedValues }) => {
  return await alegraAPI.put(`/price-lists/${priceList.id}`, {
    ...priceListTransformer({ ...priceList, ...transformedValues })
  });
};

export const useDeletePriceList = () => {
  const queryClient = useQueryClient();
  const cancel = useCancel();
  const refresh = useRefresh();
  const dispatch = useDispatch();

  queryClient.setMutationDefaults(['deletePriceList'], {
    mutationFn: deletePriceList
  });

  return useMutation({
    mutationKey: ['deletePriceList'],
    mutationFn: deletePriceList,
    onMutate: async () => {
      await cancel(PriceListKeys.all);
    },
    onError: error => {
      toast.error({
        title: I18n.get('priceListDeletedError', 'Error eliminando la lista de precio'),
        subtitle: responseError ?? handleError(error)
      });
    },
    onSuccess: (_, variables) => {
      toast.success({
        title: I18n.get("priceListDeleted", "Lista de precio eliminada con éxito")
      });
      dispatch(removePriceList(get(variables, 'id')));
    },
    onSettled: () => {
      refresh(PriceListKeys.all);
    }
  });
}

export const useUpdatePriceList = () => {
  const queryClient = useQueryClient();
  const cancel = useCancel();
  const refresh = useRefresh();
  const dispatch = useDispatch();

  queryClient.setMutationDefaults(['updatePriceList'], {
    mutationFn: updatePriceList
  });

  return useMutation({
    mutationKey: ['updatePriceList'],
    mutationFn: updatePriceList,
    onMutate: async () => {
      await cancel(PriceListKeys.all);
    },
    onError: error => {
      toast.error({
        title: I18n.get('priceListStatusError', 'Error cambiando estado de la lista de precio'),
        subtitle: handleError(error)
      });
    },
    onSuccess: (_, variables) => {
      toast.success({
        title: get(variables, 'isActive')
          ? I18n.get('priceListActivatedSucessfully', 'Lista de precio activada con éxito')
          : I18n.get('priceListDeactivatedSuccessfully', 'Lista de precio desactivada con éxito')
      });
      dispatch(updatePriceListReducer({
        id: get(variables, 'priceList.id'),
        changes: get(variables, 'priceList')
      }));
    },
    onSettled: () => {
      refresh(PriceListKeys.all);
    }
  });
};

export const useCreatePriceList = () => {
  const queryClient = useQueryClient();
  const cancel = useCancel();
  const refresh = useRefresh();
  const dispatch = useDispatch();
  const calculateActionTime = useSelector(calculateActionTimeSelector);

  queryClient.setMutationDefaults(['createPriceList'], {
    mutationFn: createPriceList
  });

  return useMutation({
    mutationKey: ['createPriceList'],
    mutationFn: createPriceList,
    onMutate: async () => {
      dispatch(startAction({action: 'createPriceList'}));
      
      await cancel(PriceListKeys.all);
    },
    onError: error => {
      const responseError = get(error, 'response.data.message', '');
      dispatch(
        sendNewGTMEvent('pos-price-list-created', {
          error: handleError(error),
        })
      );
      toast.error({
        title: I18n.get('priceListCreatedError', 'Error creando la lista de precio'),
        subtitle: responseError ?? handleError(error)
      });
    },
    onSuccess: (data, variables) => {
      toast.success({
        title: I18n.get(
          'priceListCreatedSuccessfully',
          'Ya puedes asociar tu lista de precios. 🎊'
        ),
        subtitle: replaceAndParse(
          I18n.get(
            'priceListCreatedSuccessfullyMessage',
            'Tu lista {} ya está disponible para que la asocies a tus productos.'
          ),
          [
            `<span class="font-weight-bold">${get(
              data,
              'data.name',
              ''
            )}</span>`,
          ]
        ),
      })

      dispatch(endAction({action: 'createPriceList'}));
      dispatch(sendGTMEvent('price-list-created'));
      dispatch(
        sendNewGTMEvent('pos-price-list-created', {
          error: 'no error',
          responseTime: calculateActionTime("createPriceList"),
          type: get(variables, 'transformedValues.type', ''),
        })
      );
      dispatch(addPriceList(get(data, 'data')));
      refresh(PriceListKeys.all);
    },
    onSettled: () => {
      refresh(PriceListKeys.all);
    }
  });
};

export const useUpdatePriceListFromAlegra = () => {
  const queryClient = useQueryClient();
  const cancel = useCancel();
  const refresh = useRefresh();
  const dispatch = useDispatch();

  queryClient.setMutationDefaults(['updatePriceList'], {
    mutationFn: updatePriceListFromAlegra
  });

  return useMutation({
    mutationKey: ['updatePriceList'],
    mutationFn: updatePriceListFromAlegra,
    onMutate: async () => {
      await cancel(PriceListKeys.all);
    },
    onSuccess: (_, variables) => {      
      dispatch(
        updatePriceListReducer({
          id: get(variables, 'priceList.id'),
          changes: get(variables, 'transformedValues'),
        })
      );
      
    },
    onSettled: () => {
      refresh(PriceListKeys.all);
    }
  });
};
import { handleError } from '../../../utils/errors';
import { toastHandler } from '@alegradev/smile-ui-react';
import Cookies from 'js-cookie';

export const contactsKeys = {
  all: 'allContacts',
  detail: 'contactDetail',
  delete: 'deleteContact',
};

export const DEFAULT_QUERY_PARAMS = ({ errorTitle }) => ({
  onError: (error) => {
    toastHandler({
      title: errorTitle,
      description: handleError(error),
      type: 'error',
    });
  },
  keepPreviousData: true,
  staleTime: 1000 * 60 * 60 * 24,
  refetchOnReconnect: 'always',
});

export const getAuthToken = () => {
  return Cookies.get(process.env.REACT_APP_ALEGRA_AUTH_TOKEN_COOKIE);
};

export const checkCreditLimitPermission = (scope) => {
  const permissions = scope.split(' ');

  const isOwner = permissions.includes('owner');

  if (isOwner) return true;

  const hasEclPermission = permissions.some((permission) =>
    permission.startsWith('u:ecl')
  );

  return hasEclPermission;
};

import { createDraftSafeSelector } from '@reduxjs/toolkit';
import { get, isEmpty, has } from 'lodash';

import { usersSelector } from '../reducers/users';

const usersDataSelector = state => state.users; 

export const normalUsers = createDraftSafeSelector(
    usersSelector.selectAll,
    users => !!users
        ? users.filter(user => get(user, 'role') === 'user')
        : []
)

export const allUsers = createDraftSafeSelector(
  usersSelector.selectAll,
  users => users
)

export const activeUsers = createDraftSafeSelector(
  usersSelector.selectAll,
  users => !!users
    ? users.filter(user => get(user, 'status') === 'active')
    : []
)

export const getStationUsers = station => createDraftSafeSelector(
    [normalUsers],
    users => {
      if (!station || !users || !has(station, 'users')) return [];
      
      const userIds = station.users.map(user => user.idUser);
      if (isEmpty(users))
        return userIds;
  
      return users.filter(user => userIds.includes(user.idGlobal));
    }
  )

export const loadingSelector = createDraftSafeSelector(
    usersDataSelector,
    data => data.loading === 'loading'
)
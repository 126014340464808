import { useDispatch, useSelector } from 'react-redux';
import { get } from 'lodash';
import YouTube from 'react-youtube';
import { I18n } from '@aws-amplify/core';

import Modal from '../../common/Modal';
import { country as countrySelector } from '../../../selectors/company';
import { closeModal } from '../../../reducers/modals';
import { getFeHelpedArticle } from './utils';
import { sendNewGTMEvent } from '../../../reducers/company';
import { useEffect, useState } from 'react';

function ElectronicPayrollTutorial() {
  const dispatch = useDispatch();
  const country = useSelector(countrySelector);
  const [videoStatus, setVideoStatus] = useState('ignored');
  const isOpen = useSelector((state) =>
    get(state, 'modals.electronicPayrollTutorial.isOpen', false)
  );

  useEffect(() => {
    return () => {
      setVideoStatus('ignored');
    };
  }, []);

  const handleGtmEvent = (action) => {
    dispatch(
      sendNewGTMEvent('pos-electronic-invoicing-managed', {
        action,
        status: videoStatus,
      })
    );
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={() =>
        dispatch(closeModal({ modal: 'electronicPayrollTutorial' }))
      }
      includeHeader={false}
      className='modal__medium-size modal__mobile-bottom'
    >
      <div className='p-4 electronic-payroll-tutorial'>
        <YouTube
          className='electronic-payroll-tutorial-video'
          videoId={I18n.get(
            'electronicPayrollTutorial.video.id',
            'tkWsUbovCks'
          )}
          onEnd={() => setVideoStatus('completed')}
          onPlay={() => setVideoStatus('started')}
        />

        <p className='electronic-payroll-tutorial-title'>
          {I18n.get(
            'electronicPayrollHabilitation',
            'Conoce cómo habilitarte para emitir facturas electrónicas'
          )}
        </p>

        <div className='electronic-payroll-tutorial-actions'>
          <button
            className='btn-md-default-outline'
            onClick={() => [
              dispatch(closeModal({ modal: 'electronicPayrollTutorial' })),
              handleGtmEvent('skip'),
            ]}
          >
            {I18n.get('avoidForNow', 'Omitir por ahora')}
          </button>
          <button
            className='btn-md-default-filled'
            onClick={() => [
              window.open(getFeHelpedArticle(country), '_blank'),
              handleGtmEvent('help'),
            ]}
          >
            {I18n.get('helpedArticle', 'Leer articulo de ayuda')}
          </button>
        </div>
      </div>
    </Modal>
  );
}

export default ElectronicPayrollTutorial;

import React, { useEffect } from 'react';
import { I18n } from '@aws-amplify/core';
import { graphqlOperation } from '@aws-amplify/api';
import { useSelector, useDispatch } from 'react-redux';
import { Form } from 'react-final-form';
import { useHistory } from 'react-router-dom';
import { capitalize, get } from 'lodash';

import * as mutations from '../../../graphql/mutations';
import { setUser } from '../../../reducers/auth';
import { hasPermissionTo } from '../../../selectors/auth';
import {
  country as countrySelector,
  electronicInvoicing as electronicInvoicingSelector,
  printSettings,
} from '../../../selectors/company';
import { toast } from '../../../utils';
import { formError } from '../../../utils/errors';
import PrintForm from '../../forms/print/Print';
import { transform, validate } from '../../forms/print/utils';
import Header from '../common/Header';
import Bottom from '../common/Bottom';
import Preview from './Preview';
import { APIGraphqlSelector } from '../../../selectors/app';
import { sendNewGTMEvent } from '../../../reducers/company';
import { isElectronicPOSDocumentAvailableSelector } from '../../../selectors/numerations';

const Print = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const settings = useSelector(printSettings);
  const can = useSelector(hasPermissionTo);
  const country = useSelector(countrySelector);
  const electronicInvoicing = useSelector(electronicInvoicingSelector);
  const isElectronicPOSDocumentAvailable = useSelector(isElectronicPOSDocumentAvailableSelector);
  const APIGraphql = useSelector(APIGraphqlSelector);

  useEffect(() => {
    window.dataLayer.push({
      event: 'VirtualPageview',
      virtualPageURL: '/settings/print',
      virtualPageTitle: 'Print Settings',
    });
  }, []);

  const submit = async (values) => {
    const transformedValues = transform(values);

    try {
      const response = await APIGraphql(
        graphqlOperation(mutations.updateUser, {
          user: {
            company: transformedValues,
          },
        })
      );
      if (!!get(response, 'data.updateUser.id', null))
        dispatch(setUser(get(response, 'data.updateUser')));

      toast.success({
        title: I18n.get('companyUpdatedSuccessfullyPrintTitle', '¡Ya está! 🎨'),
        subtitle: I18n.get(
          'companyUpdatedSuccessfullyPrintSubTitle',
          'La plantilla de tus documentos de venta se editó con éxito.'
        ),
      });
      dispatch(sendNewGTMEvent('pos-print-template-configured'));
      history.push('/settings');
    } catch (error) {
      return formError(
        error,
        I18n.get(
          'updateCompanyError',
          'ocurrió un error al intentar actualizar los datos de la compañía'
        )
      );
    }
  };

  return (
    <div className='container p-5'>
      <div className='d-flex flex-column'>
        <Form
          onSubmit={submit}
          validate={(values) => validate(values)}
          mutators={{
            setValues: ([field, value], state, { changeValue }) => {
              changeValue(state, field, () => value);
            },
          }}
          initialValues={{
            imageInfo: null,
            ...settings,
            template: {
              value: settings.template,
              label: capitalize(I18n.get(settings.template, settings.template)),
            },
            format: { value: settings.format, label: `${settings.format}mm` },
          }}
          keepDirtyOnReinitialize
        >
          {({
            handleSubmit,
            values,
            form,
            submitting,
            submitError,
            pristine,
          }) => (
            <form noValidate onSubmit={handleSubmit}>
              <Header
                title={
                  <p className='text-title-secundary'>
                    {I18n.get(
                      'invoicePrintSetting',
                      'configuración de tirilla'
                    )}
                  </p>
                }
                subtitle={
                  <p className='h5 text-muted text-capitalize-first'>
                    {I18n.get(
                      'invoicePrintSettingSubtitle',
                      'define como será la tirilla que ofrecerás a tus clientes'
                    )}{' '}
                    <a
                      className='text-capitalize-first btn-link'
                      href='https://ayuda.alegra.com/es/imprime-tus-facturas-en-alegra-pos'
                      target='_blank'
                      rel='noreferrer'
                    >
                      {capitalize(I18n.get('seeMore', 'ver más'))}
                    </a>
                  </p>
                }
                error={submitError}
                info={
                  !can('edit', 'company') ||
                  !can('edit-invoice-template', 'company')
                    ? I18n.get(
                        ('userNotAllowed.company.edit-invoice-template',
                        'no tienes permisos suficientes para editar la información de impresión de tu compañía')
                      )
                    : null
                }
              />

              <div className='card bg-white shadow d-flex flex-row my-3 rounded-lg'>
                <div className='bg-white p-0 col-md-6 col-12'>
                  <PrintForm values={values} form={form} country={country} electronicInvoicing={electronicInvoicing} isElectronicPOSDocumentAvailable={isElectronicPOSDocumentAvailable}/>
                </div>
                <div className='setting__print-preview p-3 col-md-6 d-none d-md-block'>
                  <Preview values={values} />
                </div>
              </div>

              <Bottom
                disabled={
                  pristine ||
                  !can('edit', 'company') ||
                  !can('edit-invoice-template', 'company')
                }
                submitting={submitting}
                onClose={() => history.push('/settings')}
                hiddeRequired
              />
            </form>
          )}
        </Form>
      </div>
    </div>
  );
};

export default Print;

import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { country as countrySelector } from '../../../selectors/company';

import RefundInfoFactory from './refundInfoStrategies/RefundInfoFactory';

/**
 * Component to display refund information.
 *
 * @param {Object} props - Component props.
 * @param {Object} props.refund - The refund object.
 * @param {Object} props.setting - Setting object containing alignment.
 * @returns {JSX.Element} - Rendered RefundInfo Component.
 */
const RefundInfo = React.memo(({ refund, setting }) => {
  const country = useSelector(countrySelector);
  const { align } = setting;

  return (
    <RefundInfoFactory
      country={country}
      refund={refund}
      align={align}
    />
  );
});

RefundInfo.propTypes = {
  refund: PropTypes.shape({
    numberTemplate: PropTypes.shape({
      documentType: PropTypes.string,
      isElectronic: PropTypes.bool,
      fullNumber: PropTypes.string,
    }),
    dueDate: PropTypes.string,
  }).isRequired,
  setting: PropTypes.shape({
    align: PropTypes.string.isRequired,
  }).isRequired,
};

export default RefundInfo;

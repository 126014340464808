import React from 'react';
import PropTypes from 'prop-types';
import * as DOMPurify from 'dompurify';

DOMPurify.addHook('afterSanitizeAttributes', function (node) {
  if ('target' in node) {
    node.setAttribute('target', '_blank');
    node.setAttribute('rel', 'noopener noreferrer');
  }
});

const Notification = ({ isOpen, type, text, useDangerouslySetInnerHTML = false }) => {
  const html = useDangerouslySetInnerHTML ? DOMPurify.sanitize(text, { ADD_ATTR: ['target'] }) : null; 
  return (
    <div className={`side-modal__notification side-modal__notification-${!!type ? type : 'error'} 
      ${isOpen ? 'open' : 'closed'}`}
    >
      {
        !useDangerouslySetInnerHTML ? (
          <div className="side-modal__notification-text text-left p-3 px-4 text-capitalize-first">
            {text}
          </div>
        ) : (
          <div className="side-modal__notification-text text-left p-3 px-4 text-capitalize-first"  
            dangerouslySetInnerHTML={{ __html: html }}>
          </div>
        )
      }
    </div>
  );
}

Notification.propTypes = {
  isOpen: PropTypes.bool,
  type: PropTypes.string,
  text: PropTypes.string,
}

export default Notification;
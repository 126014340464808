import React, { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { Field, useFormState } from 'react-final-form';
import { I18n } from '@aws-amplify/core';
import { capitalize, get } from 'lodash';

import { decimalPrecision, decimalSeparator } from '../../../selectors/company';
import { renderField } from '../fields/V2';
import { useDecimalsVersionsGroup } from '../../../hooks/useDecimalsVersionsGroup';

const CloseShift = ({ onSubmitEvent, setOnSubmitEvent, values, form }) => {
  const contentRef = useRef(null);
  const decimal = useSelector(decimalPrecision)
  const { isDecimalActive } = useDecimalsVersionsGroup();
  const separator = useSelector(decimalSeparator);
  const {errors, submitFailed} = useFormState()

  useEffect(() => {
    const scrollToContent = () => {
      if (contentRef.current) {
        contentRef.current.scrollIntoView({
          behavior: 'smooth',
          block: 'nearest',
          inline: 'nearest',
        });
      }
    };

    scrollToContent();

    if (onSubmitEvent) {
      setOnSubmitEvent(false);
      if (submitFailed && get(errors, 'expectedCash')) {
        scrollToContent();
      }
    }
  }, [submitFailed, errors, onSubmitEvent]);

  return (
    <>
      <div className="d-flex flex-column justify-content-start" >
        <p>{capitalize(I18n.get('expectedCash', 'valor esperado en caja'))} <span className="text-primary">*</span></p>
        <p className="description">{capitalize(I18n.get('expectedCashHelp', 'valor de efectivo esperado en caja al terminar el turno'))}</p>
        
        <Field
          name="expectedCash"
          component={renderField}
          fieldType="decimal"
          onDecimalsError={(status) => form?.change('decimalsError', {
            ...values?.decimalsError,
            initialQuantity: status,
          })}
          decimals={isDecimalActive ? decimal : null}
          separator={separator}
          required
        />
      </div>
      
      <div className="d-flex flex-column justify-content-start observations">
        <p>{capitalize(I18n.get('observations', 'observaciones'))}</p>
        <Field
          name="observations"
          component={renderField}
          fieldType="textarea" />
      </div>
      <div ref={contentRef}></div>
    </>
  )
}

export default CloseShift;
import React from 'react'
import { I18n } from '@aws-amplify/core';

import Modal from '../../common/Modal';
import Bottom from '../../modals/common/Bottom';

const CleanInvoiceConfirm = ({ isOpen, onRequestClose, onConfirm }) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={() => onRequestClose()}
      title={I18n.get('cleanSale', 'cancelar venta')}
    >
      <p className="text-center h4 text-capitalize-first pt-5 px-5">
        {I18n.get('cleanSaleMessage', 'los productos seleccionados serán limpiados de la venta actual')}
      </p>
      <p className="text-center h4 text-capitalize-first pb-5 px-5">
        {I18n.get('cleanSaleMessageQuestion', '¿Desea continuar?')}
      </p>

      <Bottom 
        onClose={() => onRequestClose()}
        onConfirm={onConfirm}
        submitText={I18n.get('accept', 'aceptar')}
      />
    </Modal>
  )
}

export default CleanInvoiceConfirm;
function AlegraAccounting({ className, id }) {
  return (
    <svg
      id={id}
      className={className}
      width='226'
      height='56'
      viewBox='0 0 226 56'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M14.8445 7.46655C12.574 7.46655 10.7334 9.26628 10.7334 11.4864V44.0467C10.7334 46.2668 12.574 48.0666 14.8445 48.0666H41.1556C43.4261 48.0666 45.2667 46.2668 45.2667 44.0467V17.5161C45.2667 15.296 43.4261 13.4963 41.1556 13.4963H38.6889V11.4864C38.6889 9.26628 36.8483 7.46655 34.5778 7.46655H14.8445ZM41.1556 44.8507H15.6667C14.7585 44.8507 14.0223 44.1308 14.0223 43.2428C14.0223 42.3548 14.7585 41.6349 15.6667 41.6349H34.5778C36.8483 41.6349 38.6889 39.8351 38.6889 37.6151V16.7121H41.1556C41.6097 16.7121 41.9778 17.072 41.9778 17.5161V44.0467C41.9778 44.4908 41.6097 44.8507 41.1556 44.8507ZM19.7778 21.1339C17.9614 21.1339 16.489 22.5737 16.489 24.3497C16.489 26.1258 17.9614 27.5656 19.7778 27.5656C21.5942 27.5656 23.0667 26.1258 23.0667 24.3497C23.0667 22.5737 21.5942 21.1339 19.7778 21.1339ZM16.489 33.9972C16.489 32.2212 17.9614 30.7814 19.7778 30.7814C21.5942 30.7814 23.0667 32.2212 23.0667 33.9972C23.0667 35.7733 21.5942 37.2131 19.7778 37.2131C17.9614 37.2131 16.489 35.7733 16.489 33.9972ZM29.6445 21.1339C27.8281 21.1339 26.3556 22.5737 26.3556 24.3497C26.3556 26.1258 27.8281 27.5656 29.6445 27.5656C31.4609 27.5656 32.9334 26.1258 32.9334 24.3497C32.9334 22.5737 31.4609 21.1339 29.6445 21.1339ZM26.3556 33.9972C26.3556 32.2212 27.8281 30.7814 29.6445 30.7814C31.4609 30.7814 32.9334 32.2212 32.9334 33.9972C32.9334 35.7733 31.4609 37.2131 29.6445 37.2131C27.8281 37.2131 26.3556 35.7733 26.3556 33.9972ZM18.9556 12.2903C17.5933 12.2903 16.489 13.3702 16.489 14.7022C16.489 16.0342 17.5933 17.1141 18.9556 17.1141H30.4667C31.829 17.1141 32.9334 16.0342 32.9334 14.7022C32.9334 13.3702 31.829 12.2903 30.4667 12.2903H18.9556Z'
        fill='#00D6BC'
      />
      <path
        d='M63.6413 21.4374C61.3855 21.4374 59.7334 19.6178 59.7334 17.3903C59.7334 15.1942 61.5126 13.3746 63.8795 13.3746C66.2306 13.3746 68.0257 15.1001 68.0257 17.3903V21.2491H66.5324V19.9001C65.9447 20.8413 64.9121 21.4374 63.6413 21.4374ZM63.8795 19.9629C65.341 19.9629 66.453 18.8021 66.453 17.406C66.453 15.9942 65.341 14.8334 63.8795 14.8334C62.434 14.8334 61.3061 15.9942 61.3061 17.406C61.3061 18.8021 62.434 19.9629 63.8795 19.9629Z'
        fill='#64748B'
      />
      <path
        d='M70.1196 10.0334H71.6923V21.2491H70.1196V10.0334Z'
        fill='#64748B'
      />
      <path
        d='M77.4154 21.4217C75.0485 21.4217 73.2852 19.7276 73.2852 17.3589C73.2852 15.1472 74.8896 13.3433 77.2248 13.3433C79.5759 13.3433 81.0374 15.0217 81.0374 17.1707V17.7982H74.8102C74.9849 19.1158 76.0016 20.0413 77.3996 20.0413C78.3527 20.0413 79.2105 19.6334 79.6712 18.7864L80.8785 19.3982C80.1954 20.7158 78.9405 21.4217 77.4154 21.4217ZM74.8737 16.7942H79.4647C79.4011 15.5707 78.4957 14.7393 77.2089 14.7393C75.8904 14.7393 75.0326 15.6491 74.8737 16.7942Z'
        fill='#64748B'
      />
      <path
        d='M85.9491 24.9668C84.2652 24.9668 82.8673 24.2452 82.1525 22.8805L83.471 22.1746C83.9316 23.006 84.7895 23.5393 85.9173 23.5393C87.5536 23.5393 88.6656 22.3785 88.6656 20.5746V19.9629C88.1572 20.7629 87.2041 21.3589 85.9015 21.3589C83.6934 21.3589 81.9459 19.6334 81.9459 17.3746C81.9459 15.1785 83.7251 13.3746 86.0921 13.3746C88.4432 13.3746 90.2382 15.1001 90.2382 17.3903V20.5589C90.2382 23.2256 88.4273 24.9668 85.9491 24.9668ZM86.0762 19.9001C87.5059 19.9001 88.6497 18.7707 88.6497 17.3589C88.6497 15.9472 87.5059 14.8491 86.0762 14.8491C84.6624 14.8491 83.5027 15.9472 83.5027 17.3589C83.5027 18.7707 84.6624 19.9001 86.0762 19.9001Z'
        fill='#64748B'
      />
      <path
        d='M93.5871 21.2491H92.0144V16.6687C92.0144 14.8021 93.0947 13.406 95.128 13.406C95.6205 13.406 96.1288 13.5001 96.526 13.6883V15.2256C96.1447 15.006 95.6999 14.8962 95.271 14.8962C94.2225 14.8962 93.5871 15.6021 93.5871 16.7315V21.2491Z'
        fill='#64748B'
      />
      <path
        d='M100.616 21.4374C98.3599 21.4374 96.7078 19.6178 96.7078 17.3903C96.7078 15.1942 98.487 13.3746 100.854 13.3746C103.205 13.3746 105 15.1001 105 17.3903V21.2491H103.507V19.9001C102.919 20.8413 101.886 21.4374 100.616 21.4374ZM100.854 19.9629C102.315 19.9629 103.427 18.8021 103.427 17.406C103.427 15.9942 102.315 14.8334 100.854 14.8334C99.4083 14.8334 98.2804 15.9942 98.2804 17.406C98.2804 18.8021 99.4083 19.9629 100.854 19.9629Z'
        fill='#64748B'
      />
      <path
        d='M68.6492 45.9404C63.7076 45.9404 59.7334 41.9358 59.7334 36.8318C59.7334 31.7802 63.7076 27.7755 68.6492 27.7755C71.656 27.7755 74.4014 29.3198 75.7348 31.8849L73.5647 33.1412C72.545 31.3876 70.7932 30.3144 68.5446 30.3144C65.1456 30.3144 62.4787 33.1412 62.4787 36.8318C62.4787 40.5747 65.1195 43.4277 68.5446 43.4277C70.7932 43.4277 72.5712 42.3545 73.5647 40.6009L75.7348 41.8572C74.4014 44.4223 71.656 45.9404 68.6492 45.9404Z'
        fill='#334155'
      />
      <path
        d='M83.4428 45.9404C79.5732 45.9404 76.5926 42.9042 76.5926 39.1875C76.5926 35.4969 79.5994 32.4869 83.4428 32.4869C87.3125 32.4869 90.2408 35.5231 90.2408 39.1875C90.2408 42.9042 87.2863 45.9404 83.4428 45.9404ZM83.4428 43.48C85.8221 43.48 87.6524 41.5431 87.6524 39.2136C87.6524 36.858 85.8221 34.9211 83.4428 34.9211C81.0374 34.9211 79.181 36.858 79.181 39.2136C79.181 41.5431 81.0374 43.48 83.4428 43.48Z'
        fill='#334155'
      />
      <path
        d='M98.7007 34.9734C96.1907 34.9734 94.9357 37.0412 94.9357 39.423V45.6263H92.3472V39.1613C92.3472 35.4184 94.5697 32.4869 98.7007 32.4869C102.884 32.4869 105.159 35.4184 105.159 39.1351V45.6263H102.57V39.4492C102.57 37.0412 101.263 34.9734 98.7007 34.9734Z'
        fill='#334155'
      />
      <path
        d='M113.914 45.9666C110.724 45.9666 109.024 43.9512 109.024 41.0197V35.0519H106.488V32.801H109.024V29.4768H111.613V32.801H116.528V35.0519H111.613V40.9411C111.613 42.6163 112.606 43.6109 114.149 43.6109C114.985 43.6109 115.901 43.2968 116.528 42.8519V45.2337C115.874 45.6787 114.855 45.9666 113.914 45.9666Z'
        fill='#334155'
      />
      <path
        d='M124.435 45.9404C120.723 45.9404 118.004 42.9042 118.004 39.1875C118.004 35.5231 120.932 32.4869 124.828 32.4869C128.697 32.4869 131.652 35.366 131.652 39.1875V45.6263H129.194V43.3753C128.227 44.9458 126.527 45.9404 124.435 45.9404ZM124.828 43.48C127.233 43.48 129.063 41.5431 129.063 39.2136C129.063 36.858 127.233 34.9211 124.828 34.9211C122.448 34.9211 120.592 36.858 120.592 39.2136C120.592 41.5431 122.448 43.48 124.828 43.48Z'
        fill='#334155'
      />
      <path
        d='M141.399 45.9404C137.53 45.9404 134.575 43.0351 134.575 39.2398V26.9118H137.164V34.3976C138.21 33.2459 139.778 32.4869 141.504 32.4869C145.4 32.4869 148.197 35.3399 148.197 39.2398C148.197 42.9304 145.269 45.9404 141.399 45.9404ZM141.399 43.5062C143.779 43.5062 145.609 41.5693 145.609 39.2136C145.609 36.8841 143.779 34.9473 141.399 34.9473C138.994 34.9473 137.164 36.8841 137.164 39.2136C137.164 41.5693 138.994 43.5062 141.399 43.5062Z'
        fill='#334155'
      />
      <path
        d='M152.141 30.2621C151.199 30.2621 150.441 29.503 150.441 28.5607C150.441 27.6185 151.199 26.8333 152.141 26.8333C153.082 26.8333 153.814 27.6185 153.814 28.5607C153.814 29.503 153.082 30.2621 152.141 30.2621ZM150.859 32.801H153.448V45.6263H150.859V32.801Z'
        fill='#334155'
      />
      <path
        d='M157.275 26.9118H159.864V45.6263H157.275V26.9118Z'
        fill='#334155'
      />
      <path
        d='M165.1 30.2621C164.159 30.2621 163.401 29.503 163.401 28.5607C163.401 27.6185 164.159 26.8333 165.1 26.8333C166.042 26.8333 166.774 27.6185 166.774 28.5607C166.774 29.503 166.042 30.2621 165.1 30.2621ZM163.819 32.801H166.408V45.6263H163.819V32.801Z'
        fill='#334155'
      />
      <path
        d='M175.854 45.9404C171.958 45.9404 169.029 42.9304 169.029 39.2398C169.029 35.3399 171.853 32.4869 175.723 32.4869C177.448 32.4869 179.017 33.2459 180.063 34.3976L180.089 26.9118H182.678V39.2398C182.678 43.0351 179.723 45.9404 175.854 45.9404ZM175.854 43.5062C178.259 43.5062 180.089 41.5693 180.089 39.2136C180.089 36.8841 178.259 34.9473 175.854 34.9473C173.474 34.9473 171.618 36.8841 171.618 39.2136C171.618 41.5693 173.474 43.5062 175.854 43.5062Z'
        fill='#334155'
      />
      <path
        d='M191.223 45.9404C187.51 45.9404 184.791 42.9042 184.791 39.1875C184.791 35.5231 187.719 32.4869 191.615 32.4869C195.484 32.4869 198.439 35.366 198.439 39.1875V45.6263H195.981V43.3753C195.014 44.9458 193.314 45.9404 191.223 45.9404ZM191.615 43.48C194.02 43.48 195.85 41.5431 195.85 39.2136C195.85 36.858 194.02 34.9211 191.615 34.9211C189.235 34.9211 187.379 36.858 187.379 39.2136C187.379 41.5431 189.235 43.48 191.615 43.48Z'
        fill='#334155'
      />
      <path
        d='M207.376 45.9404C203.48 45.9404 200.552 42.9304 200.552 39.2398C200.552 35.3399 203.376 32.4869 207.245 32.4869C208.971 32.4869 210.54 33.2459 211.585 34.3976L211.612 26.9118H214.2V39.2398C214.2 43.0351 211.246 45.9404 207.376 45.9404ZM207.376 43.5062C209.781 43.5062 211.612 41.5693 211.612 39.2136C211.612 36.8841 209.781 34.9473 207.376 34.9473C204.997 34.9473 203.14 36.8841 203.14 39.2136C203.14 41.5693 204.997 43.5062 207.376 43.5062Z'
        fill='#334155'
      />
    </svg>
  );
}

export default AlegraAccounting;

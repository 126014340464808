import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { I18n } from "@aws-amplify/core";
import { capitalize } from "lodash";

import { items as itemsSelector } from "../../../selectors/activeInvoice";
import Item from "./Item";
import Typography from "../../common/Typography";

import { Icon } from '@alegradev/smile-ui-react';
import { IconBasket } from "@tabler/icons-react";
import { _resetItemsOutOfStock } from "../../../reducers/activeInvoice";
import { showNewStockFeature } from "../Items/utils";
import { country as countrySelector} from "../../../selectors/company";
import { useItemsFromLocalDB } from "../../../hooks/useItemsFromLocalDB";

const InvoiceItems = () => {
  const items = useSelector((state) => itemsSelector(state));
  const country = useSelector(countrySelector)
  const { allItems } = useItemsFromLocalDB();

  const dispatch = useDispatch();

  useEffect(() => {
    const newStockFeature = showNewStockFeature({ country });
    if (items.length === 0 && newStockFeature) {
      dispatch(_resetItemsOutOfStock([]));
    }
  }, [items]);

  return (
    <div className="h-100 new-active-invoice__items">
      {!!items && items.length > 0 ? (
        items.map((item, index) => (
          item?.id &&(
          <Item key={index} item={item} index={index} allItems={allItems}/>
          )
        ))
      ) : (
        <div className="no-item p-2 w-100 h-100 text-muted text-capitalize-first d-flex flex-column justify-content-center align-items-center text-center">
          <Icon icon={IconBasket} size="large" width="40px" height="36" extraClass="mb-4" />
          <div className="w-50">
              <Typography
                htmlType="span"
                type="body-2-regular"
                variant="tertiary"
                text={capitalize(
                  I18n.get(
                    "selectProductsToCreateYourInvoice",
                    "selecciona productos para crear tu factura"
                  )
                )}
              />
            </div>
        </div>
      )}
    </div>
  );
};

export default InvoiceItems;

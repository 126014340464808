import { createDraftSafeSelector } from '@reduxjs/toolkit';
import { get } from 'lodash';

import { currenciesSelector } from '../reducers/currencies'
import { currency } from './company'

export const activeCurrencies = createDraftSafeSelector(
  currenciesSelector.selectAll, currency,
  (currencies, currency) => {
    if (!!currencies) {
      let actCurrencies = currencies.filter(currency => get(currency, 'status') === 'active' || !get(currency, 'status'));
      
      if (!actCurrencies.find(c => c.code === get(currency, 'code')))
        actCurrencies.push({ ...currency, exchangeRate: 1 });
      
      return actCurrencies;
    }

    return [];
  }
)

export const getCurrencyByCode = code => createDraftSafeSelector(
  state => currenciesSelector.selectById(state, code),
  currency => !!currency ? currency : null
)

export const getMainCurrency = createDraftSafeSelector(
  [activeCurrencies, currency],
  (currencies, currency) => {
    let mainCurrency = !!currencies
      ? !!currencies.find(c => c.code === get(currency, 'code'))
        ? currencies.find(c => c.code === get(currency, 'code'))
        : currency
      : currency
    return { ...mainCurrency, main: true }
  }
)
import { createDraftSafeSelector } from '@reduxjs/toolkit';
import { get } from 'lodash';

import { sellersSelector } from '../reducers/sellers'

export const activeSellers = createDraftSafeSelector(
  sellersSelector.selectAll,
  sellers => !!sellers
    ? sellers.filter(seller => get(seller, 'status') === 'active')
    : []
)

export const getSellerById = id => createDraftSafeSelector(
  state => sellersSelector.selectById(state, id),
  seller => !!seller && get(seller, 'status') === 'active' ? seller : null
)
import { I18n } from '@aws-amplify/core';
import { get, isNull, isUndefined } from 'lodash';

const commomErrors = (values) => {
  let errors = {};

  const email = get(values, 'email', '');

  if (!email)
    errors.email = I18n.get('enterAnEmail', 'Ingresa un correo electrónico');

  if (!!email && !/^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/.test(email))
    errors.email = I18n.get('enterAValidEmail', 'Ingresa un correo válido');

  return errors;
}

const colombiaErrors = (values, { isElectronic }) => {
  let errors = {};

  const identificationType = get(values, 'identification.type.key', '');
  const identificationNumber = get(values, 'identification.number', '');

  if (!identificationType)
    errors.identification = { ...errors.identification, type: I18n.get('selectTheIdentificationType', 'seleccione el tipo de identificación') };

  if (!identificationNumber)
    errors.identification = { ...errors.identification, number: I18n.get('enterAnID', 'Ingresa una identificación') };

  return errors;
}

const costaRicaErrors = (values) => {
  let errors = {};

  const identificationType = get(values, 'identification.type.key', '');
  const identificationNumber = get(values, 'identification.number', '');

  if (!identificationType)
    errors.identification = { ...errors.identification, type: I18n.get('selectTheIdentificationType', 'seleccione el tipo de identificación') };

  if (identificationType === 'CF' && !!identificationNumber && identificationNumber.length < 9)
    errors.identification = { ...errors.identification, number: I18n.get('theMinimumSizeForThisFieldIs', 'El tamaño minimo para este campo es de') + ' 9.' };
  if (identificationType === 'CF' && !!identificationNumber && identificationNumber.length > 9)
    errors.identification = { ...errors.identification, number: I18n.get('theMaximumSizeForThisFieldIs', 'El tamaño maximo para este campo es de') + ' 9.' };

  if (identificationType === 'CJ' && !/^[^-]+$/.test(identificationNumber))
    errors.identification = { ...errors.identification, number: I18n.get('theLegalIDMustBeWithoutHyphens', 'La cédula jurídica debe estar sin guiones.') };
  if (identificationType === 'CJ' && !!identificationNumber && identificationNumber.length < 10)
    errors.identification = { ...errors.identification, number: I18n.get('theMinimumSizeForThisFieldIs', 'El tamaño minimo para este campo es de') + ' 10.' };
  if (identificationType === 'CJ' && !!identificationNumber && identificationNumber.length > 10)
    errors.identification = { ...errors.identification, number: I18n.get('theMaximumSizeForThisFieldIs', 'El tamaño maximo para este campo es de') + ' 10.' };

  if (identificationType === 'DIMEX' && !/^[1-9]+[0-9]*$/.test(identificationNumber))
    errors.identification = { ...errors.identification, number: I18n.get('theDIMEXIsANumericalValue', 'El DIMEX es un valor numérico, sin ceros al inicio y sin guiones.') };
  if (identificationType === 'DIMEX' && !!identificationNumber && identificationNumber.length < 11)
    errors.identification = { ...errors.identification, number: I18n.get('theMinimumSizeForThisFieldIs', 'El tamaño minimo para este campo es de') + ' 11.' };
  if (identificationType === 'DIMEX' && !!identificationNumber && identificationNumber.length > 12)
    errors.identification = { ...errors.identification, number: I18n.get('theMaximumSizeForThisFieldIs', 'El tamaño maximo para este campo es de') + ' 12.' };

  if (identificationType === 'NITE' && !/^[^-]+$/.test(identificationNumber))
    errors.identification = { ...errors.identification, number: I18n.get('theNITEMustBeWithoutHyphens', 'El NITE debe estar sin guiones.') };
  if (identificationType === 'NITE' && !!identificationNumber && identificationNumber.length < 10)
    errors.identification = { ...errors.identification, number: I18n.get('theMinimumSizeForThisFieldIs', 'El tamaño minimo para este campo es de') + ' 10.' };
  if (identificationType === 'NITE' && !!identificationNumber && identificationNumber.length > 10)
    errors.identification = { ...errors.identification, number: I18n.get('theMaximumSizeForThisFieldIs', 'El tamaño maximo para este campo es de') + ' 10.' };

  if (identificationType === 'PE' && !!identificationNumber && identificationNumber.length > 20)
    errors.identification = { ...errors.identification, number: I18n.get('theMaximumSizeForThisFieldIs', 'El tamaño maximo para este campo es de') + ' 20.' };

  if (!identificationNumber)
    errors.identification = { ...errors.identification, number: I18n.get('enterAnID', 'Ingresa una identificación') };

  return errors;
}

const argentinaErrors = (values, { isElectronic }) => {
  let errors = {};

  const identificationType = get(values, 'identification.type.key', '');
  const identificationNumber = get(values, 'identification.number', '');

  if (!identificationType)
    errors.identification = { ...errors.identification, type: I18n.get('selectTheIdentificationType', 'seleccione el tipo de identificación') };

  if (identificationType === 'CUIT' && !/^[0-9]+[0-9\\-]+[0-9]+$/.test(identificationNumber))
    errors.identification = { ...errors.identification, number: I18n.get('CUITIdentificationIsANumericalValue', 'El CUIT debe ser un valor numérico, puede tener guiones') };

  if (!identificationNumber)
    errors.identification = { ...errors.identification, number: I18n.get('enterAnID', 'Ingresa una identificación') };

  return errors;
}

const peruErrors = (values) => {
  let errors = {};

  const identificationNumber = get(values, 'identification', '');

  if (!!identificationNumber && !!identificationNumber && identificationNumber.length < 11)
    errors.identification = I18n.get('theMinimumSizeForThisFieldIs', 'El tamaño minimo para este campo es de') + ' 11.';
  if (!!identificationNumber && !!identificationNumber && identificationNumber.length > 11)
    errors.identification = I18n.get('theMaximumSizeForThisFieldIs', 'El tamaño maximo para este campo es de') + ' 11.';

  if (!identificationNumber)
    errors.identification = I18n.get('enterAnID', 'Ingresa una identificación');

  return errors;
}

const mexicoErrors = (values) => {
  let errors = {};

  const identificationNumber = get(values, 'identification', '');

  if (!identificationNumber)
    errors.identification = I18n.get('enterAnID', 'Ingresa una identificación');

  return errors;
}

const republicaDominicanaErrors = (values) => {
  let errors = {};

  const identificationNumber = get(values, 'identification', '');

  if (!identificationNumber)
    errors.identification = I18n.get('enterAnID', 'Ingresa una identificación');

  return errors;
}

const countryErrors = (values, props) => {
  switch (props.country) {
    case 'colombia':
      return colombiaErrors(values, props);
    case 'argentina':
      return argentinaErrors(values, props);
    case 'costaRica':
      return costaRicaErrors(values, props);
    case 'peru':
      return peruErrors(values, props);
    case 'mexico':
      return mexicoErrors(values, props);
    case 'republicaDominicana':
      return republicaDominicanaErrors(values, props);
    default:
      return {};
  }
}

export const validate = (values, props) => {
  let errors = {
    ...commomErrors(values, props),
    ...countryErrors(values, props),
  };

  return errors;
}

const colombiaValues = (values, newValues) => {

  const identificationType = get(values, 'identification.type.key', null);
  const identificationNumber = get(values, 'identification.number', null);

  delete newValues.identification;
  return {
    ...newValues,
    identificationObject: {
      type: identificationType,
      number: identificationNumber
    },
  }
}

const costaRicaValues = (values, newValues) => {
  const identificationType = get(values, 'identification.type.key', null);
  const identificationNumber = get(values, 'identification.number', null);

  delete newValues.identification;
  return {
    ...newValues,
    identificationObject: {
      type: identificationType,
      number: identificationNumber
    },
  }
}

const argentinaValues = (values, newValues) => {
  const identificationType = get(values, 'identification.type.key', null);
  const identificationNumber = get(values, 'identification.number', null);

  delete newValues.identification;
  return {
    ...newValues,
    identificationObject: {
      type: identificationType,
      number: identificationNumber
    },
  }
}

const peruValues = (values, newValues) => {
  return {
    ...newValues,
    identification: get(values, 'identification.number', null),
  }
}

const defaultValues = values => ({
  identification: get(values, 'identification', null),
  email: get(values, 'email', null),
})

export const transform = (values, props) => {
  let newValues = defaultValues(values);

  switch (props.country) {
    case 'colombia':
      newValues = colombiaValues(values, newValues, props);
      break;
    case 'costaRica':
      newValues = costaRicaValues(values, newValues);
      break;
    case 'argentina':
      newValues = argentinaValues(values, newValues);
      break;
    case 'peru':
      newValues = peruValues(values, newValues);
      break;
    default:
      break;
  }

  Object.keys(newValues).map(key => {
    if (isNull(newValues[key]) || isUndefined(newValues[key]))
      delete newValues[key];
    return null;
  })

  return newValues;
}
import { useEffect, useState } from "react";
import { Field, useFormState } from "react-final-form";
import { useSelector } from "react-redux";
import { renderField, renderSelect } from "../../fields/V0/Fields";
import { get } from "lodash";
import { I18n } from "aws-amplify"

import { activeBanks } from "../../../../selectors/banks";
import { stationCashBank } from '../../../../selectors/app'

export const Cash = () => {
  const banks = useSelector(activeBanks)
  const cashBank = useSelector(stationCashBank) || null
  const [accountWarning, setAccountWarning] = useState("");
  const formState = useFormState();

  useEffect(() => {
    let account = get(formState.values, 'refund.bank', null);
    if (account && cashBank && account.id !== cashBank.id)
      setAccountWarning(I18n.get('refundAccountWarning', 'Solo se descontarán del valor en caja de tu cierre de turno los valores asociados a la cuenta bancaria del Efectivo POS.'));
    else 
      setAccountWarning("");
  }, [formState, cashBank])

  return (
    <div className="w-100 d-flex flex-column">
      <div className="w-100 row mx-0">
        <Field 
          name="refund.amount"
          type="text"
          component={renderField}
          className="col-12 col-md-6 p-0 pr-md-2 select-refund new-refund-input"
          label={I18n.get('refundAmount', 'Monto a devolver')}
          placeholder={I18n.get('enterAmount', 'Ingresar monto')}
          required
        />

        <Field
          name="refund.bank"
          type="select"
          defaultValue={cashBank}
          options={banks}
          component={renderSelect}
          getOptionValue={(option) => option.id}
          getOptionLabel={(option) => option.name}
          className="col-12 col-md-6 p-0 pl-md-2 select-refund new-refund-input"
          label={I18n.get('bankAccount', 'Cuenta bancaria')}
          warning={accountWarning}
          required
        />
      </div>

      <Field 
        name="refund.observations"
        type="text"
        component={renderField}
        className="col-12 p-0 select-refund new-refund-input"
        label={I18n.get('observations', 'Observaciones')}
      />
    </div>
  )
}
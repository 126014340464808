import React, { useEffect, useState } from 'react';
import { Field } from 'react-final-form';
import { I18n } from '@aws-amplify/core';
import { capitalize } from 'lodash';
import { renderField, renderSelect } from '../../fields/V0/Fields';
import Typography from '../../../common/Typography';
import { useDeviceType } from '../../../../hooks/useDeviceType';
import { Button } from '@alegradev/smile-ui-react';
import BankField from '../components/BankField';
import SellerField from '../components/SellerField';
import { useFormat } from '../../../../hooks/useFormat';
import AnnotationField from '../components/AnnotationField';
import { IconPlus, IconTrash } from '@tabler/icons-react';
import UseField from '../components/UseField';

const NewCombined = ({ values, form, anotationMaxLimit }) => {
  const paymentOptionsInitial = [
    { id: 1, name: 'cash', label: I18n.get('cash', 'efectivo') },
    { id: 2, name: 'debit', label: I18n.get('debit', 'débito') },
    { id: 3, name: 'credit', label: I18n.get('credit', 'crédito') },
    { id: 4, name: 'transfer', label: I18n.get('transfer', 'transferencia') },
  ];

  const [paymentOptions, setPaymentOptions] = useState(paymentOptionsInitial);
  const [paymentOptionsSelected, setPaymentOptionsSelected] = useState([
    { id: 1, name: 'cash', label: I18n.get('cash', 'efectivo') },
    { id: 2, name: 'debit', label: I18n.get('debit', 'débito') },
  ]);

  const [rowCount, setRowCount] = useState(2);
  const { fmt } = useFormat();

  const deviceType = useDeviceType();

  const handleOptionChange = (selectedOption, rowIndex) => {
    const updatedOptionsSelected = [...paymentOptionsSelected];
    const previousOption = updatedOptionsSelected[rowIndex];
    form.change(
      selectedOption.name,
      form.getState().values[previousOption.name]
    );
    form.change(previousOption.name, null);

    updatedOptionsSelected[rowIndex] = selectedOption;
    setPaymentOptionsSelected(updatedOptionsSelected);
  };

  useEffect(() => {
    const remainingOptions = paymentOptionsInitial.filter(
      (option) =>
        !paymentOptionsSelected.some(
          (selected) => selected.name === option.name
        )
    );
    setPaymentOptions(remainingOptions);

    if (rowCount > paymentOptionsSelected.length) {
      const firstAvailableOption = remainingOptions[0];
      if (firstAvailableOption) {
        setPaymentOptionsSelected((prevOptionsSelected) => {
          const updatedOptionsSelected = [...prevOptionsSelected];
          updatedOptionsSelected[rowCount - 1] = firstAvailableOption;
          return updatedOptionsSelected;
        });
      }
    }
  }, [rowCount, paymentOptionsSelected]);

  const handleDeleteRow = (indexToDelete) => {
    setRowCount((prevRowCount) => prevRowCount - 1);
    const optionToDelete = paymentOptionsSelected[indexToDelete];
    form.change(optionToDelete.name, null);
    setPaymentOptionsSelected((prevOptionsSelected) =>
      prevOptionsSelected.filter((_, index) => index !== indexToDelete)
    );
  };

  return (
    <>
      <div
        className={`${deviceType === 'mobile' && 'modal__invoice-combined-table-container'}`}
      >
        <table
          className={`modal__invoice-combined-table  ${
            deviceType === 'mobile' ? 'mobile' : ''
          }`}
        >
          <thead>
            <tr>
              <th>
                <Typography
                  text={capitalize(I18n.get('invoiceMethod', 'método de pago'))}
                  type='label-2'
                />
              </th>
              <th>
                <Typography
                  text={capitalize(I18n.get('paymentValue', 'valor del pago'))}
                  type='label-2'
                />
              </th>
              <th>
                <Typography
                  text={capitalize(I18n.get('bank', 'banco'))}
                  type='label-2'
                />
              </th>
            </tr>
          </thead>
          <tbody>
            {Array.from({ length: rowCount }).map((_, index) => {
              const paymentOption =
                paymentOptionsSelected[index % paymentOptionsSelected.length];
              const availableOptions = paymentOptions.filter(
                (option) =>
                  !paymentOptionsSelected.some(
                    (selected) => selected.name === option.name
                  )
              );
              const isLastRow = index === rowCount - 1;
              return (
                <tr key={index}>
                  <td>
                    <div className={`${index === 0 ? 'mt-3' : ''}`}>
                      <Field
                        name={`${paymentOption.name}Option`}
                        defaultValue={paymentOption}
                        options={availableOptions}
                        required
                        getOptionLabel={(option) => option.label}
                        getOptionValue={(option) => option.id}
                        menuPosition='absolute'
                        onChange={(selectedOption) => {
                          handleOptionChange(selectedOption, index);
                        }}
                      >
                        {(props) => (
                          <>
                            {renderSelect({
                              ...props,
                              fontSize:
                                deviceType === 'mobile' ? '14px' : '16px',
                              height: '46px',
                              borderRadius: '12px',
                              borderColor: '#CBD5E1',
                              fontColor: '#334155',
                            })}
                          </>
                        )}
                      </Field>
                    </div>
                  </td>
                  <td>
                    <Field
                      name={paymentOption.name}
                      className={`col-md-12 ${index === 0 ? 'mt-3' : ''}`}
                      type='number'
                      data-testid={`invoice-combined-${paymentOption.name}-input`}
                      placeholder={fmt.prefix}
                    >
                      {(props) => (
                        <>
                          {renderField({
                            ...props,
                            fontSize: deviceType === 'mobile' ? '14px' : '16px',
                            height: '46px',
                            fontColor: '#334155',
                          })}
                        </>
                      )}
                    </Field>
                  </td>

                  <td>
                    <div
                      className={`col-sm-12 d-flex ${index === 0 ? 'mt-3' : ''}`}
                    >
                      <BankField
                        name={`${paymentOption.name}Bank`}
                        isCombined={true}
                        paymentOption={paymentOption}
                        index={index}
                        isLastRow={isLastRow}
                      />
                      {isLastRow && index > 1 && (
                        <Button
                          onlyIcon={IconTrash}
                          variant='reversed'
                          onClick={() => handleDeleteRow(index)}
                          size='large'
                        />
                      )}
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <div className='modal__invoice-combined-btn-add'>
        <Button
          data-testid='add-payment-form-btn'
          type='button'
          leftIcon={IconPlus}
          text={capitalize(I18n.get('addPaymentForm', 'agregar método'))}
          size='large'
          emphasis='subtle'
          onClick={() => setRowCount(rowCount + 1)}
          disabled={rowCount === 4}
        />
      </div>

      <UseField values={values} />
      <div className='d-flex justify-content-center mt-4'>
        <SellerField />
      </div>
      <div className='col-sm-12 pl-2 pr-0'>
        <AnnotationField anotationMaxLimit={anotationMaxLimit} type='text' />
      </div>
    </>
  );
};

export default NewCombined;

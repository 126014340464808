import React from 'react';
import PropTypes from 'prop-types';
import PrintRefundColombia from './PrintRefundColombia';
import PrintRefundDefault from './PrintRefundDefault';
import PrintRefundSpain from './PrintRefundSpain';
import { COUNTRIES } from '../../../../utils/enums/countries';

/**
 * Factory to select the appropriate PrintRefund Component based on country.
 *
 * @param {string} country - The country code.
 * @param {Object} props - Props to pass to the PrintRefund component.
 * @returns {JSX.Element} - Rendered PrintRefund Component.
 */
const PrintRefundFactory = ({ country, ...props }) => {
  const strategies = {
    [COUNTRIES.COLOMBIA]: PrintRefundColombia,
    [COUNTRIES.SPAIN]: PrintRefundSpain,
  };

  const SelectedStrategy = strategies[country] || PrintRefundDefault;

  return <SelectedStrategy country={country} {...props} />;
};

PrintRefundFactory.propTypes = {
  country: PropTypes.string.isRequired,
  props: PropTypes.object,
};

export default PrintRefundFactory;

export const DEFAULT_CFDI_USE = {
  id: 23,
  key: 'S01',
  value: 'Sin efectos fiscales',
  personType: '-',
  isResico: true,
  regimes:
    '601|603|605|606|608|610|611|612|614|616|620|621|622|623|624|607|615|625|626',
};

export const CFDI_USES = [
  { key: 'G01', value: 'Adquisición de mercancías' },
  { key: 'G02', value: 'Devoluciones, descuentos o bonificaciones' },
  { key: 'G03', value: 'Gastos en general' },
  { key: 'I01', value: 'Construcciones' },
  { key: 'I02', value: 'Mobiliario y equipo de oficina por inversiones' },
  { key: 'I03', value: 'Equipo de transporte' },
  { key: 'I04', value: 'Equipo de cómputo y accesorios' },
  { key: 'I05', value: 'Dados, troqueles, moldes, matrices y herramental' },
  { key: 'I06', value: 'Comunicaciones telefónicas' },
  { key: 'I07', value: 'Comunicaciones satelitales' },
  { key: 'I08', value: 'Otra maquinaria y equipo' },
  { key: 'D01', value: 'Honorarios médicos, dentales y gastos hospitalarios' },
  { key: 'D02', value: 'Gastos médicos por incapacidad o discapacidad' },
  { key: 'D03', value: 'Gastos funerarios' },
  { key: 'D04', value: 'Donativos' },
  { key: 'D05', value: 'Intereses reales efectivamente pagados por créditos hipotecarios (casa habitación)' },
  { key: 'D06', value: 'Aportaciones voluntarias al SAR' },
  { key: 'D07', value: 'Primas por seguros de gastos médicos' },
  { key: 'D08', value: 'Gastos de transportación escolar obligatoria' },
  { key: 'D09', value: 'Depósitos en cuentas para el ahorro, primas que tengan como base planes de pensiones' },
  { key: 'D10', value: 'Pagos por servicios educativos (colegiaturas)' },
  { key: 'P01', value: 'Por definir' },
  { key: 'S01', value: 'Sin efectos fiscales' },
  { key: 'CP01', value: 'Pagos' },
  { key: 'CN01', value: 'Nómina' }
]
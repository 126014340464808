import { I18n } from 'aws-amplify';
import { get, capitalize } from 'lodash';

export const mexicoValidation = (values) => {
  let errors = {};
  const identification = get(values, 'rfc', null);
  const fiscalId = get(values, 'fiscalId', null);
  const thirdType = get(values, 'thirdType', null);
  const regime = get(values, 'regime', null);

  const zipCode = get(values, 'address.zipCode', null);
  const country = get(values, 'address.country', null);

  if (thirdType === 'NATIONAL') {
    if (!identification)
      errors.rfc = capitalize(
        I18n.get('thisFieldIsRequired', 'Este campo es obligatorio')
      );
    if (identification && identification.length < 12)
      errors.rfc = capitalize(
        `${I18n.get('theMinimumSizeForThisFieldIs', 'El tamaño minimo para este campo es de')} 12 ${I18n.get('characteres', 'caracteres')}`
      );
    if (identification && identification.length > 13)
      errors.rfc = capitalize(
        `${I18n.get('theMaximumSizeForThisFieldIs', 'El tamaño maximo para este campo es de')} 13 ${I18n.get('characteres', 'caracteres')}`
      );
  }

  if (thirdType === 'FOREIGN') {
    if (!fiscalId) {
      errors.fiscalId = capitalize(
        I18n.get('thisFieldIsRequired', 'Este campo es obligatorio')
      );
    }
  }

  if (!regime) {
    errors.regime = capitalize(
      I18n.get('thisFieldIsRequired', 'Este campo es obligatorio')
    );
  }

  if (zipCode) {
    if (zipCode.length !== 5 || !/^[0-9]+$/.test(zipCode)) {
      errors.address = {
        ...errors.address,
        zipCode: capitalize(
          I18n.get(
            'theZipCodeMustBe5Digits',
            'El código postal debe ser de 5 dígitos'
          )
        ),
      };
    }
  } else {
    errors.address = {
      ...errors.address,
      zipCode: capitalize(
        I18n.get('thisFieldIsRequired', 'Este campo es obligatorio')
      ),
    };
  }

  if (!country) {
    errors.address = {
      ...errors.address,
      country: capitalize(
        I18n.get('thisFieldIsRequired', 'Este campo es obligatorio')
      ),
    };
  }

  return errors;
};

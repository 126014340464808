import { merge } from 'lodash'

import http from '../utils/http'
import Cookie from 'js-cookie';

const baseUrl = process.env.REACT_APP_POS_DESKTOP_ENDPOINT
const baseConfig = {
  headers: {
    'x-api-key': process.env.REACT_APP_POS_DESKTOP_AUTHORIZATION,
  }
}

const get = async (url, params = null, userConfig = null) => await http.get(`${baseUrl}${url}`, params, merge(baseConfig, userConfig), Cookie.get("pos-block-api-request"));

const posDesktopAPI = {
  get,
  v2: {
    get: async (url, params = null, userConfig = null) => await http.get(`${baseUrl.replace("v1", "v2")}${url}`, params, merge(baseConfig, userConfig), Cookie.get("pos-block-api-request")),
  }
}

export default posDesktopAPI
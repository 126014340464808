import React from 'react';

import SwitchComponent from 'react-switch';
import PropTypes from 'prop-types';

const Switch = (props) => {
  return (
    <SwitchComponent
      onChange={props?.onChange ? props.onChange : () => null}
      disabled={props.disabled}
      uncheckedIcon={false}
      checkedIcon={false}
      onColor='#00b19d'
      offColor='#ccc'
      size={props.small ? 12 : props.large ? 18 : 12}
      height={props.small ? 12 : props.large ? 18 : 12}
      width={38}
      handleDiameter={props.small ? 10 : props.large ? 16 : 10}
      checked={props.active}
      {...props}
    />
  );
};

Switch.propTypes = {
  small: PropTypes.bool,
  large: PropTypes.bool,
  disabled: PropTypes.bool,
  active: PropTypes.bool,
  onChange: PropTypes.func,
};

export default Switch;

import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { Field } from 'react-final-form';
import { I18n } from '@aws-amplify/core';
import { get } from 'lodash';
import BigNumber from 'bignumber.js';

import { useFormat } from '../../../hooks/useFormat';
import { sendNewGTMEvent } from '../../../reducers/company';
import {
  country as countrySelector,
  externalProviderState,
  currency as companyCurrencySelector,
} from '../../../selectors/company';
import {
  itemsCount as itemsCountSelector,
  currency as currencySelector,
  numeration as numerationSelector,
} from '../../../selectors/activeInvoice';
import { replaceAndParse } from '../../../utils';

import NewExternalProvidersMethodsModals from './NewExternalProvidersMethodsModals';
import Nequi from '../../svg/paymentMethods/Nequi';
import WompiBancolombia from '../../../assets/images/wompi_bancolombia.png';
import Wompi from '../../../assets/images/wompi.png';
import Bancolombia from '../../../assets/images/bancolombia-logo.png';
import { useDecimalsVersionsGroup } from '../../../hooks/useDecimalsVersionsGroup';
import { Tooltip } from '@alegradev/smile-ui-react';

import { Icon } from '@alegradev/smile-ui-react';
import { IconBuildingBank } from '@tabler/icons-react';

const WOMPI_MIN_TOTAL = new BigNumber(1500);

const NewOtherMethods = ({ form, total }) => {
  const dispatch = useDispatch();
  const { isDecimalActive } = useDecimalsVersionsGroup();
  const [isOpen, setIsOpen] = useState(false);
  const [method, setMethod] = useState();
  const country = useSelector(countrySelector);
  const nequiState = useSelector(externalProviderState('nequi'));
  const wompiState = useSelector(externalProviderState('wompi'));
  const numeration = useSelector(numerationSelector);
  const currency = useSelector(currencySelector);
  const companyCurrency = useSelector(companyCurrencySelector);
  const itemsCount = useSelector(itemsCountSelector);

  const { fmt, decimal } = useFormat();

  const sendPaymentMethodSelectedEvent = (paymentMethod) => {
    dispatch(sendNewGTMEvent("pos-sale-integration-method-selected", {
      type: paymentMethod,
      amount: parseFloat(total),
    }))
  }

  const renderWompiMethods = () => {
    if (wompiState === 'active')
      return (
        <>
          <Field
            name="method"
            className="col-md-4"
          >
            {props => (
              <Tooltip
                visible={total.isLessThan(WOMPI_MIN_TOTAL)}
                overlay={replaceAndParse(I18n.get("bancolombiaMinTotalError", "El monto mínimo es de {} COP para pagos con QR Bancolombia."), [WOMPI_MIN_TOTAL.toFormat(decimal, fmt)])}
                placement="bottom"
              >
                <button
                  type="button"
                  className="btn btn-invoice-method button-transparent border pb-4 pt-2 px-5 d-flex flex-column align-items-center"
                  onClick={() => {
                    sendPaymentMethodSelectedEvent('wompi-bancolombia')
                    props.input.onChange('bancolombiaQR')
                  }}
                  disabled={total.isLessThan(WOMPI_MIN_TOTAL)}
                >
                  <img style={{ width: "4rem", height: "2.2rem" }} className="mb-1" src={Wompi} alt="wompi" />
                  <img style={{ width: "3.2rem", height: "3.1rem" }} className="mb-2" src={Bancolombia} alt="bancolombia" />
                  <p className="text-uppercase h5 text-muted font-weight-bold">{I18n.get('', 'QR Bancolombia')}</p>
                </button>
              </Tooltip>
            )}
          </Field>

          <Field
            name="method"
            className="col-md-4"
          >
            {props => (
              <Tooltip
                visible={total.isLessThan(WOMPI_MIN_TOTAL)}
                overlay={replaceAndParse(I18n.get("nequiMinTotalError", "El monto mínimo es de {} COP para pagos con Nequi."), [WOMPI_MIN_TOTAL.toFormat(decimal, fmt)])}
                placement="bottom"
              >
                <button
                  type="button"
                  className="btn btn-invoice-method button-transparent border pb-4 pt-2 px-5 d-flex flex-column align-items-center justify-content-between"
                  onClick={() => {
                    sendPaymentMethodSelectedEvent('wompi-nequi')
                    props.input.onChange('nequiPush')
                  }}
                  disabled={total.isLessThan(WOMPI_MIN_TOTAL)}
                >
                  <img style={{ width: "4rem", height: "2.2rem" }} className="mb-1" src={Wompi} alt="wompi" />
                  <Nequi />
                  <p className="text-uppercase h5 text-muted font-weight-bold">{I18n.get('nequi', 'Nequi')}</p>
                </button>
              </Tooltip>
            )}
          </Field>
        </>
      )
    return (
      <Field
        name="method"
        className="col-md-4"
      >
        {props => (
          <button
            type="button"
            className="btn btn-invoice-method button-transparent border py-4 px-5 d-flex flex-column align-items-center"
            onClick={() => {
              setMethod('wompi')
              setIsOpen(true)
            }}
          >
            <img src={WompiBancolombia} alt="wompi-bancolombia" />
          </button>
        )}
      </Field>
    )
  }

  return (
    <div className="form-body h-100 p-4 overflow-hidden">
      <div className="form-body__fields form-row align-items-center justify-content-around">
        <Field
          name="method"
          className="col-md-4"
        >
          {props => (
            <button
              type="button"
              data-testid="transfer-method-btn"
              className="btn btn-invoice-method button-transparent border py-4 px-5 d-flex flex-column align-items-center"
              onClick={() => {
                sendPaymentMethodSelectedEvent('transfer')
                props.input.onChange('transfer')
                form.change('transfer', total)
                if (isDecimalActive)
                  form.change('transfer', new BigNumber(total).toFixed(decimal).toString())
              }}
            >
              <Icon icon={IconBuildingBank} size='extraLarge' type='primary' />
              <p className="text-uppercase h5 text-muted font-weight-bold">{I18n.get('transfer', 'transferencia')}</p>
            </button>
          )}
        </Field>

        {country === 'colombia' && (
          <>
            <Field
              name="method"
              className="col-md-4"
            >
              {props => (
                <button
                  type="button"
                  className="btn btn-invoice-method button-transparent border py-4 px-5 d-flex flex-column align-items-center"
                  onClick={() => {
                    if (nequiState === 'active') {
                      sendPaymentMethodSelectedEvent('nequi')
                      props.input.onChange('nequi')
                    } else {
                      setMethod('nequi')
                      sendPaymentMethodSelectedEvent('nequi')
                      setIsOpen(true)
                    }
                  }}
                >
                  <Nequi />
                  <p className="text-uppercase h5 text-muted font-weight-bold">{I18n.get('nequi', 'nequi')}</p>
                </button>
              )}
            </Field>

            {renderWompiMethods()}

          </>
        )}

      </div>

      <NewExternalProvidersMethodsModals
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        method={method}
        total={parseFloat(total)}
      />
    </div>
  )
}

export default NewOtherMethods;
import { Form } from 'react-final-form';
import { I18n } from '@aws-amplify/core';
import { useHistory } from 'react-router-dom';

import { useGlobalInvoice } from '../../../pages/globalInvoices/invoice/context';
import GlobalInvoiceFormHeader from './header';
import Button from '../../common/Button';
import Typography from '../../common/Typography';
import validate from './utils';
import useElectronicPayrollStatus from '../../../hooks/useElectronicPayrollStatus/hook';

function GlobalInvoiceForm({ children, onSubmitInvoice, isLoading }) {
  const history = useHistory();
  const { isActive } = useElectronicPayrollStatus();
  const { selectedRows, initialValues, isEditable } = useGlobalInvoice();

  const handleOnSubmit = (values) =>
    onSubmitInvoice({ ...values, selectedRows });

  return (
    <Form
      onSubmit={handleOnSubmit}
      validate={(values) => validate(values, isEditable)}
      keepDirtyOnReinitialize
      initialValues={initialValues}
    >
      {({ handleSubmit, submitting, validating, invalid, values }) => (
        <>
          <form noValidate onSubmit={handleSubmit}>
            <div className='container-light'>
              <div className='form-body overflow-hidden'>
                <div className='form-body__fields'>
                  <GlobalInvoiceFormHeader />
                </div>

                {children}
              </div>
            </div>
            
            <div className='d-flex justify-content-end mt-4'>
              <Button
                onClick={() => history.goBack()}
                variant='outline'
                disabled={isLoading}
                size='md'
                type='button'
                className='mr-3'
              >
                <Typography text={I18n.get('cancel', 'Cancelar')} />
              </Button>
              {isEditable && (
                <Button
                  variant={isActive ? 'outline' : 'filled'}
                  className={isActive ? 'mr-3' : ''}
                  size='md'
                  type='button'
                  onClick={() =>
                    handleOnSubmit({
                      ...values,
                      isEditable,
                      isDraft: true,
                    })
                  }
                  loading={isLoading}
                  disabled={
                    isLoading ||
                    submitting ||
                    invalid ||
                    validating ||
                    selectedRows.length === 0
                  }
                >
                  <Typography
                    text={I18n.get('save-changes', 'Guardar cambios')}
                  />
                </Button>
              )}
              {!isEditable && (
                <Button
                  variant={isActive ? 'outline' : 'filled'}
                  className={isActive ? 'mr-3' : ''}
                  size='md'
                  type='button'
                  onClick={() => handleOnSubmit({ ...values, isDraft: true })}
                  loading={isLoading}
                  disabled={
                    isLoading ||
                    submitting ||
                    invalid ||
                    validating ||
                    selectedRows.length === 0
                  }
                >
                  <Typography
                    text={I18n.get('save-as-draft', 'Guardar como borrador')}
                  />
                </Button>
              )}
              {isActive && (
                <Button
                  variant='filled'
                  size='md'
                  type='button'
                  onClick={() =>
                    handleOnSubmit({
                      ...values,
                      isDraft: false,
                      isEditable,
                    })
                  }
                  loading={isLoading}
                  disabled={
                    isLoading ||
                    submitting ||
                    invalid ||
                    validating ||
                    selectedRows.length === 0
                  }
                >
                  <Typography text={I18n.get('stamp', 'Timbrar')} />
                </Button>
              )}
            </div>
            <div className='mt-5' />
          </form>
        </>
      )}
    </Form>
  );
}

export default GlobalInvoiceForm;

import React from 'react';
import { Icon } from '@alegradev/smile-ui-react';
import PropTypes from 'prop-types';
import { I18n } from '@aws-amplify/core';

/**
 * RefundHeader Component
 *
 * Displays the header section of the refund modal, including the title and back button.
 *
 * @param {Object} props - Component props.
 * @param {number} props.step - Current step in the refund process.
 * @param {boolean} props.isSelectingMode - Flag indicating if items are being selected.
 * @param {Function} props.changeStep - Function to change the current step.
 * @returns {JSX.Element} - The rendered component.
 */
const RefundHeader = ({ step, isSelectingMode, changeStep }) => {
  const showBackButton = step > 2 && !isSelectingMode;

  return (
    <div className='d-flex'>
      {showBackButton && (
        <button
          type='button'
          className='button-go-back-arrow d-block d-md-none'
          onClick={() => changeStep(prevStep)}
        >
          <Icon icon='arrow-left' extraClass="icon-black mr-2" />
        </button>
      )}
      <h3 className='title-new-refund'>
        {I18n.get('newRefund', 'nueva devolución')}
      </h3>
    </div>
  );
};

RefundHeader.propTypes = {
  step: PropTypes.number.isRequired,
  isSelectingMode: PropTypes.bool.isRequired,
  changeStep: PropTypes.func.isRequired,
};

export default React.memo(RefundHeader);

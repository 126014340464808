import React, { useEffect, useState } from 'react';
import { Field } from 'react-final-form';
import { I18n } from '@aws-amplify/core';

import { renderField, renderSelect } from '../../fields/V0/Fields';
import { useSelector } from 'react-redux';
import { languageSelector } from '../../../../selectors/auth';
import { get } from 'lodash';

import configAPI from '../../../../reducers/configAPI';

const renderInfo = props => (
  <>
    <Field
      name="email"
      className="col-md-6"
      component={renderField}
      type="text"
      label={I18n.get('email', 'Correo')}
    />

    <Field
      name="phone"
      className="col-md-6"
      component={renderField}
      type="text"
      label={I18n.get('phone', 'Teléfono')}
    />
    
    <Field
      name="website"
      className="col-md-6"
      component={renderField}
      type="text"
      label={I18n.get('website', 'Página web')}
    />
    {true && (
      <SectorSelector {...props} />
    )}
  </>
)

const SectorSelector = (props) => {
  const [loading, setLoading] = useState(false)
  const [sectors, setSectors] = useState([])
  const [errors, setErrors] = useState(0)
  const language = useSelector(languageSelector)

  const change = props.form.change

  useEffect(() => {
    const loadData = async () => {
      if (!get(sectors, 'length') && errors < 5) {
        try {
          setLoading(true)
          // ex queries.allCompanySectors

          const response = await configAPI.get('/sectors', {
            application_version: props.country,
            lang: language.slice(0, 2),
          })
          setLoading(false)

          const allSectors = get(response, 'data', [])
          setSectors(allSectors)

          if (!props.values.sector)
            change('sector', allSectors.find(s => get(s, 'key') === 'RETAIL') || allSectors[0])
        } catch {
          setLoading(false)
          setErrors(errors + 1)
        }
      }

    }

    loadData()
  }, [errors, sectors, props.country, language, change, props.values.sector])

  return (
    <Field
      name="sector"
      isLoading={loading}
      options={sectors}
      getOptionLabel={option => option.value}
      getOptionValue={option => option.key}
      component={renderSelect}
      className="col-md-6"
      label={I18n.get('sector', 'Sector')}
    />
  )
}

export default renderInfo;
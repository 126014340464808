const identifications = [
  {
    "id": "5",
    "value": "DNI",
    "abbreviature": "DNI",
    "key": "DNI"
  },
  {
    "id": "1",
    "value": "CUIT",
    "abbreviature": "CUIT",
    "key": "CUIT",
    "rules": {
      "regex": {
        "regex": "^[0-9]+[0-9\\-]+[0-9]+$",
        "invalidRegexText": "El CUIT debe ser un valor numérico, puede tener guiones"
      }
    }
  },
  {
    "id": "3",
    "value": "CI Extranjera",
    "abbreviature": "CI",
    "key": "CI"
  },
  {
    "id": "4",
    "value": "Pasaporte",
    "abbreviature": "PP",
    "key": "PASSPORT"
  },
  {
    "id": "2",
    "value": "CDI",
    "abbreviature": "CDI",
    "key": "CDI"
  },
  {
    "id": "6",
    "value": "Otro",
    "abbreviature": "OTRO",
    "key": "OTHER"
  }
]

export default identifications;

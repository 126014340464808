import { I18n } from '@aws-amplify/core';
import { get, capitalize } from 'lodash';

export const validate = values => {
  let errors = {}

  const name = get(values, 'name');
  const options = get(values, 'options', []);


  if (!name)
    errors.name = capitalize(I18n.get('enterAName', 'Ingresa un nombre'));


  if (!!get(options, 'length')) {
    const optionsValues = options.filter(opt => !!get(opt, 'value'))

    if (!optionsValues.length) {
      errors.options = [{
        value: capitalize(I18n.get('youNeedAtLeastOneVariant', 'Necesitas crear al menos una variante'))
      }]
    }
  }

  return errors;
}

export const transform = values => {
  let newValues = {
    name: get(values, 'name', null),
    options: get(values, 'options')
  }

  return newValues;
}
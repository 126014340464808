import { useSelector } from 'react-redux';
import {
  country as companyCountry,
  electronicInvoicing,
} from '../../selectors/company';
import { useTicketBaiInformation } from '../useTicketBaiElegible';
import { COUNTRIES } from '../../utils/enums/countries';
import { useMemo } from 'react';


const AVAILABLE_COUNTRIES = [
  COUNTRIES.PERU,
  COUNTRIES.PANAMA,
  COUNTRIES.REPUBLICA_DOMINICANA,
  COUNTRIES.COLOMBIA,
  COUNTRIES.ARGENTINA,
  COUNTRIES.COSTA_RICA,
  COUNTRIES.MEXICO,
  COUNTRIES.SPAIN
];

const useElectronicPayrollStatus = () => {

  const { isElectronic, country } = useSelector(state => ({
    isElectronic: electronicInvoicing(state),
    country: companyCountry(state)
  }));

  const { isTicketBaiEligible, isTicketBaiEnabled } = useTicketBaiInformation();

  const isSpain = country === COUNTRIES.SPAIN;
  const isAvailableCountry = AVAILABLE_COUNTRIES.includes(country);

  const isActive = isSpain ? isTicketBaiEnabled : isElectronic;

  const canElectronicPayroll = isSpain
    ? isAvailableCountry && isTicketBaiEligible
    : isAvailableCountry;

  return useMemo(() => ({
    isActive,
    availableCountries: AVAILABLE_COUNTRIES,
    canElectronicPayroll
  }), [isActive, isAvailableCountry, canElectronicPayroll]);
};

export default useElectronicPayrollStatus;

import { useCallback, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { get } from "lodash";
import { API, graphqlOperation } from "aws-amplify";
import { useQueryClient } from "@tanstack/react-query";

import { idCompanySelector } from "../selectors/company";
import * as subscriptions from "../graphql/subscriptions";
import { itemCategoriesKeys } from "../components/inventory/Categories/queries";
import alegraAPI from "../reducers/alegraAPI";
import { addItemCategory, removeItemCategory, updateItemCategory } from "../reducers/itemCategories";

const itemCategorySns = () => {
  const ref = useRef();
  const idCompany = useSelector(idCompanySelector);
  const networkStatus = useSelector((state) => get(state, "app.networkStatus", "online"));
  const dispatch = useDispatch();
  const queryClient = useQueryClient()

  const getItemCategory = useCallback(async (id) => {
    try {
      const response = await alegraAPI.get(`/item-categories/${id}`);
      return get(response, "data", null);
    } catch (error) {
      console.log("Error", error);
      return null;
    }
  }, []);

  useEffect(() => {
    let subscription = null;

    if (networkStatus === "online") {
      subscription = API.graphql(graphqlOperation(subscriptions.onCreateItemCategoryNotify, {
        channel: `pos_${idCompany}`
      }))
        .subscribe({
          next: async (data) => {
            await queryClient.refetchQueries(itemCategoriesKeys.all);

            const itemCategory = await getItemCategory(get(data, "value.data.onCreateItemCategoryNotify.id"));
            if (!!itemCategory) {
              dispatch(addItemCategory(itemCategory));
            }
          },
          error: () => setTimeout(() => {
            if (!!ref.current)
              console.log("Error");
          }, 60000)
        });
    }

    return () => {
      if (subscription)
        subscription.unsubscribe();
    };
  }, [networkStatus, idCompany]);

  useEffect(() => {
    let subscription = null;

    if (networkStatus === "online") {
      subscription = API.graphql(graphqlOperation(subscriptions.onUpdateItemCategoryNotify, {
        channel: `pos_${idCompany}`
      }))
        .subscribe({
          next: async (data) => {
            await queryClient.refetchQueries(itemCategoriesKeys.all);

            const itemCategory = await getItemCategory(get(data, "value.data.onUpdateItemCategoryNotify.id"));
            if (!!itemCategory) {
              dispatch(updateItemCategory({
                id: itemCategory.id,
                changes: itemCategory
              }));
            }
          },
          error: () => setTimeout(() => {
            if (!!ref.current)
              console.log("Error");
          }, 60000)
        });
    }

    return () => {
      if (subscription)
        subscription.unsubscribe();
    };
  }, [networkStatus, idCompany]);


  useEffect(() => {
    let subscription = null;

    if (networkStatus === "online") {
      subscription = API.graphql(graphqlOperation(subscriptions.onDeleteItemCategoryNotify, {
        channel: `pos_${idCompany}`
      }))
        .subscribe({
          next: async (data) => {
            await queryClient.refetchQueries(itemCategoriesKeys.all);

            dispatch(removeItemCategory(get(data, "value.data.onDeleteItemCategoryNotify.id")));
          },
          error: () => setTimeout(() => {
            if (!!ref.current)
              console.log("Error");
          }, 60000)
        });
    }

    return () => {
      if (subscription)
        subscription.unsubscribe();
    };
  }, [networkStatus, idCompany]);

  return null;
};

export default itemCategorySns;

import { get } from 'lodash';
import dominicanaAddresses from '../../../../../../components/countriesData/republicaDominicana/municipalityProvince.json';

function republicaDominicanaInitialValues({
  contact,
  defaultAccountReceivableCategory,
  defaultDebtToPayCategory,
  ...props
}) {
  if (!!contact) {
    return {
      ...contact,
      ...contact?.nameObject,
      firstName: contact?.nameObject?.firstName || contact?.name,
      identification: {
        ...contact?.identificationObject,
        number: contact?.identificationObject
          ? contact?.identificationObject?.number
          : contact?.identification?.number,
        type: contact?.identificationObject
          ? contact?.identificationObject?.type?.key
            ? contact?.identificationObject?.type?.key
            : contact?.identificationObject?.type
          : contact?.identification?.type,
      },
      email: contact?.email,
      address: {
        ...contact?.address,
        combined: `${contact?.address?.municipality}, ${contact?.address?.province}`,
      },
      seller: contact?.seller?.id
        ? contact?.seller?.id?.toString()
        : contact?.seller,
      priceList: contact?.priceList?.id
        ? contact?.priceList?.id?.toString()
        : contact?.priceList,
      term: contact?.term?.id ? contact?.term?.id?.toString() : contact?.term,
      statementAttached: contact?.statementAttached,
      accounting: {
        accountReceivable: contact?.accounting?.accountReceivable?.id,
        debtToPay: contact?.accounting?.debtToPay?.id,
      },
      creditLimit: contact?.creditLimit,
    };
  }

  return {
    accounting: {
      accountReceivable: defaultAccountReceivableCategory?.id,
      debtToPay: defaultDebtToPayCategory?.id,
    },
    address: {
      country: 'República Dominicana',
      combined: dominicanaAddresses.find(
        (option) =>
          option.value ===
          `${get(props, 'companyAddress.municipality', null)}, ${get(props, 'companyAddress.province', null)}`
      ),
    },
    statementAttached: true,
    ignoreRepeated: false,
  };
}

export default republicaDominicanaInitialValues;

import React from 'react';
import { Field } from 'react-final-form';
import { useSelector } from 'react-redux'
import { I18n } from '@aws-amplify/core';
import { capitalize, get } from 'lodash';

import { renderField, renderDynamicField } from '../fields/V0/Fields';
import { customFields as customFieldsSelector } from '../../../selectors/customFields'

const AdvancedData = () => {
  const customFields = useSelector(customFieldsSelector)

  return (
    <div className="form-body__fields form-row justify-content-start">

      {customFields.map((field, index) => (
        <Field
          key={index}
          name={`customFields.cf${get(field, 'id')}`}
          label={get(field, 'name')}
          fieldType={get(field, 'type')}
          options={get(field, 'options')}
          component={renderDynamicField}
          className="col-12 col-md-6"
          required={!!get(field, 'settings.isRequired', null)}
          fromModal
        />
      ))}
      
      <Field
        name="description"
        component={renderField}
        className="col-12"
        type="textarea"
        label={capitalize(I18n.get('description', 'descripción'))}
        help={I18n.get('itemDescriptionHelp', 'Clasifica tus productos y servicios por tipos o grupos')}
      />
      
    </div>
  )
}

export default AdvancedData;

import React, { useMemo } from 'react'
import { I18n } from '@aws-amplify/core';
import { useDispatch, useSelector } from 'react-redux'
import { capitalize, get } from 'lodash'

// import { removeItems, updateItem as updateItemReducer } from '../../../reducers/items';
import { updateItem as updateItemReducer } from '../../../reducers/items';
import alegraAPI from '../../../reducers/alegraAPI';
import { hasPermissionTo } from '../../../selectors/auth';
import { handleError } from '../../../utils/errors'
import { replaceAndParse, toast } from '../../../utils'

import { Tooltip, Icon } from '@alegradev/smile-ui-react';
import { IconEye, IconEyeOff } from '@tabler/icons-react';

const MultipleItemsActions = ({ selectedItems, show, onRefresh, startLoading, stopLoading }) => {
  const can = useSelector(hasPermissionTo);
  const dispatch = useDispatch();

  const [ids, itemsById] = useMemo(() => {
    const ids = [];
    const itemsById = {};
    selectedItems.forEach(item => {
      ids.push(item.original.id);
      itemsById[item.original.id] = item.original;
    })
    return [ids, itemsById]
  }, [selectedItems])

  // const deleteSelectedItems = async () => {
  //   startLoading()
  //   try {
  //     await alegraAPI.delete(`/items?id=${ids.join(',')}`);
  //     stopLoading()
  //     for (let i = 0; i < ids.length; i++) {
  //       toast.success({
  //         title: I18n.get('itemDeleted', 'producto eliminado'),
  //         subtitle: 'El producto ' + itemsById[ids[i]].name + ' fue eliminado con éxito',
  //         params: { autoClose: false }
  //       })
  //     }

  //     dispatch(removeItems(ids));

  //     onRefresh()
  //   } catch (error) {
  //     stopLoading()
  //     if (get(error, 'response.data.error.length', 0) !== 0) {
  //       error.response.data.error.forEach(errorObj => {
  //         toast.error({
  //           title: 'Error eliminando ' + itemsById[errorObj.id].name,
  //           subtitle: errorObj.message,
  //         })
  //       })
  //       error.response.data.success.forEach(successObj => {
  //         toast.success({
  //           title: I18n.get('itemDeleted', 'producto eliminado'),
  //           subtitle: 'El producto ' + itemsById[successObj.id].name + ' fue eliminado con éxito',
  //           params: { autoClose: false }
  //         })
  //       })
  //     } else {
  //       let responseError = get(error, 'response.data.message');
  //       toast.error({
  //         title: I18n.get('itemDeletedError', 'error eliminando producto'),
  //         subtitle: !!responseError ? responseError : handleError(error),
  //       })
  //     }
  //   }
  // }

  const processItemUpdate = (status, newItem) => {
    toast.success({
      title: status === 'active'
        ? replaceAndParse(I18n.get('itemWithNameActivated', 'Item {} activado con éxito'), [itemsById[newItem.id]?.name])
        : replaceAndParse(I18n.get('itemWithNameDeactivated', 'Item {} desactivado con éxito'), [itemsById[newItem.id]?.name]),
    })
    dispatch(updateItemReducer({
      id: newItem.id,
      changes: newItem,
    }));
  }

  const modifyStatusOnSelectedItems = async (status) => {
    startLoading()
    try {
      const arrayOfPromises = ids.map((itemId) => alegraAPI.put(`/items/status?id=${itemId}`, { status }));

      const results = await Promise.allSettled(arrayOfPromises);

      results.forEach((result) => {
        if (result.status === 'fulfilled' && result?.value?.data?.success?.length) {
          processItemUpdate(status, result?.value?.data?.success[0])
        }

        if (result.status === 'fulfilled' && result?.value?.data?.error?.length) {
          result?.value?.data?.error.forEach((error) => {
            const message = get(error, 'message');
            toast.error({
              title: I18n.get('itemStatusError', 'error al cambiar estado del item'),
              subtitle: !!message ? message : handleError(error)
            })
          })
        }
      })

      stopLoading()
      onRefresh()
    } catch (error) {
      stopLoading()
      let responseError = get(error, 'response.data.message');
      toast.error({
        title: I18n.get('itemStatusError', 'error al cambiar estado del item'),
        subtitle: !!responseError ? responseError : handleError(error)
      })
    }
  }

  if (!show) {
    return null
  }

  return (
    <div className="action-buttons mb-4">
      <Tooltip
        visible={!can('edit', 'items')}
        overlay={I18n.get('userNotAllowed.items.edit', 'no tienes permisos para editar productos')}
      >
        <button
          type="button"
          className="btn-action d-flex justify-content-center align-items-center"
          onClick={async () => modifyStatusOnSelectedItems('active')}
        >
          <div className="mr-2">
            <Icon icon={IconEye} />
          </div>
          {capitalize(I18n.get('activate', 'activar'))}
        </button>
      </Tooltip>

      <Tooltip
        visible={!can('edit', 'items')}
        overlay={I18n.get('userNotAllowed.items.edit', 'no tienes permisos para editar productos')}
      >
        <button
          type="button"
          className="btn-action ml-3 d-flex justify-content-center align-items-center"
          onClick={() => modifyStatusOnSelectedItems('inactive')}
        >
          <div className='mr-2'>
            <Icon icon={IconEyeOff} />
          </div>
          {capitalize(I18n.get('deactivate', 'desactivar'))}
        </button>
      </Tooltip>
    </div>
  )
}

export default MultipleItemsActions;
import es from "./es";

const dictionary = {
  ...es,
  "createItemSubmitError1Message":
    "Desde la sección variantes debes seleccionar por lo menos una opción e indicar la cantidad disponible en almacén de las que elijas.",
  "createWarehouse": "Crear almacén",
  "AlegraAccounting": "Contabilidad",
  "AlegraAccounting.description": "Gestiona las cuentas de tu negocio",
  "addWarehouse": "Agregar almacén",
  "AlegraPOS": "Punto de venta",
  "AlegraPOS.description": "Factura fácil en tus puntos de venta",
  "AlegraShop.description": "Crea tu tienda virtual fácil y rápido",
  "configureFEmodal.title": "¡Facturas electrónicas en Alegra POS!",
  "configureFEmodal.description": "Habilítate ante la DGII y <b>empieza a emitir</b> tus facturas electrónicas desde tu punto de venta.",
  "configureStationWarehouse": "Configurar almacén",
  "currentShiftDetails.description": "Conoce los movimientos de efectivo en tu turno de caja actual.",
  "createWarehouseError": "Error en la creación de almacén",
  "distributeInWarehouses": "Distribuir en almacenes",
  "goToTutorial": "Conocer cómo habilitarme",
  "howToEmit": "Cómo emitirlas",
  "indicateTheVariantQuantity": "Indica aquí las cantidades en tus almacenes",
  "inventariableHelp": "Distribuye tus productos entre tus almacenes",
  "inventoryWarehouses": "Almacenes de inventario",
  "invoiceModalTitle.invoice.electronic": "Factura electrónica",
  "itemVariantHelp": "Indica aquí las cantidades en tus almacenes",
  "itemCategoriesAreEmpty": "Las categorías que seleccionaste están vacías en el almacén de esta terminal. 🔍",
  "itemCategoryIsEmpty": "La categoría {} está vacía en el almacén de esta terminal. 🌪️",
  "itemCreatedSuccessfullyMessageAlter": "Ten en cuenta que no lo asociaste al almacén de tu terminal actual y para venderlo debes ",
  "itemCreatedSuccessfullyMessageLink": "cambiar de terminal o editar esta.",
  "learnToUseShifts.link": "https://ayuda.alegra.com/es/gestiona-los-turnos-de-caja-en-el-sistema-punto-de-venta-rep%C3%BAblica-dominicana ",
  "myWarehouses": "mis almacenes",
  "networkErrorMessage": "Revisa tu conexión a internet e intenta emitir de nuevo tu documento",
  "newInventoryAdjustmentSubtitle": "Modifica las cantidades de los productos que tienes en el almacén de tu terminal actual.",
  "newWarehouse": "Nuevo almacén",
  "numerationExpired": "Numeración vencida 📅",
  "numerationExpired.body": "Elige otra numeración o solicita una nueva en la oficina virtual de la DGII.",
  "numerationExpired.button": "Cómo solicitar otra",
  "numerationExpired.notification.title": "¡Se venció la fecha de tu numeración! 📅",
  "numerationExpired.notification.text": "Ten en cuenta que la numeración {} ya está vencida y a partir del 13 de julio no podrás usarla al vender.",
  "numerationMaxNumberReached.body": "Elige otra numeración o solicita ampliación de tus comprobantes a la DGII.",
  "numerationMaxNumberReached.button": "Cómo solicitar más",
  "numerationMaxNumberReached.notification.title": "¡Se acabaron los comprobantes de tu numeración! 🚨",
  "numerationMaxNumberReached.notification.text": "Ten en cuenta que la numeración {} se quedó sin comprobantes y a partir del 13 de julio no podrás usarla al vender.",
  "onboarding.video.url": "https://www.youtube.com/watch?v=W1kwsPHfWVQ",
  "onboarding.video.id": "W1kwsPHfWVQ",
  "planIncomeFeature": "Ingresos hasta RD{}{} al mes",
  "productDistributionHelp.mid": " cómo distribuir en diferentes almacenes",
  "setStationWarehouse": "Configura un almacén para tu terminal",
  "setStationWarehouseHelp": "Parece que se desactivó o eliminó el almacén que estaba asociado a tu terminal, selecciona uno para seguir vendiendo.",
  "stationWarehouseDeletedError": "Solo puedes eliminar almacenes que no estén asociados a una terminal.",
  "stationWarehouseStatusError": "Solo puedes desactivar almacenes que no estén asociados a una terminal.",
  "updateWarehouse": "Actualizar almacén",
  "updateWarehouseError": "Error al cambiar los datos del almacén",
  "userNotAllowed.warehouses.add": "No tienes permisos para agregar almacenes",
  "userNotAllowed.warehouses.delete": "No tienes permisos para eliminar almacenes",
  "userNotAllowed.warehouses.edit": "No tienes permisos para editar almacenes",
  "warehouse": "Almacén",
  "warehouses": "Almacenes",
  "warehouseActivated": "almacén activado con éxito",
  "warehouseChangeError": "No tienes los permisos para ver productos de otros almacenes.",
  "warehouseChangeInfo": "Cambia de almacén para visualizar los productos presentes en cada uno.",
  "warehouseCreatedSuccessfullyMessage": "El almacén {} ya está disponible para que distribuyas tu inventario.",
  "warehouseDeactivated": "almacén desactivado con éxito",
  "warehouseDeleted": "almacén eliminado con éxito",
  "warehouseDeletedError": "error eliminando almacén",
  "warehouseQuantity": "Cantidad en Almacén",
  "warehouseSetting": "Configuración de almacén",
  "warehousesLoadError": "Sucedió un error cargando los almacenes",
  "warehousesSettingSubtitle": "Administra los almacenes de tu negocio",
  "warehousesInventorySubtitle": "Crea almacenes para gestionar tu inventario en diferentes lugares de almacenamiento y distribución.",
  "warehouseStatusError": "error cambiando estado del almacén",
  "warehouseUpdated": "almacén actualizado con éxito",
  "warehouse_name": "Almacén",
  "youCanNotMakeElectronicInvoices.description": "Actívate ante la DGII y empieza a crear facturas electrónicas en tu punto de venta.",
  "youCanNowMakeElectronicInvoices": "¡Habilítate para emitir Facturas Electrónicas! 💥",
  "youCanNowMakeElectronicInvoices.description": "Actívate ante la DGII y empieza a crear facturas electrónicas en tu punto de venta.",
  "youDontHaveCreatedWarehouses": "No has creado tu primer almacén, créala aquí.",
  "youHavePendingInvoicesToEmit": "¡Tienes facturas por emitir! 🚨",
  "youHavePendingInvoicesToEmit.description": "Aprende a identificar las facturas que tienes “Por emitir” y envíalas a la DGII con un clic.",
  "youHavePendingInvoicesToEmit.link": "https://ayuda.alegra.com/es/conoce-el-estado-de-emision-de-tus-facturas-electronicas-en-el-pos-republica-dominicana",
  "yourNumerationIsAboutToExpire": "¡Tu numeración de facturas está por vencer! 📢",
  "yourNumerationIsAboutToExpire.description": "La numeración {} vencerá pronto. Solicita una nueva en la oficina virtual de la DGII.",
  "yourNumerationIsAlmostOver": "¡Ya casi se acaban tus comprobantes!⚠️",
  "yourNumerationIsAlmostOver.description": "Tienes pocos comprobantes en tu numeración {}. Solicita más comprobantes a la DGII para seguir facturando.",
  "yourNumerationIsExpired.description" : "La numeración {} está vencida. Solicita una nueva numeración a la DGII para seguir facturando.",
  "yourNumerationIsOver": "¡Se acabaron tus comprobantes fiscales! 🚨",
  "yourNumerationIsOver.description": "La numeración {} se quedó sin comprobantes. Solicita más a la DGII para seguir facturando.",
  "emissionStatus": "Estado DGII",
  "solutions.description.new": "Más soluciones para tu negocio",
  "AlegraPayroll.new": "Facturación",
  "AlegraPayroll.description.new": "Factura electrónicamente con un clic",
  "AlegraAccounting.new": "Contabilidad",
  "AlegraAccounting.description.new": "Contabiliza, factura, crea reportes y más",
  "AlegraPOS.new": "POS",
  "AlegraPOS.description.new": "Agiliza tu ventas y controla tu efectivo",
  "AlegraShop.new": "Tienda",
  "AlegraShop.description.new": "Crea tu primera tienda online en 4 clics",
  "electronicPayrollTutorial.video.id": "fUxbxiL3o38",
  "importProducts.text": "Importar productos",
  "importProducts.tooltip": "Te llevaremos a Alegra Contabilidad para que los importes de manera masiva.",
  "solutionModal.accounting.supTitle": "Tus cuentas claras con",
  "solutionModal.accounting.buttonMainText": "Prueba 15 días gratis",
  "solutionModal.accounting.buttonSecondaryText": "Más información",
  "solutionModal.accounting.description": "Con Alegra Contabilidad controlas tus ingresos y gastos con un clic, y obtienes reportes inteligentes para que estés siempre al día con la DGII.",
  "solutionModal.accounting.subTitle": "*Después de los 15 días puedes elegir un plan desde {{price}} {{currency}} al mes.",
  "solutionModal.store.supTitle": "Incrementa tus ventas con",
  "solutionModal.store.buttonMainText": "Prueba 15 días gratis",
  "solutionModal.store.buttonSecondaryText": "Más información",
  "solutionModal.store.description": "¡Empieza a vender online en segundos!\nCon Alegra Tienda creas tu primera tienda online en solo 4 clics.",
  "solutionModal.store.subTitle": "*Después de los 15 días puedes elegir un plan desde {{price}} {{currency}} al mes.",
  "solutionModal.pos.supTitle": "Gestiona tu negocio con",
  "solutionModal.pos.buttonMainText": "Prueba 15 días gratis",
  "solutionModal.pos.buttonSecondaryText": "Más información",
  "solutionModal.pos.description": "Factura en segundos con el Sistema Punto de Venta más veloz del mercado, además, podrás generar tus reportes de ventas e inventario en segundos. ⚡",
  "solutionModal.pos.subTitle": "*Después de los 15 días puedes elegir un plan desde {{price}} {{currency}} al mes.",
  "identificationDefaultError": "Debe ser un número de 9 a 11 dígitos",
  'identificationFailed.rnc': 'Debe ser un número de 9 dígitos',
  'identificationFailed.ced': 'Debe ser un número de 11 dígitos',
  "numerationElectronicOffline": "Necesitas conexión a internet para crear y enviar facturas electrónicas a la DGII.",
  'invoiceCreatedElectronicMessageTitle':"Emitiendo",
  'invoiceCreatedElectronicMessage':"Estamos enviando tu factura a la DGII ⚡",
  "warehouseChangeMessage": "Puedes cambiar de almacén para revisar los productos de cada uno.",
  "warehouseChangeErrorNew": 'Te falta el permiso de "ver detalle" en almacenes para revisar las demás.'
};

export default dictionary;
import React from 'react'
import { useSelector } from 'react-redux'
import { I18n } from '@aws-amplify/core';

import { country as countrySelector, electronicInvoicing } from '../../../../selectors/company'
import { Methods, OtherMethods } from '../../../forms/refund/Refund';

const RefundMethods = ({ form, values, total, client }) => {
  const country = useSelector(countrySelector)
  const isElectronic = useSelector(electronicInvoicing)

  return (
    <div className="px-3 pt-4">
      <div className="modal__refunds-divider position-relative justify-content-center d-flex">
        <div className="modal__refunds-divider-line">
          <div className="border-bottom" />
        </div>
        <p className="modal__refunds-divider-text text-muted h4 text-center">
          {I18n.get('chooseRefundMethod', 'Selecciona que tipo de devolución le vas a generar a tu cliente')}
        </p>
      </div>

      <div className="px-3">
        <Methods form={form} values={values} total={total}/>
      </div>

      {isElectronic && (
        <>
          <div className="modal__refunds-divider position-relative justify-content-center d-flex">
            <div className="modal__refunds-divider-line">
              <div className="border-bottom" />
            </div>
          </div>

          <div className="px-3">
            <OtherMethods form={form} country={country} client={client}/>
          </div>
        </>
      )}

    </div>
  )
}

export default RefundMethods;
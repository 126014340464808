import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Field, Form } from "react-final-form";
import { I18n } from "aws-amplify";
import { get } from 'lodash';
import { useHistory } from 'react-router';
import Cookies from "js-cookie";

import { closeModal } from "../../../reducers/modals";
import { renderCheckbox } from "../../forms/fields/V0/Fields";
import Modal from "../../common/Modal";
import Bottom from "../common/Bottom";

const CancelItemCreation = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const isOpen = useSelector((state) => get(state, 'modals.cancelItemCreation.isOpen', false));

    const close = () => {
        dispatch(closeModal({ modal: 'cancelItemCreation' }));
    };

    const submit = values => {
        if (!!get(values, 'noCancelItemConfirmation'))
            Cookies.set('_item-creation-abort', Date.now());

        history.push('/');
        close();
    }

    return (
        <Modal
            title={(
                <div className="d-flex justify-content-between align-items-center w-100">
                    <div className="modal__header-title text-capitalize-first h2 text-primary text-truncate">
                        {I18n.get('cancelItemCreationModalTitle', '¿Deseas cancelar la creación de este producto?')}
                    </div>
                </div>
            )}
            isOpen={isOpen}
            onRequestClose={() => close()}
            className="modal__medium-size"
        >
            <Form
                onSubmit={submit}
                keepDirtyOnReinitialize
            >
                {({ handleSubmit, submitting }) => (
                    <form noValidate onSubmit={handleSubmit}>
                        <div className="px-md-5 py-md-3">
                            <p className="h4 py-3 text-capitalize-first">
                                {I18n.get('cancelItemCreationModalContent', 'Ten en cuenta que al confirmar la cancelación se perderán los datos que hayas ingresado del producto.')}
                            </p>
                            <div className="form-body h-100 p-4 overflow-hidden">
                                <div className="form-body__fields form-row justify-content-start">
                                    <Field
                                        name="noCancelItemConfirmation"
                                        component={renderCheckbox}
                                        switchLabel={I18n.get('cancelItemCreationModalConfirmation', 'No volver a pedirme confirmación.')}
                                    />
                                </div>
                            </div>
                        </div>
                        <Bottom
                            submitting={submitting}
                            onClose={() => close()}
                            submitText={I18n.get('cancelItemCreation', 'Cancelar creación del producto')}
                            closeText={I18n.get('goBack', 'Volver')}
                            hideRequired
                        />
                    </form>
                )}
            </Form>
        </Modal >
    )

}

export default CancelItemCreation;
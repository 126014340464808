import React from 'react';
import { Field } from 'react-final-form';
import { I18n } from '@aws-amplify/core';
import { capitalize } from 'lodash';

import { renderSelect } from '../fields/V0/Fields';
import causes from '../../countriesData/republicaDominicana/voidInvoiceCause';

const VoidInvoiceCause = () => {
  
  return (
    <div className="form-body h-100 p-4 overflow-hidden">
      <div className="form-body__fields form-row justify-content-start">
        <Field
          name="cause"
          className="col-12"
          component={renderSelect}
          required
          options={causes}
          noOptionsMessage={() => I18n.get('noResultsWereFound', 'No se encontraron resultados.')}
          placeholder={I18n.get('selectACause', 'seleccione un motivo')}
          getOptionLabel={option => option.value}
          getOptionValue={option => option.id}
          label={capitalize(I18n.get('reason', 'motivo'))}
          help={I18n.get('voidInvoiceCauseHelp', 'esta información es necesaria para generar tus reportes correctamente.')}
          cacheOptions={false}
        />
      </div>
    </div>
  )
}

export default VoidInvoiceCause;
import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Form } from 'react-final-form';
import { I18n } from '@aws-amplify/core';
import { get } from 'lodash'

import { closeModal } from '../../../reducers/modals';
import Body from '../../forms/voidInvoiceCause/VoidInvoiceCause';
import { validate } from '../../forms/voidInvoiceCause/utils';
import Modal from '../../common/Modal';
import Notification from '../common/Notification';
import Bottom from '../common/Bottom';

const VoidInvoiceCause = ({ voidInvoice, submitError, submitting }) => {
  const isOpen = useSelector(state => get(state, 'modals.voidInvoiceCause.isOpen', false));
  const dispatch = useDispatch();

  const submit = async values => {
    const { cause } = values;
    await voidInvoice(cause.id);
    dispatch(closeModal({ modal: 'voidInvoiceCause' }))
  }

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={() => dispatch(closeModal({ modal: 'voidInvoiceCause' }))}
      title={I18n.get('voidInvoiceCause', 'motivo de anulación de venta')}
      className="modal__void-invoice-cause"
    >
      <Form
        onSubmit={submit}
        validate={values => validate(values)}
        keepDirtyOnReinitialize
      >
        {({ handleSubmit, values, invalid }) => (
          <form noValidate onSubmit={handleSubmit}>
            <div className="d-flex flex-column p-3">
              <Body values={values}/>
            </div>

            <Notification isOpen={!!submitError} text={submitError} />

            <Bottom
              submitting={submitting}
              disabled={invalid}
              submitText={I18n.get('void', 'anular')}
              onClose={() => dispatch(closeModal({ modal: 'voidInvoiceCause' }))}
            />
          </form>
        )}
      </Form>
    </Modal>
  )
}

export default VoidInvoiceCause;
const identificationTypes = [
  {
    id: 1,
    value: 'Documento Nacional de Identidad',
    abbreviature: 'DNI',
    key: 'DNI',
    rules: {
      minLength: 9,
      maxLength: 9,
      regex: {
        regex: '^d{8}[0-9a-zA-Z]$',
        invalidRegexText:
          'El DNI debe estar compuesto por 8 dígitos numéricos y un dígito alfanumérico',
      },
    },
  },
  {
    id: 2,
    value: 'Número de Identificación Fiscal',
    abbreviature: 'NIF',
    key: 'NIF',
    rules: {
      minLength: 9,
      maxLength: 9,
      regex: {
        regex: '^[a-zA-Z]d{7}[0-9a-zA-Z]$',
        invalidRegexText:
          'El NIF debe iniciar por una letra y estar compuesto por 7 dígitos numéricos y un dígito alfanumérico',
      },
    },
  },
  {
    id: 3,
    value: 'Número de Identificación Extranjero',
    abbreviature: 'NIE',
    key: 'NIE',
    rules: {
      minLength: 9,
      maxLength: 9,
      regex: {
        regex: '^[a-zA-Z]d{7}[0-9a-zA-Z]$',
        invalidRegexText:
          'El NIE debe iniciar por una letra y estar compuesto por 7 dígitos numéricos y un dígito alfanumérico',
      },
    },
  },
  {
    id: 4,
    value: 'Extranjero',
    abbreviature: 'EXT',
    key: 'EXT',
  },
];

export default identificationTypes;

import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { I18n } from '@aws-amplify/core';
import { graphqlOperation } from '@aws-amplify/api';
import { get } from 'lodash';

import {
  country as countrySelector,
  origin as originSelector,
} from '../../../selectors/company';
import * as mutations from '../../../graphql/mutations';
import { closeModal } from '../../../reducers/modals';
import { openTour } from '../../../reducers/tours';
import { setUser } from '../../../reducers/auth';
import { createMembership, setDiscountAvailability as setHappyWeekAvailability } from '../../../reducers/membership';
import membershipsAPI from '../../../reducers/membershipsAPI';
import welcomeGIF from '../../images/welcome.gif';
import BackgroundSVG from '../../images/background.png';
import Confirmation from '../confirmation/Confirmation';
import { replaceAndParse, isClothingStore } from '../../../utils';
import { sendGTMEvent, sendNewGTMEvent } from '../../../reducers/company';
import { APIGraphqlSelector } from '../../../selectors/app';

const Welcome = () => {
  const dispatch = useDispatch();
  const welcomeOpen = useSelector((state) =>
    get(state, 'modals.welcome.isOpen', false)
  );
  const tutorialWizardOpen = useSelector((state) =>
    get(state, 'modals.tutorialWizard.isOpen', false)
  );
  const isOpen = welcomeOpen && !tutorialWizardOpen;
  const country = useSelector(countrySelector);
  const origin = useSelector(originSelector);
  const [discountAvailability, setDiscountAvailability] = useState(false);
  const APIGraphql = useSelector(APIGraphqlSelector);

  const fetchDiscountAvailability = useCallback(async () => {
    try {
      const response = await membershipsAPI.get(`/coupon/happy-week/status`);
      setDiscountAvailability(get(response, 'data.results', false));
      // dispatch(setHappyWeekAvailability(get(response, 'data.results', false)))
    } catch { }
  }, []);

  useEffect(() => {
    fetchDiscountAvailability();
  }, [fetchDiscountAvailability]);

  const start = async () => {
    dispatch(
      sendGTMEvent('tour-started', {
        isSkiped: false,
      })
    );
    dispatch(sendNewGTMEvent('pos-onboarding-finished'));
    dispatch(closeModal({ modal: 'welcome' }));
    dispatch(
      openTour({ tour: 'onboarding', params: { discountAvailability } })
    );
    dispatch(sendNewGTMEvent('pos-tour-started'));
    try {
      await dispatch(createMembership());
    } catch { }
  };

  const close = useCallback(async () => {
    dispatch(
      sendGTMEvent('tour-started', {
        isSkiped: true,
      })
    );
    dispatch(sendNewGTMEvent('pos-onboarding-finished'));
    dispatch(closeModal({ modal: 'welcome' }));
    // if (discountAvailability) {
    //   history.push('/plan/plans')
    // }
    try {
      try {
        await dispatch(createMembership());
      } catch { }
      const response = await APIGraphql(
        graphqlOperation(mutations.updateUser, {
          user: {
            seenWelcome: true,
          },
        })
      );
      if (!!get(response, 'data.updateUser.id', null))
        dispatch(setUser(get(response, 'data.updateUser')));
    } catch { }
  }, [dispatch, discountAvailability, APIGraphql]);

  return (
    <Confirmation
      isOpen={isOpen}
      onClose={() => close()}
      onConfirm={() => start()}
      confirmText={I18n.get('startTour', 'Empezar el recorrido')}
      hideCancel
      hideRequired
      confirmId='start-tour'
      closeId='close-tour'
      body={
        <div
          className='d-flex flex-column justify-content-center align-items-center py-4'
          style={{
            background: `url(${BackgroundSVG}) no-repeat`,
            backgroundSize: '100% auto',
            backgroundRepeat: 'no-repeat',
            backgroundPositionY: '100%',
          }}
        >
          <img src={welcomeGIF} alt='loading...' className='w-75' />
          <p className='text-capitalize-first text-primary h4 py-4 text-center'>
            {I18n.get(
              'onboardingWelcomeTitle',
              '¡Te damos la bienvenida a Alegra POS! 😉'
            )}
          </p>
          <p className='text-capitalize-first h4 px-4 text-center text-muted pb-2'>
            {isClothingStore(country, origin)
              ? replaceAndParse(
                I18n.get(
                  'onboardingWelcomeSubtitleClothingStore',
                  'Descubre en el siguiente recorrido lo fácil que es gestionar las ventas de tu tienda 👕🕶 y empieza a darle superpoderes a tu negocio.'
                ),
                []
              )
              : replaceAndParse(
                I18n.get(
                  'onboardingWelcomeSubtitle',
                  'Descubre en el siguiente recorrido lo fácil que es gestionar las facturas de tu punto de venta y empieza a darle superpoderes a tu negocio.'
                ),
                []
              )}
          </p>
        </div>
      }
    />
  );
};

export default Welcome;

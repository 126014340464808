import GlobalInvoiceProvider from './context';
import GlobalInvoiceForm from '../../../components/forms/globalInvoice';
import GlobalInvoiceTable from './table';
import GlobalInvoiceFooter from './footer';
import GlobalInvoiceHeader from './header';
import { createGlobalInvoiceMutation } from './services/mutations';
import { useSelector } from 'react-redux';
import { APIGraphqlSelector } from '../../../selectors/app';

function GlobalInvoice() {
  const APIGraphql = useSelector(APIGraphqlSelector);
  const { mutate, isLoading } = createGlobalInvoiceMutation();

  return (
    <GlobalInvoiceProvider>
      <GlobalInvoiceHeader />
      <GlobalInvoiceForm
        onSubmitInvoice={(values) =>
          mutate({ values, api: APIGraphql })
        }
        isLoading={isLoading}
      >
        <GlobalInvoiceTable />
        <GlobalInvoiceFooter />
      </GlobalInvoiceForm>
    </GlobalInvoiceProvider>
  );
}

export default GlobalInvoice;

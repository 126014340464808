import React, { useState } from 'react';
import { I18n } from '@aws-amplify/core';
import { Field } from 'react-final-form';
import { get, capitalize } from 'lodash';

import { idTypes as identificationsColombia } from '../../../countriesData/colombia/idTypes';
import identificationsCostaRica from '../../../countriesData/costaRica/identificationTypes';
import identificationsArgentina from '../../../countriesData/argentina/identificationTypes';
import { renderField, renderSelect } from '../../fields/V0/Fields';

const identificationTypeLabel = () => capitalize(I18n.get('identificationType', 'Tipo de identificación'));
const identificationLabel = () => capitalize(I18n.get('identification', 'identificación'));

const calculateDV = props => {
  const id = get(props, 'values.identification.number', null);
  let dv = null;

  if (!!id && id.length <= 15) {
    const primeNumbers = [3, 7, 13, 17, 19, 23, 29, 37, 41, 43, 47, 53, 59, 67, 71];
    let totalSum = 0;

    for (let i = 0; i < id.length; i++) {
      totalSum += (id[i] * primeNumbers[id.length - i - 1]);
    }

    const mod = totalSum % 11;

    dv = [0, 1].indexOf(mod) !== -1 ? mod : 11 - mod;
  }

  props.form.change('identification.dv', dv);
}

const colombiaIdentification = props => {
  const identificationType = get(props, 'values.identification.type.key', '');
  const options = identificationsColombia(true);

  return (
    <>
      <Field
        name="identification.type"
        className="col-md-6"
        component={renderSelect}
        data-testid="client-id-type-input"
        label={identificationTypeLabel()}
        options={options}
        getOptionLabel={option => `${option.abbreviature} - ${option.value}`}
        getOptionValue={option => option.key}
        required
      />

      {identificationType === 'NIT'
        ? (
          <div className='col-md-6'>
            <div className="form-row">
              <Field
                name="identification.number"
                component={renderField}
                data-testid="client-id-input"
                type="text"
                label={identificationLabel()}
                required
                className="col-9"
                onBlur={() => {
                  calculateDV(props);
                }}
              />
              <Field
                name="identification.dv"
                className="col-3"
                component={renderField}
                type="text"
                helpTooltip={I18n.get('dvTooltip', 'Este campo se carga de forma automática según el código de verificación asignado por la DIAN a tu número de identificación')}
                label={capitalize(I18n.get('dv', 'DV'))}
                disabled
              />
            </div>
          </div>
        )
        : (
          <Field
            name="identification.number"
            className="col-md-6"
            component={renderField}
            data-testid="client-id-input"
            type="text"
            label={identificationLabel()}
            required
          />
        )
      }
    </>
  )
}

const costaRicaIdentification = props => {
  return (
    <>
      <Field
        name="identification.type"
        className="col-md-6"
        component={renderSelect}
        data-testid="client-id-type-input"
        options={identificationsCostaRica}
        label={identificationTypeLabel()}
        getOptionLabel={option => option.value}
        getOptionValue={option => option.key}
        required
      />

      <Field
        name="identification.number"
        className="col-md-6"
        component={renderField}
        data-testid="client-id-input"
        type="text"
        placeholder={identificationLabel()}
        label={identificationLabel()}
        required
      />
    </>
  )
}

const argentinaIdentification = props => {
  return (
    <>
      <Field
        name="identification.type"
        className="col-md-6"
        component={renderSelect}
        data-testid="client-id-type-input"
        options={identificationsArgentina}
        label={identificationTypeLabel()}
        getOptionLabel={option => option.value}
        getOptionValue={option => option.key}
        required
      />

      <Field
        name="identification.number"
        className="col-md-6"
        component={renderField}
        data-testid="client-id-input"
        type="text"
        placeholder={identificationLabel()}
        label={identificationLabel()}
        required
      />
    </>
  )
}

const peruIdentification = props => {
  return (
    <>
      <Field
        name="identification"
        className="col-md-6"
        component={renderField}
        data-testid="client-id-input"
        type="text"
        label="RUC"
        required
      />
    </>
  )
}

const panamaIdentification = props => (
  <>
    <div className='col-12'>
      <div className="form-row">
        <Field
          name="identificationObject.number"
          component={renderField}
          data-testid="client-id-input"
          type="text"
          label="RUC"
          required
          className="col-9"
          onBlur={() => {
            calculateDV(props);
          }}
        />
        <Field
          name="identificationObject.dv"
          className="col-3"
          component={renderField}
          type="text"
          label={capitalize(I18n.get('dv', 'DV'))}
          disabled
        />
      </div>
    </div>
  </>
)

const republicaDominicanaIdentification = props => (
  <Field
    name="identification"
    className="col-12"
    component={renderField}
    data-testid="client-id-input"
    type="text"
    label="RNC"
    required
  />
)

const mexicoIdentification = props => (
  <Field
    name="identification"
    className="col-12"
    component={renderField}
    data-testid="client-id-input"
    type="text"
    label="RFC"
    required
  />
)

const spainIdentification = props => (
  <Field
    name="identification"
    className="col-12"
    component={renderField}
    data-testid="client-id-input"
    type="text"
    label={I18n.get('identification', 'identificación')}
  />
)

const defaultIdentification = props => (
  <Field
    name="identification"
    className="col-12"
    component={renderField}
    data-testid="client-id-input"
    type="text"
    label={identificationLabel()}
  />
)

const RenderIdentification = props => {
  const [searchingId, setSearchingId] = useState(false);

  switch (props.country) {
    case 'colombia':
      return colombiaIdentification({ ...props, searchingId, setSearchingId });

    case 'costaRica':
      return costaRicaIdentification({ ...props, searchingId, setSearchingId });

    case 'peru':
      return peruIdentification({ ...props, searchingId, setSearchingId });

    case 'argentina':
      return argentinaIdentification({ ...props, searchingId, setSearchingId });

    case 'panama':
      return panamaIdentification({ ...props, searchingId, setSearchingId });

    case 'republicaDominicana':
      return republicaDominicanaIdentification({ ...props, searchingId, setSearchingId });

    case 'mexico':
      return mexicoIdentification({ ...props, searchingId, setSearchingId });

    case 'spain':
      return spainIdentification({ ...props, searchingId, setSearchingId });
    default:
      return defaultIdentification({ ...props, searchingId, setSearchingId });
  }
}

export default RenderIdentification;
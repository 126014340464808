import { useEffect, useState } from 'react';
import Cookies from 'js-cookie';

const useApiAvailable = () => {
  const [isApiAvailable, setIsApiAvailable] = useState(null);

  useEffect(() => {
    const checkCookie = () => {
      if (Cookies.get('pos-block-api-request')) {
        setIsApiAvailable(false);
        return;
      }

      const status = localStorage.getItem('apiServiceStatus');
      if (status === 'success') {
        setIsApiAvailable(true);
      } else if (status === 'failure') {
        setIsApiAvailable(false);
      }
    };

    const handleStorageChange = () => {
      const status = localStorage.getItem('apiServiceStatus');
      if (status === 'success') {
        setIsApiAvailable(true);
      } else if (status === 'failure') {
        setIsApiAvailable(false);
      }
    };

    checkCookie();

    const interval = setInterval(checkCookie, 5000); 

    window.addEventListener('storage', handleStorageChange);

    return () => {
      clearInterval(interval);
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);

  return isApiAvailable;
};

export default useApiAvailable;

// src/components/DetailHeader/RefundActions.jsx

import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from '@alegradev/smile-ui-react';
import { I18n } from '@aws-amplify/core';

/**
 * Component for rendering refund action buttons.
 *
 * @param {Object} props - Component props.
 * @param {boolean} props.isElectronic - Indicates if the refund is electronic.
 * @param {boolean} props.showButtonToEmit - Indicates if the emit button should be shown.
 * @param {boolean} props.stampLoading - Loading state for stamping.
 * @param {Function} props.emitRefund - Handler to emit refund.
 * @param {Function} props.sendRefund - Handler to send refund.
 * @param {Function} props.setTypeToPrint - Handler to set print type.
 * @param {boolean} props.canAddRefund - Permission to add refund.
 * @param {Function} props.openNewRefundModal - Handler to open new refund modal.
 * @returns {JSX.Element} - Rendered component.
 */
const RefundActions = React.memo(({ canAddRefund, openNewRefundModal }) => (
  <div className='d-flex justify-content-end pb-5 new-refund-button-container'>
    <Tooltip
      visible={!canAddRefund}
      overlay={I18n.get(
        'userNotAllowed.credit-notes.add',
        'no tienes permisos para agregar devoluciones'
      )}
    >
      <button
        type='button'
        disabled={!canAddRefund}
        className='btn btn-submit d-none d-sm-block new-refund-button'
        onClick={openNewRefundModal}
      >
        {I18n.get('newRefund', 'nueva devolución')}
      </button>
    </Tooltip>
  </div>
));

RefundActions.propTypes = {
  isElectronic: PropTypes.bool.isRequired,
  showButtonToEmit: PropTypes.bool.isRequired,
  stampLoading: PropTypes.bool.isRequired,
  emitRefund: PropTypes.func.isRequired,
  sendRefund: PropTypes.func.isRequired,
  setTypeToPrint: PropTypes.func.isRequired,
  canAddRefund: PropTypes.bool.isRequired,
  openNewRefundModal: PropTypes.func.isRequired,
};

export default RefundActions;

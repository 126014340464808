import { useEffect, useMemo, useState, useRef, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Field, useForm, useFormState } from 'react-final-form';
import { I18n } from 'aws-amplify';
import { get } from 'lodash';
import { graphqlOperation } from 'aws-amplify';
import BigNumber from 'bignumber.js';

import * as queries from '../../../../graphql/queries';
import { useFormat } from '../../../../hooks/useFormat';
import {
  APIGraphqlSelector,
  stationCostCenter,
} from '../../../../selectors/app';
import {
  getRefundNumerationsElectronic,
  getRefundNumerationsNoElectronic,
  getRefundNumerationsAdjustNote,
} from '../../../../selectors/numerations';
import { renderSelect, renderField, renderDate } from '../../fields/V0/Fields';
import spainCreditNoteTypes from '../../../countriesData/spain/creditNoteTypes';

import { SelectClient } from './SelectClients';
import { setNumeration, setTip } from '../../../../reducers/activeRefund';
import { isAccepted } from '../utils';
import { Icon } from '@alegradev/smile-ui-react';
import { country as countrySelector } from '../../../../selectors/company';
import { COUNTRIES } from '../../../../utils/enums/countries';
import { DEFAULT_METHODS } from '../../../modals/newRefunds/hooks/useNewRefunds';
import { REFUND_METHODS } from '../../../../utils/enums/refundMethods';

/**
 * Returns a list of available refund methods with their corresponding labels based on the provided method.
 * @param {string} method - The method to filter the options.
 * @returns {Array} - The list of options.
 */
const getOptionsMethods = (method) => {
  const nameValueMap = {
    [REFUND_METHODS.RECTIFICATIVE_IN_SIMPLIFIED_INVOICE]:
      'RECTIFICATIVE_IN_SIMPLIFIED_INVOICE',
  };

  const value = nameValueMap[method];
  const allOptions = [
    {
      value: 'RECTIFICATIVE_IN_SIMPLIFIED_INVOICE',
      label: I18n.get(
        'rectificativeInSimplifiedInvoice',
        'Factura rectificativa en factura simplificada'
      ),
    },
  ];

  return allOptions.filter((option) => option.value === value);
};

export const RectificativeInSimplifiedInvoice = ({ changeStep, method, handleMethodChange }) => {
  const { values } = useFormState();
  const { decimal, fmt } = useFormat();
  const dispatch = useDispatch();
  const form = useForm();
  const today = useMemo(() => new Date(), []);
  const [documents, setDocuments] = useState([]);
  const [loading, setLoading] = useState(false);
  const [typeNumeration, setTypeNumeration] = useState('');
  const costCenter = useSelector(stationCostCenter) || {};
  const [numerations, setNumerations] = useState([]);
  const APIGraphql = useSelector(APIGraphqlSelector);
  const country = useSelector(countrySelector);
  const ref = useRef();

  const invoiceSelected = useSelector((state) =>
    get(state, 'modals.newRefunds.params.invoice', null)
  );
  const numerationsEletronic = useSelector(getRefundNumerationsElectronic);
  const numerationsNoElectronic = useSelector(getRefundNumerationsNoElectronic);

  /**
   * Computes the default value for the refund method select field.
   */
  const defaultValue = useMemo(() => {
    const methodToValueMap = {
      [REFUND_METHODS.RECTIFICATIVE_IN_SIMPLIFIED_INVOICE]:
        'RECTIFICATIVE_IN_SIMPLIFIED_INVOICE',
    };
    const selectedMethod = getOptionsMethods(method).find(
      (item) => item.value === methodToValueMap[method]
    );
    return selectedMethod
      ? { value: selectedMethod.value, label: selectedMethod.label }
      : { value: '', label: '' };
  }, [method]);

  const getCreditNoteTypes = ({ country }) => {
    switch (country) {
      case COUNTRIES.SPAIN:
        return spainCreditNoteTypes;
      default:
        return [];
    }
  };

  useEffect(() => {
    if (!!invoiceSelected) {
      const haveTip = !!get(invoiceSelected, 'additionalCharges[0]', false);
      if (haveTip) {
        const additionalCharge = get(invoiceSelected, 'additionalCharges[0]');
        const tip = {
          include: true,
          type: 'VALUE',
          percentage: null,
          value: additionalCharge.amount,
        };
        dispatch(setTip(tip));
      }

      setNumerations(numerationsEletronic);
      setTypeNumeration('creditNote');
      if (numerationsEletronic.length > 0) {
        const newNumeration =
          numerationsEletronic.find((n) => n.isDefault) ||
          numerationsEletronic[0];
        form.change('refund.numeration', newNumeration);
      }

      return;
    }
  }, [invoiceSelected]);

  const numerationStrategies = {
    saleTicket: ({ numerationsEletronic }) => {
      return numerationsEletronic;
    },
    creditNote: ({ numerationsEletronic }) => {
      return numerationsEletronic;
    },
    noElectronic: ({ numerationsNoElectronic }) => {
      return numerationsNoElectronic;
    },
  };

  useEffect(() => {
    const selectedStrategy = numerationStrategies[typeNumeration];

    if (selectedStrategy) {
      setNumerations(
        selectedStrategy({
          numerationsNoElectronic,
          numerationsEletronic,
        })
      );
    }
  }, [typeNumeration, numerationsNoElectronic, numerationsEletronic]);

  const isElectronic = useMemo(() => {
    return !!get(values, 'refund.document.numberTemplate.isElectronic', false);
  }, [values?.refund?.document]);

  const isAllFieldsFilled = () => {
    const client = get(values, 'refund.client', false);
    const name = get(values, 'refund.document', false);
    const date = get(values, 'refund.date', false);
    const numeration = get(values, 'refund.numeration', false);

    return !!client && !!name && !!date && !!numeration;
  };

  useEffect(() => {
    let step = 1;
    if (isAllFieldsFilled()) step = 2;
    changeStep(step);
  }, [values]);

  useEffect(() => {
    ref.current = true;
    if (!invoiceSelected && !get(values, 'refund.document', null)) search();
    return () => (ref.current = false);
  }, [values?.refund?.client]);

  const search = useCallback(async () => {
    if (!values?.refund?.client) return;
    setDocuments([]);
    form.change('refund.document', null);
    setTypeNumeration(null);
    form.change('refund.numeration', null);

    if (ref.current) setLoading(true);

    try {
      const response = await APIGraphql(
        graphqlOperation(queries.getClientInvoicesById, {
          clientId: values.refund.client.id,
        })
      );

      const dataDocuments = get(
        response,
        'data.getClientInvoicesById.data',
        []
      ).filter((d) => {
        const invoiceCostCenter = d.costCenter || {};
        const isElectronic = !!get(d, 'numberTemplate.isElectronic', false);
        let isEligible = true;
        const stamp = get(d, 'stamp', null);

        if (isElectronic) {
          if (!stamp) isEligible = false;
          else isEligible = isAccepted(stamp);
        }

        return (
          (!invoiceCostCenter || invoiceCostCenter.id === costCenter.id) &&
          isEligible
        );
      });

      setDocuments(dataDocuments);
    } catch {
      return [];
    } finally {
      if (ref.current) setLoading(false);
    }
  }, [APIGraphql, values?.refund?.client]);

  return (
    <>
      <div className='row m-0 overflow-hidden'>
        <div
          className={`${invoiceSelected ? 'd-none' : 'row m-0 overflow-hidden w-100'}`}
        >
          <SelectClient
            numerations={numerations}
            changeNumeration={false}
            resetDocument={true}
          />
          <Field
            type='select'
            name='refund.document'
            className='col-12 col-md-6 p-0 pl-md-2 new-refund-input select-refund'
            placeholder={I18n.get('select', 'Seleccionar')}
            options={documents}
            isLoading={loading}
            component={renderSelect}
            getOptionValue={(option) => option.numberTemplate.fullNumber}
            getOptionLabel={(option) => {
              const balance = new BigNumber(option.total);
              const fullNumber = option.numberTemplate.fullNumber;

              return (
                <div className='d-flex justify-content-between'>
                  <span
                    className='document-fullNumber'
                    style={{
                      display: 'none',
                      color: !!invoiceSelected ? '#707070' : '#0F172A',
                    }}
                  >
                    {fullNumber}
                  </span>
                  <span
                    className='refund-document-label mb-0 mr-2'
                    style={{
                      fontWeight: 600,
                      color: !!invoiceSelected ? '#707070' : '#0F172A',
                    }}
                  >
                    {fullNumber}
                  </span>
                  <span
                    className='refund-document-balance-label text-wrap mb-0'
                    style={{ fontSize: 12, color: '#64748B' }}
                  >
                    {balance.toFormat(decimal, fmt)}
                  </span>
                </div>
              );
            }}
            onChange={(values) => {
              const invoiceSelectedIsElectronic = !!get(
                values,
                'numberTemplate.isElectronic',
                false
              );
              if (
                values.numberTemplate.documentType === 'saleTicket' &&
                invoiceSelectedIsElectronic
              ) {
                setTypeNumeration('saleTicket');
                if (numerationsEletronic.length > 0) {
                  const newNumeration =
                    numerationsEletronic.find((n) => n.isDefault) ||
                    numerationsEletronic[0];
                  form.change('refund.numeration', newNumeration);
                  form.change(
                    'refund.method',
                    DEFAULT_METHODS[COUNTRIES.SPAIN]('saleTicket', invoiceSelectedIsElectronic)
                  );
                  handleMethodChange(DEFAULT_METHODS[COUNTRIES.SPAIN]('saleTicket', invoiceSelectedIsElectronic))
                } else {
                  form.change('refund.numeration', null);
                }
              } else if (values.numberTemplate.documentType === 'saleTicket') {
                setTypeNumeration('saleTicket');
                if (numerationsNoElectronic.length > 0) {
                  const newNumeration = numerationsNoElectronic.filter(
                    (n) => n.isDefault
                  )[0];
                  form.change('refund.numeration', newNumeration);
                } else {
                  form.change('refund.numeration', null);
                }
              } else if (
                values.numberTemplate.documentType === 'invoice' &&
                invoiceSelectedIsElectronic
              ) {
                setTypeNumeration('creditNote');
                if (numerationsEletronic.length > 0) {
                  const newNumeration =
                    numerationsEletronic.find((n) => n.isDefault) ||
                    numerationsEletronic[0];
                  form.change('refund.numeration', newNumeration);
                  form.change(
                    'refund.method',
                    DEFAULT_METHODS[COUNTRIES.SPAIN]('invoice', invoiceSelectedIsElectronic)
                  );
                  handleMethodChange(DEFAULT_METHODS[COUNTRIES.SPAIN]('invoice', invoiceSelectedIsElectronic))
                } else {
                  form.change('refund.numeration', null);
                }
              } else {
                setTypeNumeration('noElectronic');
                if (numerationsNoElectronic.length > 0) {
                  const newNumeration = numerationsNoElectronic.filter(
                    (n) => n.isDefault
                  )[0];
                  form.change('refund.numeration', newNumeration);
                } else {
                  form.change('refund.numeration', null);
                }
              }
              if (get(values, 'additionalCharges[0]', false)) {
                const additionalCharge = get(values, 'additionalCharges[0]');
                const tip = {
                  include: true,
                  type: 'VALUE',
                  percentage: null,
                  value: additionalCharge.amount,
                };
                dispatch(setTip(tip));
              }
              return values;
            }}
            disabled={!!invoiceSelected}
            required
            label={I18n.get('associatedDocument', 'Documento asociado')}
          />
        </div>
        <Field
          type='select'
          name='refund.numeration'
          component={renderSelect}
          noOptionsMessage={() => {
            let text = '';
            if (typeNumeration === 'creditNote')
              text = I18n.get(
                'noResultsElectronic',
                'No tienes ninguna numeración de nota crédito electrónica'
              );
            else
              return I18n.get(
                'noResultsWereFound',
                'No se encontraron resultados.'
              );

            return (
              <div className='w-100 d-flex'>
                <div className='col-2 pr-0'>
                  <Icon icon='alert-triangle' />
                </div>
                <div className='d-flex flex-column'>
                  <p className='warning-text mb-1'>{text}</p>
                  <a
                    className='warning-text'
                    href='https://ayuda.alegra.com/es/configuraci%C3%B3n-de-la-numeraci%C3%B3n-de-tus-comprobantes-en-alegra'
                    target='_blank'
                    rel='noreferrer'
                  >
                    {I18n.get('learnToCreateIt', 'Aprender a crearla')}
                  </a>
                </div>
              </div>
            );
          }}
          options={!!numerations.length ? numerations : []}
          onChange={(option) => {
            dispatch(setNumeration(option));
            return option;
          }}
          getOptionValue={(option) => option.id}
          getOptionLabel={(option) => option.name}
          className='col-12 col-md-6 p-0 pr-md-2 new-refund-input select-refund'
          required
          cacheOptions={false}
          label={I18n.get('numeration', 'Numeración')}
        />

        <Field
          type='text'
          name='refund.cause'
          component={renderField}
          className='col-12 col-md-6 no-padding p-0 pl-md-2 select-refund new-refund-input'
          label={I18n.get('simpleCause', 'Motivo')}
          required
        />
      </div>
      <Field
        name='refund.date'
        className={`d-none`}
        component={renderDate}
        defaultValue={today}
        required
        label={I18n.get('date', 'Fecha')}
        portalId='refund-modal-portal'
      />
      <div className='row m-0'>
        {!!isElectronic && (
          <>
            <Field
              type='select'
              name='refund.creditNoteOperationType'
              component={renderSelect}
              options={getOptionsMethods(method)}
              defaultValue={defaultValue}
              getOptionLabel={(option) => option.label}
              getOptionValue={(option) => option.value}
              className='col-12 col-md-6 p-0 pr-md-2 select-refund new-refund-input'
              required
              label={I18n.get('operationType', 'Tipo de operación')}
            />
            <Field
              type='select'
              name='refund.creditNoteType'
              component={renderSelect}
              options={getCreditNoteTypes({ country })}
              defaultValue={getCreditNoteTypes({ country })[0]}
              getOptionLabel={(option) => option.value}
              getOptionValue={(option) => option.key}
              className='col-12 col-md-6 p-0 pr-md-2 select-refund new-refund-input'
              required
              label={I18n.get(
                'rectificativeInvoiceType',
                'Tipo de factura rectificativa'
              )}
            />
          </>
        )}

        <Field
          type='text'
          name='refund.anotation'
          component={renderField}
          className='col-12 p-0 select-refund new-refund-input'
          label={I18n.get(
            'operationDescription',
            'Descripción de la operación'
          )}
          required
        />
      </div>
    </>
  );
};

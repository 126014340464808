function AlegraTPVLogo({ id, className }) {
  return (
    <svg
      id={id}
      className={className}
      width='476'
      height='240'
      viewBox='0 0 476 240'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M105.914 44C96.9923 44 89.76 51.2247 89.76 60.1367V123.213H150.24V60.1367C150.24 51.2247 143.008 44 134.086 44H105.914Z'
        fill='#00D6BC'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M66.8215 84.2701C62.3608 84.2701 58.7446 87.8825 58.7446 92.3385V106.862H52.1538C43.2323 106.862 36 114.086 36 122.998V177.863C36 186.775 43.2323 194 52.1538 194H187.846C196.768 194 204 186.775 204 177.863V122.998C204 114.086 196.768 106.862 187.846 106.862H181.255V92.3385C181.255 87.8825 177.639 84.2701 173.178 84.2701C168.718 84.2701 165.102 87.8825 165.102 92.3385V122.245C165.102 131.157 157.869 138.382 148.948 138.382H91.0523C82.1308 138.382 74.8985 131.157 74.8985 122.245V92.3385C74.8985 87.8825 71.2823 84.2701 66.8215 84.2701ZM96.48 155.236C90.913 155.236 86.4 159.763 86.4 165.348C86.4 170.933 90.913 175.461 96.48 175.461H143.52C149.087 175.461 153.6 170.933 153.6 165.348C153.6 159.763 149.087 155.236 143.52 155.236H96.48Z'
        fill='#00D6BC'
      />
      <path
        d='M272.748 94.374C263.08 94.374 256 86.5756 256 77.0294C256 67.6176 263.625 59.8193 273.769 59.8193C283.845 59.8193 291.538 67.2143 291.538 77.0294V93.5672H285.139V87.7857C282.62 91.8193 278.194 94.374 272.748 94.374ZM273.769 88.0546C280.033 88.0546 284.798 83.0798 284.798 77.0966C284.798 71.0462 280.033 66.0714 273.769 66.0714C267.574 66.0714 262.74 71.0462 262.74 77.0966C262.74 83.0798 267.574 88.0546 273.769 88.0546Z'
        fill='#64748B'
      />
      <path d='M300.512 45.5H307.252V93.5672H300.512V45.5Z' fill='#64748B' />
      <path
        d='M331.78 94.3067C321.636 94.3067 314.079 87.0462 314.079 76.895C314.079 67.416 320.955 59.6849 330.963 59.6849C341.039 59.6849 347.303 66.8782 347.303 76.0882V78.7773H320.615C321.364 84.4244 325.721 88.3908 331.712 88.3908C335.797 88.3908 339.473 86.6429 341.448 83.0126L346.622 85.6345C343.694 91.2815 338.316 94.3067 331.78 94.3067ZM320.887 74.4748H340.563C340.29 69.2311 336.41 65.6681 330.895 65.6681C325.244 65.6681 321.568 69.5672 320.887 74.4748Z'
        fill='#64748B'
      />
      <path
        d='M368.353 109.5C361.136 109.5 355.145 106.408 352.082 100.559L357.732 97.5336C359.707 101.097 363.383 103.382 368.217 103.382C375.229 103.382 379.995 98.4076 379.995 90.6765V88.0546C377.816 91.4832 373.732 94.0378 368.149 94.0378C358.686 94.0378 351.197 86.6429 351.197 76.9622C351.197 67.5504 358.822 59.8193 368.966 59.8193C379.042 59.8193 386.735 67.2143 386.735 77.0294V90.6092C386.735 102.038 378.974 109.5 368.353 109.5ZM368.898 87.7857C375.025 87.7857 379.927 82.9454 379.927 76.895C379.927 70.8445 375.025 66.1387 368.898 66.1387C362.839 66.1387 357.869 70.8445 357.869 76.895C357.869 82.9454 362.839 87.7857 368.898 87.7857Z'
        fill='#64748B'
      />
      <path
        d='M401.087 93.5672H394.347V73.937C394.347 65.937 398.977 59.9538 407.691 59.9538C409.802 59.9538 411.98 60.3571 413.682 61.1639V67.7521C412.048 66.8109 410.142 66.3403 408.304 66.3403C403.811 66.3403 401.087 69.3655 401.087 74.2059V93.5672Z'
        fill='#64748B'
      />
      <path
        d='M431.21 94.374C421.542 94.374 414.462 86.5756 414.462 77.0294C414.462 67.6176 422.087 59.8193 432.231 59.8193C442.307 59.8193 450 67.2143 450 77.0294V93.5672H443.6V87.7857C441.081 91.8193 436.656 94.374 431.21 94.374ZM432.231 88.0546C438.494 88.0546 443.26 83.0798 443.26 77.0966C443.26 71.0462 438.494 66.0714 432.231 66.0714C426.035 66.0714 421.202 71.0462 421.202 77.0966C421.202 83.0798 426.035 88.0546 432.231 88.0546Z'
        fill='#64748B'
      />
      <path
        d='M313.469 117.5V128.097H290.392V193.034H278.965V128.097H256V117.5H313.469Z'
        fill='#334155'
      />
      <path
        d='M334.42 193.034H322.993V117.5H346.855C367.02 117.5 380.127 126.632 380.127 144.219C380.127 162.257 366.795 171.276 347.415 171.276H334.42V193.034ZM347.191 128.097H334.42V160.566H347.191C359.514 160.566 368.364 155.718 368.364 144.219C368.364 133.171 359.85 128.097 347.191 128.097Z'
        fill='#334155'
      />
      <path
        d='M417.496 194.5C410.102 194.5 405.173 190.329 402.597 182.099L382.88 117.5H394.531L413.127 179.168C413.911 181.873 415.255 183.226 417.496 183.226C419.737 183.226 421.081 181.873 421.865 179.168L440.237 117.5H452L432.283 182.099C429.707 190.329 424.666 194.5 417.496 194.5Z'
        fill='#334155'
      />
    </svg>
  );
}

export default AlegraTPVLogo;

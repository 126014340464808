import React, { useEffect, useState } from 'react'
import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';

const Portal = ({ children }) => {
  const modalRoot = document.getElementById('side-modal-root');
  const divElement = document.createElement('div');
  divElement.className = "side-modal";
  const [element] = useState(divElement);

  useEffect(() => {
    modalRoot.appendChild(element);

    return () => {
      modalRoot.removeChild(element);
    }
  }, [modalRoot, element]);

  return createPortal(children, element);
}

const Modal = ({ children, open, placement = 'right', onRequestClose, destroyOnClose, newest }) => {
  return (
    <Portal>
      <div 
        onMouseDown={() => !!onRequestClose ? onRequestClose(): null}
        className={`side-modal__wrapper position-fixed d-flex overflow-hidden ${open ? 'open': 'closed'}`}
      >
        <div 
          onClick={e => e.stopPropagation()}
          onMouseDown={e => e.stopPropagation()}
          className={`side-modal__children-wrapper${newest ? '-new' : ''} ${placement} ${open ? 'open': 'closed'}`}
        >
          {!!open 
            ? children
            : !destroyOnClose ? children : null
          }
        </div>
      </div>
    </Portal>
  )
}

Modal.propTypes = {
  children: PropTypes.element.isRequired,
  open: PropTypes.bool.isRequired,
  onRequestClose: PropTypes.func,
  placement: PropTypes.string,
  destroyOnClose: PropTypes.bool,
  newest: PropTypes.bool,
}

export default Modal;
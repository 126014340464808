import { createDraftSafeSelector } from '@reduxjs/toolkit';
import { get } from 'lodash';

import { priceListsSelector } from '../reducers/priceLists'

export const activePriceLists = createDraftSafeSelector(
  priceListsSelector.selectAll,
  priceLists => !!priceLists
    ? priceLists.filter(priceList => get(priceList, 'status') === 'active')
    : []
)

export const activeExtraPriceLists = createDraftSafeSelector(
  activePriceLists,
  priceLists => !!priceLists
    ? priceLists.filter(priceList => !get(priceList, 'main'))
    : []
)

export const getMainPriceList = createDraftSafeSelector(
  activePriceLists,
  priceLists => !!priceLists ? priceLists.find(priceList => !!get(priceList, 'main')): null
)

export const getPriceListById = id => createDraftSafeSelector(
  state => priceListsSelector.selectById(state, id),
  priceList => !!priceList && get(priceList, 'status') === 'active' ? priceList : null
)
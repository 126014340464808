import React, { useEffect } from 'react';
import { Field } from 'react-final-form';
import { I18n } from '@aws-amplify/core';
import { capitalize, get } from 'lodash';
import { renderField } from '../../../../../components/forms/fields/V2';
import { COUNTRIES } from '../../../../../utils/enums/countries';
import thirdType from '../../../../../components/countriesData/mexico/thirdType';
import { Grid, Typography } from '@alegradev/smile-ui-react';
import {
  getDefaultForeigMexicoAccountReceivableCategory,
  getDefaultForeigMexicoDebtToPayCategory,
  getDefaultMexicoAccountReceivableCategory,
  getDefaultMexicoDebtToPayCategory,
} from '../../../../../selectors/categories';
import { useSelector } from 'react-redux';

const mexicoThirdType = (props) => {
  const { Col, Row } = Grid;
  const thirdTypeValue = get(props, 'values.thirdType', '');
  const changeAction = get(props, 'form.change', () => {});

  const mexicoDefaultNationalAccountReceivableCategory = useSelector(
    getDefaultMexicoAccountReceivableCategory
  );
  const mexicoDefaultNationalDebtToPayCategory = useSelector(
    getDefaultMexicoDebtToPayCategory
  );

  const mexicoDefaultForeignAccountReceivableCategory = useSelector(
    getDefaultForeigMexicoAccountReceivableCategory
  );
  const mexicoDefaultForeignDebtToPayCategory = useSelector(
    getDefaultForeigMexicoDebtToPayCategory
  );

  useEffect(() => {
    if (thirdTypeValue) {
      if (thirdTypeValue === thirdType[0].value) {
        changeAction(
          'accounting.accountReceivable',
          mexicoDefaultNationalAccountReceivableCategory?.id
        );
        changeAction(
          'accounting.debtToPay',
          mexicoDefaultNationalDebtToPayCategory?.id
        );
      } else {
        changeAction(
          'accounting.accountReceivable',
          mexicoDefaultForeignAccountReceivableCategory?.id
        );
        changeAction(
          'accounting.debtToPay',
          mexicoDefaultForeignDebtToPayCategory?.id
        );
      }
    }
  }, [thirdTypeValue]);

  return (
    <Col xs={12}>
      <Typography
        type='label-2'
        text={capitalize(
          I18n.get(
            'thirdType',
            'Indica si el contacto que crearás es nacional o extranjero:'
          )
        )}
      />
      <Row>
        <Col xs={6}>
          <Field
            name='thirdType'
            component={renderField}
            type='radio'
            fieldType='radio'
            label={capitalize(I18n.get('national', 'Nacional'))}
            value={thirdType[0].value}
            required
          />
        </Col>
        <Col xs={6}>
          <Field
            name='thirdType'
            component={renderField}
            type='radio'
            fieldType='radio'
            options={thirdType}
            label={capitalize(I18n.get('foreign', 'Extranjero'))}
            value={thirdType[1].value}
            required
          />
        </Col>
      </Row>
    </Col>
  );
};

const renderThirdType = (props) => {
  switch (props.country) {
    case COUNTRIES.MEXICO:
      return mexicoThirdType(props);
    default:
      return null;
  }
};

export default renderThirdType;

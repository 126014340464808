import React, { useRef } from 'react';
import { Switch, Route, useLocation } from 'react-router-dom';

// import Menu from './Menu';
import Shifts from './Shifts/Shifts';

const Statistics = () => {
  const location = useLocation();
  const containerRef = useRef();

  React.useEffect(() => {
    containerRef.current.scrollTo({ top: 0 });
  }, [location]);

  return (
    <div ref={containerRef} className="h-100 w-100 statistics">
      <Switch>
        <Route path="/" component={Shifts}/>
        {/* <Route path="/" component={Menu} /> */}
      </Switch>
    </div>
  )
}

export default Statistics;
import Typography from '../Typography';
import PropTypes from 'prop-types';

/**
 *  @param {Object} props - Component props.
 * @param {string} props.title Title of the loader
 * @returns  {JSX.Element} A loader element.
 */
function Loader({ title }) {
  return (
    <div className='custom-loader'>
      <div className='custom-loader-spinner'></div>
      <Typography type='body-2-regular' variant='secondary' text={title} />
    </div>
  );
}

Loader.propTypes = {
  title: PropTypes.string,
};

export default Loader;

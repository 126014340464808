import { useState, useEffect, useCallback } from "react";
import { I18n } from "@aws-amplify/core";
import { get } from "lodash";

import { handleError } from "../../utils/errors";

const useLoading = ({ ref, isLoading, isFetching, error, data, tableIndicators, setErrors, setControlledPageCount, blockLoading, isLoadingMutation }) => {
  const [loading, setLoading] = useState(false);

  const startLoading = useCallback(() => {
    if (ref.current) {
      setLoading(true)
    }
  }, [ref]);

  const stopLoading = useCallback(() => {
    if (ref.current) {
      setLoading(false);
    }
  }, [ref]);

  useEffect(() => {
    if ((isLoading || isFetching) && !blockLoading) {
      startLoading();
      setErrors(null);
    }
    if (!!error)
      setErrors(handleError(error, { defaultMessage: I18n.get("itemsLoadError", "Sucedió un error cargando los productos") }));
    if (!!data)
      setControlledPageCount(Math.ceil(get(data, "metadata.total", 0) / tableIndicators?.pageSize));

    return () => stopLoading();
  }, [isLoading,
    isFetching,
    error,
    data,
    tableIndicators?.pageSize,
    setErrors,
    setControlledPageCount,
    blockLoading,
    isLoadingMutation,
    startLoading,
    stopLoading])

  return {
    loading,
    startLoading,
    stopLoading,
  }
};

export default useLoading;
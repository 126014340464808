import { createDraftSafeSelector } from '@reduxjs/toolkit';

import { paymentsSelector } from '../reducers/payments'

const paymentsDataSelector = state => state.payments; 

export const payments = createDraftSafeSelector(
  paymentsSelector.selectAll,
  payments => !!payments ? payments : [] 
)

export const getPaymentById = id => createDraftSafeSelector(
  state => paymentsSelector.selectById(state, id),
  payment => payment
)

export const loadingSelector = createDraftSafeSelector(
  paymentsDataSelector,
  data => data.loading === 'loading'
)

export const errorSelector = createDraftSafeSelector(
  paymentsDataSelector,
  data => data.error
)

export const totalSelector = createDraftSafeSelector(
  paymentsDataSelector,
  data => data.total
)
import { I18n } from 'aws-amplify';
import { get, capitalize } from 'lodash';
import { validateCreditLimit } from '../validations';

export const panamaValidation = (values) => {
  let errors = {};
  const identificationType = get(values, 'identification.type', '');
  const identificationNumber = get(values, 'identification.passport', '');
  const identificationRuc = get(values, 'identification.number', '');
  const isCorrectRuc = get(values, 'isCorrectRuc', '');
  const identificationDv = get(values, 'identification.dv', '');
  const kindOfPerson = get(values, 'identification.kindOfPerson', '');
  const lastName = get(values, 'lastName', '');
  const address = get(values, 'address.address', '');
  const combinedAddress = get(values, 'address.combined', '');
  const country = get(values, 'address.country', '');
  const phone = get(values, 'phonePrimary', '');
  const mobile = get(values, 'mobile', '');
  const isElectronic = get(values, 'isElectronic', '');

  const isRequiredField = () => {
    let isRequired = true;
    if (!identificationType && !kindOfPerson && !isElectronic) return false;
    if (identificationType === 'FINAL_CONSUMER') isRequired = false;
    return isRequired;
  };

  const isRequiredFieldAdress = () => {
    let isRequired = true;
    if (!identificationType && !kindOfPerson && !isElectronic) return false;
    if (
      identificationType === 'FINAL_CONSUMER' ||
      identificationType === 'FOREIGN' ||
      identificationType === 'FOREIGN_COMPANY'
    )
      isRequired = false;
    return isRequired;
  };

  if (!identificationType && identificationType !== 'FOREIGN' && isElectronic)
    errors.identification = {
      ...errors.identification,
      type: capitalize(
        I18n.get(
          'selectTheIdentificationType',
          'seleccione el tipo de identificación'
        )
      ),
    };

  if (
    !kindOfPerson &&
    isRequiredField() &&
    identificationType !== 'FOREIGN' &&
    identificationType !== 'FOREIGN_COMPANY' &&
    isElectronic
  )
    errors.kindOfPerson = capitalize(
      I18n.get('thisFieldIsRequired', 'Este campo es obligatorio')
    );

  if (
    !identificationNumber &&
    (identificationType === 'FOREIGN' ||
      identificationType === 'FOREIGN_COMPANY')
  )
    errors.identification = {
      ...errors.identification,
      number: capitalize(I18n.get('enterAnID', 'Ingresa una identificación')),
    };

  if (
    !identificationRuc &&
    isRequiredField() &&
    identificationType !== 'FOREIGN' &&
    identificationType !== 'FOREIGN_COMPANY'
  )
    errors.identification = {
      ...errors.identification,
      number: capitalize(
        I18n.get('thisFieldIsRequired', 'Este campo es obligatorio')
      ),
    };

  if (
    identificationRuc &&
    isRequiredField &&
    identificationType === 'TAXPAYER'
  ) {
    const isCorrectFormat = identificationRuc.match(
      /^(([0-9]{1,10})|(E|N|PE)|(([0-9]|10|11|12|13)-NT)|(([0-9]|10|11|12|13)-N))-([0-9]{1,4})-([0-9]{1,9})$/g
    );
    if (!isCorrectFormat)
      errors.identification = {
        ...errors.identification,
        number: I18n.get('rucIsInvalidFormat', 'Ingrese un formato correcto'),
      };
  }

  if (
    !identificationDv &&
    isRequiredField() &&
    identificationType !== 'FOREIGN' &&
    identificationType !== 'FOREIGN_COMPANY'
  )
    errors.identification = {
      ...errors.identification,
      dv: capitalize(
        I18n.get('thisFieldIsRequired', 'Este campo es obligatorio')
      ),
    };

  if (!lastName && kindOfPerson === 'PERSON_ENTITY')
    errors.lastName = capitalize(
      I18n.get('thisFieldIsRequired', 'Este campo es obligatorio')
    );

  if (!address && isRequiredFieldAdress())
    errors.address = {
      ...errors.address,
      address: capitalize(
        I18n.get('thisFieldIsRequired', 'Este campo es obligatorio')
      ),
    };

  if (!combinedAddress && isRequiredFieldAdress())
    errors.address = {
      ...errors.address,
      combined: capitalize(
        I18n.get('thisFieldIsRequired', 'Este campo es obligatorio')
      ),
    };

  if (!country && identificationType === 'FOREIGN')
    errors.address = {
      ...errors.address,
      country: capitalize(
        I18n.get('thisFieldIsRequired', 'Este campo es obligatorio')
      ),
    };

  if (phone && phone.length < 8) {
    errors.phonePrimary = capitalize(
      I18n.get('enterValidPhoneNumber', 'Ingrese un número de teléfono válido')
    );
  }

  if (mobile && mobile.length < 9) {
    errors.mobile = capitalize(
      I18n.get('enterValidPhoneNumber', 'Ingrese un número de teléfono válido')
    );
  }

  const creditLimitError = validateCreditLimit(values);
  if (creditLimitError) {
    errors.creditLimit = creditLimitError;
  }
  return errors;
};

import React from 'react'
import { I18n } from 'aws-amplify';
import { replaceAndParse } from '../../../../utils'

import imageTermsAndConditions from '../../../../assets/images/image_terms_and_conditions.png'

const NewTermsOnboard = ({ nextStep, signLater }) => (
  <>
    <div className="d-flex flex-column justify-content-center align-items-center">
      <img src={imageTermsAndConditions} style={{ width: '100%' }} alt="" />
      <div className="my-4 px-5">
        <p className="h4 text-capitalize-first mb-5" style={{ lineHeight: 1.6 }}>
          {replaceAndParse(I18n.get('updateInTermsAndConditions.description.1', 'Se han actualizado los <b>términos y condiciones sobre la facturación electrónica. </b>Para aceptarlos solo debes tener a la mano los datos del represente legal.'))}
        </p>
        <p className="h4 text-capitalize-first">
          {I18n.get('updateInTermsAndConditions.description.2', '¡Con Alegra estás siempre al día con la DIAN!')}
        </p>
      </div>
    </div>

    <div className="modal__bottom w-100 p-3 d-flex justify-content-end align-items-center shadow">
      <div className="modal__bottom-buttons d-flex">
        <button
          type="button"
          className="btn btn-cancel text-capitalize-first"
          onClick={signLater}
        >
          {I18n.get('later', 'Más tarde')}
        </button>
        <button
          type="button"
          data-testid="modal-submit-btn"
          onClick={nextStep}
          className="btn btn-submit text-capitalize-first"
        >
          {I18n.get('readAndSign', 'Leer y aceptar')}
        </button>
      </div>
    </div>
  </>
)

export default NewTermsOnboard
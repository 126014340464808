import { PageHeading } from '@alegradev/smile-ui-react';
import { useHistory } from 'react-router-dom';
import { I18n } from 'aws-amplify';
import { useContact } from './ContactProvider';

function ContactHeader() {
  const history = useHistory();
  const { editable, ignoreRepeated } = useContact();
  
  return (
    <PageHeading
      back={{
        title: I18n.get('contacts', 'Contactos'),
        action: () => history.push('/contacts'),
      }}
      title={
        editable && !ignoreRepeated
          ? I18n.get('editClient', 'Editar cliente')
          : I18n.get('newClient', 'Nuevo cliente')
      }
    />
  );
}

export default ContactHeader;

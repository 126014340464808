import { Divider, Icon, Typography } from '@alegradev/smile-ui-react';
import { I18n } from 'aws-amplify';
import { capitalize, get } from 'lodash';
import { getPaymentMethodName } from '../../settings/PaymentMethods/utils';
import { IconInfoCircle, IconTransferIn } from '@tabler/icons-react';

const PaymentMethodDetail = ({ id, name, instructions, status, logo, qr }) => {
  const hasLogo = get(logo, 'url', null);
  const hasQr = get(qr, 'url', null);

  return (
    <div className='mt-2 pt-5 payment-method-detail__container'>
      <div className='col-sm-12 d-flex'>
        <div className='col-md-4 d-flex justify-content-center align-items-center'>
          {hasLogo ? (
            <img src={logo.url} width={150} height={150} />
          ) : (
            <Icon
              icon={IconTransferIn}
              color='#94A3B8'
              width='100'
              height='100'
            />
          )}
        </div>
        <div className='col-md-8'>
          <div className='d-flex flex-column'>
            <Typography
              type='body-3-regular'
              text={capitalize(
                I18n.get('paymentMethodName', 'Nombre del método de pago')
              )}
              variant='primary'
            />
            <div className='payment-method-detail_name_container'>
              <Typography
                type='body-3-regular'
                text={getPaymentMethodName(name)}
                variant='secondary'
              />
            </div>
          </div>
          <div className='d-flex flex-column mt-5'>
            <Typography
              type='body-3-regular'
              text={capitalize(
                I18n.get('paymentInstructions', 'Indicaciones de pago')
              )}
              variant='primary'
            />
            <div className='payment-method-detail_instructions_container'>
              <Typography
                type='body-3-regular'
                text={instructions}
                variant='secondary'
              />
            </div>
          </div>
        </div>
      </div>
      {hasQr && (
        <div>
          <Divider />
          <div className='mt-2 payment-method-detail__container'>
            <div className='col-sm-12 d-flex p-0'>
              <div className='col-md-8 p-0'>
                <div className='d-flex flex-column'>
                  <Typography
                    type='body-3-bold'
                    text={I18n.get('associatedQRCode', 'Código QR')}
                    variant='primary'
                  />
                  <Typography
                    type='body-3-regular'
                    text={I18n.get(
                      'QRPaymentMethodDesciption',
                      'Tus clientes podrán escanearlo en la pantalla para pagarte en el punto de venta'
                    )}
                    variant='tertiary'
                  />
                </div>
              </div>
              <div className='col-md-4 d-flex justify-content-center align-items-center'>
                <img src={qr.url} width={100} height={100} />
              </div>
            </div>
          </div>
        </div>
      )}
      <div className='payment-method-detail__notification my-3'>
        <Icon icon={IconInfoCircle} color='#4338CA' size='extraLarge' />
        <Typography
          type='body-3-regular'
          text={I18n.get(
            'customPaymentMethodDisclaimer',
            'Este método de pago fue creado por ti. Alegra no se hace responsable por los pagos realizados a través de este medio.'
          )}
          variant='secondary'
        />
      </div>
    </div>
  );
};
export default PaymentMethodDetail;

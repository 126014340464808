import React from 'react'
import PropTypes from 'prop-types'; 

import Detail from './Detail';
import Pagination from './Pagination';

const MasterDetail = props => (
  <div className={`master-detail d-flex ${props.className ? props.className : ''}`}>
    <Pagination 
      {...props}
      className={props.paginationClassName}
      header={props.paginationHeader}
    />
    {!!props.editView 
      ? props.editComponent
      : (
        <Detail 
          {...props}
          className={props.detailClassName}
          header={props.detailHeader}
          children={props.detailChildren}
        />
      )
    }
  </div>
)

MasterDetail.propTypes = {
  onFetchData: PropTypes.func,
  paginationData: PropTypes.shape({
    start: PropTypes.number,
    limit: PropTypes.number,
    total: PropTypes.number,
    sortDirection: PropTypes.string,
  }),
  loading: PropTypes.bool,
  selected: PropTypes.bool,
  paginationHeader: PropTypes.element,
  detailHeader: PropTypes.element,
  detailChildren: PropTypes.element,
  options: PropTypes.array.isRequired,
  className: PropTypes.string,
  paginationClassName: PropTypes.string,
  detailClassName: PropTypes.string,
  onSelect: PropTypes.func,
  onForward: PropTypes.func,
  onBackward: PropTypes.func,
  error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  errorElement: PropTypes.element,
  emptyElement: PropTypes.element,
  noSelectedElement: PropTypes.element,
  notAllowedToIndex: PropTypes.string,
  notAllowedToView: PropTypes.string,
  editView: PropTypes.bool,
  editComponent: PropTypes.element,
}

export default MasterDetail;


import { I18n } from '@aws-amplify/core';
import { get, capitalize } from 'lodash';

export const validate = (values, sendBy) => {
  let errors = {};

  const email = get(values, 'email', '');
  const phone = get(values, 'phone', '');
  const countryKey = get(values, 'countryKey', '');

  if (!email && sendBy === 'email')
    errors.email = capitalize(I18n.get('requiredField', 'campo requerido'));

  if (
    !!email &&
    !/^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/.test(email) &&
    sendBy === 'email'
  )
    errors.email = capitalize(
      I18n.get('enterAValidEmail', 'Ingresa un correo válido')
    );

  return errors;
};

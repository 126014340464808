import React, { useState, useEffect, useRef, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { I18n } from '@aws-amplify/core';
import { capitalize, get } from 'lodash';
import { Tooltip, Icon } from '@alegradev/smile-ui-react';

import { handleError } from '../../../utils/errors';
import { openModal } from '../../../reducers/modals';
import { hasPermissionTo } from '../../../selectors/auth';
import Table from '../../common/GrayTable';
import Header from '../../settings/common/Header';
import Actions from '../PriceLists/Actions';

import { useConnectionStatus } from '../../../hooks/useConnectionStatus';
import { usePriceListsQuery } from './queries';
import { useDeletePriceList, useUpdatePriceList } from './mutations';
import { useIsMutating } from '@tanstack/react-query';
import { Filters } from '../../../utils/filter';
import { sendNewGTMEvent } from '../../../reducers/company';
import { IconPlus } from '@tabler/icons-react';

const filtersInstance = new Filters({
  metadata: true,
  order_field: 'id',
});

const PriceLists = () => {
  const connectionStatus = useConnectionStatus();
  const dispatch = useDispatch();
  const can = useSelector(hasPermissionTo);
  const ref = useRef(null);
  const [error, setError] = useState();
  const [tableIndicators, setTableIndicators] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  const [filters, setFilters] = useState({
    ...filtersInstance.getFilters(),
  });
  filtersInstance.setUpdater(setFilters);

  const isMutating = useIsMutating();

  const { mutate: deletePriceList } = useDeletePriceList();
  const { mutate: updatePriceList } = useUpdatePriceList();

  useEffect(() => {
    ref.current = true;
    window.dataLayer.push({
      event: 'VirtualPageView',
      virtualPageURL: '/inventory/price-lists',
      virtualPageTitle: 'Price Lists',
    });

    return () => (ref.current = false);
  }, []);

  const {
    data,
    isFetching,
    refetch,
    error: queryError,
    isError,
  } = usePriceListsQuery(filters);

  useEffect(() => {
    if (isError)
      setError(
        handleError(
          queryError,
          {
            defaultMessage: I18n.get(
              'priceListsLoadError',
              'Sucedió un error cargando las listas de precios.'
            )
          }
        )
      );
  }, [queryError, isError]);

  useEffect(() => {
    filtersInstance.updatePagination(tableIndicators);
  }, [tableIndicators]);

  const columns = useMemo(
    () => [
      {
        Header: capitalize(I18n.get('name', 'Nombre')),
        sortable: false,
        accessor: 'name',
        Cell: ({ value, row }) => {
          return (
            <p
              className='h4 text-primary text-truncate pointer styled-hover'
              onClick={() =>
                can('edit', 'price-lists') &&
                  get(row, 'original.editable', false)
                  ? dispatch(
                    openModal({
                      modal: 'priceList',
                      params: {
                        priceList: { ...row.original },
                        onRefresh: () => refetch(),
                      },
                    })
                  )
                  : null
              }
              hovercontent={value}
            >
              {value}
            </p>
          );
        },
      },
      {
        Header: capitalize(I18n.get('type', 'Tipo')),
        accessor: 'type',
        Cell: ({ value }) => (
          <p className='h5 text-muted'>
            {I18n.get(`priceListType.${value}`, value)}
          </p>
        ),
      },
      {
        Header: capitalize(I18n.get('actions', 'Acciones')),
        id: 'actions',
        sortable: false,
        minWidth: 110,
        maxWidth: 150,
        Cell: ({ row }) => (
          <Actions
            priceList={row.original}
            onRefresh={() => refetch()}
            onView={() =>
              dispatch(
                openModal({
                  modal: 'priceList',
                  params: {
                    priceList: { ...row.original },
                    onRefresh: () => refetch(),
                  },
                })
              )
            }
            deletePriceList={deletePriceList}
            updatePriceList={updatePriceList}
          />
        ),
      },
    ],
    [refetch, dispatch, tableIndicators, can]
  );

  const handleOpenPriceListModal = () => {
    dispatch(
      openModal({
        modal: 'priceList',
        params: {
          onRefresh: () => refetch(),
        },
      })
    );
    dispatch(sendNewGTMEvent('pos-price-list-started'));
  };

  return (
    <div className='container p-5'>
      <div className='d-flex flex-column'>
        <Header
          title={
            <p className='text-title-secundary'>
              {I18n.get('priceLists', 'Listas de precios')}
            </p>
          }
          subtitle={
            <p className='h5 text-muted text-capitalize-first'>
              {I18n.get(
                'priceListsSubtitle',
                'Asigna varios precios a tus productos con un valor fijo o un porcentaje de descuento sobre el precio base.'
              )}
              <a
                href='https://ayuda.alegra.com/es/crea-listas-de-precio-en-el-sistema-punto-de-venta-pos'
                target='_blank'
                rel='noreferrer'
              >
                {I18n.get('knowMore', 'Saber más')}
              </a>
            </p>
          }
          actions={
            <div className='d-flex'>
              <Tooltip
                visible={!can('add', 'price-lists')}
                overlay={I18n.get(
                  'userNotAllowed.price-lists.add',
                  'no tienes permisos para agregar listas de precio'
                )}
              >
                <button
                  type='button'
                  className='btn btn-submit-large d-flex justify-content-center align-items-center'
                  disabled={!can('add', 'price-lists') || !connectionStatus}
                  onClick={handleOpenPriceListModal}
                >
                  <Icon icon={IconPlus} extraClass='icon icon-white mr-2' />
                  {I18n.get('newPriceList', 'Nueva lista')}
                </button>
              </Tooltip>
            </div>
          }
        />
        <div className='shadowp-4'>
          <Table
            loading={isFetching || Boolean(isMutating)}
            data={data?.data ?? []}
            total={data?.total || 0}
            error={error}
            _pageSize={tableIndicators.pageSize}
            showNetworkConnectionError
            onRefresh={refetch}
            noDataText={
              <div className='text-center'>
                <p>
                  {capitalize(
                    I18n.get(
                      'priceListsNoDataText',
                      'Crea tu primera lista de precios en un parpadeo. 👀'
                    )
                  )}
                </p>

                <Tooltip
                  visible={!can('add', 'price-lists')}
                  overlay={I18n.get(
                    'userNotAllowed.price-lists.add',
                    'no tienes permisos para agregar listas de precio'
                  )}
                >
                  <button
                    type='button'
                    className='btn btn-submit mr-3'
                    onClick={handleOpenPriceListModal}
                  >
                    {I18n.get('newPriceList', 'Nueva lista')}
                  </button>
                </Tooltip>
              </div>
            }
            columns={columns}
            onFetchData={setTableIndicators}
          />
        </div>
      </div>
    </div>
  );
};

export default PriceLists;

const kindOfPerson = [
  {
    "id": "1",
    "value": "Persona jurídica",
    "key": "LEGAL_ENTITY"
  },
  {
    "id": "2",
    "value": "Persona natural",
    "key": "PERSON_ENTITY"
  },
]

export default kindOfPerson
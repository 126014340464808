import React from 'react'
import { I18n } from '@aws-amplify/core';

const LoadingError = ({ onRetry }) => {
  return (
    <div className="plans-container d-flex align-items-center justify-content-center flex-column px-3">
      <button
        type="button"
        className="btn btn-submit mb-3"
        onClick={() => onRetry()}
      >
        {I18n.get('retry', 'reintentar')}
      </button>

      <p className="text-capitalize-first h4 text-center">
        {I18n.get('loadingPlansError', 'Algo salió mal a la hora de obtener los planes 😔. Intenta de nuevo o ponte en contacto con soporte.')}
      </p>
    </div>
  )
}

export default LoadingError;
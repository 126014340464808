import React from 'react';
import { Field } from 'react-final-form';
import { I18n } from '@aws-amplify/core';
import { capitalize, get } from 'lodash';

import { renderField, renderPriceListType } from '../fields/V0/Fields';
import { renderField as renderNewField } from '../fields/V2';
import { useDecimalsVersionsGroup } from '../../../hooks/useDecimalsVersionsGroup';
import { useSelector } from 'react-redux';
import { decimalSeparator } from '../../../selectors/company';

const PriceList = ({ values, form }) => {
  const { isDecimalActive } = useDecimalsVersionsGroup();
  const separator = useSelector(decimalSeparator);

  return (
    <div className="form-body h-100 p-4 overflow-hidden">
      <div className="form-body__fields form-row justify-content-start">

        <Field
          name="type"
          className="col-12"
          component={renderPriceListType}
          label={capitalize(I18n.get('type', 'Tipo'))}
        />

        <Field
          name="name"
          className="col-5"
          component={renderField}
          type="text"
          label={capitalize(I18n.get('name', 'Nombre'))}
          required
        />

        {get(values, 'type', '') === 'percentage' && (
          <div className="col-5">
            <Field
            name="percentage"
            component={renderNewField}
            fieldType="decimal"
            decimals={isDecimalActive ? 1 : null}
            separator={separator}
            onDecimalsError={(status) => form?.change('decimalsError', {
              ...values?.decimalsError,
              percentage: status,
            })}
            label={capitalize(I18n.get('percentage', 'Porcentaje'))}
            required
          />
          </div>
        )}
      </div>
    </div>
  )
}

export default PriceList;
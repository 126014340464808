import { I18n } from "aws-amplify";
import { capitalize } from "lodash";

export const customFieldTypes = language => {
    switch (language) {
        case 'es':
            return (
                [
                    {
                        value: 'text',
                        label: capitalize(I18n.get('customFieldType.text', 'Texto')),
                    },
                    {
                        value: 'int',
                        label: capitalize(I18n.get('customFieldType.int', 'Número')),
                    },
                    {
                        value: 'decimal',
                        label: capitalize(I18n.get('customFieldType.decimal', 'Número decimal')),
                    },
                    {
                        value: 'date',
                        label: capitalize(I18n.get('customFieldType.date', 'Fecha')),
                    },
                    {
                        value: 'boolean',
                        label: capitalize(I18n.get('customFieldType.boolean', 'Si/No')),
                    },
                    {
                        value: 'optionsList',
                        label: capitalize(I18n.get('customFieldType.optionsList', 'Lista de opciones')),
                    }
                ]
            )
        case 'en':
            return (
                [
                    {
                        value: 'text',
                        label: capitalize(I18n.get('customFieldType.text', 'Text')),
                    },
                    {
                        value: 'int',
                        label: capitalize(I18n.get('customFieldType.int', 'Number')),
                    },
                    {
                        value: 'decimal',
                        label: capitalize(I18n.get('customFieldType.decimal', 'Decimal number')),
                    },
                    {
                        value: 'date',
                        label: capitalize(I18n.get('customFieldType.date', 'Date')),
                    },
                    {
                        value: 'boolean',
                        label: capitalize(I18n.get('customFieldType.boolean', 'Yes/No')),
                    },
                    {
                        value: 'optionsList',
                        label: capitalize(I18n.get('customFieldType.optionsList', 'Option list')),
                    }
                ]
            )
        default:
            return (
                [
                    {
                        value: 'text',
                        label: capitalize(I18n.get('customFieldType.text', 'Texto')),
                    },
                    {
                        value: 'int',
                        label: capitalize(I18n.get('customFieldType.int', 'Número')),
                    },
                    {
                        value: 'decimal',
                        label: capitalize(I18n.get('customFieldType.decimal', 'Número decimal')),
                    },
                    {
                        value: 'date',
                        label: capitalize(I18n.get('customFieldType.date', 'Fecha')),
                    },
                    {
                        value: 'boolean',
                        label: capitalize(I18n.get('customFieldType.boolean', 'Si/No')),
                    },
                    {
                        value: 'optionsList',
                        label: capitalize(I18n.get('customFieldType.optionsList', 'Lista de opciones')),
                    }
                ]
            )
    }
}

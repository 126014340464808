import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { get } from 'lodash';
import { Grid } from '@alegradev/smile-ui-react';

import {
  getDefaultForeigMexicoAccountReceivableCategory,
  getDefaultForeigMexicoDebtToPayCategory,
  getDefaultMexicoAccountReceivableCategory,
  getDefaultMexicoDebtToPayCategory,
} from '../../../../../selectors/categories';

import {
  address as addressSelector,
  country as countrySelector,
} from '../../../../../selectors/company';

import { COUNTRIES } from '../../../../../utils/enums/countries';

import renderIdentification from './Identification';
import renderName from './Name';
import renderAddress from './Address';
import renderInfo from './Info';
import renderKindOfPerson from './KindOfPerson';
import renderTaxLiability from './TaxLiability';
import renderIVACondition from './IVACondition';
import kindOfPersonOptions from '../../../../../components/countriesData/colombia/kindOfPerson';

import renderThirdType from './ThirdType';
import renderRegime from './Regime';
import renderFiscalId from './FiscalId';
import { regimes } from '../../../../../components/countriesData/colombia/regimes';
import { useContactForm } from '../FormProvider';

const BasicData = (props) => {
  const { Row } = Grid;
  const { handleClientSearch } = useContactForm();
  const identificationType = get(props, 'values.identification.type', '');
  const kinfOfPerson = get(props, 'values.kindOfPerson', '');
  const thirdType = get(props, 'values.thirdType', '');
  const identificationRuc = get(props, 'values.identification.ruc', '');
  const address = useSelector(addressSelector);
  const country = useSelector(countrySelector);

  // Mex default accounting
  const mexicoDefaultAccountReceivableCategory = useSelector(
    getDefaultMexicoAccountReceivableCategory
  );
  const mexicoDefaultDebtToPayCategory = useSelector(
    getDefaultMexicoDebtToPayCategory
  );

  const mexicoDefaultForeigAccountReceivableCategory = useSelector(
    getDefaultForeigMexicoAccountReceivableCategory
  );
  const mexicoDefaultFoiregDebtToPayCategory = useSelector(
    getDefaultForeigMexicoDebtToPayCategory
  );

  useEffect(() => {
    if (props.country === 'colombia') {
      const regimeOptions = (props) =>
        regimes.filter((regime) =>
          props.isElectronic
            ? regime.electronicInvoicingVersion === '2.1'
            : regime.electronicInvoicingVersion === '2.0'
        );
      if (
        identificationType === 'FOREIGN_NIT' ||
        identificationType === 'NIT'
      ) {
        props.form.mutators.setValues(
          'kindOfPerson',
          kindOfPersonOptions.find((option) => option.key === 'LEGAL_ENTITY')
            .key
        );

        props.form.mutators.setValues(
          'regime',
          regimeOptions(props).find((option) => option.key === 'COMMON_REGIME')
            .key
        );
      } else {
        props.form.mutators.setValues(
          'kindOfPerson',
          kindOfPersonOptions.find((option) => option.key === 'PERSON_ENTITY')
            .key
        );

        props.form.mutators.setValues(
          'regime',
          regimeOptions(props).find(
            (option) => option.key === 'SIMPLIFIED_REGIME'
          ).key
        );
      }
    }
  }, [identificationType]);

  useEffect(() => {
    if (country === COUNTRIES.MEXICO) {
      props.form.change('address.zipCode', get(address, 'zipCode', ''));
    }
  }, [address, country]);

  useEffect(() => {
    if (country === COUNTRIES.MEXICO) {
      if (thirdType === 'FOREIGN') {
        props.form.change(
          'accounting.accountReceivable',
          mexicoDefaultForeigAccountReceivableCategory
        );
        props.form.change(
          'accounting.debtToPay',
          mexicoDefaultFoiregDebtToPayCategory
        );
      }

      if (thirdType === 'NATIONAL') {
        props.form.change(
          'accounting.accountReceivable',
          mexicoDefaultAccountReceivableCategory
        );
        props.form.change(
          'accounting.debtToPay',
          mexicoDefaultDebtToPayCategory
        );
      }
    }
  }, [thirdType, country]);

  useEffect(() => {
    if (props.country === 'panama') {
      props.form.change('kindOfPerson', null);
    }
  }, [identificationType]);

  useEffect(() => {
    if (props.country === 'panama') {
      if (
        kinfOfPerson &&
        kinfOfPerson !== 'PERSON_EMPTY' &&
        identificationRuc
      ) {
        handleClientSearch();
      }
      if (kinfOfPerson === 'PERSON_EMPTY') {
        props.form.change('kindOfPerson', null);
      }
    }
  }, [kinfOfPerson]);

  return (
    <Row spacing='lg'>
      {renderThirdType(props)}

      {renderIdentification(props)}

      {renderKindOfPerson(props)}

      {renderTaxLiability(props)}

      {renderName(props)}

      {renderFiscalId(props)}

      {renderRegime(props)}

      {renderIVACondition(props)}

      {renderAddress(props)}

      {renderInfo(props)}
    </Row>
  );
};

export default BasicData;

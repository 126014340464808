import { Grid, Space, Typography } from '@alegradev/smile-ui-react';
import { I18n } from 'aws-amplify';
import { formatDateRelative } from '../../../utils';

const PrintPaymentHeader = ({ payment }) => {
  const { Wrapper } = Grid;
  return (
    <Wrapper direction={{ lg: 'column' }} align={{ lg: 'center' }}>
      <Space height={10} />

      <Typography
        type='body-2-bold'
        text={
          payment?.type === 'in'
            ? I18n.get('cashReceipt', 'Recibo de caja')
            : I18n.get('graduationProof', 'Comprobante de egreso')
        }
      />

      <Typography
        type='body-2-bold'
        text={
          payment?.type === 'in'
            ? `Nº RC${payment?.number}`
            : `Nº AF${payment?.number}`
        }
      />

      <Typography
        text={`<b>${I18n.get('creationDate', 'Fecha de creación')}:</b> ${formatDateRelative(payment?.date)}`}
        withHtml
      />

      <Space height={10} />
      <div className='border-bottom w-full' />
      <Space height={10} />
    </Wrapper>
  );
};

export default PrintPaymentHeader;

import { createDraftSafeSelector } from '@reduxjs/toolkit';
import { get } from 'lodash';

import { unitsSelector } from '../reducers/units';

export const activeProductUnits = createDraftSafeSelector(
  unitsSelector.selectAll,
  units => {
    return !!units
      ? units.filter(unit => get(unit, 'status') === 'active')
      : []
  }
);

export const allProductUnits = createDraftSafeSelector(
  unitsSelector.selectAll,
  units => units
);

export const getProductUnitById = id => createDraftSafeSelector(
  state => unitsSelector.selectById(state, id),
  unit => unit
)

export const getDefaultProductUnit = type => createDraftSafeSelector(
  [allProductUnits],
  units =>
    type === 'service'
      ? units.find(unit => get(unit, 'key') === 'service' || get(unit, 'key') === 'E48' || get(unit, 'key') === 'UND')
      : units.find(unit => get(unit, 'key') === 'unit' || get(unit, 'key') === 'XUN'|| get(unit, 'key') === 'UND')
)
import alegraAPI from "../../../reducers/alegraAPI";
import { useQuery } from "@tanstack/react-query";

const warehousesKeys = {
  all: 'warehouses',
  single: 'warehouse'
}

const fetchWarehouses = async (filters) => {
  const response = await alegraAPI.get("/warehouses", {
    ...filters,
  });

  return response.data;
}

const fetchSingleWarehouse = async (id) => {
  const response = await alegraAPI.get(`/warehouses/${id}`);

  return response.data;
}

export const useWarehousesQuery = (filters) => {
  return useQuery({
    queryKey: [warehousesKeys.all, filters ?? {}],
    queryFn: () => fetchWarehouses(filters ?? {}),
    keepPreviousData: true,
    staleTime: 1000 * 60 * 60 * 24,
    refetchOnReconnect: "always",
  })
};

export const useSingleWarehouseQuery = (id) => {
  return useQuery({
    queryKey: [warehousesKeys.single, { id }],
    queryFn: () => fetchSingleWarehouse(id),
    keepPreviousData: true,
    staleTime: 1000 * 60 * 60 * 24,
    refetchOnReconnect: "always",
  })
}
import { Container } from '@alegradev/smile-ui-react';
import { ContactForm } from './form';
import ContactHeader from './Header';

import { ContactProvider } from './ContactProvider';

function Contact() {
  return (
    <ContactProvider>
      <Container marginTop={30}>
        <ContactHeader />
        <ContactForm />
      </Container>
    </ContactProvider>
  );
}

export default Contact;

import React from 'react'
import { I18n } from '@aws-amplify/core';
import { graphqlOperation } from '@aws-amplify/api';
import { Form } from 'react-final-form'
import { useDispatch, useSelector } from 'react-redux'
import { get } from 'lodash'

import * as mutations from '../../../../graphql/mutations'
import { addPriceList } from '../../../../reducers/priceLists'
import { toast } from '../../../../utils'
import { formError } from '../../../../utils/errors'
import Body from '../../../forms/priceList/PriceList'
import { validate, transform } from '../../../forms/priceList/utils'
import Notification from '../../common/Notification'
import Bottom from '../../common/Bottom'
import Modal from '../common/Modal'
import { APIGraphqlSelector } from '../../../../selectors/app';

const PriceList = ({ isOpen, onRequestClose, onCreated }) => {
  const dispatch = useDispatch()
  const APIGraphql = useSelector(APIGraphqlSelector);
  
  const submit = async values => {
    const transformedValues = transform(values)

    try {
      const response = await APIGraphql(graphqlOperation(mutations.createPriceList, {
        priceList: transformedValues
      }))

      const priceList = !!get(response, 'data.createPriceList') ? get(response, 'data.createPriceList'): null

      if (!!priceList) {
        dispatch(addPriceList(priceList))
        if (!!onCreated)
          onCreated(priceList)
      }
      
      onRequestClose();
      toast.success({ title: I18n.get('priceListCreatedSuccessfully', 'lista de precio creada con éxito') })
    } catch (error) {
      return formError(error, I18n.get('createPriceListError', 'hubo un error en la creación de la lista de precio'))
    }
  }

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      title={I18n.get('newPriceList', 'nueva lista de precio')}
      // title={"test"}
    >
      <Form
        onSubmit={submit}
        validate={(values) => validate(values)}
        initialValues={{ type: 'percentage' }}
      >
        {({ handleSubmit, values, submitting, submitError }) => (
          <form 
            noValidate
            className="w-100 h-100 bg-white d-flex flex-column"
            onSubmit={handleSubmit}
          >
            <div className="side-modal__body">
              <Body values={values}/>
            </div>

            <Notification
              isOpen={!!submitError}
              type="error"
              text={submitError}
            />

            <Bottom
              onClose={() => onRequestClose()} 
              disabled={submitting} 
              submitting={submitting}
            />
          </form>
        )}
      </Form>
    </Modal>
  )
}

export default PriceList;
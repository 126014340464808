import React, { useEffect, useState } from 'react'
import { I18n } from '@aws-amplify/core';
import { useSelector } from 'react-redux';

import { country as countrySelector, membershipSelector, origin } from '../../../selectors/company'
import { activeNumerations } from '../../../selectors/numerations';
import { getDocumentType } from './utilities';
import { get } from 'lodash';
import { COUNTRIES } from '../../../utils/enums/countries';

const LastRow = ({ invoice }) => {
  const companyOrigin = useSelector(origin)
  const country = useSelector(countrySelector);
  const membership = useSelector(membershipSelector);
  const isElectronic = get(invoice, 'numberTemplate.isElectronic', false);
  const isPOSElectronicDocument = get(invoice, 'numberTemplate.documentType') === 'saleTicket' && isElectronic;

  const [showGeneratedBy, setShowGeneratedBy] = useState(true);

  const numerations = useSelector(activeNumerations);
  useEffect(() => {
    if(country !== COUNTRIES.COLOMBIA){
      const features = get(membership, 'features', []);
      const showAlegraPrintText = features.find(
        (feature) => feature.keyword === 'showAlegraPrintText'
      );

      const showAlegraPrintTextValue = get(showAlegraPrintText, 'include', false);
      setShowGeneratedBy(showAlegraPrintTextValue);

    }
  },[country, membership])
  return (
    <>
      {isElectronic && country === 'peru' && (
        <div className='text-center'>
          {getDocumentType(invoice, numerations) === 'invoice'
            ? I18n.get(
              'electronicInvoicePrintDescription',
              'Representación impresa de factura de venta electrónica'
            )
            : I18n.get(
              'electronicSaleTicketPrintDescription',
              'Representación impresa de boleta de venta electrónica'
            )}
        </div>
      )}

      {country === 'colombia' && isPOSElectronicDocument && (
        <p className='w-100 text-center py-2 h5 text-capitalize-first'>
          {I18n.get('electronicPOSDocumentPrintDescription', 'Representación gráfica del documento equivalente electrónico tiquete de máquina registradora con sistema P.O.S.')}
        </p>
      )}
      {showGeneratedBy && (
        <p className='w-100 text-center py-2 h5 text-capitalize-first'>
          {companyOrigin === 'partner-claro'
            ? I18n.get(
                'invoicePrintFooterClaro',
                'Generado en Software Contable Claro Negocios con tecnología de Alegra - software-contable.com.co'
              )
            : I18n.get(
                'invoicePrintFooter',
                'Generado en Alegra POS - alegra.com/pos'
              )}
        </p>
      )}
    </>
  );
};

export default LastRow;
import { I18n } from 'aws-amplify';
import { get, capitalize } from 'lodash';
import { validateCreditLimit } from '../validations';
import { zipCodes, zipCodesByValue } from '../../../../../../components/countriesData/colombia/zipCodes';

export const colombiaValidation = (values, { isElectronic }) => {
  let errors = {};

  const identificationType = get(values, 'identification.type', '');
  const identificationNumber = get(values, 'identification.number', '');
  const lastName = get(values, 'lastName', '');
  const kindOfPerson = get(values, 'kindOfPerson', '');
  const completeName =
    (identificationType === 'NIT' && kindOfPerson === 'PERSON_ENTITY') ||
    (identificationType !== 'NIT' && identificationType !== 'FOREIGN_NIT');

  if (!identificationType)
    errors.identification = {
      ...errors.identification,
      type: capitalize(
        I18n.get(
          'selectTheIdentificationType',
          'seleccione el tipo de identificación'
        )
      ),
    };

  if (!identificationNumber)
    errors.identification = {
      ...errors.identification,
      number: capitalize(I18n.get('enterAnID', 'Ingresa una identificación')),
    };

  if (
    identificationNumber &&
    ['RC', 'NIT', 'CC', 'NUIP', 'TI'].includes(identificationType)
  ) {
    const isCorrectFormat = /^[0-9]+$/.test(identificationNumber);
    if (!isCorrectFormat)
      errors.identification = {
        ...errors.identification,
        number: I18n.get(
          'onlyNumericValuesAreSupported',
          'Solo se admiten valores numéricos'
        ),
      };
  }

  if (!lastName && completeName)
    errors.lastName = capitalize(
      I18n.get(
        'youMustIncludeAtLeastOneLastName',
        'Debe incluir al menos un apellido'
      )
    );

  if (isElectronic) {
    const regime = get(values, 'regime', '');
    if (!kindOfPerson && identificationType === 'NIT')
      errors.kindOfPerson = capitalize(
        I18n.get('thisFieldIsRequired', 'Este campo es obligatorio')
      );
    if (!regime && identificationType === 'NIT')
      errors.regime = capitalize(
        I18n.get('thisFieldIsRequired', 'Este campo es obligatorio')
      );
  }

  const foreignId = ['DIE', 'PP', 'TE', 'FOREIGN_NIT'].includes(
    identificationType
  );

  const combinedAddress = get(values, 'address.combined', '');
  const address = get(values, 'address.address', '');

  const isRequired = () => {
    if (address || combinedAddress) {
      return true;
    }
    return false;
  };

  const zipCode = get(values, 'address.zipCode', '');
  if(isElectronic && zipCode) {
    const isZipCodeInDirectory = zipCodesByValue.has(zipCode);
    if (!isZipCodeInDirectory)
      errors.address = {
        ...errors.address,
        zipCode: capitalize(
          I18n.get('invalidZipCode', 'El código postal es inválido')
        ),}
  }

  if (!foreignId) {
    if (isRequired()) {
      if (!combinedAddress)
        errors.address = {
          ...errors.address,
          combined: capitalize(
            I18n.get('thisFieldIsRequired', 'Este campo es obligatorio')
          ),
        };
      if (!address)
        errors.address = {
          ...errors.address,
          address: capitalize(
            I18n.get('thisFieldIsRequired', 'Este campo es obligatorio')
          ),
        };
    }
  }

  const creditLimitError = validateCreditLimit(values);
  if (creditLimitError) {
    errors.creditLimit = creditLimitError;
  }

  return errors;
};

import React from "react";
import { FieldArray } from "react-final-form-arrays";
import { get } from "lodash";

import { Icon } from '@alegradev/smile-ui-react';
import { IconX } from "@tabler/icons-react";

const MinimalTable = ({ title, columns = [], data = [], className, tableStyle, formTable }) => {
  return (
    <div className={"details__body__table-container " + className}>
      <p className="h3 mb-5">
        {title || ""}
      </p>
      <table className="details__body__table mt-5" style={tableStyle}>
        <thead>
          <tr>
            {columns.map((column, index) => {
              return (
                <th key={index}>
                  <div className="text-muted m-0">
                    {(typeof column.header === 'function' ? column.header() : column.header) || ""}
                  </div>
                </th>
              );
            })}
            <th></th>
          </tr>
        </thead>
        <tbody>
          {formTable
            ? (<FieldArray name="items">
              {({ fields }) =>
                fields.map((name, index) => {
                  return (<tr key={index}>
                    {columns.map((column, i) => (
                      <td key={i} style={{ width: column?.width }}>
                        {column?.Render({ row: get(fields, `value.[${index}]`) }, name)}
                      </td>
                    ))}
                    <td>
                      <button
                        type="button"
                        className="btn-transparent-inline"
                        onClick={() => {
                          if (fields.length <= 1)
                            return;
                          fields.remove(index)
                        }}
                      >
                        <Icon icon={IconX} extraClass="icon-secondary" />
                      </button>
                    </td>
                  </tr>);
                })
              }
            </FieldArray>)
            : (data).map((row, index) => {
              return (<tr key={index}>
                {columns.map((column, index) => (
                  <td key={index} style={{ width: column?.width }}>
                    {column?.Render({ row })}
                  </td>
                ))}
              </tr>);
            })
          }
        </tbody>
      </table>
    </div>
  );
}

export default MinimalTable;
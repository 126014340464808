import { I18n } from '@aws-amplify/core';

const Notes = ({ notes }) => {
  if (!notes) return null;
  return (
    <div className='border-bottom py-2'>
      <p><strong>{I18n.get('notes', 'Notas')}</strong>{notes}</p>
    </div>
  );
};

export default Notes;
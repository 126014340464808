import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { I18n } from '@aws-amplify/core';
import { graphqlOperation } from '@aws-amplify/api';
import { get } from 'lodash';

import * as mutations from '../../../graphql/mutations';
import { toast } from '../../../utils';
import { formError, handleError } from '../../../utils/errors';
import { customFieldTransformer } from '../../../utils/transformers';
import { closeModal, openModal } from '../../../reducers/modals';
import {
  addCustomField,
  updateCustomField,
} from '../../../reducers/customFields';
import { sendGTMEvent, sendNewGTMEvent } from '../../../reducers/company';
import {
  validate,
  transform,
  transformFromQuery,
} from '../../forms/customField/utils';
import { replaceAndParse } from '../../../utils/index';
import { languageSelector } from '../../../selectors/auth';
import alegraAPI from '../../../reducers/alegraAPI';

import Notification from '../common/Notification';
import Bottom from '../common/Bottom';
import Body from '../../forms/customField/CustomField';
import Modal from '../../common/Modal';
import { APIGraphqlSelector } from '../../../selectors/app';
import { endAction, startAction } from '../../../reducers/monitoring';
import { calculateActionTimeSelector } from '../../../selectors/monitoring';

const CustomField = ({ onCreated }) => {
  const dispatch = useDispatch();
  const calculateActionTime = useSelector(calculateActionTimeSelector);
  const isOpen = useSelector((state) =>
    get(state, 'modals.customField.isOpen', false)
  );
  const customField = useSelector((state) =>
    get(state, 'modals.customField.params.customField', false)
  );
  const languagePrefix = useSelector(languageSelector).substring(0, 2);
  const [resetForm, setResetForm] = useState(false);
  const parseCustomField = customField
    ? transformFromQuery(customField)
    : false;
  const APIGraphql = useSelector(APIGraphqlSelector);

  const onRefresh = useSelector((state) =>
    get(state, 'modals.customField.params.onRefresh', false)
  );

  const close = () => {
    dispatch(closeModal({ modal: 'customField' }));
  };

  const open = () => {
    dispatch(
      openModal({
        modal: 'customField',
        params: {
          onRefresh,
        },
      })
    );
  };

  const submit = async (values) => {
    dispatch(startAction({action: 'createCustomField'}));
    const transformedValues = transform(values);

    let request;

    if (get(values, 'id') !== '1') {
      request = !!customField
        ? APIGraphql(
            graphqlOperation(mutations.updateCustomField, {
              customField: customFieldTransformer({
                ...customField,
                ...transformedValues,
              }),
            })
          )
        : APIGraphql(
            graphqlOperation(mutations.createCustomField, {
              customField: customFieldTransformer({ ...transformedValues }),
            })
          );
    } else {
      request = alegraAPI.put(`/custom-fields/${get(values, 'id')}`, {
        settings: JSON.parse(get(transformedValues, 'settings')),
      });
    }

    try {
      const response = await request;
      close();

      if (resetForm) {
        open();
        setResetForm(false);
      }

      const newCustomField = !customField
        ? get(response, 'data.createCustomField', null)
        : null;

      
      dispatch(endAction({action: 'createCustomField'}));
      if (!!newCustomField) {
        dispatch(sendGTMEvent('additional-field-created'));
        dispatch(
          sendNewGTMEvent('pos-custom-field-created', {
            error: 'no error',
            type: get(transformedValues, 'type', ''),
            responseTime: calculateActionTime("createCustomField")
          })
        );
        dispatch(addCustomField(newCustomField));
        if (!!onCreated) onCreated(newCustomField);
      } else {
        dispatch(
          updateCustomField({
            id: customField.id,
            changes: transformedValues,
          })
        );
      }

      if (!!onRefresh) onRefresh();

      if (!!parseCustomField)
        toast.success({
          title: I18n.get(
            'customFieldUpdated',
            'Campo adicional actualizado con éxito'
          ),
        });
      else
        toast.success({
          title: I18n.get(
            'customFieldCreatedSuccessfully',
            'Ya quedó guardado. 😎'
          ),
          subtitle: replaceAndParse(
            I18n.get(
              'customFieldCreatedSuccessfullyMessage',
              'Tu campo adicional {} está disponible para asociarlo a tus productos.'
            ),
            [
              `<span class="font-weight-bold">${get(
                newCustomField,
                'name',
                ''
              )}</span>`,
            ]
          ),
        });
    } catch (error) {
      const parseError = handleError(error);
      dispatch(
        sendNewGTMEvent('pos-custom-field-created', {
          error: parseError,
        })
      );
      return formError(
        error,
        I18n.get(
          'updateCustomFieldError',
          'Hubo un error al cambiar los datos del campo adicional'
        )
      );
    }
  };

  return (
    <Modal
      title={
        <div className='d-flex justify-content-between align-items-center w-100'>
          <div className='modal__header-title text-capitalize-first h2 text-primary text-truncate'>
            {!!parseCustomField
              ? parseCustomField.name
              : I18n.get(
                  'newCustomFieldModalTitle',
                  'Nuevo campo personalizable'
                )}
          </div>
        </div>
      }
      isOpen={isOpen}
      onRequestClose={() => close()}
      className='modal__custom-field'
      id='custom-field-modal-portal'
    >
      <Form
        onSubmit={submit}
        validate={(values) => validate(values)}
        initialValues={
          !!parseCustomField ? parseCustomField : { options: [{}] }
        }
        mutators={{
          ...arrayMutators,
        }}
        keepDirtyOnReinitialize
      >
        {({
          handleSubmit,
          submitting,
          values,
          submitError,
          pristine,
          form,
        }) => (
          <form noValidate onSubmit={handleSubmit}>
            <div className='modal__custom-field-body'>
              {!parseCustomField && (
                <p className='h5 py-3 text-subtitle-muted text-capitalize-first'>
                  {I18n.get(
                    'newCustomFieldModalSubtitle',
                    'Agrega información adicional para personalizar tus productos y servicios.'
                  )}
                </p>
              )}
              <Body
                values={values}
                fieldType={values.type ? values.type.value : 'text'}
                languagePrefix={languagePrefix}
                form={form}
              />
            </div>

            {!!submitError && !pristine && (
              <Notification isOpen={true} text={submitError} type='error' />
            )}

            <Bottom
              submitting={submitting}
              onClose={() => close()}
              onResetForm={setResetForm}
              resetForm={resetForm}
              hideRequired={true}
              submitText={
                !!parseCustomField
                  ? I18n.get('updateCustomField', 'Actualizar campo')
                  : I18n.get('createCustomField', 'Crear campo')
              }
              saveText={
                !!parseCustomField
                  ? I18n.get(
                      'updateAndCreateNewAlt',
                      'Actualizar y crear nuevo'
                    )
                  : I18n.get('saveAndCreateNewAlt', 'Guardar y crea nueva')
              }
              hideCancel
            />
          </form>
        )}
      </Form>
    </Modal>
  );
};

export default CustomField;

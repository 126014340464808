import React, { useCallback, useMemo, useState, useRef, useEffect } from 'react';
import { Field } from 'react-final-form';
import { I18n } from '@aws-amplify/core';
import { capitalize, repeat, get, debounce } from 'lodash';
import { useSelector, useDispatch } from 'react-redux'

import { hasPermissionTo } from '../../../selectors/auth'
import { activeClients } from '../../../selectors/clients'
import { groupedCategories as categoriesSelector } from '../../../selectors/categories'
import { filter } from '../../../reducers/clients';
import { renderField, renderSelect } from '../fields/V0/Fields';
import { renderField as renderNewField } from '../fields/V2';
import { useDecimalsVersionsGroup } from '../../../hooks/useDecimalsVersionsGroup';
import { decimalSeparator } from '../../../selectors/company';

const Payments = ({ values, form, onChangeType }) => {
  const dispatch = useDispatch()
  const can = useSelector(hasPermissionTo)
  const clients = useSelector(activeClients);
  const categories = useSelector(categoriesSelector)
  const type = get(values, 'type', 'out')
  const { isDecimalActive } = useDecimalsVersionsGroup();
  const separator = useSelector(decimalSeparator);

  const ref = useRef()
  const [loading, setLoading] = useState(false)
  
  useEffect(() => {
    ref.current = true
    return () => ref.current = false
  }, [])

  const search = useCallback(async (text = '') => {
    if (ref.current)
      setLoading(true)
    
    await dispatch(filter({ text, limit: 35 }))
    
    if (ref.current)
      setLoading(false)
  }, [dispatch])

  return (
    <div className="form-body h-100 p-4 overflow-hidden">
      <div className="form-body__fields form-row justify-content-start">
        <Field
          name="type"
        >
          {props => (
            <div className="btn-group col-12 mb-3" role="group">
              <button
                type="button"
                className={`btn text-uppercase btn-generic
                  ${type === 'out' ? 'text-white bg-danger border-danger': 'text-danger bg-white border-danger'}`}
                onClick={() => {
                  props.input.onChange('out')
                  onChangeType('out')
                }}
              >
                {I18n.get('outPayment', 'retiro')}
              </button>
              <button
                type="button"
                className={`btn text-uppercase btn-generic
                  ${type === 'in' ? 'text-white bg-primary border-primary': 'text-primary bg-white border-primary'}`}
                onClick={() => {
                  props.input.onChange('in')
                  onChangeType('in')
                }}
              >
                {I18n.get('inPayment', 'ingreso')}
              </button>
            </div>
          )}
        </Field>
        
        <div className="col-12 mb-2">
        <Field
          name="amount"
          component={renderNewField}
          fieldType="decimal"
          decimals={isDecimalActive ? 6 : null}
          separator={separator}
          onDecimalsError={(status) => form?.change('decimalsError', {
            ...values?.decimalsError,
            amount: status,
          })}
          label={capitalize(I18n.get('cashAmount', 'cantidad de efectivo'))}
          required
        />
        </div>
        
        <Field
          name="accountingAccount"
          className="col-12"
          component={renderSelect}
          options={categories}
          label={capitalize(I18n.get('accountingAccount', 'cuenta contable'))}
          required
          getOptionValue={option => option.id}
          getOptionLabel={option => `${!!option.depth ? repeat('-', option.depth - 1): ''} ${option.name}`}
          menuPosition="absolute"
        />
        
        <Field
          name="client"
          className="col-12"
          isLoading={loading}
          component={renderSelect}
          options={can('view', 'contacts') ? clients: []}
          noOptionsMessage={() => can('view', 'contacts')
            ? I18n.get('noResultsWereFound', 'No se encontraron resultados.')
            : I18n.get('userNotAllowed.contacts.view', 'No tienes permiso para ver el detalle de contacto. Habla con tu administrador para que te habilite el permiso y así puedas usar el POS correctamente.')
          }
          placeholder={I18n.get('selectAClient', 'selecciona un cliente')}
          getOptionLabel={option => 
            `${option.name}${!!option.identification ? ` (${option.identification})`: ''}`
          }
          getOptionValue={option => option.id}
          label={capitalize(I18n.get('client', 'cliente'))}
          help={I18n.get('paymentsClientHelp', 'elige si la operación está ligada a un cliente')}
          onInputChange={useMemo(() => 
            debounce((value) => { 
              search(value) 
            }, 350), [search]
          )}
          cacheOptions={false}
          menuPosition="absolute"
        />

        <Field
          name="observations"
          className="col-12"
          component={renderField}
          type="textarea"
          label={capitalize(I18n.get('observations', 'observaciones'))}
        />
      </div>
    </div>
  )
}

export default Payments;
import React from 'react';
import PropsTypes from 'prop-types';
import { Form } from 'react-final-form';
import { get } from 'lodash';

import { inventoryAdjustmentFilterTransformer } from '../../forms/filters/utils';
import { FilterInventoryAdjustments as Body } from '../../forms/filters';


const Filters = ({ setFilters, resetFilters, filters, setOpenFilters, openDetail }) => {

  const submit = (values) => {
    const parsedValues = inventoryAdjustmentFilterTransformer(values);
    setFilters(parsedValues);
    setOpenFilters(true);
  }

  return (
    <Form
      onSubmit={submit}
      initialValues={{
        number: get(filters, "number"),
        date: get(filters, "date")
      }}
    >
      {({ handleSubmit, form }) => (
        <form noValidate onSubmit={handleSubmit}>
          <Body resetFilters={resetFilters} form={form} setOpenFilters={setOpenFilters} openDetail={openDetail} />
        </form>
      )}
    </Form>
  )
}

export default Filters;

Filters.propTypes = {
  setFilters: PropsTypes.func,
  resetFilters: PropsTypes.func
}
import React, { useEffect, useState, useRef, useCallback, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { fetchInvoices } from '../../reducers/invoices'
import { setPrint } from '../../reducers/print'
import { hasPermissionTo } from '../../selectors/auth'
import { invoices, loadingSelector, errorSelector, offlineTotalSelector, metadataSelector } from '../../selectors/invoices'
import MasterDetail from '../common/DetailView';
import Invoice from './Invoice';
import EditInvoice from './EditInvoice';
import DetailHeader from './DetailHeader';
import DetailBody from './DetailBody';
import EmptyDetail from './EmptyDetail';
import ErrorElement from './ErrorElement';
import NoSelectedElement from './NoSelectedElement';
import NotAllowedToView from './NotAllowedToView'
import NotAllowedToIndex from './NotAllowedToIndex'
import PageNetworkMessage from '../network/PageNetworkMessage';
import { useConnectionStatus } from '../../hooks/useConnectionStatus';

const Invoices = () => {
  const connectionStatus = useConnectionStatus();
  const dispatch = useDispatch();
  const can = useSelector(hasPermissionTo);
  const ref = useRef(null);
  const [selected, setSelected] = useState(-1);
  const [metadata, setMetadata] = useState({
    start: 0,
    limit: 20,
    sortDirection: 'desc'
  });
  const [editView, setEditView] = useState(false);

  const elements = useSelector(invoices);
  const loading = useSelector(loadingSelector);
  const error = useSelector(errorSelector);
  const offlineTotal = useSelector(offlineTotalSelector);
  const requestMetadata = useSelector(metadataSelector);

  const selectedInvoice = useMemo(() => (
    selected === -1 ? null : elements[selected]
  ), [selected, elements]);

  useEffect(() => {
    ref.current = true;
    window.dataLayer.push({
      'event': 'VirtualPageview',
      'virtualPageURL': '/invoices',
      'virtualPageTitle': 'Invoices'
    });

    return () => {
      ref.current = false;
    };
  }, []);

  const fetchData = useCallback(async (batch) => {
    if (can('view', 'invoices') && can('index', 'invoices')) {
      await dispatch(fetchInvoices(batch));
    }

    if (ref.current) {
      setMetadata(batch);
    }
  }, [dispatch, can]); // Check if 'can' is stable or consider removing it if unnecessary

  useEffect(() => {
    fetchData({
      start: 0,
      limit: 20,
      sortDirection: 'desc'
    });
  }, [fetchData]);

  useEffect(() => {
    if (selectedInvoice) {
      dispatch(setPrint({ type: 'invoice', value: selectedInvoice }));
    }
  }, [selectedInvoice, dispatch]);

  const onSelect = useCallback((index) => {
    if (index !== selected) {
      setSelected(index);
      if (editView) {
        setEditView(false);
      }
    }
  }, [selected, editView]);

  if (!connectionStatus) {
    return <PageNetworkMessage />;
  }

  return (
    <div className="invoices d-flex">
      <MasterDetail
        loading={loading}
        error={error}
        selected={!!selectedInvoice}
        options={elements.map((option, index) => (
          <Invoice
            key={option.id}
            invoice={option}
            idSelected={!!selectedInvoice ? selectedInvoice.id : null}
            onSelect={() => onSelect(index)}
          />
        ))}
        onFetchData={fetchData}
        paginationData={{ ...requestMetadata, ...metadata, offlineTotal }}
        detailHeader={
          <DetailHeader
            invoice={selectedInvoice}
            onListView={() => setSelected(-1)}
            onRefresh={() => fetchData(metadata)}
            onEdit={() => setEditView(true)}
          />
        }
        detailChildren={
          <DetailBody
            invoice={selectedInvoice}
          />
        }
        detailClassName={!!selectedInvoice ? 'item-selected' : ''}
        emptyElement={<EmptyDetail />}
        errorElement={<ErrorElement onRefresh={() => fetchData(metadata)} />}
        noSelectedElement={<NoSelectedElement />}
        notAllowedToIndex={!can('view', 'invoices') ? <NotAllowedToIndex /> : null}
        notAllowedToView={!can('view', 'invoices') || !can('index', 'invoices') ? <NotAllowedToView /> : null}
        editView={editView}
        editComponent={
          <EditInvoice
            invoice={selectedInvoice}
            onRefresh={() => {
              setSelected(-1);
              setEditView(false);
              fetchData(metadata);
            }}
            onCancel={() => setEditView(false)}
          />
        }
      />
    </div>
  );
};

export default Invoices;

import React, { useRef } from 'react';
import { Field } from 'react-final-form';
import { I18n } from '@aws-amplify/core';
import { capitalize } from 'lodash';

import { renderField, RenderLabel, renderSelect, renderCheckbox, renderRadioButtons, renderFileUpload } from '../fields/V0/Fields';
import { validate } from './utils';

const Print = ({ values, form, country, electronicInvoicing, isElectronicPOSDocumentAvailable }) => {
  const errors = validate(values);
  const customPhrase = values.customPhrase || '';

  const fileRef = useRef(null);
  const uploadFile = (data) => {
    fileRef.current.click();
  }

  const onFileChange = async (e) => {
    const img = URL.createObjectURL(e.target.files[0]);
    form.mutators.setValues('imageOrQr', img);
    const convertFile = await convertToBase64(e.target.files[0]);
    const data = {
      image: convertFile,
      name: e.target.files[0].name,
      type: e.target.files[0].type,
    }
    form.mutators.setValues('imageInfo', JSON.stringify(data));
  }

  const convertToBase64 = (file) => {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        resolve(reader.result);
      };
    });
  };

  const onDeleteField = (nameProperty) => {
    form.mutators.setValues(nameProperty, null);
    if (nameProperty === 'imageOrQr') {
      form.mutators.setValues('imageInfo', null);
    }
  }

  const isUnitOfMeasureMandatory = country === 'republicaDominicana'
    || (country === 'colombia' && (electronicInvoicing || isElectronicPOSDocumentAvailable));

  return (
    <div className="form-body h-100 overflow-hidden">
      <div className="form-body__fields form-row justify-content-start bg-white p-5">
        <Field
          name="template"
          className="col-12"
          component={renderSelect}
          options={[
            { value: 'classic', label: capitalize(I18n.get('classic', 'Clásico')) },
            { value: 'simple', label: capitalize(I18n.get('simple', 'Simple')) },
            { value: 'modern', label: capitalize(I18n.get('modern', 'Moderno')) },
          ]}
          label={capitalize(I18n.get('printTemplateField', 'plantilla de tirilla'))}
          help={capitalize(I18n.get('printTemplateHelp', 'El modelo que elijas será el que se verá reflejado en la impresión.'))}
        />

        <Field
          name="align"
          className="col-12"
          component={renderRadioButtons}
          position='left'
          type="text"
          options={[
            {
              value: 'left', label: (
                <svg
                  width="40"
                  height="40"
                  viewBox="0 0 40 40"
                  xmlns="http://www.w3.org/2000/svg"
                  style={{ background: '#F4F5FB' }}
                  className="shadow"
                >
                  <g>
                    <line x1="7" y1="10" x2="33" y2="10" stroke="#8592AD" strokeWidth="2" />
                    <line x1="7" y1="20" x2="25" y2="20" stroke="#8592AD" strokeWidth="2" />
                    <line x1="7" y1="30" x2="30" y2="30" stroke="#8592AD" strokeWidth="2" />
                  </g>
                </svg>
              )
            },
            {
              value: 'center', label: (
                <svg
                  width="40"
                  height="40"
                  viewBox="0 0 40 40"
                  xmlns="http://www.w3.org/2000/svg"
                  style={{ background: '#F4F5FB' }}
                  className="shadow"
                >
                  <g>
                    <line x1="7" y1="10" x2="33" y2="10" stroke="#8592AD" strokeWidth="2" />
                    <line x1="7" y1="20" x2="33" y2="20" stroke="#8592AD" strokeWidth="2" />
                    <line x1="7" y1="30" x2="33" y2="30" stroke="#8592AD" strokeWidth="2" />
                  </g>
                </svg>
              )
            },
            {
              value: 'right', label: (
                <svg
                  width="40"
                  height="40"
                  viewBox="0 0 40 40"
                  xmlns="http://www.w3.org/2000/svg"
                  style={{ background: '#F4F5FB' }}
                  className="shadow"
                >
                  <g>
                    <line x1="7" y1="10" x2="33" y2="10" stroke="#8592AD" strokeWidth="2" />
                    <line x1="15" y1="20" x2="33" y2="20" stroke="#8592AD" strokeWidth="2" />
                    <line x1="10" y1="30" x2="33" y2="30" stroke="#8592AD" strokeWidth="2" />
                  </g>
                </svg>
              )
            },
          ]}
          label={capitalize(I18n.get('printAlign', 'Alineación de texto'))}
          help={capitalize(I18n.get('printAlignHelp', 'Con esta opción tus clientes verán la descripción de los productos en la tirilla.'))}
        />

        <Field
          name="description"
          className="col-12"
          component={renderCheckbox}
          label={capitalize(I18n.get('printDescription', 'Mostrar descripción de los productos'))}
          help={capitalize(I18n.get('printDescriptionHelp', 'Con esta opción tus clientes verán la descripción de los productos en la tirilla.'))}
          switchLabel={values.description
            ? capitalize(I18n.get('hideDescription', 'ocultar descripción'))
            : capitalize(I18n.get('showDescription', 'mostrar descripción'))
          }
        />

        <Field
          name="unitPrice"
          className="col-12"
          component={renderCheckbox}
          label={
            <>
              {capitalize(I18n.get('printUnitPrice', 'Mostrar precio unitario de los productos'))}
              {country === 'republicaDominicana' && (
                <span className="text-primary"> *</span>
              )}
            </>
          }
          help={capitalize(I18n.get('printUnitPriceHelp', 'Con esta opción tus clientes verán el precio unitario de los productos en la tirilla.'))}
          switchLabel={values.unitPrice
            ? capitalize(I18n.get('hideUnitPrice', 'ocultar precios'))
            : capitalize(I18n.get('showUnitPrice', 'mostrar precios'))
          }
        />

        <Field
          name="logo"
          className="col-12"
          component={renderCheckbox}
          label={capitalize(I18n.get('printLogo', 'Mostrar logo de la empresa'))}
          switchLabel={values.logo
            ? capitalize(I18n.get('hideLogo', 'ocultar logo'))
            : capitalize(I18n.get('showLogo', 'mostrar logo'))
          }
        />

        <Field
          name="printItemFullLine"
          className="col-12"
          component={renderCheckbox}
          label={capitalize(I18n.get('printItemFullLine', 'Agrupar en una columna los datos del producto'))}
          help={capitalize(I18n.get('printItemFullLineHelp', 'Activa para mostrar el nombre, cantidad y precio en una sola columna.'))}
          switchLabel={!values.printItemFullLine
            ? capitalize(I18n.get('groupData', 'agrupar datos'))
            : capitalize(I18n.get('ungroupData', 'desagrupar datos'))
          }
        />

        <Field
          name="unitOfMeasure"
          className="col-12"
          component={renderCheckbox}
          help={capitalize(I18n.get('printMeasurementHelp', 'Has visible la unidad de medida de tus productos y servicios.'))}
          label={<>
            {capitalize(I18n.get('printMeasurement', 'Incluye la unidad de medida en tus tickets'))}
            {isUnitOfMeasureMandatory && (
              <span className="text-primary"> *</span>
            )}
          </>
          }
          switchLabel={!values.unitOfMeasure
            ? capitalize(I18n.get('makeVisible', 'Hacer visible'))
            : capitalize(I18n.get('stopMakeVisible', 'Dejar de hacer visible'))
          }
        />

        <Field
          name="totalLines"
          className="col-12"
          component={renderCheckbox}
          label={capitalize(I18n.get('printLines', 'Mostrar total de líneas y productos en tus facturas'))}
          help={capitalize(I18n.get('printLinesHelp', 'Incluye el número de líneas de tu factura y el total de productos vendidos.'))}
          switchLabel={!values.totalLines
            ? capitalize(I18n.get('showInfo', 'mostrar información'))
            : capitalize(I18n.get('stopShowInfo', 'dejar de mostrar información'))
          }
        />
        {country === 'republicaDominicana' && (
          <Field
            name="taxesIncludedInTotal"
            className="col-12"
            component={renderCheckbox}
            label={capitalize(I18n.get('', 'Impuestos incluidos'))}
            help={capitalize(I18n.get('', 'Esta opción mostrará los precios de tus productos con impuestos'))}
            switchLabel={!values.taxesIncludedInTotal
              ? capitalize(I18n.get('activate', 'activar'))
              : capitalize(I18n.get('deactivate', 'desactivar'))
            }
          />
        )}

        <RenderLabel
          className="col-12"
          label={capitalize(I18n.get('printFormat', 'Formato de impresión de tirilla'))}
          help={capitalize(I18n.get('printFormatHelp', 'Con estas opciones podrás configurar el tamaño y los márgenes de tu tirilla.'))}
        />

        <Field
          name="format"
          className="col-md-4"
          component={renderSelect}
          options={[
            { value: '57', label: '57mm' },
            { value: '80', label: '80mm' },
          ]}
          label={capitalize(I18n.get('printSize', 'Tamaño de tirilla'))}
        />

        <Field
          name="leftMargin"
          className="col-md-4"
          component={renderField}
          type="number"
          label={capitalize(I18n.get('printLeftMargin', 'márgen izquierdo'))}
        />

        <Field
          name="rightMargin"
          className="col-md-4"
          component={renderField}
          type="number"
          label={capitalize(I18n.get('printRightMargin', 'márgen derecho'))}
        />

        {isUnitOfMeasureMandatory && (
          <p className="m-0 mt-2" style={{ fontSize: '12px', color: '#707070' }}>
            <span className="text-primary">*</span> {I18n.get('alwaysVisibleInFE', 'Siempre será visible en tus facturas electrónicas')}
          </p>
        )}
      </div>

      <div style={{ backgroundColor: '#F4F5FB', height: '15px' }}></div>

      <div className="form-body__fields form-row justify-content-start bg-white p-5">
        <RenderLabel
          className="col-12"
          label={I18n.get('printPersonalizedInvoices', 'Personalización de facturas')}
          help={capitalize(I18n.get('printPersonalizedInvoicesHelp', 'Dale tu toque a la parte final de tus facturas con una frase, imagen o ambas.'))}
        />

        <Field
          name="customPhrase"
          className="col-12"
          component={renderField}
          type="text"
          label={I18n.get('printCustomPhrase', 'Frase Personalizada:')}
          placeholder={I18n.get('printCustomPhrasePlaceholder', 'Procura que quede en máximo 200 caracteres')}
          errorRight={true}
          iconDelete={true}
          onDeleteField={() => onDeleteField("customPhrase")}
        />

        {
          !errors.customPhrase && (
            <div className="w-100 pr-2" style={{ marginTop: '-10px' }}>
              <p className={`h5 text-capitalize-first py-2 text-right ${errors.customPhrase && 'text-danger'}`}>{`${customPhrase.length} ${I18n.get("of", "de")} 200 ${I18n.get("characters", "caracteres")}`}</p>
            </div>
          )
        }

        <Field
          name="imageOrQr"
          className="col-12"
          component={renderFileUpload}
          type="text"
          label={I18n.get('printImageOrQr', 'Imagen o QR:')}
          fileRef={fileRef}
          uploadFile={uploadFile}
          onFileChange={onFileChange}
          accept="image/*"
          disabled
          onDeleteField={() => onDeleteField("imageOrQr")}
        />
      </div>
    </div>
  )
}

export default Print;
import React, { useRef } from 'react';
import { Switch, Route, useLocation } from 'react-router-dom';

import Menu from './SettingsMenu';
import Company from './Company/Company';
import NewCompany from './NewCompany/NewCompany';
import General from './General/General';
import Station from './Station/Station';
import Stations from './Stations/Stations';
import Sellers from './Sellers/Sellers';
import Print from './Print/Print';
import Invoices from './Invoices/Invoices';
import preferences from './SellPreferences/SellPreferences';
import { useSelector } from 'react-redux';
import { country as countrySelector } from '../../selectors/company';
import PageNetworkMessage from '../network/PageNetworkMessage';
import { useConnectionStatus } from '../../hooks/useConnectionStatus';
import PaymentMethods from './PaymentMethods/PaymentMethods';
import { showNewInvoiceModal } from '../modals/newInvoice/utils';

const Settings = () => {
  const connectionStatus = useConnectionStatus();
  const location = useLocation();
  const country = useSelector(countrySelector);
  const containerRef = useRef();

  React.useEffect(() => {
    containerRef?.current?.scrollTo({ top: 0 });
  }, [location]);

  if(!connectionStatus) {
    return <PageNetworkMessage />
  }

  return (
    <div ref={containerRef} className="h-100 settings">
      <Switch>
        <Route path="/settings/company" component={(country === 'colombia' || country === 'spain') ? NewCompany : Company} />
        {(country === 'colombia' || country === 'spain') && (
          <Route path="/settings/general" component={General} />
        )}
        <Route path="/settings/station" component={Station} />
        <Route path="/settings/stations" component={Stations} />
        <Route path="/settings/invoices" component={Invoices} />
        <Route path="/settings/sellers" component={Sellers} />
        {
          showNewInvoiceModal(country) && (
            <Route path="/settings/payment-methods" component={PaymentMethods} />
          )
        }
        <Route path="/settings/print" component={Print} />
        <Route path="/settings/sellPreferences" component={preferences} />
        <Route path="/" component={Menu} />
      </Switch>
    </div>
  )
}

export default Settings;
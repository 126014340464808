import React from 'react';
import PropTypes from 'prop-types';
import { I18n } from '@aws-amplify/core';

/**
 * Component to display client information.
 *
 * @param {Object} props - Component props.
 * @param {string} props.clientName - Name of the client.
 * @returns {JSX.Element} - Rendered component.
 */
const ClientInfo = React.memo(({ clientName }) => (
  <tr>
    <td className='caption-bold'>
      {I18n.get('client', 'cliente')}
      <span className='text-primary ml-1'>*</span>
    </td>
    <td>{clientName}</td>
  </tr>
));

ClientInfo.propTypes = {
  clientName: PropTypes.string.isRequired,
};

export default ClientInfo;

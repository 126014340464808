const ivaConditions = [
    {
       "id":"1",
       "value":"IVA responsable inscripto",
       "key":"IVA_RESPONSABLE"
    },
    {
       "id":"2",
       "value":"IVA responsable no inscripto",
       "key":"IVA_RESPONSABLE_NO_INSCRIPT"
    },
    {
       "id":"3",
       "value":"IVA sujeto exento",
       "key":"IVA_EXEMPT"
    },
    {
       "id":"5",
       "value":"Monotributo",
       "key":"UNIQUE_TRIBUTE_RESPONSABLE"
    },
 ];
 
 export default ivaConditions;
import React from "react";
import { I18n } from "aws-amplify";

const Bottom = ({ onClose, onConfirm, submitText }) => {
  return (
    <div
      className="modal__bottom w-100 px-5 py-4 d-flex flex-column flex-sm-row justify-content-end 
      align-items-sm-center align-items-start">
      <div className="modal__bottom-buttons d-flex">
        <button
          type="button"
          className="btn btn-cancel text-btn-normal text-capitalize-first mr-4"
          onClick={() => onClose()}
        >
          {I18n.get("cancel", "Cancelar")}
        </button>
        <button
          type="button"
          className="btn btn-danger text-btn-normal text-capitalize-first"
          onClick={() => onConfirm()}
        >
          {submitText}
        </button>
      </div>
    </div>
  )
}

export default Bottom;
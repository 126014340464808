import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import { setSettings } from '../../../../reducers/activeRefund'
import CurrencyModal from '../../../modals/currency/Currency'
import Header from './Header';
import Client from './Client';
import SearchItem from './SearchItem';
import RefundItems from './RefundItems';
import Summary from './Summary';

const ActiveRefund = ({ openSettings, openEditItem }) => {
  const dispatch = useDispatch()

  return (
    <>
      <div className="active-refund w-sm-100 d-flex flex-column border">
        <div className="refund-top d-flex flex-column">
          <Header openSettings={openSettings} />

          <Client />

          <SearchItem />
        </div>

        <div className="refund-content">

          <RefundItems openEditItem={openEditItem} />
        </div>

        <div className="refund-bottom">
          <Summary />
        </div>
      </div>

      <CurrencyModal onCreated={(currency) => dispatch(setSettings({ currency }))} />
    </>
  )
}

ActiveRefund.propTypes = {
  openSettings: PropTypes.func,
  openEditItem: PropTypes.func,
}

export default ActiveRefund;

function AlegraPayroll({ className, id }) {
  return (
    <svg
      id={id}
      className={className}
      width='159'
      height='56'
      viewBox='0 0 159 56'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M22.3999 19.303C22.3999 16.1165 24.9071 13.5333 27.9999 13.5333C31.0927 13.5333 33.5999 16.1165 33.5999 19.303C33.5999 22.4895 31.0927 25.0727 27.9999 25.0727C24.9071 25.0727 22.3999 22.4895 22.3999 19.303Z'
        fill='#00D6BC'
      />
      <path
        d='M18.6666 36.6121C18.6666 31.3013 22.8452 26.996 27.9999 26.996C33.1545 26.996 37.3332 31.3013 37.3332 36.6121V40.7446C37.3332 42.3145 36.5649 43.7768 35.1147 44.2587C33.598 44.7628 31.2653 45.2667 27.9999 45.2667C24.7345 45.2667 22.4017 44.7628 20.885 44.2587C19.4349 43.7768 18.6666 42.3145 18.6666 40.7446V36.6121Z'
        fill='#00D6BC'
      />
      <path
        d='M10.7332 19.5121C10.7332 16.7412 12.9438 14.4949 15.6332 14.4949C18.3226 14.4949 20.5332 16.7412 20.5332 19.5121C20.5332 22.283 18.3226 24.5292 15.6332 24.5292C12.9438 24.5292 10.7332 22.283 10.7332 19.5121Z'
        fill='#00D6BC'
      />
      <path
        d='M7.46655 34.4485C7.46655 29.8015 11.1229 26.0343 15.6332 26.0343C17.2057 26.0343 18.6743 26.4922 19.9204 27.2856C17.4327 29.5749 15.8666 32.9051 15.8666 36.6121V40.7446C15.8666 41.1226 15.8962 41.5103 15.957 41.8991C15.8503 41.9004 15.7423 41.901 15.6332 41.901C12.8823 41.901 10.8878 41.4882 9.55876 41.06C8.18736 40.618 7.46655 39.2381 7.46655 37.7577V34.4485Z'
        fill='#00D6BC'
      />
      <path
        d='M40.1332 40.7446C40.1332 41.1226 40.1036 41.5103 40.0427 41.8991C40.1495 41.9004 40.2574 41.901 40.3666 41.901C43.1175 41.901 45.112 41.4882 46.441 41.06C47.8124 40.618 48.5332 39.2381 48.5332 37.7577V34.4485C48.5332 29.8015 44.8769 26.0343 40.3666 26.0343C38.7941 26.0343 37.3255 26.4922 36.0794 27.2856C38.5671 29.5749 40.1332 32.9051 40.1332 36.6121V40.7446Z'
        fill='#00D6BC'
      />
      <path
        d='M35.4667 19.5121C35.4667 16.7412 37.6773 14.4949 40.3667 14.4949C43.056 14.4949 45.2666 16.7412 45.2666 19.5121C45.2666 22.283 43.056 24.5292 40.3667 24.5292C37.6773 24.5292 35.4667 22.283 35.4667 19.5121Z'
        fill='#00D6BC'
      />
      <path
        d='M63.6413 21.4372C61.3855 21.4372 59.7334 19.6176 59.7334 17.3902C59.7334 15.1941 61.5126 13.3745 63.8795 13.3745C66.2306 13.3745 68.0257 15.1 68.0257 17.3902V21.249H66.5324V19.9C65.9447 20.8412 64.9121 21.4372 63.6413 21.4372ZM63.8795 19.9627C65.341 19.9627 66.453 18.802 66.453 17.4059C66.453 15.9941 65.341 14.8333 63.8795 14.8333C62.434 14.8333 61.3061 15.9941 61.3061 17.4059C61.3061 18.802 62.434 19.9627 63.8795 19.9627Z'
        fill='#64748B'
      />
      <path
        d='M70.1196 10.0333H71.6923V21.249H70.1196V10.0333Z'
        fill='#64748B'
      />
      <path
        d='M77.4154 21.4216C75.0485 21.4216 73.2852 19.7274 73.2852 17.3588C73.2852 15.1471 74.8896 13.3431 77.2248 13.3431C79.5759 13.3431 81.0374 15.0216 81.0374 17.1706V17.798H74.8102C74.9849 19.1157 76.0016 20.0412 77.3996 20.0412C78.3527 20.0412 79.2105 19.6333 79.6712 18.7863L80.8785 19.398C80.1954 20.7157 78.9405 21.4216 77.4154 21.4216ZM74.8737 16.7941H79.4647C79.4011 15.5706 78.4957 14.7392 77.2089 14.7392C75.8904 14.7392 75.0326 15.649 74.8737 16.7941Z'
        fill='#64748B'
      />
      <path
        d='M85.9491 24.9667C84.2652 24.9667 82.8673 24.2451 82.1525 22.8804L83.471 22.1745C83.9317 23.0059 84.7895 23.5392 85.9174 23.5392C87.5536 23.5392 88.6656 22.3784 88.6656 20.5745V19.9627C88.1572 20.7627 87.2041 21.3588 85.9015 21.3588C83.6934 21.3588 81.9459 19.6333 81.9459 17.3745C81.9459 15.1784 83.7251 13.3745 86.0921 13.3745C88.4432 13.3745 90.2382 15.1 90.2382 17.3902V20.5588C90.2382 23.2255 88.4273 24.9667 85.9491 24.9667ZM86.0762 19.9C87.5059 19.9 88.6497 18.7706 88.6497 17.3588C88.6497 15.9471 87.5059 14.849 86.0762 14.849C84.6624 14.849 83.5027 15.9471 83.5027 17.3588C83.5027 18.7706 84.6624 19.9 86.0762 19.9Z'
        fill='#64748B'
      />
      <path
        d='M93.5871 21.249H92.0144V16.6686C92.0144 14.802 93.0947 13.4059 95.128 13.4059C95.6205 13.4059 96.1288 13.5 96.526 13.6882V15.2255C96.1447 15.0059 95.6999 14.8961 95.271 14.8961C94.2225 14.8961 93.5871 15.602 93.5871 16.7314V21.249Z'
        fill='#64748B'
      />
      <path
        d='M100.616 21.4372C98.3599 21.4372 96.7078 19.6176 96.7078 17.3902C96.7078 15.1941 98.487 13.3745 100.854 13.3745C103.205 13.3745 105 15.1 105 17.3902V21.249H103.507V19.9C102.919 20.8412 101.886 21.4372 100.616 21.4372ZM100.854 19.9627C102.315 19.9627 103.427 18.802 103.427 17.4059C103.427 15.9941 102.315 14.8333 100.854 14.8333C99.4083 14.8333 98.2805 15.9941 98.2805 17.4059C98.2805 18.802 99.4083 19.9627 100.854 19.9627Z'
        fill='#64748B'
      />
      <path
        d='M62.2694 45.6556H59.7334V30.463C59.7334 28.8815 60.7792 27.9741 62.1125 27.9741C63.1844 27.9741 63.9164 28.3889 64.4132 29.2963L71.7858 42.9593C71.8642 43.1926 71.9165 43.2963 72.0733 43.2963C72.1779 43.2963 72.2041 43.2445 72.2041 43.0889V28.2852H74.74V43.5037C74.74 45.0852 73.6158 45.9667 72.4132 45.9667C71.4197 45.9667 70.557 45.5519 70.0603 44.6186L62.7138 30.9297C62.6615 30.7223 62.6092 30.6186 62.4785 30.6186C62.3217 30.6186 62.2694 30.7223 62.2694 30.9297V45.6556Z'
        fill='#334155'
      />
      <path
        d='M85.4529 30.7741H82.8124L85.8974 26.8334H88.5902L85.4529 30.7741ZM84.2765 45.9667C80.4071 45.9667 77.4267 42.9593 77.4267 39.2778C77.4267 35.6223 80.4333 32.6408 84.2765 32.6408C88.1458 32.6408 91.0739 35.6482 91.0739 39.2778C91.0739 42.9593 88.1196 45.9667 84.2765 45.9667ZM84.2765 43.5297C86.6556 43.5297 88.4856 41.6112 88.4856 39.3037C88.4856 36.9704 86.6556 35.0519 84.2765 35.0519C81.8712 35.0519 80.015 36.9704 80.015 39.3037C80.015 41.6112 81.8712 43.5297 84.2765 43.5297Z'
        fill='#334155'
      />
      <path
        d='M109.076 32.5889C112.945 32.5889 115.036 35.4408 115.036 38.9667V45.6556H112.448V39.3815C112.448 37.0741 111.298 35.0519 108.893 35.0519C106.566 35.0519 105.389 36.9963 105.389 39.3297V45.6556H102.801V39.3297C102.801 36.9963 101.625 35.0519 99.2455 35.0519C96.8403 35.0519 95.7684 37.0741 95.7684 39.3815V45.6556H93.1801V38.9926C93.1801 35.4408 95.2193 32.5889 99.0625 32.5889C101.625 32.5889 103.481 33.8852 104.134 35.8556C104.84 33.8852 106.644 32.5889 109.076 32.5889Z'
        fill='#334155'
      />
      <path
        d='M119.756 30.4371C118.815 30.4371 118.057 29.6852 118.057 28.7519C118.057 27.8186 118.815 27.0408 119.756 27.0408C120.698 27.0408 121.43 27.8186 121.43 28.7519C121.43 29.6852 120.698 30.4371 119.756 30.4371ZM118.475 32.9519H121.064V45.6556H118.475V32.9519Z'
        fill='#334155'
      />
      <path
        d='M130.849 35.1037C128.339 35.1037 127.084 37.1519 127.084 39.5112V45.6556H124.496V39.2519C124.496 35.5445 126.718 32.6408 130.849 32.6408C135.032 32.6408 137.306 35.5445 137.306 39.226V45.6556H134.718V39.5371C134.718 37.1519 133.411 35.1037 130.849 35.1037Z'
        fill='#334155'
      />
      <path
        d='M145.851 45.9667C142.139 45.9667 139.42 42.9593 139.42 39.2778C139.42 35.6482 142.348 32.6408 146.243 32.6408C150.112 32.6408 153.067 35.4926 153.067 39.2778V45.6556H150.609V43.426C149.642 44.9815 147.943 45.9667 145.851 45.9667ZM146.243 43.5297C148.648 43.5297 150.478 41.6112 150.478 39.3037C150.478 36.9704 148.648 35.0519 146.243 35.0519C143.864 35.0519 142.008 36.9704 142.008 39.3037C142.008 41.6112 143.864 43.5297 146.243 43.5297Z'
        fill='#334155'
      />
    </svg>
  );
}

export default AlegraPayroll;

import { I18n } from 'aws-amplify';
import { get } from 'lodash';

export const hasItemsWithIVA = (items) => {
  if (!items) return false;

  return !!items.filter((item) => get(item, 'hasNoIvaDays')).length;
};

export const VALIDATION_ERROR_MESSAGES = {
  CONSECUTIVE_NUMERATION: I18n.get(
    'numerationMaxNumberReached',
    'Numeración sin consecutivos'
  ),
  CONTACTS_NOT_ALLOWED: I18n.get(
    'userNotAllowed.contacts.view',
    'No tienes permiso para ver el detalle de contacto. Habla con tu administrador para que te habilite el permiso y así puedas usar el POS correctamente.'
  ),
  CLIENT_NOT_FOUND: I18n.get(
    'invoiceWithoutClientError',
    'No se pudo asociar el contacto genérico, intenta facturar de nuevo por favor'
  ),
  IVA: I18n.get(
    'defaultClientIVAError',
    'Recuerda que para emitir facturas electrónicas con productos sin IVA, debes crear tu cliente con su identificación'
  ),
  INVOICE_DATA_ERROR: I18n.get('fvArgError', 'Revisá los datos de tu factura'),
  INVALID_RUC: I18n.get(
    'toIssueTheSalesInvoiceYourClientMustHaveAValidRUCIdentification',
    'Para emitir la factura de venta tu cliente debe tener una identificación RUC válida'
  ),
  ACTUAL_REGIME_ERROR_EMITTED: I18n.get(
    'itIsNotPossibleToIssueElectronicSalesInvoicesWithYourCurrentRegime',
    'No es posible emitir facturas electrónicas de venta con tu régimen actual'
  ),
  ELECTRONIC_NUMERATION_MISSING: I18n.get(
    'electronigInvoiceNotAvailable',
    'Para emitir facturas en POS debes tener una numeración no electrónica'
  ),
};

export const clientDataAreEquals = (stringA = '', stringB = '') => {
  return stringA.toLowerCase().includes(stringB.toLowerCase());
};

export const TIPS_TYPE = {
  PERCENTAGE: 'PERCENTAGE',
};

export const SALE_CONCEPT = {
  PRODUCTS_SERVICES: 'PRODUCTS_SERVICES',
  SERVICES: 'SERVICES',
  PRODUCTS: 'PRODUCTS',
};

export const ARG_IVA_CONDITION = {
  IVA_RESPONSABLE: 'IVA_RESPONSABLE',
  IVA_EXEMPT: 'IVA_EXEMPT',
  UNIQUE_TRIBUTE_RESPONSABLE: 'UNIQUE_TRIBUTE_RESPONSABLE',
  FINAL_CONSUMER: 'FINAL_CONSUMER',
};

export const ARG_DOCUMENT_TYPE = {
  INVOICE_A: 'INVOICE_A',
  INVOICE_B: 'INVOICE_B',
  INVOICE_C: 'INVOICE_C',
};

export const REGIME = {
  NUEVO_RUS: 'Nuevo RUS',
};

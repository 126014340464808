import React from 'react';
import { I18n } from '@aws-amplify/core';
import { Field } from 'react-final-form';
import { capitalize } from 'lodash';
import { useSelector } from 'react-redux'

import { hasPermissionTo } from '../../../selectors/auth'
import { isOnlyInvoicingPlan } from '../../../selectors/company'
import { activeCostCenters } from '../../../selectors/costCenters'
import { activeSellers } from '../../../selectors/sellers'
import { renderField, renderCheckbox, renderSelect } from '../fields/V0/Fields';

const AdditionalSettings = ({ values }) => {
    const can = useSelector(hasPermissionTo)
    const costCenters = useSelector(activeCostCenters)
    const sellers = useSelector(activeSellers)
    const onlyInvoicing = useSelector(isOnlyInvoicingPlan)

    return (
        <div className="form-body__fields form-row justify-content-start">
            <Field
                name="costCenter"
                className="col-md-6"
                component={renderSelect}
                disabled={onlyInvoicing}
                onlyInvoicingTooltip={onlyInvoicing}
                label={capitalize(I18n.get('costCenter', 'centro de costo'))}
                helpTooltip={
                    <p className="text-capitalize-first text-white">
                        {I18n.get('costCenterSettingTutorial', 'Selecciona centro de costos para distribuir los ingresos y gastos según las áreas, proyectos o cualquier división de tu negocio.')}{' '}
                        <a className="btn-link" href="https://ayuda.alegra.com/como-crear-centro-de-costos-en-tu-cuenta-de-alegra" target="_blank" rel="noreferrer">
                            {capitalize(I18n.get('seeMore', 'ver mas'))}
                        </a>
                    </p>
                }
                options={!can('index', 'cost-centers') ? [] : costCenters}
                selectInfo={!can('index', 'cost-centers')
                ? I18n.get('userNotAllowed.cost-centers.view', 'no tienes permisos suficientes para ver detalles de centros de costo')
                : null}
                hideNoOptionMessage={!can('index', 'cost-centers')}
                getOptionLabel={option => option.name}
                getOptionValue={option => option.id}
                menuPosition="absolute"
                showClearIndicator
            />

            <Field
                name="seller"
                className="col-md-6"
                component={renderSelect}
                label={capitalize(I18n.get('seller', 'vendedor'))}
                options={sellers}
                getOptionLabel={option => option.name}
                getOptionValue={option => option.id}
                menuPosition="absolute"
                showClearIndicator
            />

            <Field
                name="pendingInvoicesEnabled"
                className="col-md-6"
                component={renderCheckbox}
                disabled={onlyInvoicing}
                onlyInvoicingTooltip={onlyInvoicing}
                label={I18n.get('pendingSales', 'ventas pendientes')}
                helpTooltip={
                    <p className="text-capitalize-first text-white">
                        {I18n.get('pendingInvoiceSettingsTutorial', 'con esta opción podrás procesar más de una venta a la vez.')}{' '}
                        <a className="text-capitalize-first btn-link" href="https://ayuda.alegra.com/es/maneja-ventas-pendientes-y-genera-mas-ventas-al-mismo-tiempo-desde-el-pos" target="_blank" rel="noreferrer">
                        {capitalize(I18n.get('seeMore', 'ver más'))}
                        </a>
                    </p>
                }
                switchLabel={!!values.pendingInvoicesEnabled
                ? capitalize(I18n.get('disablePendingInvoices', 'deshabilitar ventas pendientes'))
                : capitalize(I18n.get('enablePendingInvoices', 'habilitar ventas pendientes'))
                }
            />

            {!!values.pendingInvoicesEnabled && (
                <Field
                name="pendingInvoicesPrefix"
                className="col-md-6"
                type="text"
                component={renderField}
                label={capitalize(I18n.get('pendingInvoicesPrefix', 'prefijo de ventas pendientes'))}
                />
            )}
        </div>
    )
}

export default AdditionalSettings;

import { createDraftSafeSelector } from '@reduxjs/toolkit';
import { get } from 'lodash';

import { inventoryAdjustmentsNumerationsSelector } from '../reducers/inventoryAdjustmentsNumerations';

export const allInventoryAdjustmentsNumerations = createDraftSafeSelector(
  inventoryAdjustmentsNumerationsSelector.selectAll,
  inventoryAdjustmentsNumerations => !!inventoryAdjustmentsNumerations ? inventoryAdjustmentsNumerations : []
);

export const activeInventoryAdjustmentsNumerations = createDraftSafeSelector(
  inventoryAdjustmentsNumerationsSelector.selectAll,
  inventoryAdjustmentsNumerations => !!inventoryAdjustmentsNumerations
    ? inventoryAdjustmentsNumerations.filter(inventoryAdjustmentsNumerations => (get(inventoryAdjustmentsNumerations, 'status') === 'active' && !!get(inventoryAdjustmentsNumerations, 'autoIncrement')))
    : []
);
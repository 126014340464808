import { createDraftSafeSelector } from '@reduxjs/toolkit';
import { get } from 'lodash';

import { warehousesSelector } from '../reducers/warehouses'

export const activeWarehouses = createDraftSafeSelector(
  warehousesSelector.selectAll,
  warehouses => !!warehouses
    ? warehouses.filter(warehouse => get(warehouse, 'status') === 'active')
    : []
)

export const getWarehouseById = id => createDraftSafeSelector(
  state => warehousesSelector.selectById(state, id),
  warehouse => !!warehouse && get(warehouse, 'status') === 'active' ? warehouse : null
)

// eslint-disable-next-line
export default ({ id, className }) => (
  <svg 
    id={id}
    className={className}
    width="55" 
    height="54" 
    viewBox="0 0 55 54" 
    fill="none" 
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0)">
      <path d="M28.4512 26.0001H41.4512V29.2501H28.4512V26.0001ZM28.4512 19.5001H41.4512V22.7501H28.4512V19.5001ZM38.2012 3.25006H25.2012C23.4137 3.25006 21.9512 4.71256 21.9512 6.50006V32.5001C21.9512 34.2876 23.3974 35.7501 25.1849 35.7501H44.7012C46.4887 35.7501 47.9512 34.2876 47.9512 32.5001V13.0001L38.2012 3.25006ZM44.7012 32.5001H25.2012V6.50006H36.5762V14.6251H44.7012V32.5001Z" fill="#00B19D"/>
    </g>
    <rect x="15.4512" y="18" width="12" height="21" fill="white"/>
    <rect x="21.4473" y="36" width="6" height="21" transform="rotate(-90 21.4473 36)" fill="white"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M21.9476 20.8281C21.2922 20.7355 20.6224 20.6877 19.9414 20.6877C12.0886 20.6877 5.72266 27.0536 5.72266 34.9064C5.72266 42.7592 12.0886 49.1252 19.9414 49.1252C27.7942 49.1252 34.1602 42.7592 34.1602 34.9064C34.1602 33.7762 34.0283 32.6767 33.7791 31.6227L31.6703 33.8064C31.7038 34.1685 31.721 34.5355 31.721 34.9064C31.721 41.413 26.4463 46.6876 19.9397 46.6876C13.4331 46.6876 8.15848 41.413 8.15848 34.9064C8.15848 28.4706 13.3189 23.2401 19.7276 23.127L21.9476 20.8281ZM24.8228 32.4626L25.383 36.5708L27.7831 36.9661L26.68 28.8768L18.5908 27.7737L18.986 30.1739L23.103 30.7353L14.7947 39.0435C14.3188 39.5194 14.3188 40.2911 14.7947 40.7671C15.2707 41.243 16.0423 41.243 16.5183 40.7671L24.8228 32.4626Z" fill="#00B19D"/>
    <defs>
    <clipPath id="clip0">
    <rect width="39" height="39" fill="white" transform="translate(15.4512)"/>
    </clipPath>
    </defs>
  </svg>
)

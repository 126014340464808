import { useDispatch, useSelector } from "react-redux";
import { APIGraphqlSelector } from "../../selectors/app";
import { country as countrySelector, notificationsSelector } from "../../selectors/company";
import { useTicketBaiInformation } from "../../hooks/useTicketBaiElegible";
import { useCallback, useEffect } from "react";
import notificationsAPIV2 from '../../reducers/notificationsAPIV2'
import { updateUser } from "../../graphql/mutations";
import { notificationsTransformer } from "./utils";
import { graphqlOperation } from "aws-amplify";
import { setUser } from "../../reducers/auth";
import { userSelector } from "../../selectors/auth";
import dayjs from "dayjs";
import { get } from "lodash";

const TicketBAITopNotification = () => {
  const dispatch = useDispatch();
  const APIGraphql = useSelector(APIGraphqlSelector);
  const notifications = useSelector(notificationsSelector);
  const user = useSelector(userSelector);
  const country = useSelector(countrySelector);

  const { isAllRequiredDataFilled, isTicketBaiEligible, isTicketBaiEnabled } = useTicketBaiInformation();

  const selectedBody = useCallback(() => {
    if (!isTicketBaiEligible || isTicketBaiEnabled) {
      return null;
    };
    if (!isAllRequiredDataFilled) {
      return {
        userId: user.idGlobal.toString(),
        title: "Activa TicketBAI",
        text: '<b>Activa TicketBAI en tu negocio:</b> Completa tus datos y habilita el sistema BATUZ para empezar a facturar.',
        actionCall: "/settings/company",
        actionLabel: "Completar mis datos",
        type: "info_dark",
        to: dayjs().add(30, 'days').toISOString(),
        country,
      }
    }

    return {
      userId: user.idGlobal.toString(),
      title: "Activa TicketBAI",
      text: '<b>Activa TicketBAI en tu negocio:</b> Habilita el sistema BATUZ para empezar a facturar fácil y rápido.',
      actionCall: "https://mi.alegra.com/fe-information",
      actionLabel: "Activar Ticketbai",
      type: "info_dark",
      to: dayjs().add(30, 'days').toISOString(),
      country,
    }
  }, [isAllRequiredDataFilled, isTicketBaiEligible, user, country]);

  useEffect(() => {
    async function checkNotification() {
      if (!selectedBody()) {
        return;
      }

      const notificationName = 'ticketbai-top-notification';
      if (notifications.find(n => n[`${notificationName}`])) return;

      try {
        const response = await APIGraphql(graphqlOperation(updateUser, notificationsTransformer(notificationName)));

        if (!!get(response, 'data.updateUser.id', null)) {
          dispatch(setUser(get(response, 'data.updateUser')));
        }

        await notificationsAPIV2.post('/notifications',
          notificationBody({
            ...selectedBody(),
          }));


      } catch (err) {
        console.log("error while creating notification", err);
      }
    }

    checkNotification();
  }, [selectedBody, notifications, dispatch, APIGraphql])
};

const notificationBody = ({ userId, from = null, to = null, section = null, title, text, actionCall, actionLabel, type, actionType }) => ({
  name: "ticketbai-top-notification",
  teamInCharge: "pos",
  receptors: {
    users: [userId.toString()]
  },
  fromDate: from ? from : dayjs().toISOString(),
  toDate: to ? to : dayjs().add(30, 'days').toISOString(),
  messages: [
    {
      channel: "web",
      type: "top",
      closeable: true,
      section: section ? section : "index-pos-home",
      text,
      title,
      application: "alegra-pos",
      category: 'new-feature',
      action: {
        actionCall,
        actionLabel
      },
      style: {
        type,
      }
    }
  ]
})

export default TicketBAITopNotification;
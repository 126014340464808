import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form } from 'react-final-form';
import { I18n } from '@aws-amplify/core';
import { graphqlOperation } from '@aws-amplify/api';
import { get } from 'lodash';

import * as mutations from '../../../graphql/mutations';
import { toast } from '../../../utils';
import { formError, handleError } from '../../../utils/errors';
import { sellerTransformer } from '../../../utils/transformers';
import { closeModal } from '../../../reducers/modals';
import { addSeller, updateSeller } from '../../../reducers/sellers';
import { validate, transform } from '../../forms/seller/utils';
import Modal from '../../common/Modal';
import Notification from '../common/Notification';
import Bottom from '../common/Bottom';
import Body from '../../forms/seller/Seller';
import { replaceAndParse } from '../../../utils/index';
import { APIGraphqlSelector } from '../../../selectors/app';
import { sendGTMEvent, sendNewGTMEvent } from '../../../reducers/company';
import { endAction, startAction } from '../../../reducers/monitoring';
import { calculateActionTimeSelector } from '../../../selectors/monitoring';
import useSendGTMEvent from '../../../hooks/useSendGtmEvent/hook';

const Seller = ({ onRefresh, onCreated }) => {
  const dispatch = useDispatch();
  const isOpen = useSelector((state) =>
    get(state, 'modals.seller.isOpen', false)
  );
  useSendGTMEvent(isOpen, 'pos-seller-started');
  const seller = useSelector((state) =>
    get(state, 'modals.seller.params.seller', false)
  );
  const APIGraphql = useSelector(APIGraphqlSelector);
  const calculateActionTime = useSelector(calculateActionTimeSelector);

  const submit = async (values) => {
    dispatch(startAction({action: 'createSeller'}));
    const transformedValues = transform(values);

    const request = !!seller
      ? APIGraphql(
          graphqlOperation(mutations.updateSeller, {
            seller: sellerTransformer({ ...seller, ...transformedValues }),
          })
        )
      : APIGraphql(
          graphqlOperation(mutations.createSeller, {
            seller: sellerTransformer({ ...transformedValues }),
          })
        );

    try {
      const response = await request;

      dispatch(closeModal({ modal: 'seller' }));

      const newSeller = !seller
        ? get(response, 'data.createSeller', null)
        : null;

      if (!!newSeller) {
        dispatch(endAction({action: 'createSeller'}));
        dispatch(sendGTMEvent('create_seller'));
        dispatch(sendNewGTMEvent('pos-seller-created', {
          origin: 'sellers',
          responseTime: calculateActionTime("createSeller")
        }));
        dispatch(addSeller(newSeller));
        if (!!onCreated) onCreated(newSeller);
      } else {
        dispatch(
          updateSeller({
            id: seller.id,
            changes: transformedValues,
          })
        );
      }

      if (!!onRefresh) onRefresh();

      if (!!seller)
        toast.success({
          title: I18n.get('sellerUpdated', 'vendedor actualizado con éxito'),
        });
      else
        toast.success({
          title: `${I18n.get(
            'sellerCreatedSuccessfully',
            'vendedor creado con éxito'
          )} 💪`,
          subtitle: replaceAndParse(
            I18n.get(
              'sellerCreatedSuccessfullyMessage',
              'Ya puedes asociar en tus facturas a {} como vendedor.'
            ),
            [
              `<span class="font-weight-bold">${get(
                newSeller,
                'name',
                ''
              )}</span>`,
            ]
          ),
        });
    } catch (error) {
      const parseError = handleError(error);
      dispatch(sendNewGTMEvent('pos-seller-created', {
        error: parseError
      }));
      return formError(
        error,
        I18n.get(
          'updateSellerError',
          'hubo un error al cambiar los datos del vendedor'
        )
      );
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={() => dispatch(closeModal({ modal: 'seller' }))}
      title={
        <div className='d-flex justify-content-between align-items-center w-100'>
          <div className='modal__header-title text-capitalize-first h2 text-primary text-truncate'>
            {!!seller ? seller.name : I18n.get('newSeller', 'nuevo vendedor')}
          </div>
        </div>
      }
      className='modal__medium-size'
    >
      <Form
        onSubmit={submit}
        validate={(values) => validate(values)}
        initialValues={seller}
        keepDirtyOnReinitialize
      >
        {({ handleSubmit, submitting, values, submitError, pristine }) => (
          <form noValidate onSubmit={handleSubmit}>
            <div className='px-md-5 py-md-3'>
              <Body values={values} />
            </div>

            {!!submitError && !pristine && (
              <Notification isOpen={true} text={submitError} type='error' />
            )}

            <Bottom
              submitting={submitting}
              onClose={() => dispatch(closeModal({ modal: 'seller' }))}
            />
          </form>
        )}
      </Form>
    </Modal>
  );
};

export default Seller;

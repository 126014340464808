import { I18n } from '@aws-amplify/core';
import { get } from 'lodash'
import { FORM_ERROR } from 'final-form'
import { BigNumber } from 'bignumber.js'

export const validate = (values, { step, total, country, isElectronic, shiftCash, shiftsEnabled, shiftOpen, stationCashBank }) => {
  let errors = {};

  const method = get(values, 'method')
  
  if (step === 0) {
    if (isElectronic) {

      if (country === 'costaRica') {
        if (!get(values, 'type.key'))
          errors.type = I18n.get('thisFieldIsRequired', 'Este campo es obligatorio')
        if (!get(values, 'cause'))
          errors.cause = I18n.get('thisFieldIsRequired', 'Este campo es obligatorio')
        if (!get(values, 'saleCondition.key'))
          errors.saleCondition = I18n.get('thisFieldIsRequired', 'Este campo es obligatorio')
        if (!get(values, 'paymentMethod.key'))
          errors.paymentMethod = I18n.get('thisFieldIsRequired', 'Este campo es obligatorio')
        if (!get(values, 'economicActivity.code'))
          errors.economicActivity = I18n.get('thisFieldIsRequired', 'Este campo es obligatorio')
        if (!get(values, 'warehouse.id'))
          errors.warehouse = I18n.get('thisFieldIsRequired', 'Este campo es obligatorio')
      }
      if (country === 'argentina') {
        if (!get(values, 'saleCondition.key'))
          errors.saleCondition = I18n.get('thisFieldIsRequired', 'Este campo es obligatorio')
      }
      if (country === 'colombia') {
        if (!get(values, 'type.key'))
          errors.type = I18n.get('thisFieldIsRequired', 'Este campo es obligatorio')
      }
      if (country === 'peru') {
        if (!get(values, 'type.key'))
          errors.type = I18n.get('thisFieldIsRequired', 'Este campo es obligatorio')
        if (!get(values, 'cause'))
          errors.cause = I18n.get('thisFieldIsRequired', 'Este campo es obligatorio')
      }
    }

    if (!method) {
      errors = {
        ...errors,
        [FORM_ERROR]: I18n.get('youMustChooseAMethod', 'debes elegir un método de devolución'),
      }
    }
  }

  if (step === 2) {
    let totalPrice = new BigNumber(total);
    let refundAmount = new BigNumber(!!get(values, 'refund.amount') ? get(values, 'refund.amount') : 0)
    let invoiceAmount = new BigNumber(!!get(values, 'invoice.amount') ? get(values, 'invoice.amount') : 0)
    const refundAccountId = get(values, 'refund.account.id');
    const stationCashBankId = get(stationCashBank, 'id');

    switch (method) {
      case 'cash':
        if (!get(values, 'refund.amount'))
          errors.refund = { 
            ...errors.refund, 
            amount: I18n.get('thisFieldIsRequired', 'Este campo es obligatorio')
          }
        if (!!shiftsEnabled && shiftOpen === true && refundAccountId === stationCashBankId && refundAmount.gt(shiftCash)) {
          errors.refund = { 
            ...errors.refund, 
            amount: I18n.get('noCashAvailable', 'el valor del egreso no puede ser mayor a la cantidad de efectivo en caja')
          }
        }
        if (totalPrice.lt(refundAmount))
          errors.refund = { 
            ...errors.refund, 
            amount: I18n.get('theAmountCannotBeGreaterThanRefundTotal', 'el monto no puede ser mayor que el total de la devolución')
          }
        if (!get(values, 'refund.account.id'))
          errors.refund = { 
            ...errors.refund, 
            account: I18n.get('thisFieldIsRequired', 'Este campo es obligatorio')
          }
        break;
      case 'invoice':
        if (!get(values, 'invoice.amount'))
          errors.invoice = { 
            ...errors.invoice, 
            amount: I18n.get('thisFieldIsRequired', 'Este campo es obligatorio')
          }
        if (totalPrice.lt(invoiceAmount))
          errors.invoice = { 
            ...errors.invoice, 
            amount: I18n.get('theAmountCannotBeGreaterThanRefundTotal', 'el monto no puede ser mayor que el total de la devolución')
          }
        if (!get(values, 'invoice.invoice.id'))
          errors.invoice = { 
            ...errors.invoice, 
            invoice: I18n.get('thisFieldIsRequired', 'Este campo es obligatorio')
          }
        
        break;
      case 'combined':
        if (!get(values, 'refund.amount'))
          errors.refund = { 
            ...errors.refund, 
            amount: I18n.get('thisFieldIsRequired', 'Este campo es obligatorio')
          }
        if (!!shiftsEnabled && shiftOpen === true && refundAmount.gt(shiftCash)) {
          errors.refund = { 
            ...errors.refund, 
            amount: I18n.get('noCashAvailable', 'el valor del egreso no puede ser mayor a la cantidad de efectivo en caja')
          }
        }
        if (totalPrice.lt(refundAmount))
          errors.refund = { 
            ...errors.refund, 
            amount: I18n.get('theAmountCannotBeGreaterThanRefundTotal', 'el monto no puede ser mayor que el total de la devolución')
          }
        if (!get(values, 'refund.account.id'))
          errors.refund = { 
            ...errors.refund, 
            account: I18n.get('thisFieldIsRequired', 'Este campo es obligatorio')
          }
        if (!get(values, 'invoice.amount'))
          errors.invoice = { 
            ...errors.invoice, 
            amount: I18n.get('thisFieldIsRequired', 'Este campo es obligatorio')
          }
        if (totalPrice.lt(invoiceAmount))
          errors.invoice = { 
            ...errors.invoice, 
            amount: I18n.get('theAmountCannotBeGreaterThanRefundTotal', 'el monto no puede ser mayor que el total de la devolución')
          }
        if (!get(values, 'invoice.invoice.id'))
          errors.invoice = { 
            ...errors.invoice, 
            invoice: I18n.get('thisFieldIsRequired', 'Este campo es obligatorio')
          }
        if (totalPrice.lt(invoiceAmount.plus(refundAmount))) {
          errors.refund = { 
            ...errors.refund, 
            amount: I18n.get('theCombinedAmountCannotBeGreaterThanRefundTotal', 'el monto combinado no puede ser mayor que el total de la devolución')
          } 
          errors.invoice = { 
            ...errors.invoice, 
            amount: I18n.get('theCombinedAmountCannotBeGreaterThanRefundTotal', 'el monto combinado no puede ser mayor que el total de la devolución')
          } 
        }
        break;
    
      default:
        break;
    }
  }

  return errors;
}

export const transform = (values, { isElectronic, country }) => {
  let transformedValues = {
    invoices: [],
    refunds: []
  }
  
  const method = get(values, 'method');

  if (method === 'cash' || method === 'combined') {
    transformedValues = {
      ...transformedValues,
      refunds: [{
        amount: +get(values, 'refund.amount', 0),
        account: get(values, 'refund.account.id', null),
        observations: get(values, 'refund.observations', null),
      }]
    }
  }
  
  if (method === 'invoice' || method === 'combined') {
    transformedValues = {
      ...transformedValues,
      invoices: [{
        ...get(values, 'invoice.invoice', null),
        amount: +get(values, 'invoice.amount', 0),
      }]
    }
  }

  if (country === 'spain') {
    transformedValues = {
      ...transformedValues,
      cause: get(values, 'cause', " "),
    }
  }
  
  if (isElectronic) {

    if (country === 'colombia') {
      transformedValues = {
        ...transformedValues,
        type: get(values, 'type.key')
      }
    }
    if (country === 'peru') {
      transformedValues = {
        ...transformedValues,
        type: get(values, 'type.key'),
        cause: get(values, 'cause'),
      }
    }
    if (country === 'costaRica') {
      transformedValues = {
        ...transformedValues,
        type: get(values, 'type.key'),
        cause: get(values, 'cause'),
        saleCondition: get(values, 'saleCondition.key'),
        paymentMethod: get(values, 'paymentMethod.key'),
        econimicActivity: get(values, 'econimicActivity.code'),
        warehouse: get(values, 'warehouse.id'),
      }
    }
    if (country === 'argentina') {
      transformedValues = {
        ...transformedValues,
        saleCondition: get(values, 'saleCondition.key'),
      }
    }
  }
  
  return transformedValues
}
import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Form } from 'react-final-form';
import { I18n } from '@aws-amplify/core';
import { graphqlOperation } from '@aws-amplify/api';
import { get } from 'lodash'

import * as mutations from '../../../graphql/mutations';
import { formError } from '../../../utils/errors';
import { stationTransformer } from '../../../utils/transformers';
import { updateStation } from '../../../reducers/app';
import { setUser } from '../../../reducers/auth';
import { getShiftStatus } from '../../../reducers/shifts';
import { closeModal } from '../../../reducers/modals';
import { station as stationSelector, stationBaseBank, APIGraphqlSelector } from '../../../selectors/app';
import { validate, transform } from '../../forms/stationSettings/utils';
import Modal from '../../common/Modal';
import Notification from '../common/Notification';
import Bottom from '../common/WizardBottom';
import Body from './FormBody';
import { sendNewGTMEvent } from '../../../reducers/company';
import { calculateActionTimeSelector } from '../../../selectors/monitoring';

const calculateSteps = options => {
  let steps = [];
  if (!options) return []
  if (options.hasOwnProperty('debitBank') || options.hasOwnProperty('creditBank')
    || options.hasOwnProperty('transferBank') || options.hasOwnProperty('closeBank')
    || options.hasOwnProperty('baseBank'))
    steps.push(1)
  if (options.hasOwnProperty('invoiceNumeration') || options.hasOwnProperty('refundNumeration')
    || options.hasOwnProperty('cashReceiptNumeration'))
    steps.push(2)
  if (options.hasOwnProperty('warehouse'))
    steps.push(3)
  if (options.hasOwnProperty('configureShift') || options.hasOwnProperty('openShift'))
    steps.push(4)
  return steps;
}

const renderTitle = step => {
  switch (step) {
    case 1:
      return I18n.get('banksSetting', 'configuración de bancos')
    case 2:
      return I18n.get('numerationsSetting', 'configuración de numeraciones')
    case 3:
      return I18n.get('warehouseSetting', 'configuración de bodega')
    case 4:
      return I18n.get('shiftSetting', 'configuración de turnos')

    default:
      return I18n.get('stationSetting', 'configuración de terminal')
  }
}

const Station = () => {
  const dispatch = useDispatch();
  const isOpen = useSelector(state => get(state, 'modals.stationSettings.isOpen', false));
  const options = useSelector(state => get(state, 'modals.stationSettings.params', false));
  const steps = calculateSteps(options)
  const station = useSelector(stationSelector)
  const baseBank = useSelector(stationBaseBank)
  const APIGraphql = useSelector(APIGraphqlSelector);
  const calculateActionTime = useSelector(calculateActionTimeSelector);

  const [step, setStep] = useState(0)

  useEffect(() => {
    setStep(0)
  }, [isOpen])

  const { id } = station;

  const submit = async values => {
    const transformedValues = transform(values)

    try {
      const response = await Promise.all([
        APIGraphql(graphqlOperation(mutations.updateStation, {
          station: stationTransformer({ ...station, ...transformedValues.station })
        })),
        options.hasOwnProperty('configureShift') ? APIGraphql(graphqlOperation(mutations.updateUser, {
          user: {
            company: { localSettings: transformedValues.localSettings }
          }
        })) : Promise.resolve(),
        steps.includes(4) && !(options.hasOwnProperty('configureShift') && !transformedValues.shiftsEnabled)
          ? APIGraphql(graphqlOperation(mutations.openShift, {
            shift: {
              idStation: id,
              ...transformedValues.shift
            }
          }))
          : Promise.resolve(),
      ])

      const updatedStation = get(response, '0.data.updateStation', null)
      if (!!updateStation)
        dispatch(updateStation(updatedStation))

      if (options.hasOwnProperty('configureShift')) {
        const updatedUser = get(response, '1.data.updateUser', null)
        if (!!updatedUser)
          dispatch(setUser(updatedUser))
      }

      if(steps.includes(4) && options?.hasOwnProperty('openShift')) {
        dispatch(sendNewGTMEvent('pos-shift-opened', {
          responseTime: calculateActionTime("openShift")
        }));
      }

      if (steps.includes(4) && !(options.hasOwnProperty('configureShift') && !transformedValues.shiftsEnabled))
        dispatch(getShiftStatus())

      dispatch(closeModal({ modal: 'stationSettings' }))
    } catch (error) {
      return formError(error, I18n.get('updateRequiredSettingsError', 'hubo un error guardar los valores'))
    }
  }

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={() => dispatch(closeModal({ modal: 'stationSettings' }))}
      title={renderTitle(steps[step])}
      className="modal__station-settting"
      overlayClassName="modal__station-settting-overlay"
    >
      <Form
        onSubmit={submit}
        validate={values => validate(values, { step: steps[step], options })}
        initialValues={{
          baseBank,
        }}
        keepDirtyOnReinitialize
      >
        {({ handleSubmit, submitting, values, submitError }) => (
          <form noValidate onSubmit={handleSubmit}>
            <Body values={values} step={steps[step]} options={options} />

            {!!submitError && (
              <Notification isOpen={true} text={submitError} type="error" />
            )}

            <Bottom
              onPrevious={step === 0 ? null : () => setStep(Math.max(step - 1, 0))}
              onNext={() => setStep(Math.min(step + 1, steps.length - 1))}
              nextAsSubmit={steps.length - 1 === step}
              submitting={submitting}
              nextText={steps.length - 1 === step ? I18n.get('save', 'guardar') : null}
            />
          </form>
        )}
      </Form>
    </Modal>
  )
}

export default Station;
import React from 'react'
import { I18n } from '@aws-amplify/core';

import Bottom from '../../common/Bottom'
import Modal from '../common/Modal'

const PaymentTerm = ({ isOpen, onClose, onConfirm }) => {

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      title={I18n.get('lookOut', 'Cuidado')}
    >
      <div className="w-100 h-100 bg-white">
        <p className="p-5 text-capitalize-first m-0">
          {I18n.get('doYouWantToExitWithoutSavingTheChanges', '¿Deseas salir sin guardar los cambios?')}
        </p>
      </div>

      <Bottom
        onClose={() => onClose()}
        onConfirm={() => onConfirm()}
        submitText={I18n.get('understood', 'entendido')}
        hideRequired
      />
    </Modal>
  )
}

export default PaymentTerm;
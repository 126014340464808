import React from 'react';
import { I18n } from '@aws-amplify/core';
import { capitalize } from 'lodash';
import { isAdmin as isAdminSelector } from '../../../selectors/auth';
import Station from '../../forms/station/Station';
import { useSelector } from 'react-redux';

const FormBody = ({ values }) => {
  const isAdmin = useSelector(isAdminSelector);

  return (
    <>
      <div className='card d-flex flex-column bg-white shadow my-3 p-4 rounded-lg'>
        <p className='h3 text-title-secundary text-capitalize-first'>
          {I18n.get('generalInfo', 'información general')}
        </p>
        <p className='h5 text-muted text-capitalize-first'>
          {I18n.get(
            'generalStationInfoTutorial',
            'Configura los datos de tu terminal y la información necesaria para crear tus documentos'
          )}
        </p>
        <div className='form-body w-100 p-3 overflow-hidden'>
          <Station.BasicInfo />
        </div>
      </div>

      {isAdmin && (
        <div className='card d-flex flex-column bg-white shadow my-3 p-4 rounded-lg'>
          <p className='h3 text-title-secundary text-capitalize-first'>
            {I18n.get('userAssociation', 'asociación de usuarios')}
          </p>
          <p className='h5 text-muted text-capitalize-first'>
            {I18n.get(
              'userAssociationTutorial',
              'elige los usuarios que tendrán acceso al uso de esta terminal'
            )}
          </p>
          <div className='form-body w-100 p-3 overflow-hidden'>
            <Station.Users />
          </div>
        </div>
      )}

      <div className='card d-flex flex-column bg-white shadow my-3 p-4 rounded-lg'>
        <p className='h3 text-title-secundary text-capitalize-first'>
          {I18n.get('bankAccounts', 'cuentas de banco')}
        </p>
        <p className='h5 text-muted text-capitalize-first'>
          {I18n.get(
            'bankAccountsTutorial',
            'descubre cómo configurar tus bancos '
          )}{' '}
          <a
            className='btn-link'
            href='https://ayuda.alegra.com/es/c%C3%B3mo-crear-un-banco'
            target='_blank'
            rel='noreferrer'
          >
            {capitalize(I18n.get('seeMore', 'ver mas'))}
          </a>
        </p>
        <div className='form-body w-100 p-3 overflow-hidden'>
          <Station.Bank values={values} />
        </div>
      </div>

      <div className='card d-flex flex-column bg-white shadow my-3 p-4 rounded-lg'>
        <p className='h3 text-title-secundary text-capitalize-first'>
          {I18n.get('shiftSetting', 'configuración de turnos')}
        </p>
        <p className='h5 text-muted text-capitalize-first'>
          {I18n.get(
            'shiftSettingTutorial',
            'Descubre cómo configurar el banco para apertura y cierre de turno '
          )}
          <a
            className='btn-link'
            href='https://ayuda.alegra.com/es/apertura-y-cierre-de-turnos-en-alegra-pos'
            target='_blank'
            rel='noreferrer'
          >
            {capitalize(I18n.get('seeMore', 'ver mas'))}
          </a>
        </p>
        <div className='form-body w-100 p-3 overflow-hidden'>
          <Station.Shift />
        </div>
      </div>

      <div className='card d-flex flex-column bg-white shadow my-3 p-4 rounded-lg'>
        <p className='h3 text-title-secundary text-capitalize-first'>
          {I18n.get('additionalSettings', 'Configuraciones adicionales')}
        </p>
        <div className='form-body w-100 p-3 overflow-hidden'>
          <Station.AdditionalSettings values={values} />
        </div>
      </div>
    </>
  );
};

export default FormBody;

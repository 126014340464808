import React from 'react';
import PropTypes from 'prop-types';
import { I18n } from '@aws-amplify/core';

import Methods from './Methods';

/**
 * RefundMethods Component
 *
 * Renders the refund method selection interface for users.
 *
 * @param {Object} props - Component properties.
 * @param {Function} props.onChooseMethod - Callback invoked when a refund method is selected.
 * @returns {JSX.Element} - The rendered RefundMethods component.
 */
const RefundMethods = ({ onChooseMethod }) => {
  return (
    <section className='refund-choose-method'>
      <h2 className='subtitle-refund-method'>
        {I18n.get(
          'chooseRefundMethodNew',
          'Elige el tipo de devolución que le vas a registrar a tu cliente:'
        )}
      </h2>

      <div>
        <Methods onChooseMethod={onChooseMethod} />
      </div>
    </section>
  );
};

RefundMethods.propTypes = {
  /**
   * Callback function invoked when a refund method is selected.
   * Receives the selected method as an argument.
   */
  onChooseMethod: PropTypes.func.isRequired,
};

export default React.memo(RefundMethods);

import { useCallback, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { get } from "lodash";
import { API, graphqlOperation } from "aws-amplify";
import { useQueryClient } from "@tanstack/react-query";

import { idCompanySelector } from "../selectors/company";
import * as subscriptions from "../graphql/subscriptions";
import { PriceListKeys } from "../components/inventory/PriceLists/queries";
import { addPriceList, removePriceList, updatePriceList } from "../reducers/priceLists";
import alegraAPI from "../reducers/alegraAPI";

const PricesList = () =>{
  const ref = useRef();
  const idCompany = useSelector(idCompanySelector);
  const networkStatus = useSelector((state) => get(state, "app.networkStatus", "online"));
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const getPriceList = useCallback(async (id) => {
    try {
      const response = await alegraAPI.get(`/price-lists/${id}`);
      return get(response, "data", null);
    } catch (error) {
      console.log("Error", error);
      return null;
    }
  }, []);

  useEffect(() => {
    let subscription = null;

    if (networkStatus === "online") {
      subscription = API.graphql(graphqlOperation(subscriptions.onCreatePriceListNotify, {
        channel: `pos_${idCompany}`
      }))
        .subscribe({
          next: async (data) => {
            await queryClient.refetchQueries(PriceListKeys.allApi);

            const pricesList = await getPriceList(get(data, "value.data.onCreatePriceListNotify.id"));
            if (!!pricesList) {
              dispatch(addPriceList(pricesList));
            }

          },
          error: () => setTimeout(() => {
            if (!!ref.current)
              console.log("Error");
          }, 60000)
        });
    }

    return () => {
      if (subscription)
        subscription.unsubscribe();
    };
  }, [networkStatus, idCompany]);

  useEffect(() => {
    let subscription = null;

    if (networkStatus === "online") {
      subscription = API.graphql(graphqlOperation(subscriptions.onUpdatePriceListNotify, {
        channel: `pos_${idCompany}`
      }))
        .subscribe({
          next: async (data) => {
            await queryClient.refetchQueries(PriceListKeys.allApi);

            const pricesList = await getPriceList(get(data, "value.data.onUpdatePriceListNotify.id"));
            if (!!pricesList) {
              dispatch(updatePriceList({
                id: pricesList.id,
                changes: pricesList
              }));
            }

          },
          error: () => setTimeout(() => {
            if (!!ref.current)
              console.log("Error");
          }, 60000)
        });
    }

    return () => {
      if (subscription)
        subscription.unsubscribe();
    };
  }, [networkStatus, idCompany]);

  useEffect(() => {
    let subscription = null;

    if (networkStatus === "online") {
      subscription = API.graphql(graphqlOperation(subscriptions.onDeletePriceListNotify, {
        channel: `pos_${idCompany}`
      }))
        .subscribe({
          next: async (data) => {
            await queryClient.refetchQueries(PriceListKeys.allApi);
            dispatch(removePriceList(get(data, "value.data.onDeletePriceListNotify.id")));
          },
          error: () => setTimeout(() => {
            if (!!ref.current)
              console.log("Error");
          }, 60000)
        });
    }

    return () => {
      if (subscription)
        subscription.unsubscribe();
    };
  }, [networkStatus, idCompany]);

  useEffect(() => {
    ref.current = true;
    return () => ref.current = false;
  }, []);

  return null;
};

export default PricesList;
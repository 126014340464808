// eslint-disable-next-line
export default ({ id, className }) => (
  <svg 
    id={id}
    className={className}
    width="120" 
    height="48" 
    viewBox="0 0 120 48" 
    fill="none" 
    xmlns="http://www.w3.org/2000/svg"
  >
    <path fillRule="evenodd" clipRule="evenodd" d="M105.834 17.7288C105.834 17.5272 105.656 17.3456 105.459 17.3456H104.204C103.987 17.3456 103.829 17.5272 103.829 17.7288V30.3815C103.829 30.5827 104.007 30.7644 104.204 30.7644H105.459C105.676 30.7644 105.834 30.5827 105.834 30.3815V17.7288Z" fill="#140F38" fillOpacity="0.94"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M98.5907 17.7249C98.5907 17.5274 98.4165 17.3492 98.2235 17.3492H96.9932C96.7804 17.3492 96.626 17.5274 96.626 17.7249L96.6325 25.2638C96.6325 26.4307 96.5357 26.9836 96.2268 27.5372C95.7628 28.348 94.8348 28.8814 93.8308 28.8814C92.8066 28.8814 91.8787 28.348 91.4147 27.5372C91.1061 26.9836 91.009 26.4307 91.009 25.2638L91.0161 17.7249C91.0161 17.5274 90.8422 17.3492 90.6492 17.3492H89.4186C89.2058 17.3492 89.0514 17.5274 89.0514 17.7249L89.0582 25.7981C89.0582 27.0827 89.3668 28.032 90.0435 28.9016C90.9517 30.0676 92.3615 30.7599 93.8308 30.7599C95.28 30.7599 96.6901 30.0676 97.5983 28.9016C98.275 28.032 98.5836 27.0634 98.5836 25.7981L98.5907 17.7249Z" fill="#140F38" fillOpacity="0.94"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M84.3987 30.203C84.7015 30.509 84.601 30.7591 84.1755 30.7588L82.8675 30.7575C82.693 30.7572 82.4488 30.6544 82.3246 30.5288L78.0487 26.2067C77.7604 25.9156 77.9623 25.4134 78.3674 25.4138L79.345 25.4147C79.5195 25.415 79.7637 25.5178 79.8879 25.6434L84.3987 30.203Z" fill="#140F38" fillOpacity="0.94"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M81.5449 27.2769C82.3961 26.3484 82.7555 25.4192 82.7555 24.0845C82.7555 21.3172 80.7122 19.188 78.0449 19.188C75.3594 19.188 73.3923 21.2784 73.3923 24.1233C73.3923 25.535 73.8458 26.6578 74.754 27.5485C75.6431 28.4193 76.8158 28.9217 77.9694 28.9217C78.745 28.9217 79.5965 28.7094 80.2202 28.342L81.5449 27.2769ZM81.5634 29.7152C80.5232 30.393 79.275 30.7605 78.0449 30.7605C76.1912 30.7605 74.3193 29.9095 73.0516 28.4578C72.0115 27.2968 71.4815 25.7678 71.4815 24.1034C71.4815 20.3688 74.4133 17.3502 78.0644 17.3502C81.7339 17.3502 84.6654 20.4076 84.6654 24.2192C84.6654 25.981 84.1172 27.3157 82.8687 28.6511" fill="#140F38" fillOpacity="0.94"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M66.2121 30.7589H66.7313C66.948 30.7589 67.1052 30.5779 67.1052 30.3773V29.2308C67.1052 29.0302 66.948 28.8492 66.7313 28.8492H63.3296L63.3284 28.8514H61.8486V24.6857H62.1285L62.1279 24.6866H66.7383C66.9551 24.6866 67.1123 24.5056 67.1123 24.305V23.1585C67.1123 22.9576 66.9551 22.7769 66.7383 22.7769H66.5491V22.7766H61.8486V19.259H66.7313C66.948 19.259 67.1052 19.0779 67.1052 18.8773V17.7309C67.1052 17.5303 66.948 17.3492 66.7313 17.3492H63.3244H60.2332C60.0165 17.3492 59.8593 17.5303 59.8593 17.7309V30.3789C59.8593 30.5773 60.0134 30.7561 60.2267 30.7599H66.2121V30.7589Z" fill="#140F38" fillOpacity="0.94"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M54.2445 17.3503H53.0308C52.8177 17.3503 52.663 17.5332 52.663 17.7363L52.6568 26.6163L44.408 17.5341C44.408 17.5341 44.276 17.3528 44.0421 17.3528C43.8223 17.3528 43.6197 17.3503 43.6197 17.3503C43.4255 17.3503 43.2519 17.5127 43.2519 17.7155V29.8374V30.3748C43.2519 30.5776 43.4187 30.7609 43.612 30.7609H44.8455C45.0585 30.7609 45.2132 30.5776 45.2132 30.3748L45.2006 21.2722L53.7102 30.6C53.7102 30.6 53.8755 30.7609 53.9713 30.7609H54.2355C54.4288 30.7609 54.6033 30.5984 54.6033 30.3956L54.6123 30.1679V17.7363C54.6123 17.5332 54.4378 17.3503 54.2445 17.3503Z" fill="#140F38" fillOpacity="0.94"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M13.8664 17.3875L24.9366 11.8064C25.6046 11.4697 26.4331 11.6164 26.9237 12.1582L36.4383 22.6688C37.0144 23.3053 37.0031 24.2414 36.4117 24.8655L26.1274 35.7161C25.1306 36.6702 23.4614 36.5723 22.5992 35.5094L12.6316 22.3089C11.3821 20.654 11.9605 18.3483 13.8664 17.3875Z" fill="#FF2F73"/>
    <g style={{mixBlendMode: 'multiply'}}>
    <path fillRule="evenodd" clipRule="evenodd" d="M11.8172 22.5345L19.0213 12.8863C19.4559 12.3042 20.2662 12.0861 20.9646 12.3634L34.5129 17.7411C35.3334 18.0667 35.7731 18.9076 35.5449 19.7139L31.5755 33.7369C31.1438 35.0089 29.6059 35.6247 28.3253 35.0387L13.0797 27.4497C11.1684 26.4982 10.5771 24.1956 11.8172 22.5345Z" fill="#2FCAD7"/>
    </g>
    <path fillRule="evenodd" clipRule="evenodd" d="M16.7437 15.9369L22.5683 13.0004L30.5558 16.1708L35.1205 21.2133L33.0966 28.363L27.2679 34.5128L18.5814 30.1888L12.6314 22.309C12.5175 22.1581 12.4187 22.0018 12.3348 21.8415L16.7437 15.9369Z" fill="#1A1446"/>
  </svg>
)

import { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { API, graphqlOperation } from '@aws-amplify/api';
import { get } from 'lodash';

import * as subscriptions from '../graphql/subscriptions';
import { idCompanySelector } from '../selectors/company';
import { closeModal, openModal } from '../reducers/modals';
import { useConnectionStatus } from '../hooks/useConnectionStatus';
import { fetchUser } from '../reducers/auth';

const MembershipSNS = () => {
  const ref = useRef();
  const dispatch = useDispatch();
  const idCompany = useSelector(idCompanySelector);
  const isOnline = useConnectionStatus();
  const [onShowPaymentDeclinedModalError, setOnShowPaymentDeclinedModalError] = useState(false);
  const [onDismissPopupNotificationError, setOnDismissPopupNotificationError] = useState(false);

  useEffect(() => {
    let subscription = null;
    if (isOnline) {
      subscription = API.graphql(graphqlOperation(subscriptions.onDeclinedPaymentNotify, {
        channel: `pos_${idCompany}`
      }))
        .subscribe({
          next: (data) => {
            const showPaymentDeclinedModal = data => {
              try {
                const attempt = get(data, 'value.data.onDeclinedPaymentNotify.attempt', null);
                const title = get(data, 'value.data.onDeclinedPaymentNotify.title', null);
                const text = get(data, 'value.data.onDeclinedPaymentNotify.text', null);
                const action = get(data, 'value.data.onDeclinedPaymentNotify.action', null);
                const actionUrl = get(data, 'value.data.onDeclinedPaymentNotify.actionUrl', null);
                const last = get(data, 'value.data.onDeclinedPaymentNotify.last', false);

                dispatch(openModal({
                  modal: 'membershipPaymentDeclined', params: {
                    attempt,
                    title,
                    text,
                    action,
                    actionUrl,
                    last
                  }
                }))
              } catch { }
            }
            showPaymentDeclinedModal(data);
          },
          error: () => setTimeout(() => {
            if (!!ref.current)
              setOnDismissPopupNotificationError(!onDismissPopupNotificationError)
          }, 60000)
        })
    }

    return () => {
      if (subscription)
        subscription.unsubscribe()
    }
  }, [isOnline, onShowPaymentDeclinedModalError, idCompany, dispatch])

  useEffect(() => {
    let subscription = null;
    if (isOnline) {
      subscription = API.graphql(graphqlOperation(subscriptions.onDismissPopupNotification, {
        channel: `pos_${idCompany}`
      }))
        .subscribe({
          next: (data) => {
            const dismissPopupNotification = data => {
              try {
                dispatch(closeModal({ modal: 'membershipPaymentDeclined' }));
                dispatch(fetchUser());
              } catch { }
            }
            dismissPopupNotification(data);
          },
          error: () => setTimeout(() => {
            if (!!ref.current)
              setOnShowPaymentDeclinedModalError(!onShowPaymentDeclinedModalError)
          }, 60000)
        })
    }

    return () => {
      if (subscription)
        subscription.unsubscribe()
    }
  }, [isOnline, onShowPaymentDeclinedModalError, idCompany, dispatch])


  useEffect(() => {
    ref.current = true
    return () => ref.current = false
  }, []);

  return null;
}

export default MembershipSNS;


import React, { useEffect, useRef, useState } from 'react'
import { I18n } from '@aws-amplify/core'
import { useSelector } from 'react-redux'

import { country as countrySelector, electronicInvoicing } from '../../../selectors/company'
import Item from '../../forms/item/Item';
import Accordion from '../../common/Accordion'
import Bottom from './Bottom';
import { useScrollTo } from '../../../hooks/useScrollTo';
import { useForm, useFormState } from 'react-final-form';
import { get } from 'lodash';

const Primary = (props) => {
  const form = useForm();
  const [onSubmitEvent, setOnSubmitEvent] = useState(get(form.getState().values, 'onSubmitEvent', false));
  const [accordion, setAccordion] = useState({
    main: true,
    variants: true,
    inventory: true,
    price: false,
    kit: true,
    electronicFields: true,
    accountant: false,
    adicionalData: false,
  })

  const country = useSelector(countrySelector)
  const isElectronic = useSelector(electronicInvoicing)

  const { errors, submitFailed } = useFormState();

  const toggleSection = section => {
    setAccordion(prev => ({
      ...prev,
      [section]: !prev[section]
    }))
  }

  const fieldRefs = {
    name: useRef(null),
    unitCost: useRef(null),
    warehouses: useRef(null),
    salePrice: useRef(null),
    price: useRef(null),
    itemVariants: useRef(null),
  };

  useEffect(() => {
    setOnSubmitEvent(get(form.getState().values, 'onSubmitEvent', false));
  },[form.getState().values])


  useScrollTo({
    submitFailed,
    errors,
    fieldRefs,
    onSubmitEvent,
  });

  return (
    <div className="bg-white p-5 new-item-primary-view">
      <Accordion
        isOpen={accordion['main']}
        onToggle={() => toggleSection('main')}
        header={I18n.get('item.generalInfo', 'información general')}
        subheader={I18n.get('item.generalInfoSubtitles', 'Indica los datos básicos de tu producto o servicio')}
        body={(
          <div className="form-body">
            <Item.SelectType {...props} />

            <Item.NewBasicData fieldRefs={fieldRefs} {...props} />

            <div className="my-3 d-lg-none">
              <Item.Image {...props} name="leftImage" />

              <Item.RapidData {...props} />
            </div>
          </div>
        )}
        headerStyle={{
          width: 'fit-content'
        }}
        carretClassName="icon-primary ml-2"
        headerClassName="text-title-secundary h3"
        subheaderClassName="h5 text-subtitle mb-2"
        containerClassName="border-bottom mb-4"
        bodyClassName="form-body"
      />

      {props.values.type === 'product' && !props.values.hasVariants && (
        <Accordion
          isOpen={accordion['inventory']}
          onToggle={() => toggleSection('inventory')}
          header={I18n.get('item.inventoryOptions', 'Detalles de inventario')}
          subheader={I18n.get('item.inventoryOptionsSubtitles', 'Distribuye y controla las cantidades de tus productos en diferentes lugares.')}
          body={(
            <div className="form-body px-2">
              <Item.NewInventory fieldRefs={fieldRefs} {...props} />
            </div>
          )}
          headerStyle={{
            width: 'fit-content'
          }}
          carretClassName="icon-primary ml-2"
          headerClassName="text-title-secundary h3"
          subheaderClassName="h5 text-subtitle mb-2"
          containerClassName="border-bottom mb-4"
          bodyClassName="form-body"
        />
      )}

      {props.values.type === 'product' && !!props.values.hasVariants && (
        <Accordion
          isOpen={accordion['variants']}
          onToggle={() => toggleSection('variants')}
          header={I18n.get('item.variantsListOptions', 'Variantes')}
          subheader={I18n.get('item.variantsListOptionsSubtitles', 'Agrega atributos como talla, color u otra cualidad para categorizar tus productos.')}
          body={(
            <div className="form-body px-2">
              <Item.Variants fieldRefs={fieldRefs} {...props} />
            </div>
          )}
          headerStyle={{
            width: 'fit-content'
          }}
          carretClassName="icon-primary ml-2"
          headerClassName="text-title-secundary h3"
          subheaderClassName="h5 text-subtitle mb-2"
          containerClassName="border-bottom mb-4"
          bodyClassName="form-body"
        />
      )}

      {props.values.type === 'kit' && (
        <Accordion
          isOpen={accordion['kit']}
          onToggle={() => toggleSection('kit')}
          header={I18n.get('item.kitListOptions', 'Combo')}
          subheader={I18n.get('item.kitListOptionsSubtitles', 'Selecciona los productos y sus cantidades para armar un combo')}
          body={(
            <div className="form-body px-2">
              <Item.Kit {...props} />
            </div>
          )}
          headerStyle={{
            width: 'fit-content'
          }}
          carretClassName="icon-primary ml-2"
          headerClassName="text-title-secundary h3"
          subheaderClassName="h5 text-subtitle mb-2"
          containerClassName="border-bottom mb-4"
          bodyClassName="form-body"
        />
      )}

      <Accordion
        isOpen={accordion['price']}
        onToggle={() => toggleSection('price')}
        header={I18n.get('item.priceListOptions', 'Listas de precios')}
        subheader={
          <p>
            {I18n.get('item.priceListOptionsSubtitles', 'Agrega diferentes precios a tus productos')}
          </p>
        }
        body={(
          <div className="form-body px-2">
            <Item.PriceLists {...props} />
          </div>
        )}
        headerStyle={{
          width: 'fit-content'
        }}
        carretClassName="icon-primary ml-2"
        headerClassName="text-title-secundary h3"
        subheaderClassName="h5 text-subtitle mb-2"
        containerClassName="border-bottom mb-4"
        bodyClassName="form-body"
      />

      <Accordion
        isOpen={accordion['electronicFields']}
        onToggle={() => toggleSection('electronicFields')}
        header={I18n.get('item.electronicFieldsOptions', 'Campos adicionales')}
        subheader={
          <p>
            {I18n.get('item.electronicFieldsOptionsSubtitles', 'Agrega información adicional para personalizar tu servicio.')}
            <a href="https://ayuda.alegra.com/es/crea-campos-adicionales-y-as%C3%B3cialos-a-tus-productos-con-el-sistema-punto-de-venta-pos" target="_blank" rel="noreferrer">
              {I18n.get('knowMore', 'Saber más')}
            </a>
          </p>
        }
        body={(
          <div className="form-body px-2">
            <Item.AdicionalFields {...props} />
          </div>
        )}
        headerStyle={{
          width: 'fit-content'
        }}
        carretClassName="icon-primary ml-2"
        headerClassName="text-title-secundary h3"
        subheaderClassName="h5 text-subtitle mb-2"
        containerClassName="border-bottom mb-4"
        bodyClassName="form-body"
      />

      <Accordion
        isOpen={accordion['accountant']}
        onToggle={() => toggleSection('accountant')}
        header={I18n.get('item.accountingOptions', 'Configuración contable')}
        subheader={I18n.get('item.accountingOptionsSubtitles', 'Elige la cuenta en donde se registrarán los ingresos de este producto')}
        body={(
          <div className="form-body px-2">
            <Item.Accounting {...props} />
          </div>
        )}
        headerStyle={{
          width: 'fit-content'
        }}
        carretClassName="icon-primary ml-2"
        headerClassName="text-title-secundary h3"
        subheaderClassName="h5 text-subtitle mb-2"
        containerClassName="border-bottom mb-4"
        bodyClassName="form-body"
      />

      {isElectronic && country === 'colombia' && (
        <Accordion
          isOpen={accordion['adicionalData']}
          onToggle={() => toggleSection('adicionalData')}
          header={I18n.get('item.adicionalDataOptions', 'Datos adicionales para facturas de exportación')}
          subheader={I18n.get('item.adicionalDataOptionsSubtitles', 'Completa estos datos si el producto que estás creando se usará en facturas de exportación.')}
          body={(
            <div className="form-body px-2">
              <Item.ElectronicFields {...props} />
            </div>
          )}
          headerStyle={{
            width: 'fit-content'
          }}
          carretClassName="icon-primary ml-2"
          headerClassName="text-title-secundary h3"
          subheaderClassName="h5 text-subtitle mb-2"
          containerClassName="border-bottom mb-4"
          bodyClassName="form-body"
        />
      )}

      <div className="d-lg-none">
        <Bottom {...props} />
      </div>
    </div>
  )
}

export default Primary;
import { createContext, useContext } from 'react';
import { I18n } from '@aws-amplify/core';

import { useParams, useHistory } from 'react-router-dom';

import { useGlobalInvoiceTicketDetailQuery } from '../../invoice/services/queries';
import Loader from '../../../../components/common/Loader';
import Empty from '../../../../components/common/Empty';
import { IconSearch } from '@tabler/icons-react';

const GlobalInvoiceDetailContext = createContext({
  data: {},
  refetch: () => null,
});

export function useGlobalInvoiceDetail() {
  return useContext(GlobalInvoiceDetailContext);
}

function GlobalInvoiceDetailProvider({ children, id }) {
  const params = useParams();
  const history = useHistory();
  const { data, isLoading, refetch } = useGlobalInvoiceTicketDetailQuery(
    params?.id || id
  );

  if (!params?.id && !id) {
    return <></>;
  }

  if (isLoading) {
    return (
      <div style={{ height: 600 }}>
        <Loader title={I18n.get('', 'Cargando tu factura 🛴')} />
      </div>
    );
  }

  if (!data) {
    return (
      <Empty
        icon={IconSearch}
        title={I18n.get('', 'No se encontro la factura global')}
        buttonAction={() => history.goBack()}
        buttonText={I18n.get('back', 'Regresar')}
      />
    );
  }

  return (
    <GlobalInvoiceDetailContext.Provider value={{ data, refetch }}>
      {children}
    </GlobalInvoiceDetailContext.Provider>
  );
}

export default GlobalInvoiceDetailProvider;

import { useDispatch, useSelector } from 'react-redux';
import Modal from '../../common/Modal';
import Typography from '../../common/Typography';
import { I18n } from 'aws-amplify';
import { Form } from 'react-final-form';
import { useCallback, useMemo } from 'react';
import { get } from 'lodash';

import { closeModal, openModal } from '../../../reducers/modals';
import { checkFeatureLimit } from '../../../reducers/membership';
import { getElectronicNumerations } from '../../../selectors/numerations';
import { setSettings } from '../../../reducers/activeInvoice';
import alegraAPI from '../../../reducers/alegraAPI';
import { replaceOfflineClient } from '../../../reducers/clients';
import { client as clientSelector } from '../../../selectors/activeInvoice';

import Body from './FormBody';
import Bottom from './Bottom';

import { COUNTRIES } from '../../../utils/enums/countries';
import spainAddresses from '../../countriesData/spain/municipalityProvince.json';
import { spainTransformer } from '../../../pages/contacts/contact/form/utils/spain/transformer';
import useSimplifiedNumeration from './useSimplifiedNumeration';
import { validate } from './utils';

/**
 * UpdateSpainClient Component
 *
 * This component renders a modal to update Spanish client information.
 *
 * @component
 */
const UpdateSpainClient = () => {
  const dispatch = useDispatch();

  // Selectors
  const numerations = useSelector(getElectronicNumerations);
  const isOpen = useSelector(
    (state) => state.modals.updateSpainClient?.isOpen ?? false
  );
  const params = useSelector(
    (state) => state.modals.updateSpainClient?.params ?? {}
  );
  const activeInvoiceClient = useSelector(clientSelector);

  // Derived data
  const contact = useMemo(
    () => ({ id: params?.id, address: params?.address }),
    [params]
  );
  const simplifiedNumeration = useSimplifiedNumeration(numerations);

  /**
   * Handler to close the modal.
   */
  const handleClose = useCallback(() => {
    dispatch(closeModal({ modal: 'updateSpainClient' }));
  }, [dispatch]);

  /**
   * Handler to continue after successful operations.
   */
  const onContinue = useCallback(() => {
    dispatch(
      checkFeatureLimit(['income', 'invoices'], () => {
        handleClose();
        dispatch(openModal({ modal: 'invoice' }));
      })
    );
  }, [dispatch, handleClose]);

  /**
   * Handler to change numeration.
   */
  const onChangeNumeration = useCallback(() => {
    if (simplifiedNumeration) {
      dispatch(setSettings({ numeration: simplifiedNumeration }));
      handleClose();
      onContinue();
    }
  }, [dispatch, simplifiedNumeration, handleClose, onContinue]);

  /**
   * Handler for form submission.
   *
   * @param {Object} values - Form values.
   */
  const handleSubmit = useCallback(
    async (values) => {
      const transformedValues = spainTransformer(values, {
        country: COUNTRIES.SPAIN,
        contact,
      });

      try {
        await dispatch(
          replaceOfflineClient(
            contact.id,
            { address: transformedValues?.address },
            'basicNewClient',
            true,
            true
          )
        );

        await alegraAPI.put(`/contacts/${contact.id}`, {
          ...activeInvoiceClient,
          address: transformedValues?.address,
        });

        handleClose();
        onContinue();
      } catch (error) {
        console.error('Error updating client:', error);
      }
    },
    [
      dispatch,
      contact.id,
      activeInvoiceClient,
      handleClose,
      onContinue,
      contact,
    ]
  );

  // Prepare initialValues for the form
  const initialValues = useMemo(() => {
    const combinedOption =
      spainAddresses.find(
        (option) =>
          option.value ===
          `${contact.address?.city ?? ''},${contact.address?.province ?? ''}`
      ) || {};

    return {
      address: {
        province: contact.address?.province ?? '',
        city: contact.address?.city ?? '',
        combined: combinedOption,
        address: contact.address?.address ?? '',
      },
    };
  }, [contact]);

  return (
    <Modal
      isOpen={isOpen}
      title={
        <Typography
          text={I18n.get(
            'modals.updateSpainClient.title',
            'Datos del cliente incompletos'
          )}
          type="heading-4"
          variant="primary"
        />
      }
      className="modal__update-spain-client"
      onRequestClose={handleClose}
      borderlessHeader
      hideClose
    >
      <Typography
        type="label-3"
        variant="secondary"
        text={I18n.get(
          'modals.updateSpainClient.description',
          'La factura actual no incluye toda la información fiscal del cliente necesaria para emitir una Factura Ordinaria. Puedes completar los siguientes datos o cambiar a una Factura Simplificada.'
        )}
      />

      <Form
        onSubmit={handleSubmit}
        initialValues={initialValues}
        validate={validate}
        keepDirtyOnReinitialize
      >
        {({ handleSubmit, values, form, submitting, invalid }) => (
          <form noValidate onSubmit={handleSubmit}>
            <Body
              values={values}
              form={form}
              contactCreated={contact}
              country={COUNTRIES.SPAIN}
            />
            <Bottom
              disabled={invalid || submitting}
              submitting={submitting}
              onChangeNumeration={onChangeNumeration}
            />
          </form>
        )}
      </Form>
    </Modal>
  );
};

// Since UpdateSpainClient does not receive props, PropTypes are not required here.

export default UpdateSpainClient;
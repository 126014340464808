import { useMutation } from '@tanstack/react-query';
import { get } from 'lodash';

import { I18n } from '@aws-amplify/core';

import toastHandler from '../../../../utils/toast-handler';
import { graphqlOperation } from '@aws-amplify/api';

import * as mutations from '../../../../graphql/mutations';

import { handleError } from '../../../../utils/errors';
import { changeModalParams, closeModal } from '../../../../reducers/modals';

export const deleteGlobalInvoiceMutation = () => {
  return useMutation(async ({ id, api, dispatch, refetch }) => {
    dispatch(
      changeModalParams({ modal: 'confirmAction', params: { loading: true } })
    );

    try {
      await api(graphqlOperation(mutations.deleteGlobalInvoice, { id }));

      dispatch(closeModal({ modal: 'confirmAction' }));

      toastHandler({
        type: 'success',
        description: I18n.get('', 'Factura eliminada con éxito 🗑'),
      });

      if (refetch) {
        refetch();
      }

      return true;
    } catch (error) {
      const responseError = get(error, 'response.data.message');
      dispatch(
        changeModalParams({
          modal: 'confirmAction',
          params: { loading: false },
        })
      );
      toastHandler({
        type: 'error',
        description: !!responseError ? responseError : handleError(error),
      });
    }
  });
};

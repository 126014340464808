import React from 'react'
import { I18n } from '@aws-amplify/core';
import { useSelector } from 'react-redux';
import { get, capitalize, chunk } from 'lodash';

import { country as countrySelector } from '../../../selectors/company'
import { COUNTRIES } from '../../../utils/enums/countries';

const Complement = ({ invoice: { anotation, termsConditions, numberTemplate, stamp } }) => {
  const country = useSelector(countrySelector);
  const peruCondition = country === 'peru' ? get(numberTemplate, 'isElectronic', false) : true;
  const isSaleTicket = get(numberTemplate, 'documentType', '') === 'saleTicket';
  const isElectronic = get(numberTemplate, 'isElectronic', false);
  const isPOSElectronicDocument = isSaleTicket && isElectronic;

  const chunks = chunk(get(stamp, 'uuid', ''), 32);
  return (
    <div className={`w-100 text-center py-2 d-flex flex-column`}>
      {
        country === COUNTRIES.COSTA_RICA && get(stamp, 'uuid') &&
        <div className='text-xs mb-2 d-flex gap-2'>
          <div className='font-weight-bold'>Clave:</div>
          <div>
          {chunks.map((chunk, index) => (
            <div key={index}>{chunk.join('')}</div>
          ))}
          </div>
        </div>
      }
      {!!termsConditions && <p>{termsConditions}</p>}
      {!!anotation && <p>{anotation}</p>}

      {peruCondition && !!get(numberTemplate, 'invoiceText', null) && <p>{get(numberTemplate, 'invoiceText', null)}</p>}

      {peruCondition && !!get(numberTemplate, 'text', null) && <p>{get(numberTemplate, 'text', null)}</p>}

      {country === 'colombia' && !!stamp && !isPOSElectronicDocument &&
        <p>{capitalize(I18n.get('printedRepresentationOfElectronicInvoice', 'Representación impresa de la factura electrónica'))}</p>
      }

      {country === 'argentina' && !stamp &&
        <p>{capitalize(I18n.get('notValidInvoiceArg', 'Factura no valida como comprobante fiscal'))}</p>
      }
    </div>
  )
};

export default Complement;
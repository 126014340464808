import { useDispatch, useSelector } from 'react-redux';
import { I18n } from 'aws-amplify';

import { hasPermissionTo } from '../../../../selectors/auth';
import { openCreateClientSideModal } from './utils/openCreateClient';
import { Button, Popover, Tooltip, useModal } from '@alegradev/smile-ui-react';
import { isCreateClientAvailable } from './hooks/isCreateClientAvailable';
import { checkFeatureLimit } from '../../../../reducers/membership';

import useNotification from '../../../../hooks/useNotification/hook';
import CreditLimitNotification from '../../../../pages/contacts/modals/CreditLimitNotification';
import dayjs from 'dayjs';
import { getHelpLink } from '../../../forms/invoice/utils';
import { companySelector, country as countrySelector, membershipSelector } from '../../../../selectors/company';
import { get } from 'lodash';
import { IconUserPlus } from '@tabler/icons-react';
import { showCreditLimitField } from '../../../../pages/contacts/utils/limitCredit';

const NewClientButton = () => {
  const dispatch = useDispatch();
  const can = useSelector(hasPermissionTo);
  const { viewed, setViewed } = useNotification({
    notificationName: `pos-contacts-credit-limit-notification`,
  });
  const membership = useSelector(membershipSelector);
  const country = useSelector(countrySelector);
  const { registryDate } = useSelector(companySelector);
  const { openModal, closeModal } = useModal();

  const isCreationAvailable = isCreateClientAvailable();

  const handleOpenCreateClientSideModal = () => {
    dispatch(
      checkFeatureLimit('clients', () =>
        openCreateClientSideModal({ dispatch })
      )
    );
  };

  if (!isCreationAvailable) return null;

  return (
    <Popover
      defaultVisible={
        !viewed &&
        get(membership, 'pay', false) &&
        showCreditLimitField(country) &&
        dayjs(registryDate).isSameOrBefore('2024-09-12')
      }
      title={I18n.get(
        'creditLimit.popover.title',
        'Configura límites de crédito ✍️ '
      )}
      description={I18n.get(
        'creditLimit.popover.description',
        'Ya puedes crear tus clientes y definirles un límite de crédito personalizado'
      )}
      showCloseButton
      primaryAction={{
        title: I18n.get('learnMore', 'Conocer más'),
        onClick: () => {
          setViewed();
          openModal({
            name: 'creditLimitNotification',
            component: CreditLimitNotification,
            primaryAction: {
              text: I18n.get('goToHelpArticle', 'Ir al artículo de ayuda'),
              onClick: () => {
                const link = getHelpLink(country);
                window.open(link, '_blank');
              },
            },
            thirdAction: {
              text: I18n.get('goBackToInvoice', 'Volver a facturar'),
              onClick: () => closeModal('creditLimitNotification'),
            },
          });
        },
      }}
      onClose={() => {
        setViewed();
      }}
    >
      <Tooltip
        visible={!can('add', 'contacts')}
        overlay={I18n.get(
          'addContactsUnallow',
          'Te hace falta el permiso para crear contactos'
        )}
      >
        <Button
          disabled={!can('add', 'contacts') || !isCreateClientAvailable}
          onClick={handleOpenCreateClientSideModal}
          type='button'
          size='large'
          emphasis='subtle'
          text={I18n.get('new', 'Nuevo')}
          leftIcon={IconUserPlus}
        />
      </Tooltip>
    </Popover>
  );
};

export default NewClientButton;

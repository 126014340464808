import { COUNTRIES } from "../../../utils/enums/countries";
import CanVoidStrategy from "./canVoidStrategy";
import {
  DefaultStrategy,
  MexicoStrategy,
  PeruStrategy,
  RepublicaDominicanaStrategy,
  PanamaStrategy,
  ColombiaStrategy,
  SpainStrategy,
} from './voidCountriesStrategies';

/**
 * Factory to get the appropriate CanVoid strategy based on the country.
 *
 * @param {string} country - The country code.
 * @returns {CanVoidStrategy} The strategy object.
 */
const getCanVoidStrategy = (country) => {
  switch (country) {
    case COUNTRIES.MEXICO:
      return new MexicoStrategy();
    case COUNTRIES.PERU:
      return new PeruStrategy();
    case COUNTRIES.REPUBLICA_DOMINICANA:
      return new RepublicaDominicanaStrategy();
    case COUNTRIES.PANAMA:
      return new PanamaStrategy();
    case COUNTRIES.COLOMBIA:
      return new ColombiaStrategy();
    case COUNTRIES.SPAIN:
      return new SpainStrategy();
    default:
      return new DefaultStrategy();
  }
};

export default getCanVoidStrategy;
import { Typography } from '@alegradev/smile-ui-react';
import { I18n } from 'aws-amplify';
import step1 from '../assets/creditLimitNotification1.png';
import step2 from '../assets/creditLimitNotification2.png';
import step3 from '../assets/creditLimitNotification3.png';
import step4 from '../assets/creditLimitNotification4.png';
import step5 from '../assets/creditLimitNotification5.png';
import { useEffect, useState } from 'react';
import './styles/creditLimitNotification.scss';

export const CreditLimitNotification = () => {
  const images = [step1, step2, step3, step4, step5];

  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 1500);

    return () => clearInterval(interval);
  }, [images]);

  return (
    <div className='d-flex flex-column align-items-center'>
      <div className='credit-limit-image-container mt-4 mb-3'>
        {images.map((image, index) => (
          <img
            key={index}
            src={image}
            alt={`Step ${index + 1}`}
            className={index === currentImageIndex ? 'active' : ''}
          />
        ))}
      </div>
      <Typography
        extraClassName='my-4'
        type='heading-4'
        text={I18n.get('controlYourCredits', '¡Tus créditos bajo control! 🌟')}
      />
      <Typography
        extraClassName='text-center pl-5 pr-5 mb-5'
        type='body-3-regular'
        text={I18n.get(
          'defineACreditLimit',
          'Define un límite de crédito para cada cliente y el sistema te alertará si lo excedes al facturar.'
        )}
      />
    </div>
  );
};

export default CreditLimitNotification;

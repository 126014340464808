import React from 'react';
import PropTypes from 'prop-types';
import { I18n } from '@aws-amplify/core';

import { Icon } from '@alegradev/smile-ui-react';
import { IconLoader2 } from '@tabler/icons-react';

const Bottom = ({ onClose, onConfirm, closeText, submitText, disabled, submitting, hideRequired }) => (
  <div className="side-modal__bottom w-100 p-3 d-flex justify-content-between align-items-center shadow bg-white">
    <div className={`required-message ${!!hideRequired ? 'invisible': ''}`}>
      <i className="text-primary mr-2">* </i>
      <span className="text-capitalize-first">{I18n.get('requiredFields', 'Campos obligatorios')}</span>
    </div>
    <div className="side-modal__bottom-buttons d-flex">
      <button
        type="button"
        className="btn btn-cancel text-capitalize-first"
        onClick={() => onClose()}
      >
        {closeText || I18n.get('cancel', 'cancelar')}
      </button>
      <button 
        type={!!onConfirm ? "button": "submit"} 
        className="btn btn-submit absolute-center text-capitalize-first" 
        disabled={disabled}
        onClick={onConfirm}
      >
        {submitting
          ? <Icon icon={IconLoader2} animated extraClass=" icon-white"/>
          : submitText || I18n.get('save', 'guardar')
        }
      </button>
    </div>
  </div>
);

Bottom.propTypes = {
  closeText: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func,
  submitText: PropTypes.string,
  disabled: PropTypes.bool,
  submitting: PropTypes.bool,
  hideRequired: PropTypes.bool,
}

export default Bottom;

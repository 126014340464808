import React from 'react';
import { Field } from 'react-final-form';
import { I18n } from '@aws-amplify/core';
import { capitalize, repeat } from 'lodash';
import { useSelector } from 'react-redux'

import { getCategoryByType } from '../../../selectors/categories'
import { isOnlyInvoicingPlan } from '../../../selectors/company'
import { renderSelect } from '../fields/V0/Fields';

const Accounting = () => {
  const categories = useSelector(getCategoryByType('income'))
  const onlyInvoicing = useSelector(isOnlyInvoicingPlan)

  return (
    <div className="form-fields form-row justify-content-start">
      <Field
        name="accountingAccount"
        component={renderSelect}
        disabled={onlyInvoicing}
        onlyInvoicingTooltip={onlyInvoicing}
        className="col-6"
        label={capitalize(I18n.get('accountingAccount', 'cuenta contable'))}
        options={categories}
        getOptionValue={option => option.id}
        getOptionLabel={option => `${!!option.depth ? repeat('-', option.depth - 1): ''} ${option.name}`}
      />
    </div>
  )
}

export default Accounting;

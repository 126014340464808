import { createDraftSafeSelector } from '@reduxjs/toolkit';
import { get } from 'lodash';

import { costCentersSelector } from '../reducers/costCenters'

export const activeCostCenters = createDraftSafeSelector(
  costCentersSelector.selectAll,
  costCenters => !!costCenters
    ? costCenters.filter(costCenter => get(costCenter, 'status') === 'active')
    : []
)

export const getCostCenterById = id => createDraftSafeSelector(
  state => costCentersSelector.selectById(state, id),
  costCenter => !!costCenter && get(costCenter, 'status') === 'active' ? costCenter : null
)
export const COLOMBIA_UVT = {
    2023: {
        UVT: 212060,
        UVT_FORMATED: '212.060',
    },
    2024: {
        UVT: 235325,
        UVT_FORMATED: '235.325',
    }
}

/**
* Get UVT 
 * @returns {object} UVT
 */
export function getColombiaUVT() {
    return COLOMBIA_UVT['2024'];
}
import React from 'react';
import { Field } from 'react-final-form';
import { I18n } from '@aws-amplify/core';
import { get, capitalize } from 'lodash';

import kindOfPersonOptionsColombia from '../../../../../components/countriesData/colombia/kindOfPerson';
import { renderField } from '../../../../../components/forms/fields/V2';
import { Grid } from '@alegradev/smile-ui-react';
import { COUNTRIES } from '../../../../../utils/enums/countries';

const colombiaAddressKindOfPerson = (props) => {
  const { Col } = Grid;
  const identification = get(props, 'values.identification.type', '');

  return props.country === 'colombia' && identification === 'NIT' ? (
    <Col xs={6}>
      <Field
        name='kindOfPerson'
        component={renderField}
        fieldType='select'
        label={capitalize(I18n.get('kindOfPerson', 'tipo de persona'))}
        options={kindOfPersonOptionsColombia?.map((option) => ({
          text: option.value,
          value: option?.key,
        }))}
        required={props.isElectronic}
      />
    </Col>
  ) : null;
};

const renderKindOfPerson = (props) => {
  switch (props.country) {
    case COUNTRIES.COLOMBIA:
      return colombiaAddressKindOfPerson(props);
    default:
      return null;
  }
};

export default renderKindOfPerson;

import { useSelector, useDispatch } from 'react-redux';
import { get } from 'lodash';

import {
  APIGraphqlSelector,
  station as stationSelector,
  stationWarehouse,
} from '../../../selectors/app';
import {
  decimalPrecision,
  country as countrySelector,
  electronicInvoicing,
  regime as regimeSelector,
} from '../../../selectors/company';
import {
  requiredFields,
  customFields as customFieldSelector,
} from '../../../selectors/customFields';
import { getCategoryByName } from '../../../selectors/categories';

const useReduxData = () => {
  const dispatch = useDispatch();
  const country = useSelector(countrySelector);
  const decimal = useSelector(decimalPrecision);
  const customFields = useSelector(requiredFields);
  const allCustomFields = useSelector(customFieldSelector);
  const station = useSelector(stationSelector);
  const mainWarehouse = useSelector(stationWarehouse);
  const regime = useSelector(regimeSelector);
  const isElectronic = useSelector(electronicInvoicing);
  const mainCategory = useSelector(getCategoryByName('Ventas', 'Sales'));
  const inventoryCategory = useSelector(getCategoryByName('Inventarios'));
  const inventariablePurchaseCategory = useSelector(getCategoryByName('Costos de inventario'));
  const APIGraphql = useSelector(APIGraphqlSelector);

  // Modal states
  const isItemCategoryOpen = useSelector((state) => get(state, 'modals.itemCategory.isOpen', false));
  const isPriceListOpen = useSelector((state) => get(state, 'modals.priceList.isOpen', false));
  const isWarehouseOpen = useSelector((state) => get(state, 'modals.warehouse.isOpen', false));

  return {
    dispatch,
    country,
    decimal,
    customFields,
    allCustomFields,
    station,
    mainWarehouse,
    regime,
    isElectronic,
    mainCategory,
    inventoryCategory,
    inventariablePurchaseCategory,
    isItemCategoryOpen,
    isPriceListOpen,
    isWarehouseOpen,
    APIGraphql,
  };
}

export default useReduxData;
import argentinaProvinces from '../../../../../../components/countriesData/argentina/provinces';
import argentinaCities from '../../../../../../components/countriesData/argentina/cities.json';
import { get } from 'lodash';

function argentinaInitialValues({
  contact,
  defaultAccountReceivableCategory,
  defaultDebtToPayCategory,
  ...props
}) {
  if (!!contact) {
    return {
      ...contact,
      ...contact?.nameObject,
      firstName: contact?.nameObject?.firstName || contact?.name,
      identification: {
        ...contact?.identificationObject,
        number: contact?.identificationObject
          ? contact?.identificationObject?.number
          : contact?.identification?.number,
        type: contact?.identificationObject
          ? contact?.identificationObject?.type?.key
            ? contact?.identificationObject?.type?.key
            : contact?.identificationObject?.type
          : contact?.identification?.type,
      },
      email: contact?.email,
      address: {
        ...contact?.address,
        combined: `${contact?.address?.city}, ${contact?.address?.department}`,
      },
      seller: contact?.seller?.id ? `${contact?.seller?.id}` : contact?.seller,
      priceList: contact?.priceList?.id
        ? `${contact?.priceList?.id}`
        : contact?.priceList,
      term: contact?.term?.id ? `${contact?.term?.id}` : contact?.term,
      statementAttached: contact?.statementAttached,
      accounting: {
        accountReceivable: contact?.accounting?.accountReceivable?.id,
        debtToPay: contact?.accounting?.debtToPay?.id,
      },
    };
  }

  return {
    accounting: {
      accountReceivable: defaultAccountReceivableCategory?.id,
      debtToPay: defaultDebtToPayCategory?.id,
    },
    address: {
      country: 'ARG',
      province: argentinaProvinces.find(
        (option) => option.value === get(props, 'companyAddress.province', null)
      ),
      city: argentinaCities.find(
        (option) => option.value === get(props, 'companyAddress.city', null)
      ),
    },
    identification: {
      type: 'DNI',
    },
    statementAttached: true,
    ignoreRepeated: false,
  };
}

export default argentinaInitialValues;

import axios from 'axios';
import Cookie from 'js-cookie';
import { get } from 'lodash';
import { toast } from '../../../utils';

export const uploadFileToAlegraAPI = async (base64file) => {
  const binary = atob(base64file.replace(/\s/g, ""));
  const buffer = new ArrayBuffer(binary.length);
  const view = new Uint8Array(buffer);
  for (let i = 0; i < binary.length; i++) {
    view[i] = binary.charCodeAt(i);
  }
  const blob = new Blob([view], { type: "application/pdf" })
  const file = new File([blob], 'document.pdf', {
    type: blob.type
  })

  const formData = new FormData();
  formData.append("document", file);
  formData.append("type", "mandate-signature")

  const config = {
    method: "POST",
    url: process.env.REACT_APP_ALEGRA_ENDPOINT + '/company/attachment',
    headers: {
      'X-Data-Source': 'pos',
      Authorization: `Basic ${Cookie.get(process.env.REACT_APP_ALEGRA_COOKIE)}`
    },
    data: formData
  }

  try {
    const response = await axios(config);
    return response;
  } catch (error) {
    if (get(error, 'response.data') && error.response.data.code && error.response.data.message) {
      return toast.error({
        title: error.response.data.message,
        params: { autoClose: 3000 }
      })
    }

    if (!error.isAxiosError) {
      uploadFileToAlegraAPI(base64file);
    }
  }
}
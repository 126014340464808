import { createDraftSafeSelector } from '@reduxjs/toolkit';
import { get, flow, deburr, lowerCase, isArray } from 'lodash';

import { categoriesSelector } from '../reducers/categories'

const accountReceivable = state => get(state, 'categories.receivableAccounts', null);
const debtToPay = state => get(state, 'categories.debtsToPayProviders', null);

const addDepth = (tree, initialDepth = 0) =>
  tree.map(child => ({
    ...child,
    depth: initialDepth,
    children: (!!child?.children && !!child?.children?.length)
      ? addDepth(child?.children, initialDepth + 1)
      : child?.children
  }))

const flattenTree = tree => {
  let arr = [];

  tree.map(leaf => {
    arr.push(leaf);

    if (!!leaf.children && !!leaf.children.length)
      arr.push(...flattenTree(leaf.children));

    return null;
  });

  return arr;
}

export const categories = createDraftSafeSelector(
  categoriesSelector.selectAll,
  categories => {
    categories = flow([
      addDepth,
      flattenTree,
    ])(categories);

    categories = categories.filter(cat => !get(cat, 'readOnly') && get(cat, 'blocked') !== 'yes');
    return categories;
  }
)

export const pasivesCategoriesWithoutFilter = createDraftSafeSelector(
  categoriesSelector.selectAll,
  categories => {
    let pasivesCategories = categories.filter(cat => {
      const categoryRuleKey = get(cat, 'categoryRule.key', '');
      return categoryRuleKey === 'LIABILITIES';
    }
    );

    pasivesCategories = flow([
      addDepth,
      flattenTree,
    ])(pasivesCategories);
    return pasivesCategories;
  }
)

export const billsCategoriesWithoutFilter = createDraftSafeSelector(
  categoriesSelector.selectAll,
  categories => {
    let pasivesCategories = categories.filter(cat => {
      const categoryRuleKey = get(cat, 'categoryRule.key', '');
      return categoryRuleKey === 'EXPENSES';
    });

    pasivesCategories = flow([
      addDepth,
      flattenTree,
    ])(pasivesCategories);
    return pasivesCategories;
  }
)

export const getCategoryByName = (name, altName = false) => createDraftSafeSelector(
  categories,
  categories => {
    if (!categories)
      return null;
    if (!categories.find(cat => get(cat, 'name') === name) && !!altName)
      return categories.find(cat => get(cat, 'name') === altName)
    return categories.find(cat => get(cat, 'name') === name)
  }
)

export const accountMexicoReceivableCategories = createDraftSafeSelector(
  accountReceivable,
  categories => {
    if (!!categories) {
      let national = get(categories, 'national', [])
      let foreign = get(categories, 'foreign', [])

      national = isArray(national) ? national : [national]
      foreign = isArray(foreign) ? foreign : [foreign]

      national = flow([
        addDepth,
        flattenTree,
      ])(national);

      foreign = flow([
        addDepth,
        flattenTree,
      ])(foreign);

      national = national.filter(cat => !get(cat, 'readOnly') && get(cat, 'blocked') !== 'yes');
      foreign = foreign.filter(cat => !get(cat, 'readOnly') && get(cat, 'blocked') !== 'yes');

      return {
        national,
        foreign,
      };
    }
    return []
  }
);

export const debtToPayMexicoCategories = createDraftSafeSelector(
  debtToPay,
  categories => {
    if (!!categories) {
      let national = get(categories, 'national', [])
      let foreign = get(categories, 'foreign', [])

      national = isArray(national) ? national : [national]
      foreign = isArray(foreign) ? foreign : [foreign]

      national = flow([
        addDepth,
        flattenTree,
      ])(national);

      foreign = flow([
        addDepth,
        flattenTree,
      ])(foreign);

      national = national.filter(cat => !get(cat, 'readOnly') && get(cat, 'blocked') !== 'yes');
      foreign = foreign.filter(cat => !get(cat, 'readOnly') && get(cat, 'blocked') !== 'yes');

      return {
        national,
        foreign,
      };
    }
    return []
  }
);

export const accountReceivableCategories = createDraftSafeSelector(
  accountReceivable,
  categories => {
    if (!!categories) {
      categories = isArray(categories) ? categories : [categories]

      categories = flow([
        addDepth,
        flattenTree,
      ])(categories);

      categories = categories.filter(cat => !get(cat, 'readOnly') && get(cat, 'blocked') !== 'yes');
      return categories;
    }
    return []
  }
);

export const debtToPayCategories = createDraftSafeSelector(
  debtToPay,
  categories => {
    if (!!categories) {
      categories = isArray(categories) ? categories : [categories]

      categories = flow([
        addDepth,
        flattenTree,
      ])(categories);

      categories = categories.filter(cat => !get(cat, 'readOnly') && get(cat, 'blocked') !== 'yes');
      return categories;
    }
    return []
  }
);

export const getCategoryByType = type => createDraftSafeSelector(
  categories,
  categories => categories.filter(cat => get(cat, 'type') === type)
)

export const groupedCategories = state => {
  const categories = categoriesSelector.selectAll(state);
  return !!categories
    ? categories.map(rootCat => ({
      label: get(rootCat, 'name', ''),
      options: getCategoryByType(get(rootCat, 'type', ''))(state),
    }))
    : []
}

export const defaultInCategory = createDraftSafeSelector(
  getCategoryByType('income'),
  categories => categories.find(category =>
    deburr(lowerCase(get(category, 'name', ''))) === deburr(lowerCase('otros ingresos')))
    || null
)

export const defaultOutCategory = createDraftSafeSelector(
  getCategoryByType('expense'),
  categories => categories.find(category =>
    deburr(lowerCase(get(category, 'name', ''))) === deburr(lowerCase('otros gastos')))
    || null
)

export const getCategoryById = id => createDraftSafeSelector(
  categories,
  categories => !!categories ? categories.find(cat => +cat.id === +id) || null : null
)

export const getDefaultAccountReceivableCategory = createDraftSafeSelector(
  accountReceivableCategories,
  categories => categories.find(category => get(category, 'parameterizedMovement', false))
)

export const getDefaultDebtToPayCategory = createDraftSafeSelector(
  debtToPayCategories,
  categories => categories.find(category => get(category, 'parameterizedMovement', false))
)

export const getDefaultMexicoAccountReceivableCategory = createDraftSafeSelector(
  accountMexicoReceivableCategories,
  categories => categories?.national?.find(category => get(category, 'parameterizedMovement', false))
)

export const getDefaultForeigMexicoAccountReceivableCategory = createDraftSafeSelector(
  accountMexicoReceivableCategories,
  categories => categories?.foreign?.find(category => get(category, 'parameterizedMovement', false))
)

export const getDefaultMexicoDebtToPayCategory = createDraftSafeSelector(
  debtToPayMexicoCategories,
  categories => categories?.national?.find(category => get(category, 'parameterizedMovement', false))
)

export const getDefaultForeigMexicoDebtToPayCategory = createDraftSafeSelector(
  debtToPayMexicoCategories,
  categories => categories?.foreign?.find(category => get(category, 'parameterizedMovement', false))
)
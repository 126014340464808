// eslint-disable-next-line
export default ({ id, className }) => (
  <svg
    id={id}
    className={className}
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M 16 13.789062 C 16 11.511719 17.789062 9.667969 20 9.667969 C 22.210938 9.667969 24 11.511719 24 13.789062 C 24 16.0625 22.210938 17.910156 20 17.910156 C 17.789062 17.910156 16 16.0625 16 13.789062 Z M 16 13.789062"
      fill="#00D6BC" />
    <path d="M 13.332031 26.152344 C 13.332031 22.359375 16.316406 19.28125 20 19.28125 C 23.683594 19.28125 26.667969 22.359375 26.667969 26.152344 L 26.667969 29.101562 C 26.667969 30.226562 26.117188 31.269531 25.082031 31.613281 C 24 31.972656 22.332031 32.332031 20 32.332031 C 17.667969 32.332031 16 31.972656 14.917969 31.613281 C 13.882812 31.269531 13.332031 30.226562 13.332031 29.101562 Z M 13.332031 26.152344"
      fill="#00D6BC" />
    <path d="M 7.667969 13.9375 C 7.667969 11.957031 9.246094 10.355469 11.167969 10.355469 C 13.085938 10.355469 14.667969 11.957031 14.667969 13.9375 C 14.667969 15.917969 13.085938 17.519531 11.167969 17.519531 C 9.246094 17.519531 7.667969 15.917969 7.667969 13.9375 Z M 7.667969 13.9375 "
      fill="#00D6BC" />
    <path d="M 5.332031 24.605469 C 5.332031 21.285156 7.945312 18.597656 11.167969 18.597656 C 12.289062 18.597656 13.339844 18.921875 14.230469 19.488281 C 12.453125 21.125 11.332031 23.503906 11.332031 26.152344 L 11.332031 29.101562 C 11.332031 29.375 11.355469 29.648438 11.398438 29.929688 C 11.320312 29.929688 11.246094 29.929688 11.167969 29.929688 C 9.203125 29.929688 7.777344 29.632812 6.828125 29.328125 C 5.847656 29.011719 5.332031 28.027344 5.332031 26.96875 Z M 5.332031 24.605469"
      fill="#00D6BC" />
    <path d="M 28.667969 29.101562 C 28.667969 29.375 28.644531 29.648438 28.601562 29.929688 C 28.679688 29.929688 28.753906 29.929688 28.832031 29.929688 C 30.796875 29.929688 32.222656 29.632812 33.171875 29.328125 C 34.152344 29.011719 34.667969 28.027344 34.667969 26.96875 L 34.667969 24.605469 C 34.667969 21.285156 32.054688 18.597656 28.832031 18.597656 C 27.710938 18.597656 26.660156 18.921875 25.769531 19.488281 C 27.546875 21.125 28.667969 23.503906 28.667969 26.152344 Z M 28.667969 29.101562 "
      fill="#00D6BC" />
    <path d="M 25.332031 13.9375 C 25.332031 11.957031 26.914062 10.355469 28.832031 10.355469 C 30.753906 10.355469 32.332031 11.957031 32.332031 13.9375 C 32.332031 15.917969 30.753906 17.519531 28.832031 17.519531 C 26.914062 17.519531 25.332031 15.917969 25.332031 13.9375 Z M 25.332031 13.9375 "
      fill="#00D6BC" />
  </svg>

)

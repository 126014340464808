import React from 'react'
import PropTypes from 'prop-types'

const Radio = ({ active, action, label, containerClassName, containerCustomCss }) => {
  const containerStyles = `${!!label ? 'border rounded ' : ''}${!!label && active ? 'border-primary' : 'pointer border-secondary-alt'}${!!containerClassName ? ' ' + containerClassName : ''}`

  const radioContainerStyles = `pointer d-inline-flex border ${active ? 'border-primary' : 'border-secondary-alt'} rounded-circle`

  return (
    <div className={containerStyles}
      style={{ background: !!label && !active ? '#F8FAFC' : '', ...containerCustomCss }}
      onClick={action}>
      <div className={radioContainerStyles}>
        <div className={`rounded-circle ${active ? 'bg-primary' : ''}`} style={{ width: '10px', height: '10px', margin: '2px' }} />
      </div>
      {!!label && (
        <div className={`d-inline noselect`}
          style={{ color: active ? '#187577' : '#94A3B8', paddingLeft: '16px', fontSize: '14px' }}>
          {label}
        </div>
      )}
    </div>
  )
}

Radio.propTypes = {
  active: PropTypes.bool,
  action: PropTypes.func,
  label: PropTypes.string,
  containerClassName: PropTypes.string,
  containerCustomCss: PropTypes.object,
}

export default Radio
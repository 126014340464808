import { createDraftSafeSelector } from '@reduxjs/toolkit';
import { get } from 'lodash';

import { itemsSelector } from '../reducers/items'

export const allItems = createDraftSafeSelector(
  itemsSelector.selectAll,
  items => !!items ? items : []
)

export const loading = state => get(state, 'items.loading') === 'pending'

export const error = state => get(state, 'items.error')

export const searching = state => get(state, 'items.searching')

export const filters = state => get(state, 'items.filters');

export const isFiltered = createDraftSafeSelector(
  filters,
  filters => {
    if (!!get(filters, 'text'))
      return true;
    if (!!get(filters, 'barcode'))
      return true;
    if (!!get(filters, 'categories.length'))
      return true;
    return false;
  }
)

import { useSelector } from 'react-redux';
import { getShift } from '../../graphql/queries';
import { APIGraphqlSelector } from '../../selectors/app';
import { graphqlOperation } from 'aws-amplify';
import { useQuery } from '@tanstack/react-query';
import alegraAPI from '../../reducers/alegraAPI';

const fetchShift = async (APIGraphql, id) => {
  const response = await APIGraphql(graphqlOperation(getShift, { id }));

  return response?.data?.getShift;
};

const fetchInvoice = async (id) => {
  const fields = "stampFiles,creditNotes";
  const response = await alegraAPI.get(`/invoices/${id}?fields=${fields}`);

  return response.data;
}

export const useShiftQuery = (id) => {
  const APIGraphql = useSelector(APIGraphqlSelector);

  return useQuery({
    queryKey: ['shift', { id }],
    queryFn: () => fetchShift(APIGraphql, id),
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    refetchOnReconnect: true,
    staleTime: 1000 * 60 * 60 * 24,
  });
};

export const useInvoiceQuery = (id, enabled) => {
  return useQuery({
    queryKey: ['invoice', { id }],
    queryFn: () => fetchInvoice(id),
    refetchOnWindowFocus: false,
    refetchOnReconnect: true,
    staleTime: 1000 * 60 * 60 * 24,
    enabled,
  });
};